import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { BaseModelService } from '../base.model.service';
import SignUpSettingsModel from '../../models/templates/signup.service.model';

@Injectable({
  providedIn: 'root'
})
export class SignUpSettingsService extends BaseModelService<SignUpSettingsModel> {

  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'SignUpConfiguration';
  }


  public getConfigurationByClientId(clientId: string): Observable<any> {
    return this.configService.loadConfig().pipe(
        flatMap((config) => {
          return this.http.get<any>(`${config.apiURL}/${this.controllerName}/byClient/${clientId}`);
        })
      );
  }

  public submitSignUpConfiguration(model: any): Observable<any> {
    return this.configService.loadConfig().pipe(
        flatMap((config) => {
          return this.http.post<any>(`${config.apiURL}/${this.controllerName}`, model);
        })
      );
  }

  public updateSignUpConfiguration(model: any): Observable<any> {
    return this.configService.loadConfig().pipe(
        flatMap((config) => {
          return this.http.put<any>(`${config.apiURL}/${this.controllerName}`, model);
        })
      );
  }

}