<div>
  <div [matMenuTriggerFor]="menu">
    <ng-content></ng-content>
  </div>
  <mat-menu #menu="matMenu" style="width: 200px;height: 200px;">
    <div class="fc-rs" (click)="$event.stopPropagation()">
      <nouislider [connect]="true" [tooltips]="[ true, true ]" [step]="step" [min]="minMaxRange[0]" [max]="minMaxRange[1]" [(ngModel)]="someRange"></nouislider>
      <div class="buttons">
        <app-button label="Apply Filters" type="basic" color="#0064be" (clickEvent)="applyFilters()"></app-button>
        <app-button label="Clear All" type="basic" color="#0064be" (clickEvent)="clearAll()"></app-button>
      </div>
    </div>
  </mat-menu>
</div>