export enum TerritorialEntityType {
  Unknown = 0,
  AreaDistrict = 1,
  CEDEX = 2,
  District = 3,
  Emirate = 4,
  Neighborhood = 5,
  Oblast = 6,
  Prefecture = 7,
  Townland = 8,
}
