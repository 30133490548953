<div class="add-tag-wrapper">
  <div class="add-tag-header">
    <div class="add-tag-title-wrapper">
      <span class="add-tag-title">{{'Add Tags' | translate | uppercase}}</span>
      <a class="add-tag-back-link" (click)="backToSegmentManagement()">
        < {{'CUSTOMIZATION.Back to Segment Management' | translate}}</a>
    </div>
  </div>
  <div class="tag-manager-wrapper">

    <div class="tag-manager-row" *ngIf="batchId$ | async as sessionId">
      <app-tag-select
        [selectedTags]="selectedTags"
        [batchId]="sessionId"
        [clientId]="authService.getIdentityClaimsOriginId()"
        [tagService]="singletonClientDonorTagService"
      ></app-tag-select>
    </div>

    <ng-container *ngIf="(canWorkWithSegments$ | async)">
      <div class="tag-manager-row">
        <div class="max-width-720">
          <app-search-select
            [formGroup]="segmentForm"
            name="segment"
            errorMassage="Segment"
            label="List of Segments"
            [selectOptions]="segmentOptions$ | async"
          ></app-search-select>
        </div>
      </div>

      <ng-container *ngIf="currentSegment$ | async">
        <div class="tag-manager-row">
          <div class="max-width-550 right-margin">
            <app-input
              [formGroup]="segmentForm"
              name="search"
              placeholder="Search"
            ></app-input>
          </div>

          <app-button
            label="Toggle All"
            type="stroked"
            color="#0064be"
            (clickEvent)="toggleAll()"
            [disabled]="inProgress$ | async"
            width="140"
          ></app-button>
        </div>

        <div class="tag-manager-donors-list" *ngIf="donors$ | async as donors">
          <div class="donors-item-checkbox" *ngFor="let donor of donors">
            <i class="far fa-square" [class.disable-view]="inProgress$ | async" *ngIf="!donor.isSelected" (click)="toggleDonor(donor)"></i>
            <i class="fas fa-check-square" [class.disable-view]="inProgress$ | async" *ngIf="donor.isSelected" (click)="toggleDonor(donor)"></i>
            <span>{{donor.displayName}}</span>
          </div>
        </div>

        <div class="tag-manager-row">
          <div class="max-width-720">
            <app-paginator
              [totalItems]="total"
              (nextPage)="getNextPage($event)"
              (entriesPerPageChanged)="setEntriesPerPage($event)"
              [setDefaultPage]="setDefaultPage$"
              [disabled]="inProgress$ | async"
            ></app-paginator>
          </div>
        </div>
      </ng-container>

      <div class="tag-manager-row">
        <div class="max-width-720 save-button">
          <app-button
            label="Update"
            color="#0064be"
            type="flat"
            width="140"
            (clickEvent)="update()"
            [disabled]="inProgress$ | async"
          ></app-button>
        </div>
      </div>

    </ng-container>

  </div>
</div>
