<div class="image-tool-wrapper" [ngStyle]="getContainerStyles()">
  <div *ngIf="isEmpty && !landingPageView; else smTemplate" class="empty-image-src">{{'Social Media'  |translate}}</div>
</div>

<ng-template #smTemplate>
  <div
    *ngIf="isHorizontal; else verticalTemplate"
    class="sm-content"
    [ngStyle]="contentStyles"
  >
    <ng-container *ngIf="!landingPageView; else horizontalLandingPageTemplate">
      <div
        *ngFor="let child of entity.children"
        class="child-container"
        [ngStyle]="getChildContainerStyles()"
      >
        <img [src]="child.attributes['src']" [ngStyle]="getImageStyles(child)">
        <div class="child-container-text">{{child.content | translate}}</div>
      </div>
    </ng-container>

  </div>
</ng-template>

<ng-template #verticalTemplate>
  <div [ngStyle]="contentStyles" class="sm-content">
    <table>
      <tr *ngFor="let child of entity.children">
        <td [ngStyle]="getChildContainerStyles()" class="child-container">
          <ng-container *ngIf="!landingPageView; else verticalLandingPageTemplate">
            <img [src]="child.attributes['src']" [ngStyle]="getImageStyles(child)">
            <div class="child-container-text">{{child.content | translate}}</div>
          </ng-container>

          <ng-template #verticalLandingPageTemplate>
            <a [href]="child.attributes['href']" class="child-container">
              <img [src]="child.attributes['src']" [ngStyle]="getImageStyles(child)">
              <div class="child-container-text" [ngStyle]="textStyle">{{child.content | translate}}</div>
            </a>
          </ng-template>

        </td>
      </tr>
    </table>
  </div>
</ng-template>


<ng-template #horizontalLandingPageTemplate>
  <a
    *ngFor="let child of entity.children"
    class="child-container"
    [ngStyle]="getChildContainerStyles()"
    [href]="child.attributes['href']"
  >
    <img [src]="child.attributes['src']" [ngStyle]="getImageStyles(child)">
    <div class="child-container-text" [ngStyle]="textStyle">{{child.content | translate}}</div>
  </a>
</ng-template>
