import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../routes/admin/admin-confirmation/admin-confirmation.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CancelReasonType } from '../../models/enum/cancelReasonType';
import FormElementDataModel from '../../models/form.element.data.model';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { ClientModuleService } from '../../routes/clients/client.module.service';

@Component({
  selector: 'app-cancelling-reason',
  templateUrl: './cancelling-reason.component.html',
  styleUrls: ['./cancelling-reason.component.scss']
})
export class CancellingReasonComponent implements OnInit, OnDestroy {
  public reasonForm: FormGroup = this.formBuilder.group({
    reason: [CancelReasonType.LaidOff, [Validators.required]],
    otherReason: ['', [Validators.required]]
  });
  public CancelReasonType = CancelReasonType;

  public reasonOptions: FormElementDataModel[] = [
    {label: 'Laid Off', value: CancelReasonType.LaidOff},
    {label: 'Financial Restrictions', value: CancelReasonType.FinancialRestrictions},
    {label: 'Donor Not Confident in the effect of the donation', value: CancelReasonType.DonorNotConfident},
    {label: 'Donor is no longer interested in Organization', value: CancelReasonType.DonorNoInterested},
    {label: 'I do not wish to disclose', value: CancelReasonType.DidNotWantToDisclose},
    {label: 'Other', value: CancelReasonType.Other}
  ];

  public reasonOptionsDonorPortal: FormElementDataModel[] = [
    {label: 'Financial Restrictions', value: CancelReasonType.FinancialRestrictions},
    {label: 'Unsure how the donation is being used', value: CancelReasonType.UnsureHowUsed},
    {label: 'I do not wish to disclose', value: CancelReasonType.DidNotWantToDisclose},
    {label: 'Other', value: CancelReasonType.Other}
  ];

  public subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<CancellingReasonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private clientModuleService: ClientModuleService
  ) { }

  public ngOnInit(): void {
    this.otherReason.disable();
    this.subscription.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
    this.subscription.add(
      this.clientModuleService.clientName.subscribe((clientName: string) => {
        this.reasonOptions[3].label = `Donor is no longer interested in ${clientName || 'Organization'}`;
      })
    );
   if(this.data?.isDonorPortal) {
     const control = this.reasonForm.get('reason');
     control.setValue('');
     control.setValidators(Validators.required)
   }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  public get reason(): CancelReasonType {
    const control = this.reasonForm.get('reason');
    return control ? control.value : CancelReasonType.LaidOff;
  }

  public get otherReason(): FormControl {
    return this.reasonForm.get('otherReason') as FormControl;
  }

  public onReasonChanged(): void {
    this.reason === CancelReasonType.Other ? this.otherReason.enable() : this.otherReason.disable();
  }

  public onOkClick(): void {
    this.reasonForm.markAllAsTouched();
    if (this.reasonForm.invalid) {
      return;
    }
    if (this.reason === CancelReasonType.Other) {
      this.dialogRef.close(this.otherReason.value);
    } else {
      var value = this.reasonOptions.find(option => option.value === this.reason) ? this.reasonOptions.find(option => option.value === this.reason) : this.reasonOptionsDonorPortal.find(option => option.value === this.reason);
      this.dialogRef.close(value ? value.label : 'Other');
    }
  }

}
