import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { DonorEmployeeService } from './donor-employee.service';
import DonorEmployeeModel from 'src/app/models/donor/donor.employee.model';

@Injectable({ providedIn: 'root' })
export class DonorEmloyeeResolver implements Resolve<DonorEmployeeModel> {
  constructor(private donorEmployeeService: DonorEmployeeService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.donorEmployeeService.getModel(route.paramMap.get('id'));
  }
}
