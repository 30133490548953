<div class="publish-tab-wrapper">

  <div class="custom-url-result">
    <span class="custom-url-result-label">{{'BUILD.URL preview' | translate}}</span>
    <span>{{currentUrl$ | async}}</span>
  </div>

  <div class="publish-tab-row">
    <div class="max-width-500 right-margin">
      <app-input
        label="Custom URL part"
        [formGroup]="customUrlForm"
        name="url"
        [inputReadOnly]="true"
      ></app-input>

      <!--<div
        class="url-info-icon"
        matTooltip="URL will be encoded into valid UTF-8 encoding"
        matTooltipHideDelay="200"
        matTooltipShowDelay="200"
        matTooltipPosition="above"
        matTooltipClass="tooltip-wrapper"
      >
        <i class="fas fa-info-circle"></i>
      </div>-->
    </div>
  </div>


  <div class="custom-url-result" *ngIf="paymentLink$ | async as link">
    <span class="custom-url-result-label">{{'BUILD.Payment Page URL' | translate}}</span>
    <div class="copy-url-container">
      <span>{{link}}</span>
      <div class="copy-button" (click)="copyContent(link, 'URL successfully copied')">
        <i class="far fa-copy"></i>
      </div>
    </div>
  </div>

  <div class="custom-url-result" *ngIf="embedCode$ | async as code">
    <span class="custom-url-result-label">{{'BUILD.Embed Code' | translate}}</span>
    <div class="copy-url-container" >
      <span>{{code}}</span>
      <div class="copy-button" (click)="copyContent(code, 'Embed Code successfully copied')">
        <i class="far fa-copy"></i>
      </div>
    </div>
  </div>
</div>
