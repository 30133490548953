<ng-container *ngIf="!landingPageView; else landingPageTemplate">
  <div class="text-tool-wrapper" *ngIf="(tms.availablePlaceholders$ | async) as placeholders" #toolRef>
    <quill-editor
      [placeholder]="readOnly ? '' : 'Insert text here ...' | translate"
      [formControl]="valueControl"
      [modules]="modules"
      theme="snow"
      [styles]="getStyles()"
      [readOnly]="readOnly"
      (onContentChanged)="onContentChanged($event)"
      (onSelectionChanged)="onSelectionChanged($event)"
    >
      <div quill-editor-toolbar>
        <div class="ql-formats">
          <button class="ql-bold" [title]="'Bold'"></button>
          <button class="ql-italic" [title]="'Italic'"></button>
          <button class="ql-underline" [title]="'Underline'"></button>
          <button class="ql-strike" [title]="'Strike'"></button>
          <button class="ql-script" value="super"></button>
          <button class="ql-script" value="sub"></button>
        </div>

        <i
          *ngIf="tms.templateAssignmentType === TemplateAssignmentType.Campaign"
          class="fas fa-info-circle"
          [matTooltip]="toolTipText | translate"
        ></i>

        <div class="ql-formats" (click)="isDropDownClick = true">
          <select class="ql-font">
            <option value="arial" selected>Arial</option>
            <option value="arial black">Arial Black</option>
            <option value="calibri">Calibri</option>
            <option value="century gothic">Century Gothic</option>
            <option value="comic sans ms">Comic Sans MS</option>
            <option value="courier">Courier</option>
            <option value="franklin gothic medium">Franklin Gothic Medium</option>
            <option value="geneva">Geneva</option>
            <option value="georgia">Georgia</option>
            <option value="helvetica">Helvetica</option>
            <option value="impact">Impact</option>
            <option value="lato">Lato</option>
            <option value="lucida">Lucida</option>
            <option value="lucida console">Lucida Console</option>
            <option value="lucida grande">Lucida Grande</option>
            <option value="lucida sans">Lucida Sans</option>
            <option value="lucida sans unicode">Lucida Sans Unicode</option>
            <option value="ms sans serif">MS Sans Serif</option>
            <option value="ms serif">MS Serif</option>
            <option value="monaco">Monaco</option>
            <option value="new york">New York</option>
            <option value="oswald">Oswald</option>
            <option value="palatino">Palatino</option>
            <option value="palatino linotype">Palatino Linotype</option>
            <option value="roboto">Roboto</option>
            <option value="tahoma">Tahoma</option>
            <option value="times">Times</option>
            <option value="times new roman">Times New Roman</option>
            <option value="trebuchet ms">Trebuchet MS</option>
            <option value="verdana">Verdana</option>
          </select>
        </div>

        <div class="ql-formats" (click)="isDropDownClick = true">
          <select class="ql-size">
            <option value="8px">8px</option>
            <option value="10px">10px</option>
            <option value="12px">12px</option>
            <option value="14px" [selected]>14px</option>
            <option value="16px">16px</option>
            <option value="18px">18px</option>
            <option value="20px">20px</option>
            <option value="22px">22px</option>
            <option value="24px">24px</option>
            <option value="28px">28px</option>
            <option value="36px">36px</option>
            <option value="48px">48px</option>
            <option value="64px">64px</option>
            <option value="72px">72px</option>
          </select>
        </div>

        <div class="ql-formats" (click)="isDropDownClick = true">

          <button class="ql-align" value=""></button>
          <button class="ql-align" value="center"></button>
          <button class="ql-align" value="right"></button>
          <button class="ql-align" value="justify"></button>

          <select class="ql-line-height">
            <option value="1.01">1.0</option>
            <option value="1.15">1.15</option>
            <option value="1.51">1.5</option>
            <option value="2.01">2.0</option>
            <option value="3.01">3.0</option>
            <option value="4.01">4.0</option>
            <option value="5.01">5.0</option>
          </select>
        </div>


        <div class="ql-formats">
          <button class="ql-list" value="bullet"></button>
          <button class="ql-list" value="ordered"></button>
        </div>

        <div class="ql-formats">
          <select class="ql-color"></select>
          <select class="ql-background"></select>
          <button class="ql-link" (click)="linkClickHandler()"></button>
          <button class="ql-clean"></button>
        </div>

        <ng-container *ngIf="!tms.isLetterBuilder">
          <div class="ql-formats" *ngIf="tms.templateAssignmentType === TemplateAssignmentType.Campaign && placeholders.length" (click)="isDropDownClick = true">
            <select class="ql-placeholder">
              <option value="10" *ngIf="placeholders.includes(10)">Donor First Name</option>
              <option value="1" *ngIf="placeholders.includes(1)">Donor Name</option>
              <option value="2" *ngIf="placeholders.includes(2)">Organization name</option>
              <option value="3" *ngIf="placeholders.includes(3)">Email</option>
              <option value="4" *ngIf="placeholders.includes(4)">Primary Phone</option>
              <option value="6" *ngIf="placeholders.includes(6)">Fax</option>
              <option value="7" *ngIf="placeholders.includes(7)">Address</option>
              <option value="8" *ngIf="placeholders.includes(8)">City</option>
              <option value="9" *ngIf="placeholders.includes(9)">State</option>
            </select>
          </div>
        </ng-container>

        <ng-container *ngIf="tms.isLetterBuilder">
          <div class="ql-formats" (click)="isDropDownClick = true">
            <select class="ql-placeholder">
              <option value="DonorFirstName">{{"Donor First Name" | translate}}</option>
              <option value="DonorLastName">{{"Donor Last Name" | translate}}</option>
              <option value="DonorName">{{"Donor Name" | translate}}</option>
              <option value="StreetAddress" >{{"Street Address" | translate}}</option>
              <option value="StreetAddress2" >{{"Street Address 2" | translate}}</option>
              <option value="City">{{"City" | translate}}</option>
              <option value="State">{{"State" | translate}}</option>
              <option value="ZipCode">{{"Zip Code" | translate}}</option>
              <option value="PrimaryContact">{{"Primary Contact" | translate}}</option>
              <option value="OrganizationName">{{"Organization Name" | translate}}</option>
              <option value="Employer">{{"Employer" | translate}}</option>
              <option value="LastDonationDate">{{"Last Donation Date" | translate}}</option>
              <option value="LastDonationAmount">{{"Last Donation Amount" | translate}}</option>
              <option value="GrossLifetimeDonationAmount">{{"BUILD.Gross/Lifetime Donation Amount" | translate}}</option>
              <option value="YTDDonationAmount">{{"BUILD.YTD Donation Amount" | translate}}</option>
              <option *ngIf="isLetterFooterTextTool" value="PageNumber">{{"Page Number" | translate}}</option>
            </select>
          </div>
        </ng-container>

      </div>
    </quill-editor>
  </div>
</ng-container>

<ng-template #landingPageTemplate>
  <div
    [ngStyle]="getStyles()"
    [innerHTML]="entity.content | safeHTML"
  ></div>
  <!-- <pre>{{entity.content | json}}</pre> -->
</ng-template>
