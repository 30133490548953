import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import FbTemplateModel, {
  FbElementModel,
  FbElementType,
} from '../../../../models/form-builder/fb.template.model';
import { BehaviorSubject, fromEvent, Subject, Subscription } from 'rxjs';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { FundAllocationModel } from '../../../../models/payments/fund.allocation.model';
import { ClientModel } from '../../../../models/client/client.model';
import EventModel from '../../../../models/event/event.model';
import { CampaignModel } from '../../../../models/campaigns/campaign.model';
import SocialPostModel from '../../../../models/socialMedia/social.post.model';
import { ClientPaymentModel } from '../../../../models/client/client.payment.model';
import { GiftType } from '../../../../models/enum/gift.type';
import { UtilsComponent } from '../../../utils.component';
import { PaymentBillingType } from '../../../../models/enum/payment.billing.type';
import { PaymentRepeatType } from '../../../../models/enum/payment.repeat.type';
import { filter, map, take, tap } from 'rxjs/operators';
import { DayOfWeek } from '../../../../models/enum/day-of-week';
import { ToastrService } from 'ngx-toastr';
import { DonationPaymentPageType } from '../../../../models/form-builder/donation.payment.page.type';
import { TypesOfDonationsEnum } from '../../../../models/enum/types-of-donations.enum';
import { TiersSettingModel } from '../../../../models/event/tiers.setting.model';
import {
  DEFAULT_DISCLAIMER_CHECKBOX_TEXT,
  DEFAULT_DISCLAIMER_CHECKBOX_TEXT_SP,
  DEFAULT_DISCLAIMER_TEXT,
  DEFAULT_DISCLAIMER_TEXT_SP,
  DONATION_FREQUENCY_OPTIONS, DONATION_RECURRING_OPTIONS, DONATION_RECURRING_OPTIONS_NO_PLEDGE
} from '../../../../constants';
import { FbInputComponent } from '../elements/fb-input/fb-input.component';
import { FbSelectComponent } from '../elements/fb-select/fb-select.component';
import { FormBuilderStateService } from '../../form-builder.state.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageType } from 'src/app/models/i18n/language.type';
import { DonationFrequency } from 'src/app/models/enum/donation-frequency.enum';
import { DonationRecurringOption } from 'src/app/models/enum/donation-recurring-option.enum';

/*Set Quill configuration in one place: TextToolComponent*/
export enum EmailPlaceHolder {
  NamedPerson = '[NamedPerson]',
  DonationAmount = '[DonationAmount]',
  RecipientName = '[RecipientName]',
  DonorName = '[DonorName]',
  OrganizationName = '[OrganizationName]',
  ClientPrimaryContactName = '[ClientPrimaryContactName]',
  InSupportOf = '[InSupportOf]',
}

@Component({
  selector: 'app-donation-info-page',
  templateUrl: './donation-info-page.component.html',
  styleUrls: ['../fb.page.scss', './donation-info-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DonationInfoPageComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  @Input() public paymentForm: FormGroup;
  @Input() public fbTemplate: FbTemplateModel;
  @Input() public donationInfoChanged$: Subject<void>;
  @Input() public distributeAmongFunds$: BehaviorSubject<boolean>;
  @Input() public allocatedFundsOptions: FormElementDataModel[] = [];
  @Input() public client: ClientModel;
  @Input() public entity:
    | EventModel
    | CampaignModel
    | SocialPostModel
    | ClientPaymentModel;
  @Input() public fundsChanged$: Subject<void>;
  @Input() public isPayPage: boolean = false;

  @Output() public donateEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Input() public libraryVersion: boolean;
  @Input() private fbss: FormBuilderStateService;

  @ViewChild('amountRef') private amountRef: ElementRef;
  @ViewChild('emailSubjectRef') private emailSubjectRef: ElementRef;
  @ViewChild('emailTextRef') private emailTextRef: ElementRef;
  @ViewChild('endDateRef') private endDateRef: ElementRef;
  @ViewChild('numberOfPeriodsRef') private numberOfPeriodsRef: ElementRef;
  @ViewChild('recipientEmailRef') private recipientEmailRef: ElementRef;
  @ViewChild('repeatRef') private repeatRef: ElementRef;
  @ViewChild('sendEmailRef') private sendEmailRef: ElementRef;
  @ViewChild('startDateRef') private startDateRef: ElementRef;
  @ViewChild('namedPersonRef') private namedPersonRef: ElementRef;
  @ViewChild('recipientNameRef') private recipientNameRef: ElementRef;
  @ViewChild('editorRef') private editorRef: any;
  @ViewChild('titleRef') private titleRef: ElementRef;
  @ViewChild('subtitleRef') private subtitleRef: ElementRef;

  @ViewChildren('everyRef') public everyRef: QueryList<FbInputComponent>;
  @ViewChildren('numberOfMonthRef')
  public numberOfMonthRef: QueryList<FbSelectComponent>;
  @ViewChildren('customNumberRef')
  public customNumberRef: QueryList<FbInputComponent>;
  public defaultCheckboxDisclaimerText: string =
    DEFAULT_DISCLAIMER_CHECKBOX_TEXT;
  public giftTypeOptions: FormElementDataModel[] = [
    { label: 'In Honor of', value: GiftType.InHonorOf },
    { label: 'In Memory of', value: GiftType.InMemoryOf },
    { label: 'In Support of', value: GiftType.InSupportOf },
  ];
  public billingTypeOptions: FormElementDataModel[] = [
    { label: 'Recurring payment', value: PaymentBillingType.Recurring },
    { label: 'Pledge', value: PaymentBillingType.Pledge },
  ];
  public billingTypeOptionsTiers: FormElementDataModel[] = [
    { label: 'Recurring payment', value: PaymentBillingType.Recurring },
  ];
  public paymentFrequency: FormElementDataModel[] = [
    { label: 'Weekly', value: PaymentRepeatType.Weekly },
    { label: 'Monthly', value: PaymentRepeatType.Monthly },
  ];
  public paymentFrequencyTiers: FormElementDataModel[] = [
    { label: 'Weekly', value: PaymentRepeatType.Weekly },
    { label: 'Monthly', value: PaymentRepeatType.Monthly },
  ];
  public dayOfWeek: FormElementDataModel[] = [
    { label: 'Sunday', value: DayOfWeek.Sunday },
    { label: 'Monday', value: DayOfWeek.Monday },
    { label: 'Tuesday', value: DayOfWeek.Tuesday },
    { label: 'Wednesday', value: DayOfWeek.Wednesday },
    { label: 'Thursday', value: DayOfWeek.Thursday },
    { label: 'Friday', value: DayOfWeek.Friday },
    { label: 'Saturday', value: DayOfWeek.Saturday },
  ];
  public recurringOptions: FormElementDataModel[] = DONATION_RECURRING_OPTIONS;
  public recurringOptionsNoPledge: FormElementDataModel[] = DONATION_RECURRING_OPTIONS_NO_PLEDGE;
  public donationRecurrenceOptions = DONATION_FREQUENCY_OPTIONS;

  public tiersMonth: FormElementDataModel[] = [
    { label: '3', value: 3 },
    { label: '6', value: 6 },
    { label: '9', value: 9 },
    { label: '12', value: 12 },
    { label: 'Own Value', value: 1 },
  ];
  public dayOfMonth: FormElementDataModel[] = UtilsComponent.dayOfMonth();
  public GiftType = GiftType;
  public PaymentRepeatType = PaymentRepeatType;
  public PaymentBillingType = PaymentBillingType;

  public mokFundsForm: FormGroup = this.formBuilder.group({
    fundId: [],
  });

  private standardTextVariable: string[] = [
    this.translate.instant('Amount to donate *'),
    this.translate.instant('Donation levels *'),
  ];

  private standardTearsImageUrl: string = '/assets/images/icon-donor-heart.png';

  private storedNamedPerson: string = EmailPlaceHolder.NamedPerson;
  private storedDonationAmount: string = EmailPlaceHolder.DonationAmount;
  private recipientNameHolder: string = EmailPlaceHolder.RecipientName;

  private emailTextInMemory = `<div>Dear <i>${EmailPlaceHolder.RecipientName}</i></div><div><br></div><div>In fond memory of <i>${EmailPlaceHolder.NamedPerson}</i>, <i>${EmailPlaceHolder.DonorName}</i> has made a generous donation in the amount of $ <i>${EmailPlaceHolder.DonationAmount}</i> to <i>${EmailPlaceHolder.OrganizationName}</i>. This donation will make a great difference to us and those in the communities we serve.</div><div><br></div><div>We appreciate the continued support!</div><div><br></div><div>Thank you,</div><div><br></div><div><i>${EmailPlaceHolder.ClientPrimaryContactName}</i></div><div><i>${EmailPlaceHolder.OrganizationName}</i></div>`;
  private emailTextInHonor = `<div>Dear <i>${EmailPlaceHolder.RecipientName}</i>,</div><div><br></div><div><i>${EmailPlaceHolder.DonorName}</i> has made a generous donation in the amount of $ <i>${EmailPlaceHolder.DonationAmount}</i> in your honor! This donation will make a great difference as we continue towards our cause.</div><div><br></div><div>We appreciate your support and believe together we will achieve our mission!</div><div><br></div><div>Thank you,</div><div><br></div><div><i>${EmailPlaceHolder.ClientPrimaryContactName}</i></div><div><i>${EmailPlaceHolder.OrganizationName}</i></div>`;
  private emailSupportOf = `<div>Dear <i>${EmailPlaceHolder.RecipientName}</i>,</div><div><br></div><div><i>${EmailPlaceHolder.DonorName}</i> has made a generous donation in the amount of $ <i>${EmailPlaceHolder.DonationAmount}</i> in Support of ${EmailPlaceHolder.InSupportOf}. This donation will make a great difference as we continue towards our cause.</div><div><br></div><div>We appreciate the continued support!</div><div><br></div><div>Thank you,</div><div><br></div><div><i>${EmailPlaceHolder.ClientPrimaryContactName}</i></div><div><i>${EmailPlaceHolder.OrganizationName}</i></div>`;
  private initialDistributeAmongFundsValue: boolean;
  public titleForm: FormGroup;
  private editSubscription: Subscription = new Subscription();
  public editor: any;
  public titleContainer: HTMLElement;
  public subtitleContainer: HTMLElement;

  public modules: /*QuillModules*/ any = {
    clipboard: {
      allowed: {
        tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span',],
        attributes: ['href', 'rel', 'target'/*, 'class'*/],
      },
      keepSelection: false,
    },
  };

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.initMissingValues();
    this.giftType.disable();
    this.recipientEmail.disable();
    this.emailSubject.disable();
    this.emailText.disable();
    this.sendEmail.disable();
    this.reccurrencyPaymentType.disable();
    this.startDate.disable();
    this.endDate.disable();
    this.reccurrency.disable();
    // this.donationFrequency.disable();
    this.reccurrencyValue.disable();
    this.everyValue.disable();
    this.numberOfPeriods.disable();
    this.amountPerTransaction.disable();
    this.recipientName.disable();
    this.namedPerson.disable();

    const auxiliaAppLanguage =
      (localStorage.getItem('auxilia-app-language') as LanguageType) ||
      LanguageType.English;
    this.translate.use(auxiliaAppLanguage);
    localStorage.setItem('auxilia-app-language', auxiliaAppLanguage);
    this.subscriptions.add(
      this.distributeAmongFunds$
        .pipe(
          tap((value) => {
            if (!value || !this.isDonationStandard) {
              this.fundAllocation.disable();
            } else {
              this.fundAllocation.enable();
              if (!this.fundAllocation.length) {
                this.fundAllocation.push(this.fundAllocationFormGroup);
              }
            }
          }),
          filter((value) => value !== null),
          take(1),
          tap(
            (value: boolean) => (this.initialDistributeAmongFundsValue = value),
          ),
        )
        .subscribe(),
    );
    this.subscriptions.add(
      this.giftType.valueChanges.subscribe((value) => {
        this.initializeEmailFields(this.giftType.value);
      }),
    );

    this.subscriptions.add(
      this.fbss.isPledgeType
        .pipe(
          tap((value) => {
            if (!value) {
              this.reccurrencyPaymentType.setValue(
                PaymentBillingType.Recurring,
              );
            }
          }),
        )
        .subscribe(),
    );

    this.subscriptions.add(
      this.amount.valueChanges.subscribe((value) => {
        const numberOfPeriods = this.numberOfPeriods.value;
        if (numberOfPeriods && value) {
          this.amountPerTransaction.setValue(
            UtilsComponent.truncateNumber(value / numberOfPeriods),
          );
        } else {
          this.amountPerTransaction.setValue('');
        }
        this.resetEmail();
      }),
    );

    this.subscriptions.add(
      this.numberOfPeriods.valueChanges.subscribe((value) => {
        const amount = this.amount.value;
        if (amount && value) {
          this.amountPerTransaction.setValue(
            UtilsComponent.truncateNumber(amount / value),
          );
        } else {
          this.amountPerTransaction.setValue('');
        }
      }),
    );

    this.subscriptions.add(
      this.fundAllocation.valueChanges.subscribe(
        (value: FundAllocationModel[]) => {
          if (!this.distributeAmongFunds$.getValue()) {
            return;
          }
          let totalAmount = 0;
          value.forEach(
            ({ amount }: FundAllocationModel) => (totalAmount += +amount),
          );
          this.amount.setValue(totalAmount || '');
        },
      ),
    );
    this.tiers.controls.forEach((tiers) => {
      tiers.get('reccurrencyValue').disable();
      tiers.get('everyValue').disable();
      tiers.get('startDate').disable();
      tiers.get('numberOfMonth').disable();
      tiers.get('customNumber').disable();
      tiers.get('amountPerTransaction').disable();
      if (
        tiers.get('reccurrencyPaymentType').value ===
        PaymentBillingType.Recurring
      ) {
        tiers.get('customNumber').clearValidators();
        tiers.get('customNumber').setValidators([Validators.max(60)]);
      }
      if (this.isDonationStandard) {
        tiers.get('ammount').disable();
      } else {
        tiers
          .get('ammount')
          .setValidators([Validators.required, Validators.min(5)]);
      }
    });

    if (!this.isDonationStandard) {
      this.fundAllocation.disable();
    }

    this.titleForm = new FormGroup({
      titleControl: new FormControl(''),
    });

    this.subscriptions.add(
      this.fbss.inSupportOfSubject$.subscribe(() => {
        if (this.getGiftTypeOptions) {
          this.paymentForm.patchValue({
            giftType: this.getGiftTypeOptions[0]?.value,
          });
        }
      }),
    );

    this.subscriptions.add(
      this.donationInfoChanged$.subscribe(() => {
        this.detectDonationInfoChanges();
      })
    );
    this.detectDonationInfoChanges();

    this.subscriptions.add(
      this.fbss.tiersChanged$.subscribe(() => {
        this.detectTiersInfoChanges();
      })
    );
    this.detectTiersInfoChanges()
  }

  private initMissingValues(): void {
    if (!this.infoPage.typesOfDonations) {
      this.infoPage.typesOfDonations = TypesOfDonationsEnum.Standard;
    }
    if (!this.infoPage.amountToDonateText) {
      this.infoPage.amountToDonateText = this.isDonationStandard
        ? this.standardTextVariable[0]
        : this.standardTextVariable[1];
    }
    if (!this.infoPage.tiersOptions) {
      this.infoPage.tiersOptions =
        '[{"name":"","description":"","ammount":"","imgUrl":"","isRecurring":true,"allowWeekly":true,"allowMonthly":true,"allowYearly":true,"isEndDate":true,"allowOwnAmount":true,"isPledgeType":true,"selectedTag":"","isCreateTagShow":false,"isTier":true}]';
    }
    if (!this.infoPage.funds) {
      this.infoPage.funds = '[]';
    }

    if (typeof this.infoPage.allowWeekly === 'undefined') {
      this.infoPage.allowWeekly = true;
    }

    if (typeof this.infoPage.allowMonthly === 'undefined') {
      this.infoPage.allowMonthly = true;
    }

    if (typeof this.infoPage.allowYearly === 'undefined') {
      this.infoPage.allowYearly = true;
    }
  }
  public getDisclaimerText(disclaimer: FormControl | AbstractControl): string {
    let text: string = disclaimer.get('content').value;
    if (text.includes(DEFAULT_DISCLAIMER_TEXT)) {
      const translated: string = this.translate.instant(
        DEFAULT_DISCLAIMER_TEXT,
      );
      text = text.replace(DEFAULT_DISCLAIMER_TEXT, translated);
    }
    if (text.includes(DEFAULT_DISCLAIMER_TEXT_SP)) {
      const translated: string = this.translate.instant(
        DEFAULT_DISCLAIMER_TEXT_SP,
      );
      text = text.replace(DEFAULT_DISCLAIMER_TEXT_SP, translated);
    }
    return text;
  }

  private initializeEmailFields(type): void {
    switch (type) {
      case GiftType.InHonorOf:
        this.emailSubject.setValue('Donation Made in Your Honor');
        this.emailText.setValue(
          this.replacePlaceholders(this.emailTextInHonor),
        );
        break;
      case GiftType.InMemoryOf:
        this.emailSubject.setValue(
          `In Memory of ${EmailPlaceHolder.NamedPerson}`,
        );
        this.emailText.setValue(
          this.replacePlaceholders(this.emailTextInMemory),
        );
        break;
      case GiftType.InSupportOf:
        this.emailSubject.setValue(
          `In Support of ${EmailPlaceHolder.NamedPerson}`,
        );
        this.emailText.setValue(this.replacePlaceholders(this.emailSupportOf));
        break;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.editSubscription.unsubscribe();
  }

  public get isDonationStandard(): boolean {
    return this.infoPage.typesOfDonations === TypesOfDonationsEnum.Standard;
  }

  public get templateFrequencyOptions(): FormElementDataModel[] {
    const pageInfo = this.infoPage;
    if (pageInfo === null) {
      return this.donationRecurrenceOptions;
    }

    const frequencyOptions = [];
    const weeklyOption = this.donationRecurrenceOptions[0];
    const monthlyOption = this.donationRecurrenceOptions[1];
    const yearlyOption = this.donationRecurrenceOptions[2];

    if (typeof pageInfo.allowWeekly === 'undefined') {
      frequencyOptions.push(weeklyOption);
    } else {
      if (pageInfo.allowWeekly) {
        frequencyOptions.push(weeklyOption);
      }
    }

    if (typeof pageInfo.allowMonthly === 'undefined') {
      frequencyOptions.push(monthlyOption);
    } else {
      if (pageInfo.allowMonthly) {
        frequencyOptions.push(monthlyOption);
      }
    }

    if (typeof pageInfo.allowYearly === 'undefined') {
      frequencyOptions.push(yearlyOption);
    } else {
      if (pageInfo.allowYearly) {
        frequencyOptions.push(yearlyOption);
      }
    }

    // If only one frequency allowed, set it as default
    if (frequencyOptions.length === 1) {
      const frequency = frequencyOptions[0].value;
      if (frequency === DonationFrequency.Weekly) {
        this.reccurrency.setValue(PaymentRepeatType.Weekly);
      }
      if (frequency === DonationFrequency.Monthly) {
        this.reccurrency.setValue(PaymentRepeatType.Monthly);
      }
      if (frequency === DonationFrequency.Yearly) {
        this.reccurrency.setValue(PaymentRepeatType.Yearly);
      }

      this.donationFrequency.setValue(frequency);
    }

    return frequencyOptions;
  }

  public get templateTierFrequencyOptions(): FormElementDataModel[] {
    if (this.getTier && this.getTier.length) {
      const frequencyOptions = [];

      const weeklyOption = this.donationRecurrenceOptions[0];
      const monthlyOption = this.donationRecurrenceOptions[1];
      const yearlyOption = this.donationRecurrenceOptions[2];
      // just get the first tier setting to get the frequency options
      const tier = this.getTier[0];

      if (typeof tier.allowWeekly === 'undefined') {
        frequencyOptions.push(weeklyOption);
      } else {
        if (tier.allowWeekly) {
          frequencyOptions.push(weeklyOption);
        }
      }

      if (typeof tier.allowMonthly === 'undefined') {
        frequencyOptions.push(monthlyOption);
      } else {
        if (tier.allowMonthly) {
          frequencyOptions.push(monthlyOption);
        }
      }

      if (typeof tier.allowYearly === 'undefined') {
        frequencyOptions.push(yearlyOption);
      } else {
        if (tier.allowYearly) {
          frequencyOptions.push(yearlyOption);
        }
      }

      // If only one frequency allowed, set it as default
      if (frequencyOptions.length === 1) {
        const frequency = frequencyOptions[0].value;
        this.setTierFrequencySingleValue(frequency);
      }

      return frequencyOptions;
    }

    return this.donationRecurrenceOptions;
  }

  private setTierFrequencySingleValue(frequency: DonationFrequency) {
    // set single recurrency in tier form
    for (const tierControl of this.getTierForm.controls) {
      const tierRecurrencyControl = tierControl.get('reccurrency');
      const donationFrequencyControl = tierControl.get('donationFrequency');
      donationFrequencyControl.setValue(frequency);
      if (frequency === DonationFrequency.Weekly) {
        tierRecurrencyControl.setValue(PaymentRepeatType.Weekly);
      }
      if (frequency === DonationFrequency.Monthly) {
        tierRecurrencyControl.setValue(PaymentRepeatType.Monthly);
      }
      if (frequency === DonationFrequency.Yearly) {
        tierRecurrencyControl.setValue(PaymentRepeatType.Yearly);
      }
    }

    // set single recurrency in custom tier form
    const tierRecurrency = this.customDonationTiersForm.get('reccurrency');
    const tierDonationFrequency = this.customDonationTiersForm.get('donationFrequency');
    tierDonationFrequency.setValue(frequency);
    if (frequency === DonationFrequency.Weekly) {
      tierRecurrency.setValue(PaymentRepeatType.Weekly);
    }
    if (frequency === DonationFrequency.Monthly) {
      tierRecurrency.setValue(PaymentRepeatType.Monthly);
    }
    if (frequency === DonationFrequency.Yearly) {
      tierRecurrency.setValue(PaymentRepeatType.Yearly);
    }
  }

  public getTierImg(i: number): string {
    if (this.getTier && this.getTier[i] && this.getTier[i].imgUrl) {
      return `center / cover no-repeat url(${this.getTier[i].imgUrl})`;
    }
    return `center / cover no-repeat url(${this.standardTearsImageUrl})`;
  }

  // public setMonthValue(i: number): void {
  //   const numberOfMonth = this.getTierForm.controls[i].get('numberOfMonth');
  //   const custom = this.getTierForm.controls[i].get('customNumber');
  //   const endDate = this.getTierForm.controls[i].get('endDate');
  //   if (numberOfMonth.value !== 1) {
  //     custom.setValue(numberOfMonth.value);
  //     this.setPeriodValue(i);
  //     return;
  //   }
  //   custom.setValue(null);
  //   endDate.setValue(null);
  // }

  private setPeriodAmountValue(control: AbstractControl | FormGroup): void {
    const custom = control.get('customNumber');
    const paymentType = control.get('reccurrencyPaymentType');
    if (paymentType.value === PaymentBillingType.Pledge) {
      const amount = control.get('ammount');
      const amountPerTransaction = control.get('amountPerTransaction');
      const value = amount.value / custom.value;
      if (!isNaN(value)) amountPerTransaction.setValue(value.toFixed(2));
    }
  }

  setPeriodValidators(control: AbstractControl | FormGroup) {
    const custom = control.get('customNumber');
    const paymentType = control.get('reccurrencyPaymentType').value;
    custom.clearValidators();
    if (paymentType === PaymentBillingType.Recurring) {
      custom.setValidators([Validators.max(60)]);
    } else if (paymentType === PaymentBillingType.Pledge) {
      custom.setValidators([
        Validators.required,
        Validators.min(1),
        Validators.max(60),
      ]);
    }
  }

  public selectTier(tier: AbstractControl): void {
    // if isInclude is true, remove validator from frequencyDropdown as is about to be flipped to false
    if (tier.get('isInclude').value) {
      tier.get('donationFrequency').clearValidators();
      tier.get('donationFrequency').updateValueAndValidity();
      tier.get('tierDonationRecurringOption').setValue(DonationRecurringOption.Once)
      tier.get('tierDonationRecurringOption').updateValueAndValidity();
      tier.get('recurring').setValue(false);
    }
    tier.get('isInclude').setValue(!tier.get('isInclude').value);
    if (this.amount.value && tier.get('isInclude').value && tier.get('ammount')) {
      this.amount.setValue(+this.amount.value + +tier.get('ammount').value);
      return;
    }
    if (this.amount.value && !tier.get('isInclude').value && tier.get('ammount')) {
      this.amount.setValue(+this.amount.value - +tier.get('ammount').value);
      return;
    }
    if (!this.amount.value && tier.get('isInclude').value && tier.get('ammount')) {
      this.amount.setValue(+tier.get('ammount').value);
    }
  }

  public setPeriod(i: number, isCustom: boolean) {
    let control: AbstractControl | FormGroup;
    if (!isCustom) control = this.getTierForm.controls[i];
    else control = this.customDonationTiersForm;

    this.setPeriodAmountValue(control);
  }

  public get isOwnAmountTiers() {
    return this.getTier[0].allowOwnAmount;
  }

  public get getTier(): TiersSettingModel[] {
    if (this.infoPage.tiersOptions) {
      return JSON.parse(this.infoPage.tiersOptions);
    }
  }

  public tirePrice(i: number): string {
    return Number(this.getTierForm.controls[i].get('ammount').value).toFixed(2);
  }

  public get pageStyle(): { [key: string]: string } {
    if (!this.fbTemplate) {
      return {};
    }
    const {
      width = 760,
      formColor = '#fff',
      fontColor = '#2C3345',
      fontFamily = 'Arial',
    }: FbTemplateModel = this.fbTemplate;
    return {
      background: formColor,
      color: fontColor,
      'font-family': fontFamily,
    };
  }

  public get isCustom(): boolean {
    return (
      this.entity.donationPaymentPage === DonationPaymentPageType.Custom ||
      this.entity.donationPaymentPage === DonationPaymentPageType.ClientDefault
    );
  }

  public get isDefault(): boolean {
    return (
      this.entity.donationPaymentPage === DonationPaymentPageType.AuxiliaDefault
    );
  }

  public get fontSizeStyle(): { [key: string]: string } {
    if (!this.fbTemplate) {
      return {};
    }
    const { fontSize = 16 }: FbTemplateModel = this.fbTemplate;
    return {
      'font-size': `${fontSize}px`,
    };
  }

  public get rowStyle(): { [key: string]: string } {
    if (!this.fbTemplate) {
      return {};
    }
    const { spacing = 10 }: FbTemplateModel = this.fbTemplate;
    return {
      'margin-bottom': `${spacing}px`,
    };
  }

  public get customDonationTiersForm(): FormGroup {
    return this.paymentForm.get('customDonationTiers') as FormGroup;
  }

  public get getTierForm(): FormArray {
    return this.paymentForm.get('tiers') as FormArray;
  }

  public get amount(): FormControl {
    return this.paymentForm.get('ammount') as FormControl;
  }

  public get fundAllocation(): FormArray {
    return this.paymentForm.get('fundAllocation') as FormArray;
  }

  public get isAnonymous(): FormControl {
    return this.paymentForm.get('isAnonymous') as FormControl;
  }

  public get recurring(): FormControl {
    return this.paymentForm.get('recurring') as FormControl;
  }

  public get pledgeDonation(): FormControl {
    return this.paymentForm.get('pledgeDonation') as FormControl;
  }

  public get reccurrencyPaymentType(): FormControl {
    return this.paymentForm.get('reccurrencyPaymentType') as FormControl;
  }

  public get donationRecurringOption(): FormControl {
    return this.paymentForm.get('donationRecurringOption') as FormControl;
  }

  public get tierDonationRecurringOption(): FormControl {
    return this.customDonationTiersForm.get('tierDonationRecurringOption') as FormControl;
  }
  public get startDate(): FormControl {
    return this.paymentForm.get('startDate') as FormControl;
  }

  public get endDate(): FormControl {
    return this.paymentForm.get('endDate') as FormControl;
  }

  public get reccurrency(): FormControl {
    return this.paymentForm.get('reccurrency') as FormControl;
  }

  public get reccurrencyValue(): FormControl {
    return this.paymentForm.get('reccurrencyValue') as FormControl;
  }

  public get donationFrequency(): FormControl {
    return this.paymentForm.get('donationFrequency') as FormControl;
  }

  public get everyValue(): FormControl {
    return this.paymentForm.get('everyValue') as FormControl;
  }

  public get numberOfPeriods(): FormControl {
    return this.paymentForm.get('numberOfPeriods') as FormControl;
  }

  public get appeal(): FormControl {
    return this.paymentForm.get('appeal') as FormControl;
  }

  public get giftType(): FormControl {
    return this.paymentForm.get('giftType') as FormControl;
  }

  public get recipientEmail(): FormControl {
    return this.paymentForm.get('recipientEmail') as FormControl;
  }

  public get recipientName(): FormControl {
    return this.paymentForm.get('recipientName') as FormControl;
  }

  public get namedPerson(): FormControl {
    return this.paymentForm.get('namedPerson') as FormControl;
  }

  public get emailSubject(): FormControl {
    return this.paymentForm.get('emailSubject') as FormControl;
  }

  public get emailText(): FormControl {
    return this.paymentForm.get('emailText') as FormControl;
  }

  public get sendEmail(): FormControl {
    return this.paymentForm.get('sendEmail') as FormControl;
  }
  public get disclaimers(): FormArray {
    return this.paymentForm.get('customDisclaimers') as FormArray;
  }

  public get tiers(): FormArray {
    return this.paymentForm.get('tiers') as FormArray;
  }

  public get isEmailSend(): FormControl {
    return this.paymentForm.get('isEmailSend') as FormControl;
  }

  public get agreedTermsConditions(): FormControl {
    return this.paymentForm.get('agreedTermsConditions') as FormControl;
  }

  public get amountPerTransaction(): FormControl {
    return this.paymentForm.get('amountPerTransaction') as FormControl;
  }

  public get isHonorGiftVisible(): boolean {
    if (
      this.entity &&
      this.entity.donationPaymentPage &&
      this.entity.donationPaymentPage ===
      DonationPaymentPageType.AuxiliaDefault &&
      this.client
    ) {
      return this.entity.hasAppeal;
    }
    return this.client && this.infoPage?.isHonorGift;
  }

  public get isHonorGiftVisible2(): boolean {
    if (
      this.entity &&
      this.entity.donationPaymentPage &&
      this.entity.donationPaymentPage ===
      DonationPaymentPageType.AuxiliaDefault &&
      this.appeal.value &&
      this.client
    ) {
      return this.entity.hasAppeal;
    } else if (this.appeal.value && this.client && this.infoPage?.isHonorGift) {
      return true;
    } else return this.appeal.value && !this.libraryVersion;
  }

  public get checkBoxDisclaimerText(): string {
    if (
      !this.infoPage.disclaimerCheckBoxText &&
      this.infoPage.disclaimerCheckBoxText !== ''
    )
      return this.translate.instant(this.defaultCheckboxDisclaimerText);
    else {
      let text: string = this.infoPage.disclaimerCheckBoxText;
      if (text.includes(DEFAULT_DISCLAIMER_CHECKBOX_TEXT)) {
        const translated: string = this.translate.instant(
          DEFAULT_DISCLAIMER_CHECKBOX_TEXT,
        );
        text = text.replace(DEFAULT_DISCLAIMER_CHECKBOX_TEXT, translated);
      }
      if (text.includes(DEFAULT_DISCLAIMER_CHECKBOX_TEXT_SP)) {
        const translated: string = this.translate.instant(
          DEFAULT_DISCLAIMER_CHECKBOX_TEXT_SP,
        );
        text = text.replace(DEFAULT_DISCLAIMER_CHECKBOX_TEXT_SP, translated);
      }
      return text;
    }
  }

  public get isHonorGiftVisible3(): boolean {
    if (
      this.entity &&
      this.entity.donationPaymentPage &&
      this.entity.donationPaymentPage ===
      DonationPaymentPageType.AuxiliaDefault &&
      this.isEmailSend.value &&
      this.client
    ) {
      return this.entity.hasAppeal;
    } else if (
      this.isEmailSend.value &&
      this.client &&
      this.infoPage?.isHonorGiftWithSendEmail &&
      this.infoPage?.isHonorGift &&
      this.giftType.value
    ) {
      return true;
    } else if (this.isShowInPaymentPage) {
      return true;
    }
    return false;
  }

  public getUniqueFundOptions(
    fundGroup: AbstractControl,
    array: FormElementDataModel[],
  ): FormElementDataModel[] {
    const fundIdControl = fundGroup.get('fundId') as FormControl;
    const nameControl = fundGroup.get('name') as FormControl;
    const selected: string[] = (
      this.fundAllocation.value as FundAllocationModel[]
    )
      .filter(({ fundId }) => !!fundId)
      .map(({ fundId }) => fundId);
    const filterArray = array.filter(
      ({ value }: FormElementDataModel) =>
        !selected.includes(value) || fundIdControl.value === value,
    );
    if (filterArray.length && filterArray.length === 1) {
      fundIdControl.setValue(filterArray[0].value);
      nameControl.setValue(filterArray[0].label);
    }
    return array.filter(
      ({ value }: FormElementDataModel) =>
        !selected.includes(value) || fundIdControl.value === value,
    );
  }

  public onFundSelected(fundGroup: AbstractControl): void {
    const fundId = fundGroup.get('fundId').value;
    const nameControl = fundGroup.get('name');
    if (!fundId) {
      nameControl.reset();
    } else {
      let fundName;
      fundName = this.allocatedFundsOptions.find(
        ({ value }) => value === fundId,
      ).label;
      nameControl.setValue(fundName);
    }
  }

  public removeAllocatedFund(index: number): void {
    this.fundAllocation.removeAt(index);
  }

  public addAllocatedFund(): void {
    this.fundAllocation.push(this.fundAllocationFormGroup);
  }

  private get fundAllocationFormGroup(): FormGroup {
    return this.formBuilder.group({
      name: [''],
      fundId: ['', [Validators.required]],
      amount: ['', [Validators.required, Validators.min(5)]],
    });
  }

  public recurringUpdate(selectedRecurringType: DonationRecurringOption): void {
    this.donationRecurringOption.setValue(selectedRecurringType);
    const isDonatingOnce =
      selectedRecurringType === DonationRecurringOption.Once;
    const isRecurringDonation =
      selectedRecurringType === DonationRecurringOption.Recurring;
    const isPledgeDonation =
      selectedRecurringType === DonationRecurringOption.Pledge;

    if (isDonatingOnce) {
      this.recurring.setValue(false);
      this.donationFrequency.disable();
      this.numberOfPeriods.disable();
      this.reccurrencyPaymentType.enable();
      this.startDate.enable();
      this.endDate.enable();
      this.reccurrency.enable();
      this.everyValue.enable();
      this.fundAllocation.enable();
      this.distributeAmongFunds$.next(this.initialDistributeAmongFundsValue);

      this.donationFrequency.clearValidators();
      this.donationFrequency.updateValueAndValidity();
    }

    if (isRecurringDonation) {
      this.reccurrencyPaymentType.setValue(PaymentBillingType.Recurring);

      this.endDate.enable();
      this.fundAllocation.enable();
      this.numberOfPeriods.setValidators([Validators.min(1)]);
      this.numberOfPeriods.updateValueAndValidity();
      this.distributeAmongFunds$.next(this.initialDistributeAmongFundsValue);

      // if weekly or yearly are allowed, set validators
      if (this.infoPage.allowWeekly && this.infoPage.allowYearly) {
        this.donationFrequency.setValidators([Validators.required]);
        this.donationFrequency.updateValueAndValidity();
        this.donationFrequency.markAsUntouched();
      }
    }
    if (isPledgeDonation) {
      this.reccurrencyPaymentType.setValue(PaymentBillingType.Pledge);

      this.fundAllocation.disable();
      this.endDate.disable();
      this.numberOfPeriods.setValidators([
        Validators.required,
        Validators.min(1),
      ]);
      this.numberOfPeriods.updateValueAndValidity();
      this.numberOfPeriods.markAsUntouched();
      this.distributeAmongFunds$.next(false);
    }

    if (isRecurringDonation || isPledgeDonation) {
      this.recurring.setValue(true);
      this.donationFrequency.enable();
      this.numberOfPeriods.enable();
      this.reccurrencyPaymentType.disable();
      this.startDate.disable();
      this.endDate.disable();
      this.reccurrency.disable();
      this.everyValue.disable();
    }
  }

  public recurringTiersUpdate(control: AbstractControl | FormGroup, selectedRecurringType: DonationRecurringOption): void {
    const isDonatingOnce = selectedRecurringType === DonationRecurringOption.Once;
    const isRecurringDonation = selectedRecurringType === DonationRecurringOption.Recurring;
    const isPledgeDonation = selectedRecurringType === DonationRecurringOption.Pledge;

    const tierDonationRecurring = control.get('tierDonationRecurringOption');
    const currentRecurring = control.get('recurring');
    const paymentType = control.get('reccurrencyPaymentType');

    tierDonationRecurring.setValue(selectedRecurringType);

    if (isDonatingOnce) {
      currentRecurring.setValue(false);
      paymentType.setValue(null);
      control.get('reccurrencyValue').disable();
      control.get('everyValue').disable();
      control.get('startDate').disable();
      control.get('numberOfMonth').disable();
      control.get('customNumber').disable();
      control.get("donationFrequency").clearValidators();
      control.get("donationFrequency").updateValueAndValidity();
    }

    if (isRecurringDonation) {
      const tierData = JSON.parse(this.infoPage.tiersOptions);
      const tier = tierData[0];
      paymentType.setValue(PaymentBillingType.Recurring);
      if (tier.allowWeekly && tier.allowYearly) {
        control.get("donationFrequency").setValidators([Validators.required]);
        control.get("donationFrequency").updateValueAndValidity();
        control.get("donationFrequency").markAsUntouched();
      }
    }

    if (isPledgeDonation) {
      paymentType.setValue(PaymentBillingType.Pledge);
    }

    if (isRecurringDonation || isPledgeDonation) {
      currentRecurring.setValue(true);
      control.get('reccurrencyValue').enable();
      control.get('everyValue').enable();
      control.get('startDate').enable();
      control.get('numberOfMonth').enable();
      control.get('customNumber').enable();
    }
  }

  public updateAppeal(): void {
    this.fbss.inSupportOfSubject$.next();
    if (this.appeal.value) {
      this.giftType.disable();
      this.recipientEmail.disable();
      this.emailSubject.disable();
      this.emailText.disable();
      this.sendEmail.disable();
      this.recipientName.disable();
      this.namedPerson.disable();
      this.isEmailSend.setValue(false);
      this.isEmailSend.disable();
    } else {
      this.giftType.enable();
      this.namedPerson.enable();
      this.namedPerson.markAsUntouched();
    }
    this.appeal.setValue(!this.appeal.value);
  }

  public get getGiftLabel(): string {
    if (this.giftType) {
      switch (this.giftType.value) {
        case GiftType.InHonorOf:
          return 'Honoree Name *';
        case GiftType.InMemoryOf:
          return 'In Memory of *';
        case GiftType.InSupportOf:
          return 'In Support of *';
      }
    }
  }

  public get getPledgePeriodsLabel(): string {
    if (this.donationFrequency) {
      switch (this.donationFrequency.value) {
        case DonationFrequency.Weekly:
          return 'Number of weeks *';
        case DonationFrequency.BiWeekly:
          return 'Number of 2-week periods *';
        case DonationFrequency.Monthly:
          return 'Number of months *';
        case DonationFrequency.Quarterly:
          return 'Number of quarters *';
        case DonationFrequency.Yearly:
          return 'Number of years *';
        default:
          return 'Number of periods *';
      }
    }
  }

  public get getEmailLabel(): string {
    if (this.giftType) {
      switch (this.giftType.value) {
        case GiftType.InHonorOf:
          return 'Honoree Email *';
        case GiftType.InMemoryOf:
          return 'Recipient Email *';
        case GiftType.InSupportOf:
          return 'Recipient Email *';
      }
    }
  }

  public updateIsSendValue(): void {
    if (this.isEmailSend.value) {
      this.recipientEmail.disable();
      this.emailSubject.disable();
      this.emailText.disable();
      this.sendEmail.disable();
      this.recipientName.disable();
    } else {
      this.giftType.enable();
      this.recipientEmail.enable();
      this.emailSubject.enable();
      this.emailText.enable();
      this.sendEmail.enable();
      this.giftType.value !== GiftType.InHonorOf && this.recipientName.enable();
      this.namedPerson.enable();
      this.initializeEmailFields(this.giftType.value);
      this.onNamedPersonChanged();
    }
    if (this.namedPerson.value) {
      this.isEmailSend.setValue(!this.isEmailSend.value);
      if (!this.isEmailSend.value) {
        this.resetEmail();
        this.recipientName.setValue('');
        this.recipientName.markAsPristine();
        this.recipientName.markAsUntouched();
      }
    } else {
      this.namedPerson.markAsTouched();
    }
  }

  public onGiftTypeChanged(): void {
    if (!this.isEmailSend.value) {
      return;
    }
    if (this.giftType.value === GiftType.InHonorOf) {
      this.recipientName.disable();
    } else {
      this.recipientName.enable();
    }
    this.onNamedPersonChanged();
  }

  public get tooltipMessage(): string {
    if (!this.giftType) {
      return '';
    } else if (this.giftType.value === GiftType.InHonorOf) {
      return 'If this is not selected, Honoree will not be notified';
    } else {
      return 'If this is not selected, Recipient will not be notified';
    }
  }

  public get notify(): string {
    if (!this.giftType || this.giftType.value === GiftType.InSupportOf) {
      return 'Notify Recipient?';
    } else if (this.giftType.value === GiftType.InHonorOf) {
      return 'Notify Honoree?';
    } else {
      return 'Notify Recipient?';
    }
  }

  public onNamedPersonChanged(): void {
    const namedPerson = this.namedPerson.value;
    if (this.giftType.value !== GiftType.InHonorOf) {
      if (this.emailSubject.value.includes(EmailPlaceHolder.NamedPerson)) {
        this.emailSubject.setValue(
          this.emailSubject.value.replace(
            EmailPlaceHolder.NamedPerson,
            namedPerson,
          ),
        );
        if (this.emailText.value.includes(EmailPlaceHolder.InSupportOf)) {
          this.emailText.setValue(
            this.emailText.value.replace(
              EmailPlaceHolder.InSupportOf,
              namedPerson,
            ),
          );
          return;
        }
        this.emailText.setValue(
          this.emailText.value.replace(
            EmailPlaceHolder.NamedPerson,
            namedPerson,
          ),
        );
      }
      if (this.giftType.value === GiftType.InMemoryOf) {
        if (this.emailSubject.value.includes(EmailPlaceHolder.NamedPerson)) {
          this.emailSubject.setValue(
            this.emailSubject.value.replace(
              EmailPlaceHolder.NamedPerson,
              namedPerson,
            ),
          );
          this.emailText.setValue(
            this.emailText.value.replace(
              EmailPlaceHolder.NamedPerson,
              namedPerson,
            ),
          );
        }
      }
    } else {
      if (
        this.emailText.value.includes(EmailPlaceHolder.RecipientName) &&
        namedPerson
      ) {
        this.emailText.setValue(
          this.emailText.value.replace(
            EmailPlaceHolder.RecipientName,
            namedPerson,
          ),
        );
      }
      this.emailText.setValue(
        this.emailText.value.replace(
          this.storedNamedPerson,
          namedPerson ? namedPerson : EmailPlaceHolder.RecipientName,
        ),
      );
    }
    this.storedNamedPerson = namedPerson
      ? namedPerson
      : EmailPlaceHolder.NamedPerson;
  }

  public resetEmail(): void {
    this.onNamedPersonChanged();
    this.emailText.setValue(this.replacePlaceholders(this.emailText.value));
  }

  private replacePlaceholders(text: string): string {
    if (!text) {
      return '';
    }
    const donationAmount = this.amount.value;
    const recipientName = this.recipientName.value;
    const newText = text
      .replace(
        this.storedDonationAmount,
        donationAmount ? donationAmount : EmailPlaceHolder.DonationAmount,
      )
      .replace(
        EmailPlaceHolder.DonationAmount,
        donationAmount ? donationAmount : EmailPlaceHolder.DonationAmount,
      )
      .replace(
        EmailPlaceHolder.DonorName,
        this.isAnonymous.value === true
          ? 'Anonymous donor'
          : EmailPlaceHolder.DonorName,
      )
      .replace(
        this.recipientNameHolder.length <= 4 &&
        this.recipientNameHolder.length > 0
          ? new RegExp(`\\b${this.recipientNameHolder}\\b`)
          : this.recipientNameHolder,
        this.giftType.value != GiftType.InHonorOf && this.recipientName.value
          ? recipientName
          : this.giftType.value === GiftType.InHonorOf && this.namedPerson.value
            ? this.namedPerson.value
            : EmailPlaceHolder.RecipientName,
      )
      .replace(
        EmailPlaceHolder.NamedPerson,
        this.namedPerson.value
          ? this.namedPerson.value
          : EmailPlaceHolder.NamedPerson,
      );
    this.storedDonationAmount = donationAmount
      ? donationAmount
      : EmailPlaceHolder.DonationAmount;
    this.recipientNameHolder = recipientName
      ? recipientName
      : EmailPlaceHolder.RecipientName;
    return newText;
  }

  public sendEmailFilter = (date: Date): boolean => {
    return (
      UtilsComponent.strictDate(date) >= UtilsComponent.strictDate(new Date())
    );
  };

  public manageRepeatType(): void {
    this.everyValue.setValue(1);
    this.donationFrequency.setValue(0);
  }

  public startDateFilter = (date: Date): boolean => {
    if (date) {
      const endDate = this.endDate.value;
      const checkDate = UtilsComponent.strictDate(date);
      const today = UtilsComponent.strictDate(new Date());
      if (endDate) {
        return (
          checkDate >= today && checkDate <= UtilsComponent.strictDate(endDate)
        );
      } else {
        return checkDate >= today;
      }
    }
  };

  public endDateFilter = (date: Date): boolean => {
    if (date) {
      const startDate = this.startDate.value;
      const checkDate = UtilsComponent.strictDate(date);
      const today = UtilsComponent.strictDate(new Date());
      if (startDate) {
        return (
          checkDate >= today &&
          checkDate >= UtilsComponent.strictDate(startDate)
        );
      } else {
        return checkDate >= today;
      }
    }
  };

  public get isAmountANumber(): boolean {
    return this.amount.value !== '' && this.amount.value !== null;
  }

  public get isAgreeWithConditionsTiers(): boolean {
    const index = this.getTierForm.controls.findIndex(
      (tier) => tier.get('recurring').value === true,
    );
    return (
      (index !== -1 &&
        this.getTierForm.controls[index].get('agreedTermsConditions').value ===
        false) ||
      (this.customDonationTiersForm.get('recurring').value === true &&
        this.customDonationTiersForm.get('agreedTermsConditions').value ===
        false)
    );
  }

  public donate(): void {
    this.paymentForm.markAllAsTouched();
    if (this.paymentForm.invalid) {
      this.showErrorMessage('Please fill all mandatory fields');
      this.getTierForm.controls.forEach((item, i) => {
        this.showErrorTiersFields(i);
      });
      this.showErrorFields();
    } else {
      this.donateEmitter.emit();
    }
  }

  private showErrorMessage(message: string): void {
    this.toastrService.error(message, 'Error');
  }

  private showErrorTiersFields(i: number): void {
    const form = this.getTierForm.controls;
    if (this.getTierForm && form[i].get('numberOfMonth').invalid) {
      const input = this.numberOfMonthRef.toArray()[i];
      if (input) {
        UtilsComponent.scrollIntoView(input.container);
      }
    } else if (form[i].get('everyValue').invalid) {
      const input = this.everyRef.toArray()[i];
      if (input) {
        UtilsComponent.scrollIntoView(input.container);
      }
    } else if (form[i].get('customNumber').invalid) {
      const input = this.customNumberRef.toArray()[i];
      if (input) {
        UtilsComponent.scrollIntoView(input.container);
      }
    }
  }

  private showErrorFields(): void {
    if (this.amount && this.amount.invalid && this.isDonationStandard) {
      UtilsComponent.scrollIntoView(this.amountRef);
    } else if (this.namedPerson && this.namedPerson.invalid) {
      UtilsComponent.scrollIntoView(this.namedPersonRef);
    } else if (this.recipientName && this.recipientName.invalid) {
      UtilsComponent.scrollIntoView(this.recipientNameRef);
    } else if (this.recipientEmail && this.recipientEmail.invalid) {
      UtilsComponent.scrollIntoView(this.recipientEmailRef);
    } else if (this.emailSubject && this.emailSubject.invalid) {
      UtilsComponent.scrollIntoView(this.emailSubjectRef);
    } else if (this.emailText && this.emailText.invalid) {
      UtilsComponent.scrollIntoView(this.emailTextRef);
    } else if (this.sendEmail && this.sendEmail.invalid) {
      UtilsComponent.scrollIntoView(this.sendEmailRef);
    } else if (
      (this.donationFrequency && this.donationFrequency.invalid) ||
      (this.everyValue && this.everyValue.invalid)
    ) {
      UtilsComponent.scrollIntoView(this.repeatRef);
    } else if (this.numberOfPeriods && this.numberOfPeriods.invalid) {
      UtilsComponent.scrollIntoView(this.numberOfPeriodsRef);
    } else if (this.startDate && this.startDate.invalid) {
      UtilsComponent.scrollIntoView(this.startDateRef);
    } else if (this.endDate && this.endDate.invalid) {
      UtilsComponent.scrollIntoView(this.endDateRef);
    }
  }

  public get infoPage(): FbElementModel {
    if (!this.fbTemplate) {
      return null;
    }

    return this.fbTemplate.infoPage.elements.find((element) => element.type === FbElementType.DonationInfo);
  }

  getDonationBubbleBackground(amountValue: number, index?: number): string {
    if (!!index || index === 0) {
      return this.fundAllocation.controls[index].get('amount').value ==
      amountValue
        ? this.fbTemplate?.secondaryButtonColor
        : this.fbTemplate?.donationBubbleDefaultColor || '#afafaf';
    }
    return this.amount.value == amountValue
      ? this.fbTemplate?.secondaryButtonColor
      : this.fbTemplate?.donationBubbleDefaultColor || '#afafaf';
  }

  public convertAmount(value: number): string {
    return Number.isInteger(value)
      ? value.toString()
      : Number(value).toFixed(2);
  }

  public editTitle(event: Event): void {
    this.handleOpenedToolbars();

    const targetContainer: any = (event.target as HTMLElement).closest('div');
    const targetTitle: HTMLElement = targetContainer.children[0];
    const { fontSize, fontWeight, color } = getComputedStyle(targetTitle);
    const stylesAttribute: string = `font-size:${fontSize}; font-weight: ${fontWeight}; color:${color}`;

    this.editor.style.display = 'block';
    targetContainer.style.display = 'none';
    const innerTextWrapper = this.editor.querySelector('.ql-editor');
    innerTextWrapper.setAttribute('style', stylesAttribute);

    this.titleForm.patchValue({
      titleControl:
      targetTitle.innerHTML /*`<span style="${stylesAttribute}">${targetTitle.innerHTML}</span>`*/,
    });

    this.handleEmptyField(stylesAttribute);
    this.handleEditorClick(targetContainer);
  }

  public handleEditorClick(element: HTMLElement) {
    this.editSubscription = fromEvent(document, 'click')
      .pipe(
        map((e: Event) => {
          if (!(e.target as HTMLElement).closest('button')) {
            if (!(e.target as HTMLElement).closest('quill-editor')) {
              element.style.display = 'flex';
              const field: string =
                element === this.titleContainer ? 'text' : 'amountToDonateText';
              this.infoPage[field] = this.titleForm.get('titleControl').value;
              this.fbss.templateWasChanged();
              this.editor.style.display = 'none';
              this.cdr.markForCheck();
            }
          }
        }),
      )
      .subscribe();
  }

  private handleOpenedToolbars() {
    this.editSubscription.unsubscribe();

    this.editor = this.editorRef.elementRef.nativeElement;
    this.titleContainer = this.titleRef.nativeElement;
    this.subtitleContainer = this.subtitleRef.nativeElement;

    this.editor.style.display = 'none';
    this.titleContainer.style.display = 'flex';
    this.subtitleContainer.style.display = 'flex';
  }

  private handleEmptyField(styles: string) {
    this.titleForm.valueChanges.subscribe((data) => {
      if (!data.titleControl) {
        this.titleForm.patchValue({
          titleControl: `<span style=${styles}> </span>`,
        });
      }
    });
  }

  public get getTitle() {
    // if (this.infoPage.isHonorGift && !this.infoPage.isInSupportOf) {
    //   return 'Make your donation in honor or in memory of someone'
    // }
    if (this.infoPage.isHonorGift) {
      return 'Make your donation in honor or in memory or in support of someone';
    }
    // if (!this.infoPage.isHonorGift && this.infoPage.isInSupportOf) {
    //   return 'Make your donation in support of someone'
    // }
  }

  public get getGiftTypeOptions() {
    if (this.infoPage.isHonorGift && !this.infoPage.isInSupportOf) {
      return this.giftTypeOptions.filter(
        (type) => type.value !== GiftType.InSupportOf,
      );
    }
    if (this.infoPage.isHonorGift && this.infoPage.isInSupportOf) {
      return this.giftTypeOptions;
    }
    if (!this.infoPage.isHonorGift && this.infoPage.isInSupportOf) {
      return this.giftTypeOptions.filter(
        (type) => type.value === GiftType.InSupportOf,
      );
    }
  }

  public get getErrorMessage() {
    if (this.giftType) {
      switch (this.giftType.value) {
        case GiftType.InHonorOf:
          return 'Honoree Email';
        case GiftType.InMemoryOf:
          return 'Recipient Email';
        case GiftType.InSupportOf:
          return 'Recipient Email';
      }
    }
  }

  public setAmount() {
    let totalTierAmount: number = 0;
    this.getTierForm.controls.forEach((control) => {
      if (control.get('isInclude').value) {
        totalTierAmount += +control.get('ammount').value;
      }
    });
    totalTierAmount += +this.customDonationTiersForm.get('ammount').value;
    this.amount.setValue(totalTierAmount);
    if (this.customDonationTiersForm.get('recurring')) {
      this.setPeriodAmountValue(this.customDonationTiersForm);
    }
  }

  public get isShowInPaymentPage() {
    return (
      this.isEmailSend.value &&
      !this.libraryVersion &&
      this.giftType.value !== GiftType.InSupportOf
    );
  }

  public get disclaimersConfirmed(): boolean {
    if (this.disclaimers.controls?.length === 0) return true;
    return (
      this.disclaimers.controls.findIndex(
        (dsc) => !dsc.get('confirmed').value,
      ) === -1
    );
  }
  public handleDisclaimersConfirmedEvent() {
    this.disclaimers.controls.forEach((control) => {
      control.get('confirmed').setValue(!control.get('confirmed').value);
    });
  }

  public onFrequencyChanged(event: any) {
    this.donationFrequency.setValue(event.value);
    // recurrency = 1 = weekly, 2 = monthly
    // everyValue = If weeks, number of weeks. || If months, number of months.
    if (event.value === DonationFrequency.Weekly) {
      this.reccurrency.setValue(PaymentRepeatType.Weekly)
      this.everyValue.setValue(1)
    }
    if (event.value === DonationFrequency.BiWeekly) {
      this.reccurrency.setValue(PaymentRepeatType.Weekly)
      this.everyValue.setValue(2)
    }
    if (event.value === DonationFrequency.Monthly) {
      this.reccurrency.setValue(PaymentRepeatType.Monthly)
      this.everyValue.setValue(1)
    }
    if (event.value === DonationFrequency.Quarterly) {
      this.reccurrency.setValue(PaymentRepeatType.Monthly)
      this.everyValue.setValue(3)
    }
    if (event.value === DonationFrequency.Yearly) {
      this.reccurrency.setValue(PaymentRepeatType.Yearly)
      // this.everyValue.setValue(12) its not used on backend.
      this.everyValue.setValue(1)
    }
  }

  public onTierFrequencyChanged(event: any, control?: AbstractControl | FormGroup) {
    const tierEveryValue = control.get('everyValue');
    const tierRecurrency = control.get('reccurrency');
    const tierFrequency = control.get('donationFrequency');

    tierFrequency.setValue(event.value);

    // recurrency = 1 = weekly, 2 = monthly
    // everyValue = If weeks, number of weeks. || If months, number of months.
    if (event.value === DonationFrequency.Weekly ||
      event.value === DonationFrequency.BiWeekly) {
      tierRecurrency.setValue(PaymentRepeatType.Weekly)
    }

    if (event.value === DonationFrequency.Weekly) {
      tierEveryValue.setValue(1)
    }
    if (event.value === DonationFrequency.BiWeekly) {
      tierEveryValue.setValue(2)
    }

    if (event.value === DonationFrequency.Monthly ||
      event.value === DonationFrequency.Quarterly) {
      tierRecurrency.setValue(PaymentRepeatType.Monthly)
    }

    if (event.value === DonationFrequency.Monthly) {
      tierEveryValue.setValue(1)
    }
    if (event.value === DonationFrequency.Quarterly) {
      tierEveryValue.setValue(3)
    }
    if (event.value === DonationFrequency.Yearly) {
      tierRecurrency.setValue(PaymentRepeatType.Yearly)
      // tierEveryValue.setValue(12) not used in backend
      tierEveryValue.setValue(1)
    }

    this.changeTierFrequencyValidators();
  }

  private changeTierFrequencyValidators() {
    const tierDonationFrequency = this.customDonationTiersForm.get('donationFrequency');
    tierDonationFrequency.clearValidators();
    tierDonationFrequency.updateValueAndValidity();

    for (const tierControl of this.getTierForm.controls) {
      const donationFrequencyControl = tierControl.get('donationFrequency');
      donationFrequencyControl.clearValidators();
      donationFrequencyControl.updateValueAndValidity();
    }
  }

  private detectDonationInfoChanges(): void {
    const donationInfo = this.fbTemplate.infoPage.elements.find(
      (element) => element.type === FbElementType.DonationInfo
    );

    const weeklyUndefined = typeof donationInfo.allowWeekly === 'undefined';
    const monthlyUndefined = typeof donationInfo.allowMonthly === 'undefined';
    const yearlyUndefined = typeof donationInfo.allowYearly === 'undefined';

    // If all undefined, set monthly as default
    if (weeklyUndefined && monthlyUndefined && yearlyUndefined) {
      this.reccurrency.setValue(PaymentRepeatType.Monthly);
      this.donationFrequency.setValue(DonationFrequency.Monthly);
      this.donationFrequency.clearValidators();
      this.donationFrequency.updateValueAndValidity();
    } else {
      const allowWeekly = donationInfo.allowWeekly
      const allowMonthly = donationInfo.allowMonthly
      const allowYearly = donationInfo.allowYearly

      // If weekly and yearly are set. Set reccurrency as null
      if ((!weeklyUndefined && !yearlyUndefined) && (allowWeekly && allowYearly)) {
        this.reccurrency.setValue(null);
        this.donationFrequency.setValue(null);
        this.donationFrequency.clearValidators();
        this.donationFrequency.updateValueAndValidity();
      }

      // If all are set and checked, make monthly default
      if ((!weeklyUndefined && !monthlyUndefined && !yearlyUndefined)
          && (allowWeekly && allowMonthly && allowYearly)) {
        this.reccurrency.setValue(PaymentRepeatType.Monthly);
        this.donationFrequency.setValue(DonationFrequency.Monthly);
        this.donationFrequency.clearValidators();
        this.donationFrequency.updateValueAndValidity();
      }

      // if weekly OR yearly is set ALONG with monthly, make monthly default
      if ((!weeklyUndefined && !monthlyUndefined) && (allowWeekly && allowMonthly)
      || (!yearlyUndefined && !monthlyUndefined) && (allowYearly && allowMonthly)) {
        this.reccurrency.setValue(PaymentRepeatType.Monthly);
        this.donationFrequency.setValue(DonationFrequency.Monthly);
        this.donationFrequency.clearValidators();
        this.donationFrequency.updateValueAndValidity();
      }
    }
  }

  private detectTiersInfoChanges(): void {
    const tierData = JSON.parse(this.infoPage.tiersOptions);
    const tier = tierData[0];

    // Tier form
    for (const tierControl of this.getTierForm.controls) {
      const tierRecurrencyControl = tierControl.get('reccurrency');
      const donationFrequencyControl = tierControl.get('donationFrequency');

      // If weekly and yearly are set. Set reccurrency as null and make donationFrequency required.
      if (tier.allowWeekly && tier.allowYearly) {
        tierRecurrencyControl.setValue(null);
        donationFrequencyControl.setValue(null);
        donationFrequencyControl.clearValidators();
        donationFrequencyControl.updateValueAndValidity();
      }

      // If all are set and checked, make monthly default
      if (tier.allowWeekly && tier.allowMonthly && tier.allowYearly) {
        tierRecurrencyControl.setValue(PaymentRepeatType.Monthly);
        donationFrequencyControl.setValue(DonationFrequency.Monthly);
        donationFrequencyControl.clearValidators();
        donationFrequencyControl.updateValueAndValidity();
      }

      // if weekly OR yearly is set ALONG with monthly, make monthly default
      if ((tier.allowWeekly && tier.allowMonthly)
          || (tier.allowYearly && tier.allowMonthly)) {
          tierRecurrencyControl.setValue(PaymentRepeatType.Monthly);
          donationFrequencyControl.setValue(DonationFrequency.Monthly);
          donationFrequencyControl.clearValidators();
          donationFrequencyControl.updateValueAndValidity();
      }
    }

    // Custom tier form
    const tierRecurrency = this.customDonationTiersForm.get('reccurrency');
    const tierDonationFrequency = this.customDonationTiersForm.get('donationFrequency');

    // If weekly and yearly are set. Set reccurrency as null and make donationFrequency required.
    if (tier.allowWeekly && tier.allowYearly) {
      tierRecurrency.setValue(null);
      tierDonationFrequency.setValue(null);
      tierDonationFrequency.clearValidators();
      tierDonationFrequency.updateValueAndValidity();
    }

    // If all are set and checked, make monthly default
    if (tier.allowWeekly && tier.allowMonthly && tier.allowYearly) {
      tierRecurrency.setValue(PaymentRepeatType.Monthly);
      tierDonationFrequency.setValue(DonationFrequency.Monthly);
      tierDonationFrequency.clearValidators();
      tierDonationFrequency.updateValueAndValidity();
    }

    // if weekly OR yearly is set ALONG with monthly, make monthly default
    if ((tier.allowWeekly && tier.allowMonthly)
        || (tier.allowYearly && tier.allowMonthly)) {
      tierRecurrency.setValue(PaymentRepeatType.Monthly);
      tierDonationFrequency.setValue(DonationFrequency.Monthly);
      tierDonationFrequency.clearValidators();
      tierDonationFrequency.updateValueAndValidity();
    }
  }
}
