import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {TagService} from '../../../../../services/tag/tag.service';
import {TagStatus} from '../../../../../models/enum/tag-status.type';
import {AdminConfirmationComponent} from '../../../../admin/admin-confirmation/admin-confirmation.component';
import {MatDialog} from '@angular/material/dialog';
import {filter, first, switchMap, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Paging} from '../../../../../models/paging/paging.model';
import {FilterType} from '../../../../../models/enum/filter.type';
import TagModel from 'src/app/models/tag/tag.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-new-tag',
  templateUrl: './add-new-tag.component.html',
  styleUrls: ['./add-new-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddNewTagComponent implements OnInit{
  @Input() public clientId: string;
  @Input() private availableLength: number;
  @Input() public isFormSettingsPage: boolean = false;
  @Input() public isDonorTagManager: boolean = false;
  @Input() public selectOnly: boolean = false;
  public form: FormGroup;
  public inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Output() public newTag: EventEmitter<TagModel> = new EventEmitter<TagModel>();
  @Output() public cancelEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private formBuilder: FormBuilder,
    private clientDonorTagService: TagService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.form = this.formBuilder.group({
      name: ['', [Validators.maxLength(25)]],
      description: ['', [Validators.maxLength(150)]],
    });
   }

  public save(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.toastr.error(this.translate.instant('Fill all mandatory fields'), this.translate.instant('Error'));
      return;
    }
    const config = {
      data: {
        title: this.translate.instant('CUSTOMIZATION.addingNewTag', {value: this.form.get('name').value}),
        secondButtonName: this.translate.instant('No')
      }
    };
    this.dialog.open(AdminConfirmationComponent, config).afterClosed()
      .pipe(
        first(),
        filter(value => !!value),
        switchMap(() => {
          this.inProgress.next(true);
          const newTag: TagModel = {
            ...this.form.value,
            clientID: this.clientId,
            status: TagStatus.Active
          };
          return this.clientDonorTagService.addModel(newTag);
        }),
        tap((tag: TagModel) => {
          this.newTag.emit(tag);
          this.inProgress.next(false);
          this.toastr.success(this.translate.instant('CUSTOMIZATION.Tag has been added successfully'), this.translate.instant('Info'));
          this.cancel();
        })
      )
      .subscribe();
  }

  public cancel(): void {
    this.form.reset();
    this.cancelEmitter.emit();
  }

  ngOnInit(): void {
    if (!this.selectOnly) {
      this.form.get('name').setValidators([Validators.required, Validators.maxLength(25)]);
      this.form.get('description').setValidators([Validators.required, Validators.maxLength(150)]);
    }
  }

  private get name(): FormControl {
    return this.form.get('name') as FormControl;
  }

  public onBlur(): void {
    if (!this.name.value || this.name.value.length > 25) {
      return;
    }
    // todo check with auto tags
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: false,
      filters: [
        {
          field: 'clientID',
          value: this.clientId,
          type: FilterType.Equal,
        },
        {
          field: 'name',
          value: this.name.value,
          type: FilterType.Equal,
        },
      ],
    };
    this.inProgress.next(true);
    this.clientDonorTagService.getTotal(paging)
      .pipe(
        first(),
        tap((total: number) => {
          if (total > 0) {
            this.name.setErrors({tagNameUnique: true});
          } else if (this.availableLength > 199) {
            this.name.setErrors({over200: true});
          } else {
            this.name.setErrors(null);
          }
          this.inProgress.next(false);
        })
      )
      .subscribe();
  }

  public onFocus(): void {
    this.inProgress.next(true);
  }

}
