<div>
  <div [matMenuTriggerFor]="menu">
    <ng-content></ng-content>
  </div>
  <mat-menu #menu="matMenu">
    <div class="date-range-selector">
      <div class="date-range-selector-input">
        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
          <mat-label *ngIf="label">{{label}}</mat-label>
          <mat-date-range-input [formGroup]="formGroup" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="{{'Start date' | translate}}">
            <input matEndDate formControlName="end" placeholder="{{'End date' |  translate}}">
          </mat-date-range-input>
          <mat-hint *ngIf="hint">{{hint}}</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="date-range-selector-action-buttons">
        <app-button
          label="{{'Apply Filters' | translate}}"
          color="#0064BE"
          type="basic"
          (clickEvent)="onApply()">
        </app-button>
        <app-button
          label="{{'Clear All' | translate}}"
          color="#0064BE"
          type="basic"
          (clickEvent)="onClear()">
        </app-button>
      </div>
    </div>
  </mat-menu>
</div>
