import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IpifyModel } from '../../models/ip/ipify.model';
import { BaseModelService } from '../base.model.service';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IpService extends BaseModelService<IpifyModel> {

  protected get controllerName(): string {
    return 'Payment';
  }

  public getIPAddress(): Observable<IpifyModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/userip`;
        return this.http.get<IpifyModel>(url);
      }),
    )
  }
}
