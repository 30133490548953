
<div class="main-label-wrapper" [ngStyle]="mainLabelStyle">
  <b>{{element.attributes['mainLabel'] | translate}}</b>
</div>
<div class="form-row-wrapper">
  <div class="form-element" [ngStyle]="singleFormElementStyle">
    <!--<app-fb-input
      [formGroup]="paymentForm"
      [label]="element.attributes['subLabel']"
      name="comment"
      errorMassage="Comment"
      [inputBackground]="template.inputBackground"
      [color]="template.fontColor"
      [fontFamily]="template.fontFamily"
      [fontSize]="template.fontSize"
      [disabled]="readOnly"
    ></app-fb-input>-->
    <app-fb-textarea
      [formGroup]="paymentForm"
      [label]="element.attributes['subLabel']"
      name="comment"
      errorMassage="Comment"
      [inputBackground]="template.inputBackground"
      [color]="template.fontColor"
      [fontFamily]="template.fontFamily"
      [fontSize]="template.fontSize"
      [disabled]="readOnly"
    ></app-fb-textarea>
  </div>
</div>
