import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { Subscription } from 'rxjs';
import { MjmlCustomType } from '../../../../models/templates/mjml.custom.type';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { AUTOPLAY_VIDEO, PRELOAD_VIDEO } from '../../../../constants';
import { TemplateType } from '../../../../models/templates/template.type';
import { VideoScreenshotsComponent } from '../video-screenshots/video-screenshots.component';
import { MatDialog } from '@angular/material/dialog';
import { AzureUploadFileService } from '../../../../services/lookup/azure-upload-file.service';

@Component({
  selector: 'app-video-tool-settings',
  templateUrl: './video-tool-settings.component.html',
  styleUrls: ['./video-tool-settings.component.scss', '../tool-settings.scss']
})
export class VideoToolSettingsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() public tms: TemplateManagementService;

  public preloadOptions: FormElementDataModel[] = PRELOAD_VIDEO;
  public autoplayOptions: FormElementDataModel[] = AUTOPLAY_VIDEO;
  public apiURL: string = localStorage.getItem('apiurl');

  public videoForm: FormGroup = this.formBuilder.group({
    containerBackgroundColor: '',
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]],
    src: ['', [Validators.required]],
    preload: 'auto',
    autoplay: true,
    poster: ''
  });

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private uploadService: AzureUploadFileService,
  ) { }

  public ngOnInit(): void {
    this.setInitialValues();

    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {

        if (!element || element.tagName !== MjmlTag.text || element.attributes['customType'] !== MjmlCustomType.video) {
          return;
        }
        this.setInitialValues();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private updateVideoTag(): void {
    const src = this.videoForm.get('src').value;
    const preload = this.videoForm.get('preload').value;
    const autoplay = this.videoForm.get('autoplay').value;
    const poster = this.videoForm.get('poster').value;
    this.tms.activeElement.content = this.tms.getVideoHtml(src, preload, autoplay, poster);
    this.tms.videoElementChanged();
  }

  private setInitialValues(): void {
    const attributes = this.tms.activeElement.attributes;
    const containerBackgroundColor = attributes['container-background-color'] || '';
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    const src = attributes['src'] || '';
    const preload = attributes['preload'] || 'auto';
    const autoplay = !!attributes['autoplay'];
    const poster = attributes['poster'] || '';

    this.videoForm.get('containerBackgroundColor').setValue(containerBackgroundColor);
    this.videoForm.get('paddingBottom').setValue(paddingBottom);
    this.videoForm.get('paddingLeft').setValue(paddingLeft);
    this.videoForm.get('paddingRight').setValue(paddingRight);
    this.videoForm.get('paddingTop').setValue(paddingTop);
    this.videoForm.get('src').setValue(src);
    this.videoForm.get('preload').setValue(preload);
    this.videoForm.get('autoplay').setValue(autoplay);
    this.videoForm.get('poster').setValue(poster);
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public onSrcChanged(): void {
    const value = this.videoForm.get('src').value;
    this.tms.activeElement.attributes['src'] = value ? value : '';
    this.updateVideoTag();
    this.tms.emitSave(true);
  }

  public onPreloadChanged(): void {
    const value = this.videoForm.get('preload').value;
    this.tms.activeElement.attributes['preload'] = value ? value : 'auto';
    this.updateVideoTag();
    this.tms.emitSave(true);
  }

  public onAutoplayChanged(): void {
    const value = this.videoForm.get('autoplay').value;
    this.tms.activeElement.attributes['autoplay'] = value ? value : true;
    this.updateVideoTag();
    this.tms.emitSave(true);
  }

  public onPosterChanged(): void {
    const value = this.videoForm.get('poster').value;
    this.tms.activeElement.attributes['poster'] = value ? value : '';
    this.updateVideoTag();
    this.tms.emitSave(true);
  }

  public onContainerBackgroundColorChanged(): void {
    const value = this.videoForm.get('containerBackgroundColor').value;
    this.tms.activeElement.attributes['container-background-color'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onPaddingTopChanged(): void {
    const value = this.videoForm.get('paddingTop').value;
    this.tms.activeElement.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.videoForm.get('paddingRight').value;
    this.tms.activeElement.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.videoForm.get('paddingBottom').value;
    this.tms.activeElement.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.videoForm.get('paddingLeft').value;
    this.tms.activeElement.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public get src(): FormControl {
    return this.videoForm.get('src') as FormControl;
  }

  public get poster(): FormControl {
    return this.videoForm.get('poster') as FormControl;
  }

  public makePoster(): void {
    const config = {
      height: '90vh',
      width: '90vw',
      data: {
        link: this.src.value
      }
    };
    this.subscription.add(
      this.dialog.open(VideoScreenshotsComponent, config).afterClosed()
        .subscribe(response => {
          if (!response) {
            return;
          }
          const poster = this.poster.value;
          if (poster) {
            //this.deletePrevious(poster);
          }
          const url = `${this.apiURL}/AzureFileStorage/image/${response}`;
          this.poster.setValue(url);
          this.onPosterChanged();
        })
    );
  }

  private deletePrevious(url: string): void {
    const urlArray = url.split('/');
    const fileName = urlArray[urlArray.length - 1];
    this.subscription.add(
      this.uploadService.deleteModel(fileName).subscribe()
    );
  }

}
