import {ConfigService} from '../appconfig.service';
import {flatMap, map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Paging} from 'src/app/models/paging/paging.model';
import {PagingHelper} from '../helper/paging.helper';
import {TotalRowsModel} from 'src/app/models/paging/totalrow.model';
import TagModel from 'src/app/models/tag/tag.model';
import DeleteItemsByIdModel from '../../models/segment/delete.items.by.id.model';

@Injectable({
  providedIn: 'root'
})
export class ClientDonorTagService {
  private controllerName = 'ClientDonorTag';

  constructor(private configService: ConfigService, private http: HttpClient) { }

  public getModelList(clientDonorID: string, filter?: Paging): Observable<TagModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<TagModel[]>(
          `${config.apiURL}/${this.controllerName}/${clientDonorID}`,
          { params }
        );
      })
    );
  }

  public getTotal(clientDonorID: string, filter?: Paging): Observable<number> {
    const params: HttpParams = PagingHelper.getPagingParams(filter, false);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/total/${clientDonorID}`;
        return this.http.get<TotalRowsModel>(url, { params })
          .pipe(map((model: TotalRowsModel) => model.total));
      })
    );
  }

  public addModels(clientDonorID: string, tagsIds: string[]): Observable<TagModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/${clientDonorID}`;
        return this.http.post<TagModel[]>(url, tagsIds);
      })
    );
  }

  public deleteModels(model: DeleteItemsByIdModel): Observable<any> {
    let params = new HttpParams();
    model.ids.forEach(item => {
      params = params.append('ids', item);
    });
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/${model.clientID}`;
        return this.http.delete(url, { params });
      })
    );
  }
}
