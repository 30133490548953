import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ImageBuilderStateService } from '../../../image-builder.state.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LayerModel } from '../../../../../models/image-builder/layer.model';
import { LayerType } from '../../../../../models/image-builder/layer.type';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import { BORDER_STYLE } from '../../../../../constants';

@Component({
  selector: 'app-image-styling',
  templateUrl: './image-styling.component.html',
  styleUrls: ['../styling.component.scss']
})
export class ImageStylingComponent implements OnInit, OnDestroy {
  @Input() public ibss: ImageBuilderStateService;
  private subscription: Subscription = new Subscription();
  public borderStyleOptions: FormElementDataModel[] = BORDER_STYLE;
  public imageURL: string = null;

  public imageForm: FormGroup = this.formBuilder.group({
    url: ['', [Validators.required]],
    borderRadius: '',
    borderWidth: '0',
    borderColor: '#414141',
  });

  constructor(
    private formBuilder: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.subscription.add(
      this.ibss.activeLayerObservable.subscribe((layer: LayerModel) => this.setInitialValues())
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get readOnly(): boolean {
    return this.ibss.readOnly;
  }

  public get themedTemplate(): boolean {
    return this.ibss.themedTemplate;
  }

  public get componentBasedTemplate(): boolean {
    return this.ibss.componentBasedTemplate;
  }

  private setInitialValues(): void {
    const {
      url = '',
      borderRadius = '0',
      borderWidth = '0',
      borderColor = '#414141',
    } = this.ibss.activeLayerAttributes;
    this.imageForm.get('url').setValue(url);
    this.imageForm.get('borderRadius').setValue(+borderRadius);
    this.imageForm.get('borderWidth').setValue(+borderWidth);
    this.imageForm.get('borderColor').setValue(borderColor);
  }

  public get uploadLabel(): string {
    switch (this.ibss.activeLayer.type) {
      case LayerType.logo:
        return 'Upload logo';
      case LayerType.image:
        return 'Upload image';
    }
  }

  public get urlLabel(): string {
    switch (this.ibss.activeLayer.type) {
      case LayerType.logo:
        return 'Logo URL';
      case LayerType.image:
        return 'Image URL';
    }
  }

  public imageUrlChanged(url: string): void {
    this.imageURL = null;
    if (url === null) {
      return;
    }
    if (this.ibss.activeLayer.type === LayerType.logo) {
      this.ibss.setActiveLayerAttributes('default-attributes-changed', 'changed');
    }
  }

  public onUrlChanged(): void {
    const value = this.imageForm.get('url').value || '';
    this.ibss.setActiveLayerAttributes('url', value);
  }

  public editImage(): void {
    this.imageURL = this.imageForm.get('url').value;
  }

  public cancelEditImage(): void {
    this.imageURL = null;
  }

  public onBorderWidthChanged(): void {
    const value = this.imageForm.get('borderWidth').value || '0';
    this.ibss.setActiveLayerAttributes('borderWidth', value);
  }

  public onBorderColorChanged(): void {
    const value = this.imageForm.get('borderColor').value || '#414141';
    this.ibss.setActiveLayerAttributes('borderColor', value);
  }

  public onBorderRadiusChanged(): void {
    const value = this.imageForm.get('borderRadius').value || '0';
    this.ibss.setActiveLayerAttributes('borderRadius', value);
  }

}
