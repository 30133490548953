import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormElementComponent } from '../base.form.element.component';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['../base.form.element.component.scss', '../new.base.form.element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RadioComponent extends BaseFormElementComponent implements OnChanges  {
  constructor(public translate: TranslateService) {
    super(translate);
  }
  @Input() radioBorderColor: string = '#0064be';

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
}
