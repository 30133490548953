<div class="events-header">
  <div class="input-wrapper">
    <input type="search" placeholder="{{'EVENTS.Search by Event Name' | translate}}" (input)="onEventSearch($event)" [(ngModel)]="searchInputValue">
    <i class="fas fa-search magnifier"></i>
  </div>
  <div class="header-buttons"  *ngIf="isAllPermissionAvailable">
    <app-button label="New Primary Event" (clickEvent)="createNew()" type="flat" color="#0064be" width="220"></app-button>
    <app-button label="New Secondary Event" (clickEvent)="createNewSecondary()" type="flat" color="#0064be" width="220"></app-button>
  </div>
</div>

<div class="events-relationship" *ngIf="clientsWithRelationships && clientsWithRelationships.length > 1">
  <app-client-select
    [formGroup]="formGroup"
    name="clientID"
    [selectOptions]="clientsWithRelationships"
    (clientChanged)="onClientIdChanged($event)"
  ></app-client-select>
</div>

<div class="events-table">
  <div class="table-wrapper">
    <app-event-list-table
      [data]="allEventsTableData"
      [tableDataColumns]="allEventsTableColumns"
      [columnsFilterOptions]="columnsFilterOptions"
      [filterSortConfig]="getFilterSortConfig(false)"
      [initialSelection]="currentClientID"
      (editClicked)="editEvent($event)"
      (navigateToEventDashboard)="navigateToEventDashboard($event)"
      [draftsData]="isDraft"
      (filterChanged)="onCurrentFilterChanged($event, false)"
      (cancelArchiveEvent)="cancelArchiveEvent($event)"
    ></app-event-list-table>
  </div>
  <app-paginator
    [totalItems]="allEventsTotal"
    (nextPage)="getNextPage($event)"
    (entriesPerPageChanged)="setEntriesPerPage($event)"
    [setDefaultPage]="setAllEventsDefaultPage$"
  ></app-paginator>
</div>
