<div class="fb-create-tab-wrapper" *ngIf="fbss.template$ | async as template">

  <!-- Form Elements -->
  <div class="fb-create-tab-elements">
    <div class="fb-create-tab-elements-title">{{'BUILD.Form Elements' | translate}}</div>

      <div
        cdkDropList
        #availableElementsList="cdkDropList"
        [cdkDropListData]="availablePaymentPageElements"
        [cdkDropListConnectedTo]="[paymentPageElementsList]"
        (cdkDropListDropped)="drop($event)"
        cdkDropListSortingDisabled
        [cdkDropListEnterPredicate]="noReturnPredicate"
        class="fb-create-tab-available-elements"
      >
        <div
          *ngFor="let element of availablePaymentPageElements"
          cdkDrag
          [cdkDragDisabled]="fbss.readOnly"
        >
          <div
            *ngIf="(fbss.paymentPage$ | async) === FormPageType.Second"
            class="tool-template"
          >
            <ng-container [ngSwitch]="element.type">
              <ng-container *ngSwitchCase="FbElementType.Header">
                <i class="fas fa-heading"></i>
                <span>{{'BUILD.Header' | translate}}</span>
              </ng-container>
              <ng-container *ngSwitchCase="FbElementType.Comments">
                <i class="far fa-comment"></i>
                <span>{{'BUILD.Comments' | translate}}</span>
              </ng-container>
            </ng-container>
          </div>
          <div *cdkDragPreview class="preview-drag-block">{{('BUILD.' + blockTitle(element.type)) | translate}}</div>
        </div>
      </div>

    <div
      cdkDropList
      #availableElementsList="cdkDropList"
      [cdkDropListData]="availableInfoPageElements"
      [cdkDropListConnectedTo]="[infoPageElementsList]"
      (cdkDropListDropped)="drop($event)"
      cdkDropListSortingDisabled
      [cdkDropListEnterPredicate]="noReturnPredicate"
      class="fb-create-tab-available-elements"
    >
      <div
        *ngFor="let element of availableInfoPageElements"
        cdkDrag
        [cdkDragDisabled]="fbss.readOnly"
      >
        <div
          *ngIf="(fbss.paymentPage$ | async) === FormPageType.First"
          class="tool-template"
        >
          <i class="fas fa-heading"></i>
          <span>{{'BUILD.Header' | translate}}</span>
        </div>
        <div *cdkDragPreview class="preview-drag-block">{{('BUILD.' + blockTitle(element.type)) | translate}}</div>
      </div>
    </div>
  </div>

  <!-- Template -->
  <div
    class="fb-create-tab-template"
    [ngStyle]="mainContainerStyle"
    id="fb-template-container"
  >

    <!-- Header -->

    <div class="top-header-wrapper">
      <div class="top-header-body" [ngStyle]="headerStyle">
        <img *ngIf="logoSrc != ''" [src]="logoSrc" [ngStyle]="logoStyle">
      </div>
      <div class="top-header-settings">
        <div
          (click)="addLogo()"
          class="settings-button"
        >
          <i class="fas fa-cog"></i>
        </div>
      </div>
    </div>

    <!-- Image Template -->
    <div
      class="fb-create-tab-template-top-image-container"
      [ngStyle]="{minHeight: getSettings('height')}"
    >
      <div class="top-images"
           [ngStyle]="{
           maxWidth: getSettings('width'),
           paddingBottom: getSettings('bottom'),
           paddingLeft: getSettings('left'),
           paddingRight: getSettings('right'),
           paddingTop: getSettings('top')
           }"
      >
        <ng-container *ngFor="let image of numberOfImage; index as i">
          <div class="image" [ngStyle]="{background: topImageStyle(i) | async}" [ngClass]="{ dashBorder: !isImageURL(i) }">
            <div class="container-transparent" *ngIf="!isImageURL(i)" (click)="uploadFile(i)">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66683 5.33333H25.3335C25.3621 5.33333 25.3906 5.33424 25.4188 5.33605C25.4386 5.33731 25.4583 5.33901 25.4778 5.34114C26.1439 5.41367 26.6668 5.98206 26.6668 6.66666V9.33333C26.6668 10.0667 26.0668 10.6667 25.3335 10.6667C24.6002 10.6667 24.0002 10.0667 24.0002 9.33333V7.99999H8.00016V9.33333C8.00016 10.0667 7.40016 10.6667 6.66683 10.6667C5.9335 10.6667 5.3335 10.0667 5.3335 9.33333V6.66666C5.3335 5.93333 5.9335 5.33333 6.66683 5.33333ZM10.6682 18.6667C10.2615 18.6667 9.86283 18.4827 9.60016 18.1333C9.1575 17.544 9.2775 16.708 9.86683 16.2667L15.2002 12.2667C15.6615 11.92 16.2962 11.9107 16.7668 12.2427L22.1002 15.9947C22.7028 16.4187 22.8482 17.2507 22.4242 17.852C22.0002 18.4533 21.1695 18.6 20.5668 18.176L17.3297 15.8988C17.3322 15.9322 17.3335 15.966 17.3335 16V26.6667C17.3335 27.4027 16.7375 28 16.0002 28C15.2628 28 14.6668 27.4027 14.6668 26.6667V16.0002L11.4668 18.4C11.2268 18.58 10.9468 18.6667 10.6682 18.6667Z" fill="#6A6A6A"/>
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="5" width="22" height="23">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66683 5.33333H25.3335C25.3621 5.33333 25.3906 5.33424 25.4188 5.33605C25.4386 5.33731 25.4583 5.33901 25.4778 5.34114C26.1439 5.41367 26.6668 5.98206 26.6668 6.66666V9.33333C26.6668 10.0667 26.0668 10.6667 25.3335 10.6667C24.6002 10.6667 24.0002 10.0667 24.0002 9.33333V7.99999H8.00016V9.33333C8.00016 10.0667 7.40016 10.6667 6.66683 10.6667C5.9335 10.6667 5.3335 10.0667 5.3335 9.33333V6.66666C5.3335 5.93333 5.9335 5.33333 6.66683 5.33333ZM10.6682 18.6667C10.2615 18.6667 9.86283 18.4827 9.60016 18.1333C9.1575 17.544 9.2775 16.708 9.86683 16.2667L15.2002 12.2667C15.6615 11.92 16.2962 11.9107 16.7668 12.2427L22.1002 15.9947C22.7028 16.4187 22.8482 17.2507 22.4242 17.852C22.0002 18.4533 21.1695 18.6 20.5668 18.176L17.3297 15.8988C17.3322 15.9322 17.3335 15.966 17.3335 16V26.6667C17.3335 27.4027 16.7375 28 16.0002 28C15.2628 28 14.6668 27.4027 14.6668 26.6667V16.0002L11.4668 18.4C11.2268 18.58 10.9468 18.6667 10.6682 18.6667Z" fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                  <rect width="32" height="32" fill="#6A6A6A"/>
                </g>
              </svg>
              <div class="container-upload">{{'BUILD.Click to select file to upload (.png, .jpg, .gif)' | translate}}</div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="top-image-settings">
        <div
          (click)="showPaymentPageElementSettings(template.topImage)"
          class="settings-button"
        >
          <i class="fas fa-cog"></i>
        </div>
      </div>
    </div>

    <!-- First Page Template -->
    <div
      class="fb-create-tab-template-page"
      (mousedown)="switchPage(FormPageType.First)"
      [class.active-page]="(fbss.paymentPage$ | async) === FormPageType.First"
    >
      <div
        #infoPageElementsList="cdkDropList"
        cdkDropList
        class="payment-page-elements-list"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListData]="template.infoPage.elements"
        [cdkDropListConnectedTo]="[availableElementsList]"
        #donationInfoPageRef
      >
        <div
          class="payment-page-elements-list-item"
          *ngFor="let element of template.infoPage.elements; index as i"
          cdkDrag
          cdkDragLockAxis="y"
          [cdkDragDisabled]="fbss.readOnly"
        >
          <div *cdkDragPreview class="preview-drag-block">{{('BUILD.' + blockTitle(element.type)) | translate}}</div>

          <app-element-renderer
            *ngIf="element.type === FbElementType.Header"
            [ngStyle]="secondPageStyle"
            [element]="element"
            [fbss]="fbss"
            [countries]="countries"
            [stateOptions$]="lookupStoreService.usaStatesOptions$"
            [cornerstoneService]="cornerstoneService"
            [appStripeService]="appStripeService"
          ></app-element-renderer>

          <app-donation-info-page
            [ngStyle]="pageStyle"
            *ngIf="element.type === FbElementType.DonationInfo"
            [paymentForm]="fbss.paymentForm"
            [fbTemplate]="template"
            [donationInfoChanged$]="donationInfoChanged$"
            [distributeAmongFunds$]="fbss.distributeAmongFunds$"
            [client]="fbss.client$ | async"
            [entity]="fbss.entity$ | async"
            [fundsChanged$]="fbss.fundsChanged"
            [allocatedFundsOptions]="allocatedFundsOptions"
            [libraryVersion]="true"
            [fbss]="fbss"
          ></app-donation-info-page>

          <ng-container *ngIf="fbss.client$ | async as client">
            <div class="form-block-actions">
              <div class="drag-handler" cdkDragHandle (mousedown)="switchPage(FormPageType.First)">
                <i class="fas fa-ellipsis-v"></i>
                <i class="fas fa-ellipsis-v"></i>
              </div>
              <div class="form-block-settings">
                <div
                  (click)="showPaymentPageElementSettings(element)"
                  class="settings-button"
                >
                  <i class="fas fa-cog"></i>
                </div>
                <div
                  *ngIf="element.type === FbElementType.Header&& !fbss.readOnly"
                  (click)="deletePaymentPageElement(template.infoPage.elements, i, availableInfoPageElements)"
                  class="delete-button"
                >
                  <i class="fas fa-trash-alt"></i>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    </div>

    <!-- Second Page Template -->
    <div
      [hidden]="!(fbss.secondPageActive$ | async)"
      class="fb-create-tab-template-page second-page"
      (mousedown)="switchPage(FormPageType.Second)"
      data-html2canvas-ignore="true"
      [class.active-page]="(fbss.paymentPage$ | async) === FormPageType.Second"
    >

      <!-- List of Payment Elements -->
      <div
        #paymentPageElementsList="cdkDropList"
        cdkDropList
        class="payment-page-elements-list"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListData]="template.paymentPage.elements"
        [cdkDropListConnectedTo]="[availableElementsList]"
      >
        <div
          class="payment-page-elements-list-item"
          *ngFor="let element of template.paymentPage.elements; index as i"
          cdkDrag
          cdkDragLockAxis="y"
          [cdkDragDisabled]="fbss.readOnly"
        >
          <div *cdkDragPreview class="preview-drag-block">{{('BUILD.' + blockTitle(element.type)) | translate}}</div>

          <app-element-renderer
            [ngStyle]="secondPageStyle"
            [element]="element"
            [fbss]="fbss"
            [countries]="countries"
            [stateOptions$]="lookupStoreService.usaStatesOptions$"
            [cornerstoneService]="cornerstoneService"
            [appStripeService]="appStripeService"
            [containerWidth]="containerWidth$ | async"
          ></app-element-renderer>

          <ng-container *ngIf="fbss.client$ | async as client">
            <div class="form-block-actions" *ngIf="(!isPackOrEmployerElementType(element)) ||
             (isPackOrEmployerElementType(element) && client.clientType === ClientType.PAC)">
              <div class="drag-handler" cdkDragHandle (mousedown)="switchPage(FormPageType.Second)">
                <i class="fas fa-ellipsis-v"></i>
                <i class="fas fa-ellipsis-v"></i>
              </div>
              <div class="form-block-settings">
                <div
                  (click)="showPaymentPageElementSettings(element)"
                  class="settings-button"
                >
                  <i class="fas fa-cog"></i>
                </div>
                <div
                  *ngIf="(element.type === FbElementType.Comments || element.type === FbElementType.Header) && !fbss.readOnly"
                  (click)="deletePaymentPageElement(template.paymentPage.elements, i, availablePaymentPageElements)"
                  class="delete-button"
                >
                  <i class="fas fa-trash-alt"></i>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="buttons-block" *ngIf="(fbss.template$ | async) as template">
        <div class="buttons-container" [ngStyle]="secondPageStyle">
          <app-button
            type="flat"
            label="Back"
            [color]="template.primaryButtonColor"
            [labelColor]="template.primaryTextButtonColor"
            width="200"
          ></app-button>

          <app-button
            type="flat"
            [label]="template.donateButtonLabel"
            [color]="template.primaryButtonColor"
            [labelColor]="template.primaryTextButtonColor"
            width="200"
          ></app-button>
        </div>
        <div class="empty-action-block"></div>
      </div>
    </div>


    <div
      *ngIf="!(fbss.secondPageActive$ | async)"
      class="fb-create-tab-template-button"
      (click)="addSecondPage()"
      matRipple
    >
      <span>- - - - - - - - - - {{'BUILD.+ ADD NEW PAGE HERE' | translate}} - - - - - - - - - -</span>
    </div>

    <!-- Footer -->
    <div class="fb-create-tab-template-mock-footer" [ngStyle]="footerStyle">
      <div class="item">
        <img src="/assets/images/logo1.png" style="margin-right:10px;">
        <a style="color:white;" href="https://www.theauxilia.com">{{'BUILD.Powered by Auxilia' | translate}}</a>
        <a style="color:#93ccff;margin-left:15px;" href="https://www.theauxilia.com/privacy-policy?__hstc=181257784.ff7e06784cd8dfcd424620280a8af09b.1581499076821.1618415342297.1618475974870.854&__hssc=181257784.17.1618475974870&__hsfp=1488147824">Privacy Policy</a>
      </div>
      <div class="item">
        <div class="sm-link" *ngIf="headerFooterAttributes.facebookLink">
          <a [href]="headerFooterAttributes.facebookLink">
            <i class="fab fa-facebook"></i>
          </a>
        </div>

        <div class="sm-link" *ngIf="headerFooterAttributes.twitterLink">
          <a [href]="headerFooterAttributes.twitterLink">
            <i class="fa6 fab fa-square-x-twitter"></i>
          </a>
        </div>

        <div class="sm-link" *ngIf="headerFooterAttributes.instagramLink">
          <a [href]="headerFooterAttributes.instagramLink">
            <i class="fab fa-instagram"></i>
          </a>
        </div>

        <div class="sm-link" *ngIf="headerFooterAttributes.linkedInLink">
          <a [href]="headerFooterAttributes.linkedInLink">
            <i class="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Options -->
  <div
    id="scroll"
    *ngIf="fbss.optionsView$ | async as viewType"
    class="fb-create-tab-options"
    [class.filled]="viewType !== OptionsViewType.None"
  >
    <ng-container [ngSwitch]="viewType">
      <div
        *ngSwitchCase="OptionsViewType.None"
        class="form-design-trigger"
        (click)="openFormDesign()"
      >
        <i class="fas fa-paint-brush"></i>
      </div>

      <app-common-options
        *ngSwitchCase="OptionsViewType.Common"
        [fbss]="fbss"
      ></app-common-options>

      <app-element-options
        *ngSwitchCase="OptionsViewType.Element"
        [fbss]="fbss"
        [donationInfoChanged$]="donationInfoChanged$"
      ></app-element-options>
    </ng-container>
  </div>
</div>
