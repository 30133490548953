<div class="email-builder-tool-settings-wrapper">

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="dividerForm"
        label="Divider color"
        name="borderColor"
        [readOnly]="readOnly"
        (colorChanged)="onBorderColorChanged()"
      ></app-email-color-picker>
    </div>

    <div class="max-width-145">
      <app-select
        label="Style"
        [formGroup]="dividerForm"
        name="borderStyle"
        errorMassage="Style"
        [selectOptions]="borderStyleOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onBorderStyleChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">

      <app-slider
        [formGroup]="dividerForm"
        name="borderWidth"
        label="Border width"
        [min]="0"
        [max]="50"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onBorderWidthChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>

    <div class="max-width-145">
      <app-select
        label="Divider width"
        [formGroup]="dividerForm"
        name="width"
        errorMassage="Width"
        [selectOptions]="widthOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onWidthChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="dividerForm"
        label="Background color"
        name="containerBackgroundColor"
        [readOnly]="readOnly"
        (colorChanged)="onContainerBackgroundColorChanged()"
      ></app-email-color-picker>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">

      <app-slider
        [formGroup]="dividerForm"
        name="paddingTop"
        label="Height above"
        [min]="0"
        [max]="300"
        [step]="5"
        [tickInterval]="10"
        (valueChanged)="onPaddingTopChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">

      <app-slider
        [formGroup]="dividerForm"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="50"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingRightChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">

      <app-slider
        [formGroup]="dividerForm"
        name="paddingBottom"
        label="Height below"
        [min]="0"
        [max]="300"
        [step]="5"
        [tickInterval]="10"
        (valueChanged)="onPaddingBottomChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="dividerForm"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="50"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingLeftChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row" *ngIf="!(readOnly || isPredesignedThemedTemplate)">
    <app-button
      type="basic"
      label="Delete content"
      (clickEvent)="tms.removeActiveElementFromColumn()"
      color="#f44336"
      width="300"
    ></app-button>
  </div>

  <div class="padding-source-100"></div>

</div>
