import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { interval, Subscription } from 'rxjs';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';

@Component({
  selector: 'app-table-tool',
  templateUrl: './table-tool.component.html',
  styleUrls: ['./table-tool.component.scss']
})
export class TableToolComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() public column: MjmlElementModel;
  @Input() public entity: MjmlElementModel;
  @Input() public tms: TemplateManagementService;
  @Input() public landingPageView: boolean = false;

  @ViewChild('tableRef') private tableRef: ElementRef;

  private static setContenteditable(html: string): string {
    const regExp = /contenteditable="false"/gm;
    const replaceValue = 'contenteditable="true"';
    return html.replace(regExp, replaceValue);
  }

  private static removeContenteditable(html: string): string {
    const regExp = /contenteditable="true"/gm;
    const replaceValue = 'contenteditable="false"';
    return html.replace(regExp, replaceValue);
  }

  constructor(
  ) { }

  public ngOnInit(): void {
    this.subscription.add(
      this.tms.activeTableChangedEmitter.subscribe((element: MjmlElementModel) => {
        if (element === this.entity) {
          this.setTableHtml();
        }
      })
    );
    this.subscription.add(
      interval(1000).subscribe(() => {
        this.updateMjmlContent();
      })
    );
  }

  public ngAfterViewInit(): void {
    this.setTableHtml();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setTableHtml(): void {
    this.tableRef.nativeElement.innerHTML = this.readOnly || this.landingPageView ? this.entity.content : TableToolComponent.setContenteditable(this.entity.content);
  }

  private updateMjmlContent(): void {
    const html = this.tableRef.nativeElement.innerHTML;
    this.entity.content = TableToolComponent.removeContenteditable(html);
    //this.tms.emitSave();
  }

  public getContainerStyles(): any {
    const attributes = this.entity.attributes;
    const align = attributes['align'] || 'left';
    let alignItems;
    switch (align) {
      case 'center':
        alignItems = 'center';
        break;
      case 'left':
        alignItems = 'flex-start';
        break;
      case 'right':
        alignItems = 'flex-end';
        break;
    }
    const color = attributes['color'] || '#000000';
    const containerBackgroundColor = attributes['container-background-color'] || 'transparent';
    const fontFamily = attributes['font-family'] || 'Roboto';
    const fontSize = attributes['font-size'] || '13px';
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    return {
      'background-color': containerBackgroundColor,
      color,
      'font-family': fontFamily,
      'font-size': fontSize,
      'align-items': alignItems,
      'padding-bottom': paddingBottom,
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      'padding-top': paddingTop
    };
  }

  public getStyles(): any {
    const attributes = this.entity.attributes;
    const width = attributes['width'] ? attributes['width'] : '100%';
    return { width };
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }
}
