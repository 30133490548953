<div class="cropper-container">
  <app-icons
    [iconType]="IconsType.Close"
    width="48px"
    height="48px"
    color="#66788A"
    (click)="closeDialog()"
  ></app-icons>

  <image-cropper
    [imageURL]="src.value"
    [canvasRotation]="userCanvasRotation"
    [aspectRatio]="userAspectRatio"
    [maintainAspectRatio]="userMaintainAspectRatio"
    format="jpeg"
    (imageCropped)="imageCropped($event)"
  ></image-cropper>

  <div class="controls" [ngStyle]="setGridStyles()">
    <div class="controls-icon controls-icon--rotate-left" (click)="rotateImage(-1)">
      <app-icons [iconType]="IconsType.RotateLeft" color="#ffffff"></app-icons>
    </div>
    <div class="controls-icon controls-icon--rotate-right" (click)="rotateImage(1)">
      <app-icons [iconType]="IconsType.RotateRight" color="#ffffff"></app-icons>
    </div>
    <div (click)="setAspectRatio(1)" class="controls-icon controls-icon--rotate-resolution">Square</div>
    <div *ngIf="data.provider === SocialProviderEnum.Instagram" (click)="setAspectRatio(5/4)"
      class="controls-icon controls-icon--rotate-resolution">5:4</div>
    <div *ngIf="data.provider !== SocialProviderEnum.Instagram" (click)="setAspectRatio(16/9)"
      class="controls-icon controls-icon--rotate-resolution">16:9</div>
    <div *ngIf="data.provider === SocialProviderEnum.Facebook || data.provider === SocialProviderEnum.Linkedin"
      (click)="setAspectRatio(7/5)" class="controls-icon controls-icon--rotate-resolution">7:5</div>
    <div *ngIf="data.provider === SocialProviderEnum.Facebook || data.provider === SocialProviderEnum.Linkedin"
      (click)="setAspectRatio(4/3)" class="controls-icon controls-icon--rotate-resolution">4:3</div>
    <div *ngIf="data.provider === SocialProviderEnum.Facebook || data.provider === SocialProviderEnum.Linkedin"
      (click)="setAspectRatio(3/2)" class="controls-icon controls-icon--rotate-resolution">3:2</div>
    <div *ngIf="data.provider === SocialProviderEnum.Twitter" (click)="setDefaultRatio()"
      class="controls-icon controls-icon--rotate-resolution">Original</div>
  </div>

  <div class="buttons">
    <app-button type="flat" color="#f44336" label="Cancel" (clickEvent)="closeDialog()" isTitleCaseText=false>
    </app-button>
    <app-button type="flat" color="#0064be" label="Save" (clickEvent)="saveCroppedImage()" isTitleCaseText=false>
    </app-button>
  </div>

</div>
