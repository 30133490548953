<div
  class="mjml-renderer-header footer"
  [ngStyle]="footerStyle"
>
  <div class="item">
    <img src="/assets/images/logo1.png" style="margin-right:10px;">
    <a style="color:white;" href="https://www.theauxilia.com">{{'Powered by Auxilia' | translate}}</a>
    <a style="color:#93ccff;margin-left:15px;" href="https://www.theauxilia.com/privacy-policy?__hstc=181257784.ff7e06784cd8dfcd424620280a8af09b.1581499076821.1618415342297.1618475974870.854&__hssc=181257784.17.1618475974870&__hsfp=1488147824">Privacy Policy</a>
  </div>
  <div class="item">
    <div class="sm-link" *ngIf="(headerFooter?.attributes.facebookLink) as facebookLink">
      <a [href]="facebookLink">
        <i class="fab fa-facebook"></i>
      </a>
    </div>

    <div class="sm-link" *ngIf="(headerFooter?.attributes.twitterLink) as twitterLink">
      <a [href]="twitterLink">
        <i class="fa6 fab fa-square-x-twitter"></i>
      </a>
    </div>

    <div class="sm-link" *ngIf="(headerFooter?.attributes.instagramLink) as instagramLink">
      <a [href]="instagramLink">
        <i class="fab fa-instagram"></i>
      </a>
    </div>

    <div class="sm-link" *ngIf="(headerFooter?.attributes.linkedInLink) as linkedInLink">
      <a [href]="linkedInLink">
        <i class="fab fa-linkedin"></i>
      </a>
    </div>
  </div>
</div>
