import {Injectable, Injector} from '@angular/core';
import {BaseModelService} from '../base.model.service';
import {ClientDonorTagManagerModel, TagManagerAssignTagModel} from '../../models/client/client.donor.tag.manager.model';
import {Paging} from '../../models/paging/paging.model';
import {Observable} from 'rxjs';
import {flatMap, map} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {PagingHelper} from '../helper/paging.helper';
import {TotalRowsModel} from '../../models/paging/totalrow.model';

@Injectable({
  providedIn: 'root'
})
export class DonorTagService extends BaseModelService<ClientDonorTagManagerModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }
  protected get controllerName(): string {
    return 'TagManager';
  }

  public createDonorsBatchId(filter?: Paging): Observable<string> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.post<any>(
          `${config.utilsURL}/${this.controllerName}/CreateBatch`,
          filter
        );
      })
    );
  }

  public assignTagsToDonors(batchId: string, tagAssignModel: TagManagerAssignTagModel): Observable<TagManagerAssignTagModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.post<TagManagerAssignTagModel>(
          `${config.utilsURL}/${this.controllerName}/AssignTags/${batchId}`,
          tagAssignModel
        );
      })
    );
  }

  public getManageDonors(batchId: string, paging?: Paging): Observable<ClientDonorTagManagerModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(paging);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<ClientDonorTagManagerModel[]>(
          `${config.utilsURL}/${this.controllerName}/Donors/${batchId}`,
          { params }
        );
      })
    );
  }

  public getTotalDonors(batchId: string, paging?: Paging): Observable<number> {
    const params: HttpParams = PagingHelper.getPagingParams(paging);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<TotalRowsModel>(
          `${config.utilsURL}/${this.controllerName}/DonorsTotal/${batchId}`,
          { params }
        );
      }),
      map(model => model.total)
    );
  }

  public getDonorsDistinct(batchId: string, paging?: Paging): Observable<number> {
    const params: HttpParams = PagingHelper.getPagingParams(paging);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<number>(
          `${config.utilsURL}/${this.controllerName}/DonorsDistinct/${batchId}`,
          { params }
        );
      })
    );
  }

  public deleteBatchId(batchId: string): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.delete<any>(
          `${config.utilsURL}/${this.controllerName}/DeleteBatch/${batchId}`
        );
      })
    );
  }

  public checkDonorsAreLoaded(batchId: string): Observable<boolean> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<boolean>(
          `${config.utilsURL}/${this.controllerName}/checkDonorsLoaded/${batchId}`
        );
      })
    );
  }
}
