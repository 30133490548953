import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import MjmlElementModel from '../../models/templates/mjml.element.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ThermometerBuilderModel} from '../../models/templates/thermometerBuilderModel';
import {TemplateManagementService} from '../../services/templates/template.management.service';
import {Subscription} from 'rxjs';
import {
  FULL_BAR_HEIGHT,
  FULL_THERMOMETER_HEIGHT,
  MIN_BAR_HEIGHT,
  MIN_THERMOMETER_HEIGHT,
  ZEROEVENT
} from '../../constants';
import {locale} from 'moment';

@Component({
  selector: 'app-thermometer',
  templateUrl: './thermometer.component.html',
  styleUrls: ['./thermometer.component.scss']
})
export class ThermometerComponent implements OnInit,  AfterViewInit, AfterContentChecked, OnDestroy, OnChanges {
  @Input() public entity: MjmlElementModel;
  @Input() public eventId: string;
  @Input() public size: string;
  @Input() public tms: TemplateManagementService;
  @Input() public form: FormGroup = this.formBuilder.group({
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]],
    borderColor: '#212121',
    containerFillColor: '#0064BE',
    containerGradientColor: '#000000',
    containerTextColor: '#000000',
    goal: ['0'],
    iteration_1: ['0'],
    iteration_2: ['0'],
    iteration_3: ['0'],
    alignment: 'vertical',
    textPlace: 'left',
    showGoal: true,
    showDonors: true,
    showDonations: true,
    showPercent: true,
    description: [''],
    showIterations: false,
    appearance: 'thermometer',
    fontSizeGoal: '14px',
    fontSizeIterations: '14px',
    fontSizeDescription: '14px',
    resize: 1
  });
  @ViewChild('thermometer') private thermometer: ElementRef;
  public percent: number = 0;
  public donors: string = '0';
  public donations: string = '0';
  public wrapperParam: DOMRect;
  public resize: string;
  public thermometerHeight: string = 'M89 378H65V394.111C51.5805 399.009 42 411.886 42 427C42 446.33 57.67 462 77 462C96.33 462 112 446.33 112 427C112 411.886 102.42 399.009 89 394.111V259Z';
  public barHeight: string = 'M67 445H127V446C127 452.627 121.627 458 115 458H79C72.3726 458 67 452.627 67 446V259Z';
  private subscription: Subscription = new Subscription();
  constructor(
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
  ) { }

  public ngOnInit(): void {
    this.getEntity;
  }

  public ngAfterViewInit(): void {
    this.wrapperParam = this.thermometer.nativeElement.getBoundingClientRect();
  }

  public get getTransform(): string {
    if (this.thermometer?.nativeElement?.offsetWidth) {
      if (this.thermometer?.nativeElement?.offsetWidth <= 300) {
        return 'scale(0.5)';
      }
      if (this.thermometer?.nativeElement?.offsetWidth <= 350) {
        return 'scale(0.6)';
      }
      if (this.thermometer?.nativeElement?.offsetWidth <= 460) {
        return 'scale(0.6)';
      }
    }
    return 'scale(1)';
  }

  public ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(): void {
    if (this.tms) {
      this.subscription.add(
        this.tms.useInTemplateEmitter.subscribe(() => {
          this.getFormValue();
        })
      );
    }
  }

  public get getGoal(): string {
    return Number(this.thermometerBuilderFormValues.goal).toLocaleString(locale('en-US'));
  }

  public get iteration_1(): string {
    return Number(this.thermometerBuilderFormValues.iteration_1).toLocaleString(locale('en-US'));
  }

  public get iteration_2(): string {
    return Number(this.thermometerBuilderFormValues.iteration_2).toLocaleString(locale('en-US'));
  }

  public get iteration_3(): string {
    return Number(this.thermometerBuilderFormValues.iteration_3).toLocaleString(locale('en-US'));
  }

  public get getEntity(): any {
    if (this.entity) {
      this.getFormValue();
      return this.entity.attributes;
    }
  }

  public getSize(): string {
    if (this.size) {
      this.resize = this.isHorizontal ? `rotate(90deg) scale(${this.size})` : `scale(${this.size})`;
    } else {
      this.resize = this.isHorizontal ? `rotate(90deg) scale(${this.thermometerBuilderFormValues.resize})` : `scale(${this.thermometerBuilderFormValues.resize})`;
    }
    return this.resize;
  }

  public get isTextOnRight(): boolean {
    if (this.entity) {
      return this.entity.attributes.textPlace === 'right';
    }
    return  this.thermometerBuilderFormValues.textPlace === 'right';
  }

  public get getRotateThermometerText1(): string {
    if (this.isHorizontal) {
      return 'rotate(-90,163,374)';
    } else {
      return 'rotate(0)';
    }
  }

  public get getRotateThermometerText2(): string {
    if (this.isHorizontal) {
      return 'rotate(-90,162,284)';
    } else {
      return 'rotate(0)';
    }
  }

  public get getRotateThermometerText3(): string {
    if (this.isHorizontal) {
      return 'rotate(-90,173,183)';
    } else {
      return 'rotate(0)';
    }
  }

  public get getRotateThermometerText4(): string {
    if (this.isHorizontal) {
      return 'rotate(-90,185,80)';
    } else {
      return 'rotate(0)';
    }
  }

  public get getRotateThermometerText5(): string {
    if (this.isHorizontal) {
      return 'rotate(-90,200,-25)';
    } else {
      return 'rotate(0)';
    }
  }

  public get getRotateBarText1(): string {
    if (this.isHorizontal) {
      return 'rotate(-90,217,446)';
    } else {
      return 'rotate(0)';
    }
  }

  public get getRotateBarText2(): string {
    if (this.isHorizontal) {
      return 'rotate(-90,217,338)';
    } else {
      return 'rotate(0)';
    }
  }

  public get getRotateBarText3(): string {
    if (this.isHorizontal) {
      return 'rotate(-90,228,220)';
    } else {
      return 'rotate(0)';
    }
  }

  public get getRotateBarText4(): string {
    if (this.isHorizontal) {
      return 'rotate(-90,239,102)';
    } else {
      return 'rotate(0)';
    }
  }

  public get getRotateBarText5(): string {
    if (this.isHorizontal) {
      return 'rotate(-90,250,-17)';
    } else {
      return 'rotate(0)';
    }
  }

  public get getRotateThermometerPercent(): string {
    if (this.isHorizontal) {
      return 'rotate(-90,20,390)';
    } else {
      return 'rotate(0)';
    }
  }

  public get getRotateBarPercent(): string {
    if (this.isHorizontal) {
      return 'rotate(-90,17,432)';
    } else {
      return 'rotate(0)';
    }
  }

  public get isHorizontal(): boolean {
    if (this.entity) {
      return this.entity.attributes.alignment === 'horizontal';
    }
    return this.thermometerBuilderFormValues.alignment === 'horizontal';
  }

  public get getDonation(): string {
    if (this.entity) {
      this.donations = this.entity.attributes.donations || '0';
      if (this.eventId !== ZEROEVENT) {
        this.getProgress();
      }
    }
    return Number(this.donations).toLocaleString(locale('en-US'));
  }

  public get getDonors(): string {
    if (this.entity) {
      return this.donors = this.entity.attributes.donors || '0';
    }
    return this.donors;
  }

  public get isThermometer(): boolean {
    return this.form.value.appearance === 'thermometer'
  }

  public get isShowIterations(): boolean {
    if (this.form.value.showIterations === 'true') {
      this.form.value.showIterations = true;
    } else if (this.form.value.showIterations === 'false') {
      this.form.value.showIterations = false;
    }
    return this.form.value.showIterations;
  }

  public get thermometerBuilderFormValues(): ThermometerBuilderModel {
      return this.form.value;
  }

  public getProgress(): void {
    if (this.entity.attributes.goal && this.entity.attributes.goal !== '0') {
      const diffMinMaxThermometer = MIN_THERMOMETER_HEIGHT - FULL_THERMOMETER_HEIGHT;
      const diffMinMaxBar = MIN_BAR_HEIGHT - FULL_BAR_HEIGHT;
      const goal = +this.entity.attributes.goal;
      const progressPercent = +this.donations / goal;
      this.percent = Math.round(progressPercent * 100);
      this.percent = this.percent > 100 ? 100 : this.percent;
      const progressThermometer = MIN_THERMOMETER_HEIGHT - ((this.percent / 100) * diffMinMaxThermometer);
      this.thermometerHeight = `M89 ${progressThermometer}H65V394.111C51.5805 399.009 42 411.886 42 427C42 446.33 57.67 462 77 462C96.33 462 112 446.33 112 427C112 411.886 102.42 399.009 89 394.111V259Z`;
      const progressBarHeight = MIN_BAR_HEIGHT - ((this.percent / 100) * diffMinMaxBar);
      this.barHeight = `M67 ${progressBarHeight + 5}H127V446C127 452.627 121.627 458 115 458H79C72.3726 458 67 452.627 67 446V259Z`;
    }
  }

  get getPercentParam(): string {
    if (this.isFontAndPercentPosition('22px', '24px')) {
      return '-20';
    }
    if (this.isFontAndPercentPosition('20px')) {
      return '-10';
    }
    if (this.isFontAndPercentPosition('18px')) {
      return '-5';
    }
    if (this.isFontAndPercentPosition('16px')) {
      return '-0';
    }
    if (this.thermometerBuilderFormValues?.fontSizeGoal &&
        (this.thermometerBuilderFormValues?.fontSizeGoal === '22px' ||
        this.thermometerBuilderFormValues?.fontSizeGoal === '24px') &&
        this.isTwoDigit()) {
      return '-7';
    }
    if (this.thermometerBuilderFormValues?.fontSizeGoal &&
      this.thermometerBuilderFormValues?.fontSizeGoal === '20px' &&
      this.isTwoDigit()) {
      return '0';
    }
    return '7';
  }

  private isFontAndPercentPosition(font: string, secondFont?: string): boolean {
    if (secondFont) {
      return this.thermometerBuilderFormValues?.fontSizeGoal &&
             (this.thermometerBuilderFormValues?.fontSizeGoal === font ||
              this.thermometerBuilderFormValues?.fontSizeGoal === secondFont) &&
              this.percent === 100
    }
    return this.thermometerBuilderFormValues?.fontSizeGoal &&
      this.thermometerBuilderFormValues?.fontSizeGoal === font &&
      this.percent === 100

  }

  private isTwoDigit(): boolean {
    return this.percent.toString().split('').length > 1
  }

  get getViewBoxSize(): string {
    if (this.isThermometer) {
      if (this.isFontAndPercentPosition('22px', '24px')) {
        return '-17 -100 350 650'
      }
      return '-5 -100 350 650'
    }
    if (this.isFontAndPercentPosition('22px', '24px')) {
      return '-17 -100 400 650'
    }
    return '-5 -100 400 650'

  }

  public get getThermometerHeight(): string {
    return this.thermometerHeight;
  }

  public get getBarHeight(): string {
    return this.barHeight;
  }

  private getFormValue(): void {
    if (this.entity.attributes.goal) {
      this.form.get('goal').setValue(this.entity.attributes.goal || '0');
      this.form.get('showIterations').setValue(this.entity.attributes.showIterations);
      this.form.get('iteration_1').setValue(this.entity.attributes.iteration_1);
      this.form.get('iteration_2').setValue(this.entity.attributes.iteration_2);
      this.form.get('iteration_3').setValue(this.entity.attributes.iteration_3);
      this.form.value.resize = this.entity.attributes.resize;
    }
    if (this.entity.attributes.containerTextColor) {
      this.form.get('paddingBottom').setValue(this.entity.attributes['padding-bottom']);
      this.form.get('paddingLeft').setValue(this.entity.attributes['padding-left']);
      this.form.get('paddingRight').setValue(this.entity.attributes['padding-right']);
      this.form.get('paddingTop').setValue(this.entity.attributes['padding-top']);
      this.form.get('borderColor').setValue(this.entity.attributes.borderColor);
      this.form.get('containerFillColor').setValue(this.entity.attributes.containerFillColor);
      this.form.get('containerGradientColor').setValue(this.entity.attributes.containerGradientColor);
      this.form.get('containerTextColor').setValue(this.entity.attributes.containerTextColor);
      if (this.entity.attributes.goal === 'null' || this.entity.attributes.goal === 'undefined') {
        this.form.get('goal').setValue('0');
      } else  {
        this.form.get('goal').setValue(this.entity.attributes.goal);
      }
      this.form.get('iteration_1').setValue(this.entity.attributes.iteration_1);
      this.form.get('iteration_2').setValue(this.entity.attributes.iteration_2);
      this.form.get('iteration_3').setValue(this.entity.attributes.iteration_3);
      this.form.get('alignment').setValue(this.entity.attributes.alignment);
      this.form.get('textPlace').setValue(this.entity.attributes.textPlace);
      this.form.get('showGoal').setValue(this.entity.attributes.showGoal);
      this.form.get('showDonors').setValue((typeof this.entity.attributes.showDonors === 'string') ? this.entity.attributes.showDonors === 'true' : this.entity.attributes.showDonors);
      this.form.get('showDonations').setValue((typeof this.entity.attributes.showDonations === 'string') ? this.entity.attributes.showDonations === 'true' : this.entity.attributes.showDonations);
      this.form.get('showPercent').setValue((typeof this.entity.attributes.showPercent === 'string') ? this.entity.attributes.showPercent === 'true' : this.entity.attributes.showPercent);
      this.form.get('description').setValue(this.entity.attributes.description);
      this.form.get('showIterations').setValue(this.entity.attributes.showIterations);
      this.form.get('appearance').setValue(this.entity.attributes.appearance);
      this.form.get('fontSizeGoal').setValue(this.entity.attributes.fontSizeGoal);
      this.form.get('fontSizeIterations').setValue(this.entity.attributes.fontSizeIterations);
      this.form.get('fontSizeDescription').setValue(this.entity.attributes.fontSizeDescription);
      this.form.get('resize').setValue(this.entity.attributes.resize);
      this.resize = `scale(${this.entity.attributes.resize})`;
    }
    this.cdRef.markForCheck();
  }
}
