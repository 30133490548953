import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilderStateService } from '../../../form-builder.state.service';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import { LABEL_ALIGN } from '../../../../../constants';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AlignmentType, FbElementModel, FbElementType } from '../../../../../models/form-builder/fb.template.model';
import { filter, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-address-options',
  templateUrl: './address-options.component.html',
  styleUrls: ['../../fb.page.scss']
})
export class AddressOptionsComponent implements OnInit, OnDestroy {
  @Input() public fbss: FormBuilderStateService;
  @Input() public type: FbElementType;
  public labelAlignmentOptions: FormElementDataModel[] = LABEL_ALIGN;

  public controlList: string[] = [
    'billingStreetAddress',
    'streetAddress2',
    'country',

    'areaDistrict',
    'cedex',
    'district',
    'emirate',
    'neighborhood',
    'oblast',
    'prefecture',
    'townland',

    'city',
    'cityCountry',
    'postTown',
    'suburb',
    'suburbCity',
    'villageTownshipCity',

    'state',
    'department',
    'doSi',
    'island',
    'parish',
    'province',

    'zipCode',
    'eirCode',
    'pinCode',
    'postalCode',
  ];

  public sublabels: {[key: string]: string} = {
    mainLabel: 'Billing Address',

    billingStreetAddress: 'Billing Street Address',
    streetAddress2: 'Street Address Line 2',
    country: 'Country',

    areaDistrict: 'Area / District',
    cedex: 'CEDEX',
    district: 'District',
    emirate: 'Emirate',
    neighborhood: 'Neighborhood',
    oblast: 'Oblast',
    prefecture: 'Prefecture',
    townland: 'Townland',

    city: 'City',
    cityCountry: 'City / Country',
    postTown: 'Post Town',
    suburb: 'Suburb',
    suburbCity: 'Suburb / City',
    villageTownshipCity: 'Village / Township / City',

    state: 'State',
    department: 'Department',
    doSi: 'Do / Si',
    island: 'Island',
    parish: 'Parish',
    province: 'Province',

    zipCode: 'Zip Code',
    eirCode: 'EIRcode',
    pinCode: 'PIN-Code',
    postalCode: 'Postal Code',
  };

  public optionsForm: FormGroup = this.formBuilder.group({
    mainLabel: '',
    mainLabelAlignment: null,
    required: true,
  });

  public isSetAsFormDefault: FormControl = this.formBuilder.control(false);

  private element: FbElementModel;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.controlList.forEach((controlName: string) => {
      this.optionsForm.addControl(controlName, this.formBuilder.control(''))
    });
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.fbss.activeElementSettings$.asObservable()
        .pipe(
          filter(value => value === this.type),
          tap(() => {
            this.element = this.fbss.template$.getValue().paymentPage.elements
              .find(({type}: FbElementModel) => type === this.type);
            this.setInitialValues();
          }),
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setInitialValues(): void {
    this.optionsForm.reset();
    const {
      mainLabel = 'Billing Address',
      mainLabelAlignment = AlignmentType.Top,
    }: {[key: string]: string} = this.element.attributes;
    this.formControl('mainLabel').setValue(mainLabel);
    this.formControl('mainLabelAlignment').setValue(mainLabelAlignment);
    this.formControl('required').setValue(!!this.element?.required)

    this.controlList.forEach((controlName: string) => {
      const controlValue = this.element.attributes[controlName] || this.sublabels[controlName];
      this.formControl(controlName).setValue(controlValue);
    })
  }

  public formControl(controlName: string): FormControl {
    return this.optionsForm.get(controlName) as FormControl;
  }

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }

  public optionChanged(controlName: string): void {
    let value = this.formControl(controlName).value;
    if (controlName !== 'mainLabel' && !value) {
      value = this.sublabels[controlName];
    }
    this.element.attributes[controlName] = value;
    this.fbss.updateAddress$.next();
    this.fbss.templateWasChanged();
  }

  public onLabelAlignmentChanged(): void {
    this.element.attributes.mainLabelAlignment = this.formControl('mainLabelAlignment').value;
    this.isSetAsFormDefault.value && this.setAsFormDefault();
    this.fbss.templateWasChanged();
  }

  public onIsSetAsFormDefaultChanged(): void {
    const next = !this.isSetAsFormDefault.value;
    this.isSetAsFormDefault.setValue(next);
    if (next) {
      this.setAsFormDefault();
      this.fbss.templateWasChanged();
    }
  }

  public setAsFormDefault(): void {
    this.fbss.setAsFormDefault(this.formControl('mainLabelAlignment').value);
  }
  public get required(): FormControl {
    return this.optionsForm.get('required') as FormControl;
  }
  public onRequiredStatusChanged(): void {
    const next = !this.required.value;
    this.required.setValue(next);
    this.element.required = next;
    this.fbss.templateWasChanged();
  }
}
