import { AfterViewChecked, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

const RADIUS = 88;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements AfterViewChecked {
  @Input()
  public progress: number;

  @ViewChild('progressValue')
  public progressValue: ElementRef;

  constructor(private renderer: Renderer2) {
  }

  public ngAfterViewChecked(): void {
    if (this.progressValue) {
      const dashoffset = CIRCUMFERENCE * (1 - this.progress);
      this.renderer.setStyle(this.progressValue.nativeElement, 'strokeDashoffset', dashoffset);
      this.renderer.setStyle(this.progressValue.nativeElement, 'strokeDasharray', CIRCUMFERENCE);
    }
  }

}
