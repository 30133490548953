import { Component, Input } from '@angular/core';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { SOCIAL_MEDIA_MJML_ICON_DEFAULT_COLOR } from '../../../../constants';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';

@Component({
  selector: 'app-sm-tool',
  templateUrl: './sm-tool.component.html',
  styleUrls: ['../img-tool.scss']
})
export class SmToolComponent {
  @Input() public column: MjmlElementModel;
  @Input() public entity: MjmlElementModel;
  @Input() public tms: TemplateManagementService;
  @Input() public landingPageView: boolean = false;

  constructor() {}

  public getContainerStyles(): any {
    const attributes = this.entity.attributes;
    return {
      'background-color': attributes['container-background-color'] || 'transparent',
      'padding-bottom': attributes['padding-bottom'] || '10px',
      'padding-left': attributes['padding-left'] || '25px',
      'padding-right': attributes['padding-right'] || '25px',
      'padding-top': attributes['padding-top'] || '10px'
    }
  }

  public get contentStyles(): any {
    const attributes = this.entity.attributes;
    const styles = {};
    const align = attributes['align'] || 'center';
    switch (align) {
      case 'center':
        styles['justify-content'] = 'center';
        break;
      case 'left':
        styles['justify-content'] = 'flex-start';
        break;
      case 'right':
        styles['justify-content'] = 'flex-end';
        break;
    }
    styles['color'] = attributes['color'] || '#333333';
    styles['font-family'] = attributes['font-family'] || 'Roboto';
    styles['font-size'] = attributes['font-size'] || '13px';
    styles['font-style'] = attributes['font-style'] || 'normal';
    styles['font-weight'] = attributes['font-weight'] || 'normal';
    styles['text-decoration'] = attributes['text-decoration'] || 'none';
    return styles;
  }

  public getImageStyles(child: MjmlElementModel): any {
    const attributes = this.entity.attributes;
    const childName = child.attributes['name'];
    return {
      'border-radius': attributes['border-radius'] || '3px',
      'background-color': child.attributes['background-color'] || SOCIAL_MEDIA_MJML_ICON_DEFAULT_COLOR[childName],
      'width': attributes['icon-size'] || '20px',
      'height': attributes['icon-size'] || '20px',
    };
  }

  public getChildContainerStyles(): any {
    const attributes = this.entity.attributes;
    return {
      padding: attributes['inner-padding'] || '4px'
    };
  }

  public get isEmpty(): boolean {
    return this.entity ? this.entity.children ? !this.entity.children.length : true : true;
  }

  public get isHorizontal(): boolean {
    const mode = this.entity.attributes['mode'] || 'horizontal';
    return mode === 'horizontal';
  }

  public get textStyle(): any {
    const attributes = this.entity.attributes;
    const color = attributes['color'] || '#333333';
    const fontFamily = attributes['font-family'] || 'Roboto';
    const fontSize = attributes['font-size'] || '13px';
    const fontStyle = attributes['font-style'] || 'normal';
    const fontWeight = attributes['font-weight'] || 'normal';
    const textDecoration = attributes['text-decoration'] || 'none';
    return {
      color,
      fontFamily,
      fontSize,
      fontStyle,
      fontWeight,
      textDecoration
    };
  }
}
