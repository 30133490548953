import {ChangeDetectorRef, Component, Input, Output, OnDestroy, OnInit, QueryList, ViewChildren, EventEmitter} from '@angular/core';
import {FormBuilderStateService} from '../../../form-builder.state.service';

import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FbElementModel, FbElementType} from '../../../../../models/form-builder/fb.template.model';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import {ClientAllocatedFundService} from '../../../../../services/client/client-allocated-fund.service';
import {Paging} from '../../../../../models/paging/paging.model';
import {SortOrder} from '../../../../../models/enum/sort.order';
import {ClientAllocatedFundModel} from '../../../../../models/client/client-allocated-fund.model';
import {FilterType} from '../../../../../models/enum/filter.type';
import {ClientIdStateService} from '../../../../../services/client.module.state/client.id.state.service';
import {
  DEFAULT_DISCLAIMER_CHECKBOX_TEXT, DEFAULT_DISCLAIMER_CHECKBOX_TEXT_SP,
  DEFAULT_DISCLAIMER_TEXT,
  DEFAULT_DISCLAIMER_TEXT_SP,
  TYPES_OF_DONATIONS
} from '../../../../../constants';
import {TypesOfDonationsEnum} from '../../../../../models/enum/types-of-donations.enum';
import {TagType} from '../../../../../models/enum/tag.type';
import {TagService} from '../../../../../services/tag/tag.service';
import {TagStatus} from '../../../../../models/enum/tag-status.type';
import {Filter} from '../../../../../models/paging/filter.model';
import TagModel from '../../../../../models/tag/tag.model';
import {MatDialog} from '@angular/material/dialog';
import {Subject, Subscription} from 'rxjs';
import {AdminConfirmationComponent} from '../../../../../routes/admin/admin-confirmation/admin-confirmation.component';
import {UtilsComponent} from '../../../../utils.component';
import {filter, tap} from 'rxjs/operators';
import {MatExpansionPanel} from '@angular/material/expansion';
import {InputComponent} from '../../../../form-elements/input/input.component';
import {TextareaComponent} from 'src/app/components/form-elements/textarea/textarea.component';
import {TranslateService} from '@ngx-translate/core';
import {EventDonationDisclaimerModel} from "../../../../../models/event/event-donation-disclaimer.model";

@Component({
  selector: 'app-donation-info-options',
  templateUrl: './donation-info-options.component.html',
  styleUrls: ['../../fb.page.scss']
})
export class DonationInfoOptionsComponent implements OnInit, OnDestroy {
  @Input() public fbss: FormBuilderStateService;
  @Output() public donationInfoChanged: EventEmitter<void> = new EventEmitter<void>();

  private subscription: Subscription = new Subscription();

  public optionsForm: FormGroup = this.formBuilder.group({
    text: '',
    amountToDonateText: this.translate.instant('Amount to donate *'),
    isAnonymous: true,
    isRecurring: true,
    isRecurringWithEndDate: true,
    allowWeekly: true,
    allowMonthly: true,
    allowYearly: true,
    isPledgeType: false,
    isHonorGift: true,
    isHonorGiftWithSendEmail: true,
    isDonationCost: true,
    allowOwnAmount: true,
    isInSupportOf: true,
    funds: [[]],
    typesOfDonations: 0,
    tiers: new FormArray([]),
    hasCustomDisclaimers: false,
    customDisclaimers: new FormArray([
    ]),
    disclaimerConfirmationText: '',
  });

  private standardTextVariable: string[] = [this.translate.instant('Amount to donate *'), this.translate.instant('Donation levels *')];

  public tagArrayLength: number = 0;

  private standardTearsImageUrl: string = '/assets/images/icon-donor-heart.png';

  private defaultTagSelectValue: FormElementDataModel = {label: '-', value: ''};

  public typesOfDonationsOptions: FormElementDataModel[] = TYPES_OF_DONATIONS;

  public tagSelectOptions: FormElementDataModel[] = [];

  public fundsOptions: FormElementDataModel[] = [];

  public clientID = this.clientIdStateService.selectedRelationshipClientId;
  public isAllowOwnAmountTiers: boolean = true;
  public isPledgeTypeTiers: boolean = false;
  public isRecurringTiers: boolean = true;
  public allowWeeklyTier: boolean = true;
  public allowMonthlyTier: boolean = true;
  public allowYearlyTier: boolean = true;
  public isEndDateTiers: boolean = true;
  public donationAmountsForm: FormArray = this.formBuilder.array([]);

  @ViewChildren('expansionPanelRef') private expansionPanelRef: QueryList<MatExpansionPanel>;

  @ViewChildren('tierNameRef') public tierNameRef: QueryList<InputComponent>;
  @ViewChildren('tierDescriptionRef') public tierDescriptionRef: QueryList<TextareaComponent>;
  @ViewChildren('tierPriceRef') public tierPriceRef: QueryList<InputComponent>;
  private tagStatusFilter: Filter[] = [
    {
      field: 'status',
      value: TagStatus.Active.toString(),
      type: FilterType.Equal,
    },
    {
      field: 'type',
      value: TagType.CustomTags.toString(),
      type: FilterType.Equal,
    }
  ];

  private allListFilterConfig: Filter[] = [{
    field: 'clientID',
    value: this.clientID,
    type: FilterType.Equal
  }];

  constructor(
    private formBuilder: FormBuilder,
    private clientAllocatedFundService: ClientAllocatedFundService,
    private clientIdStateService: ClientIdStateService,
    private clientDonorTagService: TagService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.getAllocatedFunds();
    this.getTags();
    this.subscription.add(
      this.fbss.isTiersEmpty
        .pipe(
          tap(index => {
            if (index || index === 0) {
              this.getTiersList.markAllAsTouched();
              this.expansionPanelRef.toArray()[index].open();
              this.showErrorFields(index);
            }
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.fbss.activeElementSettings$.asObservable()
        .pipe(
          filter(value => value === FbElementType.DonationInfo),
          tap(() => {
            this.setInitialValues();
          }),
        )
        .subscribe()
    );
  }
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private showErrorFields(i: number): void {
    const form = this.getTiersList.controls;
    if (this.getTiersList && form[i].get('name').invalid) {
      const input = this.tierNameRef.toArray()[i];
      if (input) {
        UtilsComponent.scrollIntoView(input.inputRef);
      }
    } else if (form[i].get('description').invalid) {
      const input = this.tierDescriptionRef.toArray()[i];
      if (input) {
        UtilsComponent.scrollIntoView(input.textArea);
      }
    } else if (form[i].get('ammount').invalid) {
      const input = this.tierPriceRef.toArray()[i];
      if (input) {
        UtilsComponent.scrollIntoView(input.inputRef);
      }
    }
  }

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }

  private getAllocatedFunds(): void {
    const paging: Paging = {
      sortField: 'Name',
      sortOrder: SortOrder.Ascending,
      filters: this.allListFilterConfig
    };
      this.clientAllocatedFundService.getModelList(paging).subscribe((funds: ClientAllocatedFundModel[]) => {
        this.fundsOptions.push(...funds.map(({ name, id }: ClientAllocatedFundModel) => ({ label: name, value: id })));
        this.cdr.markForCheck();
      });
  }

  public get getTiersList(): FormArray {
    return this.optionsForm.get('tiers') as FormArray;
  }

  public get isTiers(): boolean {
    return this.typesOfDonations.value === TypesOfDonationsEnum.Tier
  }

  public showCreateNewTag(i: number): void {
    this.toggleShowTag(i);
  }

  public addNewTagToList(tag: TagModel, i: number): void {
    const newTag: FormElementDataModel = {
      label: tag.name,
      value: tag.id
    };
    this.tagSelectOptions.splice(1, 0, newTag);
    const selectedTag = this.getTiersList.controls[i].get('selectedTag');
    this.cancelCreateNewTag(i);
    selectedTag.markAsTouched();
    selectedTag.setValue(newTag.value);
    this.changeTiersTemplate();
    this.fbss.tiersChanged$.next();
  }

  cancelTagCreation(tier: FormControl) {
    tier.get('isCreateTagShow').setValue(false)
  }

  private getTags(): void {
    const paging: Paging = {
      includeDependencies: true,
      sortField: 'name',
      sortOrder: SortOrder.Ascending,
      filters: [...this.allListFilterConfig, ...this.tagStatusFilter]
    };
    this.clientDonorTagService.getModelList(paging)
      .subscribe(tags => {
        this.tagArrayLength = tags.length;
        this.tagSelectOptions.push(...tags.map(({ name, id }: TagModel) => ({ label: name, value: id })));
        this.tagSelectOptions.unshift(this.defaultTagSelectValue);
      });
  }

  private setInitialValues(): void {
    UtilsComponent.cleanFormArray(this.donationAmountsForm);
    UtilsComponent.cleanFormArray(this.getTiersList);
    const {
      text,
      isAnonymous,
      amountToDonateText,
      isRecurring,
      allowWeekly,
      allowMonthly,
      allowYearly,
      isRecurringWithEndDate,
      isPledgeType,
      isHonorGift,
      isHonorGiftWithSendEmail,
      isDonationCost,
      isInSupportOf,
      donationAmounts = [15, 25, 40, 50],
      allowOwnAmount,
      funds,
      typesOfDonations,
      tiersOptions,
      customDisclaimers,
      disclaimerCheckBoxText,
    } = this.infoPage;
    const hasCustomDisclaimers: boolean = customDisclaimers?.length > 0;
    this.text.setValue(text);
    this.amountToDonateText.setValue(amountToDonateText);
    this.isAnonymous.setValue(!!isAnonymous);
    this.isRecurring.setValue(!!isRecurring);

    // Check for undefined value, likely from old templates.
    if (typeof allowWeekly === 'undefined') {
      this.allowWeekly.setValue(true);
    } else {
      this.allowWeekly.setValue(!!allowWeekly);
    }

    if (typeof allowMonthly === 'undefined') {
      this.allowMonthly.setValue(true);
    } else {
      this.allowMonthly.setValue(!!allowMonthly);
    }

    if (typeof allowYearly === 'undefined') {
      this.allowYearly.setValue(true);
    } else {
      this.allowYearly.setValue(!!allowYearly);
    }

    this.isInSupportOf.setValue(!!isInSupportOf);
    this.isRecurringWithEndDate.setValue(!!isRecurringWithEndDate);
    this.isPledgeType.setValue(!!isPledgeType);
    this.isHonorGift.setValue(!!isHonorGift);
    this.isHonorGiftWithSendEmail.setValue(!!isHonorGiftWithSendEmail);
    this.isDonationCost.setValue(!!isDonationCost);
    this.allowOwnAmount.setValue(!!allowOwnAmount);
    this.hasCustomDisclaimers.setValue(hasCustomDisclaimers);
    if(!!disclaimerCheckBoxText)
     this.disclaimerCheckBoxText.setValue(this.getCheckBoxDisclaimerText(disclaimerCheckBoxText));
    if(hasCustomDisclaimers) {
      customDisclaimers.forEach((dsc,index) => {
        this.customDisclaimers.push(this.disclaimersGroup);
        this.customDisclaimers.controls[index].get('content').setValue(this.getDisclaimerText(dsc.disclaimerText))
      })
    }
    if (typesOfDonations) {
      this.typesOfDonations.setValue(typesOfDonations);
    } else {
      this.typesOfDonations.setValue(0);
    }
    if (funds) {
      this.funds.setValue(JSON.parse(funds).map(item => item.value));
      this.cdr.markForCheck();
    }
    if (tiersOptions) {
      this.setTiersOptions(tiersOptions);
    }

    donationAmounts.forEach((amount) => {
      const control = this.formBuilder.group({
        value: [Number(amount).toFixed(2), [Validators.required, Validators.min(5)]],
      });
      this.donationAmountsForm.push(control);
    })
  }
  private getDisclaimerText(text:string):string {
    if(text.includes(DEFAULT_DISCLAIMER_TEXT)) {
      const translated:string = this.translate.instant(DEFAULT_DISCLAIMER_TEXT)
      text = text.replace(DEFAULT_DISCLAIMER_TEXT, translated);
    }
    if(text.includes(DEFAULT_DISCLAIMER_TEXT_SP)) {
      const translated:string = this.translate.instant(DEFAULT_DISCLAIMER_TEXT_SP)
      text = text.replace(DEFAULT_DISCLAIMER_TEXT_SP, translated);
    }
    return text;
  }
  private getCheckBoxDisclaimerText(text:string): string {
      if(text.includes(DEFAULT_DISCLAIMER_CHECKBOX_TEXT)) {
        const translated:string = this.translate.instant(DEFAULT_DISCLAIMER_CHECKBOX_TEXT)
        text = text.replace(DEFAULT_DISCLAIMER_CHECKBOX_TEXT, translated);
      }
      if(text.includes(DEFAULT_DISCLAIMER_CHECKBOX_TEXT_SP)) {
        const translated:string = this.translate.instant(DEFAULT_DISCLAIMER_CHECKBOX_TEXT_SP)
        text = text.replace(DEFAULT_DISCLAIMER_CHECKBOX_TEXT_SP, translated);
      }
      return text;
  }

  private setTiersOptions(tiersOptions: string): void {
    const parseOptions: [] = JSON.parse(tiersOptions);
    parseOptions.forEach((item:any) => {
      this.isAllowOwnAmountTiers = item.allowOwnAmount
      this.isEndDateTiers = item.isEndDate;
      this.isPledgeTypeTiers = item.isPledgeType;
      this.isRecurringTiers = item.isRecurring;

      if (typeof item.allowWeekly === 'undefined') {
        this.allowWeeklyTier = true;
      } else {
        this.allowWeeklyTier = item.allowWeekly;
      }
      if (typeof item.allowMonthly === 'undefined') {
        this.allowMonthlyTier = true;
      } else {
        this.allowMonthlyTier = item.allowMonthly;
      }
      if (typeof item.allowYearly === 'undefined') {
        this.allowYearlyTier = true;
      } else {
        this.allowYearlyTier = item.allowYearly;
      }

      const tier = this.getTiersGroup();
      tier.patchValue(item);
      this.getTiersList.push(tier);
    });
  }

  public addTier(): void {
    this.getTiersList.push(this.getTiersGroup());
    this.changeTiersTemplate();
    this.fbss.tiersChanged$.next();
  }

  public cancelCreateNewTag(i: number): void {
    this.toggleShowTag(i);
  }

  private toggleShowTag(i: number): void {
    const show = this.getTiersList.controls[i].get('isCreateTagShow');
    show.setValue(!show.value);
  }

  public getImgName(name: string): string {
    const urlArr = name.split('/');
    //take image name from url
    const imgUrlArr = urlArr[urlArr.length - 1].split('');
    //23 characters is the optimal number to fit without cutting the line
    if (imgUrlArr.length <= 23) {
      return imgUrlArr.join('');
    }
    //we take the difference a little less to insert '...' in center
    const difference = imgUrlArr.length - 20;
    imgUrlArr.splice(imgUrlArr.length / 4, difference, '...');
    return imgUrlArr.join('');
  }

  public changeTiersTemplate(): void {
    this.infoPage.tiersOptions = JSON.stringify(this.getTiersList.value);
    this.fbss.templateWasChanged();
    this.fbss.tiersChanged$.next();
  }

  public removeImg(i: number): void {
    const isRecurring = this.getTiersList.controls[i].get('imgUrl');
    isRecurring.setValue('');
    this.changeTiersTemplate();
  }

  public get isMoreThenOne(): boolean {
    return this.getTiersList.length > 1;
  }

  public deleteTiers(i: number): void {
    const config = {
      data: {
        title: `Would you like to delete tier?`
      }
    };
    this.subscription.add(
      this.dialog.open(AdminConfirmationComponent, config).afterClosed()
        .subscribe((res: boolean) => {
          if (res) {
            this.getTiersList.removeAt(i);
            this.changeTiersTemplate();
          }
        })
    );
  }

  public isTiersRecurring(i: number): void {
    const isRecurring = this.getTiersList.controls[i].get('isRecurring');
    isRecurring.setValue(!isRecurring.value);
    this.changeTiersTemplate();
  }

  public getBgImg(i: number): string {
    const imgUrl = this.getTiersList.controls[i].get('imgUrl').value;
    if (!imgUrl) {
      return null;
    }
    return `url(${imgUrl}) no-repeat center / contain`;
  }

  private getTiersGroup(): FormGroup {
    return this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(75)]],
      description: ['', [Validators.required, Validators.maxLength(150)]],
      ammount: ['', [Validators.required, Validators.min(5)]],
      imgUrl: [''],
      isTier: [true],
      isRecurring: [this.isRecurringTiers],
      allowWeekly: [this.allowWeeklyTier],
      allowMonthly: [this.allowMonthlyTier],
      allowYearly: [this.allowYearlyTier],
      isCreateTagShow: [false],
      recurring: [false],
      selectedTag: [''],
      allowOwnAmount: [this.isAllowOwnAmountTiers],
      isPledgeType: [this.isPledgeTypeTiers],
      isEndDate: [this.isEndDateTiers]
    })
  }

  private get disclaimersGroup(): FormGroup {
    return this.formBuilder.group({
      content: [this.translate.instant(DEFAULT_DISCLAIMER_TEXT)]
    })
  }

  public get typesOfDonations(): FormControl {
    return this.optionsForm.get('typesOfDonations') as FormControl;
  }

  public get text(): FormControl {
    return this.optionsForm.get('text') as FormControl;
  }

  public get amountToDonateText(): FormControl {
    return this.optionsForm.get('amountToDonateText') as FormControl;
  }

  public get isAnonymous(): FormControl {
    return this.optionsForm.get('isAnonymous') as FormControl;
  }

  public get isInSupportOf(): FormControl {
    return this.optionsForm.get('isInSupportOf') as FormControl;
  }

  public get isRecurring(): FormControl {
    return this.optionsForm.get('isRecurring') as FormControl;
  }

  public get allowWeekly(): FormControl {
    return this.optionsForm.get('allowWeekly') as FormControl;
  }

  public get allowMonthly(): FormControl {
    return this.optionsForm.get('allowMonthly') as FormControl;
  }

  public get allowYearly(): FormControl {
    return this.optionsForm.get('allowYearly') as FormControl;
  }

  public get isRecurringWithEndDate(): FormControl {
    return this.optionsForm.get('isRecurringWithEndDate') as FormControl;
  }

  public get isPledgeType(): FormControl {
    return this.optionsForm.get('isPledgeType') as FormControl;
  }

  public get isHonorGift(): FormControl {
    return this.optionsForm.get('isHonorGift') as FormControl;
  }

  public get isHonorGiftWithSendEmail(): FormControl {
    return this.optionsForm.get('isHonorGiftWithSendEmail') as FormControl;
  }

  public get isDonationCost(): FormControl {
    return this.optionsForm.get('isDonationCost') as FormControl;
  }

  public get funds(): FormControl {
    return this.optionsForm.get('funds') as FormControl;
  }

  public get allowOwnAmount(): FormControl {
    return this.optionsForm.get('allowOwnAmount') as FormControl;
  }

  public get hasCustomDisclaimers(): FormControl {
    return this.optionsForm.get('hasCustomDisclaimers') as FormControl;
  }
  public get disclaimerCheckBoxText(): FormControl {
    return this.optionsForm.get('disclaimerConfirmationText') as FormControl;
  }


  public onTextChanged(): void {
    this.infoPage.text = this.text.value;
    this.infoPage.amountToDonateText = this.amountToDonateText.value;
    this.fbss.templateWasChanged();
  }
  handleTierCheckboxState(controlName: string) {
    const paymentFormTiers = this.fbss.paymentForm.get('tiers') as FormArray;
    const referenceCopy = paymentFormTiers.controls.slice() as FormControl[];
    this.getTiersList.controls.forEach((tier,index) => {
      tier.get('recurring').setValue(referenceCopy[index].get('recurring').value)
      const control = tier.get(controlName)
      const next = !control.value;
      if(controlName === 'allowOwnAmount')
        this.isAllowOwnAmountTiers = next;
      if(controlName === 'isPledgeType')
        this.isPledgeTypeTiers = next;
      if(controlName === 'isRecurring')
        this.isRecurringTiers = next;
      if(controlName === 'isEndDate')
        this.isEndDateTiers = next;

      if (controlName === 'allowWeekly') {
        this.allowWeeklyTier = next;
      }
      if (controlName === 'allowMonthly') {
        this.allowMonthlyTier = next;
      }
      if (controlName === 'allowYearly') {
        this.allowYearlyTier = next;
      }

      control.setValue(next);
    })

    this.changeTiersTemplate();
  }
  public get customDisclaimers(): FormArray {
    return this.optionsForm.get('customDisclaimers') as FormArray;
  }

  public handleCheckboxState(controlName: string): void {
    const control = this.optionsForm.get(controlName);
    const next = !control.value;
    control.setValue(next);
    this.infoPage[controlName] = next;
    this.fbss.templateWasChanged();

    if (controlName === 'isPledgeType') {
      this.fbss.isPledgeType.next(next);
    }
    if(controlName === 'hasCustomDisclaimers') {
     if(next) {
       this.addDisclaimer();
       this.disclaimerCheckBoxText.setValue(this.translate.instant(DEFAULT_DISCLAIMER_CHECKBOX_TEXT))
     }
     if(!next) this.removeDisclaimers();
    }

    if (controlName === 'isInSupportOf' || controlName === 'isHonorGift') {
      this.fbss.inSupportOfSubject$.next();
    }

    this.donationInfoChanged.emit();
  }
 private removeDisclaimers() {
   this.infoPage.customDisclaimers = [];
   this.customDisclaimers.clear();
   this.fbss.templateWasChanged();
 }
  public onDonationTypesChanged(value: number): void {
    this.infoPage.typesOfDonations = this.typesOfDonations.value;
    this.amountToDonateText.setValue(this.standardTextVariable[value]);
    this.infoPage.amountToDonateText = this.amountToDonateText.value;
    this.fbss.paymentForm.get('ammount').reset();
     const formTiers = this.fbss.paymentForm.get('tiers') as FormArray;
     formTiers.controls.forEach(tier => {
       if(value === TypesOfDonationsEnum.Tier)
          tier.get('ammount').setValidators([Validators.required, Validators.min(5)]);
       if(value === TypesOfDonationsEnum.Standard)
          tier.get('ammount').clearValidators();
     })
    this.fbss.templateWasChanged();
  }

  public setFunds(value): void {
    const funds = this.fundsOptions.filter(fund => value.indexOf(fund.value) !== -1);
    this.infoPage.funds = JSON.stringify(funds);
    this.fbss.templateWasChanged();
    this.fbss.fundsChanged.next()
  }

  public updateDonationAmounts(): void {
    if (this.donationAmountsForm.invalid) {
      return;
    }
    this.infoPage.donationAmounts = this.donationAmountsForm.value
      .map(control => Number.parseFloat(control.value))
      .sort((a, b) => a - b);
    this.fbss.templateWasChanged();
  }

  public removeControl(i): void {
    this.donationAmountsForm.removeAt(i);
    this.updateDonationAmounts();
  }

  public addControl(): void {
    const control = this.formBuilder.group({
      value: [null, [Validators.required, Validators.min(5)]],
    });
    this.donationAmountsForm.push(control);
  }

  private get infoPage(): FbElementModel {
    return this.fbss.template$.getValue().infoPage.elements.find(element => element.type === FbElementType.DonationInfo);
  }

  public addDisclaimer():void {
    if(!this.infoPage.customDisclaimers)  this.infoPage.customDisclaimers = [];
    this.infoPage.customDisclaimers.push({disclaimerText: DEFAULT_DISCLAIMER_TEXT});
    this.customDisclaimers.push(this.disclaimersGroup);
    this.fbss.disclaimersChanged$.next();
    this.fbss.templateWasChanged();

    setTimeout(() => {
      const container = document.getElementById('scroll');
      const element = document.getElementById('addDisclaimer');

      if (container && element) {
        const containerHeight = container.offsetHeight;
        const elementOffsetTop = element.offsetTop;

        container.scrollTop = elementOffsetTop + containerHeight;
      }
    }, 200);
  }

  public handleDisclaimerChange(isCheckBoxText: boolean) {
    if(isCheckBoxText) {
      this.infoPage.disclaimerCheckBoxText = this.disclaimerCheckBoxText.value;
    } else {
      this.customDisclaimers.controls.forEach((ctrl, index) => {
        this.infoPage.customDisclaimers[index].disclaimerText = ctrl.get('content').value
      })
      this.fbss.disclaimersChanged$.next();
    }

    this.fbss.templateWasChanged();
  }
  public getIconStyle(iconType: string): {[key: string]: string} {
    switch (iconType) {
      case 'plus':
      case 'minusT':
        return {
          'position': 'absolute',
          'cursor': 'pointer',
          'bottom': '-5px',
          'left': '0'
        }
      case 'minusB':
        return {
          'position': 'absolute',
          'cursor': 'pointer',
          'bottom': '-5px',
          'left': '19px'
        }
    }
  }

  public deleteDisclaimer(index: number) {
    this.customDisclaimers.controls.splice(index, 1);
    this.infoPage.customDisclaimers?.splice(index, 1);
    this.fbss.disclaimersChanged$.next();
    this.fbss.templateWasChanged();
  }
}
