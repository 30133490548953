export enum EventGoalType {
  NoGoal = 0,
  RaiseDonation = 1,
  RepeatDonor = 2,
  NumberAttendees = 3,
  EventRevenue = 4,
  TicketSaleIncrease = 5,
  TicketSaleTotalNumber = 6,
  GrossRevenue,
  Revenue,
  DonationRevenue
}
