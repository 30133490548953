import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { flatMap, mergeMap } from "rxjs/operators";
import { P2PSettingsModel } from "src/app/models/p2p/p2p-settings.model";
import { BaseModelService } from "../base.model.service";

@Injectable({
    providedIn: 'root'
})
export class P2PSettingsService extends BaseModelService<P2PSettingsModel>  {

  protected get controllerName(): string {
    return 'P2PSettings';
  }


  public getByEventId(eventId: string, dependencies:boolean = true): Observable<P2PSettingsModel> {
    if(!eventId) return of(null);

    return this.configService.loadConfig()
      .pipe(
        flatMap(config => {
          const url = `${config.apiURL}/${this.controllerName}/getByEventId/${eventId}`;
          return this.http.get<P2PSettingsModel>(url, { params: new HttpParams().set('includeDependencies', dependencies.toString()) });
        })
      )
  }

  public token(eventId: string): Observable<string> {
    if(!eventId) throw new Error("Event Id was not provided");

    return this.configService.loadConfig()
      .pipe(
        mergeMap(config => {
          const params: HttpParams = new HttpParams().append("eventId", eventId);
          return this.http.get(`${config.apiURL}/${this.controllerName}/token`, {params, responseType: "text"})
        })
      )
  }
}
