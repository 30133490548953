import EventModel from "./event.model";
import TicketPackageModel from "./ticket.package.model";

export interface EventCouponModel {
  id?: string;
  active?: boolean;
  couponCode?: string;
  couponValue?: number;
  couponType?: CouponValue;
  eventID?: string;
  couponStatus?: CouponStatus;
  limit?: number;
  used?: number;
  startDate?: string | Date;
  endDate?: string | Date;
  ticketPackages?: TicketPackageModel[];
  event?: EventModel;
  isDeleted: boolean;
}

export enum CouponValue {
  Percent = 1,
  Dollar = 2
}

export enum CouponStatus {
  Inactive = 0,
  Active = 1
}
