import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PaymentMethodType } from 'src/app/models/enum/payment.method.type';
import { PaymentServiceType } from 'src/app/models/enum/payment.service.type';
import FbTemplateModel from 'src/app/models/form-builder/fb.template.model';
import Decimal from 'decimal.js'

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss']
})
export class PaymentSummaryComponent implements OnInit, OnChanges {
  @Input() fbTemplate: FbTemplateModel;
  @Input() paymentSummarySettings: PaymentSummarySettings;

  public result: PaymentSummaryResult;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateFee();
  }

  ngOnInit(): void {
    if(!this.paymentSummarySettings) throw new Error("Payment summary settings are required");
    if(!this.fbTemplate) throw new Error("Template is required");
    
  }

  get total(): number {
    if(this.paymentSummarySettings.amounts?.ticketsAmount?.amount && this.paymentSummarySettings.isRecurring) return this.result.tickets.total;
    return this.result.total;
  }

  private calculateFee(): void {
    const calculator: IFeeCalculator = this.feeCalculatorFactory();
    this.result = calculator.calculate(this.paymentSummarySettings)
  }

  private feeCalculatorFactory(): IFeeCalculator {
    switch(this.paymentSummarySettings.paymentServiceType) {
      case PaymentServiceType.Stripe:
        return new StripeACHFeeCalculator();
    }
  }
}

export type PaymentSummaryAmounts = {
  donationAmount: ConstituentSettings;
  ticketsAmount?: ConstituentSettings | null;
} | {
  donationAmount?: ConstituentSettings | null;
  ticketsAmount: ConstituentSettings ;
}

export interface ConstituentSettings {
  amount: number,
  includeFee: boolean
}

export interface TierSettings {
  tiers: TierModel[],
  customDonation: TiersCustomDonationModel,
  includeFee: boolean
}

export interface TierModel {
  name: string,
  amount: number,
  isRecurring: boolean
}

export interface TiersCustomDonationModel {
  amount: number,
  isRecurring: boolean
}

export interface PaymentSummarySettings {
  amounts: PaymentSummaryAmounts,
  tierSettings?: TierSettings
  paymentServiceType: PaymentServiceType,
  paymentMethodType: PaymentMethodType,
  applicationFee?: FeeSettings,
  transactionFee?: FeeSettings,
  isRecurring?: boolean,
}

export interface FeeSettings {
  feePercentage: number;
  fee: number;
}

interface IFeeCalculator {
  calculate(paymentSummarySettings: PaymentSummarySettings): PaymentSummaryResult;
}

export interface PaymentSummaryResult {
  tickets?: ConstituentSummary | null,
  donation?: ConstituentSummary | null,
  total: number,
  totalFee: number,
  appFee: number,
  tiersResult?: TiersResult
}

export interface ConstituentSummary {
  total: number,
  fee: number,
  appFee?: number
}

export interface TiersResult {
  tiers: TierResultModel[],
  customDonation: TiersCustomDonationResultModel
}

export interface TierResultModel {
  amount: number,
  name: string,
  total: number,
  fee: number,
  isRecurring: boolean
}

export interface TiersCustomDonationResultModel {
  amount: number,
  total: number,
  fee: number,
  isRecurring: boolean
} 

class StripeACHFeeCalculator implements IFeeCalculator {
  calculate(paymentSummarySettings: PaymentSummarySettings): PaymentSummaryResult {
    const ticketsAmount: number = paymentSummarySettings.amounts.ticketsAmount?.amount || 0;
    const donationAmount: number = paymentSummarySettings.amounts.donationAmount?.amount || 0;
    const applicationFeePercentage: Decimal = new Decimal(paymentSummarySettings.applicationFee.feePercentage);
    const transactionFeePercentage: Decimal = new Decimal(paymentSummarySettings.transactionFee.feePercentage);
    const transactionFee: Decimal = new Decimal(paymentSummarySettings.transactionFee?.fee || 0);

    if(!!ticketsAmount && !!donationAmount) {
      const amountsTotal: Decimal = new Decimal(ticketsAmount).add(donationAmount);
      const appFeeTotal: Decimal = this.calculateAppFeeTotal(amountsTotal, applicationFeePercentage);

      if(!paymentSummarySettings.amounts.ticketsAmount.includeFee && !paymentSummarySettings.amounts.donationAmount.includeFee) {
        return {
          total: amountsTotal.toNumber(),
          tickets: { total: ticketsAmount, fee: 0 },
          donation: { total: donationAmount, fee: 0 },
          totalFee: 0,
          appFee: appFeeTotal.toNumber(),
          tiersResult: this.calculateTiers(paymentSummarySettings)
        }
      }
      if(paymentSummarySettings.amounts.ticketsAmount.includeFee && !paymentSummarySettings.amounts.donationAmount.includeFee) {
        const ticketsAppFeeTotal: Decimal = this.calculateAppFeeTotal(new Decimal(ticketsAmount), applicationFeePercentage);
        const ticketsTransactionFeeTotal: Decimal = this.calculateTransactionFeeTotal(new Decimal(ticketsAmount), transactionFee, transactionFeePercentage, ticketsAppFeeTotal);
        const ticketsFeeTotal: Decimal = ticketsAppFeeTotal.add(ticketsTransactionFeeTotal);
        const ticketsTotal: Decimal = new Decimal(ticketsAmount).add(ticketsFeeTotal);
        const total: Decimal = ticketsTotal.add(donationAmount);

        return {
          donation: { total: donationAmount, fee: 0 },
          tickets: { total: ticketsTotal.toNumber(), fee: ticketsFeeTotal.toNumber() },
          total: total.toNumber(),
          totalFee: ticketsFeeTotal.toNumber(),
          appFee: ticketsAppFeeTotal.toNumber(),
          tiersResult: this.calculateTiers(paymentSummarySettings)
        }
      } 
      if(!paymentSummarySettings.amounts.ticketsAmount.includeFee && paymentSummarySettings.amounts.donationAmount.includeFee) {
        const donationAppFeeTotal: Decimal = new Decimal(donationAmount).times(applicationFeePercentage).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);
        const donationTransactionFeeTotal: Decimal = this.calculateTransactionFeeTotal(new Decimal(donationAmount), transactionFee, transactionFeePercentage, donationAppFeeTotal);
        const donationFeeTotal: Decimal = donationAppFeeTotal.add(donationTransactionFeeTotal);
        const donationTotal: Decimal = new Decimal(donationAmount).add(donationFeeTotal);
        const total: Decimal = donationTotal.add(ticketsAmount);

        return {
          donation: {total: total.toNumber(), fee: donationFeeTotal.toNumber() },
          tickets: { total: ticketsAmount, fee: 0 },
          total: total.toNumber(),
          totalFee: donationFeeTotal.toNumber(),
          appFee: donationAppFeeTotal.toNumber(),
          tiersResult: this.calculateTiers(paymentSummarySettings)
        }
      }

      const transactionFeeTotal: Decimal = this.calculateTransactionFeeTotal(amountsTotal, transactionFee, transactionFeePercentage, appFeeTotal);
      const feeTotal: Decimal = appFeeTotal.add(transactionFeeTotal);
      const total: Decimal = amountsTotal.add(feeTotal);

      const donationAppFee: Decimal = this.calculateAppFeeTotal(new Decimal(donationAmount), applicationFeePercentage);
      const donationFee: Decimal = this.calculateElementTotalFee(new Decimal(donationAmount), applicationFeePercentage, transactionFeePercentage, transactionFee);
      const ticketsAppFee: Decimal = this.calculateAppFeeTotal(new Decimal(ticketsAmount), applicationFeePercentage);
      const ticketsFee: Decimal = new Decimal(feeTotal).minus(donationFee);

      const ticketsTotal: Decimal = new Decimal(ticketsAmount).add(ticketsFee).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);
      const donationTotal: Decimal = new Decimal(donationFee).add(donationAmount).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);

      return { 
        tickets: { total: ticketsTotal.toNumber(), fee: ticketsFee.toDecimalPlaces(2).toNumber(), appFee: ticketsAppFee.toNumber() }, 
        donation: { total: donationTotal.toNumber(), fee: donationFee.toDecimalPlaces(2).toNumber(), appFee: donationAppFee.toNumber() }, 
        total: total.toNumber(),
        totalFee: feeTotal.toNumber(),
        appFee: appFeeTotal.toNumber(),
        tiersResult: this.calculateTiers(paymentSummarySettings)
      };
    } else {
      const constituentSettings: ConstituentSettings = paymentSummarySettings.amounts.ticketsAmount || paymentSummarySettings.amounts.donationAmount
      const constituentAmount: number = constituentSettings?.amount || 0;

      if(!constituentSettings.includeFee) {
        return {
          tickets: {total: ticketsAmount || 0, fee: 0},
          donation: {total: ticketsAmount || 0, fee: 0},
          total: constituentAmount,
          totalFee: 0,
          appFee: this.calculateAppFeeTotal(new Decimal(constituentAmount), applicationFeePercentage).toNumber(),
          tiersResult: this.calculateTiers(paymentSummarySettings)
        }
      }

      const appFeeTotal: Decimal = this.calculateAppFeeTotal(new Decimal(constituentAmount), applicationFeePercentage)
      const transactionFeeTotal: Decimal = this.calculateTransactionFeeTotal(new Decimal(constituentAmount), transactionFee, transactionFeePercentage, appFeeTotal);
      const paymentFeeTotal: Decimal = appFeeTotal.add(transactionFeeTotal);
      const total: Decimal = new Decimal(constituentAmount).add(paymentFeeTotal);

      let tickets: ConstituentSummary = null;
      let donation: ConstituentSummary = null;

      if(ticketsAmount) {
        tickets = { total: total.toNumber(), fee: paymentFeeTotal.toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN).toNumber() }
        donation = { total: 0, fee: 0 }
      } else {
        donation = { total: total.toNumber(), fee: paymentFeeTotal.toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN).toNumber() }
        tickets = { total: 0, fee: 0 }
      }
      
      return {
        tickets,
        donation,
        total: total.toNumber(),
        totalFee: paymentFeeTotal.toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN).toNumber(),
        appFee: appFeeTotal.toNumber(),
        tiersResult: this.calculateTiers(paymentSummarySettings)
      }
    }
  }

  private calculateAppFeeTotal(amount: Decimal, applicationFeePercentage: Decimal): Decimal {
    return amount.times(applicationFeePercentage).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);
  }

  private calculateTransactionFeeTotal(amount: Decimal, transactionFee: Decimal, transactionFeePercentage: Decimal, appFeeTotal: Decimal): Decimal {
    let transactionFeeTotal: Decimal = new Decimal(appFeeTotal.add(amount)).dividedBy(new Decimal(1).minus(transactionFeePercentage)).minus(amount).minus(appFeeTotal).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);
    transactionFeeTotal = this.recalculateTransactionsFeeFloorAndCap(transactionFeeTotal);
    if(transactionFee.greaterThan("0")) {
      transactionFeeTotal = new Decimal(appFeeTotal.add(amount).add(transactionFee)).dividedBy(new Decimal(1).minus(transactionFeePercentage)).minus(amount).minus(appFeeTotal).minus(transactionFee).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN);
      transactionFeeTotal = this.recalculateTransactionsFeeFloorAndCap(transactionFeeTotal);
      transactionFeeTotal = transactionFeeTotal.add(transactionFee);
    }
    return transactionFeeTotal;
  }

  private recalculateTransactionsFeeFloorAndCap(transactionFee: Decimal): Decimal {
    if(transactionFee.greaterThan("5")) {
      return new Decimal("5");
    }
    if(transactionFee.lessThan(0.01)) {
      return new Decimal("0.01");
    }
    return transactionFee;
  }

  private calculateElementTotalFee(amount: Decimal, applicationFeePercentage: Decimal, transactionFeePercentage: Decimal, transactionFee: Decimal): Decimal {
    const appFeeTotal: Decimal = this.calculateAppFeeTotal(amount, applicationFeePercentage);
    const transactionFeeTotal: Decimal = this.calculateTransactionFeeTotal(amount, transactionFee, transactionFeePercentage, appFeeTotal)
    return appFeeTotal.add(transactionFeeTotal);
  }

  private calculateTiers(paymentSummarySettings: PaymentSummarySettings): TiersResult {
    if(!paymentSummarySettings.tierSettings) return null;
    const tiersCustomDonationSettings: TiersCustomDonationModel = paymentSummarySettings.tierSettings?.customDonation || null;
    if(!paymentSummarySettings.tierSettings.includeFee) {
      const tiers: TierResultModel[] = paymentSummarySettings.tierSettings.tiers.map((tier: TierModel) => {
        return {
          amount: tier.amount,
          name: tier.name,
          total: tier.amount,
          fee: 0,
          isRecurring: tier.isRecurring,
        }
      })
      let customDonation: TiersCustomDonationResultModel = null;
      if(tiersCustomDonationSettings) {
        customDonation = {
          amount: tiersCustomDonationSettings.amount,
          fee: 0,
          isRecurring: tiersCustomDonationSettings.isRecurring,
          total: tiersCustomDonationSettings.amount
        }
      }
      return { tiers, customDonation }
    }
    
    const tiers: TierResultModel[] =  paymentSummarySettings.tierSettings.tiers.map((tier: TierModel) => {
      const donationFee: Decimal = this.calculateElementTotalFee(new Decimal(tier.amount), new Decimal(paymentSummarySettings.applicationFee.feePercentage), new Decimal(paymentSummarySettings.transactionFee.feePercentage), new Decimal(paymentSummarySettings.transactionFee.fee));
      return {
        amount: tier.amount,
        name: tier.name,
        total: new Decimal(tier.amount).add(donationFee).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN).toNumber(),
        fee: donationFee.toNumber(),
        isRecurring: tier.isRecurring,
      }
    })
    let customDonation: TiersCustomDonationResultModel = null;
    if(tiersCustomDonationSettings) {
      const donationFee: Decimal = this.calculateElementTotalFee(new Decimal(new Decimal(tiersCustomDonationSettings.amount)), new Decimal(paymentSummarySettings.applicationFee.feePercentage), new Decimal(paymentSummarySettings.transactionFee.feePercentage), new Decimal(paymentSummarySettings.transactionFee.fee));
      customDonation = {
        amount: tiersCustomDonationSettings.amount,
        fee: donationFee.toNumber(),
        isRecurring: tiersCustomDonationSettings.isRecurring,
        total: new Decimal(tiersCustomDonationSettings.amount).add(donationFee).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN).toNumber(),
      }
    }
    return { tiers, customDonation }
  }
}
