import { Injectable, OnDestroy } from "@angular/core";
import { PaymentMethod, PaymentMethodResult, SetupIntentResult, Stripe, loadStripe } from "@stripe/stripe-js";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject, Subscription, from, of } from "rxjs";
import { first, map, switchMap, tap } from "rxjs/operators";
import { MerchantModel } from "src/app/models/payments/merchant.model";
import { StripeIntentModel } from "src/app/models/payments/stripe.intent.model";
import { MerchantService } from "src/app/services/payments/merchant.service";
import { environment } from "src/environments/environment";
import { BaseStripeService } from "./base-stripe.service";
import { StripeACHFormStateService } from "src/app/services/donation-forms/stripe-ach-form-state.service";
import { StripeAchDonorDataModel } from "src/app/models/payments/stripe-ach-donor-data.model";
import { UtilsComponent } from "../utils.component";

@Injectable()
export class StripeACHService extends BaseStripeService implements OnDestroy {
  private stripe: Stripe;
  private subscription: Subscription = new Subscription();
  private clientId: string;
  public microdepositLink$: Subject<string> = new Subject();
  public microdepositVerificationHash: string;
  private donorData: StripeAchDonorDataModel;
  public usBankAccounts: PaymentMethod.UsBankAccount[] = [];

  constructor(
    private merchantService: MerchantService,
    toastrService: ToastrService,
    private stripeAChFormStateService: StripeACHFormStateService
  ) {
    super(toastrService)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public configureStripe(clientId: string): void {
  if(!clientId) throw new Error("Client ID must not be null")

  this.clientId = clientId;

  this.subscription.add(
    this.merchantService.getModelByClientId(clientId)
      .pipe(
        first(),
        map((merchant: MerchantModel) => merchant.accountId),
        switchMap((stripeAccountId: string) => {
          return from(loadStripe(environment.stripeApiKey, {stripeAccount: stripeAccountId}))
        }),
        tap((stripe: Stripe) => this.stripe = stripe),
      )
      .subscribe(() => this.stripeAChFormStateService.create(this.stripe))
    )
  }

  public startStripeACHPaymentRequest(donorData: StripeAchDonorDataModel, amount: number, errorCallback: (err) => void): void {
    this.subscription.add(
      this.getOrCreatePaymentMethod(donorData)
        .pipe(
          switchMap((res: string) => this.createPaymentIntent(donorData.email, this.clientId, amount, res)),
          switchMap((res: StripeIntentModel) => this.confirmUsBankAccountSetup(res))
        )
        .subscribe({
          next: (res: SetupIntentResult) => this.checkStripeACHPaymentIntentResponse(res),
          error: (err) => errorCallback(err)
        })
    )
  }

  public createPaymentMethodObj(donorData: StripeAchDonorDataModel): Observable<PaymentMethodResult> {
    this.stripeAChFormStateService.elements.submit();
    return from(this.stripe.createPaymentMethod({
      elements: this.stripeAChFormStateService.elements,
      params: {
        billing_details: {
          name: donorData.accountHolderName,
          email: donorData.email,
          phone: donorData.phone,
          address: {
            city: donorData.city,
            country: donorData.country,
            state: donorData.state,
            postal_code: donorData.postalCode,
            line1: donorData.street1,
            line2: donorData.street2
          },
        }
      }
    }))
    .pipe(
      tap((res: PaymentMethodResult) => {
        if(!!res.error) throw res.error;
        this.donorData = donorData;
        this.paymentMethod = res.paymentMethod.id;
      })
    )
  }

  private getOrCreatePaymentMethod(donorData: StripeAchDonorDataModel): Observable<string> {
    if(!this.paymentMethod || UtilsComponent.areEqual(this.donorData, donorData)) {
      return this.createPaymentMethodObj(donorData)
        .pipe(
          map((res: PaymentMethodResult) => res.paymentMethod.id)
        );
    }
    return of(this.paymentMethod);
  }

  private createPaymentIntent(email: string, clientID: string, ammount: number, paymentMethod: string): Observable<StripeIntentModel> {
    return this.merchantService.stripeInitializePayment({email, clientID, ammount, paymentMethod})
      .pipe(
        tap((res: StripeIntentModel) => {
          if(!res) throw new Error("Something went wrong while generating payment intent")
          this.paymentIntentModel = res;
          this.intentID = res.intentID;
          this.customerID = res.customerID;
        }),
      );
  }

  private confirmUsBankAccountSetup(paymentIntentModel: StripeIntentModel): Observable<SetupIntentResult> {
    return from(this.stripe.confirmUsBankAccountSetup(paymentIntentModel.token))
  }

  private checkStripeACHPaymentIntentResponse(paymentIntent: SetupIntentResult): void {
    if (paymentIntent.error) {
      this.onInvalidPaymentIntent(paymentIntent.error.message);
    } else {
      if (paymentIntent.setupIntent?.next_action?.type === "verify_with_microdeposits") {
        this.nextActionType = paymentIntent.setupIntent?.next_action?.type;
        const verifyWithMicrodepositsLink: string = (paymentIntent.setupIntent.next_action as unknown as any)?.verify_with_microdeposits?.hosted_verification_url
        if(!!verifyWithMicrodepositsLink) {
          this.microdepositVerificationHash = this.getMicrodepositVerificationHash(verifyWithMicrodepositsLink);
          if(environment.enableTestMode) {
            this.microdepositLink$.next(verifyWithMicrodepositsLink)
          }
        }
      }
      this.onValidPaymentIntent(paymentIntent.setupIntent);
    } 
  }

  private onValidPaymentIntent(paymentIntent: any): void {
    this.paymentIntent.next(paymentIntent);
    this.triggerSaveOrUpdate.next();
  }

  private getMicrodepositVerificationHash(url: string): string {
    const urlObj = new URL(url);
    const pathArray: string[] = urlObj.pathname.split("/");
    return pathArray.slice(-1)[0];
  }
}
