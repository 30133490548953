import { Component, Input, OnInit } from '@angular/core';
import { FormBuilderStateService } from '../../form-builder.state.service';
import { FbElementType, OptionsViewType } from '../../../../models/form-builder/fb.template.model';
import {Observable, Subject, Subscription} from 'rxjs';
import { map } from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {AdminConfirmationComponent} from '../../../../routes/admin/admin-confirmation/admin-confirmation.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-element-options',
  templateUrl: './element-options.component.html',
  styleUrls: ['../fb.page.scss']
})
export class ElementOptionsComponent implements OnInit {
  @Input() public fbss: FormBuilderStateService;

  @Input() public donationInfoChanged$: Subject<void>;
  public title$: Observable<string>;
  public FbElementType = FbElementType;
  private subscription: Subscription = new Subscription();
  private wantDownload: boolean = false;

  constructor(private toastrService: ToastrService, private dialog: MatDialog) { }

  public ngOnInit(): void {
    this.title$ = this.fbss.activeElementSettings$.asObservable()
      .pipe(
        map((elementType: FbElementType) => {
          switch (elementType) {
            case FbElementType.DonationInfo:
              return 'Donation Info Settings';
            case FbElementType.FullName:
              return 'Full Name Settings';
            case FbElementType.Phone:
              return 'Phone Settings';
            case FbElementType.PaymentDetails:
              return 'Payment Details Settings';
            case FbElementType.Header:
              return 'Header Settings';
            case FbElementType.Email:
              return 'Email Settings';
            case FbElementType.Address:
              return 'Address Settings';
            case FbElementType.TopImage:
              return 'Image Settings';
            case FbElementType.HeaderFooter:
              return 'Header and Footer Settings';
            case FbElementType.Comments:
              return 'Comment Settings';
            case FbElementType.PAC:
              return 'Political Action Committee Settings';
            default:
              return 'Element Settings';
          }
        })
      )
  }

  public close(): void {
    const template = this.fbss.template$.getValue();
    this.fbss.imgViewHandler(template);
    if (this.fbss.isTiersEmptyTier(template)) {
      this.toastrService.error('Please fill all mandatory fields');
      return
    }
    if (this.fbss.isTierHasImg(template)) {
      const config = {
        data: {
          img: '/assets/images/icon-donor-heart.png',
          title: `An image was not uploaded for all the tiers you have entered. Would you like to upload the default picture?`
        }
      };
      this.subscription.add(
        this.dialog.open(AdminConfirmationComponent, config).afterClosed()
          .subscribe((res: boolean) => {
            if (res) {
              this.fbss.setOptionsView(OptionsViewType.None);
            }
          })
      );
      return;
    }
    this.fbss.setOptionsView(OptionsViewType.None);
  }

  public onDonationInfoChanged(): void {
    this.donationInfoChanged$.next();
  }
}
