<div class="history-donations-table-container">
  <table mat-table [ngClass]="{withBorder : isWithBorder}" [dataSource]="tableSource" *ngIf="tableSource">
    <ng-container [matColumnDef]="column" *ngFor="let column of tableColumns">
      <th mat-header-cell *matHeaderCellDef class="table-header" [ngStyle]="getColumnStyle(column)">
        <div class="table-header-title-wrapper">
          <span *ngIf="column === 'isSelected' && bulkSelecting">
            <mat-checkbox
              [disabled]="disabled"
              class="custom-checkbox"
              (change)="handleHeaderChange($event)"
              [checked]="isSelectAllChecked()"
              [indeterminate]="isSelectAllIndeterminate()">
            </mat-checkbox>
          </span>
          <span *ngIf="column === 'reportActions'">

          </span>
          <span [ngStyle]="getStyle" class="title">
            {{isAdmin ? getColumnTitle(column) : (getColumnTitle(column) | translate)}}
          </span>
          <span
            [ngStyle]="getStyle"
            *ngIf="(column === 'buyerFullName' ||
                   column === 'addons' ||
                   column === 'includes' ||
                   column === 'ticketPackageName' ||
                   column === 'createdByUserName' ||
                   column === 'reportName' ||
                   checkColumnsWithFilters(column)) &&
                   filterSortConfig"
            class="column-filter"
          >
            <app-multiselect
              [columnFilterOptions]="getSelectOptions(column)"
              [initialSelection]="initialSelection"
              (selectedOptions)="selectedOptions($event)"
            >
              <app-icons
                [iconType]="IconsType.Funnel2"
                [color]="isActiveFilter(column) ? '#0064BE' : '#798594'"
                width="18"
                height="18"
              ></app-icons>
            </app-multiselect>
          </span>

          <span
            [ngStyle]="getStyle"
            *ngIf="(column === 'createdOn' ||
                    checkSortableColumns(column)) && this.filterSortConfig"
            class="column-filter"
            (click)="sortTable(column)"
          >
            <app-icons
              *ngIf="!checkColumn(column)"
              [iconType]="IconsType.Sort"
              width="20"
              height="20"
            ></app-icons>
            <app-icons
              *ngIf="checkColumn(column) && getSortOrder(column) === SortOrder.Ascending"
              [iconType]="IconsType.SortAscending"
              width="20"
              height="20"
              color="#0064BE"
            ></app-icons>
            <app-icons
              *ngIf="checkColumn(column) && getSortOrder(column) === SortOrder.Descending"
              [iconType]="IconsType.SortDescending"
              width="20"
              height="20"
              color="#0064BE"
            ></app-icons>
          </span>
        </div>
      </th>

      <td
        [ngStyle]="getStyle"
        mat-cell
        *matCellDef="let element"
        #toggle
        class="widget-table-cell"
        [ngClass]="{'clickable': isCellClickable(column)}"
        [appTooltip]="tooltip"
        (mouseenter)="mouseOver(tooltip)"
        (mouseleave)="mouseLeave(tooltip)"
        (click)="toggleTooltip(tooltip, toggle)"
      >
        <div matTooltip="{{getCustomInfo(element, column)}}" #tooltip="matTooltip" matTooltipPosition="above" matTooltipClass="table-tooltip"></div>
        <div [ngSwitch]="column" (click)="handleCellClick(column, element)">
          <div *ngSwitchCase="'isSelected'">
            <ng-container *ngIf="bulkSelecting">
              <mat-checkbox
                [disabled]="disabled"
                class="custom-checkbox"
                (click)="$event.stopPropagation()"
                (change)="handleChange($event, element)"
                [checked]="isRowChecked(element)">
              </mat-checkbox>
            </ng-container>
          </div>
          <div class="d-flex align-items-center" *ngSwitchCase="'message'">
            <span *ngIf="element['isMasquerade']" class="icon"></span> {{getTextNode(element, column)}}
          </div>
          <div class="d-flex align-items-center" [class.service-error]="!getServiceStatus(element)" [class.service-ok]="getServiceStatus(element)" *ngSwitchCase="'serviceStatus'">
            {{getTextNode(element, column)}}
          </div>
          <div class="d-flex align-items-center" *ngSwitchCase="'totalCost'">
            <span>
              <span *ngIf="element.totalCost !== undefined">$</span>
              {{getTextNode(element, column)}}
            </span>
          </div>
          <div class="d-flex align-items-center" *ngSwitchCase="'ticketCost'">
            <span>
              <span *ngIf="element.ticketCost !== 0">$</span>
              {{getTextNode(element, column) || ""}}
            </span>
          </div>
          <div class="d-flex align-items-center" *ngSwitchCase="'totalAmount'">
            <span>
              <span *ngIf="element.totalAmount !== 0">$</span>
              {{getTextNode(element, column) || ""}}
            </span>
          </div>
          <div class="d-flex align-items-center" *ngSwitchCase="'name'">
            <span class="edit-link" (click)="navigateTo(element)">{{element[column]}}</span>
          </div>
          <div *ngSwitchCase="'actions'">
            <app-button [label]="'See details'" (clickEvent)="navigateTo(element)" type="stroked" color="#0064be" width="140"></app-button>
          </div>
          <div *ngSwitchCase="'isAutoEmail'">
            <div class="result-checkbox" [ngClass]="isChecked(element, column) ? 'checkbox-checked' : 'checkbox-unchecked'"></div>
          </div>
          <div *ngSwitchCase="'isDonorPortalLink'">
            <div class="result-checkbox" [ngClass]="isChecked(element, column) ? 'checkbox-checked' : 'checkbox-unchecked'"></div>
          </div>
          <div *ngSwitchCase="'updateAction'">
            <app-button [label]="'Update status'" (clickEvent)="navigateTo(element)" type="stroked" color="#0064be" width="140"></app-button>
          </div>
          <ng-container *ngSwitchCase="'clipboard'">
          <div (click)="copyToClipboard(element)" class="icon-container" *ngIf="isUpdateEvent(element)">
              <img src="/assets/images/icon-copy.png" alt="Copy to clipboard" class="clipboard-icon">
          </div>
          </ng-container>
          <ng-container *ngSwitchCase="'reportActions'">
            <button mat-icon-button [matMenuTriggerFor]="actionsSettings">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionsSettings overlapTrigger="false" xPosition="after" yPosition="below">
              <div class="table-action-menu">
                <div class="menu-items">
                  <button class="settings-option"
                          [disabled]="disabled"
                          mat-menu-item
                          (click)="generatePDF(element.id, element.reportName, element.createdOn)"
                          >
                    <mat-icon>description</mat-icon> {{'Generate PDF' | translate}}
                  </button>
                  <button class="settings-option"
                     [disabled]="disabled"
                      mat-menu-item
                      (click)="resendEmails(element.id, element.reportDonors.length)"
                      >
                    <mat-icon>email</mat-icon> {{'Resend Emails' | translate}}
                  </button>
                  <button class="settings-option"
                      [disabled]="disabled"
                      mat-menu-item
                      (click)="removeReport(element)">
                    <mat-icon>delete</mat-icon> {{'Delete' | translate}}
                  </button>
                </div>
              </div>
            </mat-menu>
          </ng-container>
          <ng-container *ngSwitchCase="'mobile'">
            <span *ngIf="element['countryId'] === 1 || element['countryId'] === 0; else commonPhoneTemplate">{{element[column] | mask: '+0 (000) 000-0000'}}</span>
            <ng-template #commonPhoneTemplate>
              <span>{{element[column] ? '+' + element[column] : ''}}</span>
            </ng-template>
          </ng-container>
          <ng-container *ngSwitchCase="'phoneNumber'">
            <span>{{element[column] | mask: '+0 (000) 000-0000'}}</span>
          </ng-container>
          <ng-container *ngSwitchCase="'disclaimers'">
            <a *ngIf="element[column]?.length > 0; else noDisclaimers" [routerLink]="'/clients/contributions/' + element.id">Yes</a>
            <ng-template #noDisclaimers> <span>N/A</span></ng-template>
          </ng-container>
          <span *ngSwitchDefault>
            {{getTextNode(element, column)}}
          </span>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>

  </table>

</div>
