<div
  class="image-tool-wrapper"
  [ngStyle]="containerStyles"
>
  <div
    *ngIf="isEmpty && !landingPageView; else shareOnSMTemplate"
    class="empty-image-src"
  >{{'BUILD.Share on Social Media' | translate}}</div>
</div>


<ng-template #shareOnSMTemplate>
 <div
   class="share-on-container"
   [ngStyle]="wrapperStyles"
 >
   <span>{{text}}</span>
   <div
     *ngIf="landingPageView; else libraryTemplate"
     class="share-on-icons"
     [ngStyle]="modeStyles"
   >
     <ng-container *ngFor="let child of entity.children">
       <ng-container [ngSwitch]="child.attributes['name']">
         <!-- Email template -->
         <a
           *ngSwitchCase="ShareOnSmType.email"
           [href]="emailHref$ | async"
           title="Share by Email"
           [ngStyle]="iconStyles"
         >
           <img
             [src]="imgSrc(child.attributes['name'])"
             [ngStyle]="getImageStyles(child)"
           >
         </a>

         <!-- Twitter template -->
         <a
           *ngSwitchCase="ShareOnSmType.twitter"
           [ngStyle]="iconStyles"
           [href]="twitterHref$ | async"
         >
           <img
             [src]="imgSrc(child.attributes['name'])"
             [ngStyle]="getImageStyles(child)"
           >
         </a>

         <!-- Facebook template -->
         <a
           *ngSwitchCase="ShareOnSmType.facebook"
           [ngStyle]="iconStyles"
           [href]="facebookHref$ | async"
         >
           <img
             [src]="imgSrc(child.attributes['name'])"
             [ngStyle]="getImageStyles(child)"
           >
         </a>

         <!--LinkedIn template -->

         <a
           *ngSwitchCase="ShareOnSmType.linkedIn"
           [ngStyle]="iconStyles"
           [href]="linkedInHref$ | async"
         >
           <img
             [src]="imgSrc(child.attributes['name'])"
             [ngStyle]="getImageStyles(child)"
           >
         </a>
       </ng-container>
     </ng-container>

   </div>
 </div>
</ng-template>


<ng-template #libraryTemplate>
  <div
    class="share-on-icons"
    [ngStyle]="modeStyles"
  >
    <div
      *ngFor="let child of entity.children"
      [ngStyle]="iconStyles"
    >
      <img
        [src]="imgSrc(child.attributes['name'])"
        [ngStyle]="getImageStyles(child)"
      >
    </div>
  </div>
</ng-template>
