<div
  class="header-wrapper"
  *ngFor="let content of infoForRender()"
  [ngStyle]="headerStyle"
  [formGroup]="headerForm"
  #wrapper
>
  <div class="header-container" #headerRef>
    <div
      class="header-title header-header"
      [innerHTML]="renderTitle(content, 'mainLabel') | translate | safeHTML"
    ></div>
    <button mat-icon-button (click)="editTitle($event)" *ngIf="libraryVersion">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <quill-editor theme="snow" formControlName="headerControl" [modules]="modules" #editorRef>
    <div quill-editor-toolbar>
      <div class="ql-formats">
        <button class="ql-bold" [title]="'Bold'"></button>
        <button class="ql-italic" [title]="'Italic'"></button>
        <button class="ql-underline" [title]="'Underline'"></button>
        <button class="ql-strike" [title]="'Strike'"></button>
        <button class="ql-script" value="super"></button>
        <button class="ql-script" value="sub"></button>
      </div>

      <div class="ql-formats">
        <select class="ql-font">
          <option value="arial" selected>Arial</option>
          <option value="arial black">Arial Black</option>
          <option value="calibri">Calibri</option>
          <option value="century gothic">Century Gothic</option>
          <option value="comic sans ms">Comic Sans MS</option>
          <option value="courier">Courier</option>
          <option value="franklin gothic medium">Franklin Gothic Medium</option>
          <option value="geneva">Geneva</option>
          <option value="georgia">Georgia</option>
          <option value="helvetica">Helvetica</option>
          <option value="impact">Impact</option>
          <option value="lato">Lato</option>
          <option value="lucida">Lucida</option>
          <option value="lucida console">Lucida Console</option>
          <option value="lucida grande">Lucida Grande</option>
          <option value="lucida sans">Lucida Sans</option>
          <option value="lucida sans unicode">Lucida Sans Unicode</option>
          <option value="ms sans serif">MS Sans Serif</option>
          <option value="ms serif">MS Serif</option>
          <option value="monaco">Monaco</option>
          <option value="new york">New York</option>
          <option value="oswald">Oswald</option>
          <option value="palatino">Palatino</option>
          <option value="palatino linotype">Palatino Linotype</option>
          <option value="roboto">Roboto</option>
          <option value="tahoma">Tahoma</option>
          <option value="times">Times</option>
          <option value="times new roman">Times New Roman</option>
          <option value="trebuchet ms">Trebuchet MS</option>
          <option value="verdana">Verdana</option>
        </select>
      </div>

      <div class="ql-formats">
        <select class="ql-size">
          <option value="8px">8px</option>
          <option value="10px">10px</option>
          <option value="12px">12px</option>
          <option value="14px" [selected]>14px</option>
          <option value="16px">16px</option>
          <option value="18px">18px</option>
          <option value="20px">20px</option>
          <option value="22px">22px</option>
          <option value="24px">24px</option>
          <option value="28px">28px</option>
          <option value="36px">36px</option>
          <option value="48px">48px</option>
          <option value="60px">60px</option>
          <option value="64px">64px</option>
          <option value="72px">72px</option>
        </select>
      </div>

      <div class="ql-formats">
        <select class="ql-color"></select>
        <select class="ql-background"></select>
        <button class="ql-link"></button>
      </div>
    </div>
  </quill-editor>

  <div class="header-container" #subheaderRef>
    <div
      class="header-title header-subheader"
      [ngStyle]="subHeaderStyle"
      [innerHTML]="renderTitle(content, 'subLabel') | translate | safeHTML"
    ></div>
    <button mat-icon-button (click)="editTitle($event)" *ngIf="libraryVersion">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
</div>
