export enum CampaignStatus {
  Draft = 1,
  Sent = 2,
  Complete = 3,
  Disabled = 4,
  Active = 5,
  SegmentCreatingError = 6,
  SegmentSaving = 7,
  LettersPrinted = 8,
  LettersMailed = 9
}
