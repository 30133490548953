import { Injectable, Injector } from '@angular/core';
import { ConfigService } from './appconfig.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { PagingHelper } from './helper/paging.helper';
import { Paging } from '../models/paging/paging.model';
import { TotalRowsModel } from '../models/paging/totalrow.model';

@Injectable()
export abstract class BaseModelService<TModel> {
  protected abstract get controllerName(): string;

  protected configService: ConfigService;
  protected http: HttpClient;

  protected constructor(protected injector: Injector) {
    this.configService = injector.get(ConfigService);
    this.http = injector.get(HttpClient);
  }

  public getModel(id, dependencies: boolean = true): Observable<TModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<TModel>(
          `${config.apiURL}/${this.controllerName}/${id}`,
          { params: new HttpParams().set('includeDependencies', dependencies.toString()) }
        );
      })
    );
  }

  public getModelList(filter?: Paging): Observable<TModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<TModel[]>(
          `${config.apiURL}/${this.controllerName}`,
          { params }
        );
      })
    );
  }

  public getTotal(filter?: Paging): Observable<number> {
    const params: HttpParams = PagingHelper.getPagingParams(filter, false);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/total`;
        return this.http
          .get<TotalRowsModel>(url, { params })
          .pipe(map(model => model.total));
      })
    );
  }

  public addModel(model: TModel): Observable<TModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}`;
        return this.http.post<TModel>(url, model);
      })
    );
  }

  public addMany(models: TModel[]): Observable<TModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/InsertMany`;
        return this.http.post<TModel[]>(url, models);
      })
    );
  }

  public updateModel(model: TModel): Observable<TModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}`;
        return this.http.put<TModel>(url, model);
      })
    );
  }

  public updateMany(model: TModel[]): Observable<TModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/UpdateMany`;
        return this.http.put<TModel[]>(url, model);
      })
    );
  }

  public deleteModel(id: string | number): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/${id}`;
        return this.http.delete(url);
      })
    );
  }

  public getDistinctValues(filter?: Paging): Observable<any> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<any>(
          `${config.apiURL}/${this.controllerName}/distinctvalues`,
          { params }
        );
      })
    );
  }
}