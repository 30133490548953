<div class="fb-options-row">
  <div class="fb-row-element-full">
    <app-input
      [formGroup]="optionsForm"
      name="mainLabel"
      label="Element Text"
      [low]="true"
      (valueChanged)="onControlValueChanged('mainLabel')"
      [disabled]="readOnly"
    ></app-input>
  </div>
</div>

<div class="fb-options-row">
  <div class="fb-row-element-full">
    <app-select
      [formGroup]="optionsForm"
      name="mainLabelAlignment"
      label="Label Alignment"
      [low]="true"
      (valueChanged)="onLabelAlignmentChanged()"
      [disabled]="readOnly"
      [selectOptions]="labelAlignmentOptions"
    ></app-select>
  </div>
</div>

<div class="fb-options-row">
  <app-fb-checkbox
    (clickEvent)="onIsSetAsFormDefaultChanged()"
    [value]="isSetAsFormDefault.value"
    [readOnly]="readOnly"
  >{{'BUILD.Set as form default' | translate}}</app-fb-checkbox>
</div>

<div class="fb-options-row" *ngIf="(fbss.paymentServiceType$ | async) === PaymentServiceType.Cornerstone">
  <div class="fb-row-element-full">
    <app-select
      [formGroup]="optionsForm"
      name="paymentMethods"
      label="Payment Methods"
      [low]="true"
      (valueChanged)="onControlValueChanged('paymentMethods')"
      [disabled]="readOnly"
      [selectOptions]="paymentMethodsOptions"
    ></app-select>
  </div>
</div>
<div class="fb-options-row">
  <app-radio
          [formGroup]="optionsForm"
          name="doesClientCoverFee"
          label="Do you want to pay all fees associated with the donation?"
          [selectOptions]="donorCoversFeeOptions"
          (radioValueChanged)="onControlValueChanged('doesClientCoverFee')"
  ></app-radio>
</div>

<div class="fb-options-row">
  <app-radio
    #passMerchantFeeRadio
    [formGroup]="optionsForm"
    name="passMerchantFee"
    label="Pass Merchant Fee to Donor Automatically?"
    [selectOptions]="optionsForm.get('doesClientCoverFee').value === true ? mockYesNo: passMerchantFeeOptions"
    (radioValueChanged)="onControlValueChanged('passMerchantFee')"
    [disabled]="optionsForm.get('doesClientCoverFee').value === true"
  ></app-radio>
</div>

<div class="fb-options-row">
  <div class="fb-row-element-full">
    <app-textarea
      [formGroup]="optionsForm"
      name="feeText"
      label="Giving your Donors the Option? Set text to convince them to cover the fee:"
      (valueChanged)="onControlValueChanged('feeText')"
      [disabled]="readOnly || control('passMerchantFee').value === PassMerchantFee.Yes || !!control('doesClientCoverFee').value"
    ></app-textarea>
  </div>
</div>

<div class="fb-options-row" *ngIf="control('passMerchantFee').value === PassMerchantFee.No && !control('doesClientCoverFee').value">
  <div class="fb-row-element-full">
    <app-fb-checkbox
      [noWrap]="true"
      (clickEvent)="changeFeePassing()"
      [value]="control('isPassingFeeDefault').value"
      [readOnly]="readOnly"
    >
      <span class="fee-text">
        {{'BUILD.Check box for passing the fee by default to Donor' | translate}}
      </span>
    </app-fb-checkbox>
  </div>
</div>
