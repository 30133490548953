<div
  class="icon-button-wrapper"
  [ngClass]="{disabled: disabled}"
  [class.disabled-button]="disabled"
  (click)="onClick()"
>
  <app-icons
    *ngIf="iconsType"
    [iconType]="iconsType"
    [color]="color"
    [width]="width"
    [height]="height"
  >
  </app-icons>
</div>
