<div class="app-components-table-wrapper-encapsulation">

  <table mat-table [dataSource]="tableSource" class="app-components-table" *ngIf="tableSource">
    <ng-container [matColumnDef]="column" *ngFor="let column of tableDataColumns">
      <th mat-header-cell *matHeaderCellDef class="table-header">
        <div class="table-header-title-wrapper">
          <span class="title">{{getColumnTitle(column) | translate}}</span>
          <!--<span *ngIf="column === 'checked'" class="column-filter" style="margin-left: -10px;">
            <mat-checkbox (click)="selectAllCheckBox()"></mat-checkbox>
          </span>-->
          <span *ngIf="withFilter(column)" class="column-filter">
            <app-multiselect
              [columnFilterOptions]="getSelectOptions(column)"
              (selectedOptions)="selectedOptions($event)"
            >
              <i
                class="fas fa-filter action-icon"
                [class.active-filter]="isActiveFilter(column)"
              ></i>
            </app-multiselect>
          </span>
          <span *ngIf="column === 'name'" class="column-filter" (click)="sortTable()" [ngSwitch]="sortOrder">
            <i *ngSwitchCase="0" class="fas fa-sort-alpha-down sort-icon"></i>
            <i *ngSwitchCase="1" class="fas fa-sort-alpha-down-alt sort-icon"></i>
            <i *ngSwitchDefault class="fas fa-sort action-icon"></i>
          </span>
          <span *ngIf="column === 'donationsCount'" class="column-filter">
            <app-range-selector [minMaxRange]="minMax" (selectedOptions)="selectedOptions($event)">
              <i class="fas fa-filter action-icon" [class.active-filter]="isActiveFilter(column)"></i>
            </app-range-selector>
          </span>
        </div>

      </th>
      <td mat-cell *matCellDef="let element" class="app-components-table-cell" [appTooltip]="tooltip">
        <div matTooltip="{{element[column]}}" #tooltip="matTooltip" matTooltipPosition="above" matTooltipClass="table-tooltip"></div>
        <div *ngIf="column !== 'name' && column !== 'primaryPhone' && column !== 'status' && column !== 'donationsCount'  && column !== 'checked'" class="table-cell-wrapper">
          <!-- <span *ngIf="column !== 'email' && column !== 'donationsCount'; else emailTamplate">{{element[column] | titlecase}}</span> -->
          <span *ngIf="column !== 'email' && column !== 'donationsCount'; else emailTamplate">{{element[column]}}</span>
          <ng-template #emailTamplate>
            <span>{{element[column]}}</span>
          </ng-template>
        </div>

        <div *ngIf="column === 'checked'" class="table-cell-wrapper">
          <mat-checkbox [(ngModel)]="element.checked" (click)="checkBoxClick(element)"></mat-checkbox>
        </div>

        <div *ngIf="column === 'name'" class="table-cell-wrapper" (click)="selectRow(element)">
          <div class="name-icon">{{getAbbreviation(element, column)}}</div>
          <!-- <span class="name-link">{{element[column] | titlecase}}</span> -->
          <span class="name-link">{{element[column]}}</span>
        </div>

        <div *ngIf="column === 'donationsCount'" class="table-cell-wrapper">
          <span>$ {{element[column]}}</span>
        </div>


        <div *ngIf="column === 'primaryPhone'" class="table-cell-wrapper">
          <span>{{element[column] | mask: '+0 (000) 000-0000'}}</span>
        </div>
        <div *ngIf="column === 'status'" class="table-cell-wrapper">
          <span>{{getStatus(element)}}</span>
        </div>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>

  </table>

</div>
