import { ConfigService } from '../appconfig.service';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UploadDonorFileModel } from 'src/app/models/donor/upload-donor-file.model';
import { UploadDonorResultModel } from 'src/app/models/donor/upload-donor-result.model';
import { DonorUploadModel } from '../../models/donor/donor.upload.model';

@Injectable({
  providedIn: 'root'
})
export class UploadDonorsService {
  public headersError: boolean = false;
  public mandatoryError: boolean = false;

  public result: UploadDonorResultModel;

  public resultSubject: Subject<any> = new Subject<any>();
  public subject: Subject<DonorUploadModel> = new Subject<DonorUploadModel>();

  public resultSubscription: Observable<any> = this.resultSubject.asObservable();
  public uploadSubscription: Observable<DonorUploadModel> = this.subject.asObservable();

  public tagsIds: string[] = null;

  constructor(private configService: ConfigService, private http: HttpClient) { }

  public sendDonorsFile(model: UploadDonorFileModel): Observable<any> {
    if (this.tagsIds && this.tagsIds.length) {
      model.tagsIds = [...this.tagsIds];
      this.tagsIds = null;
    }
    return this.configService.loadConfig().pipe(
      switchMap(config => {
        return this.http.post(`${config.apiURL}/ClientDonor/import`, model);
      })
    );
  }

  public eventEmit(donorUploadModel: DonorUploadModel): void {
    this.subject.next(donorUploadModel);
  }

  public resultEmit(result: UploadDonorResultModel): void {
    this.result = result;
    this.resultSubject.next(result);
  }
}
