export enum TicketAddonTypeEnum {
  Meal = 1,
  ClassTraining = 3,
  ConcertPerformance = 4,
  Festival = 5,
  Competition = 6,
  NetworkingEvent = 7,
  SocialGathering = 8,
  Race = 9,
  Seminar = 10,
  Tournament = 11,
  Merchandise = 12,
  Other = 0
}
