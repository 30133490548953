import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import { IconsType } from '../../models/enum/icons.type';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input()
  public label: string = '';
  @Input()
  public type: string = 'flat';
  @Input()
  public width: number;
  @Input()
  public height: number;
  @Input()
  public color: string = 'grey';
  @Input()
  public borderColor: string;
  @Input()
  public disabled: boolean = false;
  @Input()
  public isWhiteBgc: boolean = false;
  @Input()
  public isFullWidth: boolean = false;
  @Input() public labelColor: string = '#ffffff';
  @Input() public isBgImageBtn: boolean = false;
  @Input() public isNew: boolean = false;
  @Input() public isUpperCase: boolean = false;
  @Input() public buttonColorType: 'warn' | 'primary' | 'dark' = 'primary';
  @Input() public iconType: IconsType = null;
  @Input() public isTitleCaseText: boolean = false;
  @Input() public fontFamily: string = '';
  @Input() public fontSize: string = '';
  public isAdminPortal: boolean = false;

  @Output()
  public clickEvent: EventEmitter<void> = new EventEmitter<void>();

  public constructor(
    private router: Router
  ) {
  }

  public ngOnInit(): void {
    this.isAdminPortal = this.router.url.includes('/admin-dashboard/');
  }

  public get getBorderColor(): string {
    if (this.disabled) {
      return '#afafaf';
    }
    if (this.borderColor) {
      return this.borderColor;
    }
    if (this.color !== 'grey') {
      return this.color;
    }
    return this.color;
  }

  public get getColor(): string {
    switch (this.type) {
      case 'stroked':
        switch (this.buttonColorType) {
          case 'primary':
            return '#0064BE';
            case 'warn':
              return '#C3120E';
          default:
            return
        }
      case 'flat':
          return '#FFFFFF';
      case 'basic':
        switch (this.buttonColorType) {
          case 'primary':
            return '#0064BE';
          case 'dark':
            return '#798594';
          default:
            return
        }
      default:
        return;

    }
  }

  public onClick(): void {
    if (this.disabled) {
      return;
    }
    this.clickEvent.emit();
  }
}
