import { ClientType } from './models/enum/client.type';
import { CodeType } from './models/internationalization/code.type';
import { DonationMethodType } from './models/enum/donation.method.type';
import { DonationStatusType } from './models/enum/donation.status.type';
import { DonorSource } from './models/enum/donor.source';
import { DonorStatus } from './models/enum/donor.status';
import { FeeAndTaxes } from './models/enum/fee-and-taxes.enum';
import { GenderType } from './models/enum/gender.type';
import { GiftType } from './models/enum/gift.type';
import { GiveType } from './models/enum/give-type.enum';
import { ImageTemplateModel } from './models/image-builder/image.template.model';
import { LanguagesType } from './models/enum/languages.type';
import { List } from './models';
import { LocalityType } from './models/internationalization/locality.type';
import { MjmlSmName } from './models/templates/mjml.sm.name';
import { MjmlTag } from './models/templates/mjml.tag.type';
import { onSort } from './shared/validatiors/common-function/sort';
import { PaymentServiceType } from './models/enum/payment.service.type';
import { PoliticalUnitType } from './models/internationalization/political.unit.type';
import { PortalUrls } from './models/enum/portal.urls';
import { SettingsName } from './models/enum/settings.name.type';
import { SocialMediaMetricsFilterType } from './models/enum/social.media.metrics.filter.type';
import { SocialMetricEnum } from './models/enum/social-metric.enum';
import { SocialPostStatusType } from './models/enum/social.post.status.type';
import { SocialProviderEnum } from './models/enum/social-provider.enum';
import { TemplateType } from './models/templates/template.type';
import { TemplateUsageType } from './models/templates/template.usage.type';
import { TerritorialEntityType } from './models/internationalization/territorial.entity.type';
import { UserActivityType } from './models/enum/user.activity.type';
import { UserRolesType } from './models/enum/user.roles.type';
import { YouTubeCategoryType } from './models/enum/youTube.category.type';
import FbTemplateModel, {
  AlignmentType,
  AvailablePaymentMethods,
  FbElementType,
  FbTemplateStatusType,
  HeaderSize,
  PassMerchantFee,
} from './models/form-builder/fb.template.model';
import FormElementDataModel from './models/form.element.data.model';
import MjmlElementModel from './models/templates/mjml.element.model';
import { DonationPaymentPageType } from './models/form-builder/donation.payment.page.type';
import { TicketAddonTypeEnum } from './models/enum/ticket-addon-type.enum';
import { CouponValue } from './models/event/event-coupon.model';
import { TypesOfDonationsEnum } from './models/enum/types-of-donations.enum';
import { CreatedTimeAgoType } from './models/event/event.donation.activity.model';
import { TimeFramesTypes } from './models/enum/time.frames.types';
import { ShareOnSmType } from './models/templates/share.on.sm.type';
import { ReportPeriodType } from './models/enum/report.period.type';
import { TimeZoneModel } from './models/time-zone.model';
import { MjmlCustomType } from './models/templates/mjml.custom.type';
import { RecurringEmailType } from './models/enum/recurring-email-type';
import { DonationFrequency } from './models/enum/donation-frequency.enum';
import { DonationRecurringOption } from './models/enum/donation-recurring-option.enum';

export const ZEROEVENT = '00000000-0000-0000-0000-000000000000';

export const FULL_THERMOMETER_HEIGHT = 20;
export const MIN_THERMOMETER_HEIGHT = 378;
export const FULL_BAR_HEIGHT = 17;
export const MIN_BAR_HEIGHT = 445;
export const DEFAULT_DISCLAIMER_TEXT =
  'Please click here to add your own custom disclaimer text.';
export const DEFAULT_DISCLAIMER_TEXT_SP =
  'Presione aquí para añadir el texto de una advertencia personalizada o aviso legal';

export const DEFAULT_DISCLAIMER_CHECKBOX_TEXT =
  'I confirm my understanding of the above disclaimer.';
export const DEFAULT_DISCLAIMER_CHECKBOX_TEXT_SP =
  'Confirmo haber entendido la advertencia o aviso legal.';

export const ORGANIZATIONS_COLUMNS: List[] = [
  { value: 'name', viewValue: 'Organization Name' },
  { value: 'phone', viewValue: 'Phone' },
  { value: 'primaryContact', viewValue: 'Primary Contact' },
  { value: 'primaryPhone', viewValue: 'Primary Phone' },
  { value: 'isActive', viewValue: 'Active' },
];

export const USERS_COLUMNS: List[] = [
  { value: 'firstName', viewValue: 'Name' },
  { value: 'title', viewValue: 'Title' },
  { value: 'role', viewValue: 'Role' },
  { value: 'primaryPhone', viewValue: 'Primary Phone' },
  { value: 'isActive', viewValue: 'Status' },
];

export const FUNDRAISERS_COLUMNS: List[] = [
  { value: 'createdDate', viewValue: 'P2P.Created Date' },
  { value: 'fundraiserName', viewValue: 'P2P.Fundraiser Name' },
  { value: 'goal', viewValue: 'P2P.Goal' },
  { value: 'totalDonations', viewValue: 'P2P.Total Donations' },
  { value: 'organization', viewValue: 'P2P.Organization' },
  { value: 'startDate', viewValue: 'P2P.Start Date' },
  { value: 'endDate', viewValue: 'P2P.End Date' },
  { value: 'eventName', viewValue: 'P2P.Event Name' },
  { value: 'status', viewValue: 'P2P.Status' },
];

export const DONATIONS_COLUMNS: List[] = [
  { value: 'firstName', viewValue: 'P2P.First Name' },
  { value: 'lastName', viewValue: 'P2P.Last Name' },
  { value: 'createdDate', viewValue: 'P2P.Created Date' },
  { value: 'donationAmount', viewValue: 'P2P.Donation Amount' },
  { value: 'p2PName', viewValue: 'P2P.P2P Name' },
  { value: 'eventName', viewValue: 'P2P.Event Name' },
  { value: 'organization', viewValue: 'P2P.Organization' },
];

export const FONTS: FormElementDataModel[] = [
  { label: 'Arial Black', value: 'Arial Black' },
  { label: 'Arial', value: 'Arial' },
  { label: 'Calibri', value: 'Calibri' },
  { label: 'Comic Sans MS', value: 'Comic Sans MS' },
  { label: 'Franklin Gothic Medium', value: 'Franklin Gothic Medium' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Impact', value: 'Impact' },
  { label: 'Lucida Console', value: 'Lucida Console' },
  { label: 'Lucida Sans Unicode', value: 'Lucida Sans Unicode' },
  { label: 'MS Sans Serif', value: 'MS Sans Serif' },
  { label: 'Roboto', value: 'Roboto' },
  { label: 'Times New Roman', value: 'Times New Roman' },
  { label: 'Trebuchet MS', value: 'Trebuchet MS' },
  { label: 'Verdana', value: 'Verdana' },
  { label: 'Lato', value: 'Lato' },
  { label: 'Oswald', value: 'Oswald' },
  { label: 'Courier', value: 'Courier' },
  { label: 'Tahoma', value: 'Tahoma' },
  { label: 'Times', value: 'Times' },
  { label: 'Century Gothic', value: 'Century Gothic' },
  { label: 'Geneva', value: 'Geneva' },
  { label: 'Lucida', value: 'Lucida' },
  { label: 'Lucida Sans', value: 'Lucida Sans' },
  { label: 'Lucida Grande', value: 'Lucida Grande' },
  { label: 'MS Serif', value: 'MS Serif' },
  { label: 'New York', value: 'New York' },
  { label: 'Palatino', value: 'Palatino' },
  { label: 'Palatino Linotype', value: 'Palatino Linotype' },
  { label: 'Monaco', value: 'Monaco' },
  { label: 'Helvetica', value: 'Helvetica' },
];
FONTS.sort(onSort);

export const PHONE_TYPES: List[] = [
  { value: 1, viewValue: 'Mobile' },
  { value: 2, viewValue: 'Home' },
  { value: 3, viewValue: 'Office' },
  { value: 4, viewValue: 'Other' },
];

export const ROLES: List[] = [
  { value: 'ClientAdmin', viewValue: 'Client Admin' },
  { value: 'FundraisingManager', viewValue: 'Fundraising Manager' },
  { value: 'ContentCreator', viewValue: 'Content Creator' },
  { value: 'DonorManager', viewValue: 'Donor Manager' },
];

export const PAYMENT_PROVIDERS: FormElementDataModel[] = [
  { value: PaymentServiceType.None, label: 'None' },
  { value: PaymentServiceType.CardConnect, label: 'Card Connect' },
  { value: PaymentServiceType.Cornerstone, label: 'Cornerstone' },
  { value: PaymentServiceType.Stripe, label: 'Stripe' },
];

export const CLIENT_TYPES: FormElementDataModel[] = [
  { value: ClientType.General, label: 'General' },
  { value: ClientType.PAC, label: 'Political Action Committee' },
];

export const EVENT_LIST_COLUMNS = [
  'createdOn',
  'name',
  'clientID',
  'startDate',
  'endDate',
  'createdByUserName',
  'status',
  'tickets',
  'registration',
  'secondaryEventsList',
];
export const REGISTERED_GUEST_COLUMNS = [
  'createdOn',
  'firstName',
  'lastName',
  'email',
  'mobile',
  'totalCost',
  'numberInParty',
  'mainGuest',
  'participantType',
  'fundraiserID',
  'participantEventComment',
  'registrationPaymentMethod',
  'registrationPaymentStatus',
  'edit',
];

export const EVENTS_ATTENDED_COLUMNS = [
  'createdOn',
  'eventName',
  'totalCost',
  'participantType',
  'participantEventComment',
];

export const EVENTS_EMAILS_COLUMNS = [
  'isSelected',
  'email',
  'subject',
  'createdOn',
  'status',
  'statusTime',
];

export const SENDGRID_STATUSES = {
  sent: 'Sent',
  processed: 'Processed',
  dropped: 'Dropped',
  deferred: 'Deferred',
  bounce: 'Bounced',
  delivered: 'Delivered',
  open: 'Opened',
  click: 'Engaged',
  spamreport: 'SPAM',
  unsubscribe: 'Unsubscribed',
  group_unsubscribe: 'Group unsubscribe',
  group_resubscribe: 'Group resubscribe',
};

export const INVITED_GUEST_COLUMNS = [
  'firstName',
  'lastName',
  'email',
  'mobile',
];

export const EVENTS_TICKETS_COLUMNS = [
  'publicID',
  'ticketTypeName',
  'addons',
  'includes',
  'actualPrice',
  'buyerName',
  'participantName',
  'createdOn',
  'delete',
];

export const EVENT_COMMENTS_COLUMNS = [
  'fullName',
  'email',
  'comment',
  'createdOn',
];

export const CLIENT_DONATIONS_COLUMNS = [
  'publicID',
  'donorFullName',
  'donationRealDate',
  'donationAmmount',
  'donationStatus',
  'tierName',
  'donationMethod',
  'sourceValue',
  'origin',
  'isAnonymous',
  'allocatedFundID',
  'fundraiserName',
  'organizationName',
  'countryId',
  'federalIDNumber',
  'templateName',
  'disclaimers',
];

export const CLIENT_DONATIONS_COLUMNS_TEMPLATE = [
  'publicID',
  'donorFullName',
  'donationRealDate',
  'donationAmmount',
  'donationStatus',
  'tierName',
  'donationMethod',
  'sourceValue',
  'origin',
  'isAnonymous',
  'allocatedFundID',
  'countryId',
  'templateName',
];

export const CLIENT_DONATIONS_COLUMNS_DONOR = [
  'publicID',
  'donationRealDate',
  'donationAmmount',
  'tierName',
  'donationMethod',
  'sourceValue',
  'donationStatus',
  'origin',
  'isAnonymous',
  'giftType',
  'allocatedFundID',
];

export const CAMPAIGN_COLUMNS = [
  'name',
  'clientID',
  'startDate',
  'endDate',
  'status',
  'createdByUserName',
  'scheduler',
  'type',
];

export const TAG_LIST_COLUMNS = [
  'isSelected',
  'name',
  'recordsApplied',
  'activationDate',
  'createdByUserName',
  'type',
];

export const ALL_TAG_LIST_COLUMNS = [
  'isSelected',
  'name',
  'recordsApplied',
  'status',
  'activationDate',
];

export const AUTO_TAG_SETTING_LAPSED_COLUMN = [
  'donorFullName',
  'origin',
  'cycle',
  'lastDonationAmount',
  'lastDonationDate',
  'tagEndingDate',
];

export const AUTO_TAG_SETTING_NEW_DONOR_COLUMN = [
  'donorFullName',
  'origin',
  'cycle',
  'tagEndingDate',
];

export const AUTO_TAG_SETTING_MISS_REC_COLUMN = [
  'donorFullName',
  'tagStartDate',
  'actualCount',
  'recurringAmmount',
  'totalRecurringAmmount',
  'reccurrency',
];

export const AUTO_TAG_SETTING_CANCELLED_COLUMN = [
  'donorFullName',
  'tagStartDate',
  'totalRecurringAmmount',
  'reason',
];

export const AUTO_TAG_SETTING_LEVEL_COLUMN = [
  'donorFullName',
  'tagStartDate',
  'avgDonationAmount',
  'eventAttendedCount',
  'isSupported',
];

export const AUTO_TAG_SETTING_SUPPORTER_COLUMN = [
  'donorFullName',
  'origin',
  'eventAttendedCount',
  'totalDonations',
  'interests',
];

export const AUTO_TAG_SETTING_SPEAKER_COLUMN = [
  'donorFullName',
  'origin',
  'eventAttendedCount',
  'totalDonations',
];

export const AUTO_TAG_SETTING_SPONSOR_COLUMN = [
  'donorFullName',
  'origin',
  'eventSponsoredCount',
];

// export const AUTO_TAG_SETTING_GUEST_COLUMN = [
//   'avgDonationAmount',
//   'clientDonorID',
//   'donorFullName',
//   'eventAttendedCount',
//   'eventSponsoredCount',
//   'interests',
//   'isSupported' ,
//   'origin',
//   'totalDonations'
// ];

export const DEACTIVATE_TAG_LIST_COLUMNS = [
  'isSelected',
  'name',
  'deactivationDate',
];

export const AUTO_TAGS_LIST_COLUMNS = ['isSelected', 'name'];

export const MS_PER_DAY = 86400000; // milliseconds per day
export const MS_PER_HOUR = 3600000; // milliseconds per hour
export const MS_PER_MINUTE = 60000; // milliseconds per minute
export const MS_PER_MONTH = MS_PER_DAY * 30; // milliseconds per month
export const MS_PER_YEAR = 365 * MS_PER_DAY;

export const DaysOfWeekStrings = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export const AUXILIA_ADMIN = 'AuxiliaAdmin';
export const SUPER_ADMIN = 'SuperAdmin';
export const CLIENT_ADMIN = 'ClientAdmin';
export const FUNDRAISING_MANAGER = 'FundraisingManager';
export const CONTENT_CREATOR = 'ContentCreator';
export const DONOR_ADMIN = 'DonorAdmin';
export const DONOR_MANAGER = 'DonorManager';

export const UserActivityValues: FormElementDataModel[] = [
  {
    label: 'Login',
    value: UserActivityType.Login,
  },
  {
    label: 'Logout',
    value: UserActivityType.Logout,
  },
  {
    label: 'Create User',
    value: UserActivityType.CreateUser,
  },
  {
    label: 'Update User',
    value: UserActivityType.UpdateUser,
  },
  {
    label: 'Archive User',
    value: UserActivityType.ArchiveUser,
  },
  {
    label: 'Create Donor',
    value: UserActivityType.CreateDonor,
  },
  {
    label: 'Delete Donor',
    value: UserActivityType.DeleteDonor,
  },
  {
    label: 'Upload Donors',
    value: UserActivityType.UploadDonors,
  },
  {
    label: 'Update Donor',
    value: UserActivityType.UpdateDonor,
  },
  {
    label: 'Export Donors',
    value: UserActivityType.ExportDonors,
  },
  {
    label: 'Archive Donor',
    value: UserActivityType.ArchiveDonor,
  },
  {
    label: 'Create Event',
    value: UserActivityType.CreateEvent,
  },
  {
    label: 'Update Event',
    value: UserActivityType.UpdateEvent,
  },
  {
    label: 'Publish Event',
    value: UserActivityType.PublishEvent,
  },
  {
    label: 'Cancel Event',
    value: UserActivityType.CancelEvent,
  },
  {
    label: 'Archive Event',
    value: UserActivityType.ArchiveEvent,
  },
  {
    label: 'Delete Event',
    value: UserActivityType.DeleteEvent,
  },
  {
    label: 'Update Guest Type',
    value: UserActivityType.UpdateGuestType,
  },
  {
    label: 'Export',
    value: UserActivityType.Export,
  },
  {
    label: 'Create Campaign',
    value: UserActivityType.CreateCampaign,
  },
  {
    label: 'Update Campaign',
    value: UserActivityType.UpdateCampaign,
  },
  {
    label: 'Activate Campaign',
    value: UserActivityType.ActivateCampaign,
  },
  {
    label: 'Disable Campaign',
    value: UserActivityType.DisableCampaign,
  },
  {
    label: 'Delete Campaign',
    value: UserActivityType.DeleteCampaign,
  },
  {
    label: 'Create Donation',
    value: UserActivityType.CreateDonation,
  },
  {
    label: 'Update Donation',
    value: UserActivityType.UpdateDonation,
  },
  {
    label: 'Delete Donation',
    value: UserActivityType.DeleteDonation,
  },
  {
    label: 'Update Profile',
    value: UserActivityType.UpdateProfile,
  },
  {
    label: 'Connect Account',
    value: UserActivityType.ConnectSocialAccount,
  },
  {
    label: 'Remove Account',
    value: UserActivityType.RemoveSocialAccount,
  },
  {
    label: 'Create Post',
    value: UserActivityType.CreatePost,
  },
  {
    label: 'Update Post',
    value: UserActivityType.UpdatePost,
  },
  {
    label: 'Delete Post',
    value: UserActivityType.DeletePost,
  },
  {
    label: 'Submit Post',
    value: UserActivityType.SubmitPost,
  },
  {
    label: 'Approve Post',
    value: UserActivityType.ApprovePost,
  },
  {
    label: 'Require Updates',
    value: UserActivityType.RequireUpdates,
  },
  {
    label: 'Resubmit Post',
    value: UserActivityType.ResubmitPost,
  },
  {
    label: 'Publish Post',
    value: UserActivityType.PublishPost,
  },
  {
    label: 'Schedule Post',
    value: UserActivityType.SchedulePost,
  },
  {
    label: 'Create Time Slot',
    value: UserActivityType.CreateTimeSlot,
  },
  {
    label: 'Delete Time Slot',
    value: UserActivityType.DeleteTimeSlot,
  },
  {
    label: 'All',
    value: UserActivityType.All,
  },
  {
    label: 'Update Merchant',
    value: UserActivityType.UpdateMerchant,
  },
  {
    label: 'Create Donation Form',
    value: UserActivityType.CreateDonationForm,
  },
  {
    label: 'Update Donation Form',
    value: UserActivityType.EditDonationForm,
  },
  {
    label: 'Disable Donation Form',
    value: UserActivityType.DisableDonationForm,
  },
  {
    label: 'Merged Donors',
    value: UserActivityType.MergedDonors,
  },
  {
    label: 'Add Merchant',
    value: UserActivityType.AddMerchant,
  },
  {
    label: 'Update Fee',
    value: UserActivityType.UpdateFee,
  },
  {
    label: 'Ticket Deletion',
    value: UserActivityType.TicketDeletion,
  },
];
UserActivityValues.sort(onSort);

export const UserRolesTypeViews = {
  [UserRolesType.SUPER_ADMIN]: 'Super Admin',
  [UserRolesType.FUNDRAISING_MANAGER]: 'Fundraising Manager',
  [UserRolesType.DONOR_ADMIN]: 'Donor Admin',
  [UserRolesType.CONTENT_CREATOR]: 'Content Creator',
  [UserRolesType.CLIENT_ADMIN]: 'Client Admin',
  [UserRolesType.AUXILIA_ADMIN]: 'Auxilia Admin',
  [UserRolesType.DONOR_MANAGER]: 'Donor Manager',
};

export const StatusPostsViews = {
  [SocialPostStatusType.Archived]: 'Archived',
  [SocialPostStatusType.Draft]: 'Draft',
  [SocialPostStatusType.PendingApproval]: 'Pending Approval',
  [SocialPostStatusType.Published]: 'Published',
  [SocialPostStatusType.RequireUpdates]: 'Require Updates',
  [SocialPostStatusType.Scheduled]: 'Scheduled',
  [SocialPostStatusType.Error]: 'Error',
};

export const PlatformPostsViews = {
  [SocialProviderEnum.Twitter]: 'Twitter',
  [SocialProviderEnum.Facebook]: 'Facebook',
  [SocialProviderEnum.Instagram]: 'Instagram',
  [SocialProviderEnum.Linkedin]: 'Linkedin',
  [SocialProviderEnum.YouTube]: 'YouTube',
};

export const PlatformPostsNames = {
  [SocialProviderEnum.Twitter]: 'X',
  [SocialProviderEnum.Facebook]: 'Facebook',
  [SocialProviderEnum.Instagram]: 'Instagram',
  [SocialProviderEnum.Linkedin]: 'Linkedin',
  [SocialProviderEnum.YouTube]: 'YouTube',
};

export const portalUrls = {
  [UserRolesType.AUXILIA_ADMIN]: PortalUrls.AdminPortal,
  [UserRolesType.SUPER_ADMIN]: PortalUrls.AdminPortal,
  [UserRolesType.CONTENT_CREATOR]: PortalUrls.ClientPortal,
  [UserRolesType.FUNDRAISING_MANAGER]: PortalUrls.ClientPortal,
  [UserRolesType.CLIENT_ADMIN]: PortalUrls.ClientPortal,
  [UserRolesType.DONOR_ADMIN]: PortalUrls.DonorPortal,
  [UserRolesType.DONOR_MANAGER]: PortalUrls.ClientPortal,
};

export const GiveTypesViews = {
  [GiveType.FeeForService]: 'Fee for Service',
  [GiveType.InKind]: 'In Kind',
  [GiveType.Monetary]: 'Monetary',
};

export const DonationMethodsViews = {
  [DonationMethodType.ACH]: 'ACH',
  [DonationMethodType.Bitcoin]: 'Bitcoin',
  [DonationMethodType.Cash]: 'Cash',
  [DonationMethodType.Check]: 'Check',
  [DonationMethodType.CreditCard]: 'Credit Card',
  [DonationMethodType.MobilePay]: 'Mobile Pay',
  [DonationMethodType.MoneyOrder]: 'Money Order',
  [DonationMethodType.Venmo]: 'Venmo',
  [DonationMethodType.Zelle]: 'Zelle',
  [DonationMethodType['N/A']]: 'N/A',
};

export const DonorStatusViews = {
  [DonorStatus.Activated]: 'Active',
  [DonorStatus.NotActivated]: 'Not activated',
  [DonorStatus.Disabled]: 'Disabled',
};

export const DonorSourceViews = {
  [DonorSource.Manual]: 'Manual',
  [DonorSource.Google]: 'Google',
  [DonorSource.Facebook]: 'Facebook',
};

export const giftTypes = {
  [0]: '',
  [GiftType.InHonorOf]: 'In Honor Of',
  [GiftType.InMemoryOf]: 'In Memory Of',
  [GiftType.InSupportOf]: 'In Support Of',
};

export const FeeAndTaxesOptions: FormElementDataModel[] = [
  {
    label: 'Pass the fee to the donor automatically',
    value: FeeAndTaxes.PassAutomatically,
  },
  {
    label:
      'Provide the donor the option to include the fee in their payment or assume cost fee',
    value: FeeAndTaxes.ProvideOption,
  },
];

export const DonationAskOptions: FormElementDataModel[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const DefaultThankYouPageOptions: FormElementDataModel[] = [
  { label: 'Use Default Auxilia Thank You Page', value: false },
  { label: 'Use Custom Thank You Page', value: true },
];

export const DefaultRecurringDonationEmailTexts: FormElementDataModel[] = [
  {
    label: `<div>Dear [DonorName],</div><div><br></div><div>Thank you for supporting [OrganizationName] by setting up a recurring donation.</div><div><br></div><div contenteditable="false">You have elected the following:</div><div contenteditable="false"><br></div><div contenteditable="false">Gross Donation Amount: [DonationAmountWithOrWithoutFee]</div><div contenteditable="false">Frequency: [Frequency]</div><div contenteditable="false">Start Date: [StartDate]</div><div contenteditable="false"><br></div><div contenteditable="false">We will remind you 7 days prior to your donation being processed for payment.</div><div contenteditable="false"><br></div><div contenteditable="false">If you have already created an account, please <a href="[DonorPortal]">click here</a> to login to your donor portal to view your history, change the donation amount, change the processing date, and update your credit card.</div><div contenteditable="false"><br></div><div contenteditable="false">If you have not created an account and you wish to do so, please <a href="[CreateAccount]">click here</a> to complete the registration process.</div><div><br></div><div>We look forward to accomplishing our mission together with you. We are grateful for your generous support!</div><div><br></div><div>[ClientPrimaryContactName]</div><div>[OrganizationName]</div>`,
    value: RecurringEmailType.ScheduleCreation,
  },
  {
    label: `<div>Dear [DonorName],</div><div><br></div>Thank you for supporting [OrganizationName].</div><div><br></div><div contenteditable="false">Our records indicate your next scheduled recurring donation for [GrossDonationAmount] will automatically process on [ScheduledDonationDate].</div><div contenteditable="false"><br></div><div contenteditable="false">If you have already created an account, please <a href="[DonorPortal]">click here</a> to login to your donor portal to view your history, change the donation amount, change the processing date, and update your credit card.</div><div contenteditable="false"><br></div><div contenteditable="false">If you have not created an account and you wish to do so, please <a href="[CreateAccount]">click here</a> to complete the registration process.</div><div><br></div><div>We are grateful for your generous support!</div><div><br></div><div>[ClientPrimaryContactName]</div><div>[OrganizationName]</div>`,
    value: RecurringEmailType.NextScheduledDonationDate,
  },
  {
    label: `<div>Dear [DonorName],</div><div><br></div><div>On behalf of our Board of Directors and Staff, we would like to thank you for supporting [OrganizationName].</div><div><br></div><div contenteditable="false">Your [GrossDonationAmount] recurring donation has been successfully processed.</div><br><div>This donation helps us make a positive difference. Your support encourages our continued commitment to reaching our goal.</div><div><br></div><div contenteditable="false">If you have already created an account, please <a href="[DonorPortal]">click here</a> to log on to your donor portal to view your history, change the donation amount, change the processing date, and update your credit card.</div><div contenteditable="false"><br></div><div contenteditable="false">If you have not created an account and you wish to do so, please <a href="[CreateAccount]">click here</a> to complete the registration process.</div><div><br></div><div>Thank you again for your generosity and support,</div><div><br></div><div>Regards</div><div>[ClientPrimaryContactName]</div><div>[OrganizationName]</div>`,
    value: RecurringEmailType.ThankYou,
  },
  {
    label: `<div>Dear [DonorName],</div><div><br></div><div contenteditable="false">We would like to let you know that your [GrossDonationAmount] recurring donation that was scheduled on [DonationDate] was declined.</div><div contenteditable="false"><br></div><div contenteditable="false">If you have already created an account, please <a href="[DonorPortal]">click here</a> to log on to your donor portal to view your history, change the donation amount, change the processing date, and update your credit card.</div><div contenteditable="false"><br></div><div contenteditable="false">If you have not created an account and you wish to do so, please <a href="[CreateAccount]">click here</a> to complete the registration process.</div><div><br></div><div>Should you have any questions, please do not hesitate to reach out to us!</div><div><br></div><div>Regards,</div><div>[ClientPrimaryContactName]</div><div>[OrganizationName]</div>`,
    value: RecurringEmailType.DonationDidNotProcess,
  },
  {
    label: `<div>Dear [DonorName],</div><div><br></div><div contenteditable="false">You have elected to cancel one or more of your recurring donations.</div><div contenteditable="false"><br></div><div contenteditable="false">If you have already created an account, please <a href="[DonorPortal]">click here</a> to login to your donor portal to view your history, change a donation amounts, change the processing dates, and update your credit card.</div><div contenteditable="false"><br></div><div contenteditable="false">If you have not created an account and you wish to do so, please <a href="[CreateAccount]">click here</a> to complete the registration process.</div><div><br></div><div>Please contact us with questions.  Should you have any questions, please do not hesitate to reach out to us.</div><div><br></div><div>[ClientPrimaryContactName]</div><div>[OrganizationName]</div>`,
    value: RecurringEmailType.CancelledDonation,
  },
  {
    label: `<div>Dear [DonorName],</div><div><br></div><div contenteditable="false">You have elected to reinstate your recurring donation(s).  Please see below for your donation details.</div><div contenteditable="false"><br></div><div contenteditable="false">Donation [DonationAmount]</div><div contenteditable="false">Fee [FeeAmount]</div><div contenteditable="false">Total Cost [TotalCost]</div><div><br contenteditable="false"></div><div contenteditable="false">If you have already created an account, please <a href="[DonorPortal]">click here</a> to login to your donor portal to view your history, change the donation amount, change the processing date, and update your credit card.</div><div contenteditable="false"><br></div><div contenteditable="false">If you have not created an account and you wish to do so, please <a href="[CreateAccount]">click here</a> to complete the registration process.</div><div><br></div><div>We are grateful for your generous support.</div><div><br></div><div>[ClientPrimaryContactName]</div><div>[OrganizationName]</div>`,
    value: RecurringEmailType.RestartedDonation,
  },
];

export const DefaultRecurringDonationEmailSubjects: FormElementDataModel[] = [
  {
    label: 'Thank You for Setting Up Your Recurring Donation',
    value: RecurringEmailType.ScheduleCreation,
  },
  {
    label: 'Next Donation Date is Scheduled on [NextDonationDate]',
    value: RecurringEmailType.NextScheduledDonationDate,
  },
  {
    label: 'Thank You for Your Recurring Donation',
    value: RecurringEmailType.ThankYou,
  },
  {
    label: 'Recurring Donation Did Not Process',
    value: RecurringEmailType.DonationDidNotProcess,
  },
  {
    label: 'Cancelled Recurring Donation(s)',
    value: RecurringEmailType.CancelledDonation,
  },
  {
    label: 'Thank You for Reinstating Your Donation(s)',
    value: RecurringEmailType.RestartedDonation,
  },
];

export const ShowPercentage: FormElementDataModel[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const Appearance: FormElementDataModel[] = [
  { label: 'Thermometer', value: 'thermometer' },
  { label: 'Progress Bar', value: 'bar' },
];

export const Alignment: FormElementDataModel[] = [
  { label: 'Vertical', value: 'vertical' },
  { label: 'Horizontal', value: 'horizontal' },
];

export const TextPosition: FormElementDataModel[] = [
  { label: 'Left', value: 'left' },
  { label: 'Right', value: 'right' },
];

export const TextPositionHorizontal: FormElementDataModel[] = [
  { label: 'Top', value: 'left' },
  { label: 'Bottom', value: 'right' },
];

export const FILE_SIZE_LIMIT = 3145728; // 3 mb

export const ClientSettingsAliaces = {
  [SettingsName.PrimaryColor]: 'Primary Brand Color',
  [SettingsName.SecondaryColor]: 'Secondary Brand Color',
  [SettingsName.TertiaryColor]: 'Tertiary Brand Color',
};

export const TimeFilterOptions: FormElementDataModel[] = [
  { label: 'Last 7 Days', value: SocialMediaMetricsFilterType.Last7Days },
  { label: 'Last Month', value: SocialMediaMetricsFilterType.LastMonth },
  { label: 'Last 3 Months', value: SocialMediaMetricsFilterType.Last3Months },
  { label: 'Last Year', value: SocialMediaMetricsFilterType.LastYear },
];

export const SocialMediaOptions: FormElementDataModel[] = [
  { label: 'Facebook', value: SocialProviderEnum.Facebook },
  { label: 'Instagram', value: SocialProviderEnum.Instagram },
  { label: 'Twitter', value: SocialProviderEnum.Twitter },
  { label: 'Linkedin', value: SocialProviderEnum.Linkedin },
  { label: 'YouTube', value: SocialProviderEnum.YouTube },
];

export const SocialMediaOptionsViews = {
  [SocialMetricEnum.Impressions]: 'Impressions',
  [SocialMetricEnum.Engagements]: 'Engagement',
  [SocialMetricEnum.ClickthroughRate]: 'Clickthrough Rate',
  [SocialMetricEnum.Likes]: 'Likes',
  [SocialMetricEnum.Shares]: 'Shares',
  [SocialMetricEnum.Comments]: 'Comments',
  [SocialMetricEnum.Retweets]: 'Reposts',
  [SocialMetricEnum.QuoteRetweets]: 'Quote Reposts',
  [SocialMetricEnum.Replies]: 'Replies',
  [SocialMetricEnum.VideoViews]: 'Video Views',
};

export const BACKGROUNDR_REPEAT_OPTIONS: FormElementDataModel[] = [
  { label: 'Repeat', value: 'repeat' },
  { label: 'Repeat along the X axis', value: 'repeat-x' },
  { label: 'Repeat along the Y axis', value: 'repeat-y' },
  { label: 'Space', value: 'space' },
  { label: 'Round', value: 'round' },
  { label: 'Do not repeat', value: 'no-repeat' },
];

export const BACKGROUNDR_SIZE_OPTIONS: FormElementDataModel[] = [
  { label: 'Do not scale', value: 'auto' },
  { label: 'Fit to template width', value: '100%' },
  { label: '25%', value: '25%' },
  { label: '50%', value: '50%' },
  { label: '75%', value: '75%' },
  { label: '125%', value: '125%' },
  { label: '150%', value: '150%' },
];

export const FONT_SIZES: FormElementDataModel[] = [
  { label: '8px', value: '8px' },
  { label: '10px', value: '10px' },
  { label: '12px', value: '12px' },
  { label: '13px', value: '13px' },
  { label: '14px', value: '14px' },
  { label: '16px', value: '16px' },
  { label: '18px', value: '18px' },
  { label: '20px', value: '20px' },
  { label: '22px', value: '22px' },
  { label: '24px', value: '24px' },
  { label: '26px', value: '26px' },
  { label: '28px', value: '28px' },
  { label: '30px', value: '30px' },
  { label: '32px', value: '32px' },
  { label: '34px', value: '34px' },
  { label: '36px', value: '36px' },
  { label: '38px', value: '38px' },
  { label: '40px', value: '40px' },
  { label: '44px', value: '44px' },
  { label: '48px', value: '48px' },
  { label: '52px', value: '52px' },
  { label: '56px', value: '56px' },
  { label: '60px', value: '60px' },
  { label: '64px', value: '64px' },
  { label: '68px', value: '68px' },
  { label: '72px', value: '72px' },
];

export const BUTTON_FONT_SIZES: FormElementDataModel[] = [
  { label: '14px', value: '14px' },
  { label: '16px', value: '16px' },
  { label: '18px', value: '18px' },
  { label: '20px', value: '20px' },
  { label: '22px', value: '22px' },
  { label: '24px', value: '24px' },
  { label: '26px', value: '26px' },
  { label: '28px', value: '28px' },
  { label: '30px', value: '30px' },
  { label: '32px', value: '32px' },
  { label: '34px', value: '34px' },
  { label: '36px', value: '36px' },
  { label: '38px', value: '38px' },
  { label: '40px', value: '40px' },
  { label: '44px', value: '44px' },
];

export const LOGO_SIZES: FormElementDataModel[] = [
  { label: '30px', value: '30px' },
  { label: '32px', value: '32px' },
  { label: '34px', value: '34px' },
  { label: '36px', value: '36px' },
  { label: '38px', value: '38px' },
  { label: '40px', value: '40px' },
  { label: '44px', value: '44px' },
  { label: '48px', value: '48px' },
  { label: '52px', value: '52px' },
  { label: '56px', value: '56px' },
  { label: '60px', value: '60px' },
  { label: '64px', value: '64px' },
  { label: '68px', value: '68px' },
  { label: '72px', value: '72px' },
];

export const FONT_SIZE_THERMOMETER: FormElementDataModel[] = [
  { label: '8px', value: '8px' },
  { label: '10px', value: '10px' },
  { label: '12px', value: '12px' },
  { label: '13px', value: '13px' },
  { label: '14px', value: '14px' },
  { label: '16px', value: '16px' },
  { label: '18px', value: '18px' },
  { label: '20px', value: '20px' },
  { label: '22px', value: '22px' },
  { label: '24px', value: '24px' },
];

export const TEXT_ALIGN: FormElementDataModel[] = [
  { label: 'Left', value: 'left' },
  { label: 'Right', value: 'right' },
  { label: 'Center', value: 'center' },
];

export const FONT_STYLE: FormElementDataModel[] = [
  { label: 'Normal', value: 'normal' },
  { label: 'Italic', value: 'italic' },
  { label: 'Oblique', value: 'oblique' },
];

export const FONT_WEIGHT: FormElementDataModel[] = [
  { label: 'Normal', value: 'normal' },
  { label: 'Bold', value: 'bold' },
];

export const TEXT_DECORATION: FormElementDataModel[] = [
  { label: 'None', value: 'none' },
  { label: 'Underline', value: 'underline' },
  { label: 'Overline', value: 'overline' },
];

export const BORDER_STYLE: FormElementDataModel[] = [
  { label: 'Dotted', value: 'dotted' },
  { label: 'Dashed', value: 'dashed' },
  { label: 'Solid', value: 'solid' },
  { label: 'Double', value: 'double' },
];

export const WIDTH_IN_PERCENTAGE: FormElementDataModel[] = [
  { label: '100%', value: '100%' },
  { label: '75%', value: '75%' },
  { label: '50%', value: '50%' },
  { label: '25%', value: '25%' },
];

export const PRELOAD_VIDEO: FormElementDataModel[] = [
  { label: 'Yes', value: 'auto' },
  { label: 'No', value: 'none' },
];

export const AUTOPLAY_VIDEO: FormElementDataModel[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const SOCIAL_MEDIA_MJML_ICONS = {
  [MjmlSmName.facebook]:
    'https://app-production-storage-api.politeisland-fa948fee.eastus2.azurecontainerapps.io/AzureFileStorage/image/8db201d7-65e9-1c5e-acb3-6753af104652.png',
  [MjmlSmName.instagram]:
    'https://app-production-storage-api.politeisland-fa948fee.eastus2.azurecontainerapps.io/AzureFileStorage/image/c18b8751-9991-3d5e-0bb0-83bdd840509c.png',
  [MjmlSmName.twitter]:
    'https://app-production-storage-api.politeisland-fa948fee.eastus2.azurecontainerapps.io/AzureFileStorage/image/4671e901-cad3-5caf-7356-35e791434e54.png',
  [MjmlSmName.linkedin]:
    'https://app-production-storage-api.politeisland-fa948fee.eastus2.azurecontainerapps.io/AzureFileStorage/image/55b56305-247e-10d9-8445-e1b58baa456f.png',
};

export const SOCIAL_MEDIA_MJML_ICON_DEFAULT_COLOR = {
  [MjmlSmName.facebook]: '#3b5998',
  [MjmlSmName.instagram]: '#3f729b',
  [MjmlSmName.twitter]: '#55acee',
  [MjmlSmName.linkedin]: '#0077b5',
};

export const SHARE_ON_SM_COLORS = {
  [ShareOnSmType.facebook]: '#3b5998',
  [ShareOnSmType.twitter]: '#55acee',
  [ShareOnSmType.linkedIn]: '#0077b5',
  [ShareOnSmType.email]: '#0077b5',
};

export const SHARE_ON_ICONS = {
  [ShareOnSmType.facebook]:
    'https://app-production-gateway-api.politeisland-fa948fee.eastus2.azurecontainerapps.io/AzureFileStorage/image/8db201d7-65e9-1c5e-acb3-6753af104652.png',
  [ShareOnSmType.email]: '/assets/icons/mail.svg',
  [ShareOnSmType.twitter]:
    'https://app-production-gateway-api.politeisland-fa948fee.eastus2.azurecontainerapps.io/AzureFileStorage/image/4671e901-cad3-5caf-7356-35e791434e54.png',
  [ShareOnSmType.linkedIn]:
    'https://app-production-gateway-api.politeisland-fa948fee.eastus2.azurecontainerapps.io/AzureFileStorage/image/55b56305-247e-10d9-8445-e1b58baa456f.png',
};

export const DEFAULT_SM_NAMES = {
  [MjmlSmName.facebook]: 'Facebook',
  [MjmlSmName.instagram]: 'Instagram',
  [MjmlSmName.twitter]: 'X',
  [MjmlSmName.linkedin]: 'Linkedin',
};

export const SM_PLATFORMS: FormElementDataModel[] = [
  { label: 'Facebook', value: MjmlSmName.facebook },
  { label: 'Instagram', value: MjmlSmName.instagram },
  { label: 'X', value: MjmlSmName.twitter },
  { label: 'Linkedin', value: MjmlSmName.linkedin },
];

export const SHARE_ON_SM_PLATFORMS: FormElementDataModel[] = [
  { label: 'Facebook', value: ShareOnSmType.facebook },
  { label: 'X', value: ShareOnSmType.twitter },
  { label: 'LinkedIn', value: ShareOnSmType.linkedIn },
  { label: 'Email', value: ShareOnSmType.email },
];

export const DEFAULT_SHARE_ON_SM_PLATFORMS_NAMES = {
  [ShareOnSmType.facebook]: 'Facebook',
  [ShareOnSmType.email]: 'Email',
  [ShareOnSmType.twitter]: 'X',
  [ShareOnSmType.linkedIn]: 'LinkedIn',
};

export const TEMPLATE_TYPES: FormElementDataModel[] = [
  { label: 'All Templates', value: 'all' },
  {
    label: 'Layout with no restrictions',
    value: TemplateType.Layout.toString(),
  },
  {
    label: 'Component-based template',
    value: TemplateType.ComponentBased.toString(),
  },
  {
    label: 'Predesigned themed template',
    value: TemplateType.Themed.toString(),
  },
];

export const DEFAULT_MJML_TEMPLATE: MjmlElementModel = {
  tagName: MjmlTag.mjml,
  attributes: {
    owa: 'desktop',
  },
  children: [
    {
      tagName: MjmlTag.head,
      attributes: {},
      children: [
        {
          tagName: MjmlTag.font,
          attributes: {
            name: 'Roboto',
            href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap',
          },
        },
        {
          tagName: MjmlTag.font,
          attributes: {
            name: 'Oswald',
            href: 'https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;700&display=swap',
          },
        },
        {
          tagName: MjmlTag.style,
          attributes: { inline: 'inline' },
          content: '',
        },
      ],
    },
    {
      tagName: MjmlTag.body,
      attributes: { width: '900px', 'background-color': '#ffffff' },
      children: [
        {
          tagName: MjmlTag.wrapper,
          attributes: { padding: '0px', 'full-width': 'full-width' },
          children: [
            {
              tagName: MjmlTag.section,
              attributes: { padding: '0px' },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: { padding: '0px' },
                  children: [],
                },
              ],
            },
          ],
        },
        {
          tagName: MjmlTag.headerFooter,
          attributes: {},
          children: [],
          content: '',
        },
      ],
    },
  ],
};

export const P2P_EVENT_MJML_TEMPLATE: MjmlElementModel = {
  tagName: MjmlTag.mjml,
  attributes: {
    owa: 'desktop',
  },
  children: [
    {
      tagName: MjmlTag.head,
      attributes: {},
      children: [
        {
          tagName: MjmlTag.font,
          attributes: {
            name: 'Roboto',
            href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap',
          },
        },
        {
          tagName: MjmlTag.style,
          attributes: {
            inline: 'inline',
          },
          content: '',
        },
      ],
    },
    {
      tagName: MjmlTag.body,
      attributes: {
        width: '900px',
        'background-color': '#ffffff',
      },
      children: [
        {
          tagName: MjmlTag.wrapper,
          attributes: {
            padding: '0px',
            'full-width': 'full-width',
          },
          children: [
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-url': '/assets/images/layout-img1.png',
                'background-repeat': 'no-repeat',
                'padding-top': '0px',
                'padding-right': '0px',
                'padding-bottom': '0px',
                'padding-left': '0px',
                'background-color': 'transparent',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'padding-top': '0px',
                    'padding-right': '0px',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'background-color': '',
                  },
                  children: [
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'eventName',
                        'font-family': 'Roboto',
                        'font-size': '44px',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                        align: 'left',
                        'padding-top': '100px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        color: '#575757',
                        'container-background-color': '',
                      },
                      content: '<div></div>',
                    },
                  ],
                },
              ],
            },
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-color': 'transparent',
                'background-repeat': 'repeat',
                'background-url': '',
                'padding-bottom': '20px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '80px',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'background-color': '',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'padding-right': '0px',
                    'padding-top': '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        'container-background-color': '',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'padding-right': '25px',
                        'padding-top': '10px',
                      },
                      content: '<div><strong>DATE &amp; TIME</strong></div>',
                    },
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'eventScheduler',
                        color: '#000000',
                        'font-family': 'Roboto',
                        'font-size': '18px',
                        'container-background-color': '',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'padding-right': '25px',
                        'padding-top': '10px',
                        align: 'center',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                      },
                      content: '<div></div>',
                    },
                  ],
                },
              ],
            },
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-color': 'transparent',
                'background-repeat': 'repeat',
                'background-url': '',
                'padding-bottom': '20px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '0px',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'background-color': '',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'padding-right': '0px',
                    'padding-top': '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        'padding-top': '10px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'container-background-color': '',
                      },
                      content: '<div><strong>DESCRIPTION</strong></div>',
                    },
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'eventDescription',
                        color: '#000000',
                        'font-family': 'Roboto',
                        'font-size': '18px',
                        'container-background-color': '',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'padding-right': '25px',
                        'padding-top': '10px',
                        align: 'center',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                      },
                      content: '<div></div>',
                    },
                  ],
                },
              ],
            },
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-color': 'transparent',
                'background-repeat': 'repeat',
                'background-url': '',
                'padding-bottom': '0px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '0px',
              },
              // P2P Event Template Buttons
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.button,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'register',
                        href: '',
                        align: 'center',
                        'font-family': 'Helvetica',
                        'font-size': '14px',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                        border: '0px solid #414141',
                        'border-radius': '5px',
                        'inner-padding': '10px 45px',
                        'padding-top': '10px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'background-color': '#20a643',
                      },
                      content: 'REGISTER',
                    },
                  ],
                },
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.button,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'donate',
                        href: '',
                        align: 'center',
                        'font-family': 'Helvetica',
                        'font-size': '14px',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                        border: '0px solid #414141',
                        'border-radius': '5px',
                        'inner-padding': '10px 45px',
                        'padding-top': '10px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'background-color': '#20a643',
                      },
                      content: 'DONATE',
                    },
                  ],
                },
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.button,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'fundraiser',
                        href: '',
                        align: 'center',
                        'font-family': 'Helvetica',
                        'font-size': '14px',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                        border: '0px solid #414141',
                        'border-radius': '5px',
                        'inner-padding': '10px 15px',
                        'padding-top': '10px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'background-color': '#20a643',
                      },
                      content: 'CREATE A FUNDRAISER',
                    },
                  ],
                },
              ],
            },
            {
              tagName: MjmlTag.section,
              attributes: {},
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {},
                  children: [
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        customType: 'thermometer',
                      },
                    },
                  ],
                },
                {
                  tagName: MjmlTag.column,
                  attributes: {},
                  children: [
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        customType: 'donationActivity',
                        'show-donate-now-button': 'false',
                      },
                      content:
                        '<div><strong style="font-family: roboto; font-size: 16px; color: rgb(68, 68, 68);">Donor Activity</strong></div>',
                    },
                  ],
                },
              ],
            },
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-color': 'transparent',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {},
                  children: [
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'eventAccessibility',
                        color: '#74aadd',
                        'font-family': 'Roboto',
                        'font-size': '18px',
                        'container-background-color': '',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'padding-right': '25px',
                        'padding-top': '10px',
                        align: 'center',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                      },
                      content: '<div></div>',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const TIME_LABELS = {
  [SocialMediaMetricsFilterType.Last7Days]: 'Last 7 Days',
  [SocialMediaMetricsFilterType.LastMonth]: 'Last Month',
  [SocialMediaMetricsFilterType.Last3Months]: 'Last 3 Months',
  [SocialMediaMetricsFilterType.LastYear]: 'Last 12 Months',
};

export const TabSourceObj = {
  0: TemplateUsageType.Event,
  1: TemplateUsageType.Campaign,
  2: TemplateUsageType.SocialMedia,
  3: TemplateUsageType.DonationForms,
  4: TemplateUsageType.Letter,
};

export const TEMPLATE_RESTRICTION_TYPES: FormElementDataModel[] = [
  { label: 'Layout with no restrictions', value: TemplateType.Layout },
  { label: 'Component-based template', value: TemplateType.ComponentBased },
  { label: 'Predesigned themed template', value: TemplateType.Themed },
];

export const DEFAULT_EVENT_MJML_TEMPLATE: MjmlElementModel = {
  tagName: MjmlTag.mjml,
  attributes: { owa: 'desktop' },
  children: [
    {
      tagName: MjmlTag.head,
      attributes: {},
      children: [
        {
          tagName: MjmlTag.font,
          attributes: {
            name: 'Roboto',
            href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap',
          },
        },
        {
          tagName: MjmlTag.font,
          attributes: {
            name: 'Oswald',
            href: 'https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;700&display=swap',
          },
        },
        {
          tagName: MjmlTag.style,
          attributes: {
            inline: 'inline',
          },
          content: '',
        },
      ],
    },
    {
      tagName: MjmlTag.body,
      attributes: {
        width: '1195px',
        'background-color': '#ffffff',
      },
      children: [
        {
          tagName: MjmlTag.wrapper,
          attributes: {
            padding: '0px',
            'full-width': 'full-width',
            'background-repeat': 'repeat',
            'background-url': '',
          },
          children: [
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-url': '/assets/images/layout-img1.png',
                'background-repeat': 'no-repeat',
                'padding-top': '0px',
                'padding-right': '0px',
                'padding-bottom': '0px',
                'padding-left': '0px',
                'background-color': 'transparent',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'padding-top': '0px',
                    'padding-right': '0px',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'background-color': '',
                  },
                  children: [
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'eventName',
                        'font-family': 'Roboto',
                        'font-size': '44px',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                        align: 'left',
                        'padding-top': '100px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        color: '#575757',
                        'container-background-color': '',
                      },
                      content: '<div></div>',
                    },
                  ],
                },
              ],
            },
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-color': 'transparent',
                'background-repeat': 'repeat',
                'background-url': '',
                'padding-bottom': '20px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '80px',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'background-color': '',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'padding-right': '0px',
                    'padding-top': '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        /* 'color': '#20a643',
                         'font-family': 'Roboto',
                         'font-size': '20px',*/
                        'container-background-color': '',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'padding-right': '25px',
                        'padding-top': '10px',
                        /*'align': 'center'*/
                      },
                      content: '<div><strong>DATE &amp; TIME</strong></div>',
                    },
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'eventScheduler',
                        color: '#000000',
                        'font-family': 'Roboto',
                        'font-size': '18px',
                        'container-background-color': '',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'padding-right': '25px',
                        'padding-top': '10px',
                        align: 'center',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                      },
                      content: '<div></div>',
                    },
                  ],
                },
              ],
            },
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-color': 'transparent',
                'background-repeat': 'repeat',
                'background-url': '',
                'padding-bottom': '20px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '0px',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'background-color': '',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'padding-right': '0px',
                    'padding-top': '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        /* 'font-family': 'Roboto',
                         'font-size': '20px',
                         'align': 'center',*/
                        'padding-top': '10px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        /*'color': '#20a643',*/
                        'container-background-color': '',
                      },
                      content: '<div><strong>DESCRIPTION</strong></div>',
                    },
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'eventDescription',
                        color: '#000000',
                        'font-family': 'Roboto',
                        'font-size': '18px',
                        'container-background-color': '',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'padding-right': '25px',
                        'padding-top': '10px',
                        align: 'center',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                      },
                      content: '<div></div>',
                    },
                  ],
                },
              ],
            },
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-color': 'transparent',
                'background-repeat': 'repeat',
                'background-url': '',
                'padding-bottom': '0px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '0px',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'background-color': '',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'padding-right': '0px',
                    'padding-top': '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.button,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'register',
                        href: '',
                        align: 'center',
                        'font-family': 'Helvetica',
                        'font-size': '14px',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                        border: '0px solid #414141',
                        'border-radius': '5px',
                        'inner-padding': '10px 45px',
                        'padding-top': '10px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'background-color': '#20a643',
                      },
                      content: 'REGISTER',
                    },
                  ],
                },
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.button,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'donate',
                        href: '',
                        align: 'center',
                        'font-family': 'Helvetica',
                        'font-size': '14px',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                        border: '0px solid #414141',
                        'border-radius': '5px',
                        'inner-padding': '10px 45px',
                        'padding-top': '10px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'background-color': '#20a643',
                      },
                      content: 'DONATE',
                    },
                  ],
                },
              ],
            },
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-color': 'transparent',
                'background-repeat': 'repeat',
                'background-url': '',
                'padding-bottom': '20px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '20px',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'background-color': '',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'padding-right': '0px',
                    'padding-top': '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        /*'font-family': 'Roboto',
                        'font-size': '20px',
                        'align': 'center',*/
                        'padding-top': '10px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        /*'color': '#545454'*/
                      },
                      content:
                        '<div><strong>CONTACT INFORMATION</strong></div>',
                    },
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'eventContacts',
                        color: '#575757',
                        'font-family': 'Roboto',
                        'font-size': '18px',
                        'container-background-color': '',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'padding-right': '25px',
                        'padding-top': '10px',
                        align: 'center',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                      },
                      content: '<div></div>',
                    },
                  ],
                },
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                  },
                  children: [],
                },
              ],
            },
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-color': 'transparent',
                'background-repeat': 'repeat',
                'background-url': '',
                'padding-bottom': '40px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '40px',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'background-color': '',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'padding-right': '0px',
                    'padding-top': '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'eventAccessibility',
                        color: '#74aadd',
                        'font-family': 'Roboto',
                        'font-size': '18px',
                        'container-background-color': '',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'padding-right': '25px',
                        'padding-top': '10px',
                        align: 'center',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                      },
                      content: '<div></div>',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          tagName: MjmlTag.headerFooter,
          attributes: {},
          children: [],
          content: '',
        },
      ],
    },
  ],
};

export const DEFAULT_CAMPAIGN_MJML_TEMPLATE: MjmlElementModel = {
  tagName: MjmlTag.mjml,
  attributes: {
    owa: 'desktop',
  },
  children: [
    {
      tagName: MjmlTag.head,
      attributes: {},
      children: [
        {
          tagName: MjmlTag.font,
          attributes: {
            name: 'Roboto',
            href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap',
          },
        },
        {
          tagName: MjmlTag.font,
          attributes: {
            name: 'Oswald',
            href: 'https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;700&display=swap',
          },
        },
        {
          tagName: MjmlTag.style,
          attributes: {
            inline: 'inline',
          },
          content: '',
        },
      ],
    },
    {
      tagName: MjmlTag.body,
      attributes: {
        width: '900px',
        'background-color': '#ffffff',
      },
      children: [
        {
          tagName: MjmlTag.wrapper,
          attributes: {
            padding: '0px',
            'full-width': 'full-width',
          },
          children: [
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-url': '',
                'background-repeat': 'repeat',
                'padding-top': '0px',
                'padding-right': '0px',
                'padding-bottom': '0px',
                'padding-left': '0px',
                'background-color': 'transparent',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'padding-top': '0px',
                    'padding-right': '0px',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'background-color': '',
                  },
                  children: [
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'campaignName',
                        'font-family': 'Roboto',
                        'font-size': '38px',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                        align: 'center',
                        'padding-top': '10px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        color: '#66788a',
                      },
                      content: '<div></div>',
                    },
                  ],
                },
              ],
            },
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-color': 'transparent',
                'background-repeat': 'repeat',
                'background-url': '',
                'padding-bottom': '20px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '20px',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'background-color': '',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'padding-right': '0px',
                    'padding-top': '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        'font-size': '20px',
                        align: 'left',
                        'padding-top': '10px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                      },
                      content:
                        '<div style="font-family: Arial, sans-serif">Hello <span class="ql-placeholder-content" data-id="1" data-label="DonorName" spellcheck="false">﻿<span contenteditable="false"> [DonorName] </span>﻿</span></div>',
                    },
                  ],
                },
              ],
            },
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-color': 'transparent',
                'background-repeat': 'repeat',
                'background-url': '',
                'padding-bottom': '20px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '20px',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'background-color': '',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'padding-right': '0px',
                    'padding-top': '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.button,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'donate',
                        href: '',
                        align: 'center',
                        'font-family': 'Roboto',
                        'font-size': '18px',
                        'font-style': 'normal',
                        'font-weight': 'bold',
                        'text-decoration': 'none',
                        border: '0px solid #414141',
                        'border-radius': '5px',
                        'inner-padding': '10px 45px',
                        'padding-top': '10px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'background-color': '#ffc25d',
                      },
                      content: 'MAKE A DONATION',
                    },
                  ],
                },
              ],
            },
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-color': '#023665',
                'background-repeat': 'repeat',
                'background-url': '',
                'padding-bottom': '20px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '20px',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'background-color': '',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'padding-right': '0px',
                    'padding-top': '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.social,
                      attributes: {
                        padding: '10px 25px',
                        'sm-names':
                          '["facebook-noshare","instagram","twitter-noshare","linkedin-noshare"]',
                        mode: 'horizontal',
                        align: 'center',
                        'font-family': 'Roboto',
                        'font-size': '14px',
                        'font-style': 'normal',
                        'font-weight': 'normal',
                        'text-decoration': 'none',
                        'icon-size': '32px',
                        'inner-padding': '4px',
                        'border-radius': '3px',
                        'padding-top': '10px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        color: '#ffffff',
                      },
                      content: '',
                      children: [
                        {
                          tagName: MjmlTag.socialElement,
                          attributes: {
                            name: 'facebook-noshare',
                            src: 'https://app-production-storage-api.politeisland-fa948fee.eastus2.azurecontainerapps.io/AzureFileStorage/image/8db201d7-65e9-1c5e-acb3-6753af104652.png',
                            href: '',
                            title: '',
                            'background-color': '#f7d154',
                          },
                          content: 'Facebook',
                        },
                        {
                          tagName: MjmlTag.socialElement,
                          attributes: {
                            name: 'instagram',
                            src: 'https://app-production-storage-api.politeisland-fa948fee.eastus2.azurecontainerapps.io/AzureFileStorage/image/c18b8751-9991-3d5e-0bb0-83bdd840509c.png',
                            href: '',
                            title: '',
                            'background-color': '#f7d154',
                          },
                          content: 'Instagram',
                        },
                        {
                          tagName: MjmlTag.socialElement,
                          attributes: {
                            name: 'twitter-noshare',
                            src: 'https://app-production-storage-api.politeisland-fa948fee.eastus2.azurecontainerapps.io/AzureFileStorage/image/4671e901-cad3-5caf-7356-35e791434e54.png',
                            href: '',
                            title: '',
                            'background-color': '#f7d154',
                          },
                          content: 'X',
                        },
                        {
                          tagName: MjmlTag.socialElement,
                          attributes: {
                            name: 'linkedin-noshare',
                            src: 'https://app-production-storage-api.politeisland-fa948fee.eastus2.azurecontainerapps.io/AzureFileStorage/image/55b56305-247e-10d9-8445-e1b58baa456f.png',
                            href: '',
                            title: '',
                            'background-color': '#f7d154',
                          },
                          content: 'Linkedin',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-color': '#023665',
                'background-repeat': 'repeat',
                'background-url': '',
                'padding-bottom': '0px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '0px',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'background-color': '',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'padding-right': '0px',
                    'padding-top': '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.button,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'unsubscribe',
                        href: '',
                        align: 'center',
                        'font-family': 'Roboto',
                        'font-size': '13px',
                        'font-style': 'normal',
                        'font-weight': 'normal',
                        'text-decoration': 'none',
                        border: '0px solid #414141',
                        'border-radius': '3px',
                        'inner-padding': '10px 25px',
                        'padding-top': '10px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'background-color': '#023665',
                        color: '#ffffff',
                        deletable: 'false',
                      },
                      content:
                        'If you want to unsubscribe from these emails, please click here',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const UNSUBSCRIBE_CAMPAIGN_MJML_TEMPLATE: MjmlElementModel = {
  tagName: MjmlTag.mjml,
  attributes: {
    owa: 'desktop',
  },
  children: [
    {
      tagName: MjmlTag.head,
      attributes: {},
      children: [
        {
          tagName: MjmlTag.font,
          attributes: {
            name: 'Roboto',
            href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap',
          },
        },
        {
          tagName: MjmlTag.style,
          attributes: {
            inline: 'inline',
          },
          content: '',
        },
      ],
    },
    {
      tagName: MjmlTag.body,
      attributes: {
        width: '900px',
        'background-color': '#ffffff',
      },
      children: [
        {
          tagName: MjmlTag.wrapper,
          attributes: {
            padding: '0px',
            'full-width': 'full-width',
          },
          children: [
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-color': '#023665',
                'background-repeat': 'repeat',
                'background-url': '',
                'padding-bottom': '0px',
                'padding-left': '0px',
                'padding-right': '0px',
                'padding-top': '0px',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'background-color': '',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'padding-right': '0px',
                    'padding-top': '0px',
                  },
                  children: [
                    {
                      tagName: MjmlTag.button,
                      attributes: {
                        padding: '10px 25px',
                        customType: 'unsubscribe',
                        href: '',
                        align: 'center',
                        'font-family': 'Roboto',
                        'font-size': '13px',
                        'font-style': 'normal',
                        'font-weight': 'normal',
                        'text-decoration': 'none',
                        border: '0px solid #414141',
                        'border-radius': '3px',
                        'inner-padding': '10px 25px',
                        'padding-top': '10px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                        'background-color': '#023665',
                        color: '#ffffff',
                        deletable: 'false',
                      },
                      content:
                        'If you want to unsubscribe from these emails, please click here',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const DEFAULT_LETTER_CONTENT: string =
  '<div>[DonorFirstName] [DonorLastName]</div><div>[StreetAddress]</div><div>[City] [State] [ZipCode]</div><div><br></div><div>Dear [DonorFirstName],</div><div><br></div><div><br></div><div><br></div><div><br></div><div><br></div><div>Best,</div><div>[PrimaryContact]</div><div>[OrganizationName]</div><div><br></div><div><br></div>';

export const DEFAULT_LETTER_FOOTER_TEXT_CONTENT: string =
  '<div style="text-align: center;"><span class="ql-placeholder-content" data-id="PageNumber"data-label="PageNumber" spellcheck="false">﻿<span contenteditable="false">[PageNumber]</span>﻿</span></div>';

export const DEFAULT_LETTER_FOOTER_MJML_SECTION: MjmlElementModel = {
  tagName: MjmlTag.section,
  attributes: {
    customType: MjmlCustomType.letterFooterSection,
    'margin-top': 'auto',
    'css-class': 'letter-footer',
  },
  children: [
    {
      tagName: MjmlTag.column,
      attributes: {},
      children: [
        {
          tagName: MjmlTag.text,
          attributes: { letterFooterTextTool: 'true' },
          content: DEFAULT_LETTER_FOOTER_TEXT_CONTENT,
        },
      ],
    },
  ],
};

export const DEFAULT_LETTER_CAMPAIGN_MJML_TEMPLATE: MjmlElementModel = {
  tagName: MjmlTag.mjml,
  attributes: {},
  children: [
    {
      tagName: MjmlTag.head,
      attributes: {},
      children: [
        {
          tagName: MjmlTag.font,
          attributes: {
            name: 'Roboto',
            href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap',
          },
        },
        {
          tagName: MjmlTag.style,
          attributes: {
            inline: 'inline',
          },
          content: '',
        },
      ],
    },
    {
      tagName: MjmlTag.body,
      attributes: {
        width: '900px',
        'background-color': '#ffffff',
      },
      children: [
        {
          tagName: MjmlTag.wrapper,
          attributes: {
            padding: '0px',
            'full-width': 'full-width',
          },
          children: [
            {
              tagName: MjmlTag.section,
              attributes: {
                padding: '0px',
                'background-url': '',
                'background-repeat': 'repeat',
                'padding-top': '0px',
                'padding-right': '0px',
                'padding-bottom': '0px',
                'padding-left': '0px',
                'background-color': 'transparent',
              },
              children: [
                {
                  tagName: MjmlTag.column,
                  attributes: {
                    padding: '0px',
                    'padding-top': '0px',
                    'padding-right': '0px',
                    'padding-bottom': '0px',
                    'padding-left': '0px',
                    'background-color': '',
                  },
                  children: [
                    {
                      tagName: MjmlTag.text,
                      attributes: {
                        padding: '10px 25px',
                        'padding-top': '10px',
                        'padding-right': '25px',
                        'padding-bottom': '10px',
                        'padding-left': '25px',
                      },
                      content: DEFAULT_LETTER_CONTENT,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const DEFAULT_CAMPAIGN_LABEL_CONTENT: string =
  '<div style="text-align: center;"><br></div><div style="text-align: center;"><span class="ql-placeholder-content" data-id="DonorFirstName" data-label="Donor First Name" spellcheck="false">﻿<span contenteditable="false">[Donor First Name]</span>﻿</span> <span class="ql-placeholder-content" data-id="DonorLastName" data-label="Donor Last Name" spellcheck="false">﻿<span contenteditable="false">[Donor Last Name]</span>﻿</span></div><div style="text-align: center;"><span class="ql-placeholder-content" data-id="StreetAddress" data-label="Street Address" spellcheck="false">﻿<span contenteditable="false">[Street Address]</span>﻿</span> <span class="ql-placeholder-content" data-id="StreetAddress2" data-label="Street Address 2" spellcheck="false">﻿<span contenteditable="false">[Street Address 2]</span>﻿</span></div><div style="text-align: center;"><span class="ql-placeholder-content" data-id="City" data-label="City" spellcheck="false">﻿<span contenteditable="false">[City]</span>﻿</span> <span class="ql-placeholder-content" data-id="State" data-label="State" spellcheck="false">﻿<span contenteditable="false">[State]</span>﻿</span> <span class="ql-placeholder-content" data-id="ZipCode" data-label="Zip Code" spellcheck="false">﻿<span contenteditable="false">[Zip Code]</span>﻿</span></div>';

export const GenderOptions: FormElementDataModel[] = [
  { label: 'Prefer not to say', value: GenderType.PreferNotToSay },
  { label: 'Agender', value: GenderType.Agender },
  { label: 'Androgyne', value: GenderType.Androgyne },
  { label: 'Androgynous', value: GenderType.Androgynous },
  { label: 'Bigender', value: GenderType.Bigender },
  { label: 'Cis', value: GenderType.Cis },
  { label: 'Cis Female', value: GenderType.CisFemale },
  { label: 'Cis Man', value: GenderType.CisMan },
  { label: 'Cisgender', value: GenderType.Cisgender },
  { label: 'Cisgender Female', value: GenderType.CisgenderFemale },
  { label: 'Cisgender Male', value: GenderType.CisgenderMale },
  { label: 'Cisgender Man', value: GenderType.CisgenderMan },
  { label: 'Cisgender Woman', value: GenderType.CisgenderWoman },
  { label: 'Female', value: GenderType.Female },
  { label: 'Female to Male', value: GenderType.FemaleToMale },
  { label: 'FTM', value: GenderType.FTM },
  { label: 'Gender Fluid', value: GenderType.GenderFluid },
  { label: 'Gender Nonconforming', value: GenderType.GenderNonconforming },
  { label: 'Gender Questioning', value: GenderType.GenderQuestioning },
  { label: 'Gender Variant', value: GenderType.GenderVariant },
  { label: 'Genderqueer', value: GenderType.Genderqueer },
  { label: 'Intersex', value: GenderType.Intersex },
  { label: 'Male', value: GenderType.Male },
  { label: 'Male to Female', value: GenderType.MaleToFemale },
  { label: 'MTF', value: GenderType.MTF },
  { label: 'Neither', value: GenderType.Neither },
  { label: 'Neutrois', value: GenderType.Neutrois },
  { label: 'Non-binary', value: GenderType.NonBinary },
  { label: 'Other', value: GenderType.Other },
  { label: 'Pangender', value: GenderType.Pangender },
  { label: 'Trans', value: GenderType.Trans },
  { label: 'Trans Female', value: GenderType.TransFemale },
  { label: 'Trans Male', value: GenderType.TransMale },
  { label: 'Trans Man', value: GenderType.TransMan },
  { label: 'Trans Person', value: GenderType.TransPerson },
  { label: 'Trans Woman', value: GenderType.TransWoman },
  { label: 'Trans*', value: GenderType.TransX },
  { label: 'Trans* Female', value: GenderType.TransXFemale },
  { label: 'Trans* Male', value: GenderType.TransXMale },
  { label: 'Trans* Man', value: GenderType.TransXMan },
  { label: 'Trans* Person', value: GenderType.TransXPerson },
  { label: 'Trans* Woman', value: GenderType.TransXWoman },
  { label: 'Transfeminine', value: GenderType.Transfeminine },
  { label: 'Transgender', value: GenderType.Transgender },
  { label: 'Transgender Female', value: GenderType.TransgenderFemale },
  { label: 'Transgender Male', value: GenderType.TransgenderMale },
  { label: 'Transgender Man', value: GenderType.TransgenderMan },
  { label: 'Transgender Person', value: GenderType.TransgenderPerson },
  { label: 'Transgender Woman', value: GenderType.TransgenderWoman },
  { label: 'Transmasculine', value: GenderType.Transmasculine },
  { label: 'Transsexual', value: GenderType.Transsexual },
  { label: 'Transsexual Female', value: GenderType.TranssexualFemale },
  { label: 'Transsexual Male', value: GenderType.TransMale },
  { label: 'Transsexual Man', value: GenderType.TranssexualMan },
  { label: 'Transsexual Person', value: GenderType.TranssexualPerson },
  { label: 'Transsexual Woman', value: GenderType.TranssexualWoman },
  { label: 'Two-spirit', value: GenderType.TwoSpirit },
  { label: 'Woman', value: GenderType.Woman },
];

GenderOptions.sort(onSort);

export const DEFAULT_IMAGE_TEMPLATE: ImageTemplateModel = {
  layers: [],
  width: 1080,
  height: 1080,
  attributes: {},
};
GenderOptions.sort(onSort);

export const TERRITORIAL_ENTITY = {
  [TerritorialEntityType.Unknown]: 'Unknown',
  [TerritorialEntityType.AreaDistrict]: 'Area / District',
  [TerritorialEntityType.CEDEX]: 'CEDEX',
  [TerritorialEntityType.District]: 'District',
  [TerritorialEntityType.Emirate]: 'Emirate',
  [TerritorialEntityType.Neighborhood]: 'Neighborhood',
  [TerritorialEntityType.Oblast]: 'Oblast',
  [TerritorialEntityType.Prefecture]: 'Prefecture',
  [TerritorialEntityType.Townland]: 'Townland',
};

export const LOCALITY = {
  [LocalityType.Unknown]: 'Unknown',
  [LocalityType.City]: 'City',
  [LocalityType.CityCountry]: 'City / Country',
  [LocalityType.PostTown]: 'Post Town',
  [LocalityType.Suburb]: 'Suburb',
  [LocalityType.SuburbCity]: 'Suburb / City',
  [LocalityType.VillageTownshipCity]: 'Village / Township / City',
};

export const POLITICAL_UNIT = {
  [PoliticalUnitType.Unknown]: 'Unknown',
  [PoliticalUnitType.Department]: 'Department',
  [PoliticalUnitType.DoSi]: 'Do / Si',
  [PoliticalUnitType.Island]: 'Island',
  [PoliticalUnitType.Parish]: 'Parish',
  [PoliticalUnitType.Province]: 'Province',
  [PoliticalUnitType.State]: 'State',
};

export const CODE = {
  [CodeType.Unknown]: 'Unknown',
  [CodeType.EIRcode]: 'EIRcode',
  [CodeType.PinCode]: 'PIN-Code',
  [CodeType.PostalCode]: 'Postal Code',
  [CodeType.ZipCode]: 'Zip Code',
};

export const LanguagesOptions: FormElementDataModel[] = [
  { label: 'Arabic', value: LanguagesType.Arabic },
  { label: 'Bengali', value: LanguagesType.Bengali },
  { label: 'Cantonese', value: LanguagesType.Cantonese },
  { label: 'Chinese', value: LanguagesType.Chinese },
  { label: 'Dutch', value: LanguagesType.Dutch },
  { label: 'English', value: LanguagesType.English },
  { label: 'French', value: LanguagesType.French },
  { label: 'German', value: LanguagesType.German },
  { label: 'Hindi', value: LanguagesType.Hindi },
  { label: 'Italian', value: LanguagesType.Italian },
  { label: 'Japanese', value: LanguagesType.Japanese },
  { label: 'Javanese', value: LanguagesType.Javanese },
  { label: 'Korean', value: LanguagesType.Korean },
  { label: 'Lahnda', value: LanguagesType.Lahnda },
  { label: 'Marathi', value: LanguagesType.Marathi },
  { label: 'Netherlands', value: LanguagesType.Netherlands },
  { label: 'Norsk', value: LanguagesType.Norsk },
  { label: 'Polish', value: LanguagesType.Polish },
  { label: 'Portuguese', value: LanguagesType.Portuguese },
  { label: 'Russian', value: LanguagesType.Russian },
  { label: 'Serbian', value: LanguagesType.Serbian },
  { label: 'Spanish', value: LanguagesType.Spanish },
  { label: 'Swahili', value: LanguagesType.Swahili },
  { label: 'Tamil', value: LanguagesType.Tamil },
  { label: 'Telugu', value: LanguagesType.Telugu },
  { label: 'Turkish', value: LanguagesType.Turkish },
  { label: 'Vietnamese', value: LanguagesType.Vietnamese },
  { label: 'Urdu', value: LanguagesType.Urdu },
];

export const YOUTUBE_CATEGORY: FormElementDataModel[] = [
  { label: 'Film & Animation', value: YouTubeCategoryType.FilmAnimation },
  { label: 'Autos & Vehicles', value: YouTubeCategoryType.AutosVehicles },
  { label: 'Music', value: YouTubeCategoryType.Music },
  { label: 'Pets & Animals', value: YouTubeCategoryType.PetsAnimals },
  { label: 'Sports', value: YouTubeCategoryType.Sports },
  { label: 'Travel & Events', value: YouTubeCategoryType.TravelEvents },
  { label: 'Gaming', value: YouTubeCategoryType.Gaming },
  { label: 'People & Blogs', value: YouTubeCategoryType.PeopleBlogs },
  { label: 'Comedy', value: YouTubeCategoryType.Comedy },
  { label: 'Entertainment', value: YouTubeCategoryType.Entertainment },
  { label: 'News & Politics', value: YouTubeCategoryType.NewsPolitics },
  { label: 'Howto & Style', value: YouTubeCategoryType.HowtoStyle },
  { label: 'Education', value: YouTubeCategoryType.Education },
  {
    label: 'Science & Technology',
    value: YouTubeCategoryType.ScienceTechnology,
  },
  {
    label: 'Nonprofits & Activism',
    value: YouTubeCategoryType.NonprofitsActivism,
  },
];

export const KNOWN_PLACEHOLDERS: number[] = [1, 2, 3, 4, 6, 7, 8, 9, 10];

export const DEFAULT_THANK_YOU_PAGE_HTML: string =
  '<div style="text-align: center;"><span style="color: #3a3b3f; font-size: 36px;">Thank you for your Donation!</span></div>';

export const DEFAULT_PAYMENT_FORM: FbTemplateModel = {
  infoPage: {
    attributes: {},
    elements: [
      {
        type: FbElementType.DonationInfo,
        attributes: {},
        required: true,
        text: '',
        amountToDonateText:
          '<div><span style="font-size: 16px; font-family: arial;">Amount to donate *</span></div>',
        isAnonymous: true,
        isRecurring: true,
        allowWeekly: true,
        allowMonthly: true,
        allowYearly: true,
        isRecurringWithEndDate: true,
        isPledgeType: true,
        isHonorGift: true,
        isInSupportOf: true,
        isHonorGiftWithSendEmail: true,
        isDonationCost: true,
        donationAmounts: ['15', '25', '40', '50'],
        customDisclaimers: [],
        disclaimerCheckBoxText: DEFAULT_DISCLAIMER_CHECKBOX_TEXT,
        allowOwnAmount: true,
        funds: '[]',
        typesOfDonations: 0,
        tiersOptions:
          '[{"name":"","description":"","ammount":"","imgUrl":"","isRecurring":true,"allowWeekly":true,"allowMonthly":true,"allowYearly":true,"allowOwnAmount":true,"isEndDate":true,"isPledgeType":true,"selectedTag":"","isCreateTagShow":false,"isTier":true}]'
      },
    ],
  },
  paymentPage: {
    attributes: {},
    elements: [
      {
        type: FbElementType.PAC,
        attributes: {
          mainLabel:
            'Please select the option if you are an Individual or Organization:',
          mainLabelAlignment: AlignmentType.Top,
        },
        required: true,
        federalIDRequired: false,
      },
      {
        type: FbElementType.FullName,
        attributes: {
          mainLabel: 'Name',
          mainLabelAlignment: AlignmentType.Top,
          firstName: 'First Name',
          lastName: 'Last Name',
        },
        required: true,
      },
      {
        type: FbElementType.Email,
        attributes: {
          mainLabel: 'Email',
          mainLabelAlignment: AlignmentType.Top,
          subLabel: 'example@email.com',
        },
        required: true,
      },
      {
        type: FbElementType.Address,
        attributes: {
          mainLabel: 'Billing Address',
          mainLabelAlignment: AlignmentType.Top,

          billingStreetAddress: 'Billing Street Address',
          streetAddress2: 'Street Address Line 2',
          country: 'Country',

          areaDistrict: 'Area / District',
          cedex: 'CEDEX',
          district: 'District',
          emirate: 'Emirate',
          neighborhood: 'Neighborhood',
          oblast: 'Oblast',
          prefecture: 'Prefecture',
          townland: 'Townland',

          city: 'City',
          cityCountry: 'City / Country',
          postTown: 'Post Town',
          suburb: 'Suburb',
          suburbCity: 'Suburb / City',
          villageTownshipCity: 'Village / Township / City',

          state: 'State',
          department: 'Department',
          doSi: 'Do / Si',
          island: 'Island',
          parish: 'Parish',
          province: 'Province',

          zipCode: 'Zip Code',
          eirCode: 'EIRcode',
          pinCode: 'PIN-Code',
          postalCode: 'Postal Code',
        },
        required: true,
      },
      {
        type: FbElementType.Phone,
        attributes: {
          mainLabel: 'Phone Number',
          mainLabelAlignment: AlignmentType.Top,
          subLabel: 'Please fill in a valid Phone Number',
        },
        required: true,
      },
      {
        type: FbElementType.Occupation,
        attributes: {
          mainLabel: 'Occupation',
          mainLabelAlignment: AlignmentType.Top,
          subLabel: 'Please fill in Occupation',
        },
        required: true,
      },
      {
        type: FbElementType.EmployerName,
        attributes: {
          mainLabel: 'Employer Name',
          mainLabelAlignment: AlignmentType.Top,
          subLabel: 'Please fill in Employer Name',
        },
        required: true,
      },
      {
        type: FbElementType.EmployerMailingAddress,
        attributes: {
          mainLabel: 'Employer Mailing Address',
          mainLabelAlignment: AlignmentType.Top,

          billingStreetAddress: 'Employer Street Address',
          streetAddress2: 'Employer Street Address Line 2',
          country: 'Country',

          areaDistrict: 'Area / District',
          cedex: 'CEDEX',
          district: 'District',
          emirate: 'Emirate',
          neighborhood: 'Neighborhood',
          oblast: 'Oblast',
          prefecture: 'Prefecture',
          townland: 'Townland',

          city: 'City',
          cityCountry: 'City / Country',
          postTown: 'Post Town',
          suburb: 'Suburb',
          suburbCity: 'Suburb / City',
          villageTownshipCity: 'Village / Township / City',

          state: 'State',
          department: 'Department',
          doSi: 'Do / Si',
          island: 'Island',
          parish: 'Parish',
          province: 'Province',

          zipCode: 'Zip Code',
          eirCode: 'EIRcode',
          pinCode: 'PIN-Code',
          postalCode: 'Postal Code',
        },
        required: true,
      },
      {
        type: FbElementType.PaymentDetails,
        attributes: {
          mainLabel: 'Payment Details',
          mainLabelAlignment: AlignmentType.Top,
          paymentMethods: AvailablePaymentMethods.All,
          passMerchantFee: PassMerchantFee.Yes,
          doesClientCoverFee: 'false',
          isPassingFeeDefault: 'true',
          feeText:
            'Thank you for your generosity! Donation fees can really add up. By clicking this box, you agree to pay the fees for your donation transaction so 100% of your donation will go towards our cause.',
        },
        required: true,
      },
    ],
  },
  topImage: {
    type: FbElementType.TopImage,
    attributes: {
      settings: '[{"url":"/assets/images/home.png","position":"center"}]',
      width: '1080px',
      height: '150px',
      paddingBottom: '0px',
      paddingTop: '0px',
      paddingRight: '0px',
      paddingLeft: '0px',
    },
    required: true,
  },
  headerFooter: {
    type: FbElementType.HeaderFooter,
    attributes: {
      logoSrc: '/assets/images/logo.png',
      logoSize: HeaderSize.Medium,
      background: '#023665',
      logoPosition: AlignmentType.Left,
      logoTarget: 'https://www.theauxilia.com/',

      facebookLink: '',
      twitterLink: '',
      instagramLink: '',
      linkedInLink: '',
    },
    required: true,
  },
  width: 760,
  pageColor: '#ECEDF3',
  formColor: '#ffffff',
  fontColor: '#2C3345',
  inputBackground: '#ffffff',
  spacing: 10,
  formBgImg: '',
  backgroundRepeat: 'repeat',
  backgroundSize: 'auto',
  fontFamily: 'Roboto',
  fontSize: 16,

  nextButtonLabel: 'Next',
  donateButtonLabel: 'Donate',
  primaryButtonColor: '#0064be',
  primaryButtonColorChanged: false,
  primaryTextButtonColor: '#ffffff',
  secondaryButtonColor: '#0064be',
  secondaryButtonColorChanged: false,
  secondaryTextButtonColor: '#ffffff',
  borderCheckboxColor: '#6A6A6A',
  donationBubbleDefaultColor: '#afafaf',

  thankYouPageHtml: DEFAULT_THANK_YOU_PAGE_HTML,
  isCustomThankYouPage: false,
  customThankYouPageUrl: '',
};

export const LABEL_ALIGN: FormElementDataModel[] = [
  { label: 'Left', value: AlignmentType.Left },
  { label: 'Right', value: AlignmentType.Right },
  { label: 'Top', value: AlignmentType.Top },
];

export const FB_ALIGN: FormElementDataModel[] = [
  { label: 'Left', value: AlignmentType.Left },
  { label: 'Right', value: AlignmentType.Right },
  { label: 'Center', value: AlignmentType.Top },
];

export const FB_SIZE: FormElementDataModel[] = [
  { label: 'Large', value: HeaderSize.Large },
  { label: 'Medium', value: HeaderSize.Medium },
  { label: 'Small', value: HeaderSize.Small },
];

export const FB_PAYMENT_METHODS: FormElementDataModel[] = [
  { label: 'All', value: AvailablePaymentMethods.All },
  { label: 'Credit Card', value: AvailablePaymentMethods.CreditCard },
  { label: 'ACH', value: AvailablePaymentMethods.ACH },
];

export const FB_PASS_MERCHANT_FEE: FormElementDataModel[] = [
  { label: 'Yes', value: PassMerchantFee.Yes },
  { label: 'No', value: PassMerchantFee.No },
];

export const FB_FORM_STATUS: FormElementDataModel[] = [
  { label: 'Active', value: FbTemplateStatusType.Active },
  { label: 'Inactive', value: FbTemplateStatusType.Inactive },
  { label: 'Disable on Date', value: FbTemplateStatusType.DisableOnDate },
];

export const DEFAULT_DONATION_PAYMENT_PAGE: FormElementDataModel[] = [
  { label: 'Yes', value: false },
  { label: 'No', value: true },
];

export const FB_DONATION_EMAIL_DISABLE: FormElementDataModel[] = [
  { label: 'Yes', value: false },
  { label: 'No', value: true },
];

export const DonationStatusTypes = {
  [DonationStatusType.Authorized]: 'Authorized',
  [DonationStatusType.Canceled]: 'Canceled',
  [DonationStatusType.Completed]: 'Completed',
  [DonationStatusType.Declined]: 'Declined',
  [DonationStatusType.Deferred]: 'Deferred',
  [DonationStatusType.InProgress]: 'In Progress',
  [DonationStatusType.Pending]: 'Pending',
  [DonationStatusType.Refunded]: 'Refunded',
  [DonationStatusType.Retry]: 'Retry',
};

export const TYPES_OF_DONATIONS: FormElementDataModel[] = [
  { label: 'Standard Donation', value: TypesOfDonationsEnum.Standard },
  { label: 'Tier Pricing Options', value: TypesOfDonationsEnum.Tier },
];

export const DONATION_PAYMENT_PAGE_TYPES_OPTIONS: FormElementDataModel[] = [
  {
    label: 'Auxilia Default Donation Payment Page',
    value: DonationPaymentPageType.AuxiliaDefault,
  },
  {
    label: 'Custom Default Donation Payment Page (from Templates)',
    value: DonationPaymentPageType.ClientDefault,
  },
  {
    label: 'Create Donation Payment Page',
    value: DonationPaymentPageType.Custom,
  },
];

export const AUXILIA_DEFAULT_PAYMENT_TEMPLATE_IMG: string =
  '/assets/images/template.png';

export const ADD_ON_TYPE_OPTIONS: FormElementDataModel[] = [
  { label: 'Class / Training', value: TicketAddonTypeEnum.ClassTraining },
  { label: 'Competition', value: TicketAddonTypeEnum.Competition },
  {
    label: 'Concert / Performance',
    value: TicketAddonTypeEnum.ConcertPerformance,
  },
  { label: 'Meal', value: TicketAddonTypeEnum.Meal },
  { label: 'Festival', value: TicketAddonTypeEnum.Festival },
  { label: 'Merchandise', value: TicketAddonTypeEnum.Merchandise },
  { label: 'Networking Event', value: TicketAddonTypeEnum.NetworkingEvent },
  { label: 'Other', value: TicketAddonTypeEnum.Other },
  { label: 'Race', value: TicketAddonTypeEnum.Race },
  { label: 'Seminar', value: TicketAddonTypeEnum.Seminar },
  { label: 'Social Gathering', value: TicketAddonTypeEnum.SocialGathering },
  { label: 'Tournament', value: TicketAddonTypeEnum.Tournament },
];
ADD_ON_TYPE_OPTIONS.sort(onSort);

export const AddonTypes = {
  [TicketAddonTypeEnum.ClassTraining]: 'Class / Training',
  [TicketAddonTypeEnum.Competition]: 'Competition',
  [TicketAddonTypeEnum.ConcertPerformance]: 'Concert / Performance',
  [TicketAddonTypeEnum.Meal]: 'Meal',
  [TicketAddonTypeEnum.Festival]: 'Festival',
  [TicketAddonTypeEnum.Merchandise]: 'Merchandise',
  [TicketAddonTypeEnum.NetworkingEvent]: 'Networking Event',
  [TicketAddonTypeEnum.Other]: 'Other',
  [TicketAddonTypeEnum.Race]: 'Race',
  [TicketAddonTypeEnum.Seminar]: 'Seminar',
  [TicketAddonTypeEnum.SocialGathering]: 'Social Gathering',
  [TicketAddonTypeEnum.Tournament]: 'Tournament',
};

export const COUPON_TYPES: FormElementDataModel[] = [
  { label: 'Percent', value: CouponValue.Percent },
  { label: 'Dollar', value: CouponValue.Dollar },
];

export const TOOLTIPS_TEXT = {
  inPackageCount:
    'This means the number of tickets that the registrant will be required to purchase when buying a ticket.',
  number:
    'This is referring to the number of tickets of this type that are available for purchase.',
  maxBuyCount:
    'This is referring to the maximum number of tickets that a user may buy during one transaction.',
  deductibleAmount:
    'This value will be how much a donor may write off from their taxes in a charitable gift from purchasing this ticket.',
  startDate: 'The date that the ticket becomes available for purchase.',
  endDate: 'The last date that an event registrant can purchase the ticket.',
};

export const TIME_RANGE = {
  [CreatedTimeAgoType.Minute]: { singular: 'minute', plural: 'minutes' },
  [CreatedTimeAgoType.Hour]: { singular: 'hour', plural: 'hours' },
  [CreatedTimeAgoType.Day]: { singular: 'day', plural: 'days' },
  [CreatedTimeAgoType.Week]: { singular: 'week', plural: 'weeks' },
  [CreatedTimeAgoType.Month]: { singular: 'month', plural: 'months' },
  [CreatedTimeAgoType.Year]: { singular: 'year', plural: 'years' },
};

export const DEFAULT_ADD_ON_LABEL = 'Add-On';
export const DEFAULT_PROMOCODE_LABEL = 'Name';

export const TOOLTIP_TEXT_FOR_FONTS: string =
  'It is possible that your email client might not be able to display text as you expect with the chosen font';
export const DISPLAY_COUNT_OPTIONS: FormElementDataModel[] = [
  { value: '0', label: 'All' },
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '25', label: '25' },
];

export const TIME_FRAMES_OPTIONS: FormElementDataModel[] = [
  {
    label: 'Last Week',
    value: TimeFramesTypes.LastWeek,
  },
  {
    label: 'Last Month',
    value: TimeFramesTypes.LastMonth,
  },
  {
    label: 'Last Quarter',
    value: TimeFramesTypes.LastQuarter,
  },
  {
    label: 'Last Year',
    value: TimeFramesTypes.LastYear,
  },
  {
    label: 'Specific Date',
    value: TimeFramesTypes.SpecificDate,
  },
];

export const SM_PROVIDERS: SocialProviderEnum[] = [
  SocialProviderEnum.Facebook,
  SocialProviderEnum.Twitter,
  SocialProviderEnum.Instagram,
  SocialProviderEnum.Linkedin,
];
export const SM_PROVIDERS_MEDIA_CONTENT_LENGTH = {
  [SocialProviderEnum.Facebook]: 10,
  [SocialProviderEnum.Twitter]: 4,
  [SocialProviderEnum.Instagram]: 1,
  [SocialProviderEnum.Linkedin]: 9,
};

export const PERIOD_OPTIONS: FormElementDataModel[] = [
  { label: 'Custom', value: ReportPeriodType.Custom },
  { label: 'Calendar year', value: ReportPeriodType.CalendarYear },
  { label: '1st quarter', value: ReportPeriodType.FirstQuarter },
  { label: '2nd quarter', value: ReportPeriodType.SecondQuarter },
  { label: '3rd quarter', value: ReportPeriodType.ThirdQuarter },
  { label: '4th quarter', value: ReportPeriodType.FourthQuarter },
];

export const TIME_ZONES: TimeZoneModel[] = [
  {
    id: 5,
    name: 'Pacific Standard Time',
    description: '(GMT-08:00) Pacific Time (US and Canada); Tijuana',
    offset: -8,
    shortName: 'PST',
    canonical: 'America/Tijuana',
    gmtOffset: 'GMT-08:00',
  },
  {
    id: 6,
    name: 'Mountain Standard Time',
    description: '(GMT-07:00) Mountain Time (US and Canada)',
    offset: -7,
    shortName: 'MST',
    canonical: 'America/Denver',
    gmtOffset: 'GMT-07:00',
  },
  {
    id: 9,
    name: 'Central Standard Time',
    description: '(GMT-06:00) Central Time (US and Canada)',
    offset: -6,
    shortName: 'CST',
    canonical: 'America/Mexico_City',
    gmtOffset: 'GMT-06:00',
  },
  {
    id: 13,
    name: 'Eastern Standard Time',
    description: '(GMT-05:00) Eastern Time (US and Canada)',
    offset: -5,
    shortName: 'EST',
    canonical: 'America/New_York',
    gmtOffset: 'GMT-05:00',
  },
  {
    id: 1,
    name: 'Dateline Standard Time',
    description: '(GMT-12:00) International Date Line West',
    offset: -12,
    shortName: 'IDLW',
    canonical: 'Etc/GMT+12',
    gmtOffset: 'GMT-12:00',
  },
  {
    id: 2,
    name: 'Samoa Standard Time',
    description: '(GMT-11:00) Midway Island, Samoa',
    offset: -11,
    shortName: 'SST',
    canonical: 'Pacific/Samoa',
    gmtOffset: 'GMT-11:00',
  },
  {
    id: 3,
    name: 'Hawaiian Standard Time',
    description: '(GMT-10:00) Hawaii',
    offset: -10,
    shortName: 'HST',
    canonical: 'Pacific/Honolulu',
    gmtOffset: 'GMT-10:00',
  },
  {
    id: 4,
    name: 'Alaskan Standard Time',
    description: '(GMT-09:00) Alaska',
    offset: -9,
    shortName: 'AKST',
    canonical: 'America/Anchorage',
    gmtOffset: 'GMT-09:00',
  },
  {
    id: 14,
    name: 'US Eastern Standard Time',
    description: '(GMT-05:00) Indiana (East)',
    offset: -5,
    shortName: 'EST',
    canonical: 'US/East-Indiana',
    gmtOffset: 'GMT-05:00',
  },
  {
    id: 16,
    name: 'Atlantic Standard Time',
    description: '(GMT-04:00) Atlantic Time (Canada)',
    offset: -4,
    shortName: 'AST',
    canonical: 'America/Goose_Bay',
    gmtOffset: 'GMT-04:00',
  },
  {
    id: 17,
    name: 'SA Western Standard Time',
    description: '(GMT-04:00) Caracas, La Paz',
    offset: -4,
    shortName: 'BOT',
    canonical: 'America/Caracas',
    gmtOffset: 'GMT-04:00',
  },
  {
    id: 18,
    name: 'Pacific SA Standard Time',
    description: '(GMT-04:00) Santiago',
    offset: -4,
    shortName: 'CLT',
    canonical: 'America/Santiago',
    gmtOffset: 'GMT-04:00',
  },
  {
    id: 19,
    name: 'Newfoundland Standard Time',
    description: '(GMT-03:30) Newfoundland and Labrador',
    offset: -3.5,
    shortName: 'NST',
    canonical: 'America/St_Johns',
    gmtOffset: 'GMT-03:30',
  },
  {
    id: 20,
    name: 'E. South America Standard Time',
    description: '(GMT-03:00) Brasilia',
    offset: -3,
    shortName: 'BRT',
    canonical: 'America/Argentina/Buenos_Aires',
    gmtOffset: 'GMT-03:00',
  },
  {
    id: 24,
    name: 'Azores Standard Time',
    description: '(GMT-01:00) Azores',
    offset: -1,
    shortName: 'AZOT',
    canonical: 'Atlantic/Azores',
    gmtOffset: 'GMT-01:00)',
  },
  {
    id: 25,
    name: 'Cape Verde Standard Time',
    description: '(GMT-01:00) Cape Verde Islands',
    offset: -1,
    shortName: 'CVT',
    canonical: 'Atlantic/Cape_Verde',
    gmtOffset: 'GMT-01:00)',
  },
  {
    id: 26,
    name: 'GMT Standard Time',
    description: '(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London',
    offset: 0,
    shortName: 'GMT',
    canonical: 'Europe/London',
    gmtOffset: 'GMT+00:00',
  },
  {
    id: 28,
    name: 'Central Europe Standard Time',
    description:
      '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    offset: 1,
    shortName: 'CET',
    canonical: 'Europe/Belgrade',
    gmtOffset: 'GMT+01:00',
  },
  {
    id: 29,
    name: 'Central European Standard Time',
    description: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    offset: 1,
    shortName: 'CET',
    canonical: 'Europe/Warsaw',
    gmtOffset: 'GMT+01:00',
  },
  {
    id: 30,
    name: 'Romance Standard Time',
    description: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
    offset: 1,
    shortName: 'CET',
    canonical: 'Europe/Brussels',
    gmtOffset: 'GMT+01:00',
  },
  {
    id: 31,
    name: 'W. Europe Standard Time',
    description: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    offset: 1,
    shortName: 'CET',
    canonical: 'Europe/Stockholm',
    gmtOffset: 'GMT+01:00',
  },
  {
    id: 32,
    name: 'W. Central Africa Standard Time',
    description: '(GMT+01:00) West Central Africa',
    offset: 1,
    shortName: 'WAT',
    canonical: 'Africa/Lagos',
    gmtOffset: 'GMT+01:00',
  },
  {
    id: 33,
    name: 'E. Europe Standard Time',
    description: '(GMT+02:00) Bucharest',
    offset: 2,
    shortName: 'EET',
    canonical: 'Europe/Bucharest',
    gmtOffset: 'GMT+02:00',
  },
  {
    id: 35,
    name: 'FLE Standard Time',
    description: '(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
    offset: 2,
    shortName: 'EET',
    canonical: 'Europe/Vilnius',
    gmtOffset: 'GMT+02:00',
  },
  {
    id: 37,
    name: 'Israel Standard Time',
    description: '(GMT+02:00) Jerusalem',
    offset: 2,
    shortName: 'IST',
    canonical: 'Asia/Jerusalem',
    gmtOffset: 'GMT+02:00',
  },
  {
    id: 39,
    name: 'Belarus Standard Time',
    description: '(GMT+03:00) Minsk',
    offset: 3,
    shortName: 'FET',
    canonical: 'Europe/Minsk',
    gmtOffset: 'GMT+03:00',
  },
  {
    id: 40,
    name: 'Russian Standard Time',
    description: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
    offset: 3,
    shortName: 'MSK',
    canonical: 'Europe/Moscow',
    gmtOffset: 'GMT+03:00',
  },
  {
    id: 42,
    name: 'E. Africa Standard Time',
    description: '(GMT+03:00) Nairobi',
    offset: 3,
    shortName: 'EAT',
    canonical: 'Africa/Nairobi',
    gmtOffset: 'GMT+03:00',
  },
  {
    id: 43,
    name: 'Arabic Standard Time',
    description: '(GMT+03:00) Baghdad',
    offset: 3,
    shortName: 'AST',
    canonical: 'Asia/Baghdad',
    gmtOffset: 'GMT+03:00',
  },
  {
    id: 44,
    name: 'Iran Standard Time',
    description: '(GMT+03:30) Tehran',
    offset: 3.5,
    shortName: 'IRST',
    canonical: 'Asia/Tehran',
    gmtOffset: 'GMT+03:00',
  },
  {
    id: 45,
    name: 'Arabian Standard Time',
    description: '(GMT+04:00) Abu Dhabi, Muscat',
    offset: 4,
    shortName: 'AST',
    canonical: 'Asia/Dubai',
    gmtOffset: 'GMT+04:00',
  },
  {
    id: 46,
    name: 'Caucasus Standard Time',
    description: '(GMT+04:00) Baku, Tbilisi, Yerevan',
    offset: 4,
    shortName: 'GET',
    canonical: 'Asia/Tbilisi',
    gmtOffset: 'GMT+04:00',
  },
  {
    id: 47,
    name: 'Afghanistan Standard Time',
    description: '(GMT+04:30) Kabul',
    offset: 4.5,
    shortName: 'AFT',
    canonical: 'Asia/Kabul',
    gmtOffset: 'GMT+04:30',
  },
  {
    id: 48,
    name: 'West Asia Standard Time',
    description: '(GMT+05:00) Maldives',
    offset: 5,
    shortName: 'MVT',
    canonical: 'Indian/Maldives',
    gmtOffset: 'GMT+05:00',
  },
  {
    id: 49,
    name: 'West Asia Standard Time',
    description: '(GMT+05:00) Islamabad, Karachi, Tashkent',
    offset: 5,
    shortName: 'UZT',
    canonical: 'Asia/Karachi',
    gmtOffset: 'GMT+05:00',
  },
  {
    id: 50,
    name: 'India Standard Time',
    description: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    offset: 5.5,
    shortName: 'IST',
    canonical: 'Asia/Kolkata',
    gmtOffset: 'GMT+05:30',
  },
  {
    id: 51,
    name: 'Nepal Standard Time',
    description: '(GMT+05:45) Kathmandu',
    offset: 5.75,
    shortName: 'NPT',
    canonical: 'Asia/Kathmandu',
    gmtOffset: 'GMT+05:45',
  },
  {
    id: 52,
    name: 'Central Asia Standard Time',
    description: '(GMT+06:00) Dhaka',
    offset: 6,
    shortName: 'BST',
    canonical: 'Asia/Dhaka',
    gmtOffset: 'GMT+06:00',
  },
  {
    id: 55,
    name: 'Myanmar Standard Time',
    description: '(GMT+06:30) Yangon Rangoon',
    offset: 6.5,
    shortName: 'MMT',
    canonical: 'Asia/Yangon',
    gmtOffset: 'GMT+06:30',
  },
  {
    id: 56,
    name: 'SE Asia Standard Time',
    description: '(GMT+07:00) Bangkok, Hanoi, Jakarta',
    offset: 7,
    shortName: 'THA',
    canonical: 'Asia/Bangkok',
    gmtOffset: 'GMT+07:00',
  },
  {
    id: 58,
    name: 'China Standard Time',
    description: '(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi',
    offset: 8,
    shortName: 'CST',
    canonical: 'Asia/Shanghai',
    gmtOffset: 'GMT+08:00',
  },
  {
    id: 59,
    name: 'Singapore Standard Time',
    description: '(GMT+08:00) Kuala Lumpur, Singapore',
    offset: 8,
    shortName: 'SST',
    canonical: 'Asia/Singapore',
    gmtOffset: 'GMT+08:00',
  },
  {
    id: 60,
    name: 'Taipei Standard Time',
    description: '(GMT+08:00) Taipei',
    offset: 8,
    shortName: 'CST',
    canonical: 'Asia/Taipei',
    gmtOffset: 'GMT+08:00',
  },
  {
    id: 61,
    name: 'W. Australia Standard Time',
    description: '(GMT+08:00) Perth',
    offset: 8,
    shortName: 'AWST',
    canonical: 'Australia/Perth',
    gmtOffset: 'GMT+08:00',
  },
  {
    id: 63,
    name: 'Korea Standard Time',
    description: '(GMT+09:00) Seoul',
    offset: 9,
    shortName: 'KST',
    canonical: 'Asia/Seoul',
    gmtOffset: 'GMT+09:00',
  },
  {
    id: 64,
    name: 'Tokyo Standard Time',
    description: '(GMT+09:00) Osaka, Sapporo, Tokyo',
    offset: 9,
    shortName: 'JST',
    canonical: 'Asia/Tokyo',
    gmtOffset: 'GMT+09:00',
  },
  {
    id: 67,
    name: 'Cen. Australia Standard Time',
    description: '(GMT+09:30) Adelaide',
    offset: 9.5,
    shortName: 'ACST',
    canonical: 'Australia/Adelaide',
    gmtOffset: 'GMT+09:30',
  },
  {
    id: 68,
    name: 'AUS Eastern Standard Time',
    description: '(GMT+10:00) Canberra, Melbourne, Sydney',
    offset: 10,
    shortName: 'AEST',
    canonical: 'Australia/Sydney',
    gmtOffset: 'GMT+10:00',
  },
  {
    id: 69,
    name: 'E. Australia Standard Time',
    description: '(GMT+10:00) Brisbane',
    offset: 10,
    shortName: 'AEST',
    canonical: 'Australia/Brisbane',
    gmtOffset: 'GMT+10:00',
  },
  {
    id: 72,
    name: 'Australian Central Daylight Savings Time',
    description: '(GMT+10:30) Australian Central Daylight Savings Time',
    offset: 10.5,
    shortName: 'ACDT',
    canonical: 'Australia/Lord_Howe',
    gmtOffset: 'GMT+10:30',
  },
  {
    id: 73,
    name: 'Central Pacific Standard Time',
    description: '(GMT+11:00) Solomon Islands, New Caledonia',
    offset: 11,
    shortName: 'SBT',
    canonical: 'Pacific/Norfolk',
    gmtOffset: 'GMT+11:00',
  },
  {
    id: 74,
    name: 'Fiji Standard Time',
    description: '(GMT+12:00) Fiji Islands, Marshall Islands',
    offset: 12,
    shortName: 'FJT',
    canonical: 'Pacific/Fiji',
    gmtOffset: 'GMT+12:00',
  },
  {
    id: 75,
    name: 'New Zealand Standard Time',
    description: '(GMT+12:00) Auckland, Wellington',
    offset: 12,
    shortName: 'NZST',
    canonical: 'Pacific/Auckland',
    gmtOffset: 'GMT+12:00',
  },
  {
    id: 76,
    name: 'Tonga Standard Time',
    description: "(GMT+13:00) Nuku'alofa",
    offset: 13,
    shortName: 'TOT',
    canonical: 'Pacific/Fakaofo',
    gmtOffset: 'GMT+13:00',
  },
  {
    id: 77,
    name: 'Chatham Islands Standard Time',
    description: '(GMT+12:45) Chatham Daylight Time',
    offset: 12.75,
    shortName: 'CHADT',
    canonical: 'Pacific/Chatham',
    gmtOffset: 'GMT+12:45',
  },
  {
    id: 78,
    name: 'Line Islands Standard Time',
    description: '(GMT+14:00) Line Islands Time',
    offset: 14,
    shortName: 'LINT',
    canonical: 'Pacific/Kiritimati',
    gmtOffset: 'GMT+14:00',
  },
];

export const P2P_DEFAULT_EMAIL_BODIES = {
  FundraiserThankYou:
    '<div>Dear [DonorName],</div><div><br></div><div>Thank you for your interest in supporting [Event Name].</div><div><br></div><div>Your request has been sent to [Organization Name] and they will need to approve your P2P. Once the request has been approved, you will receive an email letting you know that your P2P has been approved.</div><div><br></div><div>Your support encourages our continued commitment to reaching our goal. Thank you again for your generosity and support.</div><div><br></div><div>Regards,</div><div><br></div><div>[PrimaryContact][OrgName]</div>',
  RegistrationConfirmation:
    '<div>Hi [Guest First Name],</div><div><br></div><div>We are excited you registered through the P2P fundraising event, [P2P Name]. Your support and participation are what make events like this so successful. Don’t forget to put this event on your calendar!</div><div><br></div><div>If you have any questions leading up to the event, feel free to reach out so we can help! We look forward to seeing you there! Thank you for your continued support.</div><div><br></div><div>Kind Regards,</div><div><br></div><div>[Event Organizer/Client Primary Contact Name]</div><div>[Organization Name]</div>',
  DonationThankYou:
    '<div>Dear [DonorName],</div><div><br></div><div>On behalf of our Board of Directors and Staff, we would like to thank you for making a donation through [Name of P2P].</div><div><br></div><div>Your [DonateAmount] donation on [DonationDate] helps us make a positive difference. Your support encourages our continued commitment to reaching our goal.</div><div><br></div><div>Thanks again for your generosity and support.</div><div><br></div><div>Regards,</div><div><br></div><div>[PrimaryContact]</div><div>[OrgName]</div>',
  Approval:
    '<div>Dear [DonorName],</div><div><br></div><div>Thank you for your interest in supporting [Event Name]. Your request to begin your [Name of P2P Fundraiser] has been approved!</div><div><br></div><div>Please go to your link [P2P URL] to share with your family and friends!</div><div><br></div><div>Your support encourages our continued commitment to reaching our goal.</div><div><br></div><div>Regards,</div><div><br></div><div>[PrimaryContact]</div><div>[OrgName]</div>',
  Denial:
    '<div>Dear [DonorName],</div><div><br></div><div>Thank you for your interest in supporting [Event Name]. Your request to begin your [Name of P2P Fundraiser] has been denied.&nbsp;&nbsp;Please create new P2P by clicking on this link [Add link here to create new fundraiser].</div><div><br></div><div>Regards,</div><div><br></div><div>[PrimaryContact]</div><div>[OrgName]</div>',
  Request:
    '<div>Hi There!</div><div><br></div><div>[DonorName] just created a P2P request. Login to Auxilia to approve or deny this request.</div><div><br></div><div>Best,</div><div>Auxilia Team</div>',
};

export const P2P_DEFAULT_EMAIL_SUBJECTS = {
  FundraiserThankYou:
    'Thank You for Supporting [Event Name] Through Your P2P Fundraiser!',
  RegistrationConfirmation: 'Great news, you are registered for [Name of P2P]!',
  DonationThankYou: 'Thank You for Donating to [Name of P2P]!',
  Approval: 'Your [Name of P2P Fundraiser] request has been approved!',
  Denial: 'Your [Name of P2P Fundraiser] request has been denied',
  Request: 'P2P Request Notification',
};

export const STRIPE_ACH_INSTANT_VERIFICATION_FEE: number = 1.5;

// TODO: Once other donation options are fixed, only weekly and monthly are allowed
export const DONATION_FREQUENCY_OPTIONS: FormElementDataModel[] = [
  { label: 'Weekly', value: DonationFrequency.Weekly },
  // { label: 'Bi-Weekly', value: DonationFrequency.BiWeekly },
  { label: 'Monthly', value: DonationFrequency.Monthly },
  // { label: 'Quarterly', value: DonationFrequency.Quarterly },
  { label: 'Yearly', value: DonationFrequency.Yearly },
];

export const DONATION_RECURRING_OPTIONS: FormElementDataModel[] = [
  { label: 'One Time Donation', value: DonationRecurringOption.Once },
  { label: 'Recurring Donation', value: DonationRecurringOption.Recurring },
  // { label: 'Pledge Donation', value: DonationRecurringOption.Pledge },
];

export const DONATION_RECURRING_OPTIONS_NO_PLEDGE: FormElementDataModel[] = [
  { label: 'One Time Donation', value: DonationRecurringOption.Once },
  { label: 'Recurring Donation', value: DonationRecurringOption.Recurring },
];
