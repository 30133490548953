import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import ChartBuilderData, { NgxChartData, NgxChartDataSet, SeriesModel } from '../../../../models/templates/chart.builder.data';
import { Subscription } from 'rxjs';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AzureUploadFileService } from '../../../../services/lookup/azure-upload-file.service';
import { Guid } from 'guid-typescript';
import { AzureFileModel } from '../../../../models/files/azure-file.model';
import { LoaderService } from '../../../../services/loader.service';
import { ChartType } from '../../../../models/enum/chart.type';
import * as shape from 'd3-shape';
import html2canvas from 'html2canvas';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-ngx-charts-lib-builder',
  templateUrl: './ngx-charts-lib-builder.component.html',
  styleUrls: ['./ngx-charts-lib-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NgxChartsLibBuilderComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @ViewChild('chartRef') public chartRef: ElementRef;

  public chartTypeOptions: FormElementDataModel[] = [
    {label: 'Line Chart', value: ChartType.Line},
    {label: 'Vertical Bar Chart', value: ChartType.VerticalBar},
   // {label: 'Grouped Vertical Bar Chart', value: ChartType.GroupedVerticalBar},
    {label: 'Horizontal Bar Chart', value: ChartType.HorizontalBar},
   // {label: 'Grouped Horizontal Bar Chart', value: ChartType.GroupedHorizontalBar},
    {label: 'Pie Chart', value: ChartType.Pie},
    //{label: 'Pie Grid', value: ChartType.PieGrid},
    //{label: 'Polar Chart', value: ChartType.Polar},
  ];

  public curveTypeOptions: FormElementDataModel[] = [
    {label: 'Basis', value: 'Basis'},
    {label: 'Cardinal', value: 'Cardinal'},
    {label: 'Linear', value: 'Linear'},
    {label: 'MonotoneX', value: 'MonotoneX'},
    {label: 'MonotoneY', value: 'MonotoneY'},
    {label: 'Natural', value: 'Natural'},
    {label: 'Step', value: 'Step'},
    {label: 'StepAfter', value: 'StepAfter'},
    {label: 'StepBefore', value: 'StepBefore'},
  ];

  public legendPositionOptions: FormElementDataModel[] = [
    {label: 'Right', value: 'right'},
    {label: 'Below', value: 'below'},
  ];

  public chartBuilderForm: FormGroup = this.formBuilder.group({
    name: '',
    type: ChartType.Line,
    aspectRatioX: [700, [Validators.min(1)]],
    aspectRatioY: [300, [Validators.min(1)]],
    xAxis: true,
    yAxis: true,
    showGridLines: true,
    legend: true,
    legendPosition: 'right',
    legendTitle: 'Legend',
    showXAxisLabel: true,
    showYAxisLabel: true,
    xAxisLabel: 'X-Axis',
    yAxisLabel: 'Y-Axis',
    trimXAxisTicks: true,
    trimYAxisTicks: true,
    maxXAxisTickLength: 16,
    maxYAxisTickLength: 16,
    autoScale: true,
    curve: 'Natural',
    yScaleMin: '',
    yScaleMax: '',

    showDataLabel: false,
    noBarWhenZero: true,
    barPadding: 8,
    roundEdges: true,

    labels: false,
    trimLabels: true,
    maxLabelLength: 10,
    explodeSlices: false,
    doughnut: false,
    arcWidth: 0.25,
    basedOnPercentage: false,
  });

  public multiDataSet: FormArray = this.formBuilder.array([]);
  public singleDataSet: FormArray = this.formBuilder.array([]);

  public apiURL: string = localStorage.getItem('apiurl');
  public ChartType = ChartType;

  private static getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  constructor(
    private toastrService: ToastrService,
    private uploadService: AzureUploadFileService,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    public dialogRef: MatDialogRef<NgxChartsLibBuilderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChartBuilderData,
    private authService: AuthService,
  ) {
  }

  public ngOnInit(): void {
    if (!this.data.ngxChartData) {
      this.multiDataSet.push(this.getMultiDataSetFormGroup());
      this.singleDataSet.push(this.getSingleDataSetFormGroup());
    } else {
      const ngxChartDataModel: NgxChartData = JSON.parse(this.data.ngxChartData);
      this.chartBuilderForm.patchValue(ngxChartDataModel);
      ngxChartDataModel.dataSet && ngxChartDataModel.dataSet.forEach(({name, series, color, value}: NgxChartDataSet) => {
        const group = this.isMultiDataSetInUse ? this.getMultiDataSetFormGroup(name, series, color) : this.getSingleDataSetFormGroup(name, value, color);
        this.dataSet.push(group);
      });
      this.isMultiDataSetInUse ? this.singleDataSet.push(this.getSingleDataSetFormGroup()) : this.multiDataSet.push(this.getMultiDataSetFormGroup());
    }
    this.subscription.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public captureChart(): void {
    this.loaderService.startRequest();
    this.showInfoMessage('Capturing chart view has been started');
    html2canvas(this.chartRef.nativeElement, { allowTaint: true, useCORS: true, backgroundColor: 'transparent', removeContainer: true, scale: 2, ignoreElements: function (node) {
        return node.nodeName === 'IFRAME';
      }})
      .then(canvas => {
        const dataUrl = canvas.toDataURL();
        const name = `${Guid.create()}.png`;
        const azure: AzureFileModel = {
          file: dataUrl.substring(22, dataUrl.length),
          name,
          folder: 'images'
        };
        this.subscription.add(
          this.uploadService.addModel(azure).subscribe(() => {
            this.data.formGroup.get(this.data.controlName).setValue(`${this.apiURL}/AzureFileStorage/image/${name}`);
            const ngxChartDataModel: NgxChartData = this.chartBuilderForm.value;
            ngxChartDataModel.dataSet = this.dataSet.value;
            this.dialogRef.close(JSON.stringify(ngxChartDataModel));
          })
        );
      })
      .catch(error => {
        this.loaderService.endRequest();
        this.toastrService.error('Something went wrong!', error);
      })
  }

  private showInfoMessage(message: string): void {
    this.toastrService.info(message, 'Info');
  }

  private getMultiDataSetFormGroup(name: string = '', series: SeriesModel[] = [], color: string = NgxChartsLibBuilderComponent.getRandomColor()): FormGroup {
    const formGroup = this.formBuilder.group({
      name,
      series: new FormArray([]),
      color,
    });
    const seriesFormArray: FormArray = formGroup.get('series') as FormArray;
    if (!series.length) {
      seriesFormArray.push(this.getSeriesFormGroup());
    } else {
      series.forEach((point: SeriesModel) => {
        seriesFormArray.push(this.getSeriesFormGroup(point.name, point.value));
      })
    }
    return formGroup;
  }

  private getSingleDataSetFormGroup(name: string = '', value: number = 0, color: string = NgxChartsLibBuilderComponent.getRandomColor()): FormGroup {
    return this.formBuilder.group({name, value, color});
  }

  private getSeriesFormGroup(name: string | number | Date = '', value: number = 0): FormGroup {
    return this.formBuilder.group({
      value,
      name,
    });
  }

  public get dataSet(): FormArray {
    return this.isMultiDataSetInUse ? this.multiDataSet : this.singleDataSet;
  }

  public get type(): FormControl {
    return this.chartBuilderForm.get('type') as FormControl;
  }

  public getDataSetGroups(): FormGroup[] {
    return this.dataSet.controls as FormGroup[];
  }

  public getSeriesGroups(dataSetGroup: AbstractControl): FormGroup[] {
    return ((dataSetGroup as FormGroup).get('series') as FormArray).controls as FormGroup[];
  }

  public addPoint(dataSetGroup: FormGroup): void {
    (dataSetGroup.get('series') as FormArray).push(this.getSeriesFormGroup());
  }

  public removePoint(dataSetGroup: FormGroup, seriesIndex: number): void {
    (dataSetGroup.get('series') as FormArray).removeAt(seriesIndex);
  }

  public removeDataSet(dataSetIndex: number): void {
    this.dataSet.removeAt(dataSetIndex);
  }

  public addDataSet(): void {
    this.isMultiDataSetInUse ? this.dataSet.push(this.getMultiDataSetFormGroup()) : this.dataSet.push(this.getSingleDataSetFormGroup());
  }

  public getDataSetGroupContainerStyle(dataSetGroup: FormGroup): any {
    const color = dataSetGroup.get('color').value + '0d';
    return {
      'background': color
    };
  }

  public get view(): number[] {
    const width = this.chartBuilderForm.get('aspectRatioX').value;
    const height = this.chartBuilderForm.get('aspectRatioY').value;
    return [width, height]
  }

  public get colorScheme(): any {
    const colors = this.dataSet.value.map(({color}: NgxChartDataSet) => color);
    return {domain: colors};
  }

  public get chartBuilderFormValues(): NgxChartData {
    return this.chartBuilderForm.value;
  }

  public get results(): NgxChartDataSet[] {
    return this.isMultiDataSetInUse
      ? this.dataSet.value.map(({name, series}: NgxChartDataSet) => ({name, series}))
      : this.dataSet.value.map(({name, value}: NgxChartDataSet) => ({name, value}));
  }

  public get curve(): any {
    const lineInterpolation = {
      Basis: shape.curveBasis,
      Cardinal: shape.curveCardinal,
      Linear: shape.curveLinear,
      MonotoneX: shape.curveMonotoneX,
      MonotoneY: shape.curveMonotoneY,
      Natural: shape.curveNatural,
      Step: shape.curveStep,
      StepAfter: shape.curveStepAfter,
      StepBefore: shape.curveStepBefore,
    };
    const curve = this.chartBuilderFormValues.curve;
    return lineInterpolation[curve];
  }

  public get isMultiDataSetInUse(): boolean {
    switch (this.type.value) {
      case ChartType.Line:
        return true;
      case ChartType.VerticalBar:
      case ChartType.HorizontalBar:
      case ChartType.Pie:
        return false;
    }
  }

  public labelFormatting = label => {
    const value = this.dataSet.value.find((item: NgxChartDataSet) => item.name === label).value;
    if (this.chartBuilderForm.get('basedOnPercentage').value) {
      const valuesArray = this.dataSet.value.map(item => +item.value);
      const full = valuesArray.reduce((accumulator, currentValue) => accumulator + currentValue);
      const percentage = full ? +value / full * 100 : 0;
      return ` ${label} / ${percentage.toFixed(2)}% `;
    } else {
      return ` ${label} / ${value} `;
    }
  }
}
