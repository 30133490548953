<div class="email-builder-tool-settings-wrapper">
  <span class="title-row">{{'BUILD.Add New Tag' | translate}}</span>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
      [formGroup]="tagsForm"
      name="tagName"
      errorMassage="Tag"
      [low]="true"
      placeholder="Enter tag and hit enter to save"
      (enterPressed)="onEnterPressed($event)"
      [disabled]="strictReadOnly"
      ></app-input>
    </div>
  </div>

  <span class="title-row" *ngIf="existingTags.length">{{'BUILD.Existing Tags' | translate}}</span>
  <div class="email-builder-tool-settings-row" *ngIf="existingTags.length">
    <div *ngFor="let tag of existingTags; index as i" class="template-tag">
      <div class="template-tag-name">{{tag.name}}</div>
      <div class="remove-template-tag-name" (click)="removeTag(i)" matRipple>x</div>
    </div>
  </div>

  <span class="title-row">{{'BUILD.Template Name' | translate}} *</span>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        [formGroup]="tagsForm"
        name="name"
        errorMassage="Template Name"
        [low]="true"
        placeholder="Enter template name"
        [disabled]="strictReadOnly"
      ></app-input>
    </div>
  </div>

  <span class="title-row">{{'BUILD.Template Description' | translate}}</span>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-textarea
        [formGroup]="tagsForm"
        name="description"
        errorMassage="Template Description"
        [low]="true"
        placeholder="Enter template description"
        minRows="2"
        [disabled]="strictReadOnly"
      ></app-textarea>
    </div>
  </div>

  <span class="title-row">{{'BUILD.Template Layout' | translate}}</span>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-select
        [formGroup]="tagsForm"
        name="templateType"
        errorMassage="Template Layout"
        [low]="true"
        [selectOptions]="templateTypeOptions"
        [disabled]="strictReadOnly"
      ></app-select>
    </div>
  </div>

  <app-button
    *ngIf="!strictReadOnly"
    label="SAVE IN LIBRARY"
    type="flat"
    color="#0064be"
    [disabled]="isButtonDisabled"
    (clickEvent)="updateTemplate()"
    [disabled]="isButtonDisabled"
    width="100%"
  ></app-button>

</div>

