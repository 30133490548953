<div
  #thermometer
  class="app-thermometer-wrapper screenshots-element-ref"
  [ngClass]="{ flexDirectionColumn: isHorizontal }"
>
  <div class="description" [ngClass]="{ textSpace: isHorizontal }"   [ngStyle]="{ transform: getTransform }">
    <p
      class="description-text"
      [ngStyle]="{ color:  thermometerBuilderFormValues?.containerTextColor, fontSize: thermometerBuilderFormValues?.fontSizeDescription}"
    >
      {{ getEntity?.description || thermometerBuilderFormValues?.description}}
    </p>
    <div class="info" *ngIf="!isTextOnRight" [ngClass]="{ flexDirectionRow: isHorizontal, textSpace: isHorizontal }">
      <p [ngStyle]="{ fontSize: thermometerBuilderFormValues?.fontSizeGoal}">
        {{'BUILD.OUR GOAL' | translate}}:
        <span
          class="oval"
          [ngStyle]="{ fontSize: thermometerBuilderFormValues?.fontSizeGoal}"
        >${{ getGoal }}</span>
      </p>
      <p
        *ngIf="thermometerBuilderFormValues?.showDonations"
        [ngStyle]="{ fontSize: thermometerBuilderFormValues?.fontSizeGoal}"
      >
        {{'BUILD.RAISED SO FAR' | translate}}:
        <span
          class="oval"
          [ngStyle]="{ fontSize: thermometerBuilderFormValues?.fontSizeGoal}"
        >${{getDonation}}</span>
      </p>
      <p
        *ngIf="thermometerBuilderFormValues?.showDonors"
        [ngStyle]="{ fontSize: thermometerBuilderFormValues?.fontSizeGoal}"
      ># {{'BUILD.OF DONATIONS' | translate}}:
        <span
          class="oval"
          [ngStyle]="{ fontSize: thermometerBuilderFormValues?.fontSizeGoal}"
        >
          {{getDonors}}
        </span></p>
    </div>
  </div>

  <div class="thermometer" *ngIf="isThermometer" [ngStyle]="{ transform :  getSize()}">
    <svg id="svg2" [attr.viewBox]="getViewBoxSize" width="106%" height="100%" version="1.0">
      <path [attr.fill]="thermometerBuilderFormValues?.borderColor" fill-rule="evenodd" clip-rule="evenodd"
            d="M49 28C49 12.5361 61.536 0 77 0C92.464 0 105 12.5361 105 28V384.371C118.837 393.476 128 409.155 128 427C128 455.167 105.167 478 77 478C48.8335 478 26 455.167 26 427C26 409.155 35.1627 393.476 49 384.371V28ZM53 386.585C39.2379 394.774 30 409.797 30 427C30 452.957 51.0426 474 77 474C102.957 474 124 452.957 124 427C124 409.797 114.762 394.774 101 386.585V28C101 14.7452 90.2549 4 77 4C63.7451 4 53 14.7452 53 28V386.585Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M53 28C53 14.7452 63.7451 4 77 4C90.2549 4 101 14.7452 101 28V386.585C114.762 394.774 124 409.797 124 427C124 452.957 102.957 474 77 474C51.0426 474 30 452.957 30 427C30 409.797 39.2379 394.774 53 386.585V28Z"
            fill="white"/>
      <path
        d="M77 16C70.3726 16 65 21.3726 65 28V394.111C51.5804 399.009 42 411.886 42 427C42 446.33 57.67 462 77 462C96.33 462 112 446.33 112 427C112 411.886 102.42 399.009 89 394.111V28C89 21.3726 83.6274 16 77 16Z"
        fill="#EDEDED"/>
      <path [attr.fill]="thermometerBuilderFormValues?.containerFillColor" [attr.d]="getThermometerHeight"/>
      <g style="mix-blend-mode:soft-light" opacity="0.5">
        <circle cx="65" cy="415" r="9" fill="white"/>
      </g>
      <g style="mix-blend-mode:soft-light" opacity="0.5">
        <circle cx="56" cy="433" r="5" fill="white"/>
      </g>
      <rect x="110" *ngIf="isShowIterations" y="376" width="40" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="358" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="340" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="322" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="304" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="286" width="40" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="268" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="250" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="232" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="214" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="196" width="40" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="178" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="88" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="160" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="70" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="142" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="52" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="124" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="34" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="106" width="40" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="110" *ngIf="isShowIterations" y="16" width="40" height="4" rx="2" fill="#C4C4C4"/>
      <text *ngIf="thermometerBuilderFormValues?.showPercent" id="low" font-size="18px"
            [attr.transform]="getRotateThermometerPercent" xml:space="preserve" y="383.38419" x="136.21846"
            font-family="Bitstream Vera Sans" line-height="125%" fill="black">
            <tspan id="tspan3003" y="385" font-weight="700" font-family="Roboto"
                   [attr.x]="getPercentParam"
                   [attr.font-size]="thermometerBuilderFormValues?.fontSizeGoal"
            >
              {{percent}}%
            </tspan>
          </text>
      <text *ngIf="isShowIterations" id="low" [attr.font-size]="thermometerBuilderFormValues.fontSizeIterations"
            xml:space="preserve" [attr.transform]="getRotateThermometerText1" y="383.38419" x="136.21846"
            font-family="Bitstream Vera Sans" line-height="125%" fill="black">
            <tspan id="tspan3003" y="382" font-weight="700" font-family="Roboto" x="153">0</tspan>
          </text>
      <text *ngIf="isShowIterations" id="middle" maxlength="5"
            [attr.font-size]="thermometerBuilderFormValues.fontSizeIterations" xml:space="preserve"
            [attr.transform]="getRotateThermometerText2" line-height="125%" y="224" x="136"
            font-family="Bitstream Vera Sans" fill="black">
            <tspan id="tspan3011" y="293" font-weight="700" font-family="Roboto" x="153">{{ iteration_1 }}</tspan>
          </text>
      <text *ngIf="isShowIterations" id="high" [attr.font-size]="thermometerBuilderFormValues?.fontSizeIterations"
            xml:space="preserve" [attr.transform]="getRotateThermometerText3" line-height="125%" y="63.830078"
            x="135.32617"
            font-family="Bitstream Vera Sans" fill="black">
            <tspan id="tspan3007" y="203" font-weight="700" font-family="Roboto" x="153">{{ iteration_2 }}</tspan>
          </text>
      <text *ngIf="isShowIterations" id="high" [attr.font-size]="thermometerBuilderFormValues?.fontSizeIterations"
            xml:space="preserve" [attr.transform]="getRotateThermometerText4" line-height="125%" y="63.830078"
            x="135.32617"
            font-family="Bitstream Vera Sans" fill="black">
            <tspan id="tspan3007" y="115" font-weight="700" font-family="Roboto" x="153">{{ iteration_3}}</tspan>
          </text>
      <text *ngIf="isShowIterations" id="high" [attr.font-size]="thermometerBuilderFormValues?.fontSizeIterations"
            xml:space="preserve" [attr.transform]="getRotateThermometerText5" line-height="125%" y="63.830078"
            x="135.32617"
            font-family="Bitstream Vera Sans" fill="black">
            <tspan id="tspan3007" y="24" font-weight="700" font-family="Roboto" x="153">{{ getGoal }}</tspan>
          </text>
    </svg>
  </div>

  <div class="bar_wrapper" *ngIf="!isThermometer" [ngStyle]="{transform : getSize()}">
    <svg id="svg2" [attr.viewBox]="getViewBoxSize" width="106%" height="100%" version="1.0">
      <path [attr.fill]="thermometerBuilderFormValues?.borderColor" fill-rule="evenodd" clip-rule="evenodd"
            d="M79 0H115C130.464 0 143 12.4311 143 27.7657V446.234C143 461.569 130.464 474 115 474H79C63.536 474 51 461.569 51 446.234V27.7657C51 12.4311 63.536 0 79 0ZM115 3.96653H79C65.7452 3.96653 55 14.6218 55 27.7657V446.234C55 459.378 65.7452 470.033 79 470.033H115C128.255 470.033 139 459.378 139 446.234V27.7657C139 14.6218 128.255 3.96653 115 3.96653Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M115 4H79C65.7452 4 55 14.6537 55 27.7957V446.204C55 459.346 65.7452 470 79 470H115C128.255 470 139 459.346 139 446.204V27.7957C139 14.6537 128.255 4 115 4Z"
            fill="white"/>
      <rect x="67" y="16" width="60" height="442" rx="12" fill="#EDEDED"/>
      <path [attr.fill]="thermometerBuilderFormValues?.containerFillColor" [attr.d]="getBarHeight"/>
      <rect x="163" *ngIf="isShowIterations" y="445" width="40" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="423.55" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="402.1" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="380.65" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="359.2" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="337.75" width="40" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="316.3" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="294.85" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="273.4" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="251.95" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="230.5" width="40" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="209.05" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="187.6" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="166.15" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="144.7" width="20" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="123.25" width="40" height="4" rx="2" fill="#C4C4C4"/>
      <rect x="163" *ngIf="isShowIterations" y="101.8" width="20" height="4" rx="2" fill="#C4C4C4"/>//
      <rect x="163" *ngIf="isShowIterations" y="80.35" width="20" height="4" rx="2" fill="#C4C4C4"/>//
      <rect x="163" *ngIf="isShowIterations" y="59.9" width="20" height="4" rx="2" fill="#C4C4C4"/>//
      <rect x="163" *ngIf="isShowIterations" y="37.45" width="20" height="4" rx="2" fill="#C4C4C4"/>//
      <rect x="163" *ngIf="isShowIterations" y="16" width="40" height="4" rx="2" fill="#C4C4C4"/>//
      <text *ngIf="thermometerBuilderFormValues?.showPercent" id="low" font-size="18px"
            [attr.transform]="getRotateBarPercent" xml:space="preserve" y="383.38419" x="136.21846"
            font-family="Bitstream Vera Sans" line-height="125%" fill="black">
            <tspan id="tspan3003" y="453" [attr.font-size]="thermometerBuilderFormValues?.fontSizeGoal"
                   font-weight="700" font-family="Roboto" [attr.x]="getPercentParam"
            >
              {{percent}}%
            </tspan>
          </text>
      <text *ngIf="isShowIterations" id="low" [attr.font-size]="thermometerBuilderFormValues.fontSizeIterations"
            xml:space="preserve" [attr.transform]="getRotateBarText1" y="383.38419" x="136.21846"
            font-family="Bitstream Vera Sans" line-height="125%" fill="black">
            <tspan id="tspan3003" y="452" font-weight="700" font-family="Roboto" x="210">0</tspan>
          </text>
      <text *ngIf="isShowIterations" id="middle" maxlength="5"
            [attr.font-size]="thermometerBuilderFormValues.fontSizeIterations" xml:space="preserve"
            [attr.transform]="getRotateBarText2" line-height="125%" y="224" x="136"
            font-family="Bitstream Vera Sans" fill="black">
            <tspan id="tspan3011" y="345" font-weight="700" font-family="Roboto" x="210">{{ iteration_1 }}</tspan>
          </text>
      <text *ngIf="isShowIterations" id="high" [attr.font-size]="thermometerBuilderFormValues.fontSizeIterations"
            xml:space="preserve" [attr.transform]="getRotateBarText3" line-height="125%" y="63.830078" x="135.32617"
            font-family="Bitstream Vera Sans" fill="black">
            <tspan id="tspan3007" y="237" font-weight="700" font-family="Roboto" x="210">{{ iteration_2 }}</tspan>
          </text>
      <text *ngIf="isShowIterations" id="high" [attr.font-size]="thermometerBuilderFormValues.fontSizeIterations"
            xml:space="preserve" [attr.transform]="getRotateBarText4" line-height="125%" y="63.830078" x="135.32617"
            font-family="Bitstream Vera Sans" fill="black">
            <tspan id="tspan3007" y="130" font-weight="700" font-family="Roboto" x="210">{{ iteration_3}}</tspan>
          </text>
      <text *ngIf="isShowIterations" id="high" [attr.font-size]="thermometerBuilderFormValues.fontSizeIterations"
            xml:space="preserve" [attr.transform]="getRotateBarText5" line-height="125%" y="63.830078" x="135.32617"
            font-family="Bitstream Vera Sans" fill="black">
            <tspan id="tspan3007" y="24" font-weight="700" font-family="Roboto" x="210">{{ getGoal }}</tspan>
          </text>
      <g style="mix-blend-mode:soft-light" opacity="0.5">
        <path
          d="M74 31C74 28.7909 75.7909 27 78 27C80.2091 27 82 28.7909 82 31V443C82 445.209 80.2091 447 78 447C75.7909 447 74 445.209 74 443V31Z"
          fill="white"/>
      </g>
    </svg>
  </div>
  <div class="info" *ngIf="isTextOnRight"
       [ngClass]="{ flexDirectionRow: isHorizontal, justifyContent: isHorizontal && isTextOnRight, textSpace: isHorizontal }">
    <p
      [ngStyle]="{ fontSize: thermometerBuilderFormValues?.fontSizeGoal}"
    >
      {{'BUILD.OUR GOAL' | translate}}:
      <span
        class="oval"
        [ngStyle]="{ fontSize: thermometerBuilderFormValues?.fontSizeGoal}"
      >
          ${{ getGoal }}
        </span>
    </p>
    <p
      *ngIf="thermometerBuilderFormValues?.showDonations"
      [ngStyle]="{ fontSize: thermometerBuilderFormValues?.fontSizeGoal}"
    >
      {{'BUILD.RAISED SO FAR' | translate}}:
      <span
        class="oval"
        [ngStyle]="{ fontSize: thermometerBuilderFormValues?.fontSizeGoal}"
      >
          ${{getDonation}}
        </span>
    </p>
    <p
      *ngIf="thermometerBuilderFormValues?.showDonors"
      [ngStyle]="{ fontSize: thermometerBuilderFormValues?.fontSizeGoal}"
    ># {{'BUILD.OF DONATIONS' | translate}}:
      <span
        class="oval"
        [ngStyle]="{ fontSize: thermometerBuilderFormValues?.fontSizeGoal}"
      >
          {{getDonors}}
        </span></p>
  </div>
</div>
