<div class="email-builder-tool-settings-wrapper">
  <div *ngIf="!!srcsFormArray.value?.length" class="email-builder-tool-settings-row">
    <div *ngFor="let src of srcsFormArray.controls; let i=index">
      <app-input
        label="Logo URL"
        [formGroup]="src"
        name="url"
        errorMassage="Image URL"
        [low]="true"
        [disabled]="tms.readOnly"
        (valueChanged)="imageUrlChanged($event)"
      ></app-input>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <app-image-loader
      label="Upload Sponsor Logo"
      [readOnly]="tms.readOnly"
      [formGroup]="sponsorsForm"
      name="currUrl"
      (valueChanged)="imageUrlChanged($event)">
    </app-image-loader>
  </div>
</div>