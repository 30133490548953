import { Injectable } from '@angular/core';
import { AmountValuesProcessor } from '../amount-values-processor.interface';
import { AmountValues } from '../../../payment-page.component';
import { PaymentSummaryResult } from 'src/app/components/form-builder/components/elements/payment-summary/payment-summary.component';
import { AppFeeProcessor } from '../app-fee-processor.interface';

@Injectable()
export class StripeACHProcessorService implements AmountValuesProcessor, AppFeeProcessor {
  private _isEventRegistration: boolean;
  private _isRecurringDonation: boolean;
  private _stripeACHPaymentSummaryResult: PaymentSummaryResult;

  constructor() { }

  public getAppFee(): number {
    if(this._isEventRegistration && this._isRecurringDonation) {
      return this._stripeACHPaymentSummaryResult.tickets.appFee;
    }
    return this._stripeACHPaymentSummaryResult.appFee;
  }

  public getAmountValues(): AmountValues {
    if(this._isEventRegistration && this._isRecurringDonation) {
      return {
        amount: this._stripeACHPaymentSummaryResult.tickets.total,
        feeAmount: this._stripeACHPaymentSummaryResult.tickets.fee,
        ticketsCost: this._stripeACHPaymentSummaryResult.tickets.total,
        donationFee: this._stripeACHPaymentSummaryResult.donation.fee
      }
    }
    return {
      amount: this._stripeACHPaymentSummaryResult.total,
      feeAmount: this._stripeACHPaymentSummaryResult.totalFee,
      ticketsCost: this._stripeACHPaymentSummaryResult?.tickets.total || 0,
      donationFee: this._stripeACHPaymentSummaryResult.donation.fee
    }
  }

  public setup(stripeACHPaymentSummaryResult: PaymentSummaryResult, isRecurringDonation: boolean, isEventRegistration: boolean): void {
    this._stripeACHPaymentSummaryResult = stripeACHPaymentSummaryResult;
    this._isRecurringDonation = isRecurringDonation;
    this._isEventRegistration = isEventRegistration;
  }
}
