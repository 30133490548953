import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidators } from '../../../../shared/validatiors/form-validators';
import TemplatePayload from '../../../../models/templates/template.payload';
import TagModel from '../../../../models/templates/tag.model';
import { TemplateType } from '../../../../models/templates/template.type';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { TEMPLATE_RESTRICTION_TYPES } from '../../../../constants';
import { AdminTemplatesStateService } from '../../../../routes/admin/admin-templates/admin-templates-state.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss', '../tool-settings.scss']
})
export class TagsComponent implements OnChanges {
  @Input() public tms: TemplateManagementService;
  @Input() public templatePayload: TemplatePayload;
  @Input() public strictReadOnly: boolean = false;

  public tagsForm: FormGroup = this.formBuilder.group({
    tagName: ['', [Validators.maxLength(25)]],
    name: ['', [Validators.required, Validators.maxLength(25)]],
    description: ['', [Validators.maxLength(250)]],
    templateType: [TemplateType.Layout, [Validators.required]]
  });

  @Output() invalidTagFormEmitter: EventEmitter<void> = new EventEmitter<void>();

  public existingTags: TagModel[] = [];

  public templateTypeOptions: FormElementDataModel[] = TEMPLATE_RESTRICTION_TYPES;

  public get isButtonDisabled(): boolean {
    return this.adminTemplatesStateService.isSaveUpdateButtonDisabled;
  }

  constructor(
    private formBuilder: FormBuilder,
    private adminTemplatesStateService: AdminTemplatesStateService,
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.templatePayload && changes.templatePayload.currentValue) {
      this.tagsForm.get('name').setValue(this.templatePayload.name);
      this.tagsForm.get('description').setValue(this.templatePayload.description);
      this.tagsForm.get('templateType').setValue(this.templatePayload.templateType);
      this.existingTags = this.templatePayload.tags;
    }
  }

  public updateTemplate(): void {
    this.tagsForm.markAllAsTouched();
    if (this.tagsForm.invalid) {
      this.invalidTagFormEmitter.emit();
      return;
    }
    if (this.tagsForm.get('tagName').value && this.tagsForm.get('tagName').value.toString()) {
      this.onEnterPressed(null);
    }
    const payload: TemplatePayload = {
      tags: this.existingTags,
      name: this.tagsForm.value.name,
      description: this.tagsForm.value.description,
      templateType: this.tagsForm.value.templateType,
    };
    this.tms.triggerSaveTemplate(payload);
  }

  public removeTag(index: number): void {
    if (this.strictReadOnly) {
      return;
    }
    this.existingTags.splice(index, 1);
  }

  public onEnterPressed(event: KeyboardEvent): void {
    const value: string = this.tagsForm.get('tagName').value.toString();
    if (!value || this.tagsForm.get('tagName').invalid) {
      return;
    }
    this.existingTags.push({name: value});
    this.tagsForm.get('tagName').reset();
  }

}
