import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminTemplatesStateService {
  private isSaveButtonDisabled = false;

  public get isSaveUpdateButtonDisabled(): boolean {
    return this.isSaveButtonDisabled;
  }

  public disableSaveUpdateButton(): void {
    this.isSaveButtonDisabled = true;
  }

  public enableSaveUpdateButton(): void {
    this.isSaveButtonDisabled = false;
  }
}
