import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import TemplateModel from '../../models/templates/template.model';
import { TemplateUsageType } from '../../models/templates/template.usage.type';
import { AUXILIA_DEFAULT_PAYMENT_TEMPLATE_IMG } from '../../constants';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-forms-template-select',
  templateUrl: './forms-template-select.component.html',
  styleUrls: ['./forms-template-select.component.scss']
})
export class FormsTemplateSelectComponent implements OnInit, OnDestroy {
  public form: FormGroup = this.formBuilder.group({
    auxilia: null,
    client: null,
    formType: TemplateUsageType.DonationForms
  });

  public formTypes: FormElementDataModel[] = [
    { value: TemplateUsageType.DonationForms, label: 'Donation Form' }
  ];

  public auxiliaTemplates: FormElementDataModel[] = FormsTemplateSelectComponent.convertToFormElementDataModel(this.data.auxiliaTemplates);
  public clientTemplates: FormElementDataModel[] = FormsTemplateSelectComponent.convertToFormElementDataModel(this.data.clientTemplates);
  public imgSrc = AUXILIA_DEFAULT_PAYMENT_TEMPLATE_IMG;
  private subscriptions: Subscription = new Subscription();

  private static convertToFormElementDataModel(templates: TemplateModel[]): FormElementDataModel[] {
    return templates.map(({name, id}: TemplateModel) => ({label: name, value: id}));
}

  constructor(
    public dialogRef: MatDialogRef<FormsTemplateSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {auxiliaTemplates: TemplateModel[], clientTemplates: TemplateModel[]},
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) { }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public back(): void {
    this.dialogRef.close('back');
  }

  public edit(): void {
    let template: TemplateModel = null;
    if (this.control('auxilia').value) {
      template = this.data.auxiliaTemplates.find(item => item.id = this.control('auxilia').value);
    } else if (this.control('client').value) {
      template = this.data.clientTemplates.find(item => item.id = this.control('client').value);
    }
    this.dialogRef.close(template);
  }

  public createNew(): void {
    this.dialogRef.close('create');
  }

  public onAuxiliaChanged(): void {
    const id = this.control('auxilia').value;
    if (id) {
      this.control('client').reset();
      this.imgSrc = this.findImgSrc(this.data.auxiliaTemplates, id);
    }
  }

  public onClientChanged(): void {
    const id = this.control('client').value;
    if (id) {
      this.control('auxilia').reset();
      this.imgSrc = this.findImgSrc(this.data.clientTemplates, id);
    }
  }

  public control(controlName: string): FormControl {
    return this.form.get(controlName) as FormControl;
  }

  private findImgSrc(templates: TemplateModel[], id: string): string {
    const template = templates.find(item => item.id === id);
    if (!template || !template.imagePreviewURL) {
      return 'assets/images/example1.jpg';
    } else {
      return template.imagePreviewURL;
    }
  }
}
