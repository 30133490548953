import { BaseModelService } from '../base.model.service';
import { Injectable, Injector } from '@angular/core';
import { ClientEmployeeModel } from 'src/app/models/client/client.employee.model';
import { ClientRegistrationModel } from 'src/app/models/client/client.registration.model';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { Paging } from '../../models/paging/paging.model';
import { HttpParams } from '@angular/common/http';
import { PagingHelper } from '../helper/paging.helper';

@Injectable({
  providedIn: 'root'
})
export class ClientEmployeeService extends BaseModelService<ClientEmployeeModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'ClientEmployee';
  }

  public completeRegistration(employeeRegistrationModel: ClientRegistrationModel): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/completeregistration`;
        return this.http.post<ClientEmployeeModel>(url, employeeRegistrationModel);
      })
    );
  }

  public getAnonymousEmployee(filter?: Paging): Observable<ClientEmployeeModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<ClientEmployeeModel[]>(
          `${config.apiURL}/${this.controllerName}/anonymous`,
          { params }
        );
      })
    );
  }
  public resendRegistration(employeeRegistrationModel: ClientRegistrationModel): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/resendRegistration`;
        return this.http.post<ClientEmployeeModel>(url, employeeRegistrationModel);
      })
    );
  }
  public getCompleteRegistrationModel(clientEmployeeId: string): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/getRegistrationModel/${clientEmployeeId}`;
        return this.http.get<ClientEmployeeModel>(url);
      })
    );
  }
}
