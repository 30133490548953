<div class="image-builder-styling-content">
  <div class="image-builder-styling-row">
    <div class="max-width-350">
      <app-select
        [formGroup]="generalOptionsForm"
        name="size"
        label="Resolution"
        [low]="true"
        [selectOptions]="resolutionOptions"
        [disabled]="readOnly || themedTemplate || componentBasedTemplate"
        (valueChanged)="onSizeChanged()"
      ></app-select>
    </div>
  </div>

  <div class="image-builder-styling-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="generalOptionsForm"
        label="Background color"
        name="backgroundColor"
        [readOnly]="readOnly || themedTemplate"
        (colorChanged)="onBackgroundColorChanged()"
      ></app-email-color-picker>
    </div>

    <div class="max-width-145">
      <app-slider
        [formGroup]="generalOptionsForm"
        name="transparency"
        label="Transparency (%)"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onTransparencyChanged()"
        [disabled]="readOnly || themedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="image-builder-styling-row">
    <app-image-loader
      [formGroup]="generalOptionsForm"
      name="backgroundUrl"
      label="Upload background image"
      [imageURL]="imageURL"
      [readOnly]="readOnly || themedTemplate"
      (valueChanged)="imageUrlChanged($event)"
    ></app-image-loader>
  </div>

  <div class="image-builder-styling-row">
    <div class="max-width-145 right-margin">
      <app-input
        label="Background image URL"
        [formGroup]="generalOptionsForm"
        name="backgroundUrl"
        errorMassage="URL"
        [low]="true"
        [disabled]="readOnly || themedTemplate"
        (valueChanged)="onBackgroundUrlChanged()"
      ></app-input>
    </div>
    <app-button
      *ngIf="generalOptionsForm.get('backgroundUrl') && generalOptionsForm.get('backgroundUrl').value && !imageURL && !readOnly && !themedTemplate"
      label="Edit Image"
      type="flat"
      (clickEvent)="editImage()"
      color="#0064be"
      width="140"
    ></app-button>
    <!--<app-button
      *ngIf="!!imageURL"
      label="Cancel"
      type="flat"
      (clickEvent)="cancelEditImage()"
      color="#ec4c47"
      width="140"
    ></app-button>-->
  </div>

  <div class="image-builder-styling-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Repeat Background?"
        [formGroup]="generalOptionsForm"
        name="backgroundRepeat"
        [low]="true"
        [selectOptions]="backgroundRepeatOptions"
        [disabled]="readOnly || themedTemplate"
        (valueChanged)="onBackgroundRepeatChanged()"
      ></app-select>
    </div>
    <div class="max-width-145">
      <app-select
        label="Scale background image"
        [formGroup]="generalOptionsForm"
        name="backgroundSize"
        [low]="true"
        [selectOptions]="backgroundSizeOptions"
        [disabled]="readOnly || themedTemplate"
        (valueChanged)="onBackgroundSizeChanged()"
      ></app-select>
    </div>
  </div>
</div>
