<div class="custom-form-component-container-wrapper" [formGroup]="formGroup">
  <label class="form-field-name" [for]="id">{{label | translate}}</label>
  <mat-form-field appearance="outline">
    <mat-select
      [formControlName]="name"
      multiple
      [placeholder]="placeholder ? placeholder : ''"
      [id]="id"
      (selectionChange)="emitChanges($event); updateLabel()"
      [value]="formControl.value"
      #matSelect
    >
      <mat-option *ngFor="let option of volunteeringList" [value]="option.id" class="volunteers-select">
        <div class="volunteers-select-label">{{option.volunteerArea}}</div>
        <div class="volunteers-select-sub-label">{{dateString(option)}}</div>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="formControl.invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>
</div>
