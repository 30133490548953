import { Injectable, Injector } from '@angular/core';
import { BaseModuleGuard } from './base.module.guard';
import { AUXILIA_ADMIN, SUPER_ADMIN } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AdminModuleGuard extends BaseModuleGuard {
  constructor(protected injector: Injector) {
    super(injector);
  }

  public canLoad(): boolean {
    const roles = this.authService.getIdentityClaimsRole();
    const isAuxiliaAdmin = roles?.includes(AUXILIA_ADMIN);
    const isSuperAdmin  = roles?.includes(SUPER_ADMIN);
    if (isAuxiliaAdmin || isSuperAdmin) {
      return true;
    } else {
      this.router.navigateByUrl('/');
      return false;
    }
  }
}
