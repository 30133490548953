import { AzureFileModel } from 'src/app/models/files/azure-file.model';
import { BaseModelService } from '../base.model.service';
import { ClientModel } from 'src/app/models/client/client.model';
import { flatMap, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Paging } from '../../models/paging/paging.model';
import { PagingHelper } from '../helper/paging.helper';
import { TotalRowsModel } from 'src/app/models/paging/totalrow.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends BaseModelService<ClientModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'client';
  }

  public getClientLogo(id): Observable<AzureFileModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http
          .get<AzureFileModel>(`${config.apiURL}/${this.controllerName}/clientLogo?id=${id}`);
      }));
  }

  public getCampaignClientModel(id): Observable<ClientModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<ClientModel>(`${config.apiURL}/${this.controllerName}/campaignClientModel?id=${id}`);
      }));
  }

  public getClientDataByFriendlyUrl(friendlyURL): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<ClientModel>(`${config.apiURL}/${this.controllerName}/getByFriendlyUrl/${friendlyURL}`);
      }));
  }

  public getClientWithFundraisers(filter?: Paging, donorId?: string): Observable<ClientModel[]> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    if (donorId) {
      params = params.append('donorId', donorId)
    }
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<ClientModel[]>(`${config.apiURL}/${this.controllerName}/clientWithFundraisers`, { params });
      }));
  }

  public getTotalChildren(filter: Paging): Observable<number> {
    const params: HttpParams = PagingHelper.getPagingParams(filter, false);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<TotalRowsModel>(`${config.apiURL}/${this.controllerName}/TotalChildren`, { params })
          .pipe(map(model => model.total));
      })
    );
  }

  public getAvailableChildren(filter: Paging): Observable<ClientModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<ClientModel[]>(`${config.apiURL}/${this.controllerName}/Children`, { params });
      })
    );
  }

  public getClientsByZipCodeRadius(zipCode: string, radius: number): Observable<ClientModel[]> {
    const params: HttpParams = new HttpParams().append('radius', radius.toString());
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<ClientModel[]>(`${config.apiURL}/${this.controllerName}/ClientsByZipCode/${zipCode}`, { params });
      }));
  }

  public getPostTotal(filter?: Paging): Observable<number> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/Total`;
        return this.http.post<TotalRowsModel>(url, filter).pipe(map(model => model.total));
      })
    );
  }
}
