import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {FormBuilderStateService} from "../../../form-builder.state.service";
import FormElementDataModel from "../../../../../models/form.element.data.model";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-one-time-donation-email-settings-tab',
  templateUrl: './one-time-donation-email-settings-tab.component.html',
  styleUrls: ['../../fb.page.scss']
})
export class OneTimeDonationEmailSettingsTabComponent implements OnInit {
 @Input() public fbss: FormBuilderStateService;
 @Input() public placeholders: string[];
 @Input() public formStatusOptions: FormElementDataModel[];
 @Output() public valueChanged: EventEmitter<void> = new EventEmitter<void>();
 @Output() public resetEmailForNotification: EventEmitter<void> = new EventEmitter<void>();
 @Output() public resetEmailToDonor: EventEmitter<void> = new EventEmitter<void>();
  constructor() { }

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }



  ngOnInit(): void {
  }

}
