import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import { Subscription } from 'rxjs';
import { FormValidators } from 'src/app/shared/validatiors/form-validators';
import { distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-signup-email-settings',
  templateUrl: './signup-email-settings.component.html',
  styleUrls: ['./signup-email-settings.component.scss']
})
export class SignupEmailSettingsComponent implements OnInit, OnChanges {
  @Input() fbss: any;  // Replace 'any' with the appropriate type if known.
  @Input() config: any;
  @Input() loadingData: any;
  @Input() defaultEmail: string;
  @Output() formChange = new EventEmitter<any>();

  // Define the FormGroup
  public settingsForm: FormGroup;
  public formLoaded: boolean;
  private subscription: Subscription = new Subscription();
  public placeholders: string[] = ['DonorName', 'DonorFirstName', 'DonorLastName', 'Email', 'OrganizationName', 'Phone', 'PrimaryContact'];
  public isUpdating = false;
  public clientId: string;

  public yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  constructor(private fb: FormBuilder, private routes: ActivatedRoute) { 
    this.settingsForm = this.fb.group({
      notifyEmails: ['', [Validators.required, Validators.email]],
      subjectEmailNotification: ['', Validators.required],
      bccEmail: [null, {validators: FormValidators.emailListValidator, updateOn: 'blur'}],
      emailNotification: ['', Validators.required],
      sendConfirmationEmail: [false, Validators.required]
    });
  }

  ngOnInit(): void {
    this.clientId = this.routes.snapshot.params.clientID;
    this.formLoaded = false;

    this.settingsForm.valueChanges
    .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
    .subscribe(() => {
      if (!this.isUpdating) {
        //this.settingsForm = { ...this.settingsForm, ...changes };
        this.onSubmit();
      }
    });
    
    if(!this.config) {
      this.settingsForm.patchValue({
        notifyEmails: this.defaultEmail,
        emailNotification: this.getEmailPlaceholder(),
        subjectEmailNotification: this.getEmailSubject()
      });
    }
    this.formLoaded = true;
  }

  private getEmailPlaceholder(): string {
    return `<div>Dear [DonorName],</div><div><br></div><div>Thank you for signing up to connect with [OrganizationName]! We're thrilled to have you join our community.</div><div><br></div><div>Your interest means a lot to us, and we're excited to explore how we can collaborate and make a meaningful impact together. Whether you're looking to stay updated, get involved, or learn more about what we do, we're here to support you every step of the way.</div><div><br></div><div>Feel free to reach out if you have any questions or need more information—we'd love to hear from you.</div><div><br></div><div>[PrimaryContact]</div><div>[OrganizationName]</div>`;
  }

  private getEmailSubject(): string {
    return `Welcome! Thank You for Connecting with Us`;
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.config && changes.config.currentValue) {
      this.isUpdating = true;
      this.updateFormWithConfig(changes.config.currentValue);
      this.isUpdating = false;
    }
  }

  private updateFormWithConfig(config: any) {
      if (!this.settingsForm) {
        return;
      }

      this.settingsForm.patchValue({
        notifyEmails: config.confirmationMailFromAddress ?? this.defaultEmail,
        subjectEmailNotification: config.confirmationMailFromSubject ? config.confirmationMailFromSubject : this.getEmailSubject(),
        bccEmail: config.confimationMailBCC,
        emailNotification: config.confirmationMailBody ? config.confirmationMailBody : this.getEmailPlaceholder(),
        sendConfirmationEmail: config.sendConfirmationEmail
      });
      this.formLoaded = true;
  }

  public resetEmailBody(): void {
    this.settingsForm.get('emailNotification').setValue(this.getEmailPlaceholder());
  }

  onRadioChange(value: any, controlName: string) {
    const control = this.settingsForm.get(controlName);
    control.setValue(value);
    control.markAsDirty();
  }

  onSubmit() {
    
    const signUpConfig = {
      confirmationMailFromAddress: this.settingsForm.value.notifyEmails,
      confirmationMailFromSubject: this.settingsForm.value.subjectEmailNotification,
      confimationMailBCC: this.settingsForm.value.bccEmail,
      confirmationMailBody: this.settingsForm.value.emailNotification,
      sendConfirmationEmail: this.settingsForm.value.sendConfirmationEmail,
    };

    if (this.settingsForm.valid) {
      this.formChange.emit({ formData: signUpConfig, isValid: true });
    }else {
      this.formChange.emit({ formData: signUpConfig, isValid: false });
    }
  }
}
