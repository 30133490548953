<div class="email-builder-settings-wrapper">
  <mat-accordion multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <div class="panel-title">{{'BUILD.General settings' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>

        <div class="email-builder-settings-row">
          <div class="mat-slider-container" [formGroup]="generalForm">
            <span class="mat-slider-container-title">{{'BUILD.Content width' | translate}}</span>
            <mat-slider
              [disabled]="readOnly || isPredesignedThemedTemplate"
              [max]="1920"
              [min]="500"
              [step]="20"
              [thumbLabel]="true"
              [tickInterval]="tickInterval"
              color="primary"
              formControlName="width"
              (change)="onWidthChanged()"
            ></mat-slider>
          </div>
        </div>

        <div class="email-builder-settings-row">
          <div class="max-width-145 right-margin">
            <app-email-color-picker
              [formGroup]="generalForm"
              label="Background color"
              name="backgroundColor"
              [readOnly]="readOnly || isPredesignedThemedTemplate"
              (colorChanged)="onBackgroundColorChanged()"
            ></app-email-color-picker>
          </div>
        </div>

        <div class="email-builder-settings-row">
          <app-image-loader
            [formGroup]="generalForm"
            name="backgroundUrl"
            label="Upload background image"
            [readOnly]="readOnly || isPredesignedThemedTemplate"
            [imageURL]="imageURL"
            (valueChanged)="imageUrlChanged($event)"
          ></app-image-loader>
        </div>

        <div class="email-builder-settings-row">
          <div class="max-width-145 right-margin">
            <app-input
              label="Background image URL"
              [formGroup]="generalForm"
              name="backgroundUrl"
              errorMassage="URL"
              [low]="true"
              [disabled]="readOnly || isPredesignedThemedTemplate"
              (valueChanged)="onBackgroundUrlChanged()"
            ></app-input>
          </div>
          <app-button
            *ngIf="generalForm.get('backgroundUrl') && generalForm.get('backgroundUrl').value && !imageURL && !readOnly && !isPredesignedThemedTemplate"
            label="Edit Image"
            type="flat"
            (clickEvent)="editImage()"
            color="#0064be"
            width="170"
          ></app-button>
         <!-- <app-button
            *ngIf="!!imageURL"
            label="Cancel"
            type="flat"
            (clickEvent)="cancelEditImage()"
            color="#ec4c47"
            width="140"
          ></app-button>-->
        </div>

        <div class="email-builder-settings-row">
          <div class="max-width-145 right-margin">
            <app-select
              label="Repeat Background?"
              [formGroup]="generalForm"
              name="backgroundRepeat"
              [low]="true"
              [selectOptions]="backgroundRepeatOptions"
              [disabled]="readOnly || isPredesignedThemedTemplate"
              (valueChanged)="onBackgroundRepeatChanged()"
            ></app-select>
          </div>
          <div class="max-width-145">
            <app-select
              label="Scale background image"
              [formGroup]="generalForm"
              name="backgroundSize"
              [low]="true"
              [selectOptions]="backgroundSizeOptions"
              [disabled]="readOnly || isPredesignedThemedTemplate"
              (valueChanged)="onBackgroundSizeChanged()"
            ></app-select>
          </div>
        </div>

      </ng-template>

    </mat-expansion-panel>

    <mat-expansion-panel #contentExpansionPanelRef>
      <mat-expansion-panel-header>
        <div class="panel-title">{{'BUILD.Content settings' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div *ngIf="!tms.activeElement; else elementSettingsTemplate">{{'BUILD.Select template element' | translate}}</div>
      </ng-template>

    </mat-expansion-panel>

    <mat-expansion-panel #rowExpansionPanelRef>
      <mat-expansion-panel-header>
        <div class="panel-title">{{'BUILD.Section settings' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div *ngIf="!tms.activeRow || tms.activeRow.tagName === MjmlTag.body; else rowSettingsTemplate">{{'BUILD.Select template section' | translate}}</div>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel #columnExpansionPanelRef>
      <mat-expansion-panel-header>
        <div class="panel-title">{{'BUILD.Column settings' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div *ngIf="!tms.activeColumn; else columnSettingsTemplate">{{'BUILD.Select template column' | translate}}</div>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel
      #headerAndFooterPanelRef
      *ngIf="tms.templateAssignmentType === TemplateAssignmentType.Event"
    >
      <mat-expansion-panel-header>
        <div class="panel-title">{{'BUILD.Header and Footer settings' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-header-footer-settings [tms]="tms"></app-header-footer-settings>
      </ng-template>

    </mat-expansion-panel>
  </mat-accordion>
</div>

<!-- Template for Active element settings -->
<ng-template #elementSettingsTemplate>
  <ng-container [ngSwitch]="tms.activeElement.tagName">

    <ng-container *ngSwitchCase="MjmlTag.text">
      <app-text-tool-settings *ngIf="isSimpleText" [tms]="tms"></app-text-tool-settings>
      <ng-container *ngIf="!isSimpleText" [ngSwitch]="tms.activeElement.attributes['customType']">
        <app-video-tool-settings *ngSwitchCase="MjmlCustomType.video" [tms]="tms"></app-video-tool-settings>
        <app-thermometer-tool-settings *ngSwitchCase="MjmlCustomType.thermometer" [eventId]="eventId" [tms]="tms"></app-thermometer-tool-settings>
        <app-signature-tool-settings *ngSwitchCase="MjmlCustomType.signature" [tms]="tms"></app-signature-tool-settings>
        <app-email-video-tool-settings *ngSwitchCase="MjmlCustomType.emailVideo" [tms]="tms"></app-email-video-tool-settings>
        <app-donation-activity-settings *ngSwitchCase="MjmlCustomType.donationActivity" [tms]="tms"></app-donation-activity-settings>
        <app-comment-wall-settings *ngSwitchCase="MjmlCustomType.commentWall" [tms]="tms"></app-comment-wall-settings>
        <app-share-on-sm-settings *ngSwitchCase="MjmlCustomType.shareOnSM" [tms]="tms"></app-share-on-sm-settings>
        <app-module-tool-settings *ngSwitchDefault [tms]="tms"></app-module-tool-settings>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MjmlTag.image">
      <app-image-tool-settings *ngIf="!isChart" [tms]="tms"></app-image-tool-settings>
      <app-chart-tool-settings *ngIf="isChart" [tms]="tms"></app-chart-tool-settings>
    </ng-container>

    <app-button-tool-settings *ngSwitchCase="MjmlTag.button" [tms]="tms"></app-button-tool-settings>
    <app-table-tool-settings *ngSwitchCase="MjmlTag.table" [tms]="tms"></app-table-tool-settings>
    <app-sm-tool-settings *ngSwitchCase="MjmlTag.social" [tms]="tms"></app-sm-tool-settings>
    <app-divider-tool-settings *ngSwitchCase="MjmlTag.divider" [tms]="tms"></app-divider-tool-settings>
    <app-sponsors-tool-settings *ngSwitchCase="MjmlTag.sponsors" [tms]="tms"></app-sponsors-tool-settings>
    <div *ngSwitchCase="MjmlTag.headerFooter">{{'BUILD.Select template element' | translate}}</div>
<!--    <app-header-footer-settings *ngSwitchCase="MjmlTag.headerFooter" [tms]="tms"></app-header-footer-settings>-->
  </ng-container>
</ng-template>

<!-- Template for Active Row (mjml-section) settings -->
<ng-template #rowSettingsTemplate>
  <app-row-settings [tms]="tms"></app-row-settings>
</ng-template>


<!-- Template for Active Column (mjml-column) settings -->
<ng-template #columnSettingsTemplate>
  <app-column-settings [tms]="tms"></app-column-settings>
</ng-template>
