import { BasePagingComponent } from '../paginator/base.paging.component';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { EVENTS_EMAILS_COLUMNS, SENDGRID_STATUSES } from '../../constants';
import { Filter } from '../../models/paging/filter.model';
import { FilterType } from '../../models/enum/filter.type';
import { formatDate } from '@angular/common';
import { Paging } from '../../models/paging/paging.model';
import { SortOrder } from '../../models/enum/sort.order';
import { Observable, Subscription } from 'rxjs';
import ColumnFilterModel from '../../models/filter-sort/column.filter.model';
import ColumnFilterOptionsModel from '../../models/filter-sort/column.filter.options.model';
import FilterSortModel from '../../models/filter-sort/filter.sort.model';
import FormElementDataModel from '../../models/form.element.data.model';
import { UtilsComponent } from '../utils.component';
import DonorTrackedEmailModel from '../../models/donor/donor-tracked-email.model';
import { NotificationService } from '../../services/reports/notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AdminConfirmationComponent } from '../../routes/admin/admin-confirmation/admin-confirmation.component';
import { DonorTrackedEmailService } from '../../services/reports/donor-tracked-email.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-history-emails',
  templateUrl: './history-emails.component.html',
  styleUrls: ['./history-emails.component.scss']
})
export class HistoryEmailsComponent extends BasePagingComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public clientDonorID: string = '';
  public columnsFilterOptions: ColumnFilterOptionsModel[] = [];
  public donorTrackedEmails: DonorTrackedEmailModel[] = [];
  public subscription: Subscription = new Subscription();
  public tableColumns: string[] = [];

  public selectedEmails: Set<string> = new Set<string>();

  // Checkbox state
  public allEmailsSelected: boolean;
  public someEmailsSelected: boolean;

  public filterSortConfig: FilterSortModel = {
    sortField: 'createdOn',
    sortOrder: SortOrder.Descending,
    columnFilters: []
  };

  public distinctFields: string[] = ['email', 'status'];

  constructor(
    private notificationService: NotificationService,
    private donorTrackedEmailService: DonorTrackedEmailService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private translate: TranslateService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.tableColumns = EVENTS_EMAILS_COLUMNS;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.clientDonorID.currentValue) {
      this.getDonorTrackedEmails([]);
      this.getTotal([]);
    }
    if (changes.clientDonorID && changes.clientDonorID.currentValue) {
      this.getDistinctValues();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getDonorTrackedEmails(currentFilters: Filter[]): void {
    const baseFilter: Filter[] = [
      {
        field: 'DonorID',
        value: this.clientDonorID,
        type: FilterType.Equal,
      }
    ];
    const filters: Filter[] = [...baseFilter, ...currentFilters];
    const paging: Paging = {
      first: this.getFirstItemOnPage(),
      rows: this.entriesPerPage,
      includeDependencies: true,
      selectFields: ['ID', "CreatedOn", "Email", "DonorID", "Subject", "Status", "StatusTime"],
      sortOrder: this.filterSortConfig.sortOrder,
      sortField: this.filterSortConfig.sortField,
      filters,
    };
    this.subscription.add(
      this.donorTrackedEmailService.getModelList(paging)
        .subscribe((donorTrackedEmails: DonorTrackedEmailModel[]) => {
          this.donorTrackedEmails = donorTrackedEmails.map(element => ({ isSelected: this.selectedEmails.has(element.id), ...element }));
          this.updateAllEmailsCheckboxState();
        })
    );
  }

  private getTotal(currentFilters: Filter[]): void {
    const baseFilter: Filter[] = [
      {
        field: 'DonorID',
        value: this.clientDonorID,
        type: FilterType.Equal,
      }
    ];
    const filters: Filter[] = [...baseFilter, ...currentFilters];
    const paging: Paging = {
      includeDependencies: false,
      filters
    };
    this.subscription.add(
      this.donorTrackedEmailService.getTotal(paging)
        .subscribe((total: number) => {
          this.total = total;
        })
    );
  }

  public getNextPage(page: number): void {
    this.nextPage(page, () =>
      this.getDonorTrackedEmails(this.tableFilter)
    );
  }

  public setEntriesPerPage(amount: number): void {
    this.entriesPerPageChanged(amount, () =>
      this.getDonorTrackedEmails(this.tableFilter)
    );
  }

  private get tableFilter(): Filter[] {
    if (!this.filterSortConfig.columnFilters.length) {
      return [];
    } else {
      return this.filterSortConfig.columnFilters.map((columnFilter: ColumnFilterModel) => {
        let values;
        if (columnFilter.field === 'createdOn') {
          values = columnFilter.values.map((item: string) => `${item}`).join('').slice(0, -1);
        } else {
          values = columnFilter.values.map((item: string) => `"${item}"`).join(',');
        }
        return {
          field: columnFilter.field,
          value: `array[${values}]`,
          type: FilterType.Equal,
        };
      });
    }
  }

  public onCurrentFilterChanged(columnFilter: ColumnFilterModel): void {
    this.filterSortConfig.columnFilters = UtilsComponent.updateColumnFilters(columnFilter, this.filterSortConfig);
    this.onFilterChanged();
    this.getDonorTrackedEmails(this.tableFilter);
    this.getTotal(this.tableFilter);
  }

  public onSortChanged(event: FilterSortModel): void {
    this.filterSortConfig.sortField = event.sortField;
    this.filterSortConfig.sortOrder = event.sortOrder;
    this.getDonorTrackedEmails(this.tableFilter);
  }

  public onEmailSelectChange(email: DonorTrackedEmailModel): void {
    if (email.isSelected) {
      this.selectedEmails.add(email.id);
    } else {
      this.selectedEmails.delete(email.id);
    }

    this.updateAllEmailsCheckboxState();
  }

  private clearSelected() {
    this.selectedEmails.clear();
    this.donorTrackedEmails.forEach(x => x.isSelected = false);
  }

  public selectAllEmails(val: boolean): void {
    if (val) {
      const baseFilter: Filter[] = [
        {
          field: 'DonorID',
          value: this.clientDonorID,
          type: FilterType.Equal,
        }
      ];
      const paging: Paging = {
        selectFields: ['id'],
        filters: [...baseFilter, ...this.tableFilter],
        first: null,
        rows: null,
      };
      this.donorTrackedEmailService.getModelList(paging)
        .subscribe((ids: DonorTrackedEmailModel[]) => {
          this.selectedEmails = new Set<string>(ids.map(x => x.id));
          this.donorTrackedEmails.forEach(x => x.isSelected = true);
          this.updateAllEmailsCheckboxState();
        });
    } else {
      this.clearSelected();
      this.updateAllEmailsCheckboxState();
    }
  }

  public onResendSelectedClick(): void {
    if (this.selectedEmails.size <= 0) return;

    const configFirst: MatDialogConfig = {
      data: {
        title: `This action will resend ${this.selectedEmails.size} ${this.selectedEmails.size == 1 ? "email" : "emails"}. Do you want to continue?`,
      },
    };
    this.dialog
      .open(AdminConfirmationComponent, configFirst)
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.resendEmails();
        }
      });
  }

  public resendEmails(): void {
    this.donorTrackedEmailService.resendEmails(Array.from(this.selectedEmails.values()))
      .subscribe((res: boolean) => {
        if (res) {
          this.toastrService.success(`${this.selectedEmails.size == 1 ? "Email" : "Emails"} successfully resent.`);
        } else {
          this.toastrService.error(`There was a problem with resending ${this.selectedEmails.size == 1 ? "email" : "emails"}.`);
        }
      }, (err: any) => this.toastrService.error(`There was a problem with resending ${this.selectedEmails.size == 1 ? "email" : "emails"}.`));
  }

  public getStatusName(status: string): string {
    if (SENDGRID_STATUSES.hasOwnProperty(status)) {
      return SENDGRID_STATUSES[status];
    }

    return status;
  }

  private onDistinctValuesReceived(distinctValues: any): void {
    this.columnsFilterOptions = this.distinctFields.map((column: string) => {
      const optionsList = distinctValues[column].filter((item: string) => item !== null && item !== undefined && item !== '');
      let options;

      if (column === 'email') {
        options = optionsList.map((option: string) => ({ label: option, value: option }));
      }
      else if (column === 'status') {
        options = optionsList.map((option: string) => ({ label: this.getStatusName(option), value: option }));
      }

      return {
        columnName: column,
        options
      };
    });
  }

  private getDistinctValues(): void {
    const baseFilter: Filter[] = [
      {
        field: 'DonorID',
        value: this.clientDonorID,
        type: FilterType.Equal,
      }
    ];
    const paging: Paging = {
      includeDependencies: false,
      filters: baseFilter,
      selectFields: this.distinctFields,
    };

    this.subscription.add(
      this.donorTrackedEmailService.getDistinctValues(paging)
      .subscribe((value: any) => {
        this.onDistinctValuesReceived(value);
      })
    );
  }

  public updateAllEmailsCheckboxState(): void {
    if (this.selectedEmails == null) {
      this.allEmailsSelected = false;
      this.someEmailsSelected = false;
    }

    this.allEmailsSelected = this.selectedEmails.size > 0 && this.selectedEmails.size === this.total;
    this.someEmailsSelected = this.selectedEmails.size > 0 && !this.allEmailsSelected;
  }
}
