import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import ColumnFilterOptionsModel from 'src/app/models/filter-sort/column.filter.options.model';
import FilterSortModel from 'src/app/models/filter-sort/filter.sort.model';
import ColumnFilterModel from 'src/app/models/filter-sort/column.filter.model';
import { SortOrder } from 'src/app/models/enum/sort.order';
import { MatTableDataSource } from '@angular/material/table';
import TemplateTagStatisticModel from 'src/app/models/templates/template.tag.statistic.model';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-template-tag-statistics-table',
  templateUrl: './template-tag-statistics-table.component.html',
  styleUrls: ['./template-tag-statistics-table.component.scss']
})
export class TemplateTagStatisticsTableComponent implements OnInit, OnChanges {

  @ViewChild('tableRef', { read: ElementRef }) public table: ElementRef;
  @Input() public columnsFilterOptions: ColumnFilterOptionsModel[] = [];
  @Input() public data: TemplateTagStatisticModel[] = [];
  @Input() public filterSortConfig: FilterSortModel;
  @Input() public isSettingsVisible: boolean;
  @Input() public isFiltersVisible: boolean;
  @Input() public tableDataColumns: string[] = [];
  @Input() public rolesPermission: boolean = false;

  @Output() public deleteRow: EventEmitter<string> = new EventEmitter<string>();
  @Output() public editRow: EventEmitter<string> = new EventEmitter<string>();
  @Output() public filterChanged: EventEmitter<ColumnFilterModel> = new EventEmitter<ColumnFilterModel>();
  @Output() public sortChanged: EventEmitter<FilterSortModel> = new EventEmitter<FilterSortModel>();

  public sortOrder: SortOrder;
  public tableColumns: string[] = [];
  public tableSource: MatTableDataSource<TemplateTagStatisticModel>;
  public clientAdminPermission: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableDataColumns) {
      this.tableColumns = this.isSettingsVisible
        ? [... this.tableDataColumns, 'settings']
        : [... this.tableDataColumns];
    }
    if (this.data) {
      this.tableSource = new MatTableDataSource<TemplateTagStatisticModel>(this.data);
    }
  }

  public getColumnTitle(column: string): string {
    const columnsTitles = {
      createdOn: 'Created Date',
      name: 'Tag Title',
      numberOfUsed: '# of Times Used',
      lastUsedOn: 'Last Used',
      lastUsedByUserName: 'Last Used',
    };
    return columnsTitles[column];
  }

  public onEditLinkClick(event: string): void {
    this.editRow.next(event);
  }

  public checkColumn(column: string): boolean {
    return this.filterSortConfig.sortField === column;
  }

  public onDeleteLinkClick(event: string): void {
    this.deleteRow.next(event);
  }

  public getTextNode(element: TemplateTagStatisticModel, column: string): string {
    switch (column) {
      case 'createdOn':
        return element[column]
          ? formatDate(element[column], 'MM/dd/yyyy', 'en-US')
          : '';
      default:
        return element[column];
    }
  }
  public sortTable(column: string): void {
    switch (this.sortOrder) {
      case SortOrder.Ascending:
        this.sortOrder = SortOrder.Descending;
        break;
      case SortOrder.Descending:
        this.sortOrder = SortOrder.Ascending;
        break;
      default:
        this.sortOrder = SortOrder.Ascending;
    }
    const result = <FilterSortModel> {
      sortField: column,
      sortOrder: this.sortOrder
    };
    this.sortChanged.emit(result);
  }

  public withFilter(column: string): boolean {
    return (
      column === 'createdOn' ||
      column === 'name' ||
      column === 'numberOfUsed' ||
      column === 'lastUsedOn' ||
      column === 'lastUsedByUserName'
    );
  }


  public getSelectOptions(column: string): ColumnFilterOptionsModel {
    return this.columnsFilterOptions.find(
      options => options.columnName === column
    );
  }

  public selectedOptions(event: ColumnFilterModel): void {
    this.filterChanged.emit(event);
  }

  public isActiveFilter(column: string): boolean {
    if (!this.filterSortConfig) {
      return false;
    } else {
      const { columnFilters }: FilterSortModel = this.filterSortConfig;
      return !!columnFilters.find(
        ({ field }: ColumnFilterModel) => field === column
      );
    }
  }


}
