import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CampaignSegmentService } from 'src/app/services/campaign/segment.service';

@Component({
  selector: 'app-unsubscribe-campaign',
  templateUrl: './unsubscribe-campaign.component.html',
  styleUrls: ['./unsubscribe-campaign.component.scss']
})
export class UnsubscribeCampaignComponent implements OnInit {
  public success: boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private segmentService: CampaignSegmentService) { }

  ngOnInit() {
  }

  public unsubscribe(): void {
    const clientId = this.activatedRoute.snapshot.paramMap.get('clientId');
    const clientDonorId = this.activatedRoute.snapshot.paramMap.get('clientDonorId');
    const isAlt = this.activatedRoute.snapshot.queryParamMap.get('isAlt');
    this.segmentService.unsubscribeRecipient(clientId, clientDonorId, isAlt).subscribe(() => {
      this.success = true;
    });
  }

  public cancel(): void {
    this.router.navigate(['']);
  }
}
