import { BehaviorSubject } from 'rxjs';
import CountryModel from '../../models/internationalization/country.model';
import FormElementDataModel from '../../models/form.element.data.model';
import { CODE, LOCALITY, POLITICAL_UNIT, TERRITORIAL_ENTITY } from '../../constants';
import { TerritorialEntityType } from '../../models/internationalization/territorial.entity.type';
import { LocalityType } from '../../models/internationalization/locality.type';
import { PoliticalUnitType } from '../../models/internationalization/political.unit.type';
import { CodeType } from '../../models/internationalization/code.type';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { EventStatus } from '../../models/enum/event.status';
import { Injectable } from "@angular/core";

@Injectable()
export class CountryStateService {
  public countryState: BehaviorSubject<Partial<CountryModel>> = new BehaviorSubject<Partial<CountryModel>>(null);
  public countries: CountryModel[];
  public countriesOptions: FormElementDataModel[] = [];

  public territorialEntity$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public territorialEntityError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showTerritorialEntity$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public city$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public cityError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showCity$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public state$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public stateError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isUsa$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public zip$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public zipError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showZip$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public zipMask$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public zipPlaceholder$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public isFirstChange: boolean = true;
  public phonePrefix$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public phoneMask$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public phonePlaceholder$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public country$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public streetAddress$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private form: FormGroup;

  private eventStatus: EventStatus = EventStatus.New;

  constructor() {
    this.countryState.subscribe((nextState: Partial<CountryModel>) => {
      if (!nextState) {
        return;
      }
      const {territorialEntityType, localityType, politicalUnitType, name, codeType, phoneCode = '1'}: Partial<CountryModel> = nextState;
      const maxLength: ValidatorFn = Validators.maxLength(250);
      const required: ValidatorFn = Validators.required;
      const validator = name === 'USA' ? this.eventStatus === EventStatus.Live ? [required, maxLength] : [maxLength] : [maxLength];

      this.country$.next(name);
      this.isUsa$.next(name === 'USA');

      this.territorialEntity$.next(TERRITORIAL_ENTITY[territorialEntityType]);
      this.territorialEntityError$.next(TERRITORIAL_ENTITY[territorialEntityType]);
      const isTerritorialEntityActive = territorialEntityType !== TerritorialEntityType.Unknown;
      this.showTerritorialEntity$.next(isTerritorialEntityActive);
      const territorialEntity = this.getControl('territorialEntity');
      if (territorialEntity) {
        !this.isFirstChange && territorialEntity?.reset();
        isTerritorialEntityActive ? territorialEntity?.enable() : territorialEntity?.disable();
      }

      this.streetAddress$.next(name === 'USA' ? 'Street Address *' : 'Street Address');
      const address1 = this.getControl('address1');
      address1 && address1.setValidators(validator);
      address1 && address1.updateValueAndValidity();

      const locality = name === 'USA' ? `${LOCALITY[localityType]} *` : LOCALITY[localityType];
      this.city$.next(locality);
      this.cityError$.next(LOCALITY[localityType]);
      const isCityActive = localityType !== LocalityType.Unknown;
      this.showCity$.next(isCityActive);
      const city = this.getControl('city');
      !this.isFirstChange && city?.reset();
      if (city) {
        isCityActive ? city?.enable() : city?.disable();
        city.setValidators(validator);
        city.updateValueAndValidity();
      }

      const unit = name === 'USA' ? `${POLITICAL_UNIT[politicalUnitType]} *` : POLITICAL_UNIT[politicalUnitType];
      this.state$.next(unit);
      this.stateError$.next(POLITICAL_UNIT[politicalUnitType]);
      const isStateActive = politicalUnitType !== PoliticalUnitType.Unknown;
      this.showState$.next(isStateActive);
      const state = this.getControl('state');
      const stateValidator = name === 'USA' && this.eventStatus === EventStatus.Live ? [required] : [maxLength];
      !this.isFirstChange && state?.reset();
      if (state) {
        isStateActive ? state?.enable() : state?.disable();
        state.setValidators(stateValidator);
        state.updateValueAndValidity();
      }

      const code = (name === 'USA' || name.startsWith('Puerto')) ? `${CODE[codeType]} *` : CODE[codeType];
      this.zip$.next(code);
      this.zipError$.next(CODE[codeType]);
      const isZipActive = codeType !== CodeType.Unknown;
      this.showZip$.next(isZipActive);
      const zipCode = this.getControl('zip');
      !this.isFirstChange && zipCode?.reset();
      if (zipCode) {
        isZipActive ? zipCode?.enable() : zipCode?.disable();
        const zipValidator = (name === 'USA' || name.startsWith('Puerto')) ? [Validators.pattern('^(\\d{5}|\\d{9})$')] : [Validators.maxLength(30)];
        this.eventStatus === EventStatus.Live && zipValidator.push(Validators.required);
        zipCode.setValidators(zipValidator);
        this.zipMask$.next((name === 'USA' || name.startsWith('Puerto')) ? '00000-9999' : '');
        this.zipPlaceholder$.next((name === 'USA'  || name.startsWith('Puerto')) ? '00000-0000' : '');
        zipCode.updateValueAndValidity();
      }

      const prefix = `+${phoneCode} `;
      this.phonePrefix$.next(prefix);
      const mask = name === 'USA' ? '(000) 000-0000' : '00000000999999999999'.slice(0, -phoneCode.length);
      this.phoneMask$.next(mask);
      this.phonePlaceholder$.next(prefix);

      !this.isFirstChange && address1?.reset();
      !this.isFirstChange && this.getControl('address2')?.reset();

      this.form?.updateValueAndValidity();
      this.isFirstChange = false;
    })
  }

  public setState(state: Partial<CountryModel>): void {
    setTimeout(() => this.countryState.next(state));
  }

  public setCountryById(id: number): void {
    if (id === 0) {
      id = 1;
    }
    const model = this.countries.find(country => country.id === +id);
    this.setState(model ? model : null);
  }

  public setDefaultCountry(): void {
    this.setCountryById(1);
  }

  public storeCountries(countries: CountryModel[]): void {
    this.countries = countries;
    this.countriesOptions = countries.map(({name, id}: CountryModel) => ({label: name, value: id}));
  }

  public setForm(form: FormGroup): void {
    this.form = form;
  }

  private getControl(controlName: string): FormControl {
    return this.form?.get(controlName) as FormControl;
  }

  public updateEventStatus(status: EventStatus): void {
    this.eventStatus = status;
  }

}
