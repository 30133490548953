import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})
export class RateComponent {
  @Input()
  public value: number;
  @Input()
  public label: string;
  @Input()
  public infoMessage: string;
  @Input()
  public isClicked: boolean = false;
  @Input()
  public navigateUrl: string = '';
  constructor(
    private router: Router
  ) {
  }

  public getUnit(): string {
    return (this.label === 'Avg Retention Rate' || this.label === 'Avg Churn Rate') ? '%' : '';
  }

  public getCurrency(): string {
    return this.label === 'Avg Lifetime Value' ? '$' : '';
  }

  public goToReport(): void {
    if (this.isClicked && this.navigateUrl) {
          this.router.navigateByUrl(`clients/reports${this.navigateUrl}`);
    }
  }
}
