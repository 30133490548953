<div class="language-picker-container" [matMenuTriggerFor]="menu">
  <div
    class="language-picker-wrapper"
    [style.color]="color"
  >
    <img [src]="src$ | async">
    <span *ngIf="showLabel">{{label$ | async}}</span>
    <i class="fas fa-caret-up" *ngIf="trigger?.menuOpen"></i>
    <i class="fas fa-caret-down" *ngIf="!trigger?.menuOpen"></i>
  </div>
  <span
    *ngIf="!showLabel"
    class="language-picker-label"
    [style.color]="color"
    [style]='innerStyles'
  >{{'Language' | translate}}</span>
</div>

<mat-menu #menu="matMenu">
  <div class="language-picker-menu-wrapper">
    <div
      mat-menu-item
      *ngFor="let option of selectOptions"
      (click)="onMenuItemClick(option.value)"
      class="language-menu-item"
    >
      <img [src]="'/assets/flags/' + option.value + '.svg'">
      <span>{{option.label}}</span>
    </div>
  </div>
</mat-menu>
