import { Component, Input, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import FormElementDataModel from '../../models/form.element.data.model';
import {LanguageType} from '../../models/i18n/language.type';
import {LANGUAGES_OPTIONS} from '../../models/i18n/languages.const';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss']
})
export class LanguagePickerComponent implements OnInit {
  @Input() public showLabel: boolean = false;
  @Input() public color: string = '#fff';
  @Input() public innerStyles: string = '';
  public src$: Observable<string>;
  public label$: Observable<string>;
  public selectOptions: FormElementDataModel[] = LANGUAGES_OPTIONS;
  @ViewChild(MatMenuTrigger) public trigger: MatMenuTrigger;

  constructor(
    public translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    const startValue: LangChangeEvent = {
      lang: this.translate.currentLang,
      translations: null
    };
    this.src$ = this.translate.onLangChange
      .pipe(
        startWith(startValue),
        map(({lang}: LangChangeEvent) => `/assets/flags/${lang}.svg`)
      );
    if (this.showLabel) {
      this.label$ = this.translate.onLangChange
        .pipe(
          startWith(startValue),
          map(({lang}: LangChangeEvent) => LANGUAGES_OPTIONS.find(({value}) => value === lang).label)
        );
    }
    this.cdr.detectChanges();
  }

  public onMenuItemClick(value: LanguageType): void {
    this.translate.use(value);
    localStorage.setItem('auxilia-app-language', value);
  }


}
