import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ConfigService } from './services/appconfig.service';
import { UtilsComponent } from './components/utils.component';
import { TranslateService } from '@ngx-translate/core';
import {LANGUAGES} from './models/i18n/languages.const';
import {LanguageType} from './models/i18n/language.type';

declare global {
  interface Window {
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'auxilia';

  constructor(
    private config: ConfigService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    translate.addLangs(LANGUAGES);
    translate.setDefaultLang(LanguageType.English);

    const auxiliaAppLanguage = localStorage.getItem('auxilia-app-language') as LanguageType;
    if (auxiliaAppLanguage && LANGUAGES.includes(auxiliaAppLanguage)) {
      translate.use(auxiliaAppLanguage);
    } else {
      const browserLang = translate.getBrowserLang() as LanguageType;
      const savedLanguage: string = LANGUAGES.includes(browserLang) ? browserLang : LanguageType.English;
      translate.use(savedLanguage);
      localStorage.setItem('auxilia-app-language', savedLanguage);
    }
  }

  public ngOnInit(): void {
    this.route.queryParams.subscribe(resp => {
      if (resp['fundraising'])
        localStorage.setItem('fundraising', resp['fundraising']);
    });
    this.config.loadConfig().subscribe(response => {
      localStorage.setItem('apiurl', response.apiURL);
      localStorage.setItem('stsServerURL', response.stsServerURL);
      localStorage.setItem('appVersion', response.appVersion);
      localStorage.setItem('storageURL', response.storageURL);
    });

    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations()
        .then(function(registrations) {
          for (const registration of registrations) {
            registration.unregister();
          }
        });
    }
  }
}
