<div
  [formGroup]="formGroup"
  [ngClass]="low ? 'low-item-counter-component-container-wrapper' : 'item-counter-component-container-wrapper'"
>
  <label
    class="label-name"
    [for]="id"
  >{{label | translate}}</label>

  <div class="item-counter-container" [class.item-counter-error]="formControl.touched && formControl.invalid">
    <app-minus-icon
      (click)="mathEvent.emit('minus'); minus()"
      [color]="disabled ? '#afafaf' : '#0064be'"
    ></app-minus-icon>

    <input
      type="text"
      [formControlName]="name"
      [id]="id"
      [disabled]="disabled"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      [type]="type"
      [maxlength]="maxLength"
      [mask]="mask ? mask : ''"
      [prefix]="prefix ? prefix : ''"
      [suffix]="suffix ? suffix : ''"
      [thousandSeparator]="thousandSeparator ? thousandSeparator : ''"
      [readonly]="inputReadOnly"
      (blur)="onBlur()"
      (keydown.enter)="onKeydownEnter($event)"
      (input)="onInput($event)"
      [autocomplete]="autocompleteOff ? 'autocompleteOff' : ''"
      (focus)="focusEvent.emit()"
    >

    <app-plus-icon
      (click)="mathEvent.emit('plus'); plus()"
      [color]="disabled ? '#afafaf' : '#0064be'"
    ></app-plus-icon>

  </div>


  <div class="error-message">
    <span *ngIf="formControl.touched && formControl.invalid">{{getErrorMessage()}}</span>
  </div>

</div>
