import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { LoaderService } from '../services/loader.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

const URL_EXCEPTION_LIST: string[] = ['infoNotification', 'import', 'checkImport', 'removeitems', 'campaignActivate', 'segment', 'additems', 'RetentionRateWidget',
  'DonationsWidget', 'topdonationwidget', 'SocialWidget', 'RecentDonationsWidget', 'RecentDonationsWidgetTotal', 'RecentDonationsWidgetDistinct', 'UpcomingEventsWidget',
  'UpcomingEventsWidgetDonations', 'UpcomingEventsWidgetParticipants', 'EventsSummaryWidgetEvents', 'EventsSummaryWidgetEventsTotal', 'EventsSummaryWidgetEventParticipants',
  'AccountsInfo', 'clearall', 'Template', 'distinctvalues', 'lookup', 'eventAttendedYears'];
const URL_EXCEPTION_REG_EXP: RegExp = new RegExp(URL_EXCEPTION_LIST.map(item => item.toLowerCase()).join('|'), 'i');

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private count = 0;

  private static checkUrl(url: string): boolean {
    return URL_EXCEPTION_REG_EXP.test(url.toLowerCase());
  }

  constructor(private loaderService: LoaderService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (LoaderInterceptor.checkUrl(req.url)) {
      return next.handle(req);
    } else {
      this.count++;
      this.loaderService.startRequest();
      return next.handle(req).pipe(
        finalize(() => {
          this.count--;
          if (this.count === 0) {
            this.loaderService.endRequest();
          }
        })
      );
    }
  }
}
