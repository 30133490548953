<div class="recurring-payments-wrapper" *ngIf="!showMessagePage; else infoTemplate">
  <mat-tab-group
    mat-align-tabs="left"
    dynamicHeight
  >

    <mat-tab [label]="'Entire schedule' | translate">
      <div class="recurring-payments-header">
        <div class="recurring-payments-title-wrapper">
          <span
            class="recurring-payments-title">{{ isPublicEditPledgePage ? ('EDIT PLEDGE' | translate) : ('RECURRING DONATION' | translate) }}</span>
          <span class="recurring-payments-back-link" (click)="onBackToAll()" *ngIf="!isPublicEditPledgePage">
        < {{ 'Back to All Donations' | translate }}</span>
        </div>
        <div class="recurring-payments-header-buttons"
             *ngIf="isDataLoaded && !isCancelledAll && !isCompleted && !showMessagePage">
          <app-button
            label="APPROVE"
            (clickEvent)="save()"
            type="flat"
            color="#0064be"
            width="140"
          ></app-button>
          <app-button
            label="CANCEL"
            (clickEvent)="decline()"
            type="flat"
            color="#a9110e"
            width="140"
            [disabled]="recurringSettingsModel?.nextExecutionStatusEnum === PaymentRecurrencyStatus.Cancelled"
          ></app-button>
          <app-button label="STOP RECURRING" (clickEvent)="stopRecurringSchedule()" type="flat" color="#a9110e"
                      width="250"></app-button>
        </div>
        <div class="recurring-payments-header-buttons" *ngIf="isDataLoaded && isCancelledAll">
          <app-button label="RESTART SCHEDULE" (clickEvent)="restartRecurringSchedule()" type="flat" color="#0064be"
                      width="250"></app-button>
        </div>
      </div>

      <div class="recurring-payments-content">

        <span *ngIf="!isPublicEditPledgePage && transactions.length"
              class="row-title">{{ 'Entire schedule' | translate }}</span>
        <div *ngIf="!isPublicEditPledgePage && transactions.length" class="recurring-payments-content-table">
          <app-schedule-table
            [data]="transactions"
            (initiateChargeEvent)="initiateCharge($event)"
            [tableDataColumns]="tableDataColumns"
          ></app-schedule-table>
        </div>

        <span
          class="row-title">{{ isPublicEditPledgePage ? ('Total pledge amount *' | translate) : ('Choose amount to donate *' | translate) }}</span>
        <ng-container *ngIf="!isPublicEditPledgePage && distributeAmongFunds$.getValue() !== null">
          <div class="amount-row" *ngIf="!(distributeAmongFunds$ | async); else fundDistributionTemplate">
            <div class="buttons-row">
              <div class="amount-button" matRipple (click)="!nextAmmount.disabled && nextAmmount.setValue(15)"
                   [class.selected]="nextAmmount.value == 15">$ 15
              </div>
              <div class="amount-button" matRipple (click)="!nextAmmount.disabled && nextAmmount.setValue(25)"
                   [class.selected]="nextAmmount.value == 25">$ 25
              </div>
              <div class="amount-button" matRipple (click)="!nextAmmount.disabled && nextAmmount.setValue(40)"
                   [class.selected]="nextAmmount.value == 40">$ 40
              </div>
              <div class="amount-button" matRipple (click)="!nextAmmount.disabled && nextAmmount.setValue(50)"
                   [class.selected]="nextAmmount.value == 50">$ 50
              </div>
            </div>
            <div class="max-width-200">
              <app-input
                [formGroup]="paymentForm"
                errorMassage="Amount"
                name="nextAmmount"
                mask="separator.2"
                thousandSeparator=","
                [prefix]="nextAmmount.value ? '$ ' : ''"
                placeholder="Enter own amount"
              ></app-input>
            </div>
          </div>
        </ng-container>

        <div class="recurring-payments-row" *ngIf="isPublicEditPledgePage">
          <div class="max-width-370">
            <app-input
              [formGroup]="paymentForm"
              name="ammount"
              mask="separator.2"
              thousandSeparator=","
              prefix="$ "
            ></app-input>
          </div>
        </div>

        <!-- Recurring payments -->
        <div class="recurring-payments-row">
          <div class="max-width-370">
            <!-- Billing type -->
            <app-radio
              [formGroup]="paymentForm"
              name="reccurrencyPaymentType"
              [selectOptions]="billingTypeOptions"
              label="Billing type *"
              (radioValueChanged)="updateBillingType($event)"
              errorMassage="Billing type"
            ></app-radio>

            <!--            <app-fb-radio-->
            <!--              [formGroup]="paymentForm"-->
            <!--              name="donationRecurringOption"-->
            <!--              [selectOptions]="recurringOptions"-->
            <!--              [color]="fbTemplate?.secondaryButtonColor"-->
            <!--              (valueChanged)="recurringUpdate($event)"-->
            <!--            >-->
            <!--            </app-fb-radio>-->
          </div>
        </div>

        <div *ngIf="isOldRecurringOption" class="flex-row w-100 align-items-start">
          <span class="frequency-title">{{ "Frequency" | translate }}</span>
          <p *ngIf="isWeeklyRecurringDonation" class="old-frequency-text">*
            Occurs {{ "Every" | translate }} {{ everyValue.value }} {{ "BUILD.week(s)" | translate }}</p>
          <p *ngIf="isMonthlyRecurringDonation" class="old-frequency-text">*
            Occurs {{ "Every" | translate }} {{ everyValue.value }} {{ "BUILD.month(s)" | translate }}</p>
          <p *ngIf="isYearlyRecurringDonation" class="old-frequency-text">*
            Occurs {{ "Every" | translate }} {{ everyValue.value }} {{ "BUILD.year(s)" | translate }}</p>
        </div>

        <div *ngIf="!isOldRecurringOption" class="recurring-payments-row frequency-row">
          <app-fb-select
            [isDisabled]="true"
            [formGroup]="paymentForm"
            errorMassage="Frequency"
            name="donationFrequency"
            label="Frequency"
            [isTitle]="true"
            [isSearchOption]="false"
            [selectOptions]="donationRecurrenceOptions"
            (valueChanged)="onFrequencyChanged($event)"
            [fontWeight]="'700'"
          ></app-fb-select>
        </div>

        <div class="recurring-payments-row" *ngIf="showStart">
          <div class="max-width-370">
            <!-- Start date -->
            <app-date-picker
              [formGroup]="paymentForm"
              errorMassage="Start date"
              label="Start date *"
              name="startDate"
              [dateFilter]="startDateFilter"
              [disabled]="!showStart"
            ></app-date-picker>
          </div>
        </div>
        <div class="recurring-payments-row" *ngIf="!showStart">
          <div class="max-width-370">
            <!-- Next date -->
            <app-date-picker
              [formGroup]="paymentForm"
              errorMassage="Next date"
              label="Next date *"
              name="nextExecutionDate"
              [dateFilter]="nextDateFilter"
              [disabled]="true"
            ></app-date-picker>
          </div>
        </div>
        <div class="recurring-payments-row" *ngIf="isPublicEditPledgePage">
          <div class="max-width-370">
            <!-- Next Amount -->
            <app-input
              [formGroup]="paymentForm"
              errorMassage="Next Amount"
              label="Next Amount *"
              name="nextAmmount"
            ></app-input>
          </div>
        </div>
        <div class="recurring-payments-row central-position" *ngIf="maxCount.value !== 100">
          <div *ngIf="maxCount.value" class="max-width-370 right-margin">
            <!-- Number of periods -->
            <app-input
              [formGroup]="paymentForm"
              errorMassage="Value"
              label="Number of periods"
              name="maxCount"
              mask="separator."
              thousandSeparator=","
            ></app-input>
          </div>
        </div>
        <div class="recurring-payments-row central-position"
             *ngIf="reccurrencyPaymentType.value === PaymentBillingType.Recurring && recurringSettingsModel.endDate">
          <div class="max-width-370 right-margin">
            <!-- End date -->
            <app-date-picker [formGroup]="paymentForm" errorMassage="End date" label="End date" name="endDate"
                             [dateFilter]="endDateFilter" [disabled]="arePaymentsFinished"></app-date-picker>
          </div>
        </div>
        <div class="recurring-payments-row" *ngIf="reccurrencyPaymentType.value === PaymentBillingType.Pledge">
          <div class="max-width-370">
            <!-- Amount per transaction -->
            <app-input
              [formGroup]="paymentForm"
              errorMassage="Amount per transaction"
              label="Amount per transaction"
              name="amountPerTransaction"
              mask="separator.2"
              thousandSeparator=","
              prefix="$ "
            ></app-input>
          </div>
        </div>

        <!-- Payments Details -->
        <ng-container *ngIf="isPublicEditPledgePage">
          <div class="recurring-payments-row">
            <div class="max-width-370 add-margin">
              <app-button
                [label]="changePaymentDetails ? ('DO NOT CHANGE PAYMENT DETAILS' | translate) : ('CHANGE PAYMENT DETAILS' | translate)"
                type="flat"
                [color]="changePaymentDetails ? '#a9110e' : '#0064be'"
                (clickEvent)="changePaymentDetails = !changePaymentDetails"
              ></app-button>
            </div>
          </div>

          <div class="payment-container" [class.hidden-payment-page]="!changePaymentDetails" *ngIf="paymentServiceType">
            <app-payment-page
              [event]="event"
              [campaign]="campaign"
              [socialPost]="socialMediaPost"
              [client]="client"
              [paymentServiceType]="paymentServiceType"
              [states]="states"
              [ammount]="ammount.value"
              [pledgeEditPaymentDetails]="true"
              [initiateUpdateProcess]="initiateUpdateProcess$"
              [filledInData]="filledInData"
              [recurringPayload]="getRecurringPayload()"
              [countries]="countries"
              [countriesOptions]="countriesOptions"
              [fbTemplate]="fbTemplate"
              (paymentFinished)="paymentFinished($event)"
              (paymentFailed)="onPaymentFailed()"
            ></app-payment-page>
          </div>
        </ng-container>

      </div>
    </mat-tab>
    <mat-tab label="Transaction History">
      <div class="recurring-payments-header">
        <div class="recurring-payments-title-wrapper">
          <span
            class="recurring-payments-title">{{ isPublicEditPledgePage ? ('EDIT PLEDGE' | translate) : ('RECURRING DONATION' | translate) }}</span>
          <span class="recurring-payments-back-link" (click)="onBackToAll()" *ngIf="!isPublicEditPledgePage">
        < {{ 'Back to All Donations' | translate }}</span>
        </div>
      </div>
      <div class="recurring-payments-content">
        <span class="row-title">Transaction History</span>
        <div class="recurring-payments-content-table">
          <app-schedule-table
            [data]="transactionsHistory$ | async"
            (initiateChargeEvent)="initiateCharge($event)"
            [tableDataColumns]="transactionHistoryDataColumns"
            [showFilters]="true"
            [filterSortConfig]="filterSortConfig"
            (sortChanged)="transactionHistorySortChanged($event)"
          ></app-schedule-table>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

</div>


<ng-template #infoTemplate>
  <div class="info-template-message">
    <div class="payment-top-image"></div>
    <div style="min-width: 732px" class="payment-content-success">
      <img src="/assets/icons/check1.svg" style="margin-right: 15px;"> {{ infoMessage }}<br>
      <div class="d-flex mt-5 justify-content-lg-around justify-content-sm-between">
        <app-button (clickEvent)="onBackToAll()" type="stroked" color="#0064be">BACK TO ALL DONATION</app-button>
        <app-button (clickEvent)="onBackToRecurring()" type="flat" color="#0064be">BACK TO RECURRING DONATION
        </app-button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #fundDistributionTemplate>
  <div class="amount-row" *ngFor="let fundGroup of fundAllocation.controls; index as i">
    <div class="buttons-row">
      <div class="amount-button round" matRipple (click)="fundGroup.get('amount').setValue(15)"
           [class.selected]="fundGroup.get('amount').value == 15">$ 15
      </div>
      <div class="amount-button round" matRipple (click)="fundGroup.get('amount').setValue(25)"
           [class.selected]="fundGroup.get('amount').value == 25">$ 25
      </div>
      <div class="amount-button round" matRipple (click)="fundGroup.get('amount').setValue(40)"
           [class.selected]="fundGroup.get('amount').value == 40">$ 40
      </div>
      <div class="amount-button round" matRipple (click)="fundGroup.get('amount').setValue(50)"
           [class.selected]="fundGroup.get('amount').value == 50">$ 50
      </div>
    </div>
    <div class="max-width-150 right-margin">
      <app-input
        [formGroup]="fundGroup"
        errorMassage="Amount"
        name="amount"
        mask="separator.2"
        thousandSeparator=","
        [prefix]="fundGroup.get('amount').value ? '$ ' : ''"
        placeholder="Enter own amount"
        type="tel"
      ></app-input>
    </div>
    <div class="max-width-330">
      <app-input
        [formGroup]="fundGroup"
        name="name"
        [inputReadOnly]="true"
      ></app-input>
    </div>
  </div>
  <div class="recurring-payments-row">
    <div class="max-width-370">
      <app-input
        [formGroup]="paymentForm"
        errorMassage="Amount"
        name="nextAmmount"
        mask="separator.2"
        label="Next total donation amount"
        thousandSeparator=","
        [prefix]="nextAmmount.value ? '$ ' : ''"
        [inputReadOnly]="true"
      ></app-input>
    </div>
  </div>
</ng-template>
