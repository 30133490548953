import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { DonationActivityService } from './donation-activity.service';
import { EventDonationActivityModel } from '../../../../models/event/event.donation.activity.model';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DonationActivityPopupComponent } from '../donation-activity-popup/donation-activity-popup.component';
import {
  DonationActivityPopupDataModel,
  DonationActivityPopupType
} from '../../../../models/templates/donation.activity.popup.data.model';
import { TemplateAssignmentType } from '../../../../models/templates/template.assignment.type';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-donation-activity',
  templateUrl: './donation-activity.component.html',
  styleUrls: ['./donation-activity.component.scss'],
  providers: [DonationActivityService],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonationActivityComponent implements OnInit {

  @Input() public column: MjmlElementModel;
  @Input() public entity: MjmlElementModel;
  @Input() public tms: TemplateManagementService;
  @Input() public landingPageView: boolean = false;
  @Input() public eventId: string;
  @Input() public fundraiserId: string;
  @Input() public screenshots: boolean = false;

  public donations$: Observable<EventDonationActivityModel[]>;
  public TemplateAssignmentType = TemplateAssignmentType;

  constructor(
    public donationActivityService: DonationActivityService,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService
  ) { }

  public ngOnInit(): void {
    const isMockData = this.screenshots || !this.landingPageView;
    if(!this.fundraiserId) {
      this.donations$ = this.donationActivityService.getDonations(isMockData, this.eventId, this.showDonationAmount);
    } else {
      this.donations$ = this.donationActivityService.getFundraiserDonations(this.eventId, this.fundraiserId);
    }
    
  }

  public get containerStyles(): {[key: string]: string} {
    const attributes = this.entity.attributes;
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    return {
      'padding-bottom': paddingBottom,
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      'padding-top': paddingTop
    };
  }

  public get showDonateNowButton(): boolean {
    const attributes = this.entity.attributes;
    return attributes['show-donate-now-button'] ? JSON.parse(attributes['show-donate-now-button']) : true;
  }

  public get donateButtonColor(): string {
    const attributes = this.entity.attributes;
    return attributes['donate-button-color'] ? attributes['donate-button-color'] : '#0064BE';
  }

  public get donateButtonColorText(): string {
    const attributes = this.entity.attributes;
    return attributes['donate-button-color-text'] ? attributes['donate-button-color-text'] : '#FFFFFF';
  }

  public get viewAllButtonBorderColor(): string {
    const attributes = this.entity.attributes;
    return attributes['view-all-border-color'] ? attributes['view-all-border-color'] : '#0064BE';
  }

  public get viewAllButtonTextColor(): string {
    const attributes = this.entity.attributes;
    return attributes['view-all-text-color'] ? attributes['view-all-text-color'] : '#0064BE';
  }

  public get topDonationsButtonBorderColor(): string {
    const attributes = this.entity.attributes;
    return attributes['top-donations-border-color'] ? attributes['top-donations-border-color'] : '#0064BE';
  }

  public get topDonationsButtonTextColor(): string {
    const attributes = this.entity.attributes;
    return attributes['top-donations-text-color'] ? attributes['top-donations-text-color'] : '#0064BE';
  }

  public get donateButtonText(): string {
    const attributes = this.entity.attributes;
    return attributes['donate-button-text'] && attributes['donate-button-text'] !== '' && this.isEmptyString(attributes['donate-button-text']) ?
           attributes['donate-button-text'] :
           this.translate.instant('BUILD.Donate Now');
  }

  private isEmptyString(character: string): boolean {
    return !character.match(/^\s*$/);
  }

  public get showDonationAmount(): boolean {
    const attributes = this.entity.attributes;
    return attributes['show-donation-amount'] ? JSON.parse(attributes['show-donation-amount']) : true;
  }

  public donateNow(): void {
    if (!this.landingPageView) {
      return;
    }
    this.router.navigateByUrl(`/payments/event/${this.eventId}`);
  }

  public viewAll(): void {
    this.showPopup(DonationActivityPopupType.AllDonations);
  }

  public topDonations(): void {
    this.showPopup(DonationActivityPopupType.TopDonations);
  }

  private showPopup(popupType: DonationActivityPopupType): void {
    if (!this.landingPageView) {
      return;
    }
    const data: DonationActivityPopupDataModel = {
      eventId: this.eventId,
      fundraiserId: this.fundraiserId,
      popupType: popupType,
      showDonateNowButton: this.showDonateNowButton,
      entity: this.entity,
      showDonationAmount: this.showDonationAmount,
      donateButtonText: this.donateButtonText,
      donateButtonColor: this.donateButtonColor,
      donateButtonColorText: this.donateButtonColorText,
      viewAllBorderColor: this.viewAllButtonBorderColor,
      viewAllButtonTextColor: this.viewAllButtonTextColor,
      topDonationsButtonBorderColor: this.topDonationsButtonBorderColor,
      topDonationsButtonTextColor: this.topDonationsButtonTextColor,
    };
    const config: MatDialogConfig = {
      data,
      width: '100%',
      maxWidth: '600px',
      //maxHeight: '95vh'
      height: '85vh'
    };
    this.dialog.open(DonationActivityPopupComponent, config);
  }
}
