import { BaseModelService } from '../base.model.service';
import { flatMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {Observable} from 'rxjs';
import { Paging } from 'src/app/models/paging/paging.model';
import { PagingHelper } from '../helper/paging.helper';
import EventModel from '../../models/event/event.model';
@Injectable({
  providedIn: 'root'
})
export class EventService extends BaseModelService<EventModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'Event';
  }

  public getRegisterModel(id: string): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/registerModel?id=` + id + `&` + `includeDependencies=true`;
        return this.http.get(url);
      })
    );
  }

  public getEventModelByFriendlyUrl(friendlyUrl: string, includeDependencies: boolean = true): Observable<any> {
    const params: HttpParams = new HttpParams().set('includeDependencies', includeDependencies.toString());
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/getByFriendlyUrl/${friendlyUrl}`;
        return this.http.get(url, { params });
      })
    );
  }

  public getShortModelList(filter?: Paging): Observable<EventModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<EventModel[]>(
          `${config.apiURL}/${this.controllerName}/GetShortEventList`,
          { params }
        );
      })
    );
  }

  public sendSecondaryEventInvitations(id: string): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/sendInvitation?secondaryEventId=` + id;
        return this.http.get(url);
      })
    );
  }

  public copyEventModel(id: string): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/copyEvent?eventId=` + id;
        return this.http.get(url);
      })
    );
  }

  public hasVolunteers(volunteerId: string): Observable<boolean> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<boolean>(`${config.apiURL}/${this.controllerName}/Volunteers/${volunteerId}`);
      })
    );
  }

  public hasAllVolunteers(eventId: string): Observable<boolean> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<boolean>(`${config.apiURL}/${this.controllerName}/VolunteersByEventId/${eventId}`);
      })
    );
  }

  public getAllDonationEvents(filter?: Paging): Observable<EventModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<EventModel[]>(
          `${config.apiURL}/${this.controllerName}/GetAllDonationEvents`,
          { params }
        );
      })
    );
  }

  public getAvailableURL(eventName: string): Observable<EventModel>{
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/AvailableURL?eventName=${eventName}`;
        return this.http.get<EventModel>(url);
      })
    );
  }
}
