<form [formGroup]="settingsForm" class="signup-confirmation-screen">
  <div class="fb-page-form-row" *ngIf="loadingData === false">
    <div class="max-width-810">
      <app-text-editor
        [formGroup]="settingsForm"
        name="confirmationScreen"
        key="confirmationScreen"
        [placeholders]="placeholders"
        errorMassage="Confirmation Screen Text"
      ></app-text-editor>
    </div>
  </div>
</form>
