import { Injectable } from '@angular/core';
import { StripeACHInstantVerificationHandlerResult } from '../models/stripe-ach-instant-verification-handler-result.model';
import { STRIPE_ACH_INSTANT_VERIFICATION_FEE } from 'src/app/constants';
import { TierFormGroupModel } from '../models/tier-form-group.model';
import { FundAllocationModel } from 'src/app/models/payments/fund.allocation.model';

@Injectable()
export class StripeACHInstantVerificationHandlerService {
  private _originalDonationAmount: number;
  private _originalEventRegistrationCost: number;
  private _currentStripeACHInstantVerificationFee: number = 0;
  
  constructor() { }

  public set originalDonationAmount(donationAmount: number) {
    this._originalDonationAmount = +donationAmount
  }

  public set originalEventRegistrationCost(registrationCost: number) {
    this._originalEventRegistrationCost = +registrationCost;
  }

  public bumpCurrentStripeACHInstantVerificationFee(): void {
    this._currentStripeACHInstantVerificationFee += STRIPE_ACH_INSTANT_VERIFICATION_FEE;
  }

  public handleInstantVerificationFee(includeDonationFee: boolean, includeEventFee: boolean, isReccuringDonation: boolean, tiers: TierFormGroupModel[], tiersCustomDonation: TierFormGroupModel): StripeACHInstantVerificationHandlerResult {
    let newDonationAmount: number = this._originalDonationAmount;
    let newEventRegistrationCostAmount: number = this._originalEventRegistrationCost;

    if(!!this._currentStripeACHInstantVerificationFee) {
      if(!!this._originalDonationAmount && !!this._originalEventRegistrationCost) {
        if((includeEventFee && includeDonationFee) || (includeEventFee && !includeDonationFee)) {
          newEventRegistrationCostAmount = +newEventRegistrationCostAmount + this._currentStripeACHInstantVerificationFee;
          newDonationAmount = +this._originalDonationAmount;
        } else if(!includeEventFee && includeDonationFee && !isReccuringDonation) {
          newEventRegistrationCostAmount = +this._originalEventRegistrationCost;
          newDonationAmount = this.estimateDonationFee(tiers, tiersCustomDonation);
        } else {
          newEventRegistrationCostAmount = +this._originalEventRegistrationCost;
          newDonationAmount = +this._originalDonationAmount;
        }
      } else if(!this._originalDonationAmount) {
        if(includeEventFee) {
          newEventRegistrationCostAmount = +newEventRegistrationCostAmount + this._currentStripeACHInstantVerificationFee;
        } else {
          newEventRegistrationCostAmount = +this._originalEventRegistrationCost;
        }
      } else {
        if(includeDonationFee && !isReccuringDonation) {
          newDonationAmount = this.estimateDonationFee(tiers, tiersCustomDonation);
        } else {
          newDonationAmount = +this._originalDonationAmount;
        }
      }
    }

    newDonationAmount = newDonationAmount || 0;
    newEventRegistrationCostAmount = newEventRegistrationCostAmount || 0;
    return {newDonationAmount, newEventRegistrationCostAmount}
  }

  public updateFundAllocation(fundAllocation: FundAllocationModel[], donationAmount: number, eventRegistrationCost: number, includeDonationFee: boolean, includeEventRegistationFee: boolean, isRecurring: boolean): void {
    if(!this._currentStripeACHInstantVerificationFee || !fundAllocation?.length) return;

    const shouldUpdatefundAllocation: boolean = (!!donationAmount && !!eventRegistrationCost && !includeEventRegistationFee && includeDonationFee) ||
      (!!donationAmount && !eventRegistrationCost && includeDonationFee && !isRecurring);

    if(shouldUpdatefundAllocation) {
      fundAllocation[0].amount = +fundAllocation[0].amount + this._currentStripeACHInstantVerificationFee;
    }
  } 

  protected estimateDonationFee(tiers: TierFormGroupModel[], tiersCustomDonation: TierFormGroupModel): number {
    if(!tiers) tiers = [];
    const combinedTiers: TierFormGroupModel[] = [...tiers];
    tiersCustomDonation && combinedTiers.push(tiersCustomDonation)
    const isNonRecurringTier: boolean = !!combinedTiers?.filter((tier: TierFormGroupModel) => !!tier?.ammount && !tier.recurring)?.length;

    if(!!combinedTiers?.length) {
      if(isNonRecurringTier) return +this._originalDonationAmount + this._currentStripeACHInstantVerificationFee;
      return +this._originalDonationAmount;
    }

    return +this._originalDonationAmount + this._currentStripeACHInstantVerificationFee
  }
}


