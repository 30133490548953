import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-font-size',
  templateUrl: './font-size.component.html',
  styleUrls: ['./font-size.component.scss']
})
export class FontSizeComponent {
  @Input() public fontSizes: Array<number> = new Array<number>(18, 24, 28, 34);
  @Input() public isDisabled: boolean = false;
  @Input() public label: string;
  @Input() public value: number = 18;

  @Output() public changeFont: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  public setValue(value: number) {
    if (!this.isDisabled) {
      this.changeFont.emit(value);
    }
  }
}