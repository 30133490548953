import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {CreatedTimeAgoType, EventDonationActivityModel} from '../../../../models/event/event.donation.activity.model';
import {EventDonationsService} from '../../../../services/events/event-donations.service';
import {Paging} from '../../../../models/paging/paging.model';
import {SortOrder} from '../../../../models/enum/sort.order';
import {FilterType} from 'src/app/models/enum/filter.type';
import {DonationStatusType} from "../../../../models/enum/donation.status.type";

@Injectable()
export class DonationActivityService {

  private mockData: EventDonationActivityModel[] = [
    {donationAmmount: 293.01, donorFullName: 'Esther Howard', createdTimeAgo: 5.55, createdTimeAgoType: CreatedTimeAgoType.Minute},
    {donationAmmount: 106.58, donorFullName: 'Dianne Russell', createdTimeAgo: 8.01, createdTimeAgoType: CreatedTimeAgoType.Minute},
    {donationAmmount: 928.41, donorFullName: 'Theresa Webb', createdTimeAgo: 10, createdTimeAgoType: CreatedTimeAgoType.Minute},
    {donationAmmount: 739.65, donorFullName: 'Jacob Jones', createdTimeAgo: 12.22, createdTimeAgoType: CreatedTimeAgoType.Minute},
    {donationAmmount: 782.01, donorFullName: 'Guy Hawkins', createdTimeAgo: 15, createdTimeAgoType: CreatedTimeAgoType.Minute},
  ];

  constructor(
    private eventDonationsService: EventDonationsService
  ) {}

  public getDonations(isMockData: boolean, eventId: string,  showDonationAmount: boolean, first: number = 0, rows: number = 5): Observable<EventDonationActivityModel[]> {
    if (isMockData) {
      return of(this.mockData);
    } else {
      const paging: Paging = {
        includeDeleted: false,
        includeDependencies: false,
        rows,
        first,
        sortField: 'createdOn',
        sortOrder: SortOrder.Descending,
        isShowDonationAmount: showDonationAmount
      };
      return this.eventDonationsService.getDonationActivities(paging, eventId);
    }
  }

  public getFundraiserDonations(eventId: string, fundraiserId: string, first: number = 0, rows: number = 5): Observable<EventDonationActivityModel[]> {
    // return of(this.mockData);

    const paging: Paging = {
      includeDeleted: false,
        includeDependencies: false,
        rows,
        first,
        sortField: 'createdOn',
        sortOrder: SortOrder.Descending,
        filters: [
          {
            field: "eventId",
            value: eventId,
            type: FilterType.Equal
          },
          {
            field: "fundraiserId",
            value: fundraiserId,
            type: FilterType.Equal
          },
          {
            field: "donationStatus",
            value: `array[${DonationStatusType.Authorized}, ${DonationStatusType.Completed}]`,
            type: FilterType.Equal
          }
        ],
    }
    return this.eventDonationsService.getEventDonationActivitiesLimited(paging);
  }

  public getTotal(eventId: string): Observable<number> {
    const paging: Paging = {
      includeDeleted: false,
      includeDependencies: false,
    };
    return this.eventDonationsService.getDonationActivitiesTotal(paging, eventId);
  }

  public getFundraiserTotal(eventId: string, fundraiserId: string): Observable<number> {
    const paging: Paging = {
      includeDeleted: false,
        includeDependencies: false,
        filters: [
          {
            field: "eventId",
            value: eventId,
            type: FilterType.Equal
          },
          {
            field: "fundraiserId",
            value: fundraiserId,
            type: FilterType.Equal
          },
          {
            field: "donationStatus",
            value: `array[${DonationStatusType.Authorized}, ${DonationStatusType.Completed}]`,
            type: FilterType.Equal
          }
        ],
    }

    return this.eventDonationsService.getTotal(paging)
  }

  public getTopDonations(eventId: string, showDonationAmount: boolean): Observable<EventDonationActivityModel[]> {
    const paging: Paging = {
      includeDeleted: false,
      includeDependencies: false,
      rows: 25,
      first: 0,
      sortField: 'donationAmmount',
      sortOrder: SortOrder.Descending,
      isShowDonationAmount: showDonationAmount
    };
    return this.eventDonationsService.getDonationActivities(paging, eventId);
  }

  getFundraiserTopDonations(eventId: string, fundraiserId: string, showDonationAmount: boolean): Observable<EventDonationActivityModel[]> {
    const paging: Paging = {
      includeDeleted: false,
      includeDependencies: false,
      rows: 25,
      first: 0,
      sortField: 'donationAmmount',
      sortOrder: SortOrder.Descending,
      isShowDonationAmount: showDonationAmount,
      filters: [
        {
          field: "eventId",
          value: eventId,
          type: FilterType.Equal
        },
        {
          field: "fundraiserId",
          value: fundraiserId,
          type: FilterType.Equal
        },
        {
          field: "donationStatus",
          value: `array[${DonationStatusType.Authorized}, ${DonationStatusType.Completed}]`,
          type: FilterType.Equal
        }
      ]
    };

    return this.eventDonationsService.getEventDonationActivitiesLimited(paging);
  }

}
