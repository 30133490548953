import { Component, Input, OnInit } from '@angular/core';
import { LayerModel } from '../../../../../models/image-builder/layer.model';
import { ImageBuilderStateService } from '../../../image-builder.state.service';

@Component({
  selector: 'app-image-renderer',
  templateUrl: './image-renderer.component.html',
  styleUrls: ['./image-renderer.component.scss']
})
export class ImageRendererComponent implements OnInit {
  @Input() public layer: LayerModel;
  @Input() public ibss: ImageBuilderStateService;

  constructor() { }

  ngOnInit() {
  }

  public get src(): string {
    if (!this.layer) {
      return 'assets/images/empty-photo.jpg';
    }
    return this.layer.attributes['url'] || 'assets/images/empty-photo.jpg';
  }

  public get imageStyles(): any {
    if (!this.layer) {
      return null;
    }
    const {
      borderRadius = '0',
      borderWidth = '0',
      borderColor = '#414141',
    } = this.layer.attributes;
    return {
      'border-radius': `${borderRadius}%`,
      'border': `${borderWidth}px solid ${borderColor}`
    };
  }

}
