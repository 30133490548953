import { AddNewTagComponent } from '../routes/clients/customization/components/add-new-tag/add-new-tag.component';
import { AddressComponent } from '../components/form-builder/components/elements/address/address.component';
import { AddressOptionsComponent } from '../components/form-builder/components/element-options/address-options/address-options.component';
import { AdminConfirmationComponent } from '../routes/admin/admin-confirmation/admin-confirmation.component';
import { AdminTemplateLayoutComponent } from '../routes/admin/admin-templates/components/admin-template-layout/admin-template-layout.component';
import { AdminTemplatesComponent } from '../routes/admin/admin-templates/admin-templates.component';
import { AdminTemplatesTableComponent } from '../routes/admin/admin-templates/components/admin-templates-table/admin-templates-table.component';
import { AnonymousLayoutComponent } from '../anonymous-layout/anonymous-layout.component';
import { AppColorPickerComponent } from '../components/app-color-picker/app-color-picker.component';
import { AppImageCropperComponent } from '../components/app-image-cropper/app-image-cropper.component';
import { AuditReportsComponent } from './components/audit-reports/audit-reports.component';
import { AuditReportsFeaturesComponent } from './components/audit-reports-features/audit-reports-features.component';
import { BackgroundColorDirective } from '../directives/background-color.directive';
import { BarChartComponent } from '../components/charts/bar-chart/bar-chart.component';
import { BarHeightDirective } from '../directives/bar-height.directive';
import { BaseFormComponent } from '../components/form-builder/components/elements/base.form.component';
import { BaseFormElementComponent } from '../components/form-elements/base.form.element.component';
import { BasePagingComponent } from '../components/paginator/base.paging.component';
import { BaseTableComponent } from '../components/base-table/base-table.component';
import { BeforeUnloadComponent } from '../components/before-unload/before-unload.component';
import { UnauthorizedClientComponent } from '../components/unauthorized-client/unauthorized-client.component';
import { BorderColorDirective } from '../directives/border-color.directive';
import { ButtonComponent } from '../components/button/button.component';
import { ButtonToggleComponent } from '../components/button-toggle/button-toggle.component';
import { ButtonToolComponent } from '../components/email-builder/components/button-tool/button-tool.component';
import { ButtonToolSettingsComponent } from '../components/email-builder/components/button-tool-settings/button-tool-settings.component';
import { CampaignListTableComponent } from '../components/campaign-list-table/campaign-list-table.component';
import { CancellingReasonComponent } from '../components/cancelling-reason/cancelling-reason.component';
import { ChartsModule } from 'ng2-charts';
import { ChartToolComponent } from '../components/email-builder/components/chart-tool/chart-tool.component';
import { ChartToolSettingsComponent } from '../components/email-builder/components/chart-tool-settings/chart-tool-settings.component';
import { CheckboxComponent } from '../components/form-elements/checkbox/checkbox.component';
import { ChooseOptionComponent } from '../components/choose-option/choose-option.component';
import { ClientSelectComponent } from '../components/form-elements/client-select/client-select.component';
import { ClientsTemplatesComponent } from '../routes/admin/admin-templates/components/clients-templates/clients-templates.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ColorPickerModule } from 'ngx-color-picker';
import { ColumnSettingsComponent } from '../components/email-builder/components/column-settings/column-settings.component';
import { CommentComponent } from '../components/form-builder/components/elements/comment/comment.component';
import { CommentDialogComponent } from '../components/comment-dialog/comment-dialog.component';
import { CommentOptionsComponent } from '../components/form-builder/components/element-options/comment-options/comment-options.component';
import { CommonLayerStylingComponent } from '../components/image-builder/components/styling/common-layer-styling/common-layer-styling.component';
import { CommonModule, DatePipe } from '@angular/common';
import { CommonOptionsComponent } from '../components/form-builder/components/common-options/common-options.component';
import { CommunicationMethodsPickerComponent } from '../components/form-elements/communication-methods-picker/communication-methods-picker.component';
import { ContributionsTableComponent } from '../components/contributions-table/contributions-table.component';
import { ConvertAmountPipe } from './pipes/convert-amount.pipe';
import { CopyDialogComponent } from '../components/copy-dialog/copy-dialog.component';
import { CreateTabComponent } from '../components/form-builder/components/create-tab/create-tab.component';
import { CrossIconComponent } from '../components/button/sub-components/cross-icon/cross-icon.component';
import { DatePickerComponent } from '../components/form-elements/date-picker/date-picker.component';
import { DebounceClickDirective } from '../directives/debounce-click.directive';
import { DividerToolComponent } from '../components/email-builder/components/divider-tool/divider-tool.component';
import { DividerToolSettingsComponent } from '../components/email-builder/components/divider-tool-settings/divider-tool-settings.component';
import { DndDirective } from '../directives/dnd.directive';
import { DonationActivityComponent } from '../components/email-builder/components/donation-activity/donation-activity.component';
import { DonationActivityElementComponent } from '../components/email-builder/components/donation-activity-element/donation-activity-element.component';
import { DonationActivityPopupComponent } from '../components/email-builder/components/donation-activity-popup/donation-activity-popup.component';
import { DonationActivitySettingsComponent } from '../components/email-builder/components/donation-activity-settings/donation-activity-settings.component';
import { DonationInfoOptionsComponent } from '../components/form-builder/components/element-options/donation-info-options/donation-info-options.component';
import { DonationInfoPageComponent } from '../components/form-builder/components/donation-info-page/donation-info-page.component';
import { DonorRegisterComponent } from '../routes/donors-portal/components/donor-register/donor-register.component';
import { DoughnutChartComponent } from '../components/charts/doughnut-chart/doughnut-chart.component';
import { ElementOptionsComponent } from '../components/form-builder/components/element-options/element-options.component';
import { ElementRendererComponent } from '../components/form-builder/components/element-renderer/element-renderer.component';
import { ElementsComponent } from '../components/image-builder/components/elements/elements.component';
import { EmailBuilderComponent } from '../components/email-builder/email-builder.component';
import { EmailColorPickerComponent } from '../components/email-builder/components/email-color-picker/email-color-picker.component';
import { EmailComponent } from '../components/form-builder/components/elements/email/email.component';
import { EmailListTextareaComponent } from '../components/form-elements/email-list-textarea/email-list-textarea.component';
import { EmailOptionsComponent } from '../components/form-builder/components/element-options/email-options/email-options.component';
import { EmailSettingsComponent } from '../components/email-builder/components/settings/settings.component';
import { EmailVideoToolComponent } from '../components/email-builder/components/email-video-tool/email-video-tool.component';
import { EmailVideoToolSettingsComponent } from '../components/email-builder/components/email-video-tool-settings/email-video-tool-settings.component';
import { EventListTableComponent } from '../components/event-list-table/event-list-table.component';
import { EventRegistrationFormComponent } from './components/event-registration-form/event-registration-form.component';
import { EventsTableComponent } from '../components/events-table/events-table.component';
import { FbCheckboxComponent } from '../components/form-builder/components/elements/fb-checkbox/fb-checkbox.component';
import { FbDatePickerComponent } from '../components/form-builder/components/elements/fb-date-picker/fb-date-picker.component';
import { FbEmailSettingsComponent } from '../components/form-builder/components/email-settings/fb-email-settings.component';
import { FbInputComponent } from '../components/form-builder/components/elements/fb-input/fb-input.component';
import { FbQuillEditorComponent } from '../components/form-builder/components/elements/fb-quill-editor/fb-quill-editor.component';
import { FbRadioComponent } from '../components/form-builder/components/elements/fb-radio/fb-radio.component';
import { FbSelectComponent } from '../components/form-builder/components/elements/fb-select/fb-select.component';
import { FileLoaderComponent } from '../components/email-builder/components/file-loader/file-loader.component';
import { FillStateDirective } from '../directives/fill-state.directive';
import { FilteredComboboxComponent } from '../components/filtered-combobox/filtered-combobox.component';
import { FilteredListboxComponent } from '../components/filtered-listbox/filtered-listbox.component';
import { FontSizeComponent } from '../components/font-size/font-size.component';
import { FormBuilderComponent } from '../components/form-builder/form-builder.component';
import { FormSettingsComponent } from '../components/form-builder/components/form-settings/form-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormsTemplateSelectComponent } from '../components/forms-template-select/forms-template-select.component';
import { FullNameComponent } from '../components/form-builder/components/elements/full-name/full-name.component';
import { FullNameOptionsComponent } from '../components/form-builder/components/element-options/full-name-options/full-name-options.component';
import { GeneralStylingComponent } from '../components/image-builder/components/styling/general-styling/general-styling.component';
import { GridsterModule } from 'angular-gridster2';
import { HeaderComponent } from '../components/form-builder/components/elements/header/header.component';
import { HeaderFooterBannerOptionsComponent } from '../components/form-builder/components/element-options/header-footer-banner-options/header-footer-banner-options.component';
import { HeaderOptionsComponent } from '../components/form-builder/components/element-options/header-options/header-options.component';
import { HistoryDonationsComponent } from '../components/history-donations/history-donations.component';
import { HistoryDonationsTableComponent } from '../components/history-donations-table/history-donations-table.component';
import { HistoryEventAttendedTableComponent } from '../components/history-event-attended-table/history-event-attended-table.component';
import { HistoryEventsAttendedComponent } from '../components/history-events-attended/history-events-attended.component';
import { HttpClientModule } from '@angular/common/http';
import { ImageBuilderComponent } from '../components/image-builder/image-builder.component';
import { ImageBuilderUtilsComponent } from '../components/image-builder/image-builder.utils.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageLoaderComponent } from '../components/email-builder/components/image-loader/image-loader.component';
import { ImageRendererComponent } from '../components/image-builder/components/layer/image/image-renderer.component';
import { ImageStylingComponent } from '../components/image-builder/components/styling/image-styling/image-styling.component';
import { ImageToolComponent } from '../components/email-builder/components/image-tool/image-tool.component';
import { ImageToolSettingsComponent } from '../components/email-builder/components/image-tool-settings/image-tool-settings.component';
import { InfiniteScrollComponent } from '../components/infinite-scroll/infinite-scroll.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InputComponent } from '../components/form-elements/input/input.component';
import { ItemCounterComponent } from '../components/form-elements/item-counter/item-counter.component';
import { LayerComponent } from '../components/image-builder/components/layer/layer.component';
import { LazySelectComponent } from '../components/lazy-select/lazy-select.component';
import { LazySingleSelectComponent } from '../components/lazy-single-select/lazy-single-select.component';
import { LineChartComponent } from '../components/charts/line-chart/line-chart.component';
import { ListComponent } from '../components/button/sub-components/list/list.component';
import { MainSpinnerComponent } from '../components/main-spinner/main-spinner.component';
import { MaterialUiModule } from '../material-ui/material-ui.module';
import { MinusIconComponent } from '../components/button/sub-components/minus-icon/minus-icon.component';
import { MjmlRendererComponent } from '../components/mjml-renderer/mjml-renderer.component';
import { ModulesComponent } from '../components/email-builder/components/modules/modules.component';
import { ModuleToolComponent } from '../components/email-builder/components/module-tool/module-tool.component';
import { ModuleToolSettingsComponent } from '../components/email-builder/components/module-tool-settings/module-tool-settings.component';
import { MonthPickerComponent } from '../components/form-elements/month-picker/month-picker.component';
import { MultiselectComponent } from '../components/multiselect/multiselect.component';
import { MultiselectDialogComponent } from '../components/multiselect-dialog/multiselect-dialog.component';
import { MyThermometerComponent } from '../components/thermometer/my-thermometer/my-thermometer.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgModule } from '@angular/core';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxChartsLibBuilderComponent } from '../components/email-builder/components/ngx-charts-lib-builder/ngx-charts-lib-builder.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxMoveableModule } from 'ngx-moveable';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { NgxStripeModule } from 'ngx-stripe';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NouisliderModule } from 'ng2-nouislider';
import { PacComponent } from '../components/form-builder/components/elements/pac/pac.component';
import { PacOptionsComponent } from '../components/form-builder/components/element-options/pac-options/pac-options.component';
import { PaginatorComponent } from '../components/paginator/paginator.component';
import { ParticipantCheckComponent } from './components/participant-check/participant-check.component';
import { PasswordInputComponent } from '../components/form-elements/password-input/password-input.component';
import { PaymentComponent } from '../components/payment/payment/payment.component';
import { PaymentDetailsComponent } from '../components/form-builder/components/elements/payment-details/payment-details.component';
import { PaymentSummaryComponent } from '../components/form-builder/components/elements/payment-summary/payment-summary.component';
import { PaymentDetailsOptionsComponent } from '../components/form-builder/components/element-options/payment-details-options/payment-details-options.component';
import { PaymentFormComponent } from '../components/payment/payment-form/payment-form.component';
import { PaymentPageComponent } from '../components/payment/payment-page/payment-page.component';
import { PhoneComponent } from '../components/form-builder/components/elements/phone/phone.component';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { PhoneOptionsComponent } from '../components/form-builder/components/element-options/phone-options/phone-options.component';
import { PieChartComponent } from '../components/charts/pie-chart/pie-chart.component';
import { PlusIconComponent } from '../components/button/sub-components/plus-icon/plus-icon.component';
import { PreviewComponent } from '../components/email-builder/components/preview/preview.component';
import { ProgressComponent } from '../components/progress/progress.component';
import { PublishTabComponent } from '../components/form-builder/components/publish-tab/publish-tab.component';
import { PublishTabSmComponent } from '../components/form-builder/components/publish-tab-sm/publish-tab-sm.component';
import { QuillModule } from 'ngx-quill';
import { RadioComponent } from '../components/form-elements/radio/radio.component';
import { RangeSelectorComponent } from '../components/range-selector/range-selector.component';
import { RecurringPaymentsComponent } from '../components/payment/recurring-payments/recurring-payments.component';
import { RegistrationActivityComponent } from './components/registration-activity/registration-activity.component';
import { RegistrationContactInfoComponent } from '../components/registration-contact-info/registration-contact-info.component';
import { ReportsTableComponent } from '../components/reports-table/reports-table.component';
import { RouterModule } from '@angular/router';
import { RowSettingsComponent } from '../components/email-builder/components/row-settings/row-settings.component';
import { SafeCSSPipe } from './pipes/safe-style.pipe';
import { SafeHTMLPipe } from './pipes/safe-html-pipe';
import { SafeURLPipe } from './pipes/safe-url.pipe';
import { SaveComponent } from '../components/image-builder/components/save/save.component';
import { ScheduleTableComponent } from '../components/payment/schedule-table/schedule-table.component';
import { SearchSelectComponent } from '../components/form-elements/search-select/search-select.component';
import { SecondStepComponent } from '../routes/auth/second-step/second-step.component';
import { SelectComponent } from '../components/form-elements/select/select.component';
import { SettingsTabComponent } from '../components/form-builder/components/settings-tab/settings-tab.component';
import { ShapeRendererComponent } from '../components/image-builder/components/layer/shape/shape-renderer.component';
import { ShapeStylingComponent } from '../components/image-builder/components/styling/shape-styling/shape-styling.component';
import { SignatureToolComponent } from '../components/email-builder/components/signature-tool/signature-tool.component';
import { SignatureToolSettingsComponent } from '../components/email-builder/components/signature-tool-settings/signature-tool-settings.component';
import { SliderComponent } from '../components/form-elements/slider/slider.component';
import { SmToolComponent } from '../components/email-builder/components/sm-tool/sm-tool.component';
import { SmToolSettingsComponent } from '../components/email-builder/components/sm-tool-settings/sm-tool-settings.component';
import { SocialMediaSelectComponent } from '../components/form-elements/social-media-select/social-media-select.component';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { StarComponent } from '../components/button/sub-components/star/star.component';
import { StatusColorDirective } from '../directives/status-color.directive';
import { StringSpinnerComponent } from '../components/string-spinner/string-spinner.component';
import { StylingComponent } from '../components/image-builder/components/styling/styling.component';
import { TableComponent } from '../components/table/table.component';
import { TableToolComponent } from '../components/email-builder/components/table-tool/table-tool.component';
import { TableToolSettingsComponent } from '../components/email-builder/components/table-tool-settings/table-tool-settings.component';
import { TagManagerComponent } from '../routes/clients/customization/components/tag-manager/tag-manager.component';
import { TagsComponent } from '../components/email-builder/components/tags/tags.component';
import { TagSelectComponent } from '../routes/clients/customization/components/tag-select/tag-select.component';
import { TextareaComponent } from '../components/form-elements/textarea/textarea.component';
import { TextEditorComponent } from '../components/form-elements/text-editor/text-editor.component';
import { TextRendererComponent } from '../components/image-builder/components/layer/text/text-renderer.component';
import { TextStylingComponent } from '../components/image-builder/components/styling/text-styling/text-styling.component';
import { TextToolComponent } from '../components/email-builder/components/text-tool/text-tool.component';
import { TextToolSettingsComponent } from '../components/email-builder/components/text-tool-settings/text-tool-settings.component';
import { ThankYouPageSettingsComponent } from '../components/form-builder/components/thank-you-page-settings/thank-you-page-settings.component';
import { ThermometerBuilderComponent } from '../components/email-builder/components/thermometer-builder/thermometer-builder.component';
import { ThermometerComponent } from '../components/thermometer/thermometer.component';
import { ThermometerToolComponent } from '../components/email-builder/components/thermometer-tool/thermometer-tool.component';
import { ThermometerToolSettingsComponent } from '../components/email-builder/components/thermometer-tool-settings/thermometer-tool-settings.component';
import { TicketTypesBlockComponent } from '../components/ticket-types-block/ticket-types-block.component';
import { TimeInputComponent } from '../components/form-elements/time-input/time-input.component';
import { TimePickerComponent } from '../components/form-elements/time-picker/time-picker.component';
import { TimeSelectComponent } from '../components/form-elements/time-select/time-select.component';
import { ToggleSwitchComponent } from '../components/toggle-switch/toggle-switch.component';
import { ToolsRendererComponent } from '../components/email-builder/components/tools-renderer/tools-renderer.component';
import { TooltipDirective } from '../directives/tooltip.directive';
import { TopImageOptionsComponent } from '../components/form-builder/components/element-options/top-image-options/top-image-options.component';
import { UpdateValueDialogComponent } from '../components/update-value-dialog/update-value-dialog.component';
import { UtilsComponent } from '../components/utils.component';
import { VideoLoaderComponent } from '../components/email-builder/components/video-loader/video-loader.component';
import { VideoScreenshotsComponent } from '../components/email-builder/components/video-screenshots/video-screenshots.component';
import { VideoToolComponent } from '../components/email-builder/components/video-tool/video-tool.component';
import { VideoToolSettingsComponent } from '../components/email-builder/components/video-tool-settings/video-tool-settings.component';
import { VolunteersSelectComponent } from '../components/volunteers-select/volunteers-select.component';
import { WidgetCommonComponent } from '../components/charts/widget-common.component';
import { WidgetItemDirective } from '../directives/widget-item.directive';
import { WidgetTableComponent } from '../components/widget-table/widget-table.component';
import { FbTextareaComponent } from '../components/form-builder/components/elements/fb-textarea/fb-textarea.component';
import { CommentWallSettingsComponent } from '../components/email-builder/components/comment-wall/comment-wall-settings/comment-wall-settings.component';
import { CommentWallComponent } from '../components/email-builder/components/comment-wall/comment-wall.component';
import { CommentWallElementComponent } from '../components/email-builder/components/comment-wall/comment-wall-element/comment-wall-element.component';
import {CommentWallAddNewComponent} from '../components/email-builder/components/comment-wall/comment-wall-add-new/comment-wall-add-new.component';
import {CommentWallAllComponent} from '../components/email-builder/components/comment-wall/comment-wall-all/comment-wall-all.component';
import { EditPopupComponent } from '../routes/clients/shared/edit-component/edit-popup.component';
import {LanguagePickerComponent} from '../components/language-picker/language-picker.component';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderFooterSettingsComponent } from '../components/email-builder/components/header-footer-settings/header-footer-settings.component';
import { FooterComponent } from '../components/email-builder/components/footer/footer.component';
import { EventHeaderComponent } from '../components/email-builder/components/header/header.component';
import { ShareOnSmComponent } from '../components/email-builder/components/share-on-sm/share-on-sm.component';
import { ShareOnSmSettingsComponent } from '../components/email-builder/components/share-on-sm-settings/share-on-sm-settings.component';
import { IconsComponent } from '../components/icons/icons.component';
import {DatePickerHeaderComponent} from '../components/form-elements/date-picker/date-picker-header/date-picker-header/date-picker-header.component';
import { CropperComponent } from '../components/email-builder/components/image-loader/cropper/cropper.component';
import { SearchInputComponent } from '../components/form-elements/search-input/search-input.component';
import { YearDatePickerComponent } from '../components/form-elements/year-date-picker/year-date-picker.component';
import { SimpleCheckboxComponent } from '../components/form-elements/simple-checkbox/simple-checkbox.component';
import { CreateNewTagPopupComponent } from '../routes/clients/customization/tags/create-new-tag-popup/create-new-tag-popup.component';
import { AddDonorTagsPopupComponent } from '../routes/clients/customization/tags/add-donor-tags-popup/add-donor-tags-popup.component';
import {AddOrRemoveRectangleComponent} from '../components/add-or-remove-rectangle/add-or-remove-rectangle.component';
import {IconButtonComponent} from '../components/icon-button/icon-button.component';
import { AddOnsBlock } from '../components/addons-block/addons-block.component';
import { QuantitySelectorComponent } from '../components/quantity-selector/quantity-selector.component';
import { RegistrationCancelComponent } from '../components/registration-cancel/registration-cancel.component';
import { CountdownComponent } from '../routes/clients/events/components/countdown/countdown.component';
import { CountdownTextComponent } from './components/countdown-text/countdown-text.component';
import { HistoryEmailsComponent } from '../components/history-emails/history-emails.component';
import { HistoryEmailsTableComponent } from '../components/history-emails-table/history-emails-table.component';
import { ResendEmailsPopupComponent } from '../routes/clients/reports/components/donor-taxes/components/resend-emails-popup/resend-emails-popup.component';
import { DateRangeSelectorComponent } from '../components/date-range-selector/date-range-selector.component';
import { SpinnerAuxiliaComponent } from '../components/spinner-auxilia/spinner-auxilia.component';
import { DeleteDonorPopupComponent } from '../routes/clients/donors/components/delete-donor-popup/delete-donor-popup.component';
import { P2PStaticPageComponent } from '../components/p2p-static-page/p2p-static-page.component';
import { SmartTableComponent } from '../components/smart-table/smart-table.component';
import { TableStateComponent } from '../components/smart-table/table.state.component';
import { SponsorsToolComponent } from '../components/email-builder/components/sponsors-tool/sponsors-tool.component';
import { SponsorsToolSettingsComponent } from '../components/email-builder/components/sponsors-tool-settings/sponsors-tool-settings.component';
import { LabelEditorComponent } from '../components/form-elements/label-editor/label-editor.component';
import { DumbTableComponent } from '../components/dumb-table/dumb-table.component';
import {EmployerFullNameComponent} from "../components/form-builder/components/elements/employer-full-name/employer-full-name.component";
import {OccupationComponent} from "../components/form-builder/components/elements/occupation/occupation.component";
import {
  EmployerOptionsComponent
} from "../components/form-builder/components/element-options/employer-options/employer-options.component";
import {RecurringActionFormComponent} from "../components/recurring-action-form/recurring-action-form.component";
import {
  OneTimeDonationEmailSettingsTabComponent
} from "../components/form-builder/components/email-settings/one-time-donation-email-settings-tab/one-time-donation-email-settings-tab.component";
import {
  RecurringDonationEmailSettingsTabComponent
} from "../components/form-builder/components/email-settings/recurring-donation-email-settings-tab/recurring-donation-email-settings-tab.component";
import {QuillEditorSetupDirective} from "../directives/quill-editor-setup.directive";
import {ReportHeaderComponent} from "../components/reports-table/report-header/report-header.component";
import { StripeAchFormComponent } from '../components/form-builder/components/elements/stripe-ach-form/stripe-ach-form.component';
import { StripeAchInstantVerificationInfoDialogComponent } from '../components/stripe-ach-instant-verification-info-dialog/stripe-ach-instant-verification-info-dialog.component';
import { SignUpSettingsComponent } from '../components/signup-settings/signup-settings.component';
import { SignupFormSettingsComponent } from '../components/signup-settings/components/signup-form-settings/signup-form-settings.component';
import { SignupEmailSettingsComponent } from '../components/signup-settings/components/signup-email-settings/signup-email-settings.component';
import { SignupConfirmationPageSettingsComponent } from '../components/signup-settings/components/signup-confirmation-page-settings/signup-confirmation-page-settings.component';
import { SignUpPreviewComponent } from '../components/signup-settings/components/sign-up-preview/sign-up-preview.component';


@NgModule({
  declarations: [
    QuantitySelectorComponent,
    AddOnsBlock,
    AdminConfirmationComponent,
    AppColorPickerComponent,
    AppImageCropperComponent,
    BaseFormElementComponent,
    BaseTableComponent,
    ButtonComponent,
    CampaignListTableComponent,
    CheckboxComponent,
    CommentDialogComponent,
    UpdateValueDialogComponent,
    ContributionsTableComponent,
    DatePickerComponent,
    EventListTableComponent,
    EventsTableComponent,
    EventRegistrationFormComponent,
    FilteredComboboxComponent,
    FilteredListboxComponent,
    FontSizeComponent,
    HistoryDonationsTableComponent,
    InputComponent,
    PaginatorComponent,
    PhoneFormatPipe,
    RadioComponent,
    ReportsTableComponent,
    SafeCSSPipe,
    SafeHTMLPipe,
    SafeURLPipe,
    SecondStepComponent,
    SelectComponent,
    TableComponent,
    TextareaComponent,
    TextEditorComponent,
    TimePickerComponent,
    TooltipDirective,
    WidgetItemDirective,
    WidgetTableComponent,
    MultiselectComponent,
    RangeSelectorComponent,
    BasePagingComponent,
    PasswordInputComponent,
    BeforeUnloadComponent,
    UnauthorizedClientComponent,
    SocialMediaSelectComponent,
    AuditReportsComponent,
    ParticipantCheckComponent,
    AuditReportsFeaturesComponent,
    DndDirective,
    ProgressComponent,
    MonthPickerComponent,
    MultiselectDialogComponent,
    StatusColorDirective,
    BorderColorDirective,
    BackgroundColorDirective,
    NotificationsComponent,
    FillStateDirective,
    DonorRegisterComponent,
    BarHeightDirective,
    CopyDialogComponent,
    ToggleSwitchComponent,
    PaymentFormComponent,
    PaymentPageComponent,
    RecurringPaymentsComponent,
    ButtonToggleComponent,
    ScheduleTableComponent,
    CommunicationMethodsPickerComponent,
    TimeSelectComponent,
    BarChartComponent,
    WidgetCommonComponent,
    DoughnutChartComponent,
    PieChartComponent,
    LineChartComponent,
    TimeInputComponent,
    EmailBuilderComponent,
    TextToolComponent,
    ToolsRendererComponent,
    EmailSettingsComponent,
    ImageToolComponent,
    TextToolSettingsComponent,
    ImageToolSettingsComponent,
    ImageLoaderComponent,
    EmailColorPickerComponent,
    RowSettingsComponent,
    ColumnSettingsComponent,
    ButtonToolComponent,
    ButtonToolSettingsComponent,
    TableToolComponent,
    TableToolSettingsComponent,
    FileLoaderComponent,
    VideoToolComponent,
    VideoToolSettingsComponent,
    VideoLoaderComponent,
    ChartToolComponent,
    ChartToolSettingsComponent,
    SmToolComponent,
    SmToolSettingsComponent,
    PreviewComponent,
    ModulesComponent,
    AdminTemplatesComponent,
    AdminTemplatesTableComponent,
    AdminTemplateLayoutComponent,
    ModuleToolComponent,
    ModuleToolSettingsComponent,
    SignatureToolComponent,
    SignatureToolSettingsComponent,
    TagsComponent,
    ChooseOptionComponent,
    FormsTemplateSelectComponent,
    InfiniteScrollComponent,
    SpinnerComponent,
    MainSpinnerComponent,
    DebounceClickDirective,
    StringSpinnerComponent,
    EmailVideoToolComponent,
    EmailVideoToolSettingsComponent,
    NgxChartsLibBuilderComponent,
    LazySelectComponent,
    HistoryDonationsComponent,
    HistoryEventsAttendedComponent,
    HistoryEventAttendedTableComponent,
    HistoryEmailsComponent,
    HistoryEmailsTableComponent,
    ClientSelectComponent,
    DividerToolComponent,
    DividerToolSettingsComponent,
    CancellingReasonComponent,
    LazySingleSelectComponent,
    ImageBuilderComponent,
    LayerComponent,
    ElementsComponent,
    StylingComponent,
    TextStylingComponent,
    ImageStylingComponent,
    GeneralStylingComponent,
    CommonLayerStylingComponent,
    SliderComponent,
    TextRendererComponent,
    ImageRendererComponent,
    ClientsTemplatesComponent,
    ShapeRendererComponent,
    ShapeStylingComponent,
    ImageBuilderUtilsComponent,
    SaveComponent,
    LazySingleSelectComponent,
    SearchSelectComponent,
    VideoScreenshotsComponent,
    DonationInfoPageComponent,
    FormBuilderComponent,
    ConvertAmountPipe,
    FbCheckboxComponent,
    UtilsComponent,
    FbRadioComponent,
    CreateTabComponent,
    SettingsTabComponent,
    PublishTabComponent,
    ElementRendererComponent,
    FullNameComponent,
    EmployerFullNameComponent,
    OccupationComponent,
    HeaderComponent,
    EmailComponent,
    AddressComponent,
    PhoneComponent,
    CommentComponent,
    PaymentDetailsComponent,
    PaymentSummaryComponent,
    BaseFormComponent,
    FbInputComponent,
    FbSelectComponent,
    CommonOptionsComponent,
    ElementOptionsComponent,
    FbDatePickerComponent,
    FbQuillEditorComponent,
    DonationInfoOptionsComponent,
    FullNameOptionsComponent,
    EmailOptionsComponent,
    AddressOptionsComponent,
    PhoneOptionsComponent,
    EmployerOptionsComponent,
    HeaderOptionsComponent,
    TopImageOptionsComponent,
    HeaderFooterBannerOptionsComponent,
    AnonymousLayoutComponent,
    PaymentDetailsOptionsComponent,
    CommentOptionsComponent,
    PacComponent,
    PacOptionsComponent,
    FormSettingsComponent,
    FbEmailSettingsComponent,
    EmailListTextareaComponent,
    ThankYouPageSettingsComponent,
    PaymentComponent,
    ThermometerToolComponent,
    ThermometerComponent,
    MyThermometerComponent,
    ThermometerToolSettingsComponent,
    ThermometerBuilderComponent,
    RegistrationActivityComponent,
    PublishTabSmComponent,
    TagManagerComponent,
    AddNewTagComponent,
    TagSelectComponent,
    PlusIconComponent,
    ItemCounterComponent,
    MinusIconComponent,
    TicketTypesBlockComponent,
    RegistrationContactInfoComponent,
    VolunteersSelectComponent,
    DonationActivityComponent,
    DonationActivitySettingsComponent,
    ListComponent,
    StarComponent,
    DonationActivityElementComponent,
    MjmlRendererComponent,
    DonationActivityPopupComponent,
    CrossIconComponent,
    FbTextareaComponent,
    CommentWallComponent,
    CommentWallSettingsComponent,
    CommentWallElementComponent,
    CommentWallAddNewComponent,
    CommentWallAllComponent,
    EditPopupComponent,
    LanguagePickerComponent,
    HeaderFooterSettingsComponent,
    FooterComponent,
    EventHeaderComponent,
    ShareOnSmComponent,
    ShareOnSmSettingsComponent,
    IconsComponent,
    DatePickerHeaderComponent,
    CropperComponent,
    SearchInputComponent,
    YearDatePickerComponent,
    SimpleCheckboxComponent,
    CreateNewTagPopupComponent,
    AddDonorTagsPopupComponent,
    AddOrRemoveRectangleComponent,
    IconButtonComponent,
    RegistrationCancelComponent,
    CountdownComponent,
    CountdownTextComponent,
    DeleteDonorPopupComponent,
    ResendEmailsPopupComponent,
    DateRangeSelectorComponent,
    SpinnerAuxiliaComponent,
    P2PStaticPageComponent,
    SmartTableComponent,
    TableStateComponent,
    SponsorsToolComponent,
    SponsorsToolSettingsComponent,
    LabelEditorComponent,
    DumbTableComponent,
    SponsorsToolSettingsComponent,
    RecurringActionFormComponent,
    OneTimeDonationEmailSettingsTabComponent,
    RecurringDonationEmailSettingsTabComponent,
    QuillEditorSetupDirective,
    ReportHeaderComponent,
    StripeAchFormComponent,
    StripeAchInstantVerificationInfoDialogComponent,
    SignUpSettingsComponent,
    SignupFormSettingsComponent,
    SignupEmailSettingsComponent,
    SignupConfirmationPageSettingsComponent,
    SignUpPreviewComponent
  ],
  imports: [
    ColorPickerModule,
    CommonModule,
    FormsModule,
    GridsterModule,
    HttpClientModule,
    NouisliderModule,
    ImageCropperModule,
    MaterialUiModule,
    NgxMaskModule.forRoot(),
    NgxMaterialTimepickerModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
    RouterModule,
    NgCircleProgressModule.forRoot({
      radius: 60,
      space: -10,
      outerStrokeGradient: true,
      outerStrokeWidth: 10,
      outerStrokeColor: '#4882c2',
      outerStrokeGradientStopColor: '#53a9ff',
      innerStrokeColor: '#e7e8ea',
      innerStrokeWidth: 10,
      animateTitle: false,
      animationDuration: 1000,
      showUnits: true,
      showBackground: false,
      clockwise: false,
      startFromZero: false,
      unitsFontSize: '20',
      subtitle: ''
    }),
    ChartsModule,
    ClipboardModule,
    NgxShimmerLoadingModule,
    NgxChartsModule,
    InfiniteScrollModule,
    NgxMoveableModule,
    NgxCaptchaModule,
    NgxStripeModule.forRoot(),
    TranslateModule,
  ],
  exports: [
    AdminConfirmationComponent,
    AppColorPickerComponent,
    AppImageCropperComponent,
    BaseFormElementComponent,
    BaseTableComponent,
    ButtonComponent,
    CampaignListTableComponent,
    CheckboxComponent,
    ColorPickerModule,
    CommentDialogComponent,
    UpdateValueDialogComponent,
    CommonModule,
    ContributionsTableComponent,
    DatePickerComponent,
    EventListTableComponent,
    EventsTableComponent,
    EventRegistrationFormComponent,
    FilteredComboboxComponent,
    FilteredListboxComponent,
    FontSizeComponent,
    FormsModule,
    GridsterModule,
    HistoryDonationsTableComponent,
    HttpClientModule,
    ImageCropperModule,
    InputComponent,
    MaterialUiModule,
    NgxMaskModule,
    NgxMaterialTimepickerModule,
    PaginatorComponent,
    PhoneFormatPipe,
    QuillModule,
    RadioComponent,
    ReactiveFormsModule,
    ReportsTableComponent,
    RouterModule,
    SafeCSSPipe,
    SafeHTMLPipe,
    SafeURLPipe,
    SelectComponent,
    TableComponent,
    TextareaComponent,
    TextEditorComponent,
    TimePickerComponent,
    TooltipDirective,
    WidgetItemDirective,
    WidgetTableComponent,
    NgCircleProgressModule,
    ChartsModule,
    MultiselectComponent,
    RangeSelectorComponent,
    BasePagingComponent,
    PasswordInputComponent,
    BeforeUnloadComponent,
    UnauthorizedClientComponent,
    AuditReportsComponent,
    SocialMediaSelectComponent,
    DndDirective,
    ProgressComponent,
    MonthPickerComponent,
    MultiselectDialogComponent,
    StatusColorDirective,
    BorderColorDirective,
    BackgroundColorDirective,
    NotificationsComponent,
    FillStateDirective,
    DonorRegisterComponent,
    BarHeightDirective,
    CopyDialogComponent,
    ToggleSwitchComponent,
    PaymentFormComponent,
    PaymentPageComponent,
    RecurringPaymentsComponent,
    ButtonToggleComponent,
    ScheduleTableComponent,
    CommunicationMethodsPickerComponent,
    TimeSelectComponent,
    BarChartComponent,
    WidgetCommonComponent,
    DoughnutChartComponent,
    PieChartComponent,
    LineChartComponent,
    TimeInputComponent,
    EmailBuilderComponent,
    TextToolComponent,
    ToolsRendererComponent,
    EmailSettingsComponent,
    ImageToolComponent,
    TextToolSettingsComponent,
    ImageToolSettingsComponent,
    ImageLoaderComponent,
    EmailColorPickerComponent,
    RowSettingsComponent,
    ColumnSettingsComponent,
    ButtonToolComponent,
    ButtonToolSettingsComponent,
    TableToolComponent,
    TableToolSettingsComponent,
    FileLoaderComponent,
    VideoToolComponent,
    VideoToolSettingsComponent,
    VideoLoaderComponent,
    ChartToolComponent,
    ChartToolSettingsComponent,
    SmToolComponent,
    SmToolSettingsComponent,
    PreviewComponent,
    ModulesComponent,
    AdminTemplatesComponent,
    AdminTemplatesTableComponent,
    AdminTemplateLayoutComponent,
    ModuleToolComponent,
    ModuleToolSettingsComponent,
    SignatureToolComponent,
    SignatureToolSettingsComponent,
    TagsComponent,
    ChooseOptionComponent,
    FormsTemplateSelectComponent,
    InfiniteScrollComponent,
    NgxShimmerLoadingModule,
    SpinnerComponent,
    MainSpinnerComponent,
    DebounceClickDirective,
    StringSpinnerComponent,
    EmailVideoToolComponent,
    EmailVideoToolSettingsComponent,
    InfiniteScrollModule,
    LazySelectComponent,
    NgxChartsModule,
    NgxChartsLibBuilderComponent,
    HistoryDonationsComponent,
    HistoryEventsAttendedComponent,
    HistoryEventAttendedTableComponent,
    HistoryEmailsComponent,
    HistoryEmailsTableComponent,
    ClientSelectComponent,
    DividerToolComponent,
    DividerToolSettingsComponent,
    CancellingReasonComponent,
    LazySingleSelectComponent,
    NgxMoveableModule,
    ImageBuilderComponent,
    LayerComponent,
    ElementsComponent,
    OccupationComponent,
    StylingComponent,
    TextStylingComponent,
    ImageStylingComponent,
    GeneralStylingComponent,
    CommonLayerStylingComponent,
    SliderComponent,
    TextRendererComponent,
    ImageRendererComponent,
    ClientsTemplatesComponent,
    ShapeRendererComponent,
    ShapeStylingComponent,
    ImageBuilderUtilsComponent,
    LazySingleSelectComponent,
    SearchSelectComponent,
    VideoScreenshotsComponent,
    DonationInfoPageComponent,
    FormBuilderComponent,
    FbCheckboxComponent,
    UtilsComponent,
    FbRadioComponent,
    CreateTabComponent,
    SettingsTabComponent,
    PublishTabComponent,
    ElementRendererComponent,
    FullNameComponent,
    EmployerFullNameComponent,
    HeaderComponent,
    EmailComponent,
    AddressComponent,
    PhoneComponent,
    CommentComponent,
    PaymentDetailsComponent,
    PaymentSummaryComponent,
    BaseFormComponent,
    FbInputComponent,
    FbSelectComponent,
    CommonOptionsComponent,
    ElementOptionsComponent,
    FbDatePickerComponent,
    FbQuillEditorComponent,
    DonationInfoOptionsComponent,
    FullNameOptionsComponent,
    EmailOptionsComponent,
    AddressOptionsComponent,
    PhoneOptionsComponent,
    EmployerOptionsComponent,
    HeaderOptionsComponent,
    TopImageOptionsComponent,
    HeaderFooterBannerOptionsComponent,
    NgxCaptchaModule,
    AnonymousLayoutComponent,
    PaymentDetailsOptionsComponent,
    CommentOptionsComponent,
    PacComponent,
    PacOptionsComponent,
    FormSettingsComponent,
    FbEmailSettingsComponent,
    EmailListTextareaComponent,
    ThankYouPageSettingsComponent,
    PaymentComponent,
    ThermometerToolComponent,
    ThermometerComponent,
    ThermometerToolSettingsComponent,
    ThermometerBuilderComponent,
    PublishTabSmComponent,
    TagManagerComponent,
    AddNewTagComponent,
    TagSelectComponent,
    PlusIconComponent,
    ItemCounterComponent,
    MinusIconComponent,
    TicketTypesBlockComponent,
    RegistrationContactInfoComponent,
    VolunteersSelectComponent,
    NgxStripeModule,
    DonationActivityComponent,
    DonationActivitySettingsComponent,
    ListComponent,
    StarComponent,
    DonationActivityElementComponent,
    MjmlRendererComponent,
    DonationActivityPopupComponent,
    CrossIconComponent,
    FbTextareaComponent,
    CommentWallComponent,
    CommentWallSettingsComponent,
    CommentWallElementComponent,
    CommentWallAddNewComponent,
    CommentWallAllComponent,
    EditPopupComponent,
    LanguagePickerComponent,
    TranslateModule,
    HeaderFooterSettingsComponent,
    EventHeaderComponent,
    FooterComponent,
    ShareOnSmComponent,
    ShareOnSmSettingsComponent,
    IconsComponent,
    DatePickerHeaderComponent,
    CropperComponent,
    SearchInputComponent,
    YearDatePickerComponent,
    SimpleCheckboxComponent,
    AddOrRemoveRectangleComponent,
    IconButtonComponent,
    RegistrationCancelComponent,
    CountdownComponent,
    DateRangeSelectorComponent,
    SpinnerAuxiliaComponent,
    P2PStaticPageComponent,
    SmartTableComponent,
    TableStateComponent,
    SponsorsToolComponent,
    SponsorsToolSettingsComponent,
    LabelEditorComponent,
    DumbTableComponent,
    OneTimeDonationEmailSettingsTabComponent,
    RecurringDonationEmailSettingsTabComponent,
    QuillEditorSetupDirective,
    ReportHeaderComponent,
    StripeAchFormComponent,
    StripeAchInstantVerificationInfoDialogComponent,
    SignUpSettingsComponent,
    SignupFormSettingsComponent,
    SignupEmailSettingsComponent,
    SignupConfirmationPageSettingsComponent,
    SignUpPreviewComponent
  ],
  providers: [DatePipe]
})
export class SharedModule {
}
