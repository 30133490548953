import { BaseModelService } from '../base.model.service';
import { Injectable, Injector } from '@angular/core';
import DonorEmployeeModel from 'src/app/models/donor/donor.employee.model';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { DonorRegistrationModel } from 'src/app/models/donor/donor.registration.model';
import { PagingHelper } from '../helper/paging.helper';
import { Paging } from 'src/app/models/paging/paging.model';
import { ChangePasswordModel } from 'src/app/models/user/change-password.model';

@Injectable({
  providedIn: 'root'
})
export class DonorEmployeeService extends BaseModelService<DonorEmployeeModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'DonorEmployee';
  }

  public completeRegistration(
    donorEmployeeRegistrationModel: DonorRegistrationModel
  ): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/completeregistration`;
        return this.http.post<DonorRegistrationModel>(
          url,
          donorEmployeeRegistrationModel
        );
      })
    );
  }

  public addModel(model: DonorEmployeeModel): Observable<DonorEmployeeModel> {
    let params = new HttpParams();
    params = params.set('registerNewDonor', 'true');
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}`;
        return this.http.post<DonorEmployeeModel>(url, model, { params });
      })
    );
  }

  public getPasswordCode(email: string): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const params = new HttpParams().set('email', email);
        return this.http.get<any>(`${config.apiURL}/${this.controllerName}/SendResetPasswordCode`, { params: params });
      })
    );
  }

  public checkDonorByEmail(email: string): Observable<boolean> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const params = new HttpParams().set('email', email);
        return this.http.get<boolean>(`${config.apiURL}/${this.controllerName}/check/${email}`);
      })
    );
  }
  public getAnonymousEmployee(filter?: Paging): Observable<DonorEmployeeModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<DonorEmployeeModel[]>(
          `${config.apiURL}/${this.controllerName}/anonymous`,
          { params }
        );
      })
    );
  }
  public resetPassword(model: ChangePasswordModel): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.post<any>(`${config.apiURL}/${this.controllerName}/ResetPassword`, model);
      })
    );
  }
}
