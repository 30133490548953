<div class="rate-wrapper">
  <div class="rate-center">
    <div class="rate-data">
      <span
        *ngIf="value !== null; else shimmerTemplate"
        [ngClass]="{active: isClicked}"
        class="rate-value"
        (click)="goToReport()"
      >
        {{getCurrency()}}{{value | number}}{{getUnit()}}
      </span>
      <span class="rate-label" [ngClass]="{active: isClicked}" (click)="goToReport()">{{label | translate}}</span>
    </div>
    <div
      class="rate-info"
      [matTooltip]="infoMessage | translate"
      matTooltipHideDelay="200"
      matTooltipShowDelay="200"
      matTooltipPosition="above"
      matTooltipClass="table-tooltip"
    >
      <i class="fas fa-info-circle"></i>
    </div>
  </div>
</div>

<ng-template #shimmerTemplate>
  <ngx-shimmer-loading width="120px" height="28px"></ngx-shimmer-loading>
</ng-template>
