<div class="create-tag-wrapper">
  <div class="header">
    <span class="title" *ngIf="data.title">{{(data.title | translate)}}</span>
    <div class="close">
      <i class="fas fa-times close-icon" (click)="onClose()" matRipple></i>
    </div>
  </div>

  <div class="main-wrapper">
    <app-add-new-tag
      [availableLength]="tagArrayLength"
      [clientId]="clientID"
      [isFormSettingsPage]="true"
      [isDonorTagManager]="true"
      (newTag)="addNewTagToList($event)"
      (cancelEmitter)="cancelCreateNewTag()"
    ></app-add-new-tag>
  </div>


</div>
