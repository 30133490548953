<div class="table-container">
  <table *ngIf="!!data" 
    mat-table 
    [dataSource]="data" 
    class="table" 
    [ngClass]="{'table--no-borders': appearanceResolver?.isApplied('NO_BORDERS')}"
  >
    <ng-container matColumnDef="multiselect">
      <th mat-header-cell *matHeaderCellDef>
        <ng-content *ngTemplateOutlet="selectAll"></ng-content>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (change)="onSelectRowChange(row)"
          [checked]="selector.isRowChecked(row)"
        ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container *ngFor="let column of config.columns" [matColumnDef]="column.name">
      <th mat-header-cell *matHeaderCellDef>
        <div class="header">
          <span>{{getHeaderTitle(column) | translate}}</span>
          <div class="header_filter-sort">
            <div *ngIf="isFilterable(column)" class="item">
              <app-multiselect
                [columnFilterOptions]="getColumnFilterOptions(column)"
                (selectedOptions)="onFilterChanged($event, column)"
              >
                <i class="fas fa-filter item_icon" [ngClass]="{'item_icon--active': isFilterActive(column)}"></i>
              </app-multiselect>
            </div>
            <div *ngIf="isSortable(column)" class="item">
              <div class="item_sort-wrapper" (click)="onSortChanged(column)">
                <app-icons
                  *ngIf="!isSort(column)"
                  [iconType]="43"
                  width="20"
                  height="20"
                ></app-icons>
                <ng-container *ngIf="isSort(column)">
                  <app-icons
                    *ngIf="isSortAscending()"
                    [iconType]="45"
                    width="20"
                    height="20"
                    color="#0064BE"
                  ></app-icons>
                  <app-icons
                    *ngIf="isSortDescending()"
                    [iconType]="44"
                    width="20"
                    height="20"
                    color="#0064BE"
                  ></app-icons>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{getCellValue(column, element)}}</td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div>
  <app-paginator
    [totalItems]="total"
    (nextPage)="onPageChanged($event)"
    (entriesPerPageChanged)="onEntriesPerPageChanged($event)"
  ></app-paginator>
</div>


<ng-template #selectAll>
  <div class="select-all">
    <mat-checkbox
      (change)="onSelectAllChanged($event)"
      [checked]="selector.isSelectAllChecked"
      [indeterminate]="selector.isSelectAllIntederminate"
    ></mat-checkbox>
  </div>
</ng-template>