import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { Subscription } from 'rxjs';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MjmlSmName } from '../../../../models/templates/mjml.sm.name';
import {
  DEFAULT_SM_NAMES, FONTS, FONT_SIZES, FONT_STYLE, FONT_WEIGHT,
  SM_PLATFORMS,
  SOCIAL_MEDIA_MJML_ICON_DEFAULT_COLOR,
  SOCIAL_MEDIA_MJML_ICONS,
  TEXT_ALIGN, TEXT_DECORATION, TOOLTIP_TEXT_FOR_FONTS
} from '../../../../constants';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { TemplateType } from '../../../../models/templates/template.type';
import { TemplateAssignmentType } from '../../../../models/templates/template.assignment.type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sm-tool-settings',
  templateUrl: './sm-tool-settings.component.html',
  styleUrls: ['../tool-settings.scss']
})
export class SmToolSettingsComponent implements OnInit, OnDestroy {
  @Input() public tms: TemplateManagementService;
  //public MjmlTag = MjmlTag;
  //public MjmlSmName = MjmlSmName;
  private subscription: Subscription = new Subscription();

  public platformsOptions: FormElementDataModel[] = SM_PLATFORMS;
  public modeOptions: FormElementDataModel[] = [
    {label: 'Horizontal', value: 'horizontal'},
    {label: 'Vertical', value: 'vertical'},
  ];
  public alignOptions: FormElementDataModel[] = TEXT_ALIGN;
  public fontFamilyOptions: FormElementDataModel[] = FONTS;
  public fontSizeOptions: FormElementDataModel[] = FONT_SIZES;
  public fontStyleOptions: FormElementDataModel[] = FONT_STYLE;
  public fontWeightOptions: FormElementDataModel[] = FONT_WEIGHT;
  public textDecorationOptions: FormElementDataModel[] = TEXT_DECORATION;
  public TemplateAssignmentType = TemplateAssignmentType;
  public toolTipText: string = this.translate.instant(TOOLTIP_TEXT_FOR_FONTS);

  public smForm: FormGroup = this.formBuilder.group({
    smNames: '',
    align: 'center',
    borderRadius: ['3', [Validators.min(0)]],
    color: '#333333',
    containerBackgroundColor: '',
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    iconSize: ['20', [Validators.min(10)]],
    innerPadding: ['4', [Validators.min(0)]],
    mode: 'horizontal',
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]],
    textDecoration: 'none',
  });

  constructor(
    private formBuilder: FormBuilder,
    public translate: TranslateService,
  ) {
  }

  public ngOnInit(): void {
    this.setInitialValues();

    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (!element || element.tagName !== MjmlTag.social) {
          return;
        }
        this.setInitialValues();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getMjmlChild(name: MjmlSmName): MjmlElementModel {
    return {
      tagName: MjmlTag.socialElement,
      attributes: {
        name,
        src: SOCIAL_MEDIA_MJML_ICONS[name],
        href: this.tms.defaultSmLinks[name]
      },
      content: DEFAULT_SM_NAMES[name]
    };
  }

  private getSmElement(name: MjmlSmName): FormGroup {
    return this.formBuilder.group({
      href: '',
      name,
      src: SOCIAL_MEDIA_MJML_ICONS[name],
      title: '',
      backgroundColor: SOCIAL_MEDIA_MJML_ICON_DEFAULT_COLOR[name],
      text: DEFAULT_SM_NAMES[name]
    });
  }

  public get socialElements(): FormArray {
    return this.smForm.get('socialElements') as FormArray;
  }

  private setInitialValues(): void {
    const attributes = this.tms.activeElement.attributes;
    const smNames = attributes['sm-names'] ? JSON.parse(attributes['sm-names']) : '';
    const align = attributes['align'] || 'center';
    const borderRadius = attributes['border-radius'] || '3px';
    const color = attributes['color'] || '#333333';
    const containerBackgroundColor = attributes['container-background-color'] || 'transparent';
    const fontFamily = attributes['font-family'] || 'Roboto';
    const fontSize = attributes['font-size'] || '13px';
    const fontStyle = attributes['font-style'] || 'normal';
    const fontWeight = attributes['font-weight'] || 'normal';
    const iconSize = attributes['icon-size'] || '20px';
    const innerPadding = attributes['inner-padding'] || '4px';
    const mode = attributes['mode'] || 'horizontal';
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    const textDecoration = attributes['text-decoration'] || 'none';

    this.smForm.get('smNames').setValue(smNames);
    this.smForm.get('align').setValue(align);
    this.smForm.get('borderRadius').setValue(borderRadius.slice(0, -2));
    this.smForm.get('color').setValue(color);
    this.smForm.get('containerBackgroundColor').setValue(containerBackgroundColor);
    this.smForm.get('fontFamily').setValue(fontFamily);
    this.smForm.get('fontSize').setValue(fontSize);
    this.smForm.get('fontStyle').setValue(fontStyle);
    this.smForm.get('fontWeight').setValue(fontWeight);
    this.smForm.get('iconSize').setValue(iconSize.slice(0, -2));
    this.smForm.get('innerPadding').setValue(innerPadding.slice(0, -2));
    this.smForm.get('mode').setValue(mode);
    this.smForm.get('paddingBottom').setValue(paddingBottom.slice(0, -2));
    this.smForm.get('paddingLeft').setValue(paddingLeft.slice(0, -2));
    this.smForm.get('paddingRight').setValue(paddingRight.slice(0, -2));
    this.smForm.get('paddingTop').setValue(paddingTop.slice(0, -2));
    this.smForm.get('textDecoration').setValue(textDecoration);

    const elements = this.tms.activeElement.children;
    this.smForm.removeControl('socialElements');
    if (elements && elements.length) {
      this.patchSocialElements(elements);
    } else {
      return;
    }
  }

  public getPlatformName(element: FormGroup): string {
    const name = element.get('name').value;
    return DEFAULT_SM_NAMES[name];
  }

  public updateElementModel(value, formGroup: FormGroup, customHref: boolean = false): void {
    const name = formGroup.get('name').value;
    const mjmlChild = this.tms.activeElement.children.find((item: MjmlElementModel) => item.attributes['name'] === name);
    mjmlChild.attributes['href'] = formGroup.get('href').value || '';
    mjmlChild.attributes['title'] = formGroup.get('title').value || '';
    mjmlChild.attributes['background-color'] = formGroup.get('backgroundColor').value || '';
    mjmlChild.content = formGroup.get('text').value || '';
    if (customHref && this.tms.clientPortalVersion) {
      mjmlChild.attributes['default-href-attributes-changed'] = 'changed';
    }
  }

  private patchSocialElements(socialElements: MjmlElementModel[]): void {
    this.smForm.addControl('socialElements', this.formBuilder.array([]));
    socialElements.forEach((socialElement: MjmlElementModel) => {
      const name: MjmlSmName = socialElement.attributes['name'] as MjmlSmName;
      const formGroup = this.getSmElement(name);
      const socialElementAttributes = socialElement.attributes;
      formGroup.get('href').setValue(socialElementAttributes['href'] || this.tms.defaultSmLinks[name]);
      formGroup.get('title').setValue(socialElementAttributes['title'] || '');
      formGroup.get('backgroundColor').setValue(socialElementAttributes['background-color'] || SOCIAL_MEDIA_MJML_ICON_DEFAULT_COLOR[name]);
      formGroup.get('text').setValue(socialElement.content || DEFAULT_SM_NAMES[name]);
      this.socialElements.push(formGroup);
    });
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public onSmNamesChanged(): void {
    const value = this.smForm.get('smNames').value;
    this.tms.activeElement.attributes['sm-names'] = value ? JSON.stringify(value) : '';
    this.smForm.removeControl('socialElements');
    if (!value.length) {
      this.tms.activeElement.children = [];
      return;
    }
    if (!this.tms.activeElement.children) {
      this.tms.activeElement.children = [];
    }
    const mjmlChildren: MjmlElementModel[] = this.tms.activeElement.children.filter((child: MjmlElementModel) => value.includes(child.attributes['name']));
    const existingChildrenNames = mjmlChildren.map((child: MjmlElementModel) => child.attributes['name']);
    value.forEach((name: MjmlSmName) => {
      if (!existingChildrenNames.includes(name)) {
        mjmlChildren.push(this.getMjmlChild(name));
      }
    });
    this.tms.activeElement.children = mjmlChildren;
    this.patchSocialElements(mjmlChildren);
    this.tms.emitSave(true);
  }

  public onModeChanged(): void {
    const value = this.smForm.get('mode').value;
    this.tms.activeElement.attributes['mode'] = value ? value : 'horizontal';
    this.tms.emitSave(true);
  }

  public onAlignChanged(): void {
    const value = this.smForm.get('align').value;
    this.tms.activeElement.attributes['align'] = value ? value : 'center';
    this.tms.emitSave(true);
  }

  public onFontFamilyChanged(): void {
    const value = this.smForm.get('fontFamily').value;
    this.tms.activeElement.attributes['font-family'] = value ? value : 'Roboto';
    this.tms.emitSave(true);
  }

  public onFontSizeChanged(): void {
    const value = this.smForm.get('fontSize').value;
    this.tms.activeElement.attributes['font-size'] = value ? value : '13px';
    this.tms.emitSave(true);
  }

  public onFontStyleChanged(): void {
    const value = this.smForm.get('fontStyle').value;
    this.tms.activeElement.attributes['font-style'] = value ? value : 'normal';
    this.tms.emitSave(true);
  }

  public onFontWeightChanged(): void {
    const value = this.smForm.get('fontWeight').value;
    this.tms.activeElement.attributes['font-weight'] = value ? value : 'normal';
    this.tms.emitSave(true);
  }

  public onColorChanged(): void {
    const value = this.smForm.get('color').value;
    this.tms.activeElement.attributes['color'] = value ? value : '#333333';
    this.tms.emitSave(true);
  }

  public onTextDecorationChanged(): void {
    const value = this.smForm.get('textDecoration').value;
    this.tms.activeElement.attributes['text-decoration'] = value ? value : 'none';
    this.tms.emitSave(true);
  }

  public onContainerBackgroundColorChanged(): void {
    const value = this.smForm.get('containerBackgroundColor').value;
    this.tms.activeElement.attributes['container-background-color'] = value ? value : 'transparent';
    this.tms.emitSave(true);
  }

  public onIconSizeChanged(): void {
    const value = this.smForm.get('iconSize').value;
    this.tms.activeElement.attributes['icon-size'] = value ? `${value}px` : '20px';
    this.tms.emitSave(true);
  }

  public onInnerPaddingChanged(): void {
    const value = this.smForm.get('innerPadding').value;
    this.tms.activeElement.attributes['inner-padding'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onBorderRadiusChanged(): void {
    const value = this.smForm.get('borderRadius').value;
    this.tms.activeElement.attributes['border-radius'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingTopChanged(): void {
    const value = this.smForm.get('paddingTop').value;
    this.tms.activeElement.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.smForm.get('paddingRight').value;
    this.tms.activeElement.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.smForm.get('paddingBottom').value;
    this.tms.activeElement.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.smForm.get('paddingLeft').value;
    this.tms.activeElement.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }
}
