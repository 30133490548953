<div
  class="custom-form-component-container-wrapper"
  [formGroup]="formGroup"
>
  <label
    class="form-field-name"
    [for]="id"
  >{{label | translate}}</label>
  <mat-form-field
    appearance="outline"
    (change)="emitChanges()"
  >
    <textarea
      matInput
      [formControlName]="name"
      [id]="id"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      [type]="type"
      [mask]="mask ? mask : ''"
      [value]="formControl.value ? formControl.value : ''"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      [cdkAutosizeMinRows]="minRows"
      [cdkAutosizeMaxRows]="maxRows"
      (blur)="onBlur()"
      (keydown.enter)="$event.preventDefault(); $event.stopPropagation(); onEnter()"
      (input)="onInput($event)"
    ></textarea>
    <mat-error *ngIf="formControl.invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>

  <div
    class="email-info-icon"
    matTooltip="{{'Use \' ; \' to split emails' | translate}}"
    matTooltipHideDelay="200"
    matTooltipShowDelay="200"
    matTooltipPosition="above"
    matTooltipClass="email-info-icon-tooltip"
  >
    <i class="fas fa-info-circle"></i>
  </div>
</div>
