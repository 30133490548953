import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TemplateManagementService } from 'src/app/services/templates/template.management.service';

@Component({
  selector: 'app-sponsors-tool-settings',
  templateUrl: './sponsors-tool-settings.component.html',
  styleUrls: ['../tool-settings.scss']
})
export class SponsorsToolSettingsComponent implements OnInit {
  @Input() tms: TemplateManagementService

  sponsorsForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.sponsorsForm = fb.group({
      currUrl: this.fb.control(""),
      srcs: this.fb.array([])
    })
   }

  ngOnInit(): void {
    if(!!this.tms.activeElement.attributes["srcs"]) {
      (JSON.parse(this.tms.activeElement.attributes["srcs"]) as [])
      .filter((src: {url: string}) => !!src.url)
      .forEach((src: {url: string}) => {
        this.addUrl(src.url)
      })
    }
  }

  imageUrlChanged(url: string): void {
    if(!url) return;
    this.addUrl(url);
    this.tms.activeElement.attributes["srcs"] = JSON.stringify(this.srcsFormArray.value);
    this.tms.emitSave(true);
  }

  get srcsFormArray(): FormArray {
    return this.sponsorsForm.get("srcs") as FormArray
  }

  private addUrl(url): void {
    this.srcsFormArray.push(this.fb.group({
      url: this.fb.control(url)
    }));
  }

}
