<div
  class="comment-wall-element"
  [ngStyle]="commentRowStyles"
>
  <div
    *ngFor="let comment of comments$ | async; index as i"
    class="comment-wall-element-row"
    [class.with-background]="i % 2 === 0"
  >
    <div class="comment-author">{{comment.fullName}}</div>
    <div class="comment-content">{{comment.comment}}</div>
    <div class="comment-time">
      <div class="comment-create-time">{{getTime(comment)}}</div>
      <div *ngIf="comment.updatedByID" class="comment-update-time">{{'BUILD.Edited by Admin' | translate}} {{comment.updatedOn | date: 'd MMM y'}}</div>
    </div>
  </div>
</div>
