<div
  [formGroup]="formGroup"
  [ngClass]="low ? 'low-custom-form-component-container-wrapper' : 'custom-form-component-container-wrapper'"
  (click)="!disabled && datepicker.open()"
>
  <label
    class="form-field-name"
    [for]="id"
  >{{label | translate}}</label>
  <mat-datepicker
    #datepicker
    startView="multi-year"
    (opened)="onOpened()"
    (closed)="onClosed()"
    (monthSelected)="chosenMonthHandler($event, datepicker)"
    disabled="false"
  ></mat-datepicker>
  <div class="date-icon"></div>
  <mat-form-field appearance="outline">
    <input
      matInput
      [formControlName]="name"
      [id]="id"
      [matDatepicker]="datepicker"
      [matDatepickerFilter]="dateFilter"
      [placeholder]="placeholder ? placeholder : ''"
    >
    <mat-error *ngIf="formControl.invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>
</div>

