import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilderStateService} from "../../../form-builder.state.service";
import {FormArray, FormGroup} from "@angular/forms";
import {RecurringEmailType} from "../../../../../models/enum/recurring-email-type";
import {DefaultRecurringDonationEmailSubjects} from "../../../../../constants";

@Component({
  selector: 'app-recurring-donation-email-settings-tab',
  templateUrl: './recurring-donation-email-settings-tab.component.html',
  styleUrls: ['../../fb.page.scss']
})
export class RecurringDonationEmailSettingsTabComponent implements OnInit {
@Input() fbss: FormBuilderStateService;
@Input() public placeholders: string[];
@Output() public valueChanged: EventEmitter<void> = new EventEmitter<void>();
@Output() public resetEmail: EventEmitter<RecurringEmailType> = new EventEmitter<RecurringEmailType>();
public recurringEmailType = RecurringEmailType;
  constructor() { }
  public get recurringEmailGroup(): FormArray {
    return this.fbss.templateSettingsForm.get('recurringEmailGroup') as FormArray;
  }

  public getTitle(type: RecurringEmailType):string {
    switch (type) {
      case RecurringEmailType.ScheduleCreation:
        return "Recurring Schedule Creation"
      case RecurringEmailType.NextScheduledDonationDate:
        return "Next Scheduled Donation Date"
      case RecurringEmailType.ThankYou:
        return "Thank You Email"
      case RecurringEmailType.DonationDidNotProcess:
        return "Recurring Donation Did Not Process"
      case RecurringEmailType.CancelledDonation:
        return "Cancelled Recurring Donation(s)"
      case RecurringEmailType.RestartedDonation:
        return "Restarted Recurring Donation(s)"
    }
  }
  ngOnInit(): void {
  }

  protected readonly FormGroup = FormGroup;
}
