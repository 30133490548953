import { Component, Input, OnInit } from '@angular/core';
import { FormBuilderStateService } from '../../form-builder.state.service';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { FB_FORM_STATUS } from '../../../../constants';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { FbTemplateStatusType } from '../../../../models/form-builder/fb.template.model';
import { map, tap } from 'rxjs/operators';
import { LookupStoreService } from '../../../../services/lookup/lookup-store.service';
import { UtilsComponent } from '../../../utils.component';

@Component({
  selector: 'app-form-settings',
  templateUrl: './form-settings.component.html',
  styleUrls: ['../fb.page.scss']
})
export class FormSettingsComponent implements OnInit {
  @Input() public fbss: FormBuilderStateService;

  public formStatusOptions: FormElementDataModel[] = FB_FORM_STATUS;
  public isEnableOnDate$: Observable<boolean>;

  constructor(
    public lookupStoreService: LookupStoreService
  ) { }

  public ngOnInit(): void {
    this.disableControls();
    this.isEnableOnDate$ = this.control('fbTemplateStatus').valueChanges
      .pipe(
        map((value: FbTemplateStatusType) => value === FbTemplateStatusType.DisableOnDate),
        tap((isEnableOnDate: boolean) => {
          if (isEnableOnDate) {
            this.control('disableDate').enable();
            this.control('disableTime').enable();
            this.control('disableTimeZone').enable();
            this.control('messageWhenDisabled').enable();
          } else {
            this.disableControls();
          }
        })
      );
  }

  public control(controlName: string): FormControl {
    return this.fbss.templateSettingsForm.get(controlName) as FormControl;
  }

  private disableControls(): void {
    this.control('disableDate').disable();
    this.control('disableTime').disable();
    this.control('disableTimeZone').disable();
    this.control('messageWhenDisabled').disable();
  }

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }

  public dateFilter = (date: Date): boolean => {
    if (!date) {
      return false;
    }
    return UtilsComponent.strictDate(date) >= UtilsComponent.strictDate(new Date());
  };

  public onValueChanged(): void {
    this.fbss.templateWasChanged(true);
  }

}
