<div class="donor-register-wrapper">
  <div class="donor-register-content">
    <div class="donor-register-top-image"></div>
    <div *ngIf="!sendEmail" class="donor-register-content-body">
      <span class="donor-register-content-body-title">{{'DONOR_PORTAL.DONOR_REGISTER.Sign Up as Donor' | translate}}</span>
      <form [formGroup]="registerForm" class="form-body">
        <mat-form-field appearance="outline">
          <mat-label>{{'Email' | translate}}</mat-label>
          <input formControlName="email" matInput>
          <mat-error>
            {{errorMessage(email, 'email')}}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'First Name' | translate}}</mat-label>
          <input formControlName="firstName" matInput>
          <mat-error>
            {{errorMessage(firstName, 'firstName')}}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'Last Name' | translate}}</mat-label>
          <input formControlName="lastName" matInput>
          <mat-error>
            {{errorMessage(lastName, 'lastName')}}
          </mat-error>
        </mat-form-field>

        <div class="privacy-policy">
          <img
            src="/assets/images/checkbox.svg"
            alt="checkbox"
            [class.hidden]="agreeToPrivacyPolicy"
            (click)="agreeToPrivacyPolicy = true"
          >
          <img
            src="/assets/images/checkbox_checked.svg"
            alt="checkbox"
            [class.hidden]="!agreeToPrivacyPolicy"
            (click)="agreeToPrivacyPolicy = false"
          >
          <span>
            {{'DONOR_PORTAL.DONOR_REGISTER.I agree to' | translate}}
            <a href="https://www.theauxilia.com/privacy-policy" target="_blank">{{'DONOR_PORTAL.DONOR_REGISTER.Privacy Policy' | translate}}</a>
            {{'DONOR_PORTAL.DONOR_REGISTER.and' | translate}}
            <a href="https://www.theauxilia.com/terms-of-use" target="_blank">{{'DONOR_PORTAL.DONOR_REGISTER.Terms of Use' | translate}}</a>
          </span>
        </div>

        <div 
          class="captcha-form-row" 
          #captchaRef>
          <ngx-recaptcha2
           #captchaElem 
           [useGlobalDomain]="false"
           [siteKey]="siteKey"
           size="normal" 
           hl="en" 
           theme="light"
           type="image" 
           formControlName="reCaptchaToken">
          </ngx-recaptcha2>
          <span *ngIf="captchaError" class="captcha-error">
            {{'Please, check the checkbox' | translate}}
          </span>
        </div>

        <div class="donor-register-content-body-button">
          <app-button
            type="flat"
            label="SIGN UP"
            [isFullWidth]="true"
            (clickEvent)="signUp()"
            color="#0064be"
            width="100%"
            [disabled]="!agreeToPrivacyPolicy"
          ></app-button>
          <p class="text"[innerHTML]="'DONOR_PORTAL.DONOR_REGISTER.NonProfitLink' | translate"></p>

        </div>
      </form>
      <span class="donor-register-content-body-second-title">{{'DONOR_PORTAL.DONOR_REGISTER.Already have an Account?' | translate}}</span>
      <div class="donor-register-content-body-button">
        <app-button type="flat" label="LOGIN" [isFullWidth]="true" (clickEvent)="signIn()" color="#0064be" width="100%"></app-button>
      </div>
    </div>

    <div *ngIf="sendEmail" class="send-email">{{'DONOR_PORTAL.DONOR_REGISTER.Please check your email' | translate}}</div>
    <div *ngIf="sendEmail" class="back-button-wrapper">
      <app-button
        type="flat"
        color="#0064be"
        width="100%"
        label="BACK TO LOGIN PAGE"
        (clickEvent)="onBack()"
      ></app-button>
    </div>

  </div>
</div>
