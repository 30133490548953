<div class="paginator-component-wrapper" [class.space-evenly]="useSpaceEvenly">

  <div class="entries-per-page">
    <span class="entries-per-page-label">{{isAdminPortal ? 'Entries per page' : ('Entries per page' | translate)}}:</span>
    <div
      [matMenuTriggerFor]="menu"
      matRipple
    >
      <div class="button-text">
        <span class="selected-entries">{{entriesPerPage}}</span>
        <span class="entries-per-page-trigger"></span>
      </div>
    </div>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let item of entriesPerPageSettings">
        <button mat-menu-item (click)="setEntriesPerPage(item)">{{item}}</button>
      </ng-container>
    </mat-menu>
  </div>

  <div class="pages-navigator">
    <ng-container *ngIf="totalItems === null">
      <span class="paginator-loading">Loading...</span>
    </ng-container>

    <ng-container *ngIf="totalItems !== null">
      <div class="paginator-range">
        <span>{{getRange()}}</span>
        <span>{{isAdminPortal ? 'of' : ('of' | translate)}} {{totalItems}}</span>
      </div>
  
      <div class="paginator-pages">
        <span
          *ngFor="let page of paginationPages$ | async"
          [class.current-page]="currentPage === page"
          (click)="onPageClick(page)"
        >{{page}}</span>
      </div>
  
      <div class="paginator-arrows">
        <i (click)="onPreviousClick()" class="fas fa-chevron-left"></i>
        <i (click)="onNextClick()" class="fas fa-chevron-right"></i>
      </div>
    </ng-container>
  </div>

</div>
