<div class="email-builder-tool-settings-wrapper">

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="moduleForm"
        label="Color"
        name="color"
        [readOnly]="readOnly"
        (colorChanged)="onColorChanged()"
      ></app-email-color-picker>
    </div>
    <div class="max-width-145">
      <app-select
        label="Font family"
        [formGroup]="moduleForm"
        name="fontFamily"
        errorMassage="Font family"
        [selectOptions]="fontFamilyOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontFamilyChanged()"
      ></app-select>
      <div
        *ngIf="tms.templateAssignmentType === TemplateAssignmentType.Campaign"
        class="settings-info-icon"
        [matTooltip]="toolTipText | translate"
        matTooltipHideDelay="200"
        matTooltipShowDelay="200"
        matTooltipPosition="above"
        matTooltipClass="settings-tooltip-custom-class"
      >
        <i class="fas fa-info-circle"></i>
      </div>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Font size"
        [formGroup]="moduleForm"
        name="fontSize"
        errorMassage="Font size"
        [selectOptions]="fontSizeOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontSizeChanged()"
      ></app-select>
    </div>
    <div class="max-width-145">
      <app-select
        label="Font style"
        [formGroup]="moduleForm"
        name="fontStyle"
        errorMassage="Font style"
        [selectOptions]="fontStyleOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontStyleChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Font Weight"
        [formGroup]="moduleForm"
        name="fontWeight"
        errorMassage="Font Weight"
        [selectOptions]="fontWeightOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontWeightChanged()"
      ></app-select>
    </div>
    <div class="max-width-145">
      <app-select
        label="Decoration"
        [formGroup]="moduleForm"
        name="textDecoration"
        errorMassage="Decoration"
        [selectOptions]="textDecorationOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onTextDecorationChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="moduleForm"
        label="Background color"
        name="containerBackgroundColor"
        [readOnly]="readOnly || isPredesignedThemedTemplate"
        (colorChanged)="onContainerBackgroundColorChanged()"
      ></app-email-color-picker>
    </div>
    <div class="max-width-145">
      <app-select
        label="Position"
        [formGroup]="moduleForm"
        name="align"
        errorMassage="Position"
        [selectOptions]="alignOptions"
        [low]="true"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onAlignChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="moduleForm"
        name="paddingTop"
        label="Padding Top"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingTopChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="moduleForm"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingRightChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="moduleForm"
        name="paddingBottom"
        label="Padding Bottom"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingBottomChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="moduleForm"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingLeftChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row" *ngIf="!(readOnly || isPredesignedThemedTemplate)">
    <app-button
      type="basic"
      label="Delete content"
      (clickEvent)="tms.removeActiveElementFromColumn()"
      color="#f44336"
      width="300"
    ></app-button>
  </div>

</div>
