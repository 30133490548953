import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import ColumnFilterModel from 'src/app/models/filter-sort/column.filter.model';

@Component({
  selector: 'app-range-selector',
  templateUrl: './range-selector.component.html',
  styleUrls: ['./range-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeSelectorComponent implements OnChanges {
  @Input() public minMaxRange: number[];
  @Output() public selectedOptions: EventEmitter<ColumnFilterModel> = new EventEmitter<ColumnFilterModel>();
  @ViewChild(MatMenuTrigger) public menu: MatMenuTrigger;
  public someRange: number[];
  public step: number = 10;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.minMaxRange && changes.minMaxRange.currentValue) {
      this.someRange = this.minMaxRange;
    }
  }

  public applyFilters(): void {
    this.selectedOptions.emit({
      field: 'donationsCount',
      values: this.someRange
    });
    this.menu.closeMenu();
  }

  public clearAll(): void {
    this.someRange = this.minMaxRange;
    this.selectedOptions.emit({
      field: 'donationsCount',
      values: []
    });
    this.menu.closeMenu();
  }
}
