<div class="fb-input-wrapper" [formGroup]="formGroup" #divElement>
  <label class="form-field-name" *ngIf="isTitle" [for]="id" [ngStyle]="titleStyle">{{label | translate}}</label>
  <mat-form-field appearance="outline">
    <mat-label *ngIf="isMatTitle">{{label | translate}}</mat-label>
    <mat-select
      [formControlName]="name"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      [id]="id"
      (selectionChange)="emitChanges($event)"
      [value]="formControl.value"
      [disabled]="isDisabled"
    >
      <mat-option *ngIf="isSearchOption">
        <ngx-mat-select-search
          [formControl]="filterControl"
          placeholderLabel="{{'Search' | translate}}"
          noEntriesFoundLabel="{{'Option not found' | translate}}"
        ></ngx-mat-select-search>
      </mat-option>

      <mat-option *ngFor="let option of options$ | async" [value]="option.value">
        {{option.label | translate}}
      </mat-option>

    </mat-select>
  </mat-form-field>
  <span class="bottom-label error-message" *ngIf="formControl.touched && formControl.invalid && !customError; else labelTemplate">{{getErrorMessage()}}</span>
</div>

<ng-template #labelTemplate>
  <span class="bottom-label" *ngIf="!isTitle && !isMatTitle" [ngStyle]="labelStyle">{{label | translate}}</span>
</ng-template>
