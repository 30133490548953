<div class="flex-column max-w-300">
  <p>
    If you choose the instant verification process, there is a $1.50 additional charge as soon as you enter your bank information.
  </p>
  <p>
    To avoid the instant verification fee, please select Enter Bank Details Manually Instead. You, or the organization you're contributing to, will pay the fee.
  </p>
  <div class="flex-row justify-end">
    <div class="mr-10">
      <app-button
        [mat-dialog-close]="true"
        type="flat"
        label="Continue"
        color="#0064be">
      </app-button>
    </div>
  </div>
</div>