import { Component, Input } from '@angular/core';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';

@Component({
  selector: 'app-divider-tool',
  templateUrl: './divider-tool.component.html',
  styleUrls: ['./divider-tool.component.scss']
})
export class DividerToolComponent {
  @Input() public column: MjmlElementModel;
  @Input() public entity: MjmlElementModel;
  @Input() public tms: TemplateManagementService;

  public getContainerStyles(): any {
    const attributes = this.entity.attributes;
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    const containerBackgroundColor = attributes['container-background-color'] || 'transparent';
    return {
      'padding-bottom': paddingBottom,
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      'padding-top': paddingTop,
      'background': containerBackgroundColor
    };
  }

  public getStyles(): any {
    const attributes = this.entity.attributes;
    const borderColor = attributes['border-color'] || '#000000';
    const borderStyle = attributes['border-style'] || 'solid';
    const borderWidth = attributes['border-width'] || '4px';
    const width = attributes['width'] || '100%';
    return {
      'border-top-color': borderColor,
      'border-top-style': borderStyle,
      'border-top-width': borderWidth,
      width
    };
  }

}
