import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilderStateService } from '../../../form-builder.state.service';
import {
  AlignmentType,
  FbElementModel,
  FbElementType,
  HeaderSize
} from '../../../../../models/form-builder/fb.template.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import { FB_ALIGN, FB_SIZE } from '../../../../../constants';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-header-footer-banner-options',
  templateUrl: './header-footer-banner-options.component.html',
  styleUrls: ['../../fb.page.scss']
})
export class HeaderFooterBannerOptionsComponent implements OnInit, OnDestroy {
  @Input() public fbss: FormBuilderStateService;

  private element: FbElementModel;
  public imageURL: string = null;

  public optionsForm: FormGroup = this.formBuilder.group({
    logoSrc: '',
    logoPosition: '',
    logoSize: '',
    background: ''
  });

  public alignmentOptions: FormElementDataModel[] = FB_ALIGN;
  public sizeOptions: FormElementDataModel[] = FB_SIZE;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.fbss.activeElementSettings$.asObservable()
        .pipe(
          filter(value => value === FbElementType.HeaderFooter),
          tap(() => {
            this.element = this.fbss.template$.getValue().headerFooter;
            this.setInitialValues();
          }),
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }

  private setInitialValues(): void {
    const {
      logoSrc = '/assets/images/logo.png',
      logoPosition = AlignmentType.Left,
      logoSize = HeaderSize.Medium,
      background = '#023665'
    }: {[key: string]: string} = this.element.attributes;
    this.logoSrc.setValue(logoSrc);
    this.optionsForm.get('logoPosition').setValue(logoPosition);
    this.optionsForm.get('logoSize').setValue(logoSize);
    this.optionsForm.get('background').setValue(background);
  }

  public get logoSrc(): FormControl {
    return this.optionsForm.get('logoSrc') as FormControl;
  }

  public imageUrlChanged(url: string): void {
    this.imageURL = null;
    if (url === null) {
      return;
    }
  }

  public onUrlChanged(): void {
    this.element.attributes.logoSrc = this.logoSrc.value;
    this.fbss.templateWasChanged();
  }

  public editImage(): void {
    this.imageURL = this.logoSrc.value;
  }

  public cancelEditImage(): void {
    this.imageURL = null;
  }

  public onOptionChanged(controlName: string): void {
    this.element.attributes[controlName] = this.optionsForm.get(controlName).value;
    this.fbss.templateWasChanged();
  }
}
