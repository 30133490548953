import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from '../base.model.service';
import { EventCommentWallModel } from '../../models/event/event.comment.wall.model';
import { Paging } from '../../models/paging/paging.model';
import { Observable } from 'rxjs';
import { flatMap, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventCommentService extends BaseModelService<EventCommentWallModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'EventComment';
  }

  public deleteCommentList(model: Paging): Observable<number> {
    /*
    * const filterType = this.allChecked.value ? FilterType.NotEqual : FilterType.Equal;
      model.filters.push({
        field: 'id',
        value: `array[${UtilsComponent.getArrayQueryValue([...this.idsSet])}]`,
        type: filterType,
        filterType
      });*/
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/DeleteCommentList`;
        return this.http.put<number>(url, model);
      })
    );
  }
}
