import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import EventModel from '../../models/event/event.model';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { EventService } from '../../services/events/event.service';
import { Observable, of, Subscription } from 'rxjs';
import MjmlElementModel from '../../models/templates/mjml.element.model';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { EventStatus } from 'src/app/models/enum/event.status';
import { AuthService } from 'src/app/services/auth.service';
import { UserRolesType } from 'src/app/models/enum/user.roles.type';
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-publish-event',
  templateUrl: './publish-event.component.html',
  styleUrls: ['./publish-event.component.scss', '../../shared/styles/layout.scss']
})
export class PublishEventComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public event: EventModel;

  /** Flag for new and old template versions */
  public useTemplateModelHtml: boolean = true;

  public mjml$: Observable<MjmlElementModel>;
  public showHeaderAndFooter$: Observable<boolean>;
  isEventCanceled: boolean;

  @ViewChild('publishEventWrapperRef') private publishEventWrapperRef: ElementRef;

  constructor(
    private activeRouter: ActivatedRoute,
    private eventService: EventService,
    private toastrService: ToastrService,
    private authService: AuthService
  ) { }

  public ngOnInit(): void {
    const eventId = this.activeRouter.snapshot.params['eventId'];
    const friendlyURL = this.activeRouter.snapshot.params['friendlyURL'];

    this.subscription.add(
      of(null).pipe(
        switchMap(() => {
          if (eventId && this.useTemplateModelHtml) {
            return this.getEventById(eventId);
          } else if (friendlyURL && this.useTemplateModelHtml) {
            return this.getEventByFriendlyURL(friendlyURL);
          }
        }),
        tap((res: EventModel) => {
          const roles: string[] = this.authService.getIdentityClaimsRole();

          if(!res) {
            throw new Error("Event does not exist");
          }

          if(res.status === EventStatus.Draft && !roles?.includes(UserRolesType.CLIENT_ADMIN) && !roles?.includes(UserRolesType.SUPER_ADMIN)) {
            throw new Error("The event page is not ready to display yet");
          }

          if(!res?.mjml && res.status !== EventStatus.Canceled) {
            throw new Error("The event page is not ready to display yet");
          }

        })
      )
      .subscribe({
        next: (response: EventModel) => {
          if (response.status === EventStatus.Canceled) {
            this.isEventCanceled = true;
            return;
          }

          this.event = response;
          const createOn = new Date(response.createdOn);
          const changingPoint = new Date(2020, 6, 13); //todo set new date to prod and stage 13.07.2020
          this.useTemplateModelHtml = createOn > changingPoint;
          if (this.useTemplateModelHtml && response.mjml) {
            this.mjml$ = of(JSON.parse(response.mjml));
          }
        },
        error: (error: any) => {

          let message = error?.message;
          this.toastrService.error(message);
        }
      })
    )

    this.showHeaderAndFooter$ = this.activeRouter.url
      .pipe(
        map((urlSegments: UrlSegment[]) => !!urlSegments && !!urlSegments.length && urlSegments[0].path !== 'embed')
      )
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getEventById(id: string): Observable<EventModel> {
    return this.eventService.getModel(id);
  }

  private getEventByFriendlyURL(friendlyURL: string): Observable<EventModel> {
    return this.eventService.getEventModelByFriendlyUrl(encodeURI(friendlyURL), false);
  }
}
