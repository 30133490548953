<div class="highlighting-wrapper">

  <div class="highlighting-column">
    <div class="highlighting-title-wrapper">
      <span class="highlighting-title">{{'DASH.Upcoming Events' | translate | uppercase}}</span>
    </div>

    <div class="campaign-data">
      <span class="campaign-title-name">{{activeEvent?.name | titlecase}}</span>
      <span class="campaign-title-name">{{activeEvent?.startDate | date}} | {{activeEvent?.startTime}}</span>
    </div>

    <div class="campaign-data" *ngIf="activeEvent?.priceTicketType === PriceTicket.Ticket && !loadingRegistrations">
      <span>{{'DASH.Tickets' | translate}}</span>
      <span>{{'DASH.Sold' | translate}}: {{soldTickets}} | {{'DASH.Amount' | translate}}: {{activeEvent?.tickets.amount}}</span>
    </div>

    <div class="campaign-amount-left" *ngIf="!loadingRegistrations">
      <span class="campaign-data-money">{{registrationsToEvent}}</span>
      <span>{{'DASH.Registrations' | translate}}</span>
    </div>

    <div class="campaign-spinner" *ngIf="loadingRegistrations && !showSpinner">
      <app-string-spinner></app-string-spinner>
    </div>

  </div>
  <div class="highlighting-column">
    <div class="campaign-amount" *ngIf="hasGoal()">
      <div class="goal-item" *ngFor=" let goal of activeEvent?.goalList">
        <ng-container *ngIf="isIncreaseTicketSales(goal.type)">
          <span class="campaign-data-money-value">{{'DASH.' + existingGoals[goal.type] | translate}}: {{getGoalValue(goal)}}</span>
          <span class="campaign-data-money-value">{{goal.value - activeEvent?.tickets.sold}} {{'DASH.remaining to goal' | translate}}</span>
        </ng-container>
        <span *ngIf="!isIncreaseTicketSales(goal.type)" class="campaign-data-money-value">{{'DASH.' + existingGoals[goal.type] | translate}}: {{getGoalValue(goal)}}</span>
      </div>
    </div>

  <!--  <div class="campaign-amount">
      <span class="campaign-data-money">{{registrationsToEvent}}</span>
      <span>Registrations</span>
    </div>-->


  </div>

  <div *ngIf="showSpinner" class="spinner">
    <app-spinner></app-spinner>
  </div>

  <div *ngIf="showSpinner" class="spinner">
    <app-spinner></app-spinner>
  </div>

</div>
