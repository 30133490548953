import {AuthService} from '../../../../services/auth.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {DashboardService} from 'src/app/services/dashboard/dashboard.service';
import {DashboardWidgetModel} from 'src/app/models/dashboard/dashboard-widget.model';
import {Observable, Subscription} from 'rxjs';
import {ClientIdStateService} from '../../../../services/client.module.state/client.id.state.service';
import {Router} from '@angular/router';
import {distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {Paging} from "../../../../models/paging/paging.model";
import {FilterType} from "../../../../models/enum/filter.type";

@Component({
  selector: 'app-donor-retention-rate',
  templateUrl: './donor-retention-rate.component.html',
  styleUrls: ['./donor-retention-rate.component.scss']
})
export class DonorRetentionRateComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public atRiskDonors: number = null;
  public avgChurnRate: number = null;
  public avgLifeTimeValue: number = null;
  public avgRetentionRate: number = null;
  public oneTime: number = null;
  public progress: number = null;
  public recurring: number = null;
  public lybunt: number = null;
  public ytdNewDonors: number = null;

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private clientIdStateService: ClientIdStateService,
    private router: Router
  ) {
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.clientIdStateService.clientIdObservable
        .pipe(
          distinctUntilChanged(),
          switchMap(this.getData.bind(this))
        )
        .subscribe()
    );
  }

  private getData(clientId: string): Observable<DashboardWidgetModel> {
    this.setValuesToNull();
    const paging: Paging = {
      filters: [
        {
          field: 'clientId',
          value: clientId,
          type: FilterType.Equal
        },
        {
          field: 'isYTD',
          value: '1',
          type: FilterType.Equal
        }
      ]
    }
    return this.dashboardService.getDonorRetentionRateWidget(paging)
      .pipe(
        tap(this.calculateValues.bind(this))
      );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private calculateValues(widgetData: DashboardWidgetModel): void {
    this.ytdNewDonors = widgetData.values.find(x => x.fieldName === 'YTD New Donors').count;
    this.lybunt = widgetData.values.find(x => x.fieldName === 'LYBUNT').count;
    this.atRiskDonors = widgetData.values.find(x => x.fieldName === 'At-Risk Donors').count;
    this.avgRetentionRate = widgetData.values.find(x => x.fieldName === 'Avg Retention Rate').count;
    this.avgChurnRate = widgetData.values.find(x => x.fieldName === 'Avg Churn Rate').count;
    this.avgLifeTimeValue = widgetData.values.find(x => x.fieldName === 'Avg Lifetime Value').count;
    this.oneTime = widgetData.values.find(x => x.fieldName === 'One-Time').count;
    this.recurring = widgetData.values.find(x => x.fieldName === 'Recurring').count;
    this.progress = this.oneTime / (this.oneTime + this.recurring);
  }

  public navigateToReport(flag?: boolean): void {
    if (flag) {
      this.router.navigateByUrl('/clients/reports/donors/ytd-one-time-donors');
      return;
    }
    this.router.navigateByUrl('/clients/reports/donors/ytd-repeat-donors');
  }

  private setValuesToNull(): void {
    this.ytdNewDonors = null;
    this.lybunt = null;
    this.atRiskDonors = null;
    this.avgRetentionRate = null;
    this.avgChurnRate = null;
    this.avgLifeTimeValue = null;
    this.oneTime = null;
    this.recurring = null;
    this.progress = null;
  };
}
