import { ConfigService } from '../appconfig.service';
import { flatMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Paging } from 'src/app/models/paging/paging.model';
import { PagingHelper } from '../helper/paging.helper';

@Injectable({
  providedIn: 'root'
})
export class ExportReportsService {
  constructor(private configService: ConfigService, private http: HttpClient) { }

  public exportDonors(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/clientDonors`, { params, responseType: 'blob' });
      })
    );
  }

  public exportAllDonors(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email); return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/clientAllDonors`, { params, responseType: 'blob' });
      })
    );
  }

  public exportSelectedDonors(selectedDonorIds: string[], filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email); 
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.post(`${config.utilsURL}/export/clientSelectedDonors`, selectedDonorIds, { params, responseType: 'blob' });
      })
    );
  }

  public exportEventTickets(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email); return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/eventTickets`, { params, responseType: 'blob' });
      })
    );
  }

  public exportCampaigns(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email); return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/eventCampaigns`, { params, responseType: 'blob' });
      })
    );
  }

  public exportEventRegisteredGuests(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email); return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/eventRegisteredGuests`, { params, responseType: 'blob' });
      })
    );
  }

  public exportEventInvitedGuests(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/InvitedGuests`, { params, responseType: 'blob' });
      })
    );
  }

  public exportAudit(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/audit`, { params, responseType: 'blob' });
      })
    );
  }

  public exportAuditFeatures(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/auditFeatures`, { params, responseType: 'blob' });
      })
    );
  }

  public exportInvitedGuests(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/invitedGuests`, { params, responseType: 'blob' });
      })
    );
  }

  public exportClientDonations(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/ClientDonations`, { params, responseType: 'blob' });
      })
    );
  }

  public exportClientItemizedDonations(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/ItemizedDonations`, { params, responseType: 'blob' });
      })
    );
  }

  public exportClientFundDonations(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/FundDonations`, { params, responseType: 'blob' });
      })
    );
  }

  public exportClientDonationsFull(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/ClientDonationsFull`, { params, responseType: 'blob' });
      })
    );
  }

  public exportRecurringDonations(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/RecurringDonations`, { params, responseType: 'blob' });
      })
    );
  }

  public exportRegistrationActivity(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/RegistrationActivity`, { params, responseType: 'blob' });
      })
    );
  }

  public exportSegmentRecipients(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/SegmentRecipients`, { params, responseType: 'blob' });
      })
    );
  }

  public exportEventVolunteers(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email); return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/eventVolunteers`, { params, responseType: 'blob' });
      })
    );
  }

  public exportEventVolunteersStatistic(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email); return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/VolunteerAreaStatistics`, { params, responseType: 'blob' });
      })
    );
  }

  public exportTagging(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    // params = params.append('ClientTag', tagID);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/Tagging`, { params, responseType: 'blob' });
      })
    )
  }

  public exportTopDonations(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    // params = params.append('ClientTag', tagID);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/TopDonations`, { params, responseType: 'blob' });
      })
    )
  }

  public exportCampaignDonations(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/CampaignDonations`, { params, responseType: 'blob' });
      })
    );
  }

  public exportSoftCredits(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/SoftCredits`, { params, responseType: 'blob' });
      })
    );
  }

  public exportSoftCreditsSummary(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/SoftCreditsSummary`, { params, responseType: 'blob' });
      })
    );
  }

  public exportP2PRegistrationActivity(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/P2PRegistrationsActivity`, { params, responseType: 'blob' });
      })
    );
  }

  public exportP2PRegistrationActivitySummary(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    if (email) params = params.append('email', email);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/P2PRegistrationsActivitySummary`, { params, responseType: 'blob' });
      })
    );
  }

  public exportP2PEventDashboardReport(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/P2PEventDashboardReport`, { params, responseType: 'blob' });
      })
    );
  }
  public exportP2PRollUpReport(filter: Paging, employeeId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filter);
    params = params.append('recipientId', employeeId);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get(`${config.utilsURL}/export/P2PRollUpReport`, { params, responseType: 'blob' });
      })
    );
  }
}
