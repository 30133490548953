import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TemplateAssignmentType } from '../../../../models/templates/template.assignment.type';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { MjmlCustomType } from '../../../../models/templates/mjml.custom.type';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { EventStatus } from '../../../../models/enum/event.status';
import { EventSchedularModel } from '../../../../models/event/event.schedular.model';
import { formatDate } from '@angular/common';
import { TimeZoneModel } from '../../../../models/time-zone.model';
import { TemplateType } from '../../../../models/templates/template.type';
import { CountryStateService } from '../../../../services/events/country.state.service';
import { MatSelectChange } from '@angular/material/select';
import CountryModel from '../../../../models/internationalization/country.model';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit, OnChanges {
  @Input() public tms: TemplateManagementService;
  @Input() public eventForm: FormGroup;
  @Input() public toolContainer: MjmlElementModel;
  @Input() public stateOptions: FormElementDataModel[] = [];
  @Input() public timeZonesOptions: FormElementDataModel[] = [];
  @Input() public eventStatus: EventStatus = EventStatus.New;
  @Input() public timeZones: TimeZoneModel[] = [];
  @Input() public campaignForm: FormGroup;
  @Input() public countryStateService: CountryStateService;

  public templateAssignmentType: TemplateAssignmentType;
  public TemplateAssignmentType = TemplateAssignmentType;
  public MjmlTag = MjmlTag;
  public MjmlCustomType = MjmlCustomType;

  constructor() { }

  ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.eventForm && changes.eventForm.currentValue) {
      this.templateAssignmentType = TemplateAssignmentType.Event;
    }
    if (changes.campaignForm && changes.campaignForm.currentValue) {
      this.templateAssignmentType = TemplateAssignmentType.Campaign;
    }
  }

  /** Drop tool element and add child into mjml-column */
  public dropItem(customType: MjmlCustomType): void {
    if (!this.toolContainer || this.readOnly || this.predesignedTemplate) {
      return;
    }
    const child: MjmlElementModel = {
      tagName: MjmlTag.text,
      attributes: {
        padding: '10px 25px',
        customType,
        'font-family': 'Arial'
      },
      content: ''
    };
    switch (customType) {
      case MjmlCustomType.EventName:
        child.content = `<p>${this.eventForm.get('name').value}</p>`;
        break;
      case MjmlCustomType.EventDescription:
        child.content = `<p>${this.eventForm.get('description').value}</p>`;
        break;
      case MjmlCustomType.EventLocation:
        const countryState: Partial<CountryModel> = this.countryStateService.countryState.getValue();
        const country = countryState.name;
        const territorialEntity = this.eventForm.get('territorialEntity').value;
        const address1 = this.eventForm.get('address1').value;
        const address2 = this.eventForm.get('address2').value;
        const city = this.eventForm.get('city').value;

        let state = this.eventForm.get('state').value;
        if (country === 'USA') {
          state = state ? this.stateOptions.find(({value}: FormElementDataModel) => value === state).label : '';
        }
        let zip = this.eventForm.get('zip').value;
        if (country === 'USA' && zip && zip.length > 5) {
          zip = zip.slice(0, 5) + '-' + zip.slice(5);
        }
        child.content =
          `${address1 ? '<p>' + address1 + '</p>' : ''}
           ${address2 ? '<p>' + address2 + '</p>' : ''}
           <p>${this.countryStateService.showCity$.getValue() && city ? city : ''}${this.countryStateService.showState$.getValue() && state ? ', ' + state : ''}${this.countryStateService.showZip$.getValue() && zip ? ', ' + zip : ''}</p>
           <p>${country}${this.countryStateService.showTerritorialEntity$.getValue() && territorialEntity ? ', ' + territorialEntity : ''}</p>`;
        break;
      case MjmlCustomType.EventScheduler:
        const scheduler = this.eventSchedularList.getRawValue();
        const timeZoneID = this.eventForm.get('timeZoneID').value;
        let content = '';
        scheduler.forEach(({startDate, endDate, startTime, endTime}: any) => {
          content += `<p>${startDate ? formatDate(startDate, 'MMMM dd, yyyy', 'en-US') + ' - ' : ''}
            ${endDate ? formatDate(endDate, 'MMMM dd, yyyy', 'en-US') : ''}
            ${startTime ? startTime : ''}
            ${endTime ? ' - ' + endTime : ''}
            ${timeZoneID ? ' ' + this.getShortTimeZone() : ''}</p>`;
        });
        child.content = content;
        break;
      case MjmlCustomType.EventContacts:
        const clientName = this.eventForm.get('clientName').value;
        const clientWebSite = this.eventForm.get('clientWebSite').value;
        let webSiteUrl = clientWebSite;
        if (clientWebSite && !clientWebSite.startsWith('http')) {
          webSiteUrl = `https://${clientWebSite}`;
        }
        const contactUsEmail = this.eventForm.get('contactUsEmail').value;
        const contactUsPhone = this.eventForm.get('contactUsPhone').value;
        child.content = `${clientName ? '<p>' + clientName + '</p>' : ''}
          ${clientWebSite ? '<p><a href="' + webSiteUrl + '">' + clientWebSite + '</a></p>' : ''}
          ${contactUsEmail ? '<p><a href="mailto:' + contactUsEmail + '">' + contactUsEmail + '</a></p>' : ''}
          ${contactUsPhone ? '<p><a href="tel:+1' + contactUsPhone + '">' + '+1 ' + this.formatTelNumber(contactUsPhone) + '</a></p>' : ''}`;
        break;
      case MjmlCustomType.EventItinerary:
        const eventItineraries = this.eventItineraries.getRawValue();
        if (this.eventItineraries.valid)
          child.content = this.tms.getItinerariesSet(eventItineraries);
        break;
      case MjmlCustomType.EventAccessibility:
        child.content = `<p>${this.eventForm.get('accessabilityText').value}</p>`;
        break;
      case MjmlCustomType.CampaignName:
        child.content = `<p>${this.campaignForm.get('name').value}</p>`;
        break;

    }
    this.toolContainer.children.push(child);
    this.tms.emitSave(true);
  }

  public get eventSchedularList(): FormArray {
    return this.eventForm.get('eventSchedularList') as FormArray;
  }

  public get eventItineraries(): FormArray {
    return this.eventForm.get('eventItineraries') as FormArray;
  }

  public getShortTimeZone(): string {
    if (this.timeZones) {
      const timeZone = this.timeZones.find(x => x.id === Number(this.eventForm.get('timeZoneID').value));
      if (timeZone) {
        return timeZone.shortName;
      }
    }
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get dateTimePickerDisabled(): boolean {
    return this.readOnly || this.eventStatus === EventStatus.Live;
  }

  public get predesignedTemplate(): boolean {
    return this.tms.templateType !== TemplateType.Layout;
  }

  public onFocusOut(form: FormGroup): void {
    this.tms.emitSave();
  }

  public onCountryChanged({value}: MatSelectChange): void {
    this.countryStateService.setCountryById(+value);
  }

  private formatTelNumber(telNumber: string): string {
    telNumber = telNumber.toString()
    return `(${telNumber.slice(0,3)}) ${telNumber.slice(3,6)}-${telNumber.slice(6)}`
  }
}
