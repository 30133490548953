import {
  AfterViewChecked,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { BaseFormComponent } from '../base.form.component';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import { PaymentMethodType } from '../../../../../models/enum/payment.method.type';
import { AvailablePaymentMethods, PassMerchantFee } from '../../../../../models/form-builder/fb.template.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PaymentServiceType } from '../../../../../models/enum/payment.service.type';
import { CornerstoneService } from '../../../cornerstone.service';
import EventModel from '../../../../../models/event/event.model';
import { CampaignModel } from '../../../../../models/campaigns/campaign.model';
import SocialPostModel from '../../../../../models/socialMedia/social.post.model';
import { ClientPaymentModel } from '../../../../../models/client/client.payment.model';
import { FeeAndTaxes } from '../../../../../models/enum/fee-and-taxes.enum';
import { BehaviorSubject, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { filter, first, tap, throttleTime } from 'rxjs/operators';
import { UtilsComponent } from '../../../../utils.component';
import { StripeCardNumberComponent } from 'ngx-stripe';

import { AppStripeService } from '../../../app.stripe.service';
import { Router } from '@angular/router';
import { DonationPaymentPageType } from '../../../../../models/form-builder/donation.payment.page.type';
import { TranslateService } from '@ngx-translate/core';
import {UpdateDataService} from "../../../../../services/update.data/update.data.service";
import { MatDialog } from '@angular/material/dialog';
import { StripeAchInstantVerificationInfoDialogComponent } from 'src/app/components/stripe-ach-instant-verification-info-dialog/stripe-ach-instant-verification-info-dialog.component';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['../base.form.scss'],
})
export class PaymentDetailsComponent extends BaseFormComponent implements OnInit, OnDestroy, AfterViewChecked, OnChanges {
  @Input() public paymentServiceType: PaymentServiceType = PaymentServiceType.Cornerstone;
  @Input() public achForm: FormGroup;
  @Input() public cornerstoneService: CornerstoneService;
  @Input() public event: EventModel;
  @Input() public entity: EventModel | CampaignModel | SocialPostModel | ClientPaymentModel;
  @Input() public paymentProcessStarted: boolean = false;
  @Input() public pledgeEditPaymentDetails: boolean = false;
  @Input() public cardToken: BehaviorSubject<string>;
  @Input() public ammount: number;
  @Input() public noDonations: boolean;

  @Input() public updatePaymentMethod$: Subject<AvailablePaymentMethods> = new Subject<AvailablePaymentMethods>();
  @Input() public libraryVersion: boolean = false;
  @Input() public includeFee$: Observable<boolean>;
  @Input() public appStripeService: AppStripeService;
  @Input() private isStripeACHEnabled: boolean;
  @Output() public showError: EventEmitter<void> = new EventEmitter<void>();

  public paymentMethodOptions: FormElementDataModel[] = [
    {label: 'Credit Card', value: PaymentMethodType.CreditCard},
    {label: 'ACH', value: PaymentMethodType.ACH}
  ];
  public AvailablePaymentMethods = AvailablePaymentMethods;
  public PaymentMethodType = PaymentMethodType;
  public PaymentServiceType = PaymentServiceType;
  public FeeAndTaxes = FeeAndTaxes;

  private baseCardConnectUrl = window.location.origin === 'https://app.theauxilia.com' ? 'https://fts.cardconnect.com' : 'https://fts-uat.cardconnect.com';
  public cardConnectSrc: string;

  public cardConnectError: string = '';

  public subscription: Subscription = new Subscription();

  public isRegisterPage: boolean = false;

  public mockPaymentForm: FormGroup = this.formBuilder.group({
    cc: null,
    expMonth: null,
    expYear: null,
    cvv: null,
  });

  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;

  @HostListener('window:message', ['$event'])
  public cardConnectListener(event: MessageEvent): void {
    if (event && event.origin === this.baseCardConnectUrl && event.data && typeof event.data === 'string') {
      const data = JSON.parse(event.data);
      if (data && data.token) {
        this.cardToken.next(data.token);
      }
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public translate: TranslateService,
    private updateDataService: UpdateDataService,
    private dialog: MatDialog
  ) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.entity?.currentValue) {
      this.setIsDefaultFee();
    }
  }

  public ngOnInit(): void {
    this.isRegisterPage = this.router.url.includes('registrationEvent');
    this.subscription.add(
      fromEvent(window, 'message')
        .pipe(
          filter((event: MessageEvent) => event && event.origin === this.baseCardConnectUrl && event.data && typeof event.data === 'string'),
          throttleTime(500)
        )
        .subscribe((event: MessageEvent) => {
          const data = JSON.parse(event.data);
          if (data.errorCode !== '0') {
            this.cardToken.next(null);
          }
          this.setError(data.errorCode);
        })
    );
    this.subscription.add(
      this.updatePaymentMethod$.asObservable()
        .pipe(
          filter((value: AvailablePaymentMethods) => value !== AvailablePaymentMethods.All)
        )
        .subscribe((value: AvailablePaymentMethods) => {
          const paymentType = value === AvailablePaymentMethods.CreditCard ? PaymentMethodType.CreditCard : PaymentMethodType.ACH;
          this.paymentType.setValue(paymentType);
        })
    );
    this.setIsDefaultFee();
    this.cardConnectSrc = encodeURI(`${this.baseCardConnectUrl}/itoke/ajax-tokenizer.html?enhancedresponse=true&placeholder=0000 0000 0000 0000&placeholdercvv=0000&invalidinputevent=true&cardinputmaxlength=19&cardnumbernumericonly=true&formatinput=true&useexpiry=true&usecvv=true&css=${this.getCardConnectCss()}`);
    this.subscription.add(
      this.updateDataService.isIncludeFeeByDefault$.subscribe((res) => {
        this.includeFee.setValue(res);
      })
    )
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngAfterViewChecked(): void {
    if (this.card) {
      this.appStripeService.setCard(this.card);
    }
  }

  public get paymentType(): FormControl {
    return this.paymentForm.get('paymentType') as FormControl;
  }

  public get includeEventFee(): FormControl {
    return this.paymentForm.get('includeEventFee') as FormControl;
  }

  public get includeFee(): FormControl {
    return this.paymentForm.get('includeFee') as FormControl;
  }

  private setError(errorCode: string): void {
    switch (errorCode) {
      case '1004':
      case '1001':
        this.cardConnectError = this.translate.instant('Invalid Card Number');
        this.showError.emit();
        break;
      case '1006':
      case '1003':
        this.cardConnectError = this.translate.instant('Invalid Expiry Date');
        this.showError.emit();
        break;
      case '1005':
      case '1002':
        this.cardConnectError = this.translate.instant('Invalid CVV');
        this.showError.emit();
        break;
      case '0':
        this.cardConnectError = '';
        break;
    }
  }

  private setIsDefaultFee(): void {
    if (this.entity?.donationPaymentPage === DonationPaymentPageType.AuxiliaDefault || this.entity?.donationPaymentPage === DonationPaymentPageType.ClientDefault) {
      if(this.entity.feeAndTaxes === FeeAndTaxes.PassAutomatically) {
        this.includeFee.setValue(true);
      } else {
        this.includeFee.setValue(this.entity.isPassingFeeDefault);
      }
    } else if (this.includeFee$) {
        this.includeFee$
            .pipe(
                first(),
                tap((isPassingFeeDefault: boolean) => {
                  console.log(isPassingFeeDefault)
                    this.includeFee.setValue(isPassingFeeDefault)
                }),
            )
            .subscribe();
    }
    else {
        const value = JSON.parse(this.element.attributes.isPassingFeeDefault);
        this.includeFee.setValue(value);
    }
  }

  public get subLabelStyle(): {[key: string]: string} {
    const {fontSize, fontFamily = 'Roboto', fontColor = '#2C3345'} = this.template;
    return {
      color: fontColor,
      fontFamily,
      fontSize: fontSize ? `${+fontSize * 0.75}px` : '12px',
    };
  }

  public get cardConnectSubLabelStyle(): {[key: string]: string} {
  const {fontSize, fontColor = '#2C3345'} = this.template;
  return {
    color: fontColor,
    fontFamily: 'sans-serif',
    fontSize: fontSize ? `${+fontSize * 0.75}px` : '12px',
  };
}

  public get containerStyle(): {[key: string]: string} {
    return {
      'padding-bottom': `${this.template.spacing || 10}px`
    };
  }

  public get isPassMerchantFeeAutomatically(): boolean {
    return this.element.attributes.passMerchantFee === PassMerchantFee.No && !JSON.parse(this.element.attributes.doesClientCoverFee ?? 'false');
  }

  private getCardConnectCss(): string {
    const { fontSize = 16, inputBackground = '#ffffff', fontFamily = 'Roboto', fontColor = '#2C3345' } = this.template;
    return `
      body{
        margin: 0;
      }
      .error{
        border: 2px solid rgba(244, 67, 54, 1);
      }
       input{
        border: 1px solid rgba(0, 0, 0, 0.32);
        width: 370px;
        height: 45px;
        border-radius: 4px;
        margin: 12px 0 20px 0;
        box-sizing: border-box;
        padding: 0 10px;
        color: rgba(0, 100, 190, 1);
        font-size: 16px;
        background-color: ${UtilsComponent.hexToRgbA(inputBackground)};
      }
      input:hover{
        border: 2px solid rgba(0, 0, 0, 0.32);
      }
      select{
        border: 1px solid rgba(0, 0, 0, 0.32);
        width: 170px;
        height: 45px;
        border-radius: 4px;
        margin: 12px 0 20px 0;
        box-sizing: border-box;
        padding: 0 10px;
        color: rgba(0, 100, 190, 1);
        font-size: 16px;
        background-color: ${UtilsComponent.hexToRgbA(inputBackground)};
      }
      select:hover{
        border: 2px solid rgba(0, 0, 0, 0.32);
      }
      label{
        font-size: ${+fontSize * 0.75}px;
        color: ${UtilsComponent.hexToRgbA(fontColor)};
        font-family: ${fontFamily};
      }`;
  }

  get showFeeOption(): boolean {
    if(this.entity?.donationPaymentPage === DonationPaymentPageType.AuxiliaDefault || this.entity?.donationPaymentPage === DonationPaymentPageType.ClientDefault) {
      return this.entity.feeAndTaxes === FeeAndTaxes.ProvideOption;
    } else {
      return this.isPassMerchantFeeAutomatically
    }
  }

  get showPaymentMethodRadio(): boolean {
    switch(this.paymentServiceType) {
      case PaymentServiceType.Cornerstone:
        return this.element.attributes['paymentMethods'] === AvailablePaymentMethods.All;
      case PaymentServiceType.Stripe:
        return this.isStripeACHEnabled;
      default: return false;
    }
  }

  public onPaymentMethodChange(paymentMethod: PaymentMethodType): void {
    if(this.paymentServiceType === PaymentServiceType.Stripe && paymentMethod === PaymentMethodType.ACH) {
      this.dialog.open(StripeAchInstantVerificationInfoDialogComponent)
    }
  }
}
