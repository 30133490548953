import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { AUTOPLAY_VIDEO, FONTS } from '../../../../constants';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { MjmlCustomType } from '../../../../models/templates/mjml.custom.type';
import { TemplateType } from '../../../../models/templates/template.type';
import {TranslateService} from '@ngx-translate/core';
import { ActiveElementStateModel } from 'src/app/services/templates/activeElementState.model';

@Component({
  selector: 'app-donation-activity-settings',
  templateUrl: './donation-activity-settings.component.html',
  styleUrls: ['../tool-settings.scss']
})
export class DonationActivitySettingsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() public tms: TemplateManagementService;

  public form: FormGroup = this.formBuilder.group({
    fontFamily: 'Roboto',
    fontSize: '',
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]],
    donateButtonText: [this.translate.instant('BUILD.Donate Now'), [Validators.required]],
    showDonateNowButton: true,
    showDonationAmount: true,
    donateButtonColor: '#0064BE',
    donateButtonColorText: '#FFFFFF',
    viewAllBorderColor : '#0064BE',
    viewAllTextColor : '#0064BE',
    topDonationsBorderColor : '#0064BE',
    topDonationsTextColor : '#0064BE'
  });
  public fontFamilyOptions: FormElementDataModel[] = FONTS;
  public radioOptions: FormElementDataModel[] = AUTOPLAY_VIDEO;

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) { }

  public ngOnInit(): void {
    this.setInitialValues();

    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (!element || element.tagName !== MjmlTag.text || element.attributes['customType'] !== MjmlCustomType.donationActivity) {
          return;
        }
        this.setInitialValues();
      })
    );

    this.subscription.add(
      this.form.statusChanges.subscribe(() => {
        const isFormValid = this.form.valid;
        const elementState: ActiveElementStateModel = { element: this.tms.activeElement, isValid: isFormValid}
        this.tms.setActiveElementState(elementState);
      })
    )
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setInitialValues(): void {
    const attributes = this.tms.activeElement.attributes;
    const fontFamily = attributes['font-family'] || 'Roboto';
    const fontSize = attributes['font-size'] || '14px';
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    const showDonateNowButton = attributes['show-donate-now-button'] ? JSON.parse(attributes['show-donate-now-button']) : true;
    const showDonationAmount = attributes['show-donation-amount'] ? JSON.parse(attributes['show-donation-amount']) : true;
    const donateButtonText = attributes['donate-button-text'] ? attributes['donate-button-text'] : this.translate.instant('BUILD.Donate Now');
    const donateButtonColor = attributes['donate-button-color'] ? attributes['donate-button-color'] : '#0064BE';
    const donateButtonColorText = attributes['donate-button-color-text'] ? attributes['donate-button-color-text'] : '#FFFFFF';
    const viewAllBorderColor = attributes['view-all-border-color'] ? attributes['view-all-border-color'] : '#0064BE';
    const viewAllTextColor = attributes['view-all-text-color'] ? attributes['view-all-text-color'] : '#0064BE';
    const topDonationsBorderColor = attributes['top-donations-border-color'] ? attributes['top-donations-border-color'] : '#0064BE';
    const topDonationsTextColor = attributes['top-donations-text-color'] ? attributes['top-donations-text-color'] : '#0064BE';

    this.form.get('fontFamily').setValue(fontFamily);
    this.form.get('fontSize').setValue(fontSize.slice(0, -2));
    this.form.get('paddingBottom').setValue(paddingBottom.slice(0, -2));
    this.form.get('paddingLeft').setValue(paddingLeft.slice(0, -2));
    this.form.get('paddingRight').setValue(paddingRight.slice(0, -2));
    this.form.get('paddingTop').setValue(paddingTop.slice(0, -2));
    this.form.get('showDonateNowButton').setValue(showDonateNowButton);
    this.form.get('showDonationAmount').setValue(showDonationAmount);
    this.form.get('donateButtonText').setValue(donateButtonText);
    this.form.get('donateButtonColor').setValue(donateButtonColor);
    this.form.get('donateButtonColorText').setValue(donateButtonColorText);
    this.form.get('viewAllBorderColor').setValue(viewAllBorderColor);
    this.form.get('viewAllTextColor').setValue(viewAllTextColor);
    this.form.get('topDonationsBorderColor').setValue(topDonationsBorderColor);
    this.form.get('topDonationsTextColor').setValue(topDonationsTextColor);
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public onFontFamilyChanged(): void {
    const value = this.form.get('fontFamily').value;
    this.tms.activeElement.attributes['font-family'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onFontSizeChanged(): void {
    const value = this.form.get('fontSize').value;
    this.tms.activeElement.attributes['font-size'] = value ? `${value}px` : '8px';
    this.tms.emitSave(true);
  }

  public onColorAndTextValueChanged(formControlName: string, attrName: string): void {
    const value = this.form.get(formControlName).value;
    this.tms.activeElement.attributes[attrName] = value.toString();
    this.tms.emitSave(true);
  }

  public onShowDonateNowButtonChanged(): void {
    const value = this.form.get('showDonateNowButton').value;
    this.tms.activeElement.attributes['show-donate-now-button'] = value.toString();
    //todo more logic
    this.tms.emitSave(true);
  }

  public onPaddingValueChanged(formControlName: string, attrName: string): void {
    const value = this.form.get(formControlName).value;
    this.tms.activeElement.attributes[attrName] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

}
