<div
  class="element-renderer-wrapper"
  [ngStyle]="elementStyle"
  [ngSwitch]="element.type"
>
  <app-address
    *ngSwitchCase="FbElementType.Address"
    [element]="element"
    [updateAddress$]="fbss.updateAddress$"
    [paymentForm]="fbss.secondPaymentForm"
    [template]="fbss.template$ | async"
    [stateOptions]="stateOptions$ | async"
    [countries]="countries"
    [phonePrefix$]="fbss.phonePrefix$"
    [phoneMask$]="fbss.phoneMask$"
    [phonePlaceholder$]="fbss.phonePlaceholder$"
  ></app-address>

  <app-comment
    *ngSwitchCase="FbElementType.Comments"
    [element]="element"
    [paymentForm]="fbss.secondPaymentForm"
    [template]="fbss.template$ | async"
  ></app-comment>

  <app-email
    *ngSwitchCase="FbElementType.Email"
    [element]="element"
    [paymentForm]="fbss.secondPaymentForm"
    [template]="fbss.template$ | async"
  ></app-email>

  <app-full-name
    *ngSwitchCase="FbElementType.FullName"
    [element]="element"
    [paymentForm]="fbss.secondPaymentForm"
    [template]="fbss.template$ | async"
  ></app-full-name>

  <app-header
    *ngSwitchCase="FbElementType.Header"
    [element]="element"
    [paymentForm]="fbss.secondPaymentForm"
    [template]="fbss.template$ | async"
    [updateHeader$]="fbss.updateHeader$"
    [fbss]="fbss"
    [libraryVersion]="true"
  ></app-header>

  <app-payment-details
    *ngSwitchCase="FbElementType.PaymentDetails"
    [element]="element"
    [paymentForm]="fbss.secondPaymentForm"
    [template]="fbss.template$ | async"
    [paymentServiceType]="fbss.paymentServiceType$ | async"
    [achForm]="fbss.achForm"
    [cornerstoneService]="cornerstoneService"
    [entity]="fbss.entity$ | async"
    [updatePaymentMethod$]="fbss.updatePaymentMethod$"
    [libraryVersion]="true"
    [appStripeService]="appStripeService"
    [isStripeACHEnabled]="fbss.client$.getValue()?.isStripeACHEnabled"
  ></app-payment-details>

  <app-phone
    *ngSwitchCase="FbElementType.Phone"
    [element]="element"
    [paymentForm]="fbss.secondPaymentForm"
    [template]="fbss.template$ | async"
    [phonePrefix$]="fbss.phonePrefix$"
    [phoneMask$]="fbss.phoneMask$"
    [phonePlaceholder$]="fbss.phonePlaceholder$"
  ></app-phone>

  <ng-container *ngIf="(fbss.client$ | async)?.clientType === ClientType.PAC" >
    <app-occupation
      *ngSwitchCase="FbElementType.Occupation"
      [element]="element"
      [paymentForm]="fbss.employerGroup"
      [template]="fbss.template$ | async"
    ></app-occupation>
    <app-employer-full-name
      *ngSwitchCase="FbElementType.EmployerName"
      [element]="element"
      [paymentForm]="fbss.employerGroup"
      [template]="fbss.template$ | async"
    ></app-employer-full-name>
    <app-address
      *ngSwitchCase="FbElementType.EmployerMailingAddress"
      [element]="element"
      [updateAddress$]="fbss.updateAddress$"
      [paymentForm]="fbss.employerGroup"
      [template]="fbss.template$ | async"
      [stateOptions]="stateOptions$ | async"
      [countries]="countries"
      [isEmployerAddress]="true"
    ></app-address>
    <app-pac
      *ngSwitchCase="FbElementType.PAC"
      [element]="element"
      [paymentForm]="fbss.secondPaymentForm"
      [template]="fbss.template$ | async"
    ></app-pac>
  </ng-container>
</div>
