<div class="email-builder-tool-settings-wrapper">
  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-select
        label="Platforms"
        [formGroup]="smForm"
        name="smNames"
        errorMassage="Platforms"
        [low]="true"
        [multiSelectOptions]="platformsOptions"
        [disabled]="readOnly"
        (valueChanged)="onSmNamesChanged()"
      ></app-select>
    </div>
  </div>

  <div
    *ngFor="let element of socialElements?.controls; index as i"
    class="platform-container"
  >
    <div class="email-builder-tool-settings-row">
      <span class="platform-title">{{getPlatformName(element)}}</span>
    </div>

    <div class="email-builder-tool-settings-row">
      <div class="max-width-310">
        <app-input
          label="URL"
          [formGroup]="element"
          name="href"
          [low]="true"
          errorMassage="URL"
          (valueChanged)="updateElementModel($event, element, true)"
          [disabled]="readOnly"
        ></app-input>
      </div>
    </div>

    <div class="email-builder-tool-settings-row">
      <div class="max-width-145 right-margin">
        <app-email-color-picker
          [formGroup]="element"
          label="Icon color"
          name="backgroundColor"
          [readOnly]="readOnly || isPredesignedThemedTemplate"
          (colorChanged)="updateElementModel($event, element)"
        ></app-email-color-picker>
      </div>
      <div class="max-width-145">
        <app-input
          label="Title"
          [formGroup]="element"
          name="text"
          [low]="true"
          errorMassage="Title"
          (valueChanged)="updateElementModel($event, element)"
          [disabled]="readOnly || isPredesignedThemedTemplate"
        ></app-input>
      </div>
    </div>

    <div class="email-builder-tool-settings-row">
      <div class="max-width-310">
        <app-input
          label="Tooltip"
          [formGroup]="element"
          name="title"
          [low]="true"
          errorMassage="Tooltip"
          (valueChanged)="updateElementModel($event, element)"
          [disabled]="readOnly"
        ></app-input>
      </div>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Mode"
        [formGroup]="smForm"
        name="mode"
        errorMassage="Mode"
        [low]="true"
        [selectOptions]="modeOptions"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onModeChanged()"
      ></app-select>
    </div>
    <div class="max-width-145">
      <app-select
        label="Position"
        [formGroup]="smForm"
        name="align"
        errorMassage="Position"
        [low]="true"
        [selectOptions]="alignOptions"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onAlignChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Font Family"
        [formGroup]="smForm"
        name="fontFamily"
        errorMassage="Font Family"
        [low]="true"
        [selectOptions]="fontFamilyOptions"
        [disabled]="readOnly"
        (valueChanged)="onFontFamilyChanged()"
      ></app-select>
      <div
        *ngIf="tms.templateAssignmentType === TemplateAssignmentType.Campaign"
        class="settings-info-icon"
        [matTooltip]="toolTipText"
        matTooltipHideDelay="200"
        matTooltipShowDelay="200"
        matTooltipPosition="above"
        matTooltipClass="settings-tooltip-custom-class"
      >
        <i class="fas fa-info-circle"></i>
      </div>
    </div>
    <div class="max-width-145">
      <app-select
        label="Font Size"
        [formGroup]="smForm"
        name="fontSize"
        errorMassage="Font Size"
        [low]="true"
        [selectOptions]="fontSizeOptions"
        [disabled]="readOnly"
        (valueChanged)="onFontSizeChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Font Style"
        [formGroup]="smForm"
        name="fontStyle"
        errorMassage="Font Style"
        [low]="true"
        [selectOptions]="fontStyleOptions"
        [disabled]="readOnly"
        (valueChanged)="onFontStyleChanged()"
      ></app-select>
    </div>
    <div class="max-width-145">
      <app-select
        label="Font Weight"
        [formGroup]="smForm"
        name="fontWeight"
        errorMassage="Font Weight"
        [low]="true"
        [selectOptions]="fontWeightOptions"
        [disabled]="readOnly"
        (valueChanged)="onFontWeightChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="smForm"
        label="Text Color"
        name="color"
        [readOnly]="readOnly"
        (colorChanged)="onColorChanged()"
      ></app-email-color-picker>
    </div>
    <div class="max-width-145">
      <app-select
        label="Decoration"
        [formGroup]="smForm"
        name="textDecoration"
        errorMassage="Decoration"
        [low]="true"
        [selectOptions]="textDecorationOptions"
        [disabled]="readOnly"
        (valueChanged)="onTextDecorationChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="smForm"
        label="Background color"
        name="containerBackgroundColor"
        [readOnly]="readOnly || isPredesignedThemedTemplate"
        (colorChanged)="onContainerBackgroundColorChanged()"
      ></app-email-color-picker>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="smForm"
        name="iconSize"
        label="Icon size"
        [min]="10"
        [max]="100"
        [step]="5"
        [tickInterval]="5"
        (valueChanged)="onIconSizeChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="smForm"
        name="innerPadding"
        label="Inner padding"
        [min]="0"
        [max]="25"
        [step]="1"
        [tickInterval]="5"
        (valueChanged)="onInnerPaddingChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="smForm"
        name="borderRadius"
        label="Border radius"
        [min]="0"
        [max]="50"
        [step]="1"
        [tickInterval]="5"
        (valueChanged)="onBorderRadiusChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="smForm"
        name="paddingTop"
        label="Padding Top"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingTopChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="smForm"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingRightChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="smForm"
        name="paddingBottom"
        label="Padding Bottom"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingBottomChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="smForm"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingLeftChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row" *ngIf="!(readOnly || isPredesignedThemedTemplate)">
    <app-button
      type="basic"
      label="Delete content"
      (clickEvent)="tms.removeActiveElementFromColumn()"
      color="#f44336"
      width="300"
    ></app-button>
  </div>


</div>
