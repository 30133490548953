export interface EventDonationActivityModel {
  id?: string;
  eventId?: string;
  campaignId?: string;
  socialPostId?: string;
  donationAmmount?: number;
  donorFullName?: string;
  isAnonymous?: boolean;
  createdTimeAgo?: number;
  createdTimeAgoType?: CreatedTimeAgoType;
  createdTimeAgoString?: string;
}

export enum CreatedTimeAgoType {
  Minute = 1,
  Hour = 2,
  Day = 3,
  Week = 4,
  Month = 5,
  Year = 6
}
