import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilderStateService } from '../../../form-builder.state.service';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import { LABEL_ALIGN } from '../../../../../constants';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AlignmentType, FbElementModel, FbElementType } from '../../../../../models/form-builder/fb.template.model';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pac-options',
  templateUrl: './pac-options.component.html',
  styleUrls: ['../../fb.page.scss']
})
export class PacOptionsComponent implements OnInit, OnDestroy {
  @Input() public fbss: FormBuilderStateService;

  public labelAlignmentOptions: FormElementDataModel[] = LABEL_ALIGN;
  public optionsForm: FormGroup = this.formBuilder.group({
    mainLabel: '',
    mainLabelAlignment: null,
    isFederalIDRequired: false
  });

  public isSetAsFormDefault: FormControl = this.formBuilder.control(false);

  private element: FbElementModel;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    public translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.fbss.activeElementSettings$.asObservable()
        .pipe(
          filter(value => value === FbElementType.PAC),
          tap(() => {
            this.element = this.fbss.template$.getValue().paymentPage.elements
              .find(({type}: FbElementModel) => type === FbElementType.PAC);
            this.setInitialValues();
          }),
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setInitialValues(): void {
    const {
      mainLabel = this.translate.instant('Please select the option if you are an Individual or Organization:'),
      mainLabelAlignment = AlignmentType.Top,
    }: {[key: string]: string} = this.element.attributes;
    this.control('mainLabel').setValue(mainLabel);
    this.control('mainLabelAlignment').setValue(mainLabelAlignment);
    this.control('isFederalIDRequired').setValue(this.element.federalIDRequired)
  }

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }

  public onLabelAlignmentChanged(): void {
    this.element.attributes.mainLabelAlignment = this.control('mainLabelAlignment').value;
    this.isSetAsFormDefault.value && this.setAsFormDefault();
    this.fbss.templateWasChanged();
  }

  public onIsSetAsFormDefaultChanged(): void {
    const next = !this.isSetAsFormDefault.value;
    this.isSetAsFormDefault.setValue(next);
    if (next) {
      this.setAsFormDefault();
      this.fbss.templateWasChanged();
    }
  }
  public onIsFederalIDRequired() {
    const next = !this.control('isFederalIDRequired').value;
    this.control('isFederalIDRequired').setValue(next);
    this.element.federalIDRequired = next;
    this.fbss.templateWasChanged();
  }

  public setAsFormDefault(): void {
    this.fbss.setAsFormDefault(this.control('mainLabelAlignment').value);
  }

  public control(controlName: string): FormControl {
    return this.optionsForm.get(controlName) as FormControl;
  }

  public onControlValueChanged(controlName: string): void {
    this.element.attributes[controlName] = this.control(controlName).value;
    this.fbss.templateWasChanged();
  }
}
