<div class="create-tag-wrapper">
  <div class="header">
    <span class="title" *ngIf="data.title">{{(data.title | translate)}}</span>
    <div class="close">
      <i class="fas fa-times close-icon" (click)="onClose()" matRipple></i>
    </div>
  </div>

  <div class="main-wrapper">
    <div class="input-wrapper">
      <input type="search" placeholder="{{'DONORS.Search by Tag Name' | translate}}" (input)="onEventSearch($event)">
      <i class="fas fa-search magnifier"></i>
    </div>
    <div class="tags-wrapper-donor-popup" >
      <ng-container *ngIf="tags$ | async as tags">
        <ng-container *ngIf="tags.length; else noTags">
          <div class="tags" *ngFor="let tag of tags; index as i">
          <span class="tag-checkbox-donor-popup">
              <i class="far fa-square" *ngIf="!selection.has(tag.id)" (click)="checkBoxClick(tag.id)"></i>
              <i class="fas fa-check-square" *ngIf="selection.has(tag.id)" (click)="checkBoxClick(tag.id)"></i>
          </span>
            <span class="tag-name-donor-popup">{{tag.name}}</span>
          </div>
        </ng-container>
        <ng-template #noTags>
          <div class="noTags">
            <span>There are no Tags created</span>
          </div>
        </ng-template>
      </ng-container>
    </div>
    <ng-template #emptyContainer>
      <div class="tags-wrapper"></div>
    </ng-template>
    <div class="action-wrapper">
      <app-button
        label="Clear All"
        type="stroked"
        (clickEvent)="clearAll()"
        width="100"
        color="#6A6A6A"
        [buttonColorType]="'warn'"
        [isNew]="true"
        [disabled]="(inProgress$ | async) || selection.size === 0"
      ></app-button>
      <app-button
        label="Add"
        class="add-btn"
        type="flat"
        (clickEvent)="add()"
        width="100"
        [buttonColorType]="'primary'"
        [isNew]="true"
        [disabled]="(inProgress$ | async) || selection.size === 0"
      ></app-button>
    </div>

  </div>


</div>

