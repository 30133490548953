import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { LazySelectComponent } from '../lazy-select/lazy-select.component';
import FormElementDataModel from '../../models/form.element.data.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-lazy-single-select',
  templateUrl: './lazy-single-select.component.html',
  styleUrls: ['./lazy-single-select.component.scss', '../lazy-select/lazy-select.component.scss']
})
export class LazySingleSelectComponent extends LazySelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public singleSelection: SelectionModel<string> = new SelectionModel<string>(false, []);
  @Input() public reset: Observable<void> = new Observable<void>();
  @Output() public singleSelectedValue: EventEmitter<FormElementDataModel> = new EventEmitter<FormElementDataModel>();

  constructor() {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.reset.subscribe(() => this.onFilterChanged())
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public toggleItem(item: FormElementDataModel): void {
    this.singleSelection.toggle(item.value);
    if (this.singleSelection.hasValue()) {
      const selectedItem = this.options.find(option => option.value === this.singleSelection.selected[0]);
      this.singleSelectedValue.emit(selectedItem);
      this.menu.closeMenu();
    }
  }

}
