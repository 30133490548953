import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../models/app.config';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ConfigService {
  public readonly onConfigLoaded: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  private readonly controller = '/config';
  constructor(private client: HttpClient) {}

  public loadConfig(): Observable<AppConfig> {
    if (!this.config) {
      this.config = environment;
      return of(environment);
    } else {
      return of(this.config);
    }
  }

  private get config(): AppConfig {
    const storageConfigStr = sessionStorage.getItem('app-config-cache');
    const storageConfig: AppConfig = JSON.parse(storageConfigStr);

    return storageConfig;
  }

  private set config(conf: AppConfig) {
    sessionStorage.setItem('app-config-cache', JSON.stringify(conf));
  }
}
