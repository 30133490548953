import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormElementComponent } from '../base.form.element.component';

@Component({
  selector: 'app-email-list-textarea',
  templateUrl: './email-list-textarea.component.html',
  styleUrls: ['../base.form.element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailListTextareaComponent extends BaseFormElementComponent implements OnChanges {
  @Input()
  public minRows: number = 1;
  @Input()
  public maxRows: number = 5;

  public currentControlValue: string;

  constructor(public translate: TranslateService) {
    super(translate)
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.name && changes.name.currentValue) {
      this.currentControlValue = this.formControl.value;
    }
  }

  public onEnter(): void {
    if (this.currentControlValue.endsWith(' ') || this.currentControlValue.endsWith(';')) {
      return;
    }
    this.formControl.setValue(this.currentControlValue + '; ');
    this.formControl.markAsUntouched();
  }

  public onInput(event): void {
    this.currentControlValue = event.target.value;
  }

  public onBlur(): void {
    let value = this.formControl.value;
    if (value) {
      value = value.toString().trim();
    }
    if (value.slice(-1) === ';') {
      value = value.slice(0, -1);
    }
    this.formControl.patchValue(value);
  }

}
