<div class="mat-slider-container" [formGroup]="formGroup">
  <span class="mat-slider-container-title">{{label | translate}}</span>
  <mat-slider
    [max]="max"
    [min]="min"
    [step]="step"
    [thumbLabel]="true"
    [tickInterval]="tickInterval"
    color="primary"
    [formControlName]="name"
    (input)="emitSliderValue($event)"
    [disabled]="disabled"
  ></mat-slider>
</div>
