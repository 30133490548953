<!-- <p>
  Selected Bank: {{ctrl?.value}}
</p> -->

<div
  [ngClass]="low ? 'low-custom-form-component-container-wrapper' : 'custom-form-component-container-wrapper'"
>
  <mat-form-field appearance="outline">
    <mat-select
      #matSelectInfiniteScroll
      msInfiniteScroll
      (infiniteScroll)="getNextBatch()"
      [formControl]="ctrl"
      placeholder="{{'Choose Donor' | translate}}"
      (valueChange)="onChange($event)"
    >
      <mat-option>
        <!--disableScrollToActiveOnOptionsChanged should be set to true-->
        <ngx-mat-select-search
          [formControl]="searchCtrl"
          placeholderLabel="search"
          [disableScrollToActiveOnOptionsChanged]="true"
          noEntriesFoundLabel="{{('No entry matches' | translate)}}"
          [searching]="searching"
        >
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let option of options$ | async" [value]="option.value">
        {{option.label | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
