import { AuthService } from '../../../services/auth.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import FormElementDataModel from 'src/app/models/form.element.data.model';

@Component({
  selector: 'app-admin-confirmation',
  templateUrl: './admin-confirmation.component.html',
  styleUrls: ['./admin-confirmation.component.scss']
})
export class AdminConfirmationComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public isAdminPortal: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
    private router: Router,
    private dialogRef: MatDialogRef<AdminConfirmationComponent>
  ) { }

  public ngOnInit(): void {
    this.isAdminPortal = this.router.url.includes('/admin-dashboard/');
    this.subscriptions.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
  }

  public get imgStyle(): string {
    if (this.data.img) {
      return `center / cover no-repeat url(${this.data.img})`;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onYesClick(): void {
    this.dialogRef.close(true);
  }

  public onClose(): void {
    this.dialogRef.close(false);
  }

  public onCancel(): void {
    this.dialogRef.close(null);
  }
}

export interface DialogData {
  title: string;
  firstButtonName?: string;
  secondButtonName?: string;
  middleButtonName?: string;
  widthButton?: string;
  img?: string;
  formElementData?: FormElementDataModel[];
  isDonationForm?: boolean;
  noActions?: boolean;
  numberOfChanges?: number
  isDonorPortal?: boolean;
}
