import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';
import { BaseTableComponent } from '../base-table/base-table.component';
import EventModel from '../../models/event/event.model';
import FormElementDataModel from '../../models/form.element.data.model';
import { EventStatus } from '../../models/enum/event.status';
import { PriceTicket } from '../../models/enum/event.price.ticket';
import ColumnFilterOptionsModel from '../../models/filter-sort/column.filter.options.model';
import ColumnFilterModel from '../../models/filter-sort/column.filter.model';
import EventParticipantModel from '../../models/event/event.participant.model';
import { filter } from 'lodash';
import FilterSortModel from '../../models/filter-sort/filter.sort.model';
import { AuthService } from 'src/app/services/auth.service';
import { DONOR_MANAGER } from 'src/app/constants';
import { UserRolesType } from 'src/app/models/enum/user.roles.type';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-event-list-table',
  templateUrl: './event-list-table.component.html',
  styleUrls: ['./event-list-table.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class EventListTableComponent extends BaseTableComponent<EventModel> implements OnInit, OnChanges {
  @Input() public filterSortConfig: FilterSortModel;
  @Input() public draftsData: boolean = false;
  @Input() public columnsFilterOptions: ColumnFilterOptionsModel[] = [];
  @Input() public initialSelection: any;

  @Output() public navigateToEventDashboard: EventEmitter<EventModel> = new EventEmitter<EventModel>();
  @Output() public cancelArchiveEvent: EventEmitter<EventModel> = new EventEmitter<EventModel>();
  @Output() public filterChanged: EventEmitter<ColumnFilterModel> = new EventEmitter<ColumnFilterModel>();

  public isFullPermissionAvailable = false;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    const roles = this.authService.getIdentityClaimsRole();
    this.isFullPermissionAvailable = roles.includes(UserRolesType.CLIENT_ADMIN) || roles.includes(UserRolesType.FUNDRAISING_MANAGER) || roles.includes(UserRolesType.CONTENT_CREATOR);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public selectedOptions(event: ColumnFilterModel): void {
    this.filterChanged.emit(event);
  }

  public getColumnTitle(column): string {
    const columnsTitles = {
      createdOn: 'Created Date',
      name: 'Event Name',
      clientID: 'Organization',
      startDate: 'Start Date',
      endDate: 'End Date',
      createdByUserName: 'Role',
      status: 'Status',
      tickets: 'Tickets',
      registration: 'Registration',
      secondaryEventsList: 'Secondary Event(s)'
    };
    return this.translate.instant('EVENTS.' + columnsTitles[column]);
  }

  public onEditLinkClick(element: EventModel): void {
    this.editClicked.emit(element)
  }

  public toEventDashboard(element: EventModel): void {
    if (this.draftsData) {
      return;
    } else {
      this.navigateToEventDashboard.emit(element);
    }
  }

  public withFilter(column): boolean {
    return column === 'status' || column === 'clientID';
  }

  public getTextNode(element: EventModel, column: string): string {
    switch (column) {
      case 'createdOn':
        return element[column] ? formatDate(element[column], 'MM/dd/yyyy', 'en-US') : '';
      case 'startDate':
      case 'endDate':
        return element[column] ? formatDate(new Date(`${element[column]}`), 'MM/dd/yyyy', 'en-US') : '';
      case 'status':
        const status = {
          [EventStatus.Draft]: 'Draft',
          [EventStatus.Complete]: 'Complete',
          [EventStatus.Canceled]: 'Canceled',
          [EventStatus.Live]: 'Live',
          [EventStatus.Archived]: 'Archived'
        };
        return status[element.status];
      case 'tickets':
        if (element.priceTicketType !== PriceTicket.Ticket) {
          return 'N/A';
        }
        const {sold, amount}: any = element[column];
        return `${sold || "0"}/${amount}`;
      case 'registration':
        if(!element.hasRegistrationForm) return "N/A";
        return element[column]?.toString() || "0";
      case 'secondaryEventsList':
        const secondaryEvents: any = element[column].length ? element[column].length : 'None';
        return `${secondaryEvents}`;
      case 'clientID':
        const clients: ColumnFilterOptionsModel = this.columnsFilterOptions.find(({columnName}: ColumnFilterOptionsModel) => columnName === 'clientID');
        const option = clients ? clients.options.find((item: FormElementDataModel) => item.value === element.clientID) : null;
        return option ? option.label : '';
      default:
        return element[column];
    }
  }

  public getEditSeeDetailTextNode({status}: EventModel): string {
    return status === EventStatus.Draft || status === EventStatus.Live ? 'Edit' : 'See details';
  }

  public isCancelArchiveAvailable({status}: EventModel): boolean {
    return status === EventStatus.Live  || status === EventStatus.Canceled || status === EventStatus.Complete;
  }

  public onCancelArchiveEvent(element: EventModel): void {
    this.cancelArchiveEvent.emit(element);
  }

  public getCancelArchiveTextNode({status}: EventModel): string {
    return status === EventStatus.Live ? 'Cancel' : 'Archive';
  }

  public getSelectOptions(column: string): ColumnFilterOptionsModel {
    return this.columnsFilterOptions.find(
      options => options.columnName === column
    );
  }

  public isActiveFilter(column: string): boolean {
    if (!this.filterSortConfig) {
      return false;
    } else {
      const { columnFilters }: FilterSortModel = this.filterSortConfig;
      return !!columnFilters.find(({ field }: ColumnFilterModel) => field === column);
    }
  }
}
