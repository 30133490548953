<div class="widget-simple-wrapper">
  <div class="widget-simple-column">
    <div class="widget-simple-title-wrapper">
      <span class="widget-simple-title">{{'DASH.Active Campaigns' | translate | uppercase}}</span>
    </div>
    <div *ngIf="campaigns !== null; else shimmerTemplate" class="widget-simple-data-item">
      <span class="widget-simple-data-item-name">{{campaigns[eventTab - 1]?.name | titlecase}}</span>
      <span class="widget-simple-data-item-info">{{campaigns[eventTab - 1]?.createdOn | date}}</span>
      <span class="widget-simple-data-item-info">{{campaigns[eventTab - 1]?.campaignEmail.subject | titlecase}}</span>
    </div>
    <div *ngIf="campaigns !== null" class="widget-simple-tabs">
      <div
        *ngFor="let campaign of campaigns; index as i"
        class="widget-simple-tabs-item"
        [class.active]="eventTab === i + 1"
        (click)="eventTab = i + 1"
      ></div>
    </div>

  </div>
  <div class="widget-simple-column margin-left">
    <div class="widget-simple-icon">
      <img src="/assets/images/event_3.png" alt="widget-simple-icon">
    </div>
    <a routerLink="/clients/campaigns" class="widget-simple-link">
      <span>{{'View All' | translate}}</span>
      <div class="widget-simple-triangle-right"></div>
    </a>
  </div>
</div>
<ng-template #shimmerTemplate>
  <ngx-shimmer-loading width="200px" height="80px"></ngx-shimmer-loading>
</ng-template>
