import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import FilterSortModel from '../../../../../models/filter-sort/filter.sort.model';
import {
  ClientDonorTagManagerModel,
  TagManagerAssignTagModel
} from '../../../../../models/client/client.donor.tag.manager.model';
import {Paging} from '../../../../../models/paging/paging.model';
import {Filter} from '../../../../../models/paging/filter.model';
import {FilterType} from '../../../../../models/enum/filter.type';
import {AuthService} from '../../../../../services/auth.service';
import {DonorTagService} from '../../../../../services/donor/donor.tag.service';
import ColumnFilterModel from '../../../../../models/filter-sort/column.filter.model';
import { UtilsComponent } from '../../../../../components/utils.component';

@Injectable()
export class DonorsTagManagerService {
  public isAllSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public donorSelection: Set<string> = new Set<string>();
  public currentClientID: string = localStorage.getItem("currentSelectedClientID");
  public firstTimePagingModel: Paging = {
    filters: [{
      field: "clientID",
      filterType: FilterType.Equal,
      value: this.currentClientID
    }],
    first: 0,
    includeDeleted: false,
    includeDependencies: false,
    rows: 10,
    sortField: "createdOn",
    sortOrder: 1
  };
  public form: FormGroup = this.formBuilder.group({
    allChecked: [true],
    search: ''
  });
  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private tagDonorService: DonorTagService
  ) {}

  public getDonorData(batchId: string, config: FilterSortModel, firstItem: number, rows: number): Observable<ClientDonorTagManagerModel[]> {
    const paging: Paging = {
      first: firstItem,
      rows,
      includeDependencies: true,
      includeDeleted: false,
      filters: this.getFilter(config),
      sortField: config.sortField,
      sortOrder: config.sortOrder
    };
    return this.tagDonorService.getManageDonors(batchId, paging);
  }

  public getBatchID(): Observable<string> {
    const filter = JSON.parse(sessionStorage.getItem('tagManagerPaging'));
    if (filter) {
      this.firstTimePagingModel = {
        ...filter,
        filters: filter.filters.map(item => {
          item = {
            ...item,
            filterType: item.type
          };
          delete item.type;
          return item
        })
      };
    }

    const createBatchPaging: Paging = { ...this.firstTimePagingModel };

    const cachedSelectedDonors = JSON.parse(sessionStorage.getItem('donorsComponentSelectedIds'));
    sessionStorage.removeItem('donorsComponentSelectedIds');

    if (cachedSelectedDonors && cachedSelectedDonors.length > 0) {
      const values = UtilsComponent.getArrayQueryValue(Array.from(cachedSelectedDonors));
      createBatchPaging.filters = [
        ...createBatchPaging.filters,
        {
          field: "ID",
          filterType: FilterType.Equal,
          value: `array[${values}]`
        }
      ];
    }

    return this.tagDonorService.createDonorsBatchId(createBatchPaging)
  }

  public getDistinctValues(batchId: string, paging?: Paging): Observable<any> {
    if (paging) {
      paging.filters = [...paging.filters, ...this.getFilter({ sortField: paging.sortField, sortOrder: paging.sortOrder, columnFilters: [] })]
    }
    return this.tagDonorService.getDonorsDistinct(batchId, paging)
  }

  public getTotal(batchId: string, config: FilterSortModel): Observable<number> {
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: false,
      filters: this.getFilter(config),
    };
    return this.tagDonorService.getTotalDonors(batchId, paging);
  }

  public assignTagsToDonors(batchId: string, model: TagManagerAssignTagModel): Observable<TagManagerAssignTagModel> {
    return this.tagDonorService.assignTagsToDonors(batchId, model);
  }

  public deleteBatchId(batchId: string): void {
    this.tagDonorService.deleteBatchId(batchId).subscribe();
  }

  public checkDonorsAreLoaded(batchId: string): Observable<boolean> {
    return this.tagDonorService.checkDonorsAreLoaded(batchId);
  }

  private get searchFilter(): Filter {
    return {
      field: 'fullName',
      value: this.search.value,
      type: FilterType.Contains,
    }
  }

  private getFilter(config: FilterSortModel): Filter[] {
    const filters: Filter[] = [
    ];
    if (this.search.value) {
      filters.push(this.searchFilter);
    }
    if (!config.columnFilters.length) {
      return filters;
    } else {
      config.columnFilters.forEach((columnFilter: ColumnFilterModel) => {
        if (columnFilter.field !== 'donationsCount') {
          const values = columnFilter.values.map((item: string) => `"${item}"`).join(',');
          filters.push({
            field: columnFilter.field,
            value: `array[${values}]`,
            type: FilterType.Equal,
          });
        } else {
          filters.push({
            field: columnFilter.field,
            value: columnFilter.values[0],
            type: FilterType.GreaterThanOrEqual,
          });
          filters.push({
            field: columnFilter.field,
            value: columnFilter.values[1],
            type: FilterType.LessThanOrEqual,
          });
        }
      });
      return filters;
    }
  }

  public get search(): FormControl {
    return this.form.get('search') as FormControl;
  }

  public get allChecked(): FormControl {
    return this.form.get('allChecked') as FormControl;
  }
}
