<div
  class="add-or-remove-container"
  [ngClass]="{
    blueProfile: profileType === AddOrRemoveProfileTypeEnum.BlueProfile,
    greyProfile: profileType === AddOrRemoveProfileTypeEnum.GreyProfile,
    darkProfile: profileType === AddOrRemoveProfileTypeEnum.DarkProfile
    }"
>
  <span class="add-or-remove-container-text">
    {{innerText}}
  </span>
  <div class="add-or-remove-container-icon">
    <app-icons
      *ngIf="iconsType"
      [color]="color"
      [iconType]="iconsType"
      [width]="16"
      [height]="16"
      (click)="action()"
    >
    </app-icons>
  </div>
</div>
