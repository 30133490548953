import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BaseFormElementComponent } from '../base.form.element.component';
import { QuillEditorComponent } from 'ngx-quill';
import * as ace from 'ace-builds';
import * as beautify from 'js-beautify'
import { TranslateService } from '@ngx-translate/core';
import Quill from "quill";

/*Set Quill configuration in one place: TextToolComponent*/

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['../base.form.element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextEditorComponent extends BaseFormElementComponent implements OnChanges, AfterViewInit {
  @Input() public placeholders: string[] = [];
  @Input() public isTabOn: boolean = true;
  @Input() public isFontLimited: boolean = false;
  @Input() public isEditMode: boolean;

  public tab: number = 1;
  @Output() public cursorPositionOnFocus: EventEmitter<number> = new EventEmitter<number>();
  public editor: Quill;
  public editorInstance: Quill;

  @ViewChild(QuillEditorComponent) public quillEditor: QuillEditorComponent | any;
  @ViewChild('editor') private aceEditor: ElementRef<HTMLElement>;

  public modules = {
    placeholder: {
      delimiters: ['[', ']'],
      placeholders: [
        { id: 'DonorName', label: 'DonorName'},
		    { id: 'DonorFirstName', label: 'DonorFirstName'},
		    { id: 'DonorLastName', label: 'DonorLastName'},
        { id: 'Email', label: 'Email'},
        { id: 'Phone', label: 'Phone'},
        { id: 'DonateAmount', label: 'DonateAmount'},
        { id: 'Funds', label: 'Funds'},
        { id: 'FundsList', label: 'FundsList'},
        { id: 'PrimaryContact', label: 'PrimaryContact'},
        { id: 'OrgName', label: 'OrgName' },
        { id: 'OrganizationName', label: 'OrganizationName' },
        { id: 'Phone', label: 'Phone' },
        { id: 'PrimaryContact', label: 'PrimaryContact' },
        { id: 'TotalAmountDonated', label: 'TotalAmountDonated' },
        { id: 'YYYY', label: 'YYYY' },
        { id: 'Fax', label: 'Fax' },
        { id: 'Address', label: 'Address' },
        { id: 'StreetAddress', label: 'StreetAddress' },
        { id: 'StreetAddress2', label: 'StreetAddress2' },
        { id: 'ZipCode', label: 'ZipCode' },
        { id: 'Employer', label: 'Employer' },
        { id: 'City', label: 'City' },
        { id: 'State', label: 'State' },
        { id: 'DonationDate', label: 'DonationDate' },
        { id: 'LastDonationDate', label: 'LastDonationDate' },
        { id: 'LastDonationAmount', label: 'LastDonationAmount' },
        { id: 'GrossLifetimeDonationAmount', label: 'GrossLifetimeDonationAmount' },
        { id: 'ClientPrimaryContactName', label: 'ClientPrimaryContactName' },
        { id: 'NumberOfPeriods', label: 'NumberOfPeriods' },
        { id: 'Frequency', label: 'Frequency' },
        { id: 'DonationAmountWithOrWithoutFee', label: 'DonationAmountWithOrWithoutFee' },
        { id: 'GrossDonationAmount', label: 'GrossDonationAmount' },
        { id: 'YTDDonationAmount', label: 'YTDDonationAmount' },
        { id: 'ScheduledDonationDate', label: 'ScheduledDonationDate' },
        { id: 'NextDonationDate', label: 'NextDonationDate' },
        { id: 'EventName', label: 'EventName' },
      ]
    },
    clipboard: {
      allowed: {
        tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span'],
        attributes: ['href', 'rel', 'target'/*, 'class'*/],
      },
      keepSelection: false,
    },
  };
  constructor(public translate: TranslateService) {
    super(translate);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this.tab = changes.isEditMode?.currentValue ? 3 : 1;
  }

  public ngAfterViewInit(): void {
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    ace.config.set('fontSize', '14px');
    const aceEditor = ace.edit(this.aceEditor.nativeElement);
    ace.edit(this.aceEditor.nativeElement).session.setUseWrapMode(true);
    aceEditor.session.setValue(beautify.html(this.formControl.value));
    aceEditor.setTheme('ace/theme/textmate');
    aceEditor.session.setMode('ace/mode/html');
    aceEditor.on('blur', () => {
      const value = aceEditor.getValue().replace(/\n|\t/g, '');
      this.emitChanges(value);
      this.formControl.setValue(value);
    });

    aceEditor.setReadOnly(this.disabled);
  }

  public onFocusEditor(event): void {
    this.cursorPositionOnFocus.emit(event.editor.getSelection().index);
  }

  public onEditorCreated(editorInstance): void {
    this.quillEditor.elementRef.nativeElement.lastChild.firstChild.innerHTML = this.formControl.value;
    this.editor = editorInstance;
    this.editorInstance = editorInstance;

  }

  public setAceEditorValue(): void {
    ace.edit(this.aceEditor.nativeElement).session.setValue(beautify.html(this.formControl.value));
  }

  // Avoids bug where quill doesn't always render the html properly
  public getContent(): String {
    return beautify.html(this.formControl.value);
  }
}
