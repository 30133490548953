import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import FormElementDataModel from '../../../../models/form.element.data.model';
import {
  FONTS,
  FONT_SIZES,
  FONT_STYLE,
  FONT_WEIGHT,
  TEXT_ALIGN,
  TEXT_DECORATION,
  TOOLTIP_TEXT_FOR_FONTS
} from '../../../../constants';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { MjmlCustomType } from '../../../../models/templates/mjml.custom.type';
import { TemplateType } from '../../../../models/templates/template.type';
import { TemplateAssignmentType } from '../../../../models/templates/template.assignment.type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-module-tool-settings',
  templateUrl: './module-tool-settings.component.html',
  styleUrls: ['../tool-settings.scss']
})
export class ModuleToolSettingsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() public tms: TemplateManagementService;

  public moduleForm: FormGroup = this.formBuilder.group({
    color: '#000000',
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: 'none',
    align: 'left',
    containerBackgroundColor: '',
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]]
  });

  public alignOptions: FormElementDataModel[] = TEXT_ALIGN;
  public fontSizeOptions: FormElementDataModel[] = FONT_SIZES;
  public fontFamilyOptions: FormElementDataModel[] = FONTS;
  public fontStyleOptions: FormElementDataModel[] = FONT_STYLE;
  public fontWeightOptions: FormElementDataModel[] = FONT_WEIGHT;
  public textDecorationOptions: FormElementDataModel[] = TEXT_DECORATION;
  public TemplateAssignmentType = TemplateAssignmentType;
  public toolTipText: string = this.translate.instant(TOOLTIP_TEXT_FOR_FONTS);

  constructor(
    private formBuilder: FormBuilder,
    public translate: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.setInitialValues();

    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (element) {
          const customType = element.attributes['customType'];
          if (!element || element.tagName !== MjmlTag.text || !customType || customType === MjmlCustomType.video || customType === MjmlCustomType.signature) {
            return;
          }
          this.setInitialValues();
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setInitialValues(): void {
    const attributes = this.tms.activeElement.attributes;
    const color = attributes['color'] || '#000000';
    const fontFamily = attributes['font-family'] || 'Roboto';
    const fontSize = attributes['font-size'] || '13px';
    const fontStyle = attributes['font-style'] || 'normal';
    const fontWeight = attributes['font-weight'] || 'normal';
    const textDecoration = attributes['text-decoration'] || 'none';
    const align = attributes['align'] || 'left';
    const containerBackgroundColor = attributes['container-background-color'] || '';
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';

    this.moduleForm.get('color').setValue(color);
    this.moduleForm.get('fontFamily').setValue(fontFamily);
    this.moduleForm.get('fontSize').setValue(fontSize);
    this.moduleForm.get('fontStyle').setValue(fontStyle);
    this.moduleForm.get('fontWeight').setValue(fontWeight);
    this.moduleForm.get('textDecoration').setValue(textDecoration);
    this.moduleForm.get('align').setValue(align);
    this.moduleForm.get('containerBackgroundColor').setValue(containerBackgroundColor);
    this.moduleForm.get('paddingBottom').setValue(paddingBottom.slice(0, -2));
    this.moduleForm.get('paddingLeft').setValue(paddingLeft.slice(0, -2));
    this.moduleForm.get('paddingRight').setValue(paddingRight.slice(0, -2));
    this.moduleForm.get('paddingTop').setValue(paddingTop.slice(0, -2));
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public onColorChanged(): void {
    const value = this.moduleForm.get('color').value;
    this.tms.activeElement.attributes['color'] = value ? value : '#000000';
    this.tms.emitSave(true);
  }

  public onFontFamilyChanged(): void {
    const value = this.moduleForm.get('fontFamily').value;
    this.tms.activeElement.attributes['font-family'] = value ? value : 'Roboto';
    this.tms.emitSave(true);
  }

  public onFontSizeChanged(): void {
    const value = this.moduleForm.get('fontSize').value;
    this.tms.activeElement.attributes['font-size'] = value ? value : '13px';
    this.tms.emitSave(true);
  }

  public onFontStyleChanged(): void {
    const value = this.moduleForm.get('fontStyle').value;
    this.tms.activeElement.attributes['font-style'] = value ? value : 'normal';
    this.tms.emitSave(true);
  }

  public onFontWeightChanged(): void {
    const value = this.moduleForm.get('fontWeight').value;
    this.tms.activeElement.attributes['font-weight'] = value ? value : 'normal';
    this.tms.emitSave(true);
  }

  public onTextDecorationChanged(): void {
    const value = this.moduleForm.get('textDecoration').value;
    this.tms.activeElement.attributes['text-decoration'] = value ? value : 'none';
    this.tms.emitSave(true);
  }

  public onContainerBackgroundColorChanged(): void {
    const value = this.moduleForm.get('containerBackgroundColor').value;
    this.tms.activeElement.attributes['container-background-color'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onAlignChanged(): void {
    const value = this.moduleForm.get('align').value;
    this.tms.activeElement.attributes['align'] = value ? value : 'left';
    this.tms.emitSave(true);
  }

  public onPaddingTopChanged(): void {
    const value = this.moduleForm.get('paddingTop').value;
    this.tms.activeElement.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.moduleForm.get('paddingRight').value;
    this.tms.activeElement.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.moduleForm.get('paddingBottom').value;
    this.tms.activeElement.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.moduleForm.get('paddingLeft').value;
    this.tms.activeElement.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }
}
