import { Component } from '@angular/core';
import { formatDate } from '@angular/common';
import { Label } from 'ng2-charts';
import { SocialMediaMetricsFilterType } from '../../models/enum/social.media.metrics.filter.type';
import { SocialMetricGroupingEnum } from '../../models/enum/social-metrig.grouping.enum';
import FormElementDataModel from '../../models/form.element.data.model';

export interface StartEndDate {
  startDate: Date;
  endDate: Date;
}

@Component({
  selector: 'app-widget-common',
  template: ''
})
export class WidgetCommonComponent {
  public timeFilterOptions: FormElementDataModel[] = [
    { label: 'Last 7 Days', value: SocialMediaMetricsFilterType.Last7Days },
    { label: 'Last Month', value: SocialMediaMetricsFilterType.LastMonth },
    { label: 'Last 3 Months', value: SocialMediaMetricsFilterType.Last3Months },
    { label: 'Last Year', value: SocialMediaMetricsFilterType.LastYear }
  ];

  public labels = {
    [SocialMediaMetricsFilterType.Last7Days]: WidgetCommonComponent.getLabels(SocialMediaMetricsFilterType.Last7Days, 1),
    [SocialMediaMetricsFilterType.LastMonth]: WidgetCommonComponent.getLabels(SocialMediaMetricsFilterType.LastMonth, 1),
    [SocialMediaMetricsFilterType.Last3Months]: WidgetCommonComponent.getLabels(SocialMediaMetricsFilterType.Last3Months, 1),
    [SocialMediaMetricsFilterType.LastYear]: WidgetCommonComponent.getLabels(SocialMediaMetricsFilterType.LastYear, 1)
  };

  public labelsIncludingToday = {
    [SocialMediaMetricsFilterType.Last7Days]: WidgetCommonComponent.getLabels(SocialMediaMetricsFilterType.Last7Days, 0),
    [SocialMediaMetricsFilterType.LastMonth]: WidgetCommonComponent.getLabels(SocialMediaMetricsFilterType.LastMonth, 0),
    [SocialMediaMetricsFilterType.Last3Months]: WidgetCommonComponent.getLabels(SocialMediaMetricsFilterType.Last3Months, 0),
    [SocialMediaMetricsFilterType.LastYear]: WidgetCommonComponent.getLabels(SocialMediaMetricsFilterType.LastYear, 0)
  };

  public static getStartEndDate(range: SocialMediaMetricsFilterType): StartEndDate {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    switch (range) {
      case SocialMediaMetricsFilterType.Last7Days:
        return { startDate: new Date(year, month, day - 8), endDate: new Date(year, month, day) };
      case SocialMediaMetricsFilterType.LastMonth:
        return { startDate: new Date(year, month, day - 31), endDate: new Date(year, month, day) };
      case SocialMediaMetricsFilterType.Last3Months:
        return { startDate: new Date(year, month, day - 91), endDate: new Date(year, month, day) };
      case SocialMediaMetricsFilterType.LastYear:
        return { startDate: new Date(year, month - 12), endDate: new Date(year, month, day) };
    }
  }

  public static getGrouping(range: SocialMediaMetricsFilterType): SocialMetricGroupingEnum {
    switch (range) {
      case SocialMediaMetricsFilterType.Last7Days:
      case SocialMediaMetricsFilterType.LastMonth:
      case SocialMediaMetricsFilterType.Last3Months:
        return SocialMetricGroupingEnum.Daily;
      /*case SocialMediaMetricsFilterType.Last3Months:
        return SocialMetricGroupingEnum.Months3;*/
      case SocialMediaMetricsFilterType.LastYear:
        return SocialMetricGroupingEnum.Mounthly;
    }
  }

  public static getLabels(range: SocialMediaMetricsFilterType, indent: number): Label[] {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    const labels = [];
    switch (range) {
      case SocialMediaMetricsFilterType.Last7Days:
        for (let i = 0; i < 7; i++) {
          labels.push(formatDate(new Date(year, month, day - i - indent), 'EEE, d MMM', 'en-US'));
        }
        break;
      case SocialMediaMetricsFilterType.LastMonth:
        for (let i = 0; i < 30; i++) {
          labels.push(formatDate(new Date(year, month, day - i - indent), 'd MMM', 'en-US'));
        }
        break;
      case SocialMediaMetricsFilterType.Last3Months:
        for (let i = 0; i < 90; i++) {
          //if (i % 3 === 0) { //todo check i % 3 === 0
          labels.push(formatDate(new Date(year, month, day - i), 'd MMM', 'en-US'));
          //}
        }
        break;
      case SocialMediaMetricsFilterType.LastYear:
        for (let i = 0; i < 12; i++) {
          labels.push(formatDate(new Date(year, month - i), 'MMM y', 'en-US'));
        }
        break;
    }
    return labels.reverse();
  }

  public static correctArrayValues(array: number[], expectedLength: number): number[] {
    const missed = expectedLength - array.length;
    const emptyArray = [];
    if (missed > 0) {
      for (let i = 0; i < missed; i++) {
        emptyArray.push(null);
      }
    } else {
      return array.slice(missed * -1, array.length);
    }
    return [...emptyArray, ...array];
  }
}