<ng-container>
  <div class="label-container">
    <label for="fileDropRef" class="upload-label">{{label | translate}}</label>
    <div
      *ngIf="toolTipText"
      class="settings-info-icon"
      [matTooltip]="toolTipText"
      matTooltipHideDelay="200"
      matTooltipShowDelay="200"
      matTooltipPosition="above"
      matTooltipClass="settings-tooltip-custom-class"
    >
      <i class="fas fa-info-circle"></i>
    </div>
  </div>
  <form class="container">
    <input type="file" id="fileDropRef" #fileReaderInputRef (change)="fileChangeEvent($event)" [disabled]="readOnly" title=""/>
    <i class="fas fa-upload"></i>
    <div class="container-upload-centered">{{currentImageName && displayCurrentImageName ? currentImageName: 'Click to select file to upload (.png, .jpg, .gif)' | translate}}</div>
  </form>
</ng-container>
