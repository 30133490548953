import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import FormElementDataModel from '../../models/form.element.data.model';
import LazyLoadSearchModel from '../../models/filter-sort/lazy.load.search.model';
import { BasePagingComponent } from '../paginator/base.paging.component';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-lazy-select',
  templateUrl: './lazy-select.component.html',
  styleUrls: ['./lazy-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LazySelectComponent extends BasePagingComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public items: FormElementDataModel[];
  @Input() public totalItems: number = 0;
  @Input() public columnName: string = 'name';
  @Input() public onlyBooleanType: boolean = false;
  @Input() public onlyNumbers: boolean = false;
  @Input() public hasNaFilter: boolean = false;

  @Output() public selectedValue: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() public getOptions: EventEmitter<LazyLoadSearchModel> = new EventEmitter<LazyLoadSearchModel>();

  @ViewChild(MatMenuTrigger) public menu: MatMenuTrigger;

  public searchText: FormControl = new FormControl('');
  public letters: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  @Input() public multiSelection: SelectionModel<any> = new SelectionModel<any>(true, []);
  public options: FormElementDataModel[] = [];

  public searchSubject: Subject<void> = new Subject<void>();
  public subscription: Subscription = new Subscription();
  public isDataLoading: boolean = false;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.entriesPerPage = 50;
    this.subscription.add(
      this.searchSubject.pipe(debounceTime(500)).subscribe(() => {
        this.currentPage = 1;
        this.getOptions.emit(this.searchState);
        this.isDataLoading = true;
      })
    );
    if (this.onlyNumbers) {
      this.letters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalItems) {
      this.total = this.totalItems;
    }
    if (changes.items && changes.items.currentValue) {
      this.isDataLoading = false;
      if (this.currentPage === 1) {
        this.options = changes.items.currentValue;
      } else {
        this.options.push(...changes.items.currentValue);
      }
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onOpened(): void {
    if (!this.items || !this.items.length) {
      this.isDataLoading = true;
      this.getOptions.emit(this.searchState);
    }
  }

  public onScrollDown(): void {
    const totalPages = !this.hasNaFilter ? this.items.length / this.entriesPerPage : this.items.length / (this.entriesPerPage - 1);
    if (Math.ceil(totalPages) - totalPages !== 0) {
      return;
    } else {
      this.currentPage++;
      this.getOptions.emit(this.searchState);
      this.isDataLoading = true;
    }
  }

  public search(): void {
    this.searchSubject.next();
  }

  public searchLetter(letter: string): void {
    this.searchText.setValue(letter);
    this.searchSubject.next();
  }

  public applyFilters(): void {
    this.selectedValue.emit(this.multiSelection.selected);
    this.menu.closeMenu();
  }

  public clearAll(): void {
    this.searchText.setValue('');
    this.multiSelection.clear();
    this.selectedValue.emit([]);
    this.searchSubject.next();
    this.menu.closeMenu();
  }

  public toggleItem(item: FormElementDataModel): void {
    this.multiSelection.toggle(item.value);
  }

  public get isPhones(): boolean {
    return this.columnName === 'primaryPhone';
  }

  public get searchState(): LazyLoadSearchModel {
    return {
      searchValue: this.searchText.value || '',
      first: this.getFirstItemOnPage(),
      rows: this.entriesPerPage,
    };
  }
}
