import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-email-video-tool',
  templateUrl: './email-video-tool.component.html',
  styleUrls: ['../img-tool.scss']
})
export class EmailVideoToolComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() public column: MjmlElementModel;
  @Input() public entity: MjmlElementModel;
  @Input() public tms: TemplateManagementService;
  @ViewChild('videoContainerRef') private videoContainerRef: ElementRef;
  constructor() { }

  public ngOnInit(): void {
    this.subscription.add(
      this.tms.activeVideoChangedEmitter.subscribe((element: MjmlElementModel) => {
        if (element === this.entity) {
          this.setVideoHtml();
        }
      })
    );
  }
  public ngAfterViewInit(): void {
    this.setVideoHtml();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getContainerStyles(): any {
    const attributes = this.entity.attributes;
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    return {
      'padding-bottom': paddingBottom,
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      'padding-top': paddingTop,
    };
  }

  private setVideoHtml(): void {
    const attributes = this.entity.attributes;
    const poster = attributes['poster'];
    const html = this.tms.getEmailVideoTemplateHtml(poster);
    if (this.videoContainerRef.nativeElement.innerHTML !== html) {
      this.videoContainerRef.nativeElement.innerHTML = html;
    }
  }

}
