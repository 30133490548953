<div class="home-wrapper">
  <div class="home-content">
    <div class="home-top-image"></div>
    <div class="home-content-body">
      <span class="home-content-body-title">{{'AUTH.Confirm registration form' | translate}}</span>
      <form *ngIf="!registrationConfirmed; else registrationMessage" class="form-body" [class.hidden]="isDonorNoNeedPassword">

        <mat-form-field appearance="outline">
          <mat-label>{{'First Name' | translate}}</mat-label>
          <input [formControl]="firstName" matInput>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{'Last Name' | translate}}</mat-label>
          <input [formControl]="lastName" matInput>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{'Email Address' | translate}}</mat-label>
          <input [formControl]="email" matInput>
        </mat-form-field>

        <app-password-input [formGroup]="confirmForm" label="Password" name="password" [low]="true"></app-password-input>

        <div class="requirements" *ngIf="showRequirements">
          <div class="title">
            {{'AUTH.Password Requirements' | translate}}
          </div>
          <div class="item" [class.item-good]="!password.hasError('minlength') && !password.hasError('required') && password.touched"
            *ngIf="requirements?.requiredLength > 0">
            <div class="icon-bad" [class.icon-good]="!password.hasError('minlength') && !password.hasError('required') && password.touched">
            </div><span [innerHTML]="'AUTH.AtLeast' | translate: {value: requirements?.requiredLength}"></span>
          </div>
          <div class="item" [class.item-good]="!password.hasError('oneCapitalLetter') && !password.hasError('required')"
            *ngIf="requirements?.requireUppercase">
            <div class="icon-bad" [class.icon-good]="!password.hasError('oneCapitalLetter') && !password.hasError('required')">
            </div>{{'AUTH.One uppercase character' | translate}}
          </div>
          <div class="item" [class.item-good]="!password.hasError('oneSmallLetter') && !password.hasError('required')"
            *ngIf="requirements?.requireLowercase">
            <div class="icon-bad" [class.icon-good]="!password.hasError('oneSmallLetter') && !password.hasError('required')">
            </div>{{'AUTH.One lowercase character' | translate}}
          </div>
          <div class="item" [class.item-good]="!password.hasError('oneNumber') && !password.hasError('required')"
            *ngIf="requirements?.requireDigit">
            <div class="icon-bad" [class.icon-good]="!password.hasError('oneNumber') && !password.hasError('required')">
            </div>{{'AUTH.One numerical character' | translate}}
          </div>
          <div class="item" [class.item-good]="!password.hasError('specialSymbol') && !password.hasError('required')"
            *ngIf="requirements?.requireNonAlphanumeric">
            <div class="icon-bad" [class.icon-good]="!password.hasError('specialSymbol') && !password.hasError('required')">
            </div>{{'AUTH.One special character (@,!,*,$,%)' | translate}}
          </div>
        </div>

        <app-password-input [formGroup]="confirmForm" label="Confirm Password" name="confirmPassword" [low]="true"></app-password-input>

        <span *ngIf="confirmForm.hasError('notSame') && confirmPassword.touched" style="color: red;">
          {{'AUTH.Passwords must match' | translate}}
        </span>


        <div class="home-content-body-button">
          <app-button type="flat" label="COMPLETE" (clickEvent)="completeRegistration()" color="#0064be" width="100%" [disabled]="confirmForm.invalid">
          </app-button>
        </div>
      </form>

      <ng-template #registrationMessage>
        <span class="registration-confirmed">{{'AUTH.You have already confirmed registration' | translate}}</span>
      </ng-template>

    </div>

  </div>
</div>
