import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { IconsType } from 'src/app/models/enum/icons.type';
import { SocialProviderEnum } from 'src/app/models/enum/social-provider.enum';
import { FileCroppingModel } from '../../models/socialMedia/file.cropping.model';
import { FormControl } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { AzureFileModel } from '../../models/files/azure-file.model';
import { AzureUploadFileService } from '../../services/lookup/azure-upload-file.service';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'app-sm-image-cropper',
  templateUrl: './sm-image-cropper.component.html',
  styleUrls: ['./sm-image-cropper.component.scss'],
})
export class SmImageCropperComponent {
  public IconsType = IconsType;
  public croppedImage: any = '';
  public userAspectRatio: number = 1;
  public userCanvasRotation: number = 0;
  public userMaintainAspectRatio: boolean = false;
  public SocialProviderEnum = SocialProviderEnum;
  public storageURL: string = localStorage.getItem('storageURL');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FileCroppingModel,
    public dialogRef: MatDialogRef<SmImageCropperComponent>,
    private azureUploadFileService: AzureUploadFileService,
  ) {
  }

  public setGridStyles(): { [key: string]: string } {
    switch (this.data.provider) {
      case SocialProviderEnum.Facebook:
      case SocialProviderEnum.Linkedin:
        return {
          'grid-template-columns': 'repeat(2, 36px) 76px 60px repeat(3, 52px)',
        };
      case SocialProviderEnum.Instagram:
        return {'grid-template-columns': 'repeat(2, 36px) 76px 60px'};
      case SocialProviderEnum.Twitter:
        return {'grid-template-columns': 'repeat(2, 36px) 76px 60px 80px'};
    }
  }

  public imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  public setAspectRatio(ratio: number): void {
    this.userAspectRatio = ratio;
    this.userMaintainAspectRatio = true;
  }

  public setDefaultRatio(): void {
    this.userAspectRatio = null;
    this.userMaintainAspectRatio = false;
  }

  public rotateImage(rotation: number): void {
    this.userCanvasRotation += rotation;
  }

  public saveCroppedImage(): void {
    this.azureUploadFileService.addModel({
      file: this.croppedImage.substring(23, this.croppedImage.length),
      folder: 'images',
      name: `${Guid.create()}.jpeg`,
    })
      .pipe(
        first(),
        tap(({name}: AzureFileModel) => {
          const model = {
            name: name,
            fileName: name,
            folder: 'images',
            src: `${this.storageURL}/AzureFileStorage/image/${name}`,
            type: 'image/jpeg'
          }
          this.data.formGroup.patchValue(model);
          this.closeDialog();
        })
      )
      .subscribe();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public get src(): FormControl {
    return this.data.formGroup.get('src') as FormControl;
  }
}
