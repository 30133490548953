<div class="main-label-wrapper" [ngStyle]="mainLabelStyle">
  <b>{{element.attributes['mainLabel'] | translate}}</b>
</div>
<div class="form-row-wrapper">
  <div class="form-element right-margin" [ngStyle]="doubleFormElementStyle">
    <app-fb-input
      [formGroup]="paymentForm"
      [label]="element.attributes['firstName']"
      name="firstName"
      errorMassage="First Name"
      [inputBackground]="template.inputBackground"
      [color]="template.fontColor"
      [fontFamily]="template.fontFamily"
      [fontSize]="template.fontSize"
      [disabled]="readOnly"
    ></app-fb-input>
  </div>
  <div class="form-element" [ngStyle]="doubleFormElementStyle">
    <app-fb-input
      [formGroup]="paymentForm"
      [label]="element.attributes['lastName']"
      name="lastName"
      errorMassage="Last Name"
      [inputBackground]="template.inputBackground"
      [color]="template.fontColor"
      [fontFamily]="template.fontFamily"
      [fontSize]="template.fontSize"
      [disabled]="readOnly"
    ></app-fb-input>
  </div>
</div>
