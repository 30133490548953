import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseFormElementComponent } from '../base.form.element.component';
import { SocialProviderEnum } from '../../../models/enum/social-provider.enum';
import SocialTimeSlotModel from '../../../models/socialMedia/social.time.slot.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-social-media-select',
  templateUrl: './social-media-select.component.html',
  styleUrls: ['./social-media-select.component.scss', '../base.form.element.component.scss']
})
export class SocialMediaSelectComponent extends BaseFormElementComponent implements OnChanges {
  @Input()
  public facebookAccounts: number = 0;
  @Input()
  public instagramAccounts: number = 0;
  @Input()
  public twitterAccounts: number = 0;
  @Input()
  public linkedinAccounts: number = 0;
  @Input()
  public youTubeAccounts: number = 0;
  @Input()
  public initialTimeSlot: SocialTimeSlotModel;
  public SocialProviderEnum = SocialProviderEnum;
  public selectedProviders = new Set();

  public instagramRestrictions = 'Video: maximum length 60 seconds\nText: 2200 characters and 30 hashtags';
  public facebookRestrictions = 'Video: maximum length 240 minutes\nText: 63206 characters';
  public twitterRestrictions = '4 images OR 1 gif OR 1 video \nVideo: maximum length 140 seconds\nText: 280 characters';
  public linkedInRestrictions = 'Video: maximum length 10 minutes\nText: 1300 characters';

  constructor(public translate: TranslateService) {
    super(translate);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.initialTimeSlot && changes.initialTimeSlot.currentValue) {
      this.selectedProviders.add(changes.initialTimeSlot.currentValue.provider);
    }
  }

  public toggleItem(item: SocialProviderEnum): void {
    if (item === SocialProviderEnum.YouTube && this.selectedProviders.has(item)) {
      this.selectedProviders.delete(item);
    } else if (item === SocialProviderEnum.YouTube && !this.selectedProviders.has(item)) {
      this.selectedProviders.clear();
      this.selectedProviders.add(item);
    } else {
      this.selectedProviders.delete(SocialProviderEnum.YouTube);
      this.selectedProviders.has(item) ? this.selectedProviders.delete(item) : this.selectedProviders.add(item);
    }
    let currentValue = null;
    // eslint-disable-next-line no-bitwise
    this.selectedProviders.forEach((provider: SocialProviderEnum) => currentValue = currentValue ? currentValue | provider : provider);
    this.formControl.setValue(currentValue);
    this.formControl.markAsTouched();
    this.valueChanged.emit();
  }

}
