import { Component, Input, OnInit } from '@angular/core';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { UtilsComponent } from '../../../utils.component';
import { AlignmentType, HeaderSize } from '../../../../models/form-builder/fb.template.model';

@Component({
  selector: 'app-event-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class EventHeaderComponent implements OnInit {
  @Input() public mjml: MjmlElementModel;
  constructor() { }

  ngOnInit(): void {
  }

  public get headerStyle(): { [key: string]: string } {
    if (!this.headerFooter) {
      return {
        background: '#023665',
        'justify-content': UtilsComponent.getLogoPosition(AlignmentType.Left),
        'min-height':  `${UtilsComponent.getLogoSize(HeaderSize.Medium)}px`,
      };
    }
    const {
      background = '#023665',
      logoPosition = AlignmentType.Left,
    }: { [key: string]: string } = this.headerFooter.attributes;
    let {logoSize = HeaderSize.Medium} = this.headerFooter.attributes;
    if (logoSize === HeaderSize.Small) {
      logoSize = HeaderSize.Medium;
    }
    return {
      background,
      'justify-content': UtilsComponent.getLogoPosition(logoPosition as AlignmentType),
      'min-height':  `${UtilsComponent.getLogoSize(logoSize as HeaderSize)}px`,
    };
  }

  public get headerFooter(): MjmlElementModel {
    return this.mjml.children[1].children[1];
  }

  public get logoTarget(): string {
    if (!this.headerFooter) {
      return 'https://www.theauxilia.com/';
    }
    const {logoTarget} = this.headerFooter.attributes;
    if (logoTarget) {
      if (logoTarget.startsWith('http')) {
        return logoTarget;
      } else {
        return `https://${logoTarget}`;
      }
    } else {
      return 'https://www.theauxilia.com/';
    }
  }

  public get logoSrc(): string {
    if (!this.headerFooter) {
      return '/assets/images/logo.png';
    }
    const {
      logoSrc = '/assets/images/logo.png'
    }: { [key: string]: string } = this.headerFooter.attributes;
    return logoSrc;
  }

  public get logoStyle(): { [key: string]: string } {
    if (!this.headerFooter) {
      return {
        width: 'auto',
        height: `${UtilsComponent.getLogoSize(HeaderSize.Medium)}px`,
      };
    }
    const {
      logoSize = HeaderSize.Medium,
    }: { [key: string]: string } = this.headerFooter.attributes;
    return {
      width: 'auto',
      height: `${UtilsComponent.getLogoSize(logoSize as HeaderSize)}px`,
    };
  }

}
