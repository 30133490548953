import { AttachmentModel } from '../files/attachment.model';
import { TicketPackageAddonModel } from './ticket-package-addon.model';
import { TicketPackageIncludeModel } from './ticket-package-include.model';
import EventModel from './event.model';
import EventParticipantModel from './event.participant.model';
import TicketPackageModel from './ticket.package.model';

export default class EventTicketModel {
  id?: string;
  actualPrice?: number;
  attachments?: AttachmentModel[];
  buyer?: EventParticipantModel;
  buyerName?: string; //extra
  buyerParticipantID?: string;
  createdOn: Date;
  description?: string;
  event: EventModel;
  eventID?: string;
  eventParticipantID?: string;
  isActive: boolean;
  note?: string;
  participant?: EventParticipantModel;
  participantName?: string; //extra
  publicID: number;
  ticketPackage?: TicketPackageModel;
  ticketPackageID?: string;
  ticketStatus?: number;
  ticketTypeName?: string; //extra
  customType?: string;
  addonIncludes?: AddonsIncludes[];
  eventName?: string;
  regDate?: string;
  ticketPurchaser?: string;
  email?: string;
  phoneString?: string;
  clientID?: string;

  readonly addons?: string;
  readonly includes?: string;
}

export interface AddonsIncludes {
  addonId: number;
  eventTicketId: string;
  includeId: number;
  ticketPackageInclude?: TicketPackageIncludeModel;
  ticketPackageAddon?: TicketPackageAddonModel;
}

export enum TicketStatus {
  noStatus = 0,
  reserved = 1,
  paid = 2,
  deleted = 3
}
