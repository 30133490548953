<div class="image-tool-wrapper" [ngStyle]="getContainerStyles()">
  <div *ngIf="!srcUrls?.length; else srcs" class="empty-image-src">
    <ng-container *ngIf="!landingPageView">{{"BUILD.Add Sponsors" | translate}}</ng-container>
  </div>
</div>

<ng-template #srcs>
  <div class="mb-20">
    <span class="title">{{"BUILD.Sponsors" | translate}}</span>
  </div>
  <div class="flex-row justify-start">
    <div *ngFor="let src of srcUrls; let i=index" class="mr-10">
      <img [src]="src" class="sponsor-logo" alt="sponsors"/>
    </div>
  </div>
</ng-template>
