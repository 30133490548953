<div
  class="donation-activity-wrapper"
  [ngStyle]="containerStyles"
>
  <div class="donation-activity-row">
    <div class="donation-activity-title">

      <app-text-tool
        [column]="column"
        [entity]="entity"
        [tms]="tms"
        [maxLength]="20"
        [landingPageView]="landingPageView"
        [resetDefaultPuddings]="true"
      ></app-text-tool>

      <i *ngIf="!landingPageView" class="fas fa-pen"></i>
    </div>

    <app-button
      type="flat"
      color="#0064be"
      width="140"
      (clickEvent)="addNew()"
    >{{'BUILD.Add New' | translate}}</app-button>
  </div>

  <app-comment-wall-element
    [comments$]="comments$"
    [entity]="entity"
  ></app-comment-wall-element>

  <app-paginator
    *ngIf="!isDisplayCountAll"
    [totalItems]="total$ | async"
    (nextPage)="getNextPage($event)"
    (entriesPerPageChanged)="setEntriesPerPage($event)"
    [setDefaultPage]="setDefaultPage$"
    [entriesPerPageSettings]="[5, 10, 25]"
    [entriesPerPage]="entriesPerPage"
    [useSpaceEvenly]="true"
  ></app-paginator>


  <app-button
    *ngIf="!isDisplayCountAll"
    type="stroked"
    color="#0064be"
    (clickEvent)="viewAll()"
    [style.padding.px]="20"
  >
    <div class="button-label">
      <app-list-icon></app-list-icon>
      <span>{{'BUILD.View All' | translate}}</span>
    </div>
  </app-button>

</div>

