import { Component, OnInit } from '@angular/core';

import { BaseFormComponent } from '../base.form.component';

@Component({
    selector: 'app-occupation',
    templateUrl: './occupation.component.html',
    styleUrls: ['../base.form.scss']
})
export class OccupationComponent extends BaseFormComponent implements OnInit {

    constructor() {
        super();
    }

    ngOnInit() {
    }

}
