import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { TemplateType } from '../../../../models/templates/template.type';

@Component({
  selector: 'app-text-tool-settings',
  templateUrl: './text-tool-settings.component.html',
  styleUrls: ['./text-tool-settings.component.scss', '../tool-settings.scss']
})
export class TextToolSettingsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() public tms: TemplateManagementService;

  public textForm: FormGroup = this.formBuilder.group({
    //color: '',
    //fontFamily: 'Roboto',
    containerBackgroundColor: '',
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]],
    //align: ['left']
  });

  constructor(
    private formBuilder: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.setInitialValues();

    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (!element || element.tagName !== MjmlTag.text) {
          return;
        }
        this.setInitialValues();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setInitialValues(): void {
    const attributes = this.tms.activeElement.attributes;
    //const color = attributes['color'] || '#000000';
    //const fontFamily = attributes['font-family'] || 'Roboto';
    const containerBackgroundColor = attributes['container-background-color'] || '';
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    //const align = attributes['align'] || 'left';

    //this.textForm.get('color').setValue(color);
    //this.textForm.get('fontFamily').setValue(fontFamily);
    this.textForm.get('containerBackgroundColor').setValue(containerBackgroundColor);
    this.textForm.get('paddingBottom').setValue(paddingBottom.slice(0, -2));
    this.textForm.get('paddingLeft').setValue(paddingLeft.slice(0, -2));
    this.textForm.get('paddingRight').setValue(paddingRight.slice(0, -2));
    this.textForm.get('paddingTop').setValue(paddingTop.slice(0, -2));
    //this.textForm.get('align').setValue(align);
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  /*public onColorChanged(): void {
    const value = this.textForm.get('color').value;
    this.tms.activeElement.attributes['color'] = value ? value : '';
    this.tms.emitSave(true);
  }*/

  /*public onFontFamilyChanged(): void {
    const value = this.textForm.get('fontFamily').value;
    this.tms.activeElement.attributes['font-family'] = value ? value : '';
    this.tms.emitSave(true);
  }*/

  public onContainerBackgroundColorChanged(): void {
    const value = this.textForm.get('containerBackgroundColor').value;
    this.tms.activeElement.attributes['container-background-color'] = value ? value : '';
    this.tms.emitSave(true);
  }

  /*public onAlignChanged(): void {
    const value = this.textForm.get('align').value;
    this.tms.activeElement.attributes['align'] = value ? value : 'left';
    this.tms.emitSave(true);
  }*/

  public onPaddingTopChanged(): void {
    const value = this.textForm.get('paddingTop').value;
    this.tms.activeElement.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.textForm.get('paddingRight').value;
    this.tms.activeElement.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.textForm.get('paddingBottom').value;
    this.tms.activeElement.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.textForm.get('paddingLeft').value;
    this.tms.activeElement.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }
}
