<div
  class="donation-activity-element"
  [ngStyle]="donationRowStyles"
>
  <div
    *ngFor="let donation of donations$ | async; index as i"
    class="donation-activity-element-row"
    [class.with-background]="i % 2 === 0"
  >

    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20.5" r="20" fill="#BBD3F3"/>
      <g clip-path="url(#clip0)">
        <path d="M23.313 18.8753C24.3396 19.7461 24.47 19.8671 24.9218 20.2562C25.2528 19.9722 25.5368 19.7139 26.7407 18.7044C28.9503 16.849 30.5468 15.5094 30.5468 13.5305C30.5468 11.6545 29.1687 10.1852 27.4095 10.1852C26.2277 10.1852 25.4436 10.8519 24.9513 11.531C24.4851 10.8498 23.727 10.1852 22.5419 10.1852C20.7827 10.1852 19.4053 11.6546 19.4053 13.5305C19.4052 15.5595 20.9598 16.8785 23.313 18.8753Z" fill="white"/>
        <path d="M12.0217 30.4351C13.8639 29.1836 15.8795 28.7054 18.0733 29.0711L21.5889 29.6757C22.939 29.9008 24.1764 29.8304 25.2451 29.4789C27.4561 28.742 29.6695 26.4213 30.2561 25.5414C31.0718 24.3179 31.5198 22.982 31.8011 22.3352C32.0683 21.7164 32.0683 21.2805 31.7871 21.0273C31.4636 20.732 30.9152 20.8867 30.5074 21.1117C29.8605 21.4773 29.1733 22.532 28.4702 23.5445C27.5561 24.8664 25.9905 25.6539 24.3874 25.6539H19.2967V24.2476H21.4061C25.1466 24.2476 26.8201 23.5445 26.8201 22.8414C26.8201 22.2508 25.6529 21.9835 25.2591 21.8992C23.7264 21.5477 20.2669 21.3789 17.9045 20.3242C17.4784 20.1378 16.9954 20.0332 16.4843 20.0289C15.6828 20.0221 14.8118 20.2718 13.981 20.9148C13.967 20.9148 13.967 20.9148 13.9529 20.9289C13.4045 21.3507 12.6545 21.9695 11.642 22.8554C10.7841 23.6147 9.95449 24.5851 8.50601 25.8226L7.99976 26.2585L11.4592 30.8148L12.0217 30.4351Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 32 8.5)"/>
        </clipPath>
      </defs>
    </svg>

    <div *ngIf="showDonationAmount" class="name-block">
      <span class="name-value">{{donation.donorFullName}}</span>

      <div class="amount-block">
        <span>${{donation.donationAmmount | number:'.2'}}</span>
        <span>{{getTime(donation)}}</span>
      </div>
    </div>
    <div *ngIf="!showDonationAmount" class="common-block">
      <span class="name-value">{{donation.donorFullName}}</span>
      <span>{{getTime(donation)}}</span>
    </div>
  </div>
</div>

