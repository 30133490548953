import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { Subscription } from 'rxjs';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { BORDER_STYLE, TEXT_ALIGN } from '../../../../constants';
import { MjmlCustomType } from '../../../../models/templates/mjml.custom.type';
import { TemplateType } from '../../../../models/templates/template.type';

@Component({
  selector: 'app-image-tool-settings',
  templateUrl: './image-tool-settings.component.html',
  styleUrls: ['./image-tool-settings.component.scss', '../tool-settings.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageToolSettingsComponent implements OnInit, OnDestroy {
  @Input() public tms: TemplateManagementService;
  public MjmlTag = MjmlTag;
  private subscription: Subscription = new Subscription();

  public alignOptions: FormElementDataModel[] = TEXT_ALIGN;
  public borderStyleOptions: FormElementDataModel[] = BORDER_STYLE;
  public message: string = 'Informational text box when hovering over an item';
  public imageURL: string = null;

  public imageForm: FormGroup = this.formBuilder.group({
    url: ['', [Validators.required]],
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]],
    href: '',
    title: '',
    borderRadius: '',
    align: 'center',
    width: '',
    height: '',
    borderWidth: '0',
    borderStyle: 'solid',
    borderColor: '#414141',
  });
  private columnWidthInPercentage: string = null;
  private generalWidth: string = null;
  private maxImageSize: number = 0;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.setInitialValues();

    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (!element || element.tagName !== MjmlTag.image) {
          return;
        }
        this.setInitialValues();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public setBorder(): void {
    const borderStyle = this.imageForm.get('borderStyle').value || 'solid';
    const borderColor = this.imageForm.get('borderColor').value || '#414141';
    const borderWidth = this.imageForm.get('borderWidth').value || '0';
    this.tms.activeElement.attributes['border'] = `${borderWidth}px ${borderStyle} ${borderColor}`;
    this.tms.emitSave(true);
  }

  private setInitialValues(): void {
    const attributes = this.tms.activeElement.attributes;
    const src = attributes['src'] || '';
    const width = attributes['width'] ? attributes['width'].slice(0, -2) : '';
    const height = attributes['height'] ? attributes['height'] === 'auto' ? '' : attributes['height'].slice(0, -2) : '';
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    const href = attributes['href'] || '';
    const title = attributes['title'] || '';
    const borderRadius = attributes['border-radius'] || '';
    const align = attributes['align'] || 'center';
    const border = attributes['border'];
    const borderWidth = border ? border.split(' ')[0].slice(0, -2) : '0';
    const borderStyle = border ? border.split(' ')[1] : 'solid';
    const borderColor = border ? border.split(' ')[2] : '#414141';

    this.imageForm.get('url').setValue(src);
    this.imageForm.get('width').setValue(width);
    this.imageForm.get('height').setValue(height);
    this.imageForm.get('paddingBottom').setValue(paddingBottom.slice(0, -2));
    this.imageForm.get('paddingLeft').setValue(paddingLeft.slice(0, -2));
    this.imageForm.get('paddingRight').setValue(paddingRight.slice(0, -2));
    this.imageForm.get('paddingTop').setValue(paddingTop.slice(0, -2));
    this.imageForm.get('href').setValue(href);
    this.imageForm.get('title').setValue(title);
    this.imageForm.get('borderRadius').setValue(borderRadius);
    this.imageForm.get('align').setValue(align);
    this.imageForm.get('borderWidth').setValue(borderWidth);
    this.imageForm.get('borderStyle').setValue(borderStyle);
    this.imageForm.get('borderColor').setValue(borderColor);
  }

  public get uploadLabel(): string {
    const customType = this.tms.activeElement.attributes['customType'];
    switch (customType) {
      case MjmlCustomType.logo:
        return 'Upload logo';
      default:
        return 'Upload image';
    }
  }

  public get columnWidth(): number {
    const columnWidthInPercentage = this.tms?.activeColumn?.attributes['width'] ?? this.tms.columnWidth;
    const generalWidth = this.tms.generalContentWidth;
    if(columnWidthInPercentage === this.columnWidthInPercentage && generalWidth === this.generalWidth) {
      return this.maxImageSize;
    }
    this.columnWidthInPercentage = columnWidthInPercentage;
    this.generalWidth = generalWidth;
    const percentage = parseFloat(columnWidthInPercentage?.replace('%', '')) / 100;
    const generalWidthInPixels = parseFloat(generalWidth?.replace('px', ''));
    this.maxImageSize = Math.floor(generalWidthInPixels * percentage);
    return this.maxImageSize;
  }
  
  public get urlLabel(): string {
    const customType = this.tms.activeElement.attributes['customType'];
    switch (customType) {
      case MjmlCustomType.logo:
        return 'Logo URL';
      default:
        return 'Image URL';
    }
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public imageUrlChanged(url: string): void {
    this.imageURL = null;
    if (url === null) {
      return;
    }
    if (this.tms.activeElement.attributes['customType'] === MjmlCustomType.logo) {
      this.tms.activeElement.attributes['default-attributes-changed'] = 'changed';
    }
  }

  public editImage(): void {
    this.imageURL = this.imageForm.get('url').value;
  }

  public cancelEditImage(): void {
    this.imageURL = null;
  }

  public onUrlChanged(): void {
    const value = this.imageForm.get('url').value;
    this.tms.activeElement.attributes['src'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onWidthChanged(): void {
    const value = this.imageForm.get('width').value;
    this.tms.activeElement.attributes['width'] = value ? `${value}px` : '';
    this.tms.emitSave(true);
  }

  public onHeightChanged(): void {
    const value = this.imageForm.get('height').value;
    this.tms.activeElement.attributes['height'] = value ? `${value}px` : 'auto';
    this.tms.emitSave(true);
  }

  public onPaddingTopChanged(): void {
    const value = this.imageForm.get('paddingTop').value;
    this.tms.activeElement.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.imageForm.get('paddingRight').value;
    this.tms.activeElement.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.imageForm.get('paddingLeft').value;
    this.tms.activeElement.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.imageForm.get('paddingBottom').value;
    this.tms.activeElement.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onHrefChanged(): void {
    const value = this.imageForm.get('href').value;
    this.tms.activeElement.attributes['href'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onTitleChanged(): void {
    const value = this.imageForm.get('title').value;
    this.tms.activeElement.attributes['title'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onBorderRadiusChanged(): void {
    const value = this.imageForm.get('borderRadius').value;
    this.tms.activeElement.attributes['border-radius'] = value ? `${value}px` : '';
    this.tms.emitSave(true);
  }

  public onAlignChanged(): void {
    const value = this.imageForm.get('align').value;
    this.tms.activeElement.attributes['align'] = value ? value : 'center';
    this.tms.emitSave(true);
  }
}
