import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {UserService} from 'src/app/services/user.service';
import {ClientEmployeeService} from '../../../services/client/client-employee.service';
import {Filter} from '../../../models/paging/filter.model';
import {FilterType} from '../../../models/enum/filter.type';
import {Paging} from '../../../models/paging/paging.model';
import { FormValidators } from 'src/app/shared/validatiors/form-validators';
import { DonorEmployeeService } from 'src/app/services/donor.employee/donor-employee.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasswordResetComponent implements OnInit {
  public email: FormControl = new FormControl('', [Validators.required, FormValidators.emailValidator]);
  public emailError: boolean;
  public linkSent: boolean;

  constructor(private userService: UserService,
              private clientEmployeeService: ClientEmployeeService,
              private router: Router,
              private donorEmployeeService: DonorEmployeeService) {
  }

  ngOnInit() {

  }

  public createAccount(): void {
    this.router.navigate(['donor-register']);
  }

  public back(): void {
    this.linkSent = false;
  }

  public submit(): void {
    const filters: Filter[] = [
      {
        field: 'email',
        value: this.email.value,
        type: FilterType.Equal
      }
    ];
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: true,
      filters: filters,
    };
    this.clientEmployeeService.getAnonymousEmployee(paging).subscribe(clients => {
      this.donorEmployeeService.getAnonymousEmployee(paging).subscribe(donors => {

        if (clients.length && this.email.valid) {
            this.userService.getPasswordCode(this.email.value).subscribe(response => {
              this.linkSent = response;
            }, error => {
              this.emailError = true;
            });
        } else if (donors.length && this.email.valid && !clients.length) {
          this.donorEmployeeService.getPasswordCode(this.email.value).subscribe(response => {
            this.linkSent = response;
          }, error => {
            this.emailError = true;
          });
        } else {
          this.emailError = true;
        }
      });
    });
  }
}
