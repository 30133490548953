import { Component, Input, ViewEncapsulation } from '@angular/core';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { MjmlCustomType } from '../../../../models/templates/mjml.custom.type';
import { TemplateAssignmentType } from '../../../../models/templates/template.assignment.type';

@Component({
  selector: 'app-tools-renderer',
  templateUrl: './tools-renderer.component.html',
  styleUrls: ['./tools-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolsRendererComponent {
  @Input() public eventId: string;
  @Input() public fundraiserId: string;
  @Input() public tms: TemplateManagementService;
  @Input() public column: MjmlElementModel;

  @Input() public landingPageView: boolean = false;
  @Input() public screenshots: boolean = false;

  public MjmlTag = MjmlTag;
  public MjmlCustomType = MjmlCustomType;

  public setActiveElement(element: MjmlElementModel): void {
    if (this.landingPageView) {
      return;
    }
    this.tms.setActiveElement(element);
  }

  public isChart(child: MjmlElementModel): boolean {
    return child.attributes['customType'] === MjmlCustomType.chart;
  }

  public getCustomType(child: MjmlElementModel): MjmlCustomType {
    const customType: MjmlCustomType = child.attributes['customType'] as MjmlCustomType;
    return customType ? customType : null;
  }
}
