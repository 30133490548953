import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
    selector: 'app-quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.scss'],
})
export class QuantitySelectorComponent implements OnInit {
    @Input() minusButtonDisabled: boolean;
    @Input() parentForm: FormGroup;
    @Input() ctrlName: string;
    @Output() onValueBelowZero: EventEmitter<any> = new EventEmitter();

    private subscription: Subscription = new Subscription();

    initialValue: number;

    constructor() {}

    ngOnInit(): void {
        this.parentForm.valueChanges.subscribe((val) => {
            if(this.control.value < 0) {
                this.onValueBelowZero.emit(val);
            }
        })
    }

    get control() {
        return this.parentForm.get(this.ctrlName);
    }

    onButtonClick(operation: string) {
        const currentValue = this.control.value;
        if(operation === 'decrease') {
            this.control.setValue(currentValue - 1);
        }
        if(operation === 'increase') {
            this.control.setValue(currentValue + 1);
        }
    }
}