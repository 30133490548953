<div class="back-button-div">
  <button mat-button *ngIf="isPreview" class="back-button" (click)="back()">{{'BUILD.BACK' | translate}}</button>
</div>
<div class="c-e-lp">
  <div class="lp-container">
    <div class="lp-example">
      <div class="example-header">
        <img class="example-image" [src]="imageURL" [style.backgroundColor]="getHeaderBackground?.color" />
        <label [style.fontFamily]="getHeaderEvent?.fontName" [style.fontSize.px]="getHeaderEvent?.fontSize" class="header-label" [style.color]="getHeaderEvent?.color">{{event?.name}}</label>
      </div>
      <div class="example-body" [style.backgroundColor]="getMainBackground?.color">
        <div>
          <div>
            <div class="body-datetime">
              <div>
                <mat-label class="body-name" [style.fontFamily]="getMainTitles?.fontName" [style.fontSize.px]="getMainTitles?.fontSize" [style.color]="getMainTitles?.color">{{'BUILD.DATE' | translate}}</mat-label><br>
                <mat-label class="body-value" [style.fontFamily]="getMainDetails?.fontName" [style.fontSize.px]="getMainDetails?.fontSize" [style.color]="getMainDetails?.color">
                  {{event?.startDate | date:'MMMM dd, yyyy'}} - {{event?.endDate | date:'MMMM dd, yyyy'}}</mat-label>
              </div>
              <div>
                <mat-label class="body-name" [style.fontFamily]="getMainTitles?.fontName" [style.fontSize.px]="getMainTitles?.fontSize" [style.color]="getMainTitles?.color">{{'BUILD.TIME' | translate}}</mat-label><br>
                <mat-label class="body-value" [style.fontFamily]="getMainDetails?.fontName" [style.fontSize.px]="getMainDetails?.fontSize" [style.color]="getMainDetails?.color">{{event?.startTime}} - {{event?.endTime}} {{shortTimeZone}}</mat-label>
              </div>
            </div>
            <div class="show-itineraries" *ngIf="event?.eventItineraries?.length > 0">
              <div class="title" (click)="toggleItineraries()">
                {{'BUILD.See Details' | translate}}
              </div>
              <div *ngIf="showItineraries">
                <div *ngFor="let item of event.eventItineraries; index as i;">
                  {{i + 1}}. {{item.startDate | date:'MMMM dd, yyyy'}} {{item.startTime}} - {{item.endDate | date:'MMMM dd, yyyy'}} {{item.endTime}}: {{item.text}}
                </div>
              </div>

            </div>
            <div class="body-address" *ngIf="event?.hasLocation">
              <mat-label class="body-name" [style.fontFamily]="getMainTitles?.fontName" [style.fontSize.px]="getMainTitles?.fontSize" [style.color]="getMainTitles?.color">{{'BUILD.LOCATION' | translate}}</mat-label><br>
              <mat-label class="body-value" [style.fontFamily]="getMainDetails?.fontName" [style.fontSize.px]="getMainDetails?.fontSize" [style.color]="getMainDetails?.color"></mat-label><br>
              <mat-label class="body-value" [style.fontFamily]="getMainDetails?.fontName" [style.fontSize.px]="getMainDetails?.fontSize" [style.color]="getMainDetails?.color">{{event?.address1}} {{event?.address2}}</mat-label><br>
              <mat-label class="body-value" [style.fontFamily]="getMainDetails?.fontName" [style.fontSize.px]="getMainDetails?.fontSize" [style.color]="getMainDetails?.color">{{event?.city}}, {{getState}} {{event?.zip | mask: '00000-0000'}}</mat-label>
            </div>
            <div class="body-address">
              <mat-label class="body-name" [style.fontFamily]="getMainTitles?.fontName" [style.fontSize.px]="getMainTitles?.fontSize" [style.color]="getMainTitles?.color">{{'BUILD.DESCRIPTION' | translate}}</mat-label><br>
              <mat-label class="body-value description-value" [style.fontFamily]="getMainDetails?.fontName" [style.fontSize.px]="getMainDetails?.fontSize" [style.color]="getMainDetails?.color" [innerHTML]="getDescription | safeHTML"></mat-label><br>
            </div>
          </div>
        </div>
        <div *ngIf="getAdditionalDonate?.isAvailable">
          <span style="font-weight: 500;" [style.color]="getMainDetails?.color" [style.fontFamily]="getAdditionalDonate?.fontName" [style.fontSize.px]="getAdditionalDonate?.fontSize">{{getAdditionalDonate?.text}}&nbsp;</span>
          <a [style.fontFamily]="getAdditionalDonate?.fontName" [style.fontSize.px]="getAdditionalDonate?.fontSize" [style.color]="getAdditionalDonate?.color" (click)="onDonateClick()">{{'BUILD.DONATE' | translate}}</a>
        </div>

        <ng-container *ngIf="showDonationNotification">
          <div class="payment-content-success">
            <img src="/assets/icons/check1.svg" style="margin-right: 15px;"> {{'BUILD.Thank you for your Donation!' | translate}}<br>
          </div>
        </ng-container>

        <div class="body-button-div" *ngIf="event?.hasRegistrationForm">
          <button mat-button class="body-button" [style.fontSize.px]="getAdditionalDetails?.fontSize" [style.backgroundColor]="getAdditionalDetails?.color" (click)="registerRedirect()">{{'BUILD.REGISTER' | translate}}</button>
        </div>
        <div class="body-contact" [style.fontSize.px]="getFooterDetails?.fontSize" [class.with-donation-form]="isDonateFormVisible">
          <mat-label class="body-name" [style.fontFamily]="getMainTitles?.fontName" [style.color]="getFooterTitles?.color">{{'BUILD.CONTACT INFORMATION' | translate}}</mat-label><br>
          <mat-label class="body-value" [style.fontFamily]="getMainDetails?.fontName" [style.color]="getFooterDetails?.color">{{event?.clientName}}</mat-label><br>
          <mat-label class="body-value" [style.fontFamily]="getMainDetails?.fontName" [style.color]="getFooterDetails?.color"><a [href]="'http://'+event?.clientWebSite" [style.color]="getFooterLink?.color">{{event?.clientWebSite}}</a></mat-label><br><br>
          <mat-label class="body-value" [style.fontFamily]="getMainDetails?.fontName"><span [style.color]="getFooterTitles?.color">p.</span>&nbsp;<a [href]="'tel:'+event?.contactUsPhone" [style.color]="getFooterDetails?.color">+ 1&nbsp;{{event?.contactUsPhone | mask: '(000) 000-0000'}}</a></mat-label><br>
          <mat-label class="body-value" [style.fontFamily]="getMainDetails?.fontName"><span [style.color]="getFooterTitles?.color">e.</span>&nbsp;<a [href]="'mailto:'+event?.contactUsEmail" [style.color]="getFooterDetails?.color">{{event?.contactUsEmail}}</a></mat-label><br>
          <mat-label class="body-value" [style.fontFamily]="getMainDetails?.fontName" [style.fontSize.px]="getFooterDetails?.fontSize" [style.color]="getFooterDetails?.color" *ngIf="event?.accessabilityText">{{event?.accessabilityText}}</mat-label><br><br>
          <mat-label class="body-value" [style.fontFamily]="getMainDetails?.fontName" [style.fontSize.px]="getFooterDetails?.fontSize" [style.color]="getFooterDetails?.color" *ngIf="getFundraisingLink?.isAvailable">{{getFundraisingLink?.text}}</mat-label><br><br>
          <mat-label class="body-value" [style.fontFamily]="getMainDetails?.fontName" [style.fontSize.px]="getFooterDetails?.fontSize" [style.color]="getFooterDetails?.color" *ngIf="getFundraisingLink?.isAvailable"><a href=''>{{'BUILD.Fundraising Link' | translate}}</a></mat-label>
        </div>
      </div>

      <div #paymentFormRef></div>
      <ng-container *ngIf="isDonateFormVisible">
        <app-payment-form [event]="event" [states]="states" (paymentFinished)="onDonationFinished()" [fbTemplate]="fbTemplate"></app-payment-form>
      </ng-container>

    </div>
  </div>
</div>
