<div class="fb-options-wrapper">
  <div class="fb-options-header">
    <span></span>
    <span class="fb-options-title">{{title$ | async | translate}}</span>
    <i class="fas fa-times" (click)="close()"></i>
  </div>

  <ng-container [ngSwitch]="fbss.activeElementSettings$ | async">
    <app-donation-info-options
      *ngSwitchCase="FbElementType.DonationInfo"
      [fbss]="fbss"
      (donationInfoChanged)="onDonationInfoChanged()"
    ></app-donation-info-options>

    <app-full-name-options
      *ngSwitchCase="FbElementType.FullName"
      [fbss]="fbss"
    ></app-full-name-options>

    <app-email-options
      *ngSwitchCase="FbElementType.Email"
      [fbss]="fbss"
    ></app-email-options>

    <app-address-options
      *ngSwitchCase="FbElementType.Address"
      [fbss]="fbss"
      [type]="FbElementType.Address"
    ></app-address-options>

    <app-address-options
    *ngSwitchCase="FbElementType.EmployerMailingAddress"
    [fbss]="fbss"
    [type]="FbElementType.EmployerMailingAddress"
    >

    </app-address-options>

    <app-phone-options
      *ngSwitchCase="FbElementType.Phone"
      [fbss]="fbss"
    ></app-phone-options>

    <app-employer-options
      *ngSwitchCase="FbElementType.Occupation"
      [type]="FbElementType.Occupation"
      [fbss]="fbss"
    ></app-employer-options>
    <app-employer-options
      *ngSwitchCase="FbElementType.EmployerName"
      [type]="FbElementType.EmployerName"
      [fbss]="fbss"
    ></app-employer-options>

    <app-header-options
      *ngSwitchCase="FbElementType.Header"
      [fbss]="fbss"
    ></app-header-options>

    <app-top-image-options
      *ngSwitchCase="FbElementType.TopImage"
      [fbss]="fbss"
    ></app-top-image-options>

    <app-header-footer-banner-options
      *ngSwitchCase="FbElementType.HeaderFooter"
      [fbss]="fbss"
    ></app-header-footer-banner-options>

    <app-payment-details-options
      *ngSwitchCase="FbElementType.PaymentDetails"
      [fbss]="fbss"
    ></app-payment-details-options>

    <app-comment-options
      *ngSwitchCase="FbElementType.Comments"
      [fbss]="fbss"
    ></app-comment-options>

    <app-pac-options
      *ngSwitchCase="FbElementType.PAC"
      [fbss]="fbss"
    ></app-pac-options>
  </ng-container>
</div>
