import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild, ViewEncapsulation
} from '@angular/core';
import { BaseFormElementComponent } from '../../../../form-elements/base.form.element.component';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fb-select',
  templateUrl: './fb-select.component.html',
  styleUrls: ['../base.form.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FbSelectComponent extends BaseFormElementComponent implements OnChanges, AfterViewInit, OnInit, OnDestroy {
  @Input() public color: string;
  @Input() public fontFamily: string;
  @Input() public inputBackground: string;
  @Input() public fontSize: number;
  @Input() public isTitle: boolean = false;
  @Input() public isMatTitle: boolean = false;
  @Input() public isSearchOption: boolean = true;
  @Input() public isDisabled: boolean = false;
  @Input() public fontWeight: string = 'normal';
  @ViewChild('divElement') public container: ElementRef;

  public filterControl: FormControl = new FormControl();
  private subscription: Subscription = new Subscription();
  public options$: BehaviorSubject<FormElementDataModel[]> = new BehaviorSubject<FormElementDataModel[]>([]);

  constructor(
    private renderer: Renderer2,
    public translate: TranslateService
  ) {
    super(translate);
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.filterControl.valueChanges.pipe(debounceTime(500)).subscribe(value => {
        if (!value) {
          this.options$.next(this.selectOptions.slice());
        } else {
          value = value.toLowerCase();
          const next: FormElementDataModel[] = this.selectOptions.filter(({label}: FormElementDataModel) => label.toLowerCase().indexOf(value) === 0);
          this.options$.next(next);
        }
      })
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectOptions && changes.selectOptions.currentValue && changes.selectOptions.currentValue.length) {
      this.options$.next(this.selectOptions.slice());
    }
    if (this.container && changes.inputBackground && changes.inputBackground.currentValue) {
      this.setColor();
    }
    super.ngOnChanges(changes);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngAfterViewInit(): void {
    this.setColor();
  }

  private setColor(): void {
    const target: HTMLCollection = this.container.nativeElement.getElementsByClassName('mat-form-field-wrapper');
    target && target[0] && this.renderer.setStyle(target[0], 'background', this.inputBackground || '#fff');
  }

  public get labelStyle(): {[key: string]: string} {
    const fontSize = this.fontSize ? `${+this.fontSize * 0.75}px` : '12px';
    return {
      color: this.color || '#2c3345',
      fontFamily: this.fontFamily || 'Roboto',
      fontSize,
      fontWeight: this.fontWeight,
    };
  }

  public get titleStyle(): {[key: string]: string} {
    return {
      fontWeight: this.fontWeight,
    };
  }

}
