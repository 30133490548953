<ng-container *ngFor="let control of recurringEmailGroup.controls">
<div class="fb-page-form-row">
    <div class="page-sub-name">{{getTitle(control.get('type').value)}}</div>
</div>

<div class="fb-page-form-row">
    <div class="max-width-810">
        <app-input
                [formGroup]="control"
                name="subject"
                errorMassage="Subject *"
                label="Subject"
                placeholder="Type in the email subject"
                (valueChanged)="valueChanged.emit()"
        ></app-input>
    </div>
</div>
<div *ngIf="control.get('type').value !== recurringEmailType.ThankYou && control.get('type').value !== recurringEmailType.NextScheduledDonationDate" class="fb-page-form-row">
    <div class="max-width-810">
        <app-email-list-textarea
                [formGroup]="control"
                (valueChanged)="valueChanged.emit()"
                name="BBC"
                errorMassage="Email"
                label="BCC"
                placeholder="Type in the email address of the person who will be notified every time a form is submitted"
        ></app-email-list-textarea>
    </div>
</div>
<div class="fb-page-form-row">
    <div class="max-width-810 position-relative">
        <app-text-editor
                [formGroup]="control"
                name="email"
                (valueChanged)="valueChanged.emit()"
                errorMassage="Email"
                [placeholders]="placeholders"
                label="Email for Notification"
                placeholder="Create a notification email that gets sent to the define email address every time a form is submitted"
        ></app-text-editor>
        <div
                class="email-info-icon"
                matTooltip="The grey highlighted sections below are uneditable"
                matTooltipHideDelay="200"
                matTooltipShowDelay="200"
                matTooltipPosition="above"
                matTooltipClass="email-info-icon-tooltip"
        >
            <i class="fas fa-info-circle"></i>
        </div>
    </div>
</div>
    <div class="fb-page-form-row top-margin">
        <app-button
                label="Reset Email Body"
                type="stroked"
                color="#0064be"
                (click)="resetEmail.emit(control.get('type').value)"
        ></app-button>
    </div>
</ng-container>
