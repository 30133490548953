import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'safeCSS' })
export class SafeCSSPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }

  public transform(value: string) {
    return this.sanitized.bypassSecurityTrustStyle(value);
  }
}