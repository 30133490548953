<div class="bar-chart-wrapper" *ngIf="barChartData">
  <canvas
    baseChart
    style="width: 950px;"
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [chartType]="barChartType"
    [legend]="barChartLegend"
    [colors]="barChartColors"
  ></canvas>
</div>
<div class="widget-content" *ngIf="!barChartData">
  <div class="no-data">{{'No Available Data' | translate}}</div>
</div>
