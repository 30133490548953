import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subject } from "rxjs";
import {StripeIntentModel} from "../../models/payments/stripe.intent.model";

export abstract class BaseStripeService {
  public triggerSaveOrUpdate: Subject<void> = new Subject<void>();
  public paymentIntent: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public paymentIntentModel: StripeIntentModel = null;
  public intentID: string;
  public customerID: string;
  public paymentMethod: string;
  public nextActionType: string;
  public paymentProcessStarted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public paymentFailed: Subject<void> = new Subject<void>();

  constructor(protected toastrService: ToastrService) {}

  onInvalidPaymentIntent(message: string): void {
    this.showErrorMessage(message);
    this.paymentProcessStarted.next(false);
    this.paymentIntent.next(null);
    this.paymentFailed.next();
    this.intentID = null;
  }

  private showErrorMessage(message: string): void {
    this.toastrService.error(message, 'Error');
  }
}
