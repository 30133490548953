import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './app-color-picker.component.html',
  styleUrls: ['./app-color-picker.component.scss']
})
export class AppColorPickerComponent {
  @Input() public color: any;
  @Input() public isDisabled: boolean = false;
  @Input() public label: string;
  @Input() public position = 'auto';
  @Input() public presetColors: string[] = [];
  @Output() public changeColor: EventEmitter<string> = new EventEmitter<string>();
  @Output() public closeWindow: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  public colorChange(event: string): void {
    if (!this.isDisabled) {
      this.changeColor.emit(event);
    }
  }

  public colorClose(): void {
    this.closeWindow.emit();
  }
}