import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../routes/admin/admin-confirmation/admin-confirmation.component';
import { DialogBeforeunloadResponseType } from '../../models/enum/dialog.beforeunload.response.type';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { IconsType } from '../../models/enum/icons.type';


@Component({
  selector: 'app-before-unload',
  templateUrl: './before-unload.component.html',
  styleUrls: ['./before-unload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BeforeUnloadComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public IconsType = IconsType;
  constructor(
    public dialogRef: MatDialogRef<BeforeUnloadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onAccept(): void {
    this.dialogRef.close(DialogBeforeunloadResponseType.Accept);
  }

  public onReject(): void {
    this.dialogRef.close(DialogBeforeunloadResponseType.Reject);
  }

  public onClose(): void {
    this.dialogRef.close(DialogBeforeunloadResponseType.Close);
  }
}
