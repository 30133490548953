export enum SocialProviderEnum {
  Twitter = 1,
  // eslint-disable-next-line no-bitwise
  Facebook = 1 << 1,
  // eslint-disable-next-line no-bitwise
  Instagram = 1 << 2,
  // eslint-disable-next-line no-bitwise
  Linkedin = 1 << 3,
  // eslint-disable-next-line no-bitwise
  YouTube = 1 << 4
}
