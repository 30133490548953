<div [ngStyle]="{'height': data.useAuthorizedPartyNameInput ? '380px': '280px'}" class="recurring-action-form-wrapper">
  <h1 class="dialog-title">{{data.title}}</h1>
  <div class="recurring-action-form-field">
    <app-input
      [formGroup]="actionInfoForm"
      name="formReason"
      label="Reason"
      errorMassage="Reason"
    ></app-input>
  </div>
  <div class="recurring-action-form-field">
    <app-select
            [formGroup]="actionInfoForm"
            [selectOptions]="selectOptions"
            name="formAuthorizedBy"
            label="Authorized By"
            errorMassage="Authorized By"
    ></app-select>
  </div>
  <div class="recurring-action-form-field" *ngIf="data.useAuthorizedPartyNameInput">
    <app-input
      [formGroup]="actionInfoForm"
      name="customer"
      label="Authorized Party Name"
      errorMassage="Authorized Party Name"
    ></app-input>
  </div>

  <div class="d-flex justify-content-end">
    <div class="mr-4">
      <app-button
        type="stroked"
        color="#0064be"
        [disabled]="!actionInfoForm.valid"
        (click)="onApprove()">
        {{"Approve" | uppercase}}</app-button>
    </div>
    <div>
      <app-button
              type="stroked"
              color="#C3120E"
              (click)="onCancel()">{{'Cancel' | translate | uppercase}}
      </app-button>
    </div>
  </div>
</div>

