import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TemplateManagementService } from '../../../../../services/templates/template.management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DISPLAY_COUNT_OPTIONS, FONTS } from '../../../../../constants';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import MjmlElementModel from '../../../../../models/templates/mjml.element.model';
import { MjmlTag } from '../../../../../models/templates/mjml.tag.type';
import { MjmlCustomType } from '../../../../../models/templates/mjml.custom.type';
import { TemplateType } from '../../../../../models/templates/template.type';

@Component({
  selector: 'app-comment-wall-settings',
  templateUrl: './comment-wall-settings.component.html',
  styleUrls: ['../../tool-settings.scss']
})
export class CommentWallSettingsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() public tms: TemplateManagementService;

  public displayCountOptions = DISPLAY_COUNT_OPTIONS;
  public fontFamilyOptions: FormElementDataModel[] = FONTS;

  public form: FormGroup = this.formBuilder.group({
    fontFamily: 'Roboto',
    fontSize: '',
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]],
    displayCount: '10'
  });

  constructor(
    private formBuilder: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.setInitialValues();
    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (!element || element.tagName !== MjmlTag.text || element.attributes['customType'] !== MjmlCustomType.commentWall) {
          return;
        }
        this.setInitialValues();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setInitialValues(): void {
    const attributes = this.tms.activeElement.attributes;
    const fontFamily = attributes['font-family'] || 'Roboto';
    const fontSize = attributes['font-size'] || '14px';
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    const displayCount = attributes['display-count'] || '10';

    this.form.get('fontFamily').setValue(fontFamily);
    this.form.get('fontSize').setValue(fontSize.slice(0, -2));
    this.form.get('paddingBottom').setValue(paddingBottom.slice(0, -2));
    this.form.get('paddingLeft').setValue(paddingLeft.slice(0, -2));
    this.form.get('paddingRight').setValue(paddingRight.slice(0, -2));
    this.form.get('paddingTop').setValue(paddingTop.slice(0, -2));
    this.form.get('displayCount').setValue(displayCount);
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public onFontFamilyChanged(): void {
    const value = this.form.get('fontFamily').value;
    this.tms.activeElement.attributes['font-family'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onFontSizeChanged(): void {
    const value = this.form.get('fontSize').value;
    this.tms.activeElement.attributes['font-size'] = value ? `${value}px` : '8px';
    this.tms.emitSave(true);
  }

  public onPaddingTopChanged(): void {
    const value = this.form.get('paddingTop').value;
    this.tms.activeElement.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.form.get('paddingRight').value;
    this.tms.activeElement.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.form.get('paddingBottom').value;
    this.tms.activeElement.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.form.get('paddingLeft').value;
    this.tms.activeElement.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onDisplayCountChanged(): void {
    const value = this.form.get('displayCount').value;
    this.tms.activeElement.attributes['display-count'] = value ? value : '10';
    this.tms.emitSave(true);
    this.tms.updateCommentWallDisplayCount();
  }
}
