<div class="payment-summary" *ngIf="result">
  <div class="total-column">
    <div class="empty-block"></div>
    <ng-container *ngIf="paymentSummarySettings.amounts.ticketsAmount?.amount">
      <span [ngStyle]="{color: fbTemplate.fontColor}">{{"Tickets" | translate}}</span>
      <span [ngStyle]="{color: fbTemplate.fontColor}">{{"Fee" | translate}}</span>
    </ng-container>
    <ng-container *ngIf="!(paymentSummarySettings.isRecurring && paymentSummarySettings.amounts.ticketsAmount?.amount)">
      <ng-container *ngIf="paymentSummarySettings.amounts.donationAmount?.amount">
        <span [ngStyle]="{color: fbTemplate.fontColor}">{{"Donation" | translate}}</span>
        <span [ngStyle]="{color: fbTemplate.fontColor}">{{"Donation Fee" | translate}}</span>
      </ng-container>
    </ng-container>
    <span class="total-column-title" [ngStyle]="{color: fbTemplate.fontColor}">{{'BUILD.Total Cost' | translate}}</span>
  </div>
  <div class="total-column">
    <span class="total-column-title" [ngStyle]="{color: fbTemplate.fontColor}">{{'BUILD.Cost' | translate}}</span>
    <ng-container *ngIf="paymentSummarySettings.amounts.ticketsAmount?.amount">
      <span [ngStyle]="{color: fbTemplate.fontColor}">$ {{paymentSummarySettings.amounts.ticketsAmount?.amount | number:'.2'}}</span>
      <span [ngStyle]="{color: fbTemplate.fontColor}">$ {{result.tickets.fee | number:'1.2-2'}}</span>
    </ng-container>
    <ng-container *ngIf="!(paymentSummarySettings.isRecurring && paymentSummarySettings.amounts.ticketsAmount?.amount)">
      <ng-container *ngIf="paymentSummarySettings.amounts.donationAmount?.amount">
        <span [ngStyle]="{color: fbTemplate.fontColor}">$ {{paymentSummarySettings.amounts.donationAmount?.amount | number:'.2'}}</span>
        <span [ngStyle]="{color: fbTemplate.fontColor}">$ {{result.donation.fee | number:'1.2-2'}}</span>
      </ng-container>
    </ng-container>
    <span class="total-column-title total-result" [ngStyle]="{color: fbTemplate.fontColor}">$ {{total | number:'.2'}}</span>
  </div>
</div>