<div class="fb-options-wrapper">
  <div class="fb-options-header">
    <span></span>
    <span class="fb-options-title">{{'BUILD.Form Design' | translate}}</span>
    <i class="fas fa-times" (click)="close()"></i>
  </div>

  <mat-tab-group
    mat-align-tabs="center"
    (selectedIndexChange)="tabIndexChanged($event)"
  >
    <mat-tab label="{{'BUILD.Colors' | translate}}" >
      <div class="fb-options-row">
        <app-input
          class="width-100 non-padding"
          [formGroup]="optionsForm"
          name="pageColor"
          [colorPiker]="true"
          label='Page Color'
          errorMassage="field"
          (inputEvent)="onPageColorChanged()"
        ></app-input>
      </div>

      <div class="fb-options-row" *ngIf="!isShowUploadImg">
        <app-button
          class="edit-img-btn bg-image"
          type="flat"
          color="#0064be"
          width="302"
          (click)="showUploadImg()"
          [isBgImageBtn]="true"
        >
          <svg width="24" height="24" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g data-name="Layer 2">
              <g data-name="image">
                <rect width="24" height="24" opacity="0"/>
                <path d="M18 3H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3zM6 5h12a1 1 0 0 1 1 1v8.36l-3.2-2.73a2.77 2.77 0 0 0-3.52 0L5 17.7V6a1 1 0 0 1 1-1zm12 14H6.56l7-5.84a.78.78 0 0 1 .93 0L19 17v1a1 1 0 0 1-1 1z"/>
                <circle cx="8" cy="8.5" r="1.5"/>
              </g>
            </g>
          </svg>
          <span>
            {{'BUILD.Upload image for background of page' | translate}}
          </span>
        </app-button>
      </div>

      <div class="upload-img-wrapper" *ngIf="isShowUploadImg">
        <div class="fb-options-row">
          <div class="fb-row-element-full">
            <app-image-loader
                [formGroup]="optionsForm"
                name="formBgImg"
                label="Upload Background Image"
                [readOnly]="readOnly"
                [imageURL]="imageURL"
                (valueChanged)="imageUrlChanged($event); onUrlChanged()"
            ></app-image-loader>
          </div>
        </div>

        <div class="fb-options-row">
          <div class="fb-row-element-full">
            <app-input
              class="non-padding"
              label="Image URL"
              [formGroup]="optionsForm"
              name="formBgImg"
              errorMassage="Image URL"
              [low]="true"
              [disabled]="readOnly"
              (valueChanged)="imageUrlChanged($event); onUrlChanged()"
            ></app-input>
          </div>
        </div>

        <div class="fb-options-row">
          <div class="max-width-145 right-margin">
            <app-select
              label="Repeat Background?"
              class="non-padding"
              [formGroup]="optionsForm"
              name="backgroundRepeat"
              [low]="true"
              [selectOptions]="backgroundRepeatOptions"
              [disabled]="readOnly"
              (valueChanged)="onBackgroundRepeatChanged()"
            ></app-select>
          </div>
          <div class="max-width-145">
            <app-select
              label="Scale background image"
              class="non-padding"
              [formGroup]="optionsForm"
              name="backgroundSize"
              [low]="true"
              [selectOptions]="backgroundSizeOptions"
              [disabled]="readOnly"
              (valueChanged)="onBackgroundSizeChanged()"
            ></app-select>
          </div>
        </div>

        <div class="fb-options-row">
          <app-button
            class="edit-img-btn"
            type="basic"
            label="Delete Image"
            (clickEvent)="deleteImg()"
            color="#F44336"
            width="310"
          >
          </app-button>
        </div>
        <div class="fb-row-element-full">
          <hr class="line"/>
        </div>
      </div>

      <div class="fb-options-row">
        <app-input
          class="width-100 non-padding"
          [formGroup]="optionsForm"
          name="formColor"
          [colorPiker]="true"
          label='Form Color'
          errorMassage="field"
          (inputEvent)="onFormColorChanged()"
        ></app-input>
      </div>

      <div class="fb-options-row">
        <app-input
          class="width-100 non-padding"
          [formGroup]="optionsForm"
          name="fontColor"
          [colorPiker]="true"
          label='Font Color'
          errorMassage="field"
          (inputEvent)="onFontColorChanged()"
        ></app-input>
      </div>

      <div class="fb-options-row">
        <app-input
          class="width-100 non-padding"
          [formGroup]="optionsForm"
          name="inputBackground"
          [colorPiker]="true"
          label='Input Background'
          errorMassage="field"
          (inputEvent)="onInputBackgroundChanged()"
        ></app-input>
      </div>

      <div class="padding-source"></div>
    </mat-tab>

    <mat-tab label="{{'BUILD.Styles' | translate}}">
      <div class="fb-options-row">
        <div class="fb-row-element-full">
          <app-slider
            [formGroup]="optionsForm"
            name="width"
            label="Form Width (px)"
            [min]="350"
            [max]="1200"
            [step]="10"
            [tickInterval]="10"
            (valueChanged)="onWidthChanged()"
            [disabled]="readOnly"
          ></app-slider>
        </div>
      </div>

      <div class="fb-options-row">
        <div class="fb-row-element-full">
          <app-slider
            [formGroup]="optionsForm"
            name="spacing"
            label="Question Spacing (px)"
            [min]="1"
            [max]="100"
            [step]="1"
            [tickInterval]="5"
            (valueChanged)="onSpacingChanged()"
            [disabled]="readOnly"
          ></app-slider>
        </div>
      </div>

      <div class="fb-options-row">
        <div class="fb-row-element right-margin">
          <app-select
            label="Font Family"
            [formGroup]="optionsForm"
            name="fontFamily"
            [selectOptions]="fontFamilyOptions"
            [low]="true"
            [disabled]="readOnly"
            (valueChanged)="onFontFamilyChanged()"
          ></app-select>
        </div>

        <div class="fb-row-element">
          <app-select
            label="Font Size"
            [formGroup]="optionsForm"
            name="fontSize"
            [selectOptions]="fontSizeOptions"
            [low]="true"
            [disabled]="readOnly"
            (valueChanged)="onFontSizeChanged()"
          ></app-select>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="{{'BUILD.Buttons' | translate}}">

      <div class="fb-options-row">
        <div class="fb-options-row-subtitle">{{'BUILD.Buttons Labels' | translate}}</div>
      </div>

      <div class="fb-options-row sublabels-row">
        <div class="fb-row-element right-margin sublabel">{{'BUILD.Next' | translate}}</div>
        <div class="fb-row-element">
          <app-input
            class="width-100"
            [formGroup]="optionsForm"
            name="nextButtonLabel"
            [low]="true"
            (valueChanged)="onNextButtonLabelChanged()"
            [disabled]="readOnly"
          ></app-input>
        </div>
      </div>

      <div class="fb-options-row sublabels-row">
        <div class="fb-row-element right-margin sublabel">{{'BUILD.Donate' | translate}}</div>
        <div class="fb-row-element">
          <app-input
            class="width-100"
            [formGroup]="optionsForm"
            name="donateButtonLabel"
            [low]="true"
            (valueChanged)="onDonateButtonLabelChanged()"
            [disabled]="readOnly"
          ></app-input>
        </div>
      </div>

      <div class="fb-options-row">
        <app-input
          class="width-100"
          [formGroup]="optionsForm"
          name="primaryButtonColor"
          [colorPiker]="true"
          label='"Next" / "Donate" / "Back" Buttons Color'
          errorMassage="field"
          (inputEvent)="onPrimaryButtonColorChanged()"
        ></app-input>
      </div>

      <div class="fb-options-row">
        <app-input
          class="width-100"
          [formGroup]="optionsForm"
          name="primaryTextButtonColor"
          [colorPiker]="true"
          label='"Next" / "Donate" / "Back" Text Color'
          errorMassage="field"
          (inputEvent)="onPrimaryTextButtonColorChanged()"
        ></app-input>
      </div>

      <div class="fb-options-row">
        <app-input
          class="width-100"
          [formGroup]="optionsForm"
          name="secondaryButtonColor"
          [colorPiker]="true"
          label='Selectors Color'
          errorMassage="field"
          (inputEvent)="onSecondaryButtonColorChanged()"
        ></app-input>
      </div>

      <div class="fb-options-row">
        <app-input
          class="width-100"
          [formGroup]="optionsForm"
          name="secondaryTextButtonColor"
          [colorPiker]="true"
          label='Selectors Text Color'
          errorMassage="field"
          (inputEvent)="onSecondaryTextButtonColorChanged()"
        ></app-input>
      </div>

      <div class="fb-options-row">
        <app-input
          class="width-100"
          [formGroup]="optionsForm"
          name="borderCheckboxColor"
          [colorPiker]="true"
          label='Border Checkbox Color'
          errorMassage="field"
          (inputEvent)="onBorderCheckboxColorChanged()"
        ></app-input>
      </div>

      <div class="fb-options-row">
        <app-input
          class="width-100"
          [formGroup]="optionsForm"
          name="donationBubbleDefaultColor"
          [colorPiker]="true"
          label='Donation Bubble Default Color'
          errorMassage="field"
          (inputEvent)="onDonationBubbleDefaultColorChanged()"
        ></app-input>
      </div>

      <div class="padding-source"></div>
    </mat-tab>
  </mat-tab-group>
</div>
