import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import CountryModel from '../../models/internationalization/country.model';
import FormElementDataModel from '../../models/form.element.data.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TerritorialEntityType } from '../../models/internationalization/territorial.entity.type';
import { LocalityType } from '../../models/internationalization/locality.type';
import { PoliticalUnitType } from '../../models/internationalization/political.unit.type';
import { CodeType } from '../../models/internationalization/code.type';
import FbTemplateModel, { FbElementModel, FbElementType } from '../../models/form-builder/fb.template.model';
import { CODE, LOCALITY, POLITICAL_UNIT, TERRITORIAL_ENTITY } from '../../constants';

const TERRITORIAL_CONTROLS = {
  [TerritorialEntityType.AreaDistrict]: 'areaDistrict',
  [TerritorialEntityType.CEDEX]: 'cedex',
  [TerritorialEntityType.District]: 'district',
  [TerritorialEntityType.Emirate]: 'emirate',
  [TerritorialEntityType.Neighborhood]: 'neighborhood',
  [TerritorialEntityType.Oblast]: 'oblast',
  [TerritorialEntityType.Prefecture]: 'prefecture',
  [TerritorialEntityType.Townland]: 'townland'
};

const LOCALITY_CONTROLS = {
  [LocalityType.City]: 'city',
  [LocalityType.CityCountry]: 'cityCountry',
  [LocalityType.PostTown]: 'postTown',
  [LocalityType.Suburb]: 'suburb',
  [LocalityType.SuburbCity]: 'suburbCity',
  [LocalityType.VillageTownshipCity]: 'villageTownshipCity'
};

const POLITICAL_UNIT_CONTROLS = {
  [PoliticalUnitType.Department]: 'department',
  [PoliticalUnitType.DoSi]: 'doSi',
  [PoliticalUnitType.Island]: 'island',
  [PoliticalUnitType.Parish]: 'parish',
  [PoliticalUnitType.Province]: 'province',
  [PoliticalUnitType.State]: 'state'
};

const CODE_CONTROLS = {
  [CodeType.EIRcode]: 'eirCode',
  [CodeType.PinCode]: 'pinCode',
  [CodeType.PostalCode]: 'postalCode',
  [CodeType.ZipCode]: 'zipCode'
};

@Injectable()
export class FbCountryStateService {
  public countryState: BehaviorSubject<Partial<CountryModel>> = new BehaviorSubject<Partial<CountryModel>>(null);
  public countries: CountryModel[];
  public countriesOptions: FormElementDataModel[] = [];
  public template$: BehaviorSubject<FbTemplateModel>;

  private form: FormGroup;

  public territorialEntity$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public territorialEntityError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showTerritorialEntity$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public city$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public cityError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showCity$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public state$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public stateError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isUsa$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public zip$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public zipError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showZip$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public zipMask$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public zipPlaceholder$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public isFirstChange: boolean = true;
  public phonePrefix$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public phoneMask$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public phonePlaceholder$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public isAddressRequired$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  constructor() {
    this.countryState.subscribe((nextState: Partial<CountryModel>) => {
      if (!nextState) {
        return;
      }
      const {territorialEntityType, localityType, politicalUnitType, name, codeType, phoneCode = '1'}: Partial<CountryModel> = nextState;

      const addressTemplateElement = this.template$?.getValue().paymentPage.elements
        .find(({type}: FbElementModel) => type === FbElementType.Address);
      const addressAttributes = addressTemplateElement?.attributes;

      this.isUsa$.next(name === 'USA');

      this.territorialEntity$.next(addressAttributes[TERRITORIAL_CONTROLS[territorialEntityType]]);
      this.territorialEntityError$.next(TERRITORIAL_ENTITY[territorialEntityType]);
      const isTerritorialEntityActive = territorialEntityType !== TerritorialEntityType.Unknown;
      this.showTerritorialEntity$.next(isTerritorialEntityActive);
      const territorialEntity = this.getControl('territorialEntity');
      !this.isFirstChange && territorialEntity?.reset();
      territorialEntity && (isTerritorialEntityActive ? territorialEntity.enable() : territorialEntity.disable());

      this.city$.next(addressAttributes[LOCALITY_CONTROLS[localityType]]);
      this.cityError$.next(LOCALITY[localityType]);
      const isCityActive = localityType !== LocalityType.Unknown;
      this.showCity$.next(isCityActive);
      const city = this.getControl('city');
      !this.isFirstChange && city.reset();
      city && (isCityActive ? city.enable() : city.disable());

      this.state$.next(addressAttributes[POLITICAL_UNIT_CONTROLS[politicalUnitType]]);
      this.stateError$.next(POLITICAL_UNIT[politicalUnitType]);
      const isStateActive = politicalUnitType !== PoliticalUnitType.Unknown;
      this.showState$.next(isStateActive);
      const state = this.getControl('state');
      !this.isFirstChange && state.reset();
      state && (isStateActive ? state.enable() : state.disable());

      this.zip$.next(addressAttributes[CODE_CONTROLS[codeType]]);
      this.zipError$.next(CODE[codeType]);
      const isZipActive = codeType !== CodeType.Unknown;
      this.showZip$.next(isZipActive);
      const zipCode = this.getControl('zipCode');
      !this.isFirstChange && zipCode.reset();
      zipCode && (isZipActive ? zipCode.enable() : zipCode.disable());
      if ((name === 'USA' || name.startsWith('Puerto')) && zipCode) {
      this.isAddressRequired$.getValue() ? zipCode.setValidators([Validators.required, Validators.pattern('^(\\d{5}|\\d{9})$')]) : zipCode.setValidators([Validators.pattern('^(\\d{5}|\\d{9})$')]);
      } else {
        this.isAddressRequired$.getValue() ? zipCode.setValidators([Validators.required, Validators.maxLength(30)]) : zipCode.setValidators([Validators.maxLength(30)]);
      }
      this.zipMask$.next((name === 'USA' || name.startsWith('Puerto')) ? '00000-9999' : '');
      this.zipPlaceholder$.next((name === 'USA' || name.startsWith('Puerto')) ? '00000-0000' : '');
      zipCode && zipCode.updateValueAndValidity();

      const prefix = `+${phoneCode} `;
      this.phonePrefix$.next(prefix);
      const mask = name === 'USA' ? '(000) 000-0000' : '00000000999999999999'.slice(0, -phoneCode.length);
      this.phoneMask$.next(mask);
      this.phonePlaceholder$.next(prefix);
      const phone = this.getControl('phone');
      !this.isFirstChange && phone?.reset();

      !this.isFirstChange && this.getControl('streetAddress')?.reset();

      /*const isPhoneRequired = this.template$.getValue().paymentPage.elements
        .find(({type}: FbElementModel) => type === FbElementType.Phone).required;
      phone && (isPhoneRequired ? phone.setValidators([Validators.required]) : phone.clearValidators());
      phone && phone.updateValueAndValidity();*/
      this.form && this.form.updateValueAndValidity();
      this.isFirstChange = false;
    })
  }

  public storeCountries(countries: CountryModel[]): void {
    this.countries = countries;
    this.countriesOptions = countries.map(({name, id}: CountryModel) => ({label: name, value: id}));
  }

  public setForm(form: FormGroup): void {
    this.form = form;
  }

  public setTemplate(template: BehaviorSubject<FbTemplateModel>): void {
    this.template$ = template;
  }

  private getControl(controlName: string): FormControl {
    if (!this.form) {
      return null;
    }
    return this.form.get(controlName) as FormControl;
  }

  public setState(state: Partial<CountryModel>): void {
    this.countryState.next(state);
  }

  public setCountryById(id: number): void {
    if (id === 0) {
      id = 1;
    }
    const model = this.countries.find(country => country.id === +id);
    this.setState(model ? model : null);
  }

  public setDefaultCountry(): void {
    this.setCountryById(1);
  }

  public addUpdateAddressSubscription(subject: Subject<void>): void {
    subject.subscribe(() => {
      const {territorialEntityType, localityType, politicalUnitType, codeType}: Partial<CountryModel> = this.countryState.getValue();

      const addressTemplateElement = this.template$.getValue().paymentPage.elements
        .find(({type}: FbElementModel) => type === FbElementType.Address);
      const addressAttributes = addressTemplateElement.attributes;
      this.territorialEntity$.next(addressAttributes[TERRITORIAL_CONTROLS[territorialEntityType]]);
      this.city$.next(addressAttributes[LOCALITY_CONTROLS[localityType]]);
      this.state$.next(addressAttributes[POLITICAL_UNIT_CONTROLS[politicalUnitType]]);
      this.zip$.next(addressAttributes[CODE_CONTROLS[codeType]]);
    });
  }
}
