import { Injectable } from '@angular/core';

declare var gapi: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {
  constructor() { }

  public initClient(config, callback): void {
    return gapi.load('client', () => {
      gapi.client.init({
        clientId: config.clientId,
        discoveryDocs: config.discoveryDocs,
        scope: config.scope
      }).then(callback.bind(this));
    });
  }

  public login(): Promise<any> {
    const googleAuth = gapi.auth2.getAuthInstance();
    return googleAuth.signIn();
  }

  signIn(config, callback) {
    const option = new gapi.auth2.SigninOptionsBuilder();
    option.setScope(config.scope);
    const googleUser = gapi.auth2.getAuthInstance().currentUser.get();
    googleUser.grant({ scope: option }).then(
      () => {
        callback();
      }
    );
  }

  public getGoogleApiClient(): any {
    return gapi;
  }

  public isSignedIn(): boolean {
    if (gapi.auth2 && gapi.auth2.getAuthInstance())
      return gapi.auth2.getAuthInstance().isSignedIn.get();
    return false;
  }
}
