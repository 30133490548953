<div class="back-button-div">
  <button mat-button *ngIf="isPreview" class="back-button" (click)="back()">{{'BACK' | translate}}</button>
</div>
<div class="example-container">
  <div class="example">
    <img class="example-header" alt="Header Image" *ngIf="hasImage(0)" [src]="image(0)" />
    <div class="example-main" [style.background]="mainBackground?.color">
      <div class="title" [style.fontSize.px]="mainTitle?.fontSize" [style.color]="mainTitle?.color">
        {{campaign?.name}}
      </div>
      <div class="title-description" [style.fontSize.px]="titleDescriptionSize" [style.color]="mainTitleDescription?.color">
        {{mainTitleDescription?.text}}
      </div>
      <div class="text" [style.fontSize.px]="mainBody?.fontSize" [style.color]="mainBody?.color" [innerHTML]="mainBody?.text | safeHTML"></div>
      <div class="images" *ngIf="imagesCount > 0">
        <div class="upload-div" *ngIf="hasImage(1)">
          <img [src]="image(1)" alt="Image">
        </div>
        <div class="upload-div">
          <img [src]="image(2)" alt="Image">
        </div>
        <div class="upload-div" *ngIf="imagesCount > 2">
          <img [src]="image(3)" alt="Image">
        </div>
      </div>
      <div class="images" *ngIf="imagesCount > 3">
        <div class="upload-div">
          <img [src]="image(4)" alt="Image">
        </div>
        <div class="upload-div">
          <img [src]="image(5)" alt="Image">
        </div>
        <div class="upload-div">
          <img [src]="image(6)" alt="Image">
        </div>
      </div>
      <div class="text" [style.fontSize.px]="mainBody2?.fontSize" [style.color]="mainBody2?.color" [innerHTML]="mainBody2?.text | safeHTML"></div>
      <div style="display: flex;">
        <button *ngIf="registerButton?.isAvailable" mat-button class="body-button" [style.fontSize.px]="registerButton?.fontSize" [style.background]="registerButton?.color" (click)="registerCampaign()">{{'BUILD.REGISTER' | translate}}</button>
        <button *ngIf="donateButton?.isAvailable" mat-button class="body-button" [style.fontSize.px]="donateButton?.fontSize" [style.background]="donateButton?.color" (click)="donateCampaign()">{{'BUILD.MAKE A DONATION' | translate}}</button>
      </div>
      <div *ngIf="signature?.isAvailable" class="signature">
        <div style="text-align: center;">
          <img [src]="image(7)">
        </div>
        <div [style.fontSize.px]="mainBody?.fontSize" class="signature-text" [style.color]="mainBody?.color">
          {{signature?.text}}
        </div>
      </div>
      <div class="text" [innerHTML]="buttons?.text | safeHTML"></div>
    </div>
    <div class="example-footer" [style.background]="footerBackground?.color">
      <div class="contact">
        <div>
          <div [style.color]="footerName?.color" [style.fontSize.px]="footerName?.fontSize">
            {{footerName?.text}}
          </div>
          <div [style.color]="footerPosition?.color">
            {{footerPosition?.text}}
          </div>
          <div *ngIf="footerPhone?.text !== '1'" [style.color]="footerPhone?.color" [style.fontSize.px]="footerPhone?.fontSize">
            p <a [href]="'tel:' + footerPhone?.text">+1 {{footerPhone?.text.slice(1) | mask: '(000) 000-0000'}}</a>
          </div>
          <div *ngIf="footerEmail?.text" [style.color]="footerEmail?.color" [style.fontSize.px]="footerEmail?.fontSize">
            e <a [href]="'mailto:' + footerEmail?.text">{{footerEmail?.text}}</a>
          </div>
        </div>
      </div>
      <div class="social">
        <div class="social-icon" *ngIf="headerBackground?.text">
          <a [href]="headerBackground?.text"><img src="/assets/icons/facebook.svg"></a>
        </div>
        <div class="social-icon" *ngIf="mainBackground?.text">
          <a [href]="mainBackground?.text"><img src="/assets/icons/instagram.svg"></a>
        </div>
        <div class="social-icon" *ngIf="mainTitle?.text">
          <a [href]="mainTitle?.text"><img src="/assets/icons/twitter.svg"></a>
        </div>
      </div>
      <div class="information">
        {{footerBackground?.text}}
      </div>
      <div *ngIf="footerFundraiser?.isAvailable">
        <p>{{footerFundraiser?.text}}</p>
        <a href="">{{'BUILD.Fundraising Link' | translate}}</a>
      </div>
      <div class="unsubscribe">
        {{client?.name}} {{client?.phisicalStreet}} {{client?.phisicalAddress}} {{client?.phisicalCity}} {{state}} {{client?.phisicalPostCode | mask: '00000-0000' }}
      </div>
      <div class="unsubscribe">
        {{'BUILD.If you want to unsubscribe from these emails, please click here' | translate}} <a href='/'>{{'BUILD.Unsubscribe' | translate}}</a>
      </div>
    </div>
  </div>
</div>
