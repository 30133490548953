<div
  [ngClass]="low ? 'low-time-input-component-container-wrapper' : 'time-input-component-container-wrapper'"
  *ngIf="!isNew"
>
  <label class="label-name" [for]="id" [class.noWrap]="isNoWrap"
    >{{ label | translate
    }}<i
      *ngIf="toolTipText"
      class="fas fa-info-circle"
      [matTooltip]="toolTipText | translate"
      matTooltipHideDelay="200"
      matTooltipShowDelay="200"
      matTooltipPosition="below"
      matTooltipClass="table-tooltip"
    ></i
  ></label>
  <div class="time-input-container" [class.time-input-error]="formControl.touched && formControl.invalid">
    <div class="time-input-fields">
      <input
        type="text"
        class="time-input-field hours"
        mask="90"
        placeholder="{{'HH' | translate}}"
        (keyup)="hoursKeyPressed($event)"
        (change)="hoursChanged()"
        [(ngModel)]="hours"
        [id]="id"
        [disabled]="disabled"
      >
      <span class="time-input-field-divider">:</span>
      <input
        type="text"
        class="time-input-field"
        mask="90" placeholder="MM"
        (keyup)="minuteKeyPressed($event)"
        (change)="minutesChanged()"
        [(ngModel)]="minutes"
        [disabled]="disabled"
        #minutesRef
      >
      <span class="time-input-field-divider"> </span>
      <input
        type="text"
        class="time-input-field"
        [(ngModel)]="dayPeriod"
        (keyup)="amPmKeyPressed($event)"
        readonly
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="menu"
        [disabled]="disabled"
      >
    </div>

    <div>
      <ngx-material-timepicker-toggle [for]="toggleIcon" [disabled]="disabled">
        <div class="toggle-icon" ngxMaterialTimepickerToggleIcon>
          <i class="far fa-clock"></i>
        </div>
      </ngx-material-timepicker-toggle>
      <ngx-material-timepicker #toggleIcon></ngx-material-timepicker>
    </div>


  </div>

  <div [formGroup]="formGroup">
    <input
      type="hidden"
      [formControlName]="name"
      [ngxTimepicker]="toggleIcon"
    >
  </div>


  <div class="error-message">
    <span *ngIf="formControl.touched && formControl.invalid">{{getErrorMessage()}}</span>
  </div>

</div>


<!--<mat-menu #menu="matMenu" overlapTrigger>-->
<!--  <span mat-menu-item (keyup.enter)="setDayPeriod('am')" (click)="setDayPeriod('am')">{{'am' | translate}}</span>-->
<!--  <span mat-menu-item (keyup.enter)="setDayPeriod('pm')" (click)="setDayPeriod('pm')">{{'pm' | translate}}</span>-->
<!--</mat-menu>-->

<!--<ngx-material-timepicker-->
<!--  #timePicker-->
<!--  (timeSet)="onDateChange($event)"-->
<!--&gt;</ngx-material-timepicker>-->

<!--NEW-->
<div
  [ngClass]="low ? 'low-time-input-component-container-wrapper' : 'new-time-input-component-container-wrapper'"
  *ngIf="isNew"
>
  <label class="label-name" [ngClass]="isNoWrap && 'noWrap'" [for]="id"
  >{{ label | translate
    }}<i
      *ngIf="toolTipText"
      class="fas fa-info-circle"
      [matTooltip]="toolTipText | translate"
      matTooltipHideDelay="200"
      matTooltipShowDelay="200"
      matTooltipPosition="below"
      matTooltipClass="table-tooltip"
    ></i
    ></label>
  <div class="time-input-container" [class.time-input-error]="formControl.touched && formControl.invalid">
    <div class="time-input-fields">
      <input
        type="text"
        class="time-input-field hours"
        mask="90"
        placeholder="{{'HH' | translate}}"
        (keyup)="hoursKeyPressed($event)"
        (change)="hoursChanged()"
        [(ngModel)]="hours"
        [id]="id"
        [disabled]="disabled"
      >
      <span class="time-input-field-divider">:</span>
      <input
        type="text"
        class="time-input-field"
        mask="90" placeholder="MM"
        (keyup)="minuteKeyPressed($event)"
        (change)="minutesChanged()"
        [(ngModel)]="minutes"
        [disabled]="disabled"
        #minutesRef
      >
      <span class="time-input-field-loc"> </span>
      <input
        type="text"
        class="time-input-field"
        [(ngModel)]="dayPeriod"
        (keyup)="amPmKeyPressed($event)"
        readonly
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="menu"
        [disabled]="disabled"
      >
    </div>

    <div>
      <ngx-material-timepicker-toggle [for]="toggleIcon" [disabled]="disabled">
        <div class="toggle-icon" ngxMaterialTimepickerToggleIcon>
          <app-icons
            class="time-icon"
            [iconType]="14"
            [color]="'#0064BE'"
          >
          </app-icons>
        </div>
      </ngx-material-timepicker-toggle>
      <ngx-material-timepicker #toggleIcon></ngx-material-timepicker>
    </div>


  </div>

  <div [formGroup]="formGroup">
    <input
      type="hidden"
      [formControlName]="name"
      [ngxTimepicker]="toggleIcon"
    >
  </div>

<!--  <div class="error-message">-->
<!--    <span *ngIf="formControl.touched && formControl.invalid">{{getErrorMessage()}}</span>-->
<!--  </div>-->


  <div class="error-message" *ngIf="formControl.touched && formControl.invalid">
    <app-icons
      class="icon"
      [iconType]="24"
      [width]="13.33"
      [height]="13.33"
      [color]="'#F44336'"
    >
    </app-icons>
    <span>{{getErrorMessage()}}</span>
  </div>


</div>


<mat-menu #menu="matMenu" overlapTrigger>
  <span mat-menu-item (keyup.enter)="setDayPeriod('am')" (click)="setDayPeriod('am')">{{'am' | translate}}</span>
  <span mat-menu-item (keyup.enter)="setDayPeriod('pm')" (click)="setDayPeriod('pm')">{{'pm' | translate}}</span>
</mat-menu>

<ngx-material-timepicker
    #timePicker
    (timeSet)="onDateChange($event)"
></ngx-material-timepicker>

