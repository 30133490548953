import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { flatMap } from 'rxjs/operators';
import { BaseModelService } from '../base.model.service';
import MjmlElementModel from '../../models/templates/mjml.element.model';
import HtmlConvertedModel from '../../models/templates/html.converted.model';

@Injectable({
  providedIn: 'root'
})
export class MjmlApiService extends BaseModelService<HtmlConvertedModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }
  protected get controllerName(): string {
    return 'Template';
  }

  public mjml2html(mjml: MjmlElementModel): Observable<HtmlConvertedModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.post<HtmlConvertedModel>(`${config.apiURL}/${this.controllerName}/mjmlapijson`, mjml);
      })
    );
  }

}
