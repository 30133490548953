import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CommentWallPopupDataModel} from '../../../../../models/templates/comment.wall.popup.data.model';
import {Observable} from 'rxjs';
import {EventCommentWallModel} from '../../../../../models/event/event.comment.wall.model';

@Component({
  selector: 'app-comment-wall-all',
  templateUrl: './comment-wall-all.component.html',
  styleUrls: ['../comment-wall.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentWallAllComponent implements OnInit {
  public comments$: Observable<EventCommentWallModel[]>;
  constructor(
    public dialogRef: MatDialogRef<CommentWallAllComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommentWallPopupDataModel,
  ) { }

  public ngOnInit(): void {
    this.comments$ = this.data.commentWallService.getAllComments(false, this.data.eventID);
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public addNew(): void {
    this.dialogRef.close('addNew');
  }
}
