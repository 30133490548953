export enum SocialMetricEnum {
  Impressions = 1,
  Engagements = 2,
  Favorites = 3,
  Retweets = 4,
  Replies = 5,
  VideoViews = 6,
  ClickthroughRate = 7, // todo add to BE side
  Interactions = 8, // not exist
  CutOffPoint = 9,
  Likes = 10,
  Shares = 11,
  Comments = 12,
  QuoteRetweets = 13
}
