export enum DonationStatusType {
  Completed = 0, //Default value
  Canceled = 1,
  Refunded = 2,
  Declined = 3,
  Pending = 4,
  Deferred = 5,
  Authorized = 6,
  Retry = 7,
  InProgress = 8
}