<div class="lazy-select-container">
  <div
    [matMenuTriggerFor]="menu"
    (menuOpened)="onOpened()"
  >
    <ng-content></ng-content>
  </div>

  <mat-menu #menu="matMenu">
    <div class="lazy-select-content" (click)="$event.stopPropagation()">
      <input
        *ngIf="!onlyBooleanType"
        type="search"
        class="lazy-select-search-input"
        placeholder="{{'Search ...' | translate}}"
        [formControl]="searchText"
        (input)="search()"
      >

      <div class="lazy-select-letters" *ngIf="!onlyBooleanType">
        <div (click)="searchLetter('')" class="all-div" [class.div-active]="searchText.value===''">{{'All' | translate}}</div>
        <ng-container *ngFor="let letter of letters">
          <div (click)="searchLetter(letter)" [class.div-active]="!letter.localeCompare(searchText.value, undefined, {sensitivity: 'accent'})">{{letter}}</div>
        </ng-container>
      </div>

      <div
        class="infinite-scroll-body"
        infinite-scroll
        [infiniteScrollDistance]="0.5"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="onScrollDown()"
      >
        <div
          (click)="toggleItem(option)"
          [class.div-active]="multiSelection.isSelected(option.value)"
          *ngFor="let option of options"
        >
          <i class="far fa-square" *ngIf="!multiSelection.isSelected(option.value)"></i>
          <i class="fas fa-check-square" *ngIf="multiSelection.isSelected(option.value)"></i>
          <span *ngIf="isPhones; else commonTemplate">{{option.label === ('N/A' | translate) ? option.label : option.label | mask: '+0 (000) 000-0000'}}</span>
          <ng-template #commonTemplate>
            <span>{{option.label}}</span>
          </ng-template>
        </div>
      </div>

      <div class="buttons">
        <app-button
          label="Apply Filters"
          type="basic"
          color="#0064be"
          (clickEvent)="applyFilters()"
        ></app-button>
        <app-button
          label="Clear All"
          type="basic"
          color="#0064be"
          (clickEvent)="clearAll()"
        ></app-button>
      </div>

      <div *ngIf="isDataLoading" class="lazy-select-spinner">
        <app-spinner></app-spinner>
      </div>
    </div>

  </mat-menu>
</div>

