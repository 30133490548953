import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TemplateManagementService} from '../../../../services/templates/template.management.service';
import FormElementDataModel from '../../../../models/form.element.data.model';
import {
  DEFAULT_SHARE_ON_SM_PLATFORMS_NAMES,
  FONT_SIZES,
  FONT_STYLE,
  FONT_WEIGHT,
  FONTS,
  SHARE_ON_SM_COLORS,
  SHARE_ON_SM_PLATFORMS,
  TEXT_ALIGN,
  TEXT_DECORATION
} from '../../../../constants';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import {MjmlTag} from '../../../../models/templates/mjml.tag.type';
import {Subscription} from 'rxjs';
import {MjmlCustomType} from '../../../../models/templates/mjml.custom.type';
import {ShareOnSmType} from '../../../../models/templates/share.on.sm.type';
import {TemplateType} from '../../../../models/templates/template.type';

@Component({
  selector: 'app-share-on-sm-settings',
  templateUrl: './share-on-sm-settings.component.html',
  styleUrls: ['./share-on-sm-settings.component.scss', '../tool-settings.scss'],
})
export class ShareOnSmSettingsComponent implements OnInit, OnDestroy {
  @Input() public tms: TemplateManagementService;

  public platformsOptions: FormElementDataModel[] = SHARE_ON_SM_PLATFORMS;
  public modeOptions: FormElementDataModel[] = [
    {label: 'Horizontal', value: 'horizontal'},
    {label: 'Vertical', value: 'vertical'},
  ];
  public alignOptions: FormElementDataModel[] = TEXT_ALIGN;
  public fontFamilyOptions: FormElementDataModel[] = FONTS;
  public fontSizeOptions: FormElementDataModel[] = FONT_SIZES;
  public fontStyleOptions: FormElementDataModel[] = FONT_STYLE;
  public fontWeightOptions: FormElementDataModel[] = FONT_WEIGHT;
  public textDecorationOptions: FormElementDataModel[] = TEXT_DECORATION;
  public toolTipText: string = 'Image for the Social Media sharing. Image size is 158 x 158 px';

  public smForm: FormGroup = this.formBuilder.group({
    smNames: '',
    text: 'Share with Friends:',
    align: 'center',
    borderRadius: ['3', [Validators.min(0)]],
    color: '#333333',
    containerBackgroundColor: '',
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    iconSize: ['20', [Validators.min(10)]],
    innerPadding: ['4', [Validators.min(0)]],
    mode: 'horizontal',
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]],
    textDecoration: 'none',
    imageUrl: ''
  });

  private subscription: Subscription = new Subscription();
  public image: string = null;

  constructor(
    private formBuilder: FormBuilder,
  ) {
  }

  public ngOnInit(): void {
    this.setInitialValues();
    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (!element || element.tagName !== MjmlTag.text || element.attributes['customType'] !== MjmlCustomType.shareOnSM) {
          return;
        }
        this.setInitialValues();
      })
    );
    this.subscription.add(
      this.tms.settingsTabChangedEmitter.subscribe((tabIndex: number) => {
        /* we have to replace empty value with default according to requirements */
        const text = this.smForm.get('text');
        const currentValue = text.value;
        if (!currentValue) {
          text.setValue('Share with Friends:');
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setInitialValues(): void {
    const attributes = this.tms.activeElement.attributes;
    const smNames = attributes['sm-names'] ? JSON.parse(attributes['sm-names']) : '';
    const text = attributes['text'] || 'Share with Friends:';
    const align = attributes['align'] || 'center';
    const borderRadius = attributes['border-radius'] || '3px';
    const color = attributes['color'] || '#333333';
    const containerBackgroundColor = attributes['container-background-color'] || 'transparent';
    const fontFamily = attributes['font-family'] || 'Roboto';
    const fontSize = attributes['font-size'] || '13px';
    const fontStyle = attributes['font-style'] || 'normal';
    const fontWeight = attributes['font-weight'] || 'normal';
    const iconSize = attributes['icon-size'] || '20px';
    const innerPadding = attributes['inner-padding'] || '4px';
    const mode = attributes['mode'] || 'horizontal';
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    const textDecoration = attributes['text-decoration'] || 'none';
    const imageURL = attributes['image-url'] || '';

    this.smForm.get('smNames').setValue(smNames);
    this.smForm.get('text').setValue(text);
    this.smForm.get('align').setValue(align);
    this.smForm.get('borderRadius').setValue(borderRadius.slice(0, -2));
    this.smForm.get('color').setValue(color);
    this.smForm.get('containerBackgroundColor').setValue(containerBackgroundColor);
    this.smForm.get('fontFamily').setValue(fontFamily);
    this.smForm.get('fontSize').setValue(fontSize);
    this.smForm.get('fontStyle').setValue(fontStyle);
    this.smForm.get('fontWeight').setValue(fontWeight);
    this.smForm.get('iconSize').setValue(iconSize.slice(0, -2));
    this.smForm.get('innerPadding').setValue(innerPadding.slice(0, -2));
    this.smForm.get('mode').setValue(mode);
    this.smForm.get('paddingBottom').setValue(paddingBottom.slice(0, -2));
    this.smForm.get('paddingLeft').setValue(paddingLeft.slice(0, -2));
    this.smForm.get('paddingRight').setValue(paddingRight.slice(0, -2));
    this.smForm.get('paddingTop').setValue(paddingTop.slice(0, -2));
    this.smForm.get('textDecoration').setValue(textDecoration);
    this.imageUrl.setValue(imageURL);

    const elements = this.tms.activeElement.children;
    this.smForm.removeControl('socialElements');
    if (elements && elements.length) {
      this.patchSocialElements(elements);
    } else {
      return;
    }
  }

  private patchSocialElements(socialElements: MjmlElementModel[]): void {
    this.smForm.addControl('socialElements', this.formBuilder.array([]));
    socialElements.forEach((socialElement: MjmlElementModel) => {
      const name: ShareOnSmType = socialElement.attributes['name'] as ShareOnSmType;
      const formGroup = this.getSmElement(name);
      formGroup.get('backgroundColor').setValue(socialElement.attributes['background-color'] || SHARE_ON_SM_COLORS[name]);
      this.socialElements.push(formGroup);
    });
  }

  public get socialElements(): FormArray {
    return this.smForm.get('socialElements') as FormArray;
  }

  private getSmElement(name: ShareOnSmType): FormGroup {
    return this.formBuilder.group({
      name,
      backgroundColor: SHARE_ON_SM_COLORS[name],
    });
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public onSmNamesChanged(): void {
    const value = this.smForm.get('smNames').value;
    this.tms.activeElement.attributes['sm-names'] = value ? JSON.stringify(value) : '';
    this.smForm.removeControl('socialElements');
    if (!value.length) {
      this.tms.activeElement.children = [];
      return;
    }
    if (!this.tms.activeElement.children) {
      this.tms.activeElement.children = [];
    }
    const mjmlChildren: MjmlElementModel[] = this.tms.activeElement.children.filter((child: MjmlElementModel) => value.includes(child.attributes['name']));
    const existingChildrenNames = mjmlChildren.map((child: MjmlElementModel) => child.attributes['name']);
    value.forEach((name: ShareOnSmType) => {
      if (!existingChildrenNames.includes(name)) {
        mjmlChildren.push(this.getMjmlChild(name));
      }
    });
    this.tms.activeElement.children = mjmlChildren;
    this.patchSocialElements(mjmlChildren);
    this.tms.emitSave(true);
  }

  private getMjmlChild(name: ShareOnSmType): MjmlElementModel {
    return {
      tagName: MjmlTag.shareOnSM,
      attributes: {
        name,
      }
    };
  }

  public onTextChanged(): void {
    this.tms.activeElement.attributes['text'] = this.smForm.get('text').value || 'Share with Friends:';
    this.tms.emitSave(true);
  }

  public imageUrlChanged(url: string): void {
    this.image = null;
    if (url === null) {
      return;
    }
  }

  public onUrlChanged(): void {
    this.tms.activeElement.attributes['image-url'] = this.imageUrl.value;
    this.tms.emitSave(true);
  }

  public get imageUrl(): FormControl {
    return this.smForm.get('imageUrl') as FormControl;
  }

  public editImage(): void {
    this.image = this.imageUrl.value;
  }

  public cancelEditImage(): void {
    this.image = null;
  }

  public getPlatformName(element: AbstractControl): string {
    const name = element.get('name').value;
    return DEFAULT_SHARE_ON_SM_PLATFORMS_NAMES[name];
  }

  public onIconColorChanged(formControl: AbstractControl): void {
    const name = formControl.get('name').value;
    const mjmlChild = this.tms.activeElement.children.find((item: MjmlElementModel) => item.attributes['name'] === name);
    mjmlChild.attributes['background-color'] = formControl.get('backgroundColor').value || '';
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public onModeChanged(): void {
    this.tms.activeElement.attributes['mode'] = this.smForm.get('mode').value || 'horizontal';
    this.tms.emitSave(true);
  }

  public onAlignChanged(): void {
    this.tms.activeElement.attributes['align'] = this.smForm.get('align').value || 'center';
    this.tms.emitSave(true);
  }

  public onFontFamilyChanged(): void {
    this.tms.activeElement.attributes['font-family'] = this.smForm.get('fontFamily').value || 'Roboto';
    this.tms.emitSave(true);
  }

  public onFontSizeChanged(): void {
    this.tms.activeElement.attributes['font-size'] = this.smForm.get('fontSize').value || '13px';
    this.tms.emitSave(true);
  }

  public onFontStyleChanged(): void {
    this.tms.activeElement.attributes['font-style'] = this.smForm.get('fontStyle').value || 'normal';
    this.tms.emitSave(true);
  }

  public onFontWeightChanged(): void {
    this.tms.activeElement.attributes['font-weight'] = this.smForm.get('fontWeight').value || 'normal';
    this.tms.emitSave(true);
  }

  public onColorChanged(): void {
    this.tms.activeElement.attributes['color'] = this.smForm.get('color').value || '#333333';
    this.tms.emitSave(true);
  }

  public onTextDecorationChanged(): void {
    this.tms.activeElement.attributes['text-decoration'] = this.smForm.get('textDecoration').value || 'none';
    this.tms.emitSave(true);
  }

  public onContainerBackgroundColorChanged(): void {
    this.tms.activeElement.attributes['container-background-color'] = this.smForm.get('containerBackgroundColor').value || 'transparent';
    this.tms.emitSave(true);
  }

  public onIconSizeChanged(): void {
    const value = this.smForm.get('iconSize').value;
    this.tms.activeElement.attributes['icon-size'] = value ? `${value}px` : '20px';
    this.tms.emitSave(true);
  }

  public onInnerPaddingChanged(): void {
    const value = this.smForm.get('innerPadding').value;
    this.tms.activeElement.attributes['inner-padding'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onBorderRadiusChanged(): void {
    const value = this.smForm.get('borderRadius').value;
    this.tms.activeElement.attributes['border-radius'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingTopChanged(): void {
    const value = this.smForm.get('paddingTop').value;
    this.tms.activeElement.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.smForm.get('paddingRight').value;
    this.tms.activeElement.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.smForm.get('paddingBottom').value;
    this.tms.activeElement.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.smForm.get('paddingLeft').value;
    this.tms.activeElement.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }
}
