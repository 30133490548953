import { Injectable } from '@angular/core';
import FormElementDataModel from '../../models/form.element.data.model';
import { Observable, of, Subject } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ClientRelationshipService } from '../../services/client/client.relationship.service';
import { Paging, PagingModel } from '../../models/paging/paging.model';
import { FilterType } from '../../models/enum/filter.type';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ClientRelationshipModel } from '../../models/client/client.relationship.model';
import { ClientEmployeeModel } from '../../models/client/client.employee.model';
import { ClientEmployeeService } from '../../services/client/client-employee.service';
import { ClientModel } from '../../models/client/client.model';
import { ClientService } from '../../services/client/client.service';

@Injectable({
  providedIn: 'root'
})
export class ClientModuleService {
  private fetchClientInProgress: boolean = false;
  private clientSubject: Subject<ClientModel> = new Subject<ClientModel>();
  private userNameSubject: Subject<string> = new Subject<string>();
  private userFirstNameSubject: Subject<string> = new Subject<string>();
  private userLastNameSubject: Subject<string> = new Subject<string>();
  private clientLogoSubject: Subject<string> = new Subject<string>();
  private userEmailSubject: Subject<string> = new Subject<string>();
  private facebookLinkSubject: Subject<string> = new Subject<string>();
  private instagramLinkSubject: Subject<string> = new Subject<string>();
  private twitterLinkSubject: Subject<string> = new Subject<string>();
  private linkedinLinkSubject: Subject<string> = new Subject<string>();
  private isFundraiserEnabledSubject: Subject<string> = new Subject<string>();
  private userTitleSubject: Subject<string> = new Subject<string>();
  private clientNameSubject: Subject<string> = new Subject<string>();
  private isFundsRequiredSubject: Subject<boolean> = new Subject<boolean>();
  private clientUrlSubject: Subject<string> = new Subject<string>();

  constructor(
    private authService: AuthService,
    private clientRelationshipService: ClientRelationshipService,
    private clientEmployeeModelService: ClientEmployeeService,
    private clientService: ClientService
  ) {
  }

  public get clientsWithRelationships(): Observable<FormElementDataModel[]> {
    const sessionStorageState = localStorage.getItem('clientsWithRelationshipsToCurrentClient');
    const clientsWithRelationshipsToCurrentClient: FormElementDataModel[] = sessionStorageState ? JSON.parse(sessionStorageState) : null;

    if (clientsWithRelationshipsToCurrentClient?.length) {
      return of(clientsWithRelationshipsToCurrentClient);
    } else {
      const childrenPaging: Paging = {
        includeDependencies: true,
        includeDeleted: false,
        filters: [{
          field: 'parentID',
          value: this.authService.getIdentityClaimsOriginId(),
          type: FilterType.Equal,
        }],
      };
      return this.clientRelationshipService.getModelList(childrenPaging).pipe(
        switchMap((childrenList: ClientRelationshipModel[]) => {
          if (childrenList && childrenList.length) {
            return of(childrenList.map(({childClientName, childID, isPAC}: ClientRelationshipModel) => ({label: childClientName, value: childID, isPAC})));
          } else {
            const paging: Paging = {
              includeDependencies: true,
              includeDeleted: false,
              filters: [{
                field: 'childID',
                value: this.authService.getIdentityClaimsOriginId(),
                type: FilterType.Equal,
              }],
            };
            return this.clientRelationshipService.getModelList(paging)
              .pipe(map((parentList: ClientRelationshipModel[]) =>
                (parentList && parentList.length && parentList[0].isParentAvailable)
                  ? [{label: parentList[0].parentClientName, value: parentList[0].parentID, isParent: true, isPAC: parentList[0].isPAC}]
                  : []
              ))
          }
        }),
        tap((clients: FormElementDataModel[]) => localStorage.setItem('clientsWithRelationshipsToCurrentClient', JSON.stringify(clients)))
      )
    }
  }

  private fetchClientData(): Observable<ClientEmployeeModel> {
    this.fetchClientInProgress = true;
    const employeePaging = new PagingModel();
    employeePaging.includeDependencies = false;
    employeePaging.filters =   [{
      field: 'email',
      value: this.authService.getIdentityClaimsName(),
      type: FilterType.Equal,
    }];
    return this.clientEmployeeModelService.getModelList(employeePaging)
      .pipe(
        filter(value => !!value && !!value.length),
        switchMap((clientEmployeeModel: ClientEmployeeModel []) => {
          /*when Employee is inactive we receive []*/
          return this.clientService.getModel(clientEmployeeModel[0].clientID)
            .pipe(
              map((client: ClientModel) => {
                clientEmployeeModel[0].client = client;
                return clientEmployeeModel[0];
              })
            );
        }),
        tap(this.setLocalStorageData.bind(this))
      );
  }

  public get client(): Observable<ClientModel> {
    const localStorageState = localStorage.getItem('clientData');
    if (localStorageState !== null) {
      return of(JSON.parse(localStorageState) as ClientModel);
    } else if (this.fetchClientInProgress) {
      return this.clientSubject.asObservable();
    } else {
      return this.fetchClientData().pipe(map((clientEmployee: ClientEmployeeModel) => clientEmployee ? clientEmployee.client : null));
    }
  }

  public get clientName(): Observable<string> {
    const localStorageState = localStorage.getItem('clientName');
    if (localStorageState !== null) {
      return of(localStorageState);
    } else if (this.fetchClientInProgress) {
      return this.clientNameSubject.asObservable();
    } else {
      return this.fetchClientData().pipe(map((model: ClientEmployeeModel) => model ? model.client.name || '' : ''));
    }
  }

  public get userName(): Observable<string> {
    const localStorageState = localStorage.getItem('userName');
    if (localStorageState !== null) {
      return of(localStorageState);
    } else if (this.fetchClientInProgress) {
      return this.userNameSubject.asObservable();
    } else {
      return this.fetchClientData().pipe(map((model: ClientEmployeeModel) => model ? `${model.firstName} ${model.lastName}` : ''));
    }
  }

  public get userFirstName(): Observable<string> {
    const localStorageState = localStorage.getItem('userFirstName');
    if (localStorageState !== null) {
      return of(localStorageState);
    } else if (this.fetchClientInProgress) {
      return this.userFirstNameSubject.asObservable();
    } else {
      return this.fetchClientData().pipe(map((model: ClientEmployeeModel) => model ? model.firstName : ''));
    }
  }

  public get userLastName(): Observable<string> {
    const localStorageState = localStorage.getItem('userLastName');
    if (localStorageState !== null) {
      return of(localStorageState);
    } else if (this.fetchClientInProgress) {
      return this.userLastNameSubject.asObservable();
    } else {
      return this.fetchClientData().pipe(map((model: ClientEmployeeModel) => model ? model.lastName : ''));
    }
  }

  public get clientLogo(): Observable<string> {
    const localStorageState = localStorage.getItem('clientLogo');
    if (localStorageState !== null) {
      return of(localStorageState);
    } else if (this.fetchClientInProgress) {
      return this.clientLogoSubject.asObservable();
    } else {
      return this.fetchClientData().pipe(map((model: ClientEmployeeModel) => model ? model.client.avatarImg || '' : ''));
    }
  }

  public get userEmail(): Observable<string> {
    const localStorageState = localStorage.getItem('userEmail');
    if (localStorageState !== null) {
      return of(localStorageState);
    } else if (this.fetchClientInProgress) {
      return this.userEmailSubject.asObservable();
    } else {
      return this.fetchClientData().pipe(map((model: ClientEmployeeModel) => model ? model.email : ''));
    }
  }

  public get facebookLink(): Observable<string> {
    const localStorageState = localStorage.getItem('facebookLink');
    if (localStorageState !== null) {
      return of(localStorageState);
    } else if (this.fetchClientInProgress) {
      return this.facebookLinkSubject.asObservable();
    } else {
      return this.fetchClientData().pipe(map((model: ClientEmployeeModel) => model ? model.client.facebookLink || '' : ''));
    }
  }

  public get instagramLink(): Observable<string> {
    const localStorageState = localStorage.getItem('instagramLink');
    if (localStorageState !== null) {
      return of(localStorageState);
    } else if (this.fetchClientInProgress) {
      return this.instagramLinkSubject.asObservable();
    } else {
      return this.fetchClientData().pipe(map((model: ClientEmployeeModel) => model ? model.client.instagramLink || '' : ''));
    }
  }

  public get twitterLink(): Observable<string> {
    const localStorageState = localStorage.getItem('twitterLink');
    if (localStorageState !== null) {
      return of(localStorageState);
    } else if (this.fetchClientInProgress) {
      return this.twitterLinkSubject.asObservable();
    } else {
      return this.fetchClientData().pipe(map((model: ClientEmployeeModel) => model ? model.client.twitterLink || '' : ''));
    }
  }

  public get linkedinLink(): Observable<string> {
    const localStorageState = localStorage.getItem('linkedinLink');
    if (localStorageState !== null) {
      return of(localStorageState);
    } else if (this.fetchClientInProgress) {
      return this.linkedinLinkSubject.asObservable();
    } else {
      return this.fetchClientData().pipe(map((model: ClientEmployeeModel) => model ? model.client.linkedinLink || '' : ''));
    }
  }

  public get isFundraiserEnabled(): Observable<string> {
    const localStorageState = localStorage.getItem('isFundraiserEnabled');
    if (localStorageState !== null) {
      return of(localStorageState);
    } else if (this.fetchClientInProgress) {
      return this.isFundraiserEnabledSubject.asObservable();
    } else {
      return this.fetchClientData().pipe(map((model: ClientEmployeeModel) => model ? model.client.isFundraiserEnabled.toString() || '' : ''));
    }
  }

  public get userTitle(): Observable<string> {
    const localStorageState = localStorage.getItem('userTitle');
    if (localStorageState !== null) {
      return of(localStorageState);
    } else if (this.fetchClientInProgress) {
      return this.userTitleSubject.asObservable();
    } else {
      return this.fetchClientData().pipe(map((model: ClientEmployeeModel) => model ? model.title || '' : ''));
    }
  }

  public get isFundsRequired(): Observable<boolean> {
    const localStorageState = localStorage.getItem('isFundsRequired');
    if (localStorageState !== null) {
      return of(localStorageState === 'true');
    } else if (this.fetchClientInProgress) {
      return this.isFundsRequiredSubject.asObservable();
    } else {
      return this.fetchClientData().pipe(map((model: ClientEmployeeModel) => model ? model.client.isFundsRequired || false : false));
    }
  }

  public get clientUrl(): Observable<string> {
    const localStorageState = localStorage.getItem('clientUrl');
    if (localStorageState !== null) {
      return of(localStorageState);
    } else if (this.fetchClientInProgress) {
      return this.clientUrlSubject.asObservable();
    } else {
      return this.fetchClientData().pipe(map((model: ClientEmployeeModel) => model ? model.client.url || '' : ''));
    }
  }

  private setLocalStorageData(model: ClientEmployeeModel): void {
    const userName = model ? `${model.firstName} ${model.lastName}` : '';
    model && localStorage.setItem('clientData', JSON.stringify(model.client));
    model && localStorage.setItem('userName', userName);
    model && localStorage.setItem('userFirstName', model.firstName || '');
    model && localStorage.setItem('userLastName', model.lastName || '');
    model && localStorage.setItem('clientLogo', model.client.avatarImg || '');
    model && localStorage.setItem('userEmail', model.email);
    model && localStorage.setItem('facebookLink', model.client.facebookLink || '');
    model && localStorage.setItem('instagramLink', model.client.instagramLink || '');
    model && localStorage.setItem('twitterLink', model.client.twitterLink || '');
    model && localStorage.setItem('linkedinLink', model.client.linkedinLink || '');
    model && localStorage.setItem('isFundraiserEnabled', model.client.isFundraiserEnabled.toString() || '');
    model && localStorage.setItem('userTitle', model.title || '');
    model && localStorage.setItem('clientName', model.client.name || '');
    model && localStorage.setItem('isFundsRequired', model.client.isFundsRequired ? 'true' : 'false');
    model && localStorage.setItem('clientUrl', model.client.url || '');

    this.clientSubject.next(model ? model.client : null);
    this.userNameSubject.next(userName);
    this.userFirstNameSubject.next(model ? model.firstName || '' : '');
    this.userLastNameSubject.next(model ? model.lastName || '' : '');
    this.clientLogoSubject.next(model ? model.client.avatarImg || '' : '');
    this.userEmailSubject.next(model ? model.email : '');
    this.facebookLinkSubject.next(model ? model.client.facebookLink || '' : '');
    this.instagramLinkSubject.next(model ? model.client.instagramLink || '' : '');
    this.twitterLinkSubject.next(model ? model.client.twitterLink || '' : '');
    this.linkedinLinkSubject.next(model ? model.client.linkedinLink || '' : '');
    this.isFundraiserEnabledSubject.next(model ? model.client.isFundraiserEnabled.toString() || '' : '');
    this.userTitleSubject.next(model ? model.title || '' : '');
    this.clientNameSubject.next(model ? model.client.name || '' : '');
    this.isFundsRequiredSubject.next(model ? model.client.isFundsRequired || false : false);
    this.clientUrlSubject.next(model ? model.client.url || '' : '');

    this.fetchClientInProgress = false;
  }

  public updateClientData(): void {
    this.clearState();
    this.client.subscribe();
  }

  public clearState(): void {
    localStorage.removeItem('clientData');
    localStorage.removeItem('userName');
    localStorage.removeItem('userFirstName');
    localStorage.removeItem('userLastName');
    localStorage.removeItem('clientLogo');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('facebookLink');
    localStorage.removeItem('instagramLink');
    localStorage.removeItem('twitterLink');
    localStorage.removeItem('linkedinLink');
    localStorage.removeItem('isFundraiserEnabled');
    localStorage.removeItem('userTitle');
    localStorage.removeItem('clientName');
    localStorage.removeItem('isFundsRequired');
    localStorage.removeItem('clientUrl');
    localStorage.removeItem('clientsWithRelationshipsToCurrentClient');
    this.fetchClientInProgress = false;
  }

  public clearStorage(): void {
    this.clearState();
    localStorage.removeItem('color1');
    localStorage.removeItem('color2');
    localStorage.removeItem('color3');
    localStorage.removeItem('currentSelectedClientID');
    localStorage.removeItem('currentSelectedClientName');
    this.authService.cleanMasquerade();
  }

}
