<div class="fb-setting-tab-wrapper">
  <mat-vertical-stepper #stepper (selectionChange)="handleSelectionChange($event)">

    <mat-step>
      <ng-template matStepLabel>
        <div class="fb-setting-tab-label">
          <i class="fas fa-cogs"></i>
          <span>{{'BUILD.Form Settings' | translate}}</span>
        </div>
      </ng-template>

      <app-form-settings [fbss]="fbss"></app-form-settings>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        <div class="fb-setting-tab-label">
          <i class="far fa-envelope"></i>
          <span>{{'BUILD.Email' | translate}}</span>
        </div>
      </ng-template>

      <app-fb-email-settings
        *ngIf="!isSuperAdmin; else forClientsOnlyTemplate"
        [fbss]="fbss"
        [wasPageOpened]="wasEmailSettingsOpened"
      ></app-fb-email-settings>
      <ng-template #forClientsOnlyTemplate>
        <span>{{'BUILD.For clients only' | translate}}</span>
      </ng-template>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>
        <div class="fb-setting-tab-label">
          <i class="far fa-thumbs-up"></i>
          <span>{{'BUILD.Thank You Page' | translate}}</span>
        </div>
      </ng-template>

      <app-thank-you-page-settings
        [fbss]="fbss"
        [activeStep]="activeStep"
      ></app-thank-you-page-settings>
    </mat-step>
  </mat-vertical-stepper>
</div>
