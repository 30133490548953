<div  *ngIf="!isNew" [ngClass]="low ? 'low-custom-form-component-container-wrapper' : 'custom-form-component-container-wrapper'" [formGroup]="formGroup">
  <label class="form-field-name" [for]="id">{{label | translate}}</label>
  <mat-form-field appearance="outline">
    <mat-select
      [attr.disabled]="disabled"
      [ngClass]="{opacity: disabled}"
      [formControlName]="name"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      [id]="id"
      (selectionChange)="emitChanges($event)"
      [value]="formControl?.value"
    >
      <mat-option>
        <ngx-mat-select-search
          [formControl]="filterControl"
          placeholderLabel="{{'Search' | translate}}"
          noEntriesFoundLabel="{{'Option not found' | translate}}"
        ></ngx-mat-select-search>
      </mat-option>

      <mat-option *ngFor="let option of options$ | async" [value]="option.value">
        {{option.label | translate}}
      </mat-option>

    </mat-select>
    <mat-error *ngIf="formControl.invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>
</div>

<div *ngIf="isNew" [ngClass]="low ? 'new-low-custom-form-component-container-wrapper' : 'new-custom-form-component-container-wrapper'" [formGroup]="formGroup">
  <label class="form-field-name" [for]="id">{{label | translate}}</label>
  <mat-form-field appearance="outline">
    <mat-select
      [attr.disabled]="disabled"
      [ngClass]="{opacity: disabled}"
      [formControlName]="name"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      [id]="id"
      (selectionChange)="emitChanges($event)"
      [value]="formControl?.value"
    >
      <mat-option>
        <ngx-mat-select-search
          [formControl]="filterControl"
          placeholderLabel="{{'Search' | translate}}"
          noEntriesFoundLabel="{{'Option not found' | translate}}"
        ></ngx-mat-select-search>
      </mat-option>

      <mat-option *ngFor="let option of options$ | async" [value]="option.value">
        {{option.label | translate}}
      </mat-option>

    </mat-select>
    <mat-error *ngIf="formControl.invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>
</div>
