import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import FormElementDataModel from '../../models/form.element.data.model';
import { MatMenuTrigger } from '@angular/material/menu';
import ColumnFilterOptionsModel from '../../models/filter-sort/column.filter.options.model';
import ColumnFilterModel from '../../models/filter-sort/column.filter.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
})
export class MultiselectComponent implements OnChanges {
  @Input()
  public columnFilterOptions: ColumnFilterOptionsModel;
  /*!!! DO NOT PASS BOTH VALUES*/
  @Input() public initialSelection: any;
  @Input() public currentSelection: ColumnFilterModel;
  @Input() enableTranslation: boolean

  @ViewChild(MatMenuTrigger)
  public menu: MatMenuTrigger;

  @Output()
  public selectedOptions: EventEmitter<ColumnFilterModel> = new EventEmitter<ColumnFilterModel>();

  public selectOptions: FormElementDataModel[] = [];
  public columnName: string;

  constructor(protected ref: ChangeDetectorRef, protected translate: TranslateService) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.columnFilterOptions && this.columnFilterOptions) {
      this.selectOptions = this.columnFilterOptions.options;
      this.selectOptions.forEach(label => {
        label.label = this.translate.instant(`${label.label}`)
      });
      this.columnName = this.columnFilterOptions.columnName;
      this.ref.detectChanges();
    }
  }

  public multiSelectedValue(value: FormElementDataModel[]): void {
    const columnFilter: ColumnFilterModel = {
      field: this.columnFilterOptions.columnName,
      labels: value.map(option => option.label),
      values: value.map(option => option.value)
    };
    this.selectedOptions.emit(columnFilter);
    this.menu.closeMenu();
  }
}
