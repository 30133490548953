import { Component, Input } from '@angular/core';
import { BasePagingComponent } from '../paginator/base.paging.component';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { TableRowActionModel } from '../../models/table.row.action.model';
import ColumnFilterOptionsModel from '../../models/filter-sort/column.filter.options.model';
import { DistinctDataModel } from '../../models/distinct/distinct.data.model';
import FilterSortModel from '../../models/filter-sort/filter.sort.model';
import { IconsType } from 'src/app/models/enum/icons.type';
import { LazyLoadRequestModel } from '../../models/filter-sort/lazy.load.search.model';
import ColumnFilterModel from '../../models/filter-sort/column.filter.model';

@Component({
  selector: 'app-table-state',
  template: ''
})

export class TableStateComponent<T> extends BasePagingComponent {

  public data$: Observable<T[]>;
  public total$: Observable<number>;

  public columns: string[] = [];
  public columnsWithFilter: string[] = [];
  public columnsWithLazyFilter: string[] = [];
  public columnsWithSort: string[] = [];
  public rowClick: Subject<T> = new Subject<T>();
  public rowActions: TableRowActionModel[] = [];
  public columnsFilterOptions: ColumnFilterOptionsModel[] = [];
  public distinctData$: BehaviorSubject<DistinctDataModel> = new BehaviorSubject<DistinctDataModel>({});
  public multiselectSet: Set<string> = new Set<string>();
  public isAllSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public filterSortConfig: FilterSortModel;
  public columnLabels: {[key: string]: string};
  public sortEvent: Subject<void> = new Subject<void>(); /*Sort table emitter*/
  public filterEvent: Subject<ColumnFilterModel> = new Subject<ColumnFilterModel>(); /*Filter table emitter*/
  public filterOptionsRequest: Subject<LazyLoadRequestModel> = new Subject<LazyLoadRequestModel>(); /*lazy-select data request*/

  public IconsType = IconsType;
  public subscription: Subscription = new Subscription();

  constructor() {
    super();
  }

  public resetSelection(): void{
    this.multiselectSet.clear();
    this.isAllSelected$.next(false);
  }

}
