import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { BACKGROUNDR_REPEAT_OPTIONS } from '../../../../constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { TemplateType } from '../../../../models/templates/template.type';

@Component({
  selector: 'app-column-settings',
  templateUrl: './column-settings.component.html',
  styleUrls: ['./column-settings.component.scss', '../tool-settings.scss']
})
export class ColumnSettingsComponent implements OnInit, OnDestroy {
  @Input() public tms: TemplateManagementService;
  private subscription: Subscription = new Subscription();

  public backgroundRepeatOptions: FormElementDataModel[] = BACKGROUNDR_REPEAT_OPTIONS;

  public columnForm: FormGroup = this.formBuilder.group({
    backgroundColor: '',
    width: ['0', [Validators.min(0)]],
    paddingBottom: ['0', [Validators.min(0)]],
    paddingLeft: ['0', [Validators.min(0)]],
    paddingRight: ['0', [Validators.min(0)]],
    paddingTop: ['0', [Validators.min(0)]]
  });

  constructor(
    private formBuilder: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.setInitialValues();

    this.subscription.add(
      this.tms.activeColumnEmitter.subscribe((column: MjmlElementModel) => {
        if (!column) {
          return;
        }
        this.setInitialValues();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setInitialValues(): void {
    const backgroundColor = this.tms.activeColumn.attributes['background-color'] || '';
    const width = this.tms.activeColumn.attributes['width'] || this.tms.columnWidth;
    const paddingBottom = this.tms.activeColumn.attributes['padding-bottom'] || '0px';
    const paddingLeft = this.tms.activeColumn.attributes['padding-left'] || '0px';
    const paddingRight = this.tms.activeColumn.attributes['padding-right'] || '0px';
    const paddingTop = this.tms.activeColumn.attributes['padding-top'] || '0px';

    this.columnForm.get('backgroundColor').setValue(backgroundColor);
    this.columnForm.get('width').setValue(width.slice(0, -1));
    this.columnForm.get('paddingBottom').setValue(paddingBottom.slice(0, -2));
    this.columnForm.get('paddingLeft').setValue(paddingLeft.slice(0, -2));
    this.columnForm.get('paddingRight').setValue(paddingRight.slice(0, -2));
    this.columnForm.get('paddingTop').setValue(paddingTop.slice(0, -2));
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public onBackgroundColorChanged(): void {
    const value = this.columnForm.get('backgroundColor').value;
    this.tms.activeColumn.attributes['background-color'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onWidthChanged(): void {
    const value = this.columnForm.get('width').value;
    this.tms.activeColumn.attributes['width'] = value ? `${value}%` : '';
    this.tms.emitSave(true);
    this.changeOtherColumnsWidth();
    this.tms.adjustImageSizes();
    this.tms.emitSave(true);
  }

  public onPaddingTopChanged(): void {
    const value = this.columnForm.get('paddingTop').value;
    this.tms.activeColumn.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.columnForm.get('paddingRight').value;
    this.tms.activeColumn.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.columnForm.get('paddingBottom').value;
    this.tms.activeColumn.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.columnForm.get('paddingLeft').value;
    this.tms.activeColumn.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  private changeOtherColumnsWidth(): void {
    const columns = this.tms.activeRow.children;
    const updatedColumn = this.tms.activeColumn;
    const updatedWidth = parseFloat(updatedColumn.attributes.width);
    const totalOriginalWidth = columns.reduce((sum, column) => sum + parseFloat(column.attributes.width ?? this.tms.columnWidth), 0);
    const originalWidthWithoutUpdated = totalOriginalWidth - updatedWidth;
    const remainingWidth = 100 - updatedWidth;

    columns.forEach(column => {
      if (column === updatedColumn) {
        column.attributes.width = `${updatedWidth.toFixed(2)}%`;
      } else {
        const originalWidth = parseFloat(column.attributes.width ?? this.tms.columnWidth);
        const newWidth = (originalWidth / originalWidthWithoutUpdated) * remainingWidth;
        column.attributes.width = `${newWidth.toFixed(2)}%`;
      }
    });
    this.tms.emitSave(true);
  }

  public get hasMultipleColumns(): boolean {
    return this.tms.activeRow?.children.length > 1;
  }
  
}
