import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private clientEmployeeChangePassword = 'changepassword';
  private connectToken = 'token';
  private donorEmployee = 'donoremployee?registernewdonor=true';
  private donorReg = 'donoremployee?registernewdonor=true';
  private donorupload = 'import';
  private emptyInstagramAccounts = 'instagram';
  private processPay = 'processpay';
  private resetPass = 'resetpassword';

  private urls: string[] = [
    this.connectToken,
    this.clientEmployeeChangePassword,
    this.donorEmployee,
    this.emptyInstagramAccounts,
    this.donorReg,
    this.donorupload,
    this.resetPass,
    this.processPay
  ];
  private errorMessages: object = {
    [this.clientEmployeeChangePassword]: 'Old password is incorrect',
    [this.donorEmployee]: 'This email is already in use. Please use another Email. If you forgot your password use "Forgot password" option',
    [this.emptyInstagramAccounts]: 'No connected instagram accounts to the facebook user',
    [this.donorReg]: 'This email is already in use. Please use another Email. If you forgot your password use "Forgot password" option',
    [this.donorupload]: 'File is invalid or data is corrupted',
    [this.resetPass]: 'New password is the same as the old one',
    [this.processPay]: 'Payment error.  Please, contact the support team. support@theauxilia.com'
  };

  constructor(
    private toastrService: ToastrService,
    private translate: TranslateService
    ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.checkError(error);
          return throwError(error);
        })
      );
  }

  private checkError(err: HttpErrorResponse): void {
    if (err.status === 400) {
      const urlString = /[^/]*$/.exec(err.url)[0].toLowerCase();

      if (this.urls.includes(urlString)) {
        this.showError(
          sessionStorage.getItem('fundraising') && urlString === this.connectToken
            ? 'We are sorry, but this email does not have an active account in our system. Please try a different email or <a href="/donor-register">click here</a> to Create a donor account.'
            : err.error.error_description || this.errorMessages[urlString]
        );
      }
      if (err.error && err.error.message && err.error.message.includes('account')) {
        this.showError(err.error.message);
      }
    }

    if (err.status === 500) {
      const urlString = /[^/]*$/.exec(err.url)[0].toLowerCase();
      if (this.urls.includes(urlString)) {
        this.showError(err.error.message);
      }
    }

    if (err.status === 404 || err.status === 403) {
      this.showError(err.error.message);
    }
    if (err.status === 429) {
      this.showError('API calls quota exceeded!');
    }
  }

  private showError(message: string): void {
    if(message) {
      message = message.replace(/\"/g, "");
      this.toastrService.error(message, this.translate.instant('Notification'), {enableHtml: true});
    }
  }
}
