import {Component, Input, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormBuilderStateService} from '../../../form-builder.state.service';
import {FbElementModel} from '../../../../../models/form-builder/fb.template.model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import {ImageLoaderComponent} from '../../../../email-builder/components/image-loader/image-loader.component';
import {ImgLoaderEmitterService} from '../../../img-loader-emitter.service';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';

export interface SettingImageModel {
  url: string;
  position: string;
}

@Component({
  selector: 'app-top-image-options',
  templateUrl: './top-image-options.component.html',
  styleUrls: ['../../fb.page.scss']
})
export class TopImageOptionsComponent implements OnInit, OnDestroy {
  @Input() public fbss: FormBuilderStateService;

  private element: FbElementModel;
  private subscriptions: Subscription = new Subscription();

  public numberOfImage: SettingImageModel[] = [];

  private subscription: Subscription = new Subscription();

  public topImgInfoMessage: string = `Max image size is 1080x150 px
                                      Min image size is 550x150 px`;


  public imageURL0: string = null;
  public imageURL1: string = null;
  public imageURL2: string = null;
  public imageURL3: string = null;

  public settingForm: FormGroup = this.formBuilder.group({
    width: 1080,
    height: 150,
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingLeft: 0,
  });

  public optionsForm0: FormGroup = this.formBuilder.group({
    url: '',
    position: 'center'
  });


  public optionsForm1: FormGroup = this.formBuilder.group({
    url: '',
    position: 'center'
  });

  public optionsForm2: FormGroup = this.formBuilder.group({
    url: '',
    position: 'center'
  });

  public optionsForm3: FormGroup = this.formBuilder.group({
    url: '',
    position: 'center'
  });

  public positionOptions: FormElementDataModel[] = [
    {label: 'Left', value: 'left'},
    {label: 'Center', value: 'center'},
    {label: 'Right', value: 'right'}
  ];

  @ViewChildren('fileReaderInput') public fileReaderInput: QueryList<ImageLoaderComponent>;

  constructor(
    private formBuilder: FormBuilder,
    private imgLoaderEmitterService: ImgLoaderEmitterService
  ) { }

  public ngOnInit(): void {
    this.element = this.fbss.template$.getValue().topImage;
    this.setInitialValues();
    this.subscription.add(
      this.imgLoaderEmitterService.loaderEmitter.asObservable()
        .subscribe((i) => {
          const input = this.fileReaderInput.toArray()[i];
          input.fileReaderInputRef.nativeElement.click();
        })
    );
    this.subscription.add(
      this.fbss.isTopImageChange$.pipe(tap(() => this.setInitialValues())).subscribe()
    );
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public getImageUrl(i: number): string {
    switch (i) {
      case 0:
        return this.imageURL0;
      case 1:
        return this.imageURL1;
      case 2:
        return this.imageURL2;
      case 3:
        return this.imageURL3;
      default:
        break;
    }
  }

  public getForm(i: number): FormGroup {
    switch (i) {
      case 0:
        return this.optionsForm0;
      case 1:
        return this.optionsForm1;
      case 2:
        return this.optionsForm2;
      case 3:
        return this.optionsForm3;
      default:
        break;
    }
  }

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }

  private setInitialValues(): void {
    const {
      settings,
      width = '1080px',
      height = '150px',
      paddingBottom = '0px',
      paddingTop = '0px',
      paddingRight = '0px',
      paddingLeft = '0px',
    }: {[key: string]: string} = this.element.attributes;
    if (settings) {
      this.numberOfImage = JSON.parse(settings);
      this.setInitialFormValue(width, height, paddingBottom, paddingTop, paddingRight, paddingLeft);
    }
  }

  private setInitialFormValue(width: string, height: string, paddingBottom: string, paddingTop: string, paddingRight: string, paddingLeft: string ): void {
    this.settingForm.get('width').setValue(this.parseSettingData(width));
    this.settingForm.get('height').setValue(this.parseSettingData(height));
    this.settingForm.get('paddingBottom').setValue(this.parseSettingData(paddingBottom));
    this.settingForm.get('paddingTop').setValue(this.parseSettingData(paddingTop));
    this.settingForm.get('paddingRight').setValue(this.parseSettingData(paddingRight));
    this.settingForm.get('paddingLeft').setValue(this.parseSettingData(paddingLeft));
    this.setInitialImgForm();
  }

  private parseSettingData(data: string): number {
    return Number(data.replace(/[^\d]/g, ''));
  }

  private setInitialImgForm(): void {
    this.numberOfImage.forEach((item, i) => {
      switch (i) {
        case 0:
          this.url0.setValue(item.url);
          this.optionsForm0.get('position').setValue(item.position);
          break;
        case 1:
          this.url1.setValue(item.url);
          this.optionsForm1.get('position').setValue(item.position);
          break;
        case 2:
          this.url2.setValue(item.url);
          this.optionsForm2.get('position').setValue(item.position);
          break;
        case 3:
          this.url3.setValue(item.url);
          this.optionsForm3.get('position').setValue(item.position);
          break;
        default:
          break;
      }
    })
  }

  public imageUrlChanged(url: string, i: number): void {
    switch (i) {
      case 0:
        this.imageURL0 = null;
        if (url === null) {
          return;
        }
        break;
      case 1:
        this.imageURL1 = null;
        if (url === null) {
          return;
        }
        break;
      case 2:
        this.imageURL2 = null;
        if (url === null) {
          return;
        }
        break;
      case 3:
        this.imageURL3 = null;
        if (url === null) {
          return;
        }
        break;
      default:
        break;
    }
  }

  public getUrl(i: number): FormControl {
    switch (i) {
      case 0:
        return this.url0;
      case 1:
        return this.url1;
      case 2:
        return this.url2;
      case 3:
        return this.url3;
      default:
        break;
    }
  }

  public get url0(): FormControl {
    return this.optionsForm0.get('url') as FormControl;
  }

  public get url1(): FormControl {
    return this.optionsForm1.get('url') as FormControl;
  }

  public get url2(): FormControl {
    return this.optionsForm2.get('url') as FormControl;
  }

  public get url3(): FormControl {
    return this.optionsForm3.get('url') as FormControl;
  }

  public onUrlChanged(i: number): void {
    switch (i) {
      case 0:
        this.numberOfImage[i].url = this.url0.value;
        this.element.attributes.settings = JSON.stringify(this.numberOfImage) ;
        this.fbss.templateWasChanged();
        break;
      case 1:
        this.numberOfImage[i].url = this.url1.value;
        this.element.attributes.settings = JSON.stringify(this.numberOfImage) ;
        this.fbss.templateWasChanged();
        break;
      case 2:
        this.numberOfImage[i].url = this.url2.value;
        this.element.attributes.settings = JSON.stringify(this.numberOfImage) ;
        this.fbss.templateWasChanged();
        break;
      case 3:
        this.numberOfImage[i].url = this.url3.value;
        this.element.attributes.settings = JSON.stringify(this.numberOfImage) ;
        this.fbss.templateWasChanged();
        break;
      default:
        break;
    }
  }

  public editImage(i: number): void {
    switch (i) {
      case 0:
        this.imageURL0 = this.url0.value;
        break;
      case 1:
        this.imageURL1 = this.url1.value;
        break;
      case 2:
        this.imageURL2 = this.url2.value;
        break;
      case 3:
        this.imageURL3 = this.url3.value;
        break;
      default:
        break;
    }
  }

  public cancelEditImage(i: number): void {
    switch (i) {
      case 0:
        this.imageURL0 = null;
        break;
      case 1:
        this.imageURL1 = null;
        break;
      case 2:
        this.imageURL2 = null;
        break;
      case 3:
        this.imageURL3 = null;
        break;
      default:
        break;
    }
  }

  public get isArrayFull(): boolean {
    return this.numberOfImage.length < 4;
  }

  public get isMoreThenOne(): boolean {
    return this.numberOfImage.length > 1;
  }

  public onPositionChanged(i: number): void {
    switch (i) {
      case 0:
        this.numberOfImage[i].position = this.optionsForm0.get('position').value;
        this.element.attributes.settings = JSON.stringify(this.numberOfImage);
        this.fbss.templateWasChanged();
        break;
      case 1:
        this.numberOfImage[i].position = this.optionsForm1.get('position').value;
        this.element.attributes.settings = JSON.stringify(this.numberOfImage);
        this.fbss.templateWasChanged();
        break;
      case 2:
        this.numberOfImage[i].position = this.optionsForm2.get('position').value;
        this.element.attributes.settings = JSON.stringify(this.numberOfImage);
        this.fbss.templateWasChanged();
        break;
      case 3:
        this.numberOfImage[i].position = this.optionsForm3.get('position').value;
        this.element.attributes.settings = JSON.stringify(this.numberOfImage);
        this.fbss.templateWasChanged();
        break;
      default:
        break;
    }
  }

  public addImage(): void {
    if (this.numberOfImage.length < 4) {
      this.numberOfImage.push({url: '', position: 'center'});
      this.element.attributes.settings = JSON.stringify(this.numberOfImage);
    }
  }

  public deleteImg(i: number): void {
    if (this.numberOfImage.length) {
      this.numberOfImage.splice(i, 1);
      switch (i) {
        case 0:
          this.optionsForm0.get('url').setValue('');
          this.optionsForm0.get('position').setValue('center');
          this.element.attributes.settings = JSON.stringify(this.numberOfImage);
          if (this.optionsForm1.get('url').value) {
            this.optionsForm0.get('url').setValue(this.optionsForm1.get('url').value);
            this.optionsForm0.get('position').setValue('center');
          }
          if (this.optionsForm2.get('url').value) {
            this.optionsForm1.get('url').setValue(this.optionsForm2.get('url').value);
            this.optionsForm1.get('position').setValue('center');
          }
          if (this.optionsForm3.get('url').value) {
            this.optionsForm2.get('url').setValue(this.optionsForm3.get('url').value);
            this.optionsForm2.get('position').setValue('center');
            this.optionsForm3.get('url').setValue('');
          }
          break;
        case 1:
          this.optionsForm1.get('url').setValue('');
          this.optionsForm1.get('position').setValue('center');
          this.element.attributes.settings = JSON.stringify(this.numberOfImage);
          if (this.optionsForm2.get('url').value) {
            this.optionsForm1.get('url').setValue(this.optionsForm2.get('url').value);
            this.optionsForm1.get('position').setValue('center');
          }
          if (this.optionsForm3.get('url').value) {
            this.optionsForm2.get('url').setValue(this.optionsForm3.get('url').value);
            this.optionsForm2.get('position').setValue('center');
            this.optionsForm3.get('url').setValue('');
          }
          break;
        case 2:
          this.optionsForm2.get('url').setValue('');
          this.optionsForm2.get('position').setValue('center');
          this.element.attributes.settings = JSON.stringify(this.numberOfImage);
          if (this.optionsForm3.get('url').value) {
            this.optionsForm2.get('url').setValue(this.optionsForm3.get('url').value);
            this.optionsForm2.get('position').setValue('center');
            this.optionsForm3.get('url').setValue('');
          }
          break;
        case 3:
          this.optionsForm3.get('url').setValue('');
          this.optionsForm3.get('position').setValue('center');
          this.element.attributes.settings = JSON.stringify(this.numberOfImage);
          break;
        default:
          this.element.attributes.settings = JSON.stringify(this.numberOfImage);
          break;
      }
    }
  }

  public onSettingsChange(settingName: string): void {
    let value;
    switch (settingName) {
      case 'width':
        value = this.settingForm.get('width').value;
        this.element.attributes['width'] = value ? `${value}px` : '0px';
        break;
      case 'height':
        value = this.settingForm.get('height').value;
        this.element.attributes['height'] = value ? `${value}px` : '0px';
        break;
      case 'top':
        value = this.settingForm.get('paddingTop').value;
        this.element.attributes['paddingTop'] = value ? `${value}px` : '0px';
        break;
      case 'right':
        value = this.settingForm.get('paddingRight').value;
        this.element.attributes['paddingRight'] = value ? `${value}px` : '0px';
        break;
      case 'bottom':
        value = this.settingForm.get('paddingBottom').value;
        this.element.attributes['paddingBottom'] = value ? `${value}px` : '0px';
        break;
      case 'left':
        value = this.settingForm.get('paddingLeft').value;
        this.element.attributes['paddingLeft'] = value ? `${value}px` : '0px';
        break;
      default:
        break;
    }
    this.fbss.templateWasChanged();
  }
}
