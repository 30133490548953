import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ImageBuilderStateService } from '../../image-builder.state.service';
import { LayerType } from '../../../../models/image-builder/layer.type';
import { LayerModel } from '../../../../models/image-builder/layer.model';
import { Subscription } from 'rxjs';
import { ClientModuleService } from '../../../../routes/clients/client.module.service';
import { ImageBuilderUtilsComponent } from '../../image-builder.utils.component';

@Component({
  selector: 'app-elements',
  templateUrl: './elements.component.html',
  styleUrls: ['./elements.component.scss']
})
export class ElementsComponent extends ImageBuilderUtilsComponent implements OnInit, OnDestroy {
  @Input() public ibss: ImageBuilderStateService;
  private subscription: Subscription = new Subscription();

  public LayerType = LayerType;

  constructor(
    private clientModuleService: ClientModuleService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.clientModuleService.clientLogo.subscribe((clientLogo: string) => this.ibss.logoSrc = clientLogo || null)
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public addLayer(type: LayerType): void {
    this.ibss.createLayer(type);
  }

  public get isDraggable(): boolean {
    const {readOnly, themedTemplate, componentBasedTemplate}: any = this.ibss;
    return !(readOnly || themedTemplate || componentBasedTemplate);
  }

  public onDragStart(event: DragEvent, layerType: LayerType): void {
    this.ibss.setDraggableElementType(layerType);
  }

  public onDragEnd(event: DragEvent): void {
    this.ibss.setDraggableElementType(null);
  }
}
