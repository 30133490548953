import { ClientModel } from './client.model';
import { FeeAndTaxes } from '../enum/fee-and-taxes.enum';
import {DonationPaymentPageType} from '../form-builder/donation.payment.page.type';

export class ClientPaymentModel {
  clientModel: ClientModel;
  clientID: string;
  name: string;
  hasAppeal: boolean;
  feeAndTaxes: FeeAndTaxes;
  isPassingFeeDefault?: boolean;
  id: string;
  eventFeeAndTaxes?: FeeAndTaxes;
  customDonationPage?: boolean;
  donationPaymentPage?: DonationPaymentPageType;
}
