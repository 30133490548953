import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilderStateService } from '../../../form-builder.state.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import { LABEL_ALIGN } from '../../../../../constants';
import { AlignmentType, FbElementModel, FbElementType } from '../../../../../models/form-builder/fb.template.model';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-full-name-options',
  templateUrl: './full-name-options.component.html',
  styleUrls: ['../../fb.page.scss']
})
export class FullNameOptionsComponent implements OnInit, OnDestroy {
  @Input() public fbss: FormBuilderStateService;

  public labelAlignmentOptions: FormElementDataModel[] = LABEL_ALIGN;

  public optionsForm: FormGroup = this.formBuilder.group({
    mainLabel: '',
    mainLabelAlignment: null,
    firstName: '',
    lastName: ''
  });

  public isSetAsFormDefault: FormControl = this.formBuilder.control(false);

  private element: FbElementModel;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.fbss.activeElementSettings$.asObservable()
        .pipe(
          filter(value => value === FbElementType.FullName),
          tap(() => {
            this.element = this.fbss.template$.getValue().paymentPage.elements
              .find(({type}: FbElementModel) => type === FbElementType.FullName);
            this.setInitialValues();
          }),
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setInitialValues(): void {
    const {
      mainLabel = 'Name',
      mainLabelAlignment = AlignmentType.Top,
      firstName = 'First Name',
      lastName = 'Last Name',
    }: {[key: string]: string} = this.element.attributes;
    this.mainLabel.setValue(mainLabel);
    this.mainLabelAlignment.setValue(mainLabelAlignment);
    this.firstName.setValue(firstName);
    this.lastName.setValue(lastName);
  }

  public get mainLabel(): FormControl {
    return this.optionsForm.get('mainLabel') as FormControl;
  }

  public get mainLabelAlignment(): FormControl {
    return this.optionsForm.get('mainLabelAlignment') as FormControl;
  }

  public get firstName(): FormControl {
    return this.optionsForm.get('firstName') as FormControl;
  }

  public get lastName(): FormControl {
    return this.optionsForm.get('lastName') as FormControl;
  }

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }

  public onMainLabelChanged(): void {
    this.element.attributes.mainLabel = this.mainLabel.value;
    this.fbss.templateWasChanged();
  }

  public onLabelAlignmentChanged(): void {
    this.element.attributes.mainLabelAlignment = this.mainLabelAlignment.value;
    this.isSetAsFormDefault.value && this.setAsFormDefault();
    this.fbss.templateWasChanged();
  }

  public onFirstNameChanged(): void {
    this.element.attributes.firstName = this.firstName.value || 'First Name';
    this.fbss.templateWasChanged();
  }

  public onLastNameChanged(): void {
    this.element.attributes.lastName = this.lastName.value || 'Last Name';
    this.fbss.templateWasChanged();
  }

  public onIsSetAsFormDefaultChanged(): void {
    const next = !this.isSetAsFormDefault.value;
    this.isSetAsFormDefault.setValue(next);
    if (next) {
      this.setAsFormDefault();
      this.fbss.templateWasChanged();
    }
  }

  public setAsFormDefault(): void {
    this.fbss.setAsFormDefault(this.mainLabelAlignment.value);
  }

}
