<ng-container *ngIf="templateAssignmentType === TemplateAssignmentType.Event">
  <form class="email-builder-custom-tools-wrapper" [formGroup]="eventForm" (focusout)="onFocusOut(eventForm)">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <div class="custom-tools-panel-title">{{'BUILD.Event Name and Description' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>

        <div class="email-builder-custom-tools-row">
          <div [draggable]="!(readOnly || predesignedTemplate)" (dragend)="dropItem(MjmlCustomType.EventName)" class="tool-template right-margin">
            <i class="fas fa-heading"></i>
            <span>{{'BUILD.Event Name' | translate}}</span>
          </div>

          <div class="max-width-245">
            <app-input
              label="Event Name *"
              [formGroup]="eventForm"
              name="name"
              [low]="true"
              errorMassage="Event Name"
              [disabled]="readOnly"
            ></app-input>
          </div>
        </div>

        <div class="email-builder-custom-tools-row">
          <div [draggable]="!(readOnly || predesignedTemplate)" (dragend)="dropItem(MjmlCustomType.EventDescription)"
               class="tool-template right-margin">
            <i class="fas fa-comment-alt"></i>
            <span>{{'BUILD.Event Description' | translate}}</span>
          </div>
          <div class="max-width-245">
            <app-textarea
              label="Event Description *"
              [formGroup]="eventForm"
              name="description"
              [low]="true"
              errorMassage="Event Description"
              minRows="2"
              [disabled]="readOnly"
            ></app-textarea>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="eventForm?.get('hasLocation').value">
      <mat-expansion-panel-header>
        <div class="custom-tools-panel-title">{{'BUILD.Event Location' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>

        <div class="email-builder-custom-tools-row">
          <div [draggable]="!(readOnly || predesignedTemplate)" (dragend)="dropItem(MjmlCustomType.EventLocation)" class="tool-template right-margin">
            <i class="fas fa-map-marked-alt"></i>
            <span>{{'BUILD.Event Location' | translate}}</span>
          </div>

          <!-- Country / Region -->
          <div class="max-width-245">
            <app-search-select
              [formGroup]="eventForm"
              [low]="true"
              [disabled]="readOnly"
              errorMassage="Country"
              name="country"
              label="Country / Region *"
              [selectOptions]="countryStateService.countriesOptions"
              (valueChanged)="onCountryChanged($event)"
            ></app-search-select>
          </div>

          <!-- Neighborhood / District / CEDEX / Area / Emirate / Townland / Prefecture / Oblast -->
          <div class="max-width-338" *ngIf="countryStateService.showTerritorialEntity$ | async">
            <app-input
              [disabled]="readOnly"
              [formGroup]="eventForm"
              [errorMassage]="countryStateService.territorialEntityError$ | async"
              [label]="countryStateService.territorialEntity$ | async"
              name="territorialEntity"
              [low]="true"
            ></app-input>
          </div>

          <!-- Street Address -->
          <div class="max-width-147 right-margin">
            <app-input
              [formGroup]="eventForm"
              [low]="true"
              [disabled]="readOnly"
              errorMassage="Street Address"
              label="Street Address *"
              name="address1"
            ></app-input>
          </div>

          <!-- Street Address 2 -->
          <div class="max-width-147">
            <app-input
              [formGroup]="eventForm"
              [low]="true"
              [disabled]="readOnly"
              errorMassage="Street Address"
              label="Street Address 2"
              name="address2"
            ></app-input>
          </div>

          <div class="max-width-338" *ngIf="countryStateService.showCity$ | async">
            <app-input
              [formGroup]="eventForm"
              name="city"
              [low]="true"
              [errorMassage]="countryStateService.cityError$ | async"
              [label]="countryStateService.city$ | async"
              [disabled]="readOnly"
            ></app-input>
          </div>

          <div class="max-width-147 right-margin" *ngIf="countryStateService.showState$ | async">
            <app-search-select
              *ngIf="countryStateService.isUsa$ | async"
              [disabled]="readOnly"
              [formGroup]="eventForm"
              [errorMassage]="countryStateService.stateError$ | async"
              name="state"
              [label]="countryStateService.state$ | async"
              placeholder="All states"
              [selectOptions]="stateOptions"
              [low]="true"
            ></app-search-select>
            <app-input
              *ngIf="!(countryStateService.isUsa$ | async)"
              [disabled]="readOnly"
              [formGroup]="eventForm"
              [errorMassage]="countryStateService.stateError$ | async"
              name="state"
              [label]="countryStateService.state$ | async"
              [low]="true"
            ></app-input>
          </div>

          <div class="max-width-147" *ngIf="countryStateService.showZip$ | async">
            <app-input
              [disabled]="readOnly"
              [formGroup]="eventForm"
              name="zip"
              [label]="countryStateService.zip$ | async"
              [errorMassage]="countryStateService.zipError$ | async"
              [placeholder]="countryStateService.zipPlaceholder$ | async"
              [mask]="countryStateService.zipMask$ | async"
              [type]="(countryStateService.isUsa$ | async) ? 'tel': 'text'"
              [onlyNumbers]="countryStateService.isUsa$ | async"
              [low]="true"
            ></app-input>
          </div>
        </div>

      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <div class="custom-tools-panel-title">{{'BUILD.Event Scheduler' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="email-builder-custom-tools-row">
          <div [draggable]="!(readOnly || predesignedTemplate)" (dragend)="dropItem(MjmlCustomType.EventScheduler)"
               class="tool-template right-margin">
            <i class="fas fa-calendar-day"></i>
            <span>{{'BUILD.Event Scheduler' | translate}}</span>
          </div>

          <div class="max-width-245">
            <app-select
              label="Time Zone"
              [formGroup]="eventForm"
              name="timeZoneID"
              [low]="true"
              errorMassage="Time Zone"
              [selectOptions]="timeZonesOptions"
              [disabled]="dateTimePickerDisabled"
            ></app-select>
          </div>

          <ng-container *ngFor="let scheduler of eventSchedularList.controls as schedulers; index as i;">
            <div class="max-width-147 right-margin">
              <app-date-picker
                [turnOn]="false"
                [formGroup]="scheduler"
                errorMassage="Start Date"
                label="Start Date *"
                name="startDate"
                placeholder="MM / DD / YYYY"
                [low]="true"
                [disabled]="dateTimePickerDisabled"
              ></app-date-picker>
            </div>

            <div class="max-width-147">
              <app-date-picker
                [turnOn]="false"
                [formGroup]="scheduler"
                errorMassage="End Date"
                label="End Date *"
                name="endDate"
                placeholder="MM / DD / YYYY"
                [low]="true"
                [disabled]="dateTimePickerDisabled"
              ></app-date-picker>
            </div>

            <div class="max-width-147 right-margin">
              <app-time-input
                [formGroup]="scheduler"
                errorMassage="time"
                name="startTime"
                label="Start Time"
                placeholder="HH / MM"
                [low]="true"
                [disabled]="dateTimePickerDisabled"
              ></app-time-input>
            </div>

            <div class="max-width-147">
              <app-time-input
                [formGroup]="scheduler"
                errorMassage="time"
                name="endTime"
                label="End Time"
                placeholder="HH / MM"
                [readonly]="false"
                [low]="true"
                [disabled]="dateTimePickerDisabled"
              ></app-time-input>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <div class="custom-tools-panel-title">{{'BUILD.Contact Details' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="email-builder-custom-tools-row">
          <div [draggable]="!(readOnly || predesignedTemplate)" (dragend)="dropItem(MjmlCustomType.EventContacts)" class="tool-template right-margin">
            <i class="fas fa-at"></i>
            <span>{{'BUILD.Contact Details' | translate}}</span>
          </div>

          <div class="max-width-245">
            <app-input
              label="Organization Name"
              [formGroup]="eventForm"
              name="clientName"
              [low]="true"
              errorMassage="Organization Name"
              [disabled]="readOnly"
            ></app-input>
          </div>

          <div class="max-width-147 right-margin">
            <app-input
              label="Website"
              [formGroup]="eventForm"
              name="clientWebSite"
              [low]="true"
              errorMassage="Website"
              [disabled]="readOnly"
            ></app-input>
          </div>
          <div class="max-width-147">
            <app-input
              label="Email"
              [formGroup]="eventForm"
              name="contactUsEmail"
              [low]="true"
              errorMassage="Email"
              [disabled]="readOnly"
            ></app-input>
          </div>
          <div class="max-width-338">
            <app-input
              label="Phone"
              [formGroup]="eventForm"
              mask="(000) 000-0000"
              name="contactUsPhone"
              placeholder="0 (000) 000-0000"
              prefix="+1 "
              [low]="true"
              errorMassage="Phone"
              [disabled]="readOnly"
            ></app-input>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="eventItineraries.value.length > 0">
      <mat-expansion-panel-header>
        <div class="custom-tools-panel-title">{{'BUILD.Schedule an Itinerary' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="email-builder-custom-tools-row">
          <div [draggable]="!(readOnly || predesignedTemplate)" (dragend)="dropItem(MjmlCustomType.EventItinerary)" class="tool-template right-margin">
            <i class="fas fa-clipboard-list"></i>
            <span>{{'BUILD.Schedule an Itinerary' | translate}}</span>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <div class="custom-tools-panel-title">{{'BUILD.Accessibility Statement Text' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="email-builder-custom-tools-row">
          <div [draggable]="!(readOnly || predesignedTemplate)" (dragend)="dropItem(MjmlCustomType.EventAccessibility)" class="tool-template right-margin">
            <i class="fas fa-universal-access"></i>
            <span>{{'BUILD.Accessibility Statement' | translate}}</span>
          </div>
          <div class="max-width-245">
            <app-textarea
              label="Accessibility Statement Text"
              [formGroup]="eventForm"
              name="accessabilityText"
              [low]="true"
              errorMassage="Accessibility Statement Text"
              minRows="2"
              [disabled]="readOnly"
            ></app-textarea>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </form>
</ng-container>


<ng-container *ngIf="templateAssignmentType === TemplateAssignmentType.Campaign">
  <form class="email-builder-custom-tools-wrapper" [formGroup]="campaignForm" (focusout)="onFocusOut(campaignForm)">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <div class="custom-tools-panel-title">{{'BUILD.Campaign Name' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="email-builder-custom-tools-row">
          <div [draggable]="!(readOnly || predesignedTemplate)" (dragend)="dropItem(MjmlCustomType.CampaignName)" class="tool-template right-margin">
            <i class="fas fa-heading"></i>
            <span>{{'BUILD.Campaign Name' | translate}}</span>
          </div>

          <div class="max-width-245">
            <app-input
              label="Campaign Name *"
              [formGroup]="campaignForm"
              name="name"
              [low]="true"
              errorMassage="Campaign Name"
              [disabled]="readOnly"
            ></app-input>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </form>
</ng-container>

