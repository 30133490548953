import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { FB_ALIGN, FB_SIZE } from '../../../../constants';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { AlignmentType, HeaderSize } from '../../../../models/form-builder/fb.template.model';

@Component({
  selector: 'app-header-footer-settings',
  templateUrl: './header-footer-settings.component.html',
  styleUrls: ['../tool-settings.scss']
})
export class HeaderFooterSettingsComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();
  @Input() public tms: TemplateManagementService;
  public imageURL: string = null;
  public alignmentOptions: FormElementDataModel[] = FB_ALIGN;
  public sizeOptions: FormElementDataModel[] = FB_SIZE;

  public optionsForm: FormGroup = this.formBuilder.group({
    logoSrc: '',
    logoPosition: '',
    logoSize: '',
    background: '',
    logoTarget: 'https://www.theauxilia.com/'
  });

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  public ngOnInit(): void {
    this.setInitialValues();
    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (!element || element.tagName !== MjmlTag.headerFooter) {
          return;
        }
        this.setInitialValues();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private get getHeaderAttr(): {[key: string]: string} {
    return this.tms?.template?.children[1]?.children[1]?.attributes;
  }

  private setInitialValues(): void {
    const attributes = this.getHeaderAttr;
    const logoSrc = attributes['logoSrc'] || '/assets/images/logo.png';
    const logoPosition = attributes['logoPosition'] || AlignmentType.Left;
    const logoSize = attributes['logoSize'] || HeaderSize.Medium;
    const background = attributes['background'] || '#023665';
    this.logoSrc.setValue(logoSrc);
    this.optionsForm.get('logoPosition').setValue(logoPosition);
    this.optionsForm.get('logoSize').setValue(logoSize);
    this.optionsForm.get('background').setValue(background);
  }

  public get logoSrc(): FormControl {
    return this.optionsForm.get('logoSrc') as FormControl;
  }

  public get logoTarget(): FormControl {
    return this.optionsForm.get('logoTarget') as FormControl;
  }

  public imageUrlChanged(url: string): void {
    this.imageURL = null;
    if (url === null) {
      return;
    }
  }

  public onUrlChanged(): void {
    this.getHeaderAttr['logoSrc'] = this.logoSrc.value;
    this.tms.emitSave(true);
  }

  public onLogoTargetChanged(): void {
    this.getHeaderAttr['logoTarget'] = this.logoTarget.value;
    this.tms.emitSave(true);
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public editImage(): void {
    this.imageURL = this.logoSrc.value;
  }

  public cancelEditImage(): void {
    this.imageURL = null;
  }

  public onOptionChanged(controlName: string): void {
    this.getHeaderAttr[controlName] = this.optionsForm.get(controlName).value;
    this.tms.emitSave(true);
  }

}
