import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import EventModel from '../../../../models/event/event.model';
import { Subscription, zip } from 'rxjs';
import { Filter } from '../../../../models/paging/filter.model';
import { EventService } from '../../../../services/events/event.service';
import { AuthService } from '../../../../services/auth.service';
import { EVENT_LIST_COLUMNS } from '../../../../constants';
import { FilterType } from '../../../../models/enum/filter.type';
import { EventStatus } from '../../../../models/enum/event.status';
import { Paging } from '../../../../models/paging/paging.model';
import { SortOrder } from '../../../../models/enum/sort.order';
import { MatDialog } from '@angular/material/dialog';
import { AdminConfirmationComponent } from '../../../admin/admin-confirmation/admin-confirmation.component';
import { ToastrService } from 'ngx-toastr';
import TicketPackageModel from '../../../../models/event/ticket.package.model';
import { BasePagingComponent } from '../../../../components/paginator/base.paging.component';
import ColumnFilterModel from '../../../../models/filter-sort/column.filter.model';
import { DashboardService } from '../../../../services/dashboard/dashboard.service';
import { ClientModuleService } from '../../client.module.service';
import FilterSortModel from '../../../../models/filter-sort/filter.sort.model';
import FormElementDataModel from '../../../../models/form.element.data.model';
import ColumnFilterOptionsModel from '../../../../models/filter-sort/column.filter.options.model';
import { map } from 'rxjs/operators';
import { ClientIdStateService } from '../../../../services/client.module.state/client.id.state.service';
import { UtilsComponent } from '../../../../components/utils.component';
import { EventParticipantsService } from 'src/app/services/events/event.participants.service';
import EventRegistrationsCountModel from 'src/app/models/event/event.registrations-count.model';

@Component({
  selector: 'app-events-summary',
  templateUrl: './events-summary.component.html',
  styleUrls: ['./events-summary.component.scss', '../widget.component.scss']
})
export class EventsSummaryComponent extends BasePagingComponent implements OnInit, OnDestroy {
  public tableData: EventModel[] = [];
  private registrations: EventRegistrationsCountModel[] = [];
  public tableColumns: string[] = [];
  private subscription: Subscription = new Subscription();

  public showSpinner: boolean = true;

  public clientsWithRelationships: FormElementDataModel[] = [];
  public currentClientID: string = this.authService.getIdentityClaimsOriginId();
  public filterSortConfig: FilterSortModel = {
    sortField: 'createdOn',
    sortOrder: SortOrder.Descending,
    columnFilters: []
  };
  public columnsFilterOptions: ColumnFilterOptionsModel[] = [{
    columnName: 'status',
    options: [
      {
        label: 'Live',
        value: EventStatus.Live
      },
      {
        label: 'Canceled',
        value: EventStatus.Canceled
      },
      {
        label: 'Complete',
        value: EventStatus.Complete
      }
    ]
  }];
  private baseFilter: Filter[];

  private statusFilter: Filter[] = [
    {
      field: 'status',
      value: EventStatus.Draft.toString(),
      type: FilterType.NotEqual,
    },
    {
      field: 'status',
      value: EventStatus.Archived.toString(),
      type: FilterType.NotEqual,
    }
  ];

  constructor(
    private router: Router,
    private eventService: EventService,
    private authService: AuthService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private dashboardService: DashboardService,
    private clientModuleService: ClientModuleService,
    private clientIdStateService: ClientIdStateService,
    private eventParticipantsService: EventParticipantsService 
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subscription.add(
      zip(this.clientModuleService.clientsWithRelationships, this.clientModuleService.clientName)
        .pipe(map(([clientsWithRelationships, clientName]) => ({clientsWithRelationships, clientName})))
        .subscribe(({clientsWithRelationships, clientName}) => {
          this.clientsWithRelationships = [{label: clientName, value: this.authService.getIdentityClaimsOriginId()}, ...clientsWithRelationships];
          this.columnsFilterOptions.push({columnName: 'clientID', options: [...this.clientsWithRelationships]});
          this.baseFilter = this.getBaseFilter();
          this.tableColumns = clientsWithRelationships.length ? EVENT_LIST_COLUMNS : EVENT_LIST_COLUMNS.filter((column: string) => column !== 'clientID');
        })
    );
    this.subscription.add(
      this.clientIdStateService.clientIdObservable.subscribe((clientId: string) => {
        const columnFilter: ColumnFilterModel = {field: 'clientID', values: [clientId]};
        this.onCurrentFilterChanged(columnFilter);
        this.currentClientID = clientId;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getBaseFilter(): Filter[] {
    const ids: string[] = this.clientsWithRelationships.map(({value}: FormElementDataModel) => `"${value}"`);
    const idsArray = `array[${ids.join(',')}]`;
    return [{
      field: 'clientID',
      value: idsArray,
      type: FilterType.Equal
    }];
  }

  private getFilters(): Filter[] {
    const tableFilter: Filter[] = this.getTableFilter();
    const clientFilter: Filter[] = !tableFilter.find(({field}: Filter) => field === 'clientID') ? this.baseFilter : [];
    const statusFilter: Filter[] = !tableFilter.find(({field}: Filter) => field === 'status') ? this.statusFilter : [];
    return [
      ...clientFilter,
      ...statusFilter,
      ...tableFilter,
    ];
  }

  private getTableFilter(): Filter[] {
    if (!this.filterSortConfig.columnFilters.length) {
      return [];
    } else {
      return this.filterSortConfig.columnFilters.map((columnFilter: ColumnFilterModel) => {
        const values = columnFilter.values.map((item: string) => `"${item}"`).join(',');
        return {
          field: columnFilter.field,
          value: `array[${values}]`,
          type: FilterType.Equal,
        };
      });
    }
  }

  public onCurrentFilterChanged(columnFilter: ColumnFilterModel): void {
    this.filterSortConfig.columnFilters = UtilsComponent.updateColumnFilters(columnFilter, this.filterSortConfig);
    this.applyFilter();
  }

  private applyFilter(): void {
    this.onFilterChanged();
    this.getEvents();
    this.getTotal();
  }

  public navigateToEvents(): void {
    this.router.navigateByUrl('/clients/events');
  }

  public getNextPage(page: number): void {
    this.nextPage(page, () => this.getEvents());
  }

  public setEntriesPerPage(amount: number): void {
    this.entriesPerPageChanged(amount, () => this.getEvents());
  }

  private getEvents(): void {
    this.showSpinner = true;
    const paging: Paging = {
      first: this.getFirstItemOnPage(),
      rows: this.entriesPerPage,
      includeDependencies: true,
      includeDeleted: false,
      filters: this.getFilters(),
      sortField: this.filterSortConfig.sortField,
      sortOrder: this.filterSortConfig.sortOrder,
      selectFields: ['createdByUserName', 'createdOn', 'endDate', 'name', 'priceTicketType', 'secondaryEventsList', 'startDate', 'status', 'ticketPackageList', 'id', 'clientID', 'hasRegistrationForm']
    };
    this.subscription.add(
      this.dashboardService.getEventsSummaryEventsList(paging).subscribe((eventList: EventModel[]) => {
        this.tableData = this.addTicketsData(eventList);
        this.showSpinner = false;
        const ids = eventList.map(({ id }: EventModel) => `"${id}"`).join(',');
        this.getRegistrations(ids, () => {
          this.tableData = this.tableData.map((event: EventModel) => {
            const currRegistration: EventRegistrationsCountModel = this.registrations.find((registration: EventRegistrationsCountModel) => registration.eventId === event.id);
            event.registration = currRegistration?.registrationsCount;
            event.tickets.sold = currRegistration?.ticketsCount;
            return event;
          });
        });
      })
    );
  }

  private getRegistrations(id: string, callback: () => void): void {
    const filters: Filter[] = [
      {
        field: 'eventID',
        value: `array[${id}]`,
        type: FilterType.Equal,
      }
    ];
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: false,
      filters,
      selectFields: ['eventID', 'numberInParty'],
    };
    this.subscription.add(
      this.eventParticipantsService.getEventRegistrationsCount(paging)
        .subscribe((registrations: EventRegistrationsCountModel[]) => {
          this.registrations = registrations;
          callback();
        })
    );
  }

  private getTotal(): void {
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: false,
      filters: this.getFilters()
    };
    this.subscription.add(
      this.dashboardService.getEventsSummaryEventsListTotal(paging).subscribe((total: number) => this.total = total)
    );
  }

  public editEvent({id}: EventModel): void {
    this.router.navigateByUrl(`clients/events/${id}`);
  }

  public navigateToEventDashboard(event: EventModel): void {
    this.router.navigateByUrl(`clients/events/dashboard/${event.id}`);
  }

  public cancelArchiveEvent(event: EventModel): void {
    const eventModel = {
      ...event,
      updatedByID: this.authService.getIdentityClaimsId(),
      clientID: this.authService.getIdentityClaimsOriginId(),
      updatedByUserName: this.authService.getIdentityClaimsName(),
    };
    let action = '';
    switch (event.status) {
      case EventStatus.Live:
        action = 'cancel';
        break;
      case EventStatus.Canceled:
      case EventStatus.Complete:
        action = 'archive';
        break;
    }
    const config = {
      data: {
        title: `Would you like to ${action} this Event?`
      }
    };
    this.subscription.add(
      this.dialog.open(AdminConfirmationComponent, config).afterClosed()
        .subscribe((result: boolean) => {
          if (!result) {
            return;
          } else {
            switch (event.status) {
              /** Cancel event */
              case EventStatus.Live:
                const canceledEvent: EventModel = {
                  ...eventModel,
                  status: EventStatus.Canceled,
                };
                this.eventService.updateModel(canceledEvent)
                  .subscribe(() => {
                    this.showSuccessMessage('Event has been canceled successfully');
                    this.getEvents();
                    this.getTotal();
                  });
                break;
              /** Archive event */
              case EventStatus.Complete:
              case EventStatus.Canceled:
                const archivedEvent: EventModel = {
                  ...eventModel,
                  status: EventStatus.Archived,
                };
                this.eventService.updateModel(archivedEvent)
                  .subscribe(() => {
                    this.showSuccessMessage('Event has been archived successfully');
                    this.getEvents();
                    this.getTotal();
                  });
                break;
            }
          }
        })
    );
  }

  private showSuccessMessage(message: string): void {
    this.toastrService.success(message, 'Notification');
  }

  private addTicketsData(eventList: EventModel[]): EventModel[] {
    return eventList.map((event: EventModel) => {
      const {ticketPackageList}: EventModel = event;
      let amount = 0;
      ticketPackageList.forEach(({number, inPackageCount}: TicketPackageModel) => {
        if (inPackageCount > 1) {
          amount += (+number * inPackageCount);
        } else {
          amount += +number;
        }
      });
      return {...event, tickets: { sold: 0, amount}};
    })
  }

}
