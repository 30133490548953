import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './routes/auth/home/home.component';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { GetConfigServiceFactory, GlobalVariable } from './shared/service';
import { ConfigService } from './services/appconfig.service';
import { ClientService } from './services/client/client.service';
import { HttpClient } from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';
import { RoutesGuard } from './guards/routes.guard';
import { ConfirmRegistrationComponent } from './routes/auth/confirm-registration/confirm-registration.component';
import { LayoutPreviewComponent } from './shared/components/layout-preview/layout-preview.component';
import { PasswordResetComponent } from './routes/auth/password-reset/password-reset.component';
import { NewPasswordComponent } from './routes/auth/new-password/new-password.component';
import { AnonymousLayoutComponent } from './anonymous-layout/anonymous-layout.component';
import { EventRegistrationFormComponent } from './shared/components/event-registration-form/event-registration-form.component';
import { CampaignPreviewComponent } from './shared/components/campaign-preview/campaign-preview.component';
import { UnsubscribeCampaignComponent } from './shared/components/unsubscribe-campaign/unsubscribe-campaign.component';
import { ClientsModuleGuard } from './guards/clients.module.guard';
import { DonorEmloyeeResolver } from './services/donor.employee/donor-employee.resolver';
import { DonorRegisterComponent } from './routes/donors-portal/components/donor-register/donor-register.component';
import { AdminModuleGuard } from './guards/admin.module.guard';
import { DonorPortalGuard } from './guards/donor-portal.guard';
import { PublishEventComponent } from './components/publish-event/publish-event.component';
import { SignUpAsDonorComponent } from './components/sign-up-as-donor/sign-up-as-donor.component';
import { EmbedPaymentComponent } from './components/embed-payment/embed-payment.component';
import { PaymentComponent } from './components/payment/payment/payment.component';
import { MyThermometerComponent } from './components/thermometer/my-thermometer/my-thermometer.component';
import { EventRegistrationGuard } from './guards/event-registration.guard';
import { PublishFundraiserComponent } from './components/publish-fundraiser/publish-fundraiser.component';
import { CreateFundraiserComponent } from './components/create-fundraiser/create-fundraiser.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    ]
  },
  {
    path: 'donor-register',
    component: LayoutComponent,
    children: [
      { path: '', component: DonorRegisterComponent },
    ]
  },
  {
    path: 'clients',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./routes/clients/clients.module').then(m => m.ClientsModule),
        canActivate: [RoutesGuard, ClientsModuleGuard],
        canLoad: [RoutesGuard, ClientsModuleGuard]
      }
    ]
  },
  {
    path: 'admin-dashboard',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./routes/admin/admin.module').then(m => m.AdminModule),
        canActivate: [RoutesGuard, AdminModuleGuard],
        canLoad: [RoutesGuard, AdminModuleGuard]
      }
    ]
  },
  {
    path: 'donor-portal',
    loadChildren: () => import('./routes/donors-portal/donors-portal.module').then(m => m.DonorsPortalModule),
    canActivate: [RoutesGuard, DonorPortalGuard],
    canLoad: [RoutesGuard, DonorPortalGuard]
  },
  {
    path: 'thermometer/:eventId',
    component: MyThermometerComponent
  },
  {
    path: 'resetpass',
    component: AnonymousLayoutComponent,
    children: [
      { path: '', component: PasswordResetComponent },
      { path: ':code/:email', component: NewPasswordComponent }
    ]
  },
  {
    path: 'registrationEvent/:eventId',
    component: EventRegistrationFormComponent,
    canDeactivate: [EventRegistrationGuard]
    /*component: AnonymousLayoutComponent,
    children: [{ path: '', component: EventRegistrationFormComponent }]*/
  },
  {
    path: 'registrationEvent/:eventId/P2P/:fundraiserId',
    component: EventRegistrationFormComponent,
    canDeactivate: [EventRegistrationGuard]
  },
  {
    path: 'embed/registrationEvent/:eventId',
    component: EventRegistrationFormComponent,
  },
  {
    path: 'registrationConfirm/:id',
    component: AnonymousLayoutComponent,
    children: [{ path: '', component: ConfirmRegistrationComponent }]
  },
  {
    path: 'registrationConfirm/donor/:id',
    component: AnonymousLayoutComponent,
    children: [
      {
        path: '',
        component: ConfirmRegistrationComponent,
        resolve: { donorEmployee: DonorEmloyeeResolver }
      }
    ]
  },
  {
    path: 'campaignPreview/:id',
    component: AnonymousLayoutComponent,
    children: [{ path: '', component: CampaignPreviewComponent }]
  },
  {
    path: 'Event/:friendlyURL',
    component: PublishEventComponent,
    pathMatch: 'full'
    /*component: AnonymousLayoutComponent,
    children: [
      { path: '', component: PublishEventComponent }
    ]*/
  },
  {
    path: 'publishEvent/:eventId',
    component: PublishEventComponent,
    pathMatch: 'full'
    /*component: AnonymousLayoutComponent,
    children: [
      { path: '', component: PublishEventComponent }
    ]*/
  },
  {
    path: 'CreateFundraiser/client/:clientId/event/:eventId/fundraiser/:fundraiserId',
    component: CreateFundraiserComponent,
    pathMatch: 'full'
  },
  {
    path: 'CreateFundraiser/client/:clientId/event/:eventId',
    component: CreateFundraiserComponent,
    pathMatch: 'full'
  },
  {
    path: 'Event/:friendlyURL/P2P/:ownerName/:customUrl',
    component: PublishFundraiserComponent,
    pathMatch: 'full'
  },
  {
    path: 'publishCampaign/:id',
    component: AnonymousLayoutComponent,
    children: [{ path: '', component: CampaignPreviewComponent }]
  },
  {
    path: 'previewEvent/:eventId',
    component: AnonymousLayoutComponent,
    children: [
      { path: '', component: LayoutPreviewComponent } // /previewEvent/2fb8844d-d883-40f9-90d0-032da49537f0
    ]
  },
  {
    path: 'unsubscribe/:clientId/:clientDonorId',
    component: AnonymousLayoutComponent,
    children: [{ path: '', component: UnsubscribeCampaignComponent }]
  },
  {
    path: 'payments',
    loadChildren: () => import('./routes/payments/payments.module').then(m => m.PaymentsModule),
  },
  { path: 'pay', pathMatch: 'full', redirectTo: '' },
  {
    path: 'pay',
    children: [{ path: '**', component: PaymentComponent}]
  },
  { path: 'embed/pay', pathMatch: 'full', redirectTo: '' },
  {
    path: 'embed/pay',
    children: [{ path: '**', component: PaymentComponent}]
  },
  {
    path: 'signUpAsDonor/:clientId',
    component: SignUpAsDonorComponent,
    pathMatch: 'full'
  },
  {
    path: 'embed/event/:eventId',
    component: PublishEventComponent,
    pathMatch: 'full'
  },
  {
    path: 'embed/payments',
    component: EmbedPaymentComponent,
    children: [
      {path: '', loadChildren: () => import('./routes/payments/payments.module').then(m => m.PaymentsModule)}
    ],
  },
  {
    path: 'eventPreview/:eventId',
    redirectTo: 'previewEvent/:eventId'
  },
  {
    path: 'previewCampaign/:eventId',
    redirectTo: 'campaignPreview/:eventId'
  },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule],
  providers: [
    { provide: OAuthService, useValue: GlobalVariable.oathService },
    { provide: OAuthStorage, useValue: localStorage },
    {
      provide: ConfigService,
      deps: [HttpClient],
      useFactory: (http: HttpClient) => GetConfigServiceFactory(http)
    },
    ClientService
  ]
})
export class AppRoutingModule {}
