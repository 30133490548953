import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import {TemplateManagementService} from '../../../../services/templates/template.management.service';
import {SHARE_ON_ICONS, SHARE_ON_SM_COLORS} from '../../../../constants';
import {ShareOnSmType} from '../../../../models/templates/share.on.sm.type';
import { Observable, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-share-on-sm',
  templateUrl: './share-on-sm.component.html',
  styleUrls: ['./share-on-sm.component.scss', '../img-tool.scss']
})
export class ShareOnSmComponent implements OnInit {
  @Input() public column: MjmlElementModel;
  @Input() public entity: MjmlElementModel;
  @Input() public tms: TemplateManagementService;
  @Input() public landingPageView: boolean = false;

  public ShareOnSmType = ShareOnSmType;
  public emailHref$: Observable<string>;
  public twitterHref$: Observable<string>;
  public facebookHref$: Observable<string>;
  public linkedInHref$: Observable<string>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta
  ) {
  }

  public get isEmpty(): boolean {
    return this.entity ? this.entity.children ? !this.entity.children.length : true : true;
  }

  public get text(): string {
    return this.entity.attributes['text'] || 'Share with Friends:';
  }

  public ngOnInit(): void {
    if (this.landingPageView) {
      this.emailHref$ = of(`mailto:?subject=Check Out ${this.entity.attributes['eventName']}.&body=We hope to see you there: ${location.href}`);
      this.twitterHref$ = of(encodeURI(`https://twitter.com/share?text=Check out, ${this.entity.attributes['eventName']}&url=${location.href}`));
      this.facebookHref$ = of(`https://www.facebook.com/sharer/sharer.php?u=${location.href}`);
      this.linkedInHref$ = of(encodeURI(`https://www.linkedin.com/shareArticle?mini=true&url=${location.href}&title=${this.entity.attributes['eventName']}&summary=${this.entity.attributes['eventDescription']}&source=${location.origin}`));
      this.addMetaData();
    }
  }

  public get containerStyles(): { [key: string]: any } {
    const attributes = this.entity.attributes;
    return {
      'font-family': attributes['font-family'] || 'Roboto',
      'font-size': attributes['font-size'] || '13px',
      'color': attributes['color'] || '#333333',
      'font-style': attributes['font-style'] || 'normal',
      'font-weight': attributes['font-weight'] || 'normal',
      'text-decoration': attributes['text-decoration'] || 'none',
      'background-color': attributes['container-background-color'] || 'transparent',
      'padding-bottom': attributes['padding-bottom'] || '10px',
      'padding-left': attributes['padding-left'] || '25px',
      'padding-right': attributes['padding-right'] || '25px',
      'padding-top': attributes['padding-top'] || '10px'
    }
  }

  public get wrapperStyles(): { [key: string]: any } {
    const attributes = this.entity.attributes;
    const styles = {};
    const align = attributes['align'] || 'center';
    switch (align) {
      case 'center':
        styles['align-items'] = 'center';
        break;
      case 'left':
        styles['align-items'] = 'flex-start';
        break;
      case 'right':
        styles['align-items'] = 'flex-end';
        break;
    }
    return styles;
  }

  public imgSrc(name: string): string {
    return SHARE_ON_ICONS[name];
  }

  public getImageStyles(child: MjmlElementModel): { [key: string]: any } {
    const attributes = this.entity.attributes;
    const childName = child.attributes['name'];
    return {
      'border-radius': attributes['border-radius'] || '3px',
      'background-color': child.attributes['background-color'] || SHARE_ON_SM_COLORS[childName],
      'width': attributes['icon-size'] || '20px',
      'padding': childName === ShareOnSmType.email ? '15%' : '0'
    };
  }

  public get iconStyles(): { [key: string]: any } {
    return {
      'padding': this.entity.attributes['inner-padding'] || '4px'
    };
  }

  public get modeStyles(): { [key: string]: any } {
    const attributes = this.entity.attributes;
    const styles = {};
    const mode = attributes['mode'] || 'horizontal';
    switch (mode) {
      case 'horizontal':
        styles['flex-direction'] = 'row';
        break;
      case 'vertical':
        styles['flex-direction'] = 'column';
        break;
    }
    return styles;
  }

  private addMetaData(): void {
    //todo remove first
    const head = this.document.head;
    this.meta.addTags([
      {
        name: 'twitter:domain',
        content: location.origin
      },
      {
        name: 'twitter:title',
        content: this.entity.attributes['eventName']
      },
      {
        property: 'og:title',
        content: this.entity.attributes['eventName']
      },
      {
        name: 'twitter:description',
        content: this.entity.attributes['eventDescription']
      },
      {
        property: 'og:description',
        content: this.entity.attributes['eventDescription']
      },
      {
        property: 'og:description',
        content: this.entity.attributes['eventDescription']
      },
      {
        property: 'og:url',
        content: location.href
      },
      {
        property: 'og:image',
        content: this.entity.attributes['image-url']
      },
      {
        name: 'twitter:image',
        content: this.entity.attributes['image-url']
      },
    ], true)

    /*const twitterDomain = this.document.createElement('meta');
    twitterDomain.setAttribute('name', 'twitter:domain');
    twitterDomain.setAttribute('content', location.origin);
    head.appendChild(twitterDomain);*/

   /* const title = this.document.createElement('meta');
    title.setAttribute('name', 'twitter:title');
    title.setAttribute('content', this.entity.attributes['eventName']);
    head.appendChild(title);*/

    /*const ogTitle = this.document.createElement('meta');
    ogTitle.setAttribute('property', 'og:title');
    ogTitle.setAttribute('content', this.entity.attributes['eventName']);
    head.appendChild(ogTitle);*/

    /*const description = this.document.createElement('meta');
    description.setAttribute('name', 'twitter:description');
    description.setAttribute('content', this.entity.attributes['eventDescription']);
    head.appendChild(description);*/

   /* const ogDescription = this.document.createElement('meta');
    ogDescription.setAttribute('property', 'og:description');
    ogDescription.setAttribute('content', this.entity.attributes['eventDescription']);
    head.appendChild(ogDescription);*/

    const imageLink = this.document.createElement('link');
    imageLink.setAttribute('rel', 'image_src');
    imageLink.setAttribute('href', this.entity.attributes['image-url']);
    head.appendChild(imageLink);

    const canonical = this.document.createElement('link');
    canonical.setAttribute('rel', 'canonical');
    canonical.setAttribute('href', location.href);
    head.appendChild(canonical);

   /* const ogUrl = this.document.createElement('meta');
    ogUrl.setAttribute('property', 'og:url');
    ogUrl.setAttribute('content', location.href);
    head.appendChild(ogUrl);*/

    /*const ogImage = this.document.createElement('meta');
    ogImage.setAttribute('property', 'og:image');
    ogImage.setAttribute('content', this.entity.attributes['image-url']);
    head.appendChild(ogImage);*/

   /* const twitterImage = this.document.createElement('meta');
    twitterImage.setAttribute('name', 'twitter:image');
    twitterImage.setAttribute('content', this.entity.attributes['image-url']);
    head.appendChild(twitterImage);*/
  }
}

