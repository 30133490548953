import { AzureFileModel } from 'src/app/models/files/azure-file.model';
import { AzureUploadFileService } from 'src/app/services/lookup/azure-upload-file.service';
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Guid } from 'guid-typescript';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './app-image-cropper.component.html',
  styleUrls: ['./app-image-cropper.component.scss']
})
export class AppImageCropperComponent implements OnInit, OnChanges {
  @Input() private imageName: string;
  @Input() private inputEvent: any;
  @Input() public aspectRatio: number;
  @Input() public resizeToWidth: number;
  private croppedImage: string = '';
  public imageChangedEvent: any = '';

  @Output() public imageNameChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(private uploadService: AzureUploadFileService, private toastr: ToastrService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const file = this.inputEvent && this.inputEvent.target.files[0];
    if (file) {
      const fileNameSplitArray = file.name.split('.');
      const extension = fileNameSplitArray[fileNameSplitArray.length - 1].toLowerCase();
      if (file.size > 3145728) {
        this.toastr.warning('Size of logo can not be bigger than 3mb');
        return;
      }
      if (extension !== 'jpeg' && extension !== 'jpg' && extension !== 'png' && extension !== 'gif') {
        this.toastr.warning('File type must be jpg, png, gif');
        return;
      }
      if (!HTMLCanvasElement.prototype.toBlob) {
        Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
          value: function (callback, type, quality) {
            const dataURL = this.toDataURL(type, quality).split(',')[1];
            setTimeout(function () {
              const binStr = atob(dataURL),
                len = binStr.length,
                arr = new Uint8Array(len);
              for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
              }
              callback(new Blob([arr], { type: type || `image/${extension}` }));
            });
          }
        });
      }
    }
    this.imageChangedEvent = this.inputEvent;
  }

  public imageCropped(image: ImageCroppedEvent): void {
    this.croppedImage = image.base64;
  }

  public fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  public sendPhotoToServer(): void {
    const imgName = `${Guid.create()}.${this.croppedImage.substring(11, 14)}`;
    const azure: AzureFileModel = {
      file: this.croppedImage.substring(22, this.croppedImage.length),
      name: imgName,
      folder: 'images'
    };
    if (this.imageName && !this.imageName.includes('assets')) {
      this.uploadService.deleteModel(this.imageName).subscribe(() => { });
    }
    this.uploadService.addModel(azure).subscribe(response => {
      this.imageName = response.name;
      this.imageNameChanged.next(response.name);
    });
    this.fileChangeEvent(null);
  }

  public cancel(): void {
    this.imageNameChanged.next(null);
  }
}
