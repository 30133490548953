<div class="image-tool-wrapper" [ngStyle]="getContainerStyles()">
  <div *ngIf="!entity.attributes.src; else imageTemplate" class="empty-image-src">{{'Chart / Graph' | translate}}</div>
</div>

<ng-template #imageTemplate>
  <table style="width: 100%">
    <tr>
      <td>
        <img [src]="entity.attributes.src" style="width: 100%; height: auto">
      </td>
    </tr>
  </table>
</ng-template>
