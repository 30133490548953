<ng-container [ngSwitch]="layer.type">
  <!-- Circle -->
  <ng-container *ngSwitchCase="LayerType.circle">
    <svg xmlns="http://www.w3.org/2000/svg" width="600" height="600">
      <circle cx="300" cy="300" r="210" fill="none" stroke="black" stroke-width="8" #elementRef></circle>
    </svg>
  </ng-container>

  <!-- Square -->
  <ng-container *ngSwitchCase="LayerType.square">
    <svg xmlns="http://www.w3.org/2000/svg" width="600" height="600">
      <rect x="25" y="25" width="550" height="550" fill="none" stroke="black" stroke-width="8" #elementRef></rect>
    </svg>
  </ng-container>

  <!-- Triangle -->
  <ng-container *ngSwitchCase="LayerType.triangle">
    <svg xmlns="http://www.w3.org/2000/svg" width="600" height="600">
      <polygon points="300,65 550,565 50,565 300,65" fill="none" stroke="black" stroke-width="8" #elementRef></polygon>
    </svg>
  </ng-container>

  <!-- Pentagon -->
  <ng-container *ngSwitchCase="LayerType.pentagon">
    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512">
      <polygon
        #elementRef
        points="408.067,500.333 100.867,500.333 7,225.56 254.467,8.813 501.933,227.267"
        fill="none"
        stroke="black"
        stroke-width="8"
        transform="translate(27, 30), scale(0.9)"
      ></polygon>
    </svg>
  </ng-container>

  <!-- Hexagon -->
  <ng-container *ngSwitchCase="LayerType.hexagon">
    <svg xmlns="http://www.w3.org/2000/svg" width="726" height="628">
      <polygon
        #elementRef
        points="723,314 543,625.769145 183,625.769145 3,314 183,2.230855 543,2.230855 723,314"
        fill="none"
        stroke="black"
        stroke-width="8"
        transform="translate(36, 31), scale(0.9)"
      ></polygon>
    </svg>
  </ng-container>

</ng-container>
