<div class="registration-contact-info-wrapper" *ngIf="!isGuest">

  <div class="ticket-types-block-title">{{'BUILD.Contact' | translate}}</div>

  <form class="registration-details-form">

    <div class="registration-details-form-row">
      <div class="max-width-380 right-margin" #firstNameRef>
        <app-input [inputReadOnly]="isDisabled"
                   [formGroup]="registrationForm"
                   errorMassage="First Name"
                   name="firstName"
                   label="First Name *"></app-input>
      </div>

      <div class="max-width-380" #lastNameRef>
        <app-input [inputReadOnly]="isDisabled"
                   [formGroup]="registrationForm"
                   errorMassage="Last Name"
                   name="lastName"
                   label="Last Name *"></app-input>
      </div>
    </div>

    <div class="registration-details-form-row">
      <div class="max-width-380 right-margin" #titleRef>
        <app-input [inputReadOnly]="isDisabled"
                   errorMassage="Title"
                   [formGroup]="registrationForm"
                   name="title"
                   label="Title"></app-input>
      </div>

      <div class="max-width-380" #emailRef>
        <app-input [inputReadOnly]="isDisabled"
                   [formGroup]="registrationForm"
                   errorMassage="Email"
                   name="email"
                   [label]="isRequiredField(registrationForm, 'email') ? 'Email *' : 'Email'"></app-input>
      </div>
    </div>

    <div class="registration-details-form-row">

      <!-- Country / Region -->
      <div class="max-width-380" #countryRef>
        <app-search-select [disabled]="isDisabled"
                           [formGroup]="registrationForm"
                           errorMassage="Country"
                           name="country"
                           [label]="isRequiredField(registrationForm, 'country') ? 'Country / Region *' : 'Country / Region'"
                           [selectOptions]="lookupStoreService.countriesOptions$ | async"
                           (valueChanged)="onCountryChanged($event)"></app-search-select>
      </div>
    </div>

    <div class="registration-details-form-row">
      <!-- Phone -->
      <div class="max-width-380 right-margin" #phoneRef>
        <app-input [inputReadOnly]="isDisabled"
                   [formGroup]="registrationForm"
                   [prefix]="phonePrefix$ | async"
                   name="phone"
                   [label]="isRequiredField(registrationForm, 'phone') ? 'Phone *' : 'Phone'"
                   [placeholder]="phonePlaceholder$ | async"
                   errorMassage="Phone"
                   type="tel"
                   [mask]="phoneMask$ | async"></app-input>
      </div>

      <!-- Neighborhood / District / CEDEX / Area / Emirate / Townland / Prefecture / Oblast -->
      <div *ngIf="showTerritorialEntity$ | async"
           class="max-width-380"
           #territorialEntityRef>
        <app-input [inputReadOnly]="isDisabled"
                   [formGroup]="registrationForm"
                   name="territorialEntity"
                   [errorMassage]="territorialEntityError$ | async"
                   [label]="territorialEntity$ | async"></app-input>
      </div>
    </div>

    <div class="registration-details-form-row">

      <!-- Street  -->
      <div class="max-width-380 right-margin" #streetAddressRef>
        <app-input [inputReadOnly]="isDisabled"
                   [formGroup]="registrationForm"
                   name="streetAddress"
                   errorMassage="Street Address"
                   [label]="isRequiredField(registrationForm, 'streetAddress') ? 'Street Address *' : 'Street Address'"></app-input>
      </div>
      <!-- Street 2 -->
      <div class="max-width-380" #streetAddress2Ref>
        <app-input [inputReadOnly]="isDisabled"
                   [formGroup]="registrationForm"
                   name="streetAddress2"
                   errorMassage="Street Address 2"
                   label="Street Address 2 (Building #, Suite, Apt.)"></app-input>
      </div>
    </div>

    <div class="registration-details-form-row">

      <!-- City / Town / Locality / Suburb / Country / Post Town / Village / Township -->
      <div *ngIf="showCity$ | async"
           class="max-width-380 right-margin"
           #cityRef>
        <app-input [formGroup]="registrationForm"
                   name="city"
                   [inputReadOnly]="isDisabled"
                   [errorMassage]="cityError$ | async"
                   [label]="city$ | async"></app-input>
      </div>

      <div class="state-zip-wrapper">
        <!-- State / Province / Territory / Island / Parish / Department, Do/Si -->
        <div *ngIf="showState$ | async"
             class="length-280"
             #stateRef>
          <app-search-select *ngIf="isUsa$ | async"
                             [formGroup]="registrationForm"
                             [disabled]="isDisabled"
                             [errorMassage]="stateError$ | async"
                             name="stateId"
                             [label]="state$ | async"
                             placeholder="All states"
                             [selectOptions]="lookupStoreService.usaStatesOptions$ | async"></app-search-select>

          <app-input *ngIf="!(isUsa$ | async)"
                     [inputReadOnly]="isDisabled"
                     [disabled]="isDisabled"
                     [formGroup]="registrationForm"
                     [errorMassage]="stateError$ | async"
                     name="stateId"
                     [label]="state$ | async"></app-input>
        </div>

        <!-- Zip Code / Postal Code -->
        <div *ngIf="showZip$ | async"
             class="length-120"
             #zipCodeRef>
          <app-input [inputReadOnly]="isDisabled"
                     [formGroup]="registrationForm"
                     name="zipCode"
                     [label]="zip$ | async"
                     [errorMassage]="zipError$ | async"
                     [placeholder]="zipPlaceholder$ | async"
                     [mask]="zipMask$ | async"
                     [type]="(isUsa$ | async) ? 'tel': 'text'"
                     [onlyNumbers]="isUsa$ | async"></app-input>
        </div>
      </div>

      <div class="max-width-380 min-width-380 right-margin" *ngIf="guestTicketOptions?.length || getMainTicketType()?.length">
        <app-select [formGroup]="registrationForm"
                    name="ticketPackageId"
                    label="Ticket Type *"
                    errorMassage="Ticket Type"
                    [selectOptions]="getMainTicketType()"
                    (valueChanged)="setMainGuestAddons()"></app-select>
      </div>

      <ng-container *ngIf="isAddonsAndTicketSelected(registrationForm.get('ticketPackageId'))">
        <ng-container *ngFor="let mainAddons of getEventTicketAddons.controls; index as i">
          <div class="max-width-380 min-width-380"
               [ngClass]="{rightMargin: registrationForm.get('stateId')?.value ? ((i+1) % 2 === 0) : ((i+1) % 2 !== 0)}"
               *ngIf="getQuantityOptions(mainAddons.get('selectedOptions').value, registrationForm.get('ticketPackageId').value, mainAddons.get('addonID').value, mainAddons).length">
            <p style="height: 18px; margin: 0" *ngIf="getAddonsLabel(mainAddons.get('addonID')?.value) === ''"> </p>
            <app-select [formGroup]="mainAddons"
                        name="includeID"
                        label="{{getAddonsLabel(mainAddons.get('addonID')?.value)}}"
                        [selectOptions]="getQuantityOptions(mainAddons.get('selectedOptions').value, registrationForm.get('ticketPackageId')?.value, mainAddons.get('addonID').value, mainAddons)"></app-select>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <!--Volunteering option-->
    <ng-container *ngIf="(eventHasVolunteeringOptions$ | async) && getAvailableVolunteeringOptions(registrationForm.get('volunteeringListIds')).length">

      <div class="registration-details-form-row">

        <app-radio [disabled]="isPreviewMode"
                   [formGroup]="registrationForm"
                   name="isVolunteer"
                   label="Would you like to be a Volunteer?"
                   [selectOptions]="locationRadioButtonData"
                   (radioValueChanged)="volunteersActiveChanged($event, registrationForm.get('volunteeringListIds'))"></app-radio>

      </div>

      <div *ngIf="registrationForm.get('isVolunteer')?.valueChanges | async"
           class="registration-details-form-row">
        <div class="max-width-380 right-margin">
          <app-volunteers-select [formGroup]="registrationForm"
                                 name="volunteeringListIds"
                                 label="Preferred Area to Volunteer"
                                 errorMassage="Area to Volunteer"
                                 [volunteeringList]="getAvailableVolunteeringOptions(registrationForm.get('volunteeringListIds'))"
                                 (valueChanged)="manageVolunteeringSelection()"></app-volunteers-select>
        </div>

        <div *ngIf="registrationForm.get('volunteeringListIds')?.value as selectedVolunteers"
             class="max-width-380">
          <div class="selected-volunteers">
            <p *ngFor="let id of selectedVolunteers">
              <span>{{selectedVolunteersArea(id)}}</span>
              <span>{{selectedVolunteersDate(id)}}</span>
            </p>
          </div>
        </div>

      </div>

    </ng-container>
    <div *ngIf="guestMaxReached" class="guest-maxed-out">
      <span>
        {{"BUILD.Max guest count has been reached" | translate}}
      </span>
    </div>
    <div class="registration-details-form-row">
      <app-radio [disabled]="isPreviewMode || guestMaxReached"
                 [formGroup]="registrationForm"
                 name="hasGuest"
                 label="Would you like to add a guest?"
                 [selectOptions]="locationRadioButtonData"
                 (radioValueChanged)="manageParticipants($event)"></app-radio>
    </div>

    <ng-container *ngIf="this.registrationForm.controls?.hasGuest?.value && participantList?.length"
                  #getAdditionalParticipantRef>
      <ng-container *ngFor="let participant of getAdditionalParticipant?.controls; index as i">
        <div class="registration-details-form-row">
          <div class="max-width-380 right-margin">
            <app-input [formGroup]="participant"
                       name="fullName"
                       label="Guest Full Name *"
                       errorMassage="Full Name"></app-input>
          </div>

          <div class="max-width-380">
            <app-input [formGroup]="participant"
                       name="email"
                       [label]="isRequiredField(participant, 'email') ? 'Guest Email *' : 'Guest Email'"
                       errorMassage="Email"></app-input>
          </div>
        </div>

        <div class="registration-details-form-row">
          <div class="max-width-380 right-margin">
            <app-input [formGroup]="participant"
                       [placeholder]="phonePlaceholder$ | async"
                       [mask]="phoneMask$ | async"
                       [prefix]="phonePrefix$ | async"
                       name="phone"
                       [label]="isRequiredField(participant, 'phone') ? 'Guest Phone Number *' : 'Guest Phone Number'"
                       errorMassage="Phone"
                       type="tel"></app-input>
          </div>

          <div class="max-width-380">
            <app-select *ngIf="eventModel.priceTicketType === PriceTicket.Ticket"
                        [formGroup]="participant"
                        name="ticketType"
                        label="Ticket Type *"
                        errorMassage="Ticket Type"
                        [selectOptions]="getGuestTicketOptions(participant)"
                        (valueChanged)="updateGuestTicketOptions(participant)"></app-select>
          </div>

          <ng-container *ngIf="isAddonsAndTicketSelected(participant.get('ticketType')) && getParticipantAddonForm(participant)">
            <ng-container *ngFor="let additionalAddons of getParticipantAddonForm(participant)?.controls; index as i">
              <div class="max-width-380 min-width-380"
                   [ngClass]="{rightMargin: !((i+1) % 2 === 0)}"
                   *ngIf="getQuantityOptions(additionalAddons.get('selectedOptions').value, participant.get('ticketType').value, additionalAddons.get('addonID').value, additionalAddons).length">
                <p style="height: 18px; margin: 0" *ngIf="getAddonsLabel(additionalAddons.get('addonID').value) === ''"> </p>
                <app-select [formGroup]="additionalAddons"
                            name="includeID"
                            label="{{getAddonsLabel(additionalAddons.get('addonID').value)}}"
                            [selectOptions]="getQuantityOptions(additionalAddons.get('selectedOptions').value, participant.get('ticketType').value, additionalAddons.get('addonID').value, additionalAddons)"></app-select>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <!--Volunteering Block-->

        <ng-container *ngIf="(eventHasVolunteeringOptions$ | async) && getAvailableVolunteeringOptions(participant.get('volunteeringListIds')).length">

          <div class="registration-details-form-row">
            <app-radio [disabled]="isPreviewMode"
                       [formGroup]="participant"
                       name="isVolunteer"
                       label="Would you like to be a Volunteer?"
                       [selectOptions]="locationRadioButtonData"
                       (radioValueChanged)="volunteersActiveChanged($event, participant.get('volunteeringListIds'))"></app-radio>

          </div>

          <div *ngIf="participant.get('isVolunteer').valueChanges | async"
               class="registration-details-form-row">
            <div class="max-width-380 right-margin">
              <app-volunteers-select [formGroup]="participant"
                                     name="volunteeringListIds"
                                     label="Preferred Area to Volunteer"
                                     errorMassage="Area to Volunteer"
                                     [volunteeringList]="getAvailableEditVolunteeringOptions(participant.get('volunteeringListIds'))"
                                     (valueChanged)="manageVolunteeringSelection()"></app-volunteers-select>
            </div>

            <div *ngIf="participant.get('volunteeringListIds').value as selectedVolunteers"
                 class="max-width-380">
              <div class="selected-volunteers">
                <p *ngFor="let id of selectedVolunteers">
                  <span>{{selectedVolunteersArea(id)}}</span>
                  <span>{{selectedVolunteersDate(id)}}</span>
                </p>
              </div>
            </div>
          </div>
        </ng-container>

        <div style="width: 100%; position: relative;"
             *ngIf="i !== 0">
          <img src="/assets/images/icon-minus.png"
               (click)="deleteGuest(i)"
               style="position: absolute; right: 0; margin-top: -20px;">
        </div>
      </ng-container>
      <br>
      <app-button [disabled]="isAdditionlParticipantListInvalid"
                  type="stroked"
                  (clickEvent)="addGuest()"
                  width="180"
                  color="#20a643">
        <div class="button-label">
          <app-plus-icon color="#20a643"></app-plus-icon>
          <span [style.color]="'#20a643'">{{'BUILD.ADD GUEST' | translate}}</span>
        </div>
      </app-button>
    </ng-container>
  </form>

  <div class="ticket-types-block-title">{{'BUILD.Additional Notes' | translate}}</div>
  <div class="registration-details-form-row">
    <app-textarea
      [disabled]="isDisabled"
      [formGroup]="registrationForm"
      errorMassage="Additional Notes"
      name="note"
      label="Add any special requests, limitations, or notes here, etc…"
      minRows="4"
    ></app-textarea>
  </div>

</div>

<div class="registration-contact-info-wrapper" *ngIf="isGuest">
  <form class="registration-details-form">
      <div class="registration-details-form-row">
        <div class="max-width-380 right-margin">
          <app-input
            #firstNameRef
            [formGroup]="editAdditionalGuest"
            name="fullName"
            label="Guest Full Name *"
            errorMassage="Full Name"
          ></app-input>
        </div>

        <div class="max-width-380">
          <app-input
            #emailRef
            [formGroup]="editAdditionalGuest"
            name="email"
            label="Guest Email"
            errorMassage="Email"
          ></app-input>
        </div>
      </div>
      <div class="registration-details-form-row">
        <div class="max-width-380 right-margin">
          <app-input
            #phoneRef
            [formGroup]="editAdditionalGuest"
            name="phone"
            [placeholder]="phonePlaceholder$ | async"
            [mask]="phoneMask$ | async"
            [prefix]="phonePrefix$ | async"
            [label]="'Guest Phone Number'"
            errorMassage="Phone"
            type="tel"
          ></app-input>
        </div>
        <!--    *ngIf="eventModel.priceTicketType === 1"-->
        <div class="max-width-380" style="opacity: 0.7">
          <app-select
            [formGroup]="editAdditionalGuest"
            name="ticketType"
            label="Ticket Type *"
            errorMassage="Ticket Type"
            [disabled]="true"
            [selectOptions]="ticketTypeOptions"
            (valueChanged)="updateGuestTicketOptions()"
          ></app-select>
        </div>
      </div>
      <div class="registration-details-form-row">
        <div class="max-width-380 right-margin" *ngIf="eventParticipant.volunteeringIds.length">
          <app-volunteers-select
            [formGroup]="editAdditionalGuest"
            name="volunteeringListIds"
            label="Preferred Area to Volunteer"
            errorMassage="Area to Volunteer"
            [volunteeringList]="getAvailableEditVolunteeringOptions(editAdditionalGuest.get('volunteeringListIds'))"
            (valueChanged)="manageVolunteeringSelection()"
          ></app-volunteers-select>
        </div>

        <ng-container *ngIf="isMealOptions">
          <ng-container *ngFor="let meal of getAddonGuest.controls; index as i">
            <div
              *ngIf="meal.get('includeId').value !== null"
              class="max-width-380 min-width-380"
              [ngClass]="{rightMargin: eventParticipant.volunteeringIds.length ? ((i+1) % 2 === 0) : ((i+1) % 2 !== 0)}"
            >
              <app-select
                [formGroup]="meal"
                name="includeId"
                label="{{getAddonName(meal.get('addonId').value)}}"
                [selectOptions]="meal.get('selectedOptions').value"
                (valueChanged)="updateGuestTicketOptions()"
              ></app-select>
            </div>
          </ng-container>
        </ng-container>
      </div>
  </form>
</div>
