import { Component, Input, OnInit } from '@angular/core';
import MjmlElementModel from 'src/app/models/templates/mjml.element.model';

@Component({
  selector: 'app-sponsors-tool',
  templateUrl: './sponsors-tool.component.html',
  styleUrls: ['../img-tool.scss', './sponsors-tool.component.scss', '../../../../shared/styles/layout.scss']
})
export class SponsorsToolComponent implements OnInit {
  @Input() entity: MjmlElementModel;
  @Input() landingPageView: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  getContainerStyles(): any {
    const attributes = this.entity.attributes;
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    return {
      'padding-bottom': paddingBottom,
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      'padding-top': paddingTop,
    };
  }

  get srcUrls(): string[] {
    const srcsAttr: string = this.entity.attributes["srcs"];
    if(!srcsAttr) return null;
    return (JSON.parse(srcsAttr) as []).map((src: {url: string}) => src.url);
  }
}
