<!--Header-->
<app-event-header
  *ngIf="(showHeaderAndFooter$ | async) && template && registrationStep !== 3"
  [mjml]="template"
></app-event-header>

<div class="main-container" [ngStyle]="{'pointer-events':isPreviewMode ? 'auto' : 'auto' }"
     *ngIf="registrationStep !== 3">

  <app-mjml-renderer
    *ngIf="mjml$ | async as mjml"
    [eventId]="eventModel?.id"
    [mjml]="mjml"
  ></app-mjml-renderer>

  <div style="position: relative;" *ngIf="!useDnDEmailBuilder">
    <img [src]="imageURL" style="max-width: 100%; min-height: 135px;"/>
    <label style="font-weight: bold;" [style.fontFamily]="getHeaderEvent?.fontName"
           [style.fontSize.px]="getHeaderEvent?.fontSize" class="c-e-lp-body-example-header-label"
           [style.color]="getHeaderEvent?.color">{{eventModel?.name}}</label>
  </div>

  <div class="form-container" [class.calc]="isRegistrationComplete" [style.backgroundColor]="getMainBackground?.color" *ngIf="registrationStep !== 3">
    <div class="event-info">
      <div class="event-info-column">
        <div class="info-block">
          <h1 [style.fontFamily]="getMainTitles?.fontName"
              [style.color]="getMainTitles?.color"
              [style.fontSize.px]="getMainDetails?.fontSize"
          >{{'BUILD.DATE' | translate}}</h1>
          <p *ngFor="let schedular of eventModel?.eventSchedularList"
             [style.fontFamily]="getMainDetails?.fontName"
             [style.color]="getMainDetails?.color"
             [style.fontSize.px]="getMainDetails?.fontSize"
          >
            <span *ngIf="isNoEndDate(schedular)">{{schedular.startDate | date:'MMMM dd, yyyy'}}</span>
            <span *ngIf="!isNoEndDate(schedular)">{{schedular.startDate | date:'MMMM dd, yyyy'}} - {{schedular.endDate | date:'MMMM dd, yyyy'}}</span>
          </p>
        </div>

        <div class="info-block">
          <h1 [style.fontFamily]="getMainTitles?.fontName"
              [style.color]="getMainTitles?.color"
              [style.fontSize.px]="getMainDetails?.fontSize"
          >{{'BUILD.TIME' | translate}}</h1>
          <p *ngFor="let schedular of eventModel?.eventSchedularList"
             [style.fontFamily]="getMainDetails?.fontName"
             [style.color]="getMainDetails?.color"
             [style.fontSize.px]="getMainDetails?.fontSize"
          >
            <span *ngIf="isNoEndDate(schedular)">{{schedular.startTime}}</span>
            <span *ngIf="!isNoEndDate(schedular)">{{schedular.startTime}}{{schedular.endTime ? ' - ' + schedular.endTime : ''}}</span>
          </p>
        </div>
      </div>

      <div class="event-info-column">
        <div class="info-block" *ngIf="eventModel?.hasLocation">
          <h1 [style.fontFamily]="getMainTitles?.fontName" [style.color]="getMainTitles?.color"
              [style.fontSize.px]="getMainDetails?.fontSize">{{'BUILD.LOCATION' | translate}}</h1>
          <p [style.fontFamily]="getMainDetails?.fontName" [style.color]="getMainDetails?.color"
             [style.fontSize.px]="getMainDetails?.fontSize" [innerHTML]="location | safeHTML"></p>
        </div>
      </div>
    </div>

    <div class="event-info-description">
      <h1 [style.fontFamily]="getMainTitles?.fontName" [style.color]="getMainTitles?.color"
          [style.fontSize.px]="getMainDetails?.fontSize">{{'BUILD.DESCRIPTION' | translate}}</h1>
      <p class="event-description" [style.fontFamily]="getMainDetails?.fontName" [style.color]="getMainDetails?.color"
         [style.fontSize.px]="getMainDetails?.fontSize">
        {{eventModel?.description}}
      </p>
    </div>


    <ng-container *ngIf="isManualRegistration">
      <div class="manual-register-block-title">{{getTitle | translate}}</div>
      <div class="registration-details-form">
        <div class="registration-details-form-row">
          <app-date-picker
            label="Registered Date *"
            [formGroup]="manualRegisterForm"
            name="registerDate"
            errorMassage="Date"
            placeholder="MM / DD / YYYY"
            [dateFilter]="editDateFilter"
          ></app-date-picker>
        </div>
      </div>
    </ng-container>

    <div *ngIf="!isRegistrationComplete && isEventAvailable() && !isEventSoldOut && !allTicketsInactive && !allTicketsOutdated">
      <ng-container *ngIf="!isEventPriceTicket() || (isEventPriceTicket() && areTicketsInSale())">
        <div class="contact-form" [hidden]="registrationStep === 2">
          <mat-tab-group mat-align-tabs="center" dynamicHeight [selectedIndex]="currentTab" (selectedIndexChange)="currentTab = $event">
            <mat-tab *ngFor="let tab of tabList">
              <ng-template mat-tab-label (click)="onGoToNextStep(tab.index)">
                <div class="tab-label">
                  <div class="tab-label-indicator">{{tab.labelIndicator}}</div>
                  <div class="tab-label-text">{{tab.labelName}}</div>
                </div>
              </ng-template>
              <ng-container *ngTemplateOutlet="tab.contentRef"></ng-container>
            </mat-tab>
          </mat-tab-group>
        </div>
        <ng-container>
          <div class="form-container pl-0 pr-0 mb-108">
            <div class="additional-payment-info">
              <table>
                <tr>
                  <th class="th-desc"></th>
                  <th class="th-cost">{{'BUILD.Cost' | translate}}</th>
                  <th class="th-quantity">{{'BUILD.Quantity' | translate}}</th>
                  <th class="th-sum" style="margin-right: 20px;">{{'BUILD.Cost' | translate}}</th>
                </tr>
                <ng-container *ngIf="isRegistrationCost()">
                  <tr>
                    <td class="th-desc">Registration Fee </td>
                    <td class="td">{{this.eventModel.registrationFee | currency: 'USD'}}</td>
                    <td class="td">{{aditionalParticipantsCount + 1}}</td>
                    <td class="td">{{getRegistrationFee() | currency: 'USD'}}</td>
                  </tr>
                </ng-container>
                <ng-container *ngFor="let ticket of totalTickets">
                  <ng-container *ngIf="ticket.quantity">
                    <tr>
                      <td class="th-desc">{{ticket.name}} </td>
                      <td class="td">{{ticket.price | currency: 'USD'}}</td>
                      <td class="td">{{ticket.quantity}}</td>
                      <td class="td">{{(ticket.quantity * ticket.price) | currency: 'USD'}}</td>
                    </tr>
                    <ng-container *ngIf="ticket.coupon">
                      <tr>
                        <td class="th-desc discount-row">
                          <div class="discount-row-inner">
                            <b>
                              {{'BUILD.Discount Code' | translate}}: {{ticket.coupon.couponCode}}
                              ({{ticket.coupon.couponValue}}{{couponUnits(ticket.coupon.couponType)}} {{'BUILD.OFF' | translate}})
                            </b>
                            <button
                              *ngIf="registrationStep === 1"
                              (click)="removeCouponByTicketId(ticket.id)"
                              class="discount-row-clear-button"
                              mat-icon-button>
                              <mat-icon class="discount-row-clear-icon">clear</mat-icon>
                            </button>
                          </div>
                        </td>
                        <td class="td"></td>
                        <td class="td"></td>
                        <td class="td"><b>-{{ticket.discount | currency: 'USD'}}</b></td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngFor="let addon of totalAddOns">
                  <tr *ngIf="addon.quantity && !addon.includes">
                    <td class="th-desc">{{getAddonType(addon.type)}} <b>({{addon.name}})</b></td>
                    <td class="td">{{addon.price | currency: 'USD'}}</td>
                    <td class="td">{{addon.quantity}}</td>
                    <td class="td">{{(+addon.quantity * +addon.price) | currency: 'USD'}}</td>
                  </tr>
                  <ng-container *ngFor="let subAddon of addon.includes">
                    <tr *ngIf="subAddon.quantity">
                      <td class="th-desc">{{getAddonType(addon.type)}} <b>{{addon.name}} ({{subAddon.name}})</b></td>
                      <td class="td">{{subAddon.price | currency: 'USD'}}</td>
                      <td class="td">{{subAddon.quantity}}</td>
                      <td class="td">{{(+subAddon.quantity * +subAddon.price) | currency: 'USD'}}</td>
                    </tr>
                  </ng-container>
                </ng-container>
                  <ng-container *ngFor="let coupon of eventModel?.usedFinalCostCoupons">
                    <tr>
                      <td class="th-desc discount-row">
                        <div class="discount-row-inner">
                          <b>
                            {{'BUILD.Discount Code' | translate}}: {{coupon.couponCode}}
                            ({{coupon.couponValue}}{{couponUnits(coupon.couponType)}} {{'BUILD.OFF' | translate}})
                          </b>
                          <button
                            *ngIf="registrationStep === 1"
                            (click)="removeFinalCostCouponByCode(coupon.couponCode)"
                            class="discount-row-clear-button"
                            mat-icon-button>
                            <mat-icon class="discount-row-clear-icon">clear</mat-icon>
                          </button>
                        </div>
                      </td>
                      <td class="th-cost"></td>
                      <td class="th-quantity"></td>
                      <td class="th-sum discount-row"><b>-{{getTotalCostDiscount(coupon) | currency: 'USD'}}</b></td>
                    </tr>
                  </ng-container>
              </table>
            </div>
            <div *ngIf="currentTab === lastTab">
              <ng-container *ngTemplateOutlet="couponTemplate"></ng-container>
            </div>
            <div *ngIf="!isManualRegistration" class="payment-info-form">
              <div class="payment-value">{{'BUILD.Total Registration Cost' | translate}}</div>
              <div class="payment-amount">{{getTotalCoast | currency: 'USD'}}</div>
            </div>
            <div *ngIf="isManualRegistration" class="manual-registration-form-description">
              <div class="max-length-140" *ngIf="!isGuest">
                <app-input
                  [formGroup]="manualRegisterForm"
                  name="numberInParty"
                  label="Number in Party"
                  [inputReadOnly]="true"
                ></app-input>
              </div>
              <div class="max-length-140" *ngIf="!isGuest">
                <app-input
                  [formGroup]="manualRegisterForm"
                  name="totalPayment"
                  label="Total Payment"
                  mask="separator.2"
                  thousandSeparator=","
                  prefix="$ "
                  [inputReadOnly]="true"
                ></app-input>
              </div>

              <div class="max-length-140" *ngIf="!isGuest">
                <app-select
                  [formGroup]="manualRegisterForm"
                  name="paymentMethod"
                  label="Payment Method"
                  [selectOptions]="methodOptions"
                ></app-select>
              </div>
              <div class="max-length-140" *ngIf="!isGuest">
                <app-select
                  [formGroup]="manualRegisterForm"
                  name="paymentStatus"
                  label="Payment Status"
                  [selectOptions]="statusOptions"
                ></app-select>
              </div>
            </div>
            <div *ngIf="registrationStep === 1" class="payment-info-form-action-btns">
              <div class="payment-info-form-action-btns-start">
                <app-button
                  [disabled]="!eventModel"
                  [hidden]="currentTab === 0"
                  (clickEvent)="handlePrevStep()"
                  type="stroked"
                  color="#0064BE"
                  label="PREVIOUS STEP"></app-button>
              </div>
              <div class="payment-info-form-action-btns-end">
                <a class="cancel-registration-link"
                    [routerLink]="handleCancelRegistration()">CANCEL REGISTRATION</a>
                <span class="payment-info-form-action-btns-content-pusher"></span>
                <app-button
                  [disabled]="!eventModel"
                  [hidden]="currentTab === lastTab"
                  (clickEvent)="handleNextStep()"
                  color="#0064BE"
                  label="NEXT STEP"></app-button>
                <app-button
                  [hidden]="hideRegisterButton()"
                  (clickEvent)="handleRegisterClick()"
                  [disabled]="isPreviewMode || registrationInProgress || !eventModel"
                  label="{{'BUILD.Register' | translate}}"
                  color="#20A643"></app-button>
                <app-button
                  [hidden]="hideManualRegSaveButton()"
                  type="flat"
                  [label]="isEditMode ? 'Update' : 'Save'"
                  color="#0064be"
                  width="140"
                  (clickEvent)="registerDonor()"></app-button>
              </div>
            </div>
            <div
              *ngIf="eventModel?.hasDonationAsk && isDonateFormVisible && registrationStep === 2"
              [class.hidden-payment-page]="!isDonateFormVisible">
              <app-payment-form
                [event]="eventModel"
                [states]="states"
                [filledInData]="registrationForm.value"
                [genericSourceTemplateId]="sourceTemplateId"
                [mainPayment]="totalCost"
                [showMainPayment]="true"
                (testMicrodepositLinkRecived)="microdepositLink = $event"
                [client]="client"
                [mainPaymentTitle]="paymentTitle"
                [countries]="countries"
                [countriesOptions]="countriesOptions"
                [eventRegistrationModel]="eventRegistrationModel"
                [fbTemplate]="fbTemplate"
                [isPreviewMode]="isPreviewMode"
                [includeFee$]="includeFee$"
                (paymentFinished)="onPaymentFinished($event)"
              ></app-payment-form>
            </div>
            <div *ngIf="!isDonateFormVisible && registrationStep === 2">
              <app-payment-form
                [event]="eventModel"
                [states]="states"
                [genericSourceTemplateId]="sourceTemplateId"
                [isPreviewMode]="isPreviewMode"
                [filledInData]="registrationForm.value"
                (testMicrodepositLinkRecived)="microdepositLink = $event"
                [mainPayment]="totalCost"
                [showMainPayment]="true"
                [mainPaymentTitle]="paymentTitle"
                (paymentFinished)="onPaymentFinished($event)"
                [showDonations]="isDonateFormVisible"
                [countries]="countries"
                [countriesOptions]="countriesOptions"
                [eventRegistrationModel]="eventRegistrationModel"
                [fbTemplate]="fbTemplate"
              ></app-payment-form>
            </div>
            <div>
              <div *ngIf="registrationStep === 2" class="button-wrapper">
                <app-button
                  label="Back To Registration Info"
                  type="flat"
                  width="320"
                  color="rgb(32, 166, 67)"
                  (clickEvent)="backRegistration()"
                  [disabled]="isPreviewMode"></app-button>
                <a class="cancel-registration-link"
                    [routerLink]="handleCancelRegistration()">CANCEL REGISTRATION</a>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isEventPriceTicket() && !areTicketsInSale()">
        <div class="info-container">
          <div class="info-container-text">
            <p>{{"EVENTS.Ticket Sales Will Start on" | translate}} {{getEarliestTicket().startDate | date: 'shortDate'}}</p>
          </div>
          <div class="info-container-counter">
            <app-countdown [date]="getEarliestTicketStartDate()" [eventStatus]="eventModel.status"></app-countdown>
          </div>
          <div class="info-container-btn">
            <a class="cancel-registration-link"
              [routerLink]="handleCancelRegistration()">CANCEL REGISTRATION</a>
          </div>
        </div>
      </ng-container>
    </div>
  <ng-container *ngIf="isEventAvailable()">
  <div *ngIf="(isEventSoldOut && !isRegistrationComplete) || allTicketsInactive || allTicketsOutdated" class="event-sold-out">
      <span>
        {{"BUILD.There are no tickets available or the max guest count has been reached" | translate}}
      </span>
  </div>
  </ng-container>


    <div *ngIf="!isEventAvailable()">
      <div style="min-height: 550px;">
        <div class="success">
          <img src="/assets/icons/check1.svg" style="margin-right: 15px;"> {{'BUILD.The event was canceled or complete.' | translate}}<br>
        </div>
      </div>
    </div>

    <div style="min-height: 150px;" *ngIf="isRegistrationComplete">
      <div class="success">
        <img src="/assets/icons/check1.svg" style="margin-right: 15px;"> {{'BUILD.Thank you for registering for' | translate}} {{eventModel?.name}}<br>
      </div>
      <div class="success-info">
        {{'BUILD.Would you like to add' | translate}} {{eventModel?.name}} {{'BUILD.to your calendar?' | translate}}
      </div>
      <div class="calendars-buttons">
        <button mat-button class="register-button" style="margin-right: 10px;" (click)="addGoogleCalendar()">Google
          {{'BUILD.calendar' | translate}}
        </button>
        <button mat-button class="register-button" style="margin-left: 10px;" (click)=" addOutlookCalendar()">Outlook
          {{'BUILD.calendar' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="" *ngIf="registrationStep === 3">
  <div class="ticket" id="ticket" #ticket>
    <div class="ticket-body" [style.backgroundColor]="getMainBackground?.color">
      <img [src]="clientLogoURL" style="float: right; max-width: 165px;max-height: 165px;" *ngIf="clientLogoName">
      <div class="ticket-event-name" style="font-weight: bold;" [style.fontSize.px]="getHeaderEvent?.fontSize"
           [style.color]="getHeaderEvent?.color">{{eventModel.name}}</div>
      <div class="ticket-event-name" style="font-weight: bold;" [style.fontSize.px]="getHeaderEvent?.fontSize"
           [style.color]="getHeaderEvent?.color">{{eventModel.startDate | date:'MMMM dd, yyyy'}}
        - {{eventModel.endDate | date:'MMMM dd, yyyy'}}, {{eventModel.startTime}} - {{eventModel.endTime}}</div>
      <div class="ticket-event-address" style="font-weight: bold;" [style.color]="getMainDetails?.color"
           [style.fontSize.px]="getMainDetails?.fontSize" style="margin-top: 15px; font-weight: bold;"
           *ngIf="states.length> 0 ">{{eventModel.address1}}, {{eventModel.city}}
        , {{states[eventModel.state].shortName}}</div>
      <div class="ticket-event-address" style="font-weight: bold;" [style.color]="getMainDetails?.color"
           [style.fontSize.px]="getMainDetails?.fontSize" style="margin-top: 15px;">{{eventModel.description}}</div>
      <div class="ticket-event-address" [style.color]="getMainDetails?.color"
           [style.fontSize.px]="getMainDetails?.fontSize" style="margin-top: 15px;">
        <span style="font-weight: bold;" [style.color]="getMainDetails?.color"
              [style.fontSize.px]="getMainDetails?.fontSize">
          {{'BUILD.Purchaser' | translate}}:
        </span> Nicole Madison
      </div>
      <div class="ticket-event-address" [style.color]="getMainDetails?.color"
           [style.fontSize.px]="getMainDetails?.fontSize" style="margin-top: 15px;">
        <span style="font-weight: bold;" [style.color]="getMainDetails?.color"
              [style.fontSize.px]="getMainDetails?.fontSize">
            {{'BUILD.Ticket Holder' | translate}}:
          </span> James Madison
      </div>
      <div class="ticket-type" [style.color]="getMainDetails?.color" [style.fontSize.px]="getMainDetails?.fontSize"
           style="margin-top: 15px;">
        <span [style.color]="getMainDetails?.color" [style.fontSize.px]="getMainDetails?.fontSize"
              style="font-weight: bold;">
          {{'BUILD.Ticket Type' | translate}}:
        </span> VIP
      </div>
      <div class="ticket-type" [style.color]="getMainDetails?.color" [style.fontSize.px]="getMainDetails?.fontSize"
           style="margin-top: 15px;">
        <span [style.color]="getMainDetails?.color" [style.fontSize.px]="getMainDetails?.fontSize"
              style="font-weight: bold;">
          {{'BUILD.Ticket Price' | translate}}:
        </span> $500
      </div>
      <div class="ticket-type" [style.color]="getMainDetails?.color" [style.fontSize.px]="getMainDetails?.fontSize"
           style="margin-top: 15px;">
        <span [style.color]="getMainDetails?.color" [style.fontSize.px]="getMainDetails?.fontSize"
              style="font-weight: bold;">
          {{'BUILD.Ticket ID' | translate}}:
        </span> 18292018-2
      </div>
    </div>
  </div>

</div>

<!-- Footer -->
<app-footer
  *ngIf="(showHeaderAndFooter$ | async) && template && registrationStep !== 3"
  [mjml]="template"
></app-footer>

<ng-template #couponTemplate>
  <ng-container *ngIf="(atLeastOneCoupon$ | async) && !isGuest && !isEditMode && registrationStep === 1">
    <div class="apply-coupon-row">
      <div class="apply-coupon-row-input-container">
        <app-input
          [formGroup]="couponGroup"
          name="couponCode"
          placeholder="PROMO CODE"></app-input>
      </div>
      <div class="apply-coupon-row-btn-container">
        <app-button
          (click)="handleApplyCoupon()"
          type="stroked"
          color="#0064BE"
          label="APPLY"></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

 <!-- Tickets tab content -->
 <ng-template #ticketsTemplate>
  <div class="tickets-form" *ngIf="eventModel?.priceTicketType === PriceTicket.Ticket && !isGuest" #ticketsRef>
    <app-ticket-types-block
      [currentServerTime]="eventModel.currentServerTime"
      [eventTimeZone]="eventTimeZone"
      [isEditModeOne]="isEditModeOne"
      [ticketsFormGroup]="ticketsGroup"
      [isPreviewMode]="isPreviewMode"
      [ticketChosenOptions]="ticketChosenOptions"
      [ticketPackageList]="eventModel?.ticketPackageList"
      [tickets]="tickets"
      [eventRegEditModel]="eventRegEditModel"
      [addOnsTimesUsed]="addOnsTimesUsed"
      (ticketsChanged)="ticketWasSelected()"
      (ticketAddonValidator)="ticketAddonValidator($event)"
    ></app-ticket-types-block>
  </div>
</ng-template>

<!-- Addons tab content -->
<ng-template #addOnsTemplate>
  <app-addons-block
  [addonList]="addons"
  [addonsFormGroup]="addonsGroup"
  [totalTickets]="totalTickets"
  [addOnsTimesUsed]="addOnsTimesUsed"
  [ticketPackageList]="eventModel.ticketPackageList"
  [isPreviewMode]="isPreviewMode"
  [isEditModeOne]="isEditModeOne"
  [ticketChange]="ticketChangeSubject.asObservable()"
></app-addons-block>
</ng-template>

<!-- Contact tab content -->
<ng-template #contactTemplate>
  <app-registration-contact-info
    [isManualRegistration]="isManualRegistration"
    [ticketChosenOptions]="ticketChosenOptions"
    [isPreviewMode]="isPreviewMode"
    [registrationForm]="registrationForm"
    [eventModel]="eventModel"
    [countryState]="countryState"
    [ticketsGroup]="ticketsGroup"
    [isEditModeOne]="isEditModeOne"
    [guestMaxReached]="guestMaxReached"
    [eventParticipant]="eventParticipant"
    [ticketTypeOptions]="ticketTypeOptions"
    [eventRegEditModel]="eventRegEditModel"
    [isGuest]="isGuest"
    [tickets]="tickets"
    [freeAddons]="freeAddons"
    [guestTicketOptions]="guestTicketOptions"
    [triggerShowErrorFields]="triggerShowErrorFields.asObservable()"
    (updateTotalCost)="totalCost = $event"
    (participantsCountChange) = "participantsCountChangeHandler($event)"
  ></app-registration-contact-info>
</ng-template>


<!-- Payment tab content -->
<ng-template #paymentTemplate>
  <div class="form-description">
    <h1 [style.color]="getMainDetails?.color">{{'BUILD.Disclosures & Conditions' | translate}}</h1>
    <p [style.color]="getMainDetails?.color">{{eventModel?.accessabilityText}}</p>
    <span
      *ngIf="eventModel?.hasDonationAsk && registrationStep === 1">
        {{getDonateButtonLabel()}}
      <a
        [style.fontFamily]="getDonateDetails?.fontName"
        [style.fontSize.px]="getDonateDetails?.fontSize"
        [style.color]="getDonateDetails?.color"
        (click)="onDonateClick()"
      >{{getDonateButtonText()}}</a>
    </span>
  </div>
</ng-template>
<a *ngIf="microdepositLink" [href]="microdepositLink" class="display-none-important" id="microdeposit-link"></a>
