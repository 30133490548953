import {ClientIdStateService} from '../../../../services/client.module.state/client.id.state.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {DashboardService} from 'src/app/services/dashboard/dashboard.service';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Paging} from "../../../../models/paging/paging.model";
import {FilterType} from "../../../../models/enum/filter.type";

@Component({
  selector: 'app-total-donors',
  templateUrl: './ytd-donor-retention.component.html',
  styleUrls: ['../simple-widget.component.scss']
})
export class YtdDonorRetentionComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public totalActiveDonors: number = null;
  public totalNewDonors: number = null;
  public totalPriorYearDonors: number = null;
  public retentionRate: number = null;

  constructor(
    private clientIdStateService: ClientIdStateService,
    private dashboardService: DashboardService,
    private router: Router
  ) {
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.clientIdStateService.clientIdObservable
        .pipe(
          distinctUntilChanged(),
          tap(() => {
            this.totalNewDonors = null;
            this.totalActiveDonors = null;
            this.totalPriorYearDonors = null;
            this.retentionRate = null;
          }),
          switchMap((id: string) => {
            const paging: Paging = {
              filters: [
              {
                field: 'clientID',
                value: id,
                type: FilterType.Equal
              },
                {
                  field: 'isYTD',
                  value: '1',
                  type: FilterType.Equal
                }
              ]
            }
          return  this.dashboardService.getYTDDonorsWidget(paging)
          }),
          tap(response => {
            this.totalNewDonors = response.values.find(x => x.fieldName === 'FirstTime').count;
            this.totalActiveDonors = response.values.find(x => x.fieldName === 'Total').count;
            this.totalPriorYearDonors = response.values.find(x => x.fieldName === 'PreviousYear').count;
            this.retentionRate = response.values.find(x => x.fieldName === 'RetentionRate').count;
          })
        )
        .subscribe()
    );
  }

  public goToReport(text?: string): void {
    if (text) {
     const queryParams = `?isYTD=true`;
     const url = `/clients/reports/financial/ytd-first-time-donors${queryParams}`
      this.router.navigateByUrl(url);
      return;
    }
    this.router.navigateByUrl(`/clients/reports/financial/ytd-retained-donors`);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
