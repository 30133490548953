<div class="fb-page-container" [ngStyle]="pageStyle" [formGroup]="titleForm">
  <!-- Title -->
  <div
    class="fb-page-form-row"
    id="page-name-row"
    [ngStyle]="rowStyle"
    #titleRef
  >
    <div
      class="page-name"
      [innerHTML]="infoPage?.text | translate | safeHTML"
    ></div>
    <button mat-icon-button (click)="editTitle($event)" *ngIf="libraryVersion">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <!-- Editor -->
  <quill-editor
    theme="snow"
    formControlName="titleControl"
    [modules]="modules"
    #editorRef
  >
    <div quill-editor-toolbar>
      <div class="ql-formats">
        <button class="ql-bold" [title]="'Bold'"></button>
        <button class="ql-italic" [title]="'Italic'"></button>
        <button class="ql-underline" [title]="'Underline'"></button>
        <button class="ql-strike" [title]="'Strike'"></button>
        <button class="ql-script" value="super"></button>
        <button class="ql-script" value="sub"></button>
      </div>

      <div class="ql-formats">
        <select class="ql-font">
          <option value="arial" selected>Arial</option>
          <option value="arial black">Arial Black</option>
          <option value="calibri">Calibri</option>
          <option value="century gothic">Century Gothic</option>
          <option value="comic sans ms">Comic Sans MS</option>
          <option value="courier">Courier</option>
          <option value="franklin gothic medium">Franklin Gothic Medium</option>
          <option value="geneva">Geneva</option>
          <option value="georgia">Georgia</option>
          <option value="helvetica">Helvetica</option>
          <option value="impact">Impact</option>
          <option value="lato">Lato</option>
          <option value="lucida">Lucida</option>
          <option value="lucida console">Lucida Console</option>
          <option value="lucida grande">Lucida Grande</option>
          <option value="lucida sans">Lucida Sans</option>
          <option value="lucida sans unicode">Lucida Sans Unicode</option>
          <option value="ms sans serif">MS Sans Serif</option>
          <option value="ms serif">MS Serif</option>
          <option value="monaco">Monaco</option>
          <option value="new york">New York</option>
          <option value="oswald">Oswald</option>
          <option value="palatino">Palatino</option>
          <option value="palatino linotype">Palatino Linotype</option>
          <option value="roboto">Roboto</option>
          <option value="tahoma">Tahoma</option>
          <option value="times">Times</option>
          <option value="times new roman">Times New Roman</option>
          <option value="trebuchet ms">Trebuchet MS</option>
          <option value="verdana">Verdana</option>
        </select>
      </div>

      <div class="ql-formats">
        <select class="ql-size">
          <option value="8px">8px</option>
          <option value="10px">10px</option>
          <option value="12px">12px</option>
          <option value="14px" [selected]>14px</option>
          <option value="16px">16px</option>
          <option value="18px">18px</option>
          <option value="20px">20px</option>
          <option value="22px">22px</option>
          <option value="24px">24px</option>
          <option value="28px">28px</option>
          <option value="30px">30px</option>
          <option value="36px">36px</option>
          <option value="48px">48px</option>
          <option value="64px">64px</option>
          <option value="72px">72px</option>
        </select>
      </div>

      <div class="ql-formats">
        <select class="ql-color"></select>
        <select class="ql-background"></select>
      </div>
    </div>
  </quill-editor>

  <!-- Tier price -->
  <div
    class="fb-page-form-row"
    *ngIf="!isDonationStandard"
    [ngStyle]="rowStyle"
  >
    <div class="amount-to-donate-container edit-container" #subtitleRef>
      <div class="ammount_container">
        <div
          [innerHTML]="infoPage?.amountToDonateText | translate | safeHTML"
        ></div>
        <button
          mat-icon-button
          (click)="editTitle($event)"
          *ngIf="libraryVersion"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>
    <div
      class="tiers-wrapper"
      *ngFor="let tier of getTierForm.controls; index as i"
    >
      <div class="main-wrapper">
        <div class="main-checkbox">
          <app-fb-checkbox
            [ngStyle]="fontSizeStyle"
            (clickEvent)="selectTier(tier)"
            [borderColor]="fbTemplate?.borderCheckboxColor"
            [color]="fbTemplate?.secondaryButtonColor"
            [value]="tier.get('isInclude').value"
          >
          </app-fb-checkbox>
        </div>
        <div class="img" [ngStyle]="{ background: getTierImg(i) }"></div>
        <div class="tier-info">
          <p class="tier-info-title">
            {{ getTier[i]?.name }}
          </p>
          <p class="tier-info-description">
            {{ getTier[i]?.description }}
          </p>
        </div>
        <div class="tier-price">
          <p>${{ tirePrice(i) }}</p>
        </div>
      </div>
      <div class="recurring-donation tier-recurring" *ngIf="getTier[i]?.isRecurring">
        <app-fb-radio
          *ngIf="infoPage?.isRecurring && tier.get('isInclude').value"
          [ngStyle]="fontSizeStyle"
          [borderColor]="fbTemplate?.borderCheckboxColor"
          [formGroup]="tier"
          name="tierDonationRecurringOption"
          [selectOptions]="tier.get('isPledgeType').value ? recurringOptions : recurringOptionsNoPledge"
          [color]="fbTemplate?.secondaryButtonColor"
          (valueChanged)="recurringTiersUpdate(tier, $event)"
        >
        </app-fb-radio>

        <!-- Frequency -->
        <div
          class="fb-page-form-row recurring-inputs-row optional-form"
          [ngStyle]="rowStyle"
          *ngIf="tier.get('recurring').value && infoPage?.isRecurring && tier.get('isInclude').value"
        >
          <app-fb-select
            [formGroup]="tier"
            errorMassage="Frequency"
            name="donationFrequency"
            label="Frequency"
            [isMatTitle]="true"
            [isSearchOption]="false"
            [selectOptions]="templateTierFrequencyOptions"
            (valueChanged)="onTierFrequencyChanged($event, tier)"
            [inputBackground]="fbTemplate?.inputBackground"
            [color]="fbTemplate?.fontColor"
            [fontFamily]="fbTemplate?.fontFamily"
          ></app-fb-select>
        </div>

        <!-- Pledge Amount -->
        <div
          class="fb-page-form-row optional-form"
          *ngIf="tier.get('recurring').value &&
      getTier[i]?.isRecurring &&
      tier.get('reccurrencyPaymentType').value === PaymentBillingType.Pledge &&
      tier.get('isInclude').value
    "
          [ngStyle]="rowStyle"
        >
          <!-- Number of periods -->
          <div
            class="right-margin"
            *ngIf="tier.get('recurring').value && getTier[i]?.isRecurring"
          >
            <div #numberOfPeriodsRef>
              <!-- Number of periods -->
              <app-fb-input
                [formGroup]="tier"
                #customNumberRef
                name="customNumber"
                errorMassage="Value"
                [label]="
              tier.get('reccurrencyPaymentType').value === PaymentBillingType.Pledge
              ? getPledgePeriodsLabel
              : 'Number of periods'
          "
                mask="separator."
                thousandSeparator=","
                [autocompleteOff]="true"
                [inputBackground]="fbTemplate?.inputBackground"
                [color]="fbTemplate?.fontColor"
                [fontFamily]="fbTemplate?.fontFamily"
                [fontSize]="fbTemplate?.fontSize"
                (inputEvent)="setPeriod(i, false)"
              ></app-fb-input>
            </div>
          </div>

          <div class="max-width-200">
            <!-- Amount per transaction -->
            <app-fb-input
              [formGroup]="tier"
              errorMassage="Amount per transaction"
              [label]="
          tier.get('reccurrencyPaymentType').value !== PaymentBillingType.Pledge
            ? 'Amount per transaction'
            : 'Amount per transaction *'
        "
              name="amountPerTransaction"
              mask="separator.2"
              thousandSeparator=","
              prefix="$ "
              [autocompleteOff]="true"
              [inputBackground]="fbTemplate?.inputBackground"
              [color]="fbTemplate?.fontColor"
              [fontFamily]="fbTemplate?.fontFamily"
              [fontSize]="fbTemplate?.fontSize"
            ></app-fb-input>
          </div>
        </div>

        <!-- Terms and Conditions tier-->
        <div
          class="fb-page-form-row"
          [ngStyle]="rowStyle"
          *ngIf="tier.get('recurring').value && getTier[i]?.isRecurring && tier.get('isInclude').value"
        >
          <app-fb-checkbox
            [ngStyle]="fontSizeStyle"
            [borderColor]="fbTemplate?.borderCheckboxColor"
            (clickEvent)="
              tier
                .get('agreedTermsConditions')
                .setValue(!tier.get('agreedTermsConditions').value)
            "
            [color]="fbTemplate?.secondaryButtonColor"
            [value]="tier.get('agreedTermsConditions').value"
          >
            <span
            >{{ 'BUILD.I have read and agreed to the' | translate }}
              <a
                href="https://www.theauxilia.com/privacy-policy"
                target="_blank"
              >{{ 'BUILD.terms and conditions' | translate }}</a
              ></span
            >
          </app-fb-checkbox>
        </div>
      </div>
    </div>
    <hr class="hr"/>
  </div>

  <!-- Custom Donation Tiers -->
  <div *ngIf="!isDonationStandard && isOwnAmountTiers" class="fb-page-form-row">
    <div class="d-flex">
      <div>
        <label class="pb-0 mb-0">{{ 'Amount to donate *' | translate }}</label>
        <app-fb-input
          [formGroup]="customDonationTiersForm"
          name="ammount"
          mask="separator.2"
          errorMassage="amount"
          thousandSeparator=","
          [prefix]="'$'"
          placeholder="Enter custom amount"
          type="tel"
          [inputBackground]="fbTemplate?.inputBackground"
          [color]="fbTemplate?.fontColor"
          [fontFamily]="fbTemplate?.fontFamily"
          (inputEvent)="setAmount()"
        >
        </app-fb-input>
      </div>
    </div>
  </div>
  <div *ngIf="!isDonationStandard && isOwnAmountTiers && getTier[0]?.isRecurring" [ngStyle]="rowStyle" class="fb-page-form-row tiers-recurring">
    <!-- Billing type -->
    <app-fb-radio
      *ngIf="infoPage?.isRecurring"
      [ngStyle]="fontSizeStyle"
      [borderColor]="fbTemplate?.borderCheckboxColor"
      [formGroup]="customDonationTiersForm"
      name="tierDonationRecurringOption"
      [selectOptions]="customDonationTiersForm.get('isPledgeType').value ? recurringOptions : recurringOptionsNoPledge"
      [color]="fbTemplate?.secondaryButtonColor"
      (valueChanged)="recurringTiersUpdate(customDonationTiersForm, $event)"
    >
    </app-fb-radio>
  </div>
  <!-- Frequency -->
  <div
    class="fb-page-form-row recurring-inputs-row optional-form"
    [ngStyle]="rowStyle"
    *ngIf="!isDonationStandard && isOwnAmountTiers && customDonationTiersForm.get('recurring').value && getTier[0]?.isRecurring"
  >
    <app-fb-select
      [formGroup]="customDonationTiersForm"
      errorMassage="Frequency"
      name="donationFrequency"
      label="Frequency"
      [isMatTitle]="true"
      [isSearchOption]="false"
      [selectOptions]="templateTierFrequencyOptions"
      (valueChanged)="onTierFrequencyChanged($event, customDonationTiersForm)"
      [inputBackground]="fbTemplate?.inputBackground"
      [color]="fbTemplate?.fontColor"
      [fontFamily]="fbTemplate?.fontFamily"
    ></app-fb-select>
  </div>

  <!-- Pledge Amount -->
  <div
    class="fb-page-form-row optional-form"
    *ngIf="
    !isDonationStandard && isOwnAmountTiers &&
      customDonationTiersForm.get('recurring').value &&
      getTier[0]?.isRecurring &&
      customDonationTiersForm.get('reccurrencyPaymentType').value === PaymentBillingType.Pledge
    "
    [ngStyle]="rowStyle"
  >
    <!-- Number of periods -->
    <div
      class="right-margin"
      *ngIf="customDonationTiersForm.get('recurring').value && infoPage?.isRecurring"
    >
      <div #numberOfPeriodsRef>
        <!-- Number of periods -->
        <app-fb-input
          [formGroup]="customDonationTiersForm"
          #customNumberRef
          name="customNumber"
          errorMassage="Value"
          [label]="
              customDonationTiersForm.get('reccurrencyPaymentType').value === PaymentBillingType.Pledge
              ? getPledgePeriodsLabel
              : 'Number of periods'
          "
          mask="separator."
          thousandSeparator=","
          [autocompleteOff]="true"
          [inputBackground]="fbTemplate?.inputBackground"
          [color]="fbTemplate?.fontColor"
          [fontFamily]="fbTemplate?.fontFamily"
          [fontSize]="fbTemplate?.fontSize"
          (inputEvent)="setPeriod(0, true)"
        ></app-fb-input>
      </div>
    </div>

    <div class="max-width-200">
      <!-- Amount per transaction -->
      <app-fb-input
        [formGroup]="customDonationTiersForm"
        errorMassage="Amount per transaction"
        [label]="
          customDonationTiersForm.get('reccurrencyPaymentType').value !== PaymentBillingType.Pledge
            ? 'Amount per transaction'
            : 'Amount per transaction *'
        "
        name="amountPerTransaction"
        mask="separator.2"
        thousandSeparator=","
        prefix="$ "
        [autocompleteOff]="true"
        [inputBackground]="fbTemplate?.inputBackground"
        [color]="fbTemplate?.fontColor"
        [fontFamily]="fbTemplate?.fontFamily"
        [fontSize]="fbTemplate?.fontSize"
      ></app-fb-input>
    </div>
  </div>

  <!-- Terms and Conditions tier-->
  <div
    class="fb-page-form-row"
    [ngStyle]="rowStyle"
    *ngIf="!isDonationStandard && isOwnAmountTiers && customDonationTiersForm.get('recurring').value && getTier[0]?.isRecurring"
  >
    <app-fb-checkbox
      [ngStyle]="fontSizeStyle"
      [borderColor]="fbTemplate?.borderCheckboxColor"
      (clickEvent)="
            customDonationTiersForm
              .get('agreedTermsConditions')
              .setValue(
                !customDonationTiersForm.get('agreedTermsConditions').value
              )
          "
      [color]="fbTemplate?.secondaryButtonColor"
      [value]="customDonationTiersForm.get('agreedTermsConditions').value"
    >
          <span
          >{{ 'BUILD.I have read and agreed to the' | translate }}
            <a
              href="https://www.theauxilia.com/privacy-policy"
              target="_blank"
            >{{ 'BUILD.terms and conditions' | translate }}</a
            ></span
          >
    </app-fb-checkbox>
  </div>

  <!-- Standard Donation -->
  <!-- Amount to donate -->
  <div class="fb-page-form-row" *ngIf="isDonationStandard" [ngStyle]="rowStyle">
    <div
      *ngIf="(distributeAmongFunds$ | async) !== null"
      class="amount-to-donate-container"
      #amountRef
    >
      <div class="edit-container" #subtitleRef>
        <div
          [innerHTML]="infoPage?.amountToDonateText | translate | safeHTML"
        ></div>
        <button
          mat-icon-button
          (click)="editTitle($event)"
          *ngIf="libraryVersion"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <div
        class="amount-row"
        *ngIf="!(distributeAmongFunds$ | async); else fundDistributionTemplate"
      >
        <!-- Amount buttons -->
        <div
          class="buttons-row donation-bubbles-width"
          [ngClass]="{
            'donation-bubbles-width': infoPage?.donationAmounts.length == 6
          }"
        >
          <div
            *ngFor="let amountValue of infoPage?.donationAmounts; index as i"
            class="amount-button"
            matRipple
            (click)="amount.setValue(amountValue)"
            [style.background]="getDonationBubbleBackground(amountValue)"
            [style.color]="
              amount.value == amountValue
                ? fbTemplate?.secondaryTextButtonColor
                : '#ffffff'
            "
          >
            <div>$ {{ convertAmount(amountValue) }}</div>
          </div>
        </div>

        <div class="input-wrapper">
          <div class="input-container pledge-container">
            <!-- Amount Input -->
            <div class="custom-amount-input" *ngIf="infoPage?.allowOwnAmount">
              <app-fb-input
                [formGroup]="paymentForm"
                errorMassage="Amount"
                name="ammount"
                mask="separator.2"
                thousandSeparator=","
                [prefix]="amount.value ? '$ ' : ''"
                placeholder="Enter custom amount"
                type="tel"
                [inputBackground]="fbTemplate?.inputBackground"
                [color]="fbTemplate?.fontColor"
                [fontFamily]="fbTemplate?.fontFamily"
              >
              </app-fb-input>
            </div>

            <!-- Allocated fund select -->
            <div class="custom-amount-input" *ngIf="allocatedFundsOptions.length > 1">
              <app-fb-select
                [formGroup]="mokFundsForm"
                name="fundId"
                errorMassage="Fund"
                [label]="'Donate to *'"
                [isMatTitle]="true"
                [selectOptions]="allocatedFundsOptions"
                [inputBackground]="fbTemplate?.inputBackground"
                [color]="fbTemplate?.fontColor"
                [fontFamily]="fbTemplate?.fontFamily"
              ></app-fb-select>
            </div>

            <b *ngIf="allocatedFundsOptions.length === 1"
            >{{ 'BUILD.Donate to' | translate }}
              {{ allocatedFundsOptions[0].label }}</b
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="fb-page-form-row" [ngStyle]="rowStyle" *ngIf="isDonationStandard">
    <!-- Billing type -->
    <app-fb-radio
      *ngIf="infoPage?.isRecurring"
      [ngStyle]="fontSizeStyle"
      [borderColor]="fbTemplate?.borderCheckboxColor"
      [formGroup]="paymentForm"
      name="donationRecurringOption"
      [selectOptions]="infoPage?.isPledgeType ? recurringOptions : recurringOptionsNoPledge"
      [color]="fbTemplate?.secondaryButtonColor"
      (valueChanged)="recurringUpdate($event)"
    >
    </app-fb-radio>
  </div>

  <div
    class="fb-page-form-row recurring-inputs-row optional-form"
    [ngStyle]="rowStyle"
    *ngIf="recurring.value && infoPage?.isRecurring && isDonationStandard"
  >
    <app-fb-select
      [formGroup]="paymentForm"
      errorMassage="Frequency"
      name="donationFrequency"
      label="Frequency"
      [isMatTitle]="true"
      [isSearchOption]="false"
      [selectOptions]="templateFrequencyOptions"
      (valueChanged)="onFrequencyChanged($event)"
      [inputBackground]="fbTemplate?.inputBackground"
      [color]="fbTemplate?.fontColor"
      [fontFamily]="fbTemplate?.fontFamily"
    ></app-fb-select>
  </div>

  <!-- Pledge Amount -->
  <div
    class="fb-page-form-row optional-form"
    *ngIf="
      recurring.value &&
      infoPage?.isRecurring &&
      reccurrencyPaymentType.value === PaymentBillingType.Pledge &&
      isDonationStandard
    "
    [ngStyle]="rowStyle"
  >
    <!-- Number of periods -->
    <div
      class="right-margin"
      *ngIf="recurring.value && infoPage?.isRecurring && isDonationStandard"
    >
      <div #numberOfPeriodsRef>
        <!-- Number of periods -->
        <app-fb-input
          [formGroup]="paymentForm"
          errorMassage="Value"
          [label]="
            reccurrencyPaymentType.value === PaymentBillingType.Pledge
              ? getPledgePeriodsLabel
              : 'Number of periods'
          "
          name="numberOfPeriods"
          mask="separator."
          thousandSeparator=","
          [autocompleteOff]="true"
          [inputBackground]="fbTemplate?.inputBackground"
          [color]="fbTemplate?.fontColor"
          [fontFamily]="fbTemplate?.fontFamily"
          [fontSize]="fbTemplate?.fontSize"
        ></app-fb-input>
      </div>
    </div>

    <div class="max-width-200">
      <!-- Amount per transaction -->
      <app-fb-input
        [formGroup]="paymentForm"
        errorMassage="Amount per transaction"
        [label]="
          reccurrencyPaymentType.value !== PaymentBillingType.Pledge
            ? 'Amount per transaction'
            : 'Amount per transaction *'
        "
        name="amountPerTransaction"
        mask="separator.2"
        thousandSeparator=","
        prefix="$ "
        [autocompleteOff]="true"
        [inputBackground]="fbTemplate?.inputBackground"
        [color]="fbTemplate?.fontColor"
        [fontFamily]="fbTemplate?.fontFamily"
        [fontSize]="fbTemplate?.fontSize"
      ></app-fb-input>
    </div>
  </div>

  <!-- Terms and Conditions -->
  <div
    class="fb-page-form-row"
    [ngStyle]="rowStyle"
    *ngIf="recurring.value && infoPage?.isRecurring && isDonationStandard"
  >
    <app-fb-checkbox
      [ngStyle]="fontSizeStyle"
      [borderColor]="fbTemplate?.borderCheckboxColor"
      (clickEvent)="
        agreedTermsConditions.setValue(!agreedTermsConditions.value)
      "
      [color]="fbTemplate?.secondaryButtonColor"
      [value]="agreedTermsConditions.value"
    >
      <span
      >{{ 'BUILD.I have read and agreed to the' | translate }}
        <a href="https://www.theauxilia.com/privacy-policy" target="_blank">{{
            'BUILD.terms and conditions' | translate
          }}</a></span
      >
    </app-fb-checkbox>
  </div>

  <!-- Make donation anonymous checkbox -->
  <div
    class="fb-page-form-row"
    [ngStyle]="rowStyle"
    *ngIf="client && client.clientType === 0 && infoPage?.isAnonymous"
  >
    <app-fb-checkbox
      [ngStyle]="fontSizeStyle"
      (clickEvent)="isAnonymous.setValue(!isAnonymous.value)"
      [borderColor]="fbTemplate?.borderCheckboxColor"
      [color]="fbTemplate?.secondaryButtonColor"
      [value]="isAnonymous.value"
      tooltip="By selecting anonymous donation, your information will not be shared in relation to this donation unless required by law for tax purposes."
    >
      {{ 'BUILD.Make donation anonymous' | translate }}
    </app-fb-checkbox>
  </div>

  <!-- Make donation in honor or in memory section -->
  <div class="fb-page-form-row" [ngStyle]="rowStyle" *ngIf="isHonorGiftVisible">
    <!-- Make an Honor or Memorial Gift donation checkbox -->
    <app-fb-checkbox
      [ngStyle]="fontSizeStyle"
      [noWrap]="true"
      (clickEvent)="updateAppeal()"
      [borderColor]="fbTemplate?.borderCheckboxColor"
      [color]="fbTemplate?.secondaryButtonColor"
      [value]="appeal.value"
    >
      {{ getTitle | translate }}
    </app-fb-checkbox>

    <!-- Honor or Memorial Gift Template -->
    <div
      class="fb-page-form-row optional-form first-row"
      [ngStyle]="rowStyle"
      *ngIf="isHonorGiftVisible2"
    >
      <div class="max-width-370 right-margin">
        <app-fb-select
          [formGroup]="paymentForm"
          errorMassage="Gift Type"
          label="Gift type *"
          name="giftType"
          [selectOptions]="getGiftTypeOptions"
          [inputBackground]="fbTemplate?.inputBackground"
          [color]="fbTemplate?.fontColor"
          [fontFamily]="fbTemplate?.fontFamily"
          [fontSize]="fbTemplate?.fontSize"
          (valueChanged)="onGiftTypeChanged()"
        >
        </app-fb-select>
      </div>
      <div class="max-width-370 right-margin" #namedPersonRef>
        <app-fb-input
          [formGroup]="paymentForm"
          errorMassage="Recipient Name"
          [label]="getGiftLabel"
          name="namedPerson"
          [autocompleteOff]="true"
          [inputBackground]="fbTemplate?.inputBackground"
          [color]="fbTemplate?.fontColor"
          [fontFamily]="fbTemplate?.fontFamily"
          [fontSize]="fbTemplate?.fontSize"
          (valueChanged)="onNamedPersonChanged()"
        >
        </app-fb-input>
      </div>

      <div class="notify-email">
        <app-fb-checkbox
          [ngStyle]="fontSizeStyle"
          [borderColor]="fbTemplate?.borderCheckboxColor"
          *ngIf="infoPage?.isHonorGiftWithSendEmail"
          (clickEvent)="updateIsSendValue()"
          [color]="fbTemplate?.secondaryButtonColor"
          [value]="isEmailSend.value"
          [tooltip]="tooltipMessage"
          [topLabel]="notify"
        >
          {{ 'Email' | translate }}
        </app-fb-checkbox>
      </div>
    </div>
  </div>

  <!-- Email Honoree Inputs -->
  <div
    class="fb-page-form-row optional-form"
    [ngStyle]="rowStyle"
    *ngIf="isHonorGiftVisible3"
  >
    <div
      *ngIf="giftType.value !== GiftType.InHonorOf"
      class="max-width-370 right-margin"
      #recipientNameRef
    >
      <app-fb-input
        [formGroup]="paymentForm"
        errorMassage="Recipient Name"
        label="Recipient Name *"
        name="recipientName"
        [autocompleteOff]="true"
        [inputBackground]="fbTemplate?.inputBackground"
        [color]="fbTemplate?.fontColor"
        [fontFamily]="fbTemplate?.fontFamily"
        [fontSize]="fbTemplate?.fontSize"
        (valueChanged)="resetEmail()"
      ></app-fb-input>
    </div>

    <div class="max-width-370" #recipientEmailRef>
      <app-fb-input
        [formGroup]="paymentForm"
        [errorMassage]="getErrorMessage"
        [label]="getEmailLabel"
        name="recipientEmail"
        [autocompleteOff]="true"
        [inputBackground]="fbTemplate?.inputBackground"
        [color]="fbTemplate?.fontColor"
        [fontFamily]="fbTemplate?.fontFamily"
        [fontSize]="fbTemplate?.fontSize"
      ></app-fb-input>
    </div>
  </div>

  <div
    class="fb-page-form-row optional-form"
    [ngStyle]="rowStyle"
    *ngIf="isHonorGiftVisible3"
  >
    <div class="max-width-810" #emailSubjectRef>
      <app-fb-input
        [formGroup]="paymentForm"
        errorMassage="Email Subject"
        label="Email subject *"
        name="emailSubject"
        [autocompleteOff]="true"
        [inputBackground]="fbTemplate?.inputBackground"
        [color]="fbTemplate?.fontColor"
        [fontFamily]="fbTemplate?.fontFamily"
        [fontSize]="fbTemplate?.fontSize"
        (valueChanged)="onNamedPersonChanged()"
      >
      </app-fb-input>
    </div>
  </div>

  <div
    class="fb-page-form-row optional-form"
    [ngStyle]="rowStyle"
    *ngIf="isHonorGiftVisible3"
  >
    <div class="max-width-810" #emailTextRef>
      <app-fb-quill-editor
        [formGroup]="paymentForm"
        label="Email text *"
        name="emailText"
        placeholder="Email text..."
        errorMassage="Email text"
        [inputBackground]="fbTemplate?.inputBackground"
        [color]="fbTemplate?.fontColor"
        [fontFamily]="fbTemplate?.fontFamily"
        [fontSize]="fbTemplate?.fontSize"
      >
      </app-fb-quill-editor>
    </div>
  </div>

  <div
    class="fb-page-form-row optional-form"
    [ngStyle]="rowStyle"
    *ngIf="isHonorGiftVisible3"
  >
    <app-button
      label="RESET EMAIL BODY"
      (clickEvent)="resetEmail()"
      type="flat"
      [color]="fbTemplate?.secondaryButtonColor"
      class="mt-3"
    >
    </app-button>
  </div>

  <div
    class="fb-page-form-row optional-form last-row"
    [ngStyle]="rowStyle"
    *ngIf="isHonorGiftVisible3"
  >
    <div class="max-width-370" #sendEmailRef>
      <app-fb-date-picker
        [formGroup]="paymentForm"
        errorMassage="Send email"
        label="Send email *"
        name="sendEmail"
        [dateFilter]="sendEmailFilter"
        [inputBackground]="fbTemplate?.inputBackground"
        [color]="fbTemplate?.fontColor"
        [fontFamily]="fbTemplate?.fontFamily"
        [fontSize]="fbTemplate?.fontSize"
        [autocompleteOff]="true"
      >
      </app-fb-date-picker>
    </div>
  </div>
  <!-- End email Honoree Inputs -->

  <ng-container *ngIf="infoPage?.isDonationCost">
    <div
      class="payment-total-row"
      [ngStyle]="rowStyle"
      *ngIf="
      !(distributeAmongFunds$ | async);
        else fundDistributionTotalTemplate
      "
    >
      <div class="total-column">
        <span
          class="total-column-title"
          [ngStyle]="{ color: fbTemplate?.fontColor }"
        >{{ 'BUILD.Total' | translate }}</span
        >
        <ng-container *ngIf="!isDonationStandard">
          <div
            class="margin-bottom-10"
            *ngFor="let tier of getTierForm.controls; index as i"
          >
            <span
              [ngStyle]="{ color: fbTemplate?.fontColor }"
              *ngIf="
                getTier[i]?.name &&
                tier.get('isInclude').value &&
                getTier[i]?.ammount !== ''
              "
            >{{ getTier[i]?.name }}</span
            >
          </div>
        </ng-container>
        <span [ngStyle]="{ color: fbTemplate?.fontColor }">{{
            'BUILD.Donation' | translate
          }}</span>
      </div>
      <div class="total-column">
        <span
          class="total-column-title"
          [ngStyle]="{ color: fbTemplate?.fontColor }"
        >{{ 'BUILD.Cost' | translate }}</span
        >
        <ng-container *ngIf="!isDonationStandard">
          <div
            class="margin-bottom-10"
            *ngFor="let tier of getTierForm.controls; index as i"
          >
            <span
              [ngStyle]="{ color: fbTemplate?.fontColor }"
              *ngIf="tier.get('isInclude').value && getTier[i]?.ammount"
            >$ {{ tirePrice(i) }}</span
            >
          </div>
        </ng-container>
        <ng-container *ngIf="!isDonationStandard">
          <span
            *ngIf="amount.value; else empty1"
            class="total-value"
            [ngStyle]="{ color: fbTemplate?.fontColor }"
          >$ {{ amount.value | number : '.2' }}</span
          >
          <ng-template #empty1>
            <span [ngStyle]="{ color: fbTemplate?.fontColor }">$ 0.00</span>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="isDonationStandard">
          <span
            *ngIf="isDonationStandard && amount.value; else empty2"
            class="total-value"
            [ngStyle]="{ color: fbTemplate?.fontColor }"
          >$ {{ amount.value | number : '.2' }}</span
          >
          <ng-template #empty2>
            <span [ngStyle]="{ color: fbTemplate?.fontColor }">$ 0.00</span>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <div
    *ngIf="infoPage.customDisclaimers?.length > 0"
    class="custom-disclaimers-row"
  >
    <hr/>
    <ng-container *ngFor="let disclaimer of disclaimers.controls">
      <div class="mb-4">
        <div class="mb-3 font-italic">{{ getDisclaimerText(disclaimer) }}</div>
      </div>
    </ng-container>
    <app-fb-checkbox
      [value]="disclaimersConfirmed"
      (clickEvent)="handleDisclaimersConfirmedEvent()"
    >
      <span>{{ checkBoxDisclaimerText }}</span>
    </app-fb-checkbox>
  </div>

  <div class="donate-button">
    <app-button
      type="flat"
      [label]="fbTemplate?.nextButtonLabel"
      [color]="fbTemplate?.primaryButtonColor"
      [labelColor]="fbTemplate?.primaryTextButtonColor"
      [width]="200"
      (clickEvent)="donate()"
      [disabled]="
        (isDonationStandard &&
          recurring.value &&
          !agreedTermsConditions.value) ||
        (isDonationStandard && !disclaimersConfirmed) ||
        (!isDonationStandard && isAgreeWithConditionsTiers)
      "
    ></app-button>
  </div>
</div>

<!-- Template for Allocated Funds -->
<ng-template #fundDistributionTemplate>
  <ng-container *ngIf="isDonationStandard">
    <div #amountRef></div>
    <div
      class="amount-row"
      *ngFor="let fundGroup of fundAllocation.controls; index as i"
    >
      <!-- Amount buttons -->
      <div
        class="buttons-row"
        [ngClass]="{
          'donation-bubbles-width': infoPage?.donationAmounts.length == 6
        }"
      >
        <div
          *ngFor="let amountValue of infoPage?.donationAmounts"
          class="amount-button"
          matRipple
          (click)="fundGroup.get('amount').setValue(amountValue)"
          [style.background]="getDonationBubbleBackground(amountValue, i)"
          [style.color]="
            fundGroup.get('amount').value == amountValue
              ? fbTemplate?.secondaryTextButtonColor
              : '#ffffff'
          "
        >
          $ {{ convertAmount(amountValue) }}
        </div>
      </div>
      <div
        class="input-wrapper"
        *ngIf="
          isDefault ||
          isCustom ||
          (isCustom && isPayPage) ||
          (isDefault && isPayPage) ||
          isPayPage
        "
      >
        <div class="input-container">
          <!-- Custom Amount Input -->
          <div class="custom-amount-input" *ngIf="infoPage?.allowOwnAmount">
            <app-fb-input
              [formGroup]="fundGroup"
              errorMassage="Amount"
              name="amount"
              mask="separator.2"
              thousandSeparator=","
              [prefix]="fundGroup.get('amount').value ? '$ ' : ''"
              placeholder="Enter custom amount"
              type="tel"
              [inputBackground]="fbTemplate?.inputBackground"
              [color]="fbTemplate?.fontColor"
              [fontFamily]="fbTemplate?.fontFamily"
            ></app-fb-input>
          </div>

          <!-- Allocated fund select -->
          <div class="custom-amount-input" *ngIf="allocatedFundsOptions.length > 1">
            <app-fb-select
              [formGroup]="fundGroup"
              name="fundId"
              errorMassage="Fund"
              [label]="'Donate to *'"
              [isMatTitle]="true"
              [selectOptions]="
                getUniqueFundOptions(fundGroup, this.allocatedFundsOptions)
              "
              (valueChanged)="onFundSelected(fundGroup)"
              [inputBackground]="fbTemplate?.inputBackground"
              [color]="fbTemplate?.fontColor"
              [fontFamily]="fbTemplate?.fontFamily"
            >
            </app-fb-select>
          </div>

          <!-- Only one fund label -->
          <b *ngIf="allocatedFundsOptions.length === 1"
          >{{ 'BUILD.Donate to' | translate }}
            {{
              getUniqueFundOptions(fundGroup, this.allocatedFundsOptions)[0]
                .label
            }}</b
          >
        </div>
      </div>

      <div class="action-buttons">
        <!-- Remove Allocated Fund Button -->
        <i
          *ngIf="fundAllocation.length > 1"
          class="fas fa-minus-circle"
          (click)="removeAllocatedFund(i)"
          [style.color]="fbTemplate?.secondaryButtonColor"
        ></i>
        <i
          *ngIf="
            fundAllocation.valid &&
            i === fundAllocation.length - 1 &&
            fundAllocation.length < allocatedFundsOptions.length
          "
          class="fas fa-plus-circle"
          (click)="addAllocatedFund()"
          [style.color]="fbTemplate?.secondaryButtonColor"
        ></i>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #fundDistributionTotalTemplate>
  <div class="payment-total-row" *ngIf="isDonationStandard; else tiers">
    <div class="total-column">
      <span
        class="total-column-title"
        [ngStyle]="{ color: fbTemplate?.fontColor }"
      >Total</span
      >
      <ng-container *ngIf="isDonationStandard">
        <span
          *ngFor="let fundControl of fundAllocation.controls"
          [ngStyle]="{ color: fbTemplate?.fontColor }"
        >{{ fundControl.get('name').value }}</span
        >
      </ng-container>
      <br/>
      <span [ngStyle]="{ color: fbTemplate?.fontColor }">{{
          'BUILD.Total Donation Amount' | translate
        }}</span>
    </div>

    <div class="total-column">
      <span
        class="total-column-title"
        [ngStyle]="{ color: fbTemplate?.fontColor }"
      >Cost</span
      >
      <ng-container *ngFor="let fundControl of fundAllocation.controls">
        <span
          *ngIf="fundControl.get('amount').value; else emptyValueTemplate"
          [ngStyle]="{ color: fbTemplate?.fontColor }"
        >$ {{ fundControl.get('amount').value | number : '.2' }}</span
        >
        <ng-template #emptyValueTemplate>
          <span [ngStyle]="{ color: fbTemplate?.fontColor }">$ 0.00</span>
        </ng-template>
      </ng-container>
      <br/>
      <span
        *ngIf="amount.value"
        class="total-value"
        [ngStyle]="{ color: fbTemplate?.fontColor }"
      >${{ amount.value | number : '.2' }}</span
      >
    </div>
  </div>
  <ng-template #tiers>
    <div class="payment-total-row">
      <div class="total-column">
        <span
          class="total-column-title"
          [ngStyle]="{ color: fbTemplate?.fontColor }"
        >{{ 'BUILD.Total' | translate }}</span
        >
        <ng-container *ngFor="let tier of getTierForm.controls; index as i">
          <div
            class="margin-bottom-10"
            *ngIf="tier.get('isInclude').value && tier.get('tierName')"
          >
            <span [ngStyle]="{ color: fbTemplate?.fontColor }">
              {{ tier.get('tierName').value }}
            </span>
          </div>
        </ng-container>
        <br/>
        <span [ngStyle]="{ color: fbTemplate?.fontColor }">{{
            'BUILD.Total Donation Amount' | translate
          }}</span>
      </div>

      <div class="total-column">
        <span
          class="total-column-title"
          [ngStyle]="{ color: fbTemplate?.fontColor }"
        >{{ 'BUILD.Cost' | translate }}</span
        >
        <ng-container *ngFor="let tier of getTierForm.controls; index as i">
          <div
            class="margin-bottom-10"
            *ngIf="tier.get('isInclude').value && tier.get('ammount')"
          >
            <span [ngStyle]="{ color: fbTemplate?.fontColor }">
              $ {{ tirePrice(i) }}
            </span>
          </div>
        </ng-container>
        <br/>
        <span
          class="total-value"
          *ngIf="isAmountANumber; else empty"
          [ngStyle]="{ color: fbTemplate?.fontColor }"
        >${{ amount.value | number : '.2' }}</span
        >
        <ng-template #empty>
          <span [ngStyle]="{ color: fbTemplate?.fontColor }">$ 0.00</span>
        </ng-template>
      </div>
    </div>
  </ng-template>
</ng-template>
