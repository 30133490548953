<div class="main-label-wrapper" [ngStyle]="mainLabelStyle">
    <b>{{element.attributes['mainLabel'] | translate}}</b>
</div>
<div class="form-row-wrapper">
    <div class="form-element right-margin" [ngStyle]="doubleFormElementStyle">
        <app-fb-input
                [formGroup]="paymentForm"
                [label]="element.attributes['subLabel']"
                name="employer"
                errorMassage="Employer Name"
                [inputBackground]="template.inputBackground"
                [color]="template.fontColor"
                [fontFamily]="template.fontFamily"
                [fontSize]="template.fontSize"
                [disabled]="readOnly"
        ></app-fb-input>
    </div>
</div>
