import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormElementComponent } from '../../../../form-elements/base.form.element.component';

@Component({
  selector: 'app-fb-radio',
  templateUrl: './fb-radio.component.html',
  styleUrls: ['./fb-radio.component.scss']
})
export class FbRadioComponent extends BaseFormElementComponent implements OnChanges {
  @Input() public color: string = '#0064be';
  @Input() public borderColor: string = '#6A6A6A';

  constructor(public translate: TranslateService) {
    super(translate);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public toggleOption(item): void {
    this.formControl.setValue(item.value);
    this.emitChanges(item.value);
  }

}
