export enum MjmlTag {
  mjml = 'mjml',
  head = 'mj-head',
  style = 'mj-style',
  body = 'mj-body',
  include = 'mj-include',
  section = 'mj-section',
  column = 'mj-column',
  image = 'mj-image',
  divider = 'mj-divider',
  text = 'mj-text',
  font = 'mj-font',
  button = 'mj-button',
  table = 'mj-table',
  social = 'mj-social',
  socialElement = 'mj-social-element',
  wrapper = 'mj-wrapper',
  headerFooter = 'headerFooter', // only for events
  shareOnSM = 'shareOnSM,', // only for events
  sponsors = 'mj-sponsors'
}
