import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import EventModel from 'src/app/models/event/event.model';

@Component({
  selector: 'app-register-client-form',
  templateUrl: './register-client-form.component.html',
  styleUrls: ['./register-client-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterClientFormComponent implements OnInit {
  public clientForm: FormGroup;
  public eventModel: EventModel;
  constructor(private formBuilder: FormBuilder, public router: Router) {}

  public ngOnInit(): void {
    this.clientForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      job: [''],
      company: [''],
      email: [''],
      password: [''],
      confirm: ['']
    });
   
  }

  public register(): void {
    // this.router.navigate(['/clients']);
  }
}
