<div class="history-donations-wrapper">
  <div style="margin: 15px;">
      <app-button
        label="Resend"
        type="flat"
        color="#0064be"
        [disabled]="selectedEmails.size <= 0"
        [isTitleCaseText]="true"
        (clickEvent)="onResendSelectedClick()"
        ></app-button>
  </div>
  <div class="table-wrapper">
    <app-history-emails-table
      [data]="donorTrackedEmails"
      [tableDataColumns]="tableColumns"
      [columnsFilterOptions]="columnsFilterOptions"
      [filterSortConfig]="filterSortConfig"
      (filterChanged)="onCurrentFilterChanged($event)"
      (sortChanged)="onSortChanged($event)"
      (emailSelectChange)="onEmailSelectChange($event)"
      [allEmailsSelected]="allEmailsSelected"
      [someEmailsSelected]="someEmailsSelected"
      (selectAllEmailsChanged)="selectAllEmails($event)"
    ></app-history-emails-table>
  </div>
  <app-paginator
    [totalItems]="total"
    (nextPage)="getNextPage($event)"
    (entriesPerPageChanged)="setEntriesPerPage($event)"
    [setDefaultPage]="setDefaultPage$"
  ></app-paginator>
</div>

