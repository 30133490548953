<div class="image-tool-wrapper">
  <div *ngIf="!entity.attributes.src; else imageTemplate" class="empty-image-src">{{label | translate}}</div>
</div>

<ng-template #imageTemplate>
  <table [ngStyle]="getTableStyles()">
    <tr>
      <td [ngStyle]="getContainerStyles()">
        <img [src]="entity.attributes.src" [ngStyle]="getImageStyles()">
      </td>
    </tr>
  </table>
</ng-template>
