<div class="fb-checkbox-container">
  <div class="main-label" *ngIf="topLabel">{{topLabel | translate}}</div>
  <div
    class="checkbox-field"
    [class.with-tooltip]="tooltip"
    [class.noWrap]="noWrap"
  >
    <div class="checkbox-content" [class.noWrap]="noWrap" (click)="onClick()">
      <i class="far fa-square" *ngIf="!value" [style.color]="readOnly ? '#afafaf': borderColor"></i>
      <i class="fas fa-check-square" *ngIf="value" [style.color]="readOnly ? '#afafaf': color"></i>
      <span class="text-content">
        <ng-content></ng-content>
      </span>
    </div>
    <div
      *ngIf="tooltip"
      class="info-icon"
      [matTooltip]="tooltip | translate"
      matTooltipHideDelay="200"
      matTooltipShowDelay="200"
      matTooltipPosition="after"
      matTooltipClass="custom-tooltip-template"
    >
      <i class="fas fa-info-circle" [style.color]="color"></i>
    </div>
  </div>
</div>

