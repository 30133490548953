<form [formGroup]="form" class="template-select">
  <div class="selects-row">
    <app-select
      label="Form Type"
      [formGroup]="form"
      name="formType"
      [disabled]="true"
      [selectOptions]="formTypes"
      [low]="true"
    ></app-select>
  </div>
  <div class="selects-row">
    <app-search-select
      *ngIf="auxiliaTemplates.length"
      label="Auxilia Forms"
      [selectOptions]="auxiliaTemplates"
      [formGroup]="form"
      name="auxilia"
      [low]="true"
      (valueChanged)="onAuxiliaChanged()"
    ></app-search-select>

    <app-search-select
      *ngIf="clientTemplates.length"
      label="Created Forms"
      [selectOptions]="clientTemplates"
      [formGroup]="form"
      name="client"
      [low]="true"
      (valueChanged)="onClientChanged()"
    ></app-search-select>
  </div>

  <div class="image-container">
    <img [src]="imgSrc">
  </div>

  <div class="buttons">
    <div class="button">
      <app-button
        label="Back"
        width="100px"
        (clickEvent)="back()"
        color="#0064be"
      ></app-button>
    </div>

    <div style="display: flex;">
      <div class="button">
        <app-button
          label="Edit"
          [disabled]="!control('auxilia').value && !control('client').value"
          width="100px"
          (clickEvent)="edit()"
          color="#0064be"
        ></app-button>
      </div>

      <div class="button">
        <app-button
          label="Create New Form"
          width="150px"
          (clickEvent)="createNew()"
          color="#0064be"
        ></app-button>
      </div>
    </div>
  </div>
</form>
