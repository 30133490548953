<div class="custom-header">
  <div class="custom-header__date">
    <button
      mat-icon-button
      class="custom-header__button"
    >
      <span class="custom-header__button_month" (click)="changeDate('year')">{{ periodLabelMonth | titlecase }}</span>
    </button>

    <button
      mat-icon-button
      class="custom-header__button"
    >
      <span class="custom-header__button_year" (click)="changeDate('multi-year')">{{ periodLabelYear }}</span>
    </button>
  </div>

  <div class="custom-header__navigation">
    <button mat-icon-button (click)="previousClicked()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button mat-icon-button (click)="nextClicked()">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>
<div class="line-wrapper">
  <div class="line"></div>
</div>

