import { ActivatedRoute, Router } from '@angular/router';
import { CampaignLayoutControlModel } from 'src/app/models/campaigns/campaign-layout-contol.model';
import { CampaignLayoutModel } from 'src/app/models/campaigns/campaign-layout.model';
import { CampaignLayoutService } from 'src/app/services/campaign/campaign-layout.service';
import { CampaignModel } from 'src/app/models/campaigns/campaign.model';
import { CampaignService } from 'src/app/services/campaign/campaign.service';
import { CampaignModuleModel } from 'src/app/models/campaigns/campaign-module.model';
import { CampaignTemplateType } from 'src/app/models/enum/campaign-template.type';
import { CampaignType } from 'src/app/models/enum';
import { ClientModel } from 'src/app/models/client/client.model';
import { ClientService } from 'src/app/services/client/client.service';
import { Component, OnInit } from '@angular/core';
import { FilterType } from 'src/app/models/enum/filter.type';
import { LookupService } from 'src/app/services/lookup/lookup.service';
import { Paging } from 'src/app/models/paging/paging.model';

@Component({
  selector: 'app-campaign-preview',
  templateUrl: './campaign-preview.component.html',
  styleUrls: ['./campaign-preview.component.scss']
})
export class CampaignPreviewComponent implements OnInit {
  private apiURL: string = localStorage.getItem('apiurl');
  private layout: CampaignLayoutModel;
  public campaign: CampaignModel;
  public client: ClientModel;
  public imagesCount: number = 0;
  public isPreview: boolean;
  public state: string;

  constructor(private activerouter: ActivatedRoute,
    private campaignService: CampaignService,
    private clientService: ClientService, private layoutService: CampaignLayoutService, private lookupService: LookupService, private router: Router) { }

  ngOnInit() {
    const campaignId = this.activerouter.snapshot.params['id'];
    this.isPreview = this.router.url.toLowerCase().includes('preview');
    const campaignFilter: Paging = {
      includeDependencies: true,
      includeDeleted: false,
      filters: [
        {
          field: 'type',
          value: CampaignType.Email.toString(),
          type: FilterType.Equal
        },
        {
          field: 'campaignID',
          value: campaignId,
          type: FilterType.Equal
        }
      ]
    };
    this.campaignService.getModel(campaignId).subscribe(response => {
      this.campaign = response;
      this.clientService.getModel(this.campaign.clientID, false).subscribe(client => {
        this.client = client;
        this.lookupService.getStates().subscribe(state => {
          this.state = state.find(x => x.id === client.phisicalState).name
        });
      });
    });
    this.layoutService.getModelList(campaignFilter).subscribe(response => {
      this.layout = response[0];
      this.imagesCount = this.mainModule.controlList.length - 7;
    });
  }

  private get headerModule(): CampaignModuleModel {
    if (this.layout)
      return this.layout.moduleList.find(x => x.type === CampaignTemplateType.Header);
  }

  public get headerBackground(): CampaignLayoutControlModel {
    if (this.headerModule)
      return this.headerModule.controlList.find(x => x.name === 'Background');
  }

  private get mainModule(): CampaignModuleModel {
    if (this.layout)
      return this.layout.moduleList.find(x => x.type === CampaignTemplateType.Main);
  }

  public get mainBackground(): CampaignLayoutControlModel {
    if (this.mainModule)
      return this.mainModule.controlList.find(x => x.name === 'Background');
  }

  public get mainTitle(): CampaignLayoutControlModel {
    if (this.mainModule)
      return this.mainModule.controlList.find(x => x.name === 'Title');
  }

  public get mainTitleDescription(): CampaignLayoutControlModel {
    if (this.mainModule)
      return this.mainModule.controlList.find(x => x.name === 'TitleDescription');
  }

  public get titleDescriptionSize(): number {
    if (this.mainTitle)
      return Number(this.mainTitle.fontSize) * 0.6;
  }

  public get mainBody(): CampaignLayoutControlModel {
    if (this.mainModule)
      return this.mainModule.controlList.find(x => x.name === 'Text');
  }

  public get mainBody2(): CampaignLayoutControlModel {
    if (this.mainModule)
      return this.mainModule.controlList.find(x => x.name === 'Text2');
  }

  public get signature(): CampaignLayoutControlModel {
    if (this.mainModule)
      return this.mainModule.controlList.find(x => x.name === 'Signature');
  }

  public get buttons(): CampaignLayoutControlModel {
    if (this.mainModule)
      return this.mainModule.controlList.find(x => x.name === 'Buttons');
  }

  private get detailsModule(): CampaignModuleModel {
    if (this.layout)
      return this.layout.moduleList.find(x => x.type === CampaignTemplateType.Details);
  }

  public get donateButton(): CampaignLayoutControlModel {
    if (this.detailsModule) {
      return this.detailsModule.controlList.find(x => x.name === 'Donate');
    }
  }

  public get registerButton(): CampaignLayoutControlModel {
    if (this.detailsModule)
      return this.detailsModule.controlList.find(x => x.name === 'Register');
  }

  private get footerModule(): CampaignModuleModel {
    if (this.layout)
      return this.layout.moduleList.find(x => x.type === CampaignTemplateType.Footer);
  }

  public get footerBackground(): CampaignLayoutControlModel {
    if (this.footerModule)
      return this.footerModule.controlList.find(x => x.name === 'Background');
  }

  public get footerName(): CampaignLayoutControlModel {
    if (this.footerModule)
      return this.footerModule.controlList.find(x => x.name === 'Name');
  }

  public get footerPosition(): CampaignLayoutControlModel {
    if (this.footerModule)
      return this.footerModule.controlList.find(x => x.name === 'PositionName');
  }

  public get footerPhone(): CampaignLayoutControlModel {
    if (this.footerModule)
      return this.footerModule.controlList.find(x => x.name === 'Phone');
  }

  public get footerFundraiser(): CampaignLayoutControlModel {
    if (this.footerModule)
      return this.footerModule.controlList.find(x => x.name === 'FundraisingLink');
  }

  public get footerEmail(): CampaignLayoutControlModel {
    if (this.footerModule)
      return this.footerModule.controlList.find(x => x.name === 'Email');
  }

  public hasImage(imgNumber: number): boolean {
    return this.image(imgNumber) != null && this.image(imgNumber) !== undefined;
  }

  private getImageControl(imgNumber: number): CampaignLayoutControlModel {
    if (this.mainModule) {
      return this.mainModule.controlList.find(x => x.name === `Image${imgNumber}`);
    }
  }

  public image(imgNumber: number): string {
    let image = '';
    if (imgNumber === 0) {
      if (this.headerBackground) image = this.headerBackground.image;
    } else if (imgNumber === 7) {
      if (this.signature) image = this.signature.image;
    } else if (this.getImageControl(imgNumber)) {
      image = this.getImageControl(imgNumber).image;
    }
    if (image) return `${this.apiURL}/AzureFileStorage/image/${image}`;
  }

  public back(): void {
    if (this.campaign) {
      this.router.navigateByUrl(`/clients/campaigns/3/${this.campaign.id}`);
    }
  }

  public registerCampaign(): void {
    if (!this.isPreview) {

    }
  }

  public donateCampaign(): void {
    if (!this.isPreview) {

    }
  }
}
