import { ConfigService } from './appconfig.service';
import { flatMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {
  constructor(private configService: ConfigService, private http: HttpClient) { }

  public deleteJob(id: string): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.delete<any>(`${config.apiURL}/scheduler/${id}`);
      })
    );
  }
}