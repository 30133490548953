import { BaseTableComponent } from '../base-table/base-table.component';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SortOrder } from '../../models/enum/sort.order';
import { SENDGRID_STATUSES } from '../../constants';
import ColumnFilterModel from '../../models/filter-sort/column.filter.model';
import ColumnFilterOptionsModel from '../../models/filter-sort/column.filter.options.model';
import FilterSortModel from '../../models/filter-sort/filter.sort.model';
import {MatTooltip} from '@angular/material/tooltip';
import DonorTrackedEmailModel from '../../models/donor/donor-tracked-email.model';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-history-emails-table',
  templateUrl: './history-emails-table.component.html',
  styleUrls: ['./history-emails-table.component.scss']
})
export class HistoryEmailsTableComponent extends BaseTableComponent<DonorTrackedEmailModel> implements OnChanges {
  @Input() public columnsFilterOptions: ColumnFilterOptionsModel[] = [];
  @Input() public filterSortConfig: FilterSortModel;
  @Output() public filterChanged: EventEmitter<ColumnFilterModel> = new EventEmitter<ColumnFilterModel>();
  @Output() public sortChanged: EventEmitter<FilterSortModel> = new EventEmitter<FilterSortModel>();
  public SortOrder = SortOrder;

  @Output() public emailSelectChange: EventEmitter<DonorTrackedEmailModel> = new EventEmitter<DonorTrackedEmailModel>();

  // Checkbox state
  @Input() public allEmailsSelected: boolean;
  @Input() public someEmailsSelected: boolean;

  @Output() public selectAllEmailsChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableDataColumns) {
      this.tableColumns = [...this.tableDataColumns];
    }
    if (changes.data && this.data) {
      this.tableSource = new MatTableDataSource<DonorTrackedEmailModel>(this.data);
    }
  }

  public getColumnTitle(column: string): string {
    const columnTitles = {
      email: 'Donor Email',
      subject: 'Subject',
      createdOn: 'Date Sent',
      status: 'Engagement details',
      statusTime: 'Date/Time'
    };
    return columnTitles[column];
  }

  public getStatusName(status: string): string {
    if (SENDGRID_STATUSES.hasOwnProperty(status)) {
      return SENDGRID_STATUSES[status];
    }

    return status;
  }

  public canSort(column: string): boolean {
    const columnTitles = [
      'createdOn',
      'statusTime',
    ];
    return columnTitles.includes(column);
  }

  public canFilter(column: string): boolean {
    const columnTitles = [
      'email',
      'status',
    ];
    return columnTitles.includes(column);
  }

  public mouseOver(tooltip: MatTooltip){
    tooltip.disabled = true;
  }

  public toggleTooltip(tooltip: MatTooltip, column: string) {
    if (column !== 'subject') {
      tooltip.hide();
      tooltip.disabled = true;

      return;
    }

    if (tooltip.disabled ) {
      tooltip.disabled = false;
      tooltip.show();
    } else {
      tooltip.hide();
      tooltip.disabled = true;
    }
  }

  public mouseLeave(tooltip: MatTooltip){
    tooltip.hide();
    tooltip.disabled = true;
  }

  public getSelectOptions(column: string): ColumnFilterOptionsModel {
    return this.columnsFilterOptions.find(options => options.columnName === column);
  }

  public selectedOptions(event: ColumnFilterModel): void {
    this.filterChanged.emit(event);
  }

  public isActiveFilter(column): boolean {
    if (!this.filterSortConfig) {
      return false;
    } else {
      const { columnFilters }: FilterSortModel = this.filterSortConfig;
      return !!columnFilters.find(({ field }: ColumnFilterModel) => field === column);
    }
  }

  public sortTable(column: string): void {
    let sortOrder: SortOrder;
    if (this.filterSortConfig.sortField === column) {
      switch (this.filterSortConfig.sortOrder) {
        case SortOrder.Ascending:
          sortOrder = SortOrder.Descending;
          break;
        case SortOrder.Descending:
          sortOrder = SortOrder.Ascending;
      }
    } else {
      sortOrder = SortOrder.Ascending;
    }
    const result: FilterSortModel = {
      sortField: column,
      sortOrder,
      columnFilters: this.filterSortConfig.columnFilters
    };
    this.sortChanged.emit(result);
  }

  public checkColumn(column: string): boolean {
    return this.filterSortConfig.sortField === column;
  }

  public getSortOrder(column: string): SortOrder {
    return this.filterSortConfig.sortField === column ? this.filterSortConfig.sortOrder : null;
  }

  public emailSelect(row: DonorTrackedEmailModel) {
    this.emailSelectChange.emit(row);
  }

  public selectAllEmailsCheckbox(val: MatCheckboxChange): void {
    this.selectAllEmailsChanged.emit(val.checked);
  }
}
