import { ClientDonorModel } from './client.donor.model';
import { ClientEmployeeModel } from './client.employee.model';
import { ClientPlaceholderModel } from './client-placeholder.model';
import { PaymentServiceType } from '../enum/payment.service.type';
import { ClientSupporterModel } from './client.supporter.model';
import { ClientType } from '../enum/client.type';
import { AdditionalClientAddressModel } from './client.additional.address';
import { ClientRelationshipModel } from './client.relationship.model';

export class ClientModel {
  avatarImg?: string;
  billingAddress?: string;
  billingCity?: string;
  billingPostCode?: string;
  billingState?: number;
  billingStateName?: string;
  billingStreet?: string;
  clientPlaceholders?: ClientPlaceholderModel[];
  createdByID?: string;
  createdByUserName?: string;
  createdOn?: Date | string;
  deletedByID?: string;
  deletedByUserName?: string;
  deletedOn?: Date;
  ein?: string;
  employeeList?: ClientEmployeeModel[];
  facebookLink: string;
  fax?: string;
  hasSameBillingAddress?: boolean;
  id?: string;
  instagramLink: string;
  isActive?: boolean;
  isDeleted?: boolean = false;
  isFundraiserEnabled: boolean;
  name?: string;
  personID?: string;
  phisicalAddress?: string;
  phisicalCity?: string;
  phisicalPostCode?: string;
  phisicalState?: number;
  stateName?: string;
  phisicalStreet?: string;
  phone?: string;
  primaryContact?: ClientEmployeeModel;
  primaryContactExtra?: string;
  primaryFullName?: string;
  subscriptionPlan?: string;
  twitterLink: string;
  updatedByID?: string;
  updatedByUserName?: string;
  updatedOn?: Date;
  // for app-select
  label?: string;
  value?: string | number;
  paymentServiceType?: PaymentServiceType;
  relatedSupportersCount?: number;
  cause: number;
  url: string;
  mission: string;
  isSupporting: boolean = false;
  clientDonors: ClientSupporterModel[];
  linkedinLink?: string;
  clientType?: ClientType;
  clientAddresses?: AdditionalClientAddressModel [];
  donorList?: ClientSupporterModel[];
  contributionsSum?: number;
  clientDonorsCount?: number;
  physicalPostCodeString?: string;
  clientRelationship?: ClientRelationshipModel[];
  defaultAddressID?:	string;
  friendlyURL?: string;

  country?: string | number;
  streetAddress2?: string;
  isFundsRequired?: boolean;
  sendFromEmail?: string;
  defaultTemplateId?: string;
  paymentComission?: number;
  paymentFeePercent?: number;
  applicationPaymentComission?: number;
  applicationPaymentFeePercent?: number;
  feeManuallyChanged?: boolean;
  isMerchantFieldNeedUpdate?: boolean;
  isStripeACHEnabled: boolean;
  stripeACHApplicationFeePercent: number;
  stripeACHFeePercent: number;
}
