import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormElementComponent } from '../base.form.element.component';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['../base.form.element.component.scss', '../new.base.form.element.component.scss']
})
export class TextareaComponent extends BaseFormElementComponent implements OnChanges {
  @Input()
  public minRows: number = 1;
  @Input()
  public maxRows: number = 5;
  @Input()
  public maxlengthText: number = 999999;
  @Input()
  public isNew: boolean = false;
  @ViewChild('textArea') public textArea: ElementRef;
  constructor(public translate: TranslateService) {
    super(translate)
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

}
