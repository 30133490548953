<div #topOfPage></div>
<div class="payment-form-wrapper">
  <ng-container *ngFor="let element of fbTemplate.infoPage.elements">
    <app-donation-info-page
      *ngIf="element.type === FbElementType.DonationInfo"
      [class.hidden-payment-page]="paymentPageAvailable"
      [paymentForm]="paymentForm"
      [fbTemplate]="fbTemplate"
      [donationInfoChanged$]="donationInfoChanged$"
      [distributeAmongFunds$]="distributeAmongFunds$"
      [allocatedFundsOptions]="allocatedFundsOptions"
      [client]="client"
      [entity]="entity"
      [isPayPage]="isPayPage"
      (donateEmitter)="donate()"
      [fbss]='fbss'
    ></app-donation-info-page>

    <app-header
      *ngIf="element.type === FbElementType.Header && !paymentPageAvailable"
      [ngStyle]="elementStyle"
      [element]="element"
      [template]="fbTemplate"
      [updateHeader$]="updateHeader$"
    ></app-header>
  </ng-container>
</div>

<div [class.hidden-payment-page]="!paymentPageAvailable" *ngIf="paymentServiceType">
  <app-payment-page
    [event]="event"
    [genericTemplateID]="genericTemplateId"
    [genericSourceTemplateID]="genericSourceTemplateId"
    [campaign]="campaign"
    [socialPost]="socialPost"
    [client]="client"
    [paymentServiceType]="paymentServiceType"
    [states]="states"
    [filledInData]="filledInData"
    [clientDonor]="clientDonor"
    [ammount]="showDonations ? ammount.value : 0"
    [isPreviewMode]="isPreviewMode"
    [isAnonymous]="isAnonymous.value"
    [mainPayment]="mainPayment"
    [showMainPayment]="showMainPayment"
    [mainPaymentTitle]="mainPaymentTitle"
    [recurringPayload]="getRecurringPayload()"
    [noDonations]="!showDonations"
    [clientType]="clientType"
    [countries]="countries"
    [countriesOptions]="countriesOptions"
    [fundAllocation]="getFundAllocation()"
    [fbss]="fbss"
    [paymentForm] ="paymentForm"
    [eventRegistrationModel]="eventRegistrationModel"
    [fbTemplate]="fbTemplate"
    [isBackButtonVisible]="isBackButtonVisible"
    [sourceTemplateModelId]="sourceTemplateModelId"
    [templateId]="templateId"
    [includeFee$]="includeFee$"
    [paymentFee]="paymentFee"
    [isStripeACHEnabled]="isStripeACHEnabled"
    (paymentFinished)="onPaymentFinished($event)"
    (back)="goBack()"
  ></app-payment-page>
</div>
