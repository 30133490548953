export enum IconsType {
  Bell = 1,
  LogOut = 2,
  Facebook = 4,
  Instagram = 5,
  Twitter = 6,
  YouTube = 7,
  LinkedIn = 8,
  Menu = 9,
  Close = 10,
  Case = 11,
  Grid = 12,
  Group = 13,
  Calendar = 14,
  Campaigns = 15,
  File = 16,
  Share = 17,
  BarChart = 18,
  Layout = 19,
  Options = 20,
  CloudUpload = 21,
  Monitor = 22,
  Email = 23,
  CircleClose = 24,
  Plus = 25,
  Crop = 26,
  Move = 27,
  RotateRight = 28,
  RotateLeft = 29,
  MinusCircle = 30,
  MoreVertical = 31,
  Edit2 = 32,
  Trash = 33,
  ChevronUp = 34,
  ChevronDown = 35,
  ArrowIosDown = 36,
  ArrowIosUp = 37,
  Phone = 38,
  Edit = 39,
  ArrowIosLeft = 40,
  MoveTickets = 41,
  Search = 42,
  Sort = 43,
  SortAscending = 44,
  SortDescending = 45,
  Funnel2 = 46,
  Sync= 47,
  CheckMarkCircle= 48,
  Minus = 49,
  Info = 50,
  XLSX = 51,
  IIF = 52,
  CSV = 53,
  CheckMarkSquare = 54
}
