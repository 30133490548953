<div class="fb-input-wrapper" [formGroup]="formGroup" #divElement>
  <mat-form-field appearance="outline" (change)="emitChanges()">
    <input
      matInput
      [formControlName]="name"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      [type]="type"
      [maxlength]="maxLength"
      [mask]="mask ? mask : ''"
      [prefix]="prefix ? prefix : ''"
      [suffix]="suffix ? suffix : ''"
      [thousandSeparator]="thousandSeparator ? thousandSeparator : ''"
      [readonly]="inputReadOnly"
      (blur)="onBlur()"
      (keydown.enter)="onKeydownEnter($event)"
      (input)="onInput($event)"
      [autocomplete]="autocompleteOff ? 'autocompleteOff' : ''"
    >
  </mat-form-field>
  <span class="bottom-label error-message" *ngIf="formControl.touched && formControl.invalid && !customError; else labelTemplate">{{getErrorMessage()}}</span>
</div>


<ng-template #labelTemplate>
  <span class="bottom-label" [ngStyle]="labelStyle">{{label | translate}}</span>
</ng-template>
