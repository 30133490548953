import { AfterViewChecked, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

const RADIUS = 54;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss']
})
export class CircleProgressComponent implements AfterViewChecked {
  @Input()
  public progress: number;

  @ViewChild('progressValue')
  public progressValue: ElementRef;

  constructor(private renderer: Renderer2) {
  }

  public ngAfterViewChecked(): void {
    if (this.progressValue) {
      const dashoffset = CIRCUMFERENCE * (1 - this.progress);
      this.renderer.setStyle(this.progressValue.nativeElement, 'strokeDashoffset', dashoffset);
      this.renderer.setStyle(this.progressValue.nativeElement, 'strokeDasharray', CIRCUMFERENCE);
    }
  }
}
