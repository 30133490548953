import {BehaviorSubject, forkJoin, Observable, of, Subject, Subscription} from 'rxjs';
import {ClientDonorTagService} from '../../../../../services/tag/client-donor-tag.service';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TagService} from '../../../../../services/tag/tag.service';
import {Paging} from '../../../../../models/paging/paging.model';
import {TagStatus} from '../../../../../models/enum/tag-status.type';
import {FilterType} from '../../../../../models/enum/filter.type';
import {exhaustMap, first, tap} from 'rxjs/operators';
import {SingletonSegmentTagService} from 'src/app/services/tag/singleton-segment-tag.service';
import {SortOrder} from '../../../../../models/enum/sort.order';
import {UtilsComponent} from '../../../../../components/utils.component';
import TagModel from 'src/app/models/tag/tag.model';
import {TagType} from '../../../../../models/enum/tag.type';
import {SingletonClientTagService} from '../../../../../services/tag/singleton-client-tag.service';
import { ZEROEVENT } from '../../../../../constants';

@Component({
  selector: 'app-tag-select',
  templateUrl: './tag-select.component.html',
  styleUrls: ['./tag-select.component.scss']
})
export class TagSelectComponent implements OnInit, OnDestroy {
  @Input() public selectedTags: BehaviorSubject<TagModel[]>;
  @Input() public batchId: string;
  @Input() public donorId: string;
  @Input() public clientId: string;
  @Input() public selectOnly: string;
  @Input() public tagService: SingletonSegmentTagService | SingletonClientTagService;

  public availableTags: BehaviorSubject<TagModel[]> = new BehaviorSubject<TagModel[]>(null);
  public potentialSelectedTagsSet: Set<TagModel> = new Set<TagModel>();
  public potentialUnselectedTagsSet: Set<TagModel> = new Set<TagModel>();
  private subscriptions: Subscription = new Subscription();
  public addTagsSource$: Subject<void> = new Subject<void>();
  public removeTagsSource$: Subject<void> = new Subject<void>();
  public tagArrayLength: number;

  private static onShiftKey(set: Set<TagModel>, tags: BehaviorSubject<TagModel[]>, tag: TagModel): void {
    const previousItem = [...set][set.size - 1];
    if (!previousItem) {
      set.add(tag);
    } else {
      const tagsList = tags.getValue();
      const previousIndex = tagsList.indexOf(previousItem);
      const nextIndex = tagsList.indexOf(tag);
      const start = Math.min(previousIndex, nextIndex);
      const end = Math.max(previousIndex, nextIndex);
      for (let i = start; i <= end; i++) {
        set.add(tagsList[i]);
      }
    }
  }

  private static getTagsIds(set: Set<TagModel>): string[] {
    return [...set].map(tag => tag.id);
  }

  constructor(
    private clientDonorTagService: TagService,
    private clientDonorService: ClientDonorTagService,
  ) { }

  public ngOnInit(): void {
    this.getTags();

    /* Add tag*/
    this.subscriptions.add(
      this.addTagsSource$.asObservable()
        .pipe(
          exhaustMap(this.addTags.bind(this)),
          tap(this.onTagsAdded.bind(this))
        )
        .subscribe()
    );

    /* Remove tag*/
    this.subscriptions.add(
      this.removeTagsSource$.asObservable()
        .pipe(
          exhaustMap(this.removeTags.bind(this)),
          tap(this.onTagsRemoved.bind(this))
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private addTags(): Observable<string[]> {
    return this.donorId || this.selectOnly ? of([]) : this.tagService.addTags(this.batchId, TagSelectComponent.getTagsIds(this.potentialSelectedTagsSet));
  }

  private onTagsAdded(): void {
    this.selectedTags.next(UtilsComponent.sortByField([...this.selectedTags.getValue(), ...this.potentialSelectedTagsSet], 'name'));
    const availableTags: TagModel[] = this.availableTags.getValue().filter(tag => !this.potentialSelectedTagsSet.has(tag));
    this.availableTags.next(availableTags);
    this.potentialSelectedTagsSet.clear();
  }

  private removeTags(): Observable<string[]> {
    return this.donorId || this.selectOnly ? of([]) : this.tagService.deleteTags(this.batchId, TagSelectComponent.getTagsIds(this.potentialUnselectedTagsSet));
  }

  private onTagsRemoved(): void {
    this.availableTags.next(UtilsComponent.sortByField([...this.availableTags.getValue(), ...this.potentialUnselectedTagsSet], 'name'));
    const selectedTags: TagModel[] = this.selectedTags.getValue().filter(tag => !this.potentialUnselectedTagsSet.has(tag));
    this.selectedTags.next(selectedTags);
    this.potentialUnselectedTagsSet.clear();
  }

  public getTags(): void {
    const paging: Paging = {
      includeDeleted: false,
      includeDependencies: false,
      sortField: 'name',
      sortOrder: SortOrder.Ascending,
      filters: [
        {
          field: 'clientID',
          value: this.clientId,
          type: FilterType.Equal,
        },
        {
          field: 'status',
          value: TagStatus.Active.toString(),
          type: FilterType.Equal,
        },
        {
          field: 'type',
          value: TagType.AutoTags.toString(),
          type: FilterType.NotEqual,
        },
      ]
    };
    
    if (this.donorId && this.donorId !== ZEROEVENT) {
      this.getDonorTags(paging);
      return;
    }
    this.clientDonorTagService.getModelList(paging)
      .pipe(
        first(),
        tap((tags: TagModel[]) => {
          this.tagArrayLength = tags.length;
          const selectedTagsArray = this.selectedTags.getValue();
            // Filter tags to exclude those already in selectedTags based on id
            const filteredTags = tags.filter(tag => 
              !selectedTagsArray.some(selectedTag => selectedTag.id === tag.id)
            );

            // Update availableTags only with tags not in selectedTags
            this.availableTags.next(filteredTags);
        })
      )
      .subscribe();
  }

  public togglePotentialSelectedTags(event: MouseEvent, tag: TagModel): void {
    if (this.potentialSelectedTagsSet.has(tag)) {
      this.potentialSelectedTagsSet.delete(tag);
    } else if (event.shiftKey) {
      TagSelectComponent.onShiftKey(this.potentialSelectedTagsSet, this.availableTags, tag);
    } else {
      this.potentialSelectedTagsSet.add(tag);
    }
  }

  public togglePotentialUnselectedTags(event: MouseEvent, tag: TagModel): void {
    if (this.potentialUnselectedTagsSet.has(tag)) {
      this.potentialUnselectedTagsSet.delete(tag);
    } else if (event.shiftKey) {
      TagSelectComponent.onShiftKey(this.potentialUnselectedTagsSet, this.selectedTags, tag);
    } else {
      this.potentialUnselectedTagsSet.add(tag);
    }
  }

  public addNewTagToList(tag: TagModel): void {
    this.availableTags.next(UtilsComponent.sortByField([...this.availableTags.getValue(), tag], 'name'));
    this.tagArrayLength++;
  }

  private getDonorTags(paging: Paging): void {
    forkJoin([this.clientDonorService.getModelList(this.donorId), this.clientDonorTagService.getModelList(paging)])
      .pipe(
        tap(([donorTags, tags]) => {
          this.tagArrayLength = tags.length;
          const filterTag = tags.filter(currentTag => donorTags.filter(active => active.id === currentTag.id).length === 0);
          this.availableTags.next(filterTag)
        })
      )
      .subscribe();
  }
}
