import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ImageBuilderStateService } from '../../../image-builder.state.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import {
  FONTS,
  FONT_STYLE,
  FONT_WEIGHT,
  TEXT_ALIGN
} from '../../../../../constants';
import { Subscription } from 'rxjs';
import { LayerModel } from '../../../../../models/image-builder/layer.model';

@Component({
  selector: 'app-text-styling',
  templateUrl: './text-styling.component.html',
  styleUrls: ['../styling.component.scss']
})
export class TextStylingComponent implements OnInit, OnDestroy {
  @Input() public ibss: ImageBuilderStateService;
  private subscription: Subscription = new Subscription();

  public textForm: FormGroup = this.formBuilder.group({
    color: '',
    fontFamily: '',
    fontSize: '',
    fontStyle: '',
    fontWeight: '',
    textDecoration: '',
    align: '',
  });
  public alignOptions: FormElementDataModel[] = TEXT_ALIGN;
  public fontFamilyOptions: FormElementDataModel[] = FONTS;
  public fontStyleOptions: FormElementDataModel[] = FONT_STYLE;
  public fontWeightOptions: FormElementDataModel[] = FONT_WEIGHT;
  public textDecorationOptions: FormElementDataModel[] = [
    {label: 'None', value: 'none'},
    {label: 'Underline', value: 'underline'},
    {label: 'Overline', value: 'overline'},
    {label: 'Line through', value: 'line-through'}
  ];

  constructor(
    private formBuilder: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.subscription.add(
      this.ibss.activeLayerObservable.subscribe((layer: LayerModel) => this.setInitialValues())
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get readOnly(): boolean {
    return this.ibss.readOnly;
  }

  public get themedTemplate(): boolean {
    return this.ibss.themedTemplate;
  }

  public get componentBasedTemplate(): boolean {
    return this.ibss.componentBasedTemplate;
  }

  public onColorChanged(): void {
    const value = this.textForm.get('color').value || '#000000';
    this.ibss.setActiveLayerAttributes('color', value);
  }

  public onFontFamilyChanged(): void {
    const value = this.textForm.get('fontFamily').value || 'Roboto';
    this.ibss.setActiveLayerAttributes('fontFamily', value);
  }

  public onFontSizeChanged(): void {
    const value = this.textForm.get('fontSize').value || '40';
    this.ibss.setActiveLayerAttributes('fontSize', value);
  }

  public onFontStyleChanged(): void {
    const value = this.textForm.get('fontStyle').value || 'normal';
    this.ibss.setActiveLayerAttributes('fontStyle', value);
  }

  public onFontWeightChanged(): void {
    const value = this.textForm.get('fontWeight').value || 'normal';
    this.ibss.setActiveLayerAttributes('fontWeight', value);
  }

  public onTextDecorationChanged(): void {
    const value = this.textForm.get('textDecoration').value || 'none';
    this.ibss.setActiveLayerAttributes('textDecoration', value);
  }

  public onAlignChanged(): void {
    const value = this.textForm.get('align').value || 'center';
    this.ibss.setActiveLayerAttributes('align', value);
  }

  public setInitialValues(): void {
    const {
      color = '#000000',
      fontFamily = 'Arial',
      fontSize = '40',
      fontStyle = 'normal',
      fontWeight = 'normal',
      textDecoration = 'none',
      align = 'center'
    } = this.ibss.activeLayerAttributes;
    this.textForm.get('color').setValue(color);
    this.textForm.get('fontFamily').setValue(fontFamily);
    this.textForm.get('fontSize').setValue(+fontSize);
    this.textForm.get('fontStyle').setValue(fontStyle);
    this.textForm.get('fontWeight').setValue(fontWeight);
    this.textForm.get('textDecoration').setValue(textDecoration);
    this.textForm.get('align').setValue(align);
  }
}
