import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventRegistrationGuard } from 'src/app/guards/event-registration.guard';

@Component({
  selector: 'app-registration-cancel',
  templateUrl: './registration-cancel.component.html',
  styleUrls: ['./registration-cancel.component.scss']
})
export class RegistrationCancelComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RegistrationCancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: null,
    private eventRegistrationGuard: EventRegistrationGuard,
  ) { }

  public handleStay(): void {
    this.eventRegistrationGuard.stay();
    this.dialogRef.close();
  }

  public handleDiscard(): void {
    this.eventRegistrationGuard.discard();
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
