<div class="image-builder-styling-content">
  <div class="image-builder-styling-row">
    <div class="max-width-350">
      <app-slider
        [formGroup]="commonLayerForm"
        name="transparency"
        label="Layer transparency (%)"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onTransparencyChanged()"
        [disabled]="readOnly || themedTemplate"
      ></app-slider>
    </div>
  </div>

  <ng-content></ng-content>

  <div class="image-builder-styling-actions">
    <div
      (click)="duplicate()"
      class="image-builder-styling-layer-action"
      matRipple
    >
      <i class="far fa-clone"></i>
      <span>{{'BUILD.Duplicate' | translate}}</span>
    </div>

    <div
      (click)="delete()"
      class="image-builder-styling-layer-action"
      matRipple
    >
      <i class="far fa-trash-alt"></i>
      <span>{{'BUILD.Delete' | translate}}</span>
    </div>

    <div
      (click)="bringForward()"
      class="image-builder-styling-layer-action"
      matRipple
    >
      <div>
        <i class="fas fa-layer-group"></i>
        <i class="fas fa-level-up-alt"></i>
      </div>
      <span>{{'BUILD.Bring Forward' | translate}}</span>
    </div>

    <div
      (click)="sendBackward()"
      class="image-builder-styling-layer-action"
      matRipple
    >
      <div>
        <i class="fas fa-layer-group"></i>
        <i class="fas fa-level-down-alt"></i>
      </div>
      <span>{{'BUILD.Send Backward' | translate}}</span>
    </div>
  </div>
</div>

