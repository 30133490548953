import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';

@Component({
  selector: 'app-thermometer-tool',
  templateUrl: './thermometer-tool.component.html',
  styleUrls: ['./thermometer-tool.component.scss']
})
export class ThermometerToolComponent implements AfterViewInit {
  @Input() public column: MjmlElementModel;
  @Input() public entity: MjmlElementModel;
  @Input() public tms: TemplateManagementService;
  @Input() public eventId: string;
  @Input() public landingPageView: boolean = false;
  @Input() public screenshots: boolean = false;
  public size: string;

  @ViewChild('thermometer') private thermometer: ElementRef;
  public wrapperParam: DOMRect;

  constructor(
    //private cdRef: ChangeDetectorRef,
  ) { }

  public ngAfterViewInit(): void {
    if (this.entity.attributes.goal !== '0' && this.entity.attributes.goal !== undefined) {
      this.tms.onEditGoal(this.entity.attributes.goal);
    }
    //this.wrapperParam = this.thermometer.nativeElement.getBoundingClientRect();
    //this.getTransformParam();
  }

  /*private getTransformParam(): void {
    if (this.wrapperParam && this.wrapperParam.width !== 0) {
      this.transformParam = this.wrapperParam.width < 500 ?
        (`scale(${(this.wrapperParam.width / 500) + 0.1}, ${(this.wrapperParam.width / 500) + 0.1})`) : null;
      this.cdRef.detectChanges();
    } else {
      this.transformParam = 'scale(1)'
    }
  }*/

  public getContainerStyles(): any {
    const attributes = this.entity.attributes;
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    this.size = attributes['resize'];
    return {
      'padding-bottom': paddingBottom,
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      'padding-top': paddingTop
    };
  }
}
