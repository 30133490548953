<div class="wrapper">
  <div class="content">
    <img src="/assets/images/home.png" style="width: 100%;">
    <div class="content-body">
      <div class="confirmation" *ngIf="!success">
        <div class="title">
          {{'Are you sure you want to unsubscribe from mailing?' | translate}}
        </div>
        <div class="buttons">
          <app-button style="margin-right: 10px;" label="UNSUBSCRIBE" width="150" (clickEvent)="unsubscribe()" type="flat" color="#0064be"></app-button>
          <app-button label="CANCEL" width="150" (clickEvent)="cancel()" type="flat" color="#0064be"></app-button>
        </div>
      </div>
      <div class="success" *ngIf="success">
        <img src="/assets/icons/check1.svg" style="margin-right: 15px;">{{'You\'ve successfully unsubscribed' | translate}}
      </div>
    </div>
  </div>
</div>
