<div class="history-donations-wrapper">
  <!--<div class="history-donations-add">
    <div *ngIf="donationFormVisible; else addFormButton" class="history-donations-add-form">

      <div class="history-donations-row-container-150">
        <app-date-picker [formGroup]="donationForm" name="donationRealDate" label="Date" [errorMassage]="getErrorMessage(donationRealDate, 'Date')" placeholder="MM / DD / YYYY" [dateFilter]="dateFilter" [low]="true"></app-date-picker>
      </div>

      <div class="history-donations-row-container-105">
        <app-input [formGroup]="donationForm" label="Amount" name="donationAmmount" [errorMassage]="getErrorMessage(donationAmmount, 'Amount')" [low]="true"></app-input>
      </div>

      <div class="history-donations-row-container-150">
        <app-select [formGroup]="donationForm" label="Method" name="donationMethod" [errorMassage]="getErrorMessage(donationMethod, 'Method')" [selectOptions]="methodOptions" [low]="true"></app-select>
      </div>

      <div class="history-donations-row-container-105">
        <app-select [formGroup]="donationForm" label="Source" name="donationSource" [errorMassage]="getErrorMessage(donationSource, 'Source')" [selectOptions]="sourceOptions" [low]="true"></app-select>
      </div>

      <div class="history-donations-row-container-150">
        <app-select [formGroup]="donationForm" label="Event" name="eventID" [errorMassage]="getErrorMessage(eventID, 'Event')" [selectOptions]="eventOptions" [low]="true"></app-select>
      </div>

      <div class="history-donations-row-container-150">
        <app-select [formGroup]="donationForm" label="Status" name="donationStatus" [errorMassage]="getErrorMessage(donationStatus, 'Status')" [selectOptions]="statusOptions" [low]="true"></app-select>
      </div>
    </div>
    <ng-template #addFormButton>
      <app-button type="basic" color="#0064be" label="+ Add Another Donation" (clickEvent)="onAddDonationClick()"></app-button>
    </ng-template>
  </div>-->

  <div class="table-wrapper">
    <div class="add-button" *ngIf="isCreateNewEnable">
      <div class="btn-wrapper">
        <app-button type="flat" color="#0064be" label="CREATE NEW" (clickEvent)="onAddDonationClick()"></app-button>
      </div>
    </div>
    <div class="table-wrapper">
      <app-contributions-table
        isFiltersVisible="true"
        [filterSortConfig]="filterSortConfig"
        [columnsFilterOptions]="columnsFilterOptions"
        (filterChanged)="onCurrentFilterChanged($event)"
        [data]="eventDonations"
        [isDonorPortal]="isDonorPortal"
        [tableDataColumns]="tableColumns"
        (sortChanged)=onSortChanged($event)
        (getFilterOptions)="getOptions($event)"
        [distinctData]="distinctData"
        [allocatedFunds]="allocatedFunds"
      ></app-contributions-table>
    </div>
  </div>
  <app-paginator [totalItems]="total" (nextPage)="getNextPage($event)" (entriesPerPageChanged)="setEntriesPerPage($event)"></app-paginator>
</div>
