import {AddonTypes} from 'src/app/constants';
import {AuthService} from 'src/app/services/auth.service';
import {BasePagingComponent} from 'src/app/components/paginator/base.paging.component';
import {ClientIdStateService} from 'src/app/services/client.module.state/client.id.state.service';
import {ClientModuleService} from 'src/app/routes/clients/client.module.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {EventParticipantService} from 'src/app/services/events/event-participant.service';
import {EventService} from 'src/app/services/events/event.service';
import {EventTicketService} from 'src/app/services/events/event-ticket.service';
import {ExportReportsService} from 'src/app/services/export/export-reports.service';
import {Filter} from 'src/app/models/paging/filter.model';
import {FilterType} from 'src/app/models/enum/filter.type';
import {FormBuilder, FormGroup, ValidationErrors} from '@angular/forms';
import {Paging} from 'src/app/models/paging/paging.model';
import {SortOrder} from 'src/app/models/enum/sort.order';
import {merge, Observable, of, Subject, Subscription, zip} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {UtilsComponent} from '../../../components/utils.component';
import ColumnFilterModel from '../../../models/filter-sort/column.filter.model';
import ColumnFilterOptionsModel from '../../../models/filter-sort/column.filter.options.model';
import EventModel from 'src/app/models/event/event.model';
import EventRegistrationsActivity from 'src/app/models/event/event.registration-activity.model';
import FilterSortModel from '../../../models/filter-sort/filter.sort.model';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import {TicketPackageIncludeModel} from 'src/app/models/event/ticket-package-include.model';
import {debounceTime, first, map, startWith, switchMap, tap} from 'rxjs/operators';
import {TicketPackageAddonModel} from 'src/app/models/event/ticket-package-addon.model';
import * as moment from 'moment'

export const ALL = 'All';

@Component({
  selector: 'app-registration-activity',
  templateUrl: './registration-activity.component.html',
  styleUrls: ['../../../routes/clients/reports/components/reports-common.scss']
})
export class RegistrationActivityComponent extends BasePagingComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private ticketfield: string[] = ['buyerFullName', 'ticketPackage.Name', 'includes', 'addons'];
  public pageTitle: string = 'Registration and Ticket Activity';
  public tableColumns: string[] = ['eventName', 'regDate', 'buyerFullName', 'email', 'mobile', 'additionalNotes', 'organization', 'addons', 'includes', 'additionalGuestName', 'ticketPackageName', 'ticketPublicId', 'totalCost', 'registrationPaymentMethod', 'registrationPaymentStatus'];

  public data$: Observable<EventRegistrationsActivity[]>;
  public total$: Observable<number>;
  public columnsFilterOptions$: Observable<ColumnFilterOptionsModel[]>;

  private filterSubject$: Subject<void> = new Subject<void>();
  private pagingSubject$: Subject<void> = new Subject<void>();

  public eventOptions: FormElementDataModel[] = [{
    label: ALL,
    value: ALL
  }];
  public includes: TicketPackageAddonModel[] = [];

  public filterForm: FormGroup = this.formBuilder.group({
    startDate: null,
    endDate: null,
    event: [[ALL]]
  }, {
    validators: [this.startDateValidator.bind(this)]
  });

  public filterSortConfig: FilterSortModel = {
    sortField: 'ticketPurchaser',
    sortOrder: SortOrder.Descending,
    columnFilters: []
  };

  private arrEventsValue: string[] = [ALL];

  constructor(
    private authService: AuthService,
    private clientIdStateService: ClientIdStateService,
    private clientModuleService: ClientModuleService,
    private eventParticipantService: EventParticipantService,
    private eventService: EventService,
    private eventTicketService: EventTicketService,
    private exportReportsService: ExportReportsService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.clientIdStateService.clientIdObservable
        .pipe(
          switchMap((clientId: string) => zip(this.fetchIncludes(clientId), this.fetchEvents(clientId))),
          tap(() => {
            this.data$ = this.loadData();
            this.total$ = this.loadTotal();
            this.columnsFilterOptions$ = this.loadColumnsFilterOptions();
          })
        )
        .subscribe()
    );
  }

  private loadData(): Observable<EventRegistrationsActivity[]> {
    return merge(this.filterSubject$.asObservable(), this.pagingSubject$.asObservable())
      .pipe(
        startWith(''),
        debounceTime(100),
        switchMap(() => {
          const paging: Paging = {
            filters: this.filters(),
            first: this.getFirstItemOnPage(),
            includeDependencies: true,
            rows: this.entriesPerPage,
            selectFields: ['createdOn', 'eventID', 'ticketID', 'clientID', 'participantEventComment', 'email', 'mobile', 'ticketPakageName'],
            sortField: 'createdOn',
            sortOrder: SortOrder.Descending
          };
          return this.eventParticipantService.getRegistrationsActivity(paging)
            .pipe(
              map((response: EventRegistrationsActivity[]) => {
                return response.map((item: EventRegistrationsActivity) => {
                  item.clientID = this.clientIdStateService.selectedRelationshipClientName;
                  item.regDate = item.createdOn.toString();
                  return item;
                });
              })
            );
        })
      );
  }

  private loadTotal(): Observable<number> {
    return this.filterSubject$.asObservable()
      .pipe(
        startWith(''),
        debounceTime(100),
        switchMap(() => {
          const paging: Paging = {
            filters: this.filters(),
            includeDependencies: false
          };
          return this.eventParticipantService.getTotal(paging)
        }),
        tap((total: number) => this.total = total),
      )
  }

  private loadColumnsFilterOptions(): Observable<ColumnFilterOptionsModel[]> {
    return of()
      .pipe(
        startWith(''),
        debounceTime(100),
        switchMap(() => {
          const paging: Paging = {
            includeDependencies: false,
            filters: this.distinctFilters(),
            selectFields: ['ticketPackage.Name', 'includes', 'addons', 'buyerFullName']
          };
          return this.eventTicketService.getDistinctValues(paging)
        }),
        map((distinctValues: any) => {
          //console.log(distinctValues);
          return this.ticketfield.map((column: string) => {
            const optionsList = distinctValues[column].filter((item) => item !== null && item !== undefined && item !== '');
            let options;
            switch (column) {
              case 'buyerFullName':
                options = optionsList.map(option => {
                  return {value: option, label: option};
                });
                options = options.filter(item => item.value !== '');
                return {
                  columnName: 'buyerFullName', options
                };
              case 'addons':
                options = Object.keys(AddonTypes).map(type => ({label: AddonTypes[type], value: type}))
                options.splice(0, 0, {label:'N/A', value: 13});
                options = options.filter(item => item.value !== '');
                return {
                  columnName: 'addons', options
                };
              case 'includes':
                options = [];
                this.includes.forEach(addOn => {
                  if(addOn?.includes.length) {
                    addOn.includes.forEach(include => options.push({label: include.name, value: include.id}))
                  } else options.push({label: addOn.name, value: addOn.id})
                })
                options = this.removeOptionLabelsDuplicates(options);
                options.splice(0, 0, {label:'N/A', value: 0});
                options = options.filter(item => item.value !== '');
                return {
                  columnName: 'includes', options
                };
              case 'ticketPackage.Name':
                options = optionsList.map((option: string) => ({ label: option, value: option }));
                return {
                  columnName: 'ticketPackageName', options
                };
              default:
                options = optionsList.map((option: string) => ({ label: option, value: option }));
            }
            return { columnName: column, options };
          });
        })
      )
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private fetchIncludes(clientId: string): Observable<TicketPackageIncludeModel[]> {
    const paging: Paging = {
      includeDependencies: true,
      filters: [
        {
          field: 'Event.ClientID',
          value: clientId,
          type: FilterType.Equal
        }
      ],
    };
    return this.eventTicketService.getAddons(paging)
      .pipe(
        tap((res: TicketPackageAddonModel[]) => {
          this.includes = res;
        } )
      );
  }
  s
  public getNextPage(page: number): void {
    this.nextPage(page, () => this.pagingSubject$.next());
  }

  public setEntriesPerPage(amount: number): void {
    this.entriesPerPageChanged(amount, () => this.pagingSubject$.next());
  }

  public onCurrentFilterChanged(columnFilter: ColumnFilterModel): void {
    this.filterSortConfig.columnFilters = UtilsComponent.updateColumnFilters(columnFilter, this.filterSortConfig);
    if (this.filterForm.valid) {
      this.filterSubject$.next();
    }
  }

  private get tableFilter(): Filter[] {
    if (!this.filterSortConfig.columnFilters.length) {
      return [];
    } else {
      const filters: Filter[] = this.filterSortConfig.columnFilters.map((columnFilter: ColumnFilterModel) => {
        const values = UtilsComponent.getArrayQueryValue(columnFilter.values);
        return {
          field: columnFilter.field,
          value: `array[${values}]`,
          type: FilterType.Equal,
        };
      });

      this.filterSortConfig.columnFilters.forEach((columnFilter: ColumnFilterModel) => {
        if(columnFilter.labels && columnFilter.field === 'includes') {
          const labels = UtilsComponent.getArrayQueryValue(columnFilter.labels);
          filters.push({
            field: 'labels',
            value: `array[${labels}]`,
            type: FilterType.Equal,
          })
        }
      })
      return filters;
    }
  }

  private get baseFilter(): Filter {
    return {
      field:"fundraiserID",
      value: "array[null]",
      type: FilterType.Equal
    }
  }

  public onTopFilterChanged(): void {
    let formValue = this.filterForm.getRawValue();
    if (formValue.startDate && formValue.endDate) {
      if (formValue.endDate < formValue.startDate) {
        return;
      }
    }
    this.onFilterChanged();
    this.filterSubject$.next();
  }

  public clientChanged(): void {
    this.filterForm.reset();
    this.filterForm.get('event').setValue([ALL]);
    this.arrEventsValue = [ALL];
    this.onFilterChanged();
    this.filterSubject$.next();
  }


  public exportReport(): void {
    const paging: Paging = {
      includeDependencies: true,
      filters: this.filters(),
      selectFields: ['Buyer', 'Event', 'createdOn'],
      sortField: 'createdOn',
      sortOrder: SortOrder.Descending
    };
    if (this.filterForm.valid) {
      this.exportReportsService.exportRegistrationActivity(paging, this.authService.getIdentityClaimsId(), this.authService.getIdentityClaimsName())
        .pipe(
          first()
        )
        .subscribe(response => {
          if (response.size === 9)
            this.toastr.info('Your Export is scheduled. After finishing you can get the link in Notifications');
          else
            saveAs(response, `registrationActivity_${new Date().toDateString()}.xlsx`);
        });
    } else {
      this.toastr.error('Please fill all mandatory fields', 'Error');
    }
  }

  private fetchEvents(clientId: string): Observable<EventModel[]> {
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: true,
      filters: [
        {
          field: 'clientID',
          value: clientId,
          type: FilterType.Equal
        }
      ],
      selectFields: ['id', 'name'],
      sortField: 'name'
    };
    return this.eventService.getModelList(paging)
      .pipe(
        tap((events: EventModel[]) =>
          this.eventOptions = [
          {label: ALL, value: ALL},
          ...events.filter(a => a.name && a.name.trim() !== '').map(({ name, id }: EventModel) => ({ label: name, value: id }))
        ])
      )
  }

  private filters(): Filter[] {
    return [
      ...this.distinctFilters(),
      ...this.tableFilter,
      this.baseFilter
    ];
  }

  private distinctFilters(): Filter[] {
    const { event, startDate, endDate } = this.filterForm.getRawValue();
    const filters: Filter[] = [
      {
        field: 'Event.clientID',
        value: this.clientIdStateService.selectedRelationshipClientId,
        type: FilterType.Equal
      }
    ];
    if (event.every(item => item !== ALL) && event.length) {
      filters.push({
        field: 'Event.ID',
        value: `array[${event.map(item => `"${item}"`)}]`,
        type: FilterType.Equal,
        filterType: FilterType.Equal
      });
    }
    if (startDate) {
      filters.push({
        field: 'createdOn',
        value: moment.utc([...this.convertDate(new Date(startDate)), 0, 0, 0]).toISOString(),
        type: FilterType.GreaterThanOrEqual,
        filterType: FilterType.GreaterThanOrEqual
      });
    }
    if (endDate) {
      filters.push({
        field: 'createdOn',
        value: moment.utc([...this.convertDate(new Date(endDate)), 23, 59, 59]).toISOString(),
        type: FilterType.LessThanOrEqual,
        filterType: FilterType.LessThanOrEqual
      });
    }
    return filters;
  }

  private convertDate(date: Date): number[] {
    return [date.getFullYear(), date.getMonth(), date.getDate()];
  }

  private startDateValidator(formGroup: FormGroup): ValidationErrors {
    const { startDate, startTime, timeZoneID, endDate }: any = formGroup.value;
    if (!startDate || !endDate) {
      return null;
    } else {
      if (endDate < startDate) {
        formGroup.controls.startDate.setErrors({ matDatepickerFilter: true });
      } else {
        formGroup.controls.startDate.setErrors(null);
      }
    }
    return endDate >= startDate ? null : { invalidDate: true };
  }

  public onEventOptionsChanged(val: string[]): void {
    if (val.includes(ALL) && !this.arrEventsValue.includes(ALL)) {
      val = [ALL];
    } else if (val.includes(ALL) && this.arrEventsValue.includes(ALL)) {
      val = val.filter(eventId => eventId !== ALL);
    } else if (!val.includes(ALL) && val.length === this.eventOptions.length - 1) {
      val = [ALL];
    }
    this.filterForm.get('event').setValue(val);
    this.arrEventsValue = val;
    this.onTopFilterChanged();
  }

  private removeOptionLabelsDuplicates(options: {label: string, value: any}[]): {label: string, value: number[]}[] {
    if(!options && !options.length) return;
    const withoutDuplicates: {label: string, value: number[]}[] = [];
    options.forEach(option => {
      if(withoutDuplicates.find(item => item.label === option.label)) {
        withoutDuplicates.find(item => item.label === option.label).value.push(+option.value);
      } else {
        withoutDuplicates.push({label: option.label, value: [+option.value]})
      }
    })
    return withoutDuplicates;
  }
}
