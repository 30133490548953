<div class="tools-renderer-wrapper">
  <div class="tools-renderer-item" *ngFor="let child of column.children">
    <ng-container [ngSwitch]="child.tagName">

      <ng-container *ngSwitchCase="MjmlTag.text">
        <app-text-tool
          *ngIf="!getCustomType(child); else customTextTool"
          [column]="column"
          [entity]="child"
          [tms]="tms"
          [landingPageView]="landingPageView"
          (click)="setActiveElement(child)"
        ></app-text-tool>

        <ng-template #customTextTool>
          <ng-container [ngSwitch]="getCustomType(child)">

            <app-video-tool
              *ngSwitchCase="MjmlCustomType.video"
              [column]="column"
              [entity]="child"
              [tms]="tms"
              [landingPageView]="landingPageView"
              (click)="setActiveElement(child)"
            ></app-video-tool>

            <app-thermometer-tool
              *ngSwitchCase="MjmlCustomType.thermometer"
              [column]="column"
              [entity]="child"
              [eventId]="eventId"
              [tms]="tms"
              [landingPageView]="landingPageView"
              [screenshots]="screenshots"
              (click)="setActiveElement(child)"
            ></app-thermometer-tool>

            <app-signature-tool
              *ngSwitchCase="MjmlCustomType.signature"
              [column]="column"
              [entity]="child"
              [tms]="tms"
              (click)="setActiveElement(child)"
            ></app-signature-tool>

            <app-email-video-tool
              *ngSwitchCase="MjmlCustomType.emailVideo"
              [column]="column"
              [entity]="child"
              [tms]="tms"
              (click)="setActiveElement(child)"
            ></app-email-video-tool>

            <app-donation-activity
              *ngSwitchCase="MjmlCustomType.donationActivity"
              [column]="column"
              [entity]="child"
              [tms]="tms"
              [landingPageView]="landingPageView"
              [screenshots]="screenshots"
              [eventId]="eventId"
              [fundraiserId]="fundraiserId"
              (click)="setActiveElement(child)"
            ></app-donation-activity>

            <app-comment-wall
              *ngSwitchCase="MjmlCustomType.commentWall"
              [column]="column"
              [entity]="child"
              [tms]="tms"
              [landingPageView]="landingPageView"
              [screenshots]="screenshots"
              [eventID]="eventId"
              (click)="setActiveElement(child)"
            ></app-comment-wall>

            <app-share-on-sm
              *ngSwitchCase="MjmlCustomType.shareOnSM"
              [column]="column"
              [entity]="child"
              [tms]="tms"
              [landingPageView]="landingPageView"
              (click)="setActiveElement(child)"
            ></app-share-on-sm>

            <app-module-tool
              *ngSwitchDefault
              [column]="column"
              [entity]="child"
              [tms]="tms"
              (click)="setActiveElement(child)"
            ></app-module-tool>

          </ng-container>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="MjmlTag.image">
        <app-image-tool
          *ngIf="!isChart(child)"
          [column]="column"
          [entity]="child"
          [tms]="tms"
          [fundraiserId]="fundraiserId"
          (click)="setActiveElement(child)"
        ></app-image-tool>

        <app-chart-tool
          *ngIf="isChart(child)"
          [column]="column"
          [entity]="child"
          [tms]="tms"
          (click)="setActiveElement(child)"
        ></app-chart-tool>
      </ng-container>

      <app-button-tool
        *ngSwitchCase="MjmlTag.button"
        [column]="column"
        [entity]="child"
        [tms]="tms"
        [landingPageView]="landingPageView"
        (click)="setActiveElement(child)"
      ></app-button-tool>

      <app-table-tool
        *ngSwitchCase="MjmlTag.table"
        [column]="column"
        [entity]="child"
        [tms]="tms"
        [landingPageView]="landingPageView"
        (click)="setActiveElement(child)"
      ></app-table-tool>

      <app-sm-tool
        *ngSwitchCase="MjmlTag.social"
        [column]="column"
        [entity]="child"
        [tms]="tms"
        [landingPageView]="landingPageView"
        (click)="setActiveElement(child)"
      ></app-sm-tool>

      <app-divider-tool
        *ngSwitchCase="MjmlTag.divider"
        [column]="column"
        [entity]="child"
        [tms]="tms"
        (click)="setActiveElement(child)"
      ></app-divider-tool>

      <app-sponsors-tool
        *ngSwitchCase="MjmlTag.sponsors"
        [entity]="child"
        [landingPageView]="landingPageView"
        (click)="setActiveElement(child)"
      ></app-sponsors-tool>

    </ng-container>
  </div>
</div>

