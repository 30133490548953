<div class="remove-icon">
    <i class="fas fa-times-circle" (click)="close()"></i>
</div>
<div class="wrapper-admin-confirm-component p-3" *ngIf="!isCheckFailed">
    <div>
        {{'Please note this is a private event.\<br>Only invited guests can register, please enter your email to confirm you invitation and complete the registration process.' | translate}}
    </div>
    <form [formGroup]="participantForm">
        <app-input [formGroup]="participantForm" errorMassage="Email" name="email" label="Email *">
        </app-input>
    </form>

    <div mat-dialog-actions class="justify-content-end">
      <button color="primary" mat-button mat-stroked-button (click)="onOKClick()">{{'Save' | translate}}</button>
      <button color="primary" mat-button mat-stroked-button [mat-dialog-close]="false" (click)="close()">{{'Cancel' | translate}}</button>
    </div>
</div>
<div class="wrapper-admin-confirm-component p-3" *ngIf="isCheckFailed">
    {{'Sorry, we cannot find you email in the guests list. Please contact us for more details.' | translate}}
</div>
