export enum YouTubeCategoryType {
  FilmAnimation = 1,
  AutosVehicles = 2,
  Music = 10,
  PetsAnimals = 15,
  Sports = 17,
  TravelEvents = 19,
  Gaming = 20,
  PeopleBlogs = 22,
  Comedy = 23,
  Entertainment = 24,
  NewsPolitics = 25,
  HowtoStyle = 26,
  Education = 27,
  ScienceTechnology = 28,
  NonprofitsActivism = 29,
}
