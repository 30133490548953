import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {ButtonToggleModel} from '../../models/buttonToggleModel';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonToggleComponent {
  @Input()
  public optionsList: Array<ButtonToggleModel> = [];
  @Output()
  public changeEvent: EventEmitter<MatButtonToggleChange> = new EventEmitter<MatButtonToggleChange>();

  public onChange(event) {
    this.changeEvent.emit(event)
  }
}
