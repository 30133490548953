import { bufferCount, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ClientModel } from '../../../../../models/client/client.model';
import { Filter } from '../../../../../models/paging/filter.model';
import { FilterType } from '../../../../../models/enum/filter.type';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { Paging } from '../../../../../models/paging/paging.model';
import { Router } from '@angular/router';
import { SortOrder } from '../../../../../models/enum/sort.order';
import { TemplateModelService } from '../../../../../services/templates/template.model.service';
import { TemplateUsageType } from '../../../../../models/templates/template.usage.type';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import TemplateModel from '../../../../../models/templates/template.model';
import { UtilsComponent } from '../../../../../components/utils.component';

@Component({
  selector: 'app-clients-templates',
  templateUrl: './clients-templates.component.html',
  styleUrls: ['./clients-templates.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientsTemplatesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public clients: ClientModel[] = [];
  @Input() public search$: Observable<string>;
  @Input() public startDate: Date;
  private checkTabSubject: Subject<void> = new Subject<void>();
  private subscription: Subscription = new Subscription();
  public clientsOptions: FormElementDataModel[] = [];
  public currentTab: number = 0;
  public entriesPerPageSettings: number[] = [5, 10, 25];
  public searchFilter: Filter[] = [];

  public eventTemplates$: Observable<TemplateModel[]>;
  public eventTemplatesTotal: number = 0;
  public eventTemplatesEntriesPerPage: number = 5;
  public currentEventTemplatesPage: number = 1;

  public campaignTemplates$: Observable<TemplateModel[]>;
  public campaignTemplatesTotal: number = 0;
  public campaignTemplatesEntriesPerPage: number = 5;
  public currentCampaignTemplatesPage: number = 1;

  public socialMediaTemplates$: Observable<TemplateModel[]>;
  public socialMediaTemplatesTotal: number = 0;
  public socialMediaTemplatesEntriesPerPage: number = 5;
  public currentSocialMediaTemplatesPage: number = 1;

  public donationFormsTemplates$: Observable<TemplateModel[]>;
  public donationFormsTemplatesTotal: number = 0;
  public donationFormsTemplatesEntriesPerPage: number = 5;
  public currentDonationFormsTemplatesPage: number = 1;

  public letterTemplates$: Observable<TemplateModel[]>;
  public letterTemplatesTotal: number = 0;
  public letterTemplatesEntriesPerPage: number = 5;
  public currentLetterTemplatesPage: number = 1;

  private setEventDefaultPage: Subject<number> = new Subject<number>();
  public setEventDefaultPage$: Observable<number> = this.setEventDefaultPage.asObservable();
  private setCampaignDefaultPage: Subject<number> = new Subject<number>();
  public setCampaignDefaultPage$: Observable<number> = this.setCampaignDefaultPage.asObservable();
  private setSocialMediaDefaultPage: Subject<number> = new Subject<number>();
  public setSocialMediaDefaultPage$: Observable<number> = this.setSocialMediaDefaultPage.asObservable();
  private setDonationFormsDefaultPage: Subject<number> = new Subject<number>();
  public setDonationFormsDefaultPage$: Observable<number> = this.setDonationFormsDefaultPage.asObservable();
  private setLettersDefaultPage: Subject<number> = new Subject<number>();
  public setLettersDefaultPage$: Observable<number> = this.setDonationFormsDefaultPage.asObservable();

  public clientForm: FormGroup = this.formBuilder.group({
    client: null,
  });

  private baseFilter: Filter[] = [
    {
      field: 'isDeleted',
      value: 'false',
      type: FilterType.Equal
    }
  ];

  private eventBaseFilter: Filter[] = [
    ...this.baseFilter,
    {
      field: 'templateUsageType',
      value: TemplateUsageType.Event.toString(),
      type: FilterType.Equal
    }
  ];

  private campaignBaseFilter: Filter[] = [
    ...this.baseFilter,
    {
      field: 'templateUsageType',
      value: TemplateUsageType.Campaign.toString(),
      type: FilterType.Equal
    }
  ];

  private socialMediaBaseFilter: Filter[] = [
    ...this.baseFilter,
    {
      field: 'templateUsageType',
      value: TemplateUsageType.SocialMedia.toString(),
      type: FilterType.Equal
    }
  ];

  private donationFormsBaseFilter: Filter[] = [
    ...this.baseFilter,
    {
      field: 'templateUsageType',
      value: TemplateUsageType.DonationForms.toString(),
      type: FilterType.Equal
    }
  ];

  private lettersBaseFilter: Filter[] = [
    ...this.baseFilter,
    {
      field: 'templateUsageType',
      value: TemplateUsageType.Letter.toString(),
      type: FilterType.Equal
    }
  ];

  private commonPaging: Partial<Paging> = {
    sortField: 'createdOn',
    sortOrder: SortOrder.Descending,
    selectFields: ['name', 'templateUsageType', 'id', 'description', 'createdOn', 'imagePreviewURL'],
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private templateModelService: TemplateModelService
  ) { }

  public ngOnInit(): void {
    this.subscription.add(
      this.search$
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((value: string) => {
          if (value.toLowerCase() !== 'new') {
            this.searchFilter = value
              ? [{
                field: 'combinedSearch',
                value,
                type: FilterType.Contains
              }]
              : [];
          } else {
            this.searchFilter = [
              {
                field: 'createdOn',
                value: this.startDate.toJSON(),
                type: FilterType.GreaterThanOrEqual
              }
            ];
          }
          this.getData(true);
        })
    );
    const bufferSize = 4;
    this.subscription.add(
      this.checkTabSubject.asObservable()
        .pipe(
          bufferCount(bufferSize)
        )
        .subscribe(this.changeTabOnResponse.bind(this))
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.clients && changes.clients.currentValue && changes.clients.currentValue.length) {
      this.clientsOptions = this.clients
        .map(({ name, id }: ClientModel) => ({ value: id, label: name }))
        .sort((a, b) => {
          const aLabel = a.label.toLowerCase();
          const bLabel = b.label.toLowerCase();
          if (aLabel < bLabel) return -1;
          if (aLabel > bLabel) return 1;
          return 0;
        });
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getData(checkTab: boolean = false): void {
    if (!this.client.value) return;
    this.resetEventsTemplates();
    this.resetCampaignsTemplates();
    this.resetSocialMediaTemplates();
    this.resetLetterTemplates();

    this.getEventTemplates(checkTab);
    this.getEventTemplatesTotal();
    this.getLetterTemplates(checkTab);
    this.getLetterTemplatesTotal();
    //todo setTimeout for test purposes, can be removed
    setTimeout(() => {
      this.getCampaignTemplates(checkTab);
      this.getCampaignTemplatesTotal();
    }, 300);
    setTimeout(() => {
      this.getSocialMediaTemplates(checkTab);
      this.getSocialMediaTemplatesTotal();
    }, 600);
    setTimeout(() => {
      this.getDonationFormsTemplates(checkTab);
      this.getDonationFormsTemplatesTotal();
    }, 900);
  }

  public navigateTo(templateModel: TemplateModel) {
    const url = `/admin-dashboard/library/${templateModel.id}/${templateModel.templateUsageType}/client`;
    this.router.navigateByUrl(url);
  }

  private getEventTemplates(checkTab: boolean = false): void {
    this.eventTemplates$ = this.templateModelService.getModelList(this.eventPaging)
      .pipe(
        tap(() => {
          if (checkTab) {
            this.checkTabSubject.next();
          }
        })
      );
    this.cdr.markForCheck();
  }

  private getEventTemplatesTotal(): void {
    this.subscription.add(
      this.templateModelService.getTotal(this.getTotalPaging(this.eventBaseFilter)).subscribe((total: number) => this.eventTemplatesTotal = total)
    );
  }

  private getCampaignTemplates(checkTab: boolean = false): void {
    this.campaignTemplates$ = this.templateModelService.getModelList(this.campaignPaging)
      .pipe(
        tap(() => {
          if (checkTab) {
            this.checkTabSubject.next();
          }
        })
      );
    this.cdr.markForCheck();
  }

  private getCampaignTemplatesTotal(): void {
    this.subscription.add(
      this.templateModelService.getTotal(this.getTotalPaging(this.campaignBaseFilter)).subscribe((total: number) => this.campaignTemplatesTotal = total)
    );
  }

  private getSocialMediaTemplates(checkTab: boolean = false): void {
    this.socialMediaTemplates$ = this.templateModelService.getModelList(this.socialMediaPaging)
      .pipe(
        tap(() => {
          if (checkTab) {
            this.checkTabSubject.next();
          }
        })
      );
    this.cdr.markForCheck();
  }

  private getDonationFormsTemplates(checkTab: boolean = false): void {
    this.donationFormsTemplates$ = this.templateModelService.getModelList(this.donationFormPaging)
      .pipe(
        tap(() => {
          if (checkTab) {
            this.checkTabSubject.next();
          }
        })
      );
    this.cdr.markForCheck();
  }


  private getLetterTemplates(checkTab: boolean = false): void {
    this.letterTemplates$ = this.templateModelService.getModelList(this.letterPaging)
      .pipe(
        tap(() => {
          if (checkTab) {
            this.checkTabSubject.next();
          }
        })
      );
    this.cdr.markForCheck();
  }

  private getSocialMediaTemplatesTotal(): void {
    this.subscription.add(
      this.templateModelService.getTotal(this.getTotalPaging(this.socialMediaBaseFilter)).subscribe((total: number) => this.socialMediaTemplatesTotal = total)
    );
  }

  private getDonationFormsTemplatesTotal(): void {
    this.subscription.add(
      this.templateModelService.getTotal(this.getTotalPaging(this.donationFormsBaseFilter)).subscribe((total: number) => this.donationFormsTemplatesTotal = total)
    );
  }

  private getLetterTemplatesTotal(): void {
    this.subscription.add(
      this.templateModelService.getTotal(this.getTotalPaging(this.lettersBaseFilter)).subscribe((total: number) => this.letterTemplatesTotal = total)
    );
  }

  private getTotalPaging(baseFilter: Filter[]): Paging {
    return {
      includeDependencies: false,
      filters: [
        ...baseFilter,
        ...this.searchFilter,
        this.clientFilter
      ],
    };
  }

  private get clientFilter(): Filter {
    return {
      field: 'clientID',
      value: this.client.value,
      type: FilterType.Equal
    };
  }

  private get eventPaging(): Paging {
    return {
      ...this.getTotalPaging(this.eventBaseFilter),
      ...this.commonPaging,
      rows: this.eventTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.eventTemplatesTotal, this.currentEventTemplatesPage, this.eventTemplatesEntriesPerPage),
    };
  }

  private get campaignPaging(): Paging {
    return {
      ...this.getTotalPaging(this.campaignBaseFilter),
      ...this.commonPaging,
      rows: this.campaignTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.campaignTemplatesTotal, this.currentCampaignTemplatesPage, this.campaignTemplatesEntriesPerPage),
    };
  }

  private get socialMediaPaging(): Paging {
    return {
      ...this.getTotalPaging(this.socialMediaBaseFilter),
      sortField: 'createdOn',
      sortOrder: SortOrder.Descending,
      selectFields: ['name', 'templateUsageType', /*'content', 'contentHTML', */'id', 'description', 'createdOn', 'imagePreviewURL'],
      rows: this.socialMediaTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.socialMediaTemplatesTotal, this.currentSocialMediaTemplatesPage, this.socialMediaTemplatesEntriesPerPage),
    };
  }

  private get donationFormPaging(): Paging {
    return {
      ...this.getTotalPaging(this.donationFormsBaseFilter),
      sortField: 'createdOn',
      sortOrder: SortOrder.Descending,
      selectFields: ['name', 'templateUsageType', /*'content', 'contentHTML', */'id', 'description', 'createdOn', 'imagePreviewURL'],
      rows: this.donationFormsTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.donationFormsTemplatesTotal, this.currentDonationFormsTemplatesPage, this.donationFormsTemplatesEntriesPerPage),
    };
  }

  private get letterPaging(): Paging {
    return {
      ...this.getTotalPaging(this.lettersBaseFilter),
      sortField: 'createdOn',
      sortOrder: SortOrder.Descending,
      selectFields: ['name', 'templateUsageType', 'id', 'description', 'createdOn', 'imagePreviewURL'],
      rows: this.letterTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.letterTemplatesTotal, this.currentLetterTemplatesPage, this.letterTemplatesEntriesPerPage),
    };
  }

  public eventTemplatesEntriesPerPageChanged(entriesPerPage: number): void {
    this.eventTemplatesEntriesPerPage = entriesPerPage;
    this.resetEventsTemplates();
    this.getEventTemplates();
    this.getEventTemplatesTotal();
  }

  public eventTemplatesNextPageChanged(page: number): void {
    this.currentEventTemplatesPage = page;
    this.getEventTemplates();
  }

  public campaignTemplatesEntriesPerPageChanged(entriesPerPage: number): void {
    this.campaignTemplatesEntriesPerPage = entriesPerPage;
    this.resetCampaignsTemplates();
    this.getCampaignTemplates();
    this.getCampaignTemplatesTotal();
  }

  public campaignTemplatesNextPageChanged(page: number): void {
    this.currentCampaignTemplatesPage = page;
    this.getCampaignTemplates();
    this.getCampaignTemplatesTotal();
  }

  public socialMediaTemplatesEntriesPerPageChanged(entriesPerPage: number): void {
    this.socialMediaTemplatesEntriesPerPage = entriesPerPage;
    this.resetSocialMediaTemplates();
    this.getSocialMediaTemplates();
    this.getSocialMediaTemplatesTotal();
  }

  public donationFormsTemplatesEntriesPerPageChanged(entriesPerPage: number): void {
    this.socialMediaTemplatesEntriesPerPage = entriesPerPage;
    this.resetDonationFormsTemplates();
    this.getDonationFormsTemplates();
    this.getDonationFormsTemplatesTotal();
  }

  public letterTemplatesEntriesPerPageChanged(entriesPerPage: number): void {
    this.letterTemplatesEntriesPerPage = entriesPerPage;
    this.resetLetterTemplates();
    this.getLetterTemplates();
    this.getLetterTemplatesTotal();
  }

  public socialMediaTemplatesNextPageChanged(page: number): void {
    this.currentSocialMediaTemplatesPage = page;
    this.getSocialMediaTemplates();
    this.getSocialMediaTemplatesTotal();
  }

  public donationFormsTemplatesNextPageChanged(page: number): void {
    this.currentSocialMediaTemplatesPage = page;
    this.getDonationFormsTemplates();
    this.getDonationFormsTemplatesTotal();
  }

  public letterTemplatesNextPageChanged(page: number): void {
    this.currentLetterTemplatesPage = page;
    this.getLetterTemplates();
    this.getLetterTemplatesTotal();
  }

  private resetEventsTemplates(): void {
    this.currentEventTemplatesPage = 1;
    this.setEventDefaultPage.next(1);
  }

  private resetCampaignsTemplates(): void {
    this.currentCampaignTemplatesPage = 1;
    this.setCampaignDefaultPage.next(1);
  }

  private resetSocialMediaTemplates(): void {
    this.currentSocialMediaTemplatesPage = 1;
    this.setSocialMediaDefaultPage.next(1);
  }

  private resetDonationFormsTemplates(): void {
    this.currentDonationFormsTemplatesPage = 1;
    this.setDonationFormsDefaultPage.next(1);
  }

  private resetLetterTemplates(): void {
    this.currentLetterTemplatesPage = 1;
    this.setLettersDefaultPage.next(1);
  }

  public get client(): FormControl {
    return this.clientForm.get('client') as FormControl;
  }

  public isNewTemplate(template: TemplateModel): boolean {
    return new Date(template.createdOn) >= this.startDate;
  }

  public getTemplateImgSrc(src: string): string {
    return src ? src : '/assets/images/defaultTemplate.svg';
  }

  private changeTabOnResponse(): void {
    if (this.currentTab === 0 && !this.eventTemplatesTotal) {
      if (this.campaignTemplatesTotal) {
        this.currentTab = 1;
      } else if (this.socialMediaTemplatesTotal) {
        this.currentTab = 2;
      } else if (this.donationFormsTemplatesTotal) {
        this.currentTab = 3;
      }
    } else if (this.currentTab === 1 && !this.campaignTemplatesTotal) {
      if (this.eventTemplatesTotal) {
        this.currentTab = 0;
      } else if (this.socialMediaTemplatesTotal) {
        this.currentTab = 2;
      } else if (this.donationFormsTemplatesTotal) {
        this.currentTab = 3;
      }
    } else if (this.currentTab === 2 && !this.socialMediaTemplatesTotal) {
      if (this.eventTemplatesTotal) {
        this.currentTab = 0;
      } else if (this.campaignTemplatesTotal) {
        this.currentTab = 1;
      } else if (this.donationFormsTemplatesTotal) {
        this.currentTab = 3;
      }
    } else if (this.currentTab === 3 && !this.donationFormsTemplatesTotal) {
      if (this.eventTemplatesTotal) {
        this.currentTab = 0;
      } else if (this.campaignTemplatesTotal) {
        this.currentTab = 1;
      } else if (this.socialMediaTemplatesTotal) {
        this.currentTab = 2;
      }
    } else return;
  }
}
