import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ImageBuilderStateService } from '../../image-builder.state.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TemplateType } from '../../../../models/templates/template.type';
import { AdminTemplatesStateService } from '../../../../routes/admin/admin-templates/admin-templates-state.service';
import TemplatePayload from '../../../../models/templates/template.payload';
import TagModel from '../../../../models/templates/tag.model';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { TEMPLATE_RESTRICTION_TYPES } from '../../../../constants';

@Component({
  selector: 'app-save',
  templateUrl: './save.component.html',
  styleUrls: ['../styling/styling.component.scss']
})
export class SaveComponent implements OnChanges {
  @Input() public ibss: ImageBuilderStateService;
  @Input() public templatePayload: TemplatePayload;
  @Input() public strictReadOnly: boolean = false;
  @Output() invalidTagFormEmitter: EventEmitter<void> = new EventEmitter<void>();

  //public existingTags: TagModel[] = [];

  public templateTypeOptions: FormElementDataModel[] = TEMPLATE_RESTRICTION_TYPES;

  public get isButtonDisabled(): boolean {
    return this.adminTemplatesStateService.isSaveUpdateButtonDisabled;
  }

  public tagsForm: FormGroup = this.formBuilder.group({
    //tagName: ['', [Validators.maxLength(25)]],
    name: ['', [Validators.required, Validators.maxLength(25)]],
    description: ['', [Validators.maxLength(250)]],
    templateType: [TemplateType.Layout, [Validators.required]]
  });

  constructor(
    private formBuilder: FormBuilder,
    private adminTemplatesStateService: AdminTemplatesStateService,
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.templatePayload && changes.templatePayload.currentValue) {
      this.tagsForm.get('name').setValue(this.templatePayload.name);
      this.tagsForm.get('description').setValue(this.templatePayload.description);
      this.tagsForm.get('templateType').setValue(this.templatePayload.templateType);
      //this.existingTags = this.templatePayload.tags;
    }
  }

  public updateTemplate(): void {
    this.tagsForm.markAllAsTouched();
    if (this.tagsForm.invalid) {
      this.invalidTagFormEmitter.emit();
      return;
    }
   /* if (this.tagsForm.get('tagName').value && this.tagsForm.get('tagName').value.toString()) {
      this.onEnterPressed(null);
    }*/
    const payload: TemplatePayload = {
      //tags: this.existingTags,
      name: this.tagsForm.value.name,
      description: this.tagsForm.value.description,
      templateType: this.tagsForm.value.templateType,
    };
    this.ibss.triggerSaveTemplate(payload);
  }

  /*public removeTag(index: number): void {
    this.existingTags.splice(index, 1);
  }*/

  /*public onEnterPressed(event: KeyboardEvent): void {
    const value: string = this.tagsForm.get('tagName').value.toString();
    if (!value || this.tagsForm.get('tagName').invalid) {
      return;
    }
    this.existingTags.push({name: value});
    this.tagsForm.get('tagName').reset();
  }*/

}
