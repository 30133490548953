export enum LanguagesType {
  Arabic = 1,
  Bengali = 2,
  Cantonese = 3,
  Chinese = 4,
  Dutch = 5,
  English = 6,
  French = 7,
  German = 8,
  Hindi = 9,
  Italian = 10,
  Japanese = 11,
  Javanese = 12,
  Korean = 13,
  Lahnda = 14,
  Marathi = 15,
  Netherlands = 16,
  Norsk = 17,
  Polish = 18,
  Portuguese = 19,
  Russian = 20,
  Serbian = 21,
  Spanish = 22,
  Swahili = 23,
  Tamil = 24,
  Telugu = 25,
  Turkish = 26,
  Vietnamese = 27,
  Urdu = 28,
}