import {BaseModelService} from '../base.model.service';
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {AutoTagModel, DeactivateAllTagModel, DeactivateTagModel} from '../../models/tag/deactivate-tag.model';
import { map, mergeMap } from 'rxjs/operators';
import {Paging} from '../../models/paging/paging.model';
import {HttpParams} from '@angular/common/http';
import {PagingHelper} from '../helper/paging.helper';
import {TotalRowsModel} from '../../models/paging/totalrow.model';
import TagModel from 'src/app/models/tag/tag.model';
import GeneralTagReportModel from '../../models/tag/general.tag.report.model';
@Injectable({
  providedIn: 'root'
})
export class TagService extends BaseModelService<TagModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'ClientTag';
  }

  public setDeactivate(data: DeactivateTagModel): Observable<DeactivateTagModel> {
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.post<DeactivateTagModel>(
          `${config.apiURL}/${this.controllerName}/UpdateList`, data
        );
      })
    );
  }

  public setDeactivateAll(data: DeactivateAllTagModel): Observable<DeactivateAllTagModel> {
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.post<DeactivateAllTagModel>(
          `${config.apiURL}/${this.controllerName}/UpdateAll`, data
        );
      })
    );
  }

  public getAutoTags(filter?: Paging): Observable<AutoTagModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.get<AutoTagModel[]>(
          `${config.apiURL}/AuxiliaAutoTags`,
          { params }
        );
      })
    );
  }

  public getTotalAuto(filter?: Paging): Observable<number> {
    const params: HttpParams = PagingHelper.getPagingParams(filter, false);
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        const url = `${config.apiURL}/AuxiliaAutoTags/total`;
        return this.http
          .get<TotalRowsModel>(url, { params })
          .pipe(map(model => model.total));
      })
    );
  }

  public setAutoTags(data: DeactivateTagModel): Observable<DeactivateTagModel> {
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.post<DeactivateTagModel>(
          `${config.apiURL}/AuxiliaAutoTags/AssignAutoTagsList`, data
        );
      })
    );
  }

  public getClientsDonors(filter: Paging): Observable<GeneralTagReportModel[]> {
    const controllerName = 'TagReports';
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    const tagID = filter.filters.find(field => field.field === 'tagID').value;
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.get<GeneralTagReportModel[]>(`${config.apiURL}/${controllerName}/ConcreteTag`, { params })
      })
    );
  }

  public getTotalForTags(filter?: Paging): Observable<number> {
    const controllerName = 'TagReports';
    const params: HttpParams = PagingHelper.getPagingParams(filter, false);
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        const url = `${config.apiURL}/${controllerName}/ConcreteTag/total`;
        return this.http
          .get<TotalRowsModel>(url, { params })
          .pipe(map(model => model.total));
      })
    );
  }
}
