import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges, OnDestroy {
  public subscription: Subscription = new Subscription();
  public currentPage: number = 1;
  public paginationPages$: Observable<number[]>;
  public totalPages: number = 0;
  @Input()
  public entriesPerPageSettings: number[] = [10, 25, 50];
  @Input()
  public totalItems: number = 0;
  @Input()
  public setDefaultPage: Observable<number>;
  @Input()
  public entriesPerPage: number = this.entriesPerPageSettings[0];
  @Input()
  public disabled: boolean = false;
  public isAdminPortal: boolean = false;

  @Input() useSpaceEvenly: boolean = false;

  @Output()
  public entriesPerPageChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public nextPage: EventEmitter<number> = new EventEmitter<number>();

  private emitterSubject: Subject<number> = new Subject<number>();

  constructor(
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.isAdminPortal = this.router.url.includes('/admin-dashboard/');
    if (this.setDefaultPage) {
      this.subscription.add(
        this.setDefaultPage.subscribe((defaultPage: number) => {
          this.currentPage = defaultPage;
          this.setPaginationPages();
        })
      );
    }

    this.subscription.add(
      this.emitterSubject.asObservable().pipe(debounceTime(500)).subscribe(value => this.nextPage.emit(value))
    );
  }

  /** Update pagination body when total is received */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalItems) {
      this.totalPages = this.totalItems > 0 ? Math.ceil(this.totalItems / this.entriesPerPage) : 0;
      this.setPaginationPages();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public setEntriesPerPage(amount: number): void {
    if (this.disabled) return;
    if (this.entriesPerPage === amount) {
      return;
    }
    this.entriesPerPage = amount;
    this.totalPages = this.totalItems ? Math.ceil(this.totalItems / this.entriesPerPage) : 0;
    this.currentPage = 1;
    this.setPaginationPages();
    this.entriesPerPageChanged.emit(this.entriesPerPage);
    this.nextPage.emit(this.currentPage);
  }

  public getRange(): string {
    const {totalPages, totalItems, currentPage, entriesPerPage}: any = this;
    if (totalPages === 0) {
      return `0 - 0`;
    } else if (currentPage === 1 && totalPages === 1) {
      return `1 - ${totalItems}`;
    } else if (currentPage === 1 && totalPages > 1) {
      return `1 - ${entriesPerPage}`;
    } else if (totalPages > 1 && currentPage !== totalPages) {
      return `${(currentPage - 1) * entriesPerPage + 1} - ${currentPage * entriesPerPage}`;
    } else {
      return `${(currentPage - 1) * entriesPerPage + 1} - ${totalItems}`;
    }
  }

  /** Calculate beginning and end of pagination body */
  private setPaginationPages(): void {
    const {totalPages, currentPage}: any = this;
    if (totalPages === 0) {
      this.paginationPages$ = of([]);
    } else if (totalPages <= 5) {
      this.fillPaginationPages(1, totalPages);
    } else if (currentPage <= 3 && totalPages > 5) {
      this.fillPaginationPages(1, 5);
    } else if (currentPage > 3 && currentPage <= totalPages - 2) {
      this.fillPaginationPages(currentPage - 2, currentPage + 2);
    } else if (currentPage > 3 && currentPage > totalPages - 2) {
      this.fillPaginationPages(totalPages - 4, totalPages);
    }
  }

  /** Create array with values for layout */
  private fillPaginationPages(from: number, to: number): void {
    const pages = [];
    for (let i = from; i <= to; i++) {
      pages.push(i);
    }
    this.paginationPages$ = of(pages);
  }

  /** Set new current page */
  public onPageClick(page: number): void {
    if (this.disabled) return;
    if (this.currentPage === page) {
      return;
    }
    this.currentPage = page;
    this.updatePage();
  }

  /** Update values */
  private updatePage(): void {
    this.emitterSubject.next(this.currentPage);
    this.setPaginationPages();
  }

  /** Decrease current page */
  public onPreviousClick(): void {
    if (this.disabled) return;
    if (this.currentPage === 1) {
      return;
    }
    --this.currentPage;
    this.updatePage();
  }

  /** Increase current page */
  public onNextClick(): void {
    if (this.disabled) return;
    if (this.currentPage === this.totalPages) {
      return;
    }
    ++this.currentPage;
    this.updatePage();
  }
}
