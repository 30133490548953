<div class="admin-templates-wrapper">
  <div class="header">
    <div class="header-title-wrapper">
      <div class="title-admin-templates">{{isAdminPortal ? 'Templates' : ('LAYOUT.Templates' | translate)}}</div>
      <app-client-select
        *ngIf="isClientPortal && clientsWithRelationships.length > 1"
        [formGroup]="formGroup"
        name="clientID"
        [selectOptions]="clientsWithRelationships"
        (clientChanged)="clientChanged($event)"
      ></app-client-select>
    </div>

    <div class="header-buttons">
      <ng-container *ngIf="isAdminPortal">

        <app-button
          *ngIf="!isShare&&!isDelete"
          class="mr-2"
          type="flat"
          label="Delete"
          (clickEvent)="onDelete()"
          color="#a9110e"
          width="140"
        ></app-button>

        <app-button
          *ngIf="isDelete"
          class="mr-2"
          type="flat"
          label="Delete Now"
          (clickEvent)="deleteNow()"
          color="#a9110e"
          width="140"
        ></app-button>

        <app-button
          *ngIf="!isShare&&!isDelete"
          class="mr-2"
          type="stroked"
          label="Share"
          (clickEvent)="onShare()"
          color="#0064be"
          width="140"
        ></app-button>

        <app-button
          *ngIf="isShare || isDelete"
          class="mr-2"
          type="stroked"
          label="Cancel"
          (clickEvent)="onCancel()"
          color="#0064be"
          width="140"
        ></app-button>

        <app-button
          *ngIf="isShare"
          class="mr-2"
          type="flat"
          label="Share Now"
          (clickEvent)="shareNow()"
          color="#0064be"
          width="140"
        ></app-button>

      </ng-container>

      <app-button
        *ngIf="!isShare&&!isDelete"
        type="flat"
        label="Create New"
        [disabled]="isMerchantInfoLoading"
        (clickEvent)="showChooseOptionDialog()"
        color="#0064be"
        width="140"
      ></app-button>
    </div>
  </div>

  <div class="main">
    <div class="main-row">
      <div *ngIf="isClientPortal" class="width-350">
        <app-select
          [formGroup]="selectLayoutForm"
          name="templateType"
          (valueChanged)="changeType($event.value)"
          label="Template Type"
          [selectOptions]="hasMerchantAccount ? templateTypeOptions : getTemplatesForNonMerchant()"
        ></app-select>
      </div>
      <div *ngIf="!isShare&&!isDelete">
        <p class="search-title">{{isAdminPortal ? 'Search' : ('Search' | translate)}}</p>
        <div class="admin-templates-header">
          <div class="input-wrapper-templates">
            <input
              type="search"
              placeholder="{{isAdminPortal ? 'By Name, Description, Tag' : ('BUILD.By Name, Description, Tag' | translate)}}"
              (input)="onSearch($event)">
            <i class="fas fa-search magnifier"></i>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="isClientPortal">
      <p class="body-text saved">{{isAdminPortal ? 'Saved Templates' : ('BUILD.Saved Templates' | translate)}}</p>
      <div class="admin-template-wrapper saved">
        <div class="admin-template-inner-wrapper">
          <div
            *ngFor="let template of (savedTemplates$ | async); index as i"
            class="tepmlate-card"
          >
            <div class="image-container" (click)="navigateTo(template)">
              <img
                [src]="getTemplateImgSrc(template.imagePreviewURL)"
                alt="Preview"
              >
              <!--<ng-template #iframeTemplate>

                <div class="mock-img-layout"></div>
                <iframe
                  [width]="getTemplateWidth(template)"
                  [height]="getTemplateWidth(template)"
                  frameborder="0"
                  [id]="'savedTemplate_' + i"
                  [style.transform]="getScaleRate(template)"
                  scrolling="no"
                >{{setContent(template, 'savedTemplate_' + i)}}</iframe>
              </ng-template>-->
            </div>
            <h2 (click)="navigateTo(template)" class="tepmlate-card-title">{{template.name}}</h2>
            <p class="tepmlate-card-body-type-text">{{templateTypeViews[template.templateUsageType]}}</p>
            <p (click)="deleteSaved(template)" class="tepmlate-card-body-delete-text">{{isAdminPortal ? 'Delete' : ('Delete' | translate)}}</p>
          </div>
        </div>
      </div>

      <app-paginator
        [entriesPerPageSettings]="entriesPerPageSettings"
        [totalItems]="savedTemplatesTotal"
        [setDefaultPage]="setSavedTemplatesDefaultPage$"
        [entriesPerPage]="savedTemplatesEntriesPerPage"
        (entriesPerPageChanged)="savedTemplatesEntriesPerPageChanged($event)"
        (nextPage)="savedTemplatesNextPageChanged($event)"
      ></app-paginator>

    </ng-container>


    <p class="body-text mb-5">
      {{isAdminPortal ? 'Auxilia Templates' : ('BUILD.Auxilia Templates' | translate)}}
      <span *ngIf="showSelectedCounts()" class="selected" [innerHTML]="isAdminPortal ? getTextAdmin : 'REPORTS.selected' | translate: {value: showSelectedCounts()}"></span>
    </p>

    <div class="admin-template-wrapper">
      <mat-tab-group
        [selectedIndex]="currentTab"
        (selectedIndexChange)="currentTab = $event"
        mat-align-tabs="start"
        dynamicHeight
      >
        <mat-tab label="{{isAdminPortal ? 'Event' : ('Event' | translate)}}">
          <div class="admin-template-inner-wrapper">
            <div
              *ngFor="let template of (eventTemplates$ | async); index as i"
              class="tepmlate-card"
              [class.card-opacity]="showSelectedCounts() && !templatesEventForm.value[i].id"
            >
              <div
                class="image-container"
                (click)="chooseTemplate(template, i, template.templateUsageType)"
                [class.border-image]="isAdminPortal && templatesEventForm.value.length && templatesEventForm.value[i] && templatesEventForm.value[i].id"
              >
                <img
                  [src]="getTemplateImgSrc(template.imagePreviewURL)"
                  alt="Preview"
                >
                <!--<ng-template #iframeTemplate>
                  <div class="mock-img-layout"></div>
                  <iframe
                    [width]="getTemplateWidth(template)"
                    [height]="getTemplateWidth(template)"
                    frameborder="0"
                    [id]="'eventTemplate_' + i"
                    [style.transform]="getScaleRate(template)"
                    scrolling="no"
                  >{{setContent(template, 'eventTemplate_' + i)}}</iframe>
                </ng-template>-->
              </div>
              <h2 *ngIf="isNewTemplate(template)" class="admin-template-new-marker">{{isAdminPortal ? 'New' : ('BUILD.New' | translate)}}</h2>
              <h2 (click)="navigateTo(template)" class="tepmlate-card-title">{{template.name}}</h2>
              <p class="tepmlate-card-body-text">{{template.description}}</p>
            </div>
          </div>
          <app-paginator
            [entriesPerPageSettings]="eventCampaignEntriesPerPageSettings"
            [totalItems]="eventTemplatesTotal"
            [setDefaultPage]="setEventTemplatesDefaultPage$"
            [entriesPerPage]="eventTemplatesEntriesPerPage"
            (entriesPerPageChanged)="eventTemplatesEntriesPerPageChanged($event)"
            (nextPage)="eventTemplatesNextPageChanged($event)"
          ></app-paginator>
        </mat-tab>

        <mat-tab label="{{isAdminPortal ? 'Campaign' : ('EVENTS.Campaign' | translate)}}">
          <div class="admin-template-inner-wrapper">
            <div
              *ngFor="let template of (campaignTemplates$ | async); index as i"
              class="tepmlate-card"
              [class.card-opacity]="showSelectedCounts() && !templatesCampaignForm.value[i].id"
            >
              <div
                class="image-container"
                (click)="chooseTemplate(template, i, template.templateUsageType)"
                [class.border-image]="isAdminPortal && templatesCampaignForm.value.length && templatesCampaignForm.value[i] && templatesCampaignForm.value[i].id"
              >
                <img
                  [src]="getTemplateImgSrc(template.imagePreviewURL)"
                  alt="Preview"
                >
                <!--<ng-template #iframeTemplate>
                  <div class="mock-img-layout"></div>
                  <iframe
                    [width]="getTemplateWidth(template)"
                    [height]="getTemplateWidth(template)"
                    frameborder="0"
                    [id]="'campaignTemplate_' + i"
                    [style.transform]="getScaleRate(template)"
                    scrolling="no"
                  >{{setContent(template, 'campaignTemplate_' + i)}}</iframe>
                </ng-template>-->
              </div>
              <h2 *ngIf="isNewTemplate(template)" class="admin-template-new-marker">{{isAdminPortal ? 'New' : ('BUILD.New' | translate)}}</h2>
              <h2 (click)="navigateTo(template)" class="tepmlate-card-title">{{template.name}}</h2>
              <p class="tepmlate-card-body-text">{{template.description}}</p>
            </div>
          </div>
          <app-paginator
            [entriesPerPageSettings]="eventCampaignEntriesPerPageSettings"
            [totalItems]="campaignTemplatesTotal"
            [setDefaultPage]="setCampaignTemplatesDefaultPage$"
            [entriesPerPage]="campaignTemplatesEntriesPerPage"
            (entriesPerPageChanged)="campaignTemplatesEntriesPerPageChanged($event)"
            (nextPage)="campaignTemplatesNextPageChanged($event)"
          ></app-paginator>
        </mat-tab>

        <mat-tab label="{{isAdminPortal ? 'Social Media' : ('LAYOUT.Social Media' | translate)}}">

          <div class="admin-template-inner-wrapper">
            <div
              *ngFor="let template of (socialMediaTemplates$ | async); index as i"
              class="tepmlate-card"
              [class.card-opacity]="showSelectedCounts() && !templatesSocialMediaForm.value[i].id"
            >
              <div
                class="image-container"
                (click)="chooseTemplate(template, i, template.templateUsageType)"
                [class.border-image]="isAdminPortal && templatesSocialMediaForm.value.length && templatesSocialMediaForm.value[i] && templatesSocialMediaForm.value[i].id"
              >
                <img [src]="getTemplateImgSrc(template.imagePreviewURL)" alt="Preview">
              </div>
              <h2 *ngIf="isNewTemplate(template)" class="admin-template-new-marker">{{isAdminPortal ? 'New' : ('BUILD.New' | translate)}}</h2>
              <h2 (click)="navigateTo(template)" class="tepmlate-card-title">{{template.name}}</h2>
              <p class="tepmlate-card-body-text">{{template.description}}</p>
            </div>
          </div>
          <app-paginator
            [entriesPerPageSettings]="eventCampaignEntriesPerPageSettings"
            [totalItems]="socialMediaTemplatesTotal"
            [setDefaultPage]="setSocialMediaTemplatesDefaultPage$"
            [entriesPerPage]="socialMediaTemplatesEntriesPerPage"
            (entriesPerPageChanged)="socialMediaTemplatesEntriesPerPageChanged($event)"
            (nextPage)="socialMediaTemplatesNextPageChanged($event)"
          ></app-paginator>

        </mat-tab>
        <mat-tab label="{{isAdminPortal ? 'Forms' : ('BUILD.Forms' | translate)}}" *ngIf="isAdminPortal || (isClientPortal && hasMerchantAccount)">
          <div class="admin-template-inner-wrapper">
            <div
              *ngFor="let template of (donationFormTemplates$ | async); index as i"
              class="tepmlate-card"
              [class.card-opacity]="showSelectedCounts() && !templatesFormsForm.value[i].id"
            >
              <div
                class="image-container"
                (click)="chooseTemplate(template, i, template.templateUsageType)"
                [class.border-image]="isAdminPortal && templatesFormsForm.value.length && templatesFormsForm.value[i] && templatesFormsForm.value[i].id"
              >
                <img *ngIf="template.imagePreviewURL" [src]="getTemplateImgSrc(template.imagePreviewURL)" alt="Preview">
              </div>
              <h2 *ngIf="isNewTemplate(template)" class="admin-template-new-marker">{{isAdminPortal ? 'New' : ('BUILD.New' | translate)}}</h2>
              <h2 (click)="navigateTo(template)" class="tepmlate-card-title">{{template.name}}</h2>
              <p class="tepmlate-card-body-text">{{template.description}}</p>
            </div>
          </div>
          <app-paginator
            [entriesPerPageSettings]="eventCampaignEntriesPerPageSettings"
            [totalItems]="donationFormTemplatesTotal"
            [setDefaultPage]="setDonationFormTemplatesDefaultPage$"
            [entriesPerPage]="donationFormTemplatesEntriesPerPage"
            (entriesPerPageChanged)="donationFormsTemplatesEntriesPerPageChanged($event)"
            (nextPage)="donationFormsTemplatesNextPageChanged($event)"
          ></app-paginator>
        </mat-tab>

        <mat-tab label="{{isAdminPortal ? 'Letters' : ('Letters' | translate)}}">
          <div class="admin-template-inner-wrapper">
            <div
              *ngFor="let template of (letterTemplates$ | async); index as i"
              class="tepmlate-card"
              [class.card-opacity]="showSelectedCounts() && !templatesLettersForm.value[i].id"
            >
              <div
                class="image-container"
                (click)="chooseTemplate(template, i, template.templateUsageType)"
                [class.border-image]="isAdminPortal && templatesLettersForm.value.length && templatesLettersForm.value[i] && templatesLettersForm.value[i].id"
              >
                <img *ngIf="template.imagePreviewURL" [src]="getTemplateImgSrc(template.imagePreviewURL)" alt="Preview">
              </div>
              <h2 *ngIf="isNewTemplate(template)" class="admin-template-new-marker">{{isAdminPortal ? 'New' : ('BUILD.New' | translate)}}</h2>
              <h2 (click)="navigateTo(template)" class="tepmlate-card-title">{{template.name}}</h2>
              <p class="tepmlate-card-body-text">{{template.description}}</p>
            </div>
          </div>
          <app-paginator
            [entriesPerPageSettings]="eventCampaignEntriesPerPageSettings"
            [totalItems]="letterTemplatesTotal"
            [setDefaultPage]="setLetterTemplatesDefaultPage$"
            [entriesPerPage]="letterTemplatesEntriesPerPage"
            (entriesPerPageChanged)="letterTemplatesEntriesPerPageChanged($event)"
            (nextPage)="letterTemplatesNextPageChanged($event)"
          ></app-paginator>
        </mat-tab>
        <mat-tab label="{{isAdminPortal ? 'Sign Up Form' : ('Sign Up Form' | translate)}}">
          <div class="admin-template-inner-wrapper">
            <div
              class="tepmlate-card"
            >
              <div
                class="image-container"
                (click)="goToSignUp()"
                [class.border-image]="isAdminPortal"
              >
                <img [src]="getSignUpTemplateImgSrc()" alt="Preview">
              </div>
              <h2 class="tepmlate-card-title">{{isAdminPortal ? 'Sign Up Form' : ('Sign Up Form' | translate)}}</h2>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <ng-container *ngIf="isAdminPortal">
      <app-clients-templates
        [search$]="searchTemplates.asObservable()"
        [startDate]="getStartDate()"
        [clients]="allClients"
      ></app-clients-templates>
    </ng-container>

    <ng-container *ngIf="isAdminPortal && !isShare && !isDelete">
      <div class="d-flex justify-content-between flex-wrap">
        <div class="body-text">Tag Usage</div>
        <div class="body-text-sort">
          Sort by: <span (click)="setStatisticSortingUsed()" class="most-used"> Most Used </span>
          <i *ngIf="!showUsedArrow" class="fas fa-caret-down"></i>
          <i *ngIf="showUsedArrow" class="fas fa-sort-up"></i>
        </div>
      </div>

      <div>
        <app-admin-templates-table
          [data]="tamplateStatistic"
          [tableDataColumns]="columnNames"
          (sortOutput)="setStatisticSorting($event)"
          (tagName)="searchByTagName($event)"
        ></app-admin-templates-table>
      </div>
      <app-paginator
        [totalItems]="templatesStatisticTotal"
        (nextPage)="getNextTempaltesStatisticPage($event)"
        (entriesPerPageChanged)="setTemplatesStatisticPerPage($event)"
        [setDefaultPage]="setStatisticDefaultPage$"
      ></app-paginator>
    </ng-container>

    <ng-container *ngIf="isAdminPortal && isShare">
      <p class="body-text mb-5">Selected Recipients</p>
      <p class="search-title">Search</p>
      <div class="admin-templates-header">

        <div class="input-wrapper">
          <input
            (input)="onClientSearch($event)"
            type="search"
            placeholder="Search by Organization">
          <i class="fas fa-search magnifier"></i>
        </div>
      </div>

      <div class="campaign-step3-recipients-wrapper">
        <ng-container *ngFor="let client of clients">
          <div class="campaign-step3-recipient">
            <div class="campaign-step3-recipient-abbreviation">
              <span>{{getAbbreviation(client)}}</span>
            </div>
            <div class="campaign-step3-recipient-body">
              <span>{{client.name}}</span>
            </div>
            <div (click)="removeItem(client.id)" class="campaign-step3-recipient-delete">
              <div class="campaign-step3-recipient-minus"></div>
            </div>

          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
