import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TimeZoneInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('api.ipify.org')) {
      request = this.addTimeZoneHeader(request);
    }
    return next.handle(request);
  }

  private addTimeZoneHeader(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        TimeZone: `${new Date().getTimezoneOffset() / -60}`,
        TimeZoneString: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    });
  }
}
