<div
  *ngIf="date"
  class="countdown-wrapper"
>
  <div class="countdown-title">{{'Countdown' | translate}}</div>
  <div class="countdown-time-wrapper">
    <div *ngIf="months > 0"
      class="countdown-time-item"
    >
      <div class="countdown-time-item-value">{{months}}</div>
      <div class="countdown-time-item-label">{{'Months' | translate}}</div>
    </div>
    <div
      *ngIf="days > 0 || months > 0"
      class="countdown-time-item"
    >
      <div class="countdown-time-item-value">{{days}}</div>
      <div class="countdown-time-item-label">{{'Days' | translate}}</div>
    </div>
    <div
      *ngIf="hours > 0 || days > 0 || months > 0"
      class="countdown-time-item"
    >
      <div class="countdown-time-item-value">{{hours}}</div>
      <div class="countdown-time-item-label">{{'Hours' | translate}}</div>
    </div>
    <div
      *ngIf="minutes > 0 || hours > 0 || days > 0 || months > 0"
      class="countdown-time-item"
    >
      <div class="countdown-time-item-value">{{minutes}}</div>
      <div class="countdown-time-item-label">{{'Min' | translate}}</div>
    </div>
    <div
      *ngIf="(sec > 0 || minutes > 0 || hours > 0 || days > 0) && months < 1; else eventEnd"
      class="countdown-time-item"
    >
      <div class="countdown-time-item-value">{{sec}}</div>
      <div class="countdown-time-item-label">{{'Sec' | translate}}</div>
    </div>
    <ng-template #eventEnd>
      <div *ngIf="showShimmerTemplate">
        <ngx-shimmer-loading width="504px" height="100px"></ngx-shimmer-loading>
      </div>
      <span *ngIf="!showShimmerTemplate && showSatusTest" class="event-finished">
        {{statusText | translate}}
      </span>
    </ng-template>


  </div>

</div>
