import { Component, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormElementComponent } from '../base.form.element.component';
//import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['../base.form.element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimePickerComponent extends BaseFormElementComponent implements OnChanges {
  /*public timePickerTheme: NgxMaterialTimepickerTheme = {
    container: {
      buttonColor: '#0064be',
    },
    dial: {
      dialBackgroundColor: '#0064be',
      dialInactiveColor: '#93ccff'
    },
    clockFace: {
      clockFaceTimeInactiveColor: '#0064be',
      clockFaceBackgroundColor: '#e4e7eb',
      clockHandColor: '#0064be'
    }
  };*/

  constructor(public translate: TranslateService) {
    super(translate);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
}
