<div
  class="donation-activity-wrapper"
  [ngStyle]="containerStyles"
>
  <div class="donation-activity-row">
    <div class="donation-activity-title">

      <app-text-tool
        [column]="column"
        [entity]="entity"
        [tms]="tms"
        [landingPageView]="landingPageView"
        [resetDefaultPuddings]="true"
      ></app-text-tool>

      <i *ngIf="!landingPageView" class="fas fa-pen"></i>
    </div>

    <app-button
      *ngIf="showDonateNowButton"
      type="flat"
      [labelColor]="donateButtonColorText"
      color="{{donateButtonColor}}"
      width="160"
      (clickEvent)="donateNow()"
    >{{ donateButtonText}}</app-button>
  </div>

  <app-donation-activity-element
    [donations$]="donations$"
    [entity]="entity"
  ></app-donation-activity-element>

  <div class="action-buttons-row">
    <app-button
      type="stroked"
      [borderColor]="viewAllButtonBorderColor"
      width="190"
      (clickEvent)="viewAll()"
    >
      <div class="button-label">
        <app-list-icon [color]="viewAllButtonTextColor"></app-list-icon>
        <span [style.color]="viewAllButtonTextColor">{{'BUILD.View All' | translate}}</span>
      </div>
    </app-button>

    <app-button
      type="stroked"
      color="#0064be"
      [borderColor]="topDonationsButtonBorderColor"
      width="240"
      (clickEvent)="topDonations()"
    >
      <div class="button-label">
        <app-star-icon [color]="topDonationsButtonTextColor"></app-star-icon>
        <span [style.color]="topDonationsButtonTextColor">{{'BUILD.Top Donations' | translate}}</span>
      </div>
    </app-button>
  </div>
</div>
