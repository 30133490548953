import {
  Component,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  Input,
  ElementRef,
} from '@angular/core';
import { BaseFormElementComponent } from '../base.form.element.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePickerHeaderComponent } from './date-picker-header/date-picker-header/date-picker-header.component';
import { TranslateService } from '@ngx-translate/core';
import { CustomDateAdapter } from '../custom.date.adapter';
import { DatepickerService } from './datepicker.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'MMM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MMM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['../base.form.element.component.scss', '../new.base.form.element.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE,
      MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false }}
  ],
  encapsulation: ViewEncapsulation.None
})
export class DatePickerComponent extends BaseFormElementComponent implements OnChanges {
  @Input() turnOn: boolean = true;
  @Input() startView: 'month' | 'year' | 'multi-year' = 'month';
  @Input() startAt: any = null;
  @Input() isAdmin: boolean = false;
  public calendarHeaderComponent = DatePickerHeaderComponent;
  constructor(
    public translate: TranslateService,
    private datepickerService: DatepickerService,
    private elementRef: ElementRef,
  ) {
    super(translate);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public onOpened(): void {
    if (this.turnOn) {
      this.formControl.disable();
    }
  }

  public onClosed(): void {
    if (this.turnOn) {
      this.formControl.enable();
      if (this.elementRef.nativeElement.getAttribute('name') === 'donationRealDate') {
      document.getElementsByName('donationRealDate')[0].tabIndex = 0;
      this.elementRef.nativeElement.focus();
      }
    }
  }

  public chosenYearHandler() {
    this.datepickerService.startView = 'year';
  }

  public chosenMonthHandler() {
    this.datepickerService.startView = 'month';
  }

  public addEvent(date: string) {
    this.formControl.setValue(date ? date : "");
  }
}
