import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { BACKGROUNDR_REPEAT_OPTIONS, BACKGROUNDR_SIZE_OPTIONS, DonationAskOptions } from '../../../../constants';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { TemplateType } from '../../../../models/templates/template.type';

@Component({
  selector: 'app-row-settings',
  templateUrl: './row-settings.component.html',
  styleUrls: ['./row-settings.component.scss', '../tool-settings.scss']
})
export class RowSettingsComponent implements OnInit, OnDestroy {
  @Input() public tms: TemplateManagementService;
  private subscription: Subscription = new Subscription();

  public backgroundRepeatOptions: FormElementDataModel[] = BACKGROUNDR_REPEAT_OPTIONS;
  public backgroundSizeOptions: FormElementDataModel[] = BACKGROUNDR_SIZE_OPTIONS;
  public inheritOptions: FormElementDataModel[] = DonationAskOptions;
  public imageURL: string = null;

  public sectionForm: FormGroup = this.formBuilder.group({
    backgroundColor: '',
    backgroundRepeat: 'repeat',
    backgroundUrl: '',
    backgroundSize: 'auto',
    paddingBottom: ['0', [Validators.min(0)]],
    paddingLeft: ['0', [Validators.min(0)]],
    paddingRight: ['0', [Validators.min(0)]],
    paddingTop: ['0', [Validators.min(0)]],
    inheritSection: [false]
  });

  constructor(
    private formBuilder: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.setInitialValues();

    this.subscription.add(
      this.tms.activeRowEmitter.subscribe((row: MjmlElementModel) => {
        if (!row) {
          return;
        }
        this.setInitialValues();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setInitialValues(): void {
    const backgroundColor = this.tms.activeRow.attributes['background-color'] || 'transparent';
    const backgroundRepeat = this.tms.activeRow.attributes['background-repeat'] || 'repeat';
    const backgroundUrl = this.tms.activeRow.attributes['background-url'] || '';
    const backgroundSize = this.tms.activeRow.attributes['background-size'] || 'auto';
    const paddingBottom = this.tms.activeRow.attributes['padding-bottom'] || '0px';
    const paddingLeft = this.tms.activeRow.attributes['padding-left'] || '0px';
    const paddingRight = this.tms.activeRow.attributes['padding-right'] || '0px';
    const paddingTop = this.tms.activeRow.attributes['padding-top'] || '0px';
    const inheritSection = !!this.tms.activeRow.attributes['inherit-section'];

    this.sectionForm.get('backgroundColor').setValue(backgroundColor);
    this.sectionForm.get('backgroundRepeat').setValue(backgroundRepeat);
    this.sectionForm.get('backgroundUrl').setValue(backgroundUrl);
    this.sectionForm.get('backgroundSize').setValue(backgroundSize);
    this.sectionForm.get('paddingBottom').setValue(paddingBottom.slice(0, -2));
    this.sectionForm.get('paddingLeft').setValue(paddingLeft.slice(0, -2));
    this.sectionForm.get('paddingRight').setValue(paddingRight.slice(0, -2));
    this.sectionForm.get('paddingTop').setValue(paddingTop.slice(0, -2));
    this.sectionForm.get('inheritSection').setValue(inheritSection);
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public get isEventTemplate(): boolean {
    return !!this.tms.eventForm;
  }

  public inheritSectionChanged(value): void {
    this.removeInheritSection();
    this.tms.activeRow.attributes['inherit-section'] = value ? '1' : '';
    this.tms.emitSave(true);
  }

  public removeInheritSection(): void {
    this.tms.template.children[1].children[0].children.forEach((section: MjmlElementModel) => {
      if (section.attributes['inherit-section']) {
        section.attributes['inherit-section'] = '';
      }
    });
  }

  public imageUrlChanged(url: string): void {
    this.imageURL = null;
    this.onBackgroundUrlChanged();
  }

  public editImage(): void {
    this.imageURL = this.sectionForm.get('backgroundUrl').value;
  }

  public cancelEditImage(): void {
    this.imageURL = null;
  }

  public onBackgroundColorChanged(): void {
    const value = this.sectionForm.get('backgroundColor').value;
    this.tms.activeRow.attributes['background-color'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onBackgroundUrlChanged(): void {
    const value = this.sectionForm.get('backgroundUrl').value;
    this.tms.activeRow.attributes['background-url'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onBackgroundRepeatChanged(): void {
    const value = this.sectionForm.get('backgroundRepeat').value;
    this.tms.activeRow.attributes['background-repeat'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onBackgroundSizeChanged(): void {
    const value = this.sectionForm.get('backgroundSize').value;
    this.tms.activeRow.attributes['background-size'] = value ? value : 'auto';
    this.tms.emitSave(true);
  }

  public onPaddingTopChanged(): void {
    const value = this.sectionForm.get('paddingTop').value;
    this.tms.activeRow.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.sectionForm.get('paddingRight').value;
    this.tms.activeRow.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.sectionForm.get('paddingBottom').value;
    this.tms.activeRow.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.sectionForm.get('paddingLeft').value;
    this.tms.activeRow.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }
}
