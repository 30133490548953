import { Directive, HostBinding, Input } from '@angular/core';
import { SocialPostStatusType } from '../models/enum/social.post.status.type';
const COLORS = {
  [SocialPostStatusType.Archived]: '#3a3b3f',
  [SocialPostStatusType.Draft]: '#c9c562',
  [SocialPostStatusType.PendingApproval]: '#93ccff',
  [SocialPostStatusType.Published]: '#20a643',
  [SocialPostStatusType.RequireUpdates]: '#a9110e',
  [SocialPostStatusType.Scheduled]: '#d5990c',
  [SocialPostStatusType.Error]: '#f44336'
};
@Directive({
  selector: '[appBackgroundColor]'
})
export class BackgroundColorDirective {
  @Input()
  private status: SocialPostStatusType;

  @HostBinding('style.background')
  get getStatusColor(): string {
    return COLORS[this.status];
  }

}
