import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplateUsageType } from 'src/app/models/templates/template.usage.type';
import { DialogData } from 'src/app/routes/admin/admin-confirmation/admin-confirmation.component';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-choose-option',
  templateUrl: './choose-option.component.html',
  styleUrls: ['./choose-option.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChooseOptionComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  constructor(
    public dialogRef: MatDialogRef<ChooseOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
  ) {}

  public choice: TemplateUsageType = this.data.isDonationForm
    ? TemplateUsageType.DonationForms
    : this.data.formElementData && this.data.formElementData[0].value;

  public ngOnInit(): void {
    this.subscriptions.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onClose(reset: boolean = false): void {
    if (!reset) {
      this.dialogRef.close(this.choice);
    } else {
      this.dialogRef.close(null);
    }
  }
}
