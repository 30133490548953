import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ClientIdStateService } from '../../../../services/client.module.state/client.id.state.service';
import { DashboardService } from '../../../../services/dashboard/dashboard.service';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { DonationSourceType } from 'src/app/models/enum/donation.source.type';
import { EventDonationWidgetModel, WidgetDataModel } from '../../../../models/event/event-donation-widget.model';
import { FilterType } from '../../../../models/enum/filter.type';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Paging } from '../../../../models/paging/paging.model';
import { Router } from '@angular/router';
import { TopDonationsSourceType } from '../../../../models/enum/top.donations.source.type';
import FormElementDataModel from '../../../../models/form.element.data.model';

@Component({
  selector: 'app-top-donations',
  templateUrl: './top-donations.component.html',
  styleUrls: ['top-donations.component.scss', '../widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopDonationsComponent implements OnInit, OnDestroy {
  private apiURL: string = localStorage.getItem('apiurl');
  private subscription: Subscription = new Subscription();
  public campaigns: WidgetDataModel[] = [];
  public donors: WidgetDataModel[] = [];
  public events: WidgetDataModel[] = [];
  public socialMediaPosts: WidgetDataModel[] = [];
  public socialWidgetModel: EventDonationWidgetModel = null;
  public topDonationsSourceType = TopDonationsSourceType;

  public form: FormGroup = new FormGroup({
    selectedSource: new FormControl(TopDonationsSourceType.All)
  });
  public selectOptions: FormElementDataModel[] = [
    { label: 'All Sources', value: TopDonationsSourceType.All },
    { label: 'Campaigns', value: TopDonationsSourceType.Campaign },
    { label: 'Donors', value: TopDonationsSourceType.Donor },
    { label: 'Events', value: TopDonationsSourceType.Event },
    { label: 'Social Media Posts', value: TopDonationsSourceType.SocialMedia },
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    private clientIdStateService: ClientIdStateService,
    private dashboardService: DashboardService,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.subscription.add(
      this.clientIdStateService.clientIdObservable
        .pipe(
          distinctUntilChanged(),
          switchMap((clientId: string) => this.getData(clientId)),
          tap((response: EventDonationWidgetModel) => {
            this.socialWidgetModel = response;
            this.setValues();
          })
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getData(clientId: string): Observable<EventDonationWidgetModel> {
    this.socialWidgetModel = null;
    this.onFilterChanged();
    const widgetFilter: Paging = {
      first: 0,
      rows: 5,
      includeDependencies: false,
      filters: [
        {
          field: 'clientID',
          value: clientId,
          type: FilterType.Equal
        }
      ]
    };
    return this.dashboardService.getTopDonationWidget(widgetFilter);
  }

  private setValues(): void {
    this.events = [];
    this.donors = [];
    this.campaigns = [];
    this.socialMediaPosts = [];
    this.socialWidgetModel.data.forEach((item: WidgetDataModel) => {
      switch (item.type) {
        case DonationSourceType.Event:
          this.events.push(item);
          break;
        case DonationSourceType.Campaign:
          this.campaigns.push(item);
          break;
        case DonationSourceType.DirectClient:
          this.donors.push(item);
          break;
        case DonationSourceType.SocialPost:
          this.socialMediaPosts.push(item);
      }
    });
    this.onFilterChanged();
  }

  public isSourceShown(type: TopDonationsSourceType): boolean {
    return this.selectedSource === type || this.selectedSource === TopDonationsSourceType.All;
  }

  public navigateToPage(type: TopDonationsSourceType, id: string): void {
    let url;
    switch (type) {
      case TopDonationsSourceType.Event:
        url = `/clients/events/dashboard/${id}`;
        break;
      case TopDonationsSourceType.SocialMedia:
        url = `/clients/social-media/post-dashboard/${id}`;
        break;
      case TopDonationsSourceType.Donor:
        url = `/clients/donors/${id}`;
        break;
      case TopDonationsSourceType.Campaign:
        url = `/clients/campaigns/dashboard/${id}`;
        break;
    }
    this.router.navigateByUrl(url);
  }

  public getDonorImage(item: WidgetDataModel): string {
    return item.image ? `${this.apiURL}/AzureFileStorage/image/${item.image}` : '/assets/images/auxilia-logo@1x.png'
  }

  private get selectedSource(): TopDonationsSourceType {
    const control = this.form.get('selectedSource');
    return control ? control.value : TopDonationsSourceType.All;
  }

  public onFilterChanged(): void {
    this.cdr.markForCheck();
  }

  public viewTopSources(): void {
    this.router.navigate([`clients/reports/donors/top-donations-by-source`]);
  }
}