import { Injectable } from '@angular/core';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { StripeCardNumberComponent, StripeFactoryService, StripeInstance } from 'ngx-stripe';
import { MerchantService } from '../../services/payments/merchant.service';
import { Observable } from 'rxjs';
import { StripeIntentModel } from '../../models/payments/stripe.intent.model';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MerchantModel } from '../../models/payments/merchant.model';
import { environment } from '../../../../src/environments/environment';
import {TranslateService} from "@ngx-translate/core";
import { BaseStripeService } from './base-stripe.service';

@Injectable()
export class AppStripeService extends BaseStripeService {
  public stripe: StripeInstance;
  private card: StripeCardNumberComponent;
  public cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        color: '#0064be',
        fontWeight: '300',
        fontFamily: 'Roboto, Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#afafaf',
        },
      },
    },
  };
  public elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  constructor(
    private merchantService: MerchantService,
    toastrService: ToastrService,
    private stripeFactory: StripeFactoryService,
    private translate: TranslateService,
  ) {
    super(toastrService);
  }

  public configureStripe(clientId: string): void {
    this.merchantService.getModelByClientId(clientId)
      .pipe(
        first(),
        map((merchant: MerchantModel) => merchant.accountId),
        tap((accountId: string) => this.initStripeInstance(accountId))
      )
      .subscribe();
  }

  public initStripeInstance(stripeAccount: string): void {
	this.stripe = this.stripeFactory.create(environment.stripeApiKey, { stripeAccount });
  }

  public startStripePaymentRequest(email: string, clientID: string, amount: number, errorCallback: (err) => void): void {
    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.card.element
    })
      .pipe(
        first(),
        tap((paymentMethodResponse: any) => {
          if (paymentMethodResponse.error) {
            errorCallback(paymentMethodResponse.error);
            return;
          } else {
            this.paymentMethod = paymentMethodResponse.paymentMethod.id;
          }
        }),
        switchMap((paymentMethodResponse: any) => this.createPaymentIntent(email, clientID, amount, this.paymentMethod)),
        tap((paymentIntent: StripeIntentModel) => {
            this.intentID = paymentIntent.intentID;
            this.customerID = paymentIntent.customerID;
          }
        ),
        switchMap((paymentIntent: StripeIntentModel) => this.stripe.confirmCardSetup(paymentIntent.token)),
        tap((confirmResponse: any) => {
          this.checkPaymentIntentResponse(confirmResponse);
        })
      )
      .subscribe();
  }

  public createPaymentIntent(email: string, clientID: string, ammount: number, paymentMethod: string): Observable<StripeIntentModel> {
    return this.merchantService.stripeInitializePayment({email, clientID, ammount, paymentMethod});
  }

  public setCard(card: StripeCardNumberComponent): void {
    this.card = card;
  }

  public checkPaymentIntentResponse(paymentIntent: any): void {
    if (paymentIntent.error) {
      this.onInvalidPaymentIntent(paymentIntent.error.message);
    } else if (paymentIntent.setupIntent) {
      this.onValidPaymentIntent(paymentIntent.setupIntent);
    }
  }

  private onValidPaymentIntent(paymentIntent: any): void {
    this.paymentIntent.next(paymentIntent);
    this.triggerSaveOrUpdate.next();
  }
}
