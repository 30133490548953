import {ClientDonorModel} from '../../models/client/client.donor.model';
import {ClientDonorStatus} from '../../models/enum/client.donor.status';
import {ClipboardService} from 'ngx-clipboard';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AddonTypes,
  DonationMethodsViews,
  DonationStatusTypes,
  DonorSourceViews,
  DonorStatusViews,
  giftTypes,
  GiveTypesViews,
  PlatformPostsNames,
  StatusPostsViews,
  UserRolesTypeViews
} from 'src/app/constants';
import {ErrorLogModel} from '../../models/admin/error-log.model';
import {formatDate} from '@angular/common';
import {HealthCheckupModel} from '../../models/admin/health-checkup.model';
import {HealthCheckUpStatusEnum} from '../../models/enum/health-checkUp-status.enum';
import {includes, toLower} from 'lodash';
import {InfoLogModel} from 'src/app/models/reports/info.log.model';
import {ItemizedDonationModel} from 'src/app/models/reports/itemized.donation.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatTooltip} from '@angular/material/tooltip';
import {PaymentRecurrencyStatus} from '../../models/enum/payment.recurrency.status';
import {PaymentRepeatType} from 'src/app/models/enum/payment.repeat.type';
import {TaxReturnTemplateModel} from '../../models/reports/tax-return-template.model';
import {ToastrService} from 'ngx-toastr';
import ColumnFilterModel from '../../models/filter-sort/column.filter.model';
import ColumnFilterOptionsModel from '../../models/filter-sort/column.filter.options.model';
import DonorEmployeeModel from 'src/app/models/donor/donor.employee.model';
import EventTicketModel from 'src/app/models/event/event-ticket.model';
import FilterSortModel from '../../models/filter-sort/filter.sort.model';
import RecurringSettingsModel from '../../models/payments/recurring.settings.model';
import SendGridLogModel from '../../models/reports/send.grid.log.model';
import SocialPostModel from 'src/app/models/socialMedia/social.post.model';
import TemplateModel from 'src/app/models/templates/template.model';
import GeneralTagReportModel from '../../models/tag/general.tag.report.model';
import {RecurringDonationsReportModel} from '../../models/reports/recurring.donations.report.model';
import {SoftCreditsReportModel} from '../../models/reports/p2p.soft.credits.report.model';
import {Router} from '@angular/router';
import {SortOrder} from 'src/app/models/enum/sort.order';
import {IconsType} from 'src/app/models/enum/icons.type';
import {UtilsComponent} from '../utils.component';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {P2PEventRegistrationActivityModel} from 'src/app/models/reports/p2p-event-registration-activity.model';
import {P2PActivitySummaryModel} from 'src/app/models/reports/p2p-activity-summary.model';
import {PageType} from '../../models/enum/page.type'
import {DonationOrganizationType} from "../../models/enum/donation.organization.type";
import {StateModel} from "../../models/state.model";
import {DonorActivityType} from "../../models/enum/donor-activity-type.enum";
import {DonorActivityLogModel} from "../../models/donor/donor-activity-log.model";
import {DonationMethodType} from "../../models/enum/donation.method.type";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-reports-table',
  templateUrl: './reports-table.component.html',
  styleUrls: ['./reports-table.component.scss']
})
export class ReportsTableComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public data: (
    | SendGridLogModel[]
    | ClientDonorModel[]
    | DonorEmployeeModel[]
    | SocialPostModel[]
    | ErrorLogModel[]
    | HealthCheckupModel[]
    | TaxReturnTemplateModel[]
    | ItemizedDonationModel[]
    | RecurringSettingsModel[]
    | EventTicketModel[]
    | GeneralTagReportModel[]
    | RecurringDonationsReportModel[]
    | SoftCreditsReportModel[]
    | DonorActivityLogModel[]
  )[] = [];
  @Input() public tableDataColumns: string[] = [];
  @Input() public filterSortConfig: FilterSortModel;
  @Input() public columnsFilterOptions: ColumnFilterOptionsModel[] = [];
  @Input() public initialSelection: any;
  @Input() public fieldToCopy = '';
  @Input() public isWithBorder: boolean = false;
  @Input() public getStyle: { [key: string]: string };
  @Input() public bulkSelecting: boolean = false;
  @Input() public total: number;
  @Input() public allDataIdsProvider$: Observable<any[]> = new Observable<any[]>()
  @Input() public clickableColumnNames: string[];
  @Input() public selectedDataRows$: BehaviorSubject<any[]>;
  @Input() public disabled: boolean;
  @Input() public filterableColumns: string[];
  @Input() public sortableColumns: string[];
  @Input() public states: StateModel[] = [];
  @Output() public passInfo: EventEmitter<InfoLogModel | DonorEmployeeModel | SocialPostModel> = new EventEmitter<InfoLogModel | DonorEmployeeModel | SocialPostModel | HealthCheckupModel>();
  @Output() public filterChanged: EventEmitter<ColumnFilterModel> = new EventEmitter<ColumnFilterModel>();
  @Output() public sort: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onCellClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onResendEmailsClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onGeneratePDFClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() private onRemoveReport: EventEmitter<string> = new EventEmitter<string>();
  public isAdmin: boolean = false;

  public tableColumns: string[] = [];
  public tableSource: MatTableDataSource<
    | SendGridLogModel[]
    | ClientDonorModel[]
    | DonorEmployeeModel[]
    | SocialPostModel[]
    | ErrorLogModel[]
    | HealthCheckupModel[]
    | TaxReturnTemplateModel[]
    | ItemizedDonationModel[]
    | RecurringSettingsModel[]
    | EventTicketModel[]
    | GeneralTagReportModel[]
    | RecurringDonationsReportModel[]
    | SoftCreditsReportModel[]
    | P2PEventRegistrationActivityModel[]
    | P2PActivitySummaryModel[]>;

  public SortOrder = SortOrder;
  public IconsType = IconsType;
  private subscription: Subscription = new Subscription();

  private static matchClickedLinks(url: string): string {
    if (url.includes('attachments')) {
      return 'Attachment';
    } else if (url.includes('payments')) {
      return 'Donation Button';
    } else if (url.includes('registrationEvent')) {
      return 'Register Button';
    } else if (url.includes('unsubscribe')) {
      return 'Unsubscribe';
    } else if (url.includes('instagram')) {
      return 'Instagram';
    } else if (url.includes('facebook')) {
      return 'Facebook';
    } else if (url.includes('twitter')) {
      return 'Twitter';
    } else if (url.includes('linkedin')) {
      return 'Linkedin';
    } else if (url.includes('fundraising')) {
      return 'Create Fundraiser';
    } else {
      return url;
    }
  }

  constructor(
    private clipboardService: ClipboardService,
    private toastrService: ToastrService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) { }

  public ngOnInit(): void {
    this.isAdmin = this.router.url.includes('/admin-dashboard/');
    if(this.tableDataColumns && this.bulkSelecting) {
      this.tableColumns = ['isSelected', ...this.tableDataColumns, 'reportActions']
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableDataColumns) {
      this.tableColumns = this.tableDataColumns && [...this.tableDataColumns];
    }
    if (changes.data && this.data) {
      this.tableSource = new MatTableDataSource<
        | SendGridLogModel[]
        | ClientDonorModel[]
        | DonorEmployeeModel[]
        | SocialPostModel[]
        | ErrorLogModel[]
        | HealthCheckupModel[]
        | TaxReturnTemplateModel[]
        | ItemizedDonationModel[]
        | RecurringSettingsModel[]
        | EventTicketModel[]
        | GeneralTagReportModel[]
        | RecurringDonationsReportModel[]
        | SoftCreditsReportModel[]
        | P2PEventRegistrationActivityModel[]
        | P2PActivitySummaryModel[]
        >(this.data);
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getColumnTitle(column: string): string {
    const columnTitle = {
      bouncedDate: 'Bounced Date',
      buttonClicked: '"Unsubscribe"/"Register"',
      campaignName: 'Campaign Name',
      createdByUserName: 'Creator',
      createdOn: 'Creation Date',
      dateClicked: 'Date clicked',
      deliveredDate: 'Delivered Date',
      donorName: 'Donor Name',
      email: 'Email',
      alternativeEmail1: 'First Alternative Email',
      alternativeEmail2: 'Second Alternative Email',
      alternativeEmail3: 'Third Alternative Email',
      alternativeEmail4: 'Fourth Alternative Email',
      emailDate: 'Email Date',
      eventName: 'Event Name',
      firstOpenDate: 'Open Date',
      organization: 'Organization',
      reason: 'Reason',
      spamDate: 'SPAM Date',
      status: 'Status',
      unsubscribedDate: 'Unsubscribed Date',
      url: 'Button Clicked',

      // for report activity...
      clientName: 'Organization',
      customInfo: 'Details',
      loggedOnDate: 'Date & Time',
      message: 'Activity Type',
      userName: 'User',

      // registration activity
      regDate: 'Registration Date',
      buyerFullName: 'Ticket Purchaser',
      mobile: 'Phone Number',
      additionalNotes: 'Additional Notes',
      includes: 'Add-On',
      addons: 'Add-On Type',
      ticketPackageName: 'Ticket Type/Ticket Package',
      ticketPublicId: 'Ticket Number',
      additionalGuestName: 'Additional Guest Name',
      totalCost: 'Ticket Cost',
      paymentMethod: 'Payment Method',
      registrationPaymentMethod: 'Payment Method',
      paymentCreditType: 'Payment Method',
      registrationPaymentStatus: 'Payment Status',
      paymentStatus: 'Payment Status',
      donationStatus: 'Payment Status',

      // feature use
      actions: ' ',
      clipboard: ' ',
      isSelected: ' ',
      frequency: 'Frequency',
      userRole: 'User Role',
      reportActions: ' ',

      // donor employee
      donorSource: 'Source',
      donorStatus: 'Status',
      firstName: 'First Name',
      lastName: 'Last Name',

      // social posts
      name: 'Social Media Post Name',
      postStatus: 'Status',
      providers: 'Platform',

      // logs
      machineName: 'Machine name',
      serviceEnvironment: 'Service environment',
      serviceName: 'Service name',
      type: 'Exception type',

      // health check
      checkDate: 'Check date',
      lastError: 'Last error',
      lastErrorDate: 'Last error date',
      serviceStatus: 'Service status',
      updateAction: ' ',

      //donations report
      date: 'Date',
      deductibleAmount: 'Deductible Amount',
      note: 'Note',
      payment: 'Payment',
      receivedAmount: 'Received amount',

      // tax return report
      isAutoEmail: 'Email donors',
      isDonorPortalLink: 'Available link for donor',
      reportDescription: 'Report Description',
      reportName: 'Report Name',
      year: 'Year of report',

      // itemized donation report
      address: 'Address',
      donationAmmount: 'Total Donation Amount',
      donationRealDate: 'Date of Contribution',
      donationRealDateFund: 'Donation Date',
      federalIDNumber: 'Federal ID Number',
      feeAmmount: 'Fee Amount',
      fullAmmount: 'Gross Donation Amount',
      netDonationAmount: "Net Donation Amount",
      fullName: 'Full Name',
      isDonorPaidFee: 'Is Donor Paid Fee',
      organizationName: 'Organization',
      organizationalType: 'Organizational Type',
      occupation: 'Occupation',
      employer: 'Employer',
      employerAddress: 'Employer Address',
      donationType: 'Recurring',
      avgDonationAmount: 'Avg Donation Amount',
      disclaimers: 'Disclaimers',

      //RECURRING DONATIONS report
      lastDonationDate: 'Last Donation Date',
      nextExecutionDate: 'Next Donation Date',
      ammount: 'Donation Amount',
      isFeeIncluded: 'Donor Paid Fees',
      grossAmount: 'Gross Donation Amount',
      reccurrency: 'Frequency',
      reccurrencyValue: 'Date',
      maxCount: 'Number of Periods',
      recurrencyStatusEnum: 'Status',
      clientID: 'Organization',

      //Allocation Fund Donation Report
      allocatedFund: 'Allocated Fund',
      isAnonymous: 'Anonymous',

      //Itemized/Allocation Fund Donation Report
      donationMethod: 'Donation Method',
      giftName: 'Recipient',
      giftType: 'Gift Type',
      giveType: 'Give Type',
      templateName: 'Donation Form',

      //tag reports
      totalDonationAmount: 'Total Donation Amount',
      countryName: 'Country',
      stateName: 'State',
      city: 'City',
      startDate: 'Start Date',
      endTagDate: 'End Date',
      donorFullName: 'Donor',
      clientDonorID: 'Donor ID',
      street: 'Street',
      zipCode: 'Zip Code',
      phone: 'Phone',
      id: 'ID',
      tagName: 'Tag name',
      lastDonationAmount: 'Last Donation Amount',
      default: column,

      // Soft Credits Donations Report
      influencerFirstName: "Direct Influencer First Name",
      influencerLastName: "Direct Influencer Last Name",
      indirectInfluencerFirstName: "Indirect Influencer First Name",
      indirectInfluencerLastName: "Indirect Influencer Last Name",
      donationDate: "Donation Date",
      donationAmount: "Donation Amount",
      recurring: "Recurring",
      sourceName: "Source",
      donorFirstName: "Donor First Name",
      donorLastName: "Donor Last Name",
      checkNumber: "Check Number",
      depositDate: "Deposit Date",
      description: "Description",
      anonymous: "Anonymous",
      comments: "Comments",

      // Soft Credits Summary Report
      donationsSum: "Soft Credit Donations",
      donorsCount: "Donors",

      // P2P Registration Activities
      registrationDate: "Registration Date",
      buyerFirstName: "Ticket Purchaser First Name",
      buyerLastName: "Ticket Purchaser Last Name",
      phoneNumber: "Phone Number",
      addOnType: "Add-On Type",
      addOn: "Add-On",
      additionalGuestsNames: "Additional Guest Name",
      ticketPackage: "Ticket Type/Ticket Package",
      ticketNumber: "Ticket Number",
      ticketCost: "Ticket Cost",
      pageType: "Page Type",
      p2PName: "Name of P2P",

      // P2P Registration Activity Summary
      fundraiserName: "Fundraiser Name",
      registrations: "Total Registrations",
      tickets: "Total Tickets",
      totalAmount: "Total Amount",
      donorPaidFeeAmount: "Donor Paid Fee Amount",

      // Donor Activity History
      details: 'Details',
      activityType: 'Activity Type'

    };
    return columnTitle[column] || columnTitle['default'];
  }

  public getColumnStyle(column:string):{[key: string]: string} {
    switch (column) {
      case "netDonationAmount":
      case "donorPaidFeeAmount":
        return {"min-width": "130px"}
      case "isDonorPaidFee":
      case "fullAmmount":
        return {"min-width": "100px"}
      default :
        return null
    }
  }

  private getDateWithOffset(date: Date): Date {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  }

  public getSelectOptions(column: string): ColumnFilterOptionsModel {
    return this.columnsFilterOptions?.find(
      options => options.columnName === column
    );
  }

  public selectedOptions(event: ColumnFilterModel): void {
    this.filterChanged.emit(event);
  }

  public isActiveFilter(column: string): boolean {
    if (!this.filterSortConfig) {
      return false;
    } else {
      if (column === 'ticketPackageName') {
        column = 'ticket.TicketPackage.Name';
      }
      const { columnFilters }: FilterSortModel = this.filterSortConfig;
      return !!columnFilters.find(({ field }: ColumnFilterModel) => field === column);
    }
  }

  public getTextNode(element, column): string {
    switch (column) {
      case 'emailDate':
      case 'deliveredDate':
      case 'bouncedDate':
      case 'unsubscribedDate':
      case 'dateClicked':
      case 'spamDate':
      case 'registrationDate':
      case 'createdOn':
        return element[column]
          ? formatDate(new Date(element[column]), 'mediumDate', 'en-US')
          : '';
      case 'zipCode':
        return element[column] ? element[column].length > 5 ? element[column].replace(/(\d{5})/, "$1-") : element[column] : '';
      case 'donationRealDateFund':
      case 'donationRealDate':
        return element[column] ? formatDate(element[column], 'mediumDate', 'en-US') : '';
      case 'checkDate':
      case 'firstOpenDate':
      case 'lastErrorDate':
      case 'regDate':
        return element[column]
          ? formatDate(new Date(element[column]), 'short', 'en-US')
          : '';
      case 'loggedOnDate': return element[column] ? formatDate(new Date(element[column] + 'Z'), 'short', 'en-US'): '';
      case 'lastDonationDate':
      case 'nextExecutionDate':
        return element[column]
          ? formatDate(element[column], 'MM/dd/yyyy', 'en-US')
          //? formatDate(element[column], 'short', 'en-US')
          : '';
      case 'endDate':
      case 'startDate':
        return element[column]
          ? formatDate(element[column], 'MM/dd/yyyy', 'en-US')
          : '';
      case 'endTagDate':
        return element[column]
          ? formatDate(element[column].split('T')[0], 'shortDate', 'en-US')
          : 'N/A';
      case 'eventName':
        return element[column] === 'No Event' ? '' : element[column];
      case 'recurrency':
        return PaymentRepeatType[element[column]];
      case 'recurrencyStatusEnum':
        return element[column] === (PaymentRecurrencyStatus.Completed || PaymentRecurrencyStatus.CancelledAll) ? 'Completed' : 'Active';
      case 'status':
        if(element[column] === ClientDonorStatus.Active){
          return 'Active';
        }else if(element[column] === ClientDonorStatus.DoNotContact){
          return 'Do Not Contact';
        }else if(element[column] === ClientDonorStatus.Archived){
          return 'Archived';
        }
        return '';
      case 'userRole':
        const stringRole = UserRolesTypeViews[element[column]];
        return stringRole ? stringRole : element[column];
      case 'donorStatus':
        return element[column] ? DonorStatusViews[element[column]] : 'not set';
      case 'giveType':
        return element[column] ? GiveTypesViews[element[column]] : '';
      case 'donationMethod':
      case 'registrationPaymentMethod':
        return element[column] || element[column] === 0 ? this.translate.instant(DonationMethodsViews[element[column]]) : '';
      case 'paymentCreditType':
        return element[column] === 1 ? DonationMethodsViews[DonationMethodType.CreditCard] : DonationMethodsViews[DonationMethodType.ACH]
      case 'donorSource':
        return element[column] ? DonorSourceViews[element[column]] : 'unknown';
      case 'providers':
        return PlatformPostsNames[element[column]];
      case 'postStatus':
        return StatusPostsViews[element[column]];
      case 'serviceStatus':
        return element[column] === HealthCheckUpStatusEnum.Ok ? 'OK' : 'Error';
      case 'isAnonymous':
      case 'isDonorPaidFee':
        return element[column] ? 'Yes' : 'No';
      case 'isFeeIncluded':
        return element[column] ? 'Y' : 'N';
      case 'url':
        return ReportsTableComponent.matchClickedLinks(element[column]);
      case 'donationAmmount':
      case 'donationAmount':
      case 'feeAmmount':
      case 'feeAmount':
      case 'fullAmmount':
      case 'totalDonationAmount':
      case 'lastDonationAmount':
      case 'avgDonationAmount':
      case 'donationsSum':
        return '$ ' + this.convertCash(element, column);
      case 'ammount':
        return '$ ' + this.convertCash(element, column);
      case 'grossAmount':
        return '$ ' + this.convertCash(element, column);
      case 'giftType':
        return element[column] ? giftTypes[element[column]] : '';
      case 'organizationName':
      case 'organization':
        return element.organizationName || element.organization;
      case 'additionalNotes':
        return element.participantEventComment ? element.participantEventComment : element.additionalNotes;
      case 'ticketPurchaser':
        return element.buyerFullName;
      case 'includeType':
        return AddonTypes[element[column]];
      // case 'totalCost':
      //   return element[column] !== undefined ? element[column] : element.ticketPackage?.price;
      case 'addons':
        return element[column];
      case 'alternativeEmail1':
        return element['alternativeEmails'][0]?.email ? element['alternativeEmails'][0]?.email : '';
      case 'alternativeEmail2':
        return element['alternativeEmails'][1]?.email ? element['alternativeEmails'][1]?.email : '';
      case 'alternativeEmail3':
        return element['alternativeEmails'][2]?.email ? element['alternativeEmails'][2]?.email : '';
      case 'alternativeEmail4':
        return element['alternativeEmails'][3]?.email ? element['alternativeEmails'][3]?.email : '';
      case 'pageType':
        return this.translate.instant(PageType[element['pageType']].toString());
      case 'maxCount':
        return element[column] === 0 ? '' : element[column];
      case 'netDonationAmount':
        return `$ ${element[column]}`
      case 'donorPaidFeeAmount':
        return `$ ${element[column]}`
      case 'disclaimers':
        return element[column]?.length > 0 ? 'Yes': 'No'
      case 'employer' :
        return element['donor']?.employer?.employer ?? element[column] // some models have employer in different places
      case 'occupation':
        return element['donor']?.employer?.occupation
      case 'employerAddress':
        const adjustData:any = (obj:any) => !!obj ? obj + ',' : '';
        const getState = (state: number) => !!state ? this.states.find(({ id }: StateModel) => id === state)?.shortName + ',' : ''
        if(!!element['donor']?.employer) {
          const {address1,address2,city,state,zipCode} = element['donor']?.employer;
          return `${adjustData(address1)} ${adjustData(address2)} ${adjustData(city)} ${getState(+state)} ${zipCode ? UtilsComponent.formatPostCode(zipCode) : ""}`
        }
        return ""
      case 'organizationalType':
        return element['organizationType'] ? this.getOrganizationalType(element) : ""
      case 'activityType':
        return element[column] ? this.getActivityType(+element[column]) : ""
      case 'donationStatus':
      case 'registrationPaymentStatus':
        return !!DonationStatusTypes[element[column]] ? this.translate.instant(DonationStatusTypes[element[column]]) : ''
      default:
        return element[column];
    }
  }

  private getActivityType(type: DonorActivityType):string {
    switch (type) {
      case DonorActivityType.RecurringCanceled:
        return "Recurring Canceled"
      case DonorActivityType.RecurringRestarted:
        return "Recurring Restarted"
      case DonorActivityType.RecurringCreated:
        return "Recurring Created"
      case DonorActivityType.RecurringPaymentOptionEdited:
        return "Recurring Payment Option Edited"
      case DonorActivityType.RecurringScheduleEdited:
        return "Recurring Schedule Edited"
      default:
        return "Unknown"
    }
  }

  private convertCash(element, column): string {
    const isFractional = element[column].toString().split('').some(char => char === '.');
    return isFractional ? (Math.round((element[column]) * 100) / 100).toFixed(2) : element[column].toString()
  }

  public navigateTo(val: TemplateModel): void {
    this.passInfo.emit(val);
  }

  private getOrganizationalType(element:ItemizedDonationModel): string {
    switch (element.organizationType) {
      case DonationOrganizationType.Partnership:
        return "Partnership"
      case DonationOrganizationType.LLC:
        return "LLC"
      case DonationOrganizationType.SoleProprietor:
        return "Sole Proprietor"
      case DonationOrganizationType.Other:
        return element.organizationTypeName
      default:
        return "Unknown"
    }

  }

  public copyToClipboard(element: string) {
    if (!!element[this.fieldToCopy].length) {
      this.clipboardService.copy(element[this.fieldToCopy]);
      this.toastrService.success(`${this.getColumnTitle(this.fieldToCopy)} copied to clipboard successfully`)
    } else {
      this.clipboardService.copy(element);
      this.toastrService.success('Object copied to clipboard successfully')
    }
  }

  public isUpdateEvent(element): boolean {
    return !!(!!element.message && includes(toLower(element.message), 'update') && (includes(toLower(element.message), 'campaign') || includes(toLower(element.message), 'event')));
  }

  public getServiceStatus(element): boolean {
    return element.serviceStatus === HealthCheckUpStatusEnum.Ok
  }

  public isChecked(element, column): boolean {
    return element[column];
  }

  public getCustomInfo(element, column?): string {
    if (column === 'additionalGuestName')
      return element.additionalGuestName;
    if (column === 'additionalNotes') {
      return element.participantEventComment;
    }
    if (column === 'templateName') {
      return element[column];
    }
    if (column === 'additionalGuestName') {
      return element[column];
    }
    if (column === 'includes') {
      return element[column];
    }
    if (column === 'addons') {
      return element[column];
    }
    return element.customInfo && !this.isUpdateEvent(element) ? element.customInfo : '';
  }

  public mouseOver(tooltip: MatTooltip) {
    tooltip.disabled = true;
  }

  public toggleTooltip(tooltip: MatTooltip, toggle: HTMLTableCellElement) {
    if (tooltip.disabled) {
      tooltip.disabled = false;
      if (toggle.scrollWidth > toggle.offsetWidth) {
        tooltip.show();
      }
    } else {
      tooltip.hide();
      tooltip.disabled = true;
    }
  }

  public mouseLeave(tooltip: MatTooltip) {
    tooltip.hide();
    tooltip.disabled = true;
  }

  public sortTable(column: string): void {
    this.filterSortConfig.sortField = column;
    this.filterSortConfig.sortOrder = UtilsComponent.nextSortOrder(this.filterSortConfig, column);
    this.sort.next();
  }

  public checkColumn(column: string): boolean {
    return this.filterSortConfig?.sortField === column;
  }

  public getSortOrder(column: string): SortOrder {
    return this.filterSortConfig.sortField === column ? this.filterSortConfig.sortOrder : null;
  }

  public handleChange(event: MatCheckboxChange, element: any): void {
    const currSelectedDataRows: any[] = this.selectedDataRows$.getValue();
    if(event.checked) {
      currSelectedDataRows.push(element);
    } else {
      const index = currSelectedDataRows.findIndex(item => item.id === element.id)
      currSelectedDataRows.splice(index, 1);
    }
    this.selectedDataRows$.next(currSelectedDataRows);
  }

  public isSelectAllChecked(): boolean {
    if(this.selectedDataRows$.getValue()?.length === this.total) return true;
    return false;
  }

  public isSelectAllIndeterminate(): boolean {
    if(
      !!this.selectedDataRows$.getValue()?.length &&
      this.selectedDataRows$.getValue()?.length !== this.total
    ) return true;
    return false;
  }

  public isRowChecked(element: any): boolean {
    if(this.selectedDataRows$.getValue().filter(item => item.id === element.id).length) {
      return true;
    } else return false;
  }

  public handleHeaderChange(event: MatCheckboxChange): void {
    if(event.checked) {
      this.subscription.add(
        this.allDataIdsProvider$
          .subscribe(res => {
            this.selectedDataRows$.next(res);
          })
      )
    } else {
      this.selectedDataRows$.next([]);
    }
  }

  public isCellClickable(columnName: string): boolean {
    return !!this.clickableColumnNames?.find(name => name === columnName);
  }

  public handleCellClick(column: string, element: TaxReturnTemplateModel): void {
    if(!this.isCellClickable(column)) return;
    this.onCellClick.emit(element);
  }

  public generatePDF(id: string, report_name: string, report_date: string):void {
    this.onGeneratePDFClick.emit({id: id, name: report_name, report_date: report_date});
  }
  public resendEmails(id: string, donors_count: any):void {
    this.onResendEmailsClick.emit({id: id, donors_count: donors_count});
  }

  public removeReport(report: any): void {
    this.onRemoveReport.emit(report);
  }

  checkColumnsWithFilters(currColumn: string): boolean {
    return !!this.filterableColumns?.find((column: string) => currColumn === column);
  }

  checkSortableColumns(currColumn: string): boolean {
    return !!this.sortableColumns?.find((column: string) => currColumn === column);
  }
}
