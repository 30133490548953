import {
  AfterViewChecked,
  Component,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Renderer2, SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { LayerModel } from '../../../../../models/image-builder/layer.model';
import { QuillEditorComponent } from 'ngx-quill';
import { FormBuilder, FormControl } from '@angular/forms';
import { ImageBuilderStateService } from '../../../image-builder.state.service';
import { Subscription } from 'rxjs';
import LayerIndexModel from '../../../../../models/image-builder/layer.index.model';

/*Set Quill configuration in one place: TextToolComponent*/

@Component({
  selector: 'app-text-renderer',
  templateUrl: './text-renderer.component.html',
  styleUrls: ['./text-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextRendererComponent implements OnInit, OnDestroy, AfterViewChecked, OnChanges {
  @Input() public layer: LayerModel;
  @Input() public ibss: ImageBuilderStateService;
  @Input() public static: boolean = true;
  @Input() public layerIndex: number;

  public valueControl: FormControl = this.formBuilder.control('');
  private subscription: Subscription = new Subscription();
  public isLayerActive: boolean = false;

  @ViewChild(QuillEditorComponent) public quillEditor: QuillEditorComponent | any;

  public modules: /*QuillModules*/ any = {
    clipboard: {
      allowed: {
        tags: ['b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span'],
        attributes: ['href', 'rel', 'target'/*, 'class'*/],
      },
      keepSelection: false,
    },
    toolbar: [],
  };

  constructor(
    private formBuilder: FormBuilder,
    private renderer: Renderer2
  ) {
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.ibss.activeLayerIndexObservable.subscribe((indexModel: LayerIndexModel) => {
        if (!indexModel) {
          this.isLayerActive = false;
        } else {
          const {index, activate}: LayerIndexModel = indexModel;
          if (index === null) {
            this.isLayerActive = false;
          } else {
            this.isLayerActive = this.layerIndex === index && activate;
          }
        }
      })
    );
    this.subscription.add(
      this.valueControl.valueChanges.subscribe((value: string) => {
        if (!value) {
          value = '';
        }
        this.layer.content = value;
      })
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.layer && changes.layer.currentValue) {
      this.valueControl.setValue(this.layer.content || '');
    }
  }

  public ngAfterViewChecked(): void {
    if (this.quillEditor) {
      const element = (this.quillEditor as any).elementRef.nativeElement.firstChild.firstChild;
      const textAlign = this.layer.attributes['align'] || 'center';
      this.renderer.setStyle(element, 'text-align', textAlign);
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get styles(): any {
    if (!this.layer) {
      return null;
    }
    const {
      color = '#000000',
      fontFamily = 'Arial',
      fontSize = '40',
      fontStyle = 'normal',
      fontWeight = 'normal',
      textDecoration = 'none',
    } = this.layer.attributes;
    return {
      height: `${this.layer.height}px`,
      width: `${this.layer.width}px`,
      color,
      fontFamily,
      fontSize: `${fontSize}px`,
      fontStyle,
      fontWeight,
      textDecoration
    };
  }

  public get viewStyles(): any {
    if (!this.layer) {
      return null;
    }
    const {
      color = '#000000',
      fontFamily = 'Arial',
      fontSize = '40',
      fontStyle = 'normal',
      fontWeight = 'normal',
      textDecoration = 'none',
      align = 'center'
    } = this.layer.attributes;
    return {
      height: `${this.layer.height}px`,
      width: `${this.layer.width}px`,
      color,
      'font-family': fontFamily,
      'font-size': `${fontSize}px`,
      'font-style': fontStyle,
      'font-weight': fontWeight,
      'text-decoration': textDecoration,
      'text-align': align,
    };
  }

  public get readOnly(): boolean {
    return this.ibss.readOnly;
  }

  public onEditorCreated(event): void {
    event.focus();
  }

  public get isUnderline(): boolean {
    if (!this.layer) {
      return false;
    }
    return this.layer.attributes.textDecoration === 'underline';
  }

  public get isOverline(): boolean {
    if (!this.layer) {
      return false;
    }
    return this.layer.attributes.textDecoration === 'overline';
  }

  public get isLineThrough(): boolean {
    if (!this.layer) {
      return false;
    }
    return this.layer.attributes.textDecoration === 'line-through';
  }

}
