import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { CampaignPreviewComponent } from './shared/components/campaign-preview/campaign-preview.component';
import { CommonModule } from '@angular/common';
import { ConfigService } from './services/appconfig.service';
import { ConfirmRegistrationComponent } from './routes/auth/confirm-registration/confirm-registration.component';
import { EmbedPaymentComponent } from './components/embed-payment/embed-payment.component';
import { GetConfigServiceFactory } from './shared/service';
import { HomeComponent } from './routes/auth/home/home.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { LayoutComponent } from './layout/layout.component';
import { LayoutPreviewComponent } from './shared/components/layout-preview/layout-preview.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { NewPasswordComponent } from './routes/auth/new-password/new-password.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgModule } from '@angular/core';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { OutlookAuthConfig } from './models/externalServices/outlookAuth.config';
import { PasswordResetComponent } from './routes/auth/password-reset/password-reset.component';
import { PublishEventComponent } from './components/publish-event/publish-event.component';
import { RegisterClientFormComponent } from './routes/auth/register-client-form/register-client-form.component';
import { SharedModule } from './shared/shared.module';
import { SignUpAsDonorComponent } from './components/sign-up-as-donor/sign-up-as-donor.component';
import { TemplateTagStatisticsTableComponent } from './components/template-tag-statistics-table/template-tag-statistics-table.component';
import { TimeZoneInterceptor } from './interceptors/timezone.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { UnsubscribeCampaignComponent } from './shared/components/unsubscribe-campaign/unsubscribe-campaign.component';
import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { I18nInterceptor } from './interceptors/i18n.interceptor';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { SmImageCropperComponent } from './components/sm-image-cropper/sm-image-cropper.component';
import { CustomLoader } from './translate.custom.loader';
import { environment } from '../environments/environment';
import { P2pStaticPagePreviewDialogComponent } from './components/p2p-static-page/p2p-static-page-preview-dialog/p2p-static-page-preview-dialog.component';
import { PublishFundraiserComponent } from './components/publish-fundraiser/publish-fundraiser.component';
import { CreateFundraiserComponent } from './components/create-fundraiser/create-fundraiser.component';

registerLocaleData(localeEs, 'es')

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
}

const fbLoginOptions = {
  scope: 'email,pages_show_list,instagram_basic,read_insights,pages_read_engagement,pages_manage_posts,pages_read_user_content,business_management',
  return_scopes: true,
  enable_profile_selector: true,
  version: 'v9.0'
};

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: OutlookAuthConfig.clientId,
      authority: 'https://login.microsoftonline.com/common',
      // redirectUri: `https://dev.theauxilia.com/`,
      redirectUri: window.location.origin + '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['Calendars.ReadWrite']
    },
    loginFailedRoute: '/login-failed'
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['Calendars.ReadWrite']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

/*
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
*/

@NgModule({
  declarations: [
    AppComponent,
    CampaignPreviewComponent,
    ConfirmRegistrationComponent,
    EmbedPaymentComponent,
    HomeComponent,
    LayoutComponent,
    LayoutPreviewComponent,
    NewPasswordComponent,
    PasswordResetComponent,
    PublishEventComponent,
    RegisterClientFormComponent,
    SignUpAsDonorComponent,
    TemplateTagStatisticsTableComponent,
    UnsubscribeCampaignComponent,
    SmImageCropperComponent,
    P2pStaticPagePreviewDialogComponent,
    PublishFundraiserComponent,
    CreateFundraiserComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    MsalModule,
    OAuthModule.forRoot(),
    SharedModule,
    SocialLoginModule,
    ToastrModule.forRoot({ extendedTimeOut: 10000 }),
    NgIdleKeepaliveModule.forRoot(),
    HammerModule,
    /*TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })*/
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeZoneInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: I18nInterceptor, multi: true},
    { provide: OAuthStorage, useValue: localStorage },
    {
      provide: ConfigService,
      deps: [HttpClient],
      useFactory: (http: HttpClient) => GetConfigServiceFactory(http)
    },
    OAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.FacebookAppId, fbLoginOptions)
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
