import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { BaseFormElementComponent } from '../base.form.element.component';
import { MatSliderChange } from '@angular/material/slider';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['../base.form.element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SliderComponent extends BaseFormElementComponent implements OnChanges {
  @Input() public max: number = 1;
  @Input() public min: number = 0;
  @Input() public step: number = 0.5;
  @Input() public tickInterval: number | 'auto' = 'auto';
  @Input() public displayWith: string;

  constructor(public translate: TranslateService) {
    super(translate)
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public emitSliderValue(event: MatSliderChange): void {
    const value = event.value;
    this.formControl.patchValue(value);
    this.valueChanged.emit(value);
  }

}
