import {BasePagingComponent} from 'src/app/components/paginator/base.paging.component';
import {CLIENT_DONATIONS_COLUMNS_DONOR} from '../../constants';
import {ClientAllocatedFundModel} from 'src/app/models/client/client-allocated-fund.model';
import {ClientAllocatedFundService} from 'src/app/services/client/client-allocated-fund.service';
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DonationMethodType} from '../../models/enum/donation.method.type';
import {DonationStatusType} from '../../models/enum/donation.status.type';
import {EventDonationModel} from '../../models/event/event-donation.model';
import {EventDonationsService} from '../../services/events/event-donations.service';
import {Filter} from '../../models/paging/filter.model';
import {FilterType} from '../../models/enum/filter.type';
import {LazyLoadRequestModel} from '../../models/filter-sort/lazy.load.search.model';
import {Observable, Subscription} from 'rxjs';
import {Paging} from '../../models/paging/paging.model';
import {Router} from '@angular/router';
import {SortOrder} from '../../models/enum/sort.order';
import ColumnFilterModel from 'src/app/models/filter-sort/column.filter.model';
import ColumnFilterOptionsModel from 'src/app/models/filter-sort/column.filter.options.model';
import FilterSortModel from 'src/app/models/filter-sort/filter.sort.model';
import {first, map, switchMap, tap} from 'rxjs/operators';
import {ClientModuleService} from 'src/app/routes/clients/client.module.service';
import {ClientType} from 'src/app/models/enum/client.type';
import {UtilsComponent} from '../utils.component';
import {ClientService} from "../../services/client/client.service";
import FormElementDataModel from "../../models/form.element.data.model";

@Component({
  selector: 'app-history-donations',
  templateUrl: './history-donations.component.html',
  styleUrls: ['./history-donations.component.scss']
})
export class HistoryDonationsComponent extends BasePagingComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public clientDonorID: string = '';
  @Input() public isCreateNewEnable: boolean = true;
  @Input() public isDonorPortal: boolean = false;
  @Input() public clientID: string;
  private fields: string[] = ['donationStatus', 'donationMethod', 'isAnonymous', 'origin', 'allocatedFundId', 'tierName'];
  private searchByNameFilter: Filter[] = [];
  private subscription: Subscription = new Subscription();
  public allocatedFunds: ClientAllocatedFundModel[] = [];
  public columnsFilterOptions: ColumnFilterOptionsModel[] = [];
  public eventDonations: EventDonationModel[] = [];
  public filter: Filter[] = [];
  public tableColumns: string[] = CLIENT_DONATIONS_COLUMNS_DONOR;
  public filterSortConfig: FilterSortModel = {
    sortField: 'donationRealDate',
    sortOrder: SortOrder.Descending,
    columnFilters: []
  };
  public distinctData: any = {
    sourceValue: {items: [], total: 0}
  };

  constructor(private clientAllocatedFundService: ClientAllocatedFundService, private eventDonationsService: EventDonationsService, private router: Router, private clientModuleService: ClientModuleService, private clientService: ClientService) {
    super();
  }

  public ngOnInit(): void {
    if(!this.isDonorPortal){
      this.clientModuleService.client.subscribe(client => {
        this.tableColumns = client.clientType === ClientType.PAC
              ? [...CLIENT_DONATIONS_COLUMNS_DONOR, 'federalIDNumber',   'organizationName'] : [...CLIENT_DONATIONS_COLUMNS_DONOR];
      });
    }
    else{
      this.tableColumns = ['clientName', ...CLIENT_DONATIONS_COLUMNS_DONOR];
      this.fields = ['donationStatus', 'donationMethod', 'isAnonymous', 'origin', 'allocatedFundId', 'tierName', 'clientID']
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.clientDonorID && changes.clientDonorID.currentValue) {
      this.getDonations();
      this.getTotal();
    }
    if (changes.clientID && changes.clientID.currentValue) {
      this.getAllocatedFunds()
        .pipe(
          switchMap(() => this.getDistinctValues())
        )
        .subscribe();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getDonations(): void {
    const paging: Paging = {
      first: this.getFirstItemOnPage(),
      rows: this.entriesPerPage,
      includeDependencies: true,
      filters: this.filters,
      sortField: this.filterSortConfig.sortField,
      sortOrder: this.filterSortConfig.sortOrder,
      selectFields: ['publicID', 'donationAmmount', 'donationRealDate', 'donationType', 'donationMethod', 'donationSource', 'donationStatus', 'origin', 'isAnonymous', 'organizationName', 'federalIDNumber', 'id', 'giftType', 'sourceValue', 'reccuringId', 'allocatedFundId', 'tierName', 'createdOn', 'clientID']
    };
    this.eventDonationsService.getModelList(paging)
      .pipe(
        first()
      )
      .subscribe((eventDonations: EventDonationModel[]) => {
        this.eventDonations = eventDonations;
        console.log(this.eventDonations);
      });
  }

  private getTotal(): void {
    this.eventDonationsService.getTotal({
      includeDependencies: false,
      filters: this.filters
    })
      .pipe(
        first()
      )
      .subscribe((total: number) => {
        this.total = total;
      });
  }

  public onAddDonationClick(): void {
    this.router.navigateByUrl(`/clients/contributions/add/${this.clientDonorID}`);
    //this.showAddForm.emit();
  }

  public onSortChanged(event: FilterSortModel): void {
    this.filterSortConfig.sortField = event.sortField;
    this.filterSortConfig.sortOrder = event.sortOrder;
    this.getDonations();
  }

  private get tableFilter(): Filter[] {
    if (!this.filterSortConfig.columnFilters.length) {
      return [];
    } else {
      return this.filterSortConfig.columnFilters.map((columnFilter: ColumnFilterModel) => {
        if (columnFilter.field === 'isAnonymous') {
          return {
            field: columnFilter.field,
            value: columnFilter.values.length > 1 ? '' : columnFilter.values[0],
            type: FilterType.Equal
          };
        } else {
          if(columnFilter.values.findIndex(val => (!val) && val !== 0) !== -1) {
           columnFilter.values = columnFilter.values.filter(val => !!val);
            columnFilter.values.push(...["null", ""]);
          }
          const values = columnFilter.values.map((item: string) => `"${item}"`).join(',');
          return {
            field: columnFilter.field === 'clientName' ? 'clientID': columnFilter.field,
            value: `array[${values}]`,
            type: FilterType.Equal
          };
        }
      });
    }
  }

  private getAllocatedFunds(): Observable<ClientAllocatedFundModel[]> {
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: true,
      filters: [{
        field: 'clientId',
        type: FilterType.Equal,
        value: this.clientID
      }]
    };
    return this.clientAllocatedFundService.getModelList(paging)
      .pipe(
        first(),
        tap(response => this.allocatedFunds = response)
      );
  }

  private async onDistinctValuesReceived(distinctValues: any): Promise<void> {

    for (const column of this.fields) {
      const optionsList = distinctValues[column];
      let optionsPromise;

      if (column === 'donationStatus') {
        optionsPromise = Promise.resolve(optionsList.map((option: number) => {
          const status = DonationStatusType[option].toString();
          return {
            label: status ? status : option,
            value: option
          };
        }));
      } else if (column === 'donationMethod') {
        optionsPromise = Promise.resolve(optionsList.map((option: number) => {
          const status = DonationMethodType[option].toString();
          return {
            label: status ? status : option,
            value: option
          };
        }));
      } else if (column === 'isAnonymous') {
        optionsPromise = Promise.resolve(optionsList.map((option: boolean) => ({
          label: option ? 'Yes' : 'No',
          value: option
        })));
      } else if (column === 'allocatedFundId') {
        optionsPromise = Promise.resolve(optionsList.map((option: string) => {
          const fund = this.allocatedFunds.find(({id}) => id === option);
          return {label: fund ? fund.name : 'N/A', value: option};
        }));
      } else if (column === 'clientID') {
        optionsPromise = this.getClientOptions(distinctValues[column]).toPromise();
      } else {
        optionsPromise = Promise.resolve(optionsList.map((option: string) => ({
          label: option ? option : 'N/A',
          value: option
        })));
      }

      const options = await optionsPromise;
      this.columnsFilterOptions.push({
        columnName: column === 'clientID' ? 'clientName' : column,
        options: UtilsComponent.makeNaFirst(options)
      });
    }
  }

  private getClientOptions(clientIds: string[]): Observable<any> {
    const paging: Paging = {
      includeDependencies: false,
      filters: [{
        field: 'id',
        value: `array${JSON.stringify(clientIds)}`,
        type: FilterType.Equal
      }]
    }
    return this.clientService.getModelList(paging).pipe(
      map(val => val.map(c => {
      return (new Object({label: c.name, value: c.id}) as FormElementDataModel)
    })
      )
    )
  }

  private getDistinctValues(): Observable<any> {
    const paging: Paging = {
      includeDependencies: false,
      filters: this.filters,
      selectFields: this.fields
    };
    return this.eventDonationsService.getDistinctValues(paging)
      .pipe(
        tap(this.onDistinctValuesReceived.bind(this))
      );
  }

  public onCurrentFilterChanged(columnFilter: ColumnFilterModel): void {
    this.filterSortConfig.columnFilters = UtilsComponent.updateColumnFilters(columnFilter, this.filterSortConfig);
    this.onFilterChanged();
    this.getDonations();
    this.getTotal();
  }

  public getNextPage(page: number): void {
    this.nextPage(page, () => this.getDonations());
  }

  public setEntriesPerPage(amount: number): void {
    this.entriesPerPageChanged(amount, () => this.getDonations());
  }

  private get filters(): Filter[] {
    return [
      ...this.searchByNameFilter,
      ...this.tableFilter,
      {
        field: 'donorID',
        value: this.clientDonorID,
        type: FilterType.Equal
      }
    ];
  }

  public getOptions(searchModel: LazyLoadRequestModel): void {
    const result = [];
    const paging: Paging = {
      includeDependencies: false,
      selectFields: [searchModel.field],
      first: searchModel.first,
      rows: searchModel.rows,
      filters: [
        {
          field: searchModel.field,
          value: searchModel.searchValue,
          type: FilterType.Contains
        },
        {
          field: 'donorID',
          value: this.clientDonorID,
          type: FilterType.Equal
        }
      ]
    };
    this.subscription.add(
      this.eventDonationsService.getDistinctValues(paging).subscribe(response => {
        response[searchModel.field].forEach((item: string) => result.push({label: item === '' || item === null || item === undefined ? 'N/A' : item, value: item}));
        this.distinctData[searchModel.field].items = UtilsComponent.makeNaFirst(result);
        this.distinctData[searchModel.field].total += response[searchModel.field].length + 1;
      })
    );
  }
}
