<div class="history-donations">
  <div class="history-donations__table-wrapper">
    <app-history-event-attended-table
      [data]="eventParticipants"
      [tableDataColumns]="tableColumns"
      [columnsFilterOptions]="columnsFilterOptions"
      [filterSortConfig]="filterSortConfig"
      (filterChanged)="onCurrentFilterChanged($event)"
      (sortChanged)="onSortChanged($event)"
    ></app-history-event-attended-table>
  </div>
  <app-paginator
    [totalItems]="total"
    (nextPage)="getNextPage($event)"
    (entriesPerPageChanged)="setEntriesPerPage($event)"
    [setDefaultPage]="setDefaultPage$"
  ></app-paginator>
</div>

