import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[appBarHeight]'
})
export class BarHeightDirective {
  @Input('appBarHeight')
  private percentage: number;

  @HostBinding('style.height')
  get getHeight(): string {
    return this.percentage * 100 + '%';
  }

}
