import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-simple-checkbox',
  templateUrl: './simple-checkbox.component.html',
  styleUrls: ['./simple-checkbox.component.scss']
})
export class SimpleCheckboxComponent implements OnInit {
  @Input() public value: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public main: boolean = false;
  @Output() public clickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public stroke: string = '#C5D0DE';
  public fill: string = '#0064be';

  constructor() { }

  ngOnInit(): void {
  }

  public onMouseEnter(): void {
    this.stroke = '#798594';
    this.fill = '#00498A';
  }

  public onMouseLeave(): void {
    this.stroke = '#C5D0DE';
    this.fill = '#0064be';
  }

  public onClick(value: boolean): void {
    this.clickEvent.emit(value);
  }

}
