import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from '../base.model.service';
import TransactionReviewModel from '../../models/payments/transaction.review.model';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransactionReviewService extends BaseModelService<TransactionReviewModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }
  protected get controllerName(): string {
    return 'TransactionReview/review';
  }

  public GetRecurringSettingsScheduleById(recurringSettingsId: string): Observable<TransactionReviewModel[]> {
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.get<TransactionReviewModel[]>(`${config.apiURL}/TransactionReview/GetRecurringSettingsScheduleById/${recurringSettingsId}`);
      })
    );
  }
}
