<div class="image-builder-styling-content">
  <div class="image-builder-styling-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="textForm"
        label="Color"
        name="color"
        [readOnly]="readOnly"
        (colorChanged)="onColorChanged()"
      ></app-email-color-picker>
    </div>
    <div class="max-width-145">
      <app-select
        label="Font family"
        [formGroup]="textForm"
        name="fontFamily"
        errorMassage="Font family"
        [selectOptions]="fontFamilyOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontFamilyChanged()"
      ></app-select>
    </div>
  </div>

  <div class="image-builder-styling-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="textForm"
        name="fontSize"
        label="Font size (px)"
        [min]="20"
        [max]="200"
        [step]="2"
        [tickInterval]="10"
        (valueChanged)="onFontSizeChanged()"
        [disabled]="readOnly"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-select
        label="Font style"
        [formGroup]="textForm"
        name="fontStyle"
        errorMassage="Font style"
        [selectOptions]="fontStyleOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontStyleChanged()"
      ></app-select>
    </div>
  </div>

  <div class="image-builder-styling-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Font Weight"
        [formGroup]="textForm"
        name="fontWeight"
        errorMassage="Font Weight"
        [selectOptions]="fontWeightOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontWeightChanged()"
      ></app-select>
    </div>
    <div class="max-width-145">
      <app-select
        label="Decoration"
        [formGroup]="textForm"
        name="textDecoration"
        errorMassage="Decoration"
        [selectOptions]="textDecorationOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onTextDecorationChanged()"
      ></app-select>
    </div>
  </div>

  <div class="image-builder-styling-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Position"
        [formGroup]="textForm"
        name="align"
        errorMassage="Position"
        [selectOptions]="alignOptions"
        [low]="true"
        [disabled]="readOnly || themedTemplate"
        (valueChanged)="onAlignChanged()"
      ></app-select>
    </div>
  </div>
</div>

