import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import Donations from '../../models/donations.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Donor from '../../models/donor.model';

@Component({
  selector: 'app-widget-table',
  templateUrl: './widget-table.component.html',
  styleUrls: ['./widget-table.component.scss']
})
export class WidgetTableComponent implements OnInit, OnChanges {
  @Input()
  public data: any[] = [];
  @Input()
  public tableDataColumns: string[] = [];

  public tableColumns: string[] = [];
  public tableSource: MatTableDataSource<Donations>;

  @Output()
  public rowClicked: EventEmitter<Donor> = new EventEmitter<Donor>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor() { }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    /** Change table columns */
    if (changes.tableDataColumns) {
      this.tableColumns = [...this.tableDataColumns, 'settings'];
    }
    if (changes.data && this.data) {
      this.tableSource = new MatTableDataSource<Donations>(this.data);
      this.tableSource.paginator = this.paginator;
    }
  }

  public selectRow(row: Donor): void {
    this.rowClicked.emit(row);
  }

}
