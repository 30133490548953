<div class="report-wrapper">
  <div class="report-header">
    <div class="report-title-wrapper">
      <span class="report-title">{{isAdmin ? 'AUDIT REPORTS' : ('REPORTS.AUDIT REPORTS' | translate)}}</span>
      <span class="event-dashboard-back-link" (click)="getBackNavigate()">
        {{isAdmin ? backNavigateLabel : (backNavigateLabel | translate)}} </span>
    </div>
    <app-client-select
      *ngIf="!isSuperAdminOrAuxiliaAdmin && clientsWithRelationships.length > 1"
      [formGroup]="formGroup"
      name="clientID"
      [selectOptions]="clientsWithRelationships"
      (clientChanged)="clientChanged($event)"
    ></app-client-select>
  </div>
  <div class="report-body">
    <span class="report-body-title">{{isAdmin ? (pageTitle | uppercase) : ('REPORTS.' + pageTitle | translate | uppercase)}}</span>
    <div *ngIf="!userRoleFromRoute" style="display: flex;justify-content: space-between;">
      <div class="report-body-filters">
        <!-- Start Date Input-Datepicker -->
        <div class="max-length-215">
          <app-date-picker [formGroup]="filterForm" name="startDate" label="Start from" errorMassage="Start Date"
                           placeholder="MM / DD / YYYY" (timeValueChanged)="onStartDateChanged($event)"></app-date-picker>
        </div>

        <!-- End Date Input-Datepicker -->
        <div class="max-length-215">
          <app-date-picker [formGroup]="filterForm" name="endDate" label="Date to" errorMassage="End Date" placeholder="MM / DD / YYYY"
                           (timeValueChanged)="onEndDateChanged($event)"></app-date-picker>
        </div>

        <ng-container *ngIf="isSuperAdminOrAuxiliaAdmin">
          <div class="max-length-350" *ngIf="organizations$ | async as organizationOptions">
            <app-select [isAdmin]="isAdmin" [formGroup]="filterForm" name="organizationName" label="Organization" errorMassage="Organization"
                        [multiSelectOptions]="organizationOptions" (valueChanged)="onOrgChanged($event.value)"></app-select>
          </div>
          <div class="max-length-350">
            <app-select [isAdmin]="isAdmin" [formGroup]="filterForm" name="mode" label="Mode" errorMassage="Mode" [selectOptions]="modeOptions"
                        (valueChanged)="onModeChanged($event.value)"></app-select>
          </div>
        </ng-container>

        <div class="max-length-350">
          <app-select [isAdmin]="isAdmin" [formGroup]="filterForm" name="activity" label="Activity Type" errorMassage="Activity"
                      [multiSelectOptions]="multiActivityOptions" (valueChanged)="onActivityChanged($event.value)"></app-select>
        </div>

      </div>
      <div style="display:flex;align-items:flex-end;">
        <app-button label="Export" (clickEvent)="exportReport()" type="flat" color="#0064be"></app-button>
      </div>
    </div>

    <span *ngIf="userRoleFromRoute" class="report-body-total">{{isAdmin ? 'Activity Type' : ('REPORTS.Activity Type' | translate) }}: {{messageFromRoute}}, {{isAdmin ? 'User role' : ('REPORTS.User role' | translate) }}:
      {{userRolesTypeViews[userRoleFromRoute]}}</span>
    <span *ngIf="total$ | async" class="report-body-total">{{isAdmin ? 'TOTAL' : ('REPORTS.TOTAL' | translate)}} - {{total}}</span>

    <div class="report-body-table">
      <div class="table-wrapper" *ngIf="infoLogs$ | async as infoLogs">
        <app-reports-table [data]="infoLogs" [tableDataColumns]="tableColumns" fieldToCopy="customInfo"></app-reports-table>
      </div>
      <app-paginator
        [totalItems]="total"
        (nextPage)="getNextPage($event)"
        (entriesPerPageChanged)="setEntriesPerPage($event)"
        [setDefaultPage]="setDefaultPage$"
      ></app-paginator>

    </div>

  </div>
</div>
