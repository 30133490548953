import { Component, Input, OnInit } from '@angular/core';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';

@Component({
  selector: 'app-chart-tool',
  templateUrl: './chart-tool.component.html',
  styleUrls: ['../img-tool.scss']
})
export class ChartToolComponent implements OnInit {
  @Input() public column: MjmlElementModel;
  @Input() public entity: MjmlElementModel;
  @Input() public tms: TemplateManagementService;

  constructor() { }

  ngOnInit() {
  }

  public getContainerStyles(): any {
    const attributes = this.entity.attributes;
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    return {
      'padding-bottom': paddingBottom,
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      'padding-top': paddingTop,
    };
  }

}
