import { Directive, HostBinding, Input } from '@angular/core';
import { UsMapRangeType } from '../models/enum/us.map.range.type';

const COLORS = {
  [UsMapRangeType.firstRange]: '#c6e2cb',
  [UsMapRangeType.secondRange]: '#83cacf',
  [UsMapRangeType.thirdRange]: '#48aed0',
  [UsMapRangeType.fourthRange]: '#3984b6',
  [UsMapRangeType.fifthRange]: '#2c5a9c',
  [UsMapRangeType.sixthRange]: '#1f3082',
  [UsMapRangeType.seventhRange]: '#141c58'
};

@Directive({
  selector: '[appFillState]'
})
export class FillStateDirective {
  @Input('appFillState')
  private range: UsMapRangeType;

  @HostBinding('style.fill')
  get getFillColor(): string {
    return COLORS[this.range];
  }

}
