import { formatDate } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { P2PStaticPageModel } from 'src/app/models/p2p/p2p-static-page.model';
import { MjmlCustomType } from 'src/app/models/templates/mjml.custom.type';
import MjmlElementModel from 'src/app/models/templates/mjml.element.model';
import { MjmlTag } from 'src/app/models/templates/mjml.tag.type';
import { UtilsComponent } from '../utils.component';
import {P2PSettingsService} from "../../services/p2p/p2p-settings.service";
import {Paging} from "../../models/paging/paging.model";
import { P2PStatus } from 'src/app/models/p2p/p2p-fundraiser.model';

const DEFAULT_FUNDRAISER_TITLE: string = "FUNDRAISER EXAMPLE NAME";
const DEFAULT_FUNDRAISER_ORGANIZER: string = "John Doe";
const PAYMENTS_URL_ROOT: string = "/payments/event";

const EMPTY_MJML_SECTION: MjmlElementModel = {
  tagName: MjmlTag.section,
    'attributes': {
      'padding': '0px',
      'background-color': 'transparent',
    },
    children: []
}

const EMPTY_MJML_TAG: MjmlElementModel = {
  attributes: {},
}

@Component({
  selector: 'app-p2p-static-page',
  templateUrl: './p2p-static-page.component.html',
  styleUrls: ['./p2p-static-page.component.scss']
})
export class P2PStaticPageComponent implements OnChanges{

  @Input() staticPageModel: P2PStaticPageModel;
  @Input() isPreview: boolean;
  @Input() hasRegistration: boolean;
  public isExtended: boolean = true;
  public mjml: MjmlElementModel;

  constructor(private p2pSettings: P2PSettingsService) { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.staticPageModel?.currentValue) {
      if(this.staticPageModel?.fundraiserId && this.staticPageModel?.eventId) {
        this.p2pSettings.getByEventId(this.staticPageModel?.eventId).subscribe(settings => {
          this.hasRegistration = settings.event?.hasRegistrationForm;
          this.isExtended = settings.isExtended;
          this.getMjml();
        })
      } else this.getMjml();
    }
  }

  public getMjml() {
    this.mjml = {
      tagName: MjmlTag.mjml,
      attributes: {
        owa: 'desktop'
      },
      children: [
        {
          tagName: MjmlTag.head,
          attributes: {},
          children: [
            {
              tagName: MjmlTag.font,
              attributes: {
                name: 'Roboto',
                href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap'
              }
            },
            {
              tagName: MjmlTag.style,
              attributes: {
                'inline': 'inline'
              },
              'content': ''
            }
          ]
        },

        // Body
        {
          tagName: MjmlTag.body,
          attributes: {
            'width': '1195px',
            'background-color': '#ffffff'
          },
          children: [
            {
              tagName: MjmlTag.wrapper,
              attributes: {
                'padding': '0px',
                'full-width': 'full-width',
                'background-repeat': 'repeat',
                'background-url': ''
              },
              children: [

                // Event title section
                this.eventTitleSection,

                // Section from event page marked as banner;
                this.bannerSection,

                // Date & Time section
                this.dateTimeSection,

                // Description section
                this.descriptionSection,

                // Action buttons section
                this.actionButtonsSection,

                // Visualization section
                this.visualizationSection,

                // Social media section
                this.socialMediaSection,

                // Accessability text section
                this.accessabilityTextSection,

                // Sponsors section
                this.sponsorsSection
              ]
            },
            {
              ...this.headerFooter
            }
          ]
        }
      ]
    }

  }

  private get eventTitleSection(): MjmlElementModel {
    const eventNameSections: MjmlElementModel[] = this.getSectionsByInnerCustomType(MjmlCustomType.EventName);

    const defaultSection: MjmlElementModel = (
      {
        tagName: MjmlTag.section,
        attributes: {
          'padding': '0px',
          'background-url': '/assets/images/layout-img1.png',
          'background-repeat': 'no-repeat',
          'padding-top': '0px',
          'padding-right': '0px',
          'padding-bottom': '0px',
          'padding-left': '0px',
          'background-color': 'transparent'
        },
        children: [
          {
            tagName: MjmlTag.column,
            attributes: {
              'padding': '0px',
              'padding-top': '0px',
              'padding-right': '0px',
              'padding-bottom': '0px',
              'padding-left': '0px',
              'background-color': ''
            },
            children: [
              {
                tagName: MjmlTag.text,
                attributes: {
                  'padding': '10px 25px',
                  'customType': 'eventName',
                  'font-family': 'Roboto',
                  'font-size': '44px',
                  'font-style': 'normal',
                  'font-weight': 'bold',
                  'text-decoration': 'none',
                  'align': 'left',
                  'padding-top': '100px',
                  'padding-right': '25px',
                  'padding-bottom': '10px',
                  'padding-left': '25px',
                  'color': '#575757',
                  'container-background-color': ''
                },
                content: this.staticPageModel?.eventName
              }
            ]
          }
        ]
      }
    )

    return !!eventNameSections?.length ? eventNameSections[0] : EMPTY_MJML_SECTION;
  }

  private get bannerSection(): MjmlElementModel {
    const banners: MjmlElementModel[] = this.eventBanners;

    if(!!banners?.length) return banners[0];
    return EMPTY_MJML_SECTION;
  }

  private get dateTimeSection(): MjmlElementModel {
    const startDate: string = this.staticPageModel?.eventStartDate && formatDate(this.staticPageModel.eventStartDate, 'MMMM dd, yyyy', 'en-US');
    const endDate: string = this.staticPageModel?.eventEndDate && formatDate(this.staticPageModel.eventEndDate, 'MMMM dd, yyyy', 'en-US');
    const startTime: string = this.staticPageModel?.eventStartTime;
    const endTime: string = this.staticPageModel?.eventEndTime;

    const content: string = `${startDate} - ${endDate} ${startTime} - ${endTime}`;

    const dateTimeSections: MjmlElementModel[] = this.getSectionsByInnerCustomType(MjmlCustomType.EventScheduler);

    const defaultDateTimeSection: MjmlElementModel = (
      {
        tagName: MjmlTag.section,
        'attributes': {
          'padding': '0px',
          'background-color': 'transparent',
          'background-repeat': 'repeat',
          'background-url': '',
          'padding-bottom': '20px',
          'padding-left': '0px',
          'padding-right': '0px',
          'padding-top': '80px'
        },
        children: [
          {
            tagName: MjmlTag.column,
            'attributes': {
              'padding': '0px',
              'background-color': '',
              'padding-bottom': '0px',
              'padding-left': '0px',
              'padding-right': '0px',
              'padding-top': '0px'
            },
            children: [
              {
                tagName: MjmlTag.text,
                'attributes': {
                  'padding': '10px 25px',
                  'container-background-color': '',
                  'padding-bottom': '10px',
                  'padding-left': '25px',
                  'padding-right': '25px',
                  'padding-top': '10px',
                },
                'content': '<div><strong>DATE &amp; TIME</strong></div>'
              },
              {
                tagName: MjmlTag.text,
                'attributes': {
                  'padding': '10px 25px',
                  'customType': 'eventScheduler',
                  'color': '#000000',
                  'font-family': 'Roboto',
                  'font-size': '18px',
                  'container-background-color': '',
                  'padding-bottom': '10px',
                  'padding-left': '25px',
                  'padding-right': '25px',
                  'padding-top': '10px',
                  'align': 'center',
                  'font-style': 'normal',
                  'font-weight': 'bold',
                  'text-decoration': 'none'
                },
                'content': `<p>${content}</p>`
              }
            ]
          }
        ]
      }
    )

    return !!dateTimeSections?.length ? dateTimeSections[0] : defaultDateTimeSection;
  }

  private get descriptionSection(): MjmlElementModel {
    const fundraiserTitle: string = this.staticPageModel?.fundraiserName ? this.staticPageModel.fundraiserName : DEFAULT_FUNDRAISER_TITLE;
    const fundraiserDesc: string = this.staticPageModel?.fundraiserDesc ? this.staticPageModel.fundraiserDesc : "";
    const organizerName: string = this.staticPageModel?.fundraiserCreatedBy ? this.staticPageModel.fundraiserCreatedBy : DEFAULT_FUNDRAISER_ORGANIZER;

    const children: MjmlElementModel[] = [
      // Text Col
      {
        tagName: MjmlTag.column,
        attributes: {},
        children: [
          {
            tagName: MjmlTag.text,
            'attributes': {
              'color': '#000000',
              'font-family': 'Roboto',
              'font-size': '32px',
              'container-background-color': '',
              // 'padding-bottom': '10px',
              'align': 'left',
              'font-style': 'normal',
              'font-weight': 'bold',
              'text-decoration': 'none'
            },
            content: `<span style="font-size: 16px; font-family: roboto;">${fundraiserTitle}</span>`
          },
          {
            tagName: MjmlTag.text,
            'attributes': {
              'color': '#000000',
              'font-family': 'Roboto',
              'font-size': '16px',
              'container-background-color': '',
              'align': 'left',
              'font-style': 'normal',
              'text-decoration': 'none'
            },
            content: `<div><strong style="font-size: 16px; font-family: roboto;">Organizer    </strong><span style="font-size: 16px; font-family: roboto;">${organizerName}</span></div>`
          },
          {
            tagName: MjmlTag.text,
            'attributes': {
              'color': '#000000',
              'font-family': 'Roboto',
              'font-size': '16px',
              'container-background-color': '',
              'align': 'left',
              'font-style': 'normal',
              'text-decoration': 'none'
            },
            content: fundraiserDesc
          }
        ]
      }
    ]

    if(this.staticPageModel.fundraiserImageURL || this.isPreview) {
      children.unshift(
         // Image col
         {
          tagName: MjmlTag.column,
          attributes: {
            'padding': '0px',
            'padding-top': '0px',
            'padding-right': '0px',
            'padding-bottom': '0px',
            'padding-left': '0px',
            'background-color': ''
          },
          children: [
            {
              tagName: MjmlTag.image,
              attributes: {
                'padding': '0px',
                'width': '100%',
                'height': '100%',
                'background-color': '#D9D9D9',
                src: this.staticPageModel?.fundraiserImageURL
              },
              content: ''
            }
          ]
        }
      )
    }

    return(
      {
        tagName: MjmlTag.section,
        'attributes': {
          'padding': '0px',
          'background-color': 'transparent',
        },
        children
      }
    )
  }

  private get actionButtonsSection(): MjmlElementModel {
    const registerHref: string = `${window.location.origin}/registrationEvent/${this.staticPageModel?.eventId}/P2P/${this.staticPageModel.fundraiserId}`;

    let donateHref: string = "";

      if(this.staticPageModel?.fundraiserId && this.staticPageModel?.eventId) {
        if(this.staticPageModel.fundraiserStatus !== P2PStatus.live) {
          donateHref = `${PAYMENTS_URL_ROOT}/${this.staticPageModel.eventId}`;
        } else {
          donateHref = `${PAYMENTS_URL_ROOT}/${this.staticPageModel.eventId}/P2P/${this.staticPageModel.fundraiserId}`;
        }
      }

    const createFundraiserHref: string = (this.staticPageModel?.clientId && this.staticPageModel?.eventId && this.staticPageModel.fundraiserId) ? `${window.location.origin}/CreateFundraiser/client/${this.staticPageModel.clientId}/event/${this.staticPageModel.eventId}/fundraiser/${this.staticPageModel.fundraiserId}` : ""

    const eventMjmlRegistrationBtns: MjmlElementModel[] = this.getToolsByCustomType(MjmlCustomType.register);
    const eventMjmlDonateBtns: MjmlElementModel[] = this.getToolsByCustomType(MjmlCustomType.donate);
    const eventMjmlFudraiserBtns: MjmlElementModel[] = this.getToolsByCustomType(MjmlCustomType.fundraiser);

    const registrationBtnColumn: MjmlElementModel = {
      tagName: MjmlTag.column,
      attributes: {},
      children: [
        {
          tagName: MjmlTag.button,
          'attributes': {
            'customType': 'register',
            'padding': '10px 25px',
            'href': registerHref,
            'align': 'center',
            'font-family': 'Helvetica',
            'font-size': '14px',
            'font-style': 'normal',
            'font-weight': 'bold',
            'text-decoration': 'none',
            'border': '0px solid #414141',
            'border-radius': '5px',
            'inner-padding': '10px 45px',
            'padding-top': '10px',
            'padding-right': '25px',
            'padding-bottom': '10px',
            'padding-left': '25px',
            'background-color': '#20a643'
          },
          'content': 'REGISTER'
        }
      ]
    }
    const createFundraiserBtn: MjmlElementModel = {
      tagName: MjmlTag.column,
      attributes: {},
      children: [
        {
          tagName: MjmlTag.button,
          'attributes': {
            'customType': 'fundraiser',
            'padding': '10px 25px',
            'href': createFundraiserHref,
            'align': 'center',
            'font-family': 'Helvetica',
            'font-size': '14px',
            'font-style': 'normal',
            'font-weight': 'bold',
            'text-decoration': 'none',
            'border': '0px solid #414141',
            'border-radius': '5px',
            'inner-padding': '10px 45px',
            'padding-top': '10px',
            'padding-right': '25px',
            'padding-bottom': '10px',
            'padding-left': '25px',
            'background-color': '#20a643'
          },
          'content': 'CREATE A FUNDRAISER'
        }
      ]
    }

    const btnColumns: MjmlElementModel[] = [
      {
        tagName: MjmlTag.column,
        attributes: {},
        children: [
          {
            tagName: MjmlTag.button,
            'attributes': {
              'customType': 'donate',
              'padding': '10px 25px',
              'href': donateHref,
              'align': 'center',
              'font-family': 'Helvetica',
              'font-size': '14px',
              'font-style': 'normal',
              'font-weight': 'bold',
              'text-decoration': 'none',
              'border': '0px solid #414141',
              'border-radius': '5px',
              'inner-padding': '10px 45px',
              'padding-top': '10px',
              'padding-right': '25px',
              'padding-bottom': '10px',
              'padding-left': '25px',
              'background-color': '#20a643'
            },
            'content': 'DONATE'
          }
        ]
      },
    ]

    if(this.hasRegistration) {
      btnColumns.unshift(registrationBtnColumn)
      if(!!eventMjmlFudraiserBtns?.length) {
        this.updateBtnAttributes(btnColumns, MjmlCustomType.register, eventMjmlRegistrationBtns[0], registerHref)
      }
    }
    if (this.isExtended && this.staticPageModel.fundraiserParentId == null) {
      btnColumns.push(createFundraiserBtn)
    }

    if(!!eventMjmlFudraiserBtns?.length) {
      this.updateBtnAttributes(btnColumns, MjmlCustomType.fundraiser, eventMjmlFudraiserBtns[0], createFundraiserHref);
    }

    if(!!eventMjmlDonateBtns?.length) {
      this.updateBtnAttributes(btnColumns, MjmlCustomType.donate, eventMjmlDonateBtns[0], donateHref);
    }

    return (
      {
        tagName: MjmlTag.section,
        'attributes': {
          'padding': '0px',
          'background-color': 'transparent',
        },
        children: btnColumns
      }
    )
  }

  private updateBtnAttributes(btnColumns: MjmlElementModel[], btnCustomType: MjmlCustomType, newBtn: MjmlElementModel, currHref: string): void {
    btnColumns.forEach((col: MjmlElementModel) => {
      const currBtn = col.children[0];
      if(currBtn.attributes.customType === btnCustomType && newBtn.attributes?.display !== 'none') {
        currBtn.attributes = {
          ...newBtn.attributes,
          href: currHref
        };
      }
    })
  }

  private get visualizationSection(): MjmlElementModel {
    let thermometerAttr = {
      customType: MjmlCustomType.thermometer,
      goal: (this.staticPageModel?.fundraiserGoal || 0).toString(),
      donations: (this.staticPageModel?.raisedFunds || 0).toString(),
      donors: (this.staticPageModel?.donationsCount || 0).toString(),
    }

    if(this.staticPageModel?.fundraiserGoal) {
      thermometerAttr["showIterations"] = "true";
      thermometerAttr["iteration_1"] = (Math.ceil((this.staticPageModel.fundraiserGoal * 0.25) * 100 ) / 100) + '';
      thermometerAttr["iteration_2"] = (Math.ceil((this.staticPageModel.fundraiserGoal * 0.50) * 100 ) / 100) + '';
      thermometerAttr["iteration_3"] = (Math.ceil((this.staticPageModel.fundraiserGoal * 0.75) * 100 ) / 100) + '';
    }

    const donationActivityAttr = {
      customType: MjmlCustomType.donationActivity,
      'show-donate-now-button': "false"
    }

    return (
      {
        tagName: MjmlTag.section,
        'attributes': {
          'padding': '0px',
          'background-color': 'transparent',
        },
        children: [
          {
            tagName: MjmlTag.column,
            attributes: {},
            children: [
              {
                tagName: MjmlTag.text,
                attributes: thermometerAttr,
              }
            ]
          },
          {
            tagName: MjmlTag.column,
            attributes: {},
            children: [
              {
                tagName: MjmlTag.text,
                attributes: donationActivityAttr,
                content: '<div><strong style="font-family: roboto; font-size: 16px; color: rgb(68, 68, 68);">Donor Activity</strong></div>'
              }
            ]
          }
        ]
      }
    )
  }

  private get socialMediaSection(): MjmlElementModel {
    const eventMjmlSocialMedia: MjmlElementModel[] = this.eventMjmlBody && UtilsComponent.getMjmlElementByTagType(this.eventMjmlBody, MjmlTag.social);

    if(!eventMjmlSocialMedia?.length) return EMPTY_MJML_SECTION;

    return (
      {
        tagName: MjmlTag.section,
        'attributes': {
          'padding': '0px',
          'background-color': 'transparent',
        },
        children: [
          {
            tagName: MjmlTag.column,
            attributes: {},
            children: eventMjmlSocialMedia
          }
        ]
      }
    )
  }

  private get accessabilityTextSection(): MjmlElementModel {
    return (
      {
        tagName: MjmlTag.section,
        'attributes': {
          'padding': '0px',
          'background-color': 'transparent',
        },
        children: [
          {
            tagName: MjmlTag.column,
            attributes: {},
            children: [
              {
                tagName: MjmlTag.text,
                'attributes': {
                  "padding": "10px 25px",
                  "customType": "eventAccessibility",
                  "color": "#74aadd",
                  "font-family": "Roboto",
                  "font-size": "18px",
                  "container-background-color": "",
                  "padding-bottom": "10px",
                  "padding-left": "25px",
                  "padding-right": "25px",
                  "padding-top": "10px",
                  "align": "center",
                  "font-style": "normal",
                  "font-weight": "bold",
                  "text-decoration": "none"
                },
                content: this.staticPageModel?.eventAccessabilityText
              }
            ]
          }
        ]
      }
    )
  }

  private get sponsorsSection(): MjmlElementModel {
    const sponsorsMjml: MjmlElementModel[] = this.eventMjmlBody && UtilsComponent.getMjmlElementByTagType(this.eventMjmlBody, MjmlTag.sponsors);

    if(!sponsorsMjml?.length) return EMPTY_MJML_SECTION;

    return (
      {
        tagName: MjmlTag.section,
        'attributes': {
          'padding': '0px',
          'background-color': 'transparent',
        },
        children: [
          {
            tagName: MjmlTag.column,
            attributes: {},
            children: sponsorsMjml
          }
        ]
      }
    )
  }

  private get headerFooter(): MjmlElementModel {
    const eventHeaderFooter: MjmlElementModel = JSON.parse(this.staticPageModel.eventMjml).children[1].children[1];


    if(!eventHeaderFooter) return EMPTY_MJML_TAG;
    return eventHeaderFooter as MjmlElementModel;    
  }

  private get eventBanners(): MjmlElementModel[] {
    const results: MjmlElementModel[] = [];

    this.onTool((tree: MjmlTreeModel) => {
      if(tree.section?.attributes["inherit-section"] === "1") {
        results.push(tree.section);
      }
    })

    return results;
  }

  private get eventMjmlBody(): MjmlElementModel[] {
    if(!this.staticPageModel?.eventMjml) return;
    return JSON.parse(this.staticPageModel.eventMjml).children[1].children[0].children;
  }

  private onTool(cb: Function): void {
    this.eventMjmlBody.forEach(section => {
      const columns: MjmlElementModel[] = section.children;
      columns.forEach(column => {
        const tools: MjmlElementModel[] = column.children;
        tools.forEach(tool => {
          cb({tool, column, section});
        })
      })
    })
  }

  private getSectionsByInnerCustomType(customType: MjmlCustomType): MjmlElementModel[] {
    let results: MjmlElementModel[] = [];

    this.onTool((tree: MjmlTreeModel) => {
      if(tree.tool?.attributes?.customType === customType) {
        results.push(tree.section)
      }
    })

    return results;
  }

  private getToolsByCustomType(customType: MjmlCustomType) {
    let results: MjmlElementModel[] = [];

    this.onTool((tree: MjmlTreeModel) => {
      if(tree.tool?.attributes?.customType === customType) {
        results.push(tree.tool)
      }
    })

    return results;
  }
}

interface MjmlTreeModel {
  tool: MjmlElementModel,
  column: MjmlElementModel,
  section: MjmlElementModel
}


