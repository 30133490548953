import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { Guid } from 'guid-typescript';
import { AzureFileModel } from '../../../../models/files/azure-file.model';
import { AzureUploadFileService } from '../../../../services/lookup/azure-upload-file.service';
import { AuthService } from '../../../../services/auth.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-video-screenshots',
  templateUrl: './video-screenshots.component.html',
  styleUrls: ['./video-screenshots.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoScreenshotsComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild('videoElementRef') private videoElementRef: ElementRef;
  //@ViewChild('canvasRef') private canvasRef: ElementRef;

  private subscription: Subscription = new Subscription();
  //private context: CanvasRenderingContext2D;
  private videoWidth: number;
  private videoHeight: number;

  public videoReady$: Observable<boolean>;
  public screenshotReady$: Observable<boolean>;

  private saveSubject: Subject<void> = new Subject<void>();

  public croppedImage: string = '';
  public imageBase64: string = '';

  constructor(
    private dialogRef: MatDialogRef<VideoScreenshotsComponent>,
    private renderer: Renderer2,
    private uploadService: AzureUploadFileService,
    @Inject(MAT_DIALOG_DATA) public data: { link: string },
    private authService: AuthService,
  ) {
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.saveSubject.asObservable()
        .pipe(
          exhaustMap(() => this.saveImage())
        )
        .subscribe((imageName: string) => this.dialogRef.close(imageName))
    );
    this.subscription.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
  }

  public ngAfterViewInit(): void {
    const video = this.videoElementRef.nativeElement;
   /* const canvas = this.canvasRef.nativeElement;
    this.context = canvas.getContext('2d');*/

    this.subscription.add(
      fromEvent(video, 'loadedmetadata')
        .pipe(
          tap(() => this.videoReady$ = of(true))
        )
        .subscribe(() => {
            this.videoWidth = video.videoWidth;
            this.videoHeight = video.videoHeight;
           /* const width = this.videoWidth > 600 ? 600 : this.videoWidth;
            const height = Math.ceil(width * this.videoHeight / this.videoWidth);
            this.renderer.setAttribute(canvas, 'width', width.toString());
            this.renderer.setAttribute(canvas, 'height', height.toString());*/
          }
        )
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }

  public takeScreenshot(): void {
    const outputImage = document.createElement('canvas');
    const width = this.videoWidth > 600 ? 600 : this.videoWidth;
    const height = Math.ceil(width * this.videoHeight / this.videoWidth);
    outputImage.width = width;
    outputImage.height = height;
    outputImage.getContext('2d').drawImage(this.videoElementRef.nativeElement, 0, 0, width, height);
   /* this.context.fillRect(0, 0, width, height);
    this.context.drawImage(this.videoElementRef.nativeElement, 0, 0, width, height);*/
    this.screenshotReady$ = of(true);
    this.imageBase64 = outputImage.toDataURL('image/png');
  }


  public useScreenshot(): void {
    this.saveSubject.next();
  }

  private saveImage(): Observable<string> {
    const name = `${Guid.create()}.png`;
    /*const dataURL = this.canvasRef.nativeElement.toDataURL('image/png');
    const azure: AzureFileModel = {
      file: dataURL.substring(22, dataURL.length),
      name,
      folder: 'images'
    };*/
    const azure: AzureFileModel = {
      file: this.croppedImage.substring(22, this.croppedImage.length),
      name,
      folder: 'images'
    };
    return this.uploadService.addModel(azure)
      .pipe(
        map((azureModel) => azureModel.name)
      );
  }
  public imageCropped(image: ImageCroppedEvent): void {
    this.croppedImage = image.base64;
  }
}
