import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageType } from './models/i18n/language.type';
import { map } from 'rxjs/operators';

export class CustomLoader implements TranslateLoader {
  constructor(private http: HttpClient) {
  }

  public getTranslation(lang: LanguageType): Observable<any> {
    return this.http.get('/assets/i18n/' + lang + '.json')
      .pipe(
        map(translations => {
          if (lang === LanguageType.English) {
            return translations;
          } else {
            this.removeEmptyValues(translations);
            return translations;
          }
        })
      );
  }

  private removeEmptyValues(translations: Object): void {
    const keys: string[] = Object.keys(translations);
    keys.forEach((key: string) => {
      if (typeof translations[key] === 'object') {
        this.removeEmptyValues(translations[key]);
      } else if (translations[key] === '') {
        delete translations[key];
      }
    });
  }
}
