import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { interval, Subscription, Observable, Subject, from } from 'rxjs';
import { tap, map, takeUntil } from 'rxjs/operators';
import { MS_PER_DAY, MS_PER_HOUR, MS_PER_MINUTE } from 'src/app/constants';
import { TimeZoneModel } from 'src/app/models/time-zone.model';

@Component({
  selector: 'app-countdown-text',
  templateUrl: './countdown-text.component.html',
  styleUrls: ['./countdown-text.component.scss']
})
export class CountdownTextComponent implements OnInit, OnChanges, OnDestroy {
  @Input() private earlierDate: Date;
  @Input() private laterDate: Date;
  @Input() private earlierDateOffset: number;
  @Input() private laterDateOffset: number;
  @Output() public countdownStopped = new EventEmitter()
  private subscription = new Subscription()
  private timeDiff: number;
  public result$: Observable<string>;

  ngOnChanges(changes: SimpleChanges): void {
    if(this.earlierDate && this.laterDate) this.calculateDiffTime();
  }
  
  ngOnInit(): void {
    this.result$ = this.updateTime();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private calculateDiffTime(): void {
    const earlierDate: number = this.dateToUTCTime(this.earlierDate, this.earlierDateOffset);
    const laterDate: number = this.dateToUTCTime(this.laterDate, this.laterDateOffset);
    if(earlierDate >= laterDate) {
      this.timeDiff = 0;
    } else {
      this.timeDiff = Math.abs(earlierDate - laterDate);
    }
  }

  private updateTime(): Observable<string> {
    return interval(1000)
    .pipe(
      map((i) => {
        return this.timeDiff - 1000 * i;
      }),
      tap((timeLeft) => {
        if(timeLeft <= 0) {
          this.countdownStopped.emit(null);
        }
      }),
      takeUntil(from(this.countdownStopped)),
      map(this.formatTime),
    )
  }

  private formatTime(leftTime: number): string {
    const days = Math.floor(leftTime / MS_PER_DAY);
    const msPerDay = days * MS_PER_DAY;
    const hours = Math.floor((leftTime - msPerDay) / MS_PER_HOUR);
    const msPerHour = hours * MS_PER_HOUR;
    const minutes = Math.floor((leftTime - msPerDay - msPerHour) / MS_PER_MINUTE)
    const msPerMinute = minutes * MS_PER_MINUTE;
    const seconds = Math.floor((leftTime - msPerDay - msPerHour - msPerMinute) / 1000 )
    let result: string = '';

    if(days) result += `${Math.floor(days)}D`;
    if(hours) result += ` ${Math.floor(hours)}h`;
    if(minutes) result += ` ${Math.floor(minutes)}m`;
    if(seconds) result += ` ${Math.floor(seconds)}s`

    return result;
  }

  private dateToUTCTime(date: Date, offset?: number): number {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hours = offset ? date.getHours() - offset : date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return Date.UTC(year, month, day, hours, minutes, seconds);
  }

}
