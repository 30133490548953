import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseFormElementComponent } from '../base.form.element.component';
import { CommunicationMethods } from '../../../models/enum/communication-methods.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-communication-methods-picker',
  templateUrl: './communication-methods-picker.component.html',
  styleUrls: ['./communication-methods-picker.component.scss', '../base.form.element.component.scss']
})
export class CommunicationMethodsPickerComponent extends BaseFormElementComponent implements OnChanges, OnInit {
  public CommunicationMethods = CommunicationMethods;
  public selected: Set<CommunicationMethods> = new Set();

  constructor(public translate: TranslateService) {
    super(translate);
  }

  public ngOnInit(): void {
    this.patchSelected();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public toggleItem(item: CommunicationMethods): void {
    if (item === CommunicationMethods.DoNotContact && this.selected.has(item)) {
      this.selected.delete(item);
    } else if (item === CommunicationMethods.DoNotContact && !this.selected.has(item)) {
      this.selected.clear();
      this.selected.add(item);
    } else {
      this.selected.delete(CommunicationMethods.DoNotContact);
      this.selected.has(item) ? this.selected.delete(item) : this.selected.add(item);
    }
  }

  public setValue(): void {
    let currentValue = null;
    // eslint-disable-next-line no-bitwise
    this.selected.forEach((method: CommunicationMethods) => currentValue = currentValue ? currentValue | method : method);
    this.formControl.setValue(currentValue);
    this.formControl.markAsTouched();
    this.valueChanged.emit();
  }

  public get currentValue(): string {
    if (!this.formControl) {
      return '';
    }
    switch (this.formControl.value) {
      case CommunicationMethods.DoNotContact:
        return 'Do not contact';
      case CommunicationMethods.Email:
        return 'Emails';
      case CommunicationMethods.Mail:
        return 'Standard mail';
      case CommunicationMethods.Phone:
        return 'Phone calls';
      case CommunicationMethods.Text:
        return 'Text';

      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Email | CommunicationMethods.Mail:
        return 'Emails, Standard mail';
      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Email | CommunicationMethods.Phone:
        return 'Emails, Phone calls';
      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Email | CommunicationMethods.Text:
        return 'Emails, Text';

      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Email | CommunicationMethods.Mail | CommunicationMethods.Phone:
        return 'Emails, Standard mail, Phone calls';
      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Email | CommunicationMethods.Mail | CommunicationMethods.Text:
        return 'Emails, Standard mail, Text';
      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Email | CommunicationMethods.Phone | CommunicationMethods.Text:
        return 'Emails, Phone calls, Text';
      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Email | CommunicationMethods.Mail | CommunicationMethods.Phone | CommunicationMethods.Text:
        return 'Emails, Standard mail, Phone calls, Text';

      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Mail | CommunicationMethods.Phone:
        return 'Standard mail, Phone calls';
      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Mail | CommunicationMethods.Text:
        return 'Standard mail, Text';
      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Mail | CommunicationMethods.Phone | CommunicationMethods.Text:
        return 'Standard mail, Phone calls, Text';

      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Phone | CommunicationMethods.Text:
        return 'Phone calls, Text';

      default:
        return 'None';
    }
  }

  private patchSelected(): void {
    if (!this.formControl) {
      return;
    }
    switch (this.formControl.value) {
      case CommunicationMethods.DoNotContact:
        this.selected.add(CommunicationMethods.DoNotContact);
        break;
      case CommunicationMethods.Email:
        this.selected.add(CommunicationMethods.Email);
        break;
      case CommunicationMethods.Mail:
        this.selected.add(CommunicationMethods.Mail);
        break;
      case CommunicationMethods.Phone:
        this.selected.add(CommunicationMethods.Phone);
        break;
      case CommunicationMethods.Text:
        this.selected.add(CommunicationMethods.Text);
        break;

      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Email | CommunicationMethods.Mail:
        this.selected.add(CommunicationMethods.Email);
        this.selected.add(CommunicationMethods.Mail);
        break;
      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Email | CommunicationMethods.Phone:
        this.selected.add(CommunicationMethods.Email);
        this.selected.add(CommunicationMethods.Phone);
        break;
      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Email | CommunicationMethods.Text:
        this.selected.add(CommunicationMethods.Email);
        this.selected.add(CommunicationMethods.Text);
        break;

      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Email | CommunicationMethods.Mail | CommunicationMethods.Phone:
        this.selected.add(CommunicationMethods.Email);
        this.selected.add(CommunicationMethods.Mail);
        this.selected.add(CommunicationMethods.Phone);
        break;
      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Email | CommunicationMethods.Mail | CommunicationMethods.Text:
        this.selected.add(CommunicationMethods.Email);
        this.selected.add(CommunicationMethods.Mail);
        this.selected.add(CommunicationMethods.Text);
        break;
      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Email | CommunicationMethods.Phone | CommunicationMethods.Text:
        this.selected.add(CommunicationMethods.Email);
        this.selected.add(CommunicationMethods.Phone);
        this.selected.add(CommunicationMethods.Text);
        break;
      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Email | CommunicationMethods.Mail | CommunicationMethods.Phone | CommunicationMethods.Text:
        this.selected.add(CommunicationMethods.Email);
        this.selected.add(CommunicationMethods.Phone);
        this.selected.add(CommunicationMethods.Text);
        this.selected.add(CommunicationMethods.Mail);
        break;

      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Mail | CommunicationMethods.Phone:
        this.selected.add(CommunicationMethods.Mail);
        this.selected.add(CommunicationMethods.Phone);
        break;
      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Mail | CommunicationMethods.Text:
        this.selected.add(CommunicationMethods.Mail);
        this.selected.add(CommunicationMethods.Text);
        break;
      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Mail | CommunicationMethods.Phone | CommunicationMethods.Text:
        this.selected.add(CommunicationMethods.Mail);
        this.selected.add(CommunicationMethods.Phone);
        this.selected.add(CommunicationMethods.Text);
        break;

      // eslint-disable-next-line no-bitwise
      case CommunicationMethods.Phone | CommunicationMethods.Text:
        this.selected.add(CommunicationMethods.Phone);
        this.selected.add(CommunicationMethods.Text);
        break;

      default:
        this.selected.clear();
    }
  }
}
