import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilderStateService } from '../../../form-builder.state.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  AlignmentType,
  FbElementModel,
  FbElementType,
  HeaderSize
} from '../../../../../models/form-builder/fb.template.model';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import { FB_ALIGN, FB_SIZE } from '../../../../../constants';
import { FormPageType } from '../../../../../models/form-builder/form.page.type';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export interface DefaultHeaders {
  mainLabel: string;
  subLabel: string;
};

@Component({
  selector: 'app-header-options',
  templateUrl: './header-options.component.html',
  styleUrls: ['../../fb.page.scss']
})
export class HeaderOptionsComponent implements OnInit, OnDestroy {
  @Input() public fbss: FormBuilderStateService;

  public optionsForm: FormGroup = this.formBuilder.group({
    mainLabel: this.translate.instant('Type a header'),
    alignment: AlignmentType.Left,
    subLabel: this.translate.instant('Type a Sub-header'),
    size: HeaderSize.Large,
    duplicates: '1',
  });

  public alignmentOptions: FormElementDataModel[] = FB_ALIGN;
  public sizeOptions: FormElementDataModel[] = FB_SIZE;

  private element: FbElementModel;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    public translate: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.fbss.activeElementSettings$.asObservable()
        .pipe(
          filter(value => value === FbElementType.Header),
          tap(() => {
            const actualPage = this.fbss.paymentPage$.getValue();
            const actualElements = actualPage === FormPageType.First
              ? this.fbss.template$.getValue().infoPage.elements
              : this.fbss.template$.getValue().paymentPage.elements;
            this.element = actualElements.find(({type}: FbElementModel) => type === FbElementType.Header);
            this.setInitialValues();
          }),
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }

  private setInitialValues(): void {
    const {
      // mainLabel = 'Type a header',
      alignment = AlignmentType.Left,
      // subLabel = 'Type a Sub-header',
      // size = HeaderSize.Large,
      duplicates = '1',
    }: { [key: string]: string | DefaultHeaders[] } = this.element.attributes;
    // this.mainLabel.setValue(mainLabel);
    this.alignment.setValue(alignment);
    // this.subLabel.setValue(subLabel);
    // this.size.setValue(size);
    this.duplicates.setValue(duplicates);
  }

  // public get mainLabel(): FormControl {
  //   return this.optionsForm.get('mainLabel') as FormControl;
  // }

  public get alignment(): FormControl {
    return this.optionsForm.get('alignment') as FormControl;
  }

  // public get subLabel(): FormControl {
  //   return this.optionsForm.get('subLabel') as FormControl;
  // }

  // public get size(): FormControl {
  //   return this.optionsForm.get('size') as FormControl;
  // }

  public get duplicates(): FormControl {
    return this.optionsForm.get('duplicates') as FormControl;
  }

  // public onMainLabelChanged(): void {
  //   this.element.attributes.mainLabel = this.mainLabel.value;
  //   this.fbss.templateWasChanged();
  // }

  // public onSubLabelChanged(): void {
  //   this.element.attributes.subLabel = this.subLabel.value;
  //   this.fbss.templateWasChanged();
  // }

  // public onSizeChanged(): void {
  //   this.element.attributes.size = this.size.value;
  //   this.fbss.templateWasChanged();
  // }

  public onAlignmentChanged(): void {
    this.element.attributes.alignment = this.alignment.value;
    this.fbss.templateWasChanged();
  }

  public duplicateField(): void {
    const currentDuplicateValue = this.element.attributes.duplicates;
    const nextDuplicateValue = +currentDuplicateValue + 1;
    this.element.attributes.duplicates = nextDuplicateValue.toString();
    const defaultHeaders: DefaultHeaders = {
      mainLabel: this.translate.instant('Type a new header'),
      subLabel: this.translate.instant('Type a new Sub-header'),
    };

    if (this.element.attributes.content) {
      (this.element.attributes.content as any).push(defaultHeaders);
    } else {
      const newField: any = new Array(
        +this.element.attributes.duplicates - 1
      ).fill({
        mainLabel: this.element.attributes.mainLabel,
        subLabel: this.element.attributes.subLabel,
      });
      newField.push(defaultHeaders);
      this.element.attributes.content = newField;
    }

    this.fbss.updateHeader$.next();
    this.fbss.templateWasChanged();
  }
}
