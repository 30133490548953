import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LanguageType } from '../models/i18n/language.type';
import { map } from 'rxjs/operators';

@Injectable()
export class I18nInterceptor implements HttpInterceptor {
  private currentLanguage: LanguageType = LanguageType.English;

  constructor(
    private translate: TranslateService
  ) {
    translate.onLangChange
      .pipe(
        map(({lang}: LangChangeEvent) => lang as LanguageType)
      )
      .subscribe((lang: LanguageType) => this.currentLanguage = lang);
  }
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addLocalizationHeader(request));
  }

  private addLocalizationHeader(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Auxilia-App-Language': this.currentLanguage
      }
    });
  }
}
