import FormElementDataModel from '../form.element.data.model';
import {LanguageType} from './language.type';

export const LANGUAGES: LanguageType[] = [
  LanguageType.English,
  LanguageType.Spanish,
 /* LanguageType.German,
  LanguageType.Russian*/
];

export const LANGUAGES_OPTIONS: FormElementDataModel[] = [
  {value: LanguageType.English, label: 'English'},
  {value: LanguageType.Spanish, label: 'Spanish'},
  /*{value: LanguageType.German, label: 'German'},
  {value: LanguageType.Russian, label: 'Russian'},*/
];
