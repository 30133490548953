import { BaseModelService } from '../base.model.service';
import { Injectable, Injector } from '@angular/core';
import { ClientAllocatedFundModel } from 'src/app/models/client/client-allocated-fund.model';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientAllocatedFundService extends BaseModelService<ClientAllocatedFundModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'ClientAllocatedFund';
  }

  public selectAll(clientId: string, isSelected: boolean): Observable<any> {
    let params = new HttpParams();
    params = params.append('ClientID', clientId);
    params = params.append('isSelectAll', isSelected.toString());
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<any>(`${config.apiURL}/${this.controllerName}/selectAll`, {params});
      })
    );
  }
}
