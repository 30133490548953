import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AddOrRemoveProfileTypeEnum} from '../../models/enum/add-or-remove-profile-type.enum';

@Component({
  selector: 'app-add-or-remove-rectangle',
  templateUrl: './add-or-remove-rectangle.component.html',
  styleUrls: ['./add-or-remove-rectangle.component.scss']
})
export class AddOrRemoveRectangleComponent implements OnInit{
  @Input() public iconsType: number = null;
  @Input() public innerText: string = null;
  @Input() public profileType: number;
  public AddOrRemoveProfileTypeEnum = AddOrRemoveProfileTypeEnum;
  @Output() public emitAction: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  public ngOnInit(): void {
  }

  public action(): void {
    this.emitAction.emit();
  }

  public get color(): string {
    switch (this.profileType) {
      case this.AddOrRemoveProfileTypeEnum.BlueProfile:
        return '#fff';
      case this.AddOrRemoveProfileTypeEnum.GreyProfile:
        return '#3A3B3F';
      case this.AddOrRemoveProfileTypeEnum.DarkProfile:
        return '#fff';
      default:
        return '#000';
    }
  }

}
