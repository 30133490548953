import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { BaseFormElementComponent } from '../base.form.element.component';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import FormElementDataModel from '../../../models/form.element.data.model';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['../base.form.element.component.scss', '../new.base.form.element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchSelectComponent extends BaseFormElementComponent implements OnChanges, OnInit, OnDestroy {

  public filterControl: FormControl = new FormControl();
  private subscription: Subscription = new Subscription();
  public options$: BehaviorSubject<FormElementDataModel[]> = new BehaviorSubject<FormElementDataModel[]>([]);

  constructor(public translate: TranslateService) {
    super(translate);
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.filterControl.valueChanges.pipe(debounceTime(500)).subscribe(value => {
        if (!value) {
          this.options$.next(this.selectOptions.slice());
        } else {
          value = value.toLowerCase();
          const next: FormElementDataModel[] = this.selectOptions.filter(({label}: FormElementDataModel) => label.toLowerCase().indexOf(value) === 0);
          this.options$.next(next);
        }
      })
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectOptions && changes.selectOptions.currentValue && changes.selectOptions.currentValue.length) {
      this.options$.next(this.selectOptions.slice());
    }
    super.ngOnChanges(changes);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
