import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from '../base.model.service';
import EventParticipantModel from '../../models/event/event.participant.model';
import { Paging } from '../../models/paging/paging.model';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { PagingHelper } from '../helper/paging.helper';
import { flatMap } from 'rxjs/operators';
import { TotalRowsModel } from 'src/app/models/paging/totalrow.model';
import {CampaignModel} from '../../models/campaigns/campaign.model';


@Injectable({
  providedIn: 'root'
})
export class EventParticipantService extends BaseModelService<EventParticipantModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }
  protected get controllerName(): string {
    return 'EventParticipant';
  }

  public getRegistrations(filter?: Paging): Observable<EventParticipantModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<EventParticipantModel[]>(
          `${config.apiURL}/${this.controllerName}/registrations`,
          { params }
        );
      })
    );
  }

  public getRegistrationsCount(filter?: Paging): Observable<TotalRowsModel> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<TotalRowsModel>(
          `${config.apiURL}/${this.controllerName}/registrationsTotal`,
          { params }
        );
      })
    );
  }

  public getEventParticipantById(id: string, filter?: Paging): Observable<EventParticipantModel> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<EventParticipantModel>(
          `${config.apiURL}/${this.controllerName}/${id}`,
          { params }
        );
      })
    );
  }

  public getRegistrationsActivity(filter?: Paging): Observable<EventParticipantModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<EventParticipantModel[]>(
          `${config.apiURL}/${this.controllerName}/registrationsActivity`,
          { params }
        );
      })
    );
  }
}
