import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { TranslateService } from '@ngx-translate/core';
import FormElementDataModel from '../../models/form.element.data.model';
import {Router} from '@angular/router';
import { IconsType } from 'src/app/models/enum/icons.type';

const NUMBERS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

@Component({
  selector: 'app-base-element',
  template: ''
})
export class BaseFormElementComponent implements OnChanges {
  @Input()
  public formGroup: FormGroup;
  @Input()
  public name: string;
  @Input()
  public label: string;
  @Input()
  public errorMassage: string;
  @Input()
  public isNew: boolean = false;
  @Input()
  public selectOptions: FormElementDataModel[] = [];
  @Input()
  public maxLength: number;
  @Input()
  public placeholder: string;
  @Input()
  public dateFilter: (date: Date) => boolean;
  @Input()
  public type: string = 'text';
  @Input()
  public disabled: boolean = false;
  @Input()
  public mask: string;
  @Input()
  public low: boolean = false;
  @Input()
  public readonly: boolean = true;
  @Input()
  public inputReadOnly: boolean = false;
  @Input()
  public prefix: string;
  @Input()
  public suffix: string;
  @Input()
  public thousandSeparator: string = null;
  @Input()
  public colorPiker: boolean = false;
  @Input()
  public customError: boolean = false;
  @Input()
  public minDate: Date = new Date(1950, 0, 1);
  @Input()
  public maxDate: Date;
  @Input()
  public onlyNumbers: boolean = false;
  @Input()
  public isNoWrap: boolean = false;
  @Input()
  public autocompleteOff: boolean = false;
  @Input()
  public isOpacity: boolean = false;
  @Input()
  public toolTipText: string;
  @Input()
  public iconType: number;
  @Input()
  public iconColor: string;
  @Input()
  public classForTooltip: string;
  @Input()
  public disableOnBlur: boolean = false;
  @Input()
  public classForTooltipIcon: string;
  @Output()
  public valueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public timeValueChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  public radioValueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() public focusEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public mathEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public blurEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public enterPressed: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

  @Output()
  public inputEvent: EventEmitter<any> = new EventEmitter<any>();

  public id: string = Math.random().toString();
  public IconsType = IconsType;

  constructor(public translate: TranslateService) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && this.formControl) {
      changes.disabled.currentValue
        ? setTimeout(() => this.formControl?.disable())
        : setTimeout(() => this.formControl?.enable());
    }
  }

  public get formControl(): FormControl {
    return this.formGroup.get(this.name) as FormControl;
  }

  public emitChanges(value?): void {
    this.valueChanged.emit(value);
  }

  public onDateChange(event: string): void {
    this.timeValueChanged.emit(event);
  }

  public onRadioChange({ value }: MatRadioChange): void {
    this.radioValueChanged.emit(value);
  }

  public onBlur(): void {
    if(this.disableOnBlur) return;
    let value = this.formControl.value;
    if (value) {
      value = value.toString().trim();
    }
    this.formControl.patchValue(value);
    this.blurEvent.emit();
  }

  public getErrorMessage(): string {
    const errors = this.formControl.errors;
    if (this.formControl.hasError('required')) {
      return `${this.translate.instant('Please fill in')} ${this.translate.instant(this.errorMassage)}`;
    } else if (this.formControl.hasError('maxlength')) {
      const { actualLength, requiredLength }: any = errors.maxlength;
      return `${this.translate.instant('Max required length is')} ${requiredLength}, ${this.translate.instant('but actual is')} ${actualLength}`;
    } else if (this.formControl.hasError('nameInvalid')) {
      return `${this.translate.instant('This tag name already exist please enter another tag name')}`;
    } else if (this.formControl.hasError('minlength')) {
      const { actualLength, requiredLength }: any = errors.minlength;
      return `${this.translate.instant('Min required length is')} ${requiredLength}, ${this.translate.instant('but actual is')} ${actualLength}`;
    } else if (this.formControl.hasError('min')) {
      const { actual, min }: any = errors.min;
      return `${this.translate.instant('Min value is')} ${min}, ${this.translate.instant('but actual is')} ${actual}`;
    } else if (this.formControl.hasError('max')) {
      const { actual, max }: any = errors.max;
      return `${this.translate.instant('Max value is')} ${max}, ${this.translate.instant('but actual is')} ${actual}`;
    } else if (this.formControl.hasError('invalidDate')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('is Invalid')}`;
    } else if (this.formControl.hasError('matDatepickerFilter')) {
      const formGroup = this.formControl.parent.controls;
      const controlName = Object.keys(formGroup).find(name => this.formControl === formGroup[name]) || null;
      if (controlName === 'endDate')
        return this.translate.instant('End Date cannot be before Start Date');
      else if (controlName === 'startDate')
        return this.translate.instant('Start Date is invalid');
      else return this.translate.instant('Date is less than current date')
    } else if (this.formControl.hasError('onlySpaces')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('can\'t contain only spaces')}`;
    } else if (this.formControl.hasError('spacesOnTheSides')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('can\'t contain spaces on the sides')}`;
    } else if (this.formControl.hasError('pattern')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('is incorrect')}`;
    } else if (this.formControl.hasError('email')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('is incorrect')}`;
    } else if (this.formControl.hasError('clientDonorEmailUnique')) {
      return this.translate.instant('Profile with specified email already exists');
    } else if (this.formControl.hasError('clientDonorEmailUniqueAlternative')) {
      return this.translate.instant('Email is duplicate');
    } else if (this.formControl.hasError('emailIsCompareWithPrimary')) {
      return this.translate.instant('Email already used like primary');
    } else if (this.formControl.hasError('allocatedFundNameUnique')) {
      return this.translate.instant('Allocated Fund with specified name already exists');
    } else if (this.formControl.hasError('segmentNameUnique')) {
      return this.translate.instant('Sorry but you already have Segment with specified name');
    } else if (this.formControl.hasError('Mask error')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('is incorrect')}`;
    } else if (this.formControl.hasError('mask')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('is incorrect')}`;
    } else if (this.formControl.hasError('maxBuyCount')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('is incorrect')}`;
    } else if (this.formControl.hasError('soldCount')) {
      const { soldCount }: any = errors;
      return `${this.translate.instant('SoldCount', {value: soldCount})} `;
    } else if (this.formControl.hasError('quantityIncludedCount')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('is incorrect')}`;
    } else if (this.formControl.hasError('invalidTime')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('is incorrect')}`;
    } else if (this.formControl.hasError('matDatepickerMax')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('is incorrect')}`;
    } else if (this.formControl.hasError('matDatepickerMin')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('is incorrect')}`;
    } else if (this.formControl.hasError('containsPlaceholder')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('contains placeholder')} ${errors['containsPlaceholder']}. ${this.translate.instant('Please populate')} ${errors['containsPlaceholder']} ${this.translate.instant('box or remove placeholder')}`;
    } else if (this.formControl.hasError('matDatepickerFilter')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('is incorrect')}`;
    } else if (this.formControl.hasError('greaterThan')) {
      return `${this.formControl.getError('greaterThan')}`;
    } else if (this.formControl.hasError('existsInList')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('already exists in the list')}`;
    } else if (this.formControl.hasError('emailList')) {
      return `${this.translate.instant('Must use ; to separate emails')}`;
    } else if (this.formControl.hasError('deductibleAmount')) {
      return this.translate.instant('The deductible amount can only be as much as the ticket price');
    } else if (this.formControl.hasError('registrationFee')) {
      return `${this.translate.instant('The deductible amount can only be as much as the registration fee')}`;
    } else if (this.formControl.hasError('noEventEndDate')) {
      return `${this.translate.instant('Event End Date is unknown. Please, set Event End Date')}`;
    } else if (this.formControl.hasError('targetGreaterEventEndDate')) {
      return `${this.translate.instant('End Date is greater than Event End Date')}`;
    } else if (this.formControl.hasError('startDateTargetGreaterEventEndDate')) {
      return `${this.translate.instant('Start Date is greater than Event End Date')}`;
    } else if (this.formControl.hasError('endDateIsGreaterThanTicketEndDate')) {
      return `${this.translate.instant('End Date is greater than Ticket End Date')}`;
    } else if(this.formControl.hasError('startDateIsLowerThanTicketStartDate')) {
      return `${this.translate.instant('Start Date is lower than Ticket Start Date')}`;
    } else if(this.formControl.hasError('emailAddressesInvalid')) {
      return this.translate.instant("P2P.Some of the entered email addresses are invalid");
    }
      else if (this.formControl.hasError('hashtag')) {
      return `${this.translate.instant('Text can\'t contain more than')} ${errors['hashtag']} ${this.translate.instant('unique hashtags')}`;
    } /*else if (this.formControl.hasError('lengthCJK')) {
      const { actualLength, requiredLength }: any = errors.lengthCJK;
      return `${this.translate.instant('Max required length is')} ${requiredLength}, ${this.translate.instant('but actual is')} ${actualLength}`;
    }*/ else if (this.formControl.hasError('uniqueUrl')) {
      return this.translate.instant('Payment page with specified URL already exists');
    } else if (this.formControl.hasError('uniqueEventUrl')) {
      return this.translate.instant('Event page with specified URL already exists');
    } else if (this.formControl.hasError('tagNameUnique')) {
      return this.translate.instant('Sorry but you already have Tag with specified name');
    } else if (this.formControl.hasError('over200')) {
      return this.translate.instant('Sorry but you cannot create more than 200 custom active tags');
    } else if (this.formControl.hasError('middleMinValidator')) {
      return `${this.translate.instant('The entered value cannot be less than the maximum value of Junior-Level Donor, minimum value you can enter is')} ${errors.middleMinValidator.min}`;
    } else if (this.formControl.hasError('middleMaxValidator')) {
      return `${this.translate.instant('The entered value cannot be more than the minimum value of Major-Level Donor, maximum value you can enter is')} ${errors.middleMaxValidator.min}`;
    } else if (this.formControl.hasError('incorrectNumber')) {
      return `${this.translate.instant('The minimum value must be less than the maximum')}`;
    } else if (this.formControl.hasError('lowMaxValidator')) {
      return `${this.translate.instant('The entered value cannot be more than the maximum value of Mid-Level Donor, minimum value you can enter is')} ${errors.lowMaxValidator.min}`;
    } else if (this.formControl.hasError('majorMinValidator')) {
      return `${this.translate.instant('The entered value cannot be less than the maximum value of Mid-Level Donor, minimum value you can enter is')} ${errors.majorMinValidator.min}`;
    } else if (this.formControl.hasError('lowMaxMajorValidator')) {
      return `${this.translate.instant('The entered value cannot be more than the minimum value of Major-Level Donor, minimum value you can enter is')} ${errors.lowMaxMajorValidator.min}`;
    } else if (this.formControl.hasError('uniqueControlValueInFormArray')) {
      return this.translate.instant(this.errorMassage) + this.translate.instant(' is not unique');
    } else if (this.formControl.hasError('invalidLength')) {
      if(this.name == "fullName"){
        return `${this.translate.instant('Max length is')} ${this.maxLength}`;
      }else{
        return `${this.translate.instant('Max length is')} ${this.maxLength-1}`;
      }
    } else if (this.formControl.hasError('percent')) {
      const { actual, required }: any = errors.percent;
      return `${this.translate.instant('Sorry but you cannot set Coupon value more than 100%')}`;
    } else if (this.formControl.hasError('dollar')) {
      const { actual, required }: any = errors.dollar;
      return `${this.translate.instant('Sorry but you cannot set Coupon value more than Ticket price')} ($${required})`;
    } else if (this.formControl.hasError('previousYearDate')) {
      return `${this.translate.instant('Enter the')} ${this.translate.instant(this.errorMassage)} ${this.translate.instant('of the Previous Year')}`;
    } else if (this.formControl.hasError('amountNeedToBeLowered')) {
      return `${this.translate.instant('You must Update the amount to Save the Add-on')}`;
    } else if (this.formControl.hasError('addOnVsTicketQuantity')) {
      return `${this.translate.instant('This Quantity cannot exceed the ticket quantity identified for the Ticket Type')}`;
    } else if (this.formControl.hasError('addOnIncludeQuantity')) {
      return `${this.translate.instant('This Quantity cannot exceed the total quantity entered for the Add-On')}`;
    } else if (this.formControl.hasError('purchasedOrSelectedAddOn')) {
      return `${this.translate.instant('Due to the Add-on being purchased/selected, the quantity number cannot be less then purchased/selected number')}`;
    } else if (this.formControl.hasError('purchasedOrSelectedAddOnInclude')) {
      return `${this.translate.instant('Due to the Add-on Option being purchased/selected, the quantity number cannot be less then purchased/selected number')}`;
    } else if (this.formControl.hasError('numberOfVolunteersNeeded')) {
      return `${this.translate.instant('Number of Volunteers Needed cannot be less than the quantity of registered Volunteers')}`;
    } else if (this.formControl.hasError('promoCodeQuantityInvalid')) {
      return `${this.translate.instant('Number of Coupon Code quantity bigger than the quantity of Ticket(s)')}`;
    } else if (this.formControl.hasError('invalidPromoCodeLimit')) {
      const { invalidPromoCodeLimit }: any = errors;
      return `${this.translate.instant('Promo Code limit cannot be set to fewer than the number already selected.  Number selected: ')} ${invalidPromoCodeLimit}`;
    } else if (this.formControl.hasError('lessThanSold')) {
      return `${this.translate.instant('The number of tickets available cannot be less than the number of tickets already sold')}`
    } else if (this.formControl.hasError('searchCriteriaAreRequired')) {
      return this.translate.instant("DONORS.Search criteria are required");
    } else if (this.formControl.hasError('forbiddenConsecutiveCharacters')) {
      const forbiddenCharacters: string[] = this.formControl.getError('forbiddenConsecutiveCharacters')
      return `Consecutive use of given characters is not allowed: ${forbiddenCharacters.join(", ")}`
    } else if (this.formControl.hasError('forbiddenWhitespaceAfterCharacter')) {
      const followingWhitespaceCharacters: string[] = this.formControl.getError('forbiddenWhitespaceAfterCharacter');
      return  `Symbols should not be followed by a whitespace: ${followingWhitespaceCharacters.join(", ")}`;
    } else if (this.formControl.hasError('precedingCharacterBeforeLink')) {
      return 'Link should not be preceded by any character';
    }
  }

  public onKeydownEnter(event: KeyboardEvent): void {
    this.enterPressed.emit(event)
  }

  public onInput(event): void {
    if (this.onlyNumbers && !NUMBERS.includes(event.data)) {
      const currentValue = this.formControl.value;
      if (currentValue) {
        const symbol = currentValue.slice(-1);
        if (!NUMBERS.includes(symbol)) {
          this.formControl.setValue(currentValue.slice(0, -1));
        }
      }
    }
    this.inputEvent.emit(event);
  }
}
