<div class="before-unload-wrapper">
  <div class="before-unload-close">
    <i class="fas fa-times close-icon" (click)="onClose()" matRipple></i>
  </div>

  <span class="before-unload-title">{{data.title | translate: {value: data?.numberOfChanges} }}</span>

  <div class="before-unload-actions">
    <app-button type="stroked" label="{{data?.firstButtonName || 'YES' | translate}}" color="#0064be" (clickEvent)="onAccept()"
      width="{{data?.widthButton || '130'}}"></app-button>

    <app-button type="stroked" label="{{data?.secondButtonName || 'NO' | translate}}" color="#a9110e" (clickEvent)="onReject()"
      width="{{data?.widthButton || '130'}}"></app-button>
  </div>
</div>
