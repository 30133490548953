<div class="pie-chart-wrapper">
  <canvas
    baseChart
    width="950"
    height="950"
    [data]="pieChartData"
    [labels]="pieChartLabels"
    [chartType]="pieChartType"
    [options]="pieChartOptions"
    [colors]="pieChartColors"
    [legend]="pieChartLegend">
  </canvas>
</div>


