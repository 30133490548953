import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from '../base.model.service';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdentityUsersService extends BaseModelService<any> {
  constructor(protected injector: Injector) {
    super(injector);
  }
  protected get controllerName(): string {
    return 'api/UsersApi';
  }

  public checkEmail(email: string): Observable<boolean> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.authority}${this.controllerName}/check/${email}`;
        return this.http.get<boolean>(url);
      })
    );
  }

  public checkEmailExist(email: string): Observable<boolean> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.authority}${this.controllerName}/exist/${email}`;
        return this.http.get<boolean>(url);
      })
    );
  }
}
