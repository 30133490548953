<div class="donation-activity-popup-wrapper">
  <div class="donation-activity-popup-header">
    <span>{{popupTitle$ | async | translate}}</span>
    <app-cross-icon (click)="onClose()"></app-cross-icon>
  </div>

  <ng-container *ngIf="total$ | async as totalItems">
    <span class="donation-activity-popup-total">{{totalItems | number}} {{'Total' | translate}}</span>
  </ng-container>

  <div class="donation-activity-popup-actions">
    <app-button
      *ngIf="(popupType$ | async) === DonationActivityPopupType.AllDonations"
      type="stroked"
      [borderColor]="data.topDonationsButtonBorderColor"
      (clickEvent)="changeType(DonationActivityPopupType.TopDonations)"
    >
      <div class="button-label">
        <app-star-icon [color]="data.topDonationsButtonTextColor"></app-star-icon>
        <span [style.color]='data.topDonationsButtonTextColor'>{{'See Top Donations' | translate}}</span>
      </div>
    </app-button>

    <app-button
      *ngIf="(popupType$ | async) === DonationActivityPopupType.TopDonations"
      type="stroked"
      [borderColor]="data.viewAllBorderColor"
      (clickEvent)="changeType(DonationActivityPopupType.AllDonations)"
    >
      <div class="button-label">
        <app-list-icon [color]="data.viewAllButtonTextColor"></app-list-icon>
        <span [style.color]='data.viewAllButtonTextColor'>{{'See All Donations' | translate}}</span>
      </div>
    </app-button>

    <app-button
      *ngIf="data.showDonateNowButton"
      type="flat"
      [labelColor]="data.donateButtonColorText"
      color="{{data.donateButtonColor}}"
      (clickEvent)="donateNow()"
      class="donate-now"
    >{{data.donateButtonText}}</app-button>
  </div>

  <app-donation-activity-element
    [donations$]="donations$"
    [entity]="data.entity"
  ></app-donation-activity-element>

  <app-paginator
    *ngIf="(popupType$ | async) === DonationActivityPopupType.AllDonations"
    [totalItems]="total"
    (nextPage)="getNextPage($event)"
    (entriesPerPageChanged)="setEntriesPerPage($event)"
    [setDefaultPage]="setDefaultPage$"
    [entriesPerPageSettings]="[5, 10, 20]"
    [entriesPerPage]="entriesPerPage"
  ></app-paginator>

</div>
