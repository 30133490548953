import {BasePagingComponent} from '../paginator/base.paging.component';
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {EventParticipantsService} from '../../services/events/event.participants.service';
import {EventParticipantType} from '../../models/enum/event.participant.type';
import {EVENTS_ATTENDED_COLUMNS} from '../../constants';
import {EventService} from '../../services/events/event.service';
import {Filter} from '../../models/paging/filter.model';
import {FilterType} from '../../models/enum/filter.type';
import {formatDate} from '@angular/common';
import {Paging} from '../../models/paging/paging.model';
import {SortOrder} from '../../models/enum/sort.order';
import {Subscription} from 'rxjs';
import ColumnFilterModel from '../../models/filter-sort/column.filter.model';
import ColumnFilterOptionsModel from '../../models/filter-sort/column.filter.options.model';
import EventModel from '../../models/event/event.model';
import EventParticipantModel from '../../models/event/event.participant.model';
import FilterSortModel from '../../models/filter-sort/filter.sort.model';
import FormElementDataModel from '../../models/form.element.data.model';
import {UtilsComponent} from '../utils.component';
import {TicketStatus} from "../../models/event/event-ticket.model";

const FILTERED_FIELDS = ['eventID', 'createdOn', 'participantType', 'totalCost'];

@Component({
  selector: 'app-history-events-attended',
  templateUrl: './history-events-attended.component.html',
  styleUrls: ['./history-events-attended.component.scss']
})
export class HistoryEventsAttendedComponent extends BasePagingComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public clientDonorID: string = '';
  public columnsFilterOptions: ColumnFilterOptionsModel[] = [];
  public eventParticipants: EventParticipantModel[] = [];
  public events: EventModel[];
  public subscription: Subscription = new Subscription();
  public tableColumns: string[] = [];

  public filterSortConfig: FilterSortModel = {
    sortField: 'createdOn',
    sortOrder: SortOrder.Descending,
    columnFilters: []
  };

  constructor(
    private eventParticipantsService: EventParticipantsService,
    private eventService: EventService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.tableColumns = EVENTS_ATTENDED_COLUMNS;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.clientDonorID.currentValue) {
      this.getEventParticipants([]);
      this.getTotal([]);
      this.getDistinctValues([]);
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getEventParticipants(currentFilters: Filter[]): void {
    const baseFilter: Filter[] = [
      {
        field: 'clientDonorID',
        value: this.clientDonorID,
        type: FilterType.Equal,
      },
      {
        field: 'Ticket.TicketStatus',
        value: TicketStatus.deleted.toString(),
        type: FilterType.NotEqual
      }
    ];
    const filters: Filter[] = [...baseFilter, ...currentFilters];
    const paging: Paging = {
      first: this.getFirstItemOnPage(),
      rows: this.entriesPerPage,
      includeDependencies: true,
      selectFields: ['eventID', 'totalCost', 'createdOn', 'participantType', 'participantEventComment', 'totalCost', 'Event'],
      sortOrder: this.filterSortConfig.sortOrder,
      sortField: this.filterSortConfig.sortField,
      filters,
    };
    this.subscription.add(
      this.eventParticipantsService.getModelList(paging)
        .subscribe((eventParticipants: EventParticipantModel[]) => {
          this.eventParticipants = eventParticipants;
        })
    );
  }

  private getTotal(currentFilters: Filter[]): void {
    const baseFilter: Filter[] = [
      {
        field: 'clientDonorID',
        value: this.clientDonorID,
        type: FilterType.Equal,
      },
      {
        field: 'Ticket.TicketStatus',
        value: TicketStatus.deleted.toString(),
        type: FilterType.NotEqual
      }
    ];
    const filters: Filter[] = [...baseFilter, ...currentFilters];
    const paging: Paging = {
      includeDependencies: false,
      filters
    };
    this.subscription.add(
      this.eventParticipantsService.getTotal(paging)
        .subscribe((total: number) => {
          this.total = total;
        })
    );
  }

  public getNextPage(page: number): void {
    this.nextPage(page, () =>
      this.getEventParticipants(this.tableFilter)
    );
  }

  public setEntriesPerPage(amount: number): void {
    this.entriesPerPageChanged(amount, () =>
      this.getEventParticipants(this.tableFilter)
    );
  }

  private getDistinctValues(currentFilters: Filter[]): void {
    const baseFilter: Filter[] = [
      {
        field: 'clientDonorID',
        value: this.clientDonorID,
        type: FilterType.Equal,
      },
      {
        field: 'Ticket.TicketStatus',
        value: TicketStatus.deleted.toString(),
        type: FilterType.NotEqual
      }
    ];
    const filters: Filter[] = [...baseFilter, ...currentFilters];
    const paging: Paging = {
      first: this.getFirstItemOnPage(),
      rows: this.entriesPerPage,
      includeDependencies: false,
      filters,
      selectFields: FILTERED_FIELDS
    };
    this.subscription.add(
      this.eventParticipantsService.getDistinctValues(paging).subscribe(this.onDistinctValuesReceived.bind(this))
    );
  }

  private onDistinctValuesReceived(distinctValues: any): void {
    const ids: string[] = distinctValues.eventID.filter((item) => item !== null && item !== undefined && item !== '');
    if (ids && ids.length > 0) {
      this.getEventsByIds(ids, (events: EventModel[]) => {
        this.columnsFilterOptions = FILTERED_FIELDS.map((column: string) => {
          const optionsList = distinctValues[column].filter((item) => item !== null && item !== undefined && item !== '');
          let options;
          switch (column) {
            case 'eventID':
              options = optionsList.map((id: string) => {
                const eventName = events.find((event) => event.id === id).name;
                return { label: eventName, value: id };
              });
              break;
            case 'participantType':
              const participantTypes = {
                [EventParticipantType.Speaker]: 'Speaker',
                [EventParticipantType.Sponsor]: 'Sponsor',
                [EventParticipantType.Standard]: 'Standard',
                [EventParticipantType.Celebrity]: 'Celebrity'
              };
              options = optionsList.map((type: EventParticipantType) => ({ label: participantTypes[type], value: type }));
              break;
            case 'createdOn':
              const dateSet = new Set(optionsList.map((option: string) => formatDate(option, 'MMMM d, y', 'en-US')));
              options = [...dateSet].map((label: string) => ({ label, value: '' }));
              optionsList.forEach((option: string) => {
                const dateOption = options.find(({ label }: FormElementDataModel) => label === formatDate(option, 'MMMM d, y', 'en-US'));
                dateOption.value += `"${option}",`;
              });
              break;
            default:
              options = optionsList.map((option: string) => ({ label: option, value: option }));
          }
          return { columnName: column, options };
        });
      });
    }
  }

  private get tableFilter(): Filter[] {
    if (!this.filterSortConfig.columnFilters.length) {
      return [];
    } else {
      return this.filterSortConfig.columnFilters.map((columnFilter: ColumnFilterModel) => {
        let values;
        if (columnFilter.field === 'createdOn') {
          values = columnFilter.values.map((item: string) => `${item}`).join('').slice(0, -1);
        } else {
          values = columnFilter.values.map((item: string) => `"${item}"`).join(',');
        }
        return {
          field: columnFilter.field,
          value: `array[${values}]`,
          type: FilterType.Equal,
        };
      });
    }
  }

  private getEventsByIds(ids: string[], callback: (events: EventModel[]) => void): void {
    const values = ids.map((item: string) => `"${item}"`).join(',');
    const paging: Paging = {
      includeDependencies: false,
      selectFields: ['id', 'name'],
      filters: [
        {
          field: 'id',
          value: `array[${values}]`,
          type: FilterType.Equal,
        }
      ],
    };
    this.subscription.add(
      this.eventService.getModelList(paging).subscribe(callback.bind(this))
    );
  }

  public onCurrentFilterChanged(columnFilter: ColumnFilterModel): void {
    this.filterSortConfig.columnFilters = UtilsComponent.updateColumnFilters(columnFilter, this.filterSortConfig);
    this.onFilterChanged();
    this.getEventParticipants(this.tableFilter);
    this.getTotal(this.tableFilter);
  }

  public onSortChanged(event: FilterSortModel): void {
    this.filterSortConfig.sortField = event.sortField;
    this.filterSortConfig.sortOrder = event.sortOrder;
    this.getEventParticipants(this.tableFilter);
  }
}
