export enum PaymentRecurrencyStatus {
  Active = 0,
  Cancelled = 1,
  Completed = 2,
  FailedPay = 3,
  Pending = 4,
  Pledged = 5,
  Scheduled = 6,
  Declined = 7,
  CancelledAll = 8,
  HistoricalEntry = 9
}
