import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-string-spinner',
  templateUrl: './string-spinner.component.html',
  styleUrls: ['./string-spinner.component.scss']
})
export class StringSpinnerComponent {
  @Input() public color: string = '#d4e6ff';
}

