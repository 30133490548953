import { FbTemplateStatusType } from '../form-builder/fb.template.model';
import { TemplateUsageType } from './template.usage.type';

export default class SourceTemplateModel {
  id?: string;
  clientID?: string;
  content?: string;
  createdByID?: string;
  createdByUserName?: string;
  createdOn?: string;
  disableDate?: string;
  disableTime?: string;
  disableTimeZone?: number;
  frendlyURL?: string;
  messageWhenDisabled?: string;
  name?: string;
  sourceID?: string;
  sourceType?: TemplateSourceType;
  templateId?: string;
  templateStatus?: FbTemplateStatusType;
  templateUsageType?: TemplateUsageType;
  isSendDonationEmailDisabled?: boolean;
  customThankYouPageUrl?: string;
  isCustomThankYouPage?: boolean;
  notifyEmails?: string;
  emailNotification?: string;
  subjectEmailNotification?: string;
  emailToDonor?: string;
  subjectEmailToDonor?: string;
  parentID?: string;
  recurringScheduleCreationSubject?:string;
  recurringScheduleCreationBcc?:string;
  recurringScheduleCreationBody?:string;
  recurringNextScheduledDonationDateSubject?:string;
  recurringNextScheduledDonationDateBody?:string;
  recurringThankYouSubject?:string;
  recurringThankYouBody?:string;
  recurringDonationDidNotProcessSubject?:string;
  recurringDonationDidNotProcessBcc?:string;
  recurringDonationDidNotProcessBody?:string;
  recurringCancelledSubject?:string;
  recurringCancelledBcc?:string;
  recurringCancelledBody?:string;
  recurringRestartedSubject?:string;
  recurringRestartedBcc?:string;
  recurringRestartedBody?:string;
}

export enum TemplateSourceType {
  Client = 0,
  Event = 1,
  Campaign = 2,
  SocialMedia = 3,
}
