import { Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseFormElementComponent } from '../base.form.element.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import {NgxMaterialTimepickerTheme} from 'ngx-material-timepicker';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss', '../base.form.element.component.scss' , '../new.base.form.element.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class TimeInputComponent extends BaseFormElementComponent implements OnChanges, OnInit {

  public dayPeriod: string = 'am';
  public hours: string = '';
  public minutes: string = '';
  @ViewChild('minutesRef') minutesElement: ElementRef;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  constructor(public translate: TranslateService) {
    super(translate);
  }

  public ngOnInit(): void {
    if (this.formControl) {
      this.patchValues(this.formControl.value);
    }
    this.formControl.valueChanges.subscribe((value: string) => {
      this.patchValues(value);
    })
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public setDayPeriod(value: string): void {
    this.dayPeriod = value;
    this.setControlValue();
    this.menuTrigger.closeMenu();
  }

  public hoursKeyPressed($event: KeyboardEvent): void {
    switch ($event.keyCode) {
      case keyboardKeyCode.arrowUpCode:
        this.hoursIncrease();
        break;
      case keyboardKeyCode.arrowDownCode:
        this.hoursDecrease();
        break;
      case keyboardKeyCode.enterCode:
        this.minutesElement.nativeElement.focus();
        break;
      case keyboardKeyCode.pKeyCode:
        this.dayPeriod = 'pm';
        break;
      case keyboardKeyCode.aKeyCode:
        this.dayPeriod = 'am';
        break;
      default:
        this.focusOnMinuteInput();
        break;
    }
    this.setControlValue();
  }

  public hoursChanged(): void {
    if (!this.hours || (this.hours.length === 2 && +this.hours > 12)) {
      this.hours = '';
    } else if (this.hours.length === 2 && this.hours[0] === '0') {
      this.hours = this.hours[1];
    }
    this.setControlValue();
  }

  public minuteKeyPressed($event: KeyboardEvent): void {
    switch ($event.keyCode) {
      case keyboardKeyCode.arrowUpCode:
        this.minuteIncrease();
        break;
      case keyboardKeyCode.arrowDownCode:
        this.minuteDecrease();
        break;
      case keyboardKeyCode.enterCode:
        this.menuTrigger.openMenu();
        break;
      case keyboardKeyCode.pKeyCode:
        this.dayPeriod = 'pm';
        break;
      case keyboardKeyCode.aKeyCode:
        this.dayPeriod = 'am';
        break;
      default:
        break;
    }
    this.setControlValue();
  }

  public amPmKeyPressed($event: KeyboardEvent): void {
    $event.keyCode === keyboardKeyCode.tabKeyCode ? this.menuTrigger.openMenu() : null;
    this.setControlValue();
  }

  public minutesChanged(): void {
    if (!this.minutes || (this.minutes.length === 2 && +this.minutes > 60)) {
      this.minutes = '';
    } else if (this.minutes.length === 1) {
      this.minutes = '0' + this.minutes;
    } else if (this.minutes.length === 2 && this.minutes === '60') {
      this.minutes = '00';
    }
    this.setControlValue();
  }

  private minuteIncrease(): void {
    this.minutes = this.increase(this.minutes, 59, false);
  }

  private minuteDecrease(): void {
    this.minutes = this.decrease(this.minutes, 0, false);
  }

  private increase(time: string, limiter: number, isHours: boolean): string {
    if (isHours) {
      time = +time >= limiter ? '' : ((+time + 1).toString());
    } else {
      if (+time <= 9) {
        time = (+time === 9) ? ((+time + 1).toString()) : ('0' +  ((+time + 1).toString()));
      } else {
        time = +time >= limiter ? '' : ((+time + 1).toString());
      }
    }
    return time;
  }

  private decrease(time: string, limiter: number, isHours: boolean): string {
    if (isHours) {
      time = +time <= limiter ? '' : ((+time - 1).toString());
    } else {
      if (+time <= 10) {
        time = (+time <= limiter) ? '' : ('0' +  ((+time - 1).toString()));
      } else {
        time = +time <= limiter ? '' : ((+time - 1).toString());
      }
    }
    return time;
  }

  private hoursIncrease(): void {
    this.hours = this.increase(this.hours, 12, true);
  }

  private hoursDecrease(): void {
    this.hours = this.decrease(this.hours, 0, true);
  }

  private focusOnMinuteInput(): void {
    if ((this.hours.length === 1 && +this.hours !== 0 && +this.hours !== 1) || (this.hours.length === 2 && +this.hours <= 12)) {
      this.minutesElement.nativeElement.focus();
    }
  }

  private setControlValue(): void {
    if (this.hours.length && this.minutes.length) {
      this.formControl.setValue(`${this.hours}:${this.minutes} ${this.dayPeriod}`);
      this.formControl.markAsTouched();
    } else if (this.formControl.value !== '') {
      this.formControl.setValue('');
      this.formControl.markAsTouched();
    }
  }

  private patchValues(value: string): void {
    if (!value) {
      this.hours = '';
      this.minutes = '';
      this.dayPeriod = 'am';
    } else {
      const hours = value.split(':')[0];
      if (hours.length === 2 && hours[0] === '0') {
        this.hours = hours[1];
      } else {
        this.hours = hours;
      }
      const minutes = value.split(' ')[0].split(':')[1];
      if (this.minutes.length === 1) {
        this.minutes = '0' + minutes;
      } else {
        this.minutes = minutes;
      }
      this.minutes = value.split(' ')[0].split(':')[1];
      this.dayPeriod = value.split(' ')[1].toLowerCase();
    }

  }

}

export enum keyboardKeyCode {
  arrowUpCode = 38,
  arrowDownCode = 40,
  enterCode = 13,
  pKeyCode= 80,
  aKeyCode = 65,
  tabKeyCode = 9
}
