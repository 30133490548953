import { BaseModelService } from '../base.model.service';
import { flatMap, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { InfoLogModel } from 'src/app/models/reports/info.log.model';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Paging } from 'src/app/models/paging/paging.model';
import { PagingHelper } from '../helper/paging.helper';
import { TotalRowsModel } from 'src/app/models/paging/totalrow.model';

@Injectable({
  providedIn: 'root'
})
export class InfoLogService extends BaseModelService<InfoLogModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }
  protected get controllerName(): string {
    return 'InfoLog';
  }

  public getInfoLogFeatures(filter?: Paging): Observable<InfoLogModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<InfoLogModel[]>(
          `${config.apiURL}/${this.controllerName}/Features`,
          { params }
        );
      })
    );
  }

  public getInfoLogFeaturesTotal(filter?: Paging): Observable<number> {
    const params: HttpParams = PagingHelper.getPagingParams(filter, false);
    return this.configService.loadConfig().pipe(flatMap(config => {
      return this.http.get<TotalRowsModel>(`${config.apiURL}/${this.controllerName}/FeaturesTotal`, { params }).pipe(map(model => model.total));
    }));
  }
}