import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FormBuilderStateService} from './form-builder.state.service';
import FbTemplateModel, {FbTemplateStatusType, OptionsViewType} from '../../models/form-builder/fb.template.model';
import {ClientModel} from '../../models/client/client.model';
import {ClientModuleService} from '../../routes/clients/client.module.service';
import EventModel from '../../models/event/event.model';
import {CampaignModel} from '../../models/campaigns/campaign.model';
import SocialPostModel from '../../models/socialMedia/social.post.model';
import {ClientPaymentModel} from '../../models/client/client.payment.model';
import {CornerstoneService} from './cornerstone.service';
import {BehaviorSubject, from, fromEvent, Observable, of, Subscription} from 'rxjs';
import {UtilsComponent} from '../utils.component';
import {DEFAULT_PAYMENT_FORM} from '../../constants';
import TemplatePayload from '../../models/templates/template.payload';
import {Guid} from 'guid-typescript';
import {AzureFileModel} from '../../models/files/azure-file.model';
import html2canvas, {Options} from 'html2canvas';
import {AzureUploadFileService} from '../../services/lookup/azure-upload-file.service';
import {Filter} from '../../models/paging/filter.model';
import {FilterType} from '../../models/enum/filter.type';
import {TemplateStatus} from '../../models/enum/template.status';
import {TemplateUsageType} from '../../models/templates/template.usage.type';
import {TemplateCreationType} from '../../models/templates/template.creation.type';
import {Paging} from '../../models/paging/paging.model';
import {ClientIdStateService} from '../../services/client.module.state/client.id.state.service';
import {TemplateModelService} from '../../services/templates/template.model.service';
import {catchError, delay, first, map, switchMap, tap} from 'rxjs/operators';
import TemplateModel from '../../models/templates/template.model';
import {FormGroup} from '@angular/forms';
import { AppStripeService } from './app.stripe.service';
import {ToastrService} from 'ngx-toastr';
import {AdminConfirmationComponent} from '../../routes/admin/admin-confirmation/admin-confirmation.component';
import {MatDialog} from '@angular/material/dialog';
import { PaymentServiceType } from '../../models/enum/payment.service.type';
import { TranslateService } from '@ngx-translate/core';
import { StripeACHService } from './stripe-ach.service';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
  providers: [FormBuilderStateService, CornerstoneService, AppStripeService, StripeACHService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FormBuilderComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() public readOnly: boolean;
  @Input() public templateIsSaved$: Observable<boolean>;
  @Input() public publishStepHidden: boolean = false;
  @Input() public isAdminPortal: boolean = false;
  @Input() public socialMediaPublishStep: boolean = false;
  @Input() public socialMediaPostForm: FormGroup = null; // for SocialMedia publish step
  @Input() public postFriendlyUrl$: BehaviorSubject<string>; // triggered in PostComponent for SocialMedia publish step
  public screenshotsInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public templateReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @ViewChild('publishLabel') private publishLabel: ElementRef;

  private subscription: Subscription = new Subscription();

  public currentTab: number = 0;

  public apiURL: string = localStorage.getItem('apiurl');

  private nextTab: number = 0;

  constructor(
    public fbss: FormBuilderStateService,
    private clientModuleService: ClientModuleService,
    public cornerstoneService: CornerstoneService,
    private uploadService: AzureUploadFileService,
    private cdr: ChangeDetectorRef,
    private clientIdStateService: ClientIdStateService,
    private templateModelService: TemplateModelService,
    public appStripeService: AppStripeService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    public translate: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.subscription.add(
      this.fbss.invalidTemplate$.subscribe(() => {
        this.currentTab = 1;
        this.cdr.markForCheck();
      })
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.readOnly) {
      this.fbss.setReadOnly(this.readOnly);
    }
    if (changes.isAdminPortal) {
      this.fbss.setAdminPortalRestrictions(this.isAdminPortal);
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngAfterViewInit(): void {
    if (!this.publishLabel) {
      return;
    }
    this.subscription.add(
      fromEvent(this.publishLabel.nativeElement.parentElement.parentElement, 'click')
        .subscribe(() => this.onPublishClick())
    );
  }

  private onPublishClick(): void {
    this.templateIsSaved$
      .pipe(
        first(),
        tap((isSaved) => {
          if (!isSaved) {
            this.triggerTemplatePayload();
            this.nextTab = 2;
          }
        })
      )
      .subscribe();
  }

  public setTemplate(template: FbTemplateModel): void {
    this.templateReady$.next(true);
    if (!template) {
      template = UtilsComponent.clone(DEFAULT_PAYMENT_FORM);
    }
    this.fbss.setTemplate(template);
    this.resetLastTemplateState();
    this.fbss.resetHistoryStorage();
    this.currentTab = 0;
  }

  public setClient(client: ClientModel): void {
    this.fbss.setClient(client);
  }

  public setEntity(entity: EventModel | CampaignModel | SocialPostModel | ClientPaymentModel): void {
    this.fbss.setEntity(entity);
  }

  public setPaymentServiceType({paymentServiceType, id}: ClientModel): void {
    this.fbss.setPaymentServiceType(paymentServiceType);
    /*if (paymentServiceType === PaymentServiceType.Cornerstone) {
      this.subscription.add(
        this.fbss.secondPageActive$.asObservable()
          .pipe(
            filter((value) => !!value)
          )
          .subscribe(() => this.cornerstoneService.configureCornerstone(id, this.fbss.secondPaymentForm))
      );
    }*/
    if (paymentServiceType === PaymentServiceType.Stripe) {
      this.appStripeService.configureStripe(id);
    }
  }

  public getTemplatePreviewImage(payload: TemplatePayload, callback: (payload: TemplatePayload, url: string) => void): void {
    this.screenshotsInProgress$.next(true);
    if (this.nextTab !== 2) {
      this.nextTab = this.currentTab;
    }
    this.currentTab = 0;
    this.fbss.setOptionsView(OptionsViewType.None);
    setTimeout(() => {
      const container = document.getElementById('fb-template-container');
      const containerWidth = container.getBoundingClientRect().width;
      const options: Options = {
        useCORS: true,
        removeContainer: true,
        backgroundColor: '#fff',
        scale: (428 / containerWidth),
        ignoreElements: function (node) {
          return node.nodeName === 'IFRAME';
        },
        logging: false,
      };
      html2canvas(container, options)
        .then(canvas => {
          const dataUrl = canvas.toDataURL('image/png');
          const name = `${Guid.create()}.png`;
          const azure: AzureFileModel = {
            file: dataUrl.substring(22, dataUrl.length),
            name,
            folder: 'images'
          };
          this.currentTab = this.nextTab;
          this.nextTab = 0;
          this.subscription.add(
            this.uploadService.addModel(azure).subscribe(() => {
              const url = `${this.apiURL}/AzureFileStorage/image/${name}`;
              callback(payload, url);
              this.screenshotsInProgress$.next(false);
            })
          );
        })
        .catch(error => {
          this.currentTab = this.nextTab;
          this.nextTab = 0;
          //console.log(error);
          this.screenshotsInProgress$.next(false);
        });
    }, 0);
  }

  public templateImage(): Observable<string> {
    this.screenshotsInProgress$.next(true);
    if (this.nextTab !== 2) {
      this.nextTab = this.currentTab;
    }
    this.currentTab = 0;
    this.fbss.setOptionsView(OptionsViewType.None);
    return of(null)
      .pipe(
        delay(0),
        switchMap(() => {
          const container = document.getElementById('fb-template-container');
          const containerWidth = container.getBoundingClientRect().width;
          const options: Options = {
            useCORS: true,
            removeContainer: true,
            backgroundColor: '#fff',
            scale: (428 / containerWidth),
            logging: false,
          };
          return from(html2canvas(container, options));
        }),
        catchError(err => {
          this.currentTab = this.nextTab;
          this.nextTab = 0;
          this.screenshotsInProgress$.next(false);
          return err;
        }),
        switchMap((canvas: any) => {
          const dataUrl = canvas.toDataURL('image/png');
          const name = `${Guid.create()}.png`;
          const azure: AzureFileModel = {
            file: dataUrl.substring(22, dataUrl.length),
            name,
            folder: 'images'
          };
          this.currentTab = this.nextTab;
          this.nextTab = 0;
          return this.uploadService.addModel(azure)
            .pipe(
              map(() => {
                this.screenshotsInProgress$.next(false);
                return `${this.apiURL}/AzureFileStorage/image/${name}`;
              })
            );
        }),
      );
  }

  public get template(): FbTemplateModel {
    return this.fbss.template$.getValue();
  }

  public setTemplatePayload(templatePayload: TemplatePayload): void {
    console.log(templatePayload)
    this.fbss.patchTemplateSettingsForm(templatePayload);
  }

  public get updateTemplate(): Observable<TemplatePayload> {
    return this.fbss.forceSaveTemplateEmitter;
  }

  public triggerTemplatePayload(): void {
    const template = this.fbss.template$.getValue();
    this.fbss.imgViewHandler(template);
    if (this.fbss.isTiersEmptyTier(template)) {
      this.toastrService.error('Please fill all mandatory fields');
      return
    }
    if (this.fbss.isTierHasImg(template)) {
      const config = {
        data: {
          img: '/assets/images/icon-donor-heart.png',
          title: `${this.translate.instant('An image was not uploaded for all the tiers you have entered. Would you like to upload the default picture?')}`
        }
      };
      this.subscription.add(
        this.dialog.open(AdminConfirmationComponent, config).afterClosed()
          .subscribe((res: boolean) => {
            if (res) {
              this.fbss.triggerTemplatePayload();
            }
          })
      );
      return;
    }
    this.fbss.triggerTemplatePayload();
  }

  public showErrorFields(): void {
    this.currentTab = 1;
  }

  public setDefaultTemplateName(): void {
    if (this.fbss.templateSettingsForm.get('fbTemplateName').value) {
      return;
    }

    const paging: Paging = {
      includeDeleted: false,
      includeDependencies: false,
      filters: this.commonFilter
    };

    this.subscription.add(
      this.templateModelService.getTotal(paging)
        .pipe(
          switchMap((total: number) => {
            return this.isNameUnique(total);
          })
        )
        .subscribe()
    );
  }

  private isNameUnique(total: number): Observable<boolean> {
    total += 1;
    const paging: Paging = {
      includeDeleted: false,
      includeDependencies: false,
      filters: [
        ...this.commonFilter,
        {
          field: 'name',
          value: `Donation Form ${total}`,
          type: FilterType.Equal
        }
      ]
    };
    return this.templateModelService.getTotal(paging)
      .pipe(
        switchMap((totalResponse: number) => {
          if (totalResponse !== 0) {
            return this.isNameUnique(total);
          } else {
            this.fbss.templateSettingsForm.get('fbTemplateName').setValue(`Donation Form ${total}`);
            return of(null);
          }
        }),
      );
  }

  private get commonFilter(): Filter[] {
    const filters: Filter[] = [
      {
        field: 'templateStatus',
        value: TemplateStatus.Completed.toString(),
        type: FilterType.Equal
      },
      {
        field: 'templateUsageType',
        value: TemplateUsageType.DonationForms.toString(),
        type: FilterType.Equal
      }
    ];
    if (this.clientIdStateService.selectedRelationshipClientId) {
      filters.push({
        field: 'clientID',
        value: this.clientIdStateService.selectedRelationshipClientId,
        type: FilterType.Equal
      });
    } else {
      filters.push({
        field: 'templateCreationType',
        value: TemplateCreationType.Admin.toString(),
        type: FilterType.Equal
      })
    }
    return filters;
  }

  public get actualFriendlyUrl(): string {
    return this.fbss.publishForm.get('friendlyURL').value;
  }

  public setKnownFriendlyUrl(url: string): void {
    this.fbss.publishForm.get('friendlyURL').setValue(url);
    this.fbss.publishForm.get('currentFriendlyUrl').setValue(url);
  }

  public get addNewSourceTemplateModel(): Observable<string> {
    return this.fbss.addNewSourceTemplateModel$.asObservable();
  }

  public get updateDefaultClientTemplate(): Observable<boolean> {
    return this.fbss.updateDefaultClientTemplate$.asObservable();
  }

  public setIsTemplateDefaultForClient(isDefault: boolean): void {
    this.fbss.publishForm.get('setAsAuxiliaDefault').setValue(isDefault);
  }

  public get templateWithChanges(): Partial<TemplateModel> {
    return this.fbss.lastTemplateState;
  }

  public resetLastTemplateState(): void {
    this.fbss.lastTemplateState = null;
  }

  /** if status inactive, remove this template from client default */
  public checkStatusAndUpdateDefaultClientTemplate(): void {
    const fbTemplateStatus = this.fbss.templateSettingsForm.get('fbTemplateStatus');
    const setAsAuxiliaDefault = this.fbss.publishForm.get('setAsAuxiliaDefault');
    if (fbTemplateStatus && fbTemplateStatus.value === FbTemplateStatusType.Inactive && setAsAuxiliaDefault && setAsAuxiliaDefault.value) {
      setAsAuxiliaDefault.setValue(false);
      this.fbss.updateDefaultClientTemplate$.next(false);
    }
    this.fbss.resetHistoryStorage();
  }

  public get isReadyForSave(): boolean {
    return this.fbss.templateSettingsFormValid();
  }
}
