import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-fb-checkbox',
  templateUrl: './fb-checkbox.component.html',
  styleUrls: ['./fb-checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FbCheckboxComponent {
  @Input() public value: boolean = false;
  @Input() public color: string = '#0064be';
  @Input() public borderColor = '#575757';
  @Input() public tooltip: string = '';
  @Input() public topLabel: string = '';
  @Input() public readOnly: boolean = false;
  @Input() public noWrap: boolean = false;

  @Output() public clickEvent: EventEmitter<void> = new EventEmitter<void>();

  public onClick(): void {
    if (this.readOnly) {
      return;
    }
    this.clickEvent.emit();
  }

}
