import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import MjmlElementModel from '../../../../../models/templates/mjml.element.model';
import { EventCommentWallModel } from '../../../../../models/event/event.comment.wall.model';
import { TIME_RANGE } from '../../../../../constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-comment-wall-element',
  templateUrl: './comment-wall-element.component.html',
  styleUrls: ['./comment-wall-element.component.scss']
})
export class CommentWallElementComponent {
  @Input() public comments$: Observable<EventCommentWallModel[]>;
  @Input() public entity: MjmlElementModel;

  constructor(public translate: TranslateService) {}

  public get commentRowStyles(): {[key: string]: string} {
    const attributes = this.entity.attributes;
    const fontFamily = attributes['font-family'] || 'Roboto';
    const fontSize = attributes['font-size'] || '14px';
    return {
      'font-family': fontFamily,
      'font-size': fontSize,
    };
  }

  public getTime(model: EventCommentWallModel): string {
    const value = Math.floor(model.createdTimeAgo);
    const timeRange = TIME_RANGE[model.createdTimeAgoType];
    const units = value > 1 ? this.translate.instant(timeRange.plural) : this.translate.instant(timeRange.singular);
    return `${value} ${units}`;
  }

}
