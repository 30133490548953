<div class="home-wrapper">
  <div class="home-content">
    <div class="home-top-image"></div>
    <div class="home-content-body">
      <span class="home-content-body-title">{{'AUTH.Login to Your Account' | translate}}</span>
      <form class="form-body">
        <mat-form-field appearance="outline">
          <mat-label>{{'Email Address' | translate}}</mat-label>
          <input [formControl]="userName" matInput (blur)="onBlur(userName)">
        </mat-form-field>
        <app-password-input errorMassage="Password" [formGroup]="loginForm" label="Password" name="password" (keydownEnter)="signIn()" [low]="true">
        </app-password-input>
        <div class="home-content-body-button mt-3">
          <app-button type="flat" [isFullWidth]="true" label="LOGIN" (clickEvent)="signIn()" color="#0064be"></app-button>
          <app-button type="flat" [isFullWidth]="true" style="margin-top: 10px;" label="Continue with Google" (clickEvent)="continueWithGoogle()" color="#dd4a39"></app-button>
          <app-button type="flat" [isFullWidth]="true" style="margin-top: 10px;" label="Continue with Facebook" (clickEvent)="continueWithFacebook()" color="#4267b2"></app-button>

          <app-button type="basic" label="Forgot Your Password?" (clickEvent)="onForgotPassword()" color="#0064be"></app-button>
        </div>
      </form>
      <span class="home-content-body-second-title">{{'AUTH.Don’t have an Account?' | translate}}</span>
      <div class="home-content-body-button">
        <app-button type="flat" label="SIGN UP" [isFullWidth]="true" (clickEvent)="signUp()" color="#0064be"></app-button>
      </div>
    </div>
  </div>
</div>
