import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-multiselect-dialog',
  templateUrl: './multiselect-dialog.component.html',
  styleUrls: ['./multiselect-dialog.component.scss']
})
export class MultiselectDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public form: FormGroup = this.formBuilder.group({
    selectControl: ['', [Validators.required]]
  });

  constructor(
    public dialogRef: MatDialogRef<MultiselectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) { }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public onOKClick(): void {
    this.dialogRef.close(this.form.controls.selectControl.value);
  }
}
