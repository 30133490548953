<div class="app-components-table-wrapper-encapsulation">

  <table
    mat-table
    [dataSource]="tableSource"
    class="app-components-table"
    *ngIf="tableSource"
  >
    <ng-container
      [matColumnDef]="column"
      *ngFor="let column of tableDataColumns"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        class="table-header"
      >
        <div class="table-header-title-wrapper">
          <span *ngIf="column === 'isSelected'" class="column-filter" style="margin-left: -10px;">
            <mat-checkbox
              [checked]="allEmailsSelected"
              [indeterminate]="someEmailsSelected"
              (change)="selectAllEmailsCheckbox($event)"
              color="primary"
            >
            </mat-checkbox>
          </span>
          <span class="title" *ngIf="column !== 'isSelected'">{{getColumnTitle(column) | translate}}</span>
          <span class="column-filter" (click)="sortTable(column)" *ngIf="canSort(column)">
            <i class="fas fa-sort-alpha-down sort-icon" *ngIf="checkColumn(column) && getSortOrder(column) === SortOrder.Ascending"></i>
            <i class="fas fa-sort-alpha-down-alt sort-icon" *ngIf="checkColumn(column) && getSortOrder(column) === SortOrder.Descending"></i>
            <i class="fas fa-sort action-icon" *ngIf="!checkColumn(column)"></i>
          </span>
          <span class="column-filter" *ngIf="canFilter(column)">
            <app-multiselect [columnFilterOptions]="getSelectOptions(column)"
                             (selectedOptions)="selectedOptions($event)">
              <i class="fas fa-filter action-icon"
                 [class.active-filter]="isActiveFilter(column)"></i>
            </app-multiselect>
          </span>
        </div>
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="app-components-table-cell"
        [appTooltip]="tooltip"
        (mouseenter)="mouseOver(tooltip)"
        (mouseleave)="mouseLeave(tooltip)"
        (click)="toggleTooltip(tooltip, column)"
      >
        <div
          matTooltip="{{element[column] | translate}}"
          #tooltip="matTooltip"
          matTooltipPosition="above"
          matTooltipClass="table-tooltip"
        ></div>
        <div
          class="table-cell-wrapper"
          [ngSwitch]="column"
        >
          <span *ngSwitchCase="'isSelected'" class="table-cell-wrapper" style="margin-left: -10px;">
            <mat-checkbox [(ngModel)]="element.isSelected" (change)="emailSelect(element)" color="primary"></mat-checkbox>
          </span>
          <span *ngSwitchCase="'email'">{{element[column]}}</span>
          <span *ngSwitchCase="'subject'" class="truncated-subject">{{element[column]}}</span>
          <span *ngSwitchCase="'createdOn'">{{element[column] | date: 'MM/dd/yyyy'}}</span>
          <span *ngSwitchCase="'status'">{{getStatusName(element[column])}}</span>
          <span *ngSwitchCase="'statusTime'">{{element[column] | date: 'MM/dd/yyyy, hh:mm a'}}</span>
          <span *ngSwitchDefault>{{element[column]}}</span>
        </div>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="tableColumns; sticky: true"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: tableColumns;"
    ></tr>

  </table>

</div>
