import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
  QueryList,
  ViewChildren
} from '@angular/core';
import { BaseFormComponent } from '../base.form.component';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HeaderSize } from '../../../../../models/form-builder/fb.template.model';
import { FormControl, FormGroup } from '@angular/forms';
import { FormBuilderStateService } from '../../../form-builder.state.service';
import { DefaultHeaders } from '../../element-options/header-options/header-options.component';

/*Set Quill configuration in one place: TextToolComponent*/

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [
    './header.component.scss',
    '../base.form.scss',
    './../../../../email-builder/components/text-tool/text-tool.component.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent
  extends BaseFormComponent
  implements OnInit, OnDestroy
{
  public duplicates: number[] = [];
  private subscription: Subscription = new Subscription();
  private editSubscription: Subscription = new Subscription();
  public headerForm: FormGroup;
  public title: HTMLElement;
  public editors: ElementRef[];
  public mainLabels: ElementRef[];
  public subLabels: ElementRef[];
  public index: number;

  @Input() public updateHeader$: Subject<void>;
  @Input() public libraryVersion: boolean;
  @Input() private fbss: FormBuilderStateService;

  @ViewChildren('headerRef') headersRef: QueryList<ElementRef>;
  @ViewChildren('subheaderRef') subheadersRef: QueryList<ElementRef>;
  @ViewChildren('editorRef') editorsRef: QueryList<any>;
  @ViewChildren('wrapper') wrappers: QueryList<ElementRef>;

  private static fontSize(type: HeaderSize): number {
    switch (type) {
      case HeaderSize.Large:
        return 60;
      case HeaderSize.Medium:
        return 40;
      default:
        return 20;
    }
  }

  public modules: /*QuillModules*/ any = {
    clipboard: {
      allowed: {
        tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span',],
        attributes: ['href', 'rel', 'target'/*, 'class'*/],
      },
      keepSelection: false,
    },
  };

  constructor(private renderer: Renderer2) {
    super();
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.updateHeader$
        .asObservable()
        .pipe(startWith(''))
        .subscribe(this.setDuplicates.bind(this))
    );

    this.headerForm = new FormGroup({
      headerControl: new FormControl(''),
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setDuplicates(): void {
    this.duplicates = [];
    for (let i = 0; i < +this.element.attributes.duplicates; i++) {
      this.duplicates.push(i);
    }
  }

  public get headerStyle(): { [key: string]: string } {
    const alignType = this.element.attributes['alignment'];
    const size = this.element.attributes['size'] as HeaderSize;
    const { fontColor = '#2C3345', fontFamily = 'Roboto' } = this.template;

    return {
      'align-items': HeaderComponent.getAlign(alignType),
      color: fontColor,
      'font-family': fontFamily,
      'font-size': `${HeaderComponent.fontSize(size)}px`,
    }
  }

  public get subHeaderStyle(): { [key: string]: string } {
    const size = this.element.attributes['size'] as HeaderSize;
    const fontSize = HeaderComponent.fontSize(size) / 2;
    return {
      fontSize: `${fontSize}px`,
    };
  }

  public editTitle(event: Event): void {
    this.handleOpenedToolbars();
    const allWrappers: ElementRef[] = this.wrappers.toArray();
    const wrapperContainer: HTMLElement = (event.target as HTMLElement).closest(
      'div.header-wrapper'
    );
    const titleContainer: HTMLElement = (event.target as HTMLElement).closest(
      'div.header-container'
    );
    this.index = allWrappers.findIndex(
      (wrapper) => wrapper.nativeElement === wrapperContainer
    );
    this.title = titleContainer.children[0] as HTMLElement;
    const styles: string = this.getStyles(titleContainer);
    titleContainer.style.display = 'none';

    // (this.editors[this.index] as any).elementRef.nativeElement.style.display = 'block';
    const editor = (this.editors[this.index] as any).elementRef.nativeElement
    editor.style.display = 'block';
    const innerTextWrapper = editor.querySelector('.ql-editor');
    innerTextWrapper.setAttribute('style', styles);

    this.headerForm.patchValue({
      headerControl: this.title.innerHTML /*`<span style="${styles}">${this.title.innerHTML}</span>`*/,
    });

    this.handleEmptyField(styles);
    this.handleEditorClick(titleContainer);
  }

  private getStyles(container: HTMLElement): string {
    return (
      this.renderer.parentNode(container).getAttribute('style') +
      this.title.getAttribute('style')
    )
  }

  private handleOpenedToolbars(): void{
    this.editSubscription.unsubscribe();

    this.editors = this.editorsRef.toArray();
    this.mainLabels = this.headersRef.toArray();
    this.subLabels = this.subheadersRef.toArray();

    this.hideShowElements(this.editors, 'none');
    this.hideShowElements(this.mainLabels, 'flex');
    this.hideShowElements(this.subLabels, 'flex');
  }

  private checkElements(elements) {
    if(elements === this.editors){
      return elements.map(element => element.elementRef.nativeElement);
    }
      return elements.map(element => element.nativeElement);
  };

  private hideShowElements(elements, visibility: string): void {
    elements = this.checkElements(elements);
    elements.forEach((element: HTMLElement) => element.style.display = visibility);
  }

  private setTitleValue(field: string, value: string): void {
    if(this.element.attributes.content){
      this.element.attributes.content[this.index][field] = value;
    }
    else{
      this.element.attributes[field] = value;
    }
  }

  private handleEmptyField(styles: string): void {
    this.headerForm.valueChanges.subscribe((data) => {
      if (!data.headerControl) {
        this.headerForm.patchValue({
          headerControl: `<span style="${styles}"> </span>`
        })
      }
    })
  }

  private handleEditorClick(container: HTMLElement): void {
    this.editSubscription = fromEvent(document, 'click')
      .pipe(
        map((e: Event) => {
          if (!(e.target as HTMLElement).closest('button')) {
            if (!(e.target as HTMLElement).closest('quill-editor')) {
              const field: string = this.title.classList.contains('header-header') ? 'mainLabel' : 'subLabel';
              const newTitleValue: string = this.headerForm.get('headerControl').value;
              container.style.display = 'flex';
              this.setTitleValue(field, newTitleValue);
              this.fbss.templateWasChanged();
              this.hideShowElements(this.editors, 'none')
            }
          }
        })
      )
      .subscribe();
  }

  public infoForRender() {
    const content = this.element.attributes.content;
    return content?.length ? content : this.duplicates;
  }

  public renderTitle(content: DefaultHeaders | number, title: string): string {
    return content[title] || this.element.attributes[title];
  }
}
