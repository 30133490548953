import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilderStateService } from '../../../form-builder.state.service';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import { LABEL_ALIGN } from '../../../../../constants';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AlignmentType, FbElementModel, FbElementType } from '../../../../../models/form-builder/fb.template.model';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-phone-options',
  templateUrl: './phone-options.component.html',
  styleUrls: ['../../fb.page.scss']
})
export class PhoneOptionsComponent implements OnInit, OnDestroy {
  @Input() public fbss: FormBuilderStateService;

  public labelAlignmentOptions: FormElementDataModel[] = LABEL_ALIGN;

  public optionsForm: FormGroup = this.formBuilder.group({
    mainLabel: '',
    mainLabelAlignment: null,
    subLabel: '',
    //required: true
  });

  public isSetAsFormDefault: FormControl = this.formBuilder.control(false);

  private element: FbElementModel;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    public translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.fbss.activeElementSettings$.asObservable()
        .pipe(
          filter(value => value === FbElementType.Phone),
          tap(() => {
            this.element = this.fbss.template$.getValue().paymentPage.elements
              .find(({type}: FbElementModel) => type === FbElementType.Phone);
            this.setInitialValues();
          }),
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setInitialValues(): void {
    const {
      mainLabel = this.translate.instant('Phone Number'),
      mainLabelAlignment = AlignmentType.Top,
      subLabel = this.translate.instant('Please enter a valid phone number'),
    }: {[key: string]: string} = this.element.attributes;
    this.mainLabel.setValue(mainLabel);
    this.mainLabelAlignment.setValue(mainLabelAlignment);
    this.subLabel.setValue(subLabel);
    //this.required.setValue(this.element.required);
  }

  public get mainLabel(): FormControl {
    return this.optionsForm.get('mainLabel') as FormControl;
  }

  public get mainLabelAlignment(): FormControl {
    return this.optionsForm.get('mainLabelAlignment') as FormControl;
  }

  public get subLabel(): FormControl {
    return this.optionsForm.get('subLabel') as FormControl;
  }

 /* public get required(): FormControl {
    return this.optionsForm.get('required') as FormControl;
  }*/

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }

  public onMainLabelChanged(): void {
    this.element.attributes.mainLabel = this.mainLabel.value;
    this.fbss.templateWasChanged();
  }

  public onLabelAlignmentChanged(): void {
    this.element.attributes.mainLabelAlignment = this.mainLabelAlignment.value;
    this.isSetAsFormDefault.value && this.setAsFormDefault();
    this.fbss.templateWasChanged();
  }

  public onSubLabelChanged(): void {
    this.element.attributes.subLabel = this.subLabel.value || this.translate.instant('Please enter a valid phone number');
    this.fbss.templateWasChanged();
  }

  public onIsSetAsFormDefaultChanged(): void {
    const next = !this.isSetAsFormDefault.value;
    this.isSetAsFormDefault.setValue(next);
    if (next) {
      this.setAsFormDefault();
      this.fbss.templateWasChanged();
    }
  }

  public setAsFormDefault(): void {
    this.fbss.setAsFormDefault(this.mainLabelAlignment.value);
  }

  /*public onRequiredChanged(): void {
    const next = !this.required.value;
    this.required.setValue(next);
    this.element.required = next;
  }*/

}
