export enum ChartType {
  Line = 1,
  Polar = 2,
  VerticalBar = 3,
  HorizontalBar = 4,
  GroupedVerticalBar = 5,
  GroupedHorizontalBar = 6,
  Pie = 7,
  PieGrid = 8,
}
