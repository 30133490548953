import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-second-step',
  templateUrl: './second-step.component.html',
  styleUrls: ['./second-step.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SecondStepComponent {
  public code: string;

  constructor(public dialogRef: MatDialogRef<SecondStepComponent>) {}

  public onCancelClick(): void {
    this.dialogRef.close();
  }
}
