import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { StripeACHService } from '../../../stripe-ach.service';
import { StripeACHFormStateService } from 'src/app/services/donation-forms/stripe-ach-form-state.service';

@Component({
  selector: 'app-stripe-ach-form',
  templateUrl: './stripe-ach-form.component.html',
  styleUrls: ['./stripe-ach-form.component.scss']
})
export class StripeAchFormComponent implements AfterViewInit {
  @Input() private clientId: string;
  @ViewChild("paymentForm") paymentForm: ElementRef

  constructor(
    private stripeACHService: StripeACHService,
    private stripeACHFormStateService: StripeACHFormStateService
  ) { }

  ngAfterViewInit(): void {
    this.init();
  }

  private init(): void {
    this.stripeACHFormStateService.paymentFormRef = this.paymentForm;
    this.stripeACHService.configureStripe(this.clientId)
  }
}
