import { DumbTableAppearance } from "src/app/models/dumb-table-config.model";

export interface IAppearanceResolver {
  isApplied(appearance: string): boolean;
}

export class AppearanceResolver implements IAppearanceResolver {
  private _appliedAppearance: DumbTableAppearance[];

  constructor(appliedAppearance: DumbTableAppearance[]) {
    this._appliedAppearance = appliedAppearance;
  }

  public isApplied(appearance: string): boolean {
    if(!this._appliedAppearance) return;
    return !!Object.keys(this._appliedAppearance).find((key: string) => this._appliedAppearance[key] === appearance);
  }

}