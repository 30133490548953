import { ChangeDetectorRef, Injectable, OnDestroy } from '@angular/core';
import MjmlElementModel from '../../models/templates/mjml.element.model';
import { MjmlApiService } from './mjml.api.service';
import HtmlConvertedModel from '../../models/templates/html.converted.model';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { debounceTime, delay, filter, first, switchMap, tap } from 'rxjs/operators';
import { DEFAULT_MJML_TEMPLATE, MS_PER_DAY } from '../../constants';
import TemplatePayload from '../../models/templates/template.payload';
import { TemplateType } from '../../models/templates/template.type';
import { EventItineraryModel } from '../../models/event/event-itinerary.model';
import { formatDate } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { MjmlSmName } from '../../models/templates/mjml.sm.name';
import { UtilsComponent } from '../../components/utils.component';
import { TemplateAssignmentType } from '../../models/templates/template.assignment.type';
import { ActiveElementStateModel } from './activeElementState.model';

@Injectable()
export class TemplateManagementService implements OnDestroy {
  private subscription: Subscription = new Subscription();

  public html: string = '';

  public template: MjmlElementModel = JSON.parse(JSON.stringify(DEFAULT_MJML_TEMPLATE));
  public readOnly: boolean = false;
  public templateType: TemplateType = TemplateType.Layout;

  private availableClientPlaceholdersIds: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(null); //default value;
  public availablePlaceholders$: Observable<number[]> = this.availableClientPlaceholdersIds.asObservable().pipe(filter(val => !!val));

  public clientPortalVersion: boolean = false;

  public eventForm: FormGroup;
  public campaignForm: FormGroup;

  /** Row and Element in current 'focus' */
  public activeElement: MjmlElementModel;
  public activeRow: MjmlElementModel;
  public activeColumn: MjmlElementModel;

  private activeElementSubject: Subject<MjmlElementModel> = new Subject<MjmlElementModel>();
  public activeElementEmitter: Observable<MjmlElementModel> = this.activeElementSubject.asObservable();

  public activeElementStateSubject: Subject<ActiveElementStateModel> = new Subject<ActiveElementStateModel>()
  private activeElementState: ActiveElementStateModel;

  public setActiveElementState(elementState: ActiveElementStateModel) {
    if(!elementState) return;
    this.activeElementState = elementState;
    this.activeElementStateSubject.next(elementState);
  }

  private activeRowSubject: Subject<MjmlElementModel> = new Subject<MjmlElementModel>();
  public activeRowEmitter: Observable<MjmlElementModel> = this.activeRowSubject.asObservable();

  private activeColumnSubject: Subject<MjmlElementModel> = new Subject<MjmlElementModel>();
  public activeColumnEmitter: Observable<MjmlElementModel> = this.activeColumnSubject.asObservable();

  private activeTableChangedSubject: Subject<MjmlElementModel> = new Subject<MjmlElementModel>();
  public activeTableChangedEmitter: Observable<MjmlElementModel> = this.activeTableChangedSubject.asObservable();

  private activeVideoChangedSubject: Subject<MjmlElementModel> = new Subject<MjmlElementModel>();
  public activeVideoChangedEmitter: Observable<MjmlElementModel> = this.activeVideoChangedSubject.asObservable();

  private moduleChangedSubject: Subject<MjmlElementModel> = new Subject<MjmlElementModel>();
  public moduleChangedEmitter: Observable<MjmlElementModel> = this.moduleChangedSubject.asObservable();

  private templateChangedSubject: Subject<MjmlElementModel> = new Subject<MjmlElementModel>();
  public templateChangedEmitter: Observable<MjmlElementModel> = this.templateChangedSubject.asObservable();

  private signatureChangedSubject: Subject<MjmlElementModel> = new Subject<MjmlElementModel>();
  public signatureChangedEmitter: Observable<MjmlElementModel> = this.signatureChangedSubject.asObservable();

  private saveTemplateSubject: Subject<TemplatePayload> = new Subject<TemplatePayload>();
  public saveTemplateEmitter: Observable<TemplatePayload> = this.saveTemplateSubject.asObservable();

  private commentWallDisplayCountSubject: Subject<void> = new Subject<void>();
  public commentWallDisplayCountEmitter: Observable<void> = this.commentWallDisplayCountSubject.asObservable();

  private saveSubject: Subject<FormGroup> = new Subject<FormGroup>();
  /*public saveEmitter: Observable<FormGroup> = this.saveSubject.asObservable()
    .pipe(
      skip(6),
      debounceTime(10000)
    );*/
  public saveEmitter: Observable<FormGroup> = timer(5000)
    .pipe(
      switchMap(() => this.saveSubject.asObservable()),
      debounceTime(10000)
    );

  private useInTemplate: Subject<any> = new Subject<any>();
  public useInTemplateEmitter: Observable<any> = this.useInTemplate.asObservable();

  private editGoal: Subject<string> = new Subject<string>();
  public editGoalEmitter: Observable<string> = this.editGoal.asObservable();

  /* Use it to know when settings tab was changed*/
  private settingsTabChangedSubject: Subject<number> = new Subject<number>();
  public settingsTabChangedEmitter: Observable<number> = this.settingsTabChangedSubject.asObservable();

  public defaultSmLinks = {
    [MjmlSmName.facebook]: '',
    [MjmlSmName.twitter]: '',
    [MjmlSmName.instagram]: '',
    [MjmlSmName.linkedin]: ''
  };

  private historyStorage: MjmlElementModel[] = [];
  private undoIndex: number = 0;
  private historyStorageSource: Subject<void> = new Subject<void>();
  private skipOneUpdate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public templateAssignmentType: TemplateAssignmentType;

  isLetterBuilder: boolean;

  constructor(
    private mjmlApiService: MjmlApiService,
    private cdr: ChangeDetectorRef
  ) {
    this.historyStorageSource.asObservable()
      .pipe(
        debounceTime(500),
        /*filter(() => {
          const storageLength = this.historyStorageLength;
          return !(storageLength && JSON.stringify(this.historyStorage[storageLength - 1]) === JSON.stringify(this.template));
        }),*/
        switchMap(() => this.skipOneUpdate.asObservable().pipe(first())),
        tap((skipOne: boolean) => {
          if (skipOne) {
            this.skipOneUpdate.next(false);
            return;
          }
          if (this.undoIndex > 0) {
            this.historyStorage.splice(-this.undoIndex);
          }
          this.historyStorage.push(UtilsComponent.clone(this.template));
          this.cdr.markForCheck();
          if (this.historyStorageLength === 12) {
            this.historyStorage.splice(0, 1);
          }
          this.undoIndex = 0;
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public addTemplate(): void {
    this.useInTemplate.next();
  }

  public onEditGoal(goal: string): void {
    this.editGoal.next(goal);
  }

  /** Convert MJML model to HTML */
  public convertMjmlToHtml(): void {
    //this.setActiveElement(null);
    this.subscription.add(
      this.mjmlApiService.mjml2html(this.template).pipe(delay(0)).subscribe((convertedHtml: HtmlConvertedModel) => {
        if (!convertedHtml) {
          return;
        }
        this.html = convertedHtml.html;
      })
    );
  }

  /** Convert MJML model to HTML and return observable */
  public getHtmlObserver(): Observable<HtmlConvertedModel> {
    //this.setActiveElement(null);
    return this.mjmlApiService.mjml2html(this.template).pipe(delay(0), tap((convertedHtml: HtmlConvertedModel) => {
      if (!convertedHtml) {
        return;
      }
      this.html = convertedHtml.html;
    }));
  }

  /** Delete Row/MJML-section from template */
  public deleteSection(index: number): void {
    const body = this.template.children[1].children[0];
    body.children.splice(index, 1);
    this.resetActiveElement();
  }

  /** Emit template Element in 'focus' */
  public setActiveElement(element: MjmlElementModel): void {
    /*if (this.activeElement === element) {
      return;
    }*/
    if(this.activeElementState && !this.activeElementState.isValid) {
      return;
    }

    this.activeElement = element;
    this.activeElementSubject.next(element);
  }

  /** Emit template Row (mjml-section) in 'focus' */
  public setActiveRow(row: MjmlElementModel): void {
    if (this.activeRow === row) {
      return;
    }
    this.activeRow = row;
    this.activeRowSubject.next(row);
  }

  /** Emit template Column (mjml-column) in 'focus' */
  public setActiveColumn(column: MjmlElementModel): void {
    if (this.activeColumn === column) {
      return;
    }
    this.activeColumn = column;
    this.activeColumnSubject.next(column);
  }

  public getTableHtml(rows: number, columns: number): string {
    let tableHtml = '';
    for (let i = 0; i < rows; i++) {
      let rowHtml = '';
      for (let j = 0; j < columns; j++) {
        if (i === 0) {
          rowHtml += `<th contenteditable="false">Title</th>`;
        } else {
          rowHtml += `<td contenteditable="false">Content</td>`;
        }
      }
      tableHtml += `<tr>${rowHtml}</tr>`;
    }
    return tableHtml;
  }

  /** Emit table element (mjml-table) that changed */
  public tableElementChanged(): void {
    this.activeTableChangedSubject.next(this.activeElement);
  }

  /** Emit video element (mjml-table) that changed */
  public videoElementChanged(): void {
    this.activeVideoChangedSubject.next(this.activeElement);
  }

  public getVideoHtml(src: string = '', preload: string = 'auto', autoplay: boolean = false, poster: string = ''): string {
    return `
      <video controls preload="${preload}" ${autoplay ? 'autoplay' : ''} src="${src}" style="width: 100%; height: auto" ${poster ? 'poster="' + poster + '"' : ''} crossorigin="anonymous">
        <a href="${src}" target="_blank">Your browser does not support the video tag.</a>
      </video>
    `;
  }

  public getVideoHtmlWithoutControls(src: string = '', preload: string = 'auto', autoplay: boolean = false, poster: string = ''): string {
    return `
      <video preload="${preload}" ${autoplay ? 'autoplay' : ''} src="${src}" style="width: 100%; height: auto" ${poster ? 'poster="' + poster + '"' : ''} crossorigin="anonymous">
        <a href="${src}" target="_blank">Your browser does not support the video tag.</a>
      </video>
    `;
  }

  public getEmailVideoHtml(href: string = '', background: string = ''): string {
    const bg = background ? `url(${background}) top center / 100% no-repeat` : 'none';
    const host: string = window.location.origin;
    return `<table width="300px" height="169px" style="background: ${bg}" background="${background}">
              <tr>
                <td align="center" valign="middle">
                  <a href="${href}" target="_blank">
                    <img src="${host}/assets/images/play.svg">
                  </a>
                </td>
              </tr>
            </table>`;
  }

  public getEmailVideoTemplateHtml(background: string = ''): string {
    const bg = background ? `url(${background}) top center / 100% no-repeat` : 'none';
    const host: string = window.location.origin;
    return `<table width="300px" height="169px" style="background: ${bg}">
              <tr>
                <td align="center" valign="middle">
                   <img src="${host}/assets/images/play.svg">
                </td>
              </tr>
            </table>`;
  }

  public getThermometer(eventId: string, entity?: MjmlElementModel): string {
    const url = window.location.origin;
    if (entity) {
      const src = encodeURI(`${url}/thermometer/${eventId}?padding-bottom=${entity.attributes['padding-bottom'] || '10'}&resize=${entity.attributes['resize'] || '1'}&fontSizeDescription=${entity.attributes['fontSizeDescription'] || '14px'}&fontSizeIterations=${entity.attributes['fontSizeIterations'] || '14px'}&fontSizeGoal=${entity.attributes['fontSizeGoal'] || '14px'}&padding-left=${entity.attributes['padding-left'] || '25'}&padding-right=${entity.attributes['padding-right'] || '25'}&padding-top=${entity.attributes['padding-top'] || '10'}&borderColor=${entity.attributes['borderColor'] || '#212121'}&containerFillColor=${entity.attributes['containerFillColor'] || '#0064BE'}&containerTextColor=${entity.attributes['containerTextColor'] || '#000000'}&goal=${entity.attributes['goal'] || '0'}&iteration_1=${entity.attributes['iteration_1'] || '0'}&iteration_2=${entity.attributes['iteration_2'] || '0'}&iteration_3=${entity.attributes['iteration_3'] || '0'}&alignment=${entity.attributes['alignment'] || 'vertical'}&textPlace=${entity.attributes['textPlace'] || 'left'}&showGoal=${entity.attributes['showGoal'] || 'true'}&showDonors=${(!entity.attributes['showDonors'] && entity.attributes['showDonors'] !== undefined) ? entity.attributes['showDonors'].toString() : true}&showDonations=${(!entity.attributes['showDonations'] && entity.attributes['showDonations'] !== undefined)  ? entity.attributes['showDonations'].toString() : true}&showPercent=${(!entity.attributes['showPercent'] && entity.attributes['showPercent'] !== undefined) ? entity.attributes['showPercent'].toString() : true}&description=${entity.attributes['description'] || ''}&showIterations=${entity.attributes['showIterations'] || 'false'}&appearance=${entity.attributes['appearance'] || 'thermometer'}&containerGradientColor=${entity.attributes['containerGradientColor'] || '#c00'}`);
      return `<iframe src="${src}" style="overflow:hidden;" width="100%" onload="const width = +window.getComputedStyle(this).width.slice(0, -2); const attr = +this.getAttribute('data-attr') - 60; this.height = (width > attr ? attr : width) + 'px';" frameborder="0"  align="left" data-attr="${entity.attributes['contentHeight'] || '750'}"></iframe>`;
    }
    return `<iframe src="${url}/thermometer/${eventId}?goal=0&iteration_1=0&iteration_2=0&iteration_3=0" style="max-height: 750px;overflow:hidden;" width="100%" onload="this.height=window.getComputedStyle(this).width" frameborder="0" align="left" data-attr="750"></iframe>`;
  }

  public setTemplate(template: MjmlElementModel): void {
    this.setActiveElement(null);
    this.setActiveRow(null);
    this.setActiveColumn(null);
    this.template = JSON.parse(JSON.stringify(template));
    this.html = '';
    this.templateChangedSubject.next();
    this.cdr.markForCheck();
  }

  public updateTemplate(): void {
    this.moduleChangedSubject.next();
  }

  public updateSignature(): void {
    this.signatureChangedSubject.next();
  }

  public updateCommentWallDisplayCount(): void {
    this.commentWallDisplayCountSubject.next();
  }

  public triggerSaveTemplate(templatePayload: TemplatePayload): void {
    this.saveTemplateSubject.next(templatePayload);
  }

  public setReadOnly(readOnly: boolean): void {
    this.readOnly = readOnly;
  }

  public setTemplateType(templateType: TemplateType): void {
    this.templateType = templateType;
  }

  public getItinerariesSet(eventItineraries: EventItineraryModel[]): string {
    if (!eventItineraries.length) {
      return '';
    }
    const dates: Set<number> = new Set();
    eventItineraries.forEach((item: EventItineraryModel) => {
      const from = new Date(item.startDate);
      const to = new Date(item.endDate);
      const daysInRange = (to.getTime() - from.getTime()) / MS_PER_DAY + 1;
      for (let i = 0; i < daysInRange; i++) {
        dates.add(new Date(from.getFullYear(), from.getMonth(), from.getDate() + i).getTime());
      }
    });

    let result = '';
    dates.forEach((uniqueDay: number) => {
      result += `<p><b>${formatDate(uniqueDay, 'EEEE, MMMM d, y', 'en-US')}:</b></p>`;
      eventItineraries.forEach((item: EventItineraryModel) => {
        const from = new Date(item.startDate).getTime();
        const to = new Date(item.endDate).getTime();
        if (uniqueDay >= from && uniqueDay <= to) {
          result += `<p>${this.trimZero(item.startTime)} - ${this.trimZero(item.endTime)}: ${item.text}</p>`;
        }
      });
    });
    return result;
  }

  private trimZero(timeString: string): string {
    if (timeString && timeString.charAt(0) === '0') {
      return timeString.slice(1);
    } else return timeString;
  }

  public setAvailableClientPlaceholdersIds(placeholdersIds: number[]): void {
    this.availableClientPlaceholdersIds.next(placeholdersIds);
  }

  public onActiveFormBlur(form: FormGroup): void {
    this.saveSubject.next(form);
  }

  public emitSave(addToHistory: boolean = false): void {
    if (addToHistory) {
      this.historyStorageSource.next();
    }
    const form: FormGroup = this.eventForm ? this.eventForm : this.campaignForm ? this.campaignForm : null;
    if(!form) return;
    form.markAsDirty();
    this.saveSubject.next(form);
  }

  public setEventForm(form: FormGroup): void {
    this.eventForm = form;
  }

  public setCampaignForm(form: FormGroup): void {
    this.campaignForm = form;
  }

  public removeActiveElementFromColumn(): void {
    const index = this.activeColumn.children.findIndex((element: MjmlElementModel) => element === this.activeElement);
    if (index !== -1) {
      this.activeColumn.children.splice(index, 1);
      this.resetActiveElement();
    }
  }

  public setClientPortalVersion(value: boolean): void {
    this.clientPortalVersion = value;
  }

  public undo(): void {
    const length = this.historyStorageLength;
    if (length <= 1 || this.undoIndex === length - 1) {
      return;
    }
    const template = UtilsComponent.clone(this.historyStorage[length - 1 - ++this.undoIndex]);
    this.setTemplate(template);
    this.skipOneUpdate.next(true);
    this.historyStorageSource.next();
  }

  public redo(): void {
    if (this.undoIndex === 0) {
      return;
    }
    const template = UtilsComponent.clone(this.historyStorage[this.historyStorageLength - 1 - --this.undoIndex]);
    this.setTemplate(template);
    this.skipOneUpdate.next(true);
    this.historyStorageSource.next();
  }

  public get undoDisable(): boolean {
    return this.historyStorageLength <= 1 || this.undoIndex === this.historyStorageLength - 1 || this.readOnly;
  }

  public get redoDisable(): boolean {
    return this.undoIndex === 0 || this.readOnly;
  }

  public resetHistoryStorage(): void {
    this.historyStorage = [];
    this.historyStorageSource.next();
  }

  public adjustImageSizes(): void {

    const calculateAvailableWidthInPixels = (column: MjmlElementModel): number => {
      const columnWidthInPercentage = parseFloat(column.attributes['width'] ?? this.columnWidth);
      const generalWidthInPixels = parseFloat(this.generalContentWidth?.replace('px', ''));
      return (columnWidthInPercentage / 100) * generalWidthInPixels;
    };

    for (const column of this.activeRow.children) {
      const availableWidthInPixels = calculateAvailableWidthInPixels(column);

      for (const child of column.children) {
        if (child.tagName === 'mj-image') {
          const imageWidthInPixels = parseFloat(child.attributes['width']?.replace('px', ''));

          if (imageWidthInPixels > availableWidthInPixels) {
            child.attributes['width'] = `${availableWidthInPixels}px`;
          }
        }
      }
    }
  }

  public get historyStorageLength(): number {
    return this.historyStorage.length;
  }

  public get generalContentWidth(): string {
    return this.template.children[1].attributes['width'];
  }

  public get columnWidth(): string {
    const childrenCount = this.activeRow?.children.length;
    const widthMap: { [key: number]: string } = {
      4: "25%",
      3: "33.333%",
      2: "50%",
      1: "100%",
      0: "100%"
    };

    return widthMap[childrenCount] || "100%";
  }
  
  private resetActiveElement(): void {
    this.setActiveElement(null);
    this.setActiveRow(null);
    this.setActiveColumn(null);
  }

  public settingsTabWasChanged(tabIndex: number): void {
    this.settingsTabChangedSubject.next(tabIndex);
  }
}
