<div
  class="auxilia-sign-up-as-a-donor-today-wrapper"
  [ngClass]="{
    'horizontal-layout': formSettings.layoutStyle === 'horizontal',
    'horizontal-layout-mail-fields':
      formSettings.layoutStyle === 'horizontal' &&
      formSettings.showMailingAddress
  }"
>
  <ng-container *ngIf="loadingData">
    <div class="lazy-select-spinner">
      <app-spinner></app-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!successMessage">
    <form [formGroup]="donorForm" class="auxilia-sign-up-as-a-donor-today-form">
      <div
        class="auxilia-sign-up-as-a-donor-today-row header"
        [ngClass]="{
          'logo-left': formSettings.logoPosition === 'left',
          'logo-center': formSettings.logoPosition === 'center',
          'logo-right': formSettings.logoPosition === 'right',
          'no-logo': formSettings.showLogo === false || !formSettings.logo
        }"
      >
        <img
          [src]="formSettings.logo"
          class="logo-absolute"
          [ngStyle]="{
            width: formSettings.logoSize,
            height: formSettings.logoSize
          }"
          *ngIf="formSettings.showLogo && formSettings.logo"
          alt="Auxilia"
        />
        <span
          class="auxilia-sign-up-as-a-donor-today-title"
          [innerHTML]="formSettings.customText"
        ></span>
      </div>
      <div
        class="sign-up-fields"
        [ngClass]="{
          'horizontal-layout': formSettings.layoutStyle === 'horizontal'
        }"
      >
        <div
          [ngClass]="{
            'vertical-one':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '1',
            'vertical-two':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '2'
          }"
          class="auxilia-sign-up-as-a-donor-today-row"
        >
          <div class="auxilia-sign-up-as-a-donor-today-row-item">
            <app-input
              [label]="formSettings.firstNameLabel"
              [formGroup]="donorForm"
              name="firstName"
              errorMassage="First Name"
              [isRequired]="true"
              [labelFontSize]="formSettings.labelFontSize"
              [inputFontSize]="formSettings.inputFontSize"
              [labelFontFamily]="formSettings.labelFontFamily"
              [inputFontFamily]="formSettings.inputFontFamily"
              [labelFontColor]="formSettings.labelFontColor"
              [inputFontColor]="formSettings.inputFontColor"
            ></app-input>
          </div>
        </div>
        <div
          [ngClass]="{
            'vertical-one':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '1',
            'vertical-two':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '2'
          }"
          class="auxilia-sign-up-as-a-donor-today-row"
        >
          <div class="auxilia-sign-up-as-a-donor-today-row-item">
            <app-input
              [label]="formSettings.lastNameLabel"
              [formGroup]="donorForm"
              name="lastName"
              errorMassage="Last Name"
              [isRequired]="true"
              [labelFontSize]="formSettings.labelFontSize"
              [inputFontSize]="formSettings.inputFontSize"
              [labelFontFamily]="formSettings.labelFontFamily"
              [inputFontFamily]="formSettings.inputFontFamily"
              [labelFontColor]="formSettings.labelFontColor"
              [inputFontColor]="formSettings.inputFontColor"
            ></app-input>
          </div>
        </div>
        <div
          [ngClass]="{
            'vertical-one':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '1',
            'vertical-two':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '2',
            horizontal: formSettings.layoutStyle === 'horizontal'
          }"
          class="auxilia-sign-up-as-a-donor-today-row"
        >
          <div class="auxilia-sign-up-as-a-donor-today-row-item">
            <app-input
              [label]="formSettings.emailLabel"
              [formGroup]="donorForm"
              name="email"
              errorMassage="Email"
              [isRequired]="true"
              [labelFontSize]="formSettings.labelFontSize"
              [inputFontSize]="formSettings.inputFontSize"
              [labelFontFamily]="formSettings.labelFontFamily"
              [inputFontFamily]="formSettings.inputFontFamily"
              [labelFontColor]="formSettings.labelFontColor"
              [inputFontColor]="formSettings.inputFontColor"
            ></app-input>
          </div>
        </div>
        <div
          [ngClass]="{
            'vertical-one':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '1',
            'vertical-two':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '2',
            horizontal: formSettings.layoutStyle === 'horizontal'
          }"
          class="auxilia-sign-up-as-a-donor-today-row"
          *ngIf="formSettings.showPhoneNumber"
        >
          <div class="auxilia-sign-up-as-a-donor-today-row-item">
            <app-input
              [label]="formSettings.phoneLabel"
              [formGroup]="donorForm"
              name="phone"
              errorMassage="Phone"
              [prefix]="phonePrefix$ | async"
              [mask]="phoneMask$ | async"
              [labelFontSize]="formSettings.labelFontSize"
              [placeholder]="phonePlaceholder$ | async"
              [isRequired]="formSettings.isPhoneFieldRequired"
              [inputFontSize]="formSettings.inputFontSize"
              [labelFontFamily]="formSettings.labelFontFamily"
              [inputFontFamily]="formSettings.inputFontFamily"
              [labelFontColor]="formSettings.labelFontColor"
              [inputFontColor]="formSettings.inputFontColor"
            ></app-input>
          </div>
        </div>

        <div
          [ngClass]="{
            'vertical-one':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '1',
            'vertical-two':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '2',
            horizontal: formSettings.layoutStyle === 'horizontal'
          }"
          class="auxilia-sign-up-as-a-donor-today-row"
          *ngIf="formSettings.showMailingAddress"
        >
          <div class="auxilia-sign-up-as-a-donor-today-row-item">
            <app-select
              [formGroup]="donorForm"
              label="Country"
              name="mailingCountry"
              errorMassage="Country"
              [isRequired]="formSettings.areMailingAddressFieldsRequired"
              [selectOptions]="lookupStoreService.countriesOptions$ | async"
              (valueChanged)="onCountryChanged($event)"
              [label]="formSettings.mailingCountryLabel"
              [labelFontSize]="formSettings.labelFontSize"
              [inputFontSize]="formSettings.inputFontSize"
              [labelFontFamily]="formSettings.labelFontFamily"
              [inputFontFamily]="formSettings.inputFontFamily"
              [labelFontColor]="formSettings.labelFontColor"
              [inputFontColor]="formSettings.inputFontColor"
            ></app-select>
          </div>
        </div>
        <div
          [ngClass]="{
            'vertical-one':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '1',
            'vertical-two':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '2',
            horizontal: formSettings.layoutStyle === 'horizontal'
          }"
          class="auxilia-sign-up-as-a-donor-today-row"
          *ngIf="formSettings.showMailingAddress"
        >
          <div class="auxilia-sign-up-as-a-donor-today-row-item">
            <app-input
              [label]="formSettings.mailingAddressLabel"
              [formGroup]="donorForm"
              name="mailingAddress"
              [isRequired]="formSettings.areMailingAddressFieldsRequired"
              [labelFontSize]="formSettings.labelFontSize"
              [inputFontSize]="formSettings.inputFontSize"
              [labelFontFamily]="formSettings.labelFontFamily"
              [inputFontFamily]="formSettings.inputFontFamily"
              [labelFontColor]="formSettings.labelFontColor"
              [inputFontColor]="formSettings.inputFontColor"
              errorMassage="Address"
            ></app-input>
          </div>
        </div>
        <div
          [ngClass]="{
            'vertical-one':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '1',
            'vertical-two':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '2',
            horizontal: formSettings.layoutStyle === 'horizontal'
          }"
          class="auxilia-sign-up-as-a-donor-today-row"
          *ngIf="formSettings.showMailingAddress && showCity$ | async"
        >
          <div class="auxilia-sign-up-as-a-donor-today-row-item">
            <app-input
              [label]="formSettings.mailingCityLabel"
              [formGroup]="donorForm"
              name="mailingCity"
              [isRequired]="formSettings.areMailingAddressFieldsRequired"
              [labelFontSize]="formSettings.labelFontSize"
              [inputFontSize]="formSettings.inputFontSize"
              [labelFontFamily]="formSettings.labelFontFamily"
              [inputFontFamily]="formSettings.inputFontFamily"
              [labelFontColor]="formSettings.labelFontColor"
              [inputFontColor]="formSettings.inputFontColor"
              errorMassage="City"
            ></app-input>
          </div>
        </div>
        <div
          [ngClass]="{
            'vertical-one':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '1',
            'vertical-two':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '2',
            horizontal: formSettings.layoutStyle === 'horizontal'
          }"
          class="auxilia-sign-up-as-a-donor-today-row"
          *ngIf="formSettings.showMailingAddress && showState$ | async"
        >
          <div class="auxilia-sign-up-as-a-donor-today-row-item">
            <app-select
              *ngIf="isUsa$ | async"
              [formGroup]="donorForm"
              [selectOptions]="lookupStoreService.usaStatesOptions$ | async"
              name="mailingState"
              [isRequired]="formSettings.areMailingAddressFieldsRequired"
              [label]="formSettings.mailingStateLabel"
              [labelFontSize]="formSettings.labelFontSize"
              [inputFontSize]="formSettings.inputFontSize"
              [labelFontFamily]="formSettings.labelFontFamily"
              [inputFontFamily]="formSettings.inputFontFamily"
              [labelFontColor]="formSettings.labelFontColor"
              [inputFontColor]="formSettings.inputFontColor"
              [errorMassage]="state$ | async"
            ></app-select>
            <app-input
              *ngIf="!(isUsa$ | async)"
              [formGroup]="donorForm"
              [errorMassage]="state$ | async"
              name="mailingState"
              [isRequired]="formSettings.areMailingAddressFieldsRequired"
              [label]="formSettings.mailingStateLabel"
              [labelFontSize]="formSettings.labelFontSize"
              [inputFontSize]="formSettings.inputFontSize"
              [labelFontFamily]="formSettings.labelFontFamily"
              [inputFontFamily]="formSettings.inputFontFamily"
              [labelFontColor]="formSettings.labelFontColor"
              [inputFontColor]="formSettings.inputFontColor"
            ></app-input>
          </div>
        </div>
        <div
          [ngClass]="{
            'vertical-one':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '1',
            'vertical-two':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '2',
            horizontal: formSettings.layoutStyle === 'horizontal'
          }"
          class="auxilia-sign-up-as-a-donor-today-row"
          *ngIf="formSettings.showMailingAddress && (showZip$ | async)"
        >
          <div class="auxilia-sign-up-as-a-donor-today-row-item">
            <app-input
              [formGroup]="donorForm"
              name="mailingZipCode"
              [label]="formSettings.mailingZipCodeLabel"
              [isRequired]="formSettings.areMailingAddressFieldsRequired"
              [errorMassage]="zip$ | async"
              [placeholder]="zipPlaceholder$ | async"
              [mask]="zipMask$ | async"
              [type]="(isUsa$ | async) ? 'tel' : 'text'"
              [onlyNumbers]="isUsa$ | async"
              [labelFontSize]="formSettings.labelFontSize"
              [inputFontSize]="formSettings.inputFontSize"
              [labelFontFamily]="formSettings.labelFontFamily"
              [inputFontFamily]="formSettings.inputFontFamily"
              [labelFontColor]="formSettings.labelFontColor"
              [inputFontColor]="formSettings.inputFontColor"
            ></app-input>
          </div>
        </div>
        <div
          [ngClass]="{
            'vertical-one':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '1',
            'vertical-two':
              formSettings.layoutStyle === 'vertical' &&
              formSettings.layoutColumns === '2',
            horizontal: formSettings.layoutStyle === 'horizontal'
          }"
          class="auxilia-sign-up-as-a-donor-today-row is-tag"
          *ngIf="formSettings.showTags"
        >
          <div class="auxilia-sign-up-as-a-donor-today-row-item">
            <app-select
              [label]="formSettings.tagLabel"
              [formGroup]="donorForm"
              name="tag"
              [multiSelectOptions]="formSettings.tag"
              [labelFontSize]="formSettings.labelFontSize"
              [inputFontSize]="formSettings.inputFontSize"
              [labelFontFamily]="formSettings.labelFontFamily"
              [inputFontFamily]="formSettings.inputFontFamily"
              [labelFontColor]="formSettings.labelFontColor"
              [inputFontColor]="formSettings.inputFontColor"
              errorMassage="Tag"
            ></app-select>
          </div>
        </div>
      </div>
      <div class="auxilia-sign-up-as-a-donor-today-row">
        <div
          class="auxilia-sign-up-as-a-donor-today-row-item btn-submit-container"
        >
          <app-button
            [label]="formSettings.buttonText"
            width="200"
            type="flat"
            class="btn-submit"
            [color]="formSettings.buttonColor"
            [labelColor]="formSettings.buttonTextColor"
            [fontFamily]="formSettings.buttonFontFamily"
            [fontSize]="formSettings.buttonFontSize"
            (clickEvent)="submitDonorForm()"
            [disabled]="disableSubmit"
          ></app-button>
        </div>
      </div>
    </form>
  </ng-container>
  <div *ngIf="successMessage" class="auxilia-sign-up-as-a-donor-today-row-item">
    <span [innerHTML]="formSettings.successMessage"></span>
  </div>
</div>
