import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BasePagingComponent } from 'src/app/components/paginator/base.paging.component';
import { ClientIdStateService } from '../../../services/client.module.state/client.id.state.service';
import { ClientModel } from 'src/app/models/client/client.model';
import { ClientModuleService } from '../../../routes/clients/client.module.service';
import { ClientService } from 'src/app/services/client/client.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExportReportsService } from 'src/app/services/export/export-reports.service';
import { FilterType } from 'src/app/models/enum/filter.type';
import { FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { InfoLogModel } from 'src/app/models/reports/info.log.model';
import { InfoLogService } from 'src/app/services/reports/info-log.service';
import { map, tap } from 'rxjs/operators';
import { Observable, of, Subscription, zip } from 'rxjs';
import { PagingModel } from 'src/app/models/paging/paging.model';
import { saveAs } from 'file-saver';
import { SortOrder } from 'src/app/models/enum/sort.order';
import { ToastrService } from 'ngx-toastr';
import { UserActivityType } from 'src/app/models/enum/user.activity.type';
import { UserActivityValues, UserRolesTypeViews } from 'src/app/constants';
import { UserRolesType } from 'src/app/models/enum/user.roles.type';
import * as moment from 'moment';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import { convertMillimetersToTwip } from 'docx';

enum FilterAction {
  Initial = 0,
  StartFrom = 1,
  DateTo = 2,
  Organization = 3,
  ActivityType = 4,
  UserRole = 5,
  Mode = 6,
  ModeExtra = 7
}

enum ModeType {
  All = 'All',
  Masquerade = 'MASQUERADE',
  Normal = 'NORMAL'
}

@Component({
  selector: 'app-audit-reports',
  templateUrl: './audit-reports.component.html',
  styleUrls: ['../../../routes/clients/reports/components/reports-common.scss']
})
export class AuditReportsComponent extends BasePagingComponent implements OnInit, OnDestroy {
  public pageTitle: string = 'Activity History';
  public backNavigateLabel: string;
  public userRolesTypeViews = UserRolesTypeViews;
  private subscription: Subscription = new Subscription();
  public clientsWithRelationships: FormElementDataModel[] = [];

  public multiActivityOptions: FormElementDataModel[] = UserActivityValues;
  private sortFieldLogs: string = 'loggedOnDate';
  private modelFilter = new PagingModel();
  public organizations$: Observable<ClientModel[]>;
  public infoLogs$: Observable<InfoLogModel[]>;
  public total$: Observable<number>;
  public modeOptions: FormElementDataModel[] = [
    { label: 'All', value: ModeType.All },
    { label: 'Masquerade', value: ModeType.Masquerade },
    { label: 'Normal', value: ModeType.Normal }
  ];

  public tableData: InfoLogModel[];
  public tableColumns: string[];
  public isSuperAdminOrAuxiliaAdmin: boolean;
  private arrClientsIdsStr: string;
  private arrActivityValueStr: string;
  private arrActivityValue: string[] = ['All'];
  private modeTypeStr: string;
  private startFromStr: string;
  private dateToStr: string;
  public messageFromRoute: string;
  public userRoleFromRoute: string;
  public clientIdFromRoute: string;
  public isAdmin: boolean = false;

  constructor(
    private authService: AuthService,
    private clientIdStateService: ClientIdStateService,
    private clientModuleService: ClientModuleService,
    private clientService: ClientService,
    private exportReportsService: ExportReportsService,
    private formBuilder: FormBuilder,
    private infoLogService: InfoLogService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
  ) {
    super();
  }

  public filterForm: FormGroup = this.formBuilder.group({
    activity: [[UserActivityType.All]],
    startDate: null,
    endDate: null,
    organizationName: null,
    mode: ModeType.All
  }, {
    validators: [this.startDateValidator.bind(this)]
  });

  public ngOnInit() {
    this.isAdmin = this.router.url.includes('/admin-dashboard/');
    this.isSuperAdminOrAuxiliaAdmin = this.getRoles().includes(UserRolesType.SUPER_ADMIN) || this.getRoles().includes(UserRolesType.AUXILIA_ADMIN);
    this.messageFromRoute = this.route.snapshot.params.message;
    this.userRoleFromRoute = this.route.snapshot.params.userRole;
    this.clientIdFromRoute = this.route.snapshot.params.clientId;

    this.backNavigateLabel = this.getbackNavigateLabel();

    this.modelFilter.includeDependencies = false;
    this.modelFilter.sortOrder = SortOrder.Descending;
    this.isSuperAdminOrAuxiliaAdmin && this.getOrganizations();
    this.modelFilter.selectFields =  ['LoggedOnDate', 'UserName', 'Message', 'Level', 'ClientId', 'CustomInfo'];

    this.createFiltersFromFeatureUsePage();

    if (this.isSuperAdminOrAuxiliaAdmin) {
      this.tableColumns = this.getTableColumns();
      this.getInfoLogs();
      this.getTotal();
      return;
    }

    this.subscription.add(
      zip(this.clientModuleService.clientsWithRelationships, this.clientModuleService.clientName)
        .pipe(map(([clientsWithRelationships, clientName]) => ({ clientsWithRelationships, clientName })))
        .subscribe(({ clientsWithRelationships, clientName }) => {
          this.clientsWithRelationships = [{ label: clientName, value: this.authService.getIdentityClaimsOriginId() }, ...clientsWithRelationships];
          this.tableColumns = this.getTableColumns();
          this.getInfoLogs();
          this.getTotal();
        })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getRoles(): string[] {
    return this.authService.getIdentityClaimsRole();
  }

  private getOrganizations(): void {
    this.modelFilter.rows = null;
    this.modelFilter.first = null;
    this.modelFilter.sortField = null;
    this.modelFilter.selectFields =  ['name', 'id'];
    this.organizations$ = this.clientService.getModelList({ ...this.modelFilter, sortField: 'name', sortOrder: SortOrder.Ascending })
      .pipe(map(orgs => orgs.map(org => ({
          ...org,
          label: org.name,
          value: org.id
        }))
      ));
  }

  private createFilters(action?: FilterAction) {
    let fn;
    this.modelFilter.rows = this.entriesPerPage;
    this.modelFilter.first = this.getFirstItemOnPage();

    this.modelFilter.sortField = this.sortFieldLogs;

    if (!this.isSuperAdminOrAuxiliaAdmin) {
      this.modelFilter.filters[FilterAction.Initial] = {
        field: 'clientId',
        value: this.clientIdStateService.selectedRelationshipClientId,
        type: FilterType.Equal
      };
    } else {
      this.modelFilter.filters[FilterAction.Initial] = {
        field: 'clientId',
        value: 'null',
        type: FilterType.NotEqual
      };
    }

    const organizationsFilter = () => {
      this.modelFilter.filters[FilterAction.Organization] = {
        field: 'clientId',
        value: this.arrClientsIdsStr ? `array[${this.arrClientsIdsStr}]` : '',
        type: FilterType.Equal
      };
    };

    const ativityTypeFilter = () => {
      this.modelFilter.filters[FilterAction.ActivityType] = {
        field: 'message',
        value: !this.arrActivityValueStr || this.arrActivityValue.includes(UserActivityType.All) ? '' : `array[${this.arrActivityValueStr}]`,
        type: FilterType.Equal
      };
    };

    const modeFilter = () => {
      if (this.isSuperAdminOrAuxiliaAdmin) {
        const getFilter = () => {
          switch (this.modeTypeStr) {
            case ModeType.Normal:
              this.modelFilter.filters.splice(FilterAction.Mode);
              this.modelFilter.filters[FilterAction.Mode] = {
                field: 'userRole',
                value: UserRolesType.SUPER_ADMIN,
                type: FilterType.NotEqual
              };
              break;
            case ModeType.Masquerade:
              this.modelFilter.filters[FilterAction.Mode] = {
                field: 'userRole',
                value: UserRolesType.SUPER_ADMIN,
                type: FilterType.Equal
              };
              this.modelFilter.filters[FilterAction.ModeExtra] = {
                field: 'clientId',
                value: 'null',
                type: FilterType.NotEqual
              };
              break;
            case ModeType.All:
              this.modelFilter.filters.splice(FilterAction.Mode - 1);
          }
        };

        getFilter();
      }
    };

    const startFromDateFilter = () => {
      this.modelFilter.filters[FilterAction.StartFrom] = {
        field: 'loggedOnDate',
        value: this.startFromStr,
        type: FilterType.GreaterThan
      };
    };
    
    const dateToFilter = () => {
      this.modelFilter.filters[FilterAction.DateTo] = {
        field: 'loggedOnDate',
        value: this.dateToStr,
        type: FilterType.LessThanOrEqual
      };
    };

    const actions = {
      [FilterAction.Organization]: organizationsFilter,
      [FilterAction.ActivityType]: ativityTypeFilter,
      [FilterAction.Mode]: modeFilter,
      [FilterAction.StartFrom]: startFromDateFilter,
      [FilterAction.DateTo]: dateToFilter,
      default: () => null
    };
    if (actions[action]) {
      fn = actions[action];
    } else {
      fn = actions['default'];
    }
    fn();
    if(action == 2){
      fn = actions[1];
      fn();
    }
  }

  private createFiltersFromFeatureUsePage() {
    if (!this.userRoleFromRoute) {
      return;
    }
    this.modelFilter.sortField = this.sortFieldLogs;

    this.modelFilter.filters[FilterAction.UserRole] = {
      field: 'userRole',
      value: this.userRoleFromRoute,
      type: FilterType.Equal
    };
    this.modelFilter.filters[FilterAction.Mode] = {
      field: 'message',
      value: this.messageFromRoute,
      type: FilterType.Equal
    };
    if (this.isSuperAdminOrAuxiliaAdmin) {
      this.modelFilter.filters[FilterAction.Initial] = {
        field: 'clientId',
        value: this.clientIdFromRoute,
        type: FilterType.Equal
      };
    }
  }

  private getInfoLogs(action?: FilterAction): void {
    setTimeout(() => {
      if (this.filterForm.valid) {
        if (this.arrActivityValue.length > 0) {
          this.createFilters(action);
          this.infoLogs$ = this.infoLogService.getModelList(this.modelFilter).pipe(
            map(infoLogs => {
              return infoLogs.map(infoLog => {
                return this.isSuperAdminOrAuxiliaAdmin
                  ? {
                    ...infoLog,
                    isMasquerade: !!infoLog.clientId && infoLog.userRole === UserRolesType.SUPER_ADMIN,
                  }
                  : {
                    ...infoLog,
                    isMasquerade: infoLog.userRole === UserRolesType.SUPER_ADMIN,
                    organization: this.clientIdStateService.selectedRelationshipClientName
                  };
              });
            })
          );
        } else {
          this.infoLogs$ = of(null)
        }
      }
    }, 100);
  }

  private getTotal() {
    setTimeout(() => {
      if (this.filterForm.valid) {
        if (this.arrActivityValue.length > 0) {
          this.total$ = this.infoLogService.getTotal(this.modelFilter).pipe(tap(total => this.total = total));
        } else {
          this.total = 0;
        }
      }
    }, 100);
  }

  public getNextPage(page: number): void {
    this.nextPage(page, () => this.getInfoLogs());
  }

  public setEntriesPerPage(amount: number): void {
    this.entriesPerPageChanged(amount, () => this.getInfoLogs());
  }

  public get startDate(): FormControl {
    return this.filterForm.get('startDate') as FormControl;
  }

  public get endDate(): FormControl {
    return this.filterForm.get('endDate') as FormControl;
  }

  public startDateFilter = (date: Date): boolean => {
    const endDate = this.endDate.value;
    return endDate ? endDate.getTime() >= date.getTime() : true;
  };

  public endDateFilter = (date: Date): boolean => {
    const startDate = this.startDate.value;
    return startDate ? startDate.getTime() <= date.getTime() : true;
  };

  private getTableColumns(): string[] {
    const baseColumns = ['loggedOnDate', 'message', 'customInfo', 'userName'];
    if (this.clientsWithRelationships.length > 1) {
      baseColumns.push('organization');
    }

    if (this.messageFromRoute) {
      baseColumns.splice(1, 0, 'userRole');
    }

    if (this.isSuperAdminOrAuxiliaAdmin) {
      // org name
      return [...baseColumns, 'clientName', 'clipboard'];
    }
    return [...baseColumns, 'clipboard'];
  }

  public getbackNavigateLabel(): string {
    let label = '< Back to All Reports';
    if (this.userRoleFromRoute) {
      label = '< Back to Feature Reports';
    }
    return label;
  }

  public getBackNavigate(): void {
    let url = '/clients/reports';
    if (this.isSuperAdminOrAuxiliaAdmin) {
      url = '/admin-dashboard/reports';
    }
    if (this.userRoleFromRoute && this.isSuperAdminOrAuxiliaAdmin) {
      url = '/admin-dashboard/reports/audit-features';
    }
    if (this.userRoleFromRoute && !this.isSuperAdminOrAuxiliaAdmin) {
      url = '/clients/reports/audit/features';
    }
    this.router.navigateByUrl(url);
  }

  private addDays(date: Date, days: number) {
    const result = date;
    result.setDate(result.getDate() + days);
    return result;
  }

  public onStartDateChanged(value: Date): void {
    this.startFromStr = new Date(value).toISOString();
    // moment(value).format();
    this.onFilterChanged();
    this.getInfoLogs(FilterAction.StartFrom);
    this.getTotal();
  }

  public onEndDateChanged(value: Date): void {
    const date = new Date(value);
    this.dateToStr = new Date(this.addDays(date, 1)).toISOString();
    this.onFilterChanged();
    this.getInfoLogs(FilterAction.DateTo);
    this.getTotal();
  }

  public onModeChanged(mode: string): void {
    this.modeTypeStr = mode;
    this.onFilterChanged();
    this.getInfoLogs(FilterAction.Mode);
    this.getTotal();
  }

  public onOrgChanged(clientIds: string[]): void {
    this.arrClientsIdsStr = this.getArrQueryVal(clientIds);
    this.onFilterChanged();
    this.getInfoLogs(FilterAction.Organization);
    this.getTotal();
  }

  public onActivityChanged(val: string[]): void {
    if (val.includes(UserActivityType.All) && !this.arrActivityValue.includes(UserActivityType.All)) {
      val = [UserActivityType.All];
    } else if (val.includes(UserActivityType.All) && this.arrActivityValue.includes(UserActivityType.All)) {
      val = val.filter(userActivityType => userActivityType !== UserActivityType.All);
    } else if (!val.includes(UserActivityType.All) && val.length === this.multiActivityOptions.length - 1) {
      val = [UserActivityType.All];
    }
    this.filterForm.get('activity').setValue(val);
    this.arrActivityValue = val;
    this.arrActivityValueStr = this.getArrQueryVal(val);
    this.onFilterChanged();
    if (this.arrActivityValue.length > 0) {
      this.getInfoLogs(FilterAction.ActivityType);
      this.getTotal();
    } else {
      this.infoLogs$ = of(null);
      this.total = 0;
    }
  }

  public exportReport(): void {
    if (this.filterForm.valid) {
      this.subscription.add(
        this.exportReportsService.exportAudit(this.modelFilter, this.authService.getIdentityClaimsId(), this.authService.getIdentityClaimsName()).subscribe(response => {
          if (response.size === 9) {
            this.toastr.info('Your Export is scheduled. After finishing you can get the link in Notifications');
          } else
            saveAs(response, `auditReport_${new Date().toDateString()}.xlsx`);
        })
      );
    } else {
      this.toastr.error('Please fill all mandatory fields', 'Error');
    }

  }

  public clientChanged(clientID: string): void {
    const name = this.clientsWithRelationships.find((option: FormElementDataModel) => option.value === clientID).label;
    this.clientIdStateService.setSelectedRelationshipClient(clientID, name);
    this.onFilterChanged();
    this.getInfoLogs();
    this.getTotal();
  }

  public get formGroup(): FormGroup {
    return this.clientIdStateService.clientIdForm;
  }

  private startDateValidator(formGroup: FormGroup): ValidationErrors {
    const { startDate, startTime, timeZoneID, endDate }: any = formGroup.value;
    if (!startDate || !endDate) {
      return null;
    } else {
      if (endDate < startDate) {
        formGroup.controls.startDate.setErrors({ required: true });
      } else {
        formGroup.controls.startDate.setErrors(null);
      }
    }
    return endDate >= startDate ? null : { invalidDate: true };
  }
}
