import { Filter } from './filter.model';
import { FilterModel } from './filter.model';
import { SortOrder } from '../enum/sort.order';
import { SegmentType } from '../enum/campaign.segment.type';
import { FilterType } from '../enum/filter.type';

export interface Paging {
  first?: number;
  rows?: number;
  sortField?: string;
  sortOrder?: number;
  globalFilter?: any;
  filters?: Filter[];
  includeDependencies?: boolean;
  includeDeleted?: boolean;
  selectFields?: string[];
  campaignId?: string;
  isAddRequest?: boolean;
  segmentType?: SegmentType;
  isFirstTime?: boolean;
  from?: Date;
  to?: Date;
  isAlternative?: boolean;

  filterFields?: string[];
  filterValues?: string[];
  filterTypes?: FilterType[];
  isShowDonationAmount?: boolean;
}

export class PagingModel implements Paging {
  first?: number;
  rows?: number;
  sortField?: string;
  sortOrder?: number;
  globalFilter?: any;
  filters?: FilterModel[] = [];
  includeDependencies: boolean;
  includeDeleted?: boolean = false;
  selectFields?: string[];
  campaignId?: string;
  isAddRequest?: boolean = false;
  segmentType?: SegmentType;
  isAlternative?: boolean;

  constructor() { }
}

export interface SortValues {
  sortField: string;
  sortOrder: SortOrder;
}
