<div class="image-builder-styling-content">
  <div class="image-builder-styling-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="imageForm"
        label="Border color"
        name="borderColor"
        [readOnly]="readOnly || themedTemplate"
        (colorChanged)="onBorderColorChanged()"
      ></app-email-color-picker>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="imageForm"
        name="borderWidth"
        label="Border width (px)"
        [min]="0"
        [max]="50"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onBorderWidthChanged()"
        [disabled]="readOnly || themedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="image-builder-styling-row">
    <div class="max-width-310">
      <app-image-loader
        [formGroup]="imageForm"
        name="url"
        [label]="uploadLabel"
        [readOnly]="readOnly || themedTemplate"
        [imageURL]="imageURL"
        (valueChanged)="imageUrlChanged($event); onUrlChanged()"
      ></app-image-loader>
    </div>
  </div>

  <div class="image-builder-styling-row">
    <div class="max-width-145 right-margin">
      <app-input
        [label]="urlLabel"
        [formGroup]="imageForm"
        name="url"
        errorMassage="Image URL"
        [low]="true"
        [disabled]="readOnly || themedTemplate"
        (valueChanged)="imageUrlChanged($event); onUrlChanged()"
      ></app-input>
    </div>
    <app-button
      *ngIf="imageForm.get('url') && imageForm.get('url').value && !imageURL && !readOnly && !themedTemplate"
      label="Edit Image"
      type="flat"
      (clickEvent)="editImage()"
      color="#0064be"
      width="140"
    ></app-button>
    <!--<app-button
      *ngIf="!!imageURL"
      label="Cancel"
      type="flat"
      (clickEvent)="cancelEditImage()"
      color="#ec4c47"
      width="140"
    ></app-button>-->
  </div>

  <div class="image-builder-styling-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="imageForm"
        name="borderRadius"
        label="Round (%)"
        [min]="0"
        [max]="50"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onBorderRadiusChanged()"
        [disabled]="readOnly || themedTemplate"
      ></app-slider>
    </div>

  </div>

</div>
