import { AuthService } from '../../../../services/auth.service';
import { ClientIdStateService } from '../../../../services/client.module.state/client.id.state.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { DonationStatusType } from '../../../../models/enum/donation.status.type';
import { ExportReportsService } from 'src/app/services/export/export-reports.service';
import { FilterType } from '../../../../models/enum/filter.type';
import { Paging } from '../../../../models/paging/paging.model';
import { SortOrder } from 'src/app/models/enum/sort.order';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { DashboardWidgetModel } from '../../../../models/dashboard/dashboard-widget.model';

@Component({
  selector: 'app-donations',
  templateUrl: './donations.component.html',
  styleUrls: ['../simple-widget.component.scss']
})
export class DonationsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public currentYear = new Date().getFullYear();
  public currentYearDonations: number = null;
  public differenceBetweenCurrentAndPreviousYear: number;
  public previousYearDonations: number = null;
  public previousYearPercentage: number = 0;
  public summaryDonations: number = null;
  public fiscalYearChanges: number = 0;

  constructor(
    private authService: AuthService,
    private clientIdStateService: ClientIdStateService,
    private dashboardService: DashboardService,
    private exportReportsService: ExportReportsService, private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.subscription.add(
      this.clientIdStateService.clientIdObservable
        .pipe(
          distinctUntilChanged(),
          switchMap(this.getDonations.bind(this)),
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getDonations(clientId: string): Observable<DashboardWidgetModel> {
    this.setValuesToNull();
    return this.dashboardService.getYTDDonationsWidget(clientId)
      .pipe(
        tap(response => {
          this.summaryDonations = response.values.find(x => x.fieldName === 'Total').count;
          this.currentYearDonations = response.values.find(x => x.fieldName === 'LastYear').count;
          this.previousYearDonations = response.values.find(x => x.fieldName === 'YearBeforeLast').count;
          this.differenceBetweenCurrentAndPreviousYear = response.values.find(x => x.fieldName === 'DifferenceBetweenLastAndBeforeLast').count;
          this.previousYearPercentage = response.values.find(x => x.fieldName === 'PreviousYearPercentage').count;
          this.fiscalYearChanges = response.values.find(x => x.fieldName === 'FiscalYearChanges').count;
        })
      );
  }

  public get previousYearChanges(): string {
    if (this.differenceBetweenCurrentAndPreviousYear > 0) {
      return `More than in previous year`;
    } else {
      return `Less than in previous year`;
    }
  }

  public get previousYearChangesValue(): number {
    if (!this.differenceBetweenCurrentAndPreviousYear) {
      return 0;
    }
    return this.differenceBetweenCurrentAndPreviousYear > 0 ? this.differenceBetweenCurrentAndPreviousYear : this.differenceBetweenCurrentAndPreviousYear * -1;
  }

  public exportReport(): void {
    const reportsPaging: Paging = {
      includeDependencies: false,
      filters: [{
        field: 'clientID',
        value: this.authService.getIdentityClaimsOriginId(),
        type: FilterType.Equal,
      },
      {
        field: 'donationStatus',
        value: `array["${DonationStatusType.Authorized}","${DonationStatusType.Completed}"]`,
        type: FilterType.Equal,
      }],
      sortField: 'createdOn',
      sortOrder: SortOrder.Descending
    };
    this.exportReportsService.exportClientDonations(reportsPaging, this.authService.getIdentityClaimsId(), this.authService.getIdentityClaimsName()).subscribe(response => {
      if (response.size === 9) {
        this.toastr.info('Your Export is scheduled. After finishing you can get the link in Notifications');
      } else
        saveAs(response, `donations_${new Date().toDateString()}.xlsx`);
    });
  }

  private setValuesToNull(): void {
    this.summaryDonations = null;
    this.currentYearDonations = null;
    this.previousYearDonations = null;
  }
}
