<div class="wrapper-auth-register-second-step-component">
  <h1 mat-dialog-title>{{'Please, type code from email' | translate}}</h1>
  <mat-form-field appearance="outline">
    <mat-label>{{'Secret Code' | translate}}</mat-label>
    <input [(ngModel)]="code" matInput placeholder="Secret code" required>
  </mat-form-field>

  <div mat-dialog-actions>
    <button color="primary" mat-button mat-stroked-button (click)="onCancelClick()">{{'Cancel' | translate}}</button>
    <button color="primary" mat-button mat-stroked-button [mat-dialog-close]="code" [disabled]="!code">{{'Send' | translate}}</button>
  </div>
</div>
