import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import FormElementDataModel from '../../models/form.element.data.model';
import { ClientDonorModel } from '../../models/client/client.donor.model';
import { SortOrder } from '../../models/enum/sort.order';
import ColumnFilterOptionsModel from '../../models/filter-sort/column.filter.options.model';
import ColumnFilterModel from '../../models/filter-sort/column.filter.model';
import FilterSortModel from '../../models/filter-sort/filter.sort.model';
import { ClientDonorStatus } from '../../models/enum/client.donor.status';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnChanges {
  @Input() public columnsFilterOptions: ColumnFilterOptionsModel[] = [];
  @Input() public data: ClientDonorModel[] = [];
  @Input() public filterSortConfig: FilterSortModel;
  @Input() public tableDataColumns: string[] = [];
  @Output() public checkBoxChanged: EventEmitter<ClientDonorModel> = new EventEmitter<ClientDonorModel>();
  @Output() public filterChanged: EventEmitter<ColumnFilterModel> = new EventEmitter<ColumnFilterModel>();
  @Output() public rowClicked: EventEmitter<ClientDonorModel> = new EventEmitter<ClientDonorModel>();
  @Output() public selectAllChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public sortChanged: EventEmitter<SortOrder> = new EventEmitter<SortOrder>();
  private selection = new SelectionModel<ClientDonorModel>(true, []);
  public isAllCheckboxSelected = false;
  public sortOrder: SortOrder;
  public tableColumns: string[] = [];
  public tableSource: MatTableDataSource<ClientDonorModel>;
  public minMax: number[];

  public ngOnChanges(changes: SimpleChanges): void {
    /** Change table columns */
    if (changes.tableDataColumns) {
      this.tableColumns = [...this.tableDataColumns];
    }
    if (changes.data && this.data) {
      this.tableSource = new MatTableDataSource<ClientDonorModel>(this.data.map(item => {
        return {
          ...item, name: `${item.firstName} ${item.lastName}`
        };
      }));
    }
    if (changes.columnsFilterOptions && changes.columnsFilterOptions.currentValue) {
      this.minMax = this.getMinMax('donationsCount')
    }
  }

  public selectAllCheckBox(): void {
    this.isAllCheckboxSelected = !this.isAllCheckboxSelected;
    this.selectAllChanged.emit(this.isAllCheckboxSelected);
  }

  public selectRow(row: ClientDonorModel): void {
    this.rowClicked.emit(row);
  }

  public checkBoxClick(row: ClientDonorModel) {
    this.checkBoxChanged.emit(row);
  }
  public getColumnTitle(column): string {
    const columnsTitles = {
      name: 'Name',
      employer: 'Employer',
      primaryPhone: 'Phone Number',
      email: 'Email Address',
      city: 'City',
      stateName: 'State',
      donationsCount: 'Donations',
      status: 'Active'
    };
    return columnsTitles[column];
  }

  public withFilter(column): boolean {
    return column !== 'name' && column !== 'status' && column !== 'checked' && column !== 'donationsCount';
  }

  public getAbbreviation(element, column): string {
    return element[column].split(' ').map(item => {
      if (item) {
        return item[0].toUpperCase();
      }
    }).join('');
  }

  public getFilterOptions(column): FormElementDataModel[] {
    const arr = [...new Set(this.data.map(item => item[column]))];
    return arr.map(item => ({ label: item, value: item }));
  }

  public getSelectOptions(column: string): ColumnFilterOptionsModel {
    const field = column === 'stateName' ? 'state' : column;
    return this.columnsFilterOptions.find(options => options.columnName === field);
  }

  public getMinMax(column: string): number[] {
    const model = this.columnsFilterOptions.find(options => options.columnName === column);
    let min = 0;
    let max = 1;
    if (model && model.options) {
      model.options.forEach(item => {
        if (min > Number(item.value)) min = Number(item.value);
        if (max < Number(item.value)) max = Number(item.value);
      });
    }
    return [min, max];
  }

  public selectedOptions(event: ColumnFilterModel): void {
    this.filterChanged.emit(event);
  }

  public sortTable(): void {
    switch (this.sortOrder) {
      case SortOrder.Ascending:
        this.sortOrder = SortOrder.Descending;
        break;
      case SortOrder.Descending:
        this.sortOrder = SortOrder.Ascending;
        break;
      default:
        this.sortOrder = SortOrder.Ascending;
    }
    this.sortChanged.emit(this.sortOrder);
  }

  public isActiveFilter(column): boolean {
    const columnName = column === 'stateName' ? 'state' : column;
    if (!this.filterSortConfig) {
      return false;
    } else {
      const { columnFilters }: FilterSortModel = this.filterSortConfig;
      return !!columnFilters.find(({ field }: ColumnFilterModel) => field === columnName);
    }
  }

  public getStatus(element): string {
    const status = element['status'];
    if (!status) {
      return '';
    } else {
      if(status === ClientDonorStatus.Active){
        return 'Active';
      }else if(status === ClientDonorStatus.DoNotContact){
        return 'Do Not Contact';
      }else if(status === ClientDonorStatus.Archived){
        return 'Archived';
      }
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.tableSource.data.forEach(row => this.selection.select(row));
  }
}
