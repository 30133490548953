import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DialogData} from "../../../admin-confirmation/admin-confirmation.component";

@Component({
  selector: 'app-save-or-create-campaign-dialog',
  templateUrl: './save-or-create-campaign-dialog.component.html',
  styleUrls: ['../../../../../shared/styles/common.scss', '../../../../../shared/styles/layout.scss']
})
export class SaveOrCreateCampaignDialogComponent implements OnInit {
public isSaveOrNotPage: boolean;
  constructor(
      private dialogRef: MatDialogRef<SaveOrCreateCampaignDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: boolean,
      ) { }

  ngOnInit(): void {
    this.isSaveOrNotPage = this.data
  }
  public get dialogText(): string {
  return  !this.isSaveOrNotPage ? "Would you like to start creating new letter campaign?":
        "TEMPLATES.Would you like to save a template?"
  }

  onApproveClick(): void {
    this.isSaveOrNotPage ?
    this.dialogRef.close(SaveLetterAction.Save) :
        this.dialogRef.close(SaveLetterAction.CreateCampaign)
  }

  onRejectClick(): void {
       this.dialogRef.close(null)
  }

  onClose(): void {
    this.dialogRef.close(null)
  }
}

export enum SaveLetterAction {
  Save = 1,
  CreateCampaign = 2
}
