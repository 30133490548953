import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from '../base.model.service';
import AchTokenResponseModel from '../../models/payments/ach.token.response.model';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CardConnectTokenizeService extends BaseModelService<AchTokenResponseModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'Node/CardConnecToken';
  }

  public getToken(account: string): Observable<AchTokenResponseModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}`;
        return this.http.post<AchTokenResponseModel>(url, {account});
      })
    );
  }


}
