import { ElementRef, Injectable } from "@angular/core";
import { Stripe, StripeElements, StripePaymentElement, StripePaymentElementOptions } from "@stripe/stripe-js";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class StripeACHFormStateService {
  public elements: StripeElements;
  public paymentElement: StripePaymentElement;
  public paymentFormRef: ElementRef;

  public formReady$: Subject<void> = new Subject<void>();
  public formCompleted$: Subject<void> = new Subject<void>();
  public formFocused$: Subject<void> = new Subject<void>();

  private _paymentElementOptions: StripePaymentElementOptions = {
    fields: {
      billingDetails: 'never'
    },
    terms: {
      usBankAccount: 'always'
    }
  }

  public create(stripe: Stripe): void {
    if(!stripe) throw new Error("Stripe instance must not be null");
    if(!this.paymentFormRef) new Error("Payment Form Reference must not be null")

    this.elements = stripe.elements({
      mode: "setup",
      currency: "usd",
      paymentMethodTypes: ['us_bank_account'],
      paymentMethodCreation: 'manual'
    });

    this.paymentElement = this.elements.create("payment", {...this._paymentElementOptions});
    this.paymentElement.mount(this.paymentFormRef.nativeElement)
    this.handleOnFormReady();
    this.handleOnformCompleted();
    this.handleOnFormClicked();
  }

  public enableForm(): void {
    this.paymentElement?.update({ readOnly: false })
  }

  public disableForm(): void {
    this.paymentElement?.update({ readOnly: true })
  }

  private handleOnFormReady(): void {
    this.paymentElement.on("ready", (event) => {
      if(!!event) {
        this.formReady$.next();
      }
    })
  }

  private handleOnformCompleted(): void {
    this.paymentElement.on("change", (event) => {
      if(event.complete) {
        this.formCompleted$.next();
      }
    })
  }

  private handleOnFormClicked(): void {
    this.paymentElement.on("focus", () => {
      this.formFocused$.next();
    })
  }
}