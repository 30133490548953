<div class="image-builder-styling-content margin-wrapper">
  <!--<span class="title-row">Add New Tag</span>
  <div class="image-builder-styling-row">
    <div class="max-width-350">
      <app-input
        [formGroup]="tagsForm"
        name="tagName"
        errorMassage="Tag"
        [low]="true"
        placeholder="Enter tag and hit enter to save"
        (enterPressed)="onEnterPressed($event)"
      ></app-input>
    </div>
  </div>-->

  <!--<span class="title-row" *ngIf="existingTags.length">Existing Tags</span>
  <div class="image-builder-styling-row" *ngIf="existingTags.length">
    <div *ngFor="let tag of existingTags; index as i" class="template-tag">
      <div class="template-tag-name">{{tag.name}}</div>
      <div class="remove-template-tag-name" (click)="removeTag(i)" matRipple>x</div>
    </div>
  </div>-->

  <span class="title-row">{{'BUILD.Template Name' | translate}} *</span>
  <div class="image-builder-styling-row">
    <div class="max-width-350">
      <app-input
        [formGroup]="tagsForm"
        name="name"
        errorMassage="Template Name"
        [low]="true"
        placeholder="Enter template name"
        [disabled]="strictReadOnly"
      ></app-input>
    </div>
  </div>

  <span class="title-row">{{'BUILD.Template Description' | translate}}</span>
  <div class="image-builder-styling-row">
    <div class="max-width-350">
      <app-textarea
        [formGroup]="tagsForm"
        name="description"
        errorMassage="Template Description"
        [low]="true"
        placeholder="Enter template description"
        minRows="2"
        [disabled]="strictReadOnly"
      ></app-textarea>
    </div>
  </div>

  <span class="title-row">{{'BUILD.Template Layout' | translate}}</span>
  <div class="image-builder-styling-row">
    <div class="max-width-350">
      <app-select
        [formGroup]="tagsForm"
        name="templateType"
        errorMassage="Template Layout"
        [low]="true"
        [selectOptions]="templateTypeOptions"
        [disabled]="strictReadOnly"
      ></app-select>
    </div>
  </div>

  <app-button
    *ngIf="!strictReadOnly"
    label="SAVE IN LIBRARY"
    type="flat"
    color="#0064be"
    (clickEvent)="updateTemplate()"
    [disabled]="isButtonDisabled"
    width="100%"
  ></app-button>

</div>


