import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { filter, scan, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import { UpdateDataService } from 'src/app/services/update.data/update.data.service';

@Component({
  selector: 'app-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: [
    './infinite-scroll.component.scss',
    '../form-elements/base.form.element.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class InfiniteScrollComponent implements OnInit, OnDestroy {
  @Input() low: boolean = false;
  @Input() searching: boolean = false;
  @Output()
  public passOffset: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public infiniteSearch: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('matSelectInfiniteScroll', { static: true })
  infiniteScrollSelect: MatSelect;
  parentData: FormElementDataModel[] = [];

  // Mocks some sort of backend data source
  // mockBankList = Array.from({ length: 1000 }).map((_, i) => `Bank ${i}`);
  // private _data = new BehaviorSubject<any[]>(this.parentData);
  // data$ = this._data.asObservable();

  ctrl: FormControl = new FormControl();
  searchCtrl: FormControl = new FormControl();

  subscriptions: Subscription[] = [];
  _options = new BehaviorSubject([]);
  options$ = this._options.asObservable().pipe(
    scan((acc, curr) => {
      if (!acc || !curr) {
        return [];
      }
      return [...acc, ...curr];
    }, [])
  );
  offset = 0;
  limit = 20;
  /**
   * Holds the option matches
   * a subset of this data is selected
   * using the offset and limit
   */
  // data = [];

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  count: number = 0;
  searchingDonor: any;

  constructor(private updateDataService: UpdateDataService) {}

  ngOnInit() {
    this.updateDataService
      .getInfititeScroll()
      // .pipe(skip(1))
      .subscribe((donor) => {
        // this.count++;
        this.parentData = donor;

        // const results = this.data.slice(this.offset, this.offset + this.limit);
        this._options.next(donor);
      });

    // this.data$.pipe(takeUntil(this._onDestroy)).subscribe({
    //   next: (data) => {
    //     this.data = data;
    //     this.offset = 0;
    //     this._options.next(null);
    //     this.getNextBatch();
    //   }
    // });

    const searchValueChange$ = this.searchCtrl.valueChanges;
    const selectOpenChange$ = this.infiniteScrollSelect.openedChange;
    combineLatest([selectOpenChange$, searchValueChange$])
      .pipe(
        filter(([isOpen]) => isOpen === true),
        takeUntil(this._onDestroy)
      )
      .subscribe(([, value]) => this.onSearchChange(value));

    // this.options$
    //   .pipe(takeUntil(this._onDestroy))
    //   .subscribe((val) =>
    //     console.log(`New view array contains ${val.length} items`)
    //   );
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onChange(e): void {
    this.selectionChange.emit(e);
  }

  onSearchChange(e): void {
    this.searchingDonor = e;
    if (this.searchingDonor) {
      // this.count++;
      // if (this.count === 1) {
        this._options.next(null);

        this.offset = 0;
      // }
    } else {
      this.offset = 0;
      this.count = 0;
      this._options.next(null);
      this.passOffset.emit({
        offset: this.offset
      });
    }
    this.searchingDonor && this.infiniteSearch.emit(e);
    return;
    let val = e ? e.trim() : null;
    if (!val) {
      // Empty search, returns everything
      // this._data.next(this.parentData);
      return;
    } else {
      val = val.toLowerCase();
    }

    const matches = this.parentData.filter(
      (i) => i.label.toLowerCase().indexOf(val.toLowerCase()) > -1
    );

    // this._data.next(matches);
  }

  /**
   * Used for selecting next batch
   * for ngx-infinite-scroll
   */
  getNextBatch(): void {
    // const results = this.data.slice(this.offset, this.offset + this.limit);
    // const results = this.parentData;
    // this._options.next(results);
    this.offset += this.limit;
    this.passOffset.emit({
      offset: this.offset,
      searchingDonor: this.searchingDonor
    });
  }
}
