import {Injectable, Injector} from "@angular/core";
import {HttpParams} from "@angular/common/http";
import {flatMap, mergeMap} from "rxjs/operators";
import {P2pFundraiserModel} from "../../models/p2p/p2p-fundraiser.model";
import {BaseModelService} from '../base.model.service';
import { Paging } from "src/app/models/paging/paging.model";
import { Observable } from "rxjs-compat";
import { PagingHelper } from "../helper/paging.helper";
import { P2pFundraiserDashboardModel } from "src/app/models/p2p/p2p-fundraiser-dashboard.model";
import { P2PStaticPageModel } from "src/app/models/p2p/p2p-static-page.model";

@Injectable({
  providedIn: 'root'
})
export class P2pFundraiserService extends BaseModelService<P2pFundraiserModel> {
  protected get controllerName(): string {
    return 'P2PFundraiser';
  }
  constructor(protected injector: Injector) {
    super(injector);
  }

  public saveFundraiser(model: P2pFundraiserModel) {
    return this.configService.loadConfig().pipe(flatMap(config => {
      return this.http.post(`${config.apiURL}/${this.controllerName}`, model);
    }))
  };

  public publishFundraiser(model: P2pFundraiserModel, token?: string) {
    return this.configService.loadConfig().pipe(flatMap(config => {
      return this.http.post(`${config.apiURL}/${this.controllerName}/publish?token=${token}`, model);
    }))
  };

  public getFundraisers(filter?: Paging) {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(flatMap(config => {
      return this.http.get<P2pFundraiserModel[]>(`${config.apiURL}/${this.controllerName}`,
        {params});
    }))
  };
  public getFundraiserById(id:string) {
    return this.configService.loadConfig().pipe(flatMap(config => {
      return this.http.get(`${config.apiURL}/${this.controllerName}/${id}`);
    }))
  }
  public editFundraiser(model: P2pFundraiserModel) {
    return this.configService.loadConfig().pipe(flatMap(config => {
      return this.http.put(`${config.apiURL}/${this.controllerName}`, model);
    }))
  }

  public getTotalFundraiser(filter?: Paging): Observable<P2pFundraiserModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<P2pFundraiserModel[]>(
          `${config.apiURL}/${this.controllerName}/total`,
          { params }
        );
      })
    );
  }

  public getFundraisersPerPage(filter?: Paging): Observable<P2pFundraiserModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<P2pFundraiserModel[]>(
          `${config.apiURL}/${this.controllerName}`,
          { params }
        );
      })
    );
  }
  public validateToken(params:{token:string, parentId?: string, p2PId?:string}) {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<boolean>(`${config.apiURL}/${this.controllerName}/validateToken`,
          {params})
      })
    )
  }

  public donationsSum(id:string) {
    return this.configService.loadConfig().pipe(flatMap(config => {
      return this.http.get(`${config.apiURL}/${this.controllerName}/donationsSum/${id}`);
    }))
  }

  public reject(id:string) {
    return this.configService.loadConfig().pipe(flatMap(config => {
      return this.http.get(`${config.apiURL}/${this.controllerName}/reject/${id}`);
    }))
  }

  public approve(id:string) {
    return this.configService.loadConfig().pipe(flatMap(config => {
      return this.http.get(`${config.apiURL}/${this.controllerName}/approve/${id}`);
    }))
  }

  public getSoftCreditRollUp(filter?: Paging): Observable<P2pFundraiserDashboardModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.get<P2pFundraiserDashboardModel[]>(
          `${config.apiURL}/${this.controllerName}`,
          { params }
        );
      })
    );
  }

  public getAvailableUrl(customURL: string, p2pId: string, donorName: string, fundraiserId?: string): Observable<string> {
    const params: HttpParams = new HttpParams({fromObject: {
      "name": customURL,
      "p2pId": p2pId,
      "donorName": donorName,
      "fundraiserId": fundraiserId ?? ""
    }})

    return this.configService.loadConfig()
      .pipe(
        mergeMap(config => {
          return this.http.get(`${config.apiURL}/${this.controllerName}/availableURL`, {
            params,
            responseType: "text"
          })
        })
      )
  }

  public getP2Pdashboard(filter?: Paging): Observable<P2pFundraiserDashboardModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<P2pFundraiserDashboardModel[]>(
          `${config.apiURL}/${this.controllerName}/P2Pdashboard`,
          { params }
        );
      })
    );
  }

  public getP2PRollUp(filter?: Paging): Observable<P2pFundraiserDashboardModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<P2pFundraiserDashboardModel[]>(
          `${config.apiURL}/${this.controllerName}/P2PRollUp`,
          { params }
        );
      })
    );
  }

  public getP2PFundraiserPageInfo(eventFriendlyUrl: string, ownerName: string, customUrl: string): Observable<P2PStaticPageModel> {
    if(!eventFriendlyUrl) throw new Error("Event URL was not provided");
    if(!ownerName) throw new Error("Fundraiser's owner's name was not provided");
    if(!customUrl) throw new Error("Fundraiser's custom URL was not provided");

    const params: HttpParams = new HttpParams()
      .set("eventFriendlyUrl", eventFriendlyUrl)
      .set("ownerName", ownerName)
      .set("customUrl", customUrl)

    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.get<P2PStaticPageModel>(`${config.apiURL}/${this.controllerName}/FundraiserPageInfo`, {params});
      })
    )
  }

  public getTokenByFundraiserId(id: string) {
    return this.configService.loadConfig().pipe(flatMap(config => {
      return this.http.get(`${config.apiURL}/${this.controllerName}/GetTokenByFundraiserId/${id}`, {responseType: "text"});
    }))
  }

 }
