import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef, HostListener, Input, OnChanges,
  OnDestroy,
  OnInit, Renderer2, SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AzureFileModel } from '../../models/files/azure-file.model';
import { AzureUploadFileService } from '../../services/lookup/azure-upload-file.service';
import { CampaignModel } from '../../models/campaigns/campaign.model';
import { Guid } from 'guid-typescript';
import { ImageBuilderStateService } from './image-builder.state.service';
import { ImageTemplateModel } from '../../models/image-builder/image.template.model';
import { LayerModel } from '../../models/image-builder/layer.model';
import { Media, Packer, Paragraph, Document } from 'docx';
import { Observable, Subscription } from 'rxjs';
import { SaveComponent } from './components/save/save.component';
import { TemplateType } from '../../models/templates/template.type';
import { TimeZoneModel } from '../../models/time-zone.model';
import EventModel from '../../models/event/event.model';
import FormElementDataModel from '../../models/form.element.data.model';
import html2canvas, { Options } from 'html2canvas';
import { jsPDF } from 'jspdf';
import TemplatePayload from '../../models/templates/template.payload';
import { saveAs } from 'file-saver';

const INITIAL_SCALE_COEFFICIENT = 0.8;
const STYLING_BAR_WIDTH = 375;
const TAB_HEIGHT = 54;
const WORK_AREA_SIDE_MARGINS = 60;

@Component({
  selector: 'app-image-builder',
  templateUrl: './image-builder.component.html',
  styleUrls: ['./image-builder.component.scss'],
  providers: [ImageBuilderStateService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageBuilderComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() public campaign: Partial<CampaignModel>;
  @Input() public event: Partial<EventModel>;
  @Input() public libraryVersion: boolean = false;
  @Input() public readOnly: boolean = false;
  @Input() public stateOptions: FormElementDataModel[] = [];
  @Input() public strictReadOnly: boolean = false;
  @Input() public timeZones: TimeZoneModel[] = [];
  @ViewChild('imageBuilderContainerRef') private imageBuilderContainerRef: ElementRef;
  @ViewChild('mainContainerRef') private mainContainerRef: ElementRef;
  @ViewChild('rootContainerRef') private rootContainerRef: ElementRef;
  @ViewChild(SaveComponent) private SaveComponent: SaveComponent;
  private scale: number = INITIAL_SCALE_COEFFICIENT;
  private subscription: Subscription = new Subscription();
  public apiURL: string = localStorage.getItem('apiurl');
  public currentTab: number = 1;
  public inProgress: boolean = false;
  public stylingContainerHeight: number = 700;

  @HostListener('window:resize') public onWindowResize(): void {
    this.setStylingContainerHeight();
  }

  @HostListener('window:keyup', ['$event']) public keyEvent(event: KeyboardEvent) {
    if (event.key !== 'Delete') {
      return;
    }
    this.ibss.tryToDeleteLayer();
  }

  constructor(
    public ibss: ImageBuilderStateService,
    private renderer: Renderer2,
    private uploadService: AzureUploadFileService,
    private cdr: ChangeDetectorRef,
  ) { }

  public ngOnInit(): void {
    this.subscription.add(
      this.ibss.activeLayerObservable.subscribe((activeLayer: LayerModel) => {
        if (!activeLayer) {
          return;
        }
        this.currentTab = 0;
      })
    );
    this.subscription.add(
      this.ibss.resizeObservable.subscribe(() => this.setStylingContainerHeight())
    );
    this.ibss.resetHistoryStorage();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.stateOptions && changes.stateOptions.currentValue) {
      this.ibss.setStateOptions(changes.stateOptions.currentValue);
    }
    if (changes.timeZones && changes.timeZones.currentValue) {
      this.ibss.setTimeZonesOptions(changes.timeZones.currentValue);
    }
    if (changes.event) {
      this.ibss.setEventData(changes.event.currentValue);
    }
    if (changes.campaign) {
      this.ibss.setCampaignData(changes.campaign.currentValue);
    }
    if (changes.readOnly) {
      this.ibss.setReadOnly(changes.readOnly.currentValue)
    }
  }

  public ngAfterViewInit(): void {
    this.setStylingContainerHeight();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get imageBuilderContainerStyle(): any {
    return {
      width: `${this.ibss.templateWidth}px`,
      height: `${this.ibss.templateHeight}px`,
      transform: `scale(${this.scale}, ${this.scale})`,
    };
  }

  public get imageBuilderContainerBackgroundStyle(): any {
    const {
      backgroundColor = 'transparent',
      backgroundRepeat = 'repeat',
      backgroundUrl = '',
      backgroundSize = 'auto',
      transparency = '1'
    } = this.ibss.templateAttributes;
    return {
      'background': backgroundUrl ? `${backgroundRepeat} url(${backgroundUrl}) top center / ${backgroundSize}, ${backgroundColor}` : 'none',
      'background-color': backgroundColor,
      width: `${this.ibss.templateWidth}px`,
      height: `${this.ibss.templateHeight}px`,
      opacity: transparency
    };
  }

  public screenshots(): void {
    this.inProgress = true;
    this.renderer.setStyle(this.imageBuilderContainerRef.nativeElement, 'transform', 'scale(1, 1)');
    const options: Options = {
      useCORS: true,
      removeContainer: true,
      backgroundColor: '#fff',
      scale: 1,
      logging: false,
    };
    this.ibss.setActiveLayerIndex(null);
    setTimeout(() => {
      html2canvas(this.imageBuilderContainerRef.nativeElement, options)
        .then(canvas => {
          const dataUrl = canvas.toDataURL('image/png');
          const a = document.createElement('a');
          a.href = dataUrl;
          a.download = `${Guid.create()}.png`;
          a.click();
          this.inProgress = false;
          this.renderer.setStyle(this.imageBuilderContainerRef.nativeElement, 'transform', `scale(${this.scale}, ${this.scale})`);
          this.cdr.markForCheck();
        })
        .catch(error => console.log(error));
    });
  }

  private setStylingContainerHeight(): void {
    if (!this.mainContainerRef) {
      return;
    }
    this.ibss.setActiveLayerIndex(null);
    setTimeout(() => {
      this.stylingContainerHeight = this.mainContainerRef.nativeElement.clientHeight - TAB_HEIGHT;
      this.scale = (this.rootContainerRef.nativeElement.clientWidth - STYLING_BAR_WIDTH) / (this.ibss.templateWidth + WORK_AREA_SIDE_MARGINS);
      if (this.scale > INITIAL_SCALE_COEFFICIENT) {
        this.scale = INITIAL_SCALE_COEFFICIENT;
      }
    });
  }

  public get sizeValues(): any {
    return {
      width: `${this.ibss.templateWidth * this.scale}px`,
      height: `${this.ibss.templateHeight * this.scale}px`,
    }
  }

  public get screenshotsSizeValues(): any {
    return {
      width: `${this.ibss.templateWidth * this.scale}px`,
      height: this.inProgress ? `${this.ibss.templateHeight * this.scale}px` : '0px',
    }
  }

  public setTemplate(template: ImageTemplateModel): void {
    this.ibss.setTemplate(template)
  }

  public setTemplatePayload(templatePayload: TemplatePayload): void {
    this.ibss.templatePayload = templatePayload;
  }

  public setTemplateType(templateType: TemplateType): void {
    this.ibss.templateType = templateType;
  }

  public getTemplatePreviewImage(payload: TemplatePayload, callback: (payload: TemplatePayload, url: string) => void): void {
    this.inProgress = true;
    this.renderer.setStyle(this.imageBuilderContainerRef.nativeElement, 'transform', 'scale(1, 1)');
    const options: Options = {
      useCORS: true,
      removeContainer: true,
      backgroundColor: '#fff',
      scale: (208 / this.ibss.templateWidth),
      logging: false,
    };
    this.ibss.setActiveLayerIndex(null);
    setTimeout(() => {
      html2canvas(this.imageBuilderContainerRef.nativeElement, options)
        .then(canvas => {
          const dataUrl = canvas.toDataURL('image/png');
          const name = `${Guid.create()}.png`;
          const azure: AzureFileModel = {
            file: dataUrl.substring(22, dataUrl.length),
            name,
            folder: 'images'
          };
          this.subscription.add(
            this.uploadService.addModel(azure).subscribe(() => {
              const url = `${this.apiURL}/AzureFileStorage/image/${name}`;
              callback(payload, url);
              this.inProgress = false;
              this.renderer.setStyle(this.imageBuilderContainerRef.nativeElement, 'transform', `scale(${this.scale}, ${this.scale})`);
              this.cdr.markForCheck();
            })
          );
        })
        .catch(error => console.log(error));
    });
  }

  public getTemplateImage(callback: (url: string) => void): void {
    this.inProgress = true;
    this.renderer.setStyle(this.imageBuilderContainerRef.nativeElement, 'transform', 'scale(1, 1)');
    const options: Options = {
      useCORS: true,
      removeContainer: true,
      backgroundColor: '#fff',
      scale: 1,
      logging: false,
    };
    this.ibss.setActiveLayerIndex(null);
    setTimeout(() => {
      html2canvas(this.imageBuilderContainerRef.nativeElement, options)
        .then(canvas => {
          const dataUrl = canvas.toDataURL('image/png');
          callback(dataUrl);
          this.inProgress = false;
          this.renderer.setStyle(this.imageBuilderContainerRef.nativeElement, 'transform', `scale(${this.scale}, ${this.scale})`);
          this.cdr.markForCheck();
        })
        .catch(error => console.log(error));
    });
  }

  public get template(): ImageTemplateModel {
    return this.ibss.template;
  }

  public get updateTemplate(): Observable<TemplatePayload> {
    return this.ibss.forceSaveTemplateEmitter;
  }

  public triggerTemplatePayload(): void {
    this.SaveComponent.updateTemplate();
  }

  public showErrorFields(): void {
    this.currentTab = 2;
  }

  public resetActiveLayer(): void {
    this.ibss.setActiveLayerIndex(null);
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }

  public onDrop({ x, y }: DragEvent): void {
    const domRect: DOMRect = this.imageBuilderContainerRef.nativeElement.getBoundingClientRect();
    const containerX = domRect.x;
    const containerY = domRect.y;
    const dropX = (x - containerX) / this.scale;
    const dropY = (y - containerY) / this.scale;
    this.ibss.createLayerOnDrop(dropX, dropY);
  }

  public exportPDF(): void {
    this.inProgress = true;
    this.renderer.setStyle(this.imageBuilderContainerRef.nativeElement, 'transform', 'scale(1, 1)');
    this.ibss.setActiveLayerIndex(null);
    const options: Options = {
      backgroundColor: '#fff',
      removeContainer: true,
      scale: 1,
      useCORS: true,
      logging: false,
    };
    this.subscription.add(html2canvas(this.imageBuilderContainerRef.nativeElement, options).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'cm', 'a4'); // Generates PDF in portrait mode
      const height = 20 * this.ibss.templateHeight / this.ibss.templateWidth;
      pdf.addImage(contentDataURL, 'PNG', 0.5, 0.5, 20, height);
      pdf.save(`template_${new Date().toDateString()}`);
      this.renderer.setStyle(this.imageBuilderContainerRef.nativeElement, 'transform', `scale(${this.scale}, ${this.scale})`);
      this.inProgress = false;
      this.cdr.markForCheck();
    }));
  }

  public exportDOCX(): void {
    this.inProgress = true;
    this.renderer.setStyle(this.imageBuilderContainerRef.nativeElement, 'transform', 'scale(1, 1)');
    this.ibss.setActiveLayerIndex(null);
    const options: Options = {
      backgroundColor: '#fff',
      removeContainer: true,
      scale: 1,
      useCORS: true,
      logging: false,
    };
    this.subscription.add(html2canvas(this.imageBuilderContainerRef.nativeElement, options).then(canvas => {
      const docWidth = 600;
      const docHeight = this.ibss.templateHeight * docWidth / this.ibss.templateWidth;
      const doc = new Document();
      doc.addSection({
        children: [new Paragraph(Media.addImage(doc, canvas.toDataURL('image/png'), docWidth, docHeight))],
      });
      Packer.toBlob(doc).then(buffer => {
        saveAs(buffer, `template_${new Date().toDateString()}.docx`);
      });
      this.renderer.setStyle(this.imageBuilderContainerRef.nativeElement, 'transform', `scale(${this.scale}, ${this.scale})`);
      this.inProgress = false;
      this.cdr.markForCheck();
    }));
  }
}
