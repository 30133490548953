import { Component, OnInit, OnDestroy } from '@angular/core';
import { delay } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { Subscription } from 'rxjs';
import { AppConfig } from '../models/app.config';
import { ConfigService } from '../services/appconfig.service';
import { IconsType } from '../models/enum/icons.type';

@Component({
  selector: 'app-anonymous-layout',
  templateUrl: './anonymous-layout.component.html',
  styleUrls: ['./anonymous-layout.component.scss']
})
export class AnonymousLayoutComponent implements OnInit, OnDestroy {
  public isDataLoading: boolean = false;
  public isMobileMenuOpen: boolean;
  public subscription: Subscription = new Subscription();
  public currentYear: number = new Date().getFullYear();
  public appVersion: string = '';
  public IconsType = IconsType;

  constructor(
    private loaderService: LoaderService,
    private configService: ConfigService
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.loaderService.loader
        .pipe(delay(0))
        .subscribe((value: boolean) => (this.isDataLoading = value))
    );
    this.subscription.add(
      this.configService.loadConfig().subscribe((config: AppConfig) => this.appVersion = config.appVersion)
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onLogoClick(): void {
    window.location.href = 'https://www.theauxilia.com/';
  }
}
