<div class="add-new-tag-wrapper" [ngClass]="{padding0: isFormSettingsPage}">
  <div *ngIf="!isDonorTagManager; else TagTextArea">
    <div class="add-new-tag-row">
      <div class="max-width-350 right-margin" [ngClass]="{marginRight0: isFormSettingsPage}">
        <app-input [formGroup]="form"
                   name="name"
                   label="Tag Name *"
                   errorMassage="Tag Name"
                   (blurEvent)="onBlur()"
                   (focusEvent)="onFocus()"></app-input>
      </div>

      <div class="max-width-350 has-tooltip">
        <app-textarea [formGroup]="form"
                      name="description"
                      label="Tag Description *"
                      errorMassage="Tag Description"
                      [minRows]="isFormSettingsPage ? 4 : 1"
                      [maxRows]="isFormSettingsPage ? 5 : 1"></app-textarea>
        <i class="fas fa-info-circle tooltip-info-icon" *ngIf="!isFormSettingsPage"
           matTooltip="{{'DONORS.Remember, other members of your team will need to know what the tag means! Be sure to provide a good definition' | translate}}"
           matTooltipHideDelay="200"
           matTooltipShowDelay="200"
           matTooltipPosition="above"
           matTooltipClass="table-tooltip"></i>
      </div>
    </div>
  </div>

  <ng-template #TagTextArea>
    <div class="add-new-tag-row flexColumn">
      <div class="max-width-350 right-margin" [ngClass]="{marginRight0: isFormSettingsPage}">
        <app-input [formGroup]="form"
                   name="name"
                   label="Tag Name *"
                   errorMassage="Tag Name"
                   (blurEvent)="onBlur()"
                   (focusEvent)="onFocus()"></app-input>
      </div>

      <div class="max-width-350 has-tooltip">
        <app-textarea [formGroup]="form"
                      name="description"
                      label="Tag Description *"
                      errorMassage="Tag Description"
                      [minRows]="1"></app-textarea>
        <i class="fas fa-info-circle tooltip-info-icon tooltipPosition"
           matTooltip="{{'DONORS.Remember, other members of your team will need to know what the tag means! Be sure to provide a good definition' | translate}}"
           matTooltipHideDelay="200"
           matTooltipShowDelay="200"
           matTooltipPosition="above"
           matTooltipClass="table-tooltip"></i>
      </div>
    </div>
  </ng-template>

  <div class="add-new-tag-row action-buttons" [ngClass]="{settingActionBtn: isFormSettingsPage}">
    <div *ngIf="!isDonorTagManager; else TagButtons">
      <app-button 
                  *ngIf="form.get('description')?.value && form.get('name')?.value"
                  [ngClass]="{paddingBottom22: isFormSettingsPage}"
                  label="Save"
                  type="stroked"
                  (clickEvent)="save()"
                  width="140"
                  color="{{isFormSettingsPage ? '#6A6A6A' : '#0064be'}}"
                  [disabled]="(inProgress | async) || form.invalid"></app-button>

      <app-button *ngIf="!isFormSettingsPage && form.get('description')?.value && form.get('name')?.value"
                  [ngClass]="{paddingBottom22: isFormSettingsPage}"
                  label="Cancel"
                  type="stroked"
                  (clickEvent)="cancel()"
                  width="140"></app-button>

      <app-button *ngIf="isFormSettingsPage"
                  [ngClass]="{paddingBottom22: isFormSettingsPage}"
                  label="Cancel"
                  type="stroked"
                  (clickEvent)="cancel()"
                  width="140"
                  color="#6A6A6A"></app-button>
    </div>

    <ng-template #TagButtons>
      <app-button [ngClass]="{paddingBottom22: isFormSettingsPage}"
                  label="Cancel"
                  type="stroked"
                  (clickEvent)="cancel()"
                  width="100"
                  [buttonColorType]="'warn'"
                  [isNew]="true"></app-button>
      <app-button [ngClass]="{paddingBottom22: isFormSettingsPage}"
                  label="Add"
                  type="flat"
                  (clickEvent)="save()"
                  width="100"
                  [buttonColorType]="'primary'"
                  [isNew]="true"
                  [disabled]="(inProgress | async) || form.invalid"></app-button>
    </ng-template>
  </div>
</div>
