import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { Subscription } from 'rxjs-compat';
import { EventStatus } from 'src/app/models/enum/event.status';
import EventModel from 'src/app/models/event/event.model';
import { P2pFundraiserModel, P2PStatus } from 'src/app/models/p2p/p2p-fundraiser.model';
import { EventService } from 'src/app/services/events/event.service';
import { P2pFundraiserService } from 'src/app/services/p2p/p2p-fundraiser.service';

/**
 * Component used as proxy betweeen p2p page and donor portal.
 * Main goal is to check if event is finished (completed or canceled).
 * If finished displays proper message. Otherwise redirect to donor portal.
 */

@Component({
  selector: 'app-create-fundraiser',
  templateUrl: './create-fundraiser.component.html',
  styleUrls: ['./create-fundraiser.component.scss', '../../shared/styles/layout.scss']
})
export class CreateFundraiserComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  isLoading: boolean;
  eventFinished: boolean;
  fundraiserFinished: boolean;

  constructor(
    private eventService: EventService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fundraiserService: P2pFundraiserService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;

    const clientId: string = this.activatedRoute.snapshot.params.clientId;
    const eventId: string = this.activatedRoute.snapshot.params.eventId;
    const fundraiserId: string = this.activatedRoute.snapshot.params.fundraiserId;

    const eventRequest: Observable<EventModel> = this.eventService.getModel(eventId);
    const fundraiserRequest: Observable<P2pFundraiserModel> = this.fundraiserService.getModel(fundraiserId);
   if(fundraiserId) {
     this.subscription.add(
       forkJoin([eventRequest, fundraiserRequest]).subscribe({
         next: (res: any[]) => {
           this.isLoading = false;

           const event: EventModel = res[0];
           const fundraiser: P2pFundraiserModel = res[1];


           if(!event) throw new Error(`Event with id: ${eventId} does not exists`);
           if(!fundraiser) throw new Error(``)

           if(event.status === EventStatus.Canceled || event.status === EventStatus.Complete) {
             this.eventFinished = true;
             return;
           }

           if(fundraiser.status !== P2PStatus.live) {
             this.fundraiserFinished = true;
             return;
           }

           this.router.navigate(["/donor-portal", "organizations", clientId, "createP2P"], {queryParams: {eID: event?.id, fID: fundraiser?.id}});
         },
         error: (err) => {
           this.isLoading = false;

         }
       })
     )
    } else {
     this.subscription.add(
       eventRequest
         .subscribe(res => {
         const event: EventModel = res;
         if(!event) throw new Error(`Event with id: ${eventId} does not exists`);
         if(event.status === EventStatus.Canceled || event.status === EventStatus.Complete) {
           this.eventFinished = true;
           return;
         }
         this.router.navigate(["/donor-portal", "organizations", clientId, "createP2P"], {queryParams: {eID: event?.id, fID: null}});
       },
         error => {
           this.isLoading = false;
           console.error(error);
         }
         )
     )
   }

  }

}
