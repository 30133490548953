import {AuthService} from '../../services/auth.service';
import {CLIENT_ADMIN, DonationMethodsViews, DonationStatusTypes, giftTypes as giftTypesConstant} from '../../constants';
import {ClientAllocatedFundModel} from '../../models/client/client-allocated-fund.model';
import {ClientDonorType} from 'src/app/models/enum/donor.type';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {DonationStatusType} from '../../models/enum/donation.status.type';
import {DonationType} from '../../models/enum/donation-type.enum';
import {EventDonationModel} from '../../models/event/event-donation.model';
import {MatTableDataSource} from '@angular/material/table';
import {PaymentSourceType} from '../../models/enum/payment.source.type';
import {Router} from '@angular/router';
import {SortOrder} from 'src/app/models/enum/sort.order';
import ColumnFilterModel from 'src/app/models/filter-sort/column.filter.model';
import ColumnFilterOptionsModel from 'src/app/models/filter-sort/column.filter.options.model';
import FilterSortModel from 'src/app/models/filter-sort/filter.sort.model';
import FormElementDataModel from '../../models/form.element.data.model';
import LazyLoadSearchModel, {LazyLoadRequestModel} from '../../models/filter-sort/lazy.load.search.model';
import {LookupStoreService} from '../../services/lookup/lookup-store.service';
import CountryModel from '../../models/internationalization/country.model';
import {first, map, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {P2pFundraiserService} from "../../services/p2p/p2p-fundraiser.service";
import * as moment from "moment";

@Component({
  selector: 'app-contributions-table',
  templateUrl: './contributions-table.component.html',
  styleUrls: ['./contributions-table.component.scss']
})
export class ContributionsTableComponent implements OnChanges, OnInit {
  @Input() public allocatedFunds: ClientAllocatedFundModel[] = [];
  @Input() public columnsFilterOptions: ColumnFilterOptionsModel[] = [];
  @Input() public data: EventDonationModel[] = [];
  @Input() public distinctData: any;
  @Input() public filterSortConfig: FilterSortModel;
  @Input() public initialSelection: any;
  @Input() public isFiltersVisible: boolean;
  @Input() public isDonorPortal: boolean = false;
  @Input() public isSettingsVisible: boolean;
  @Input() public rolesPermission: boolean = false;
  @Input() public tableDataColumns: string[] = [];
  @Output() public deleteRow: EventEmitter<string> = new EventEmitter<string>();
  @Output() public editRow: EventEmitter<string> = new EventEmitter<string>();
  @Output() public filterChanged: EventEmitter<ColumnFilterModel> = new EventEmitter<ColumnFilterModel>();
  @Output() public getFilterOptions: EventEmitter<LazyLoadRequestModel> = new EventEmitter<LazyLoadRequestModel>();
  @Output() public sortChanged: EventEmitter<FilterSortModel> = new EventEmitter<FilterSortModel>();
  @ViewChild('tableRef', { read: ElementRef }) public table: ElementRef;
  public clientAdminPermission: boolean = false;
  public SortOrder = SortOrder;
  public tableColumns: string[] = [];
  public tableSource: MatTableDataSource<EventDonationModel>;
  public fundraisersList: {id: string, name: string}[] = [];

  private countriesMap: Map<number, CountryModel> = new Map<number, CountryModel>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private lookupStoreService: LookupStoreService,
    private translate: TranslateService,
    private fundraiserService: P2pFundraiserService,
  ) { }

  public ngOnInit(): void {
    const roles = this.authService.getIdentityClaimsRole();
    this.clientAdminPermission = roles.includes(CLIENT_ADMIN);

    this.lookupStoreService.countriesMap$
      .pipe(
        first(),
        tap((countriesMap: Map<number, CountryModel>) => this.countriesMap = countriesMap)
      )
      .subscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableDataColumns && changes.tableDataColumns.currentValue) {
      this.tableColumns = this.isSettingsVisible
        ? [...this.tableDataColumns, 'settings']
        : [...this.tableDataColumns];
    }
    if (this.data) {
      this.tableSource = new MatTableDataSource<EventDonationModel>(this.data);
      this.getFundraisers();
    }
  }

  public getColumnTitle(column: string): string {
    const columnsTitles = {
      allocatedFundID: 'Fund',
      clientID: 'Organization',
      clientName: 'Organization',
      countryId: 'Country/Region',
      createdByUserName: 'Role',
      donationAmmount: 'Amount',
      donationMethod: 'Method',
      donationPaymentSource: 'Payment service',
      donationRealDate: 'Date',
      donationStatus: 'Status',
      donorFullName: 'Donor',
      donorType: 'Donor Type',
      federalIDNumber: 'Federal ID Number',
      giftType: 'Gift Type',
      isAnonymous: 'Anonymous',
      fundraiserName: 'Fundraiser Name',
      organizationName: 'Organization Name',
      origin: 'Origin',
      publicID: 'Donation Id',
      sourceValue: 'Source',
      templateName: 'Donation Form',
      tierName: 'Tier Name',
      disclaimers: 'Disclaimers'
    };
    return this.translate.instant('CONTR.' + columnsTitles[column]);
  }


  private getFundraisers() {
    this.data.forEach(d => {
      if(d.fundraiserID) {
        this.fundraiserService.getModel(d.fundraiserID)
          .pipe(
            map(fundraiser => {
              return fundraiser.p2PName
            })
          )
          .subscribe(name => {
            this.fundraisersList.push({id: d.fundraiserID, name});
          })
      }
    });
  }
 public getFundraiserName(id:any) {
   if(id && this.fundraisersList.length > 0){
     return this.fundraisersList.find(f => f.id === id)?.name
   }
   else return '-'
 }
  public onEditLinkClick(event: string): void {
    this.editRow.next(event);
  }

  public checkColumn(column: string): boolean {
    return this.filterSortConfig.sortField === column;
  }

  public onDeleteLinkClick(event: string): void {
    this.deleteRow.next(event);
  }

  public getTextNode(element: EventDonationModel, column: string): string {
    switch (column) {
      case 'donationRealDate':
        return moment.utc(element[column]).format("MM/DD/YYYY") || ""
      case 'countryId':
        return this.countriesMap.size > 0 ? this.countriesMap.get(element[column]).name : 'USA';
      case 'donationStatus':
        return DonationStatusTypes[element[column]];
      case 'donationMethod':
        return DonationMethodsViews[element[column]];
      case 'isAnonymous':
        return element[column] ? 'Yes' : 'No';
      case 'donationPaymentSource':
        return this.getPaymentService(element[column]);
      case 'donorType':
        return ClientDonorType[element[column]];
      case 'clientID':
        const clientIdOptions = this.columnsFilterOptions.find(({ columnName }: ColumnFilterOptionsModel) => columnName === 'clientID');
        if (!clientIdOptions) {
          return '';
        }
        const client = clientIdOptions.options.find(({ value }: FormElementDataModel) => value === element[column]);
        return client ? client.label : '';
      case 'allocatedFundID':
        const fund = this.allocatedFunds.find(({ id }) => id === element[column]);
        return fund ? fund.name : '-';
      case 'giftType':
        const giftTypes = giftTypesConstant
        return giftTypes[element[column]];
      case 'templateName':
        return element.origin !== 'Offline' ? (element[column] ? element[column] : '-') : '-';
      case 'tierName':
        return element[column] !== null ? element[column] : '-';
      case 'disclaimers':
        return element[column].length !== 0 ? 'Yes' : 'N/A';
      case 'clientName' :
        return this.columnsFilterOptions.find(o => o.columnName === 'clientName')?.options?.find(opt => opt.value === element['clientID'])?.label
      default:
        return element[column] || '-';
    }
  }

  private getPaymentService(value: number): string {
    switch (value) {
      case PaymentSourceType.Bitcoin:
        return 'Bitcoin';
      case PaymentSourceType.CardConnect:
        return 'CardConnect';
      case PaymentSourceType.Cornerstone:
        return 'Cornerstone';
      case PaymentSourceType.PayPal:
        return 'PayPal';
      default:
        return '';
    }
  }

  public getStatusCssClass(element: EventDonationModel): string {
    switch (element.donationStatus) {
      case DonationStatusType.Completed:
      case DonationStatusType.Authorized:
        return 'status-indicator green';
      case DonationStatusType.Canceled:
      case DonationStatusType.Refunded:
      case DonationStatusType.Declined:
        return 'status-indicator red';
      case DonationStatusType.Pending:
        return 'status-indicator black';
      case DonationStatusType.Deferred:
      case DonationStatusType.Retry:
        return 'status-indicator yellow';
    }
  }

  public sortTable(column: string): void {
    let sortOrder: SortOrder;
    if (this.filterSortConfig.sortField === column) {
      switch (this.filterSortConfig.sortOrder) {
        case SortOrder.Ascending:
          sortOrder = SortOrder.Descending;
          break;
        case SortOrder.Descending:
          sortOrder = SortOrder.Ascending;
      }
    } else {
      sortOrder = SortOrder.Ascending;
    }
    const result: FilterSortModel = {
      sortField: column,
      sortOrder,
      columnFilters: this.filterSortConfig.columnFilters
    };
    this.sortChanged.emit(result);
  }

  public withMultiSelectFilter(column: string): boolean {
    return column === 'donationStatus'
      || column === 'donationMethod'
      || column === 'isAnonymous'
      || column === 'origin'
      || column === 'clientID'
      || column === 'templateName'
      || column === 'allocatedFundID'
      || column === 'tierName'
      || column === 'fundraiserName'
      || column === 'clientName';
  }

  public withFilter(column: string): boolean {
    return column === 'donorFullName' || column === 'sourceValue';
  }

  public checkDonationType(model: EventDonationModel): boolean {
    return model.donationType === DonationType.Recurring || model.donationType === DonationType.Pledge;
  }

  public getSelectOptions(column: string): ColumnFilterOptionsModel {
      return this.columnsFilterOptions.find(
        options => options.columnName === column
      );
    }


  public selectedOptions(event: ColumnFilterModel): void {
    this.filterChanged.emit(event);
  }

  public isActiveFilter(column: string): boolean {
    if (!this.filterSortConfig) {
      return false;
    } else {
      const { columnFilters }: FilterSortModel = this.filterSortConfig;
      return !!columnFilters.find(({ field }: ColumnFilterModel) => field === column);
    }
  }

  public recurringSettingsEdit(id: string): void {
    const url = this.isDonorPortal ? `/donor-portal/dashboard/recurring-donation/${id}` : `/clients/contributions/recurring-payments/${id}`
    this.router.navigateByUrl(url);
  }

  public getSortOrder(column: string): SortOrder {
    return this.filterSortConfig.sortField === column ? this.filterSortConfig.sortOrder : null;
  }

  public onLazySelectFilterChanged(values: any[], field: string): void {
    this.filterChanged.emit({ field, values });
  }

  public getOptions(searchModel: LazyLoadSearchModel, column: string): void {
    this.getFilterOptions.emit({ ...searchModel, field: column });
  }

  public getInitialSelection(column: string): ColumnFilterModel {
    return this.filterSortConfig.columnFilters.find(item => item.field === column);
  }
}
