import {ActivatedRoute, Router} from '@angular/router';
import {AdminTemplatesStateService} from '../../admin-templates-state.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import {AzureFileModel} from 'src/app/models/files/azure-file.model';
import {AzureUploadFileService} from 'src/app/services/lookup/azure-upload-file.service';
import {BeforeUnloadComponent} from '../../../../../components/before-unload/before-unload.component';
import {ClientModel} from '../../../../../models/client/client.model';
import {ClientModuleService} from '../../../../clients/client.module.service';
import {ClientPlaceholderModel} from '../../../../../models/client/client-placeholder.model';
import {ClientService} from '../../../../../services/client/client.service';
import {ComponentCanDeactivate} from '../../../../../guards/pending-changes.guard';
import {CountryStateService} from '../../../../../services/events/country.state.service';
import {DEFAULT_LETTER_CAMPAIGN_MJML_TEMPLATE, KNOWN_PLACEHOLDERS} from '../../../../../constants';
import {DialogBeforeunloadResponseType} from '../../../../../models/enum/dialog.beforeunload.response.type';
import {EmailBuilderComponent} from 'src/app/components/email-builder/email-builder.component';
import {EventStatus} from '../../../../../models/enum/event.status';
import {Filter} from '../../../../../models/paging/filter.model';
import {FilterType} from '../../../../../models/enum/filter.type';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormValidators} from '../../../../../shared/validatiors/form-validators';
import {Guid} from 'guid-typescript';
import {ImageBuilderComponent} from '../../../../../components/image-builder/image-builder.component';
import {LoaderService} from '../../../../../services/loader.service';
import {Location} from '@angular/common';
import {debounceTime, filter, first, map, switchMap, tap} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {BehaviorSubject, forkJoin, Observable, of, Subject, Subscription, zip} from 'rxjs';
import {Paging} from '../../../../../models/paging/paging.model';
import {Patterns} from '../../../../../shared/validatiors/patterns.model';
import {TemplateCreationType} from 'src/app/models/templates/template.creation.type';
import {TemplateModelService} from 'src/app/services/templates/template.model.service';
import {TemplateStatus} from 'src/app/models/enum/template.status';
import {TemplateUsageType} from 'src/app/models/templates/template.usage.type';
import {ToastrService} from 'ngx-toastr';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import html2canvas, {Options} from 'html2canvas';
import HtmlConvertedModel from 'src/app/models/templates/html.converted.model';
import TemplateModel from 'src/app/models/templates/template.model';
import TemplatePayload from 'src/app/models/templates/template.payload';
import {FormBuilderComponent} from '../../../../../components/form-builder/form-builder.component';
import {LookupStoreService} from '../../../../../services/lookup/lookup-store.service';
import CountryModel from '../../../../../models/internationalization/country.model';
import {ClientIdStateService} from '../../../../../services/client.module.state/client.id.state.service';
import {ClientPaymentModel} from '../../../../../models/client/client.payment.model';
import {FeeAndTaxes} from '../../../../../models/enum/fee-and-taxes.enum';
import {PaymentServiceType} from '../../../../../models/enum/payment.service.type';
import {ClientType} from '../../../../../models/enum/client.type';
import {SourceTemplateService} from '../../../../../services/templates/source.template.service';
import SourceTemplateModel, {TemplateSourceType} from '../../../../../models/templates/source.template.model';
import {SortOrder} from '../../../../../models/enum/sort.order';
import {UtilsComponent} from '../../../../../components/utils.component';
import {CustomDonationFormsService} from '../../../../../services/donation-forms/custom.donation.forms.service';
import {AdminConfirmationComponent} from '../../../admin-confirmation/admin-confirmation.component';
import {TranslateService} from '@ngx-translate/core';
import {UnauthorizedClientComponent} from '../../../../../components/unauthorized-client/unauthorized-client.component';
import {
  SaveLetterAction,
  SaveOrCreateCampaignDialogComponent
} from '../save-or-create-campaign-dialog/save-or-create-campaign-dialog.component';
import {CampaignModel} from 'src/app/models/campaigns/campaign.model';
import {CampaignStatus, CampaignType} from 'src/app/models/enum';
import {CampaignOriginType} from 'src/app/models/enum/campaign.origin.type';
import {CampaignService} from 'src/app/services/campaign/campaign.service';
import {TemplateClientRestrictionModel} from 'src/app/models/templates/template.client.restriction.model';
import FbTemplateModel, {FbElementType} from 'src/app/models/form-builder/fb.template.model';
import {EventDonationDisclaimerModel} from "../../../../../models/event/event-donation-disclaimer.model";
import {EventDonationDisclaimerService} from "../../../../../services/events/event-donation-disclaimer.service";

@Component({
  selector: 'app-admin-template-layout',
  templateUrl: './admin-template-layout.component.html',
  styleUrls: ['./admin-template-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CountryStateService]
})
export class AdminTemplateLayoutComponent implements OnInit, AfterViewInit, OnDestroy, ComponentCanDeactivate {
  @ViewChild(EmailBuilderComponent) private EmailBuilderComponent: EmailBuilderComponent;
  @ViewChild(ImageBuilderComponent) private ImageBuilderComponent: ImageBuilderComponent;
  @ViewChild(FormBuilderComponent) private FormBuilderComponent: FormBuilderComponent;
  public isAdminPortal: boolean;
  private subscription: Subscription = new Subscription();
  private templateId: string;
  private today: Date = new Date();
  private url: string;
  private userID: string;
  private userName: string;
  public apiURL: string = localStorage.getItem('apiurl');
  public beforeunload: EventEmitter<boolean> = new EventEmitter<boolean>();
  public clientID: string;
  public clientName: string;
  public clientsWithRelationships: FormElementDataModel[] = [];
  public currentTab: number;
  public EventStatus = EventStatus;
  public eventTemplateId: string;
  public globalTemplate: TemplateModel;
  public isCampaignForm: boolean;
  public isClientPortal: boolean;
  public isEventForm: boolean;
  public mockId: string = '00000000-0000-0000-0000-000000000000';
  public readOnly: boolean;
  public templateIsSaved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLetterTemplatePage: boolean;
  public strictReadOnly: boolean;
  private disclaimersReferenceModel: string = '[]';
  public usageType: TemplateUsageType;
  private newSourceInstance: boolean = false;

  public campaignForm = this.fb.group({
    name: ['Campaign Name', [Validators.required, Validators.maxLength(250)]],
    clientId: [this.mockId],
    hasDonationAsk: [true]
  });

  public eventForm: FormGroup = this.fb.group({
    name: ['Event name', [Validators.required, Validators.maxLength(1000)]],
    description: ['Event description. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', [Validators.maxLength(1000)]],
    country: [1],
    territorialEntity: ['', [Validators.maxLength(250)]],
    address1: ['Location Street', [Validators.required, Validators.maxLength(250)]],
    address2: ['Location Street', [Validators.required, Validators.maxLength(250)]],
    city: ['Location City', [Validators.required, Validators.maxLength(250)]],
    state: [1, [Validators.required]],
    zip: [123456789, [Validators.required, Validators.pattern('^(\\d{5}|\\d{9})$')]],
    timeZoneID: 13,
    eventSchedularList: this.fb.array([
      this.fb.group({
        startTime: ['09:00 am', [Validators.required]],
        endTime: ['10:30 pm', [Validators.required]],
        startDate: [this.today, [Validators.required]],
        endDate: [this.today, [Validators.required]],
      })
    ]),
    clientName: ['Organization Name', [Validators.maxLength(250)]],
    clientWebSite: ['organization-web-site.com', [Validators.maxLength(250)]],
    contactUsEmail: ['info@organization-web-site.com', [FormValidators.emailValidator, Validators.maxLength(100)]],
    contactUsPhone: [2345678910, [Validators.pattern(Patterns.onlyNumbers)]],
    eventItineraries: this.fb.array([
      this.fb.group({
        startTime: ['09:00 am', [Validators.required]],
        endTime: ['10:30 pm', [Validators.required]],
        startDate: [new Date(2020, 6, 4), [Validators.required]],
        endDate: [new Date(2020, 6, 4), [Validators.required]],
        text: ['Itinerary text. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', [Validators.required, Validators.maxLength(150)]],
      }),
    ]),
    accessabilityText: ['Accessibility statement text. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'],
    hasLocation: true,
    hasDonationAsk: [true]
  });

  private mockClient: ClientModel = new ClientModel();
  private clientModel: ClientModel;

  private compareWithTemplateModel: string = null;
  private saveSource: Subject<void> = new Subject<void>();
  private newCampaignCreated: boolean;

  constructor(
    private adminTemplatesStateService: AdminTemplatesStateService,
    private authService: AuthService,
    private clientModuleService: ClientModuleService,
    private clientService: ClientService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private templateModelService: TemplateModelService,
    private toastrService: ToastrService,
    private uploadService: AzureUploadFileService,
    public countryStateService: CountryStateService,
    public lookupStoreService: LookupStoreService,
    private clientIdStateService: ClientIdStateService,
    private sourceTemplateService: SourceTemplateService,
    private customDonationFormsService: CustomDonationFormsService,
    public translate: TranslateService,
    private campaignService: CampaignService,
    private eventDonationDisclaimerService: EventDonationDisclaimerService
  ) {
  }

  public ngOnInit(): void {
    if (this.route.snapshot.params.readonly) {
      this.readOnly = true;
      this.strictReadOnly = true;
    }
    this.templateId = this.route.snapshot.params.templateId;
    this.clientID = this.route.snapshot.params.clientID;
    this.setRolesAndUrl();
    this.usageType = +this.route.snapshot.params.usageType;
    if(this.usageType === TemplateUsageType.Letter) this.isLetterTemplatePage = true;
    this.defineForm();
    this.getTemplate();
    if (this.clientID) {
      this.subscription.add(
        zip(this.clientModuleService.clientsWithRelationships, this.clientModuleService.clientName)
          .subscribe(([clientsWithRelationships, clientName]) => {
            this.clientsWithRelationships = [{
              label: clientName,
              value: this.authService.getIdentityClaimsOriginId()
            }, ...clientsWithRelationships];
            this.clientName = clientName;
          })
      );
    }
    this.countryStateService.setForm(this.eventForm);
    this.getInitialData();

    this.subscription.add(
      this.saveSource.asObservable()
        .pipe(
          debounceTime(1000),
          tap(() => {
            if (this.isEmailBuilder) {
              if(this.usageType !== TemplateUsageType.Letter || !this.clientID) {
                this.EmailBuilderComponent.triggerTemplatePayload();
                return;
              };
              const config: MatDialogConfig<boolean> = {
                data: true
              }
              this.dialog.open(SaveOrCreateCampaignDialogComponent, config)
                .afterClosed()
                .subscribe((res: SaveLetterAction) => {
                  if(res === SaveLetterAction.Save) {
                    this.EmailBuilderComponent.triggerTemplatePayload();
                  }  else if(res === null) {
                    return
                  }
                })

            } else if (this.isImageBuilder) {
              this.ImageBuilderComponent.triggerTemplatePayload();
            } else if (this.isFormBuilder) {
              this.FormBuilderComponent.triggerTemplatePayload();
            }
          })
        )
        .subscribe()
    );
  }
   public showCreateCampaignDialog() {
     const config: MatDialogConfig<boolean> = {
       data: false
     }
     this.subscription.add(
       this.dialog.open(SaveOrCreateCampaignDialogComponent, config).afterClosed()
           .subscribe((res: SaveLetterAction) => {
             if(!this.clientID) {
               throw Error("No organization has been selected");
             }
             if(res === SaveLetterAction.CreateCampaign) this.createNewCampaignDraft();
               else return
             })
     )
   }
  private createNewCampaignDraft(): void {
    const newCampaign: CampaignModel = {
      clientID: this.clientID,
      clientName: this.clientName,
      type: CampaignType.Letter,
      hasAppeal: false,
      hasDonationAsk: false,
      isArchived: false,
      isFundraiserLinkEnabled: false,
      isShareLinkEnabled: false,
      status: CampaignStatus.Draft,
      campaignOrigin: CampaignOriginType.Campaign,
      createdByUserName: this.userName,
      campaignEmail: {
        mjml: JSON.stringify(this.EmailBuilderComponent.template)
      }
    }

    this.subscription.add(
      this.campaignService.addModel(newCampaign).subscribe({
        next: (res: CampaignModel) => {
          this.newCampaignCreated = true;
          this.toastrService.success(this.translate.instant("CAMP.Creating Campaign was successful"));
          this.router.navigate(['clients', 'campaigns', res.id]);
        },
        error: (error) => {
          console.error(error)
          this.toastrService.error(this.translate.instant("CAMP.Creating Campaign was unsuccessful"));
        }
      })
    )
  }

  private getTemplate(): void {
    if (!this.templateId) {
      setTimeout(() => this.usageType === TemplateUsageType.DonationForms && this.FormBuilderComponent.setTemplate(null), 1000);
      return;
    }

    if(this.usageType === TemplateUsageType.Letter) {
      setTimeout(() => this.EmailBuilderComponent.tms.isLetterBuilder = true);
    }

    const template$ = this.templateModelService.getModel(this.templateId, true)
      .pipe(
        tap(this.setExistingTemplate.bind(this))
    );
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: false,
      filters: [
        {
          field: 'templateId',
          value: this.templateId,
          type: FilterType.Equal
        },
        {
          field: 'sourceType',
          value: TemplateSourceType.Client.toString(),
          type: FilterType.Equal
        }
      ],
      sortField: 'createdOn',
      sortOrder: SortOrder.Descending,
      rows: 1,
      selectFields: ['frendlyURL']
    };
    const friendlyUrl$ = this.sourceTemplateService.getModelList(paging)
      .pipe(
        map((sourceTemplateModels: SourceTemplateModel[]) => {
          if (sourceTemplateModels && sourceTemplateModels.length) {
            return sourceTemplateModels[0].frendlyURL;
          } else {
            return null;
          }

        }),
      );
    if (this.isFormBuilder) {
      zip(template$, friendlyUrl$)
        .pipe(
          first(),
          tap(([template, friendlyURL]) => {
            if (template.templateCreationType !== TemplateCreationType.Admin && friendlyURL) {
              this.FormBuilderComponent.setKnownFriendlyUrl(friendlyURL);
            }
          })
        )
        .subscribe();
    } else {
      template$
        .pipe(
          first()
        )
        .subscribe((globalTemplate: TemplateModel) => {
          if(this.canAccess(globalTemplate)) return;
          if ((this.authService.masqueradeAs !== '' ? this.authService.masqueradeAsRecord.clientID !== globalTemplate.clientID : this.authService.getIdentityClaimsOriginId() !== globalTemplate.clientID)
            && !this.isAdminPortal) {
            const config = {
              data: {
                title: 'This template doesn\'t belong to currently logged client',

              }
            };
            this.dialog
              .open(UnauthorizedClientComponent, config)
              .afterClosed()
              .subscribe((response: any) => {
                this.router.navigateByUrl('/clients/library');
              });
          }
        });
    }
  }

  private canAccess(template: TemplateModel): boolean {
    if (!template?.clientID) {
      const isSharedWithClient: boolean = !!template?.templateClientRestrictionList.filter((restriction: TemplateClientRestrictionModel) => restriction.clientID === this.clientID)?.length
      if (isSharedWithClient) {
        return true
      }
      return false;
    }
    return true;
  }

  private setRolesAndUrl(): void {
    this.userID = this.authService.getIdentityClaimsId();
    this.isAdminPortal = this.router.url.includes('/admin-dashboard/');
    this.isClientPortal = this.router.url.includes('/clients/');
    if (this.isAdminPortal) {
      this.userName = 'Admin';
    } else {
      this.subscription.add(
        this.clientModuleService.userName.subscribe((userName: string) => this.userName = userName)
      );
    }

    this.setUrl();
  }

  private setUrl(): void {
    if (this.isAdminPortal) {
      this.url = '/admin-dashboard/library';
    } else if (this.isClientPortal) {
      this.url = '/clients/library';
    }
  }

  private getInitialData(): void {
    this.subscription.add(
      this.lookupStoreService.countries$.subscribe((countries: CountryModel[]) => {
        this.countryStateService.storeCountries(countries);
        this.countryStateService.setDefaultCountry();
      })
    );
  }

  private defineForm(): void {
    if (this.usageType === TemplateUsageType.Event) {
      this.isCampaignForm = false;
      this.isEventForm = true;
    } else if (this.usageType === TemplateUsageType.Campaign) {
      this.isEventForm = false;
      this.isCampaignForm = true;
    }
  }

  public onBackToAllTemplates(): void {
    this.router.navigateByUrl(this.url);
  }

  private captureTemplateView(payload: TemplatePayload, callback: (payload: TemplatePayload, url: string) => void): void {
    this.showInfoMessage('Capturing template view has been started');
    if (this.isEmailBuilder) {
      this.loaderService.startRequest();
      const options: Options = {
        useCORS: true,
        removeContainer: true,
        backgroundColor: null,
        scale: (208 / this.EmailBuilderComponent.templateContainerRef.nativeElement.offsetWidth),
        logging: false,
      };
      html2canvas(this.EmailBuilderComponent.templateContainerRef.nativeElement, options)
        .then(canvas => {
          const dataUrl = canvas.toDataURL('image/png');
          const name = `${Guid.create()}.png`;
          const azure: AzureFileModel = {
            file: dataUrl.substring(22, dataUrl.length),
            name,
            folder: 'images'
          };
          this.loaderService.endRequest();
          this.subscription.add(
            this.uploadService.addModel(azure).subscribe(() => {
              const url = `${this.apiURL}/AzureFileStorage/image/${name}`;
              callback(payload, url);
            })
          );
        })
        .catch(error => {
          this.loaderService.endRequest();
        });
    } else if (this.isImageBuilder) {
      this.ImageBuilderComponent.getTemplatePreviewImage(payload, callback);
    } else if (this.isFormBuilder) {
      this.FormBuilderComponent.getTemplatePreviewImage(payload, callback);
    }
  }

  private saveOriginalTemplate(payload: TemplatePayload, url: string): void {
    const templateModel: TemplateModel = {
      name: payload.name,
      description: payload.description,
      imagePreviewURL: url,
      tagList: payload.tags,
      templateCreationType: this.isClientPortal ? TemplateCreationType.Client : TemplateCreationType.Admin,
      templateUsageType: this.usageType,
      clientID: this.isClientPortal ? this.clientID : null,
      templateStatus: TemplateStatus.Completed,
      createdByUserName: this.userName,
      createdByID: this.userID,
      templateType: payload.templateType,
      isSendDonationEmailDisabled: payload.isSendDonationEmailDisabled,
      customThankYouPageUrl: payload.customThankYouPageUrl
    };
    if (
      (this.isClientPortal && this.globalTemplate && this.globalTemplate.templateCreationType === TemplateCreationType.Client)
      || (this.isAdminPortal && this.globalTemplate && this.globalTemplate.templateCreationType === TemplateCreationType.Admin)
    ) {
      templateModel.parentID = this.globalTemplate.id;
    }
    const message: string = 'Template has been saved successfully';
    if (this.isEmailBuilder) {
      this.subscription.add(
        this.EmailBuilderComponent.html
          .pipe(
            switchMap((convertedHtml: HtmlConvertedModel) => {
              if (!convertedHtml) {
                return of(null);
              } else {
                templateModel.contentHTML = convertedHtml.html;
                templateModel.content = JSON.stringify(this.EmailBuilderComponent.template);
                return this.templateModelService.addModel(templateModel);
              }
            }),
            tap(() => this.EmailBuilderComponent.tms.resetHistoryStorage())
          )
          .subscribe(this.onTemplateModelServiceResponseAdd.bind(this, message))
      );
    } else if (this.isImageBuilder) {
      const template = this.ImageBuilderComponent.template;
      if (!template) return;
      templateModel.content = JSON.stringify(template);
      this.subscription.add(
        this.templateModelService.addModel(templateModel).subscribe(this.onTemplateModelServiceResponseAdd.bind(this, message))
      );
    } else if (this.isFormBuilder) {
      const template = this.FormBuilderComponent.template;
      if (!template) return;
      templateModel.content = JSON.stringify(template);
      UtilsComponent.setFormBuilderTemplate(templateModel, payload);
      const customDisclaimers: EventDonationDisclaimerModel[] = this.getCustomDisclaimersModel(template);
      if(customDisclaimers.length > 0) {
        this.subscription.add(
            this.eventDonationDisclaimerService.addMany(customDisclaimers).pipe(
                switchMap(disclaimers => {
                  template.infoPage.elements.find(element => element.type === FbElementType.DonationInfo).customDisclaimers = disclaimers;
                  templateModel.content = JSON.stringify(template);
                  return this.addTemplateModelObs(templateModel)
                })
            ).subscribe(this.onTemplateModelServiceResponseAdd.bind(this, message))
        )
      } else {
        this.subscription.add(
            this.addTemplateModelObs(templateModel).subscribe(this.onTemplateModelServiceResponseAdd.bind(this, message))
        )
      }



    }
  }

  private addTemplateModelObs(templateModel: TemplateModel): Observable<any> {
   return this.templateModelService.addModel(templateModel)
            .pipe(
                switchMap((savedTemplate) => {
                  if (this.isAdminPortal) {
                    return of(savedTemplate);
                  } else {
                    this.FormBuilderComponent.checkStatusAndUpdateDefaultClientTemplate();
                    this.customDonationFormsService.addModel(savedTemplate);
                    return this.addSourceTemplateModel(savedTemplate)
                        .pipe(
                            map((sourceTemplateModel: SourceTemplateModel) => savedTemplate)
                        );
                  }
                })
            )
  }

  private updateTemplateModelObs(templateModel: TemplateModel): Observable<any> {
    return this.templateModelService.updateModel(templateModel)
        .pipe(
            tap((updatedTemplate) => {
              this.FormBuilderComponent.checkStatusAndUpdateDefaultClientTemplate();
              this.customDonationFormsService.updateModel(updatedTemplate);
            })
        )
  }
//
  private updateTemplate(payload: TemplatePayload, url: string): void {
    const templateModel: TemplateModel = {
      ...this.globalTemplate,
      name: payload.name,
      description: payload.description,
      imagePreviewURL: url,
      tagList: payload.tags,
      templateCreationType: this.isClientPortal ? TemplateCreationType.Client : TemplateCreationType.Admin,
      templateUsageType: this.usageType,
      clientID: this.isClientPortal ? this.clientID : null,
      templateStatus: TemplateStatus.Completed,
      updatedByUserName: this.userName,
      updatedByID: this.userID,
      templateType: payload.templateType,
      isSendDonationEmailDisabled: payload.isSendDonationEmailDisabled,
      customThankYouPageUrl: payload.customThankYouPageUrl
    };
    const message: string = 'Template has been updated successfully';
    if (this.globalTemplate.imagePreviewURL) {
      this.subscription.add(
        this.uploadService.deleteModel(this.globalTemplate.imagePreviewURL.slice(-40)).subscribe()
      );
    }
    if (this.isEmailBuilder) {
      this.subscription.add(
        this.EmailBuilderComponent.html
          .pipe(
            switchMap((convertedHtml: HtmlConvertedModel) => {
              if (!convertedHtml) {
                return of(null);
              } else {
                templateModel.contentHTML = convertedHtml.html;
                templateModel.content = JSON.stringify(this.EmailBuilderComponent.template);
                return this.templateModelService.updateModel(templateModel);
              }
            }),
            tap(() => this.EmailBuilderComponent.tms.resetHistoryStorage())
          )
          .subscribe(this.onTemplateModelServiceResponse.bind(this, message))
      );
    } else if (this.isImageBuilder) {
      const template = this.ImageBuilderComponent.template;
      if (!template) return;
      templateModel.content = JSON.stringify(template);
      this.subscription.add(
        this.templateModelService.updateModel(templateModel).subscribe(this.onTemplateModelServiceResponse.bind(this, message))
      );
    } else if (this.isFormBuilder) {
      const template = this.FormBuilderComponent.template;
      if (!template) return;
      templateModel.content = JSON.stringify(template);
      UtilsComponent.setFormBuilderTemplate(templateModel, payload);
      const customDisclaimers: EventDonationDisclaimerModel[] = this.getCustomDisclaimersModel(template);
      const parsedDisclaimersRefModel = this.disclaimersReferenceModel ? JSON.parse(this.disclaimersReferenceModel) : null;
      const existingUntouchedDisclaimers: EventDonationDisclaimerModel[] = customDisclaimers.filter(dsc => (dsc.hasOwnProperty('id') && dsc.disclaimerText === parsedDisclaimersRefModel.find(d => d.id === dsc.id)?.disclaimerText))
      of(customDisclaimers).pipe(
          switchMap((disclaimers) => {
            let existingModifiedDisclaimers: EventDonationDisclaimerModel[] = disclaimers.filter(dsc => (dsc.hasOwnProperty('id') && dsc.disclaimerText !== parsedDisclaimersRefModel.find(d => d.id === dsc.id)?.disclaimerText));
            existingModifiedDisclaimers = existingModifiedDisclaimers.map(dsc => {
              return {disclaimerText: dsc.disclaimerText}
            })
            const newDisclaimers: EventDonationDisclaimerModel[] = disclaimers.filter(dsc => !dsc.hasOwnProperty('id'));
            const obs$: Observable<any> = disclaimers.length > 0 ? this.eventDonationDisclaimerService.addMany([...newDisclaimers, ...existingModifiedDisclaimers].filter(d => !!d).map((d) =>  {return {disclaimerText: d.disclaimerText}})) : of([]);
            return obs$;
          }),
          switchMap( (res) => {
            template.infoPage.elements.find(element => element.type === FbElementType.DonationInfo).customDisclaimers = [...res, ...existingUntouchedDisclaimers];
            templateModel.content = JSON.stringify(template);
            return this.updateTemplateModelObs(templateModel)
          })
      ).subscribe(this.onTemplateModelServiceResponseAdd.bind(this, message))
    }
  }

  private getCustomDisclaimersModel(template: FbTemplateModel): EventDonationDisclaimerModel[] {
    const customDisclaimers: EventDonationDisclaimerModel[] = template.infoPage.elements.find(element => element.type === FbElementType.DonationInfo).customDisclaimers;
    return  customDisclaimers?.length > 0 ? customDisclaimers.map((d) => { return {disclaimerText: d.disclaimerText}}) : [];
  }

  private onTemplateModelServiceResponseAdd(message: string, model: TemplateModel): void {
    if (model) {
      this.globalTemplate = model;
      this.eventTemplateId = model.id;
      this.toastrService.success(message);
      this.templateId = model.id;
      this.location.replaceState(`${this.url}/${this.templateId}?usageType=${this.usageType}`);
      this.beforeunload.emit(true);
      this.adminTemplatesStateService.enableSaveUpdateButton();
      this.templateIsSaved$.next(true);
    }
  }

  private onTemplateModelServiceResponse(message: string, model: TemplateModel): void {
    if (model) {
      this.globalTemplate = model;
      this.eventTemplateId = model.id;
      this.toastrService.success(message);
      this.templateId = model.id;
      this.beforeunload.emit(true);
      this.adminTemplatesStateService.enableSaveUpdateButton();
      this.templateIsSaved$.next(true);
    }
  }

  private setExistingTemplate(template: TemplateModel): void {
    this.setGlobalTemplate(template);
    if (this.isEmailBuilder) {
      this.EmailBuilderComponent.setTemplate(template.content ? JSON.parse(template.content) : null);
      setTimeout(() => this.updateCompareWithModel(this.EmailBuilderComponent.template), 1000);
    } else if (this.isImageBuilder) {
      this.ImageBuilderComponent.setTemplate(template.content ? JSON.parse(template.content) : null);
      setTimeout(() => this.updateCompareWithModel(this.ImageBuilderComponent.template), 1000);
    } else if (this.isFormBuilder) {
      if (template.templateCreationType !== TemplateCreationType.Admin) {
        this.templateIsSaved$.next(true);
      }
      this.FormBuilderComponent.setTemplate(template.content ? JSON.parse(template.content) : null);
      setTimeout(() => this.updateCompareWithModel(this.FormBuilderComponent.template), 1000);
      this.disclaimersReferenceModel = JSON.stringify(this.FormBuilderComponent.template.infoPage.elements.find(element => element.type === FbElementType.DonationInfo).customDisclaimers)
    }
    if (this.templateId) {
      this.currentTab = 0;
    }
  }

  private setGlobalTemplate(template: TemplateModel): void {
    if (template) {
      this.globalTemplate = template;
      const templatePayload: TemplatePayload = {
        tags: template.tagList,
        name: template.name,
        description: template.description,
        templateType: template.templateType,
        isSendDonationEmailDisabled:template.isSendDonationEmailDisabled
      };
      if (this.isEmailBuilder) {
        this.EmailBuilderComponent.setTemplatePayload(templatePayload);
        this.EmailBuilderComponent.setTemplateType(template.templateType);
      } else if (this.isImageBuilder) {
        this.ImageBuilderComponent.setTemplatePayload(templatePayload);
        this.ImageBuilderComponent.setTemplateType(template.templateType);
      } else if (this.isFormBuilder) {
        UtilsComponent.setFormBuilderPayload(templatePayload, template);
        this.FormBuilderComponent.setTemplatePayload(templatePayload);
      }
    }
  }

  private updateCompareWithModel(model: any): void {
    this.compareWithTemplateModel = JSON.stringify(model);
  }

  public ngAfterViewInit(): void {
    if (this.isEmailBuilder) {
      if(!this.templateId && this.usageType === TemplateUsageType.Letter) {
        this.EmailBuilderComponent.setTemplate(DEFAULT_LETTER_CAMPAIGN_MJML_TEMPLATE);
        this.EmailBuilderComponent.tms.isLetterBuilder = true;
      }
      this.setPlaceholders();
      this.subscription.add(
        this.EmailBuilderComponent.updateTemplate.subscribe(this.onPayloadReceived.bind(this))
      );
      !this.templateId && setTimeout(() => {
        this.updateCompareWithModel(this.EmailBuilderComponent.template);
        this.EmailBuilderComponent.tms.resetHistoryStorage();
      }, 1000);
    } else if (this.isImageBuilder) {
      this.subscription.add(
        this.ImageBuilderComponent.updateTemplate.subscribe(this.onPayloadReceived.bind(this))
      );
      !this.templateId && setTimeout(() => this.updateCompareWithModel(this.ImageBuilderComponent.template), 1000);
    } else if (this.isFormBuilder) {
      this.subscription.add(
        this.FormBuilderComponent.updateTemplate.subscribe(this.onPayloadReceived.bind(this))
      );
      this.subscription.add(
        this.FormBuilderComponent.addNewSourceTemplateModel
          .pipe(
            switchMap((nextUrl: string) => this.addSourceTemplateModel(this.globalTemplate))
          )
          .subscribe()
      );
      this.subscription.add(
        this.FormBuilderComponent.updateDefaultClientTemplate
          .pipe(
            switchMap((setCurrentTemplateAsDefault: boolean) => this.updateDefaultClientTemplate(setCurrentTemplateAsDefault))
          )
          .subscribe()
      );
      if (!this.templateId) {
        this.FormBuilderComponent.setDefaultTemplateName();
        setTimeout(() => this.updateCompareWithModel(this.FormBuilderComponent.template), 3000);
      }
      this.subscription.add(
        this.setFormBuilderData().subscribe()
      );
    }
  }

  private onPayloadReceived(payload: TemplatePayload): void {
    if (payload) {
      if ((this.isClientPortal && this.templateId && this.globalTemplate.templateCreationType === TemplateCreationType.Admin) || !this.templateId) {
        this.checkUniqueTemplateName(
          payload.name,
          () => {
            this.adminTemplatesStateService.disableSaveUpdateButton();
            this.captureTemplateView(payload, this.saveOriginalTemplate.bind(this));
          });
      } else if ((this.isAdminPortal && this.templateId) || (this.isClientPortal && this.templateId && this.globalTemplate.templateCreationType === TemplateCreationType.Client)) {
        const config = {
          data: {
            title: `${this.translate.instant('Would you like to save template as New or update')} ${this.globalTemplate.name}?`,
            firstButtonName: 'SAVE',
            secondButtonName: 'UPDATE',
          }
        };
        this.dialog.open(BeforeUnloadComponent, config).afterClosed()
          .pipe(
            first(),
            tap((response: any) => {
              switch (response) {
                case DialogBeforeunloadResponseType.Accept:
                  this.checkUniqueTemplateName(
                    payload.name,
                    () => {
                      this.adminTemplatesStateService.disableSaveUpdateButton();
                      this.newSourceInstance = true;
                      this.captureTemplateView(payload, this.saveOriginalTemplate.bind(this));
                    });
                  break;
                case DialogBeforeunloadResponseType.Reject:
                  this.checkUniqueTemplateName(
                    payload.name,
                    () => {
                      this.adminTemplatesStateService.disableSaveUpdateButton();
                      this.captureTemplateView(payload, this.updateTemplate.bind(this));
                    },
                    this.globalTemplate.name);
                  break;
                case DialogBeforeunloadResponseType.Close:
                case undefined:
                  return;
              }
            })
          )
          .subscribe()
      } else {
        this.checkUniqueTemplateName(
          payload.name,
          () => {
            this.adminTemplatesStateService.disableSaveUpdateButton();
            this.captureTemplateView(payload, this.updateTemplate.bind(this));
          },
          this.globalTemplate.name);
      }
    }
  }

  public ngOnDestroy(): void {
    this.adminTemplatesStateService.enableSaveUpdateButton();
    this.subscription.unsubscribe();
  }

  private setPlaceholders(): void {
    if (this.isClientPortal) {
      this.subscription.add(
        this.clientService.getModel(this.clientID, true).subscribe(({clientPlaceholders}: ClientModel) => {
          this.EmailBuilderComponent.setAvailableClientPlaceholdersIds(clientPlaceholders.map(({placeholderId}: ClientPlaceholderModel) => placeholderId));
        })
      );
    } else {
      setTimeout(() => this.EmailBuilderComponent.setAvailableClientPlaceholdersIds(KNOWN_PLACEHOLDERS));
    }
  }

  public saveProcess(isFromDeactivate?: boolean): void {
    const activeElementState = this.EmailBuilderComponent?.activeElementState;
    if(activeElementState && !activeElementState?.isValid) {
      this.EmailBuilderComponent.showStylingErrorFields();
    } else {
      if(isFromDeactivate && this.isLetterTemplatePage) {
        this.EmailBuilderComponent.triggerTemplatePayload();
        return
      }
       this.saveSource.next();
    }
  }

  public canDeactivate(): Observable<boolean> | boolean {
    if (!this.compareWithTemplateModel || this.newCampaignCreated) {
      return true;
    }
    let currentTemplateState = '';
    if (this.isEmailBuilder) {
      currentTemplateState = JSON.stringify(this.EmailBuilderComponent.template);
    } else if (this.isImageBuilder) {
      currentTemplateState = JSON.stringify(this.ImageBuilderComponent.template);
    } else if (this.isFormBuilder) {
      currentTemplateState = JSON.stringify(this.FormBuilderComponent.template);
    }
    let isTemplateHasChanges = false;
    if (this.globalTemplate) {
      isTemplateHasChanges = this.globalTemplate.content !== currentTemplateState;
      if (this.isEmailBuilder) {
        isTemplateHasChanges = !!(this.EmailBuilderComponent.tms.historyStorageLength > 1);
      }
      if (this.isFormBuilder) {
        isTemplateHasChanges = this.checkFormBuilder();
      }
    } else {
      isTemplateHasChanges = currentTemplateState !== this.compareWithTemplateModel;
    }

    if (!isTemplateHasChanges) {
      return true;
    } else {
      const config = {
        data: {
          title: 'You have unsaved changes. Would you like to save them?'
        }
      };
      this.dialog.open(BeforeUnloadComponent, config).afterClosed()
        .pipe(
          first()
        )
        .subscribe((response: any) => {
        switch (response) {
          case DialogBeforeunloadResponseType.Accept:
            this.saveProcess(true);
            break;
          case DialogBeforeunloadResponseType.Reject:
            this.beforeunload.emit(true);
            break;
          case DialogBeforeunloadResponseType.Close:
          case undefined:
            this.beforeunload.emit(false);
        }
      });
      return this.beforeunload;
    }
  }

  private checkFormBuilder(): boolean {
    const currentTemplateState = JSON.stringify(this.FormBuilderComponent.template);
    const currentSettings = this.FormBuilderComponent.fbss.templateSettingsForm.getRawValue() as TemplateModel;
    return this.globalTemplate.content !== currentTemplateState
      || this.isDateCompare(currentSettings)
      || this.isTimeCompare(currentSettings)
      || this.globalTemplate.disableTimeZone !== currentSettings.disableTimeZone
      || this.globalTemplate.emailNotification !== currentSettings.emailNotification
      || this.globalTemplate.emailToDonor !== currentSettings.emailToDonor
      || this.globalTemplate.fbTemplateName !== currentSettings.fbTemplateName
      || this.globalTemplate.fbTemplateStatus !== currentSettings.fbTemplateStatus
      || this.globalTemplate.messageWhenDisabled !== currentSettings.messageWhenDisabled
      || this.globalTemplate.notifyEmails !== currentSettings.notifyEmails
      || this.globalTemplate.subjectEmailNotification !== currentSettings.subjectEmailNotification
      || this.globalTemplate.subjectEmailToDonor !== currentSettings.subjectEmailToDonor
      || this.globalTemplate.isSendDonationEmailDisabled !== currentSettings.isSendDonationEmailDisabled;
  }

  private isDateCompare(currentSettings: TemplateModel): boolean {
    if (this.globalTemplate.disableDate) {
      return new Date(this.globalTemplate.disableDate).toString() !== currentSettings.disableDate.toString()
    }
    return false;
  }

  private isTimeCompare(currentSettings: TemplateModel): boolean {
    if (this.globalTemplate.disableTime) {
      return this.globalTemplate.disableTime !== currentSettings.disableTime;
    }
    return false;
  }

  private checkUniqueTemplateName(name: string, callback: () => void, currentName?: string): void {

    const filters: Filter[] = [
      {
        field: 'templateStatus',
        value: TemplateStatus.Completed.toString(),
        type: FilterType.Equal
      },
      {
        field: 'templateUsageType',
        value: this.usageType.toString(),
        type: FilterType.Equal
      },
      {
        field: 'name',
        value: name,
        type: FilterType.Equal
      },
    ];

    if (currentName)
      filters.push(
        {
          field: 'name',
          value: currentName,
          type: FilterType.NotEqual
        },
      );

    if (this.clientIdStateService.selectedRelationshipClientId) {
      filters.push({
        field: 'clientID',
        value: this.clientIdStateService.selectedRelationshipClientId,
        type: FilterType.Equal
      });
    } else {
      filters.push({
        field: 'templateCreationType',
        value: TemplateCreationType.Admin.toString(),
        type: FilterType.Equal
      })
    }
    const paging: Paging = {
      includeDeleted: false,
      includeDependencies: false,
      filters
    };

    this.subscription.add(
      this.templateModelService.getTotal(paging).subscribe((total: number) => {
        if (total && total > 0) {
          this.showErrorMessage(`Template name '${name}' already exists. Please, change the template name`);
          if (this.isEmailBuilder) {
            this.EmailBuilderComponent.showErrorFields();
          } else if (this.isImageBuilder) {
            this.ImageBuilderComponent.showErrorFields();
          } else {
            this.FormBuilderComponent.showErrorFields();
          }
          this.adminTemplatesStateService.enableSaveUpdateButton();
        } else {
          callback();
        }
      })
    );
  }

  private showErrorMessage(message: string): void {
    this.toastrService.error(message, 'Error');
  }

  private showInfoMessage(message: string): void {
    this.toastrService.info(message, 'Info');
  }

  public get isTemplateChanged(): boolean {
    if (this.FormBuilderComponent) {
      return !this.FormBuilderComponent.templateWithChanges;
    }
  }

  public get isButtonDisabled(): boolean {
    return this.adminTemplatesStateService.isSaveUpdateButtonDisabled;

  }

  public get organizationName(): string {
    const org = this.clientsWithRelationships.find(({value}: FormElementDataModel) => value === this.clientID);
    return org ? org.label : this.clientName;
  }

  public get isEmailBuilder(): boolean {
    return this.usageType === TemplateUsageType.Event || this.usageType === TemplateUsageType.Campaign || this.usageType === TemplateUsageType.Letter;
  }

  public get isImageBuilder(): boolean {
    return this.usageType === TemplateUsageType.SocialMedia;
  }

  public get isFormBuilder(): boolean {
    return this.usageType === TemplateUsageType.DonationForms;
  }

  private setFormBuilderData(): Observable<ClientModel> {
    return of(null)
      .pipe(
        switchMap(() => {
          if (this.isClientPortal && this.clientID === this.authService.getIdentityClaimsId()) {
            return this.clientModuleService.client;
          } else if (this.isClientPortal && this.clientID !== this.authService.getIdentityClaimsId()) {
            return this.clientService.getModel(this.clientID, true);
          } else {
            this.mockClient.id = this.mockId;
            this.mockClient.name = 'Auxilia';
            this.mockClient.paymentServiceType = PaymentServiceType.Cornerstone;
            this.mockClient.clientType = ClientType.PAC;
            return of(this.mockClient);
          }
        }),
        tap((client: ClientModel) => {
          client.paymentFeePercent *= 100;
          client.applicationPaymentFeePercent *= 100;
          this.clientModel = client;
          const entity: ClientPaymentModel = {
            clientModel: client,
            clientID: client.id,
            name: client.name,
            hasAppeal: true,
            feeAndTaxes: FeeAndTaxes.ProvideOption,
            id: client.id,
          };
          this.FormBuilderComponent.setClient(client);
          this.FormBuilderComponent.setEntity(entity);
          this.FormBuilderComponent.setPaymentServiceType(client);
          if (this.templateId) {
            this.FormBuilderComponent.setIsTemplateDefaultForClient(this.templateId === client.defaultTemplateId)
          }
        })
      );
  }

  private addSourceTemplateModel(
    {
      clientID,
      name,
      templateUsageType,
      fbTemplateStatus,
      content,
      disableDate,
      disableTime,
      disableTimeZone,
      messageWhenDisabled,
      id,
      notifyEmails,
      emailNotification,
      subjectEmailNotification,
      emailToDonor,
      subjectEmailToDonor,
      isSendDonationEmailDisabled,
      customThankYouPageUrl
    }: TemplateModel): Observable<SourceTemplateModel> {
    const model: SourceTemplateModel = {
      clientID,
      name,
      templateUsageType,
      templateStatus: fbTemplateStatus,
      sourceID: clientID,
      content,
      disableDate,
      disableTime,
      disableTimeZone,
      messageWhenDisabled,
      frendlyURL: this.newSourceInstance ? Guid.create().toString() : this.FormBuilderComponent.actualFriendlyUrl,
      templateId: id,
      sourceType: TemplateSourceType.Client,
      notifyEmails,
      emailNotification,
      subjectEmailNotification,
      emailToDonor,
      subjectEmailToDonor,
      isSendDonationEmailDisabled,
      customThankYouPageUrl
    };
    model.disableDate = model.disableDate === undefined ? null : model.disableDate;
    model.disableTime = model.disableTime === undefined ? null : model.disableTime;
    return this.sourceTemplateService.addModel(model)
      .pipe(
        tap((newSourceTemplateModel: SourceTemplateModel) => {
          this.newSourceInstance = false;
          this.FormBuilderComponent.setKnownFriendlyUrl(newSourceTemplateModel.frendlyURL);
        })
      );
  }

  private updateDefaultClientTemplate(setCurrentTemplateAsDefault: boolean): Observable<ClientModel> {
    const nextClientModel: ClientModel = {
      ...this.clientModel,
      defaultTemplateId: setCurrentTemplateAsDefault && this.globalTemplate ? this.globalTemplate.id : null
    };
    return this.clientService.updateModel(nextClientModel)
      .pipe(
        tap(() => this.clientModuleService.updateClientData())
      );
  }

  public get updateInitialFormActive(): boolean {
    return (
      this.usageType === TemplateUsageType.DonationForms
      && !!this.FormBuilderComponent
      && (!!(this.globalTemplate && this.globalTemplate.parentID)/* || !!this.clientModel.defaultTemplateId*/)
    );
  }

  public updateInitialForm(): void {
    const fbTemplate = this.FormBuilderComponent.template;
    if (!this.FormBuilderComponent.isReadyForSave) {
      return;
    }
    if (this.FormBuilderComponent.fbss.isTiersEmptyTier(fbTemplate)) {
      this.toastrService.error('Please fill all mandatory fields');
      return
    }
    if (this.FormBuilderComponent.fbss.isTierHasImg(fbTemplate)) {
      const configTier = {
        data: {
          img: '/assets/images/icon-donor-heart.png',
          title: `An image was not uploaded for all the tiers you have entered. Would you like to upload the default picture?`
        }
      };
      this.subscription.add(
        this.dialog.open(AdminConfirmationComponent, configTier).afterClosed()
          .subscribe((res: boolean) => {
            if (res) {
              this.updateTemplateInit();
            }
          })
      );
      return;
    }
  this.updateTemplateInit();
  }

  private updateTemplateInit(): void {
    const config = {
      data: {
        title: 'Would you like to update the Initial Form?'
      }
    };
    this.dialog.open(BeforeUnloadComponent, config).afterClosed()
      .pipe(
        first(),
        switchMap((response) => {
          switch (response) {
            case DialogBeforeunloadResponseType.Accept:
              this.adminTemplatesStateService.disableSaveUpdateButton();
              return this.templateModelService.getModel(this.globalTemplate.parentID, false);
            case DialogBeforeunloadResponseType.Reject:
            case DialogBeforeunloadResponseType.Close:
            case undefined:
              return of(null);
          }
        }),
        filter(value => !!value),
        switchMap((template: TemplateModel) => this.FormBuilderComponent.templateImage()
          .pipe(
            switchMap((url: string) => {
              return this.templateModelService.updateModel({...template, ...this.FormBuilderComponent.templateWithChanges, imagePreviewURL: url})
                .pipe(
                  switchMap((newTemplate: TemplateModel) => this.uploadService.deleteModel(template.imagePreviewURL.slice(-40))
                    .pipe(
                      map(() => newTemplate)
                    )
                  )
                );
            }),
          )
        ),
        tap((template: TemplateModel) => {
          this.customDonationFormsService.updateModel(template);
          this.adminTemplatesStateService.enableSaveUpdateButton();
          this.FormBuilderComponent.resetLastTemplateState();
          this.showInfoMessage(`Template "${template.name}" has been updated successfully`);
        })
      )
      .subscribe();
  }
}
