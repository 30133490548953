<div class="campaign-list-table-container">
  <table mat-table [dataSource]="tableSource" *ngIf="tableSource">
    <ng-container [matColumnDef]="column" *ngFor="let column of tableDataColumns">
      <th mat-header-cell *matHeaderCellDef class="table-header">
        <div class="table-header-title-wrapper">
          <span class="title">{{getColumnTitle(column) | translate}}</span>
          <span *ngIf="withFilter(column)" class="column-filter">
            <app-multiselect
              [columnFilterOptions]="getSelectOptions(column)"
              (selectedOptions)="selectedOptions($event)"
              [initialSelection]="initialSelection"
              [enableTranslation]="true"
            >
              <i class="fas fa-filter action-icon" [class.active-filter]="isActiveFilter(column)"></i>
            </app-multiselect>
          </span>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="widget-table-cell" [appTooltip]="tooltip">
        <div matTooltip="{{element[column]}}" #tooltip="matTooltip" matTooltipPosition="above" matTooltipClass="table-tooltip"></div>
        <span *ngIf="column !== 'name' && column !== 'type'">{{getTextNode(element, column)}}</span>

        <span
          *ngIf="column === 'name'"
          [class.edit-link]="!draftsData"
          (click)="toCampaignDashboard(element)"
        >{{element[column]}}</span>

        <span *ngIf="column === 'type'">{{getTextNode(element, column) | translate}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="settings">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button class="settings-container" mat-icon-button [matMenuTriggerFor]="settingsMenu" *ngIf="isAllPermissionAvailable" [disabled]="readOnly">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #settingsMenu overlapTrigger xPosition="before">
          <div>
            <button mat-menu-item (click)="onEditLinkClick(element)">{{getEditSeeDetailTextNode(element) | translate}}</button>
            <button *ngIf="isCancelArchiveAvailable(element)" (click)="onCancelArchiveCampaign(element)"mat-menu-item>
              {{getCancelArchiveTextNode(element) | translate}}
            </button>
            <button *ngIf="isLetterCampaign(element) && !draftsData" mat-menu-item [matMenuTriggerFor]="leterCampaignStatusMenu">
              {{"Update Status" | translate}}
            </button>
            <mat-menu #leterCampaignStatusMenu>
              <button *ngFor="let option of availableLetterStatuses(element)" mat-menu-item (click)="onChangeStatusClick(element, option)">
                {{option.label | translate}}
              </button>
            </mat-menu>
          </div>
        </mat-menu>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>

  </table>

</div>
