import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input, OnChanges,
  OnInit, SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { DonationsComponent } from '../routes/clients/widgets/donations/donations.component';
import { YtdDonorRetentionComponent } from '../routes/clients/widgets/ytd-donor-retention/ytd-donor-retention.component';
import { ActiveCampaignsComponent } from '../routes/clients/widgets/active-campaigns/active-campaigns.component';
import { UpcomingEventsComponent } from '../routes/clients/widgets/upcoming-events/upcoming-events.component';
import { RecentDonationsComponent } from '../routes/clients/widgets/recent-donations/recent-donations.component';
import { EventsSummaryComponent } from '../routes/clients/widgets/events-summary/events-summary.component';
//import { ActionPlanLinksComponent } from '../routes/clients/widgets/action-plan-links/action-plan-links.component';
import { DonorRetentionRateComponent } from '../routes/clients/widgets/donor-retention-rate/donor-retention-rate.component';
import { TopDonationsComponent } from '../routes/clients/widgets/top-donations/top-donations.component';

const WIDGETS = {
  DonationsComponent: DonationsComponent,
  YtdDonorRetentionComponent: YtdDonorRetentionComponent,
  ActiveCampaignsComponent: ActiveCampaignsComponent,
  UpcomingEventsComponent: UpcomingEventsComponent,
  RecentDonationsComponent: RecentDonationsComponent,
  EventsSummaryComponent: EventsSummaryComponent,
  //ActionPlanLinksComponent: ActionPlanLinksComponent,
  DonorRetentionRateComponent: DonorRetentionRateComponent,
  TopDonationsComponent: TopDonationsComponent
};

@Directive({
  selector: '[appWidgetItem]'
})
export class WidgetItemDirective implements OnInit, OnChanges {
  @Input()
  public appWidgetItem: string;

  private component: ComponentRef<any>;

  constructor(
    private container: ViewContainerRef,
    private resolver: ComponentFactoryResolver
  ) { }

  public ngOnInit(): void {
    const component = WIDGETS[this.appWidgetItem];
    if (component) {
      this.container.clear();
      const factory = this.resolver.resolveComponentFactory<any>(component);
      this.component = this.container.createComponent(factory);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.firstChange && !changes.previousValue) {
      return;
    }
    const component = WIDGETS[this.appWidgetItem];
    if (component) {
      this.container.clear();
      const factory = this.resolver.resolveComponentFactory<any>(component);
      this.component = this.container.createComponent(factory);
    }
  }

}
