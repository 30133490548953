import { GoogleAuthCalendarConfig } from 'src/app/models/externalServices/googleAuth.config';
import { GoogleAuthService } from './google-auth.service';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import EventModel from 'src/app/models/event/event.model';
import { TimeZoneModel } from 'src/app/models/time-zone.model';

@Injectable({
  providedIn: 'root'
})
export class GoogleEventService {
  constructor(private googleAuthService: GoogleAuthService, private toastrService: ToastrService) { }

  public async getCalendarEvents(): Promise<void> {
    const gapi = this.googleAuthService.getGoogleApiClient();
    const events = await gapi.client.calendar.events.list({
      calendarId: 'primary',
      timeMin: new Date().toISOString(),
      showDeleted: false,
      singleEvents: true,
      maxResults: 10,
      orderBy: 'startTime'
    })
  }

    public async insertEvent(event: EventModel, timeZone: TimeZoneModel) {
    if (this.googleAuthService.isSignedIn()) {
     // this.googleAuthService.signIn(GoogleAuthCalendarConfig, async () => {
      await this.addToCalendar(event, timeZone);
     // });
    } else {
      this.googleAuthService.initClient(GoogleAuthCalendarConfig, async () => {
        await this.googleAuthService.login();
        await this.addToCalendar(event, timeZone);
      });
    }
  }

  private async addToCalendar(event: EventModel, timeZone: TimeZoneModel): Promise<void> {
    const gapi = this.googleAuthService.getGoogleApiClient();
    let eventUrl = event.friendlyURL ? window.location.origin + '/Event/' + event.friendlyURL : ''
    let event_address1 = event.address1 ? event.address1 : ''
    let event_address2 = event.address2 ? event.address2 : ''
    let event_city = event.city ? event.city : ''
    let event_stateName = event.stateName ? event.stateName : ''
    let event_zip = event.zip ? event.zip : ''
    let event_countryName = event.countryName ? event.countryName : ''

    await gapi.client.calendar.events.insert({
      calendarId: 'primary',
      start: {
        dateTime: this.getStartDate(event),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      end: {
        dateTime: this.getEndDate(event),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      summary: event.name,
      description: event.description + ' ' + eventUrl,
      location: event.hasLocation ? event_address1 + ', ' + event_address2 + ', ' + event_city + ', ' + event_stateName  + ' ' + event_zip + ', ' + event_countryName  : ''
    });
    this.toastrService.success('Event successfully added', 'Google Calendar');
    }

  private getStartDate(event: any): string {
    if (event.eventSchedularList && event.eventSchedularList.length > 0) {
      return event.eventSchedularList[0].startDate.slice(0, 11) + this.get24TimeFormat(event.startTime)
    } else {
      const date = new Date(event.startDate);
      date.setDate( date.getDate() + 1);
      return new Date(date).toISOString().slice(0, 11) + this.get24TimeFormat(event.startTime)
    }
  }
  private getEndDate(event: any): string {
    if (event.eventSchedularList && event.eventSchedularList.length > 0) {
      return event.eventSchedularList[0].endDate.slice(0, 11) + this.get24TimeFormat(event.endTime)
    } else {
      const date = new Date(event.endDate);
      date.setDate(date.getDate() + (event.endTime ? 1 : 2));
      return new Date(date).toISOString().slice(0, 11) + this.get24TimeFormat(event.endTime ? event.endTime : '12:00 PM')
    }
  }
  private get24TimeFormat(timeInitial: string): string {
    const [time, modifier] = timeInitial.split(' ');
    // eslint-disable-next-line prefer-const
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
     hours = '00';
    }
    if (modifier === 'PM' || modifier === 'pm') {
    hours = (parseInt(hours, 10) + 12).toString();
    }
    return `${hours}:${minutes}:00`;
  }
}
