import { Injectable, Injector } from '@angular/core';
import { BaseModuleGuard } from './base.module.guard';
import { AUXILIA_ADMIN, CLIENT_ADMIN, CONTENT_CREATOR, DONOR_ADMIN, DONOR_MANAGER, FUNDRAISING_MANAGER, SUPER_ADMIN } from '../constants';
import { PortalUrls } from '../models/enum/portal.urls';
import { ClientModuleService } from '../routes/clients/client.module.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends BaseModuleGuard {
  constructor(
    protected injector: Injector,
    private clientModuleService: ClientModuleService,
    ) {
    super(injector);
  }

  public canLoad(): boolean {
    const token = this.oauthService.hasValidAccessToken();
    if (token) {
      const roles = this.authService.getIdentityClaimsRole();
      let redirectURL;
      const admin = roles?.includes(AUXILIA_ADMIN) || roles?.includes(SUPER_ADMIN);
      const client = (roles?.includes(CLIENT_ADMIN) || roles?.includes(FUNDRAISING_MANAGER) || roles?.includes(CONTENT_CREATOR) || roles?.includes(DONOR_MANAGER)) && !roles?.includes(DONOR_ADMIN);
      const clientAndDonor = (roles?.includes(CLIENT_ADMIN) || roles?.includes(FUNDRAISING_MANAGER) || roles?.includes(CONTENT_CREATOR) || roles?.includes(DONOR_MANAGER)) && roles?.includes(DONOR_ADMIN);
      if (admin) {
        redirectURL = PortalUrls.AdminPortal;
        this.clientModuleService.clearStorage();
      } else if (client) {
        redirectURL = PortalUrls.ClientPortal;
      } else if (clientAndDonor) {
        redirectURL = localStorage.getItem('currentRouteChoice') ===  PortalUrls.DonorPortal.substring(1) ? PortalUrls.DonorPortal : PortalUrls.ClientPortal;
      } else {
        redirectURL = PortalUrls.DonorPortal;
      }
      this.router.navigate([redirectURL]);
    }
    return !token;
  }
}
