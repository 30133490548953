<div *ngIf="low; else baseInput" class="password-input-wrapper">
  <mat-form-field appearance="outline" [formGroup]="formGroup">
    <mat-label>{{label | translate}}</mat-label>
    <input [formControl]="formControl" matInput [type]="type" (keydown.enter)="onKeydownEnter()" (blur)="onBlur()">
    <mat-error [ngClass]="{marginTop: errorMargin}" *ngIf="formControl.invalid && !customError">{{getErrorMessage()}}</mat-error>
  </mat-form-field>
  <div *ngIf="showAsterisks" class="show-asterisks">
    ************
  </div>
  <div *ngIf="showIcon" class="show-password" (click)="toggleType()">
    <i *ngIf="type === 'password'; else hidePassword" class="far fa-eye"></i>
    <ng-template #hidePassword>
      <i class="far fa-eye-slash"></i>
    </ng-template>
  </div>
</div>

<ng-template #baseInput>
  <div [ngClass]="normalHeight ? 'custom-form-component-container-wrapper' : 'low-custom-form-component-container-wrapper'" [formGroup]="formGroup">
    <label class="form-field-name" [for]="id">{{label | translate}}</label>
    <mat-form-field appearance="outline">
      <input
        matInput
        [formControl]="formControl"
        [id]="id"
        [type]="type"
        (keydown.enter)="onKeydownEnter()"
        (blur)="onBlur()"
        [readonly]="inputReadOnly"
      >
      <mat-error [ngClass]="{marginTop: errorMargin}" *ngIf="formControl.invalid && !customError">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <div class="show-password" (click)="toggleType()">
      <i *ngIf="type === 'password'; else hidePassword" class="far fa-eye"></i>
      <ng-template #hidePassword>
        <i class="far fa-eye-slash"></i>
      </ng-template>
    </div>
  </div>
</ng-template>
