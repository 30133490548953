import { AuthService } from 'src/app/services/auth.service';
import { BasePagingComponent } from '../../../components/paginator/base.paging.component';
import { ClientModel } from 'src/app/models/client/client.model';
import { ClientModuleService } from '../../../routes/clients/client.module.service';
import { ClientService } from 'src/app/services/client/client.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExportReportsService } from 'src/app/services/export/export-reports.service';
import { FilterType } from 'src/app/models/enum/filter.type';
import { FormBuilder, FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { InfoLogModel } from 'src/app/models/reports/info.log.model';
import { InfoLogService } from 'src/app/services/reports/info-log.service';
import { map, tap } from 'rxjs/operators';
import { Observable, of, Subscription, zip } from 'rxjs';
import { PagingModel } from 'src/app/models/paging/paging.model';
import { Router } from '@angular/router';
import { SortOrder } from 'src/app/models/enum/sort.order';
import { UserActivityType } from 'src/app/models/enum/user.activity.type';
import { UserActivityValues } from 'src/app/constants';
import { UserRolesType } from 'src/app/models/enum/user.roles.type';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import { ClientIdStateService } from '../../../services/client.module.state/client.id.state.service';
import { ToastrService } from 'ngx-toastr';

enum FilterAction {
  Initial = 0,
  StartFrom = 1,
  DateTo = 2,
  Organization = 3,
  ActivityType = 4,
  UserRole = 5
}

@Component({
  selector: 'app-audit-reports-features',
  templateUrl: './audit-reports-features.component.html',
  styleUrls: ['../../../routes/clients/reports/components/reports-common.scss']
})
export class AuditReportsFeaturesComponent extends BasePagingComponent implements OnInit, OnDestroy {
  public pageTitle: string = 'Feature Use';
  public backNavigateLabel: string = '< Back to All Reports';

  public multiActivityOptions: FormElementDataModel[] = UserActivityValues;
  private sortFieldLogs: string = 'loggedOnDate';
  private modelFilter = new PagingModel();
  public organizations$: Observable<ClientModel[]>;
  public infoLogs$: Observable<InfoLogModel[]>;
  public total$: Observable<number>;
  public userRoleOptions: FormElementDataModel[] = [
    { label: 'All', value: 'ALL' },
    { label: 'Client Admin', value: UserRolesType.CLIENT_ADMIN },
    { label: 'Content Creator', value: UserRolesType.CONTENT_CREATOR },
    { label: 'Donor Admin', value: UserRolesType.DONOR_ADMIN },
    { label: 'Fundraising Manager', value: UserRolesType.FUNDRAISING_MANAGER },
    { label: 'Super Admin', value: UserRolesType.SUPER_ADMIN }
  ];

  public tableData: InfoLogModel[];
  public tableColumns: string[];
  public isSuperAdminOrAuxiliaAdmin: boolean;
  private arrClientsIdsStr: string;
  private arrActivityValueStr: string;
  private arrActivityValue: string[] = ['All'];
  private startFromStr: string;
  private dateToStr: string;
  public arrUserRoleValue: string[] = ['All'];
  public arrUserRoleValueStr: string;
  private subscription: Subscription = new Subscription();
  public clientsWithRelationships: FormElementDataModel[] = [];
  public isAdmin: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private exportReportsService: ExportReportsService,
    private clientService: ClientService,
    private infoLogService: InfoLogService,
    private authService: AuthService,
    private router: Router,
    private clientModuleService: ClientModuleService,
    private clientIdStateService: ClientIdStateService, private toastr: ToastrService
  ) {
    super();
  }

  public filterForm: FormGroup = this.formBuilder.group({
    activity: [[UserActivityType.All]],
    startDate: null,
    endDate: null,
    organizationName: null,
    userRole: [['ALL']]
  }, {
    validators: [this.startDateValidator.bind(this)]
  });

  public ngOnInit(): void {
    this.isAdmin = this.router.url.includes('/admin-dashboard/');
    this.isSuperAdminOrAuxiliaAdmin =
      this.getRoles().includes(UserRolesType.SUPER_ADMIN) ||
      this.getRoles().includes(UserRolesType.AUXILIA_ADMIN);

    this.modelFilter.includeDependencies = false;
    this.modelFilter.sortOrder = SortOrder.Descending;
    this.isSuperAdminOrAuxiliaAdmin && this.getOrganizations();
    this.modelFilter.selectFields = ['LoggedOnDate', 'UserName', 'Message', 'Level', 'ClientId', 'CustomInfo', 'UserRole'];


    if (this.isSuperAdminOrAuxiliaAdmin) {
      this.tableColumns = this.getTableColumns();
      this.getInfoLogs();
      this.getTotal();
      return;
    }

    this.subscription.add(
      zip(this.clientModuleService.clientsWithRelationships, this.clientModuleService.clientName)
        .pipe(map(([clientsWithRelationships, clientName]) => ({ clientsWithRelationships, clientName })))
        .subscribe(({ clientsWithRelationships, clientName }) => {
          this.clientsWithRelationships = [{ label: clientName, value: this.authService.getIdentityClaimsOriginId() }, ...clientsWithRelationships];
          this.tableColumns = this.getTableColumns();
          this.getInfoLogs();
          this.getTotal();
        })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getRoles(): string[] {
    return this.authService.getIdentityClaimsRole();
  }

  private getOrganizations(): void {
    this.modelFilter.rows = null;
    this.modelFilter.first = null;
    this.modelFilter.sortField = null;
    this.organizations$ = this.clientService.getModelList(this.modelFilter).pipe(map(orgs =>
        orgs.map(org => ({
          ...org,
          label: org.name,
          value: org.id
        }))
      )
    );
  }

  private createFilters(action?: FilterAction) {
    let fn;
    this.modelFilter.rows = this.entriesPerPage;
    this.modelFilter.first = this.getFirstItemOnPage();

    this.modelFilter.sortField = this.sortFieldLogs;

    if (!this.isSuperAdminOrAuxiliaAdmin) {
      this.modelFilter.filters[FilterAction.Initial] = {
        field: 'clientId',
        value: this.clientIdStateService.selectedRelationshipClientId,
        type: FilterType.Equal
      };
    }

    const organizationsFilter = () => {
      this.modelFilter.filters[FilterAction.Organization] = {
        field: 'clientId',
        value: this.arrClientsIdsStr ? `array[${this.arrClientsIdsStr}]` : '',
        type: FilterType.Equal
      };
    };

    const ativityTypeFilter = () => {
      this.modelFilter.filters[FilterAction.ActivityType] = {
        field: 'message',
        value: !this.arrActivityValueStr || this.arrActivityValue.includes(UserActivityType.All) ? '' : `array[${this.arrActivityValueStr}]`,
        type: FilterType.Equal
      };
    };

    const userRoleFilter = () => {
      this.modelFilter.filters[FilterAction.UserRole] = {
        field: 'userRole',
        value: !this.arrUserRoleValueStr || this.arrUserRoleValue.includes('ALL') ? '' : `array[${this.arrUserRoleValueStr}]`,
        type: FilterType.Equal
      };
    };

    const startFromDateFilter = () => {
      this.modelFilter.filters[FilterAction.StartFrom] = {
        field: 'loggedOnDate',
        value: this.startFromStr,
        type: FilterType.GreaterThan
      };
    };

    const dateToFilter = () => {
      this.modelFilter.filters[FilterAction.DateTo] = {
        field: 'loggedOnDate',
        value: this.dateToStr,
        type: FilterType.LessThanOrEqual
      };
    };

    const actions = {
      [FilterAction.Organization]: organizationsFilter,
      [FilterAction.ActivityType]: ativityTypeFilter,
      [FilterAction.UserRole]: userRoleFilter,
      [FilterAction.StartFrom]: startFromDateFilter,
      [FilterAction.DateTo]: dateToFilter,
      default: () => null
    };
    if (actions[action]) {
      fn = actions[action];
    } else {
      fn = actions['default'];
    }
    fn();
  }

  private getInfoLogs(action?: FilterAction): void {
    this.createFilters(action);
    if (this.arrUserRoleValue.length > 0 && this.arrActivityValue.length > 0) {
      this.infoLogs$ = this.infoLogService.getInfoLogFeatures(this.modelFilter)
        .pipe(map(infoLogs => infoLogs.map(log => ({ ...log, actions: null, organization: this.clientIdStateService.selectedRelationshipClientName }))));
    } else {
      this.infoLogs$ = of(null);
    }
  }

  private getTotal(): void {
    if (this.arrActivityValue.length > 0) {
      this.total$ = this.infoLogService.getInfoLogFeaturesTotal(this.modelFilter).pipe(tap(total => this.total = total));
    } else {
      this.total = 0;
    }
  }

  public get startDate(): FormControl {
    return this.filterForm.get('startDate') as FormControl;
  }

  public get endDate(): FormControl {
    return this.filterForm.get('endDate') as FormControl;
  }

  public startDateFilter = (date: Date): boolean => {
    const endDate = this.endDate.value;
    return endDate ? endDate.getTime() >= date.getTime() : true;
  };

  public endDateFilter = (date: Date): boolean => {
    const startDate = this.startDate.value;
    return startDate ? startDate.getTime() <= date.getTime() : true;
  };

  private getTableColumns(): string[] {
    const baseColumns = ['message', 'userRole', 'frequency', 'actions'];
    if (this.clientsWithRelationships.length > 1) {
      baseColumns.push('organization');
    }

    if (this.isSuperAdminOrAuxiliaAdmin) {
      // org name
      baseColumns.splice(2, 0, 'clientName');
      return baseColumns;
    }
    return baseColumns;
  }

  public getBackNavigate(): void {
    let url = '/clients/reports';
    if (this.isSuperAdminOrAuxiliaAdmin) {
      url = '/admin-dashboard/reports';
    }
    this.router.navigateByUrl(url);
  }

  public navigateToLogFeaturePage({ clientId, message, userRole }: InfoLogModel): void {
    if (this.isSuperAdminOrAuxiliaAdmin) {
      if (!clientId || !message || !userRole) {
        return;
      }
      this.router.navigate(['/admin-dashboard/reports/audit-activity', { userRole, message, clientId }]);
    } else {
      this.router.navigate(['/clients/reports/audit/history', { userRole, message }]);
    }
  }

  private addDays(date: Date, days: number) {
    const result = date;
    result.setDate(result.getDate() + days);
    return result;
  }

  public onStartDateChanged(value: Date): void {
    setTimeout(() => {
      if (this.filterForm.valid) {
        this.startFromStr = value.toISOString();
        this.onFilterChanged();
        this.getInfoLogs(FilterAction.StartFrom);
        this.getTotal();
      }
    }, 100);
  }

  public onEndDateChanged(value: Date): void {
    setTimeout(() => {
      if (this.filterForm.valid) {
        const date = new Date(value);
        this.dateToStr = this.addDays(date, 1).toISOString();
        this.onFilterChanged();
        this.getInfoLogs(FilterAction.DateTo);
        this.getTotal();
      }
    }, 100);
  }

  public onRoleChanged(roles: string[]): void {
    if (!this.arrUserRoleValue.includes('ALL') && roles.includes('ALL')) {
      roles = ['ALL'];
    } else if (this.arrUserRoleValue.includes('ALL') && roles.includes('ALL') && roles.length >= 2) {
      roles = roles.filter(role => role != 'ALL');
    }
    this.filterForm.get('userRole').setValue(roles);
    this.arrUserRoleValue = roles;
    this.arrUserRoleValueStr = this.getArrQueryVal(roles);
    this.onFilterChanged();
    this.getInfoLogs(FilterAction.UserRole);
    this.getTotal();
  }

  public onOrgChanged(clientIds: string[]): void {
    this.arrClientsIdsStr = this.getArrQueryVal(clientIds);
    this.onFilterChanged();
    this.getInfoLogs(FilterAction.Organization);
    this.getTotal();
  }

  public onActivityChanged(val: string[]): void {
    if (!this.arrActivityValue.includes(UserActivityType.All) && val.includes(UserActivityType.All)) {
      val = [UserActivityType.All];
    } else if (this.arrActivityValue.includes(UserActivityType.All) && val.includes(UserActivityType.All) && val.length >= 2) {
      val = val.filter(role => role != UserActivityType.All);
    }
    this.filterForm.get('activity').setValue(val);
    this.arrActivityValue = val;
    this.arrActivityValueStr = this.getArrQueryVal(val);
    this.onFilterChanged();
    this.getInfoLogs(FilterAction.ActivityType);
    this.getTotal();
  }

  public exportReport(): void {
    if (this.filterForm.valid) {
      this.subscription.add(
        this.exportReportsService.exportAuditFeatures(this.modelFilter, this.authService.getIdentityClaimsId(), this.authService.getIdentityClaimsName()).subscribe(response => {
          if (response.size === 9) {
            this.toastr.info('Your Export is scheduled. After finishing you can get the link in Notifications');
          } else
            saveAs(response, `userFeatures${new Date().toDateString()}.xlsx`);
        })
      );
    } else {
      this.toastr.error('Please fill all mandatory fields', 'Error');
    }
  }

  public getArrQueryVal(val: string[]): string {
    return val.map((item: string) => `"${item}"`).join(',');
  }

  public clientChanged(clientID: string): void {
    const name = this.clientsWithRelationships.find((option: FormElementDataModel) => option.value === clientID).label;
    this.clientIdStateService.setSelectedRelationshipClient(clientID, name);
    this.onFilterChanged();
    this.getInfoLogs();
    this.getTotal();
  }

  public get formGroup(): FormGroup {
    return this.clientIdStateService.clientIdForm;
  }

  public getNextPage(page: number): void {
    this.nextPage(page, () => this.getInfoLogs());
  }

  public setEntriesPerPage(amount: number): void {
    this.entriesPerPageChanged(amount, () => this.getInfoLogs());
  }

  private startDateValidator(formGroup: FormGroup): ValidationErrors {
    const { startDate, startTime, timeZoneID, endDate }: any = formGroup.value;
    if (!startDate || !endDate) {
      return null;
    } else {
      if (endDate < startDate) {
        formGroup.controls.startDate.setErrors({ required: true });
      } else {
        formGroup.controls.startDate.setErrors(null);
      }
    }
    return endDate > startDate ? null : { invalidDate: true };
  }
}
