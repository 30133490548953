import { ConfigService } from '../services/appconfig.service';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';


export class GlobalVariable {
    public static configService: ConfigService;
    public static oathService: OAuthService;
}

export function GetConfigServiceFactory(http: HttpClient): ConfigService {
    if (GlobalVariable.configService != null || GlobalVariable.configService !== undefined) return GlobalVariable.configService;

    GlobalVariable.configService = new ConfigService(http)
    return GlobalVariable.configService;
}
