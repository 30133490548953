import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';

@Component({
  selector: 'app-signature-tool',
  templateUrl: './signature-tool.component.html',
  styleUrls: ['../img-tool.scss']
})
export class SignatureToolComponent implements AfterViewInit, OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() public column: MjmlElementModel;
  @Input() public entity: MjmlElementModel;
  @Input() public tms: TemplateManagementService;

  @ViewChild('signatureContainerRef') private signatureContainerRef: ElementRef;

  constructor() { }

  ngOnInit() {
  }
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngAfterViewInit(): void {
    this.subscription.add(
      this.tms.signatureChangedEmitter.subscribe(() => this.setContent())
    );
    this.setContent();
  }

  private setContent(): void {
    this.signatureContainerRef.nativeElement.innerHTML = this.entity.content;
  }

  public getContainerStyles(): any {
    const attributes = this.entity.attributes;
    const color = attributes['color'] || '#000000';
    const fontFamily = attributes['font-family'] || 'Roboto';
    const fontSize = attributes['font-size'] || '13px';
    const fontStyle = attributes['font-style'] || 'normal';
    const fontWeight = attributes['font-weight'] || 'normal';
    const textDecoration = attributes['text-decoration'] || 'none';
    const align = attributes['align'] || 'left';
    const containerBackgroundColor = attributes['container-background-color'] || '';
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    return {
      color,
      'font-family': fontFamily,
      'font-size': fontSize,
      'font-style': fontStyle,
      'font-weight': fontWeight,
      'text-decoration': textDecoration,
      'text-align': align,
      'background-color': containerBackgroundColor,
      'padding-bottom': paddingBottom,
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      'padding-top': paddingTop
    };
  }
}
