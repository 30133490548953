import { Component, Input } from '@angular/core';
import { FbElementModel, FbElementType, OptionsViewType } from '../../../../models/form-builder/fb.template.model';
import { FormBuilderStateService } from '../../form-builder.state.service';
import {BehaviorSubject, Observable} from 'rxjs';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { CornerstoneService } from '../../cornerstone.service';
import { ClientType } from '../../../../models/enum/client.type';
import { AppStripeService } from '../../app.stripe.service';
import CountryModel from "../../../../models/internationalization/country.model";

@Component({
  selector: 'app-element-renderer',
  templateUrl: './element-renderer.component.html',
  styleUrls: ['./element-renderer.component.scss']
})
export class ElementRendererComponent {
  @Input() public element: FbElementModel;
  @Input() public fbss: FormBuilderStateService;
  @Input() public stateOptions$: Observable<FormElementDataModel[]>;
  @Input() public cornerstoneService: CornerstoneService;
  @Input() public appStripeService: AppStripeService;
  @Input() public countries: CountryModel[];
  @Input() public containerWidth: number;
  public FbElementType = FbElementType;
  public ClientType = ClientType;

  constructor(
  ) { }

  public get elementStyle(): any {
    const { width = 760 } = this.fbss.template$.getValue();
    if (this.fbss.optionsView$.getValue() === OptionsViewType.None) {
      return {
        'width': `${width}px`,
      };
    } else {
      return {
        'width': `${this.containerWidth - 70}px`,
      };
    }
  }

}
