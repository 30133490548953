import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { PaymentModel } from '../../models/payments/payment.model';
import { BaseModelService } from '../base.model.service';
import { TransactionModel } from 'src/app/models/payments/transaction.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends BaseModelService<PaymentModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }
  protected get controllerName(): string {
    return 'Payment';
  }

  public addPayment(model: PaymentModel): Observable<TransactionModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/processpay`;
        return this.http.post<TransactionModel>(url, model);
      })
    );
  }

  public addPaymentNew(model: PaymentModel): Observable<TransactionModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/processpayment`;
        return this.http.post<TransactionModel>(url, model);
      })
    );
  }

  public updatePaymentOption(recurringSettingsId: string, paymentModel: PaymentModel): Observable<void> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/UpdatePaymentOption/${recurringSettingsId}`;
        return this.http.post<void>(url, paymentModel);
      })
    );
  }

}
