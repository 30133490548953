<div class="choose-option-wrapper">
  <div class="choose-option-close">
    <i class="fas fa-times close-icon" (click)="onClose(true)" matRipple></i>
  </div>

  <span class="choose-option-title">{{data.title | translate}}</span>

  <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" [(ngModel)]="choice">
    <mat-radio-button class="radio-button" *ngFor="let item of data.formElementData" [value]="item.value">
      {{item.label | translate}}
    </mat-radio-button>
  </mat-radio-group>

  <div class="choose-option-actions">
    <app-button type="stroked" label="{{data?.firstButtonName || 'OK' | translate}}" color="#0064be" (clickEvent)="onClose()"
      width="{{data?.widthButton || '120'}}"></app-button>

    <app-button type="stroked" label="{{data?.firstButtonName || 'CANCEL' | translate}}" color="#a9110e" (clickEvent)="onClose(true)"
      width="{{data?.widthButton || '120'}}"></app-button>
  </div>
</div>
