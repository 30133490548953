import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateRangeSelectorModel } from 'src/app/models/date-range-selector.model';

@Component({
  selector: 'app-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss']
})
export class DateRangeSelectorComponent implements OnInit {

  @Input() label: string;
  @Input() hint: string;
  @Output() selectedDateRange: EventEmitter<DateRangeSelectorModel> = new EventEmitter<DateRangeSelectorModel>()

  formGroup: FormGroup;

  constructor(private fb: FormBuilder) { 
    this.formGroup = this.fb.group({
      start: this.fb.control(''),
      end: this.fb.control('')
    })
  }

  ngOnInit(): void {
  }

  onApply(): void {
    const startDate = this.formGroup.get('start').value;
    const endDate = this.formGroup.get('end').value;
    this.selectedDateRange.emit({
      startDate: startDate && startDate !== '' ? new Date(startDate) : null,
      endDate: endDate && endDate !== '' ? new Date(endDate) : null
    })
  }

  onClear(): void {
    this.formGroup.reset();
    this.selectedDateRange.emit({
      startDate: null,
      endDate: null
    });
  }

}
