<div class="ngx-charts-lib-builder-wrapper">
  <div class="ngx-charts-lib-builder-header">
    <span>{{'Chart / Graph' | translate}}</span>
    <i class="fas fa-times" (click)="close()"></i>
  </div>

  <div class="ngx-charts-lib-builder-content">
    <div class="ngx-charts-lib-builder-options">
      <div class="ngx-charts-lib-builder-options-row">
        <div class="max-width-500">
          <app-select
            [formGroup]="chartBuilderForm"
            name="type"
            label="Chart Type"
            [selectOptions]="chartTypeOptions"
            [low]="true"
          ></app-select>
        </div>
      </div>

      <div class="ngx-charts-lib-builder-options-row">
        <div class="max-width-500">
          <app-input
            [formGroup]="chartBuilderForm"
            name="name"
            label="Chart Title"
            [low]="true"
          ></app-input>
        </div>
      </div>

      <ng-container *ngIf="isMultiDataSetInUse; else singleDataSetTemplate">

        <div
          *ngFor="let dataSetGroup of getDataSetGroups(); index as dataSetIndex"
          [ngStyle]="getDataSetGroupContainerStyle(dataSetGroup)"
          class="data-set-container"
        >

          <div class="ngx-charts-lib-builder-options-row">
            <div class="data-set-group-title">Data Set #{{dataSetIndex + 1}}</div>
            <app-button
              *ngIf="getDataSetGroups().length > 1"
              label="Remove Data Set"
              type="basic"
              color="#f44336"
              (clickEvent)="removeDataSet(dataSetIndex)"
            ></app-button>
            <app-button
              *ngIf="dataSetIndex === getDataSetGroups().length - 1"
              label="Add Data Set"
              type="basic"
              color="#0064be"
              (clickEvent)="addDataSet()"
            ></app-button>
          </div>

          <div class="ngx-charts-lib-builder-options-row">
            <div class="max-width-390 margin-right">
              <app-input
                [formGroup]="dataSetGroup"
                name="name"
                label="Name"
                [low]="true"
              ></app-input>
            </div>

            <div class="max-width-60">
              <app-email-color-picker
                [formGroup]="dataSetGroup"
                label="Color"
                name="color"
              ></app-email-color-picker>
            </div>
          </div>

          <ng-container *ngFor="let seriesGroup of getSeriesGroups(dataSetGroup); index as seriesIndex">

            <div class="ngx-charts-lib-builder-options-row">
              <div class="max-width-170 margin-right">
                <app-input
                  [formGroup]="seriesGroup"
                  name="name"
                  label="X-Axis Value"
                  [low]="true"
                ></app-input>
              </div>
              <div class="max-width-170 margin-right">
                <app-input
                  [formGroup]="seriesGroup"
                  name="value"
                  label="Y-Axis Value"
                  mask="separator.2"
                  thousandSeparator=","
                  [low]="true"
                ></app-input>
              </div>
              <div>
                <app-button
                  *ngIf="getSeriesGroups(dataSetGroup).length > 1"
                  label="Remove"
                  type="basic"
                  color="#f44336"
                  width="90"
                  (clickEvent)="removePoint(dataSetGroup, seriesIndex)"
                ></app-button>
                <app-button
                  *ngIf="seriesIndex === getSeriesGroups(dataSetGroup).length - 1"
                  label="Add"
                  type="basic"
                  color="#0064be"
                  width="90"
                  (clickEvent)="addPoint(dataSetGroup)"
                ></app-button>
              </div>

            </div>

          </ng-container>
        </div>
      </ng-container>

      <div class="ngx-charts-lib-builder-options-row">
        <div class="max-width-225 margin-right">
          <app-slider
            [formGroup]="chartBuilderForm"
            name="aspectRatioX"
            label="Aspect Ratio X"
            [min]="300"
            [max]="1000"
            [step]="20"
            [tickInterval]="5"
          ></app-slider>
        </div>

        <div class="max-width-225">
          <app-slider
            [formGroup]="chartBuilderForm"
            name="aspectRatioY"
            label="Aspect Ratio Y"
            [min]="200"
            [max]="1000"
            [step]="20"
            [tickInterval]="5"
          ></app-slider>
        </div>
      </div>

      <div class="ngx-charts-lib-builder-options-row" *ngIf="type.value !== ChartType.Pie">
        <div class="max-width-225 margin-right">
          <app-select
            *ngIf="type.value === ChartType.Line"
            [formGroup]="chartBuilderForm"
            name="curve"
            label="Line Interpolation"
            [low]="true"
            [selectOptions]="curveTypeOptions"
          ></app-select>

          <app-checkbox
            *ngIf="type.value === ChartType.VerticalBar || type.value === ChartType.HorizontalBar"
            [formGroup]="chartBuilderForm"
            name="showDataLabel"
            label="Show Data Label"
          ></app-checkbox>
        </div>
        <div class="max-width-225">
          <app-checkbox
            [formGroup]="chartBuilderForm"
            name="showGridLines"
            label="Show Grid Lines"
          ></app-checkbox>
        </div>
      </div>

      <div class="ngx-charts-lib-builder-options-row" *ngIf="type.value !== ChartType.Pie">
        <div class="max-width-225 margin-right">
          <app-checkbox
            [formGroup]="chartBuilderForm"
            name="xAxis"
            label="Show X Axis"
          ></app-checkbox>
        </div>
        <div class="max-width-225">
          <app-checkbox
            [formGroup]="chartBuilderForm"
            name="yAxis"
            label="Show Y Axis"
          ></app-checkbox>
        </div>
      </div>

      <div class="ngx-charts-lib-builder-options-row" *ngIf="type.value !== ChartType.Pie">
        <div class="max-width-225 margin-right">
          <app-checkbox
            [formGroup]="chartBuilderForm"
            name="showXAxisLabel"
            label="Show X Axis Label"
          ></app-checkbox>
        </div>
        <div class="max-width-225">
          <app-checkbox
            [formGroup]="chartBuilderForm"
            name="showYAxisLabel"
            label="Show Y Axis Label"
          ></app-checkbox>
        </div>
      </div>

      <div class="ngx-charts-lib-builder-options-row" *ngIf="type.value !== ChartType.Pie">
        <div class="max-width-225 margin-right">
          <app-input
            [formGroup]="chartBuilderForm"
            name="xAxisLabel"
            label="X Axis Label"
            [low]="true"
            errorMassage="X Axis Label"
          ></app-input>
        </div>
        <div class="max-width-225">
          <app-input
            [formGroup]="chartBuilderForm"
            name="yAxisLabel"
            label="Y Axis Label"
            [low]="true"
            errorMassage="Y Axis Label"
          ></app-input>
        </div>
      </div>

      <div class="ngx-charts-lib-builder-options-row">
        <div class="max-width-225 margin-right">
          <app-checkbox
            [formGroup]="chartBuilderForm"
            name="legend"
            label="Show Legend"
          ></app-checkbox>
        </div>
        <div class="max-width-225">
          <app-select
            [formGroup]="chartBuilderForm"
            name="legendPosition"
            label="Legend Position"
            [low]="true"
            [selectOptions]="legendPositionOptions"
          ></app-select>
        </div>
      </div>

      <div class="ngx-charts-lib-builder-options-row">
        <div class="max-width-225 margin-right">
          <app-input
            [formGroup]="chartBuilderForm"
            name="legendTitle"
            label="Legend Title"
            [low]="true"
            errorMassage="Legend Title"
          ></app-input>
        </div>
        <div class="max-width-225">
          <app-checkbox
            *ngIf="type.value === ChartType.Line"
            [formGroup]="chartBuilderForm"
            name="autoScale"
            label="Auto Scale"
          ></app-checkbox>

          <app-checkbox
            *ngIf="type.value === ChartType.VerticalBar || type.value === ChartType.HorizontalBar"
            [formGroup]="chartBuilderForm"
            name="noBarWhenZero"
            label="Hide bar if value is 0"
          ></app-checkbox>

          <app-checkbox
            *ngIf="type.value === ChartType.Pie"
            [formGroup]="chartBuilderForm"
            name="basedOnPercentage"
            label="Use Percentage For Labels"
          ></app-checkbox>
        </div>
      </div>

      <div class="ngx-charts-lib-builder-options-row" *ngIf="type.value !== ChartType.Pie">
        <div class="max-width-225 margin-right">
          <app-checkbox
            [formGroup]="chartBuilderForm"
            name="trimXAxisTicks"
            label="Trim X Axis Ticks"
          ></app-checkbox>
        </div>
        <div class="max-width-225">
          <app-checkbox
            [formGroup]="chartBuilderForm"
            name="trimYAxisTicks"
            label="Trim Y Axis Ticks"
          ></app-checkbox>
        </div>
      </div>

      <div class="ngx-charts-lib-builder-options-row" *ngIf="type.value !== ChartType.Pie">
        <div class="max-width-225 margin-right">
          <app-slider
            [formGroup]="chartBuilderForm"
            name="maxXAxisTickLength"
            label="Max X Axis Tick Length"
            [min]="3"
            [max]="50"
            [step]="1"
            [tickInterval]="5"
          ></app-slider>
        </div>
        <div class="max-width-225">
          <app-slider
            [formGroup]="chartBuilderForm"
            name="maxYAxisTickLength"
            label="Max Y Axis Tick Length"
            [min]="3"
            [max]="50"
            [step]="1"
            [tickInterval]="5"
          ></app-slider>
        </div>
      </div>

      <div class="ngx-charts-lib-builder-options-row" *ngIf="type.value === ChartType.Line">
        <div class="max-width-225 margin-right">
          <app-input
            [formGroup]="chartBuilderForm"
            name="yScaleMin"
            label="Minimum Y-Scale Value"
            [low]="true"
            mask="separator.2"
            thousandSeparator=","
            errorMassage="Value"
          ></app-input>
        </div>
        <div class="max-width-225">
          <app-input
            [formGroup]="chartBuilderForm"
            name="yScaleMax"
            label="Maximum Y-Scale Value"
            [low]="true"
            mask="separator.2"
            thousandSeparator=","
            errorMassage="Value"
          ></app-input>
        </div>
      </div>

      <div class="ngx-charts-lib-builder-options-row" *ngIf="type.value === ChartType.VerticalBar || type.value === ChartType.HorizontalBar">
        <div class="max-width-225 margin-right">
          <app-slider
            [formGroup]="chartBuilderForm"
            name="barPadding"
            label="Padding between bars"
            [min]="0"
            [max]="100"
            [step]="1"
            [tickInterval]="5"
          ></app-slider>
        </div>
        <div class="max-width-225">
          <app-checkbox
            [formGroup]="chartBuilderForm"
            name="roundEdges"
            label="Round Bar Edges"
          ></app-checkbox>
        </div>
      </div>

      <div class="ngx-charts-lib-builder-options-row" *ngIf="type.value === ChartType.Pie">
        <div class="max-width-225 margin-right">
          <app-checkbox
            [formGroup]="chartBuilderForm"
            name="labels"
            label="Show Labels"
          ></app-checkbox>
        </div>

        <div class="max-width-225">
          <app-checkbox
            [formGroup]="chartBuilderForm"
            name="trimLabels"
            label="Trim Labels"
          ></app-checkbox>
        </div>
      </div>

      <div class="ngx-charts-lib-builder-options-row" *ngIf="type.value === ChartType.Pie">
        <div class="max-width-225 margin-right">
          <app-slider
            [formGroup]="chartBuilderForm"
            name="maxLabelLength"
            label="Maximum Label Length"
            [min]="0"
            [max]="100"
            [step]="1"
            [tickInterval]="5"
          ></app-slider>
        </div>

        <div class="max-width-225">
          <app-checkbox
            [formGroup]="chartBuilderForm"
            name="explodeSlices"
            label="Explode Slices"
          ></app-checkbox>
        </div>
      </div>

      <div class="ngx-charts-lib-builder-options-row" *ngIf="type.value === ChartType.Pie">
        <div class="max-width-225 margin-right">
          <app-checkbox
            [formGroup]="chartBuilderForm"
            name="doughnut"
            label="Doughnut"
          ></app-checkbox>
        </div>

        <div class="max-width-225" *ngIf="chartBuilderForm.get('doughnut').value">
          <app-slider
            [formGroup]="chartBuilderForm"
            name="arcWidth"
            label="Arc width (fraction of radius)"
            [min]="0"
            [max]="1"
            [step]="0.01"
            [tickInterval]="10"
          ></app-slider>
        </div>
      </div>
    </div>

    <div
      [ngSwitch]="type.value"
      class="ngx-charts-lib-builder-result"
      #chartRef
    >
      <div
        *ngIf="chartBuilderFormValues.name"
        class="chart-title"
        [style.width.px]="chartBuilderFormValues.aspectRatioX"
      >{{chartBuilderFormValues.name}}</div>
      <ngx-charts-line-chart
        *ngSwitchCase="ChartType.Line"
        [view]="view"
        [scheme]="colorScheme"
        [legend]="chartBuilderFormValues.legend"
        [xAxis]="chartBuilderFormValues.xAxis"
        [yAxis]="chartBuilderFormValues.yAxis"
        [showGridLines]="chartBuilderFormValues.showGridLines"
        [legendPosition]="chartBuilderFormValues.legendPosition"
        [legendTitle]="chartBuilderFormValues.legendTitle"
        [showXAxisLabel]="chartBuilderFormValues.showXAxisLabel"
        [showYAxisLabel]="chartBuilderFormValues.showYAxisLabel"
        [xAxisLabel]="chartBuilderFormValues.xAxisLabel"
        [yAxisLabel]="chartBuilderFormValues.yAxisLabel"
        [trimXAxisTicks]="chartBuilderFormValues.trimXAxisTicks"
        [trimYAxisTicks]="chartBuilderFormValues.trimYAxisTicks"
        [maxXAxisTickLength]="chartBuilderFormValues.maxXAxisTickLength"
        [maxYAxisTickLength]="chartBuilderFormValues.maxYAxisTickLength"
        [autoScale]="chartBuilderFormValues.autoScale"
        [curve]="curve"
        [yScaleMin]="chartBuilderFormValues.yScaleMin"
        [yScaleMax]="chartBuilderFormValues.yScaleMax"
        [results]="results"
      ></ngx-charts-line-chart>

      <ngx-charts-bar-vertical
        *ngSwitchCase="ChartType.VerticalBar"
        [view]="view"
        [scheme]="colorScheme"
        [legend]="chartBuilderFormValues.legend"
        [xAxis]="chartBuilderFormValues.xAxis"
        [yAxis]="chartBuilderFormValues.yAxis"
        [showGridLines]="chartBuilderFormValues.showGridLines"
        [legendPosition]="chartBuilderFormValues.legendPosition"
        [legendTitle]="chartBuilderFormValues.legendTitle"
        [showXAxisLabel]="chartBuilderFormValues.showXAxisLabel"
        [showYAxisLabel]="chartBuilderFormValues.showYAxisLabel"
        [xAxisLabel]="chartBuilderFormValues.xAxisLabel"
        [yAxisLabel]="chartBuilderFormValues.yAxisLabel"
        [trimXAxisTicks]="chartBuilderFormValues.trimXAxisTicks"
        [trimYAxisTicks]="chartBuilderFormValues.trimYAxisTicks"
        [maxXAxisTickLength]="chartBuilderFormValues.maxXAxisTickLength"
        [maxYAxisTickLength]="chartBuilderFormValues.maxYAxisTickLength"
        [yScaleMin]="chartBuilderFormValues.yScaleMin"
        [yScaleMax]="chartBuilderFormValues.yScaleMax"
        [results]="results"
        [showDataLabel]="chartBuilderFormValues.showDataLabel"
        [noBarWhenZero]="chartBuilderFormValues.noBarWhenZero"
        [barPadding]="chartBuilderFormValues.barPadding"
        [roundEdges]="chartBuilderFormValues.roundEdges"
      ></ngx-charts-bar-vertical>

      <ngx-charts-bar-horizontal
        *ngSwitchCase="ChartType.HorizontalBar"
        [view]="view"
        [scheme]="colorScheme"
        [legend]="chartBuilderFormValues.legend"
        [xAxis]="chartBuilderFormValues.xAxis"
        [yAxis]="chartBuilderFormValues.yAxis"
        [showGridLines]="chartBuilderFormValues.showGridLines"
        [legendPosition]="chartBuilderFormValues.legendPosition"
        [legendTitle]="chartBuilderFormValues.legendTitle"
        [showXAxisLabel]="chartBuilderFormValues.showXAxisLabel"
        [showYAxisLabel]="chartBuilderFormValues.showYAxisLabel"
        [xAxisLabel]="chartBuilderFormValues.xAxisLabel"
        [yAxisLabel]="chartBuilderFormValues.yAxisLabel"
        [trimXAxisTicks]="chartBuilderFormValues.trimXAxisTicks"
        [trimYAxisTicks]="chartBuilderFormValues.trimYAxisTicks"
        [maxXAxisTickLength]="chartBuilderFormValues.maxXAxisTickLength"
        [maxYAxisTickLength]="chartBuilderFormValues.maxYAxisTickLength"
        [results]="results"
        [showDataLabel]="chartBuilderFormValues.showDataLabel"
        [noBarWhenZero]="chartBuilderFormValues.noBarWhenZero"
        [barPadding]="chartBuilderFormValues.barPadding"
        [roundEdges]="chartBuilderFormValues.roundEdges"
      ></ngx-charts-bar-horizontal>

      <ngx-charts-pie-chart
        *ngSwitchCase="ChartType.Pie"
        [view]="view"
        [scheme]="colorScheme"
        [legend]="chartBuilderFormValues.legend"
        [legendPosition]="chartBuilderFormValues.legendPosition"
        [legendTitle]="chartBuilderFormValues.legendTitle"
        [results]="results"
        [labels]="chartBuilderFormValues.labels"
        [trimLabels]="chartBuilderFormValues.trimLabels"
        [maxLabelLength]="chartBuilderFormValues.maxLabelLength"
        [labelFormatting]="labelFormatting"
        [explodeSlices]="chartBuilderFormValues.explodeSlices"
        [doughnut]="chartBuilderFormValues.doughnut"
        [arcWidth]="chartBuilderFormValues.arcWidth"
      ></ngx-charts-pie-chart>
    </div>
  </div>

  <div class="use-in-template-button">
    <app-button
      label="USE IN TEMPLATE"
      type="flat"
      width="200"
      color="#0064be"
      (clickEvent)="captureChart()"
    ></app-button>
  </div>

</div>


<ng-template #singleDataSetTemplate>
  <div
    *ngFor="let dataSetGroup of getDataSetGroups(); index as dataSetIndex"
    [ngStyle]="getDataSetGroupContainerStyle(dataSetGroup)"
    class="data-set-container"
  >
    <div class="ngx-charts-lib-builder-options-row">
      <div class="max-width-390 margin-right">
        <app-input
          [formGroup]="dataSetGroup"
          name="name"
          label="Name"
          [low]="true"
        ></app-input>
      </div>

      <div class="max-width-60">
        <app-email-color-picker
          [formGroup]="dataSetGroup"
          label="Color"
          name="color"
        ></app-email-color-picker>
      </div>
    </div>

    <div class="ngx-charts-lib-builder-options-row">
      <div class="max-width-300 margin-right">
        <app-input
          [formGroup]="dataSetGroup"
          name="value"
          label="Value"
          [low]="true"
          mask="separator.2"
          thousandSeparator=","
        ></app-input>
      </div>

      <div>
        <app-button
          *ngIf="getDataSetGroups().length > 1"
          label="Remove Value"
          type="basic"
          color="#f44336"
          (clickEvent)="removeDataSet(dataSetIndex)"
        ></app-button>
        <app-button
          *ngIf="dataSetIndex === getDataSetGroups().length - 1"
          label="Add Value"
          type="basic"
          color="#0064be"
          (clickEvent)="addDataSet()"
        ></app-button>
      </div>
    </div>

  </div>
</ng-template>
