<div class="email-builder-tool-settings-wrapper">
  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-video-loader
        [formGroup]="emailVideoForm"
        name="videoSrc"
        label="Upload video file"
        [readOnly]="readOnly || isPredesignedThemedTemplate"
        maxVideoDuration="300"
        (valueChanged)="onVideoSrcChanged()"
      ></app-video-loader>
    </div>
  </div>
  <div class="email-builder-tool-settings-row no-margin-bottom">
    <div class="max-width-310">
      <app-input
        label="Video file path"
        [formGroup]="emailVideoForm"
        name="videoSrc"
        [low]="true"
        errorMassage="Path"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onVideoSrcChanged()"
      ></app-input>
    </div>
  </div>

  <div
    class="email-builder-tool-settings-row"
    *ngIf="videoSrc.value && !(readOnly || isPredesignedThemedTemplate)"
  >
    <app-button
      type="flat"
      label="Make a video poster"
      (clickEvent)="makePoster()"
      color="#0064be"
      width="300"
    ></app-button>
  </div>

  <div class="email-builder-tool-settings-row">
    <app-image-loader
      [formGroup]="emailVideoForm"
      name="poster"
      label="Upload poster"
      [readOnly]="readOnly || isPredesignedThemedTemplate"
      [imageURL]="imageURL"
      [aspectRatio]="16 / 9"
      [resizeToWidth]="300"
      [resizeToHeight]="169"
      (valueChanged)="imageUrlChanged($event)"
    ></app-image-loader>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-input
        label="Poster URL"
        [formGroup]="emailVideoForm"
        name="poster"
        errorMassage="Poster URL"
        [low]="true"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="imageUrlChanged($event)"
      ></app-input>
    </div>
    <app-button
      *ngIf="emailVideoForm.get('poster') && emailVideoForm.get('poster').value && !imageURL && !readOnly && !isPredesignedThemedTemplate"
      label="Edit Poster"
      type="flat"
      (clickEvent)="editImage()"
      color="#0064be"
      width="140"
    ></app-button>
    <!--<app-button
      *ngIf="!!imageURL"
      label="Cancel"
      type="flat"
      (clickEvent)="cancelEditImage()"
      color="#ec4c47"
      width="140"
    ></app-button>-->
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="emailVideoForm"
        name="paddingTop"
        label="Padding Top"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingTopChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="emailVideoForm"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingRightChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="emailVideoForm"
        name="paddingBottom"
        label="Padding Bottom"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingBottomChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="emailVideoForm"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingLeftChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row" *ngIf="!(readOnly || isPredesignedThemedTemplate)">
    <app-button
      type="basic"
      label="Delete content"
      (clickEvent)="tms.removeActiveElementFromColumn()"
      color="#f44336"
      width="300"
    ></app-button>
  </div>
</div>
