<div class="custom-app-multiselect">
  <div [matMenuTriggerFor]="menu">
    <ng-content></ng-content>
  </div>

  <mat-menu #menu="matMenu">
    <app-filtered-listbox
      (multiSelectedValue)="multiSelectedValue($event)"
      [items]="selectOptions"
      [columnName]="columnName"
      [initialSelection]="initialSelection"
      [currentSelection]="currentSelection"
      [enableTranslation]="enableTranslation"
      isFilterButtonsVisible="true"
      multiselectable="true"
    ></app-filtered-listbox>
  </mat-menu>
</div>
