export enum CommunicationMethods {
  DoNotContact = 1,
  // eslint-disable-next-line no-bitwise
  Email = 1 << 1,
  // eslint-disable-next-line no-bitwise
  Phone = 1 << 2,
  // eslint-disable-next-line no-bitwise
  Text = 1 << 3,
  // eslint-disable-next-line no-bitwise
  Mail = 1 << 4,
}
