import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from '../base.model.service';
import EventParticipantModel from '../../models/event/event.participant.model';
import { Paging } from '../../models/paging/paging.model';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { PagingHelper } from '../helper/paging.helper';
import { flatMap, mergeMap } from 'rxjs/operators';
import { TotalRowsModel } from 'src/app/models/paging/totalrow.model';
import { VolunteerEventParticipantModel } from 'src/app/models/event/volunteering.model';
import EventRegistrationsCountModel from 'src/app/models/event/event.registrations-count.model';
@Injectable({
  providedIn: 'root'
})
export class EventParticipantsService extends BaseModelService<EventParticipantModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }
  protected get controllerName(): string {
    return 'EventParticipant';
  }

  public getRegistrations(filter?: Paging): Observable<EventParticipantModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<EventParticipantModel[]>(
          `${config.apiURL}/${this.controllerName}/registrations`,
          { params }
        );
      })
    );
  }

  public getVolunteerRegistrations(filter?: Paging): Observable<VolunteerEventParticipantModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<VolunteerEventParticipantModel[]>(
          `${config.apiURL}/${this.controllerName}/volunteerRegistrations`,
          { params }
        );
      })
    );
  }

  public getRegistrationsCount(filter?: Paging): Observable<TotalRowsModel> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<TotalRowsModel>(
          `${config.apiURL}/${this.controllerName}/registrationsTotal`,
          { params }
        );
      })
    );
  }

  public getRegistrationsEventList(filter?: Paging): Observable<EventParticipantModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<EventParticipantModel[]>(
          `${config.apiURL}/${this.controllerName}/registrationsEventList`,
          { params }
        );
      })
    );
  }

  public getEventRegistrationsCount(filter?: Paging): Observable<EventRegistrationsCountModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.get<EventRegistrationsCountModel[]>(
          `${config.apiURL}/${this.controllerName}/EventRegistrationsCount`,
          { params }
        );
      })
    );
  }
}
