import MjmlElementModel from './mjml.element.model';

export interface DonationActivityPopupDataModel {
  eventId: string;
  fundraiserId?: string;
  popupType: DonationActivityPopupType;
  showDonateNowButton: boolean;
  showDonationAmount: boolean;
  entity: MjmlElementModel;
  donateButtonText?: string
  donateButtonColor?: string
  donateButtonColorText?: string
  viewAllBorderColor?: string
  viewAllButtonTextColor?: string
  topDonationsButtonBorderColor?: string
  topDonationsButtonTextColor?: string
}

export enum DonationActivityPopupType {
  AllDonations = 1,
  TopDonations = 2
}
