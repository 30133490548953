import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges,} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {ADD_ON_TYPE_OPTIONS} from 'src/app/constants';
import {EventAddOnTimesUsed, EventSubAddOnTimesUsed} from 'src/app/models/event/event-add-on-times-used.model';
import {EventAddOnIncludeModel, EventAddOnModel} from 'src/app/models/event/event.add-on.model';
import TicketPackageModel from 'src/app/models/event/ticket.package.model';
import {TicketRegistrationModel} from 'src/app/models/event/ticket.registration.model';
import {UtilsComponent} from '../utils.component';

@Component({
  selector: 'app-addons-block',
  templateUrl: './addons-block.component.html',
  styleUrls: ['./addons-block.component.scss'],
})
export class AddOnsBlock implements OnInit, OnDestroy, OnChanges {
  @Input() addonList: EventAddOnModel[] = [];
  @Input() addonsFormGroup: FormGroup;
  @Input() totalTickets: TicketRegistrationModel[];
  @Input() addOnsTimesUsed: EventAddOnTimesUsed[];
  @Input() ticketPackageList: TicketPackageModel[];
  @Input() isPreviewMode: boolean = false;
  @Input() isEditModeOne: boolean = false;
  addonListAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private subscription: Subscription = new Subscription();
  private selectedAddOns: any[];
  private ticketExist: string;
  private addOnCounterValueChanged: Subject<EventAddOnModel> = new Subject<EventAddOnModel>()

  @Input() private ticketChange: Observable<TicketPackageModel>;

  constructor(
    private toastrService: ToastrService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.addonsFormGroup.valueChanges.subscribe((res: FormGroup) => {
        this.selectedAddOns = Object.keys(res)
          .map(key => {
            return {
              id: +key.replace('addon', ''),
              usedTimes: res[key].quantity,
              subAddons: this.getSubAddonsGroup(res[key].subAddons),
            }
          })
      })
    )

    this.subscription.add(
      this.addOnCounterValueChanged
        .pipe(
          debounceTime(750),
          filter(() => this.addonsFormGroup.invalid && this.addonsFormGroup.dirty),
          tap(value => {
            this.invalidTicketRelatedAddOnsAmountMessage(value.ticketPackageId);
            this.invalidQuantityOfUnrelatedAddOns(value)
          })
        )
        .subscribe()
    )

    this.ticketChange.subscribe((value: TicketPackageModel) => {
      this.addonsFormGroup.updateValueAndValidity();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.addonList) {
      this.setAddonList(changes.addonList.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private invalidQuantityOfUnrelatedAddOns(value) {
    const control: FormGroup = this.addonsFormGroup.get(`addon${value.id}`) as FormGroup;
    if (!control) return;
    if (control.controls.quantity.errors?.minimumQuantity) {
      this.toastrService.error(this.translate.instant('EVENTS.The minimum quantity of Add-Ons unrelated to ticket is 1'))
    }
  }

  private invalidTicketRelatedAddOnsAmountMessage(ticketId: string): void {
    const ticket = this.totalTickets.find(ticket => ticket.id === ticketId);
    this.ticketExist = ticketId;
    if (!ticket) return;
    const ticketPackage: TicketPackageModel = this.ticketPackageList.find(({id}) => id === ticketId)
    const addOnsLeft: number = UtilsComponent.getRequiredAddOnsLeftCount(ticketPackage, this.addOnsTimesUsed);
    const ticketsAmounts: number = ticketPackage.inPackageCount > 1 ? ticket.quantity * ticketPackage.inPackageCount : ticket.quantity;
    const result: number = addOnsLeft < ticketsAmounts ? addOnsLeft : ticketsAmounts;
    this.toastrService.error(this.translate.instant(`EVENTS.The required amount of Add-Ons related to ticket "{{value}}" is: `, {value: ticket.name.toUpperCase()}) + result);
  }

  private getSubAddonsGroup(subAddonList): any[] {
    return Object.keys(subAddonList)
      .map(key => {
        return {
          id: +key.replace('subAddon', ''),
          timesUsed: subAddonList[key].quantity,
        }
      });
  }

  private setAddonList(addonList: EventAddOnModel[]): void {
    if (!addonList || addonList.length === 0) {
      this.addonListAvailable.next(false);
      return;
    }

    this.addonList = addonList;
    this.addonListAvailable.next(true);
  }

  addonsFormGroupById(id: number): FormGroup {
    return this.addonsFormGroup.get(`addon${id}`) as FormGroup;
  }

  subAddonFormGroupByIds(addonId: number, subAddonId: number): FormGroup {
    const addonFormGroup: FormGroup = this.addonsFormGroupById(addonId).get(
      'subAddons',
    ) as FormGroup;
    const subAddonFormGroup = addonFormGroup.get(`subAddon${subAddonId}`);
    return subAddonFormGroup as FormGroup;
  }

  getAddonType(typeNum: number): string {
    const result = ADD_ON_TYPE_OPTIONS.find((type) => {
      return type.value === typeNum;
    });

    return result.label;
  }

  isAddonActive(addonId: number): boolean {
    const addonsGroup = this.addonsFormGroupById(addonId);
    if (addonsGroup.get('isChecked').value) return true;
    return false;
  }

  hasSubAddons(addonIndex: number): boolean {
    if (this.addonList.includes && !!this.addonList[addonIndex].includes.length)
      return true;
    return false;
  }

  showBottomBorder(subAddons, subAddonIndex): boolean {
    if (subAddonIndex !== subAddons.length - 1) return true;
    return false;
  }

  handleCheckBoxClick(addOnId): void {
    const addOnControl: AbstractControl = this.addonsFormGroupById(addOnId);
    addOnControl.get('quantity').setValue(0);
    if (addOnControl.get('subAddons').value) {
      const subAddOnsControl: AbstractControl = addOnControl.get('subAddons').value;
      Object.values(subAddOnsControl).forEach(subAddOn => {
        this.subAddonFormGroupByIds(addOnId, subAddOn.subAddonId).get('quantity').setValue(0);
      })
    }
  }

  displayAddOn(addOn: EventAddOnModel): boolean {
    if (this.isAddOnSoldOut(addOn)) return false;
    if (!this.isAddOnTicketSeleted(addOn)) return false;
    return true;
  }

  handleMaxValueReached(event: number): void {
    this.toastrService.info(this.translate.instant("EVENTS.The quantity limit on the number of add-ons is:") + ` ${event}`);
  }

  private isAddOnTicketSeleted(addOn: EventAddOnModel): boolean {
    if (!addOn.ticketPackageId) return true;
    const ticket = this.totalTickets.find(ticket => ticket.id === addOn.ticketPackageId);
    if (ticket && ticket.quantity > 0) return true;
    return false;
  }

  public getAvailableAddOnsAmount(addOnId: number): number {
    if (!addOnId) return;
    const currentAddOn: EventAddOnModel = this.addonList.find(addOn => addOn.id === addOnId);
    if (!currentAddOn) return;
    const eventTotalQuantity: number = currentAddOn.quantity;
    const currentAddOnUsedAmount: number = this.addOnsTimesUsed
      ?.find(currUsedAmount => {
        return currUsedAmount.id === addOnId;
      })
      ?.timesUsed || 0;
    return eventTotalQuantity - currentAddOnUsedAmount;
  }

  public getAvailableSubAddOnsAmount(addOnId: number, subAddOnId: number): number {
    if (!addOnId || !subAddOnId) return 0;

    const currentAddOn: EventAddOnModel = this.addonList.find(addOn => addOn.id === addOnId);
    if (!currentAddOn || !currentAddOn.includes || currentAddOn.includes.length === 0) return 0;

    const currentSubAddOn: EventAddOnIncludeModel = currentAddOn.includes.find(currSubAddOn => currSubAddOn.id === subAddOnId);
    const currentAddOnUsed: EventAddOnTimesUsed = this.addOnsTimesUsed.find(addOn => addOn.id === addOnId);
    const currentSubAddOnUsed: EventSubAddOnTimesUsed = currentAddOnUsed?.subAddons.find(subAddOn => subAddOn.id === subAddOnId);
    const currentSubAddOnUsedAmount: number = currentSubAddOnUsed ? currentSubAddOnUsed.timesUsed : 0;

    const otherSubAddOnsSelected = this.selectedAddOns
      .find(addOn => addOn.id === addOnId)
      .subAddons
      .filter(subAddOn => subAddOn.id !== subAddOnId);
    const otherSubAddOnsSelectedAmount: number = otherSubAddOnsSelected.length && otherSubAddOnsSelected
      .map(subAddOn => subAddOn.timesUsed)
      .reduce((prev, curr) => prev + curr, 0);

    let result: number = currentAddOn.quantity;
    if (currentAddOnUsed) {
      result -= currentAddOnUsed.timesUsed;
    }
    if (otherSubAddOnsSelectedAmount) {
      result -= otherSubAddOnsSelectedAmount;
    }

    // If subAddon has defined quantity
    if (currentSubAddOn.quantity && currentSubAddOn.quantity > 0) {
      const newResult: number = currentSubAddOn.quantity - currentSubAddOnUsedAmount;
      if (result < newResult) {
        result = newResult;
      }
    }

    return result ? result : 0;
  }

  public hideSubAddOn(time: number = 600): boolean {
    let result: boolean = false
    setTimeout(() => {
      result = true
    }, time)
    return result
  }

  private isAddOnSoldOut(addOn: EventAddOnModel): boolean {
    if (!this.addOnsTimesUsed) return true;
    const currentAddOnTimesUsed: number = this.addOnsTimesUsed.find(
      item => item.id === addOn.id
    )?.timesUsed;
    if (currentAddOnTimesUsed && addOn.quantity === currentAddOnTimesUsed) return true;
    return false;
  }

  public isAddOnInvalid(addOnId): boolean {
    return this.addonsFormGroup.dirty && !!this.addonsFormGroupById(addOnId).errors;
  }

  public getMinMaxPrices(addOn: EventAddOnModel): number[] {
    if (!addOn.includes.length) return;
    if (addOn.includes.length === 1) return [addOn.includes[0].price];
    const priceList: number[] = addOn.includes.map(subAddOn => +subAddOn.price || 0);
    const min: number = Math.min(...priceList);
    const max: number = Math.max(...priceList);
    return [min, max];
  }

  public handleValueChange(addOn: EventAddOnModel): void {
    const subAddOnsExist = Object.keys(this.addonsFormGroupById(addOn.id).get('subAddons').value).length > 0;
    const withTicket = this.totalTickets.find(ticket => ticket.id === this.ticketExist);
    if (subAddOnsExist && !withTicket) {
      let quantity = 0;
      const addOnControl: AbstractControl = this.addonsFormGroupById(addOn.id);
      const subAddOnsControl: AbstractControl = addOnControl.get('subAddons').value;
      Object.values(subAddOnsControl).forEach(subAddOn => {
        quantity += this.subAddonFormGroupByIds(addOn.id, subAddOn.subAddonId).get('quantity').value
      });
      this.addonsFormGroupById(addOn.id).get('quantity').setValue(quantity);
    }
    this.addOnCounterValueChanged.next(addOn);
    this.addonsFormGroup.updateValueAndValidity();
  }

  public isDisabled(): boolean {
    return this.isPreviewMode || this.isEditModeOne
  }
}
