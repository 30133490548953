<div class="dialog">
  <div class="dialog_close">
    <i class="fas fa-times" (click)="onClose()" matRipple></i>
  </div>
  <div style="margin: 10px 20px" class="pr-0">
    <h3 class="dialog-text--default" style="font-size: 21px">
      {{dialogText | translate}}
    </h3>
  </div>
  <div mat-dialog-actions class="justify-content-end" style="margin:38px 0 0;">
    <app-button
      style="margin: 0 20px 20px 0;"
      label="{{'Yes' | translate}}"
      type="stroked"
      color="#0064be"
      width="130"
      (clickEvent)="onApproveClick()">
    </app-button>
    <app-button
      style="margin: 0 20px 20px 0;"
      label="{{'No' | translate}}"
      color="#a9110e"
      type="stroked"
      width="130"
      (clickEvent)="onRejectClick()">
    </app-button>
  </div>
</div>
