import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import MjmlElementModel from '../../models/templates/mjml.element.model';
import { MjmlTag } from '../../models/templates/mjml.tag.type';
import { TemplateManagementService } from '../../services/templates/template.management.service';
import { BehaviorSubject, forkJoin, from, fromEvent, Observable, of, Subscription, zip } from 'rxjs';
import { MjmlCustomType } from '../../models/templates/mjml.custom.type';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import HtmlConvertedModel from '../../models/templates/html.converted.model';
import { PreviewComponent } from './components/preview/preview.component';
import { FormArray, FormGroup } from '@angular/forms';
import FormElementDataModel from '../../models/form.element.data.model';
import { EventStatus } from '../../models/enum/event.status';
import { TimeZoneModel } from '../../models/time-zone.model';
import { TemplateAssignmentType } from '../../models/templates/template.assignment.type';
import { formatDate } from '@angular/common';
import { debounceTime, distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators';
import {
  DEFAULT_CAMPAIGN_MJML_TEMPLATE,
  DEFAULT_EVENT_MJML_TEMPLATE,
  DEFAULT_LETTER_FOOTER_MJML_SECTION,
  DEFAULT_MJML_TEMPLATE,
  UNSUBSCRIBE_CAMPAIGN_MJML_TEMPLATE,
  ZEROEVENT
} from '../../constants';
import TemplatePayload from '../../models/templates/template.payload';
import { TagsComponent } from './components/tags/tags.component';
import { TemplateType } from '../../models/templates/template.type';
import { AuthService } from '../../services/auth.service';
import { MjmlSmName } from '../../models/templates/mjml.sm.name';
import { ClientModuleService } from '../../routes/clients/client.module.service';
import { CountryStateService } from '../../services/events/country.state.service';
import CountryModel from '../../models/internationalization/country.model';
import { ClientIdStateService } from '../../services/client.module.state/client.id.state.service';
import { ClientService } from '../../services/client/client.service';
import { ClientModel } from '../../models/client/client.model';
import html2canvas, { Options } from 'html2canvas';
import { Document, Media, Packer, Paragraph } from 'docx';
import { EventGoalType } from '../../models/enum/event.goal';
import { jsPDF } from 'jspdf';
import { Guid } from 'guid-typescript';
import { MatTooltip } from '@angular/material/tooltip';
import { AlignmentType, HeaderSize } from '../../models/form-builder/fb.template.model';
import { UtilsComponent } from '../utils.component';
import { TranslateService } from '@ngx-translate/core';
import { ActiveElementStateModel } from 'src/app/services/templates/activeElementState.model';
import { CampaignService } from 'src/app/services/campaign/campaign.service';

/** Default blanks */
const SECTIONS: MjmlElementModel[] = [
  {
    tagName: MjmlTag.section, attributes: {padding: '0px'}, children: [
      {tagName: MjmlTag.column, attributes: {padding: '0px', width: '100%'}, children: []}
    ]
  },
  {
    tagName: MjmlTag.section, attributes: {padding: '0px'}, children: [
      {tagName: MjmlTag.column, attributes: {padding: '0px', width: '50%'}, children: []},
      {tagName: MjmlTag.column, attributes: {padding: '0px', width: '50%'}, children: []}
    ]
  },
  {
    tagName: MjmlTag.section, attributes: {padding: '0px'}, children: [
      {tagName: MjmlTag.column, attributes: {padding: '0px', width: '33.333%'}, children: []},
      {tagName: MjmlTag.column, attributes: {padding: '0px', width: '33.333%'}, children: []},
      {tagName: MjmlTag.column, attributes: {padding: '0px', width: '33.333%'}, children: []}
    ]
  },
  {
    tagName: MjmlTag.section, attributes: {padding: '0px'}, children: [
      {tagName: MjmlTag.column, attributes: {padding: '0px', width: '25%'}, children: []},
      {tagName: MjmlTag.column, attributes: {padding: '0px', width: '25%'}, children: []},
      {tagName: MjmlTag.column, attributes: {padding: '0px', width: '25%'}, children: []},
      {tagName: MjmlTag.column, attributes: {padding: '0px', width: '25%'}, children: []}
    ]
  },
];

@Component({
  selector: 'app-email-builder',
  templateUrl: './email-builder.component.html',
  styleUrls: ['./email-builder.component.scss'],
  providers: [TemplateManagementService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailBuilderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public eventForm: FormGroup;
  @Input() public eventHasRegistration: boolean;
  @Input() public stateOptions: FormElementDataModel[] = [];
  @Input() public timeZonesOptions: FormElementDataModel[] = [];
  @Input() public eventStatus: EventStatus = EventStatus.New;
  @Input() public timeZones: TimeZoneModel[] = [];
  @Input() public eventId: string;

  @Input() public campaignId: string;
  @Input() public campaignForm: FormGroup;
  @Input() public campaignEventHasRegistrationForm: boolean;

  @Input() public readOnly: boolean = false;
  @Input() public strictReadOnly: boolean = false;

  @Input() public libraryVersion: boolean = false;
  @Input() public clientPortalVersion: boolean = false;
  @Input() public defaultTemplate: TemplateAssignmentType;

  @Input() public countryStateService: CountryStateService;

  @Input() public isP2PEnabled: boolean;
  @Input() public clientId: string;

  public patchAvailable: boolean = false;

  private eventHasRegistrationFirstChange: boolean = true;
  private campaignEventHasRegistrationFirstChange: boolean = true;
  public fundraiserLinkPermission: boolean = true;

  @ViewChild('templateContainerRef') public templateContainerRef: ElementRef;
  @ViewChild(TagsComponent) private TagsComponent: TagsComponent;
  @ViewChild('exportContainer') private exportContainer: ElementRef;

  private subscription: Subscription = new Subscription();
  public MjmlTag = MjmlTag;
  public MjmlCustomType = MjmlCustomType;
  public currentTab: number = 1;
  public TemplateAssignmentType = TemplateAssignmentType;

  public toolContainer: MjmlElementModel;
  private sectionSize: number = null;
  public templateType: TemplateType = TemplateType.Layout;

  public availableSections: MjmlElementModel[] = JSON.parse(JSON.stringify(SECTIONS));
  private letterFooterSection: MjmlElementModel = JSON.parse(JSON.stringify(DEFAULT_LETTER_FOOTER_MJML_SECTION));
  private apiURL: string = localStorage.getItem('apiurl');
  private logoSrc: string = null;
  /* for header and footer */
  private facebookLink: string = '';
  private twitterLink: string = '';
  private instagramLink: string = '';
  private linkedinLink: string = '';
  private clientUrl: string = '';

  public templatePayload: TemplatePayload;

  private donationActivityDragStart: boolean = false;
  private commentWallDragStart: boolean = false;

  public showExportContainer$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public activeElementState: ActiveElementStateModel;

  constructor(
    public tms: TemplateManagementService,
    private dialog: MatDialog,
    private authService: AuthService,
    private clientModuleService: ClientModuleService,
    private clientIdStateService: ClientIdStateService,
    private clientService: ClientService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    public translate: TranslateService,
    private campaignService: CampaignService
  ) {
  }

  public ngOnInit(): void {
    this.setSubscriptions();

    this.setDefaultTemplate();

    this.subscription.add(
      this.clientIdStateService.clientIdObservable
        .pipe(
          distinctUntilChanged(),
          filter(value => !!value && value !== 'null'),
          switchMap((clientId: string) => {
            if (clientId === this.authService.getIdentityClaimsOriginId()) {
              return forkJoin([
                this.clientModuleService.clientLogo,
                this.clientModuleService.facebookLink,
                this.clientModuleService.twitterLink,
                this.clientModuleService.instagramLink,
                this.clientModuleService.linkedinLink,
                this.clientModuleService.clientUrl
              ]);
            } else {
              return this.clientService.getModel(clientId, false)
                .pipe(
                  map((client: ClientModel) => ([
                    client.avatarImg,
                    client.facebookLink,
                    client.twitterLink,
                    client.instagramLink,
                    client.linkedinLink,
                    client.url
                  ])),
                );
            }
          }),
        )
        .subscribe(([clientLogo = null, facebookLink = '', twitterLink = '', instagramLink = '', linkedinLink = '', clientUrl = '']) => {
          this.logoSrc = clientLogo;
          this.tms.defaultSmLinks = {
            [MjmlSmName.facebook]: facebookLink,
            [MjmlSmName.twitter]: twitterLink,
            [MjmlSmName.instagram]: instagramLink,
            [MjmlSmName.linkedin]: linkedinLink
          };
          this.facebookLink = facebookLink;
          this.twitterLink = twitterLink;
          this.instagramLink = instagramLink;
          this.linkedinLink = linkedinLink;
          this.clientUrl = clientUrl;
        })
    );

    this.subscription.add(
      this.tms.activeElementStateSubject
        .asObservable()
        .subscribe(el => this.activeElementState = el)
    )
  }

  public canEdit() {
    return this.activeElementState && !this.activeElementState.isValid;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.isP2PEnabled && !changes.isP2PEnabled.isFirstChange()) {
      if(this.patchAvailable) {
        this.patchTemplate();
      }
    }

    if (changes.eventHasRegistration) {
      this.eventHasRegistrationFirstChange = changes.eventHasRegistration.firstChange;
    }

    if (changes.eventForm && changes.eventForm.currentValue) {
      this.tms.templateAssignmentType = TemplateAssignmentType.Event;
      this.tms.setEventForm(changes.eventForm.currentValue);
      this.subscription.add(
        this.eventForm.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
          if (this.libraryVersion) {
            this.patchTemplate();
          } else if (!this.patchAvailable || !value.name) {
            return;
          } else {
            this.patchTemplate();
          }
        })
      );
    }

    if (changes.campaignEventHasRegistrationForm) {
      this.campaignEventHasRegistrationFirstChange = changes.campaignEventHasRegistrationForm.firstChange;
    }

    if (changes.campaignForm &&
        changes.campaignForm.currentValue) {
      this.tms.templateAssignmentType = TemplateAssignmentType.Campaign;
      this.tms.setCampaignForm(changes.campaignForm.currentValue);
      this.subscription.add(
        this.campaignForm.get('name').valueChanges.pipe(debounceTime(1000)).subscribe(value => {
          if (this.libraryVersion) {
            this.patchTemplate();
          } else if (!this.patchAvailable || !value) {
            return;
          } else {
            this.patchTemplate();
          }
        })
      );
    }

    if (changes.readOnly) {
      this.tms.setReadOnly(changes.readOnly.currentValue);
    }

    if (changes.clientPortalVersion) {
      this.tms.setClientPortalVersion(changes.clientPortalVersion.currentValue)
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private moveLetterFooterToEnd(event: CdkDragDrop<any>): void {
    const data: any[] = event.container.data;
    data.push(data.splice(data.findIndex(this.isTemplateLetterFooterSection), 1)[0]);
  }

  /** Drop cdkDrag element in cdkDropList container */
  public drop(event: CdkDragDrop<any>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }

    this.moveLetterFooterToEnd(event);

    this.availableSections = JSON.parse(JSON.stringify(SECTIONS));
    this.emitChanges(true);
  }

  /** Change position of cdkDrag element in horizontal cdkDropList container (mjml-column in mjml-section) */
  public horizontalDrop(event: CdkDragDrop<string[]>, item: MjmlElementModel[]): void {
    moveItemInArray(item, event.previousIndex, event.currentIndex);
    this.emitChanges(true);
  }

  /** Prevent adding cdkDrag element in cdkDropList container */
  public noReturnPredicate(): boolean {
    return false;
  }

  /** Drop tool element and add child into mjml-column */
  public dropItem(toolName: MjmlTag, customType?: MjmlCustomType): void {
    if (!this.toolContainer || this.readOnly || this.predesignedTemplate) {
      return;
    }
    const child: MjmlElementModel = {
      tagName: toolName,
      attributes: {padding: '10px 25px'},
      content: ''
    };
    switch (toolName) {
      case MjmlTag.button:
        child.attributes['font-family'] = 'Arial';
        switch (customType) {
          case MjmlCustomType.attachment:
            child.content = this.translate.instant('Download file');
            child.attributes['customType'] = MjmlCustomType.attachment;
            break;
          case MjmlCustomType.register:
            child.content = this.translate.instant('Register');
            child.attributes['customType'] = MjmlCustomType.register;
            child.attributes['href'] = `${window.location.origin}/registrationEvent/${this.eventId}`;
            break;
          case MjmlCustomType.donate:
            child.content = this.translate.instant('Donate');
            child.attributes['customType'] = MjmlCustomType.donate;
            const href = this.tms.templateAssignmentType === TemplateAssignmentType.Campaign
              ? `${window.location.origin}/payments/campaign/${this.campaignId}`
              : `${window.location.origin}/payments/event/${this.eventId}`;
            child.attributes['href'] = href;
            break;
          case MjmlCustomType.unsubscribe:
            child.content = this.translate.instant('Unsubscribe');
            child.attributes['customType'] = MjmlCustomType.unsubscribe;
            child.attributes['href'] = `${window.location.origin}/unsubscribe/${this.campaignForm.get('clientId').value}/[ClientDonorID]`;
            break;
          case MjmlCustomType.fundraiser:
            child.content = this.translate.instant('Create a P2P Fundraiser');
            child.attributes['customType'] = MjmlCustomType.fundraiser;
            child.attributes['href'] = this.createFundraiserLink;
            break;
          default:
            child.content = this.translate.instant('Button text');
        }
        break;
      case MjmlTag.table:
        const columns = 2;
        const rows = 2;
        child.content = this.tms.getTableHtml(rows, columns);
        child.attributes['rows'] = rows.toString();
        child.attributes['columns'] = columns.toString();
        child.attributes['font-family'] = 'Arial';
        break;
      case MjmlTag.text:
        child.attributes['font-family'] = 'Arial';
        child.attributes['line-height'] = '1.42';
        switch (customType) {
          case MjmlCustomType.video:
            child.content = this.tms.getVideoHtml();
            child.attributes['customType'] = MjmlCustomType.video;
            break;
          case MjmlCustomType.signature:
            child.attributes['customType'] = MjmlCustomType.signature;
            break;
          case MjmlCustomType.emailVideo:
            child.attributes['customType'] = MjmlCustomType.emailVideo;
            child.content = this.tms.getEmailVideoHtml();
            break;
          case MjmlCustomType.thermometer:
            if ((ZEROEVENT !== this.eventId) && this.eventForm.value.goalList.filter(list => list.type === EventGoalType.DonationRevenue)[0]) {
              const goal = this.eventForm.value.goalList.filter(list => list.type === EventGoalType.DonationRevenue)[0].value;
              if (goal >= 5000) {
                child.attributes['goal'] = goal;
                child.attributes['iteration_1'] = (Math.ceil((goal * 0.25) * 100) / 100) + '';
                child.attributes['iteration_2'] = (Math.ceil((goal * 0.50) * 100) / 100) + '';
                child.attributes['iteration_3'] = (Math.ceil((goal * 0.75) * 100) / 100) + '';
                child.attributes['showIterations'] = 'true';
              } else {
                child.attributes['goal'] = goal;
                child.attributes['iteration_1'] = 'null';
                child.attributes['iteration_2'] = 'null';
                child.attributes['iteration_3'] = 'null';
                child.attributes['showIterations'] = 'false';
              }
            }
            child.attributes['customType'] = MjmlCustomType.thermometer;
            child.attributes['uniqueId'] = Guid.create().toString();
            child.content = this.tms.getThermometer(this.eventId, child);
            break;
          case MjmlCustomType.donationActivity:
            child.attributes['customType'] = MjmlCustomType.donationActivity;
            child.content = `<div><strong style="font-family: roboto; font-size: 16px; color: rgb(68, 68, 68);">${this.translate.instant('Donation Activity')}</strong></div>`;
            break;
          case MjmlCustomType.commentWall:
            child.attributes['customType'] = MjmlCustomType.commentWall;
            child.content = `<div><strong style="font-family: roboto; font-size: 16px; color: rgb(68, 68, 68);">${this.translate.instant('Comment Wall')}</strong></div>`;
            break;
          case MjmlCustomType.shareOnSM:
            child.attributes['customType'] = MjmlCustomType.shareOnSM;
            child.attributes['eventName'] = this.eventForm.get('name').value;
            child.attributes['eventDescription'] = this.eventForm.get('description').value;
            child.content = '';
        }
        break;
      case MjmlTag.image:
        switch (customType) {
          case MjmlCustomType.chart:
            child.attributes['customType'] = MjmlCustomType.chart;
            break;
          case MjmlCustomType.logo:
            child.attributes['customType'] = MjmlCustomType.logo;
            child.attributes['src'] = this.logoSrc ? `${this.apiURL}/AzureFileStorage/image/${this.logoSrc}` : null;
            child.attributes['width'] = '100px';
            child.attributes['height'] = '100px';
            break;
        }
        break;
      case MjmlTag.sponsors:
        break;
    }
    this.toolContainer.children.push(child);
    this.emitChanges(true);
  }

  /** Mark dragover container */
  public setDropContainer(ev: DragEvent, column: MjmlElementModel, section: MjmlElementModel, tooltip): void {
    ev.preventDefault();
    this.sectionSize = section.children.length;
    if ((this.donationActivityDragStart && this.sectionSize > 2) || (this.commentWallDragStart && this.sectionSize > 2)) {
      ev.dataTransfer.dropEffect = 'none';
      tooltip.disable = false;
      tooltip.show();
      return;
    }
    ev.dataTransfer.dropEffect = 'move';
    this.toolContainer = column;
  }

  /** Remove selected container */
  public removeDropContainer(tooltip): void {
    setTimeout(() => {
      this.toolContainer = null;
      this.sectionSize = null;
    }, 1000);
    tooltip.disabled = false;
    tooltip.hide();
  }

  /** Delete Row/MJML-section from template */
  public removeSection(index: number): void {
    this.tms.deleteSection(index);
    this.emitChanges(true);
  }

  public get template(): MjmlElementModel {
    return this.tms.template;
  }

  public get templateBody(): MjmlElementModel[] {
    return this.tms.template.children[1].children[0].children;
  }

  public setActiveRow(rowElement: MjmlElementModel): void {
    this.tms.setActiveRow(rowElement);
  }

  /** Show element settings on 'focus' */
  private setSubscriptions(): void {
    this.subscription.add(
    this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (!element) {
          return;
        }
        this.currentTab = 0;
      })
    );
    this.subscription.add(
      this.tms.templateChangedEmitter.subscribe(() => {
        if (
          this.libraryVersion
          || (this.eventForm && this.eventForm.get('name') && !this.eventHasRegistrationFirstChange && this.patchAvailable)
          || (this.campaignForm && this.campaignForm.get('name') && !this.campaignEventHasRegistrationFirstChange && this.patchAvailable)
        ) {
          this.patchTemplate();
        }
      })
    );
  }

  public getRowStyles(rowElement: MjmlElementModel): any {
    const backgroundColor = rowElement.attributes['background-color'] || 'transparent';
    const backgroundRepeat = rowElement.attributes['background-repeat'] || 'repeat';
    const backgroundUrl = rowElement.attributes['background-url'] || '';
    const backgroundSize = rowElement.attributes['background-size'] || 'auto';
    const paddingBottom = rowElement.attributes['padding-bottom'] || '0px';
    const paddingLeft = rowElement.attributes['padding-left'] || '0px';
    const paddingRight = rowElement.attributes['padding-right'] || '0px';
    const paddingTop = rowElement.attributes['padding-top'] || '0px';
    return {
      'background': backgroundUrl ? `${backgroundRepeat} url(${backgroundUrl}) top center / ${backgroundSize}, ${backgroundColor}` : 'none',
      'background-color': backgroundColor,
      'padding-bottom': paddingBottom,
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      'padding-top': paddingTop,
    };
  }

  public getColumnStyles(columnElement: MjmlElementModel): any {
    const backgroundColor = columnElement.attributes['background-color'];
    const width = columnElement.attributes['width'];
    const paddingBottom = columnElement.attributes['padding-bottom'];
    const paddingLeft = columnElement.attributes['padding-left'];
    const paddingRight = columnElement.attributes['padding-right'];
    const paddingTop = columnElement.attributes['padding-top'];
    return {
      'background-color': backgroundColor ? backgroundColor : 'transparent',
      'flex-basis': width ? width : '',
      'max-width': width ? width : '100%',
      'padding-bottom': paddingBottom ? paddingBottom : '0px',
      'padding-left': paddingLeft ? paddingLeft : '0px',
      'padding-right': paddingRight ? paddingRight : '0px',
      'padding-top': paddingTop ? paddingTop : '0px',
    };
  }

  public setActiveColumn(column: MjmlElementModel): void {
    this.tms.setActiveColumn(column);
    this.showToolbar();
  }

  public showToolbar() {
    const linkToolbars: NodeList = this.templateContainerRef.nativeElement.querySelectorAll('.ql-tooltip');
    this.hideToolbars();

    const newToolbar$ = fromEvent(document, 'click')
      .pipe(
        map((e: Event) => {
          const currentRow: HTMLElement = (e.target as HTMLElement).closest('.template-list-row');
          const currentField: HTMLElement = (e.target as HTMLElement).closest('.list-row-column');

          if (currentField) {
            const currentToolbars: NodeList = currentField.querySelectorAll('.ql-toolbar, ql-snow');
            const currentToolbar: HTMLElement = this.findCurrentField(currentToolbars, (e.target as HTMLElement), currentField);

            if (currentToolbar) {
              this.alignToolbar(currentToolbar, currentRow, linkToolbars);
            }
          } else {
            this.hideToolbars();
            newToolbar$.unsubscribe();
          }
        })
      ).subscribe();
  }

  public findCurrentField(nodeList: NodeList, target: HTMLElement, field: HTMLElement): HTMLElement {
    if (nodeList.length > 1) {
      const toolbarParentField = target.closest('.tools-renderer-item');
      return toolbarParentField.querySelector('.ql-toolbar, ql-snow');
    }
    return field.querySelector('.ql-toolbar, ql-snow');
  }

  public hideToolbars(): void {
    const allToolbars: NodeList = this.templateContainerRef?.nativeElement.querySelectorAll('.ql-toolbar, ql-snow');
    if (allToolbars) {
      allToolbars.forEach(bar => {
        this.renderer.setStyle(bar, 'display', 'none');
      });
    }
  }

  public alignToolbar(toolbar: HTMLElement, row: HTMLElement, linkbars: NodeList) {
    toolbar.style.display = 'flex';
    toolbar.style.left = '0px';
    const currentRowCoords: DOMRect = row.getBoundingClientRect();
    toolbar.style.width = currentRowCoords.width + 'px';
    const currentToolbarCoords: DOMRect = toolbar.getBoundingClientRect();
    toolbar.style.top = -(currentToolbarCoords.height + 10) + 'px';

    if (currentToolbarCoords.right > currentRowCoords.right) {
      toolbar.style.left = currentRowCoords.left - currentToolbarCoords.left + 'px';
    }

    this.alignLinkbar(linkbars, currentRowCoords);
  }

  public alignLinkbar(linkbars: NodeList, rowPosition: DOMRect) {
    linkbars.forEach((bar: HTMLElement) => {
      if (!bar.classList.contains('ql-hidden')) {
        bar.style.left = '0px';
        const linkbarCoords: DOMRect = bar.getBoundingClientRect();
        if (linkbarCoords.right > rowPosition.right) {
          bar.style.left = rowPosition.right - linkbarCoords.right + 'px';
        }
      }
    })
  }

  private openLetterPreview(): void {
    this.subscription.add(
      this.letterPreviewPdf
        .subscribe((res: Blob) => {
          const config: MatDialogConfig = {
            data: {
              previewFile: res
            },
            width: '95vw',
            maxWidth: '95vw',
            height: '95vh'
          }
          this.dialog.open(PreviewComponent, config)
        })
    )
  }

  public openPreview(): void {
    if(this.tms.isLetterBuilder) {
      this.openLetterPreview();
      return;
    }

    if (this.tms.templateAssignmentType === TemplateAssignmentType.Event) {
      const config: MatDialogConfig = {
        data: {
          mjml: this.tms.template,
          templateAssignmentType: this.tms.templateAssignmentType,
          eventId: this.eventId
        },
        width: '95vw',
        maxWidth: '95vw',
        height: '95vh'
      };
      this.dialog.open(PreviewComponent, config)
    } else {
      this.subscription.add(
        this.tms.getHtmlObserver().subscribe((convertedHtml: HtmlConvertedModel) => {
          if (!convertedHtml) {
            return;
          }
          const html = UtilsComponent.updateHtmlStyle(convertedHtml.html);
          const config: MatDialogConfig = {
            data: {
              html,
              templateAssignmentType: this.tms.templateAssignmentType,
            },
            width: '95vw',
            maxWidth: '95vw',
            height: '95vh'
          };
          this.dialog.open(PreviewComponent, config)
        })
      );
    }
  }

  public get isRegisterButtonAvailable(): boolean {
    switch (this.tms.templateAssignmentType) {
      case TemplateAssignmentType.Event:
        return this.eventHasRegistration;
      case TemplateAssignmentType.Campaign:
        return this.campaignEventHasRegistrationForm;
    }
  }

  public get isCreateFundraiserButtonAvailable(): boolean {
    return this.tms.templateAssignmentType === TemplateAssignmentType.Event && this.isP2PEnabled && !!this.clientId;
  }

  public get campaignHasDonationAsk(): boolean {
    return this.campaignForm && this.campaignForm.get('hasDonationAsk') && this.campaignForm.get('hasDonationAsk').value;
  }

  public get eventHasDonationAsk(): boolean {
    return this.eventForm && this.eventForm.get('hasDonationAsk') && this.eventForm.get('hasDonationAsk').value;
  }

  private patchTemplate(): void {
    const sections: MjmlElementModel[] = this.tms.template.children[1].children[0].children;
    sections.forEach((section: MjmlElementModel) => {
      const columns: MjmlElementModel[] = section.children;
      columns.forEach((column: MjmlElementModel) => {
        const tools: MjmlElementModel[] = column.children;
        tools.forEach((tool: MjmlElementModel, toolIndex) => {
          const customType = tool.attributes['customType'];
          const tagName = tool.tagName;
          if (customType) {
            switch (customType) {
              case MjmlCustomType.EventName:
                const name = this.eventForm.get('name') && this.eventForm.get('name').value || '';
                tool.content = `<p>${name}</p>`;
                break;
              case MjmlCustomType.EventDescription:
                const description = this.eventForm.get('description') && this.eventForm.get('description').value || '';
                tool.content = `<p>${description}</p>`;
                break;
              case MjmlCustomType.EventLocation:
                const countryState: Partial<CountryModel> = this.countryStateService.countryState.getValue();
                const country = countryState ? countryState.name : 'USA';
                const territorialEntity = this.eventForm.get('territorialEntity').value;
                const address1 = this.eventForm.get('address1').value;
                const address2 = this.eventForm.get('address2').value;
                const city = this.eventForm.get('city').value;

                let state = this.eventForm.get('state').value;
                if (country === 'USA') {
                  state = state ? this.stateOptions.find(({value}: FormElementDataModel) => value === state).label : '';
                }
                let zipCode = this.eventForm.get('zip').value;
                if (country === 'USA' && zipCode && zipCode.length > 5) {
                  zipCode = zipCode.slice(0, 5) + '-' + zipCode.slice(5);
                }
                tool.content =
                  `${address1 ? '<p>' + address1 + '</p>' : ''}
                   ${address2 ? '<p>' + address2 + '</p>' : ''}
                   <p>${this.countryStateService.showCity$.getValue() && city ? city : ''}${this.countryStateService.showState$.getValue() && state ? ', ' + state : ''}${this.countryStateService.showZip$.getValue() && zipCode ? ', ' + zipCode : ''}</p>
                   <p>${country}${this.countryStateService.showTerritorialEntity$.getValue() && territorialEntity ? ', ' + territorialEntity : ''}</p>`;
                break;
              case MjmlCustomType.EventScheduler:
                const scheduler = this.eventSchedularList && this.eventSchedularList.getRawValue() || [];
                const timeZoneID = this.eventForm.get('timeZoneID') && this.eventForm.get('timeZoneID').value || null;
                let content = '';
                scheduler.forEach(({startDate, endDate, startTime, endTime}: any) => {
                  content += `<p>${startDate ? formatDate(startDate, 'MMMM dd, yyyy', 'en-US') + ' - ' : ''}
                    ${endDate ? formatDate(endDate, 'MMMM dd, yyyy', 'en-US') : ''}
                    ${startTime ? startTime : ''}
                    ${endTime ? ' - ' + endTime : ''}
                    ${timeZoneID ? ' ' + this.getShortTimeZone() : ''}</p>`;
                });
                tool.content = content;
                break;
              case MjmlCustomType.EventContacts:
                const clientName = this.eventForm.get('clientName') && this.eventForm.get('clientName').value || null;
                const clientWebSite = this.eventForm.get('clientWebSite') && this.eventForm.get('clientWebSite').value || null;
                let webSiteUrl = clientWebSite;
                if (clientWebSite && !clientWebSite.startsWith('http')) {
                  webSiteUrl = `https://${clientWebSite}`;
                }
                const contactUsEmail = this.eventForm.get('contactUsEmail') && this.eventForm.get('contactUsEmail').value || null;
                const contactUsPhone = this.eventForm.get('contactUsPhone') && this.eventForm.get('contactUsPhone').value || null;
                tool.content = `${clientName ? '<p>' + clientName + '</p>' : ''}
                  ${clientWebSite ? '<p><a href="' + webSiteUrl + '">' + clientWebSite + '</a></p>' : ''}
                  ${contactUsEmail ? '<p><a href="mailto:' + contactUsEmail + '">' + contactUsEmail + '</a></p>' : ''}
                  ${contactUsPhone ? '<p><a href="tel:+1' + contactUsPhone + '">' + '+1 ' + this.formatTelNumber(contactUsPhone) + '</a></p>' : ''}`;
                break;
              case MjmlCustomType.EventItinerary:
                const eventItineraries = this.eventItineraries && this.eventItineraries.getRawValue() || [];
                if (this.eventItineraries.valid)
                  tool.content = this.tms.getItinerariesSet(eventItineraries);
                break;
              case MjmlCustomType.EventAccessibility:
                const accessabilityText = this.eventForm.get('accessabilityText') && this.eventForm.get('accessabilityText').value || '';
                tool.content = `<p>${accessabilityText}</p>`;
                break;
              case MjmlCustomType.thermometer:
                if (this.tms.templateAssignmentType === TemplateAssignmentType.Event) {
                  if ((ZEROEVENT !== this.eventId) && this.eventForm.value.goalList.filter(list => list.type === EventGoalType.DonationRevenue)[0]) {
                    const goal = this.eventForm.value.goalList.filter(list => list.type === EventGoalType.DonationRevenue)[0].value;
                    if (goal >= 5000) {
                      tool.attributes['goal'] = goal;
                      tool.attributes['iteration_1'] = (Math.ceil((goal * 0.25) * 100) / 100) + '';
                      tool.attributes['iteration_2'] = (Math.ceil((goal * 0.50) * 100) / 100) + '';
                      tool.attributes['iteration_3'] = (Math.ceil((goal * 0.75) * 100) / 100) + '';
                      tool.attributes['showIterations'] = 'true';
                    } else {
                      tool.attributes['goal'] = goal;
                      tool.attributes['iteration_1'] = 'null';
                      tool.attributes['iteration_2'] = 'null';
                      tool.attributes['iteration_3'] = 'null';
                      tool.attributes['showIterations'] = 'false';
                    }
                  }
                  const container = document.getElementById(tool.attributes['uniqueId']);
                  if (container) {
                    const containerRect = container.getBoundingClientRect();
                    tool.attributes['contentHeight'] = containerRect.height.toString();
                  }
                  tool.content = this.tms.getThermometer(this.eventId, tool);
                  tool.attributes['eventId'] = this.eventId;
                }
                break;
              case MjmlCustomType.CampaignName:
                const campaignName = this.campaignForm.get('name') && this.campaignForm.get('name').value || '';
                tool.content = `<p>${campaignName}</p>`;
                break;
              case MjmlCustomType.logo:
                const defaultAttributesChanged = tool.attributes['default-attributes-changed'];
                if (!defaultAttributesChanged) {
                  tool.attributes['src'] = this.logoSrc ? `${this.apiURL}/AzureFileStorage/image/${this.logoSrc}` : null;
                }
                //tool.attributes['src'] = this.logoSrc ? `${this.apiURL}/AzureFileStorage/image/${this.logoSrc}` : null;
                break;
              case MjmlCustomType.donate:
                if (this.donateButtonPermission) {
                  const href = this.tms.templateAssignmentType === TemplateAssignmentType.Campaign
                    ? `${window.location.origin}/payments/campaign/${this.campaignId}`
                    : `${window.location.origin}/payments/event/${this.eventId}`;
                  tool.attributes['href'] = href;
                  tool.attributes['display'] = 'block';
                  if(
                    this.tms.templateAssignmentType === TemplateAssignmentType.Campaign &&
                    tool.attributes['background-color'] === 'transparent' &&
                    tool.attributes['color'] === 'transparent' &&
                    tool.content === ''
                  ) {
                     tool.attributes['background-color'] = '#ffc25d';
                     tool.attributes['color'] = '#ffffff';
                     tool.content = 'MAKE A DONATION'
                  }
                } else {
                  tool.attributes['href'] = '';
                  tool.attributes['display'] = 'none';
                  if(this.tms.templateAssignmentType === TemplateAssignmentType.Campaign) {
                    tool.attributes['background-color'] = 'transparent';
                    tool.attributes['color'] = 'transparent';
                    tool.content = '';
                  }

                }
                break;
              case MjmlCustomType.register:
                if (this.isRegisterButtonAvailable) {
                  tool.attributes['href'] = `${window.location.origin}/registrationEvent/${this.eventId}`;
                  tool.attributes['display'] = 'block';
                } else {
                  tool.attributes['href'] = '';
                  tool.attributes['display'] = 'none';
                }
                break;
              case MjmlCustomType.unsubscribe:
                //const clientId = this.campaignForm.get('clientId') && this.campaignForm.get('clientId').value || '';
                tool.attributes['href'] = `${window.location.origin}/unsubscribe/${this.authService.getIdentityClaimsOriginId()}/[ClientDonorID]?isAlt=[IsAlt]`;
                break;
              case MjmlCustomType.fundraiser:
                if (this.isCreateFundraiserButtonAvailable) {
                  tool.attributes['href'] = this.createFundraiserLink;
                  tool.attributes['display'] = 'block';
                } else {
                  tool.attributes['href'] = '';
                  tool.attributes['display'] = 'none';
                }
                break;
              case MjmlCustomType.shareOnSM:
                tool.attributes['eventName'] = this.eventForm.get('name').value;
                tool.attributes['eventDescription'] = this.eventForm.get('description').value;
                break;
              //todo add Placeholders
            }
          }
          if (tagName === MjmlTag.social) {
            const socialElements: MjmlElementModel[] = tool.children;
            if (socialElements) {
              socialElements.forEach((socialElement: MjmlElementModel) => {
                const defaultAttributesChanged = socialElement.attributes['default-href-attributes-changed'];
                if (!defaultAttributesChanged) {
                  const name = socialElement.attributes['name'];
                  socialElement.attributes['href'] = this.tms.defaultSmLinks[name];
                }
              });
            }
          }
        });
      });
    });
    this.headerFooterAttributes.facebookLink = this.facebookLink;
    this.headerFooterAttributes.twitterLink = this.twitterLink;
    this.headerFooterAttributes.instagramLink = this.instagramLink;
    this.headerFooterAttributes.linkedInLink = this.linkedinLink;
    this.headerFooterAttributes.clientUrl = this.clientUrl;
    this.tms.updateTemplate();
  }

  public get eventSchedularList(): FormArray {
    return this.eventForm.get('eventSchedularList') as FormArray;
  }

  public getShortTimeZone(): string {
    if (this.timeZones) {
      const timeZone = this.timeZones.find(x => x.id === Number(this.eventForm.get('timeZoneID').value));
      if (timeZone) {
        return timeZone.shortName;
      }
    }
  }

  public get eventItineraries(): FormArray {
    return this.eventForm.get('eventItineraries') as FormArray;
  }

  public get donateButtonPermission(): boolean {
    return this.tms.templateAssignmentType === TemplateAssignmentType.Campaign ? this.campaignHasDonationAsk : this.eventHasDonationAsk;
  }

  public showErrorFields(): void {
    this.currentTab = 2;
    this.cdr.markForCheck();
  }

  public showStylingErrorFields(): void {
    this.currentTab = 0;
    this.cdr.markForCheck();
  }

  public get html(): Observable<HtmlConvertedModel> {
    return this.tms.getHtmlObserver();
  }

  public setTemplate(template: MjmlElementModel): void {
    const defaultTemplate = this.libraryVersion
      ? DEFAULT_MJML_TEMPLATE : this.tms.templateAssignmentType === TemplateAssignmentType.Campaign
        ? DEFAULT_CAMPAIGN_MJML_TEMPLATE : DEFAULT_EVENT_MJML_TEMPLATE;
    this.tms.setTemplate(template ? this.removeOldStylesAndAddHeaderAndFooter(template) : defaultTemplate);
    this.emitChanges();
    setTimeout(() => this.tms.resetHistoryStorage(), 1000);
  }

  public setTemplatePayload(templatePayload: TemplatePayload): void {
    this.templatePayload = templatePayload;
  }

  public get updateTemplate(): Observable<TemplatePayload> {
    return this.tms.saveTemplateEmitter;
  }

  public triggerTemplatePayload(): void {
    this.TagsComponent.updateTemplate();
  }

  public setTemplateType(templateType: TemplateType): void {
    this.tms.setTemplateType(templateType);
  }

  public get predesignedTemplate(): boolean {
    return this.tms.templateType !== TemplateType.Layout;
  }

  public getTemplateBodyStyles(): any {
    const width = this.template.children[1].attributes['width'] || '900px';
    return {
      width
    }
  }

  public getBackgroundStyles(): any {
    const backgroundRepeat = this.template.children[1].children[0].attributes['background-repeat'] || 'repeat';
    const backgroundUrl = this.template.children[1].children[0].attributes['background-url'];
    const backgroundColor = this.template.children[1].attributes['background-color'] || 'transparent';
    const backgroundSize = this.template.children[1].children[0].attributes['background-size'] || 'auto';
    return {
      'background-repeat': backgroundRepeat,
      'background': backgroundUrl ? `${backgroundRepeat} url(${backgroundUrl}) top center / ${backgroundSize}, ${backgroundColor}` : 'none',
      'background-color': backgroundColor,
    }
  }

  public setAvailableClientPlaceholdersIds(placeholdersIds: number[]): void {
    this.tms.setAvailableClientPlaceholdersIds(placeholdersIds);
    //patch
  }

  private get letterPreviewPdf(): Observable<Blob> {
    return this.tms.getHtmlObserver()
      .pipe(
        switchMap((res: HtmlConvertedModel) => {
          return this.campaignService.letterPreview(res)
        })
      )
  }

  public exportPDF(): void {
    if(this.tms.isLetterBuilder) {
      this.subscription.add(
        this.letterPreviewPdf
          .subscribe((res: Blob) => {
            if(!res) return;
            saveAs(res, "LetterPreview.pdf");
          })
      )
      return;
    } 
    if (this.tms.templateAssignmentType === TemplateAssignmentType.Campaign) {
      this.tms.getHtmlObserver()
        .pipe(
          first(),
          switchMap(this.createTemplateImage.bind(this)),
          tap(({canvas, forExport}) => {
            this.createPDF(canvas, forExport);
            forExport.remove();
          })
        )
        .subscribe();
    } else {
      this.showExportContainer$.next(true);
      setTimeout(() => {
        const templateWidth = this.tms.template.children[1].attributes['width'];
        const container = this.exportContainer.nativeElement;
        this.renderer.setStyle(container, 'width', templateWidth);
        this.updateThermometers(container);
        this.addMockVideo(container)
          .pipe(
            first(),
            switchMap(() => this.getCanvas(container)),
            tap(({canvas, forExport}) => {
              this.createPDF(canvas, forExport);
              this.showExportContainer$.next(false);
            })
          )
          .subscribe();
      });
    }
  }

  private createPDF(canvas: any, forExport: HTMLDivElement): void {
    const contentDataURL = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'cm', 'a4'); // Generates PDF in portrait mode
    const {width, height}: ClientRect | DOMRect = forExport.getBoundingClientRect();
    let pdfWidth = 20;
    let pdfHeight = pdfWidth * height / width;
    if (pdfHeight > 28.7) {
      pdfHeight = 28.7;
      pdfWidth = width * pdfHeight / height;
    }
    pdf.addImage(contentDataURL, 'PNG', ((20 - pdfWidth) / 2) + 0.5, 0.5, pdfWidth, pdfHeight);
    pdf.save(`template_${new Date().toDateString()}`);
  }

  public exportDOCX(): void {
    if (this.tms.templateAssignmentType === TemplateAssignmentType.Campaign) {
      this.tms.getHtmlObserver()
        .pipe(
          first(),
          switchMap(this.createTemplateImage.bind(this)),
          tap(({canvas, forExport}) => {
            this.createDOCX(canvas, forExport);
            forExport.remove();
          })
        )
        .subscribe();
    } else {
      this.showExportContainer$.next(true);
      setTimeout(() => {
        const templateWidth = this.tms.template.children[1].attributes['width'];
        const container = this.exportContainer.nativeElement;
        this.renderer.setStyle(container, 'width', templateWidth);
        this.updateThermometers(container);
        this.addMockVideo(container)
          .pipe(
            first(),
            switchMap(() => this.getCanvas(container)),
            tap(({canvas, forExport}) => {
              this.createDOCX(canvas, forExport);
              this.showExportContainer$.next(false);
            })
          )
          .subscribe();
      });
    }
  }

  private createDOCX(canvas: any, forExport: HTMLDivElement): void {
    const {width, height}: ClientRect | DOMRect = forExport.getBoundingClientRect();
    let docWidth = 600;
    let docHeight = height * docWidth / width;
    if (docHeight > 936) {
      docHeight = 936;
      docWidth = width * docHeight / height;
    }
    const doc = new Document();
    doc.addSection({
      children: [new Paragraph(Media.addImage(doc, canvas.toDataURL('image/png'), docWidth, docHeight))],
    });
    Packer.toBlob(doc).then(buffer => {
      saveAs(buffer, `template_${new Date().toDateString()}.docx`);
    });
  }

  public get activeFormBlur(): Observable<FormGroup> {
    return this.tms.saveEmitter;
  }

  public setPatchAvailable(): void {
    if (this.patchAvailable) {
      return;
    }
    this.patchAvailable = true;
    this.patchTemplate();
  }

  private emitChanges(addToHistory: boolean = false): void {
    this.tms.emitSave(addToHistory);
  }

  private getCanvas(element: HTMLDivElement): Observable<{ canvas: any; forExport: HTMLDivElement }> {
    const options: Options = {
      backgroundColor: '#fff',
      removeContainer: true,
      scale: 1,
      useCORS: true,
      logging: false,
    };
    return from(html2canvas(element, options))
      .pipe(
        map((canvas: any) => ({canvas, forExport: element}))
      );
  }

  private createTemplateImage(convertedHtml: HtmlConvertedModel): Observable<{ canvas: any; forExport: HTMLDivElement }> {
    if (!convertedHtml) {
      return of(null);
    }
    const templateWidth = this.tms.template.children[1].attributes['width'];
    const forExport = document.createElement('div');
    forExport.className = 'pdf';
    forExport.style.width = templateWidth;
    forExport.innerHTML = convertedHtml.html;
    document.body.appendChild(forExport);
    return this.getCanvas(forExport);
  }

  private addMockVideo(element: HTMLDivElement): Observable<any> {
    const list = element.getElementsByTagName('video');
    if (list.length) {
      const sources = Array.from(list).map(item => {
        return fromEvent(item, 'loadeddata')
          .pipe(
            tap(() => {
              const {width, height} = item.getBoundingClientRect();
              if (item.poster) {
                const img = document.createElement('img');
                img.src = item.poster;
                this.renderer.setAttribute(img, 'width', width.toString());
                this.renderer.setAttribute(img, 'height', height.toString());
                item.parentNode.insertBefore(img, item);
                item.remove();
              } else {
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(item, 0, 0, width, height);
                item.parentNode.insertBefore(canvas, item);
                item.remove();
              }
            })
          );
      });
      return zip(...sources);
    }
    return of(null);
  }

  private updateThermometers(element: HTMLDivElement): void {
    const list = element.getElementsByClassName('app-thermometer-wrapper screenshots-element-ref');
    const elementRefList: HTMLCollection = this.templateContainerRef.nativeElement.getElementsByClassName('screenshots-element-ref');
    Array.from(list).forEach((item, index) => {
      const sourceItem = Array.from(elementRefList)[index];
      const svgWidth = sourceItem.getElementsByTagName('svg')[0].width.animVal.value;
      const svg = item.getElementsByTagName('svg');
      this.renderer.setAttribute(svg[0], 'width', svgWidth.toString());
    });
  }

  /*private addMockThermometers(element: HTMLDivElement): Observable<any> {
    const iframeList = element.getElementsByTagName('iframe');
    const elementRefList: HTMLCollection = this.templateContainerRef.nativeElement.getElementsByClassName('screenshots-element-ref');
    if (iframeList.length) {
      const sources = Array.from(iframeList).map((item, index) => {
        const sourceSubject: Subject<void> = new Subject<void>();
        const sourceItem = Array.from(elementRefList)[index];
        const {width, height} = sourceItem.getBoundingClientRect();
        const options: Options = {
          removeContainer: true,
          //scale: 1,
          backgroundColor: null,
          //useCORS: true,
          //logging: false,
          width,
          height
        };
        const svg = sourceItem.getElementsByTagName('svg');
        const svgWidth = svg[0].width.animVal.value;
        this.renderer.setAttribute(svg[0], 'width', svgWidth.toString());
        setTimeout(() => {
          html2canvas(sourceItem, options)
            .then((canvas) => {
              item.parentNode.insertBefore(canvas, item);
              item.remove();
              sourceSubject.next();
            })
            .catch(error => console.log(error));
        }, 1000);

        return sourceSubject.asObservable();
      });
      return zip(...sources);
    }
    return of(null);
  }*/

  /*have to check section size before drop donation activity component*/
  public onDonationActivityDragStart(): void {
    this.donationActivityDragStart = true;
  }

  public onDonationActivityDragEnd(): void {
    this.donationActivityDragStart = false;
  }

  public mouseEnter(tooltip: MatTooltip): void {
    tooltip.disabled = true;
  }

  public onCommentWallDragEnd(): void {
    this.commentWallDragStart = false;
  }

  /*have to check section size before drop Comment Wall component*/
  public onCommentWallDragStart(): void {
    this.commentWallDragStart = true;
  }

  public get tooltipRestrictionMessage(): string {
    if (this.donationActivityDragStart) {
      return 'Donation Activity Component could be added only either to the full block or to the 1/2 block';
    } else if (this.commentWallDragStart) {
      return 'Comment Wall Component can only be added to either the full block or to the 1/2 block';
    } else {
      return '';
    }}

  private removeOldStylesAndAddHeaderAndFooter(template: MjmlElementModel): MjmlElementModel {
    template.children[1].children[0].children.forEach(section => {
      section.children.forEach(column => {
        column.children.forEach(element => {
          if (element.tagName === MjmlTag.text && !element.attributes['customType']) {
            delete element.attributes['color'];
            delete element.attributes['font-family'];
            delete element.attributes['font-size'];
            delete element.attributes['align'];
          }
        })
      })
    });
    if (!template.children[1].children[1] && this.tms.templateAssignmentType === TemplateAssignmentType.Event) {
      template.children[1].children.push({
        tagName: MjmlTag.headerFooter,
        attributes: {},
        children: [],
        content: ''
      })
    }
    return template;
  }

  public get headerStyle(): { [key: string]: string } {
    if (!this.headerFooter) {
      return {};
    }
    const {
      background = '#023665',
      logoPosition = AlignmentType.Left,
    }: { [key: string]: string } = this.headerFooter.attributes;
    let {logoSize = HeaderSize.Medium} = this.headerFooter.attributes;
    if (logoSize === HeaderSize.Small) {
      logoSize = HeaderSize.Medium;
    }
    return {
      background,
      'justify-content': UtilsComponent.getLogoPosition(logoPosition as AlignmentType),
      'min-height':  `${UtilsComponent.getLogoSize(logoSize as HeaderSize)}px`,
    };
  }

  public get logoStyle(): { [key: string]: string } {
    if (!this.headerFooter) {
      return {};
    }
    const {
      logoSize = HeaderSize.Medium,
    }: { [key: string]: string } = this.headerFooter.attributes;
    return {
      width: 'auto',
      height: `${UtilsComponent.getLogoSize(logoSize as HeaderSize)}px`,
    };
  }

  public get logo(): string {
    if (!this.headerFooter) {
      return '/assets/images/logo.png';
    }
    const {
      logoSrc = '/assets/images/logo.png'
    }: { [key: string]: string } = this.headerFooter.attributes;
    return logoSrc;
  }

  public get footerStyle(): { [key: string]: string } {
    if (!this.headerFooter) {
      return {};
    }
    const {
      background = '#023665',
    }: { [key: string]: string } = this.headerFooter.attributes;
    return {
      background,
    };
  }

  public get headerFooterAttributes(): { [key: string]: string } {
    if (!this.headerFooter) {
      return {};
    }
    return this.headerFooter.attributes;
  }

  public activateHeaderFooter(): void {
    this.setActiveRow(this.tms.template.children[1]); // MjmlTag.body as row
    this.tms.setActiveElement(this.headerFooter); // MjmlTag.headerFooter as Active Element
    this.tms.setActiveColumn(null);
  }

  public get headerFooter(): MjmlElementModel {
    return this.tms.template.children[1].children[1];
  }

  private formatTelNumber(telNumber: string): string {
    return `(${telNumber.slice(0,3)}) ${telNumber.slice(3,6)}-${telNumber.slice(6)}`
  }

  public tabChanged(tab: number): void {
    if (tab === this.currentTab) {
      return;
    }
    this.currentTab = tab;
    this.tms.settingsTabWasChanged(tab);
  }

  public get createFundraiserLink(): string {
    return `${window.location.origin}/CreateFundraiser/client/${this.clientId}/event/${this.eventId}`;
  }

  private setDefaultTemplate(): void {
    if (!this.libraryVersion) {
      if (this.defaultTemplate === TemplateAssignmentType.Event) {
        this.setTemplate(DEFAULT_EVENT_MJML_TEMPLATE)
      } else {
        this.setTemplate(DEFAULT_CAMPAIGN_MJML_TEMPLATE);
      }
    } else if (!!this.campaignForm && !!this.campaignId) {
      this.setTemplate(UNSUBSCRIBE_CAMPAIGN_MJML_TEMPLATE);
    }
  }

  addLetterFooter(): void {
    if(!this.tms.isLetterBuilder) throw Error("To use the Footer tool, the template needs to be of type Letter");
    if(!!this.templateLetterFooterSection) return;
   
    this.templateBody.push(this.letterFooterSection);
    this.letterFooterSection = JSON.parse(JSON.stringify(DEFAULT_LETTER_FOOTER_MJML_SECTION));
    this.emitChanges(true);
  }

  get templateLetterFooterSection(): MjmlElementModel {
    return this.templateBody.find((section: MjmlElementModel) => !!Object.keys(section.attributes).find(attribute => section.attributes[attribute] === MjmlCustomType.letterFooterSection));
  }

  isTemplateLetterFooterSection(row: MjmlElementModel): boolean {
    return !!Object.keys(row.attributes).find(attribute => row.attributes[attribute] === MjmlCustomType.letterFooterSection);
  }
}
