<div class="string-spinner-wrapper">
  <div class="string-spinner-label" [style.color]="color">
    <ng-content></ng-content>
  </div>
  <div class="lds-ellipsis">
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
  </div>
</div>
