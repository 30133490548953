import { Component, Input } from '@angular/core';
import FbTemplateModel, { AlignmentType, FbElementModel } from '../../../../models/form-builder/fb.template.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-base-form',
  template: ''
})
export class BaseFormComponent {
  @Input() public element: FbElementModel;
  @Input() public paymentForm: FormGroup;
  @Input() public template: FbTemplateModel;
  @Input() public readOnly: boolean = false;

  public static getAlign(type): string {
    switch (type) {
      case AlignmentType.Left:
        return 'flex-start';
      case AlignmentType.Right:
        return 'flex-end';
      case AlignmentType.Top:
        return 'center';
    }
  }

  public static getTextAlign(type): string {
    switch (type) {
      case AlignmentType.Left:
        return 'left';
      case AlignmentType.Right:
        return 'right';
      case AlignmentType.Top:
        return 'center';
    }
  }

  public get mainLabelStyle(): { [key: string]: string } {
    const alignType = this.element.attributes['mainLabelAlignment'];
    const {fontColor = '#2C3345', fontFamily = 'Roboto', fontSize = 16} = this.template;

    return {
      'justify-content': BaseFormComponent.getAlign(alignType),
      color: fontColor,
      'font-family': fontFamily,
      'font-size': `${fontSize}px`,
      'text-align': BaseFormComponent.getTextAlign(alignType),
    }
  }

  public get doubleFormElementStyle(): { [key: string]: string } {
    const {width = 760, fontFamily = 'Roboto', fontSize = 16} = this.template;
    return {
      'max-width': `${width / 2 - 20}px`,
      'font-family': fontFamily,
    }
  }

  public get singleFormElementStyle(): { [key: string]: string } {
    const {width = 760, fontFamily = 'Roboto', fontSize = 16, fontColor = '#2C3345'} = this.template;
    return {
      'max-width': `${width}px`,
      'font-family': fontFamily,
      'font-size': `${fontSize}px`,
      color: fontColor,
    }
  }
}
