<div class="wrapper">
  <div class="content">
    <img src="/assets/images/home.png" style="width: 100%">
    <div class="content-body">
      <div class="title-sent" *ngIf="!success">
        <img src="/assets/icons/check1.svg" style="margin-right: 15px;">{{'AUTH.Password Reset' | translate}}
      </div>
      <div class="description" *ngIf="!success">
        {{'AUTH.Create a new password below' | translate}}:
      </div>
      <form [formGroup]="resetPassForm" [class.hidden]="success">
        <!--<mat-form-field appearance="outline" class="field">
          <mat-label>Password</mat-label>
          <input formControlName="password" type="password" matInput>
        </mat-form-field>-->
        <app-password-input
          label="Password"
          [formGroup]="resetPassForm"
          name="password"
          (keydownEnter)="resetPassword()"
          [low]="true"
        ></app-password-input>
        <div class="requirements" *ngIf="showRequirements">
          <div class="title">
            {{'Password Requirements' | translate}}
          </div>
          <div class="item" [class.item-good]="!newPassword.hasError('minlength') && !newPassword.hasError('required')" *ngIf="requirements?.requiredLength > 0">
            <div class="icon-bad" [class.icon-good]="!newPassword.hasError('minlength') && !newPassword.hasError('required')"></div>
            <span [innerHTML]="'AUTH.AtLeast' | translate: {value: requirements?.requiredLength}"></span>
          </div>
          <div class="item" [class.item-good]="!newPassword.hasError('oneCapitalLetter') && !newPassword.hasError('required')" *ngIf="requirements?.requireUppercase">
            <div class="icon-bad" [class.icon-good]="!newPassword.hasError('oneCapitalLetter') && !newPassword.hasError('required')"></div>{{'AUTH.One uppercase character' | translate}}
          </div>
          <div class="item" [class.item-good]="!newPassword.hasError('oneSmallLetter') && !newPassword.hasError('required')" *ngIf="requirements?.requireLowercase">
            <div class="icon-bad" [class.icon-good]="!newPassword.hasError('oneSmallLetter') && !newPassword.hasError('required')"></div>{{'AUTH.One lowercase character' | translate}}
          </div>
          <div class="item" [class.item-good]="!newPassword.hasError('oneNumber') && !newPassword.hasError('required')" *ngIf="requirements?.requireDigit">
            <div class="icon-bad" [class.icon-good]="!newPassword.hasError('oneNumber') && !newPassword.hasError('required')"></div>{{'AUTH.One numerical character' | translate}}
          </div>
          <div class="item" [class.item-good]="!newPassword.hasError('specialSymbol') && !newPassword.hasError('required')" *ngIf="requirements?.requireNonAlphanumeric">
            <div class="icon-bad" [class.icon-good]="!newPassword.hasError('specialSymbol') && !newPassword.hasError('required')"></div>{{'AUTH.One special character (@,!,*,$,%)' | translate}}
          </div>
          <div class="item" *ngIf="email.hasError('email')">
            <div class="icon-bad" [class.icon-good]="!email.hasError('email')"></div>{{'AUTH.Valid Email in address bar' | translate}}
          </div>
        </div><br>
        <!--<mat-form-field appearance="outline" class="field">
          <mat-label>Confirm Password</mat-label>
          <input formControlName="confirmPassword" type="password" matInput>
        </mat-form-field>-->
        <app-password-input
          label="Confirm Password"
          [formGroup]="resetPassForm"
          name="confirmPassword"
          (keydownEnter)="resetPassword()"
          [low]="true"
        ></app-password-input>
        <br>
        <button (click)="resetPassword()" [class.button-disabled]="resetPassForm.invalid">{{'AUTH.RESET PASSWORD' | translate}}</button>
      </form>
      <div *ngIf="success">
        <div class="title-sent">
          <img src="/assets/icons/check1.svg" style="margin-right: 15px;">{{'AUTH.Password Successfully Changed' | translate}}
        </div>
      </div>
    </div>
  </div>
</div>
