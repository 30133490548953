import { BaseModelService } from '../base.model.service';
import { FilterType } from '../../models/enum/filter.type';
import { flatMap, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Paging } from 'src/app/models/paging/paging.model';
import { PagingHelper } from '../helper/paging.helper';
import SendGridLogModel from '../../models/reports/send.grid.log.model';
import DonorTrackedEmailModel from '../../models/donor/donor-tracked-email.model';
import { TotalRowsModel } from '../../models/paging/totalrow.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseModelService<SendGridLogModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }
  protected get controllerName(): string {
    return 'Notification';
  }

  public getGroupedCount(id: string): Observable<any> {
    const model = {
      filterFields: ['CampaignID'],
      filterValues: [id],
      filterTypes: [FilterType.Equal],
      includeDependencies: true,
      includeDeleted: true
    };
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        const url = `${config.apiURL}/${this.controllerName}/GetGroupedCount`;
        return this.http.post<any[]>(url, model);
      })
    );
  }

  public getPostModelList(filter?: Paging): Observable<SendGridLogModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.post<SendGridLogModel[]>(`${config.apiURL}/${this.controllerName}/GetAll`, filter);
      })
    );
  }
}
