import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TemplateType } from '../../../../models/templates/template.type';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ThermometerBuilderComponent } from '../thermometer-builder/thermometer-builder.component';
import { ThermometerBuilderData } from '../../../../models/templates/thermometerBuilderModel';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { MjmlCustomType } from '../../../../models/templates/mjml.custom.type';

@Component({
  selector: 'app-thermometer-tool-settings',
  templateUrl: './thermometer-tool-settings.component.html',
  styleUrls: ['../tool-settings.scss']
})
export class ThermometerToolSettingsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public tms: TemplateManagementService;
  @Input() public eventId: string;
  private subscription: Subscription = new Subscription();

  public thermometerForm: FormGroup = this.formBuilder.group({
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]],
    borderColor: '#212121',
    containerFillColor: '#0064BE',
    containerGradientColor: '#000000',
    containerTextColor: '#000000',
    goal: [null, [Validators.required, Validators.min(0.01)]],
    iteration_1: [null],
    iteration_2: [null],
    iteration_3: [null],
    alignment: 'vertical',
    textPlace: 'left',
    showGoal: true,
    showDonors: true,
    showDonations: true,
    showPercent: true,
    description: ['', [Validators.required]],
    showIterations: false,
    appearance: 'thermometer',
    fontSizeGoal: '14px',
    fontSizeIterations: '14px',
    fontSizeDescription: '14px',
    resize: 1
  });

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
  ) { }

  public ngOnInit(): void {
    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (!element || element.tagName !== MjmlTag.text || element.attributes['customType'] !== MjmlCustomType.thermometer) {
          return;
        }
        this.setInitialValues();
        this.setAttributes();
      })
    );

  }

  public ngOnChanges(): void {
    this.setInitialValues()
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public setThermometer(): void {
    const thermometerData: string | number = this.tms.activeElement.attributes['thermometer-builder'];
    const data: ThermometerBuilderData = {
      formGroup: this.thermometerForm,
      thermometerDataActual: thermometerData
    };
    const config: MatDialogConfig = {
      data,
      width: '95vw',
      maxWidth: '95vw',
      height: '95vh'
    };
    const dialogRef = this.dialog.open(ThermometerBuilderComponent, config);
    this.subscription.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          return;
        }
        this.setAttributes();
        this.tms.onEditGoal(this.thermometerForm.value.goal);
        this.tms.emitSave(true);
        this.tms.addTemplate();
      })
    );
  }

  public onPaddingTopChanged(): void {
    const value = this.thermometerForm.get('paddingTop').value;
    this.tms.activeElement.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.thermometerForm.get('paddingBottom').value;
    this.tms.activeElement.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.thermometerForm.get('paddingRight').value;
    this.tms.activeElement.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.thermometerForm.get('paddingLeft').value;
    this.tms.activeElement.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onResizeChanged(): void {
    this.setAttributes();
    this.tms.emitSave(true);
  }

  private setInitialValues(): void {
    const attributes = this.tms.activeElement.attributes;
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    const borderColor = attributes['borderColor'] || '#212121';
    const fillColor = attributes['containerFillColor'] || '#0064BE';
    const containerTextColor = attributes['containerTextColor'] || '#000000';
    const containerGradientColor = attributes['containerGradientColor'] || '#000000';
    const goal = attributes['goal'] || null;
    const iteration_1 = attributes['iteration_1'] || null;
    const iteration_2 = attributes['iteration_2'] || null;
    const iteration_3 = attributes['iteration_3'] || null;
    const alignment = attributes['alignment'] || 'vertical';
    const textPlace = attributes['textPlace'] || 'left';
    const showGoal = attributes['showGoal'] || true;
    const showDonors = !attributes['showDonors'] && attributes['showDonors'] !== undefined ? attributes['showDonors'] : true;
    const showDonations = !attributes['showDonations'] && attributes['showDonations'] !== undefined ? attributes['showDonations'] : true;
    const showPercent = !attributes['showPercent'] && attributes['showPercent'] !== undefined ? attributes['showPercent'] : true;
    const description = attributes['description'] || '';
    const showIterations = attributes['showIterations'] || false;
    const appearance = attributes['appearance'] || 'thermometer';
    const fontSizeGoal = attributes['fontSizeGoal'] || '14px';
    const fontSizeIterations = attributes['fontSizeIterations'] || '14px';
    const fontSizeDescription = attributes['fontSizeDescription'] || '14px';
    const resize = attributes['resize'] || 1;

    this.thermometerForm.get('paddingBottom').setValue(paddingBottom.slice(0, -2));
    this.thermometerForm.get('paddingLeft').setValue(paddingLeft.slice(0, -2));
    this.thermometerForm.get('paddingRight').setValue(paddingRight.slice(0, -2));
    this.thermometerForm.get('paddingTop').setValue(paddingTop.slice(0, -2));
    this.thermometerForm.get('borderColor').setValue(borderColor);
    this.thermometerForm.get('containerFillColor').setValue(fillColor);
    this.thermometerForm.get('containerGradientColor').setValue(containerGradientColor);
    this.thermometerForm.get('containerTextColor').setValue(containerTextColor);
    this.thermometerForm.get('goal').setValue(goal);
    this.thermometerForm.get('iteration_1').setValue(iteration_1);
    this.thermometerForm.get('iteration_2').setValue(iteration_2);
    this.thermometerForm.get('iteration_3').setValue(iteration_3);
    this.thermometerForm.get('alignment').setValue(alignment);
    this.thermometerForm.get('textPlace').setValue(textPlace);
    this.thermometerForm.get('showGoal').setValue(showGoal);
    this.thermometerForm.get('showDonors').setValue(showDonors);
    this.thermometerForm.get('showDonations').setValue(showDonations);
    this.thermometerForm.get('showPercent').setValue(showPercent);
    this.thermometerForm.get('description').setValue(description);
    this.thermometerForm.get('showIterations').setValue(showIterations);
    this.thermometerForm.get('appearance').setValue(appearance);
    this.thermometerForm.get('fontSizeGoal').setValue(fontSizeGoal);
    this.thermometerForm.get('fontSizeIterations').setValue(fontSizeIterations);
    this.thermometerForm.get('fontSizeDescription').setValue(fontSizeDescription);
    this.thermometerForm.get('resize').setValue(resize);
  }


  private setAttributes(): void {
    const url = window.location.origin;
    const encode = encodeURI(`${url}/thermometer/${this.eventId}?padding-bottom=${this.thermometerForm.value.paddingBottom}&resize=${this.thermometerForm.value.resize}&fontSizeDescription=${this.thermometerForm.value.fontSizeDescription}&fontSizeIterations=${this.thermometerForm.value.fontSizeIterations}&fontSizeGoal=${this.thermometerForm.value.fontSizeGoal}&padding-left=${this.thermometerForm.value.paddingLeft}&padding-right=${this.thermometerForm.value.paddingRight}&padding-top=${this.thermometerForm.value.paddingTop}&borderColor=${this.thermometerForm.value.borderColor}&containerFillColor=${this.thermometerForm.value.containerFillColor}&containerTextColor=${this.thermometerForm.value.containerTextColor}&goal=${this.thermometerForm.value.goal}&iteration_1=${this.thermometerForm.value.iteration_1}&iteration_2=${this.thermometerForm.value.iteration_2}&iteration_3=${this.thermometerForm.value.iteration_3}&alignment=${this.thermometerForm.value.alignment}&textPlace=${this.thermometerForm.value.textPlace}&showGoal=${this.thermometerForm.value.showGoal}&showDonors=${this.thermometerForm.value.showDonors}&showDonations=${this.thermometerForm.value.showDonations}&showPercent=${this.thermometerForm.value.showPercent}&description=${this.thermometerForm.value.description}&showIterations=${this.thermometerForm.value.showIterations}&appearance=${this.thermometerForm.value.appearance}&containerGradientColor=${this.thermometerForm.value.containerGradientColor}`);
    this.tms.activeElement.content = `<iframe src="${encode}" style="overflow:hidden;" width="100%" onload="const width = +window.getComputedStyle(this).width.slice(0, -2); const attr = +this.getAttribute('data-attr') - 60; this.height = (width > attr ? attr : width) + 'px';" frameborder="0" align="left" data-attr="750"></iframe>`;
    this.tms.activeElement.attributes['padding-bottom'] = this.thermometerForm.value.paddingBottom + 'px';
    this.tms.activeElement.attributes['padding-left'] = this.thermometerForm.value.paddingLeft + 'px';
    this.tms.activeElement.attributes['padding-right'] = this.thermometerForm.value.paddingRight + 'px';
    this.tms.activeElement.attributes['padding-top'] = this.thermometerForm.value.paddingTop + 'px';
    this.tms.activeElement.attributes['borderColor'] = this.thermometerForm.value.borderColor;
    this.tms.activeElement.attributes['containerFillColor'] = this.thermometerForm.value.containerFillColor;
    this.tms.activeElement.attributes['containerTextColor'] = this.thermometerForm.value.containerTextColor;
    this.tms.activeElement.attributes['containerGradientColor'] = this.thermometerForm.value.containerGradientColor;
    this.tms.activeElement.attributes['goal'] = this.thermometerForm.value.goal;
    this.tms.activeElement.attributes['iteration_1'] = this.thermometerForm.value.iteration_1;
    this.tms.activeElement.attributes['iteration_2'] = this.thermometerForm.value.iteration_2;
    this.tms.activeElement.attributes['iteration_3'] = this.thermometerForm.value.iteration_3;
    this.tms.activeElement.attributes['alignment'] = this.thermometerForm.value.alignment;
    this.tms.activeElement.attributes['textPlace'] = this.thermometerForm.value.textPlace;
    this.tms.activeElement.attributes['showGoal'] = this.thermometerForm.value.showGoal;
    this.tms.activeElement.attributes['showDonors'] = this.thermometerForm.value.showDonors;
    this.tms.activeElement.attributes['showDonations'] = this.thermometerForm.value.showDonations;
    this.tms.activeElement.attributes['showPercent'] = this.thermometerForm.value.showPercent;
    this.tms.activeElement.attributes['description'] = this.thermometerForm.value.description;
    this.tms.activeElement.attributes['showIterations'] = this.thermometerForm.value.showIterations;
    this.tms.activeElement.attributes['appearance'] = this.thermometerForm.value.appearance;
    this.tms.activeElement.attributes['fontSizeGoal'] = this.thermometerForm.value.fontSizeGoal;
    this.tms.activeElement.attributes['fontSizeIterations'] = this.thermometerForm.value.fontSizeIterations;
    this.tms.activeElement.attributes['fontSizeDescription'] = this.thermometerForm.value.fontSizeDescription;
    this.tms.activeElement.attributes['resize'] = this.thermometerForm.value.resize;
  }
}
