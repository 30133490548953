<div class="fb-options-row">
  <div class="fb-row-element-full">
    <app-input
      [formGroup]="optionsForm"
      name="mainLabel"
      label="Element Text"
      [low]="true"
      (valueChanged)="onMainLabelChanged()"
      [disabled]="readOnly"
    ></app-input>
  </div>
</div>

<div class="fb-options-row">
  <div class="fb-row-element-full">
    <app-select
      [formGroup]="optionsForm"
      name="mainLabelAlignment"
      label="Label Alignment"
      [low]="true"
      (valueChanged)="onLabelAlignmentChanged()"
      [disabled]="readOnly"
      [selectOptions]="labelAlignmentOptions"
    ></app-select>
  </div>
</div>

<div class="fb-options-row">
  <app-fb-checkbox
    (clickEvent)="onIsSetAsFormDefaultChanged()"
    [value]="isSetAsFormDefault.value"
    [readOnly]="readOnly"
  >{{'BUILD.Set as form default' | translate}}</app-fb-checkbox>
</div>

<div class="fb-options-row">
  <div class="fb-options-row-subtitle">{{'BUILD.Sublabels' | translate}}</div>
</div>

<div class="fb-options-row">
  <div class="fb-row-element right-margin sublabel">{{'BUILD.First Name' | translate}}</div>

  <div class="fb-row-element">
    <app-input
      [formGroup]="optionsForm"
      name="firstName"
      [low]="true"
      (valueChanged)="onFirstNameChanged()"
      [disabled]="readOnly"
    ></app-input>
  </div>
</div>

<div class="fb-options-row">
  <div class="fb-row-element right-margin sublabel">{{'BUILD.Last Name' | translate}}</div>

  <div class="fb-row-element">
    <app-input
      [formGroup]="optionsForm"
      name="lastName"
      [low]="true"
      (valueChanged)="onLastNameChanged()"
      [disabled]="readOnly"
    ></app-input>
  </div>
</div>
