<div class="table-container">
  <table
    mat-table
    [dataSource]="tableSource"
    *ngIf="tableSource"
  >
    <ng-container
      [matColumnDef]="column"
      *ngFor="let column of tableDataColumns"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
      >{{column}}</th>
      <td
        mat-cell
        *matCellDef="let element"
        class="widget-table-cell"
        [appTooltip]="tooltip"
      >
        <div
          matTooltip="{{element[column]}}"
          #tooltip="matTooltip"
          matTooltipPosition="above"
          matTooltipClass="table-tooltip"
        ></div>
        <span>{{element[column]}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="settings">
      <th
        mat-header-cell
        *matHeaderCellDef
      ></th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <i class="fas fa-ellipsis-v"></i>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="tableColumns; sticky: true"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: tableColumns;"
      class="table-row"
      (click)="selectRow(row)"
    ></tr>

  </table>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>

</div>
