<div
  [formGroup]="formGroup"
  class="fb-radio-container"
>
  <ng-content></ng-content>
  <div class="fb-radio-controls">
    <div
      *ngFor="let item of selectOptions"
      class="fb-radio-controls-item"
      (click)="toggleOption(item)"
    >
      <i class="far fa-circle" [style.color]="borderColor" *ngIf="formControl.value !== item.value; else checkedTemplate"></i>
      <ng-template #checkedTemplate>
        <div>
          <i class="fas fa-circle" [style.color]="color"></i>
        </div>
      </ng-template>
      <span>{{item.label | translate}}</span>
    </div>
  </div>
</div>
