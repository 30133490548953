import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { FONT_SIZES, FONTS, LOGO_SIZES, BUTTON_FONT_SIZES } from 'src/app/constants';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import TagModel from 'src/app/models/templates/tag.model';
import { ClientModuleService } from '../../../../routes/clients/client.module.service';
import { ActivatedRoute } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-signup-form-settings',
  templateUrl: './signup-form-settings.component.html',
  styleUrls: ['./signup-form-settings.component.scss']
})
export class SignupFormSettingsComponent implements OnInit, OnChanges {
  @Input() fbss: any;
  @Input() config: any;
  @Input() loadingData: any;
  @Output() formChange = new EventEmitter<any>();

  public selectedTags: BehaviorSubject<TagModel[]> = new BehaviorSubject<TagModel[]>([]);
  public settingsForm: FormGroup;
  public clientId: string;
  public fontSizeOptions: FormElementDataModel[] = FONT_SIZES;
  public buttonFontSizeOptions: FormElementDataModel[] = BUTTON_FONT_SIZES;
  public logoSizeOptions: FormElementDataModel[] = LOGO_SIZES;
  public fontFamilyOptions: FormElementDataModel[] = FONTS;
  private apiURL: string = localStorage.getItem('apiurl');
  public imageURL: string = null;
  public defaultLogo: string = null;
  public SignUpLayoutStyle: FormElementDataModel[] = 
  [
    { label: 'Vertical', value: 'vertical' },
    { label: 'Horizontal', value: 'horizontal' },
  ];
  public logoPositionOptions: FormElementDataModel[] = 
  [
    { label: 'Left', value: 'left' },
    { label: 'Right', value: 'right' },
    { label: 'Center', value: 'center' },
  ];

  public SignUpLayoutColumns: FormElementDataModel[] = 
  [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
  ];

  public yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];
  public isUpdating = false;

  settings: any = {
    font: [''],
    labelFontFamily: 'Roboto',
    inputFontFamily: 'Roboto',
    buttonFontFamily: 'Roboto',
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    tagLabel: 'Tag',
    emailLabel: 'Email',
    phoneLabel: 'Phone',
    buttonText: 'SUBMIT',
    layoutStyle: 'vertical',
    layoutColumns: '2',
    buttonTextColor: '#ffffff',
    buttonColor: '#0064be',
    labelFontColor: '#2C3345',
    inputFontColor: '#000000',
    inputFontSize: '16px',
    labelFontSize: '16px',
    buttonFontSize: '16px',
    logoSize: '44px',
    logoPosition: 'left',
    logo: [null],
    showCustomOptions: false,
    isPhoneFieldRequired: false,
    showMailingAddress: false,
    areMailingAddressFieldsRequired: false,
    showTags: false,
    showLogo: true,
    showPhoneNumber: true,
    mailingCountryLabel: 'Country',
    mailingAddressLabel: 'Address',
    mailingStateLabel: 'State',
    mailingCityLabel: 'City',
    mailingZipCodeLabel: 'Zip Code',
    customText: '<div style="text-align: center;"><strong style="font-size: 30px; color: #212529; font-weight: bold;">Sign Up Today</strong></div>',
    customOptionsList: [],
  };

  constructor(private fb: FormBuilder, 
    private routes: ActivatedRoute,
    private clientModuleService: ClientModuleService) { 
      this.settingsForm = this.fb.group(this.settings);
    }

  ngOnChanges(changes: SimpleChanges) {
    
    if (changes.config && changes.config.currentValue) {
      this.isUpdating = true;
      this.updateFormWithConfig(changes.config.currentValue);
      this.isUpdating = false;
    }
  }
  
  private updateFormWithConfig(config: any) {
    if (!this.settingsForm) {
      return;
    }
      this.settingsForm.patchValue({
        labelFontFamily: config.labelFontFamilyName,
        inputFontFamily: config.inputFontFamilyName,
        buttonFontFamily: config.buttonFontFamilyName,
        firstNameLabel: config.firstNameValue,
        lastNameLabel: config.lastNameValue,
        tagLabel: config.tagLabel ?? 'Tag',
        emailLabel: config.emailValue,
        phoneLabel: config.phoneNumberValue,
        buttonText: config.buttonValue,
        layoutStyle: config.layoutStyle,
        layoutColumns: config.layoutColumns,
        buttonTextColor: config.buttonFontColor,
        buttonColor: config.buttonBackgroundColor,
        labelFontColor: config.labelFontColor,
        inputFontColor: config.inputFontColor,
        inputFontSize: config.inputFontSize,
        labelFontSize: config.labelFontSize,
        buttonFontSize: config.buttonFontSize,
        logoSize: config.logosize,
        logoPosition: config.logoPosition,
        logo: config.logoPath ?? this.defaultLogo,
        isPhoneFieldRequired: config.phoneFieldRequired,
        showMailingAddress: config.showMailingAddressFields,
        areMailingAddressFieldsRequired: config.areMailingAddressFieldsRequired,
        showTags: config.showCustomOptions,
        showLogo: config.showLogo,
        showPhoneNumber: config.showPhoneNumber,
        mailingCountryLabel: config.country,
        mailingAddressLabel: config.address,
        mailingStateLabel: config.state,
        mailingCityLabel: config.city,
        mailingZipCodeLabel: config.zipCode,
        customText: config.customTitle,
        customOptionsList: config.customOptionsList.map(tag => ({
          value: tag.id,
          label: tag.name,
        }))
      });

      if (this.config.customOptionsList) {
        const tags = this.config.customOptionsList.map((option) => {
          return { id: option.tagId, name: option.name } as TagModel;
        });
        this.selectedTags.next(tags);
      }
      this.settings = { ...this.settings, ...this.settingsForm.value };
  }

  ngOnInit(): void {
    this.clientId = this.routes.snapshot.params.clientID;
    
    this.clientModuleService.clientLogo.subscribe((clientLogo: string) => {
      const logo = clientLogo ? `${this.apiURL}/AzureFileStorage/image/${clientLogo}` : '/assets/images/auxilia-logo@1x.png';
      this.defaultLogo = logo;
      this.settingsForm.get('logo').setValue(logo);
    })

    this.settingsForm.valueChanges
    .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
    .subscribe((changes) => {
      if (!this.isUpdating) {
        this.settings = { ...this.settings, ...changes };
        this.sendData();
      }
    });    

    this.selectedTags
    .pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
    )
    .subscribe((tags) => {
      if (!this.isUpdating) {
        this.settings = { ...this.settings, selectedTags: tags };
        this.sendData();
      }
    });
  }

  sendData() {
    if (this.settingsForm.valid) {
      const signUpConfig = {
        name: 'Sign Up Settings For ' + this.clientId,
        customTitle: this.settingsForm.value.customText,
        logoPath: this.settingsForm.value.logo,
        labelFontFamilyName: this.settingsForm.value.labelFontFamily,
        labelFontSize: this.settingsForm.value.labelFontSize,
        labelFontColor: this.settingsForm.value.labelFontColor,
        inputFontFamilyName: this.settingsForm.value.inputFontFamily,
        inputFontSize: this.settingsForm.value.inputFontSize,
        inputFontColor: this.settingsForm.value.inputFontColor,
        firstNameValue: this.settingsForm.value.firstNameLabel,
        lastNameValue: this.settingsForm.value.lastNameLabel,
        tagLabel: this.settingsForm.value.tagLabel,
        emailValue: this.settingsForm.value.emailLabel,
        phoneFieldRequired: this.settingsForm.value.isPhoneFieldRequired,
        phoneNumberValue: this.settingsForm.value.phoneLabel,
        buttonFontFamilyName: this.settingsForm.value.buttonFontFamily,
        buttonFontSize: this.settingsForm.value.buttonFontSize,
        buttonFontColor: this.settingsForm.value.buttonTextColor,
        buttonBackgroundColor: this.settingsForm.value.buttonColor,
        buttonValue: this.settingsForm.value.buttonText,
        showLogo: this.settingsForm.value.showLogo,
        showPhoneNumber: this.settingsForm.value.showPhoneNumber,
        logoPosition: this.settingsForm.value.logoPosition,
        logosize: this.settingsForm.value.logoSize,
        showCustomOptions: this.settingsForm.value.showTags,
        showMailingAddressFields: this.settingsForm.value.showMailingAddress,
        areMailingAddressFieldsRequired: this.settingsForm.value.areMailingAddressFieldsRequired,
        country: this.settingsForm.value.mailingCountryLabel,
        address: this.settingsForm.value.mailingAddressLabel,
        state: this.settingsForm.value.mailingStateLabel,
        city: this.settingsForm.value.mailingCityLabel,
        zipCode: this.settingsForm.value.mailingZipCodeLabel,
        layoutStyle: this.settingsForm.value.layoutStyle,
        layoutColumns: this.settingsForm.value.layoutColumns,
        customOptionsList: this.selectedTags.getValue().map(tag => ({
          tagId: tag.id,
          name: tag.name,
        }))
      };
      this.formChange.emit({ formData: signUpConfig, isValid: true });
    }
  }

  onRadioChange(value: any, controlName: string) {
    const control = this.settingsForm.get(controlName);
    control.setValue(value);
    control.markAsDirty();
  }
  public editImage(): void {
    this.imageURL = this.settingsForm.get('logo').value;
  }
}
