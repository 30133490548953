<div class="schedule-table-container">
  <table
    mat-table
    [dataSource]="tableSource"
    *ngIf="tableSource"
  >
    <ng-container
      [matColumnDef]="column"
      *ngFor="let column of tableDataColumns"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        class="table-header"
      >
        <div class="table-header-title-wrapper">
          <span class="title">{{getColumnTitle(column) | translate}}</span>
          <span (click)="sortTable(column)" class="column-filter" *ngIf="(column === 'paymentDate' || column === 'loggedOnDate') && showFilters">
            <i class="fas fa-sort-alpha-down sort-icon" *ngIf="checkColumn(column) && getSortOrder(column) === SortOrder.Ascending"></i>
            <i class="fas fa-sort-alpha-down-alt sort-icon" *ngIf="checkColumn(column) && getSortOrder(column) === SortOrder.Descending"></i>
            <i class="fas fa-sort action-icon" *ngIf="!checkColumn(column)"></i>
          </span>
        </div>
      </th>
      <td
        mat-cell
        *matCellDef="let element, let i = index"
        class="widget-table-cell"
        [appTooltip]="tooltip"
      >
        <div
          matTooltip="{{element[column]}}"
          #tooltip="matTooltip"
          matTooltipPosition="above"
          matTooltipClass="table-tooltip"
        ></div>
        <div class="cell-data" *ngIf="column === 'status'; else textNode">
          <div [ngClass]="getStatusCssClass(element)"></div>
          <span [class.status]="column === 'status'">{{getTextNode(element, column)}}</span>
          <div class="ml-1" *ngIf="isScheduledAndPassed(element)">
            <app-button
              type="flat"
              color="#E5F0FF"
              width="130"
              (clickEvent)="initiateChargeEvent.emit(element.id)"
            >
              <span style="color: #00498A; letter-spacing: 0">Initiate Charge</span>
            </app-button>
          </div>

        </div>
        <ng-template #textNode>
          <span *ngIf="column !== 'eventDonationPublicId'">{{getTextNode(element, column)}}</span>
          <span
              *ngIf="column === 'eventDonationPublicId'"
              class="edit-link"
              (click)="goToDonationPage(element['eventDonationId'])"
          >{{getTextNode(element, column)}}</span>
          <span *ngIf="column === 'declineReason' && isDonorPortal">
            <div class="ml-1" *ngIf="isDonorPortal && shouldDisplaySkipBtn(i)">
              <app-button
                type="flat"
                color="#E5F0FF"
                width="88"
                height="34"
                (clickEvent)="skipPaymentEvent.emit()"
              >
                <span style="color: #00498A; letter-spacing: 0">{{'Skip' | translate}}</span>
              </app-button>
            </div>
          </span>
        </ng-template>

      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="tableColumns; sticky: true"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: tableColumns;"
    ></tr>

  </table>

</div>
