import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilderStateService} from '../../../form-builder.state.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {
  AlignmentType,
  AvailablePaymentMethods,
  FbElementModel,
  FbElementType,
  PassMerchantFee
} from '../../../../../models/form-builder/fb.template.model';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import {
  DEFAULT_DONATION_PAYMENT_PAGE, DonationAskOptions,
  FB_PASS_MERCHANT_FEE,
  FB_PAYMENT_METHODS,
  LABEL_ALIGN
} from '../../../../../constants';
import {PaymentServiceType} from '../../../../../models/enum/payment.service.type';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { UpdateDataService } from '../../../../../services/update.data/update.data.service';
import { TranslateService } from '@ngx-translate/core';
import {RadioComponent} from "../../../../form-elements/radio/radio.component";

export const FEE_TEXT = "Thank you for your generosity! Donation fees can really add up. By clicking this box, you agree to pay the fees for your donation transaction so 100% of your donation will go towards our cause.";

@Component({
  selector: 'app-payment-details-options',
  templateUrl: './payment-details-options.component.html',
  styleUrls: ['../../fb.page.scss']
})
export class PaymentDetailsOptionsComponent implements OnInit, OnDestroy {
  @Input() public fbss: FormBuilderStateService;
  @ViewChild('passMerchantFeeRadio') passMerchantFeeRadio:  RadioComponent;
  public optionsForm: FormGroup = this.formBuilder.group({
    mainLabel: '',
    mainLabelAlignment: null,
    paymentMethods: AvailablePaymentMethods.All,
    passMerchantFee: '',
    feeText: '',
    doesClientCoverFee: false,
    isPassingFeeDefault: true
  });

  public isSetAsFormDefault: FormControl = this.formBuilder.control(false);

  private element: FbElementModel;
  public labelAlignmentOptions: FormElementDataModel[] = LABEL_ALIGN;
  public paymentMethodsOptions: FormElementDataModel[] = FB_PAYMENT_METHODS;
  public passMerchantFeeOptions: FormElementDataModel[] = FB_PASS_MERCHANT_FEE;
  public mockYesNo: FormElementDataModel[] = [{label: 'Yes', value: ''},{label: 'No', value: ''}];
  public donorCoversFeeOptions: FormElementDataModel[] = DonationAskOptions;
  public PassMerchantFee = PassMerchantFee;
  public PaymentServiceType = PaymentServiceType;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private updateDataService: UpdateDataService,
    public translate: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.fbss.activeElementSettings$.asObservable()
        .pipe(
          filter(value => value === FbElementType.PaymentDetails),
          tap(() => {
            this.element = this.fbss.template$.getValue().paymentPage.elements
              .find(({type}: FbElementModel) => type === FbElementType.PaymentDetails);
            this.setInitialValues();
          }),
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setInitialValues(): void {
    const {
      mainLabel = 'Payment Details',
      mainLabelAlignment = AlignmentType.Top,
      paymentMethods = AvailablePaymentMethods.All,
      passMerchantFee = PassMerchantFee.Yes,
      feeText = this.translate.instant(FEE_TEXT),
      doesClientCoverFee = 'false',
      isPassingFeeDefault = 'true'
    }: {[key: string]: string} = this.element.attributes;
    this.control('mainLabel').setValue(mainLabel);
    this.control('mainLabelAlignment').setValue(mainLabelAlignment);
    this.control('paymentMethods').setValue(paymentMethods);
    this.control('doesClientCoverFee').setValue(JSON.parse(doesClientCoverFee));
    this.control('passMerchantFee').setValue(passMerchantFee);
    this.control('feeText').setValue(feeText);
    this.control('isPassingFeeDefault').setValue(JSON.parse(isPassingFeeDefault));
  }

  public control(controlName: string): FormControl {
    return this.optionsForm.get(controlName) as FormControl;
  }

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }

  public onControlValueChanged(controlName: string): void {
    let value = this.control(controlName).value;
    if (controlName === 'feeText' && !value) {
      value = this.translate.instant(FEE_TEXT);
    }
    if(controlName === 'doesClientCoverFee' && !!value) {
      this.control('isPassingFeeDefault').setValue(PassMerchantFee.No);
    }
    this.element.attributes[controlName] = value;
    controlName === 'paymentMethods' && this.fbss.updatePaymentMethod$.next(value);
    this.fbss.templateWasChanged();
  }

  public changeFeePassing(): void {
    const next = !this.control('isPassingFeeDefault').value;
    this.updateDataService.isIncludeFeeByDefault$.next(next);
    this.control('isPassingFeeDefault').setValue(next);
    this.element.attributes['isPassingFeeDefault'] = JSON.stringify(next);
    this.fbss.templateWasChanged();
  }

  public onLabelAlignmentChanged(): void {
    this.element.attributes.mainLabelAlignment = this.control('mainLabelAlignment').value;
    this.isSetAsFormDefault.value && this.setAsFormDefault();
    this.fbss.templateWasChanged();
  }

  public onIsSetAsFormDefaultChanged(): void {
    const next = !this.isSetAsFormDefault.value;
    this.isSetAsFormDefault.setValue(next);
    if (next) {
      this.setAsFormDefault();
      this.fbss.templateWasChanged();
    }
  }

  public setAsFormDefault(): void {
    this.fbss.setAsFormDefault(this.control('mainLabelAlignment').value);
  }

}
