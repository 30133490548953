import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../services/auth.service';
import { TemplateAssignmentType } from '../../../../models/templates/template.assignment.type';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements AfterViewInit, OnInit, OnDestroy {
  public currentTab: number = 0;
  private subscriptions: Subscription = new Subscription();

  public TemplateAssignmentType = TemplateAssignmentType;

  src: SafeResourceUrl;

  @ViewChild('iframeDesktopRef') private iframeDesktopRef: ElementRef;
  @ViewChild('iframeMobileRef') private iframeMobileRef: ElementRef;
  @ViewChild('iframePrintRef') private iframePrintRef: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<PreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private sanitizer: DomSanitizer
  ) { }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngAfterViewInit(): void {
    if(!this.data.previewFile) {
      this.setDesktopIframe();
    } else {
      this.setPrintIframe();
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  public onTabChanged(tab: number): void {
    this.currentTab = tab;
    setTimeout(() => {
      if (tab === 0) {
        this.setDesktopIframe();
      } else {
        this.setMobileIframe();
      }
    }, 500);
  }

  private setDesktopIframe(): void {
    if (this.data.templateAssignmentType === TemplateAssignmentType.Event) {
      return;
    }
    this.iframeDesktopRef.nativeElement.contentWindow.document.open();
    this.iframeDesktopRef.nativeElement.contentWindow.document.write(this.data.html);
    this.iframeDesktopRef.nativeElement.contentWindow.document.close();
  }

  private setMobileIframe(): void {
    if (this.data.templateAssignmentType === TemplateAssignmentType.Event) {
      return;
    }
    this.iframeMobileRef.nativeElement.contentWindow.document.open();
    this.iframeMobileRef.nativeElement.contentWindow.document.write(this.data.html);
    this.iframeMobileRef.nativeElement.contentWindow.document.close();
  }

  private setPrintIframe(): void {
    if(!this.data.previewFile) return;
    this.src = null;
    const url = URL.createObjectURL(this.data.previewFile);

    setTimeout(() => {
      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(`${url}#toolbar=0&view=FitH`);
    })
  }

}
