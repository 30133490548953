
export interface P2pFundraiserModel
{
  id?: string,
  parentID?: string,
  p2PID?: string
  donorID: string,
  clientID: string,
  donorName: string,
  createdOn: Date,
  createdByID: string,
  createdByUserName: string,
  pageType?: number,
  status?: P2PStatus,
  goal: number,
  p2PName: string,
  customURL: string,
  description?: string,
  template?: string,
  token: string,
  hasToken?:boolean
}
export enum P2PStatus {
  unknown = 0,
  draft = 1,
  waitingForApproval = 2,
  approved = 3,
  rejected = 4,
  disabled = 5,
  live = 6
}
