<div class="fc-c" (click)="$event.stopPropagation()">
  <app-button-toggle *ngIf="toggleOptions.length > 0" (changeEvent)="onChangeFilterType($event)" class="toggle-container" [optionsList]="toggleOptions"></app-button-toggle>
  <input *ngIf="!isVisible; else withAddButton" type="search" class="input" placeholder="{{('Search ...') | translate}}"
         [formControl]="searchText"
         (input)="search()">
  <ng-template #withAddButton>
    <div class="filtered-listbox_custom-value-container">
    <input type="search" class="input" placeholder="{{'Search ...' | translate}}" [formControl]="searchText"
           (input)="search()">
    <app-button label="Add new"
                type="flat"
                color="#0064be"
                class="filtered-listbox_custom-value-container_button"
                [disabled]="!isCanActivate"
                (clickEvent)="openModal()"></app-button>
    </div>
  </ng-template>

  <div class="letters">
    <div (click)="searchLetter('')" class="all-div" [class.div-active]="searchText.value===''">{{'All' | translate}}</div>
    <div (click)="searchLetter('A')" [class.div-active]="searchText.value==='A'">A</div>
    <div (click)="searchLetter('B')" [class.div-active]="searchText.value==='B'">B</div>
    <div (click)="searchLetter('C')" [class.div-active]="searchText.value==='C'">C</div>
    <div (click)="searchLetter('D')" [class.div-active]="searchText.value==='D'">D</div>
    <div (click)="searchLetter('E')" [class.div-active]="searchText.value==='E'">E</div>
    <div (click)="searchLetter('F')" [class.div-active]="searchText.value==='F'">F</div>
    <div (click)="searchLetter('G')" [class.div-active]="searchText.value==='G'">G</div>
    <div (click)="searchLetter('H')" [class.div-active]="searchText.value==='H'">H</div>
    <div (click)="searchLetter('I')" [class.div-active]="searchText.value==='I'">I</div>
    <div (click)="searchLetter('J')" [class.div-active]="searchText.value==='J'">J</div>
    <div (click)="searchLetter('K')" [class.div-active]="searchText.value==='K'">K</div>
    <div (click)="searchLetter('L')" [class.div-active]="searchText.value==='L'">L</div>
    <div (click)="searchLetter('M')" [class.div-active]="searchText.value==='M'">M</div>
    <div (click)="searchLetter('N')" [class.div-active]="searchText.value==='N'">N</div>
    <div (click)="searchLetter('O')" [class.div-active]="searchText.value==='O'">O</div>
    <div (click)="searchLetter('P')" [class.div-active]="searchText.value==='P'">P</div>
    <div (click)="searchLetter('Q')" [class.div-active]="searchText.value==='Q'">Q</div>
    <div (click)="searchLetter('R')" [class.div-active]="searchText.value==='R'">R</div>
    <div (click)="searchLetter('S')" [class.div-active]="searchText.value==='S'">S</div>
    <div (click)="searchLetter('T')" [class.div-active]="searchText.value==='T'">T</div>
    <div (click)="searchLetter('U')" [class.div-active]="searchText.value==='U'">U</div>
    <div (click)="searchLetter('V')" [class.div-active]="searchText.value==='V'">V</div>
    <div (click)="searchLetter('W')" [class.div-active]="searchText.value==='W'">W</div>
    <div (click)="searchLetter('X')" [class.div-active]="searchText.value==='X'">X</div>
    <div (click)="searchLetter('Y')" [class.div-active]="searchText.value==='Y'">Y</div>
    <div (click)="searchLetter('Z')" [class.div-active]="searchText.value==='Z'">Z</div>
  </div>
  <!--<div class="letters">
    <div (click)="searchLetter('0')" [class.div-active]="searchText.value==='0'">0</div>
    <div (click)="searchLetter('1')" [class.div-active]="searchText.value==='1'">1</div>
    <div (click)="searchLetter('2')" [class.div-active]="searchText.value==='2'">2</div>
    <div (click)="searchLetter('3')" [class.div-active]="searchText.value==='3'">3</div>
    <div (click)="searchLetter('4')" [class.div-active]="searchText.value==='4'">4</div>
    <div (click)="searchLetter('5')" [class.div-active]="searchText.value==='5'">5</div>
    <div (click)="searchLetter('6')" [class.div-active]="searchText.value==='6'">6</div>
    <div (click)="searchLetter('7')" [class.div-active]="searchText.value==='7'">7</div>
    <div (click)="searchLetter('8')" [class.div-active]="searchText.value==='8'">8</div>
    <div (click)="searchLetter('9')" [class.div-active]="searchText.value==='9'">9</div>
  </div>-->
  <div
    *ngIf="!multiselectable; else multiselectableTemplate"
    class="result"
  >
    <span *ngIf="!isDataLoading">
      <div
        (click)="selectItem(item)"
        [class.div-active]="singleSelection.isSelected(item)"
        *ngFor="let item of filtered"
        [hidden]="item.hidden"
      >{{item.label}}</div>
    </span>
  </div>

  <ng-template #multiselectableTemplate>
    <div class="result" *ngIf="!isDataLoading">
      <div
        (click)="toggleItem(item)"
        [class.div-active]="multiSelection.isSelected(item)"
        *ngFor="let item of filtered"
        [hidden]="item.hidden"
      >
        <i class="far fa-square" *ngIf="!multiSelection.isSelected(item)"></i>
        <i class="fas fa-check-square" *ngIf="multiSelection.isSelected(item)"></i>
        <span *ngIf="isPhones; else commonTemplate">{{item.label | mask: '+0 (000) 000-0000'}}</span>
        <ng-template #commonTemplate>
          <span *ngIf="enableTranslation">{{item.label | translate}}</span>
          <span *ngIf="!enableTranslation">{{item.label}}</span>
        </ng-template>
      </div>
    </div>
  </ng-template>

  <div *ngIf="isDataLoading" class="lazy-spinner">
    <app-spinner></app-spinner>
  </div>

  <div *ngIf="isFilterButtonsVisible" class="buttons">
    <app-button
      label="Apply Filters"
      type="basic"
      color="#0064be"
      (clickEvent)="applyFilters()"
    ></app-button>
    <app-button
      label="Clear All"
      type="basic"
      color="#0064be"
      (clickEvent)="clearAll()"
    ></app-button>
  </div>
</div>
