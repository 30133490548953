import { AfterViewInit, Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-embed-payment',
  templateUrl: './embed-payment.component.html',
  styleUrls: ['./embed-payment.component.scss']
})
export class EmbedPaymentComponent implements AfterViewInit {

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
    this.removeHubSpotScript();
  }

  public ngAfterViewInit(): void {
    this.removeHubSpotScript();
  }

  private removeHubSpotScript(): void {
    const hubSpotScript = this.document.getElementById('hs-script-loader');
    const hubSpotContainer = this.document.getElementById('hubspot-messages-iframe-container');
    hubSpotScript && hubSpotScript.remove();
    hubSpotContainer && hubSpotContainer.remove();
  }
}
