import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormElementComponent } from '../../../../form-elements/base.form.element.component';

@Component({
  selector: 'app-fb-textarea',
  templateUrl: './fb-textarea.component.html',
  styleUrls: ['../base.form.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FbTextareaComponent extends BaseFormElementComponent implements OnChanges, AfterViewInit {
  @Input() public color: string;
  @Input() public fontFamily: string;
  @Input() public inputBackground: string;
  @Input() public fontSize: number;
  @Input()
  public minRows: number = 1;
  @Input()
  public maxRows: number = 5;
  @Input()
  public maxlengthText: number = 999999;
  @ViewChild('divElement') public container: ElementRef;
  constructor(
    private renderer: Renderer2,
    public translate: TranslateService
  ) {
    super(translate);
  }

  public get labelStyle(): {[key: string]: string} {
    const fontSize = this.fontSize ? `${+this.fontSize * 0.75}px` : '12px';
    return {
      color: this.color || '#2C3345',
      fontFamily: this.fontFamily || 'Roboto',
      fontSize,
    };
  }

  public ngAfterViewInit(): void {
    this.setColor();
  }

  private setColor(): void {
    const target: HTMLCollection = this.container.nativeElement.getElementsByClassName('mat-form-field-wrapper');
    target && target[0] && this.renderer.setStyle(target[0], 'background', this.inputBackground || '#fff');
  }
}
