import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import TemplateModel from 'src/app/models/templates/template.model';
import { MatTableDataSource } from '@angular/material/table';
import { formatDate } from '@angular/common';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import { SortOrder } from 'src/app/models/enum/sort.order';
import { SortValues } from 'src/app/models/paging/paging.model';

@Component({
  selector: 'app-admin-templates-table',
  templateUrl: './admin-templates-table.component.html',
  styleUrls: ['./admin-templates-table.component.scss']
})
export class AdminTemplatesTableComponent implements OnInit, OnChanges {
  @Input()
  public data: TemplateModel[] = [];
  @Input()
  public tableDataColumns: string[] = [];
  @Input() public fieldToCopy = '';
  @Output()
  public passInfo: EventEmitter<TemplateModel> = new EventEmitter<
    TemplateModel
  >();
  @Output()
  public sortOutput: EventEmitter<SortValues> = new EventEmitter<SortValues>();
  @Output()
  public tagName: EventEmitter<string> = new EventEmitter<string>();

  public tableColumns: string[] = [];
  public tableSource: MatTableDataSource<TemplateModel>;
  public sortingDirectionOptions: FormElementDataModel[] = [
    { label: 'Ascending', value: 0 },
    { label: 'Descending', value: 1 }
  ];
  constructor() {}

  ngOnInit() {}

  // public getServiceStatus(val): boolean {
  //   return false;
  // }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableDataColumns) {
      this.tableColumns = [...this.tableDataColumns];
    }
    if (changes.data && this.data) {
      this.tableSource = new MatTableDataSource<TemplateModel>(this.data);
    }
  }

  public onTagNameSearch(name: string): void {
      this.tagName.emit(name);
  }

  public getColumnTitle(column: string): string {
    const columnTitle = {
      createdOn: 'Creation Date',
      tagName: 'Tag Title',
      usageVolume: '# of Times Used',
      lastUsedDate: 'Last Used',
      lastUsedByName: 'Last Used By',
      default: column
    };
    return columnTitle[column] || columnTitle['default'];
  }

  public showSorting(column: string): boolean {
    const columnTitle = {
      createdOn: 'Creation Date',
      lastUsedDate: 'Last Used',
      lastUsedByName: 'Last Used By',
      default: column
    };
    return columnTitle[column] || false;
  }

  // private getDateWithOffset(date: Date): Date {
  //   return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  // }

  public getTextNode(element, column): string {
    switch (column) {
      case 'createdOn':
        return element[column]
          ? formatDate(new Date(element[column]), 'mediumDate', 'en-US')
          : '';
      case 'lastUsedDate':
        return element[column] ? this.getLastUsedDate(element[column]) : '';
      // case 'templateType':
      //   const templateType = {
      //     [TemplateType.ComponentBased]: 'Component Based',
      //     [TemplateType.FinishedTemplate]: 'Finished Template',
      //     [TemplateType.Layout]: 'Layout',
      //     [TemplateType.Themed]: 'Themed'
      //   };
      // return templateType[element.templateType];
      default:
        return element[column];
    }
  }

  // public navigateTo(val: TemplateModel): void {
  //   this.passInfo.emit(val);
  // }

  // public isChecked(element, column): boolean {
  //   return element[column];
  // }

  public sortChange(sortOrder: SortOrder, column: string): void {
    this.sortOutput.emit({ sortOrder: sortOrder, sortField: column });
  }

  private getLastUsedDate(tagDate: Date): string {
    let diffInMilliSeconds =
      Math.abs(new Date(tagDate).getTime() - new Date().getTime()) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    //console.log('calculated days', days);

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    //console.log('calculated hours', hours);

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    //console.log('minutes', minutes);

    let difference = '';
    if (days > 0) {
      difference += days === 1 ? `${days} day, ` : `${days} days, `;
    }

    difference +=
      hours === 0 || hours === 1 ? `${hours} hour, ` : `${hours} hours, `;

    difference +=
      minutes === 0 || hours === 1
        ? `${minutes} minutes`
        : `${minutes} minutes`;

    return difference;
  }
}
