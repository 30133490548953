import { AuthService } from 'src/app/services/auth.service';
import { BeforeUnloadComponent } from '../../../components/before-unload/before-unload.component';
import {
  bufferCount,
  debounceTime,
  distinctUntilChanged,
  filter, first,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { ChooseOptionComponent } from 'src/app/components/choose-option/choose-option.component';
import { ClientIdStateService } from '../../../services/client.module.state/client.id.state.service';
import { ClientModel } from 'src/app/models/client/client.model';
import { ClientModuleService } from '../../clients/client.module.service';
import { ClientService } from 'src/app/services/client/client.service';
import { DialogBeforeunloadResponseType } from '../../../models/enum/dialog.beforeunload.response.type';
import { Filter } from 'src/app/models/paging/filter.model';
import { FilterType } from 'src/app/models/enum/filter.type';
import { forkJoin, Observable, of, Subject, Subscription, zip } from 'rxjs';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { FormsTemplateSelectComponent } from 'src/app/components/forms-template-select/forms-template-select.component';
import { MatDialog } from '@angular/material/dialog';
import { Paging, SortValues } from 'src/app/models/paging/paging.model';
import { Router } from '@angular/router';
import { SortOrder } from 'src/app/models/enum/sort.order';
import { TabSourceObj } from 'src/app/constants';
import { TemplateClientRestrictionModel } from 'src/app/models/templates/template.client.restriction.model';
import { TemplateClientRestrictionService } from 'src/app/services/templates/template.client.restriction.service';
import { TemplateCreationType } from 'src/app/models/templates/template.creation.type';
import { TemplateModelService } from 'src/app/services/templates/template.model.service';
import { TemplateStatisticService } from 'src/app/services/templates/template.statistic.service';
import { TemplateStatisticType } from 'src/app/models/templates/template.statistic.type';
import { TemplateUsageType } from 'src/app/models/templates/template.usage.type';
import { ToastrService } from 'ngx-toastr';
import { UserRolesType } from 'src/app/models/enum/user.roles.type';
import { UtilsComponent } from '../../../components/utils.component';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import MjmlElementModel from '../../../models/templates/mjml.element.model';
import TemplateModel from 'src/app/models/templates/template.model';
import TemplateStatistic from 'src/app/models/templates/template.statistic.model';
import { PaymentServiceType } from '../../../models/enum/payment.service.type';
import { MerchantService } from '../../../services/payments/merchant.service';
import { MerchantModel } from '../../../models/payments/merchant.model';
import { CustomDonationFormsService } from '../../../services/donation-forms/custom.donation.forms.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-templates',
  templateUrl: './admin-templates.component.html',
  styleUrls: ['./admin-templates.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminTemplatesComponent implements OnInit, OnDestroy {
  public eventTemplates$: Observable<TemplateModel[]>;
  public draftTemplatesTable: TemplateModel[] = [];
  private subscription: Subscription = new Subscription();
  public searchTemplates: Subject<string> = new Subject<string>();
  public currentTab: number = 0;
  public allTemplatesEntriesPerPage: number = 10;
  public nameFilter: Filter[] = [];
  public templateTypeFilter: Filter[] = [];
  public dateFilter: Filter[] = [];
  public templatesStatisticTotal: number = 0;
  public currentTemplatesStatisticPage: number = 1;
  public templatesTagsEntriesPerPage: number = 10;
  private setStatisticDefaultPage: Subject<number> = new Subject<number>();
  public setStatisticDefaultPage$: Observable<number> = this.setStatisticDefaultPage.asObservable();
  private templateModelForm: { [key: string]: any } | TemplateModel = {
    id: null,
    name: null,
    description: null,
    templateType: null,
    content: null,
    contentHTML: null,
    templateStatisticsID: null,
    parentID: null,
    layoutID: null,
    libraryID: null,
    clientID: null,
    imagePreviewURL: null,
    metadata: null,
    generalTemplateType: null,
    title: null,
    templateCreationType: null,
    templateStatus: null
  };

  public templatesEventForm: FormArray = new FormArray([this.fb.group(this.templateModelForm)]);
  public templatesCampaignForm: FormArray = new FormArray([this.fb.group(this.templateModelForm)]);
  public templatesSocialMediaForm: FormArray = new FormArray([this.fb.group(this.templateModelForm)]);
  public templatesFormsForm: FormArray = new FormArray([this.fb.group(this.templateModelForm)]);
  public templatesLettersForm: FormArray = new FormArray([this.fb.group(this.templateModelForm)]);
  public isShare: boolean;

  public columnNames: string[] = [
    'createdOn',
    'tagName',
    'usageVolume',
    'lastUsedDate',
    'lastUsedByName'
  ];
  selectedTemplates: TemplateModel[] = [];
  arrCounts: string[] = [];
  clients: ClientModel[] = [];
  public allClients: ClientModel[] = [];
  private searchClients: Subject<string> = new Subject<string>();
  private searchStatistic: Subject<string> = new Subject<string>();
  private tabSourceObj = TabSourceObj;
  public campaignTemplates$: Observable<TemplateModel[]>;
  public isClientPortal: boolean;
  private selectedEventsTemplatesCount: number;
  private url: string;
  public isAdminPortal: boolean;
  public tamplateStatistic: TemplateStatistic[] = [];
  private selectedCampaignsTemplatesCount: number = 0;
  public templateTypeOptions: FormElementDataModel[] = [
    {
      label: 'All Templates',
      value: 'all'
    },
    {
      label: 'Campaign',
      value: TemplateUsageType.Campaign
    },
    {
      label: 'Event',
      value: TemplateUsageType.Event
    },
    {
      label: 'Social Media',
      value: TemplateUsageType.SocialMedia
    },
    {
      label: 'Donation Form',
      value: TemplateUsageType.DonationForms
    },
    {
      label: 'Letter',
      value: TemplateUsageType.Letter
    }
  ];

  public templateTypeViews = {
    [TemplateUsageType.Event]: 'Event',
    [TemplateUsageType.Campaign]: 'Campaign',
    [TemplateUsageType.EmailSignature]: 'Email Signature',
    [TemplateUsageType.SocialMedia]: 'Social Media',
    [TemplateUsageType.DonationForms]: 'Donation Form',
    [TemplateUsageType.Letter]: 'Letter'
  };

  public selectLayoutForm: FormGroup = this.formBuilder.group({
    templateType: 'all',
  });
  public clientsWithRelationships: FormElementDataModel[] = [];

  public isDelete: boolean;
  public savedTemplates$: Observable<TemplateModel[]>;
  private eventTemplatesIds: string[] = [];
  private campaignTemplatesIds: string[] = [];
  private userID: string;
  private userName: string;
  private sortStatisticField: string = 'CreatedOn';
  private sortStatisticOrder: SortOrder = SortOrder.Descending;
  public showUsedArrow: boolean;
  private countUsed: number = 0;

  private checkTabSubject: Subject<void> = new Subject<void>();

  public entriesPerPageSettings: number[] = [6, 12, 36];
  public eventCampaignEntriesPerPageSettings: number[] = [5, 10, 25];
  public savedTemplatesTotal: number = 0;
  private setSavedTemplatesDefaultPage: Subject<number> = new Subject<number>();
  public setSavedTemplatesDefaultPage$: Observable<number> = this.setSavedTemplatesDefaultPage.asObservable();
  public savedTemplatesEntriesPerPage: number = 6;
  public currentSavedTemplatesPage: number = 1;

  public eventTemplatesTotal: number = 0;
  private setEventTemplatesDefaultPage: Subject<number> = new Subject<number>();
  public setEventTemplatesDefaultPage$: Observable<number> = this.setEventTemplatesDefaultPage.asObservable();
  public eventTemplatesEntriesPerPage: number = 5;
  public currentEventTemplatesPage: number = 1;

  public campaignTemplatesTotal: number = 0;
  private setCampaignTemplatesDefaultPage: Subject<number> = new Subject<number>();
  public setCampaignTemplatesDefaultPage$: Observable<number> = this.setCampaignTemplatesDefaultPage.asObservable();
  public campaignTemplatesEntriesPerPage: number = 5;
  public currentCampaignTemplatesPage: number = 1;

  public socialMediaTemplates$: Observable<TemplateModel[]>;
  private socialMediaTemplatesIds: string[] = [];
  public socialMediaTemplatesTotal: number = 0;
  private setSocialMediaTemplatesDefaultPage: Subject<number> = new Subject<number>();
  public setSocialMediaTemplatesDefaultPage$: Observable<number> = this.setSocialMediaTemplatesDefaultPage.asObservable();
  public socialMediaTemplatesEntriesPerPage: number = 5;
  public currentSocialMediaTemplatesPage: number = 1;
  private selectedSocialMediaTemplatesCount: number = 0;

  public donationFormTemplates$: Observable<TemplateModel[]>;
  private donationFormTemplatesIds: string[] = [];
  public donationFormTemplatesTotal: number = 0;
  private setDonationFormTemplatesDefaultPage: Subject<number> = new Subject<number>();
  public setDonationFormTemplatesDefaultPage$: Observable<number> = this.setDonationFormTemplatesDefaultPage.asObservable();
  public donationFormTemplatesEntriesPerPage: number = 5;
  public currentDonationFormTemplatesPage: number = 1;
  private selectedDonationFormTemplatesCount: number = 0;

  public letterTemplates$: Observable<TemplateModel[]>;
  private letterTemplatesIds: string[] = [];
  public letterTemplatesTotal: number = 0;
  private setLetterTemplatesDefaultPage: Subject<number> = new Subject<number>();
  public setLetterTemplatesDefaultPage$: Observable<number> = this.setLetterTemplatesDefaultPage.asObservable();
  public letterTemplatesEntriesPerPage: number = 5;
  public currentLetterTemplatesPage: number = 1;
  private selectedLetterTemplatesCount: number = 0;
  public isMerchantInfoLoading = true;
  public hasMerchantAccount: boolean = false;
  private client: ClientModel;

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private clientIdStateService: ClientIdStateService,
    private clientModuleService: ClientModuleService,
    private clientService: ClientService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private router: Router,
    private templateModelService: TemplateModelService,
    private templateRestrictionService: TemplateClientRestrictionService,
    private templateStatisticService: TemplateStatisticService,
    private toastr: ToastrService,
    private merchantService: MerchantService,
    private customDonationFormsService: CustomDonationFormsService,
    private translate: TranslateService
  ) {
  }

  public ngOnInit(): void {
    this.setRolesAndUrl();
    if (this.isClientPortal) {
      this.subscription.add(
        zip(this.clientModuleService.clientsWithRelationships, this.clientModuleService.clientName)
          .subscribe(([clientsWithRelationships, clientName]) => {
            this.clientsWithRelationships = [{
              label: clientName,
              value: this.authService.getIdentityClaimsOriginId()
            }, ...clientsWithRelationships];
          })
      );
    }
    this.searchByTemplateNameSubscription();

    this.getMerchantAccount(() => {
      this.isMerchantInfoLoading = false;
      this.getSavedTemplates([]);
      this.getSavedTemplatesTotal([]);
      this.getRestrictions();
    });

    this.getEventTemplates([]);
    this.getEventTemplatesTotal([]);
    this.getCampaignTemplates([]);
    this.getCampaignTemplatesTotal([]);

    this.getSocialMediaTemplates([]);
    this.getSocialMediaTemplatesTotal([]);

    this.getDonationFormsTemplates([]);
    this.getDonationFormsTemplatesTotal([]);

    this.getLetterTemplates([]);
    this.getLetterTemplatesTotal([])

    this.getTagTemplatesStatistic([]);
    this.getTotalTemplatesStatistic([]);
    this.getClients();
    this.searchClientsSubscription();
    this.searchStatisticSubscription();
    this.selectTemplates();

    const bufferSize = this.isClientPortal ? 5 : 4;
    this.subscription.add(
      this.checkTabSubject.asObservable()
        .pipe(
          bufferCount(bufferSize)
        )
        .subscribe(this.changeTabOnResponse.bind(this))
    );
  }

  private getMerchantAccount(callback: () => void): void {
    if (this.isAdminPortal) {
      this.isMerchantInfoLoading = false;
      return;
    }
    this.clientIdStateService.clientIdObservable
      .pipe(
        first(),
        switchMap((clientID: string) => {
          if (clientID === this.authService.getIdentityClaimsId()) {
            return this.clientModuleService.client;
          } else {
            return this.clientService.getModel(clientID, true);
          }
        }),
        switchMap((client: ClientModel) => {
          this.client = client;
          return this.merchantService.getModelByClientId(client.id)
            .pipe(
              tap((merchantModel: MerchantModel) => {
                this.hasMerchantAccount = UtilsComponent.hasMerchantAccount(client.paymentServiceType, merchantModel);
              })
            );
        })
      )
      .subscribe(callback.bind(this));
  }

  public changeType(type: TemplateUsageType | string): void {
    this.templateTypeFilter = type !== 'all'
      ? [
        {
          field: 'templateUsageType',
          value: type.toString(),
          type: FilterType.Equal
        }
      ]
      : [];
    this.resetEventTemplates();
    this.resetCampaignTemplates();
    this.resetSocialMediaTemplates();
    this.resetDonationFormsTemplates();
    this.resetLetterTemplates();

    this.getAdminClientTemplatesSeparate([...this.nameFilter, ...this.templateTypeFilter]);
    this.resetSavedTemplates();
    this.getSavedTemplates([...this.nameFilter, ...this.templateTypeFilter]);
    this.getSavedTemplatesTotal([...this.nameFilter, ...this.templateTypeFilter]);
  }

  private getRestrictions(): void {
    if (!this.isClientPortal) return;
    this.eventTemplatesIds = [];
    this.campaignTemplatesIds = [];
    this.socialMediaTemplatesIds = [];
    this.donationFormTemplatesIds = [];
    this.letterTemplatesIds = [];
    const filterPaging: Paging = {
      includeDependencies: true,
      filters: [{
        field: 'clientId',
        value: this.clientID,
        type: FilterType.Equal
      }],
      selectFields: ['templateID', 'template']
    };
    this.subscription.add(
      this.templateRestrictionService.getModelList(filterPaging)
        .pipe(
          filter((x) => !!x.length),
          tap((restrictions) => {
            restrictions.forEach((val) => {
              switch (val.template.templateUsageType) {
                case TemplateUsageType.Event:
                  this.eventTemplatesIds.push(val.templateID);
                  break;
                case TemplateUsageType.Campaign:
                  this.campaignTemplatesIds.push(val.templateID);
                  break;
                case TemplateUsageType.SocialMedia:
                  this.socialMediaTemplatesIds.push(val.templateID);
                  break;
                case TemplateUsageType.DonationForms:
                  this.donationFormTemplatesIds.push(val.templateID);
                  break;
                case TemplateUsageType.Letter:
                  this.letterTemplatesIds.push(val.templateID);
                  break;
              }
            });
          })
        )
        .subscribe(() => this.getAdminClientTemplatesSeparate([]))
    );
  }

  private getAdminSocialMediaClientTemplates(currentFilters: Filter[]): void {
    if (!this.isClientPortal) return;
    const paging: Paging = {
      ...this.getAdminClientTemplatesFilter(this.socialMediaTemplatesIds, currentFilters, TemplateUsageType.SocialMedia),
      rows: this.socialMediaTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.socialMediaTemplatesTotal, this.currentSocialMediaTemplatesPage, this.socialMediaTemplatesEntriesPerPage),
    };
    this.socialMediaTemplates$ = this.templateModelService.getModelList(paging)
      .pipe(
        tap(() => this.checkTabSubject.next())
      );
    this.cdr.markForCheck();
  }

  public getTemplatesForNonMerchant() {
    return this.templateTypeOptions.filter(opt => opt.value !== TemplateUsageType.DonationForms)
  }

  private getAdminDonationFormsClientTemplates(currentFilters: Filter[]): void {
    if (!this.isClientPortal) return;
    const paging: Paging = {
      ...this.getAdminClientTemplatesFilter(this.donationFormTemplatesIds, currentFilters, TemplateUsageType.DonationForms),
      rows: this.donationFormTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.donationFormTemplatesTotal, this.currentDonationFormTemplatesPage, this.donationFormTemplatesEntriesPerPage),
    };
    this.donationFormTemplates$ = this.templateModelService.getModelList(paging)
      .pipe(
        tap(() => this.checkTabSubject.next())
      );
    this.cdr.markForCheck();
  }

  private getAdminLettersClientTemplates(currentFilters: Filter[]): void {
    if (!this.isClientPortal) return;
    const paging: Paging = {
      ...this.getAdminClientTemplatesFilter(this.letterTemplatesIds, currentFilters, TemplateUsageType.Letter),
      rows: this.letterTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.letterTemplatesTotal, this.currentLetterTemplatesPage, this.letterTemplatesEntriesPerPage),
    };
    this.letterTemplates$ = this.templateModelService.getModelList(paging)
      .pipe(
        tap(() => this.checkTabSubject.next())
      );
    this.cdr.markForCheck();
  }

  private getAdminSocialMediaClientTemplatesTotal(currentFilters: Filter[]): void {
    if (!this.isClientPortal) return;
    this.subscription.add(
      this.templateModelService.getTotal(this.getAdminClientTemplatesFilter(this.socialMediaTemplatesIds, currentFilters, TemplateUsageType.SocialMedia))
        .subscribe((total => this.socialMediaTemplatesTotal = total))
    );
  }

  private getAdminDonationFormsClientTemplatesTotal(currentFilters: Filter[]): void {
    if (!this.isClientPortal) return;
    this.subscription.add(
      this.templateModelService.getTotal(this.getAdminClientTemplatesFilter(this.donationFormTemplatesIds, currentFilters, TemplateUsageType.DonationForms))
        .subscribe((total => this.donationFormTemplatesTotal = total))
    );
  }

  private getAdminLetterClientTemplatesTotal(currentFilters: Filter[]): void {
    if (!this.isClientPortal) return;
    this.subscription.add(
      this.templateModelService.getTotal(this.getAdminClientTemplatesFilter(this.letterTemplatesIds, currentFilters, TemplateUsageType.Letter))
        .subscribe((total => this.letterTemplatesTotal = total))
    );
  }

  private getAdminEventClientTemplates(currentFilters: Filter[]): void {
    if (!this.isClientPortal) return;
    const paging: Paging = {
      ...this.getAdminClientTemplatesFilter(this.eventTemplatesIds, currentFilters, TemplateUsageType.Event),
      rows: this.eventTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.eventTemplatesTotal, this.currentEventTemplatesPage, this.eventTemplatesEntriesPerPage),
    };
    this.eventTemplates$ = this.templateModelService.getModelList(paging)
      .pipe(
        tap(() => this.checkTabSubject.next())
      );
    this.cdr.markForCheck();
  }

  private getAdminEventClientTemplatesTotal(currentFilters: Filter[]): void {
    if (!this.isClientPortal) return;
    this.subscription.add(
      this.templateModelService.getTotal(this.getAdminClientTemplatesFilter(this.eventTemplatesIds, currentFilters, TemplateUsageType.Event))
        .subscribe((total => this.eventTemplatesTotal = total))
    );
  }

  private getAdminCampaignClientTemplates(currentFilters: Filter[]): void {
    if (!this.isClientPortal) return;
    const paging: Paging = {
      ...this.getAdminClientTemplatesFilter(this.campaignTemplatesIds, currentFilters, TemplateUsageType.Campaign),
      rows: this.campaignTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.campaignTemplatesTotal, this.currentCampaignTemplatesPage, this.campaignTemplatesEntriesPerPage),
    };
    this.campaignTemplates$ = this.templateModelService.getModelList(paging)
      .pipe(
        tap(() => this.checkTabSubject.next())
      );
    this.cdr.markForCheck();
  }

  private getAdminCampaignClientTemplatesTotal(currentFilters: Filter[]): void {
    if (!this.isClientPortal) return;
    this.subscription.add(
      this.templateModelService.getTotal(this.getAdminClientTemplatesFilter(this.campaignTemplatesIds, currentFilters, TemplateUsageType.Campaign))
        .subscribe((total => this.campaignTemplatesTotal = total))
    );
  }

  private getAdminClientTemplatesSeparate(currentFilters?: Filter[]): void {
    if (!this.isClientPortal) {
      return;
    }
    this.getAdminEventClientTemplates(currentFilters);
    this.getAdminEventClientTemplatesTotal(currentFilters);
    this.getAdminCampaignClientTemplates(currentFilters);
    this.getAdminCampaignClientTemplatesTotal(currentFilters);
    this.getAdminSocialMediaClientTemplates(currentFilters);
    this.getAdminSocialMediaClientTemplatesTotal(currentFilters);
    this.getAdminDonationFormsClientTemplates(currentFilters);
    this.getAdminDonationFormsClientTemplatesTotal(currentFilters);
    this.getAdminLettersClientTemplates(currentFilters);
    this.getAdminLetterClientTemplatesTotal(currentFilters);
  }

  private getAdminClientTemplatesFilter(templatesIds: string[], currentFilters?: Filter[], usageType?: TemplateUsageType): Paging {
    const ids = UtilsComponent.getArrayQueryValue(templatesIds);
    const baseFilter: Filter[] = [
      /*{
        field: 'TemplateCreationType',
        value: TemplateCreationType.Admin.toString(),
        type: FilterType.Equal
      },
      {
        field: 'templateUsageType',
        value: usageType.toString(),
        type: FilterType.Equal
      },*/
      {
        field: 'id',
        value: `array[${ids}]`,
        type: FilterType.Equal
      },
      {
        field: 'isDeleted',
        value: 'false',
        type: FilterType.Equal
      }
    ];

    const filters: Filter[] = [...baseFilter, ...currentFilters];
    return {
      includeDependencies: false,
      filters: filters,
      sortField: 'createdOn',
      sortOrder: SortOrder.Descending,
      selectFields: ['name', 'templateUsageType', 'id', 'description', 'createdOn', 'imagePreviewURL'],
    };
  }

  private setRolesAndUrl(): void {
    const roles = this.authService.getIdentityClaimsRole();
    this.isAdminPortal = this.router.url.includes('/admin-dashboard/');
    this.isClientPortal = this.router.url.includes('/clients/');
    this.userID = this.authService.getIdentityClaimsId();
    if (this.isAdminPortal) {
      this.userName = 'Admin';
    } else {
      this.subscription.add(
        this.clientModuleService.userName.subscribe((userName: string) => this.userName = userName)
      );
    }
    this.setUrl();
  }

  public get getTextAdmin(): string {
    return `${this.showSelectedCounts()} selected`;
  }

  private setUrl(): void {
    if (this.isAdminPortal) {
      this.url = '/admin-dashboard/library';
    } else if (this.isClientPortal) {
      this.url = '/clients/library';
    }
  }

  private selectTemplates(): void {
    this.subscription.add(
      this.templatesEventForm.valueChanges.subscribe((x) => {
        this.selectedEventsTemplatesCount = 0;
        x.forEach((template: TemplateModel) => {
          if (template.id) this.selectedEventsTemplatesCount += 1;
        });
      })
    );
    this.subscription.add(
      this.templatesCampaignForm.valueChanges.subscribe((x) => {
        this.selectedCampaignsTemplatesCount = 0;
        x.forEach((template: TemplateModel) => {
          if (template.id) this.selectedCampaignsTemplatesCount += 1;
        });
      })
    );
    this.subscription.add(
      this.templatesSocialMediaForm.valueChanges.subscribe((x) => {
        this.selectedSocialMediaTemplatesCount = 0;
        x.forEach((template: TemplateModel) => {
          if (template.id) this.selectedSocialMediaTemplatesCount += 1;
        });
      })
    );
    this.subscription.add(
      this.templatesFormsForm.valueChanges.subscribe((x) => {
        this.selectedDonationFormTemplatesCount = 0;
        x.forEach((template: TemplateModel) => {
          if (template.id) this.selectedDonationFormTemplatesCount += 1;
        });
      })
    );
    this.subscription.add(
      this.templatesLettersForm.valueChanges.subscribe((x) => {
        this.selectedLetterTemplatesCount = 0;
        x.forEach((template: TemplateModel) => {
          if (template.id) this.selectedLetterTemplatesCount += 1;
        })
      })
    )
  }

  public setStatisticSorting(val: SortValues = {sortField: 'usageVolume', sortOrder: SortOrder.Descending}): void {
    this.countUsed = 0;
    this.sortStatisticOrder = val.sortOrder;
    this.sortStatisticField = val.sortField;
    this.setStatisticDefaultPage.next(1);
    this.currentTemplatesStatisticPage = 1;
    this.getTagTemplatesStatistic([]);
    this.getTotalTemplatesStatistic([]);
  }

  public setStatisticSortingUsed(): void {
    this.countUsed += 1;
    this.countUsed % 2 === 0
      ? (this.showUsedArrow = true)
      : (this.showUsedArrow = false);

    this.sortStatisticOrder = this.showUsedArrow
      ? SortOrder.Ascending
      : SortOrder.Descending;
    this.sortStatisticField = 'usageVolume';
    this.setStatisticDefaultPage.next(1);
    this.currentTemplatesStatisticPage = 1;
    this.getTagTemplatesStatistic([]);
    this.getTotalTemplatesStatistic([]);
  }

  public showSelectedCounts(): number {
    if (!this.isAdminPortal) return 0;
    const usageType: TemplateUsageType = this.tabSourceObj[this.currentTab];
    let number = 0;
    switch (usageType) {
      case TemplateUsageType.Event:
        number = this.selectedEventsTemplatesCount;
        break;
      case TemplateUsageType.Campaign:
        number = this.selectedCampaignsTemplatesCount;
        break;
      case TemplateUsageType.SocialMedia:
        number = this.selectedSocialMediaTemplatesCount;
        break;
      case TemplateUsageType.DonationForms:
        number = this.selectedDonationFormTemplatesCount;
        break;
      case TemplateUsageType.Letter:
        number = this.selectedLetterTemplatesCount;
        break;
    }
    return number;
  }

  private getClients(): void {
    if (!this.isAdminPortal) return;
    const filters: Filter[] = [
      {
        field: 'isActive',
        value: 'true',
        type: FilterType.Equal
      }
    ];
    const filterPaging: Paging = {
      includeDependencies: false,
      sortField: 'CreatedOn',
      sortOrder: SortOrder.Descending,
      filters,
      selectFields: ['id', 'name']
    };

    this.subscription.add(
      this.clientService.getModelList(filterPaging).subscribe((clients) => {
        this.allClients = clients;
        this.clients = clients;
      })
    );
  }

  public showChooseOptionDialog(choice: boolean = false): void {
    const config = {
      data: {
        title: 'Please select a template type.',
        formElementData: this.isAdminPortal || (this.isClientPortal && this.hasMerchantAccount) ? this.templateTypeOptions.filter(o => o.value !== 'all') : this.templateTypeOptions.filter(o => o.value !== 'all' && o.value !== TemplateUsageType.DonationForms),
        isDonationForm: choice,
      }
    };
    const dialogRef = this.dialog.open(ChooseOptionComponent, config);
    this.subscription.add(
      dialogRef.afterClosed().subscribe((usageType) => {
        if (usageType) {
          if (usageType === TemplateUsageType.DonationForms) {
            this.showFormsSelectDialog();
          } else {
            const url = this.isClientPortal
              ? `${this.url}/create/${usageType}/${this.clientID}`
              : `${this.url}/create/${usageType}`;
            this.router.navigateByUrl(url);
          }
        }
      })
    );
  }

  private getAvailableDonationFormTemplates(): Observable<{ admin: TemplateModel[], client: TemplateModel[] }> {
    const selectFields = ['name', 'templateUsageType', 'id', 'imagePreviewURL'];
    if (this.isClientPortal) {
      const sharedAdminTemplates$ = this.templateModelService.getModelList({
        ...this.getAdminClientTemplatesFilter(this.donationFormTemplatesIds, [], TemplateUsageType.DonationForms),
        selectFields
      });
      const clientPaging: Paging = {
        sortField: 'createdOn',
        sortOrder: SortOrder.Descending,
        ...this.getSavedTemplatesTotalPaging([
          {
            field: 'templateUsageType',
            value: TemplateUsageType.DonationForms.toString(),
            type: FilterType.Equal
          },
        ]),
        selectFields
      };
      const clientTemplates$ = this.templateModelService.getModelList(clientPaging);
      return forkJoin([sharedAdminTemplates$, clientTemplates$])
        .pipe(
          map(([sharedAdminTemplates, clientTemplates]) => ({admin: sharedAdminTemplates, client: clientTemplates}))
        );
    } else {
      const adminPaging: Paging = {
        sortField: 'createdOn',
        sortOrder: SortOrder.Descending,
        ...this.getDonationFormsTemplatesTotalPaging([]),
        selectFields
      };
      return this.templateModelService.getModelList(adminPaging)
        .pipe(
          map(adminTemplates => ({admin: adminTemplates, client: []}))
        );
    }

  }

  private showFormsSelectDialog(): void {
    this.subscription.add(
      this.getAvailableDonationFormTemplates()
        .pipe(
          switchMap(({admin, client}) => {
            return this.dialog.open(FormsTemplateSelectComponent, {
              data: {
                auxiliaTemplates: admin,
                clientTemplates: client
              },
              width: '95vw',
              maxWidth: '476px',
            }).afterClosed();
          })
        ).subscribe(response => {
        if (!response) {
          return;
        } else if (response === 'back') {
          this.showChooseOptionDialog(true);
        } else if (response === 'create') {
          const url = this.isClientPortal
            ? `${this.url}/create/${TemplateUsageType.DonationForms}/${this.clientID}`
            : `${this.url}/create/${TemplateUsageType.DonationForms}`;
          this.router.navigateByUrl(url);
        } else {
          this.navigateTo(response);
        }
      })
    );
  }

  public navigateTo(templateModel: TemplateModel) {
    const url = this.isClientPortal
      ? `${this.url}/${templateModel.id}/${templateModel.templateUsageType}/${this.clientID}`
      : `${this.url}/${templateModel.id}/${templateModel.templateUsageType}`;
    this.router.navigateByUrl(url);
  }

  public removeItem(clientId: string): void {
    this.clients = this.clients.filter(({id}: ClientModel) => id !== clientId);
  }

  public onClientSearch(event: Event): void {
    this.searchClients.next((event.target as HTMLInputElement).value);
  }

  public searchByTagName(name: string): void {
    this.searchStatistic.next(name);
  }

  public onShare(): void {
    const usageType: TemplateUsageType = this.tabSourceObj[this.currentTab];
    if (!this.getSelectedTemplates(usageType).length) {
      this.toastr.warning('Please choose template!', 'Warning');
      return;
    }
    this.isShare = true;
  }

  public onDelete(): void {
    const usageType: TemplateUsageType = this.tabSourceObj[this.currentTab];
    if (!this.getSelectedTemplates(usageType).length) {
      this.toastr.warning('Please choose template!', 'Warning');
      return;
    }
    this.isDelete = true;
    this.isShare = false;
  }

  public deleteNow(): void {
    const usageType: TemplateUsageType = this.tabSourceObj[this.currentTab];

    const selectedTemplates: TemplateModel[] = this.getSelectedTemplates(usageType);

    if (!selectedTemplates.length) {
      this.toastr.warning('Please choose template!', 'Warning');
      return;
    }

    const model: TemplateModel[] = selectedTemplates.map((templ) => ({
      ...templ,
      isDeleted: true,
      deletedByUserName: this.userName,
      deletedByID: this.userID
    }));

    this.subscription.add(
      this.templateModelService.updateMany(model).subscribe(() => {
        switch (usageType) {
          case TemplateUsageType.Event:
            this.resetEventTemplates();
            this.getEventTemplates([...this.nameFilter, ...this.templateTypeFilter]);
            this.getEventTemplatesTotal([...this.nameFilter, ...this.templateTypeFilter]);
            break;
          case TemplateUsageType.Campaign:
            this.resetCampaignTemplates();
            this.getCampaignTemplates([...this.nameFilter, ...this.templateTypeFilter]);
            this.getCampaignTemplatesTotal([...this.nameFilter, ...this.templateTypeFilter]);
            break;
          case TemplateUsageType.SocialMedia:
            this.resetSocialMediaTemplates();
            this.getSocialMediaTemplates([...this.nameFilter, ...this.templateTypeFilter]);
            this.getSocialMediaTemplatesTotal([...this.nameFilter, ...this.templateTypeFilter]);
            break;
          case TemplateUsageType.DonationForms:
            this.resetDonationFormsTemplates();
            this.getDonationFormsTemplates([...this.nameFilter, ...this.templateTypeFilter]);
            this.getDonationFormsTemplatesTotal([...this.nameFilter, ...this.templateTypeFilter]);
            break;
          case TemplateUsageType.Letter:
            this.resetLetterTemplates();
            this.getLetterTemplates([...this.nameFilter, ...this.templateTypeFilter]);
            this.getLetterTemplatesTotal([...this.nameFilter, ...this.templateTypeFilter]);
            break;
        }
        this.clearForms();
        this.isDelete = false;
        this.isShare = false;
        this.toastr.success(`Templates successfully deleted!`);
      })
    );
  }

  public deleteSaved(template: TemplateModel): void {
    const config = {
      data: {
        title: this.translate.instant('BUILD.SelectedToDelete', {value: template.name})
      }
    };
    this.subscription.add(
      this.dialog.open(BeforeUnloadComponent, config).afterClosed()
        .pipe(
          switchMap((response: any) => {
            switch (response) {
              case DialogBeforeunloadResponseType.Accept:
                return this.templateModelService.updateModel(
                  {
                    ...template,
                    isDeleted: true,
                    deletedByUserName: this.userName,
                    deletedByID: this.userID
                  }
                )
                  .pipe(
                    switchMap((templateModel: TemplateModel) => {
                      if (templateModel.templateUsageType === TemplateUsageType.DonationForms) {
                        this.customDonationFormsService.removeModel(templateModel.id);
                      }
                      if (templateModel.id === this.client.defaultTemplateId) {
                        const nextClientModel: ClientModel = {
                          ...this.client,
                          defaultTemplateId: null
                        };
                        return this.clientService.updateModel(nextClientModel)
                          .pipe(
                            tap(() => this.clientModuleService.updateClientData())
                          );
                      } else {
                        return of(true)
                      }
                    })
                  );
              case DialogBeforeunloadResponseType.Reject:
              case DialogBeforeunloadResponseType.Close:
              case undefined:
                return of(null);
            }
          }))
        .subscribe((result) => {
          if (!result) {
            return;
          }
          this.resetSavedTemplates();
          this.getSavedTemplates([...this.nameFilter, ...this.templateTypeFilter]);
          this.getSavedTemplatesTotal([...this.nameFilter, ...this.templateTypeFilter]);
          const name = template.name;
          this.toastr.success(this.translate.instant('BUILD.TemplateDeleted', {value: name}));
        })
    );
  }

  public onCancel(): void {
    this.isShare = false;
    this.isDelete = false;
    this.clients = [...this.allClients];
    this.clearForms();
  }

  public shareNow(): void {
    let selectedTemplates: TemplateModel[] = [];
    const usageType: TemplateUsageType = this.tabSourceObj[this.currentTab];

    if (!this.clients.length) {
      this.toastr.warning('Please choose organization', 'Warning');
      return;
    }

    selectedTemplates = this.getSelectedTemplates(usageType);

    if (!selectedTemplates.length) {
      this.toastr.warning('Please choose template!', 'Warning');
      return;
    }

    const templateRestrictionModels: TemplateClientRestrictionModel[] = [];
    this.clients.forEach((client) =>
      selectedTemplates.forEach((template: TemplateModel) => {
        templateRestrictionModels.push({
          templateID: template.id,
          clientID: client.id
        });
      })
    );

    this.subscription.add(
      this.templateRestrictionService
        .createMany(templateRestrictionModels)
        .subscribe(() => {
          this.isShare = false;
          this.clearForms();
          this.toastr.success('Templates are shared successfully!');
        })
    );
  }

  private getSelectedTemplates(usageType: TemplateUsageType): TemplateModel[] {
    let fn;
    let selectedTemplates: TemplateModel[] = [];
    const actions = {
      [TemplateUsageType.Event]: () => selectedTemplates = this.templatesEventForm.value.filter((template: TemplateModel) => !!template.id),
      [TemplateUsageType.Campaign]: () => selectedTemplates = this.templatesCampaignForm.value.filter((template: TemplateModel) => !!template.id),
      [TemplateUsageType.SocialMedia]: () => selectedTemplates = this.templatesSocialMediaForm.value.filter((template: TemplateModel) => !!template.id),
      [TemplateUsageType.DonationForms]: () => selectedTemplates = this.templatesFormsForm.value.filter((template: TemplateModel) => !!template.id),
      [TemplateUsageType.Letter]: () => selectedTemplates = this.templatesLettersForm.value.filter((template: TemplateModel) => !!template.id),
      default: () => {
      }
    };

    actions[usageType] ? (fn = actions[usageType]) : (fn = actions['default']);
    fn();

    return selectedTemplates;
  }

  private clearForms(): void {
    this.templatesEventForm.reset();
    this.templatesCampaignForm.reset();
    this.templatesSocialMediaForm.reset();
    this.templatesFormsForm.reset();
    this.cdr.markForCheck();
  }

  private searchClientsSubscription(): void {
    this.subscription.add(
      this.searchClients.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value: string) => {
        this.clients = this.allClients.filter(({name}: ClientModel) => name.toLowerCase().includes(value.toLowerCase()));
        this.cdr.markForCheck();
      })
    );
  }

  private getFilterStatistic(val: string): Filter[] {
    return val
      ? [{
        field: 'tag.name',
        value: val,
        type: FilterType.Contains
      }]
      : [];
  }

  private searchStatisticSubscription(): void {
    this.subscription.add(
      this.searchStatistic
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => {
            this.setStatisticDefaultPage.next(1);
            this.currentTemplatesStatisticPage = 1;
          }),
          switchMap((val) =>
            forkJoin([
              this.templateStatisticService.getModelList(this.getTagTemplatesStatisticPaging(this.getFilterStatistic(val))),
              this.templateStatisticService.getTotal(this.getTotalTemplatesStatisticPaging(this.getFilterStatistic(val)))
            ])
          )
        )
        .subscribe(([statisRes, total]) => {
          this.tamplateStatistic = statisRes.map((statistic) => ({
            ...statistic,
            tagName: statistic.tag ? statistic.tag.name : null
          }));
          this.templatesStatisticTotal = total;
        })
    );
  }

  public getAbbreviation(element: ClientModel): string {
    return element.name
      .split(' ')
      .map((item) => {
        if (item) {
          return item[0].toUpperCase();
        }
      })
      .join('');
  }

  public isSelectedTemplate(id: string): boolean {
    if (!id) return;
    return this.selectedTemplates.some((template) => template.id === id);
  }

  public goToSignUp() {
    const url = `${this.url}/signup-settings/${this.clientID}`;
    this.router.navigateByUrl(url);
  }

  public chooseTemplate(template: TemplateModel, i: number, type: TemplateUsageType): void {
    if (!this.isAdminPortal) {
      this.navigateTo(template);
    } else {
      switch (type) {
        case TemplateUsageType.Event:
          const controlEvent = this.templatesEventForm.controls[i];
          controlEvent.patchValue(controlEvent.value.id ? this.templateModelForm : template);
          break;
        case TemplateUsageType.Campaign:
          const controlCampaign = this.templatesCampaignForm.controls[i];
          controlCampaign.patchValue(controlCampaign.value.id ? this.templateModelForm : template);
          break;
        case TemplateUsageType.SocialMedia:
          const controlSocialMedia = this.templatesSocialMediaForm.controls[i];
          controlSocialMedia.patchValue(controlSocialMedia.value.id ? this.templateModelForm : template);
          break;
        case TemplateUsageType.DonationForms:
          const controlForms = this.templatesFormsForm.controls[i];
          controlForms.patchValue(controlForms.value.id ? this.templateModelForm : template);
          break;
        case TemplateUsageType.Letter:
          const controlLetter = this.templatesLettersForm.controls[i];
          controlLetter.patchValue(controlLetter.value.id ? this.templateModelForm : template);
          break;
      }
    }
  }

  private getSavedTemplates(currentFilters: Filter[]): void {
    if (!this.isClientPortal) {
      return;
    }
    this.savedTemplates$ = this.templateModelService.getModelList(this.getSavedTemplatesPaging(currentFilters))
      .pipe(
        tap(() => this.checkTabSubject.next())
      );
    this.cdr.markForCheck();
  }

  private getSavedTemplatesTotal(currentFilters: Filter[]): void {
    if (!this.isClientPortal) {
      return;
    }
    this.subscription.add(
      this.templateModelService.getTotal(this.getSavedTemplatesTotalPaging(currentFilters)).subscribe((total: number) => this.savedTemplatesTotal = total)
    );
  }

  private resetSavedTemplates(): void {
    this.currentSavedTemplatesPage = 1;
    this.setSavedTemplatesDefaultPage.next(1);
  }

  private getSavedTemplatesTotalPaging(currentFilters: Filter[]): Paging {
    const paging: Paging = {
      includeDependencies: false,
      filters: [...this.baseFilter, ...currentFilters],
    };
    if (!this.hasMerchantAccount) {
      paging.filters.push({
        field: 'templateUsageType',
        value: TemplateUsageType.DonationForms.toString(),
        type: FilterType.NotEqual
      });
    }
    return paging;
  }

  private get baseFilter(): Filter[] {
    return [
      {
        field: 'clientId',
        value: this.clientID,
        type: FilterType.Equal
      },
      {
        field: 'isDeleted',
        value: 'false',
        type: FilterType.Equal
      }
    ];
  }

  private getSavedTemplatesPaging(currentFilters: Filter[]): Paging {
    return {
      ...this.getSavedTemplatesTotalPaging(currentFilters),
      sortField: 'createdOn',
      sortOrder: SortOrder.Descending,
      rows: this.savedTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.savedTemplatesTotal, this.currentSavedTemplatesPage, this.savedTemplatesEntriesPerPage),
      selectFields: ['name', 'templateUsageType', 'id', 'description', 'createdOn', 'imagePreviewURL'],
    };
  }

  private getEventTemplates(currentFilters: Filter[]): void {
    if (!this.isAdminPortal) {
      return;
    }
    this.eventTemplates$ = this.templateModelService.getModelList(this.getEventTemplatesPaging(currentFilters))
      .pipe(
        tap((templates) => {
          this.createEventTemplateForm(templates.length);
          this.checkTabSubject.next();
        })
      );
    this.cdr.markForCheck();
  }

  private getEventTemplatesTotal(currentFilters: Filter[]): void {
    if (!this.isAdminPortal) {
      return;
    }
    this.subscription.add(
      this.templateModelService.getTotal(this.getEventTemplatesTotalPaging(currentFilters)).subscribe((total: number) => this.eventTemplatesTotal = total)
    );
  }

  private getEventTemplatesTotalPaging(currentFilters: Filter[]): Paging {
    return {
      includeDependencies: false,
      filters: [...this.eventBaseFilter, ...currentFilters],
    };
  }

  private getEventTemplatesPaging(currentFilters: Filter[]): Paging {
    return {
      ...this.getEventTemplatesTotalPaging(currentFilters),
      sortField: 'createdOn',
      sortOrder: SortOrder.Descending,
      selectFields: ['name', 'templateUsageType', 'id', 'description', 'createdOn', 'imagePreviewURL'],
      rows: this.eventTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.eventTemplatesTotal, this.currentEventTemplatesPage, this.eventTemplatesEntriesPerPage),
    };
  }

  private get eventBaseFilter(): Filter[] {
    return [
      {
        field: 'TemplateCreationType',
        value: TemplateCreationType.Admin.toString(),
        type: FilterType.Equal
      },
      {
        field: 'templateUsageType',
        value: TemplateUsageType.Event.toString(),
        type: FilterType.Equal
      },
      {
        field: 'isDeleted',
        value: 'false',
        type: FilterType.Equal
      }
    ];
  }

  private resetEventTemplates(): void {
    this.currentEventTemplatesPage = 1;
    this.setEventTemplatesDefaultPage.next(1);
  }

  private getCampaignTemplates(currentFilters: Filter[]): void {
    if (!this.isAdminPortal) {
      return;
    }
    this.campaignTemplates$ = this.templateModelService.getModelList(this.getCampaignTemplatesPaging(currentFilters))
      .pipe(
        tap((templates) => {
          this.createCampaignTemplateForm(templates.length);
          this.checkTabSubject.next();
        })
      );
    this.cdr.markForCheck();
  }

  private getCampaignTemplatesTotal(currentFilters: Filter[]): void {
    if (!this.isAdminPortal) {
      return;
    }
    this.subscription.add(
      this.templateModelService.getTotal(this.getCampaignTemplatesTotalPaging(currentFilters)).subscribe((total: number) => this.campaignTemplatesTotal = total)
    );
  }

  private getCampaignTemplatesTotalPaging(currentFilters: Filter[]): Paging {
    return {
      includeDependencies: false,
      filters: [...this.campaignBaseFilter, ...currentFilters],
    };
  }

  private get campaignBaseFilter(): Filter[] {
    return [
      {
        field: 'TemplateCreationType',
        value: TemplateCreationType.Admin.toString(),
        type: FilterType.Equal
      },
      {
        field: 'templateUsageType',
        value: TemplateUsageType.Campaign.toString(),
        type: FilterType.Equal
      },
      {
        field: 'isDeleted',
        value: 'false',
        type: FilterType.Equal
      }
    ];
  }

  private getCampaignTemplatesPaging(currentFilters: Filter[]): Paging {
    return {
      ...this.getCampaignTemplatesTotalPaging(currentFilters),
      sortField: 'createdOn',
      sortOrder: SortOrder.Descending,
      selectFields: ['name', 'templateUsageType', 'id', 'description', 'createdOn', 'imagePreviewURL'],
      rows: this.campaignTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.campaignTemplatesTotal, this.currentCampaignTemplatesPage, this.campaignTemplatesEntriesPerPage),
    };
  }

  private resetCampaignTemplates(): void {
    this.currentCampaignTemplatesPage = 1;
    this.setCampaignTemplatesDefaultPage.next(1);
  }

  private getSocialMediaTemplates(currentFilters: Filter[]): void {
    if (!this.isAdminPortal) {
      return;
    }

    this.socialMediaTemplates$ = this.templateModelService.getModelList(this.getSocialMediaTemplatesPaging(currentFilters))
      .pipe(
        tap((templates) => {
          this.createSocialMediaTemplateForm(templates.length);
          this.checkTabSubject.next();
        })
      );
    this.cdr.markForCheck();
  }

  private getDonationFormsTemplates(currentFilters: Filter[]): void {
    if (!this.isAdminPortal) {
      return;
    }

    this.donationFormTemplates$ = this.templateModelService.getModelList(this.getDonationFormsTemplatesPaging(currentFilters))
      .pipe(
        tap((templates) => {
          this.createDonationFormsTemplateForm(templates.length);
          this.checkTabSubject.next();
        })
      );
    this.cdr.markForCheck();
  }

  private getLetterTemplates(currentFilters: Filter[]): void {
    if (!this.isAdminPortal) {
      return;
    }

    this.letterTemplates$ = this.templateModelService.getModelList(this.getLetterTemplatesPaging(currentFilters))
      .pipe(
        tap((templates) => {
          this.createLettersTemplateForm(templates.length);
          this.checkTabSubject.next();
        })
      );
    this.cdr.markForCheck();
  }

  private getSocialMediaTemplatesTotal(currentFilters: Filter[]): void {
    if (!this.isAdminPortal) {
      return;
    }
    this.subscription.add(
      this.templateModelService.getTotal(this.getSocialMediaTemplatesTotalPaging(currentFilters)).subscribe((total: number) => this.socialMediaTemplatesTotal = total)
    );
  }

  private getDonationFormsTemplatesTotal(currentFilters: Filter[]): void {
    if (!this.isAdminPortal) {
      return;
    }
    this.subscription.add(
      this.templateModelService.getTotal(this.getDonationFormsTemplatesTotalPaging(currentFilters)).subscribe((total: number) => this.donationFormTemplatesTotal = total)
    );
  }

  private getDonationFormsTemplatesTotalPaging(currentFilters: Filter[]): Paging {
    return {
      includeDependencies: false,
      filters: [...this.donationFormsBaseFilter, ...currentFilters],
    };
  }

  private getLetterTemplatesTotal(currentFilters: Filter[]): void {
    if (!this.isAdminPortal) {
      return;
    }
    this.subscription.add(
      this.templateModelService.getTotal(this.getLetterTemplatesTotalPaging(currentFilters)).subscribe((total: number) => this.letterTemplatesTotal = total)
    );
  }

  private get donationFormsBaseFilter(): Filter[] {
    return [
      {
        field: 'TemplateCreationType',
        value: TemplateCreationType.Admin.toString(),
        type: FilterType.Equal
      },
      {
        field: 'templateUsageType',
        value: TemplateUsageType.DonationForms.toString(),
        type: FilterType.Equal
      },
      {
        field: 'isDeleted',
        value: 'false',
        type: FilterType.Equal
      }
    ];
  }

  private getSocialMediaTemplatesTotalPaging(currentFilters: Filter[]): Paging {
    return {
      includeDependencies: false,
      filters: [...this.socialMediaBaseFilter, ...currentFilters],
    };
  }

  private get socialMediaBaseFilter(): Filter[] {
    return [
      {
        field: 'TemplateCreationType',
        value: TemplateCreationType.Admin.toString(),
        type: FilterType.Equal
      },
      {
        field: 'templateUsageType',
        value: TemplateUsageType.SocialMedia.toString(),
        type: FilterType.Equal
      },
      {
        field: 'isDeleted',
        value: 'false',
        type: FilterType.Equal
      }
    ];
  }

  private getSocialMediaTemplatesPaging(currentFilters: Filter[]): Paging {
    return {
      ...this.getSocialMediaTemplatesTotalPaging(currentFilters),
      sortField: 'createdOn',
      sortOrder: SortOrder.Descending,
      selectFields: ['name', 'templateUsageType', 'id', 'description', 'createdOn', 'imagePreviewURL'],
      rows: this.socialMediaTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.socialMediaTemplatesTotal, this.currentSocialMediaTemplatesPage, this.socialMediaTemplatesEntriesPerPage),
    };
  }

  private getDonationFormsTemplatesPaging(currentFilters: Filter[]): Paging {
    return {
      ...this.getDonationFormsTemplatesTotalPaging(currentFilters),
      sortField: 'createdOn',
      sortOrder: SortOrder.Descending,
      selectFields: ['name', 'templateUsageType', 'id', 'description', 'createdOn', 'imagePreviewURL'],
      rows: this.donationFormTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.donationFormTemplatesTotal, this.currentDonationFormTemplatesPage, this.donationFormTemplatesEntriesPerPage),
    };
  }

  private getLetterTemplatesPaging(currentFilters: Filter[]): Paging {
    return {
      ...this.getLetterTemplatesTotalPaging(currentFilters),
      sortField: 'createdOn',
      sortOrder: SortOrder.Descending,
      selectFields: ['name', 'templateUsageType', 'id', 'description', 'createdOn', 'imagePreviewURL'],
      rows: this.letterTemplatesEntriesPerPage,
      first: UtilsComponent.getFirstItemOnPage(this.letterTemplatesTotal, this.currentLetterTemplatesPage, this.letterTemplatesEntriesPerPage),
    };
  }

  private getLetterTemplatesTotalPaging(currentFilters: Filter[]): Paging {
    return {
      includeDependencies: false,
      filters: [...this.lettersBaseFilter, ...currentFilters],
    };
  }

  private get lettersBaseFilter(): Filter[] {
    return [
      {
        field: 'TemplateCreationType',
        value: TemplateCreationType.Admin.toString(),
        type: FilterType.Equal
      },
      {
        field: 'templateUsageType',
        value: TemplateUsageType.Letter.toString(),
        type: FilterType.Equal
      },
      {
        field: 'isDeleted',
        value: 'false',
        type: FilterType.Equal
      }
    ];
  }

  private resetSocialMediaTemplates(): void {
    this.currentSocialMediaTemplatesPage = 1;
    this.setSocialMediaTemplatesDefaultPage.next(1);
  }

  private resetDonationFormsTemplates(): void {
    this.currentDonationFormTemplatesPage = 1;
    this.setDonationFormTemplatesDefaultPage.next(1);
  }

  private resetLetterTemplates(): void {
    this.currentLetterTemplatesPage = 1;
    this.setLetterTemplatesDefaultPage.next(1);
  }

  private createEventTemplateForm(templatesLength: number): void {
    this.templatesEventForm.clear();
    for (let index = 0; index < templatesLength; index++) {
      this.templatesEventForm.push(this.fb.group(this.templateModelForm));
    }
  }

  private createCampaignTemplateForm(templatesLength: number): void {
    this.templatesCampaignForm.clear();
    for (let index = 0; index < templatesLength; index++) {
      this.templatesCampaignForm.push(this.fb.group(this.templateModelForm));
    }
  }

  private createSocialMediaTemplateForm(templatesLength: number): void {
    this.templatesSocialMediaForm.clear();
    for (let index = 0; index < templatesLength; index++) {
      this.templatesSocialMediaForm.push(this.fb.group(this.templateModelForm));
    }
  }

  private createDonationFormsTemplateForm(templatesLength: number): void {
    this.templatesFormsForm.clear();
    for (let index = 0; index < templatesLength; index++) {
      this.templatesFormsForm.push(this.fb.group(this.templateModelForm));
    }
  }

  private createLettersTemplateForm(templatesLength: number): void {
    this.templatesLettersForm.clear();
    for (let index = 0; index < templatesLength; index++) {
      this.templatesLettersForm.push(this.fb.group(this.templateModelForm));
    }
  }

  private getTagTemplatesStatistic(currentFilters: Filter[]): void {
    if (!this.isAdminPortal) return;
    this.subscription.add(
      this.templateStatisticService
        .getModelList(this.getTagTemplatesStatisticPaging(currentFilters))
        .pipe(
          map((statistics) =>
            statistics.map((statistic) => ({
              ...statistic,
              tagName: statistic.tag ? statistic.tag.name : null
            }))
          )
        )
        .subscribe((templateStatistic: TemplateStatistic[]) => {
          this.tamplateStatistic = templateStatistic;
        })
    );
  }

  private getTagTemplatesStatisticPaging(currentFilters: Filter[]): Paging {
    const baseFilter: Filter[] = [
      {
        field: 'templateStatisticType',
        value: TemplateStatisticType.Tag.toString(),
        type: FilterType.Equal
      }
    ];
    const filters: Filter[] = [...baseFilter, ...currentFilters];
    return {
      first: UtilsComponent.getFirstItemOnPage(
        this.templatesStatisticTotal,
        this.currentTemplatesStatisticPage,
        this.templatesTagsEntriesPerPage
      ),
      rows: this.templatesTagsEntriesPerPage,
      includeDependencies: true,
      filters: filters,
      sortField: this.sortStatisticField,
      sortOrder: this.sortStatisticOrder
    };
  }

  private getTotalTemplatesStatistic(currentFilters: Filter[]): void {
    if (!this.isAdminPortal) return;
    this.subscription.add(
      this.templateStatisticService
        .getTotal(this.getTotalTemplatesStatisticPaging(currentFilters))
        .subscribe((total: number) => {
          this.templatesStatisticTotal = total;
        })
    );
  }

  private getTotalTemplatesStatisticPaging(currentFilters: Filter[]): Paging {
    const baseFilter: Filter[] = [
      {
        field: 'templateStatisticType',
        value: TemplateStatisticType.Tag.toString(),
        type: FilterType.Equal
      }
    ];

    const filters: Filter[] = [...baseFilter, ...currentFilters];
    return {
      includeDependencies: false,
      filters
    };
  }

  public onSearch(event: Event): void {
    this.searchTemplates.next((event.target as HTMLInputElement).value);
  }

  public getNextTempaltesStatisticPage(page: number): void {
    this.currentTemplatesStatisticPage = page;
    this.getTagTemplatesStatistic([...this.nameFilter]);
  }

  public setTemplatesStatisticPerPage(amount: number): void {
    this.templatesTagsEntriesPerPage = amount;
    this.getTagTemplatesStatistic([...this.nameFilter]);
  }

  private searchByTemplateNameSubscription(): void {
    this.subscription.add(
      this.searchTemplates
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
        )
        .subscribe((value: string) => {
          if (value.toLowerCase() !== 'new') {
            this.nameFilter = value
              ? [
                {
                  field: 'combinedSearch',
                  value,
                  type: FilterType.Contains
                }
              ]
              : [];

            this.resetEventTemplates();
            this.getEventTemplates([...this.nameFilter]);
            this.getEventTemplatesTotal([...this.nameFilter]);

            this.resetCampaignTemplates();
            this.getCampaignTemplates([...this.nameFilter]);
            this.getCampaignTemplatesTotal([...this.nameFilter]);

            this.resetSocialMediaTemplates();
            this.getSocialMediaTemplates([...this.nameFilter]);
            this.getSocialMediaTemplatesTotal([...this.nameFilter]);

            this.resetDonationFormsTemplates();
            this.getDonationFormsTemplates([...this.nameFilter]);
            this.getDonationFormsTemplatesTotal([...this.nameFilter]);

            this.resetLetterTemplates();
            this.getLetterTemplates([...this.nameFilter]);
            this.getLetterTemplatesTotal([...this.nameFilter]);

            this.getAdminClientTemplatesSeparate([...this.nameFilter, ...this.templateTypeFilter]);

            this.resetSavedTemplates();
            this.getSavedTemplates([...this.nameFilter, ...this.templateTypeFilter]);
            this.getSavedTemplatesTotal([...this.nameFilter, ...this.templateTypeFilter]);
          } else {
            this.dateFilter = [
              {
                field: 'createdOn',
                value: this.getStartDate().toJSON(),
                type: FilterType.GreaterThanOrEqual
              }
            ];
            this.resetEventTemplates();
            this.getEventTemplates([...this.dateFilter]);
            this.getEventTemplatesTotal([...this.dateFilter]);

            this.resetCampaignTemplates();
            this.getCampaignTemplates([...this.dateFilter]);
            this.getCampaignTemplatesTotal([...this.dateFilter]);

            this.resetSocialMediaTemplates();
            this.getSocialMediaTemplates([...this.dateFilter]);
            this.getSocialMediaTemplatesTotal([...this.dateFilter]);

            this.resetDonationFormsTemplates();
            this.getDonationFormsTemplates([...this.dateFilter]);
            this.getDonationFormsTemplatesTotal([...this.dateFilter]);

            this.resetLetterTemplates();
            this.getLetterTemplates([...this.dateFilter]);
            this.getLetterTemplatesTotal([...this.dateFilter]);

            this.getAdminClientTemplatesSeparate([...this.dateFilter, ...this.templateTypeFilter]);

            this.resetSavedTemplates();
            this.getSavedTemplates([...this.dateFilter, ...this.templateTypeFilter]);
            this.getSavedTemplatesTotal([...this.dateFilter, ...this.templateTypeFilter]);
          }
        })
    );
  }

  public isNewTemplate(template: TemplateModel): boolean {
    return new Date(template.createdOn) >= this.getStartDate();
  }

  public getStartDate(): Date {
    const startDate = new Date();
    let counter = 0;
    while (counter < 13) {
      if (startDate.getDay() !== 6 && startDate.getDay() !== 0) {
        counter++;
      }
      startDate.setDate(startDate.getDate() - 1);
    }
    return startDate;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /*public getScaleRate(template: TemplateModel): string {
    return `scale(${208 / this.getTemplateWidth(template)})`;
  }*/

  /*public getTemplateWidth(template: TemplateModel): number {
    const mjml: MjmlElementModel = template.content && JSON.parse(template.content);
    return +mjml.children[1].attributes['width'].slice(0, -2);
  }*/

  /*public setContent(template: TemplateModel, id: string): void {
    const iframeElement: any = document.getElementById(id);
    if (iframeElement) {
      iframeElement.contentWindow.document.open();
      iframeElement.contentWindow.document.write(template.contentHTML);
      iframeElement.contentWindow.document.close();
    }
  }*/

  public get clientID(): string {
    return this.clientIdStateService.selectedRelationshipClientId;
  }

  public get formGroup(): FormGroup {
    return this.clientIdStateService.clientIdForm;
  }

  public clientChanged(clientID: string): void {
    const name = this.clientsWithRelationships.find((option: FormElementDataModel) => option.value === clientID).label;
    this.clientIdStateService.setSelectedRelationshipClient(clientID, name);
    this.resetSavedTemplates();
    this.resetEventTemplates();
    this.resetCampaignTemplates();
    this.resetSocialMediaTemplates();
    this.resetDonationFormsTemplates();
    this.resetLetterTemplates();
    this.getMerchantAccount(() => {
      this.getSavedTemplates([...this.nameFilter, ...this.templateTypeFilter]);
      this.getSavedTemplatesTotal([...this.nameFilter, ...this.templateTypeFilter]);
      this.getRestrictions();
    });
  }

  public savedTemplatesEntriesPerPageChanged(entriesPerPage: number): void {
    this.savedTemplatesEntriesPerPage = entriesPerPage;
    this.getSavedTemplates([...this.nameFilter, ...this.templateTypeFilter]);
    this.getSavedTemplatesTotal([...this.nameFilter, ...this.templateTypeFilter]);
  }

  public savedTemplatesNextPageChanged(page: number): void {
    this.currentSavedTemplatesPage = page;
    this.getSavedTemplates([...this.nameFilter, ...this.templateTypeFilter]);
  }

  public eventTemplatesEntriesPerPageChanged(entriesPerPage: number): void {
    this.eventTemplatesEntriesPerPage = entriesPerPage;
    const currentFilter = [...this.nameFilter, ...this.templateTypeFilter];
    this.getEventTemplates(currentFilter);
    this.getEventTemplatesTotal(currentFilter);
    this.getAdminEventClientTemplates(currentFilter);
    this.getAdminEventClientTemplatesTotal(currentFilter);
  }

  public eventTemplatesNextPageChanged(page: number): void {
    this.currentEventTemplatesPage = page;
    const currentFilter = [...this.nameFilter, ...this.templateTypeFilter];
    this.getEventTemplates(currentFilter);
    this.getAdminEventClientTemplates(currentFilter);
  }

  public campaignTemplatesEntriesPerPageChanged(entriesPerPage: number): void {
    this.campaignTemplatesEntriesPerPage = entriesPerPage;
    const currentFilter = [...this.nameFilter, ...this.templateTypeFilter];
    this.getCampaignTemplates(currentFilter);
    this.getCampaignTemplatesTotal(currentFilter);
    this.getAdminCampaignClientTemplates(currentFilter);
    this.getAdminCampaignClientTemplatesTotal(currentFilter);
  }

  public campaignTemplatesNextPageChanged(page: number): void {
    this.currentCampaignTemplatesPage = page;
    const currentFilter = [...this.nameFilter, ...this.templateTypeFilter];
    this.getCampaignTemplates(currentFilter);
    this.getAdminCampaignClientTemplates(currentFilter);
  }

  public donationFormsTemplatesNextPageChanged(page: number): void {
    this.currentDonationFormTemplatesPage = page;
    const currentFilter = [...this.nameFilter, ...this.templateTypeFilter];
    this.getDonationFormsTemplates(currentFilter);
    this.getAdminDonationFormsClientTemplates(currentFilter);
  }

  public letterTemplatesNextPageChanged(page: number): void {
    this.currentLetterTemplatesPage = page;
    const currentFilter = [...this.nameFilter, ...this.templateTypeFilter];
    this.getLetterTemplates(currentFilter);
    this.getAdminLettersClientTemplates(currentFilter);
  }

  public socialMediaTemplatesEntriesPerPageChanged(entriesPerPage: number): void {
    this.socialMediaTemplatesEntriesPerPage = entriesPerPage;
    const currentFilter = [...this.nameFilter, ...this.templateTypeFilter];
    this.getSocialMediaTemplates(currentFilter);
    this.getSocialMediaTemplatesTotal(currentFilter);
    this.getAdminSocialMediaClientTemplates(currentFilter);
    this.getAdminSocialMediaClientTemplatesTotal(currentFilter);
  }

  public donationFormsTemplatesEntriesPerPageChanged(entriesPerPage: number): void {
    this.donationFormTemplatesEntriesPerPage = entriesPerPage;
    const currentFilter = [...this.nameFilter, ...this.templateTypeFilter];
    this.getDonationFormsTemplates(currentFilter);
    this.getDonationFormsTemplatesTotal(currentFilter);
    this.getAdminDonationFormsClientTemplates(currentFilter);
    this.getAdminDonationFormsClientTemplatesTotal(currentFilter);
  }

  public letterTemplatesEntriesPerPageChanged(entriesPerPage: number): void {
    this.letterTemplatesEntriesPerPage = entriesPerPage;
    const currentFilter = [...this.nameFilter, ...this.templateTypeFilter];
    this.getLetterTemplates(currentFilter);
    this.getLetterTemplatesTotal(currentFilter);
    this.getAdminLettersClientTemplates(currentFilter);
    this.getAdminLetterClientTemplatesTotal(currentFilter);
  }

  public socialMediaTemplatesNextPageChanged(page: number): void {
    this.currentSocialMediaTemplatesPage = page;
    const currentFilter = [...this.nameFilter, ...this.templateTypeFilter];
    this.getSocialMediaTemplates(currentFilter);
    this.getAdminSocialMediaClientTemplates(currentFilter);
  }

  public getTemplateImgSrc(src?: string): string {
    return src ?? '/assets/images/defaultTemplate.svg';
  }

  public getSignUpTemplateImgSrc(src?: string): string {
    return src ?? '/assets/images/signup-form.png';
  }

  private get tabTotals(): number[] {
    return [
      this.eventTemplatesTotal,
      this.campaignTemplatesTotal,
      this.socialMediaTemplatesTotal,
      this.donationFormTemplatesTotal,
      this.letterTemplatesTotal
    ]
  }

  private changeTabOnResponse(): void {
    if(!this.tabTotals[this.currentTab]) {
      const index: number = this.tabTotals.findIndex((total, i) => i !== this.currentTab && total > 0);
      if(index >= 0) this.currentTab = index; 
    }
  }
}