
<div class="table-wrapper">
  <table
    *ngIf="distinctData$ | async as distinctData"
    mat-table
    [dataSource]="data"
    class="app-components-table"
  >
    <ng-container [matColumnDef]="column" *ngFor="let column of tableColumns">
      <th mat-header-cell *matHeaderCellDef class="table-header">
        <div class="table-header-title-wrapper">
          <ng-container *ngIf="column === 'multiselect'">
            <app-simple-checkbox
              [value]="isAllSelected$.getValue()"
              (clickEvent)="toggleAll($event)"
              [main]="true"
            ></app-simple-checkbox>
          </ng-container>
          <span
            *ngIf="column !== 'multiselect' && column !== 'rowActions'"
            class="title"
          >{{getColumnTitle(column) | translate}}</span>

          <span
            *ngIf="columnsWithLazyFilter.includes(column)"
            class="column-filter"
          >
            <app-lazy-select
              [items]="distinctData[column]?.items"
              [columnName]="column"
              [totalItems]="distinctData[column]?.total"
              (selectedValue)="onLazySelectFilterChanged($event, column)"
              (getOptions)="getOptions($event, column)"
            >
              <app-icons
                [iconType]="IconsType.Funnel2"
                [color]="isActiveFilter(column) ? '#0064BE' : '#798594'"
                width="18"
                height="18"
              ></app-icons>
            </app-lazy-select>
          </span>

          <span
            *ngIf="columnsWithFilter.includes(column)"
            class="column-filter"
          >
            <app-multiselect
              [columnFilterOptions]="getSelectOptions(column)"
              (selectedOptions)="onCurrentFilterChanged($event)"
            >
              <app-icons
                [iconType]="IconsType.Funnel2"
                [color]="isActiveFilter(column) ? '#0064BE' : '#798594'"
                width="18"
                height="18"
              ></app-icons>
           </app-multiselect>
          </span>

          <span
            *ngIf="columnsWithSort.includes(column)"
            class="column-filter"
            (click)="sortTable(column)"
          >
            <app-icons
              *ngIf="!checkColumn(column)"
              [iconType]="IconsType.Sort"
              width="20"
              height="20"
            ></app-icons>
            <app-icons
              *ngIf="checkColumn(column) && getSortOrder(column) === SortOrder.Ascending"
              [iconType]="IconsType.SortAscending"
              width="20"
              height="20"
              color="#0064BE"
            ></app-icons>
            <app-icons
              *ngIf="checkColumn(column) && getSortOrder(column) === SortOrder.Descending"
              [iconType]="IconsType.SortDescending"
              width="20"
              height="20"
              color="#0064BE"
            ></app-icons>
          </span>
        </div>
      </th>

      <td mat-cell *matCellDef="let element" class="app-components-table-cell" [appTooltip]="tooltip">
        <div matTooltip="{{element[column]}}" #tooltip="matTooltip" matTooltipPosition="above" matTooltipClass="table-tooltip"></div>
        <div
          *ngIf="column === 'multiselect'"
          class="table-cell-wrapper"
        >
          <app-simple-checkbox
            [value]="isAllSelected$.getValue() ? !multiselectSet.has(isDonorReportList ? element.donationId : element.id) : multiselectSet.has(isDonorReportList ? element.donationId : element.id)"
            (clickEvent)="toggleElement(isDonorReportList ? element.donationId : element.id)"
          ></app-simple-checkbox>
        </div>

        <div
          *ngIf="column === 'rowActions'"
          class="table-cell-wrapper"
        >
          <app-button
            type="icon-button"
            [iconType]="IconsType.MoreVertical"
            color="#231F20"
            [isNew]="true"
            [matMenuTriggerFor]="actionsMenu"
          ></app-button>

          <mat-menu #actionsMenu>
            <ng-container *ngFor="let action of rowActions">
              <button class="row-action-item" mat-menu-item (click)="action.trigger.next(getExportModel(element, action.iconType))">
                <app-icons [iconType]="action.iconType" color="#023665"></app-icons>
                <span>{{action.title | translate}}</span>
              </button>
            </ng-container>
          </mat-menu>
        </div>

        <ng-container
          *ngIf="column !== 'multiselect' && column !== 'rowActions'"
          class="table-cell-wrapper"
        >
          <span *ngIf="column !== rowClickColumn; else clickable">{{getTextNode(element, column)}}</span>
          <ng-template #clickable>
            <a class="name-link" (click)="navigate(element)">{{element[column]}}</a>
          </ng-template>
        </ng-container>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
  </table>
</div>

