<div
  class="custom-form-component-container-wrapper"
  [formGroup]="formGroup"
>
  <label
    class="form-field-name"
    [for]="id"
  >{{label | translate}}</label>
  <div *ngIf="!isEditMode" class="qe-buttons-wrapper">
    <app-button
      label="Editor"
      width="200"
      type="basic"
      [color]="tab === 1 ? '#0064be' : '#9ea0a5'"
      (clickEvent)="tab = 1"
    ></app-button>
    <app-button
      label="HTML"
      width="200"
      type="basic"
      [color]="tab === 2 ? '#0064be' : '#9ea0a5'"
      (clickEvent)="tab = 2"
    ></app-button>
    <app-button
      label="Preview"
      width="200"
      type="basic"
      [color]="tab === 3 ? '#0064be' : '#9ea0a5'"
      (clickEvent)="tab = 3"
    ></app-button>
  </div>
  <div
    class="qe-container" [class.active-block]="tab === 2"
    #editor
    style="width: 100%; min-height: 260px;"
  ></div>
  <div *ngIf="!isEditMode" class="qe-container" [class.active-block]="tab === 1">
    <quill-editor
      [appQuillEditorSetup]="editorInstance"
      (onEditorCreated)="onEditorCreated($event)"
      (onFocus)="onFocusEditor($event)"
      [id]="id"
      [formControlName]="name"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      (onContentChanged)="emitChanges(); setAceEditorValue()"
      [styles]="{minHeight: '260px'}"
      [modules]="modules"
      [bounds]="'self'"
      [strict]="false"
      [readOnly]="disabled"
    >
      <div quill-editor-toolbar>
        <div class="ql-formats" *ngIf="!disabled">
          <button class="ql-bold" [title]="'Bold'"></button>
          <button class="ql-italic" [title]="'Italic'"></button>
          <button class="ql-underline" [title]="'Underline'"></button>
          <button class="ql-strike" [title]="'Strike'"></button>
        </div>

        <div class="ql-formats" *ngIf="!disabled">
          <select class="ql-size">
           <!-- <option value="8px">8px</option>-->
            <option value="10px">10px</option>
           <!-- <option value="12px">12px</option>-->
            <option selected>13px</option>
            <!--<option value="14px">14px</option>-->
            <option value="16px">16px</option>
            <option value="18px">18px</option>
            <option value="20px">20px</option>
           <!-- <option value="22px">22px</option>-->
              <option value="24px">24px</option>
            <ng-container *ngIf="!isFontLimited">
              <!--<option value="28px">28px</option>-->
              <option value="36px">36px</option>
              <option value="48px">48px</option>
              <option value="64px">64px</option>
            <!-- <option value="72px">72px</option>-->
            </ng-container>
          </select>
        </div>

        <div class="ql-formats" *ngIf="!disabled">
          <button class="ql-script" value="sub"></button>
          <button class="ql-script" value="super"></button>
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
        </div>

        <div class="ql-formats" *ngIf="!disabled">
          <select class="ql-align"></select>
          <select class="ql-color"></select>
          <select class="ql-background"></select>
          <button class="ql-link"></button>
          <button class="ql-clean"></button>
        </div>

        <div class="ql-formats">
            <select class="ql-font">
	            <option value="arial" selected>Arial</option>
	            <option value="arial black">Arial Black</option>
	            <option value="calibri">Calibri</option>
	            <option value="century gothic">Century Gothic</option>
	            <option value="comic sans ms">Comic Sans MS</option>
	            <option value="courier">Courier</option>
	            <option value="franklin gothic medium">Franklin Gothic Medium</option>
	            <option value="geneva">Geneva</option>
	            <option value="georgia">Georgia</option>
	            <option value="helvetica">Helvetica</option>
	            <option value="impact">Impact</option>
	            <option value="lato">Lato</option>
	            <option value="lucida">Lucida</option>
	            <option value="lucida console">Lucida Console</option>
	            <option value="lucida grande">Lucida Grande</option>
	            <option value="lucida sans">Lucida Sans</option>
	            <option value="lucida sans unicode">Lucida Sans Unicode</option>
	            <option value="ms sans serif">MS Sans Serif</option>
	            <option value="ms serif">MS Serif</option>
	            <option value="monaco">Monaco</option>
	            <option value="new york">New York</option>
	            <option value="oswald">Oswald</option>
	            <option value="palatino">Palatino</option>
	            <option value="palatino linotype">Palatino Linotype</option>
	            <option value="roboto">Roboto</option>
	            <option value="tahoma">Tahoma</option>
	            <option value="times">Times</option>
	            <option value="times new roman">Times New Roman</option>
	            <option value="trebuchet ms">Trebuchet MS</option>
	            <option value="verdana">Verdana</option>
            </select>
        </div>

        <div class="ql-formats" *ngIf="placeholders.length && !disabled">
          <select class="ql-placeholder">
            <option value="Address" *ngIf="placeholders.includes('Address')">{{'Address' | translate}}</option>
            <option value="City" *ngIf="placeholders.includes('City')">{{'City' | translate}}</option>
            <option value="ClientPrimaryContactName" *ngIf="placeholders.includes('ClientPrimaryContactName')">{{'Client Primary Contact Name' | translate}}</option>
            <option value="DonateAmount" *ngIf="placeholders.includes('DonateAmount')">{{'Donation Amount' | translate}}</option>
            <option value="DonationDate" *ngIf="placeholders.includes('DonationDate')">{{'Donation Date' | translate}}</option>
            <option value="DonationAmountWithOrWithoutFee" *ngIf="placeholders.includes('DonationAmountWithOrWithoutFee')">{{'Donation Amount With Or Without Fee' | translate}}</option>
            <option value="DonorFirstName" *ngIf="placeholders.includes('DonorFirstName')">{{'Donor First Name' | translate}}</option>
            <option value="DonorLastName" *ngIf="placeholders.includes('DonorLastName')">{{'Donor Last Name' | translate}}</option>
            <option value="DonorName" *ngIf="placeholders.includes('DonorName')">{{'Donor Name' | translate}}</option>
            <option value="StreetAddress" *ngIf="placeholders.includes('StreetAddress')">{{'Street Address' | translate}}</option>
            <option value="StreetAddress2" *ngIf="placeholders.includes('StreetAddress2')">{{'Street Address 2' | translate}}</option>
            <option value="Email" *ngIf="placeholders.includes('Email')">{{'Email' | translate}}</option>
            <option value="Employer" *ngIf="placeholders.includes('Employer')">{{"Employer" | translate}}</option>
            <option value="Fax" *ngIf="placeholders.includes('Fax')">{{'Fax' | translate}}</option>
            <option value="Funds" *ngIf="placeholders.includes('Funds')">{{'Funds' | translate}}</option>
            <option value="FundsList" *ngIf="placeholders.includes('FundsList')">{{'Funds List' | translate}}</option>
            <option value="GrossDonationAmount" *ngIf="placeholders.includes('GrossDonationAmount')">{{'Gross Donation Amount' | translate}}</option>
            <option value="GrossLifetimeDonationAmount" *ngIf="placeholders.includes('GrossLifetimeDonationAmount')">{{"BUILD.Gross/Lifetime Donation Amount" | translate}}</option>
            <option value="LastDonationAmount" *ngIf="placeholders.includes('LastDonationAmount')">{{"Last Donation Amount" | translate}}</option>
            <option value="LastDonationDate" *ngIf="placeholders.includes('LastDonationDate')">{{"Last Donation Date" | translate}}</option>
            <option value="NextDonationDate" *ngIf="placeholders.includes('NextDonationDate')">{{'Next Donation Date' | translate}}</option>
            <option value="OrganizationName" *ngIf="placeholders.includes('OrganizationName')">{{'Organization Name' | translate}}</option>
            <option value="OrgName" *ngIf="placeholders.includes('OrgName')">{{'Organization Name' | translate}}</option>
            <option value="Phone" *ngIf="placeholders.includes('Phone')">{{'Phone' | translate}}</option>
            <option value="PrimaryContact" *ngIf="placeholders.includes('PrimaryContact')">{{'Primary Contact' | translate}}</option>
            <option value="State" *ngIf="placeholders.includes('State')">{{'State' | translate}}</option>
            <option value="TotalAmountDonated" *ngIf="placeholders.includes('TotalAmountDonated')">{{'Total Amount Donated' | translate}}</option>
            <option value="YYYY" *ngIf="placeholders.includes('YYYY')">{{'Year' | translate}}</option>
            <option value="Frequency" *ngIf="placeholders.includes('Frequency')">{{'Frequency' | translate}}</option>
            <option value="ScheduledDonationDate" *ngIf="placeholders.includes('ScheduledDonationDate')">{{'Scheduled Donation Date' | translate}}</option>
            <option value="YTDDonationAmount" *ngIf="placeholders.includes('YTDDonationAmount')">{{"BUILD.YTD Donation Amount" | translate}}</option>
            <option value="ZipCode" *ngIf="placeholders.includes('ZipCode')">{{'Zip Code' | translate}}</option>
            <option value="EventName" *ngIf="placeholders.includes('EventName')">{{'Event Name' | translate}}</option>
          </select>
        </div>
      </div>
    </quill-editor>
    <mat-error *ngIf="formControl.invalid && formControl.touched">{{getErrorMessage()}}</mat-error>
  </div>
  <div class="qe-container qe-preview" [class.active-block]="tab === 3">
    <quill-view-html [content]="getContent()" theme="snow"></quill-view-html>
  </div>

</div>
