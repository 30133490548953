import { Injectable, Injector } from '@angular/core';
import { BaseModuleGuard } from './base.module.guard';
import { DONOR_ADMIN } from '../constants';
import { NavigationCancel, RouterEvent } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DonorPortalGuard extends BaseModuleGuard {
  public returnUrl: string;

  constructor(protected injector: Injector) {
    super(injector);
  }

  public canLoad(): boolean {
    const roles = this.authService.getIdentityClaimsRole();
    const isDonorAdmin = roles?.includes(DONOR_ADMIN);
    if (isDonorAdmin) {
      return true;
    } else {
      this.router.events
        .filter((event) => event instanceof NavigationCancel)
        .subscribe((e: RouterEvent) => {
          if (e.url.includes('createP2P')) {
            this.returnUrl = e.url;
          }
        });
      setTimeout(() => {
        if (this.returnUrl) {
          this.router.navigate(['/donor-register'], {
            queryParams: { returnUrl: this.returnUrl },
          });
        } else {
          this.router.navigateByUrl('/');
          window.location.reload();
        }
      }, 500);
      return false;
    }
  }
}
