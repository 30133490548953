import { Component, Input } from '@angular/core';
import { BaseFormComponent } from '../base.form.component';
import { FbCountryStateService } from '../../../fb.country.state.service';
import CountryModel from "../../../../../models/internationalization/country.model";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['../base.form.scss']
})
export class PhoneComponent extends BaseFormComponent {
  @Input() public phonePrefix$: BehaviorSubject<string>;
  @Input() public phoneMask$: BehaviorSubject<string>;
  @Input() public phonePlaceholder$: BehaviorSubject<string>;

  constructor() {
    super();
  }

}
