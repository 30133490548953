import { AfterViewInit, Component, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseFormElementComponent } from '../base.form.element.component';
import { NgxTimepickerFieldComponent } from 'ngx-material-timepicker';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-time-select',
  templateUrl: './time-select.component.html',
  styleUrls: ['./time-select.component.scss', '../base.form.element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimeSelectComponent extends BaseFormElementComponent implements OnChanges, AfterViewInit {

  @ViewChild('ngxTimePickerFieldRef')
  public ngxTimePickerFieldRef: NgxTimepickerFieldComponent;

  constructor(public translate: TranslateService) {
    super(translate);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public ngAfterViewInit(): void {
    this.ngxTimePickerFieldRef.registerOnChange((value: string) => {
      this.formControl.setValue(value);
      this.timeValueChanged.emit(value);
    })
  }
}
