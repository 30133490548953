<div *ngIf="!landingPageView; else landingPageTemplate" class="app-thermometer-content-wrapper" #thermometer [style.transform]="transformParam$ | async">
  <ng-container *ngIf="data$ | async">
    <app-thermometer
      [eventId]="eventId"
      [entity]="entity"
    ></app-thermometer>
  </ng-container>
</div>


<ng-template #landingPageTemplate>
  <ng-container *ngIf="data$ | async">
    <app-thermometer
      [eventId]="eventId"
      [entity]="entity"
    ></app-thermometer>
  </ng-container>
</ng-template>
