import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormElementComponent } from '../base.form.element.component';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: [ '../base.form.element.component.scss', '../new.base.form.element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputComponent extends BaseFormElementComponent implements OnChanges {
  @Input() public topFix: boolean = false;
  @Input() public blockEInTypeNumber: boolean = false;
  @Input() labelFontSize: string;
  @Input() inputFontSize: string;
  @Input() labelFontFamily: string;
  @Input() inputFontFamily: string;
  @Input() labelFontColor: string;
  @Input() inputFontColor: string;
  @Input() isRequired: boolean = false;
  @ViewChild('input') public inputRef: ElementRef;
  constructor(public translate: TranslateService) {
    super(translate)
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
  public handleKeyDown(event:KeyboardEvent) {
    if(this.blockEInTypeNumber && this.type === 'number') {
      return event.code !== "KeyE"
    } else return true
  }
}
