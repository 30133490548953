<div class="tags-table-container">
    <table mat-table [dataSource]="tableSource" *ngIf="tableSource" #tableRef>
      <ng-container [matColumnDef]="column" *ngFor="let column of tableDataColumns">
        <th mat-header-cell *matHeaderCellDef class="table-header">
          <div class="table-header-title-wrapper">
            <span class="title">{{getColumnTitle(column) | translate}}</span>
            <span class="column-filter" (click)="sortTable(column)" *ngIf="isFiltersVisible && (column === 'createdOn' || column === 'numberOfUsed' || column === 'lastUsedOn' || column === 'lastUsedByUserName')">
              <i class="fas fa-sort-alpha-down sort-icon" *ngIf="checkColumn(column) && sortOrder === 0"></i>
              <i class="fas fa-sort-alpha-down-alt sort-icon" *ngIf="checkColumn(column) && sortOrder === 1"></i>
              <i class="fas fa-sort action-icon" *ngIf="!checkColumn(column)"></i>
            </span>
            <span *ngIf="isFiltersVisible && withFilter(column)" class="column-filter">
              <app-multiselect [columnFilterOptions]="getSelectOptions(column)" (selectedOptions)="selectedOptions($event)">
                <i class="fas fa-filter action-icon" [class.active-filter]="isActiveFilter(column)"></i>
              </app-multiselect>
            </span>
          </div>
        </th>

        <td mat-cell *matCellDef="let element" class="widget-table-cell" [appTooltip]="tooltip">
          <!-- Tooltip -->
          <div matTooltip="{{element[column]}}" #tooltip="matTooltip" matTooltipPosition="above" matTooltipClass="table-tooltip"></div>
          <!-- Rest columns -->
          <div #textNode >
            <span>{{getTextNode(element, column)}}</span>
          </div>
        </td>

      </ng-container>
      <ng-container matColumnDef="settings" *ngIf="isSettingsVisible">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button class="settings-container" mat-icon-button [matMenuTriggerFor]="settingsMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #settingsMenu overlapTrigger xPosition="before">
            <div class="table-action-menu">
              <div class="menu-items">
                <button mat-menu-item (click)="onEditLinkClick(element.id)">
                  <span *ngIf="!element.isDeleted">{{'Edit' | translate}}</span>
                  <span *ngIf="element.isDeleted">{{'History' | translate}}</span>
                </button>
                <button mat-menu-item (click)="onDeleteLinkClick(element.id)" *ngIf="!element.isDeleted ">Delete</button>
                <button mat-menu-item class="menu-close">
                  <i class="fas fa-times-circle"></i>
                </button>
              </div>
            </div>
          </mat-menu>

        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>
    </table>
  </div>
