<form 
  [formGroup]="settingsForm" 
  [ngClass]="{
    'is-loading': loadingData
  }"
  class="signup-setting-tab-wrapper"
  >
  <div class="preview-section">
    <h3>Preview</h3>
    <div class="preview-container">
      <app-sign-up-preview [settings]="settings"></app-sign-up-preview>
    </div>
  </div>
  <div class="settings-section">
    <mat-vertical-stepper #stepper>
      <mat-step>
        <ng-template matStepLabel>
          <div class="fb-setting-tab-label">
            <i class="fas fa-cogs"></i>
            <span>Labels</span>
          </div>
        </ng-template>
        <div class="signup-settings-form">
          <div class="input-field">
            <app-input
              class="width-100 non-padding"
              [formGroup]="settingsForm"
              name="firstNameLabel"
              label="First Name"
            ></app-input>
          </div>
          <div class="input-field">
            <app-input
              class="width-100 non-padding"
              [formGroup]="settingsForm"
              name="lastNameLabel"
              label="Last Name"
            ></app-input>
          </div>
          <div class="input-field">
            <app-input
              class="width-100 non-padding"
              [formGroup]="settingsForm"
              name="emailLabel"
              label="Email"
            ></app-input>
          </div>
          <div class="input-field">
            <app-input
              class="width-100 non-padding"
              [formGroup]="settingsForm"
              name="phoneLabel"
              label="Phone Number"
            ></app-input>
          </div>
          <div class="input-field">
            <app-select
              label="Font Size Label"
              [formGroup]="settingsForm"
              name="labelFontSize"
              errorMassage="Label Font size"
              [shouldSort]="false"
              [selectOptions]="fontSizeOptions"
            ></app-select>
          </div>
          <div class="input-field">
            <app-select
              label="Font Family Label"
              [formGroup]="settingsForm"
              name="labelFontFamily"
              errorMassage="Font Family"
              [selectOptions]="fontFamilyOptions"
            ></app-select>
          </div>
          <div class="input-field">
            <app-input
              class="width-100 non-padding"
              [formGroup]="settingsForm"
              name="labelFontColor"
              [colorPiker]="true"
              [topFix]="true"
              label="Text Color"
              [isNew]="true"
            ></app-input>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>
          <div class="fb-setting-tab-label">
            <i class="fas fa-cogs"></i>
            <span>Fields</span>
          </div>
        </ng-template>
        <div class="signup-settings-form">
          <div class="input-field">
            <app-select
              label="Font size"
              [formGroup]="settingsForm"
              name="inputFontSize"
              [shouldSort]="false"
              errorMassage="Font Size Field"
              [selectOptions]="fontSizeOptions"
              [low]="true"
            ></app-select>
          </div>
          <div class="input-field">
            <app-select
              label="Font Family Field"
              [formGroup]="settingsForm"
              name="inputFontFamily"
              errorMassage="Input Font Family"
              [selectOptions]="fontFamilyOptions"
            ></app-select>
          </div>
          <div class="input-field">
            <app-input
              class="width-100 non-padding"
              [formGroup]="settingsForm"
              name="inputFontColor"
              [colorPiker]="true"
              [topFix]="true"
              label="Text Color"
              [isNew]="true"
            ></app-input>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>
          <div class="fb-setting-tab-label">
            <i class="fas fa-cogs"></i>
            <span>Button</span>
          </div>
        </ng-template>
        <div class="signup-settings-form">
          <div class="input-field">
            <app-input
              class="width-100 non-padding"
              [formGroup]="settingsForm"
              name="buttonText"
              label="Button Text"
            ></app-input>
          </div>
          <div class="input-field">
            <app-select
              label="Font Size Button"
              [formGroup]="settingsForm"
              name="buttonFontSize"
              errorMassage="Button Font size"
              [selectOptions]="buttonFontSizeOptions"
              [low]="true"
            ></app-select>
          </div>
          <div class="input-field">
            <app-select
              label="Font Family Button"
              [formGroup]="settingsForm"
              name="buttonFontFamily"
              errorMassage="Button Font Family"
              [selectOptions]="fontFamilyOptions"
            ></app-select>
          </div>
          <div class="input-field">
            <app-input
              class="width-100 non-padding"
              [formGroup]="settingsForm"
              name="buttonTextColor"
              [colorPiker]="true"
              label="Text Color Button"
            ></app-input>
          </div>
          <div class="input-field">
            <app-input
              class="width-100 non-padding"
              [formGroup]="settingsForm"
              name="buttonColor"
              [colorPiker]="true"
              label="Button Color"
            ></app-input>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>
          <div class="fb-setting-tab-label">
            <i class="fas fa-cogs"></i>
            <span>Logo</span>
          </div>
        </ng-template>
        <div class="signup-settings-form">
          <div class="input-field">
            <span class="label">Show Logo</span>
            <div class="form-control-container">
              <app-radio
                [formGroup]="settingsForm"
                [selectOptions]="yesNoOptions"
                name="showLogo"
                (radioValueChanged)="onRadioChange($event, 'showLogo')"
                [isNew]="true"
              ></app-radio>
            </div>
          </div>
          <div class="input-field logo-section">
            <div class="d-flex align-items-center logo-container flex-wrap mb-3">
              <div class="form-control-container image-input">
                <app-image-loader
                  [formGroup]="settingsForm"
                  label="Logo/Image"
                  name="logo"
                  [imageURL]="imageURL"
                  [displayCurrentImageName]="true"
                ></app-image-loader>
              </div>
              <div class="mt-4 url-input">
                <div class="max-width-310">
                  <div class="img-setting-share-sm">
                    <app-input
                      class="max-width-145"
                      [formGroup]="settingsForm"
                      label="Image URL"
                      name="logo"
                      errorMassage="Image URL"
                      [low]="true"
                    ></app-input>
            
                    <app-button
                      label="Edit Image"
                      type="flat"
                      (clickEvent)="editImage()"
                      color="#0064be"
                      width="140"
                    ></app-button>
                  </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="input-field">
            <app-select
              label="Logo size"
              [formGroup]="settingsForm"
              name="logoSize"
              errorMassage="Logo size"
              [selectOptions]="logoSizeOptions"
              [low]="true"
            ></app-select>
          </div>
          <div class="input-field">
            <app-select
              label="Logo Position"
              [formGroup]="settingsForm"
              name="logoPosition"
              errorMassage="Logo size"
              [selectOptions]="logoPositionOptions"
              [low]="true"
            ></app-select>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>
          <div class="fb-setting-tab-label">
            <i class="fas fa-cogs"></i>
            <span>Custom Text</span>
          </div>
        </ng-template>
        <div class="signup-settings-form">
          <div>
            <app-text-editor
            [formGroup]="settingsForm"
            name="customText"
            label="Custom Title and Text"
          ></app-text-editor>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>
          <div class="fb-setting-tab-label">
            <i class="fas fa-cogs"></i>
            <span>Tags</span>
          </div>
        </ng-template>
        <div class="signup-settings-form">
          <div>
            <div class="input-field">
              <span class="label">Show Tags</span>
              <div class="form-control-container">
                <app-radio
                  [formGroup]="settingsForm"
                  [selectOptions]="yesNoOptions"
                  name="showTags"
                  (radioValueChanged)="onRadioChange($event, 'showTags')"
                  [isNew]="true"
                ></app-radio>
              </div>
            </div>
              <div class="input-field">
                <span class="label">Tag Label</span>
                <app-input
                  class="width-100 non-padding"
                  [formGroup]="settingsForm"
                  name="tagLabel"
                ></app-input>
              </div>
                <div class="form-group" *ngIf="loadingData === false">
                  <label for="existingOption">Select an existing tag or add a new tag:</label>
                  <app-tag-select
                  [selectedTags]="selectedTags"
                  [clientId]="clientId"
                  [selectOnly]="true"
                ></app-tag-select>
                </div>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>
          <div class="fb-setting-tab-label">
            <i class="fas fa-cogs"></i>
            <span>General Settings</span>
          </div>
        </ng-template>
        <div class="signup-settings-form">
          <div class="input-field">
            <div class="d-flex align-items-center flex-wrap mb-3">
              <span class="label">Show Mailing Address Fields</span>
              <div class="form-control-container">
                <app-radio
                  [formGroup]="settingsForm"
                  [selectOptions]="yesNoOptions"
                  name="showMailingAddress"
                  (radioValueChanged)="onRadioChange($event, 'showMailingAddress')"
                  [isNew]="true"
                ></app-radio>
              </div>
            </div>
          </div>
          <div class="input-field" *ngIf="settingsForm.get('showMailingAddress').value === true">
            <div class="d-flex align-items-center flex-wrap mb-3">
              <span class="label">Mailing Address Fields Required</span>
              <div class="form-control-container">
                <app-radio
                  [formGroup]="settingsForm"
                  [selectOptions]="yesNoOptions"
                  name="areMailingAddressFieldsRequired"
                  (radioValueChanged)="onRadioChange($event, 'areMailingAddressFieldsRequired')"
                  [isNew]="true"
                ></app-radio>
              </div>
            </div>
          </div>
          <div *ngIf="settingsForm.get('showMailingAddress').value === true">
            <div class="input-field">
              <app-input
                class="width-100 non-padding"
                [formGroup]="settingsForm"
                name="mailingCountryLabel"
                label="Country Label"
              ></app-input>
            </div>
            <div class="input-field">
              <app-input
                class="width-100 non-padding"
                [formGroup]="settingsForm"
                name="mailingAddressLabel"
                label="Address Label"
              ></app-input>
            </div>
            <div class="input-field">
              <app-input
                class="width-100 non-padding"
                [formGroup]="settingsForm"
                name="mailingCityLabel"
                label="City Label"
              ></app-input>
            </div>
            <div class="input-field">
              <app-input
                class="width-100 non-padding"
                [formGroup]="settingsForm"
                name="mailingStateLabel"
                label="State Label"
              ></app-input>
            </div>
            <div class="input-field">
              <app-input
                class="width-100 non-padding"
                [formGroup]="settingsForm"
                name="mailingZipCodeLabel"
                label="Zip Code Label"
              ></app-input>
            </div>
          </div>
          <div class="input-field">
            <div class="d-flex align-items-center flex-wrap mb-3">
              <span class="label">Show Phone Number</span>
              <div class="form-control-container">
                <app-radio
                  [formGroup]="settingsForm"
                  [selectOptions]="yesNoOptions"
                  name="showPhoneNumber"
                  (radioValueChanged)="onRadioChange($event, 'showPhoneNumber')"
                  [isNew]="true"
                ></app-radio>
              </div>
            </div>
          </div>
          <div class="input-field" *ngIf="settingsForm.get('showPhoneNumber').value === true">
            <div class="d-flex align-items-center flex-wrap mb-3">
              <span class="label">Phone Field Required</span>
              <div class="form-control-container">
                <app-radio
                  [formGroup]="settingsForm"
                  [selectOptions]="yesNoOptions"
                  name="isPhoneFieldRequired"
                  (radioValueChanged)="onRadioChange($event, 'isPhoneFieldRequired')"
                  [isNew]="true"
                ></app-radio>
              </div>
            </div>
          </div>
          <div class="input-field">
            <app-select
              label="Layout Style"
              [formGroup]="settingsForm"
              name="layoutStyle"
              [selectOptions]="SignUpLayoutStyle"
            ></app-select>
          </div>
          <div class="max-width-310" *ngIf="settingsForm.get('layoutStyle')?.value === 'vertical'" >
            <app-select
              label="Layout Columns"
              [formGroup]="settingsForm"
              name="layoutColumns"
              [selectOptions]="SignUpLayoutColumns"
            ></app-select>
          </div>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</form>
