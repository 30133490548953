<div class="delete-donor-wrapper">
  <div class="text-wrapper">
    <div>
      {{ data | translate }}
    </div>
    <div class="reason-wrapper">
      {{ 'DONORS.Please, enter reason why profile is being deleted' | translate }}
    </div>
    <div>
      <app-textarea errorMassage="reason" [formGroup]="reasonForm" name="reason" minRows="2" [isNew]="true"></app-textarea>
    </div>
  </div>
  <div class="actions-wrapper">
    <app-button width="100" (clickEvent)="onClose(false)" type=stroked color="#C3120E" [buttonColorType]="'warn'" [isNew]="true">{{('Cancel' | translate | uppercase)}}</app-button>
    <app-button width="100" (clickEvent)="onClose(true)" type=stroked color="#0064BE" [buttonColorType]="'primary'" [isNew]="true" [disabled]="isInvalid()">{{'DELETE' | translate}}</app-button>
  </div>
</div>
