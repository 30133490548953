import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SourceTemplateService } from '../templates/source.template.service';
import { TemplateModelService } from '../templates/template.model.service';
import TemplateModel from '../../models/templates/template.model';
import { Paging } from '../../models/paging/paging.model';
import { FilterType } from '../../models/enum/filter.type';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { ClientIdStateService } from '../client.module.state/client.id.state.service';
import { TemplateUsageType } from '../../models/templates/template.usage.type';
import { SortOrder } from '../../models/enum/sort.order';

@Injectable({
  providedIn: 'root'
})
export class CustomDonationFormsService {
  private availableForms: BehaviorSubject<TemplateModel[]> = new BehaviorSubject<TemplateModel[]>(null);
  public formList$: Observable<TemplateModel[]> = this.availableForms.asObservable()
    .pipe(
      filter(value => !!value)
    );

  constructor(
    private sourceTemplateService: SourceTemplateService,
    private templateModelService: TemplateModelService,
    private clientIdStateService: ClientIdStateService,
  ) {
    this.clientIdStateService.clientIdObservable
      .pipe(
        filter(value => !!value),
        distinctUntilChanged(),
        switchMap((clientId: string) => this.getTemplateList(clientId)),
        tap((templates: TemplateModel[]) => this.availableForms.next(templates))
      )
      .subscribe()
  }

  private getTemplateList(clientId: string): Observable<TemplateModel[]> {
    const paging: Paging = {
      includeDeleted: false,
      includeDependencies: false,
      sortOrder: SortOrder.Descending,
      sortField: 'createdOn',
      filters: [
        {
          field: 'clientId',
          value: clientId,
          type: FilterType.Equal
        },
        {
          field: 'templateUsageType',
          value: TemplateUsageType.DonationForms.toString(),
          type: FilterType.Equal
        }
      ],
      selectFields: ['id', 'name', 'imagePreviewURL', 'fbTemplateStatus', 'disableDate', 'disableTime', 'disableTimeZone', 'messageWhenDisabled'],
    };
    return this.templateModelService.getModelList(paging);
  }

  public addModel(model: TemplateModel): void {
    const nextState: TemplateModel[] = [
      model,
      ...this.availableForms.getValue()
    ];
    this.availableForms.next(nextState);
  }

  public removeModel(id: string): void {
    const nextState: TemplateModel[] = this.availableForms.getValue().filter((item => item.id !== id));
    this.availableForms.next(nextState);
  }

  public updateModel(model: TemplateModel): void {
    const index = this.availableForms.getValue().findIndex(({id}: TemplateModel) => id === model.id);
    if (index === -1) {
      return;
    }
    const nextState: TemplateModel[] = [...this.availableForms.getValue()];
    nextState[index] = {
      ...this.availableForms.getValue()[index],
      ...model,
    };
    this.availableForms.next(nextState);
  }

}
