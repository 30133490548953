import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FILE_SIZE_LIMIT } from '../../../../constants';
import { ToastrService } from 'ngx-toastr';
import { Guid } from 'guid-typescript';
import { AzureFileModel } from '../../../../models/files/azure-file.model';
import { of, Subscription } from 'rxjs';
import { AzureUploadFileService } from '../../../../services/lookup/azure-upload-file.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperModel } from '../../../../models/image.cropper.model';
import { CropperComponent } from './cropper/cropper.component';
import { first, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['../loader.scss']
})
export class ImageLoaderComponent implements OnDestroy, OnChanges {
  @Input() public formGroup: FormGroup;
  @Input() public name: string;
  @Input() public label: string;
  @Input() public readOnly: boolean = false;
  @Input() public isNew: boolean = false;
  @Input() public aspectRatio: number = 1;
  @Input() public toolTipText: string;
  @Input() public displayCurrentImageName: boolean = false
  @Input() public resizeToWidth: number = 0;
  @Input() public resizeToHeight: number = 0;

  private subscription: Subscription = new Subscription();
  @Input() public imageURL: string = null;

  @Output() public valueChanged: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('fileReaderInputRef') public fileReaderInputRef: ElementRef;
  public storageURL: string = localStorage.getItem('storageURL');
  public currentImageName: string;

  constructor(
    private toastr: ToastrService,
    private uploadService: AzureUploadFileService,
    public dialog: MatDialog
  ) { }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.imageURL && changes.imageURL.currentValue) {
      this.cropImage(null, changes.imageURL.currentValue);
    }

    if(changes.imageURL && changes.imageURL.currentValue === null) {
      this.currentImageName = null;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public fileChangeEvent(event): void {
    const file = event && event.target.files[0];
    if (file) {
      this.currentImageName = file.name;
      const fileNameSplitArray = file.name.split('.');
      const extension = fileNameSplitArray[fileNameSplitArray.length - 1].toLowerCase();
      if (file.size > FILE_SIZE_LIMIT) {
        this.showErrorMessage('Size of image can not be bigger than 3mb');
        return;
      }
      if (extension !== 'jpeg' && extension !== 'jpg' && extension !== 'png' && extension !== 'gif') {
        this.showErrorMessage('File type must be jpg, png, gif');
        return;
      }
      if (!HTMLCanvasElement.prototype.toBlob) {
        Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
          value: function(callback, type, quality) {
            const dataURL = this.toDataURL(type, quality).split(',')[1];
            setTimeout(function() {
              const binStr = atob(dataURL),
                len = binStr.length,
                arr = new Uint8Array(len);
              for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
              }
              callback(new Blob([arr], { type: type || `image/${extension}` }));
            });
          }
        });
      }
    }
    this.cropImage(event, null);
  }

  private showErrorMessage(message: string): void {
    this.toastr.error(message, 'Error');
  }

  private resetFileReaderInput() {
    if (this.fileReaderInputRef) {
      this.fileReaderInputRef.nativeElement.value = null;
    }
  }

  private cropImage(imageChangedEvent: any, imageURL: string): void {
    if (!imageChangedEvent && !imageURL) {
      return;
    }
    const data: ImageCropperModel = {
      aspectRatio: this.aspectRatio,
      resizeToWidth: this.resizeToWidth,
      resizeToHeight: this.resizeToHeight,
    }
    if (imageChangedEvent && imageChangedEvent.target.files.length > 0) {
      data.imageChangedEvent = imageChangedEvent;
    } else if (imageURL) {
      data.imageURL = imageURL;
    }
    this.dialog.open(CropperComponent, {
      width: '95vw',
      maxWidth: '800px',
      maxHeight: '95vh',
      minHeight: '618px',
      data,
    }).afterClosed()
      .pipe(
        first(),
        switchMap((response: string) => {
          if (!response) {
            return of(null);
          } else {
            const name = `${Guid.create()}.${response.substring(11, 14)}`;
            const azure: AzureFileModel = {
              file: response.substring(22, response.length),
              name,
              folder: 'images'
            };
            return this.uploadService.addModel(azure);
          }
        }),
        tap(res => {
          if (res) {
            const url = `${this.storageURL}/AzureFileStorage/image/${res.name}`;
            this.formGroup.get(this.name).setValue(url);
            this.valueChanged.emit(url);
          } else {
            this.imageURL = null;
            this.valueChanged.emit(null);
          }
          this.resetFileReaderInput();
        })
      )
      .subscribe();
  }
}
