<div class="c-topdonation">
  <div class="c-topdonation__header-wrapper">
    <div class="header-top">
      <span>{{'DASH.Top Donation by Source' | translate}}</span>
      <app-button
        label="View Top Sources"
        type="flat"
        (click)="viewTopSources()"
        color="#0064be"
      ></app-button>
    </div>
    <div class="form">
      <form [formGroup]="form">
        <app-select
          [selectOptions]="selectOptions"
          [formGroup]="form"
          name="selectedSource"
          (valueChanged)="onFilterChanged()"
          [low]="true">
        </app-select>
      </form>
    </div>
  </div>
  <div *ngIf="socialWidgetModel !== null; else shimmerTemplate" class="donations-list">
    <ng-container *ngIf="isSourceShown(topDonationsSourceType.Event)">
      <ng-container *ngIf="events.length">
        <span class="donation-source-title">{{'Events' | translate}}</span>
        <div class="donation-item" *ngFor="let item of events; index as i">
          <div>
            <span class="donation-item-index">{{i + 1}}.</span>
            <span (click)="navigateToPage(topDonationsSourceType.Event, item.id)" class="donation-item-link">{{item.name}}</span>
          </div>
          <span class="donation-item-amount">${{item.amount | number:'.2'}}</span>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isSourceShown(topDonationsSourceType.Campaign)">
      <ng-container *ngIf="campaigns.length">
        <span class="donation-source-title">{{'Campaigns' | translate}}</span>
        <div class="donation-item" *ngFor="let item of campaigns; index as i">
          <div>
            <span class="donation-item-index">{{i + 1}}.</span>
            <span (click)="navigateToPage(topDonationsSourceType.Campaign, item.id)" class="donation-item-link">{{item.name}}</span>
          </div>
          <span class="donation-item-amount">${{item.amount | number:'.2'}}</span>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isSourceShown(topDonationsSourceType.Donor)">
      <ng-container *ngIf="donors.length">
        <span class="donation-source-title">{{'Donors' | translate}}</span>
        <div class="donation-item" *ngFor="let item of donors; index as i">
          <div>
            <span class="donation-item-index">{{i + 1}}.</span>
            <img class="donor-image" [src]="getDonorImage(item)">
            <span (click)="navigateToPage(topDonationsSourceType.Donor, item.id)" class="donation-item-link mr-auto">{{item.name}}</span>
          </div>
          <span class="donation-item-amount-donor">${{item.amount | number:'.2'}}</span>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isSourceShown(topDonationsSourceType.SocialMedia)">
      <ng-container *ngIf="socialMediaPosts.length">
        <span class="donation-source-title">{{'Social Media Posts' | translate}}</span>
        <div class="donation-item" *ngFor="let item of socialMediaPosts; index as i">
          <div>
            <span class="donation-item-index">{{i + 1}}.</span>
            <span (click)="navigateToPage(topDonationsSourceType.SocialMedia, item.id)" class="donation-item-link">{{item.name}}</span>
          </div>
          <span class="donation-item-amount">${{item.amount | number:'.2'}}</span>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #shimmerTemplate>
  <div class="shimmer-list">
    <span>{{'Events' | translate}}</span>
    <ngx-shimmer-loading width="100%" height="60px"></ngx-shimmer-loading>
    <span>{{'Campaigns' | translate}}</span>
    <ngx-shimmer-loading width="100%" height="60px"></ngx-shimmer-loading>
    <span>{{'Donors' | translate}}</span>
    <ngx-shimmer-loading width="100%" height="60px"></ngx-shimmer-loading>
    <span>{{'Social Media Posts' | translate}}</span>
    <ngx-shimmer-loading width="100%" height="60px"></ngx-shimmer-loading>
  </div>
</ng-template>