import { BaseModelService } from '../base.model.service';
import { flatMap } from 'rxjs/operators';
import { FreeAddonModel } from 'src/app/models/event/free-addon.model';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Paging } from 'src/app/models/paging/paging.model';
import EventRegistrationModel, {EventRegistrationTickets} from 'src/app/models/event/event-registration.model';
import EventTicketModel from 'src/app/models/event/event-ticket.model';
import { HttpParams } from '@angular/common/http';
import { PagingHelper } from '../helper/paging.helper';
import TicketPackageModel from "../../models/event/ticket.package.model";

@Injectable({
  providedIn: 'root'
})
export class EventTicketService extends BaseModelService<EventTicketModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'EventTicket';
  }

  public resendTickets(eventId: string, paging?: Paging): Observable<number> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.post<number>(`${config.apiURL}/${this.controllerName}/ResendTickets/${eventId}`, paging);
      })
    );
  }

  public createMany(model: EventTicketModel[]): Observable<EventTicketModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.post<EventTicketModel[]>(`${config.apiURL}/EventTicket/InsertMany`, model);
      })
    );
  }

  public getEventRegistration(participantId: string): Observable<EventRegistrationModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<EventRegistrationModel>(`${config.apiURL}/EventTicket/EventRegistration/${participantId}`);
      })
    );
  }

  public processEventRegistration(model: EventRegistrationModel): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.post(`${config.apiURL}/EventTicket/ProcessEventRegistration`, model);
      })
    );
  }

  public updateEventRegistration(model: EventRegistrationModel): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.put(`${config.apiURL}/EventTicket/EventRegistration`, model);
      })
    );
  }

  public freeAddons(participantID: string): Observable<FreeAddonModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<FreeAddonModel[]>(`${config.apiURL}/EventTicket/FreeAddons/${participantID}`);
      })
    );
  }

  public getIncludes(filter?: Paging): Observable<any[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<any>(
          `${config.apiURL}/${this.controllerName}/Includes`,
          { params }
        );
      })
    );
  }

  public getAddons(filter?: Paging): Observable<any[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<any>(
          `${config.apiURL}/${this.controllerName}/Addons`,
          { params }
        );
      })
    );
  }

  public getSoldCount(filter?: Paging): Observable<any> {
    const params: HttpParams = PagingHelper.getPagingParams(filter)
    return this.configService.loadConfig()
      .pipe(
        flatMap(config => {
          return this.http.get<any>(`${config.apiURL}/${this.controllerName}/total`, {params} )
        })
      )
  }
  public deleteTicket(id: string): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/softDelete/${id}`;
        return this.http.put(url, id);
      })
    );
  }

  public checkIfTicketsAreAvailable(tickets: EventRegistrationTickets[], eventID: string): Observable<TicketPackageModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.post<TicketPackageModel[]>(`${config.apiURL}/${this.controllerName}/RequestedTicketsCheck/${eventID}`, tickets);
      })
    );
  }
}
