import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {
  @Input()
  private appTooltip: MatTooltip;

  constructor(private elementRef: ElementRef) { }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    const element = this.elementRef.nativeElement;
    /** Check whether text element width more then its container width, show tooltip if it true */
    if (element.scrollWidth > element.offsetWidth) {
      this.appTooltip.show();
    }
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.appTooltip.hide();
  }
}
