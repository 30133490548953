import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import FormElementDataModel from '../../../../models/form.element.data.model';
import {
  BORDER_STYLE,
  FONTS,
  FONT_SIZES,
  FONT_STYLE,
  FONT_WEIGHT,
  TEXT_ALIGN,
  TEXT_DECORATION,
  TOOLTIP_TEXT_FOR_FONTS
} from '../../../../constants';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { MjmlCustomType } from '../../../../models/templates/mjml.custom.type';
import { TemplateType } from '../../../../models/templates/template.type';
import { TranslateService } from '@ngx-translate/core';
import { TemplateAssignmentType } from '../../../../models/templates/template.assignment.type';
import { ActiveElementStateModel } from 'src/app/services/templates/activeElementState.model';

@Component({
  selector: 'app-button-tool-settings',
  templateUrl: './button-tool-settings.component.html',
  styleUrls: ['../tool-settings.scss']
})
export class ButtonToolSettingsComponent implements OnInit, OnDestroy {
  @Input() public tms: TemplateManagementService;
  private subscription: Subscription = new Subscription();

  public buttonForm: FormGroup = this.formBuilder.group({
    align: 'center',
    backgroundColor: '#414141',
    borderWidth: '0', // border
    borderStyle: 'solid',
    borderColor: '#414141',
    borderRadius: '3',
    color: '#ffffff',
    fontFamily: 'Roboto',
    fontSize: '13',
    fontStyle: 'normal',
    fontWeight: 'normal',
    href: '',
    innerPaddingTopBottom: '10',
    innerPaddingLeftRight: '25',
    textDecoration: 'none',
    text: [this.translate.instant('Button Text'), [Validators.required]],
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]]
  });

  public TemplateAssignmentType = TemplateAssignmentType;

  public alignOptions: FormElementDataModel[] = TEXT_ALIGN;
  public fontSizeOptions: FormElementDataModel[] = FONT_SIZES;
  public fontFamilyOptions: FormElementDataModel[] = FONTS;
  public fontStyleOptions: FormElementDataModel[] = FONT_STYLE;
  public fontWeightOptions: FormElementDataModel[] = FONT_WEIGHT;
  public textDecorationOptions: FormElementDataModel[] = TEXT_DECORATION;
  public borderStyleOptions: FormElementDataModel[] = BORDER_STYLE;
  public toolTipText: string = this.translate.instant(TOOLTIP_TEXT_FOR_FONTS);
  public isValid: Observable<boolean>;

  constructor(
    private formBuilder: FormBuilder,
    public translate: TranslateService,
  ) { }

   public ngOnInit(): void {
    this.setInitialValues();

    const customType = this.tms.activeElement.attributes['customType'];
    if (customType === MjmlCustomType.register || customType === MjmlCustomType.donate || customType === MjmlCustomType.unsubscribe || customType === MjmlCustomType.fundraiser) {
      this.buttonForm.get('href').disable();
    }

    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (!element || element.tagName !== MjmlTag.button) {
          return;
        }
        this.setInitialValues();
      })
    );

    this.subscription.add(
      this.buttonForm.statusChanges.subscribe(() => {
        const isFormValid = this.buttonForm.valid;
        const elementState: ActiveElementStateModel = { element: this.tms.activeElement, isValid: isFormValid}
        this.tms.setActiveElementState(elementState);
      })
    )

  }

  public setInnerPadding(): void {
    const innerPaddingTopBottom = this.buttonForm.get('innerPaddingTopBottom').value || '0';
    const innerPaddingLeftRight = this.buttonForm.get('innerPaddingLeftRight').value || '0';
    this.tms.activeElement.attributes['inner-padding'] = `${innerPaddingTopBottom}px ${innerPaddingLeftRight}px`;
    this.tms.emitSave(true);
  }

  public setBorder(): void {
    const borderStyle = this.buttonForm.get('borderStyle').value || 'solid';
    const borderColor = this.buttonForm.get('borderColor').value || '#414141';
    const borderWidth = this.buttonForm.get('borderWidth').value || '0';
    this.tms.activeElement.attributes['border'] = `${borderWidth}px ${borderStyle} ${borderColor}`;
    this.tms.emitSave(true);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setInitialValues(): void {
    const attributes = this.tms.activeElement.attributes;
    const text = this.tms.activeElement.content ? this.translate.instant(this.tms.activeElement.content) : null;
    const align = attributes['align'] || 'center';
    const backgroundColor = attributes['background-color'] || '#414141';
    const border = attributes['border'];
    const borderWidth = border ? border.split(' ')[0].slice(0, -2) : '0';
    const borderStyle = border ? border.split(' ')[1] : 'solid';
    const borderColor = border ? border.split(' ')[2] : '#414141';
    const borderRadius = attributes['border-radius'] ? attributes['border-radius'].slice(0, -2) : '3';
    const color = attributes['color'] || '#ffffff';
    const fontFamily = attributes['font-family'] || 'Roboto';
    const fontSize = attributes['font-size'] || '13px';
    const fontStyle = attributes['font-style'] || 'normal';
    const fontWeight = attributes['font-weight'] || 'normal';
    const href = attributes['href'] || '';
    const padding = attributes['inner-padding'];
    const innerPaddingTopBottom = padding ? padding.split(' ')[0].slice(0, -2) : '10';
    const innerPaddingLeftRight = padding ? padding.split(' ')[1].slice(0, -2) : '25';
    const textDecoration = attributes['text-decoration'] || 'none';
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';

    this.buttonForm.get('text').setValue(text);
    this.buttonForm.get('align').setValue(align);
    this.buttonForm.get('backgroundColor').setValue(backgroundColor);
    this.buttonForm.get('borderWidth').setValue(borderWidth);
    this.buttonForm.get('borderStyle').setValue(borderStyle);
    this.buttonForm.get('borderColor').setValue(borderColor);
    this.buttonForm.get('borderRadius').setValue(borderRadius);
    this.buttonForm.get('color').setValue(color);
    this.buttonForm.get('fontFamily').setValue(fontFamily);
    this.buttonForm.get('fontSize').setValue(fontSize);
    this.buttonForm.get('fontStyle').setValue(fontStyle);
    this.buttonForm.get('fontWeight').setValue(fontWeight);
    this.buttonForm.get('href').setValue(href);
    this.buttonForm.get('innerPaddingTopBottom').setValue(innerPaddingTopBottom);
    this.buttonForm.get('innerPaddingLeftRight').setValue(innerPaddingLeftRight);
    this.buttonForm.get('textDecoration').setValue(textDecoration);
    this.buttonForm.get('paddingBottom').setValue(paddingBottom.slice(0, -2));
    this.buttonForm.get('paddingLeft').setValue(paddingLeft.slice(0, -2));
    this.buttonForm.get('paddingRight').setValue(paddingRight.slice(0, -2));
    this.buttonForm.get('paddingTop').setValue(paddingTop.slice(0, -2));
  }

  public get isAttachment(): boolean {
    return this.tms.activeElement.attributes['customType'] === MjmlCustomType.attachment;
  }

  public get linkLabel(): string {
    if (this.isAttachment) {
      return 'File path';
    } else {
      return 'Link to be triggered';
    }
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public onTextChanged(): void {
    const value = (this.buttonForm.get('text').value);
    this.tms.activeElement.content = value ? this.translate.instant(value) : '';
    this.tms.emitSave(true);
  }

  public onHrefChanged(): void {
    const value = this.buttonForm.get('href').value;
    this.tms.activeElement.attributes['href'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onBackgroundColorChanged(): void {
    const value = this.buttonForm.get('backgroundColor').value;
    this.tms.activeElement.attributes['background-color'] = value ? value : '#414141';
    this.tms.emitSave(true);
  }

  public onAlignChanged(): void {
    const value = this.buttonForm.get('align').value;
    this.tms.activeElement.attributes['align'] = value ? value : 'center';
    this.tms.emitSave(true);
  }

  public onColorChanged(): void {
    const value = this.buttonForm.get('color').value;
    this.tms.activeElement.attributes['color'] = value ? value : '#ffffff';
    this.tms.emitSave(true);
  }

  public onFontFamilyChanged(): void {
    const value = this.buttonForm.get('fontFamily').value;
    this.tms.activeElement.attributes['font-family'] = value ? value : 'Roboto';
    this.tms.emitSave(true);
  }

  public onFontSizeChanged(): void {
    const value = this.buttonForm.get('fontSize').value;
    this.tms.activeElement.attributes['font-size'] = value ? value : '13px';
    this.tms.emitSave(true);
  }

  public onFontStyleChanged(): void {
    const value = this.buttonForm.get('fontStyle').value;
    this.tms.activeElement.attributes['font-style'] = value ? value : 'normal';
    this.tms.emitSave(true);
  }

  public onFontWeightChanged(): void {
    const value = this.buttonForm.get('fontWeight').value;
    this.tms.activeElement.attributes['font-weight'] = value ? value : 'normal';
    this.tms.emitSave(true);
  }

  public onTextDecorationChanged(): void {
    const value = this.buttonForm.get('textDecoration').value;
    this.tms.activeElement.attributes['text-decoration'] = value ? value : 'none';
    this.tms.emitSave(true);
  }

  public onBorderRadiusChanged(): void {
    const value = this.buttonForm.get('borderRadius').value;
    this.tms.activeElement.attributes['border-radius'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingTopChanged(): void {
    const value = this.buttonForm.get('paddingTop').value;
    this.tms.activeElement.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.buttonForm.get('paddingRight').value;
    this.tms.activeElement.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.buttonForm.get('paddingBottom').value;
    this.tms.activeElement.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.buttonForm.get('paddingLeft').value;
    this.tms.activeElement.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }
}
