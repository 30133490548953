import { Inject, Injectable } from '@angular/core';
import { OutlookUser } from 'src/app/models/externalServices/outlookUser.model';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { OutlookAuthConfig } from 'src/app/models/externalServices/outlookAuth.config';
import { Client } from '@microsoft/microsoft-graph-client';
import { filter } from 'rxjs/operators';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest } from '@azure/msal-browser';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OutlookAuthService {

  public authenticated: boolean;
  public user: OutlookUser;
  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) {
    this.setLoginDisplay();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  public checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  private setLoginDisplay() {
    this.authenticated = this.msalService.instance.getAllAccounts().length > 0;
  }

  async signIn(): Promise<void> {
    /*const result = await this.msalService.loginPopup(OutlookAuthConfig.scopes)
      .catch((reason) => {
      });

    if (result) {
      this.authenticated = true;
      //this.user = await this.getUser();
    }*/

    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
        });
    } else {
      this.msalService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
        });
    }
  }

  // Sign out
  signOut(popup?: boolean): void {
    /*this.msalService.logout();
    this.user = null;
    this.authenticated = false;*/
    if (popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: '/'
      });
    } else {
      this.msalService.logoutRedirect();
    }
  }

  // Silently request an access token
  public getAccessToken(): Observable<AuthenticationResult> {
    return this.msalService.acquireTokenPopup({...this.msalGuardConfig.authRequest} as PopupRequest);
  }

  /*private async getUser(): Promise<OutlookUser> {
    if (!this.authenticated) return null;


    const graphClient = Client.init({
      // Initialize the Graph client with an auth
      // provider that requests the token from the
      // auth service
      authProvider: async(done) => {
        //console.log(done);
        const token = await this.getAccessToken()
          .catch((reason) => {
            done(reason, null);
          });

        if (token) {
          done(null, token);
        } else {
          done('Could not get an access token', null);
        }
      }
    });
    const graphUser = await graphClient.api('/me').get()
    const user = new OutlookUser();
    user.displayName = graphUser.displayName;
    // Prefer the mail property, but fall back to userPrincipalName
    user.email = graphUser.mail || graphUser.userPrincipalName;

    return user;
  }*/
}
