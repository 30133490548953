import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {CampaignModel} from '../../../models/campaigns/campaign.model';
import {ClientAllocatedFundModel} from '../../../models/client/client-allocated-fund.model';
import {ClientAllocatedFundService} from '../../../services/client/client-allocated-fund.service';
import {ClientHonorService} from 'src/app/services/client/client-honor.service';
import {ClientModel} from '../../../models/client/client.model';
import {ClientPaymentModel} from 'src/app/models/client/client.payment.model';
import {ClientService} from '../../../services/client/client.service';
import {ClientType} from 'src/app/models/enum/client.type';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FeeAndTaxes} from '../../../models/enum/fee-and-taxes.enum';
import {FilterType} from '../../../models/enum/filter.type';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormValidators} from '../../../shared/validatiors/form-validators';
import {FundAllocationModel} from '../../../models/payments/fund.allocation.model';
import {GiftType} from '../../../models/enum/gift.type';
import {HonorModel} from 'src/app/models/client/honor.model';
import {Paging} from '../../../models/paging/paging.model';
import {PaymentBillingType} from '../../../models/enum/payment.billing.type';
import {PaymentRecurrencyStatus} from '../../../models/enum/payment.recurrency.status';
import {PaymentRepeatType} from '../../../models/enum/payment.repeat.type';
import {PaymentServiceType} from '../../../models/enum/payment.service.type';
import {SortOrder} from '../../../models/enum/sort.order';
import {StateModel} from '../../../models/state.model';
import {UtilsComponent} from '../../utils.component';
import ClientDonor from 'src/app/models/client-donor.model';
import CountryModel from '../../../models/internationalization/country.model';
import EventModel from '../../../models/event/event.model';
import EventRegistrationModel from '../../../models/event/event-registration.model';
import FbTemplateModel, {FbElementModel, FbElementType} from '../../../models/form-builder/fb.template.model';
import FormElementDataModel from '../../../models/form.element.data.model';
import RecurringSettingsModel from '../../../models/payments/recurring.settings.model';
import SocialPostModel from 'src/app/models/socialMedia/social.post.model';
import {PaymentFeeModel} from '../../../models/payments/payment.fee.model';
import {DonationPaymentPageType} from '../../../models/form-builder/donation.payment.page.type';
import {Router} from '@angular/router';
import {TiersSettingModel} from '../../../models/event/tiers.setting.model';
import {TypesOfDonationsEnum} from '../../../models/enum/types-of-donations.enum';
import { FormBuilderStateService } from '../../form-builder/form-builder.state.service';
import {EventDonationDisclaimerModel} from "../../../models/event/event-donation-disclaimer.model";
import { StripeACHService } from '../../form-builder/stripe-ach.service';
import {environment} from "../../../../environments/environment";
import { DonationRecurringOption } from 'src/app/models/enum/donation-recurring-option.enum';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
  providers: [FormBuilderStateService, StripeACHService],
  encapsulation: ViewEncapsulation.None
})
export class PaymentFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public campaign: CampaignModel;
  @Input() public client: ClientModel;
  @Input() public clientDonor: ClientDonor;
  @Input() public countries: CountryModel[] = [];
  @Input() public countriesOptions: FormElementDataModel[] = [];
  @Input() public event: EventModel;
  @Input() public eventRegistrationModel: EventRegistrationModel;
  @Input() public filledInData: any;
  @Input() public isBackButtonVisible: boolean = false;
  @Input() public isPreviewMode: boolean = false;
  @Input() public mainPayment: number;
  @Input() public mainPaymentTitle: string;
  @Input() public showDonations: boolean = true;
  @Input() public showMainPayment: boolean = false;
  @Input() public socialPost: SocialPostModel;
  @Input() public states: StateModel[] = [];
  @Input() public sourceTemplateModelId: string = null;
  @Input() public templateId: string = null;
  @Input() public genericTemplateId: string = null;
  @Input() public genericSourceTemplateId: string = null;
  @Input() public includeFee$: Observable<boolean>;
  @Output() public paymentFinished: EventEmitter<any> = new EventEmitter<any>();
  @Output() public testMicrodepositLinkRecived: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('topOfPage') private topOfPage: ElementRef;

  public donationInfoChanged$: Subject<void> = new Subject<void>();
  public clientType: ClientType;
  public entity: EventModel | CampaignModel | SocialPostModel | ClientPaymentModel;
  public paymentPageAvailable: boolean = false;
  public paymentServiceType: PaymentServiceType;
  public subscription: Subscription = new Subscription();
  public FbElementType = FbElementType;
  public isPayPage = false;
  public isOrganizationPaymentLink = false;
  public updateHeader$: Subject<void> = new Subject<void>();
  public isStripeACHEnabled: boolean;
  public paymentForm: FormGroup = this.formBuilder.group({
    ammount: ['', [Validators.required, Validators.min(5)]],
    isAnonymous: [false],
    appeal: [false],
    isEmailSend: [false],
    giftType: [GiftType.InHonorOf],
    recipientName: ['', [Validators.required]],
    recipientEmail: ['', [Validators.required, FormValidators.emailValidator]],
    emailSubject: ['', [Validators.required, Validators.maxLength(250), FormValidators.containsPlaceholder('[NamedPerson]')]],
    emailText: ['', [Validators.required, FormValidators.containsPlaceholder('[NamedPerson]')]],
    sendEmail: [new Date(), [Validators.required]],
    namedPerson: ['', [Validators.required, Validators.maxLength(50)]],
    recurring: [false],
    reccurrencyPaymentType: [PaymentBillingType.Recurring],
    donationRecurringOption: [DonationRecurringOption.Once],
    startDate: [new Date(), [Validators.required]],
    endDate: [''],
    reccurrency: [PaymentRepeatType.Monthly],
    donationFrequency: [2, [Validators.required]],
    reccurrencyValue: [1, [Validators.required]],
    everyValue: [1, [Validators.required, Validators.min(1)]],
    numberOfPeriods: ['', [Validators.min(1)]],
    amountPerTransaction: [''],
    agreedTermsConditions: [false],
    fundAllocation: this.formBuilder.array([]),
    isTier: [],
    tiers: this.formBuilder.array([]),
    customDisclaimers: this.formBuilder.array([]),
    customDonationTiers: this.formBuilder.group({
      ammount: [null,[Validators.min(5)]],
      isTire: [false],
      isInclude: [false],
      recurring: [false],
      reccurrencyPaymentType: [PaymentBillingType.Recurring],
      tierDonationRecurringOption: [DonationRecurringOption.Once],
      agreedTermsConditions: [false],
      reccurrency: [PaymentRepeatType.Monthly],
      donationFrequency: [2, [Validators.required]],
      reccurrencyValue: [1],
      everyValue: [1, [ Validators.min(1)]],
      startDate: [new Date()],
      amountPerTransaction: [null],
      endDate: [''],
      numberOfMonth: [null],
      customNumber: [null, [Validators.min(1), Validators.max(60)]],
      tagId: [],
      allowOwnAmount: [true],
      isPledgeType: [true],
      isEndDate: [true],
    })
  });
  private formValue;

  public distributeAmongFunds$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public allocatedFundsOptions: FormElementDataModel[] = [];
  public paymentFee: PaymentFeeModel = {
    paymentFee: 0.02,
    paymentCommission: 0.2,
    applicationPaymentCommission: 0,
    applicationPaymentFeePercent: 0,
    stripeACHApplicationFeePercent: 0.002,
    stripeACHFeePercent: 0.008
  };

  @Input() public fbTemplate: FbTemplateModel;

  constructor(
    private formBuilder: FormBuilder,
    public fbss: FormBuilderStateService,
    private clientHonorService: ClientHonorService,
    private clientModelService: ClientService,
    private clientAllocatedFundService: ClientAllocatedFundService,
    private route: Router,
    private stripeACHService: StripeACHService
  ) { }

  public ngOnInit(): void {
    this.isPayPage = this.route.url.includes('pay' || 'embed/pay');
    this.isOrganizationPaymentLink = this.route.url.includes('payments/client');
    this.getTier.forEach((tier, i) => {
      this.tier.push(this.tiersFormGroup);
      this.tier.controls[i].get('tierName').setValue(tier.name);
      this.tier.controls[i].get('ammount').setValue(tier.ammount);
      this.tier.controls[i].get('tagId').setValue(tier.selectedTag);
      this.tier.controls[i].get('isTier').setValue(tier.isTier);
      this.tier.controls[i].get('allowOwnAmount').setValue(tier.allowOwnAmount);
      this.tier.controls[i].get('isPledgeType').setValue(tier.isPledgeType);
      this.tier.controls[i].get('isEndDate').setValue(tier.isEndDate)
    });
    if(this.getCustomDisclaimers.length > 0)
      this.setCustomDisclaimers();

    this.stripeACHService.microdepositLink$.asObservable().subscribe(link => {
      if(link && environment.enableTestMode) this.testMicrodepositLinkRecived.emit(link)
    })
  }

  private get tiersFormGroup(): FormGroup {
    return this.formBuilder.group({
      tierName: [''],
      ammount: [null,[Validators.required, Validators.min(5)]],
      isFeeIncluded: [],
      feeAmmount: [],
      donationFee: [],
      reccuringSettings: [],
      isTier: [],
      isInclude: [false],
      recurring: [false],
      allowOwnAmount: [false],
      isPledgeType: [false],
      isEndDate: [false],
      reccurrencyPaymentType: [PaymentBillingType.Recurring],
      tierDonationRecurringOption: [DonationRecurringOption.Once],
      agreedTermsConditions: [false],
      reccurrency: [PaymentRepeatType.Monthly],
      donationFrequency: [2, [Validators.required]],
      reccurrencyValue: [1, [Validators.required]],
      everyValue: [1, [Validators.required, Validators.min(1)]],
      startDate: [new Date(), [Validators.required]],
      endDate: [''],
      ticketsCost: 0,
      numberOfMonth: [null],
      amountPerTransaction: [null],
      customNumber: [null, [Validators.required, Validators.min(1), Validators.max(60)]],
      tagId: []
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.event && changes.event.currentValue) {
      this.entity = this.event;
      this.getPaymentServiceType(this.event.clientID);
    } else if (changes.campaign && changes.campaign.currentValue) {
      this.entity = this.campaign;
      this.getPaymentServiceType(this.campaign.clientID);
    } else if (changes.socialPost && changes.socialPost.currentValue) {
      this.entity = this.socialPost;
      this.getPaymentServiceType(this.socialPost.clientID);
    } else if (changes.client && changes.client.currentValue) {
      if (!this.entity) {
        this.entity = this.getClientPaymentModel(this.client);
      }
      this.getPaymentServiceType(this.client.id);
    }
    if (changes.showDonations) {
      this.paymentPageAvailable = !this.showDonations;
    }
  }

  private setCustomDisclaimers() {
    this.getCustomDisclaimers.forEach((disclaimer, index) => {
      this.customDisclaimers.push(this.fbss.disclaimersGroup);
      this.customDisclaimers.controls[index].get('content').setValue(disclaimer.disclaimerText);
    })
  }

  private getPaymentServiceType(id: string): void {
    this.subscription.add(
      this.clientModelService.getModel(id, false).subscribe((client: ClientModel) => {
        if (client.paymentServiceType) {
          this.paymentServiceType = client.paymentServiceType;
          this.clientType = client.clientType;
          if (client.paymentFeePercent !== undefined && !isNaN(client.paymentFeePercent) && client.paymentFeePercent !== null) {
            this.paymentFee.paymentFee = client.paymentFeePercent;
          }
          if (client.paymentComission !== undefined && !isNaN(client.paymentComission) && client.paymentComission !== null) {
            this.paymentFee.paymentCommission = client.paymentComission;
          }
          this.paymentFee.applicationPaymentFeePercent = +client.applicationPaymentFeePercent;
          this.paymentFee.applicationPaymentCommission = +client.applicationPaymentComission;
          if(!!client.stripeACHApplicationFeePercent) this.paymentFee.stripeACHApplicationFeePercent = client.stripeACHApplicationFeePercent;
          if(!!client.stripeACHFeePercent) this.paymentFee.stripeACHFeePercent = client.stripeACHFeePercent;
        }
        const isFundsRequired = client.isFundsRequired;
        if ((this.isPayPage && this.isCustom) || (this.isPayPage && isFundsRequired && this.isDefaultAuxilia) || (isFundsRequired && this.isDefaultAuxilia) || this.isCustom || this.isPayPage) {
          this.fetchAllocatedFunds(client.id);
        } else {
          this.distributeAmongFunds$.next(false);
        }
        this.isStripeACHEnabled = client?.isStripeACHEnabled;
      })
    );
  }

  private get isDefaultAuxilia(): boolean {
    return this.entity.donationPaymentPage === DonationPaymentPageType.AuxiliaDefault;
  }

  public get isCustom(): boolean {
    return this.entity.donationPaymentPage === DonationPaymentPageType.Custom || this.isCustomDefault;
  }

  public get isCustomDefault(): boolean {
    // @ts-ignore
    return this.entity.donationPaymentPage === DonationPaymentPageType.ClientDefault;
  }
  private getClientPaymentModel(client: ClientModel): ClientPaymentModel {
    const model = new ClientPaymentModel();
    model.clientModel = client;
    model.clientID = client.id;
    model.name = client.name;
    // model.hasAppeal = true;
    //model.feeAndTaxes = FeeAndTaxes.ProvideOption;
    model.id = client.id;
    return model;
  }

  public get ammount(): FormControl {
    return this.paymentForm.get('ammount') as FormControl;
  }

  public get tier(): FormArray {
    return this.paymentForm.get('tiers') as FormArray;
  }

  public get isAnonymous(): FormControl {
    return this.paymentForm.get('isAnonymous') as FormControl;
  }

  public get appeal(): FormControl {
    return this.paymentForm.get('appeal') as FormControl;
  }

  public get isEmailSend(): FormControl {
    return this.paymentForm.get('isEmailSend') as FormControl;
  }

  public get recurring(): FormControl {
    return this.paymentForm.get('recurring') as FormControl;
  }

  public get reccurrencyPaymentType(): FormControl {
    return this.paymentForm.get('reccurrencyPaymentType') as FormControl;
  }

  public get startDate(): FormControl {
    return this.paymentForm.get('startDate') as FormControl;
  }

  public get endDate(): FormControl {
    return this.paymentForm.get('endDate') as FormControl;
  }

  public get reccurrency(): FormControl {
    return this.paymentForm.get('reccurrency') as FormControl;
  }

  public get reccurrencyValue(): FormControl {
    return this.paymentForm.get('reccurrencyValue') as FormControl;
  }

  public get everyValue(): FormControl {
    return this.paymentForm.get('everyValue') as FormControl;
  }

  public get numberOfPeriods(): FormControl {
    return this.paymentForm.get('numberOfPeriods') as FormControl;
  }

  public get amountPerTransaction(): FormControl {
    return this.paymentForm.get('amountPerTransaction') as FormControl;
  }

  public get fundAllocation(): FormArray {
    return this.paymentForm.get('fundAllocation') as FormArray;
  }

  public get customDisclaimers(): FormArray {
    return this.paymentForm.get('customDisclaimers') as FormArray;
  }

  public get getTier(): TiersSettingModel[] {
    return JSON.parse(this.infoPage.tiersOptions);
  }
  public get getCustomDisclaimers():EventDonationDisclaimerModel[] {
    return this.infoPage.customDisclaimers;
  }

  public get isDonationStandard(): boolean {
    return this.infoPage.typesOfDonations === TypesOfDonationsEnum.Standard;
  }

  public donate(): void {
    this.formValue = this.paymentForm.getRawValue();
    this.paymentPageAvailable = true;
    UtilsComponent.scrollIntoView(this.topOfPage);
  }

  public onPaymentFinished(event): void {
    if (this.appeal.value) {
      const honorModel: HonorModel = {
        email: this.formValue.recipientEmail,
        subject: this.formValue.emailSubject,
        text: this.formValue.emailText
          .replace('[RecipientName]', this.formValue.giftType === GiftType.InHonorOf ? this.formValue.namedPerson : this.formValue.recipientName)
          .replace('[DonationAmount]', `$ ${this.ammount.value}`)
          .replace('[DonorName]', this.isAnonymous.value === true ? 'Anonymous donor' : event.cardHolderName)
          .replace('[NamedPerson]', this.formValue.namedPerson),
        sendDate: UtilsComponent.getOffsetDate(this.formValue.sendEmail, '+00:00', '12:00 PM'),
        giftType: this.formValue.giftType,
        clientId: this.campaign ? this.campaign.clientID : this.event ? this.event.clientID : this.socialPost ? this.socialPost.clientID : this.client.id
      };
      if (this.isEmailSend && this.isEmailSend.value) {
        this.subscription.add(this.clientHonorService.addModel(honorModel).subscribe());
      }
    }
    const result: any = { ...event, ...this.formValue };
    const fee = event.ammount - (this.formValue?.ammount ?? this.mainPayment)
    result.fee = (Math.round((fee + Number.EPSILON) * 100) / 100);
    result.ammount = Number(event.ammount).toFixed(2);
    result.microdepositVerificationHash = this.stripeACHService.microdepositVerificationHash;

    this.paymentFinished.emit(result);
  }

  public getRecurringPayload(): RecurringSettingsModel {
    if (!this.recurring.value) {
      return null;
    }
    let donationRecurrence = 1;

    if (this.reccurrency.value === PaymentRepeatType.Weekly) {
      // Get current week day
      donationRecurrence = new Date().getDay();
    } else if (this.reccurrency.value === PaymentRepeatType.Monthly
        || this.reccurrency.value === PaymentRepeatType.Yearly) {
      // Get current month day
      donationRecurrence = new Date().getDate();
    }

    const recurringSettingsModel: RecurringSettingsModel = {
      clientId: this.entity.clientID,
      reccurrency: this.reccurrency.value,
      reccurrencyValue: donationRecurrence,
      startDate: this.startDate.value ? new Date(this.startDate.value).toLocaleDateString() : null,
      reccurrencyPaymentType: this.reccurrencyPaymentType.value,
      everyValue: this.everyValue.value,
      maxCount: this.numberOfPeriods.value,
      endDate: this.endDate.value,
      ammount: 0,
      currency: 'USD',
      recurrencyStatusEnum: PaymentRecurrencyStatus.Active
    };
    if (this.event) {
      recurringSettingsModel.eventId = this.event.id;
    }
    if (this.campaign) {
      recurringSettingsModel.campaignId = this.campaign.id;
    }
    if (this.socialPost) {
      recurringSettingsModel.socialMediaPost = this.socialPost.id;
    }
    if(this.genericTemplateId) {
      recurringSettingsModel.templateID = this.genericTemplateId;
    }
    switch (this.reccurrencyPaymentType.value) {
      case PaymentBillingType.Recurring:
        recurringSettingsModel.ammount = this.ammount.value;
        break;
      case PaymentBillingType.Pledge:
        recurringSettingsModel.ammount = this.amountPerTransaction.value;
        break;
    }
    return recurringSettingsModel;
  }

  private fetchAllocatedFunds(clientID: string): void {
    const paging: Paging = {
      sortField: 'name',
      sortOrder: SortOrder.Ascending,
      includeDependencies: false,
      includeDeleted: false,
      filters: [
        {
          field: 'clientID',
          value: clientID,
          type: FilterType.Equal,
        },
        {
          field: 'isActive',
          value: 'true',
          type: FilterType.Equal,
        }
      ]
    };
    if (this.isDefault) {
      this.subscription.add(
        this.getAllocatedFunds(paging)
      );
      return;
    }
    if (this.isCustom || this.isPayPage) {
      if ((this.isCustomDefault && (JSON.parse(this.infoPage.funds).length === 0)) ||
        // @ts-ignore
        (this.isOrganizationPaymentLink && !this.entity?.clientModel?.defaultTemplateId) ||
        // @ts-ignore
        (this.isOrganizationPaymentLink && this.entity?.clientModel?.defaultTemplateId && (JSON.parse(this.infoPage.funds).length === 0))) {
        this.subscription.add(
          this.getAllocatedFunds(paging)
        );
        return;
      }
      let state;
      if (this.isDonationStandard) {
        state = !!JSON.parse(this.infoPage.funds).length
      } else {
        state = !!JSON.parse(this.infoPage.tiersOptions).length;
      }
      this.distributeAmongFunds$.next(state);
      this.allocatedFundsOptions = JSON.parse(this.infoPage.funds);
    }
  }

  private getAllocatedFunds(paging: Paging): Subscription {
    return this.clientAllocatedFundService.getModelList(paging).subscribe((funds: ClientAllocatedFundModel[]) => {
      const state = !!funds.length;
      this.distributeAmongFunds$.next(state);
      this.allocatedFundsOptions = funds.map(({ name, id }) => ({ label: name, value: id }));
    })
  }

  public get isDefault(): boolean {
    // @ts-ignore
    // if (this.entity?.clientModel) {
    //   // @ts-ignore
    //   return this.entity?.donationPaymentPage === DonationPaymentPageType?.AuxiliaDefault || !this.entity?.clientModel?.defaultTemplateId;
    // }
    return this.entity?.donationPaymentPage === DonationPaymentPageType?.AuxiliaDefault;
  }

  public get infoPage(): FbElementModel {
    if (!this.fbTemplate) {
      return null;
    }
    return this.fbTemplate.infoPage.elements.find(element => element.type === FbElementType.DonationInfo)
  }

  public getFundAllocation(): FundAllocationModel[] {
    if (!this.distributeAmongFunds$.getValue()) {
      return [];
    } else {
      const fundAllocation = this.fundAllocation.value.filter(({ fundId }) => !!fundId);
      return fundAllocation && fundAllocation.length ? fundAllocation : [];
    }
  }

  public goBack(): void {
    this.paymentPageAvailable = false;
  }

  public get elementStyle(): {[key: string]: string} {
    if (!this.fbTemplate) {
      return {};
    }
    const {
      spacing = 10,
    }: FbTemplateModel = this.fbTemplate;
    return {
      'padding-bottom': `${spacing}px`,
    };
  }
}
