<div class="wrapper-admin-confirm-component p-3">
    <h1 mat-dialog-title>{{data.title}}</h1>
    <input matInput (keydown.enter)="onOKClick()" type="text" [formControl]="formControl" class="input" maxlength="260">
    <div class="error" *ngIf="formControl.touched">
      {{getError | translate}}
    </div>
    <div mat-dialog-actions class="justify-content-end">
      <button color="primary" mat-button mat-stroked-button [disabled]="!formControl.value" (click)="onOKClick()">{{'Save' | translate}}</button>
      <button color="primary" mat-button mat-stroked-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
    </div>
  </div>
