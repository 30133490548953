import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilderStateService } from '../../../form-builder.state.service';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import { LABEL_ALIGN } from '../../../../../constants';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AlignmentType, FbElementModel, FbElementType } from '../../../../../models/form-builder/fb.template.model';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-comment-options',
  templateUrl: './comment-options.component.html',
  styleUrls: ['../../fb.page.scss']
})
export class CommentOptionsComponent implements OnInit, OnDestroy {
  @Input() public fbss: FormBuilderStateService;

  public labelAlignmentOptions: FormElementDataModel[] = LABEL_ALIGN;

  public optionsForm: FormGroup = this.formBuilder.group({
    mainLabel: '',
    mainLabelAlignment: null,
    subLabel: '',
    required: false
  });

  public isSetAsFormDefault: FormControl = this.formBuilder.control(false);

  private element: FbElementModel;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    public translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.fbss.activeElementSettings$.asObservable()
        .pipe(
          filter(value => value === FbElementType.Comments),
          tap(() => {
            this.element = this.fbss.template$.getValue().paymentPage.elements
              .find(({type}: FbElementModel) => type === FbElementType.Comments);
            this.setInitialValues();
          }),
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setInitialValues(): void {
    const {
      mainLabel = this.translate.instant('Add a Comment:'),
      mainLabelAlignment = AlignmentType.Top,
      subLabel = this.translate.instant('Let us know what you think about our work!'),
    }: {[key: string]: string} = this.element.attributes;
    this.control('mainLabel').setValue(mainLabel);
    this.control('mainLabelAlignment').setValue(mainLabelAlignment);
    this.control('subLabel').setValue(subLabel);
    this.control('required').setValue(this.element.required)
  }

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }

  public onLabelAlignmentChanged(): void {
    this.element.attributes.mainLabelAlignment = this.control('mainLabelAlignment').value;
    this.isSetAsFormDefault.value && this.setAsFormDefault();
    this.fbss.templateWasChanged();
  }

  public onIsSetAsFormDefaultChanged(): void {
    const next = !this.isSetAsFormDefault.value;
    this.isSetAsFormDefault.setValue(next);
    if (next) {
      this.setAsFormDefault();
      this.fbss.templateWasChanged();
    }
  }

  public setAsFormDefault(): void {
    this.fbss.setAsFormDefault(this.control('mainLabelAlignment').value);
  }

  public control(controlName: string): FormControl {
    return this.optionsForm.get(controlName) as FormControl;
  }

  public onControlValueChanged(controlName: string): void {
    let value = this.control(controlName).value;
    if (controlName === 'subLabel' && !value) {
      value = this.translate.instant('Let us know what you think about our work!');
    }
    this.element.attributes[controlName] = value;
    this.fbss.templateWasChanged();
  }

  public onRequiredChanged(): void {
    const next = !this.control('required').value;
    this.control('required').setValue(next);
    this.element.required = next;
    this.fbss.templateWasChanged();
  }


}
