<div class="email-builder-tool-settings-wrapper">
  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-select
        label="Font Family"
        [formGroup]="form"
        name="fontFamily"
        errorMassage="Font Family"
        [selectOptions]="fontFamilyOptions"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onFontFamilyChanged()"
        [isNew]="true"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-slider
        [formGroup]="form"
        name="fontSize"
        label="Font Size"
        [min]="8"
        [max]="24"
        [step]="1"
        [tickInterval]="1"
        (valueChanged)="onFontSizeChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-checkbox
        [formGroup]="form"
        [isLow]="true"
        name="showDonationAmount"
        label='Show Donation Amount'
        (valueChanged)="onColorAndTextValueChanged('showDonationAmount', 'show-donation-amount')"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-checkbox>
    </div>
  </div>

  <div class="email-builder-tool-settings-row margin-top-20">
    <div class="max-width-310">
      <app-checkbox
        [formGroup]="form"
        [isLow]="true"
        name="showDonateNowButton"
        label='Show "Donate Now" Button'
        (valueChanged)="onColorAndTextValueChanged('showDonateNowButton', 'show-donate-now-button')"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-checkbox>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        [disabled]="readOnly || isPredesignedThemedTemplate"
        [formGroup]="form"
        errorMassage="Donate Button Text"
        label="Donate Button Text"
        name="donateButtonText"
        [maxLength]="25"
        (inputEvent)="onColorAndTextValueChanged('donateButtonText', 'donate-button-text')"
      ></app-input>
    </div>
  </div>
  <!--  color-section-->
  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        class="non-padding"
        [formGroup]="form"
        name="donateButtonColor"
        [colorPiker]="true"
        [isNew]="true"
        label='Donate Button Color'
        errorMassage="field"
        (inputEvent)="onColorAndTextValueChanged('donateButtonColor', 'donate-button-color')"
      >
      </app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        class="non-padding"
        [formGroup]="form"
        name="donateButtonColorText"
        [colorPiker]="true"
        [isNew]="true"
        label='Donate Button Text Color'
        errorMassage="field"
        (inputEvent)="onColorAndTextValueChanged('donateButtonColorText', 'donate-button-color-text')"
      >
      </app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        class="non-padding"
        [formGroup]="form"
        name="viewAllBorderColor"
        [colorPiker]="true"
        [isNew]="true"
        label='View All Border Color'
        errorMassage="field"
        (inputEvent)="onColorAndTextValueChanged('viewAllBorderColor', 'view-all-border-color')"
      >
      </app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        class="non-padding"
        [formGroup]="form"
        name="viewAllTextColor"
        [colorPiker]="true"
        [isNew]="true"
        label='View All Text Color'
        errorMassage="field"
        (inputEvent)="onColorAndTextValueChanged('viewAllTextColor', 'view-all-text-color')"
      >
      </app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        class="non-padding"
        [formGroup]="form"
        name="topDonationsBorderColor"
        [colorPiker]="true"
        [isNew]="true"
        label='Top Donations Border Color'
        errorMassage="field"
        (inputEvent)="onColorAndTextValueChanged('topDonationsBorderColor', 'top-donations-border-color')"
      >
      </app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        class="non-padding"
        [formGroup]="form"
        name="topDonationsTextColor"
        [colorPiker]="true"
        [isNew]="true"
        label='Top Donations Text Color'
        errorMassage="field"
        (inputEvent)="onColorAndTextValueChanged('topDonationsTextColor', 'top-donations-text-color')"
      >
      </app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="form"
        name="paddingTop"
        label="Padding Top"
        [min]="0"
        [max]="100"
        [step]="1"
        [isNew]="true"
        [tickInterval]="10"
        (valueChanged)="onPaddingValueChanged('paddingTop', 'padding-top')"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="form"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="100"
        [step]="1"
        [isNew]="true"
        [tickInterval]="10"
        (valueChanged)="onPaddingValueChanged('paddingRight', 'padding-right')"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="form"
        name="paddingBottom"
        label="Padding Bottom"
        [min]="0"
        [max]="100"
        [step]="1"
        [isNew]="true"
        [tickInterval]="10"
        (valueChanged)="onPaddingValueChanged('paddingBottom', 'padding-bottom')"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="form"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="100"
        [step]="1"
        [isNew]="true"
        [tickInterval]="10"
        (valueChanged)="onPaddingValueChanged('paddingLeft', 'padding-left')"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row" *ngIf="!(readOnly || isPredesignedThemedTemplate)">
    <div
      class="margin-bottom-40"
    >
      <app-button
        type="basic"
        label="Delete content"
        (clickEvent)="tms.removeActiveElementFromColumn()"
        color="#f44336"
        width="300"
      ></app-button>
    </div>
  </div>

</div>
