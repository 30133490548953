import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DonationActivityPopupDataModel,
  DonationActivityPopupType
} from '../../../../models/templates/donation.activity.popup.data.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DonationActivityService } from '../donation-activity/donation-activity.service';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EventDonationActivityModel } from '../../../../models/event/event.donation.activity.model';
import { BasePagingComponent } from '../../../paginator/base.paging.component';
import { UtilsComponent } from '../../../utils.component';

export const POPUP_TITLE = {
  [DonationActivityPopupType.TopDonations]: 'TOP DONATIONS',
  [DonationActivityPopupType.AllDonations]: 'ALL DONATIONS',
};

@Component({
  selector: 'app-donation-activity-popup',
  templateUrl: './donation-activity-popup.component.html',
  styleUrls: ['./donation-activity-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DonationActivityService],
})
export class DonationActivityPopupComponent extends BasePagingComponent implements OnInit {
  public popupType$: BehaviorSubject<DonationActivityPopupType> = new BehaviorSubject<DonationActivityPopupType>(this.data.popupType);

  public popupTitle$: Observable<string>;
  public total$: Observable<number>;

  public DonationActivityPopupType = DonationActivityPopupType;

  public donations$: Observable<EventDonationActivityModel[]>;

  public pagingSource$: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<DonationActivityPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DonationActivityPopupDataModel,
    public donationActivityService: DonationActivityService,
    private router: Router,
  ) {
    super();
  }

  public ngOnInit(): void {
    if(!this.data.fundraiserId) {
      this.total$ = this.donationActivityService.getTotal(this.data.eventId)
      .pipe(
        tap((total: number) => this.total = total)
      );
    } else {
      this.total$ = this.donationActivityService.getFundraiserTotal(this.data.eventId, this.data.fundraiserId)
      .pipe(
        tap((total: number) => this.total = total)
      );
    }
    

    this.popupTitle$ = this.popupType$.asObservable()
      .pipe(
        map((popupType: DonationActivityPopupType) => POPUP_TITLE[popupType])
      );

    this.donations$ = this.popupType$.asObservable()
      .pipe(
        switchMap((popupType: DonationActivityPopupType) => {
          switch (popupType) {
            case DonationActivityPopupType.TopDonations:
              if(!this.data.fundraiserId) return this.donationActivityService.getTopDonations(this.data.eventId, this.data.showDonationAmount);
              return this.donationActivityService.getFundraiserTopDonations(this.data.eventId, this.data.fundraiserId, this.data.showDonationAmount);
            case DonationActivityPopupType.AllDonations:
              return this.pagingSource$.asObservable()
                .pipe(
                  startWith(''),
                  switchMap(() => {
                    if(!this.data.fundraiserId) {
                      return this.donationActivityService.getDonations(
                        false,
                        this.data.eventId,
                        this.data.showDonationAmount,
                        UtilsComponent.getFirstItemOnPage(this.total, this.currentPage, this.entriesPerPage),
                        this.entriesPerPage
                      );
                    }

                    return this.donationActivityService.getFundraiserDonations(
                      this.data.eventId, 
                      this.data.fundraiserId, 
                      UtilsComponent.getFirstItemOnPage(this.total, this.currentPage, this.entriesPerPage),
                      this.entriesPerPage
                    )
                  })
                );
          }
        })
      );
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public changeType(popupType: DonationActivityPopupType): void {
    this.popupType$.next(popupType);
    this.onFilterChanged();
  }

  public donateNow(): void {
    this.router.navigateByUrl(`/payments/event/${this.data.eventId}`)
      .then(() => this.dialogRef.close());
  }

  public getNextPage(page: number): void {
    this.nextPage(page, () => this.pagingSource$.next());
  }

  public setEntriesPerPage(amount: number): void {
    this.onFilterChanged();
    this.entriesPerPageChanged(amount, () => this.pagingSource$.next());
  }

}
