import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { ClientEmployeeModel } from '../models/client/client.employee.model';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UtilsComponent } from '../components/utils.component';
import { ConfigService } from './appconfig.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private tokenSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoggedIn: Observable<boolean>;
  public isLoggedOut: Observable<boolean>;
  private getUserEmailParamSubj: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private stsServerURL: string = localStorage.getItem('stsServerURL');

  private pendo$: Subject<string> = new Subject<string>();
  public setPendoIdentifyModel$: Observable<string> = of(null); // subscribe in ClientsComponent



  constructor(
    private http: HttpClient,
    private oauthService: OAuthService,
    public router: Router,
    private config: ConfigService,
  ) {
    this.tokenSubject.next(this.oauthService.hasValidAccessToken());
    this.isLoggedIn = this.tokenSubject.asObservable();
    this.isLoggedOut = this.isLoggedIn.pipe(map(loggedId => !loggedId));
  }

  public setIsLoggedIn(isLoggedIn: boolean): void {
    this.tokenSubject.next(isLoggedIn);
  }

  public clearStorage(): void {
    localStorage.clear();
    this.config.loadConfig()
      .pipe(
        first()
      )
      .subscribe(response => {
        localStorage.setItem('apiurl', response.apiURL);
        localStorage.setItem('stsServerURL', response.stsServerURL);
        localStorage.setItem('appVersion', response.appVersion);
        localStorage.setItem('storageURL', response.storageURL);
      });
    sessionStorage.removeItem('clientsWithRelationshipsToCurrentClient');
    sessionStorage.removeItem('donorsComponentSelectedIds');
    if (sessionStorage.getItem('tagManagerPaging')) {
      sessionStorage.removeItem('tagManagerPaging');
    }
  }

  public setUserEmailparam(param: string): void {
    this.getUserEmailParamSubj.next(param);
  }

  public getUserEmail(): Observable<string> {
    return this.getUserEmailParamSubj.asObservable();
  }

  private get logoutRequest(): Observable<any> {
    if (this.masqueradeAs) {
      return this.http.get(`${localStorage.getItem('stsServerURL')}/logout?name=${this.oauthService.getIdentityClaims()['given_name']}`);
    }
    else {
      return this.http.get(`${localStorage.getItem('stsServerURL')}/logout?name=${this.getIdentityClaimsName()}`);
    }
  }

  public getIdentityClaimsName(): string {
    return this.tokenSubject.getValue()
      ? this.masqueradeAs ? this.masqueradeAsRecord.email : this.oauthService.getIdentityClaims()['given_name'] || localStorage.getItem('given_name')
      : null;
  }

  public getIdentityClaimsId(): string {
    return this.tokenSubject.getValue()
      ? this.masqueradeAs ? this.masqueradeAsRecord.id : this.oauthService.getIdentityClaims()['sub']
      : null;
  }


  public getIdentityClaimsRole(): string[] {
    return this.tokenSubject.getValue()
      ? this.masqueradeAs ? [...this.masqueradeAsRecord.role] : this.oauthService.getIdentityClaims()['role'] || localStorage.getItem('roleCopy')
      : null;
  }

  public getOriginalRole(): string[] | string {
    return this.oauthService.getIdentityClaims()['role'];
  }

  public getIdentityClaimsOriginId(): string {
    return this.tokenSubject.getValue()
      ? this.masqueradeAs ? this.masqueradeAsRecord.clientID : this.oauthService.getIdentityClaims()['origin_id'] || localStorage.getItem('origin_id')
      : '';
  }

  public getIdentityClaimsUserType(): string[] {
    return this.tokenSubject.getValue() ? this.oauthService.getIdentityClaims()['user_type'] : null;
  }

  public getIdentityClaimsDonorId(): string {
    return this.oauthService.getIdentityClaims()['donor_id'] === "null" || !this.oauthService.getIdentityClaims()['donor_id'] ? localStorage.getItem('helper_id') : this.oauthService.getIdentityClaims()['donor_id'];
  }

  public getIdentityClaimsClientEmployeeId(): string {
    return this.oauthService.getIdentityClaims()['clientEmployee_id'] === "null" || !this.oauthService.getIdentityClaims()['clientEmployee_id'] ? localStorage.getItem('helperEmployee_id') : this.oauthService.getIdentityClaims()['clientEmployee_id'];
  }

  public getIdentityClaims(): any {
    return this.tokenSubject.getValue() ? this.oauthService.getIdentityClaims() : null;
  }

  public get masqueradeAs(): string {
    const storageRecord = sessionStorage.getItem('masqueradeAs');
    return storageRecord ? storageRecord : '';
  }

  public get masqueradeAsRecord(): ClientEmployeeModel {
    return JSON.parse(this.masqueradeAs);
  }

  public cleanMasquerade(): void {
    sessionStorage.removeItem('masqueradeAs');
  }

  public onLogout(isForced: boolean) {
    if (this.tokenSubject.getValue() && this.oauthService.hasValidAccessToken()) {
      this.logoutRequest
        .pipe(
          first()
        )
        .subscribe()
    }
    this.logoutFlow(isForced);
  }

  private logoutFlow(isForced: boolean): void {
    //console.log('isForced logout Flow:', isForced);
    /*if (isForced) {
      this.router.navigate(['/'], {
        queryParams: {redirectURL: window.location.pathname}
      }).then(() => window.location.reload());
    } else {
      this.router.navigateByUrl('/').then(() => window.location.reload());
    }*/

    this.oauthService.logOut();
    this.tokenSubject.next(false);
    this.cleanMasquerade();
    this.clearStorage();
    //console.log('cleanMasquerade and clearStorage done');
    if (isForced) {
      this.router.navigate(['/'], {
        queryParams: {redirectURL: window.location.pathname}
      }).then(() => window.location.reload());
    } else {
      this.router.navigateByUrl('/')/*.then(() => window.location.reload())*/;
    }
  }
}
