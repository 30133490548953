import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from '../base.model.service';
import { TemplateClientRestrictionModel } from 'src/app/models/templates/template.client.restriction.model';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TemplateClientRestrictionService extends BaseModelService<
  TemplateClientRestrictionModel
> {
  constructor(protected injector: Injector) {
    super(injector);
  }
  protected get controllerName(): string {
    return 'TemplateClientRestriction';
  }

  public createMany(
    model: TemplateClientRestrictionModel[]
  ): Observable<TemplateClientRestrictionModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        return this.http.post<TemplateClientRestrictionModel[]>(
          `${config.apiURL}/${this.controllerName}/InsertMany`,
          model
        );
      })
    );
  }

  public updateMany(
    model: TemplateClientRestrictionModel[]
  ): Observable<TemplateClientRestrictionModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        return this.http.put<TemplateClientRestrictionModel[]>(
          `${config.apiURL}/${this.controllerName}/UpdateMany`,
          model
        );
      })
    );
  }
}
