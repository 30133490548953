import { ClientIdStateService } from '../../../../services/client.module.state/client.id.state.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from '../../../../services/dashboard/dashboard.service';
import { DonationStatusType } from '../../../../models/enum/donation.status.type';
import { EventDonationModel } from '../../../../models/event/event-donation.model';
import { EventGoalType } from 'src/app/models/enum/event.goal';
import { EventParticipantsService } from '../../../../services/events/event.participants.service';
import { EventStatus } from '../../../../models/enum/event.status';
import { Filter } from '../../../../models/paging/filter.model';
import { FilterType } from '../../../../models/enum/filter.type';
import { find, sumBy } from 'lodash';
import { interval, Observable, of, Subscription, zip } from 'rxjs';
import { MS_PER_DAY } from '../../../../constants';
import { Paging } from '../../../../models/paging/paging.model';
import { PriceTicket } from '../../../../models/enum/event.price.ticket';
import { SortOrder } from '../../../../models/enum/sort.order';
import EventGoalModel from '../../../../models/event/event.goal.model';
import EventModel from '../../../../models/event/event.model';
import EventParticipantModel from '../../../../models/event/event.participant.model';
import TicketPackageModel from '../../../../models/event/ticket.package.model';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { tap } from 'rxjs/internal/operators/tap';
import EventRegistrationsCountModel from 'src/app/models/event/event.registrations-count.model';

@Component({
  selector: 'app-highlighted-data',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.scss']
})
export class UpcomingEventsComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();
  private events: EventModel[] = [];
  public activeEvent: EventModel;
  public index: number = null;
  public interval$: Observable<number> = interval(30000);
  public EventGoalType = EventGoalType;
  public PriceTicket = PriceTicket;
  public existingGoals = {
    [EventGoalType.NumberAttendees]: 'Number of Attendees',
    [EventGoalType.TicketSaleTotalNumber]: 'Number of Tickets Sold',
    [EventGoalType.TicketSaleIncrease]: 'Increase ticket sales',
    [EventGoalType.Revenue]: 'Revenue',
    [EventGoalType.DonationRevenue]: 'Donation Revenue',
    [EventGoalType.GrossRevenue]: 'Gross Revenue'
  };
  public showSpinner: boolean = true;
  public registrationsToEvent: number = 0;
  public soldTickets: number = 0;
  public loadingRegistrations: boolean;

  constructor(
    private clientIdStateService: ClientIdStateService,
    private dashboardService: DashboardService,
    private eventParticipantsService: EventParticipantsService
  ) {
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.clientIdStateService.clientIdObservable
        .pipe(
          distinctUntilChanged(),
          switchMap(this.getEvents.bind(this))
        )
        .subscribe()
    );
    this.subscription.add(
      this.interval$
        .pipe(
          switchMap(() => {
            if (!this.events.length) {
              return of(null);
            }
            this.index = this.index === (this.events.length - 1) ? 0 : this.index + 1;
            this.activeEvent = this.events[this.index];
            return this.getRegistrations();
          })
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getEvents(clientId: string): Observable<EventRegistrationsCountModel[]> {
    this.showSpinner = true;
    const filters: Filter[] = [
      {
        field: 'clientID',
        value: clientId,
        type: FilterType.Equal
      },
      {
        field: 'status',
        value: `array["${EventStatus.Live}"]`,
        type: FilterType.Equal
      },
      {
        field: 'startDate',
        // 30 business days ~ 6 weeks ~ 42 days
        value: new Date(Date.now() + 42 * MS_PER_DAY).toJSON(),
        type: FilterType.LessThanOrEqual
      },
      {
        field: 'startDate',
        value: new Date().toJSON(),
        type: FilterType.GreaterThan
      }
    ];
    const paging: Paging = {
      includeDependencies: true,
      filters,
      sortField: 'createdOn',
      sortOrder: SortOrder.Descending,
      selectFields: ['goalList', 'id', 'name', 'priceTicketType', 'startDate', 'startTime', 'ticketPackageList']
    };

    return this.dashboardService.getUpcomingEventsList(paging)
      .pipe(
        tap((value) => {
          if (!value || !value.length) {
            this.showSpinner = false;
            this.activeEvent = null;
            this.events = [];
          }
        }),
        filter(value => !!value && !!value.length),
        switchMap((eventList: EventModel[]) => {
          this.events = this.addTicketsData(eventList);
          const ids = eventList.map(({id}: EventModel) => `"${id}"`).join(',');
          return this.getDonations(ids);
        }),
        switchMap(() => {
          this.activeEvent = this.events.length ? this.events[0] : null;
          this.index = this.events.length ? 0 : null;
          this.showSpinner = false;
          return this.getRegistrations();
        }),
      );
  }

  private getDonations(ids: string): Observable<any> {
    const filters: Filter[] = [
      {
        field: 'eventID',
        value: `array[${ids}]`,
        type: FilterType.Equal,
      }
    ];

    const donationsPaging: Paging = {
      includeDependencies: false,
      filters: [
        ...filters,
        {
          field: 'donationStatus',
          type: FilterType.Equal,
          value: `array["${DonationStatusType.Authorized}","${DonationStatusType.Completed}"]`
        }
      ],
      selectFields: ['eventID', 'donationAmmount']
    };
    const donations$ = this.dashboardService.getUpcomingEventsDonationsList(donationsPaging);

    return donations$
      .pipe(
        tap((donationsList: EventDonationModel[]) => {
          this.events = this.events.map((event: EventModel) => {
            let received = 0;
            donationsList.filter(({eventID}: EventDonationModel) => eventID === event.id)
              .forEach(({donationAmmount}: EventDonationModel) => received += +donationAmmount);
            event.donations.received = received;
            return {...event};
          });
        })
      );
  }

  private addTicketsData(eventList: EventModel[]): EventModel[] {
    return eventList.map((event: EventModel) => {
      const {ticketPackageList}: EventModel = event;
      let amount = 0;
      let sold = 0;
      let goal = 0;
      ticketPackageList.forEach(({number, soldCount, price, inPackageCount}: TicketPackageModel) => {
        amount += +number * +inPackageCount;
        sold += +soldCount * +inPackageCount;
        goal += +number * +price;
      });
      return {...event, tickets: {sold, amount}, donations: {goal, received: 0}};
    })
  }

  public getRemainingString(): string {
    const remaining = this.getRemaining();
    if (remaining)
      return (+remaining.toFixed(2)).toLocaleString('en-GB');
  }

  public getRemaining(): number | null {
    if (!this.activeEvent) {
      return null;
    }
    let tickets = 0;
    if (this.activeEvent && this.activeEvent.participants) {
      tickets = this.activeEvent.participants.length;
    }
    const {goal}: any = this.activeEvent.donations;
    const eventGoal = find(this.activeEvent.goalList, (goalItem: EventGoalModel) => goalItem.type === EventGoalType.TicketSaleIncrease);
    if (!!eventGoal === false) {
      return null;
    }
    const price = goal / this.activeEvent.tickets.amount;
    return ((+eventGoal.value - tickets) * price) <= 0 ? 0 : ((+eventGoal.value - tickets) * price);
  }

  public getGoalValue(goal: EventGoalModel): string {
    if (goal.type === EventGoalType.TicketSaleIncrease) {
      const eventGoal = find(this.activeEvent.goalList, (goalItem: EventGoalModel) => goalItem.type === EventGoalType.TicketSaleIncrease);
      let tickets = 0;
      if (this.activeEvent && this.activeEvent.participants) {
        tickets = this.activeEvent.participants.length;
      }
      return (+eventGoal.value - tickets).toString();
    } else if (goal.type === EventGoalType.GrossRevenue || goal.type === EventGoalType.DonationRevenue || goal.type === EventGoalType.Revenue) {
      return `$${this.getRevenueValue(goal).toFixed(2)}/$${(+goal.value).toFixed(2)}`;
    } else {
      return goal.value;
    }
  }

  private getRevenueValue(goal: EventGoalModel): number {
    switch (goal.type) {
      case EventGoalType.Revenue:
        //  Revenue will factor in two aspects of your event: Ticket sales and registration fees.
        return sumBy(this.activeEvent.participants, (item: EventParticipantModel) => item.ticket.actualPrice);
      case EventGoalType.DonationRevenue:
        // Donation Revenue will be focusing solely on how much revenue your organization brings in, just in donations from the event.
        return this.activeEvent.donations.received;
      case EventGoalType.GrossRevenue:
        // Gross Revenue will factor in all aspects of revenue of your event, including: Ticket sales, registration fees, and donations
        return sumBy(this.activeEvent.participants, (item: EventParticipantModel) => item.ticket.actualPrice) + this.activeEvent.donations.received;
    }
  }

  public hasGoal(): boolean {
    if (!this.activeEvent || !this.activeEvent.goalList || !this.activeEvent.goalList.length) {
      return false;
    } else if (this.activeEvent.goalList.length > 1) {
      return true;
    } else {
      return this.activeEvent.goalList[0].type !== EventGoalType.NoGoal;
    }
  }

  public isIncreaseTicketSales(goalType: EventGoalType): boolean {
    return goalType === EventGoalType.TicketSaleIncrease;
  }

  private getRegistrations(): Observable<EventRegistrationsCountModel[]> {
    if (!this.activeEvent) {
      this.registrationsToEvent = 0;
      this.soldTickets = 0;
      return;
    }

    this.loadingRegistrations = true;
    
    const paging: Paging = {
      includeDependencies: true,
      selectFields: ['eventID', 'numberInParty'],
      filters: [
        {
          field: 'eventID',
          value: this.activeEvent.id,
          type: FilterType.Equal
        }
      ]
    };

    return this.eventParticipantsService.getEventRegistrationsCount(paging)
      .pipe(
        tap((registrations: EventRegistrationsCountModel[]) => {
          this.registrationsToEvent = registrations[0]?.registrationsCount || 0;
          this.soldTickets = registrations[0]?.ticketsCount || 0;
          this.loadingRegistrations = false;
        })
      );
  }
}
