import { ButtonToggleModel } from '../../models/buttonToggleModel';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatMenuTrigger } from '@angular/material/menu';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filtered-combobox',
  templateUrl: './filtered-combobox.component.html',
  styleUrls: ['./filtered-combobox.component.scss']
})
export class FilteredComboboxComponent implements OnChanges {
  @Input() public buttonEnablingValues: string[] = [];
  @Input() public errorMassage: string;
  @Input() public formGroup: FormGroup;
  @Input() public header: string;
  @Input() public isClearButtonVisible: boolean;
  @Input() public isCustomValueAddingAllow: boolean = false;
  @Input() public isReadOnly: boolean;
  @Input() public items: FormElementDataModel[] = [];
  @Input() public modalLabel: string;
  @Input() public name: string;
  @Input() public toggleOptions: Array<ButtonToggleModel> = [];
  @Output() public changeFilterType: EventEmitter<MatButtonToggleChange> = new EventEmitter<MatButtonToggleChange>();
  @Output() public selectedValue: EventEmitter<string> = new EventEmitter<string>();
  public label: string;

  @ViewChild(MatMenuTrigger)
  public menu: MatMenuTrigger;

  constructor(
    public translate: TranslateService,
  ) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && changes.items.currentValue && changes.items.currentValue.length) {
      const value = this.items.find(x => x.value === this.formValue);
      if (value) {
        this.label = value.label;
      }
    }
  }

  public get formControl(): FormControl {
    return this.formGroup.get(this.name) as FormControl;
  }

  private get formValue(): string {
    return this.formControl.value;
  }

  public selectValue(value: FormElementDataModel): void {
    this.formControl.markAsTouched();
    this.formControl.setValue(value.value);
    this.label = value.label;
    this.menu.closeMenu();
    this.selectedValue.emit(value.value);
  }

  public clear(): void {
    this.formControl.setValue('');
    this.label = '';
    this.selectedValue.next('');
    this.menu.closeMenu();
  }

  public getErrorMessage(): string {
    if (this.formControl.hasError('required')) {
      return `${this.translate.instant(this.errorMassage)} ${this.translate.instant('is required')}`;
    }
  }

  public onChangeFilterType($event): void {
    this.changeFilterType.emit($event);
  }
}
