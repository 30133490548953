import { Injectable } from '@angular/core';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { RequestCacheService } from '../services/requestCache.service';

import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

const TTL = 432000;

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cacheableUrls = [
    'statelist',
    'subscriptionPlan',
    'timezonelist',
    'campaignPlaceholders',
    'charitableinterests',
    'industryTypes',
    'Skills',
    'campaignPlaceholders',
    'VolunteerPreferences',
    'clientcauses'
  ];
  constructor(private cache: RequestCacheService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const cachedResponse = this.cache.get(req.url);
    return cachedResponse
      ? of(cachedResponse)
      : this.sendRequest(req, next);
  }

  sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse && req.method === 'GET') {
          const url = /[^/]*$/.exec(req.url)[0];
          if (this.cacheableUrls.indexOf(url) > -1) {
            this.cache.set(req.url, event, TTL);
          }
        }
      })
    );
  }
}
