export enum DonationSourceType {
  Other = 0, //Default value
  Event = 1,
  Campaign = 2,
  SocialPost = 3,
  DirectClient = 4,
}

export const DonationSourceTypeView = {
  [DonationSourceType.Other]: 'Other',
  [DonationSourceType.Event]: 'Event',
  [DonationSourceType.Campaign]: 'Campaign',
  [DonationSourceType.SocialPost]: 'Social Media',
  [DonationSourceType.DirectClient]: 'Donors'
};
