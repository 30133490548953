<div class="email-builder-tool-settings-wrapper">
  <div class="email-builder-tool-settings-row">
    <app-image-loader
      [formGroup]="imageForm"
      name="url"
      [label]="uploadLabel"
      [readOnly]="readOnly || isPredesignedThemedTemplate"
      [imageURL]="imageURL"
      (valueChanged)="imageUrlChanged($event); onUrlChanged()"
    ></app-image-loader>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-input
        [label]="urlLabel"
        [formGroup]="imageForm"
        name="url"
        errorMassage="Image URL"
        [low]="true"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="imageUrlChanged($event); onUrlChanged()"
      ></app-input>
    </div>
    <app-button
      *ngIf="imageForm.get('url') && imageForm.get('url').value && !imageURL && !readOnly && !isPredesignedThemedTemplate"
      label="Edit Image"
      type="flat"
      (clickEvent)="editImage()"
      color="#0064be"
      width="180"
    ></app-button>
    <!--<app-button
      *ngIf="!!imageURL"
      label="Cancel"
      type="flat"
      (clickEvent)="cancelEditImage()"
      color="#ec4c47"
      width="140"
    ></app-button>-->
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="imageForm"
        name="width"
        [label]="imageForm.get('width').value ? 'Width (px)' : 'Width (auto)'"
        [min]="0"
        [max]="columnWidth"
        [step]="20"
        [tickInterval]="100"
        (valueChanged)="onWidthChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="imageForm"
        name="height"
        [label]="imageForm.get('height').value ? 'Height (px)' : 'Height (auto)'"
        [min]="0"
        [max]="1920"
        [step]="20"
        [tickInterval]="100"
        (valueChanged)="onHeightChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="imageForm"
        name="borderWidth"
        label="Border width"
        [min]="0"
        [max]="50"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="setBorder()"
        [disabled]="readOnly"
      ></app-slider>
    </div>

    <div class="max-width-145">
      <app-select
        label="Border style"
        [formGroup]="imageForm"
        name="borderStyle"
        errorMassage="Border style"
        [selectOptions]="borderStyleOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="setBorder()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="imageForm"
        label="Border color"
        name="borderColor"
        [readOnly]="readOnly"
        (colorChanged)="setBorder()"
      ></app-email-color-picker>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="imageForm"
        name="paddingTop"
        label="Padding Top"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingTopChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="imageForm"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingRightChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="imageForm"
        name="paddingBottom"
        label="Padding Bottom"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingBottomChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="imageForm"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingLeftChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        label="Link to redirect to on click"
        [formGroup]="imageForm"
        name="href"
        errorMassage="URL"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onHrefChanged()"
      ></app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        label="Tooltip"
        [formGroup]="imageForm"
        name="title"
        errorMassage="Tooltip"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onTitleChanged()"
        [toolTipText]="message"
      ></app-input>

      <!-- <div
        class="settings-info-icon"
        [matTooltip]="message"
        matTooltipHideDelay="200"
        matTooltipShowDelay="200"
        matTooltipPosition="above"
        matTooltipClass="settings-tooltip-custom-class"
      >
        <i class="fas fa-info-circle"></i>
      </div> -->
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">

      <app-slider
        [formGroup]="imageForm"
        name="borderRadius"
        label="Border radius"
        [min]="0"
        [max]="500"
        [step]="10"
        [tickInterval]="10"
        (valueChanged)="onBorderRadiusChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>

    <div class="max-width-145">
      <app-select
        label="Position"
        [formGroup]="imageForm"
        name="align"
        errorMassage="Position"
        [selectOptions]="alignOptions"
        [low]="true"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onAlignChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row" *ngIf="!(readOnly || isPredesignedThemedTemplate)">
    <app-button
      type="basic"
      label="Delete content"
      (clickEvent)="tms.removeActiveElementFromColumn()"
      color="#f44336"
      width="300"
    ></app-button>
  </div>
</div>
