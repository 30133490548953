import {Injectable, Injector} from '@angular/core';
import {BaseModelService} from "../base.model.service";
import {EventDonationDisclaimerModel} from "../../models/event/event-donation-disclaimer.model";

@Injectable({
    providedIn: "root"
})
export class EventDonationDisclaimerService extends BaseModelService<EventDonationDisclaimerModel> {
    constructor(protected injector: Injector) {
        super(injector);
    }
    protected get controllerName(): string {
        return 'EventDonationDisclaimer';
    }
}