import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ImageBuilderStateService } from '../../../image-builder.state.service';
import { Subscription } from 'rxjs';
import { LayerModel } from '../../../../../models/image-builder/layer.model';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-common-layer-styling',
  templateUrl: './common-layer-styling.component.html',
  styleUrls: ['../styling.component.scss']
})
export class CommonLayerStylingComponent implements OnInit, OnDestroy {
  @Input() public ibss: ImageBuilderStateService;
  private subscription: Subscription = new Subscription();

  public commonLayerForm: FormGroup = this.formBuilder.group({
    transparency: 100,
  });

  constructor(
    private formBuilder: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.subscription.add(
      this.ibss.activeLayerObservable.subscribe((layer: LayerModel) => {
        if (!layer) {
          return;
        }
        this.setInitialValues();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public setInitialValues(): void {
    const transparency = this.ibss.activeLayer.transparency;
    this.commonLayerForm.get('transparency').setValue(transparency * 100);
  }

  public onTransparencyChanged(): void {
    const value = this.commonLayerForm.get('transparency').value;
    this.ibss.activeLayer.transparency = value ? value / 100 : 0;
    this.ibss.saveTemplate();
  }

  public duplicate(): void {
    if (this.readOnly || this.themedTemplate || this.componentBasedTemplate) {
      return;
    }
    this.ibss.duplicateLayer();
  }

  public delete(): void {
    if (this.readOnly || this.themedTemplate || this.componentBasedTemplate) {
      return;
    }
    this.ibss.deleteLayer();
  }

  public bringForward(): void {
    if (this.readOnly || this.themedTemplate || this.componentBasedTemplate) {
      return;
    }
    this.ibss.bringLayerForward();
  }

  public sendBackward(): void {
    if (this.readOnly || this.themedTemplate || this.componentBasedTemplate) {
      return;
    }
    this.ibss.sendLayerBackward();
  }

  public get readOnly(): boolean {
    return this.ibss.readOnly;
  }

  public get themedTemplate(): boolean {
    return this.ibss.themedTemplate;
  }

  public get componentBasedTemplate(): boolean {
    return this.ibss.componentBasedTemplate;
  }
}
