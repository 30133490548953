import {AdminConfirmationComponent} from 'src/app/routes/admin/admin-confirmation/admin-confirmation.component';
import {AuthService} from 'src/app/services/auth.service';
import {ClientIdStateService} from 'src/app/services/client.module.state/client.id.state.service';
import {ClientModuleService} from 'src/app/routes/clients/client.module.service';
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {CLIENT_ADMIN, CONTENT_CREATOR, EVENT_LIST_COLUMNS, FUNDRAISING_MANAGER} from 'src/app/constants';
import {EventParticipantsService} from 'src/app/services/events/event.participants.service';
import {EventService} from 'src/app/services/events/event.service';
import {EventStatus} from 'src/app/models/enum/event.status';
import {Filter} from 'src/app/models/paging/filter.model';
import {FilterType} from 'src/app/models/enum/filter.type';
import {MatDialog} from '@angular/material/dialog';
import {Paging} from 'src/app/models/paging/paging.model';
import {Router} from '@angular/router';
import {SortOrder} from 'src/app/models/enum/sort.order';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import ColumnFilterModel from 'src/app/models/filter-sort/column.filter.model';
import ColumnFilterOptionsModel from 'src/app/models/filter-sort/column.filter.options.model';
import EventModel from 'src/app/models/event/event.model';
import FilterSortModel from 'src/app/models/filter-sort/filter.sort.model';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import TicketPackageModel from 'src/app/models/event/ticket.package.model';
import { FormGroup } from '@angular/forms';
import { UtilsComponent } from '../utils.component';
import EventRegistrationsCountModel from 'src/app/models/event/event.registrations-count.model';
import {TicketStatus} from "../../models/event/event-ticket.model";
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-events-table',
  templateUrl: './events-table.component.html',
  styleUrls: ['./events-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EventsTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public clientsWithRelationships: FormElementDataModel[] = [];
  @Input() public isDraft: boolean = false;
  private baseFilter: Filter[];
  private registrations: EventRegistrationsCountModel[] = [];
  private setAllEventsDefaultPage: Subject<number> = new Subject<number>();
  public allEventsEntriesPerPage: number = 10;
  public allEventsTableColumns: string[] = [];
  public allEventsTableData: EventModel[] = [];
  public allEventsTotal: number = 0;
  public currentAllEventsPage: number = 1;
  public currentClientID: string = this.authService.getIdentityClaimsOriginId();
  public nameFilter: Filter[] = [];
  public searchEventsByName: Subject<string> = new Subject<string>();
  public searchInputValue: string = '';
  public setAllEventsDefaultPage$: Observable<number> = this.setAllEventsDefaultPage.asObservable();
  public subscription: Subscription = new Subscription();
  public userName: string = this.authService.getIdentityClaimsName();
  public isAllPermissionAvailable = false;

  constructor(
    private authService: AuthService,
    private clientIdStateService: ClientIdStateService,
    private clientModuleService: ClientModuleService,
    private dialog: MatDialog,
    private eventParticipantsService: EventParticipantsService,
    private eventService: EventService,
    private router: Router,
    private toastrService: ToastrService,
    private datePipe: DatePipe,
    private translate: TranslateService
  ) {
  }

  private allListFilterSortConfig: FilterSortModel = {
    sortField: 'startDate',
    sortOrder: SortOrder.Descending,
    columnFilters: [
      {
        field: 'clientID',
        values: [this.currentClientID]
      }
    ]
  };

  public columnsFilterOptions: ColumnFilterOptionsModel[] = [{
    columnName: 'status',
    options: [
      {
        label: 'Live',
        value: EventStatus.Live
      },
      {
        label: 'Canceled',
        value: EventStatus.Canceled
      },
      {
        label: 'Complete',
        value: EventStatus.Complete
      }
    ]
  }];

  private draftBaseFilter: Filter[] = [
    {
      field: 'status',
      value: EventStatus.Draft.toString(),
      type: FilterType.Equal,
    }
  ];

  private eventsStatusFilter: Filter[] = [
    {
      field: 'status',
      value: EventStatus.Draft.toString(),
      type: FilterType.NotEqual,
    },
    {
      field: 'status',
      value: EventStatus.Archived.toString(),
      type: FilterType.NotEqual,
    }
  ];

  private draftListFilterSortConfig: FilterSortModel = {
    sortField: 'createdOn',
    sortOrder: SortOrder.Descending,
    columnFilters: [
      {
        field: 'clientID',
        values: [this.currentClientID]
      }
    ]
  };

  public ngOnInit(): void {
    this.getUser();
    this.searchByEventNameSubscription();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.clientsWithRelationships && changes.clientsWithRelationships.currentValue && changes.clientsWithRelationships.currentValue.length) {
      this.columnsFilterOptions.push({columnName: 'clientID', options: [...this.clientsWithRelationships]});
      this.baseFilter = this.getBaseFilter();
      if (this.clientsWithRelationships.length < 2) {
        this.allEventsTableColumns = (this.isDraft === false) ? EVENT_LIST_COLUMNS.filter((column: string) => column !== 'clientID') : EVENT_LIST_COLUMNS.filter((column: string) => column !== 'status' && column !== 'registration' && column !== 'secondaryEventsList' && column !== 'clientID');
      } else {
        this.allEventsTableColumns = (this.isDraft === false) ? EVENT_LIST_COLUMNS : EVENT_LIST_COLUMNS.filter((column: string) => column !== 'status' && column !== 'registration' && column !== 'secondaryEventsList');
      }
      this.subscription.add(
        this.clientIdStateService.clientIdObservable.subscribe((clientId: string) => {
          const columnFilter: ColumnFilterModel = {field: 'clientID', values: [clientId]};
          this.onCurrentFilterChanged(columnFilter, this.isDraft);
          this.currentClientID = clientId;
        })
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public navigateToEventDashboard(event: EventModel): void {
    this.router.navigateByUrl(`clients/events/dashboard/${event.id}`);
  }

  public cancelArchiveEvent(event: EventModel): void {
    const eventModel = {
      ...event,
      updatedByID: this.authService.getIdentityClaimsId(),
      clientID: this.authService.getIdentityClaimsOriginId(),
      updatedByUserName: this.authService.getIdentityClaimsName(),
    };
    let action = '';
    switch (event.status) {
      case EventStatus.Live:
        action = 'cancel';
        break;
      case EventStatus.Canceled:
      case EventStatus.Complete:
        action = 'archive';
        break;
    }
    const config = {
      data: {
        title: this.translate.instant(`Would you like to ${action} this Event? All campaigns connected to this event will be archived.`)
      }
    };
    this.subscription.add(
      this.dialog.open(AdminConfirmationComponent, config).afterClosed()
        .subscribe((result: boolean) => {
          if (!result) {
            return;
          } else {
            switch (event.status) {
              /** Cancel event */
              case EventStatus.Live:
                const canceledEvent: EventModel = {
                  ...eventModel,
                  status: EventStatus.Canceled,
                };
                this.eventService.updateModel(canceledEvent)
                  .subscribe(() => {
                    this.showSuccessMessage(this.translate.instant('Event has been canceled successfully!'));
                    this.getEvents();
                    this.getTotalEvents();
                  });
                break;
              /** Archive event */
              case EventStatus.Complete:
              case EventStatus.Canceled:
                const archivedEvent: EventModel = {
                  ...eventModel,
                  status: EventStatus.Archived,
                };
                this.eventService.updateModel(archivedEvent)
                  .subscribe(() => {
                    this.showSuccessMessage(this.translate.instant('Event has been archived successfully!'));
                    this.getEvents();
                    this.getTotalEvents();
                  });
                break;
            }
          }
        })
    );
  }

  private showSuccessMessage(message: string): void {
    this.toastrService.success(message, this.translate.instant('Notification'));
  }

  public onCurrentFilterChanged(columnFilter: ColumnFilterModel, isDraftList: boolean): void {
    this.getFilterSortConfig(isDraftList).columnFilters = UtilsComponent.updateColumnFilters(columnFilter, this.getFilterSortConfig(isDraftList));
    isDraftList ? this.applyDraftListFilter() : this.applyAllListFilter();
  }

  public getFilterSortConfig(isDraftList: boolean): FilterSortModel {
    return isDraftList ? this.draftListFilterSortConfig : this.allListFilterSortConfig;
  }

  private applyDraftListFilter(): void {
    this.setAllEventsDefaultPage.next(1);
    this.currentAllEventsPage = 1;
    this.getDrafts();
    this.getTotalDrafts();
  }

  private applyAllListFilter(): void {
    this.setAllEventsDefaultPage.next(1);
    this.currentAllEventsPage = 1;
    this.getEvents();
    this.getTotalEvents();
  }

  private getUser(): void {
    this.subscription.add(
      this.clientModuleService.userName.subscribe((userName: string) => this.userName = userName)
    );
    const roles = this.authService.getIdentityClaimsRole();
    this.isAllPermissionAvailable = this.rolePermission();
  }
  public rolePermission(): boolean {
    const roles = this.authService.getIdentityClaimsRole();
    return roles.includes(CLIENT_ADMIN) || roles.includes(FUNDRAISING_MANAGER) || roles.includes(CONTENT_CREATOR);
  }

  private getEvents(): void {
    const paging: Paging = {
      first: UtilsComponent.getFirstItemOnPage(this.allEventsTotal, this.currentAllEventsPage, this.allEventsEntriesPerPage),
      rows: this.allEventsEntriesPerPage,
      includeDependencies: true,
      filters: this.getFilters(false),
      sortField: this.allListFilterSortConfig.sortField,
      sortOrder: this.allListFilterSortConfig.sortOrder,
      selectFields: ['createdByUserName', 'createdOn', 'endDate', 'name', 'priceTicketType', 'secondaryEventsList', 'startDate', 'status', 'ticketPackageList', 'id', 'status', 'isSecondaryEvent', 'clientID', 'timeZoneID', 'startTime', 'hasRegistrationForm']
    };
    this.subscription.add(
      this.eventService.getModelList(paging)
        .pipe(
          switchMap((eventList: EventModel[]) => {
           eventList.forEach(event => {
              let endDate = this.datePipe.transform(event.endDate, 'yyyy')
              if (endDate === "2137") event.endDate = '';
           });
            this.allEventsTableData = this.addTicketsData(eventList);
            const ids = eventList.map(({id}: EventModel) => `"${id}"`).join(',');
            return this.getRegistrations(ids);
          })
        )
        .subscribe(() => {
          this.allEventsTableData = this.allEventsTableData.map((event: EventModel) => {
            const currRegistration: EventRegistrationsCountModel = this.registrations.find((registration: EventRegistrationsCountModel) => registration.eventId === event.id);
            event.registration = currRegistration?.registrationsCount;
            event.tickets.sold = currRegistration?.ticketsCount;
            return event
          });
        })
    );
  }

  private getDrafts(): void {
    const paging: Paging = {
      first: UtilsComponent.getFirstItemOnPage(this.allEventsTotal, this.currentAllEventsPage, this.allEventsEntriesPerPage),
      rows: this.allEventsEntriesPerPage,
      includeDependencies: true,
      filters: this.getFilters(true),
      sortField: this.draftListFilterSortConfig.sortField,
      sortOrder: this.draftListFilterSortConfig.sortOrder,
      selectFields: ['createdByUserName', 'createdOn', 'endDate', 'name', 'priceTicketType', 'startDate', 'ticketPackageList', 'id', 'status', 'isSecondaryEvent', 'clientID'],
    };
    this.subscription.add(
      this.eventService.getModelList(paging).subscribe((eventList: EventModel[]) => this.allEventsTableData = this.addTicketsData(eventList))
    );
  }

  private getTotalEvents(): void {
    const paging: Paging = {
      includeDependencies: false,
      filters: this.getFilters(false),
    };
    this.subscription.add(
      this.eventService.getTotal(paging).subscribe((total: number) => this.allEventsTotal = total)
    );
  }

  private getTotalDrafts(): void {
    const paging: Paging = {
      includeDependencies: false,
      filters: this.getFilters(true)
    };
    this.subscription.add(
      this.eventService.getTotal(paging).subscribe((total: number) => this.allEventsTotal = total)
    );
  }

  private getFilters(isDraftList: boolean): Filter[] {
    const tableFilter: Filter[] = this.getTableFilter(isDraftList);
    const baseStatusFilter: Filter[] = isDraftList ? this.draftBaseFilter : this.eventsStatusFilter;
    const clientFilter: Filter[] = !tableFilter.find(({field}: Filter) => field === 'clientID') ? this.baseFilter : [];
    const statusFilter: Filter[] = !tableFilter.find(({field}: Filter) => field === 'status') ? baseStatusFilter : [];
    return [
      ...clientFilter,
      ...statusFilter,
      ...tableFilter,
      ...this.nameFilter,
    ];
  }

  private addTicketsData(eventList: EventModel[]): EventModel[] {
    return eventList.map((event: EventModel) => {
      const {ticketPackageList}: EventModel = event;
      let amount = 0;
      ticketPackageList.forEach(({number, inPackageCount}: TicketPackageModel) => {
        if (inPackageCount > 1) {
          amount += (+number * inPackageCount);
        } else {
          amount += +number;
        }
      });
      return {...event, tickets: {sold: 0, amount}};
    })
  }

  private getBaseFilter(): Filter[] {
    const ids: string[] = this.clientsWithRelationships.map(({value}: FormElementDataModel) => `"${value}"`);
    const idsArray = `array[${ids.join(',')}]`;
    return [{
      field: 'clientID',
      value: idsArray,
      type: FilterType.Equal
    }];
  }

  private getRegistrations(id: string): Observable<EventRegistrationsCountModel[]> {
    const filters: Filter[] = [
      {
        field: 'eventID',
        value: `array[${id}]`,
        type: FilterType.Equal,
      },
      {
        field: 'Ticket.TicketStatus',
        value: TicketStatus.deleted.toString(),
        type: FilterType.NotEqual
      }
    ];
    const paging: Paging = {
      includeDependencies: true,
      filters,
      selectFields: ['eventID', 'numberInParty'],
    };

    return this.eventParticipantsService.getEventRegistrationsCount(paging)
      .pipe(
        tap((res: EventRegistrationsCountModel[]) => {
          this.registrations = res;
        })
      );
  }

  private searchByEventNameSubscription(): void {
    this.subscription.add(
      this.searchEventsByName.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((value: string) => {
          this.nameFilter = value
            ? [{
              field: 'name',
              value,
              type: FilterType.Contains
            }]
            : [];
          if (this.isDraft === true) this.applyDraftListFilter(); else this.applyAllListFilter();
        })
    );
  }

  public get clientID(): string {
    return this.clientIdStateService.selectedRelationshipClientId;
  }

  private getTableFilter(isDraftList: boolean): Filter[] {
    if (!this.getFilterSortConfig(isDraftList).columnFilters.length) {
      return [];
    } else {
      return this.getFilterSortConfig(isDraftList).columnFilters.map((columnFilter: ColumnFilterModel) => {
        const values = columnFilter.values.map((item: string) => `"${item}"`).join(',');
        return {
          field: columnFilter.field,
          value: `array[${values}]`,
          type: FilterType.Equal
        };
      });
    }
  }

  public editEvent({id}: EventModel): void {
    this.router.navigateByUrl(`clients/events/${id}`);
  }

  public onEventSearch(event: Event): void {
    this.searchEventsByName.next((event.target as HTMLInputElement).value);
  }

  public createNew(): void {
    this.router.navigateByUrl('clients/events/create');
  }

  public createNewSecondary(): void {
    this.router.navigate(['/clients/events/create'], {queryParams: {type: 'secondary'}});
  }

  public getNextPage(page: number): void {
    this.currentAllEventsPage = page;
    if (this.isDraft === false) this.getEvents(); else this.getDrafts();
  }

  public setEntriesPerPage(amount: number): void {
    this.allEventsEntriesPerPage = amount;
    if (this.isDraft === false) this.getEvents(); else this.getDrafts();
  }

  public get formGroup(): FormGroup {
    return this.clientIdStateService.clientIdForm;
  }

  public onClientIdChanged(id: string): void {
    const name = this.clientsWithRelationships.find((option: FormElementDataModel) => option.value === id).label;
    this.clientIdStateService.setSelectedRelationshipClient(id, name);
  }
}
