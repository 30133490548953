import {
  AfterViewChecked, AfterViewInit,
  Component, ElementRef, HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { QuillEditorComponent } from 'ngx-quill';
import { TemplateAssignmentType } from '../../../../models/templates/template.assignment.type';
import getPlaceholderModule from 'quill-placeholder-module';
import QuillPasteSmart from 'quill-paste-smart';

import * as QuillNamespace from 'quill';
import { FONTS, TOOLTIP_TEXT_FOR_FONTS } from 'src/app/constants';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import { tap, throttleTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

/*Quill configuration*/
const Quill: any = QuillNamespace;

Quill.register('modules/placeholder', getPlaceholderModule(Quill, {className: 'ql-placeholder-content'}));
Quill.register('modules/clipboard', QuillPasteSmart, true);

const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ['8px', '10px', '12px', false, '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '30px', '32px', '34px', '36px', '38px', '40px', '44px', '48px', '52px', '56px', '60px', '64px', '68px', '72px'];
Quill.register(SizeStyle, true);
const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const Parchment = Quill.import('parchment');
const lineHeightConfig = {
  scope: Parchment.Scope.INLINE,
  whitelist: ['1.01', '1.15', '1.51', '2.01', '3.01', '4.01', '5.01']
};
const lineHeightClass = new Parchment.Attributor.Class('lineheight', 'ql-line-height', lineHeightConfig);
const lineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', lineHeightConfig);
Parchment.register(lineHeightClass);
Parchment.register(lineHeightStyle);

const FontAttributor = Quill.import('attributors/style/font');
FontAttributor.whitelist = FONTS.map((font: FormElementDataModel) => font.value.split(' ').map(value => (value).toLowerCase()).join(' '));
Quill.register(FontAttributor, true);

const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);
class NonEditableBlock extends Block {
  static create(value) {
    let node = super.create();
    // Sprawdź, czy wartość jest true lub czy element ma już ustawiony atrybut contenteditable="false"
    if (value || node.getAttribute('contenteditable') === 'false') {
      node.setAttribute('contenteditable', 'false');
    }
    return node;
  }

  static formats(node) {
    // Sprawdź, czy element ma atrybut contenteditable ustawiony na false
    return node.getAttribute('contenteditable') === 'false';
  }
}
NonEditableBlock.blotName = 'nonEditable';
NonEditableBlock.tagName = 'div';

Quill.register({
  'formats/nonEditable': NonEditableBlock
}, true);

@Component({
  selector: 'app-text-tool',
  templateUrl: './text-tool.component.html',
  styleUrls: ['./text-tool.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextToolComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked, AfterViewInit {
  private subscription: Subscription = new Subscription();
  @Input() public column: MjmlElementModel;
  @Input() public entity: MjmlElementModel;
  @Input() public tms: TemplateManagementService;
  @Input() public maxLength: number = null;
  @Input() public landingPageView: boolean = false;
  @Input() public resetDefaultPuddings: boolean = false; // for Comment Wall and Donation Activity

  @ViewChild(QuillEditorComponent) public quillEditor: QuillEditorComponent | any;
  @ViewChild('toolRef') public toolRef: ElementRef;
  public isDropDownClick:boolean = false;
  public TemplateAssignmentType = TemplateAssignmentType;
  public valueControl: FormControl = this.formBuilder.control('');
  private setInitialValue: boolean = true;

  private linkClick: boolean = false

  public modules: any= {
    placeholder: {
      delimiters: ['[', ']'],
      placeholders: []
    },
    clipboard: {
      allowed: {
        tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span'],
        attributes: ['href', 'rel', 'target'/*, 'class'*/],
      },
      keepSelection: false,
    },
  };

  public toolTipText: string = this.translate.instant(TOOLTIP_TEXT_FOR_FONTS);
  private contentHtml$: Subject<string> = new Subject();

  private isCurrentTextToolInFocus: boolean = false;

  /*We have to set selection manually to null, because in onSelectionChanged method we set it from old range value*/
  @HostListener('window:click', ['$event']) public clickHandler(event): void {
    if (!this.toolRef || !this.quillEditor) {
      return;
    }
    if (this.toolRef.nativeElement.contains(event.target)) {
      this.isCurrentTextToolInFocus = true;
    } else if (this.isCurrentTextToolInFocus) {
      this.isCurrentTextToolInFocus = false;
      this.quillEditor.quillEditor.setSelection(null);
    } else {
      return;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    public translate: TranslateService,
  ) {
  }

  public ngOnInit(): void {
    this.initPLaceholders();

    if (this.maxLength) {
      this.subscription.add(
        this.contentHtml$.asObservable()
          .pipe(
            throttleTime(0),
            tap(this.setValue.bind(this))
          )
          .subscribe()
      );
    } else {
      this.subscription.add(
        this.contentHtml$.asObservable()
          .pipe(
            tap(this.setValue.bind(this))
          )
          .subscribe()
      );
    }
  }

  private setValue(value: string): void {
    if (!value) {
      value = '';
    }
    this.entity.content = value;
    this.tms.emitSave(true);
  }

  public onContentChanged(ev): void {
    if (this.maxLength && ev.editor.getLength() >= this.maxLength) {
      ev.editor.deleteText(this.maxLength, ev.editor.getLength());
    }
    this.contentHtml$.next(ev.html);
  }

  public getErrorMessage(): string {
    return `Max required length is ${this.maxLength}. Only the first ${this.maxLength} characters will be saved`;
  }

  public ngAfterViewInit(): void {
    if (this.entity && this.entity.content && this.quillEditor && this.setInitialValue) {
      this.valueControl.setValue(this.entity.content);
      setTimeout(() => this.quillEditor.elementRef.nativeElement.lastChild.firstChild.innerHTML = this.entity.content);
      this.setInitialValue = false;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity && changes.entity.currentValue && this.quillEditor && this.setInitialValue) {
      const value = this.entity.content;
      if (value) {
        this.valueControl.setValue(this.entity.content);
        this.setInitialValue = false;
      }
    }
  }

  public ngAfterViewChecked(): void {
    if (this.landingPageView) {
      return;
    }
    //const element = (this.quillEditor as any).elementRef.nativeElement.firstChild.firstChild;
    //this.renderer.setStyle(element, 'word-break', 'break-word');

    /*dirty hack to prevent actions on Tab-key*/
    const quill = this.quillEditor.quillEditor;
    if (quill) {
      const keyboard = quill.keyboard;
      delete keyboard.bindings['9'];
    }

  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getStyles(): any {
    if (!this.entity) {
      return null;
    }
    const attributes = this.entity.attributes;
    const containerBackgroundColor = attributes['container-background-color'] || 'transparent';
    const paddingBottom = attributes['padding-bottom'] || '10px';
    let paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    if (this.resetDefaultPuddings) {
      paddingLeft = '0px';
    }
    return {
      backgroundColor: containerBackgroundColor,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      'word-break': 'break-word',
      'min-width': '100px',
      'font-size': '13px'
    };
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public onSelectionChanged(ev): void {
    setTimeout(() => {
      if (this.isDropDownClick && ev.oldRange && !this.linkClick && ev.oldRange.length > 0) {
        ev.editor.setSelection(ev.oldRange.index, ev.oldRange.length, 'silent');
      }
      this.linkClick = false;
      this.isDropDownClick = false;
    },0);


  }
  public linkClickHandler(): void {
    this.linkClick = true;
  }

  private initPLaceholders(): void {
    if(this.tms.isLetterBuilder) {
      this.modules.placeholder.placeholders = [
        {id:"DonorFirstName", label: "DonorFirstName"},
        {id:"DonorLastName", label: "DonorLastName"},
        {id:"DonorName", label: "DonorName"},
        {id:"StreetAddress", label: "StreetAddress"},
        {id:"StreetAddress2", label: "StreetAddress2"},
        {id:"City", label: "City"},
        {id:"State", label: "State"},
        {id:"ZipCode", label: "ZipCode"},
        {id:"PrimaryContact", label: "PrimaryContact"},
        {id:"OrganizationName", label: "OrganizationName"},
        {id:"Employer", label: "Employer"},
        {id:"LastDonationDate", label: "LastDonationDate"},
        {id:"LastDonationAmount", label: "LastDonationAmount"},
        {id:"GrossLifetimeDonationAmount", label: "GrossLifetimeDonationAmount"},
        {id:"YTDDonationAmount", label: "YTDDonationAmount"},
        {id:"PageNumber", label: "PageNumber"},
      ]
    } else {
      this.modules.placeholder.placeholders = [
        {id: '7', label: 'Address'},
        {id: '8', label: 'City'},
        {id: '1', label: 'DonorName'},
        {id: '3', label: 'Email'},
        {id: '6', label: 'Fax'},
        {id: '10', label: 'DonorFirstName'},
        {id: '2', label: 'OrgName'},
        {id: '4', label: 'PrimaryPhone'},
        {id: '9', label: 'State'},
      ]
    }
  }

  get isLetterFooterTextTool(): boolean {
    return !!this.entity.attributes?.letterFooterTextTool;
  }
}
