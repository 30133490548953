import { ClientDonorTagModel } from 'src/app/models/tag/client-donor-tag.model';
import { ConfigService } from '../appconfig.service';
import { flatMap, map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Paging } from 'src/app/models/paging/paging.model';
import { PagingHelper } from '../helper/paging.helper';
import { TotalRowsModel } from 'src/app/models/paging/totalrow.model';
import SegmentTagsInfoModel from 'src/app/models/segment/segment.tags.info.model';

@Injectable({
  providedIn: 'root'
})
export class SingletonSegmentTagService {
  private controllerName = 'SingletonSegmentTag';

  constructor(private configService: ConfigService, private http: HttpClient) { }

  public addSegments(segments: SegmentTagsInfoModel[], batchId?: string): Observable<string> {
    let params: HttpParams = new HttpParams();
    if (batchId)
      params = params.set('batchId', batchId);
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        return this.http.post<string>(
          `${config.utilsURL}/${this.controllerName}/Segments`, segments, { params }
        );
      })
    );
  }

  public addTags(batchId: string, tagIds: string[]): Observable<string[]> {
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        return this.http.post<string[]>(
          `${config.utilsURL}/${this.controllerName}/Tag/${batchId}`, tagIds
        );
      })
    );
  }

  public deleteTags(batchId: string, tagIds: string[]): Observable<string[]> {
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        return this.http.put<string[]>(
          `${config.utilsURL}/${this.controllerName}/Tag/${batchId}`, tagIds
        );
      })
    );
  }

  public getClientDonors(batchId: string, segmentId: string, paging?: Paging): Observable<ClientDonorTagModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(paging);
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        return this.http.get<ClientDonorTagModel[]>(
          `${config.utilsURL}/${this.controllerName}/Donors/${batchId}/${segmentId}`, { params }
        );
      })
    );
  }

  public isAtLeastOneDonorSelected(batchId: string): Observable<boolean> {
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        return this.http.get<boolean>(
          `${config.utilsURL}/${this.controllerName}/Donors/${batchId}`
        );
      })
    );
  }

  public getTotalClientDonors(batchId: string, segmentId: string, paging?: Paging): Observable<number> {
    const params: HttpParams = PagingHelper.getPagingParams(paging);
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        return this.http.get<TotalRowsModel>(
          `${config.utilsURL}/${this.controllerName}/TotalDonors/${batchId}/${segmentId}`, { params }
        ).pipe(
          map((response) => response.total)
        );
      })
    );
  }

  public selectDonor(batchId: string, segmentId: string, status: boolean, clientDonorId?: string): Observable<any> {
    let params: HttpParams = new HttpParams().set('status', status.toString());
    if (clientDonorId)
      params = params.set('clientDonorId', clientDonorId);
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        return this.http.post(
          `${config.utilsURL}/${this.controllerName}/SelectDonor/${batchId}/${segmentId}`, null, { params }
        );
      })
    );
  }

  public save(batchId: string): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        return this.http.get(
          `${config.utilsURL}/${this.controllerName}/Save/${batchId}`
        );
      })
    );
  }
}
