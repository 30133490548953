import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilderStateService} from '../../form-builder.state.service';
import {FormArray, FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import {DefaultRecurringDonationEmailTexts, FB_DONATION_EMAIL_DISABLE} from 'src/app/constants';
import {RecurringEmailType} from "../../../../models/enum/recurring-email-type";
import {MatTabGroup} from "@angular/material/tabs";

@Component({
  selector: 'app-fb-email-settings',
  templateUrl: './fb-email-settings.component.html',
  styleUrls: ['../fb.page.scss']
})
export class FbEmailSettingsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public fbss: FormBuilderStateService;
  @Input() public wasPageOpened: boolean = false;
  @ViewChild('wrapper') contentWrapper: MatTabGroup;
  private subscription: Subscription = new Subscription();
  public currentTab: number = 0;
  public placeholders: string[] = ['DonorName', 'Email', 'Phone', 'DonateAmount', 'Funds', 'FundsList', 'PrimaryContact', 'OrgName', 'DonationDate'];
  public recurringPlaceholders: string[] = ['DonorName', 'OrganizationName', 'ClientPrimaryContactName', 'NumberOfPeriods', 'Frequency', 'DonationAmountWithOrWithoutFee', 'GrossDonationAmount', 'ScheduledDonationDate', 'NextDonationDate'];

  public defaultEmailForNotification = `<div>Hi There!</div><div><br></div><div>[DonorName] just filled out the [FormName] Form. They donated [DonateAmount] on [DonationDate]. Login to Auxilia to see more details about the transaction!</div><div><br></div><div>Best,</div><div>Auxilia Team</div>`;
  public defaultEmailToDonor = `<div>Dear [DonorName],</div><div><br></div><div>On behalf of our Board of Directors and Staff, we would like to thank you for supporting [Funds].</div><div><br></div><div>Your [DonateAmount] donation on [DonationDate] helps us make a positive difference. Your support [FundsList] encourages our continued commitment to reaching our goal.</div><div><br></div><div>Thanks again for your generosity and support.</div><div><br></div><div>Regards,</div><div><br></div><div>[PrimaryContact]</div><div>[OrgName]</div>`;

  public formStatusOptions: FormElementDataModel[] = FB_DONATION_EMAIL_DISABLE;

  constructor() { }

  public ngOnInit(): void {
    if (!this.control('emailNotification').value) {
      this.resetEmailForNotification();
    }
    if (!this.control('emailToDonor').value) {
      this.resetEmailToDonor();
    }

    setTimeout(() => {
      this.subscription.add(
        this.control('emailNotification').valueChanges
          .pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            tap(() => this.fbss.templateWasChanged(true))
          )
          .subscribe()
      );
      this.subscription.add(
        this.control('emailToDonor').valueChanges
          .pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            tap(() => this.fbss.templateWasChanged(true))
          )
          .subscribe()
      );
    }, 3000);

  }

  ngOnChanges(changes: SimpleChanges) {
   if(changes.wasPageOpened.currentValue && !changes.wasPageOpened.previousValue) {
     this.currentTab = 0;
     this.contentWrapper.realignInkBar();
   }
 }

 //Resolve problem with Quill losing formatting;
   public adjustQuillFormatting(i: number) {
        if(i === 1) {
            setTimeout(() => {
                const recurringEmailGroupValues = (this.fbss.recurringEmailGroupFormArray).getRawValue();
                this.fbss.recurringEmailGroupFormArray.patchValue(recurringEmailGroupValues);
            })
        }
    }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public control(controlName: string): FormControl {
    return this.fbss.templateSettingsForm.get(controlName) as FormControl;
  }

  public resetEmailForNotification(): void {
    this.control('emailNotification').setValue(this.defaultEmailForNotification);
  }

  public resetRecurringEmail(type: RecurringEmailType): void {
      const defaultEmailBody = DefaultRecurringDonationEmailTexts.find(el => el.value === type).label;
      (this.fbss.templateSettingsForm.get('recurringEmailGroup') as FormArray).controls.find(c => c.get('type').value === type).get('email').setValue(defaultEmailBody)

  }

  public resetEmailToDonor(): void {
    this.control('emailToDonor').setValue(this.defaultEmailToDonor);
  }

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }

  public onValueChanged(): void {
    this.fbss.templateWasChanged(true);
  }

}
