import {Component, ElementRef} from '@angular/core';
import FormElementDataModel from '../models/form.element.data.model';
import TemplatePayload, {RecurringEmailGroup} from '../models/templates/template.payload';
import TemplateModel from '../models/templates/template.model';
import ColumnFilterModel from '../models/filter-sort/column.filter.model';
import FilterSortModel from '../models/filter-sort/filter.sort.model';
import {FormArray, FormGroup} from '@angular/forms';
import {VolunteeringModel} from '../models/event/volunteering.model';
import {formatDate} from '@angular/common';
import {
  CLIENT_DONATIONS_COLUMNS,
  CLIENT_DONATIONS_COLUMNS_TEMPLATE,
  DefaultRecurringDonationEmailSubjects, DefaultRecurringDonationEmailTexts,
  MS_PER_DAY
} from '../constants';
import {PaymentServiceType} from '../models/enum/payment.service.type';
import {MerchantModel} from '../models/payments/merchant.model';
import {AlignmentType, HeaderSize} from '../models/form-builder/fb.template.model';
import {SocialProviderEnum} from '../models/enum/social-provider.enum';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {SortOrder} from '../models/enum/sort.order';
import {DialogBeforeunloadResponseType} from '../models/enum/dialog.beforeunload.response.type';
import TicketPackageModel from '../models/event/ticket.package.model';
import {EventAddOnTimesUsed} from '../models/event/event-add-on-times-used.model';
import {Filter} from '../models/paging/filter.model';
import {FilterType} from '../models/enum/filter.type';
import {MjmlTag} from '../models/templates/mjml.tag.type';
import MjmlElementModel from '../models/templates/mjml.element.model';
import {DonationMethodType} from '../models/enum/donation.method.type';
import {RecurringEmailType} from "../models/enum/recurring-email-type";
import SourceTemplateModel from "../models/templates/source.template.model";

@Component({
  selector: 'app-utils',
  template: '',
})
export class UtilsComponent {

  public static strictDate(date: Date | string): Date {
    if (date) {
      const dateObj = new Date(date);
      return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
    }
  }

  public static dayOfMonth(): FormElementDataModel[] {
    const dayOfMonth = [];
    for (let i = 1; i <= 31; i++) {
      dayOfMonth.push({label: i.toString(), value: i});
    }
    return dayOfMonth;
  }

  public static clone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  public static getFirstItemOnPage(total, currentPage, entriesPerPage): number {
    const totalPages = Math.ceil(total / entriesPerPage);
    if (totalPages > 1 && currentPage > 1) {
      return (currentPage - 1) * entriesPerPage;
    } else {
      return 0;
    }
  }

  public static scrollIntoView(element: ElementRef): void {
    element.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  public static truncateNumber(value: number): string {
    if (!value) {
      return (0).toFixed(2);
    } else {
      return value.toFixed(3).slice(0, -1);
    }
  }

  public static getOffsetDate(date: Date, timeZone?: string, startTime?: string) {
    const time = date.getTime();
    const formatTime = time + Math.abs(date.getTimezoneOffset() * 60000);
    let basicDate = new Date(formatTime).toISOString();
    if (startTime) {
      basicDate = basicDate.substr(0, basicDate.indexOf('T') + 1);
      basicDate += this.get24Time(startTime) + ':30';
    } else {
      basicDate = basicDate.substr(0, basicDate.indexOf('.'));
    }
    if (!timeZone) {
      return basicDate + ' +03:00';
    } else {
      return basicDate + ' ' + timeZone;
    }
  }

  public static get24Time(time: string): string {
    let hours = Number(time.match(/^(\d+)/)[1]);
    const minutes = Number(time.match(/:(\d+)/)[1]);
    const AMPM = time.match(/\s(.*)$/)[1].toLowerCase();
    if (AMPM === 'pm' && hours < 12) {
      hours = hours + 12;
    }
    if (AMPM === 'am' && hours === 12) {
      hours = hours - 12;
    }
    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) {
      sHours = '0' + sHours;
    }
    if (minutes < 10) {
      sMinutes = '0' + sMinutes;
    }
    return sHours + ':' + sMinutes;
  }

  public static hexToRgbA(hex: string): string {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      // eslint-disable-next-line no-bitwise
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
    } else {
      return hex;
    }
  }

  public static getArrayQueryValue(val: string[]): string {
    return val.map((item: any) => {
      if(typeof item === 'string' || item instanceof String) return `"${item}"`
      if(Array.isArray(item)) return [...item];
      if(item === null) return "null";
      return item
    }).join(',');
  }

  public static setFormBuilderPayload(payload: TemplatePayload, template: TemplateModel): void {
    payload.fbTemplateName = template.name;
    payload.name = template.name;
    payload.fbTemplateStatus = template.fbTemplateStatus;
    payload.disableDate = template.disableDate;
    payload.disableTime = template.disableTime;
    payload.disableTimeZone = template.disableTimeZone;
    payload.messageWhenDisabled = template.messageWhenDisabled;
    payload.notifyEmails = template.notifyEmails;
    payload.emailNotification = template.emailNotification;
    payload.subjectEmailNotification = template.subjectEmailNotification;
    payload.emailToDonor = template.emailToDonor;
    payload.subjectEmailToDonor = template.subjectEmailToDonor;
    payload.isSendDonationEmailDisabled = template.isSendDonationEmailDisabled;
    payload.recurringEmailGroup = this.getRecurringEmailGroup(template)
  }

  private static getRecurringEmailGroup(template: TemplateModel): RecurringEmailGroup[] {
    const defaultSubject = (t: RecurringEmailType) => DefaultRecurringDonationEmailSubjects.find(el => el.value === t).label;
    const defaultBBC = '';
    const defaultEmail = (t: RecurringEmailType) => DefaultRecurringDonationEmailTexts.find(el => el.value === t).label;
    return (Object.values(RecurringEmailType).filter(a => !isNaN(Number(a)) && Number(a) !== 0).map(opt => {
        switch (opt) {
          case RecurringEmailType.ScheduleCreation:
            return {subject: template.recurringScheduleCreationSubject ?? defaultSubject(opt), BBC: template.recurringScheduleCreationBcc ?? defaultBBC, type: opt, email: template.recurringScheduleCreationBody ?? defaultEmail(opt)};
          case RecurringEmailType.NextScheduledDonationDate:
            return {subject: template.recurringNextScheduledDonationDateSubject  ?? defaultSubject(opt), type: opt, email: template.recurringNextScheduledDonationDateBody ?? defaultEmail(opt)};
          case RecurringEmailType.ThankYou:
            return {subject: template.recurringThankYouSubject ?? defaultSubject(opt), type: opt, email: template.recurringThankYouBody ?? defaultEmail(opt)};
          case RecurringEmailType.DonationDidNotProcess:
            return {subject: template.recurringDonationDidNotProcessSubject ?? defaultSubject(opt), BBC: template.recurringDonationDidNotProcessBcc ?? defaultBBC, type: opt, email: template.recurringDonationDidNotProcessBody ?? defaultEmail(opt)};
          case RecurringEmailType.CancelledDonation:
            return {subject: template.recurringCancelledSubject ?? defaultSubject(opt), BBC: template.recurringCancelledBcc ?? defaultBBC, type: opt, email: template.recurringCancelledBody ?? defaultEmail(opt)};
          case RecurringEmailType.RestartedDonation:
            return {subject: template.recurringRestartedSubject ?? defaultSubject(opt), BBC: template.recurringRestartedBcc ?? defaultBBC, type: opt, email: template.recurringRestartedBody ?? defaultEmail(opt)};
        }
      })
    )
  }

  public static setFormBuilderTemplate(template: TemplateModel, payload: TemplatePayload): void {
    template.fbTemplateName = payload.fbTemplateName;
    template.name = payload.fbTemplateName;
    template.fbTemplateStatus = payload.fbTemplateStatus;
    template.disableDate = payload.disableDate ? UtilsComponent.dateToPartlyString(payload.disableDate) : null;
    template.disableTime = payload.disableTime;
    template.disableTimeZone = payload.disableTimeZone;
    template.messageWhenDisabled = payload.messageWhenDisabled;
    template.notifyEmails = payload.notifyEmails;
    template.emailNotification = payload.emailNotification;
    template.subjectEmailNotification = payload.subjectEmailNotification;
    template.emailToDonor = payload.emailToDonor;
    template.subjectEmailToDonor = payload.subjectEmailToDonor;
    template.recurringScheduleCreationSubject = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.ScheduleCreation)?.subject
    template.recurringScheduleCreationBcc = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.ScheduleCreation)?.BBC
    template.recurringScheduleCreationBody = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.ScheduleCreation)?.email
    template.recurringNextScheduledDonationDateSubject = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.NextScheduledDonationDate)?.subject
    template.recurringNextScheduledDonationDateBody = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.NextScheduledDonationDate)?.email
    template.recurringThankYouSubject = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.ThankYou)?.subject
    template.recurringThankYouBody = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.ThankYou)?.email
    template.recurringDonationDidNotProcessSubject = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.DonationDidNotProcess)?.subject
    template.recurringDonationDidNotProcessBcc = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.DonationDidNotProcess)?.BBC
    template.recurringDonationDidNotProcessBody = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.DonationDidNotProcess)?.email
    template.recurringCancelledSubject = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.CancelledDonation)?.subject
    template.recurringCancelledBcc = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.CancelledDonation)?.BBC
    template.recurringCancelledBody = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.CancelledDonation)?.email
    template.recurringRestartedSubject = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.RestartedDonation)?.subject
    template.recurringRestartedBcc = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.RestartedDonation)?.BBC
    template.recurringRestartedBody = payload.recurringEmailGroup.find(g => g.type === RecurringEmailType.RestartedDonation)?.email
    payload.isSendDonationEmailDisabled = template.isSendDonationEmailDisabled;
  }

  public static transformRecurringEmailGroupToTemplateModelProps(template: TemplateModel | SourceTemplateModel ,payload: RecurringEmailGroup[]) {
    template.recurringScheduleCreationSubject = payload.find(g => g.type === RecurringEmailType.ScheduleCreation)?.subject
    template.recurringScheduleCreationBcc = payload.find(g => g.type === RecurringEmailType.ScheduleCreation)?.BBC
    template.recurringScheduleCreationBody = payload.find(g => g.type === RecurringEmailType.ScheduleCreation)?.email
    template.recurringNextScheduledDonationDateSubject = payload.find(g => g.type === RecurringEmailType.NextScheduledDonationDate)?.subject
    template.recurringNextScheduledDonationDateBody = payload.find(g => g.type === RecurringEmailType.NextScheduledDonationDate)?.email
    template.recurringThankYouSubject = payload.find(g => g.type === RecurringEmailType.ThankYou)?.subject
    template.recurringThankYouBody = payload.find(g => g.type === RecurringEmailType.ThankYou)?.email
    template.recurringDonationDidNotProcessSubject = payload.find(g => g.type === RecurringEmailType.DonationDidNotProcess)?.subject
    template.recurringDonationDidNotProcessBcc = payload.find(g => g.type === RecurringEmailType.DonationDidNotProcess)?.BBC
    template.recurringDonationDidNotProcessBody = payload.find(g => g.type === RecurringEmailType.DonationDidNotProcess)?.email
    template.recurringCancelledSubject = payload.find(g => g.type === RecurringEmailType.CancelledDonation)?.subject
    template.recurringCancelledBcc = payload.find(g => g.type === RecurringEmailType.CancelledDonation)?.BBC
    template.recurringCancelledBody = payload.find(g => g.type === RecurringEmailType.CancelledDonation)?.email
    template.recurringRestartedSubject = payload.find(g => g.type === RecurringEmailType.RestartedDonation)?.subject
    template.recurringRestartedBcc = payload.find(g => g.type === RecurringEmailType.RestartedDonation)?.BBC
    template.recurringRestartedBody = payload.find(g => g.type === RecurringEmailType.RestartedDonation)?.email
  }

  public static dateToPartlyString(date: string): string {
    const dateObj = new Date(date);
    return `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
  }

  private static getHours(timeParts:any) {
    if((timeParts[1].toLowerCase() === 'am' && timeParts[0].split(':')[0] !== '12') || (timeParts[0].split(':')[0] === '12' && timeParts[1] === 'pm')) {
      return +timeParts[0].split(':')[0]
    }
    else if(timeParts[1].toLowerCase() === 'am' && timeParts[0].split(':')[0] === '12') {
      return +timeParts[0].split(':')[0] - 12;
    }
    else {
      return  +timeParts[0].split(':')[0] + 12;
    }
  }

  public static getCustomDate(date: Date, time: string, offset: number): Date {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const timeParts = time.split(' ');
    const hours = this.getHours(timeParts);
    const minutes = +timeParts[0].split(':')[1];
    return new Date(Date.UTC(year, month, day, hours - offset, minutes));
  }

  public static dateDiffInDays(a, b): number {
    // Discard the time and time-zone information.
    a = new Date(a);
    b = new Date(b);
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / MS_PER_DAY);
  }

  public static replaceSpecialCharactersWithUnderscore(string: string): string {
    return string.toLowerCase().replace(/[^a-zA-Z0-9_-]/g, '_');
  }

  public static sortByField(array: any[], field: string, order: SortOrder = SortOrder.Ascending): any[] {
    return array.sort((a, b) => {
      if (a[field].toLowerCase() > b[field].toLowerCase()) {
        switch (order) {
          case SortOrder.Ascending:
            return 1;
          case SortOrder.Descending:
            return -1;
        }
      }
      if (a[field].toLowerCase() < b[field].toLowerCase()) {
        switch (order) {
          case SortOrder.Ascending:
            return -1;
          case SortOrder.Descending:
            return 1;
        }
      }
      return 0;
    })
  }

  public static sortByFieldDesc(array: any[], field: string): any[] {
    return array.sort((a, b) => {
      if (a[field] < b[field]) {
        return 1;
      }
      if (a[field] > b[field]) {
        return -1;
      }
      return 0;
    })
  }

  public static sortByFieldAndOrder(array: any[], field: string, order: SortOrder = SortOrder.Ascending): any[] {
    if(!array || !field) throw new Error('Array and sort field name have to be provided')
    if(order === SortOrder.Ascending) return this.sortByField(array, field);
    if(order === SortOrder.Descending) return this.sortByFieldDesc(array, field);
    throw new Error('Something went wrong')
  }

  public static updateColumnFilters(columnFilter: ColumnFilterModel, filterSortConfig: FilterSortModel): ColumnFilterModel[] {
    let {columnFilters}: FilterSortModel = filterSortConfig;
    if (columnFilter.field === 'ticketPackageName')
      columnFilter.field = 'ticket.TicketPackage.Name';
    if (!columnFilters.length && columnFilter.values.length) {
      columnFilters.push(columnFilter);
    } else if (columnFilters.find(({field}: ColumnFilterModel) => field === columnFilter.field)) {
      if (columnFilter.values.length) {
        columnFilters.forEach((columnFilterModel: ColumnFilterModel) => {
          if (columnFilterModel.field === columnFilter.field) {
            columnFilterModel.values = columnFilter.values;
            columnFilterModel.labels = columnFilter.labels;
          }
        });
      } else {
        columnFilters = columnFilters.filter((columnFilterModel: ColumnFilterModel) => columnFilterModel.field !== columnFilter.field);
      }
    } else if (columnFilter.values.length) {
      columnFilters.push(columnFilter);
    }
    return columnFilters;
  }

  public static cleanFormArray(formArray: FormArray): void {
    if (formArray) {
      while (formArray.length > 0) {
        formArray.removeAt(0);
      }
    }
  }

  public static dateStringFromVolunteeringModel(option: VolunteeringModel): string {
    const {startDate, endDate, startTime, endTime}: VolunteeringModel = option;
    let date = '';
    const isDataEqual = startDate === endDate;
    if (startDate && !isDataEqual) {
      date = formatDate(new Date(startDate), 'MMMM d, y', 'en-US');
    }
    if (endDate && !isDataEqual) {
      date += ` - ${formatDate(new Date(endDate), 'MMMM d, y', 'en-US')}`;
    }
    if (startDate && endDate && isDataEqual) {
      date += `${formatDate(new Date(startDate), 'MMMM d, y', 'en-US')}`;
    }
    if (startTime && endTime) {
      date += ` from ${startTime} to ${endTime}`;
    }
    return date;
  }

  public static hasMerchantAccount(paymentServiceType: PaymentServiceType, merchantModel: MerchantModel): boolean {
    return paymentServiceType !== PaymentServiceType.None
      && merchantModel
      && (
        (paymentServiceType === PaymentServiceType.CardConnect && merchantModel.hasCardConnectInfo)
        || (paymentServiceType === PaymentServiceType.Cornerstone && merchantModel.hasCornerStoneInfo)
        || (paymentServiceType === PaymentServiceType.Stripe && merchantModel.isStripeOnboardingComplete)
      );
  }

  public static getLogoPosition(type: AlignmentType): string {
    switch (type) {
      case AlignmentType.Left:
        return 'flex-start';
      case AlignmentType.Top:
        return 'center';
      case AlignmentType.Right:
        return 'flex-end';
    }
  }

  public static getLogoSize(type: HeaderSize): number {
    switch (type) {
      case HeaderSize.Small:
        return 32;
      case HeaderSize.Medium:
        return 64;
      case HeaderSize.Large:
        return 96;
    }
  }

  public static getOnlyNumberFromString(string: string): number {
    const regEx = /[^\d]/g;
    return Number(string.replace(regEx, ''));
  }

  public static dropdownSorting(property: string): (a, b) => number {
    return (a, b) => a[property].localeCompare(b[property], undefined, { numeric: false, sensitivity: 'base' })
  }
  public static getFileType(fileName: string): string {
    const extension = fileName.split('.')[1].toLowerCase();
    const images = ['png', 'jpg', 'jpeg', 'gif'];
    const videos = ['avi', 'flv', 'mkv', 'mov', 'mp4'];
    if (images.includes(extension)) {
      return `image/${extension}`;
    } else if (videos.includes(extension)) {
      return `video/${extension}`;
    } else {
      return '';
    }
  }

  public static hasProvider(providers: SocialProviderEnum, checkValue: SocialProviderEnum): boolean {
    // eslint-disable-next-line no-bitwise
    return (providers & checkValue) === checkValue;
  }

  public static cropImage(inputSrc: string, outputAspectRatio: number = 1, outputExtension: string = 'png'): Observable<string> {
    const croppedDataURL: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    const inputImage = new Image();
    inputImage.src = inputSrc;
    inputImage.crossOrigin = 'anonymous';

    inputImage.onload = () => {

      const inputWidth = inputImage.naturalWidth;
      const inputHeight = inputImage.naturalHeight;

      const inputImageAspectRatio = inputWidth / inputHeight;

      let outputWidth = inputWidth;
      let outputHeight = inputHeight;

      if (inputImageAspectRatio > outputAspectRatio) {
        outputWidth = inputHeight * outputAspectRatio;
      } else if (inputImageAspectRatio < outputAspectRatio) {
        outputHeight = inputWidth / outputAspectRatio;
      }

      const outputX = (outputWidth - inputWidth) * 0.5;
      const outputY = (outputHeight - inputHeight) * 0.5;

      const outputImage = document.createElement('canvas');
      outputImage.width = outputWidth;
      outputImage.height = outputHeight;
      outputImage.getContext('2d').drawImage(inputImage, outputX, outputY);
      croppedDataURL.next(outputImage.toDataURL(`image/${outputExtension}`));
    }

    return croppedDataURL.asObservable()
      .pipe(
        filter(value => !!value)
      );
  }

  public static dateToFormat(date: string | number | Date, format: string = 'MM/dd/yyyy', defaultOutput: string = ''): string {
    return date ? formatDate(date, format, 'en-US') : defaultOutput;
  }

  public static firstVsSecondDateValidation(first: Date, second: Date, formGroup: FormGroup, controlNames: string[]): boolean {
    if (new Date(first) < new Date(second)) {
      controlNames.forEach(name => formGroup.controls[name].setErrors({invalidTime: true}));
      return true;
    }
    return false;
  }

   public static getDateString(dateString: Date): string {
    const year = new Date(dateString).getFullYear();
    const monthNumber = new Date(dateString).getMonth();
    const month = monthNumber + 1 < 10 ? `0${monthNumber + 1}` : monthNumber + 1;
    const dayNumber = new Date(dateString).getDate();
    const day = dayNumber < 10 ? `0${dayNumber}` : dayNumber;
    return `${year}-${month}-${day}`;
  }

  public static filterAcceptResponse(response): boolean {
    return response === DialogBeforeunloadResponseType.Accept;
  }

  public static nextSortOrder(config: FilterSortModel, column: string): SortOrder {
    let sortOrder: SortOrder;
    if (config.sortField === column) {
      switch (config.sortOrder) {
        case SortOrder.Ascending:
          sortOrder = SortOrder.Descending;
          break;
        case SortOrder.Descending:
          sortOrder = SortOrder.Ascending;
      }
    } else {
      sortOrder = SortOrder.Ascending;
    }
    return sortOrder;
  }

  public static makeNaFirst(array: FormElementDataModel[]): FormElementDataModel[] {
    /*Make N/A label first in list of filters*/
    const naItems = array.filter(item => item.label === 'N/A');
    if (naItems.length > 0) {
      array = array.filter(item => item.label !== 'N/A')
      array.unshift({
        label: 'N/A',
        value: null
      })
    }
    return array;
  }
  public static checkOrgsIsPAC(tableColumns: string[], clientsWithRelationships: FormElementDataModel[], filters: ColumnFilterModel): string[] {
    if (filters.field === 'clientID') {
      tableColumns = [...CLIENT_DONATIONS_COLUMNS];
      const chosenClientsID: string[] = filters.values || [];
      let isHideColumn: boolean = true;
      chosenClientsID.forEach((id: string) => {
        const singleOrg: FormElementDataModel = clientsWithRelationships.find((org: FormElementDataModel) => org.value === id);
        if (singleOrg && singleOrg.isPAC) {
          isHideColumn = false;
        }
      });
      /* Clear All event*/
      if (!chosenClientsID.length) {
        isHideColumn = !clientsWithRelationships.some(client => client.isPAC);
      }
      if (isHideColumn) {
        tableColumns = [...CLIENT_DONATIONS_COLUMNS_TEMPLATE];
      }
      if (clientsWithRelationships.length > 1) {
        tableColumns.splice(2, 0, 'clientID');
      }
    }
    return tableColumns;
  }

  public static updateHtmlStyle(html: string): string {
    const res = html.match(/style="background:url(.*?)"/gmi);
    if (res && res.length) {
      res.forEach(match => {
        let style = '';
        if (match.includes('top center')) {
          style += 'background-position:top center;'
        }

        if (match.includes('no-repeat')) {
          style += 'background-repeat:no-repeat;'
        } else if (match.includes('repeat-x')) {
          style += 'background-repeat:repeat-x;'
        } else if (match.includes('repeat-y')) {
          style += 'background-repeat:repeat-y;'
        } else if (match.includes('repeat')) {
          style += 'background-repeat:repeat;'
        } else if (match.includes('space')) {
          style += 'background-repeat:space;'
        } else if (match.includes('round')) {
          style += 'background-repeat:round;'
        }

        if (match.includes('/ 100%')) {
          style += 'background-size:100%;';
        } else if (match.includes('/ auto')) {
          style += 'background-size:auto;'
        } else if (match.includes('/ 25%')) {
          style += 'background-size:25%;'
        } else if (match.includes('/ 50%')) {
          style += 'background-size:50%;'
        } else if (match.includes('/ 75%')) {
          style += 'background-size:75%;'
        } else if (match.includes('/ 125%')) {
          style += 'background-size:125%;'
        } else if (match.includes('/ 150%')) {
          style += 'background-size:150%;'
        }

        html = html.replace(match, match.slice(0, -1) + style + '"');
      });
    }
    return html;
  }
  public static setDateFilters(filters: Filter[], [startDate, endDate]: Date[], isQuickBooks: boolean = false): void {
    filters.push({
      field: isQuickBooks ? 'donationRealDate' : 'startDate',
      value: startDate.toISOString(),
      type: FilterType.GreaterThanOrEqual,
      filterType: FilterType.GreaterThanOrEqual
    });
    filters.push({
      field: isQuickBooks ? 'donationRealDate' :  'endDate',
      value: endDate.toISOString(),
      type: FilterType.LessThanOrEqual,
      filterType: FilterType.LessThanOrEqual
    });
  }
  public static getRequiredAddOnsLeftCount(ticket: TicketPackageModel, addOnTimesUsed: EventAddOnTimesUsed[]): number {
    let addOnsLeft: number = 0;
    ticket?.addons.forEach(addOn => {
      const currAddOnLeft: number = addOnTimesUsed.find(({id}) => id === addOn.id)?.timesUsed || 0
      addOnsLeft += (addOn.quantity - currAddOnLeft);
    })
    return addOnsLeft;
  }

  public static splitArray(arr: any[], subMaxSize: number): any[][] {
    const res = [];
    for(let i=0; i<arr.length; i+=subMaxSize) {
      const chunk = arr.slice(i, i + subMaxSize);
      res.push(chunk);
    }
    return res;
  }

  public static getMjmlElementByTagType(mjmlBody: MjmlElementModel[], type: MjmlTag): MjmlElementModel[] {
    if(!mjmlBody?.length) throw new Error("no MJML structure was provided or it was not an array");

    let results: MjmlElementModel[] = [];

    mjmlBody.forEach(section => {
      const columns: MjmlElementModel[] = section.children;
      columns.forEach(column => {
        const tools: MjmlElementModel[] = column.children;
        tools.forEach(tool => {
          if(tool.tagName === type) {
            results.push(tool);
          }
        })
      })
    })

    return results;
  }

  public static generateToken(signsCount: number = 6): string {
    let possibleChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwyz1234567890";
    let token: string = '';

    const getRandomNumber = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1) + min)
    }

    for(let i = 0; i < signsCount; i++) {
      let randomIndex = getRandomNumber(0, (possibleChars.length - 1));
      token += possibleChars[randomIndex]
    }

    return token;
  }
  public static formatPostCode(postCode: string, isZipCode: boolean = true) {
    if(!isZipCode || postCode?.length !== 9) return postCode;
    return postCode.slice(0, 5) + "-" + postCode.slice(5);
  }

  public static getDonationMethodTextByType(donationMethodType: DonationMethodType): string {
    const donationMethodTypes = {
      [DonationMethodType['N/A']]: "N/A",
      [DonationMethodType.Cash]: 'Cash',
      [DonationMethodType.CreditCard]: 'Credit Card',
      [DonationMethodType.Check]: 'Check',
      [DonationMethodType.MoneyOrder]: 'Money Order',
      [DonationMethodType.Bitcoin]: 'Bitcoin',
      [DonationMethodType.MobilePay]: 'Mobile Pay',
      [DonationMethodType.Venmo]: 'Venmo',
      [DonationMethodType.Zelle]: 'Zelle',
      [DonationMethodType.ACH]: 'ACH'
    };
    return donationMethodTypes[donationMethodType];
  }

  public static areEqual(oldObj: any, newObj: any): boolean {
    const oldObjStringify: string = JSON.stringify(oldObj);
    const newObjStringify: string = JSON.stringify(newObj);
    return oldObjStringify !== newObjStringify;
  }
}
