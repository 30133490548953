<div class="email-builder-tool-settings-wrapper">

  <!--<div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="textForm"
        label="Text color"
        name="color"
        [readOnly]="readOnly"
        (colorChanged)="onColorChanged()"
      ></app-email-color-picker>
    </div>

    <div class="max-width-145">
      <app-select
        label="Font family"
        [formGroup]="textForm"
        name="fontFamily"
        errorMassage="Font Family"
        [selectOptions]="fontFamilyOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontFamilyChanged()"
      ></app-select>
    </div>
  </div>-->

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="textForm"
        label="Background color"
        name="containerBackgroundColor"
        [readOnly]="readOnly"
        (colorChanged)="onContainerBackgroundColorChanged()"
      ></app-email-color-picker>
    </div>

    <!--<div class="max-width-145">
      <app-select
        label="Position"
        [formGroup]="textForm"
        name="align"
        errorMassage="Position"
        [selectOptions]="alignOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onAlignChanged()"
      ></app-select>
    </div>-->

  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="textForm"
        name="paddingTop"
        label="Padding Top"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingTopChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="textForm"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingRightChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="textForm"
        name="paddingBottom"
        label="Padding Bottom"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingBottomChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="textForm"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingLeftChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row" *ngIf="!(readOnly || isPredesignedThemedTemplate)">
    <app-button
      type="basic"
      label="Delete content"
      (clickEvent)="tms.removeActiveElementFromColumn()"
      color="#f44336"
      width="300"
    ></app-button>
  </div>

  <div class="padding-source-100"></div>
</div>

