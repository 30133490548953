import { Client } from '@microsoft/microsoft-graph-client';
import { Injectable } from '@angular/core';
import { OutlookAuthService } from './outlook-auth.service';
import { TimeZoneModel } from 'src/app/models/time-zone.model';
import { ToastrService } from 'ngx-toastr';
import EventModel from 'src/app/models/event/event.model';
import { AuthenticationResult } from '@azure/msal-browser';
import { AuthProviderCallback } from '@microsoft/microsoft-graph-client/src/IAuthProviderCallback';


@Injectable({
  providedIn: 'root'
})

export class OutlookEventService {
  private graphClient: Client;

  constructor(
    private authService: OutlookAuthService,
    private toastrService: ToastrService,
  ) {

    /*this.authService.getAccessToken()
      .subscribe((response: AuthenticationResult) => {
        this.graphClient = Client.init({
          authProvider: (done: AuthProviderCallback) => {
            if (response.accessToken) {
              done(null, response.accessToken);
            } else {
              done('Could not get an access token', null);
            }
          }
        })
      });*/
  }

  async getEvents(): Promise<Event[]> {
    try {
      const result = await this.graphClient
        .api('/me/events')
        .select('subject,organizer,start,end')
        .orderby('createdDateTime DESC')
        .get();
      return result.value;
    } catch (error) {
    }
  }

  async createEvents(event: EventModel, timeZone: TimeZoneModel): Promise<any> {
    await this.authService.signIn();
    //await this.initGraphClient();
    const calendar = {
      'Subject': event.name,
      'Body': {
        'ContentType': 'HTML',
        'Content': event.description
      },
      'Start': {
        'DateTime': this.getStartDate(event),
        'TimeZone': timeZone.name
      },
      'End': {
        'DateTime': this.getEndDate(event),
        'TimeZone': timeZone.name
      }
    };
    this.authService.getAccessToken()
      .subscribe((response: AuthenticationResult) => {
        this.graphClient = Client.init({
          authProvider: (done: AuthProviderCallback) => {
            if (response.accessToken) {
              done(null, response.accessToken);
            } else {
              done('Could not get an access token', null);
            }
          }
        });
        try {
          const result = this.graphClient
            .api('/me/events')
            .create(calendar);
          this.toastrService.success('Event successfully added', 'Outlook Calendar');
          return new Promise((resolve, reject) => resolve(result));
        } catch (error) {
        }
      });
  }

  /*initGraphClient() {
    this.graphClient = Client.init({
      authProvider: async (done) => {
        // Get the token from the auth service
        const token = await this.authService.getAccessToken()
          .catch((reason) => {
            done(reason, null);
          });

        if (token) {
          done(null, token);
        } else {
          done('Could not get an access token', null);
        }
      }
    });
  }*/

  private get24TimeFormat(timeInitial: string): string {
    const [time, modifier] = timeInitial.split(' ');
    // eslint-disable-next-line prefer-const
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM' || modifier === 'pm') {
      hours = (parseInt(hours, 10) + 12).toString();
    }
    return `${hours}:${minutes}:00`;
  }

  private getStartDate(event: any): string {
    if (event.eventSchedularList && event.eventSchedularList.length > 0) {
      return event.eventSchedularList[0].startDate.slice(0, 11) + this.get24TimeFormat(event.startTime)
    } else {
      const date = new Date(event.startDate);
      date.setDate(date.getDate() + 1);
      return new Date(date).toISOString().slice(0, 11) + this.get24TimeFormat(event.startTime)
    }
  }

  private getEndDate(event: any): string {
    if (event.eventSchedularList && event.eventSchedularList.length > 0) {
      return event.eventSchedularList[0].endDate.slice(0, 11) + this.get24TimeFormat(event.endTime)
    } else {
      const date = new Date(event.endDate);
      date.setDate(date.getDate() + (event.endTime ? 1 : 2));
      return new Date(date).toISOString().slice(0, 11) + this.get24TimeFormat(event.endTime ? event.endTime : '12:00 PM')
    }
  }
}
