import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CommentWallPopupDataModel} from '../../../../../models/templates/comment.wall.popup.data.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormValidators} from '../../../../../shared/validatiors/form-validators';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {exhaustMap, finalize, first, map, tap} from 'rxjs/operators';
import {BeforeUnloadComponent} from '../../../../before-unload/before-unload.component';
import {DialogBeforeunloadResponseType} from '../../../../../models/enum/dialog.beforeunload.response.type';
import {EventCommentWallModel} from '../../../../../models/event/event.comment.wall.model';
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-comment-wall-add-new',
  templateUrl: './comment-wall-add-new.component.html',
  styleUrls: ['../comment-wall.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentWallAddNewComponent implements OnInit {

  public form: FormGroup = this.formBuilder.group({
    firstName: ['', [Validators.required, Validators.maxLength(20)]],
    lastName: ['', [Validators.required, Validators.maxLength(20)]],
    email: ['', [Validators.required, FormValidators.emailValidator, Validators.maxLength(75)]],
    comment: ['', [Validators.required, Validators.maxLength(200)]],
    reCaptchaToken: ['', Validators.required],
  });

  public siteKey: string = environment.recaptcha_site_key;
  private saveCommentSubject: Subject<void> = new Subject<void>();
  constructor(
    public dialogRef: MatDialogRef<CommentWallAddNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommentWallPopupDataModel,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private dialog: MatDialog,
  ) { }

  public ngOnInit(): void {
    this.saveCommentSubject.asObservable()
      .pipe(
        first(),
        map(() => {
          const comment: Partial<EventCommentWallModel> = {
            ...this.form.value,
            eventID: this.data.eventID,
          };
          return comment;
        }),
        exhaustMap((comment: Partial<EventCommentWallModel>) => this.data.commentWallService.addNewComment(comment)),
        finalize(() => this.dialogRef.close('refreshComments'))
      )
      .subscribe()
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public get captchaError(): boolean {
    const control = this.form.get('reCaptchaToken') as FormControl;
    return control.touched && control.invalid;
  }

  public cancel(): void {
    const config = {
      data: {
        title: 'Would you like to close the new comment form?'
      }
    };
    this.dialog.open(BeforeUnloadComponent, config).afterClosed()
      .pipe(
        first(),
        tap(response => {
          switch (response) {
            case DialogBeforeunloadResponseType.Accept:
              this.dialogRef.close();
              break;
            case DialogBeforeunloadResponseType.Reject:
            case DialogBeforeunloadResponseType.Close:
            case undefined:
              return;
          }
        })
      )
      .subscribe()

  }

  public add(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.toastrService.error('Please fill all mandatory fields', 'Error');
    } else {
      this.saveCommentSubject.next();
    }
  }

}
