import { Component } from '@angular/core';
import { LayerType } from '../../models/image-builder/layer.type';

@Component({
  selector: 'app-image-builder-utils',
  template: ''
})
export class ImageBuilderUtilsComponent {

  public isTextLayer(type: LayerType): boolean {
    switch (type) {
      case LayerType.text:
      case LayerType.eventName:
      case LayerType.eventDescription:
      case LayerType.eventLocation:
      case LayerType.eventScheduler:
      case LayerType.eventContactDetails:
      case LayerType.eventScheduleItinerary:
      case LayerType.eventAccessibilityStatement:
      case LayerType.campaignName:
        return true;
      default:
        return false;
    }
  }

  public isImageLayer(type: LayerType): boolean {
    switch (type) {
      case LayerType.image:
      case LayerType.logo:
        return true;
      default:
        return false;
    }
  }

  public isShapeLayer(type: LayerType): boolean {
    switch (type) {
      case LayerType.circle:
      case LayerType.square:
      case LayerType.triangle:
      case LayerType.pentagon:
      case LayerType.hexagon:
        return true;
      default:
        return false;
    }
  }
}
