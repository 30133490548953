import { Injectable, Injector } from '@angular/core';
import { NavigationCancel, RouterEvent } from '@angular/router';
import { BaseModuleGuard } from './base.module.guard';

@Injectable({
  providedIn: 'root',
})
export class RoutesGuard extends BaseModuleGuard {
  public returnUrl: string;

  constructor(protected injector: Injector) {
    super(injector);
  }

  public canLoad(): boolean {
    const token = this.oauthService.hasValidAccessToken();
    if (!token) {
      this.authService.clearStorage();
      this.router.events
        .filter((event) => event instanceof NavigationCancel)
        .subscribe((e: RouterEvent) => {
          if (e.url.includes('createP2P')) {
            this.returnUrl = e.url;
          }
        });
      setTimeout(() => {
        if (this.returnUrl) {
          this.router.navigate(['/donor-register'], {
            queryParams: { returnUrl: this.returnUrl },
          });
        } else {
          this.router.navigateByUrl('/');
        }
      }, 500);
    }
    return token;
  }
}
