import {
  Component,
  Input,
  OnChanges,
  SimpleChanges, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { VolunteeringModel } from '../../models/event/volunteering.model';
import { SelectComponent } from '../form-elements/select/select.component';
import { UtilsComponent } from '../utils.component';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-volunteers-select',
  templateUrl: './volunteers-select.component.html',
  styleUrls: ['./volunteers-select.component.scss', '../form-elements/base.form.element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VolunteersSelectComponent extends SelectComponent implements OnChanges {
  @Input() public volunteeringList: VolunteeringModel[];
  @ViewChild('matSelect') public matSelect: MatSelect;

  constructor(public translate: TranslateService) {
    super(translate);
  }

  ngAfterViewInit(): void {
    (<any>this.matSelect).baseonselect = (<any>this.matSelect)._onSelect;
    (<any>this.matSelect)._onSelect = (ev, isUserInput) => {
       (<any>this.matSelect).baseonselect(ev, false);
    };
 }

  public ngOnChanges(changes: SimpleChanges): void {
    this.volunteeringList.sort(UtilsComponent.dropdownSorting('volunteerArea'))
    super.ngOnChanges(changes);
  }

  public dateString(option: VolunteeringModel): string {
    return UtilsComponent.dateStringFromVolunteeringModel(option);
  }

  public updateLabel(): void {
    this.matSelect.trigger.nativeElement.innerText = this.formControl.value
      .map((id: string) => this.volunteeringList.find((option: VolunteeringModel) => option.id === id).volunteerArea)
      .join(', ');
  }

}
