import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-star-icon',
  templateUrl: './star.component.html',
})
export class StarComponent {
  @Input() public color: string = '#0064BE'

}
