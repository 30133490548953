import { BaseModelService } from '../base.model.service';
import { Injectable, Injector } from '@angular/core';
import { ClientSettingsModel } from 'src/app/models/client/client.settings.model';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientSettingsService extends BaseModelService<
  ClientSettingsModel
> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'ClientSettings';
  }

  public addSettings(
    employeeRegistrationModel: ClientSettingsModel[]
  ): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        const url = `${config.apiURL}/${this.controllerName}/arrayPost`;
        return this.http.post<ClientSettingsModel[]>(
          url,
          employeeRegistrationModel
        );
      })
    );
  }

  public updateSettings(
    employeeRegistrationModel: ClientSettingsModel[]
  ): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        const url = `${config.apiURL}/${this.controllerName}/arrayPut`;
        return this.http.put<ClientSettingsModel[]>(
          url,
          employeeRegistrationModel
        );
      })
    );
  }
}
