<div class="email-builder-tool-settings-wrapper">
  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-select
        label="Platforms"
        [formGroup]="smForm"
        name="smNames"
        errorMassage="Platforms"
        [low]="true"
        [multiSelectOptions]="platformsOptions"
        [disabled]="readOnly"
        (valueChanged)="onSmNamesChanged()"
      ></app-select>
    </div>

    <div class="max-width-310">
      <app-input
        label="Text"
        [formGroup]="smForm"
        name="text"
        [low]="true"
        errorMassage="Text"
        (valueChanged)="onTextChanged()"
        [disabled]="readOnly"
        maxLength="250"
      ></app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-image-loader
        [formGroup]="smForm"
        name="imageUrl"
        [toolTipText]="toolTipText"
        label="Upload Preview Image"
        [readOnly]="readOnly"
        [imageURL]="image"
        (valueChanged)="imageUrlChanged($event); onUrlChanged()"
      ></app-image-loader>
    </div>


  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <div class="img-setting-share-sm">
        <app-input
          class="max-width-145"
          label="Image URL"
          [formGroup]="smForm"
          name="imageUrl"
          errorMassage="Image URL"
          [low]="true"
          [disabled]="readOnly"
          (valueChanged)="imageUrlChanged($event); onUrlChanged()"
        ></app-input>

        <app-button
          *ngIf="imageUrl && imageUrl.value && !image && !readOnly"
          label="Edit Image"
          type="flat"
          (clickEvent)="editImage()"
          color="#0064be"
          width="140"
        ></app-button>

        <!--<app-button
          *ngIf="!!image"
          label="Cancel"
          type="flat"
          (clickEvent)="cancelEditImage()"
          color="#ec4c47"
          width="140"
        ></app-button>-->
      </div>
      </div>
  </div>

  <div class="block-divider"></div>

  <ng-container *ngFor="let element of socialElements?.controls; index as i">
    <div class="email-builder-tool-settings-row">
      <span class="share-on-title">{{getPlatformName(element)}}</span>
    </div>

    <div class="email-builder-tool-settings-row">
      <div class="max-width-310">
        <app-input
          [formGroup]="element"
          name="backgroundColor"
          [colorPiker]="true"
          label="Icon Color"
          errorMassage="Icon Color"
          [topFix]="true"
          (inputEvent)="onIconColorChanged(element)"
        ></app-input>
      </div>
    </div>

    <div class="block-divider"></div>
  </ng-container>
  <br>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Mode"
        [formGroup]="smForm"
        name="mode"
        errorMassage="Mode"
        [low]="true"
        [selectOptions]="modeOptions"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onModeChanged()"
      ></app-select>
    </div>
    <div class="max-width-145">
      <app-select
        label="Position"
        [formGroup]="smForm"
        name="align"
        errorMassage="Position"
        [low]="true"
        [selectOptions]="alignOptions"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onAlignChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Font Family"
        [formGroup]="smForm"
        name="fontFamily"
        errorMassage="Font Family"
        [low]="true"
        [selectOptions]="fontFamilyOptions"
        [disabled]="readOnly"
        (valueChanged)="onFontFamilyChanged()"
      ></app-select>
    </div>
    <div class="max-width-145">
      <app-select
        label="Font Size"
        [formGroup]="smForm"
        name="fontSize"
        errorMassage="Font Size"
        [low]="true"
        [selectOptions]="fontSizeOptions"
        [disabled]="readOnly"
        (valueChanged)="onFontSizeChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Font Style"
        [formGroup]="smForm"
        name="fontStyle"
        errorMassage="Font Style"
        [low]="true"
        [selectOptions]="fontStyleOptions"
        [disabled]="readOnly"
        (valueChanged)="onFontStyleChanged()"
      ></app-select>
    </div>
    <div class="max-width-145">
      <app-select
        label="Font Weight"
        [formGroup]="smForm"
        name="fontWeight"
        errorMassage="Font Weight"
        [low]="true"
        [selectOptions]="fontWeightOptions"
        [disabled]="readOnly"
        (valueChanged)="onFontWeightChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="smForm"
        label="Text Color"
        name="color"
        [readOnly]="readOnly"
        (colorChanged)="onColorChanged()"
      ></app-email-color-picker>
    </div>
    <div class="max-width-145">
      <app-select
        label="Decoration"
        [formGroup]="smForm"
        name="textDecoration"
        errorMassage="Decoration"
        [low]="true"
        [selectOptions]="textDecorationOptions"
        [disabled]="readOnly"
        (valueChanged)="onTextDecorationChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="smForm"
        label="Background color"
        name="containerBackgroundColor"
        [readOnly]="readOnly || isPredesignedThemedTemplate"
        (colorChanged)="onContainerBackgroundColorChanged()"
      ></app-email-color-picker>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="smForm"
        name="iconSize"
        label="Icon size"
        [min]="10"
        [max]="100"
        [step]="5"
        [tickInterval]="5"
        (valueChanged)="onIconSizeChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="smForm"
        name="innerPadding"
        label="Inner padding"
        [min]="0"
        [max]="25"
        [step]="1"
        [tickInterval]="5"
        (valueChanged)="onInnerPaddingChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="smForm"
        name="borderRadius"
        label="Border radius"
        [min]="0"
        [max]="50"
        [step]="1"
        [tickInterval]="5"
        (valueChanged)="onBorderRadiusChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="smForm"
        name="paddingTop"
        label="Padding Top"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingTopChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="smForm"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingRightChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="smForm"
        name="paddingBottom"
        label="Padding Bottom"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingBottomChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="smForm"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingLeftChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row" *ngIf="!(readOnly || isPredesignedThemedTemplate)">
    <app-button
      type="basic"
      label="Delete content"
      (clickEvent)="tms.removeActiveElementFromColumn()"
      color="#f44336"
      width="300"
    ></app-button>
  </div>

</div>

