import { Component, Input } from '@angular/core';
import { IconsType } from '../../models/enum/icons.type';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent {
  @Input() public iconType: IconsType;
  @Input() public color: string = '#231F20';
  @Input() public width: number = 24;
  @Input() public height: number = 24;
  @Input() public disabled: boolean = false;

  public IconsType = IconsType;
}
