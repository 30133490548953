import { Injectable, Injector } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseModuleGuard implements CanActivate, CanLoad {
  protected authService: AuthService;
  protected oauthService: OAuthService;
  protected router: Router;
  public abstract canLoad(): boolean;

  protected constructor(
    protected injector: Injector
  ) {
    this.authService = injector.get(AuthService);
    this.router = injector.get(Router);
    this.oauthService = injector.get(OAuthService);
  }

  public canActivate(): boolean {
    return this.canLoad();
  }
}
