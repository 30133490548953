<div class="email-builder-tool-settings-wrapper">

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="tableForm"
        name="columns"
        label="Columns"
        [min]="1"
        [max]="10"
        [step]="1"
        [tickInterval]="1"
        (valueChanged)="onColumnsChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>

    <div class="max-width-145">
      <app-slider
        [formGroup]="tableForm"
        name="rows"
        label="Rows"
        [min]="1"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onRowsChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="tableForm"
        label="Table color"
        name="color"
        [readOnly]="readOnly"
        (colorChanged)="onColorChanged()"
      ></app-email-color-picker>
    </div>

    <div class="max-width-145">
      <app-select
        label="Position"
        [formGroup]="tableForm"
        name="align"
        errorMassage="Position"
        [selectOptions]="alignOptions"
        [low]="true"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onAlignChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="tableForm"
        label="Background color"
        name="containerBackgroundColor"
        [readOnly]="readOnly || isPredesignedThemedTemplate"
        (colorChanged)="onContainerBackgroundColorChanged()"
      ></app-email-color-picker>
    </div>

    <div class="max-width-145">
      <app-select
        label="Font Family"
        [formGroup]="tableForm"
        name="fontFamily"
        errorMassage="Font Family"
        [selectOptions]="fontFamilyOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontFamilyChanged()"
      ></app-select>
      <div
        *ngIf="tms.templateAssignmentType === TemplateAssignmentType.Campaign"
        class="settings-info-icon"
        [matTooltip]="toolTipText"
        matTooltipHideDelay="200"
        matTooltipShowDelay="200"
        matTooltipPosition="above"
        matTooltipClass="settings-tooltip-custom-class"
      >
        <i class="fas fa-info-circle"></i>
      </div>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Font Size"
        [formGroup]="tableForm"
        name="fontSize"
        errorMassage="Font Size"
        [selectOptions]="fontSizeOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontSizeChanged()"
      ></app-select>
    </div>

    <div class="max-width-145">
      <app-slider
        [formGroup]="tableForm"
        name="width"
        label="Table width (%)"
        [min]="50"
        [max]="100"
        [step]="5"
        [tickInterval]="1"
        (valueChanged)="onWidthChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="tableForm"
        name="paddingTop"
        label="Padding Top"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingTopChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="tableForm"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingRightChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="tableForm"
        name="paddingBottom"
        label="Padding Bottom"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingBottomChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="tableForm"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingLeftChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <app-button
      type="basic"
      label="Delete content"
      (clickEvent)="tms.removeActiveElementFromColumn()"
      color="#f44336"
      width="300"
    ></app-button>
  </div>

  <div class="email-builder-tool-settings-row" *ngIf="!(readOnly || isPredesignedThemedTemplate)">
    <app-button
      type="basic"
      label="Delete content"
      (clickEvent)="tms.removeActiveElementFromColumn()"
      color="#f44336"
      width="300"
    ></app-button>
  </div>

</div>
