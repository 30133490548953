import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-minus-icon',
  templateUrl: './minus-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MinusIconComponent {
  @Input() public color: string = '#0064be';
}
