import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { LayerModel } from '../../../../../models/image-builder/layer.model';
import { ImageBuilderStateService } from '../../../image-builder.state.service';
import { LayerType } from '../../../../../models/image-builder/layer.type';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shape-renderer',
  templateUrl: './shape-renderer.component.html',
  styleUrls: ['./shape-renderer.component.scss']
})
export class ShapeRendererComponent implements AfterViewInit, OnDestroy {
  @Input() public layer: LayerModel;
  @Input() public ibss: ImageBuilderStateService;
  public LayerType = LayerType;
  private subscription: Subscription = new Subscription();

  @ViewChild('elementRef') public elementRef: ElementRef;


  constructor(
    private renderer: Renderer2
  ) {
  }

  public ngAfterViewInit(): void {
    this.setShapeAttributes();
    this.subscription.add(
      this.ibss.shapeObservable.subscribe(() => {
        if (this.ibss.activeLayer !== this.layer) {
          return;
        }
        this.setShapeAttributes();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setShapeAttributes(): void {
    if (!this.elementRef) {
      return;
    }
    const {fill = 'none', stroke = '#66788a', strokeWidth = '0'} = this.layer.attributes;
    const element = this.elementRef.nativeElement;
    this.renderer.setAttribute(element, 'stroke', stroke);
    this.renderer.setAttribute(element, 'stroke-width', strokeWidth);
    this.renderer.setAttribute(element, 'fill', fill);
    if (this.layer.type === LayerType.circle) {
      this.renderer.setAttribute(element, 'r', (300 - +strokeWidth / 2).toString());
    }
  }
}
