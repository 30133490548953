<div class="custom-form-component-container-wrapper" [formGroup]="formGroup">
  <label class="form-field-name" [for]="id">{{label | translate}}</label>
  <input
    class="control-container"
    [formControlName]="name"
    [id]="id"
  >
  <mat-form-field appearance="outline" [matMenuTriggerFor]="menu">
    <input
      matInput
      [value]="currentValue | translate"
    >
    <mat-error *ngIf="formControl.invalid && !customError">{{getErrorMessage()}}</mat-error>
  </mat-form-field>
</div>


<mat-menu #menu="matMenu" overlapTrigger (closed)="setValue()">
  <div class="picker-container" (click)="$event.stopPropagation()">
    <div class="checkbox-field" matRipple (click)="toggleItem(CommunicationMethods.DoNotContact)">
      <img src="/assets/images/checkbox.svg" alt="checkbox" [class.hidden]="selected.has(CommunicationMethods.DoNotContact)">
      <img src="/assets/images/checkbox_checked.svg" alt="checkbox" [class.hidden]="!selected.has(CommunicationMethods.DoNotContact)">
      <span>{{'Do not contact' | translate}}</span>
    </div>
    <div class="checkbox-field" matRipple (click)="toggleItem(CommunicationMethods.Email)">
      <img src="/assets/images/checkbox.svg" alt="checkbox" [class.hidden]="selected.has(CommunicationMethods.Email)">
      <img src="/assets/images/checkbox_checked.svg" alt="checkbox" [class.hidden]="!selected.has(CommunicationMethods.Email)">
      <span>{{'Emails' | translate}}</span>
    </div>
    <div class="checkbox-field" matRipple (click)="toggleItem(CommunicationMethods.Phone)">
      <img src="/assets/images/checkbox.svg" alt="checkbox" [class.hidden]="selected.has(CommunicationMethods.Phone)">
      <img src="/assets/images/checkbox_checked.svg" alt="checkbox" [class.hidden]="!selected.has(CommunicationMethods.Phone)">
      <span>{{'Phone calls' | translate}}</span>
    </div>
    <div class="checkbox-field" matRipple (click)="toggleItem(CommunicationMethods.Mail)">
      <img src="/assets/images/checkbox.svg" alt="checkbox" [class.hidden]="selected.has(CommunicationMethods.Mail)">
      <img src="/assets/images/checkbox_checked.svg" alt="checkbox" [class.hidden]="!selected.has(CommunicationMethods.Mail)">
      <span>{{'Standard mail' | translate}}</span>
    </div>
    <div class="checkbox-field" matRipple (click)="toggleItem(CommunicationMethods.Text)">
      <img src="/assets/images/checkbox.svg" alt="checkbox" [class.hidden]="selected.has(CommunicationMethods.Text)">
      <img src="/assets/images/checkbox_checked.svg" alt="checkbox" [class.hidden]="!selected.has(CommunicationMethods.Text)">
      <span>{{'Text' | translate}}</span>
    </div>
  </div>
</mat-menu>
