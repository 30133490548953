import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import EventModel from '../../models/event/event.model';
import { PriceTicket } from '../../models/enum/event.price.ticket';
import { EventRegistrationRequiredFieldsType } from '../../models/enum/event.registration.required.fields.type';
import { LookupStoreService } from '../../services/lookup/lookup-store.service';
import { MatSelectChange } from '@angular/material/select';
import CountryModel from '../../models/internationalization/country.model';
import { BehaviorSubject, Observable, pipe, Subject, Subscription } from 'rxjs';
import { TerritorialEntityType } from '../../models/internationalization/territorial.entity.type';
import { CODE, LOCALITY, POLITICAL_UNIT, TERRITORIAL_ENTITY } from '../../constants';
import { LocalityType } from '../../models/internationalization/locality.type';
import { PoliticalUnitType } from '../../models/internationalization/political.unit.type';
import { CodeType } from '../../models/internationalization/code.type';
import { UtilsComponent } from '../utils.component';
import { filter, first, map, tap } from 'rxjs/operators';
import FormElementDataModel from '../../models/form.element.data.model';
import { FormValidators } from '../../shared/validatiors/form-validators';
import { ToastrService } from 'ngx-toastr';
import { VolunteeringModel } from '../../models/event/volunteering.model';
import EventParticipantModel from '../../models/event/event.participant.model';
import EventRegistrationModel from '../../models/event/event-registration.model';
import { FreeAddonModel } from '../../models/event/free-addon.model';
import { EventParticipantService } from '../../services/events/event-participant.service';
import { Paging } from 'src/app/models/paging/paging.model';
import { Filter } from '../../models/paging/filter.model';
import { FilterType } from 'src/app/models/enum/filter.type';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
@Component({
  selector: 'app-registration-contact-info',
  templateUrl: './registration-contact-info.component.html',
  styleUrls: ['./registration-contact-info.component.scss']
})
export class RegistrationContactInfoComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public isPreviewMode: boolean = false;
  @Input() public registrationForm: FormGroup;
  @Input() public eventModel: EventModel;
  @Input() public countryState: BehaviorSubject<Partial<CountryModel>>;
  @Input() public ticketsGroup: FormGroup;
  @Input() public tickets: FormElementDataModel[] = [];
  @Input() public guestTicketOptions: FormElementDataModel[];
  @Input() public triggerShowErrorFields: Observable<void>;
  @Input() public isEditModeOne: boolean = false;
  @Input() public isGuest: boolean = false;
  @Input() public isManualRegistration: boolean = false;
  @Input() public eventParticipant: EventParticipantModel;
  @Input() public ticketTypeOptions: FormElementDataModel[] = [];
  @Input() public freeAddons: FreeAddonModel[] = [];
  @Input() public eventRegEditModel: EventRegistrationModel;
  @Input() public ticketChosenOptions: Map<string, any[]>;
  @Input() public guestMaxReached: boolean = false;
  @ViewChild('firstNameRef') private firstNameRef: ElementRef;
  @ViewChild('lastNameRef') private lastNameRef: ElementRef;
  @ViewChild('titleRef') private titleRef: ElementRef;
  @ViewChild('emailRef') private emailRef: ElementRef;
  @ViewChild('phoneRef') private phoneRef: ElementRef;
  @ViewChild('countryRef') private countryRef: ElementRef;
  @ViewChild('territorialEntityRef') private territorialEntityRef: ElementRef;
  @ViewChild('streetAddressRef') private streetAddressRef: ElementRef;
  @ViewChild('streetAddress2Ref') private streetAddress2Ref: ElementRef;
  @ViewChild('cityRef') private cityRef: ElementRef;
  @ViewChild('stateRef') private stateRef: ElementRef;
  @ViewChild('zipCodeRef') private zipCodeRef: ElementRef;
  @ViewChild('getAdditionalParticipantRef') private getAdditionalParticipantRef: ElementRef;
  @ViewChild('noteRef') private noteRef: ElementRef;

  @Output() public updateTotalCost: EventEmitter<number> = new EventEmitter<number>();

  private countries: Map<number, CountryModel> = new Map<number, CountryModel>();

  public territorialEntity$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public territorialEntityError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showTerritorialEntity$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public city$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public cityError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showCity$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public state$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public stateError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isUsa$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public zip$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public zipError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public zipPlaceholder$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public zipMask$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showZip$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public phonePrefix$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public phoneMask$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public phonePlaceholder$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public selectedOptions: FormElementDataModel[] = [{label: '1', value: 1}];
  public TerritorialEntityType = TerritorialEntityType;

  private subscription: Subscription = new Subscription();
  private ticketErrorMessage = 'To continue, you will need to increase the quantity of tickets selected';
  private guestErrorMessage = 'Maximum number of allowed guests has been reached';
  public locationRadioButtonData: FormElementDataModel[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  public eventHasVolunteeringOptions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private volunteeringSelection: Map<string, number> = new Map<string, number>();
  public PriceTicket = PriceTicket;

  private static getAvailableVolunteering(volunteeringList: VolunteeringModel[]): VolunteeringModel[] {
    return volunteeringList.filter((item: VolunteeringModel) => item.numberOfVolunteers < item.numberOfVolunteersNeeded);
  }

  private participantsCountChanges$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  @Output() public participantsCountChange: EventEmitter<number> = new EventEmitter<number>();

  public isAdditionlParticipantListInvalid: boolean = true;


  constructor(
    public lookupStoreService: LookupStoreService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private eventParticipantService: EventParticipantService,
    private activeRouter: ActivatedRoute,
  ) { }

  public ngOnInit(): void {
    this.subscription.add(
      this.participantsCountChanges$.subscribe((count) => {
        this.participantsCountChange.emit(count);
      })
    )
    this.lookupStoreService.countriesMap$
      .pipe(
        first(),
        tap(countriesMap => {
          this.countries = countriesMap;
        })
      )
      .subscribe();

    this.countryStateSubscription();

    this.subscription.add(
      this.lookupStoreService.countriesChanged$
        .pipe(tap((resp) => {
            const country = this.countries.get(resp.registrationModel?.country);
            this.countryState.next(country);
        }))
        .subscribe()
    );

    this.subscription.add(
      this.triggerShowErrorFields.subscribe(this.showErrorFields.bind(this))
    );

    if (this.eventParticipant && this.eventParticipant.isGuest) {
      const country = this.countries.get(this.eventParticipant.countryId);
      this.countryState.next(country);
    }

    this.registrationForm.valueChanges
      .pipe(
        filter(() => this.participantList?.value),
        map(() => this.participantList?.invalid)
      )
      .subscribe(value => {
        this.isAdditionlParticipantListInvalid = value
      })
  }

  public get getAddonGuest(): FormArray {
    return this.editAdditionalGuest.get('addonIncludes') as FormArray;
  }

  public get isMealOptions(): boolean {
    return this.freeAddons && !!this.freeAddons.length || !!this.getAddonGuest.value
  }

  public getAddonName(id: number): string {
    let name = '';
    if (this.isGuest) {
      this.eventParticipant.ticket.addonIncludes.forEach(item => {
        if (item.ticketPackageAddon && item.ticketPackageAddon.id === id) {
          name = item.ticketPackageAddon.name;
        }
      });
    }
    return name;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.eventModel && changes.eventModel.currentValue) {
      this.countryStateSubscription();
      const volunteering = !!(this.eventModel.volunteeringList && this.eventModel.volunteeringList.length);
      this.eventHasVolunteeringOptions$.next(volunteering);
      this.eventModel.volunteeringList.forEach(option => this.volunteeringSelection.set(option.id, 0));
      this.volunteeringListIds.disable();
    }
  }

  public get editAdditionalGuest(): FormGroup {
    return this.registrationForm.get('editAdditionalGuest') as FormGroup;
  }

  public get requiredFieldsPermission(): boolean {
    if (!this.eventModel /*|| this.isManualRegistration || this.isEditModeOne*/) {
      return false;
    } else {
      const { hasRegistrationForm, priceTicketType, registrationRequiredFields }: EventModel = this.eventModel;
      return !(
        hasRegistrationForm
        && priceTicketType === PriceTicket.NoFee
        && registrationRequiredFields === EventRegistrationRequiredFieldsType.FirstLastNameEmailRequired
      );
    }
  }

  public get isDisabled(): boolean {
    return this.isPreviewMode || this.isEditModeOne;
  }

  public onCountryChanged({ value }: MatSelectChange): void {
    const country = this.countries.get(value);
    this.countryState.next(country);
  }

  private countryStateSubscription(): void {
    this.subscription.add(
      this.countryState.subscribe(({ territorialEntityType, localityType, politicalUnitType, name, codeType, phoneCode = '1' }: Partial<CountryModel>) => {
        this.isUsa$.next(name === 'USA');

        this.territorialEntity$.next(TERRITORIAL_ENTITY[territorialEntityType]);
        this.territorialEntityError$.next(TERRITORIAL_ENTITY[territorialEntityType]);
        const isTerritorialEntityActive = territorialEntityType !== TerritorialEntityType.Unknown;
        this.showTerritorialEntity$.next(isTerritorialEntityActive);
        this.territorialEntity.reset();
        isTerritorialEntityActive ? this.territorialEntity.enable() : this.territorialEntity.disable();

        this.city$.next(this.getAsteriskLabel(LOCALITY[localityType]));
        this.cityError$.next(LOCALITY[localityType]);
        const isCityActive = localityType !== LocalityType.Unknown;
        this.showCity$.next(isCityActive);
        this.city.reset();
        isCityActive ? this.city.enable() : this.city.disable();
        this.state$.next(this.getAsteriskLabel(POLITICAL_UNIT[politicalUnitType]));
        this.stateError$.next(POLITICAL_UNIT[politicalUnitType]);
        const isStateActive = politicalUnitType !== PoliticalUnitType.Unknown;
        this.showState$.next(isStateActive);
        this.state.reset();
        isStateActive && !this.isDisabled ? this.state.enable() : this.state.disable();

        this.zip$.next(this.getAsteriskLabel(CODE[codeType]));
        this.zipError$.next(CODE[codeType]);
        const isZipActive = codeType !== CodeType.Unknown;
        this.showZip$.next(isZipActive);
        this.zipCode.reset();
        isZipActive ? this.zipCode.enable() : this.zipCode.disable();
        if ((name === 'USA' || name.startsWith('Puerto')) && this.requiredFieldsPermission) {
          this.zipCode.setValidators([Validators.required, Validators.pattern('^(\\d{5}|\\d{9})$')]);
        } else if (this.requiredFieldsPermission) {
          this.zipCode.setValidators([Validators.required, Validators.maxLength(30)]);
        } else {
          this.zipCode.clearValidators();
        }
        this.zipMask$.next((name === 'USA' || name.startsWith('Puerto')) ? '00000-9999' : '');
        this.zipPlaceholder$.next((name === 'USA' || name.startsWith('Puerto')) ? '00000-0000' : '');
        this.zipCode.updateValueAndValidity();
        const prefix = `+${phoneCode} `;
        this.phonePrefix$.next(prefix);
        const mask = name === 'USA' ? '(000) 000-0000' : '00000000999999999999'.slice(0, -phoneCode.length);
        this.phoneMask$.next(mask);
        this.phonePlaceholder$.next(prefix);
        this.phone.reset();
        this.getAdditionalParticipant && this.getAdditionalParticipant.controls.forEach((formGroup: FormGroup) => formGroup.get('phone').reset());

        this.streetAddress.reset();
        this.streetAddress2.reset();
        this.registrationForm.updateValueAndValidity();
      })
    );
  }

  private getAsteriskLabel(label: string): string {
    return this.requiredFieldsPermission ? label + ' *' : label;
  }

  private showErrorFields(): void {
    if (this.firstName && this.firstName.invalid) {
      UtilsComponent.scrollIntoView(this.firstNameRef);
    } else if (this.lastName && this.lastName.invalid) {
      UtilsComponent.scrollIntoView(this.lastNameRef);
    } else if (this.title && this.title.invalid) {
      UtilsComponent.scrollIntoView(this.titleRef);
    } else if (this.email && this.email.invalid) {
      UtilsComponent.scrollIntoView(this.emailRef);
    } else if (this.phone && this.phone.invalid) {
      UtilsComponent.scrollIntoView(this.phoneRef);
    } else if (this.country && this.country.invalid) {
      UtilsComponent.scrollIntoView(this.countryRef);
    } else if (this.territorialEntity && this.territorialEntity.invalid) {
      UtilsComponent.scrollIntoView(this.territorialEntityRef);
    } else if (this.streetAddress && this.streetAddress.invalid) {
      UtilsComponent.scrollIntoView(this.streetAddressRef);
    } else if (this.streetAddress2 && this.streetAddress2.invalid) {
      UtilsComponent.scrollIntoView(this.streetAddress2Ref);
    } else if (this.city && this.city.invalid) {
      UtilsComponent.scrollIntoView(this.cityRef);
    } else if (this.state && this.state.invalid) {
      UtilsComponent.scrollIntoView(this.stateRef);
    } else if (this.zipCode && this.zipCode.invalid) {
      UtilsComponent.scrollIntoView(this.zipCodeRef);
    } else if (this.getAdditionalParticipant && this.getAdditionalParticipant.invalid) {
      UtilsComponent.scrollIntoView(this.getAdditionalParticipantRef);
    } else if (this.note && this.note.invalid) {
      UtilsComponent.scrollIntoView(this.noteRef);
    }
  }

  public get firstName(): FormControl {
    return this.registrationForm.get('firstName') as FormControl;
  }

  public get lastName(): FormControl {
    return this.registrationForm.get('lastName') as FormControl;
  }

  public get email(): FormControl {
    return this.registrationForm.get('email') as FormControl;
  }

  public get phone(): FormControl {
    return this.registrationForm.get('phone') as FormControl;
  }

  public get country(): FormControl {
    return this.registrationForm.get('country') as FormControl;
  }

  public get territorialEntity(): FormControl {
    return this.registrationForm.get('territorialEntity') as FormControl;
  }

  public get city(): FormControl {
    return this.registrationForm.get('city') as FormControl;
  }

  public get state(): FormControl {
    return this.registrationForm.get('stateId') as FormControl;
  }

  public get zipCode(): FormControl {
    return this.registrationForm.get('zipCode') as FormControl;
  }

  public get streetAddress(): FormControl {
    return this.registrationForm.get('streetAddress') as FormControl;
  }

  public get title(): FormControl {
    return this.registrationForm.get('title') as FormControl;
  }

  public get streetAddress2(): FormControl {
    return this.registrationForm.get('streetAddress2') as FormControl;
  }

  public get note(): FormControl {
    return this.registrationForm.get('note') as FormControl;
  }

  public get getAdditionalParticipant(): FormArray {
    return this.registrationForm.get('additionalParticipants') as FormArray;
  }

  public get participantList(): FormArray {
    return this.registrationForm.get('additionalParticipants') as FormArray;
  }

  public get volunteeringListIds(): FormControl {
    return this.registrationForm.get('volunteeringListIds') as FormControl;
  }

  public checkGuestCount(): boolean {
    let possibleCount = 0;
    this.eventModel?.ticketPackageList.forEach(ticketPackage => {
      const ticketCount = this.ticketsGroup.controls[ticketPackage.id].get('quantity').value;
      possibleCount += ticketCount * ticketPackage.inPackageCount;
    });
    //const mainGuestTicket = this.registrationForm.get('ticketPackageId').value ? 1 : 0;
    return ((!this.participantList && possibleCount > 1) || (this.participantList && (this.participantList.length + 1) <= possibleCount));
  }

  public addEventParticipant(): void {
    const participantGroup = this.participantFormGroup;
    this.setParticipantPhoneValidators(participantGroup);

    /*if (this.eventModel.priceTicketType === PriceTicket.Ticket) {
      participantGroup.addControl('ticketType', new FormControl(['', Validators.required]));
    }*/

    this.registrationForm.addControl('additionalParticipants', this.formBuilder.array([participantGroup]));
  }

  private setParticipantPhoneValidators(participantGroup: FormGroup): void {
    if (this.isManualRegistration || this.isEditModeOne) {
      return;
    }
    if (this.requiredFieldsPermission) {
      participantGroup.get('phone').setValidators(Validators.required);
      participantGroup.get('phone').updateValueAndValidity();
      participantGroup.updateValueAndValidity();
    }
  };

  private addAdditionParticipantAddonForm(participant?: any): void {
    UtilsComponent.cleanFormArray(this.getParticipantAddonForm(participant));
    if (this.ticketChosenOptions.get(participant.get('ticketType').value)) {
      this.ticketChosenOptions.get(participant.get('ticketType').value).forEach((item, index) => {
        const key = +Object.keys(item)[0];
        const selectedArr = item[key];
        const newForm = this.formBuilder.group({
          addonID: key,
          selectedOptions: [selectedArr],
          includeID: null
        });
        this.getParticipantAddonForm(participant).push(newForm);
      });
    }
  }

  public getParticipantAddonForm(participant?: any): FormArray {
    if (participant) {
      return participant.get('eventTicketAddons') as FormArray;
    }
  }

  public deleteGuest(index: number): void {
    this.participantList.removeAt(index);
    this.updateGuestTicketOptions();
    this.updateParticipantsCount(false);
  }

  public addGuest(): void {
    const filters: Filter[] = [
      {
        field: 'eventID',
        value: this.activeRouter.snapshot.params.eventId,
        type: FilterType.Equal,
      }
    ];
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: false,
      filters
    };
    this.eventParticipantService.getRegistrations(paging).subscribe(participant => {
      if (participant && (this.eventModel?.guestAmountMax <= participant?.length + 1 + this.participantList.length)) {
        this.toastrService.error(this.guestErrorMessage, 'Warning');
      } else {
        let possibleCount = 0;
        this.eventModel.ticketPackageList.forEach(ticketPackage => {
          const ticketCount = this.ticketsGroup.controls[ticketPackage.id].get('quantity').value;
          possibleCount += ticketCount * ticketPackage.inPackageCount;
        });
        if ((this.participantList && (this.participantList.length + 2 <= possibleCount)) || this.eventModel.priceTicketType !== PriceTicket.Ticket) {
          const participantGroup = this.participantFormGroup;
          this.setParticipantPhoneValidators(participantGroup);
          this.participantList.push(participantGroup);
          this.updateParticipantsCount();
        } else {
          this.toastrService.error(this.ticketErrorMessage, 'Warning');
        }
      }
    });
  }

  public getQuantityOptions(selectedOptions: FormElementDataModel[], ticketID: string, addonID: number, currentValueForm: AbstractControl): FormElementDataModel[] {
    const currentValue = currentValueForm.get('includeID').value;
    const options = {};
    selectedOptions.forEach(item => {
      options[item.value] = 0;
    });
    /*checking how match times addon selected by main guest*/
    if (this.getEventTicketAddons) {
      this.getEventTicketAddons.controls.forEach(mainAddons => {
        if (mainAddons.get('includeID').value) {
          Object.keys(options).forEach(item => {
            if (+item === mainAddons.get('includeID').value) {
              options[item]++;
            }
          })
        }
      });
    }
    /*checking how match times addon selected by by additional guest*/
    if (this.getAdditionalParticipant) {
      this.getAdditionalParticipant.controls.forEach(participant => {
        if (participant.get('eventTicketAddons')) {
          const participantAddons = participant.get('eventTicketAddons') as FormArray;
          participantAddons.controls.forEach(control => {
            if (control.get('includeID').value) {
              Object.keys(options).forEach(item => {
                if (+item === control.get('includeID').value) {
                  options[item]++;
                }
              })
            }
          })
        }
      })
    }
    /*filtered selected options by huw match times addon selected plus current selected by buyer*/
    return selectedOptions.filter(selectOption => {
      const a = selectOption.value === currentValue ? 1 : 0;
      return ((selectOption.count - (options[selectOption.value] - a))) > 0;
    });
  }

  public isAddonsAndTicketSelected(formControl: AbstractControl): boolean {
    if (formControl) {
      if (this.ticketFormGroupById(formControl.value)) {
        const addons = this.ticketFormGroupById(formControl.value).get('addons') as FormArray;
        let isAnyAddons = false;
        addons.controls.forEach(item => {
          const includes = item.get('includes') as FormArray;
          includes.controls.forEach(controls => {
            if (controls.get('includeID').value && controls.get('count').value) {
              isAnyAddons = true;
            }
          });
        });
        return isAnyAddons;
      }
    }
  }


  public get getEventTicketAddons(): FormArray {
    return this.registrationForm.get('eventTicketAddons') as FormArray;
  }

  public getAddonsLabel(addonID: number): string {
    let label = '';
    this.eventModel.ticketPackageList.forEach(packageItem => {
      packageItem.addons.forEach(addonsItem => {
        if (addonsItem.id === addonID) {
          label = addonsItem.name
        }
      })
    });
    return label;
  }

  public getMainTicketType(): FormElementDataModel[] {
    const ticketType = this.registrationForm.get('ticketPackageId').value;
    if (ticketType) {
      const selectedOption = this.tickets.find(option => option.value === ticketType);
      const hasOption = this.guestTicketOptions.find(option => option.value === selectedOption.value);
      const result =  hasOption ? this.guestTicketOptions : [selectedOption, ...this.guestTicketOptions];
      return result.sort((item1, item2) => item1.label.localeCompare(item2.label));
    } else {
      return this.guestTicketOptions.sort((item1, item2) => item1.label.localeCompare(item2.label));
    }
  }

  public manageParticipants(isGuest: any) {
        if (isGuest) {
          if (this.checkGuestCount() || this.eventModel.priceTicketType !== PriceTicket.Ticket) {
            this.addEventParticipant();
            this.updateParticipantsCount();
          } else {
            setTimeout(() => {
              this.registrationForm.controls.hasGuest.setValue(false, { emitEvent: true });
            }, 0);
            this.toastrService.error(this.ticketErrorMessage, 'Warning');
          }
        } else {
          if (this.eventModel.priceTicketType === 2) {
            this.updateTotalCost.emit(this.eventModel.ticketPackageList[0].price);
          }
          this.registrationForm.removeControl('additionalParticipants');
          this.mangeMainAndAdditionalGuestOptions();
          this.participantsCountChanges$.next(0);
        }
  }

  public setMainGuestAddons(): void {
    UtilsComponent.cleanFormArray(this.getEventTicketAddons);
    if (this.ticketChosenOptions.get(this.registrationForm.get('ticketPackageId').value)) {
      this.ticketChosenOptions.get(this.registrationForm.get('ticketPackageId').value).forEach((item, index) => {
        const key = +Object.keys(item)[0];
        const selectedArr = item[key];
        const newForm = this.formBuilder.group({
          addonID: key,
          selectedOptions: [selectedArr],
          includeID: null
        });
        this.getEventTicketAddons.push(newForm);
      });
    }
    this.mangeMainAndAdditionalGuestOptions();
  }

  private mangeMainAndAdditionalGuestOptions(): void {
    const tickets: FormElementDataModel[] = JSON.parse(JSON.stringify(this.tickets));
    if (this.registrationForm.get('ticketPackageId').value) {
      setTimeout(() => {
        const ticketOption = tickets.find(ticket => ticket.value === this.registrationForm.get('ticketPackageId').value);
        ticketOption.amount -= 1;
        this.guestTicketOptions = tickets.filter(ticket => ticket.amount !== 0);
      }, 500);
    } else {
      this.guestTicketOptions = tickets;
    }
  }

  public getGuestTicketOptions(participant: any): FormElementDataModel[] {
    const ticketType = participant.get('ticketType').value;
    if (ticketType) {
      const selectedOption = this.tickets.find(option => option.value === ticketType);
      const hasOption = this.guestTicketOptions.find(option => option.value === selectedOption.value);
      const result = hasOption ? this.guestTicketOptions : [selectedOption, ...this.guestTicketOptions];
      return result.sort((item1, item2) => item1.label.localeCompare(item2.label));
    } else {
      return this.guestTicketOptions.sort((item1, item2) => item1.label.localeCompare(item2.label));
    }
  }

  public updateGuestTicketOptions(participant?: any): void {
    if(this.eventModel.priceTicketType !== PriceTicket.Ticket || !participant) return;
    const tickets: FormElementDataModel[] = JSON.parse(JSON.stringify(this.tickets));
    setTimeout(() => {
      this.participantList.value.forEach(model => {
        if (model.ticketType) {
          const ticketOption = tickets.find(ticket => ticket.value === model.ticketType);
          ticketOption.amount -= 1;
        }
      });
      if (this.registrationForm.get('ticketPackageId').value) {
        const main = tickets.find(ticket => ticket.value === this.registrationForm.get('ticketPackageId').value);
        main.amount -= 1;
      }
      this.guestTicketOptions = tickets.filter(ticket => ticket.amount !== 0);
    }, 500);
    this.addAdditionParticipantAddonForm(participant);
  }

  public get getTicketArray(): FormArray {
    return this.ticketFormGroupById(this.registrationForm.get('ticketPackageId').value).get('addons') as FormArray;
  }

  public ticketFormGroupById(id: string): FormGroup {
    return this.ticketsGroup.get(id) as FormGroup;
  }

  public get getIsManualOrEdit(): boolean {
    return this.isManualRegistration || this.isEditModeOne;
  }

  private get participantFormGroup(): FormGroup {
    const ticketTypeValidator = this.eventModel.priceTicketType === PriceTicket.Ticket ? [Validators.required] : [];
    const formGroup: FormGroup = this.formBuilder.group({
      fullName: ['', [Validators.required]],
      email: ['', [!this.getIsManualOrEdit ? Validators.required : Validators.nullValidator, FormValidators.emailValidator]],
      ticketType: ['', ticketTypeValidator],
      phone: [''],
      volunteeringListIds: [[]],
      isVolunteer: false,
      eventTicketAddons: new FormArray([])
    });
    formGroup.get('volunteeringListIds').disable();
    return formGroup;
  }

  public selectedVolunteersArea(id: string): string {
    const volunteerOption: VolunteeringModel = this.getVolunteerOptionById(id);
    return volunteerOption ? volunteerOption.volunteerArea : '';
  }

  public selectedVolunteersDate(id: string): string {
    const volunteerOption: VolunteeringModel = this.getVolunteerOptionById(id);
    return `(${UtilsComponent.dateStringFromVolunteeringModel(volunteerOption)})`;
  }

  private getVolunteerOptionById(id: string): VolunteeringModel {
    return this.eventModel.volunteeringList.find(item => item.id === id);
  }

  public volunteersActiveChanged(event: boolean, volunteersControl: AbstractControl): void {
    if (event) {
      volunteersControl.enable();
      volunteersControl.setValidators([Validators.required]);
    } else {
      volunteersControl.disable();
      volunteersControl.clearValidators();
      volunteersControl.reset();
    }
  }

  public getAvailableEditVolunteeringOptions(control: AbstractControl): VolunteeringModel[] {
    return this.eventModel.volunteeringList
      .filter((item: VolunteeringModel) => control?.value?.includes(item.id) || item?.numberOfVolunteers + this.volunteeringSelection.get(item.id) < item?.numberOfVolunteersNeeded);
  }

  public getAvailableVolunteeringOptions(control: AbstractControl): VolunteeringModel[] {
    if (control.value) {
      return RegistrationContactInfoComponent.getAvailableVolunteering(this.eventModel.volunteeringList)
        .filter((item: VolunteeringModel) => control.value.includes(item.id) || item.numberOfVolunteers + this.volunteeringSelection.get(item.id) < item.numberOfVolunteersNeeded);
    } else {
      return RegistrationContactInfoComponent.getAvailableVolunteering(this.eventModel.volunteeringList)
        .filter((item: VolunteeringModel) => item.numberOfVolunteers + this.volunteeringSelection.get(item.id) < item.numberOfVolunteersNeeded);
    }
  }

  private setVolunteeringSelection(volunteeringListIds: string[], volunteeringSelection: Map<string, number>): void {
    if (!volunteeringListIds) {
      return;
    }
    volunteeringListIds.forEach(
      (volunteeringId: string) => volunteeringSelection.set(volunteeringId, volunteeringSelection.get(volunteeringId) + 1)
    );
  }

  public manageVolunteeringSelection(): void {
    const initVolunteeringOptions: VolunteeringModel[] = RegistrationContactInfoComponent.getAvailableVolunteering(this.eventModel.volunteeringList);
    initVolunteeringOptions.forEach(option => this.volunteeringSelection.set(option.id, 0));
    this.setVolunteeringSelection(this.volunteeringListIds.value, this.volunteeringSelection);
    this.getAdditionalParticipant && this.getAdditionalParticipant.value.forEach(
      ({volunteeringListIds}) => this.setVolunteeringSelection(volunteeringListIds, this.volunteeringSelection)
    );
  }

  public isRequiredField(formGroup: AbstractControl, field: string) {
    const formControl = formGroup.get(field);
    if (!formControl) {
      return false;
    }

    if (!formControl.validator) {
      return false;
    }

    const validator = formControl.validator({} as AbstractControl);
    return (validator && validator.required);
  }

  private updateParticipantsCount(isIncrease: boolean = true): void {
    if(isIncrease) {
      const currParticipantsCount: number = this.participantsCountChanges$.getValue();
      this.participantsCountChanges$.next(currParticipantsCount + 1)
      return;
    }

    const currParticipantsCount: number = this.participantsCountChanges$.getValue();
    currParticipantsCount >= 0 && this.participantsCountChanges$.next(currParticipantsCount - 1);
    
  }
}
