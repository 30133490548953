import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormElementComponent } from '../base.form.element.component';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['../base.form.element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxComponent extends BaseFormElementComponent implements OnChanges {
  @Input() public isAdmin: boolean = false;
  @Input() public isLow: boolean = false;
  constructor(public translate: TranslateService) {
    super(translate);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
}
