import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { Subscription } from 'rxjs';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';

@Component({
  selector: 'app-video-tool',
  templateUrl: './video-tool.component.html',
  styleUrls: ['./video-tool.component.scss']
})
export class VideoToolComponent implements AfterViewInit, OnInit, OnDestroy  {
  private subscription: Subscription = new Subscription();
  @Input() public column: MjmlElementModel;
  @Input() public entity: MjmlElementModel;
  @Input() public tms: TemplateManagementService;
  @Input() public landingPageView: boolean = false;

  @ViewChild('videoContainerRef') private videoContainerRef: ElementRef;

  constructor() { }

  public ngOnInit(): void {
    this.subscription.add(
      this.tms.activeVideoChangedEmitter.subscribe((element: MjmlElementModel) => {
        if (element === this.entity) {
          this.setVideoHtml();
        }
      })
    );
  }

  public ngAfterViewInit(): void {
    this.setVideoHtml();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setVideoHtml(): void {
    const src = this.entity.attributes['src'] || '';
    const preload = this.entity.attributes['preload'] || 'auto';
    const autoplay = !!this.entity.attributes['autoplay'];
    const poster = this.entity.attributes['poster'] || '';
    const html = this.landingPageView
      ? this.tms.getVideoHtml(src, preload, autoplay, poster)
      : this.tms.getVideoHtmlWithoutControls(src, preload, autoplay, poster);
    if (this.videoContainerRef.nativeElement.innerHTML !== html) {
      this.videoContainerRef.nativeElement.innerHTML = html;
    }
  }

  public getContainerStyles(): any {
    const attributes = this.entity.attributes;
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    const containerBackgroundColor = attributes['container-background-color'] || 'transparent';
    return {
      'background-color': containerBackgroundColor,
      'padding-bottom': paddingBottom,
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      'padding-top': paddingTop
    };
  }

}
