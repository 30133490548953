import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from '../base.form.component';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['../base.form.scss']
})
export class CommentComponent extends BaseFormComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

  public get singleFormElementStyle(): { [key: string]: string } {
    const {width = 760, fontFamily = 'Roboto', fontSize = 16, fontColor = '#2C3345'} = this.template;
    return {
      'max-width': `${width}px`,
      'font-family': fontFamily,
      //'font-size': `${fontSize}px`,
      color: fontColor,
    }
  }

}
