import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { MjmlCustomType } from '../../../../models/templates/mjml.custom.type';
import { TemplateType } from '../../../../models/templates/template.type';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { BACKGROUNDR_REPEAT_OPTIONS, BACKGROUNDR_SIZE_OPTIONS } from '../../../../constants';
import { ActivatedRoute } from '@angular/router';
import { TemplateAssignmentType } from 'src/app/models/templates/template.assignment.type';

@Component({
  selector: 'app-email-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class EmailSettingsComponent implements OnInit, OnDestroy {
  @Input() public tms: TemplateManagementService;
  @Input() public eventId: string;
  @ViewChild('contentExpansionPanelRef') private contentExpansionPanelRef: MatExpansionPanel;
  @ViewChild('rowExpansionPanelRef') private rowExpansionPanelRef: MatExpansionPanel;
  @ViewChild('columnExpansionPanelRef') private columnExpansionPanelRef: MatExpansionPanel;
  @ViewChild('headerAndFooterPanelRef') private headerAndFooterPanelRef: MatExpansionPanel;
  public MjmlTag = MjmlTag;
  public MjmlCustomType = MjmlCustomType;
  private subscription: Subscription = new Subscription();
  public generalForm: FormGroup = this.formBuilder.group({
    width: [null, [Validators.min(500), Validators.max(1920)]],
    backgroundColor: ['#ffffff'],
    backgroundRepeat: 'repeat',
    backgroundUrl: '',
    backgroundSize: 'auto'
  });

  public backgroundRepeatOptions: FormElementDataModel[] = BACKGROUNDR_REPEAT_OPTIONS;
  public backgroundSizeOptions: FormElementDataModel[] = BACKGROUNDR_SIZE_OPTIONS;
  public imageURL: string = null;
  public TemplateAssignmentType = TemplateAssignmentType;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.setInitialValues();
    this.setSubscriptions();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setInitialValues(): void {
    const width = this.tms.template.children[1].attributes['width'];
    const backgroundColor = this.tms.template.children[1].attributes['background-color'];
    const backgroundRepeat = this.tms.template.children[1].children[0].attributes['background-repeat'];
    const backgroundUrl = this.tms.template.children[1].children[0].attributes['background-url'];
    const backgroundSize = this.tms.template.children[1].children[0].attributes['background-size'];
    if (width) {
      this.generalForm.get('width').setValue(width.slice(0, -2));
    }
    if (backgroundColor)
      this.generalForm.get('backgroundColor').setValue(backgroundColor);
    if (backgroundRepeat)
      this.generalForm.get('backgroundRepeat').setValue(backgroundRepeat);
    if (backgroundUrl)
      this.generalForm.get('backgroundUrl').setValue(backgroundUrl);
    if (backgroundSize)
      this.generalForm.get('backgroundSize').setValue(backgroundSize);
  }

  private setSubscriptions(): void {
    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((activeElement: MjmlElementModel) => {
        if (!activeElement) {
          return;
        }
        if (activeElement.tagName === MjmlTag.headerFooter) {
          this.headerAndFooterPanelRef.open();
          return;
        }
        this.contentExpansionPanelRef.open();
      })
    );
    this.subscription.add(
      this.tms.activeRowEmitter.subscribe((activeRow: MjmlElementModel) => {
        if (activeRow) {
          this.rowExpansionPanelRef.open();
        } else {
          this.rowExpansionPanelRef.close();
        }
      })
    );
    this.subscription.add(
      this.tms.activeColumnEmitter.subscribe((activeColumn: MjmlElementModel) => {
        if (activeColumn) {
          this.columnExpansionPanelRef.open();
        } else {
          this.columnExpansionPanelRef.close();
        }
      })
    );
    this.subscription.add(
      this.tms.templateChangedEmitter.subscribe((template: MjmlElementModel) => this.setInitialValues())
    );
  }

  public get isChart(): boolean {
    return this.tms.activeElement.attributes['customType'] === MjmlCustomType.chart;
  }

  public get isVideo(): boolean {
    const customType = this.tms.activeElement.attributes['customType'];
    return customType && customType === MjmlCustomType.video;
  }

  public get isSimpleText(): boolean {
    return !this.tms.activeElement.attributes['customType'];
  }

  public get isModule(): boolean {
    const customType = this.tms.activeElement.attributes['customType'];
    return customType && customType !== MjmlCustomType.video && customType !== MjmlCustomType.signature;
  }

  public get isSignature(): boolean {
    const customType = this.tms.activeElement.attributes['customType'];
    return customType && customType === MjmlCustomType.signature;
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public imageUrlChanged(url: string): void {
    this.imageURL = null;
    this.onBackgroundUrlChanged();
  }

  public editImage(): void {
    this.imageURL = this.generalForm.get('backgroundUrl').value;
  }

  public cancelEditImage(): void {
    this.imageURL = null;
  }

  public get customType(): MjmlCustomType {
    const customType: MjmlCustomType = this.tms.activeElement.attributes['customType'] as MjmlCustomType;
    return customType ? customType : null;
  }

  public onBackgroundColorChanged(): void {
    const value = this.generalForm.get('backgroundColor').value;
    if (value && this.generalForm.get('backgroundColor').valid) {
      this.tms.template.children[1].attributes['background-color'] = value;
      this.tms.emitSave(true);
    }
  }

  public onWidthChanged(): void {
    const value = this.generalForm.get('width').value;
    if (value && this.generalForm.get('width').valid) {
      this.tms.template.children[1].attributes.width = `${value}px`;
      this.tms.adjustImageSizes();
      this.tms.emitSave(true);
    }
  }

  public onBackgroundUrlChanged(): void {
    const value = this.generalForm.get('backgroundUrl').value;
    this.tms.template.children[1].children[0].attributes['background-url'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onBackgroundRepeatChanged(): void {
    const value = this.generalForm.get('backgroundRepeat').value;
    this.tms.template.children[1].children[0].attributes['background-repeat'] = value ? value : 'repeat';
    this.tms.emitSave(true);
  }

  public onBackgroundSizeChanged(): void {
    const value = this.generalForm.get('backgroundSize').value;
    this.tms.template.children[1].children[0].attributes['background-size'] = value ? value : 'auto';
    this.tms.emitSave(true);
  }
  public get tickInterval(): number | 'auto' {
    return 5;
  }
}
