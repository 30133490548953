import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {
  @Input()
  public disabled: boolean = false;
  @Output()
  public clickEvent: EventEmitter<void> = new EventEmitter<void>();
  @Input()
  public iconsType: number;
  @Input()
  public width: number = 16;
  @Input()
  public height: number = 16;
  @Input()
  public color: string = '#0064BE';

  constructor() {}

  public onClick(): void {
    if (this.disabled) {
      return;
    }
    this.clickEvent.emit();
  }
}
