<form class="quantity-group" [formGroup]="parentForm">
    <label class="quantity-label" for="quantity">Quantity *</label>
    <div class="quantity-container">
      <button
        class="quantity-button"
        (click)="onButtonClick('decrease')"
        [disabled]="minusButtonDisabled"
      >
        -
      </button>
      <input
        class="quantity-input"
        type="number"
        [formControlName]="ctrlName"
        name="quantity"
      />
      <button
        class="quantity-button"
        (click)="onButtonClick('increase')"
      >
        +
      </button>
    </div>
</form>