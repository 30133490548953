import {Directive, Input} from "@angular/core";
import Quill from "quill"

@Directive({
    selector: '[appQuillEditorSetup]'
})
export class QuillEditorSetupDirective {
    private _quill: Quill;
    @Input('appQuillEditorSetup') set quill(value: Quill) {
        if(!!value) {
            this._quill = value;
            this.initLogic();
        }
    }
  private initLogic() {
        const quill = this._quill;
      quill.keyboard.addBinding({
              key: 'backspace'
          }
          ,function(range, context) {
              if (!!context.format.nonEditable) {
                  return false;
              }
              else return true;
          }
      );
      quill.on('selection-change', function(range, oldRange, source) {
          if (range && source === 'user') {
              let nonEditableExists = false;
              let index = range.index;
              let length = range.length;
              while (length > 0) {
                  let [leaf, offset] = quill.getLeaf(index);
                  if(!leaf) break;
                  if ((leaf.domNode &&
                      !!leaf.domNode.parentElement.attributes?.contenteditable &&
                      leaf.domNode.parentElement.attributes?.contenteditable.value === 'false')
                  ) {
                      nonEditableExists = true;
                      break;
                  }
                  const elLength = leaf.length() > 0 ? leaf.length() : 1;
                  index += elLength;
                  length -= elLength;
              }
              if (nonEditableExists) {
                  quill.setSelection(0,0);
              }
          }
      });
   }



}
