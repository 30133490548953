import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from '../base.model.service';
import RecurringSettingsModel from '../../models/payments/recurring.settings.model';
import {Observable} from "rxjs";
import {flatMap} from "rxjs/operators";
import {ScheduleRecurringSettingModel} from "../../models/payments/schedule-recurring-setting.model";
import {HttpParams, HttpResponseBase} from "@angular/common/http";
import {Paging} from "../../models/paging/paging.model";
import {PagingHelper} from "../helper/paging.helper";

@Injectable({
  providedIn: 'root'
})
export class RecurringSettingsService extends BaseModelService<RecurringSettingsModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }
  protected get controllerName(): string {
    return 'ReccuringSettings';
  }

  public scheduleReccuringSetting(model: ScheduleRecurringSettingModel): Observable<HttpResponseBase> {
    return this.configService.loadConfig().pipe(
        flatMap(config => {
              return this.http.post<HttpResponseBase>(
                  `${config.apiURL}/${this.controllerName}/scheduleReccuring`, model, {observe: "response"})
            }
              )
        )
  }

  public updateSchedule(model: RecurringSettingsModel): Observable<RecurringSettingsModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
          return this.http.post<any>(`${config.apiURL}/${this.controllerName}/UpdateSchedule`, model)
        }
      )
    )
  }

  public getRecurringTransactionHistoryLog(filter: Paging) {
      const params: HttpParams = PagingHelper.getPagingParams(filter);
      return this.configService.loadConfig().pipe(
          flatMap(config => {
              return this.http.get<any[]>(
                  `${config.apiURL}/TransactionHistoryLog`,
                  { params }
              );
          })
      );
  }
}
