import { FilterType } from "./enum/filter.type";
import { SortOrder } from "./enum/sort.order";
import ColumnFilterModel from "./filter-sort/column.filter.model";
import { Filter } from "./paging/filter.model";
import { Paging } from "./paging/paging.model";

export interface DumbTableConfigModel {
  columns: DumbTableColumnConfigModel[],
  appearance?: DumbTableAppearance[],
  selectable?: boolean
  selectorConfig?: {
    /**
     * Determines which data field will be used as row id
     * "id" by default
     */
    rowId?: string | number
  }
}

export interface DumbTableColumnConfigModel {
  /**
   * Refers data source model's field.
   * Use xxx.yyy notation to access nested fields
   */
  name: string;

  /**
   * Table column's header real title.
   * Replaces name field (camel case syntax required) if label is not provided
   */
  label?: string;

   /**
   * Real data field name.
   * 
   * Pass distict value name if corresponding column name is not the same.
   * Helps select right options if column name and distinct value field name are not consistent
   */
   refersTo?: string,

  /**
   * Map default cell value. Eg. change date format or map enum number value to string
   * @param cellValue default cell value
   * @returns mapped cell value
   */
  mapValue?: (cellValue: any) => string | number;

  /**
   *  Set true to display filter icon.
   */
  filterable?: boolean

  /**
   * Extra filter settings
   */
  filterConfig?: DumbTableColumnFilterConfigModel;

  sortable?: boolean;
}

export interface DumbTableColumnFilterConfigModel {
  /**
   * Used during creating paging filters model
   */
  filterType?: FilterType

  /**
   * Map default displayed filter options labels. Eg. use it to change numeric statuses to strings
   * @param label default label
   * @returns mapped option's label
   */
  mapOptionLabels?: (label: any) => string | number
}

/**
 * Argument passed to filter changed handler
 */
export interface FilterChangedData {
  currColumn: DumbTableColumnConfigModel,
  selectedFilters: ColumnFilterModel[],
  filtersModel: Filter[],
}

/**
 * Argument passed to sort changed handler
 */
export interface SortChangedData {
  sortField: string,
  sortOrder: SortOrder
}

/**
 * Argument passed to paging changed handlers
 */
export interface PagingChangedData {
  total: number,
  currentPage: number,
  entriesPerPage: number,
  pagingModel: Paging
}

export interface SelectionChangedData {
  isSelectAll: boolean,
  touchedRows: any[],
  selectedRowsCount: number
}

export interface DistinctValues {
  [key: string]: any[]
}

export enum DumbTableAppearance {
  NO_BORDERS = "NO_BORDERS"
}

export class DumbTableConfigError extends Error {

  constructor(message: string) {
    super(message);
    this.name = "DumbTableConfigError";
  }
}