<div class="thermometer-builder-wrapper">
  <div class="thermometer-builder-header">
    <span>{{'Thermometer Configuration Tool' | translate}}</span>
    <i class="fas fa-times" (click)="close()"></i>
  </div>

  <div class="thermometer-builder-content">
    <div class="thermometer-builder-options">

      <div class="thermometer-builder-options-row">
        <div class="flex-start" style="margin-right: 19px;">
          <div class="max-width-275 min-width-240">
            <app-select
              [formGroup]="thermometerForm"
              name="appearance"
              label="Thermometer View"
              [selectOptions]="showThermometerAppearance"
              [low]="true"
            ></app-select>
          </div>
<!--          <div class="max-width-275">-->
<!--            <app-radio-->
<!--              [formGroup]="thermometerForm"-->
<!--              label="Alignment"-->
<!--              name="alignment"-->
<!--              [selectOptions]="alignmentOptions"-->
<!--            ></app-radio>-->
<!--          </div>-->
        </div>

        <div class="thermometer-builder-options-row-iterations">
          <app-input
            [formGroup]="thermometerForm"
            name="borderColor"
            [colorPiker]="true"
            label="Border"
            errorMassage="Border"
            [low]="true"
          ></app-input>

          <app-input
            [formGroup]="thermometerForm"
            name="containerFillColor"
            [colorPiker]="true"
            label="Fill"
            errorMassage="Fill"
            [low]="true"
          ></app-input>
        </div>

      </div>

      <div class="thermometer-builder-options-row">
        <div class="max-width-240 margin-right flex-start">
          <app-input
            [formGroup]="thermometerForm"
            name="goal"
            label="Goal *"
            mask="separator.2"
            prefix="$ "
            maxLength="13"
            thousandSeparator=","
            errorMassage="Goal"
            (keyup)="getGoal()"
            [low]="true"
          ></app-input>
        </div>

        <div class="max-width-240 flex-start">
          <app-select
            label="Text Size"
            [formGroup]="thermometerForm"
            name="fontSizeGoal"
            [selectOptions]="fontSizeOptions"
            [low]="true"
          ></app-select>
        </div>
      </div>

      <div class="thermometer-builder-options-row">

        <div class="thermometer-builder-options-row-iterations">
          <app-input
            [formGroup]="thermometerForm"
            name="iteration_1"
            label="Iterations"
            [inputReadOnly]="true"
            errorMassage="field"
            mask="separator.2"
            id="iteration_1"
            prefix="$ "
            thousandSeparator=","
            placeholder="#1"
            [low]="true"
          ></app-input>

          <app-input
            [formGroup]="thermometerForm"
            name="iteration_2"
            errorMassage="field"
            [inputReadOnly]="true"
            mask="separator.2"
            thousandSeparator=","
            prefix="$ "
            placeholder="#2"
            [low]="true"
          ></app-input>

          <app-input
            [formGroup]="thermometerForm"
            name="iteration_3"
            errorMassage="field"
            [inputReadOnly]="true"
            mask="separator.2"
            thousandSeparator=","
            prefix="$ "
            placeholder="#3"
            [low]="true"
          ></app-input>
        </div>
        <div class="max-width-240 margin-left flex-start">
          <app-select
            label="Text Size"
            [formGroup]="thermometerForm"
            name="fontSizeIterations"
            [selectOptions]="fontSizeOptions"
            [low]="true"
          ></app-select>

          <app-radio
            [formGroup]="thermometerForm"
            label="Text Placement"
            name="textPlace"
            [selectOptions]="getPosition"
          ></app-radio>
        </div>


      </div>

      <div class="thermometer-builder-options-row">
        <div class="max-width-225 margin-right">
          <app-checkbox
            [disabled]="true"
            [formGroup]="thermometerForm"
            name="showGoal"
            label="Goal"
            [isOpacity]="true"
          ></app-checkbox>
        </div>

        <div class="max-width-225 margin-right">
          <app-checkbox
            [formGroup]="thermometerForm"
            name="showDonations"
            label="Raised So Far"
          ></app-checkbox>
        </div>

        <div class="max-width-225 margin-right">
          <app-checkbox
            [formGroup]="thermometerForm"
            name="showDonors"
            label="# of Donations"
          ></app-checkbox>
        </div>
      </div>

      <div class="ngx-charts-lib-builder-options-row">
        <div class="max-width-60">
          <app-select
            [formGroup]="thermometerForm"
            name="showPercent"
            label="Show Percent Completion"
            [selectOptions]="showPercentageOptions"
            [low]="true"
          ></app-select>
        </div>
      </div>


      <div class="ngx-charts-lib-builder-options-row">
        <div class="max-width-170 min-height margin-right">
          <app-textarea
            [formGroup]="thermometerForm"
            name="description"
            label="Description *"
            errorMassage="Description"
            placeholder="Please enter description"
            [maxlengthText]="500"
            [minRows]="4"
            [maxRows]="5"
          ></app-textarea>
        </div>
      </div>

      <div class="thermometer-builder-options-row">
        <div class="max-width-240 margin-right flex-start">
          <app-select
            label="Description Text Size"
            [formGroup]="thermometerForm"
            name="fontSizeDescription"
            [selectOptions]="fontSizeOptions"
            [low]="true"
          ></app-select>
        </div>

        <div class="max-width-240 flex-start">
          <app-input
            [formGroup]="thermometerForm"
            name="containerTextColor"
            [colorPiker]="true"
            label="Text Color"
            errorMassage="field"
            [low]="true"
          ></app-input>
        </div>
      </div>
    </div>

    <div class="thermometer-builder-result">
      <app-thermometer [form]="thermometerForm">
      </app-thermometer>
    </div>

  </div>
  <div class="use-in-template-button">
    <app-button
      [disabled]="thermometerForm.invalid"
      label="Use In Template"
      type="flat"
      width="200"
      color="#0064be"
      (clickEvent)="captureThermometer()"
    ></app-button>
  </div>
</div>
