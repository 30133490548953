<div *ngIf="addonListAvailable | async" class="addons-block-container">
  <ng-container *ngFor="let addon of addonList; let addonIndex = index">
    <div
      *ngIf="displayAddOn(addon)"
      class="addon"
      [ngClass]="{
        'addon-active': isAddonActive(addon.id),
        'addon-invalid': isAddOnInvalid(addon.id)
      }"
    >
      <div class="addon-content">
        <div class="content-pusher">
          <app-checkbox
            [formGroup]="addonsFormGroupById(addon.id)"
            name="isChecked"
            (valueChanged)="handleCheckBoxClick(addon.id)"
            [disabled]="isDisabled()"
          ></app-checkbox>
        </div>
        <div class="addon-desc">
          <div class="addon-type-name">
            <span>
              {{ getAddonType(addon.type) }}
            </span>
          </div>
          <div class="addon-name">
            <span>
              {{ addon.name }}
            </span>
          </div>
        </div>
        <div
          *ngIf="!hasSubAddons(addonIndex) && isAddonActive(addon.id)"
          class="addon-counter"
        >
          <app-item-counter
            [formGroup]="addonsFormGroupById(addon.id)"
            name="quantity"
            [maxValue]="getAvailableAddOnsAmount(addon.id)"
            label="Quantity *"
            type="number"
            (maxValueReached)="handleMaxValueReached($event)"
            (valueChange)="handleValueChange(addon)"
            [disabled]="isDisabled()"
          ></app-item-counter>
        </div>
        <div *ngIf="!hasSubAddons(addonIndex)" class="addon-cost-desc">
          <div class="addon-cost">
            <span>Cost</span>
          </div>
          <div class="addon-ammount">
            <span>{{ addon.price | currency: 'USD' }}</span>
          </div>
        </div>
        <div *ngIf="hasSubAddons(addonIndex) && !isAddonActive(addon.id)" class="addon-cost-desc">
          <div class="addon-cost">
            <span>Cost</span>
          </div>
          <div
            *ngIf="getMinMaxPrices(addon).length === 1"
            class="addon-ammount">
            <span>{{ getMinMaxPrices(addon)[0] | currency: 'USD' }}</span>
          </div>
          <div
            *ngIf="getMinMaxPrices(addon).length !== 1 && getMinMaxPrices(addon)[0] === getMinMaxPrices(addon)[1]"
            class="addon-ammount">
            <span>{{ getMinMaxPrices(addon)[0] | currency: 'USD' }}</span>
          </div>
          <div
            *ngIf="getMinMaxPrices(addon).length !== 1 && getMinMaxPrices(addon)[0] !== getMinMaxPrices(addon)[1]"
            class="addon-ammount">
            <span>{{ getMinMaxPrices(addon)[0] | currency: 'USD' }} - {{ getMinMaxPrices(addon)[1] | currency: 'USD' }}</span>
          </div>
        </div>
      </div>

      <!-- SubAddons  -->
      <div
        *ngIf="hasSubAddons(addonIndex) && isAddonActive(addon.id)"
        class="addon-subAddpons"
      >
        <div *ngFor="let subAddon of addon.includes; let subAddonIndex = index">
          <div class="subAddon">
            <div class="subAddon-content">
              <span class="content-pusher"></span>
              <div
                class="subAddon-content-text-container"
                [ngClass]="{
                  'bottom-border': showBottomBorder(
                    addon.includes,
                    subAddonIndex
                  )
                }"
              >
                <div class="subAddon-desc">
                  <div class="subAddon-name">
                    <span>{{ subAddon.name }}</span>
                  </div>
                  <div class="subAddon-description">
                    <span>{{ subAddon.description }}</span>
                  </div>
                </div>
                <div>
                  <app-item-counter
                    [formGroup]="subAddonFormGroupByIds(addon.id, subAddon.id)"
                    name="quantity"
                    [maxValue]="getAvailableSubAddOnsAmount(addon.id, subAddon.id)"
                    label="Quantity *"
                    (maxValueReached)="handleMaxValueReached($event)"
                    (valueChange)="handleValueChange(addon)"
                    [disabled]="isDisabled()"
                  ></app-item-counter>
                </div>
                <div class="subAddon-cost-desc">
                  <div class="subAddon-cost">
                    <span>Cost</span>
                  </div>
                  <div class="subAddon-ammount">
                    <span>{{ subAddon.price | currency: 'USD' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
