<div  class="fb-page-container with-background email-settings-wrapper">
  <mat-tab-group #wrapper
    [selectedIndex]="currentTab"
    (selectedIndexChange)="currentTab = $event; adjustQuillFormatting($event)"
  >

    <mat-tab label="One Time Donation">
 <app-one-time-donation-email-settings-tab
 [fbss]="fbss"
 [placeholders]="placeholders"
 [formStatusOptions]="formStatusOptions"
 (valueChanged)="onValueChanged()"
 (resetEmailForNotification)="resetEmailForNotification()"
 (resetEmailToDonor)="resetEmailToDonor()"
 ></app-one-time-donation-email-settings-tab>
    </mat-tab>
    <mat-tab label="Recurring Donations">
        <app-recurring-donation-email-settings-tab
        [fbss]="fbss"
        (valueChanged)="onValueChanged()"
        [placeholders]="recurringPlaceholders"
        (resetEmail)="resetRecurringEmail($event)"
        >
        </app-recurring-donation-email-settings-tab>
    </mat-tab>
  </mat-tab-group>
</div>
