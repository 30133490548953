<div class="fb-options-row">
  <div class="fb-row-element-full">
    <app-input
      [formGroup]="optionsForm"
      name="mainLabel"
      label="Element Text"
      [low]="true"
      (valueChanged)="onControlValueChanged('mainLabel')"
      [disabled]="readOnly"
    ></app-input>
  </div>
</div>

<div class="fb-options-row">
  <div class="fb-row-element-full">
    <app-select
      [formGroup]="optionsForm"
      name="mainLabelAlignment"
      label="Label Alignment"
      [low]="true"
      (valueChanged)="onLabelAlignmentChanged()"
      [disabled]="readOnly"
      [selectOptions]="labelAlignmentOptions"
    ></app-select>
  </div>
</div>
<div class="fb-options-row">
  <app-fb-checkbox
          (clickEvent)="onIsFederalIDRequired()"
          [value]="control('isFederalIDRequired').value"
          [readOnly]="readOnly"
  >{{'Federal ID required'}}</app-fb-checkbox>
</div>

<div class="fb-options-row">
  <app-fb-checkbox
    (clickEvent)="onIsSetAsFormDefaultChanged()"
    [value]="isSetAsFormDefault.value"
    [readOnly]="readOnly"
  >{{'BUILD.Set as form default' | translate}}</app-fb-checkbox>
</div>
