import { Injectable } from '@angular/core';
import { ConfigService } from './appconfig.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { PasswordRequirementsModel } from '../models/user/password-requirements.model';
import { ChangePasswordModel } from '../models/user/change-password.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private api: string = '/ClientEmployee/';

  constructor(private configService: ConfigService, private http: HttpClient) { }

  public getPasswordCode(email: string): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const params = new HttpParams().set('email', email);
        return this.http.get<any>(`${config.apiURL}${this.api}SendResetPasswordCode`, { params: params });
      })
    );
  }

  public getPasswordRequirements(): Observable<PasswordRequirementsModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<PasswordRequirementsModel>(`${config.apiURL}${this.api}GetPasswordRequirements`);
      })
    );
  }

  public CheckResetPasswordToken(model: ChangePasswordModel): Observable<boolean> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const params = new HttpParams().set('email', model.email).set('code', model.code);
        return this.http.get<boolean>(`${config.apiURL}${this.api}CheckResetPasswordToken`, { params: params });
      })
    );
  }

  public resetPassword(model: ChangePasswordModel): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.post<any>(`${config.apiURL}${this.api}ResetPassword`, model);
      })
    );
  }

  public changePassword(model: ChangePasswordModel): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.post<any>(`${config.apiURL}${this.api}ChangePassword`, model);
      })
    );
  }
  public adminChangePassword(model: ChangePasswordModel): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.post<any>(`${config.apiURL}${this.api}AdminChangePassword`, model);
      })
    );
  }
  public checkUserExistByEmail(email: string): Observable<boolean> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<boolean>(`${config.apiURL}${this.api}CheckUserExistByEmail?email=${email}`);
      })
    );
  }
}
