import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DumbTableColumnConfigModel, DumbTableConfigError, DumbTableConfigModel, DistinctValues, FilterChangedData, SortChangedData, PagingChangedData, SelectionChangedData } from 'src/app/models/dumb-table-config.model';
import { FilterType } from 'src/app/models/enum/filter.type';
import { SortOrder } from 'src/app/models/enum/sort.order';
import ColumnFilterModel from 'src/app/models/filter-sort/column.filter.model';
import ColumnFilterOptionsModel from 'src/app/models/filter-sort/column.filter.options.model';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import { Filter, FilterModel } from 'src/app/models/paging/filter.model';
import { UtilsComponent } from '../utils.component';
import { AppearanceResolver, IAppearanceResolver } from './appearance-resolver';
import { Selector } from './helpers/selector';

@Component({
  selector: 'app-dumb-table',
  templateUrl: './dumb-table.component.html',
  styleUrls: ['./dumb-table.component.scss'],
})
export class DumbTableComponent implements OnInit, AfterViewInit {
  @Input() config: DumbTableConfigModel;
  @Input() data: any[];
  @Input() distinctValues: DistinctValues;
  @Input() total: number;

  @Output() filtersChanged: EventEmitter<FilterChangedData> = new EventEmitter<FilterChangedData>();
  @Output() sortChanged: EventEmitter<SortChangedData> = new EventEmitter<SortChangedData>();
  @Output() pagingChanged: EventEmitter<PagingChangedData> = new EventEmitter<PagingChangedData>();
  @Output() selectionChanged: EventEmitter<SelectionChangedData> = new EventEmitter<SelectionChangedData>();

  displayedColumns: string[];

  private filterOptions: ColumnFilterOptionsModel[];
  private selectedFilters: ColumnFilterModel[] = []

  private sortField: string;
  private sortOrder: SortOrder;

  private currPage: number = 1;
  private entriesPerPage: number = 10;

  selector: Selector;

  appearanceResolver: IAppearanceResolver;

  ngOnInit(): void {
    this.selector = new Selector(this.config);
  }

  ngAfterViewInit(): void {
    if(!this.data) throw new DumbTableConfigError("No data source was provided");
    if(!this.config) throw new DumbTableConfigError("No config object was provided");
  
    this.selector.rowId = this.config.selectorConfig?.rowId || "id"
    this.appearanceResolver = new AppearanceResolver(this.config.appearance);

    setTimeout(() => {
      if(!!this.distinctValues) {
        this.filterOptions = this.mapDistinctValues(this.distinctValues);
      }

      const colsToDisplay: string[] = this.config.columns.map((column: DumbTableColumnConfigModel) => column.name);
      this.selector.isSelectable && colsToDisplay.unshift("multiselect");
      this.displayedColumns = colsToDisplay
    });
  }

  private mapDistinctValues(dv: DistinctValues): ColumnFilterOptionsModel[] {
    const result: ColumnFilterOptionsModel[] = Object.keys(dv).map((key: string) => {

      const options: FormElementDataModel[] = dv[key].map((value: any) => {
        return {
          label: this.mapOptionLabel(key, value),
          value
        } as FormElementDataModel
      })
      
      return {
        columnName: key,
        options
      } as ColumnFilterOptionsModel

    })

    return result;
  }

  private mapOptionLabel(key: string, value: any): string | number {
    const currColumn: DumbTableColumnConfigModel = this.config.columns.find((col: DumbTableColumnConfigModel) => col?.refersTo === key || col.name === key)

    if(!!currColumn?.filterConfig?.mapOptionLabels) return currColumn.filterConfig.mapOptionLabels(value);

    return value;
  }

  private camelCaseToSpaces(text): string {
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

    return finalResult;
  }

  getHeaderTitle(column: DumbTableColumnConfigModel): string {
    if(!!column?.label) return column.label;

    return this.camelCaseToSpaces(column.name);
  }

  getCellValue(column: DumbTableColumnConfigModel, element: any): string | number {
    if(!column?.name) return null;

    const pathArray: string[] = column.name.split(".");

    if(!pathArray?.length) return null;

    let currValue = element;
    pathArray.forEach((field: string) => {
      currValue = currValue[field];
    })

    if(!!column.mapValue) return column.mapValue(currValue);

    return currValue;
  }

  getColumnFilterOptions(column: DumbTableColumnConfigModel): ColumnFilterOptionsModel {
    const colName: string = column?.refersTo ? column.refersTo : column.name;
    const result: ColumnFilterOptionsModel = this.filterOptions?.find((filter: ColumnFilterOptionsModel) => filter.columnName === colName);

    if(!result) throw new DumbTableConfigError(`Distinct value "${colName}" does not exist. Check if column name has corresponding distinct value item. If not, use "column.filterConfig.refersTo" field to connect those two values`);
    return result;
  }

  onFilterChanged(event: ColumnFilterModel, column: DumbTableColumnConfigModel): void {
    this.updateSelectedFilters(event);

    this.selector.clearSelection();
    this.selectionChanged.emit(this.selector.selectionChangedData)

    this.filtersChanged.emit({
      currColumn: column,
      selectedFilters: this.selectedFilters,
      filtersModel: this.mapColFilters()
    })
  }

  onSortChanged(column: DumbTableColumnConfigModel): void {
    this.updateSort(column);
    this.sortChanged.emit({
      sortField: this.sortField,
      sortOrder: this.sortOrder,
    })
  }

  onPageChanged(page: number): void {
    this.currPage = page;
    this.pagingChanged.emit(this.pagingChangedData)
  }

  onEntriesPerPageChanged(entries: number): void {
    this.entriesPerPage = entries;
    this.pagingChanged.emit(this.pagingChangedData)
  }

  private updateSelectedFilters(event: ColumnFilterModel): void {
    if(!event.values?.length) {
      this.deleteSelectedFiltersByName(event.field);
      return;
    }

    const currFilter = this.selectedFilters.find((filter: ColumnFilterModel) => filter.field === event.field);

    if(!currFilter) {
      this.selectedFilters.push(event);
      return;
    };

    currFilter.labels = event.labels;
    currFilter.values = event.values;
  }

  private updateSort(column: DumbTableColumnConfigModel): void {
    this.sortField = column.refersTo || column.name;

    if(this.sortOrder === null || this.sortOrder === undefined) {
      this.sortOrder = SortOrder.Ascending
    } else {
      if(this.sortOrder === SortOrder.Descending) {
        this.sortOrder = SortOrder.Ascending
      } else {
        this.sortOrder = SortOrder.Descending
      }
    }
  }

  private deleteSelectedFiltersByName(fieldName: string): void {
    const index = this.selectedFilters.findIndex((filter: ColumnFilterModel) => filter.field === fieldName);
    this.selectedFilters.splice(index, 1);
  }

  private mapColFilters(): Filter[] {
    return this.selectedFilters.map((colFilter: ColumnFilterModel) => {
      const values = colFilter.values.map((item: string) => `"${item}"`).join(',');
      const currColumn: DumbTableColumnConfigModel = this.config.columns.find((column: DumbTableColumnConfigModel) => column.name === colFilter.field);

      return {
        field: colFilter.field,
        value: `array[${values}]`,
        type: currColumn?.filterConfig?.filterType || FilterType.Equal
      } as FilterModel
    })
  }

  private get pagingChangedData(): PagingChangedData {
    return {
      currentPage: this.currPage,
      total: this.total,
      entriesPerPage: this.entriesPerPage,
      pagingModel: {
        first: UtilsComponent.getFirstItemOnPage(this.total, this.currPage, this.entriesPerPage),
        rows: this.entriesPerPage
      }
    }
  }

  isFilterable(column: DumbTableColumnConfigModel): boolean {
    return column.filterable || !!column.filterConfig;
  }

  isFilterActive(column: DumbTableColumnConfigModel): boolean {
    return !!this.selectedFilters.find((filter: ColumnFilterModel) => filter.field === column.name || filter.field === column?.refersTo)
  }

  isSortable(column: DumbTableColumnConfigModel): boolean {
    return column.sortable;
  }

  isSort(column: DumbTableColumnConfigModel): boolean {
    const colName: string = column.refersTo || column.name;
    return this.sortField === colName;
  }

  isSortAscending(): boolean {
    return this.sortOrder === SortOrder.Ascending;
  }

  isSortDescending(): boolean {
    return this.sortOrder === SortOrder.Descending;
  }

  onSelectAllChanged(event: MatCheckboxChange): void {
    this.selector.onSelectAllChanged({data: this.data, total: this.total, event});
    this.selectionChanged.emit(this.selector.selectionChangedData)
  }

  onSelectRowChange(row: any): void {
    this.selector.onSelectRowChange(row);
    this.selectionChanged.emit(this.selector.selectionChangedData)
  }
}