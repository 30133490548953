<div class="fb-page-container" [ngStyle]="pageStyle" [formGroup]="secondPaymentForm">
  <ng-container *ngFor="let element of fbTemplate.paymentPage.elements">
    <ng-container [ngSwitch]="element.type">
      <div #addressRef *ngSwitchCase="FbElementType.Address"></div>
      <app-address
        [ngStyle]="elementStyle"
        *ngSwitchCase="FbElementType.Address"
        [element]="element"
        [paymentForm]="secondPaymentForm"
        [template]="fbTemplate"
        [stateOptions]="stateOptions"
        [readOnly]="isPreviewMode || paymentProcessStarted"
        [countries]="countries"
        [phonePrefix$]="fbss.phonePrefix$"
        [phoneMask$]="fbss.phoneMask$"
        [phonePlaceholder$]="fbss.phonePlaceholder$"
        [filledInData]="filledInData"
      ></app-address>

      <div #commentRef *ngSwitchCase="FbElementType.Comments"></div>
      <app-comment
        [ngStyle]="elementStyle"
        *ngSwitchCase="FbElementType.Comments"
        [element]="element"
        [paymentForm]="secondPaymentForm"
        [template]="fbTemplate"
        [readOnly]="isPreviewMode || paymentProcessStarted"
      ></app-comment>

      <div #emailRef *ngSwitchCase="FbElementType.Email"></div>
      <app-email
        [ngStyle]="elementStyle"
        *ngSwitchCase="FbElementType.Email"
        [element]="element"
        [paymentForm]="secondPaymentForm"
        [template]="fbTemplate"
        [readOnly]="isPreviewMode || paymentProcessStarted"
      ></app-email>

      <div #nameRef *ngSwitchCase="FbElementType.FullName"></div>
      <app-full-name
        [ngStyle]="elementStyle"
        *ngSwitchCase="FbElementType.FullName"
        [element]="element"
        [paymentForm]="secondPaymentForm"
        [template]="fbTemplate"
        [readOnly]="isPreviewMode || paymentProcessStarted"
      ></app-full-name>

      <app-header
        [ngStyle]="elementStyle"
        *ngSwitchCase="FbElementType.Header"
        [element]="element"
        [paymentForm]="secondPaymentForm"
        [template]="fbTemplate"
        [updateHeader$]="updateHeader$"
      ></app-header>

      <div #creditCardRef *ngSwitchCase="FbElementType.PaymentDetails"></div>
      <app-payment-details
        [ngStyle]="elementStyle"
        *ngSwitchCase="FbElementType.PaymentDetails"
        [element]="element"
        [paymentForm]="secondPaymentForm"
        [template]="fbTemplate"
        [paymentServiceType]="paymentServiceType"
        [achForm]="achForm"
        [cornerstoneService]="cornerstoneService"
        [cardToken]="cardToken"
        (showError)="showError()"
        [event]="event"
        [entity]="entity"
        [ammount]="ammount"
        [includeFee$]="includeFee$"
        [readOnly]="isPreviewMode || paymentProcessStarted"
        [appStripeService]="appStripeService"
        [noDonations]="noDonations"
        [isStripeACHEnabled]="isStripeACHEnabled"
      ></app-payment-details>

      <div #phoneRef *ngSwitchCase="FbElementType.Phone"></div>
      <app-phone
        [ngStyle]="elementStyle"
        *ngSwitchCase="FbElementType.Phone"
        [element]="element"
        [paymentForm]="secondPaymentForm"
        [template]="fbTemplate"
        [phonePrefix$]="fbss.phonePrefix$"
        [phoneMask$]="fbss.phoneMask$"
        [phonePlaceholder$]="fbss.phonePlaceholder$"
        [readOnly]="isPreviewMode || paymentProcessStarted"
      ></app-phone>

      <ng-container *ngIf="client?.clientType === ClientType.PAC">
        <div #pacOrganizationRef *ngSwitchCase="FbElementType.PAC"></div>
        <app-pac
          [ngStyle]="elementStyle"
          *ngSwitchCase="FbElementType.PAC"
          [element]="element"
          [paymentForm]="secondPaymentForm"
          [template]="fbTemplate"
          [readOnly]="isPreviewMode || paymentProcessStarted"
        ></app-pac>
       <ng-container>
         <div #employerName *ngSwitchCase="FbElementType.EmployerName"></div>
         <app-employer-full-name
                 [ngStyle]="elementStyle"
                 *ngSwitchCase="FbElementType.EmployerName"
                 [element]="element"
                 [paymentForm]="employerGroup"
                 [template]="fbTemplate"
                 [readOnly]="isPreviewMode || paymentProcessStarted"
         >
         </app-employer-full-name>
         <div #occupation *ngSwitchCase="FbElementType.Occupation"></div>
         <app-occupation
                 [ngStyle]="elementStyle"
                 *ngSwitchCase="FbElementType.Occupation"
                 [element]="element"
                 [paymentForm]="employerGroup"
                 [template]="fbTemplate"
                 [readOnly]="isPreviewMode || paymentProcessStarted"
         >
         </app-occupation>
         <div #employerAddress *ngSwitchCase="FbElementType.EmployerMailingAddress"></div>
         <app-address
                 [ngStyle]="elementStyle"
                 *ngSwitchCase="FbElementType.EmployerMailingAddress"
                 [element]="element"
                 [paymentForm]="employerGroup"
                 [isAddressRequired]="isEmployerAddressRequired"
                 [template]="fbTemplate"
                 [readOnly]="isPreviewMode || paymentProcessStarted"
                 [stateOptions]="stateOptions"
                 [countries]="countries"
                 [filledInData]="filledInData"
                 [isEmployerAddress]="true"
         >
         </app-address>
       </ng-container>

      </ng-container>

    </ng-container>
  </ng-container>

  <ng-container *ngIf="!isStripeACHSelected">
    <div *ngIf="!showMainPayment; else mainPaymentTemplate" class="payment-total-row">
      <div class="total-column">
        <div class="empty-block"></div>
        <span *ngIf="!noDonations" [ngStyle]="{color: fbTemplate.fontColor}">{{'BUILD.Donation' | translate}}</span>
        <span *ngIf="noDonations" [ngStyle]="{color: fbTemplate.fontColor}">{{mainPaymentTitle | translate}}</span>
        <span [ngStyle]="{color: fbTemplate.fontColor}">{{'BUILD.Fee' | translate}}</span>
  
        <span class="total-column-title" [ngStyle]="{color: fbTemplate.fontColor}">{{'BUILD.Total Cost' | translate}}</span>
  
      </div>
      <div class="total-column">
        <span class="total-column-title" [ngStyle]="{color: fbTemplate.fontColor}">{{'BUILD.Cost' | translate}}</span>
        <span [ngStyle]="{color: fbTemplate.fontColor}">$ {{ammount | number:'.2'}}</span>
        <span [ngStyle]="{color: fbTemplate.fontColor}">$ {{newFeeAndTotalCalculating('donationFee') | number:'1.2-2'}}</span>
        <span class="total-column-title total-result" [ngStyle]="{color: fbTemplate.fontColor}">$ {{newFeeAndTotalCalculating('total') | number:'.2'}}</span>
      </div>
    </div>
  </ng-container>

  <app-payment-summary 
    #paymentSummaryRef
    *ngIf="stripeACHSettings && isStripeACHSelected"
    [fbTemplate]="fbTemplate" 
    [paymentSummarySettings]="stripeACHSettings">
  </app-payment-summary>

  <div
    *ngIf="!isPreviewMode"
    class="captcha-form-row"
    #captchaRef
    [ngStyle]="elementStyle"
  >
    <ngx-recaptcha2
      #captchaElem
      [siteKey]="siteKey"
      [useGlobalDomain]="false"
      size="normal"
      hl="en"
      theme="light"
      type="image"
      formControlName="reCaptchaToken">
    </ngx-recaptcha2>
    <span class="captcha-error" *ngIf="captchaError">{{'Please, check the checkbox' | translate}}</span>
  </div>


  <div class="buttons-container">
    <app-button
      *ngIf="isBackButtonVisible"
      type="flat"
      label="Back"
      [color]="fbTemplate.primaryButtonColor"
      [labelColor]="fbTemplate.primaryTextButtonColor"
      width="200"
      (clickEvent)="goBack()"
      [disabled]="isPreviewMode || paymentProcessStarted"
    ></app-button>
    <div *ngIf="!isBackButtonVisible"></div>

    <app-button
      type="flat"
      [label]="isBackButtonVisible ? fbTemplate.donateButtonLabel : 'Register'"
      [color]="fbTemplate.primaryButtonColor"
      [labelColor]="fbTemplate.primaryTextButtonColor"
      width="220"
      [disabled]="isPreviewMode || paymentProcessStarted || (event && event.status != EventStatus.Live)"
      (clickEvent)="confirmDonation()"
    ></app-button>
  </div>

 
</div>

<ng-template #mainPaymentTemplate>
  <div class="payment-total-row">
    <div class="total-column">
      <div class="empty-block"></div>
      <span>{{mainPaymentTitle | translate}}</span>
      <span class="separator-margin" [ngStyle]="{color: fbTemplate.fontColor}">{{'BUILD.Fee' | translate}}</span>
      <span *ngIf="!recurringPayload && ammount" [ngStyle]="{color: fbTemplate.fontColor}">{{'BUILD.Donation' | translate}}</span>
      <span *ngIf="!recurringPayload && ammount" [ngStyle]="{color: fbTemplate.fontColor}">{{'BUILD.Donation Fee' | translate}}</span>
      <span class="total-column-title" [ngStyle]="{color: fbTemplate.fontColor}">{{'BUILD.Total Cost' | translate}}</span>
    </div>
    <div class="total-column">
      <span class="total-column-title" [ngStyle]="{color: fbTemplate.fontColor}">{{'BUILD.Cost' | translate}}</span>
      <span [ngStyle]="{color: fbTemplate.fontColor}">$ {{mainPayment | number:'1.2-2'}}</span>
      <span class="separator-margin" [ngStyle]="{color: fbTemplate.fontColor}">$  {{newFeeAndTotalCalculating('mainFee') | number:'1.2-2'}}</span>
      <span *ngIf="!recurringPayload && ammount" [ngStyle]="{color: fbTemplate.fontColor}">$ {{ammount | number:'1.2-2'}}</span>
      <span *ngIf="!recurringPayload && ammount" [ngStyle]="{color: fbTemplate.fontColor}">$ {{newFeeAndTotalCalculating('donationFee') | number:'1.2-2'}}</span>
      <span *ngIf="!recurringPayload" class="total-column-title total-result">$ {{newFeeAndTotalCalculating('total') | number:'1.2-2'}}</span>
      <span *ngIf="recurringPayload" class="total-column-title total-result">$ {{getMainPayment() | number:'1.2-2'}}</span>
    </div>
  </div>
</ng-template>


