<div class="search-input-wrapper">
  <input
    type="search"
    [placeholder]="placeholder | translate"
    (input)="onSearch($event)"
  >
  <app-icons
    [iconType]="IconsType.Search"
    color="#0064BE"
  ></app-icons>
</div>
