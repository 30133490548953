import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ImageBuilderStateService } from '../../image-builder.state.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subscription } from 'rxjs';
import { LayerModel } from '../../../../models/image-builder/layer.model';
import { ImageBuilderUtilsComponent } from '../../image-builder.utils.component';

@Component({
  selector: 'app-styling',
  templateUrl: './styling.component.html',
  styleUrls: ['./styling.component.scss']
})
export class StylingComponent extends ImageBuilderUtilsComponent implements OnInit, OnDestroy {
  @Input() public ibss: ImageBuilderStateService;
  private subscription: Subscription = new Subscription();

  @ViewChild('layerOptionsExpansionPanelRef') private layerOptionsExpansionPanelRef: MatExpansionPanel;
  @ViewChild('generalOptionsExpansionPanelRef') private generalOptionsExpansionPanelRef: MatExpansionPanel;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.ibss.activeLayerObservable.subscribe((activeLayer: LayerModel) => {
        if (!activeLayer) {
          return;
        }
        this.layerOptionsExpansionPanelRef.open();
        this.generalOptionsExpansionPanelRef.close();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get textActiveLayer(): boolean {
    if (!this.ibss.activeLayer) return false;
    return this.isTextLayer(this.ibss.activeLayer.type);
  }

  public get imageActiveLayer(): boolean {
    if (!this.ibss.activeLayer) return false;
    return this.isImageLayer(this.ibss.activeLayer.type);
  }

  public get shapeLayer(): boolean {
    if (!this.ibss.activeLayer) return false;
    return this.isShapeLayer(this.ibss.activeLayer.type);
  }

}
