<div class="video-screenshots-wrapper">
  <div class="video-screenshots-close">
    <i class="fas fa-times close-icon" (click)="onClose()" matRipple></i>
  </div>

  <span class="video-screenshots-title">{{'Video screenshots' | translate}}</span>

  <div class="video-screenshots-body">
    <div class="video-container">
      <video
        preload="auto"
        autoplay
        loop
        controls
        [muted]="true"
        [src]="data.link"
        #videoElementRef
        crossorigin="anonymous"
        style="max-width: 600px; height: auto"
      ></video>
    </div>

    <div
      *ngIf="videoReady$ | async"
      class="video-screenshots-button blue"
      matRipple
      (click)="takeScreenshot()"
    >
      <span>{{'Take screenshot' | translate}}</span>
      <i class="fas fa-camera-retro"></i>
    </div>

   <!-- <canvas #canvasRef></canvas>-->

    <image-cropper
      *ngIf="imageBase64"
      (imageCropped)="imageCropped($event)"
      [imageBase64]="imageBase64"
      [aspectRatio]="16 / 9"
      [resizeToWidth]="300"
      [resizeToHeight]="169"
    ></image-cropper>

    <div
      *ngIf="screenshotReady$ | async"
      class="video-screenshots-button green"
      matRipple
      (click)="useScreenshot()"
    >
      <span>{{'Use Image as video poster' | translate}}</span>
      <i class="far fa-thumbs-up"></i>
    </div>

  </div>

  <div class="video-screenshots-actions">
    <app-button
      type="stroked"
      label="Close"
      color="#a9110e"
      (clickEvent)="onClose()"
    ></app-button>
  </div>
</div>
