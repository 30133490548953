import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TemplateAssignmentType } from '../../models/templates/template.assignment.type';
import { TemplateManagementService } from '../../services/templates/template.management.service';
import MjmlElementModel from '../../models/templates/mjml.element.model';

@Component({
  selector: 'app-mjml-renderer',
  templateUrl: './mjml-renderer.component.html',
  styleUrls: ['./mjml-renderer.component.scss'],
  providers: [TemplateManagementService]
})
export class MjmlRendererComponent implements AfterViewInit {
  @Input() public eventId: string;
  @Input() public fundraiserId: string;
  @Input() public mjml: MjmlElementModel;
  @Input() public screenshots: boolean = false;
  @Input() public showHeaderAndFooter: boolean = false;
  @ViewChild('mjmlRendererContainer') private containerRef: ElementRef;
  public isMobileScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public TemplateAssignmentType = TemplateAssignmentType;

  @HostListener('window:resize') public onWindowResize(): void {
    this.updateStyles();
  }

  constructor(
    public tms: TemplateManagementService
  ) { }

  public ngAfterViewInit(): void {
    this.updateStyles();
  }

  public updateStyles(): void {
    if (this.containerRef) {
      setTimeout(() => this.isMobileScreen.next(this.containerRef.nativeElement.getBoundingClientRect().width <= 490));
    }
  }

  public get sections(): MjmlElementModel[] {
    return this.wrapper.children;
  }

  private get wrapper(): MjmlElementModel {
    return this.body.children[0];
  }

  private get body(): MjmlElementModel {
    return this.mjml.children[1];
  }

  public get fullContainerStyle(): {[key: string]: string} {
    const backgroundRepeat = this.wrapper.attributes['background-repeat'] || 'repeat';
    const backgroundUrl = this.wrapper.attributes['background-url'];
    const backgroundColor = this.body.attributes['background-color'] || 'transparent';
    const backgroundSize = this.wrapper.attributes['background-size'] || 'auto';
    return {
      'background-repeat': backgroundRepeat,
      'background': backgroundUrl ? `${backgroundRepeat} url(${backgroundUrl}) top center / ${backgroundSize}, ${backgroundColor}` : 'none',
      'background-color': backgroundColor,
    };
  }

  public get templateContainerStyle(): {[key: string]: string} {
    const width = this.body.attributes['width'] || '900px';
    return {
      'max-width': width
    }
  }

  public getRowStyles(rowElement: MjmlElementModel): {[key: string]: string} {
    const backgroundColor = rowElement.attributes['background-color'] || 'transparent';
    const backgroundRepeat = rowElement.attributes['background-repeat'] || 'repeat';
    const backgroundUrl = rowElement.attributes['background-url'] || '';
    const backgroundSize = rowElement.attributes['background-size'] || 'auto';
    const paddingBottom = rowElement.attributes['padding-bottom'] || '0px';
    const paddingLeft = rowElement.attributes['padding-left'] || '0px';
    const paddingRight = rowElement.attributes['padding-right'] || '0px';
    const paddingTop = rowElement.attributes['padding-top'] || '0px';
    return {
      'background': backgroundUrl ? `${backgroundRepeat} url(${backgroundUrl}) top center / ${backgroundSize}, ${backgroundColor}` : 'none',
      'background-color': backgroundColor,
      'padding-bottom': paddingBottom,
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      'padding-top': paddingTop
    };
  }

  public getColumnStyles(columnElement: MjmlElementModel): {[key: string]: string} {
    const backgroundColor = columnElement.attributes['background-color'];
    const paddingBottom = columnElement.attributes['padding-bottom'];
    const paddingLeft = columnElement.attributes['padding-left'];
    const paddingRight = columnElement.attributes['padding-right'];
    const paddingTop = columnElement.attributes['padding-top'];
    const width = columnElement.attributes['width'];
    return {
      'background-color': backgroundColor ? backgroundColor : 'transparent',
      'padding-bottom': paddingBottom ? paddingBottom : '0px',
      'padding-left': paddingLeft ? paddingLeft : '0px',
      'padding-right': paddingRight ? paddingRight : '0px',
      'padding-top': paddingTop ? paddingTop : '0px',
      'width': width ? width : this.tms.columnWidth
    };
  }
}