import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import FormElementDataModel from '../../../../models/form.element.data.model';
import {
  FONTS,
  FONT_SIZES,
  FONT_STYLE,
  FONT_WEIGHT,
  TEXT_ALIGN,
  TEXT_DECORATION,
  TOOLTIP_TEXT_FOR_FONTS
} from '../../../../constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { MjmlCustomType } from '../../../../models/templates/mjml.custom.type';
import { TemplateType } from '../../../../models/templates/template.type';
import { TemplateAssignmentType } from '../../../../models/templates/template.assignment.type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-signature-tool-settings',
  templateUrl: './signature-tool-settings.component.html',
  styleUrls: ['./signature-tool-settings.component.scss', '../tool-settings.scss']
})
export class SignatureToolSettingsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() public tms: TemplateManagementService;

  public imageURL: string = null;

  public alignOptions: FormElementDataModel[] = TEXT_ALIGN;
  public fontSizeOptions: FormElementDataModel[] = FONT_SIZES;
  public fontFamilyOptions: FormElementDataModel[] = FONTS;
  public fontStyleOptions: FormElementDataModel[] = FONT_STYLE;
  public fontWeightOptions: FormElementDataModel[] = FONT_WEIGHT;
  public textDecorationOptions: FormElementDataModel[] = TEXT_DECORATION;
  public TemplateAssignmentType = TemplateAssignmentType;
  public toolTipText: string = this.translate.instant(TOOLTIP_TEXT_FOR_FONTS);

  public signatureForm: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required, Validators.maxLength(250)]],
    officePhone: '',
    cellPhone: '',
    email: ['', [Validators.email]],
    companyUrl: '',
    customText: '',
    imageSrc: '',

    color: '#000000',
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: 'none',
    align: 'left',
    containerBackgroundColor: '',
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]]
  });

  constructor(
    private formBuilder: FormBuilder,
    public translate: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.setInitialValues();

    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (!element || element.tagName !== MjmlTag.text || element.attributes['customType'] !== MjmlCustomType.signature) {
          return;
        }
        this.setInitialValues();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public setContent(): void {
    const name = this.signatureForm.get('name').value || '';
    const officePhone = this.signatureForm.get('officePhone').value || '';
    const cellPhone = this.signatureForm.get('cellPhone').value || '';
    const email = this.signatureForm.get('email').value || '';
    const companyUrl = this.signatureForm.get('companyUrl').value || '';
    const customText = this.signatureForm.get('customText').value || '';
    const imageSrc = this.signatureForm.get('imageSrc').value || '';
    this.tms.activeElement.attributes['signature-values'] = JSON.stringify({
      name: name || '',
      officePhone: officePhone || '',
      cellPhone: cellPhone || '',
      email: email || '',
      companyUrl: companyUrl || '',
      customText: customText || '',
      imageSrc: imageSrc || ''
    });
    this.tms.activeElement.content = `
      ${customText ? '<p>' + customText + '</p>' : ''}
      ${officePhone ? '<p>Office Phone Number: <a href="tel:+1' + officePhone + '">' + '+1' + officePhone + '</a></p>' : ''}
      ${cellPhone ? '<p>Cell Phone Number: <a href="tel:+1' + cellPhone + '">' + '+1' + cellPhone + '</a></p>' : ''}
      ${email ? '<p><a href="mailto:' + email + '">' + email + '</a></p>' : ''}
      ${companyUrl ? '<p><a href="' + companyUrl + '">' + companyUrl + '</a></p>' : ''}
      <p style="vertical-align: bottom">${name ? '<span style="vertical-align: bottom">' + name + ' </span>' : ''}${imageSrc ? '<img src="' + imageSrc + '" alt="Signature" style="width: 165px; height: auto;">' : ''}</p>
    `;
    this.tms.updateSignature();
    this.tms.emitSave(true);
  }

  private setInitialValues(): void {
    const attributes = this.tms.activeElement.attributes;
    const signatureValues = attributes['signature-values'] ? JSON.parse(attributes['signature-values']) : null;
    if (signatureValues) {
      this.signatureForm.get('name').setValue(signatureValues.name || '');
      this.signatureForm.get('officePhone').setValue(signatureValues.officePhone || '');
      this.signatureForm.get('cellPhone').setValue(signatureValues.cellPhone || '');
      this.signatureForm.get('email').setValue(signatureValues.email || '');
      this.signatureForm.get('companyUrl').setValue(signatureValues.companyUrl || '');
      this.signatureForm.get('customText').setValue(signatureValues.customText || '');
      this.signatureForm.get('imageSrc').setValue(signatureValues.imageSrc || '');
    }
    const color = attributes['color'] || '#000000';
    const fontFamily = attributes['font-family'] || 'Roboto';
    const fontSize = attributes['font-size'] || '13px';
    const fontStyle = attributes['font-style'] || 'normal';
    const fontWeight = attributes['font-weight'] || 'normal';
    const textDecoration = attributes['text-decoration'] || 'none';
    const align = attributes['align'] || 'left';
    const containerBackgroundColor = attributes['container-background-color'] || '';
    const paddingBottom = attributes['padding-bottom'] || '10';
    const paddingLeft = attributes['padding-left'] || '25';
    const paddingRight = attributes['padding-right'] || '25';
    const paddingTop = attributes['padding-top'] || '10';

    this.signatureForm.get('color').setValue(color);
    this.signatureForm.get('fontFamily').setValue(fontFamily);
    this.signatureForm.get('fontSize').setValue(fontSize);
    this.signatureForm.get('fontStyle').setValue(fontStyle);
    this.signatureForm.get('fontWeight').setValue(fontWeight);
    this.signatureForm.get('textDecoration').setValue(textDecoration);
    this.signatureForm.get('align').setValue(align);
    this.signatureForm.get('containerBackgroundColor').setValue(containerBackgroundColor);
    this.signatureForm.get('paddingBottom').setValue(paddingBottom);
    this.signatureForm.get('paddingLeft').setValue(paddingLeft);
    this.signatureForm.get('paddingRight').setValue(paddingRight);
    this.signatureForm.get('paddingTop').setValue(paddingTop);
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public imageUrlChanged(url: string): void {
    this.imageURL = null;
    this.setContent();
  }

  public editImage(): void {
    this.imageURL = this.signatureForm.get('imageSrc').value;
  }

  public cancelEditImage(): void {
    this.imageURL = null;
  }

  public onColorChanged(): void {
    const value = this.signatureForm.get('color').value;
    this.tms.activeElement.attributes['color'] = value ? value : '#000000';
    this.tms.emitSave(true);
  }

  public onFontFamilyChanged(): void {
    const value = this.signatureForm.get('fontFamily').value;
    this.tms.activeElement.attributes['font-family'] = value ? value : 'Roboto';
    this.tms.emitSave(true);
  }

  public onFontSizeChanged(): void {
    const value = this.signatureForm.get('fontSize').value;
    this.tms.activeElement.attributes['font-size'] = value ? value : '13px';
    this.tms.emitSave(true);
  }

  public onFontStyleChanged(): void {
    const value = this.signatureForm.get('fontStyle').value;
    this.tms.activeElement.attributes['font-style'] = value ? value : 'normal';
    this.tms.emitSave(true);
  }

  public onFontWeightChanged(): void {
    const value = this.signatureForm.get('fontWeight').value;
    this.tms.activeElement.attributes['font-weight'] = value ? value : 'normal';
    this.tms.emitSave(true);
  }

  public onTextDecorationChanged(): void {
    const value = this.signatureForm.get('textDecoration').value;
    this.tms.activeElement.attributes['text-decoration'] = value ? value : 'none';
    this.tms.emitSave(true);
  }

  public onContainerBackgroundColorChanged(): void {
    const value = this.signatureForm.get('containerBackgroundColor').value;
    this.tms.activeElement.attributes['container-background-color'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onAlignChanged(): void {
    const value = this.signatureForm.get('align').value;
    this.tms.activeElement.attributes['align'] = value ? value : 'left';
    this.tms.emitSave(true);
  }

  public onPaddingTopChanged(): void {
    const value = this.signatureForm.get('paddingTop').value;
    this.tms.activeElement.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.signatureForm.get('paddingRight').value;
    this.tms.activeElement.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.signatureForm.get('paddingBottom').value;
    this.tms.activeElement.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.signatureForm.get('paddingLeft').value;
    this.tms.activeElement.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

}
