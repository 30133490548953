<div class="auxilia-sign-up-as-a-donor-today-wrapper" [ngClass]="{
  'horizontal-layout': settings.layoutStyle === 'horizontal',
  'horizontal-layout-mail-fields': settings.layoutStyle === 'horizontal' && settings.showMailingAddress
}">
  <form [formGroup]="donorForm" class="auxilia-sign-up-as-a-donor-today-form">
    <div class="auxilia-sign-up-as-a-donor-today-row header"
    [ngClass]="{
      'logo-left': settings.logoPosition === 'left',
      'logo-center': settings.logoPosition === 'center',
      'logo-right': settings.logoPosition === 'right',
      'no-logo': settings.showLogo === false || !settings.logo
      }">
      <img 
        [src]="settings.logo" 
        [ngStyle]="{'width': settings.logoSize, 'height': settings.logoSize }"
        *ngIf="settings.showLogo && settings.logo"
        alt="Auxilia">
      <span class="auxilia-sign-up-as-a-donor-today-title" [innerHTML]="customText"></span>
    </div>
    <div class="sign-up-fields" [ngClass]="{'horizontal-layout': settings.layoutStyle === 'horizontal'}" >
    <div [ngClass]="{
      'vertical-one': settings.layoutStyle === 'vertical' && settings.layoutColumns === '1',
      'vertical-two': settings.layoutStyle === 'vertical' && settings.layoutColumns === '2'
    }" class="auxilia-sign-up-as-a-donor-today-row">
      <div class="auxilia-sign-up-as-a-donor-today-row-item">
        <app-input
          [label]="settings.firstNameLabel"
          [formGroup]="donorForm"
          name="firstName"
          errorMassage="First Name"
          [labelFontSize]="settings.labelFontSize"
          [isRequired]="true"
          [inputFontSize]="settings.inputFontSize"
          [labelFontFamily]="settings.labelFontFamily"
          [inputFontFamily]="settings.inputFontFamily"
          [labelFontColor]="settings.labelFontColor"
          [inputFontColor]="settings.inputFontColor"
        ></app-input>
      </div>
    </div>
    <div [ngClass]="{
      'vertical-one': settings.layoutStyle === 'vertical' && settings.layoutColumns === '1',
      'vertical-two': settings.layoutStyle === 'vertical' && settings.layoutColumns === '2'
    }" class="auxilia-sign-up-as-a-donor-today-row">
      <div class="auxilia-sign-up-as-a-donor-today-row-item">
        <app-input
          [label]="settings.lastNameLabel"
          [formGroup]="donorForm"
          name="lastName"
          [isRequired]="true"
          errorMassage="Last Name"
          [labelFontSize]="settings.labelFontSize"
          [inputFontSize]="settings.inputFontSize"
          [labelFontFamily]="settings.labelFontFamily"
          [inputFontFamily]="settings.inputFontFamily"
          [labelFontColor]="settings.labelFontColor"
          [inputFontColor]="settings.inputFontColor"
        ></app-input>
      </div>
    </div>
    <div [ngClass]="{
      'vertical-one': settings.layoutStyle === 'vertical' && settings.layoutColumns === '1',
      'vertical-two': settings.layoutStyle === 'vertical' && settings.layoutColumns === '2',
      'horizontal': settings.layoutStyle === 'horizontal'
    }" class="auxilia-sign-up-as-a-donor-today-row">
      <div class="auxilia-sign-up-as-a-donor-today-row-item">
        <app-input
          [label]="settings.emailLabel"
          [formGroup]="donorForm"
          name="email"
          errorMassage="Email"
          [isRequired]="true"
          [labelFontSize]="settings.labelFontSize"
          [inputFontSize]="settings.inputFontSize"
          [labelFontFamily]="settings.labelFontFamily"
          [inputFontFamily]="settings.inputFontFamily"
          [labelFontColor]="settings.labelFontColor"
          [inputFontColor]="settings.inputFontColor"
        ></app-input>
      </div>
    </div>
    <div [ngClass]="{
      'vertical-one': settings.layoutStyle === 'vertical' && settings.layoutColumns === '1',
      'vertical-two': settings.layoutStyle === 'vertical' && settings.layoutColumns === '2',
      'horizontal': settings.layoutStyle === 'horizontal'
    }" 
    *ngIf="settings.showPhoneNumber"
    class="auxilia-sign-up-as-a-donor-today-row">
      <div class="auxilia-sign-up-as-a-donor-today-row-item">
        <app-input
          [label]="settings.phoneLabel"
          [formGroup]="donorForm"
          name="phone"
          errorMassage="Phone"
          type="tel"
          prefix="+1 "
          mask="(000) 000-0000"
          [isRequired]="settings.isPhoneFieldRequired"
          [labelFontSize]="settings.labelFontSize"
          [inputFontSize]="settings.inputFontSize"
          [labelFontFamily]="settings.labelFontFamily"
          [inputFontFamily]="settings.inputFontFamily"
          [labelFontColor]="settings.labelFontColor"
          [inputFontColor]="settings.inputFontColor"
        ></app-input>
      </div>
    </div>
    <div [ngClass]="{
      'vertical-one': settings.layoutStyle === 'vertical' && settings.layoutColumns === '1',
      'vertical-two': settings.layoutStyle === 'vertical' && settings.layoutColumns === '2',
      'horizontal': settings.layoutStyle === 'horizontal'
    }" class="auxilia-sign-up-as-a-donor-today-row" *ngIf="settings.showMailingAddress">
      <div class="auxilia-sign-up-as-a-donor-today-row-item row-item-margin-right">
        <app-input
          [label]="settings.mailingCountryLabel"
          [formGroup]="donorForm"
          name="mailingCountry"
          [isRequired]="settings.areMailingAddressFieldsRequired"
          [labelFontSize]="settings.labelFontSize"
          [inputFontSize]="settings.inputFontSize"
          [labelFontFamily]="settings.labelFontFamily"
          [inputFontFamily]="settings.inputFontFamily"
          [labelFontColor]="settings.labelFontColor"
          [inputFontColor]="settings.inputFontColor"
        ></app-input>
      </div>
    </div>
    <div [ngClass]="{
      'vertical-one': settings.layoutStyle === 'vertical' && settings.layoutColumns === '1',
      'vertical-two': settings.layoutStyle === 'vertical' && settings.layoutColumns === '2',
      'horizontal': settings.layoutStyle === 'horizontal'
    }" class="auxilia-sign-up-as-a-donor-today-row" *ngIf="settings.showMailingAddress">
      <div class="auxilia-sign-up-as-a-donor-today-row-item">
        <app-input
          [label]="settings.mailingAddressLabel"
          [formGroup]="donorForm"
          name="mailingAddress"
          [isRequired]="settings.areMailingAddressFieldsRequired"
          [labelFontSize]="settings.labelFontSize"
          [inputFontSize]="settings.inputFontSize"
          [labelFontFamily]="settings.labelFontFamily"
          [inputFontFamily]="settings.inputFontFamily"
          [labelFontColor]="settings.labelFontColor"
          [inputFontColor]="settings.inputFontColor"
        ></app-input>
      </div>
    </div>
    <div [ngClass]="{
      'vertical-one': settings.layoutStyle === 'vertical' && settings.layoutColumns === '1',
      'vertical-two': settings.layoutStyle === 'vertical' && settings.layoutColumns === '2',
      'horizontal': settings.layoutStyle === 'horizontal'
    }" class="auxilia-sign-up-as-a-donor-today-row" *ngIf="settings.showMailingAddress">
      <div class="auxilia-sign-up-as-a-donor-today-row-item row-item-margin-right">
        <app-input
          [label]="settings.mailingCityLabel"
          [formGroup]="donorForm"
          name="mailingCity"
          [isRequired]="settings.areMailingAddressFieldsRequired"
          [labelFontSize]="settings.labelFontSize"
          [inputFontSize]="settings.inputFontSize"
          [labelFontFamily]="settings.labelFontFamily"
          [inputFontFamily]="settings.inputFontFamily"
          [labelFontColor]="settings.labelFontColor"
          [inputFontColor]="settings.inputFontColor"
        ></app-input>
      </div>
    </div>
    <div [ngClass]="{
      'vertical-one': settings.layoutStyle === 'vertical' && settings.layoutColumns === '1',
      'vertical-two': settings.layoutStyle === 'vertical' && settings.layoutColumns === '2',
      'horizontal': settings.layoutStyle === 'horizontal'
    }" class="auxilia-sign-up-as-a-donor-today-row" *ngIf="settings.showMailingAddress">
      <div class="auxilia-sign-up-as-a-donor-today-row-item">
        <app-input
          [label]="settings.mailingStateLabel"
          [formGroup]="donorForm"
          name="mailingState"
          [isRequired]="settings.areMailingAddressFieldsRequired"
          [labelFontSize]="settings.labelFontSize"
          [inputFontSize]="settings.inputFontSize"
          [labelFontFamily]="settings.labelFontFamily"
          [inputFontFamily]="settings.inputFontFamily"
          [labelFontColor]="settings.labelFontColor"
          [inputFontColor]="settings.inputFontColor"
        ></app-input>
      </div>
    </div>
    <div [ngClass]="{
      'vertical-one': settings.layoutStyle === 'vertical' && settings.layoutColumns === '1',
      'vertical-two': settings.layoutStyle === 'vertical' && settings.layoutColumns === '2',
      'horizontal': settings.layoutStyle === 'horizontal'
    }" class="auxilia-sign-up-as-a-donor-today-row" *ngIf="settings.showMailingAddress">
      <div class="auxilia-sign-up-as-a-donor-today-row-item row-item-margin-right">
        <app-input
          [label]="settings.mailingZipCodeLabel"
          [formGroup]="donorForm"
          name="mailingZipCode"
          [isRequired]="settings.areMailingAddressFieldsRequired"
          [labelFontSize]="settings.labelFontSize"
          [inputFontSize]="settings.inputFontSize"
          [labelFontFamily]="settings.labelFontFamily"
          [inputFontFamily]="settings.inputFontFamily"
          [labelFontColor]="settings.labelFontColor"
          [inputFontColor]="settings.inputFontColor"
        ></app-input>
      </div>
    </div>
    <div 
    *ngIf="settings.showTags"
    [ngClass]="{
      'vertical-one': settings.layoutStyle === 'vertical' && settings.layoutColumns === '1',
      'vertical-two': settings.layoutStyle === 'vertical' && settings.layoutColumns === '2',
      'horizontal': settings.layoutStyle === 'horizontal'
    }" class="auxilia-sign-up-as-a-donor-today-row is-tag">
      <div class="auxilia-sign-up-as-a-donor-today-row-item">
        <app-select
              [label]="settings.tagLabel"
              [formGroup]="donorForm"
              name="tag"
              [ngStyle]="{'background-color': 'red'}"
              [multiSelectOptions]="settings.customOptionsList"
              [labelFontSize]="settings.labelFontSize"
              [inputFontSize]="settings.inputFontSize"
              [labelFontFamily]="settings.labelFontFamily"
              [inputFontFamily]="settings.inputFontFamily"
              [labelFontColor]="settings.labelFontColor"
              [inputFontColor]="settings.inputFontColor"
            ></app-select>
      </div>
    </div>
  </div>
    <div class="auxilia-sign-up-as-a-donor-today-row">
      <div class="auxilia-sign-up-as-a-donor-today-row-item row-item-margin-right btn-submit-container">
        <app-button
          [label]="settings.buttonText"
          width="200"
          type="flat"
          class="btn-submit"
          [color]="settings.buttonColor"
          [labelColor]="settings.buttonTextColor"
          [fontFamily]="settings.buttonFontFamily"
          [fontSize]="settings.buttonFontSize"
        ></app-button>
      </div>

      <div *ngIf="successMessage" class="auxilia-sign-up-as-a-donor-today-row-item success-message">
        <i class="fas fa-check"></i>
        <span>{{'Success' | translate}}!</span>
      </div>
    </div>
  </form>
</div>
