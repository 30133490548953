import { BaseModelService } from '../base.model.service';
import { CampaignLayoutModel } from 'src/app/models/campaigns/campaign-layout.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CampaignLayoutService extends BaseModelService<CampaignLayoutModel> {
  protected get controllerName(): string {
    return 'CampaignLayout';
  }
}
