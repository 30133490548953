import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ClientService } from './client/client.service';
import { ClientRelationshipModel } from '../models/client/client.relationship.model';
import FormElementDataModel from '../models/form.element.data.model';

@Injectable({
  providedIn: 'root',
})
export class ClientChildRelationshipService implements OnDestroy {
  private subscription: Subscription = new Subscription();
  private parentNoMasquerade: ClientRelationshipModel[];
  private childrenForNotifications: ClientRelationshipModel[];

  constructor(
    private authService: AuthService,
    private clientService: ClientService,
  ) {
    this.authService.isLoggedIn.subscribe(res => {
      if (res) {
        this.subscription.add(
          //called getModel() in constructor for smaller delay in checkIfFromChild()
          this.clientService
            .getModel(this.authService.getIdentityClaimsOriginId(), true)
            .subscribe((res) => {
              this.childrenForNotifications = res.clientRelationship;
              this.parentNoMasquerade =
                this.authService.masqueradeAs === ''
                  ? res.clientRelationship
                  : null;
            }),
        );
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Checks if the clientID of the donor matches and child IDs of parent or is same as parent ID
   * @param currentlySelectedClientId currently selected client/org
   * @returns boolean: true if it is a donor from child or parent
   */
  public checkIfFromChild(currentlySelectedClientId: string): boolean {
    if (this.authService.masqueradeAs !== '') {
      return (
        this.authService.masqueradeAsRecord.clientID === currentlySelectedClientId ||
        this.authService.masqueradeAsRecord.client.clientRelationship.some((x) => x?.childID === currentlySelectedClientId) ||
        this.getParentOrganizationsSharingDataWithIds()?.some(id => id === currentlySelectedClientId)
      );
    } else {
      return (
        this.authService.getIdentityClaimsOriginId() === currentlySelectedClientId ||
        this.parentNoMasquerade?.some((x) => x?.childID === currentlySelectedClientId) ||
        this.getParentOrganizationsSharingDataWithIds()?.some(id => id === currentlySelectedClientId)
      );
    }
  }

  public getAllChildrenForNotifications() {
    return this.childrenForNotifications;
  }

  public getParentOrganizationsSharingDataWithIds(): string[] {
    const clientsWithRelationshipsToCurrentClient: FormElementDataModel[] = JSON.parse(localStorage.getItem('clientsWithRelationshipsToCurrentClient')) as FormElementDataModel[];
    return clientsWithRelationshipsToCurrentClient?.filter(client => client.isParent).map(client => client.value);
  }
}
