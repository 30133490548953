<div *ngIf="error$ | async" class="smth-went-wrong">{{'Something Went Wrong' | translate}}</div>
<div
  *ngIf="fbTemplate$ | async as template"
  class="payment-content"
  [ngStyle]="mainContainerStyle(template)"
>

  <div
    *ngIf="!isEmbedPage"
    class="payment-wrapper-header"
    [ngStyle]="headerStyle(template)"
  >
    <a [href]="logoTarget(template)" target="_blank">
      <img [src]="logoSrc(template)" [ngStyle]="logoStyle(template)">
    </a>

  </div>

  <!-- Image Template -->
    <div
      *ngIf="parseTemplate(template)[0].url"
      class="payment-content-top-image"
    >
      <div class="top-images"
           [ngStyle]="{
           maxWidth: getSettings(template,'width'),
           paddingBottom: getSettings(template,'bottom'),
           paddingLeft: getSettings(template,'left'),
           paddingRight: getSettings(template,'right'),
           paddingTop: getSettings(template,'top')
           }"
      >
        <ng-container *ngFor="let image of bgcArray; index as i">
          <div class="image" [ngStyle]="{background: image}">
          </div>
        </ng-container>
      </div>
    </div>


  <app-payment-form
    [ngStyle]="paymentFormStyle(template)"
    *ngIf="!isDonationComplete"
    [campaign]="campaign"
    [socialPost]="socialPost"
    [states]="lookupStoreService.usaStates$ | async"
    [event]="event"
    [clientDonor]="clientDonor"
    [client]="client"
    [countries]="lookupStoreService.countries$ | async"
    [countriesOptions]="lookupStoreService.countriesOptions$ | async"
    [genericTemplateId]="genericTemplateId"
    [genericSourceTemplateId]="genericSourceTemplateId"
    [isBackButtonVisible]="true"
    [fbTemplate]="template"
    [sourceTemplateModelId]="sourceTemplateModelId"
    [templateId]="templateId"
    [includeFee$]="includeFee$"
    (paymentFinished)="onPaymentFinished($event, template)"
  ></app-payment-form>

  <div class="payment-content-success" *ngIf="isDonationComplete">
    <ng-container *ngIf="(thankYouPageHtml$ | async) as html">
      <div [innerHTML]="html | translate | safeHTML"></div>
    </ng-container>
    <!-- <img src="/assets/icons/check1.svg" style="margin-right: 15px;"> Thank you for your Donation<br>-->
  </div>

  <!-- Footer -->
  <div
    *ngIf="!isEmbedPage"
    class="payment-wrapper-header footer"
    [ngStyle]="footerStyle(template)"
  >
    <div class="item">
      <img src="/assets/images/logo1.png" style="margin-right:10px;">
      <a style="color:white;" href="https://www.theauxilia.com">Powered by Auxilia</a>
      <a style="color:#93ccff;margin-left:15px;" href="https://www.theauxilia.com/privacy-policy?__hstc=181257784.ff7e06784cd8dfcd424620280a8af09b.1581499076821.1618415342297.1618475974870.854&__hssc=181257784.17.1618475974870&__hsfp=1488147824">Privacy Policy</a>
    </div>
    <div class="item">
      <div class="sm-link" *ngIf="(template.headerFooter.attributes.facebookLink) as facebookLink">
        <a [href]="facebookLink">
          <i class="fab fa-facebook"></i>
        </a>
      </div>

      <div class="sm-link" *ngIf="(template.headerFooter.attributes.twitterLink) as twitterLink">
        <a [href]="twitterLink">
          <i class="fa6 fab fa-square-x-twitter"></i>
        </a>
      </div>

      <div class="sm-link" *ngIf="(template.headerFooter.attributes.instagramLink) as instagramLink">
        <a [href]="instagramLink">
          <i class="fab fa-instagram"></i>
        </a>
      </div>

      <div class="sm-link" *ngIf="(template.headerFooter.attributes.linkedInLink) as linkedInLink">
        <a [href]="linkedInLink">
          <i class="fab fa-linkedin"></i>
        </a>
      </div>
    </div>
  </div>
</div>
<a *ngIf="addMicroDepositLinkToDom" [href]="microdepositsLink" class="display-none-important" id="microdeposit-link"></a>
