<div class="email-color-picker-wrapper" style="position: relative">
  <div class="email-color-picker-label">{{label | translate}}</div>
  <div
    [colorPicker]="color"
    [style.background]="color"
    (colorPickerChange)="colorChange($event)"
    [cpPosition]="position"
    [cpPresetColors]="presetColors"
    [cpDisabled]="readOnly"
    [ngClass]="getPikerSize"
  ></div>
</div>
