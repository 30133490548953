<div class="fb-setting-tab-wrapper signup-settings-wrapper" [ngClass]="{
  'is-loading': loadingData
}">
  <div class="save-btn">
    <button class="bg-white" width="200"
    type="flat"(click)="onSave()" [disabled]="loadingData" mat-stroked-button>
      {{ 'Save' | translate | uppercase }}
    </button>
  </div>
  <mat-tab-group>
    <mat-tab label="{{'BUILD.Form Settings' | translate}}">
      <app-signup-form-settings [config]="config" 
        [loadingData]="loadingData" 
        (formChange)="updateFormData($event.formData, $event.isValid)"
        ></app-signup-form-settings>
    </mat-tab>
    <mat-tab label="{{'BUILD.Confirmation Email' | translate}}">
      <app-signup-email-settings 
        [config]="config" 
        [loadingData]="loadingData" 
        *ngIf="loadingData === false"
        [defaultEmail]="defaultEmail"
        (formChange)="updateFormData($event.formData, $event.isValid)"
        ></app-signup-email-settings>
    </mat-tab>
    <mat-tab label="{{'BUILD.Confirmation Screen' | translate}}">
      <app-signup-confirmation-page-settings 
        [config]="config" 
        [loadingData]="loadingData" 
        (formChange)="updateFormData($event.formData, $event.isValid)"
        ></app-signup-confirmation-page-settings>
    </mat-tab>
</mat-tab-group>
</div>
