<div class="main-label-wrapper" [ngStyle]="mainLabelStyle">
  <b>{{element.attributes['mainLabel'] | translate}}</b>
</div>

<div
  class="form-row-wrapper"
  *ngIf="showPaymentMethodRadio"
  [ngStyle]="singleFormElementStyle"
>
  <app-fb-radio
    [borderColor]="template?.borderCheckboxColor"
    [ngStyle]="containerStyle"
    [formGroup]="paymentForm"
    name="paymentType"
    [selectOptions]="paymentMethodOptions"
    [color]="template?.secondaryButtonColor"
    [disabled]="readOnly"
    (valueChanged)="onPaymentMethodChange($event)"
  ><b>{{'Payment Method' | translate}}</b></app-fb-radio>
</div>

<!-- CardConnect Credit Card Template -->
<ng-container *ngIf="paymentType.value === PaymentMethodType.CreditCard && paymentServiceType === PaymentServiceType.CardConnect">
  <div
    *ngIf="paymentServiceType === PaymentServiceType.CardConnect && template && !libraryVersion; else cardConnectCreditCardMockTemplate"
    class="form-row-wrapper"
  >
    <!-- CardConnect iFrame Tokenizer -->
    <iframe
      id="tokenFrame"
      name="tokenFrame"
      [src]="cardConnectSrc | safeURL"
      frameborder="0"
      width="100%"
      height="295"
    ></iframe>
  </div>
  <!-- CardConnect Errors -->
  <span
    class="card-connect-error"
    *ngIf="paymentServiceType === PaymentServiceType.CardConnect && cardConnectError"
  >{{cardConnectError | translate}}</span>
</ng-container>

<!-- CardConnect ACH Template -->
<ng-container *ngIf="paymentType.value === PaymentMethodType.ACH && paymentServiceType === PaymentServiceType.CardConnect">
  <div class="form-row-wrapper" [ngStyle]="containerStyle">
    <div class="form-element right-margin" [ngStyle]="doubleFormElementStyle">
      <app-fb-input
        [formGroup]="achForm"
        errorMassage="Routing Number"
        label="Routing Number *"
        name="routingNumber"
        mask="000 000 000"
        placeholder="000 000 000"
        type="tel"
        [inputBackground]="template.inputBackground"
        [color]="template.fontColor"
        [fontFamily]="template.fontFamily"
        [fontSize]="template.fontSize"
        [disabled]="readOnly"
      ></app-fb-input>
    </div>
    <div class="form-element" [ngStyle]="doubleFormElementStyle">
      <app-fb-input
        [formGroup]="achForm"
        errorMassage="Account Number"
        label="Account Number *"
        name="accountNumber"
        mask="0000 0000 9999"
        placeholder="0000 0000 (0000)"
        type="tel"
        [inputBackground]="template.inputBackground"
        [color]="template.fontColor"
        [fontFamily]="template.fontFamily"
        [fontSize]="template.fontSize"
        [disabled]="readOnly"
      ></app-fb-input>
    </div>
  </div>
</ng-container>


<!-- Cornerstone Credit Card Template -->
<div
  [ngStyle]="paymentType.value === PaymentMethodType.CreditCard ? containerStyle : null"
  [class.hidden-block]="paymentType.value === PaymentMethodType.ACH"
  *ngIf="paymentServiceType === PaymentServiceType.Cornerstone"
>
  <!-- Cornerstone iFrame -->
  <div class="cornerstone-container">
    <div class="cornerstone-field">
      <div id="creditCardNumber" *ngIf="!libraryVersion; else mockCornerStoneCreditCardTemplate"></div>
      <div class="field-sub-label" *ngIf="(cornerstoneService.isCornerstoneCCNumberValid | async)">
        <span [ngStyle]="subLabelStyle">{{'Card Number' | translate}}</span>
      </div>
      <div class="error-field" *ngIf="!(cornerstoneService.isCornerstoneCCNumberValid | async)">
        <span>{{cornerstoneService.cornerstoneCCNumberError | async}}</span>
      </div>
    </div>
    <div class="cornerstone-field">
      <div id="creditCardExpiration" *ngIf="!libraryVersion; else mockCornerStoneExpirationDateTemplate"></div>
      <div class="field-sub-label" *ngIf="(cornerstoneService.isCornerstoneCCExpValid | async)">
        <span [ngStyle]="subLabelStyle">{{'Expiration Date' | translate}}</span>
      </div>
      <div class="error-field" *ngIf="!(cornerstoneService.isCornerstoneCCExpValid | async)">
        <span>{{cornerstoneService.cornerstoneCCExpError | async}}</span>
      </div>
    </div>
    <div class="cornerstone-field">
      <div id="creditCardCVV" *ngIf="!libraryVersion; else mockCornerStoneCVVTemplate"></div>
      <div class="field-sub-label" *ngIf="(cornerstoneService.isCornerstoneCCCvvValid | async)">
        <span [ngStyle]="subLabelStyle">CVV</span>
      </div>
      <div class="error-field" *ngIf="!(cornerstoneService.isCornerstoneCCCvvValid | async)">
        <span>{{cornerstoneService.cornerstoneCCCvvError | async}}</span>
      </div>
    </div>
  </div>
</div>

<!-- Cornerstone ACH Template -->
<div
  [class.hidden-block]="paymentType.value === PaymentMethodType.CreditCard"
  *ngIf="paymentServiceType === PaymentServiceType.Cornerstone"
  [ngStyle]="paymentType.value === PaymentMethodType.ACH ? containerStyle : null"
>
  <!-- Cornerstone ACH Form -->
  <div class="cornerstone-form-row">
    <div class="cornerstone-field">
      <div id="checkaba" *ngIf="!libraryVersion; else mockCornerStoneRoutingNumberTemplate"></div>
      <div class="field-sub-label" *ngIf="(cornerstoneService.isCornerstoneRoutingValid | async)">
        <span [ngStyle]="subLabelStyle">{{'Routing Number' | translate}}</span>
      </div>
      <div class="error-field" *ngIf="!(cornerstoneService.isCornerstoneRoutingValid | async)">
        <span>{{cornerstoneService.cornerstoneRoutingError | async}}</span>
      </div>
    </div>
    <div class="cornerstone-field">
      <div id="checkaccount" *ngIf="!libraryVersion; else mockCornerStoneAccountNumberTemplate"></div>
      <div class="field-sub-label" *ngIf="(cornerstoneService.isCornerstoneAccountValid | async)">
        <span [ngStyle]="subLabelStyle">{{'Account Number' | translate}}</span>
      </div>
      <div class="error-field" *ngIf="!(cornerstoneService.isCornerstoneAccountValid | async)">
        <span>{{cornerstoneService.cornerstoneAccountError | async}}</span>
      </div>
    </div>
  </div>
  <div class="cornerstone-form-row">
    <div class="cornerstone-field">
      <div id="checkname" *ngIf="!libraryVersion; else mockCornerStoneAccountNameTemplate"></div>
      <div class="field-sub-label" *ngIf="(cornerstoneService.isCornerstoneNameValid | async)">
        <span [ngStyle]="subLabelStyle">{{'Account Name' | translate}}</span>
      </div>
      <div class="error-field" *ngIf="!(cornerstoneService.isCornerstoneNameValid | async)">
        <span>{{cornerstoneService.cornerstoneNameError | async}}</span>
      </div>
    </div>
  </div>
</div>



<!--Stripe Card Template-->
<div
  [class.hidden-block]="paymentType.value === PaymentMethodType.ACH"
  *ngIf="paymentServiceType === PaymentServiceType.Stripe && appStripeService.stripe"
  class="stripe-payment-provider"
>
  <ngx-stripe-card-group
    [stripe]="appStripeService.stripe"
    [elementsOptions]="appStripeService.elementsOptions"
  >

    <div class="stripe-input-wrapper" [style.backgroundColor]="template.inputBackground">
      <ngx-stripe-card-number [options]="appStripeService.cardOptions"></ngx-stripe-card-number>
    </div>
    <div class="stripe-label" [ngStyle]="subLabelStyle">{{'Card Number' | translate}}</div>


    <div class="stripe-input-wrapper" [style.backgroundColor]="template.inputBackground">
      <ngx-stripe-card-expiry [options]="appStripeService.cardOptions"></ngx-stripe-card-expiry>
    </div>
    <div class="stripe-label" [ngStyle]="subLabelStyle">{{'Expiration Date' | translate}}</div>


    <div class="stripe-input-wrapper" [style.backgroundColor]="template.inputBackground">
      <ngx-stripe-card-cvc [options]="appStripeService.cardOptions"></ngx-stripe-card-cvc>
    </div>
    <div class="stripe-label" [ngStyle]="subLabelStyle">CVV</div>
  </ngx-stripe-card-group>
</div>

<!-- Stripe ACH Template -->
<div 
  [class.hidden-block]="paymentType.value === PaymentMethodType.CreditCard"
  *ngIf="paymentServiceType === PaymentServiceType.Stripe && appStripeService.stripe"
>
  <app-stripe-ach-form [clientId]="entity.clientID"></app-stripe-ach-form>
</div>

<div
  class="form-row-wrapper"
  *ngIf="(event && entity?.eventFeeAndTaxes === FeeAndTaxes.ProvideOption) && isRegisterPage"
>
  <app-fb-checkbox
    (clickEvent)="includeEventFee.setValue(!includeEventFee.value)"
    [value]="includeEventFee.value"
    [readOnly]="readOnly || paymentProcessStarted || pledgeEditPaymentDetails"
    [color]="template?.secondaryButtonColor"
    [noWrap]="true"
  >{{'By clicking this box, you agree to pay the fees for your ticket/registration so 100% of your ticket/registration cost will go towards our cause' | translate}}</app-fb-checkbox>
</div>

<div
  class="form-row-wrapper"
  *ngIf="showFeeOption && !noDonations"
>
  <app-fb-checkbox
    [borderColor]="template?.borderCheckboxColor"
    (clickEvent)="includeFee.setValue(!includeFee.value)"
    [value]="includeFee.value"
    [readOnly]="readOnly || paymentProcessStarted || pledgeEditPaymentDetails"
    [color]="template?.secondaryButtonColor"
    [noWrap]="true"
  >{{element?.attributes.feeText | translate}}</app-fb-checkbox>
</div>

<ng-template #cardConnectCreditCardMockTemplate>
  <div class="form-row-wrapper">
    <div class="card-connect-field">
      <div class="card-connect-sub-label" [ngStyle]="cardConnectSubLabelStyle">{{'Card Number' | translate}}</div>
      <div class="card-connect-form-element">
        <app-fb-input
          [formGroup]="mockPaymentForm"
          name="cc"
          mask="0000 0000 0000 0000"
          placeholder="0000 0000 0000 0000"
          type="tel"
          [inputBackground]="template.inputBackground"
          [color]="template.fontColor"
          [fontFamily]="template.fontFamily"
          [fontSize]="template.fontSize"
          [disabled]="readOnly"
        ></app-fb-input>
      </div>
    </div>
  </div>

  <div class="form-row-wrapper">
    <div class="card-connect-field">
      <div class="card-connect-sub-label" [ngStyle]="cardConnectSubLabelStyle">{{'Expiration Date' | translate}}</div>
      <div class="card-connect-form-element expiration-date">
        <app-fb-select
          [formGroup]="mockPaymentForm"
          name="expMonth"
          placeholder="--"
          type="tel"
          [inputBackground]="template.inputBackground"
          [color]="template.fontColor"
          [fontFamily]="template.fontFamily"
          [fontSize]="template.fontSize"
          [disabled]="readOnly"
        ></app-fb-select>

        <app-fb-select
          [formGroup]="mockPaymentForm"
          name="expYear"
          placeholder="--"
          type="tel"
          [inputBackground]="template.inputBackground"
          [color]="template.fontColor"
          [fontFamily]="template.fontFamily"
          [fontSize]="template.fontSize"
          [disabled]="readOnly"
        ></app-fb-select>
      </div>
    </div>
  </div>

  <div class="form-row-wrapper">
    <div class="card-connect-field">
      <div class="card-connect-sub-label" [ngStyle]="cardConnectSubLabelStyle">CVV</div>
      <div class="card-connect-form-element">
        <app-fb-input
          [formGroup]="mockPaymentForm"
          name="cvv"
          mask="0000"
          placeholder="0000"
          type="tel"
          [inputBackground]="template.inputBackground"
          [color]="template.fontColor"
          [fontFamily]="template.fontFamily"
          [fontSize]="template.fontSize"
          [disabled]="readOnly"
        ></app-fb-input>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #mockCornerStoneCreditCardTemplate>
  <app-fb-input
    [formGroup]="mockPaymentForm"
    name="cc"
    mask="0000 0000 0000 0000"
    placeholder="0000 0000 0000 0000"
    type="tel"
    [inputBackground]="template.inputBackground"
    [color]="template.fontColor"
    [fontFamily]="template.fontFamily"
    [fontSize]="template.fontSize"
    [disabled]="readOnly"
  ></app-fb-input>
</ng-template>


<ng-template #mockCornerStoneExpirationDateTemplate>
  <app-fb-input
    [formGroup]="mockPaymentForm"
    name="expMonth"
    mask="00 / 00"
    placeholder="00 / 00"
    type="tel"
    [inputBackground]="template.inputBackground"
    [color]="template.fontColor"
    [fontFamily]="template.fontFamily"
    [fontSize]="template.fontSize"
    [disabled]="readOnly"
  ></app-fb-input>
</ng-template>

<ng-template #mockCornerStoneCVVTemplate>
  <app-fb-input
    [formGroup]="mockPaymentForm"
    name="cvv"
    mask="0009"
    placeholder="000(0)"
    type="tel"
    [inputBackground]="template.inputBackground"
    [color]="template.fontColor"
    [fontFamily]="template.fontFamily"
    [fontSize]="template.fontSize"
    [disabled]="readOnly"
  ></app-fb-input>
</ng-template>

<ng-template #mockCornerStoneRoutingNumberTemplate>
  <app-fb-input
    [formGroup]="mockPaymentForm"
    name="cc"
    errorMassage="Routing"
    mask="000 000 000"
    placeholder="000 000 000"
    type="tel"
    [inputBackground]="template.inputBackground"
    [color]="template.fontColor"
    [fontFamily]="template.fontFamily"
    [fontSize]="template.fontSize"
    [disabled]="readOnly"
  ></app-fb-input>
</ng-template>

<ng-template #mockCornerStoneAccountNumberTemplate>
  <app-fb-input
    [formGroup]="mockPaymentForm"
    name="expMonth"
    errorMassage="Account"
    mask="0000 0000 0000"
    placeholder="0000 0000 (0000)"
    type="tel"
    [inputBackground]="template.inputBackground"
    [color]="template.fontColor"
    [fontFamily]="template.fontFamily"
    [fontSize]="template.fontSize"
    [disabled]="readOnly"
  ></app-fb-input>
</ng-template>

<ng-template #mockCornerStoneAccountNameTemplate>
  <app-fb-input
    [formGroup]="mockPaymentForm"
    name="expYear"
    errorMassage="Name"
    placeholder="Name on Checking Account"
    [inputBackground]="template.inputBackground"
    [color]="template.fontColor"
    [fontFamily]="template.fontFamily"
    [fontSize]="template.fontSize"
    [disabled]="readOnly"
  ></app-fb-input>
</ng-template>
