<div class="main-label-wrapper" [ngStyle]="mainLabelStyle">
  <b>{{element.attributes['mainLabel'] | translate}}</b>
</div>

<div
  class="form-row-wrapper"
  [ngStyle]="singleFormElementStyle"
>
  <app-fb-radio
    [style.padding-bottom]="'10px'"
    [formGroup]="paymentForm"
    name="donorType"
    [selectOptions]="clientDonorTypeOptions"
    [color]="template?.secondaryButtonColor"
    [borderColor]="template?.borderCheckboxColor"
    [disabled]="readOnly"
    (valueChanged)="donorTypeChanged()"
  ><!--<b>Please select the option if you are an individual or Organization:</b>--></app-fb-radio>
</div>
<ng-container *ngIf="donorType.value === ClientDonorType.Organization">
  <div class="form-row-wrapper">
    <div class="form-element right-margin" [ngStyle]="doubleFormElementStyle">
      <app-fb-input
              [formGroup]="paymentForm"
              label="Organization Name"
              name="organizationName"
              errorMassage="Organization Name"
              [inputBackground]="template.inputBackground"
              [color]="template.fontColor"
              [fontFamily]="template.fontFamily"
              [fontSize]="template.fontSize"
              [disabled]="readOnly"
      ></app-fb-input>
    </div>
    <div class="form-element" [ngStyle]="doubleFormElementStyle">
      <app-fb-input
              [formGroup]="paymentForm"
              label="Federal ID Number"
              name="federalIDNumber"
              errorMassage="Federal ID Number"
              [inputBackground]="template.inputBackground"
              [color]="template.fontColor"
              [fontFamily]="template.fontFamily"
              [fontSize]="template.fontSize"
              [disabled]="readOnly"
      ></app-fb-input>
    </div>
  </div>
  <div class="form-row-wrapper">
    <div class="form-element right-margin" [ngStyle]="doubleFormElementStyle">
      <app-fb-select
              [formGroup]="paymentForm"
              label="Organizational Type"
              name="organizationType"
              errorMassage="Organizational Type"
              [isSearchOption]="false"
              [selectOptions]="donationOrganizationalTypeOptions"
              [inputBackground]="template.inputBackground"
              [color]="template.fontColor"
              [fontFamily]="template.fontFamily"
              [fontSize]="template.fontSize"
              [disabled]="readOnly"
      >
      </app-fb-select>
    </div>
    <div *ngIf="organizationType.value == 4" class="form-element" [ngStyle]="doubleFormElementStyle">
      <app-fb-input
              [formGroup]="paymentForm"
              label="Organizational Type Name"
              name="organizationTypeName"
              errorMassage="Organizational Type Name"
              [inputBackground]="template.inputBackground"
              [color]="template.fontColor"
              [fontFamily]="template.fontFamily"
              [fontSize]="template.fontSize"
              [disabled]="readOnly"
      >
      </app-fb-input>
    </div>
  </div>
</ng-container>

