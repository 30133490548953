import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertAmount'
})
export class ConvertAmountPipe implements PipeTransform {

  public transform(value: string | number): string {
    if (typeof value === 'string') {
      const number = parseFloat(value);
      return Number.isInteger(number) ? number.toString() : number.toFixed(2);
    } else if (typeof value === 'number') {
      return Number.isInteger(value) ? value.toString() : value.toFixed(2);
    } else {
      return value;
    }
  }

}
