export enum MjmlCustomType {
  attachment = 'attachment',
  video = 'video',
  chart = 'chart',
  register = 'register',
  donate = 'donate',
  logo = 'logo',
  signature = 'signature',
  unsubscribe = 'unsubscribe',
  fundraiser = 'fundraiser',
  emailVideo = 'emailVideo',
  thermometer = 'thermometer',
  donationActivity = 'donationActivity',
  commentWall = 'commentWall',
  shareOnSM = 'shareOnSM',

  EventName = 'eventName',
  EventDescription = 'eventDescription',
  EventLocation = 'eventLocation',
  EventScheduler = 'eventScheduler',
  EventContacts = 'eventContacts',
  EventItinerary = 'eventItinerary',
  EventAccessibility = 'eventAccessibility',
  CampaignName = 'campaignName',

  letterFooterSection = 'mj-letter-footer-section'
}
