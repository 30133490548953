import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { BaseFormElementComponent } from '../base.form.element.component';
import FormElementDataModel from '../../../models/form.element.data.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-client-select',
  templateUrl: './client-select.component.html',
  styleUrls: ['./client-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientSelectComponent extends BaseFormElementComponent {
  @Output() public clientChanged: EventEmitter<string> = new EventEmitter<string>()

  constructor(public translate: TranslateService) {
    super(translate);
  }

  public get clientName(): string {
    const controlValue = this.formControl.value;
    if (!controlValue) {
      return '';
    }
    const currentSelectedOption = this.selectOptions.find(({value}: FormElementDataModel) => value === controlValue);
    return currentSelectedOption ? currentSelectedOption.label : '';
  }

  public onMenuItemClick(id: string): void {
    if (!id || id === this.formControl.value) {
      return;
    }
    this.formControl.setValue(id);
    this.clientChanged.emit(id);
  }

  public isActive(id: string): boolean {
    return id === this.formControl.value;
  }

}
