<div
  *ngIf="screenshots || !landingPageView; else landingPageTemplate"
  class="app-thermometer-tool-wrapper"
  #thermometer
  [ngStyle]="getContainerStyles()"
  [id]="entity.attributes.uniqueId"
>
  <app-thermometer
    [tms]="tms"
    [eventId]="eventId"
    [size]="size"
    [entity]="entity"
  ></app-thermometer>
</div>

<ng-template #landingPageTemplate>
 <!-- <div
    [ngStyle]="getContainerStyles()"
    [innerHTML]="entity.content | safeHTML"
  ></div>-->
  <!--<app-thermometer
    [eventId]="eventId"
    [entity]="entity"
  ></app-thermometer>-->
  <app-my-thermometer
    [eventId]="eventId"
    [entity]="entity"
    [landingPageView]="landingPageView"
  ></app-my-thermometer>
</ng-template>
