import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { BehaviorSubject, Subscription, zip } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormValidators } from '../../shared/validatiors/form-validators';
import { ActivatedRoute } from '@angular/router';
import { SignUpAsDonorEmailAsyncValidator } from '../../shared/validatiors/sign.up.as.donor.email.async.validator';
import { ClientDonorModel } from '../../models/client/client.donor.model';
import { ClientDonorService } from '../../services/client.donors/client-donor.service';
import { Filter } from '../../models/paging/filter.model';
import { FilterType } from '../../models/enum/filter.type';
import { Paging } from '../../models/paging/paging.model';
import {ClientService} from '../../services/client/client.service';
import {ClientModel} from '../../models/client/client.model';
import { DOCUMENT } from '@angular/common';
import { SignUpSettingsService } from 'src/app/services/templates/signup.settings.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PoliticalUnitType } from 'src/app/models/internationalization/political.unit.type';
import { CodeType } from 'src/app/models/internationalization/code.type';
import CountryModel from 'src/app/models/internationalization/country.model';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import { CountryStateService } from 'src/app/services/events/country.state.service';
import { LookupStoreService } from 'src/app/services/lookup/lookup-store.service';
import { CODE, LanguagesOptions, LOCALITY, POLITICAL_UNIT, TERRITORIAL_ENTITY } from 'src/app/constants';
import { LocalityType } from 'src/app/models/internationalization/locality.type';
import { MatSelectChange } from '@angular/material/select';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-sign-up-as-donor',
  templateUrl: './sign-up-as-donor.component.html',
  styleUrls: ['./sign-up-as-donor.component.scss'],
  providers: [CountryStateService]
})
export class SignUpAsDonorComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscription: Subscription = new Subscription();
  public disableSubmit: boolean = false;
  public successMessage: boolean = false;
  private clientId: string;
  private clientName: string;
  private primaryContact: string;
  private apiURL: string = localStorage.getItem('apiurl');
  public imageURL: string;
  public countryState: BehaviorSubject<Partial<CountryModel>> = new BehaviorSubject<Partial<CountryModel>>(null);
  public isUsa$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showZip$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public city$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public showCity$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public state$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public zip$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public zipMask$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public zipPlaceholder$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public isFirstChange: boolean = true;
  public phonePrefix$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public phoneMask$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public phonePlaceholder$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public countries: Map<number, CountryModel> = new Map<number, CountryModel>();
  public countriesOptions: FormElementDataModel[];
  public successMessageText: string = "<div style='text-align: center;'><span style='font-size: 20px;'>Thank you for connecting with us! We're thrilled to have you join our community.</span></div>";
  public loadingData: boolean = false;
  public defaultLogo: string = '';
  @ViewChild('appSelect') appSelectComponent!: ElementRef;

  public formSettings: any = {
    font: [''],
    labelFontFamily: 'Roboto',
    inputFontFamily: 'Roboto',
    buttonFontFamily: 'Roboto',
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    tagLabel: 'Tag',
    emailLabel: 'Email',
    phoneLabel: 'Phone',
    buttonText: 'SUBMIT',
    layoutStyle: 'vertical',
    layoutColumns: '2',
    buttonTextColor: '#ffffff',
    buttonColor: '#0064be',
    labelFontColor: '#afafaf',
    inputFontColor: '#000000',
    inputFontSize: '16px',
    labelFontSize: '16px',
    buttonFontSize: '16px',
    logoSize: '44px',
    logoPosition: 'left',
    logo: null,
    isPhoneFieldRequired: false,
    areMailingAddressFieldsRequired: false,
    showMailingAddress: false,
    showCustomOptions: false,
    showLogo: [true],
    showPhoneNumber: [true],
    mailingCountryLabel: 'Country',
    mailingAddressLabel: 'Address',
    mailingStateLabel: 'State',
    mailingCityLabel: 'City',
    mailingZipCodeLabel: 'Zip Code',
    customText: '<div style="text-align: center;"><strong style="font-size: 30px; color: #212529; font-weight: bold;">Sign Up Today</strong></div>',
    tag: ['']
  };

  public donorForm: FormGroup = this.formBuilder.group({
    firstName: ['', [Validators.required, Validators.maxLength(250)]],
    lastName: ['', [Validators.required, Validators.maxLength(250)]],
    email: ['', [Validators.required, FormValidators.emailValidator]],
    phone: [''],
    mailingCountry: [1],
    mailingAddress: [''],
    mailingState: [''],
    mailingCity: [''],
    mailingZipCode: ['', [Validators.pattern('^(\\d{5}|\\d{9})$')]],
    tag: ['']
  });
  private guidRegExp: RegExp = new RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i);

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private clientDonorService: ClientDonorService,
    private clientService: ClientService,
    private signUpSettingsService: SignUpSettingsService,
    private sanitizer: DomSanitizer,
    public countryStateService: CountryStateService,
    public lookupStoreService: LookupStoreService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.removeHubSpotScript();
  }

  public ngOnInit(): void {
    this.renderer.addClass(document.body, 'sign-up-body');

    zip(this.lookupStoreService.countriesMap$)
    .pipe(
      first()
    )
    .subscribe(([countries]) => {
      this.countries = countries;
      this.countryState.next(countries.get(1));
    });

    this.loadingData = true;
    this.subscription.add(this.lookupStoreService.countries$.subscribe(res => this.countryStateService.storeCountries(res)));
    const clientId = this.activatedRoute.snapshot.params.clientId;
    if (this.guidRegExp.test(clientId)) {
      this.clientService.getModel(clientId, false).subscribe(this.onClientReceived.bind(this));
    } else {
      this.subscription.add(
        this.clientService.getClientDataByFriendlyUrl(clientId).subscribe(async (clientModelList: ClientModel) => {
          if (clientModelList) {
            await this.onClientReceived(clientModelList)
          }
        })
      );
    }    
    //this.onCountryChanged(1);
    this.countryStateSubscription();
  }

  public ngAfterViewInit(): void {
    this.removeHubSpotScript();
  }

  private async onClientReceived(clientModel: ClientModel): Promise<void> {
    if (clientModel) {
      this.clientId = clientModel.id;
      this.clientName = clientModel.name;
      this.primaryContact = clientModel.primaryFullName;
      this.formSettings.logo = clientModel.avatarImg
        ? `${this.apiURL}/AzureFileStorage/image/${clientModel.avatarImg}`
        : '/assets/images/auxilia-logo@1x.png';
      this.defaultLogo = this.formSettings.logo;
      this.checkExistingConfiguration();
    }
  }


  private checkExistingConfiguration() {
    this.signUpSettingsService.getConfigurationByClientId(this.clientId).subscribe(
      (config) => {
        if (config) {
          this.formSettings = {
            labelFontFamily: config.labelFontFamilyName,
            inputFontFamily: config.inputFontFamilyName,
            buttonFontFamily: config.buttonFontFamilyName,
            firstNameLabel: config.firstNameValue,
            lastNameLabel: config.lastNameValue,
            tagLabel: config.tagLabel ?? 'Tag',
            emailLabel: config.emailValue,
            phoneLabel: config.phoneNumberValue,
            buttonText: config.buttonValue,
            layoutStyle: config.layoutStyle,
            layoutColumns: config.layoutColumns,
            buttonTextColor: config.buttonFontColor,
            buttonColor: config.buttonBackgroundColor,
            labelFontColor: config.labelFontColor,
            inputFontColor: config.inputFontColor,
            inputFontSize: config.inputFontSize,
            labelFontSize: config.labelFontSize,
            buttonFontSize: config.buttonFontSize,
            logoSize: config.logosize,
            logoPosition: config.logoPosition,
            logo: config.logoPath ?? this.defaultLogo,
            isPhoneFieldRequired: config.phoneFieldRequired,
            areMailingAddressFieldsRequired: config.areMailingAddressFieldsRequired,
            showMailingAddress: config.showMailingAddressFields,
            showTags: config.showCustomOptions,
            showLogo: config.showLogo,
            showPhoneNumber: config.showPhoneNumber,
            mailingCountryLabel: config.country,
            mailingAddressLabel: config.address,
            mailingStateLabel: config.state,
            mailingCityLabel: config.city,
            mailingZipCodeLabel: config.zipCode,
            confirmationMailBody: config.confirmationMailBody,
            confirmationMailFromAddress: config.confirmationMailFromAddress,
            confirmationMailFromSubject: config.confirmationMailFromSubject,
            confimationMailBCC: config.confimationMailBCC,
            sendConfirmationEmail: config.sendConfirmationEmail,
            customText: this.sanitizer.bypassSecurityTrustHtml(config.customTitle),
            successMessage: config.confirmationScreen ? config.confirmationScreen : this.successMessageText,
            tag: config.customOptionsList.map(tag => ({
              label: tag.name,
              value: tag.tagId,
            }))
          };

          if (this.formSettings.isPhoneFieldRequired && this.formSettings.showPhoneNumber) {
            this.donorForm.get('phone').setValidators([Validators.required]);
          } else {
            this.donorForm.get('phone').clearValidators();
          }

          if (this.formSettings.areMailingAddressFieldsRequired && this.formSettings.showMailingAddress) {
            this.donorForm.get('mailingCountry').setValidators([Validators.required]);
            this.donorForm.get('mailingAddress').setValidators([Validators.required]);
            if(this.showState$) {
              this.donorForm.get('mailingState').setValidators([Validators.required]);
            }
            this.donorForm.get('mailingCity').setValidators([Validators.required]);
            this.donorForm.get('mailingZipCode').setValidators([Validators.required]);
          } else {
            this.donorForm.get('mailingCountry').clearValidators();
            this.donorForm.get('mailingAddress').clearValidators();
            this.donorForm.get('mailingState').clearValidators();
            this.donorForm.get('mailingCity').clearValidators();
            this.donorForm.get('mailingZipCode').clearValidators();
          }

          
          this.donorForm.get('phone').updateValueAndValidity();
          document.documentElement.style.setProperty('--select-dynamic-color', this.formSettings.inputFontColor);
          document.documentElement.style.setProperty('--select-dynamic-font-family', this.formSettings.inputFontFamily);
          document.documentElement.style.setProperty('--select-dynamic-font-size', this.formSettings.inputFontSize);

        } else {
          this.formSettings = this.formSettings;
          this.formSettings.customText = this.sanitizer.bypassSecurityTrustHtml(this.formSettings.customText);
          this.formSettings.successMessage = this.successMessageText;
        }
        setTimeout(() => {
          this.loadingData = false;
        }, 1000);
       
      },
      (error) => {
        console.error('Error fetching configuration', error);
      }
    );
  }

  public ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'sign-up-body');

    this.subscription.unsubscribe();
  }

  public onCountryChanged({ value }: MatSelectChange): void {
    this.countryState.next(this.countries.get(value));
  }

  private countryStateSubscription(): void {
    this.subscription.add(
      this.countryState.subscribe((nextState: Partial<CountryModel>) => {
        if (!nextState) {
          return;
        }
        const { localityType, politicalUnitType, name, codeType, phoneCode = '1' }: Partial<CountryModel> = nextState;

        this.isUsa$.next(name === 'USA');
        
        this.city$.next(LOCALITY[localityType]);
        const isCityActive = localityType !== LocalityType.Unknown;
        this.showCity$.next(isCityActive);
        const city = this.getFormControl('mailingCity');
        this.setEnableDisableProp(isCityActive, [city]);

        this.state$.next(POLITICAL_UNIT[politicalUnitType]);
        const isStateActive = politicalUnitType !== PoliticalUnitType.Unknown;
        this.showState$.next(isStateActive);
        const state = this.getFormControl('mailingState');
        this.setEnableDisableProp(isStateActive, [state]);

        this.zip$.next(CODE[codeType]);
        const isZipActive = codeType !== CodeType.Unknown;
        this.showZip$.next(isZipActive);
        const zipCode = this.getFormControl('mailingZipCode');

        this.setEnableDisableProp(isZipActive, [zipCode]);

        const zipValidator = (name === 'USA' || name.startsWith('Puerto')) ? [Validators.pattern('^(\\d{5}|\\d{9})$')] : [Validators.maxLength(30)];
        zipCode.setValidators(zipValidator);

        this.zipMask$.next((name === 'USA' || name.startsWith('Puerto')) ? '00000-9999' : '');
        this.zipPlaceholder$.next((name === 'USA' || name.startsWith('Puerto')) ? '00000-0000' : '');
        zipCode.updateValueAndValidity();

        const prefix = `+${phoneCode} `;
        this.phonePrefix$.next(prefix);
        const mask = name === 'USA' ? '(000) 000-0000' : '00000000999999999999'.slice(0, -phoneCode.length);
        this.phoneMask$.next(mask);
        this.phonePlaceholder$.next(prefix);

        if (!this.isFirstChange) {
          city.reset();
          state.reset();
          zipCode.reset();
          this.getFormControl('phone').reset();
        }

        this.donorForm.updateValueAndValidity();
        this.isFirstChange = false;
      })
    );
  }

  private setEnableDisableProp(condition: boolean, controls: FormControl[]): void {
    controls.forEach((control: FormControl) => condition ? control.enable() : control.disable());
  }


  private getFormControl(controlName: string): FormControl {
    return this.donorForm.get(controlName) as FormControl;
  }
  
  public submitDonorForm(): void {
    const firstName = this.donorForm.get('firstName').value.trim();
    const lastName = this.donorForm.get('lastName').value.trim();
    const email = this.donorForm.get('email').value.trim();
    const phone = this.donorForm.get('phone').value?.trim();
    
    const address = this.donorForm.get('mailingAddress').value?.trim();
    const tags = this.donorForm.get('tag').value;

    let country = null;
    let state = null;
    let city = null;
    let zipCode = null;

    if(this.formSettings.showMailingAddress) {
    country = this.countryState.getValue();
      if(country) {
        state = this.donorForm.get('mailingState').value;
        city = this.donorForm.get('mailingCity')?.value?.trim();
        zipCode = this.donorForm.get('mailingZipCode')?.value?.trim();
      }
    }

    this.donorForm.get('firstName').setValue(firstName);
    this.donorForm.get('lastName').setValue(lastName);
    this.donorForm.get('email').setValue(email);
    this.donorForm.markAllAsTouched();

    if (this.donorForm.invalid) {
      return;
    } else {
      this.disableSubmit = true;

      const filters: Filter[] = [
        {
          field: 'clientID',
          value: this.clientId,
          type: FilterType.Equal,
        },
        {
          field: 'email',
          value: email,
          type: FilterType.Equal,
        }
      ];
      const paging: Paging = {
        includeDependencies: false,
        includeDeleted: false,
        filters,
      };
     //this.subscription.add(
        //this.clientDonorService.getModelByClientIdEmail(this.clientId, email).subscribe((clientDonor: ClientDonorModel) => {
          
        /*if (clientDonor) {
            this.donorForm.get('email').setErrors({clientDonorEmailUnique: true});
            this.disableSubmit = false;
            return;
          } else {*/
            const donor: ClientDonorModel = {
              firstName,
              lastName,
              email,
              clientID: this.clientId,
              sendInvitation: true,
            };
            if (phone) {
              const phonePrefix = this.phonePrefix$.getValue();
              const phonePrefixWithoutPlus = phonePrefix.startsWith('+') ? phonePrefix.slice(1) : phonePrefix;
              donor.primaryPhone = phonePrefixWithoutPlus + phone;
            }
            if(this.formSettings.showMailingAddress) {
              if (address) {
                donor.street = address;
              }
              if (country) {
                donor.country = country.id;
              }

              if (state) {
                if (country.id !== 1) {
                  const stateName = state ? state.toString() : '';
                  donor.stateName = stateName;
                  donor.state = stateName ? 0 : null;
                } else {
                  donor.state = state;
                }
              }
              if (city) {
                donor.city = city;
              }
              if (zipCode) {
                donor.postCode = zipCode;
              }
            }
            if(tags) {
              donor.tagsIds = tags;
            }

            if(this.formSettings.confirmationMailBody) {
              donor.confirmationMailBody = this.formSettings.confirmationMailBody;
            }

            if(this.formSettings.sendConfirmationEmail) {
              donor.sendConfirmationEmail = this.formSettings.sendConfirmationEmail;
            }
            
            if(this.formSettings.confirmationMailFromAddress) {
              donor.confirmationMailFromAddress = this.formSettings.confirmationMailFromAddress;
            }

            if(this.formSettings.confirmationMailFromSubject) {
              donor.confirmationMailFromSubject = this.formSettings.confirmationMailFromSubject;
            }

            if(this.formSettings.confimationMailBCC) {
              donor.confimationMailBCC = this.formSettings.confimationMailBCC;
            }
            donor.signup = true;
            this.subscription.add(
              this.clientDonorService.addModel(donor).subscribe((clientDonor: ClientDonorModel) => {
                this.successMessage = true;
                this.donorForm.reset();
                this.donorForm.markAsUntouched();
                this.disableSubmit = false;
                const data = {
                  DonorFirstName: firstName,
                  DonorLastName: lastName,
                  DonorName: firstName + " " + lastName,
                  Email: email,
                  OrganizationName: this.clientName,
                  Phone: donor.primaryPhone,
                  PrimaryContact: this.primaryContact,
                };

                const successMessage = this.formSettings?.successMessage?.toString() ?? '';
                
                const resultMessage = successMessage.replace(/\[([^\]]+)\]/g, (match, p1) => {
                  return data[p1] !== undefined ? data[p1] : match;
                });

                this.donorForm.get('mailingCountry').setValue(1);
                this.formSettings.successMessage = this.sanitizer.bypassSecurityTrustHtml(resultMessage);
                //this.onCountryChanged(1);
                this.countryState.next(this.countries.get(1));
                setTimeout(() => {
                  //this.successMessage = false;
                  window.location.reload();
                }, 5000);
              })
            );
            
          //}
        //})
     // );
    }
  }

  private removeHubSpotScript(): void {
    const hubSpotScript = this.document.getElementById('hs-script-loader');
    const hubSpotContainer = this.document.getElementById('hubspot-messages-iframe-container');
    hubSpotScript && hubSpotScript.remove();
    hubSpotContainer && hubSpotContainer.remove();
  }

}
