<div class="fb-page-container with-background">
  <div class="fb-page-form-row">
    <div class="max-width-810">
      <app-input
        [formGroup]="fbss.templateSettingsForm"
        name="fbTemplateName"
        errorMassage="Form Name"
        label="Form Name *"
        placeholder="Define the name of the form"
        [disabled]="readOnly"
      ></app-input>
    </div>
  </div>

  <div class="fb-page-form-row">
    <div class="max-width-810">
      <app-radio
        [formGroup]="fbss.templateSettingsForm"
        name="fbTemplateStatus"
        errorMassage="Form Status"
        label="Form Status"
        [selectOptions]="formStatusOptions"
        [disabled]="readOnly"
        (radioValueChanged)="onValueChanged()"
      ></app-radio>
    </div>
  </div>

  <ng-container *ngIf="isEnableOnDate$ | async">
    <div class="fb-page-form-row">
      <div class="max-width-200 right-margin">
        <app-date-picker
          [formGroup]="fbss.templateSettingsForm"
          name="disableDate"
          errorMassage="Date"
          label="Date"
          placeholder="MM / DD / YYYY"
          [disabled]="readOnly"
          [dateFilter]="dateFilter"
          (timeValueChanged)="onValueChanged()"
        ></app-date-picker>
      </div>

      <div class="max-width-200 right-margin">
        <app-time-input
          [formGroup]="fbss.templateSettingsForm"
          name="disableTime"
          errorMassage="Time"
          label="Time"
          [disabled]="readOnly"
          (timeValueChanged)="onValueChanged()"
        ></app-time-input>
      </div>

      <div class="max-width-370">
        <app-select
          [formGroup]="fbss.templateSettingsForm"
          [selectOptions]="lookupStoreService.timeZonesOptions$ | async"
          errorMassage="Time Zone"
          label="Time Zone"
          name="disableTimeZone"
          [disabled]="readOnly"
          (valueChanged)="onValueChanged()"
        ></app-select>
      </div>
    </div>

    <div class="fb-page-form-row">
      <div class="max-width-810">
        <app-textarea
          [formGroup]="fbss.templateSettingsForm"
          name="messageWhenDisabled"
          errorMassage="Message"
          label="Notification Message"
          [disabled]="readOnly"
          (valueChanged)="onValueChanged()"
        ></app-textarea>
      </div>
    </div>
  </ng-container>
</div>
