<div class="main-label-wrapper" [ngStyle]="mainLabelStyle">
  <b>{{element.attributes['mainLabel'] | translate}}</b>
</div>

<div class="form-row-wrapper">
  <div class="form-element right-margin" [ngStyle]="doubleFormElementStyle">
    <app-fb-select
      [formGroup]="paymentForm"
      [label]="element.attributes['country'] || 'Country'"
      name="country"
      errorMassage="Country"
      [inputBackground]="template.inputBackground"
      [selectOptions]="countryStateService.countriesOptions"
      (valueChanged)="onCountryChanged($event)"
      [color]="template.fontColor"
      [fontFamily]="template.fontFamily"
      [fontSize]="template.fontSize"
      [disabled]="readOnly"
    ></app-fb-select>
  </div>
  <!-- Neighborhood / District / CEDEX / Area / Emirate / Townland / Prefecture / Oblast -->
  <div
    *ngIf="(countryStateService.showTerritorialEntity$ | async) && !isEmployerAddress"
    class="form-element"
    [ngStyle]="doubleFormElementStyle"
  >
    <app-fb-input
      [formGroup]="paymentForm"
      name="territorialEntity"
      [errorMassage]="countryStateService.territorialEntityError$ | async"
      [label]="countryStateService.territorialEntity$ | async"
      [inputBackground]="template.inputBackground"
      [color]="template.fontColor"
      [fontFamily]="template.fontFamily"
      [fontSize]="template.fontSize"
      [disabled]="readOnly"
    ></app-fb-input>
  </div>
</div>

<div class="form-row-wrapper">
  <!-- Street -->
  <div class="form-element right-margin" [ngStyle]="doubleFormElementStyle">
    <app-fb-input
      [formGroup]="paymentForm"
      errorMassage="Billing Street Address"
      [label]="element.attributes['billingStreetAddress']"
      [name]="isEmployerAddress ? 'address1' : 'streetAddress'"
      [inputBackground]="template.inputBackground"
      [color]="template.fontColor"
      [fontFamily]="template.fontFamily"
      [fontSize]="template.fontSize"
      [disabled]="readOnly"
    ></app-fb-input>
  </div>
  <!-- Street 2 -->
  <div class="form-element" [ngStyle]="doubleFormElementStyle">
    <app-fb-input
      [formGroup]="paymentForm"
      errorMassage="Street Address"
      [label]="element.attributes['streetAddress2']"
      [name]="isEmployerAddress ? 'address2' : 'streetAddress2'"
      [inputBackground]="template.inputBackground"
      [color]="template.fontColor"
      [fontFamily]="template.fontFamily"
      [fontSize]="template.fontSize"
      [disabled]="readOnly"
    ></app-fb-input>
  </div>
</div>

<div class="form-row-wrapper">
  <!-- City / Town / Locality / Suburb / Country / Post Town / Village / Township -->
  <div
    [ngStyle]="doubleFormElementStyle"
    class="form-element right-margin"
    *ngIf="countryStateService.showCity$ | async"
  >
    <app-fb-input
      [formGroup]="paymentForm"
      [name]="'city'"
      [errorMassage]="countryStateService.cityError$ | async"
      [label]="countryStateService.city$ | async"
      [inputBackground]="template.inputBackground"
      [color]="template.fontColor"
      [fontFamily]="template.fontFamily"
      [fontSize]="template.fontSize"
      [disabled]="readOnly"
    ></app-fb-input>
  </div>

  <!-- State / Province / Territory / Island / Parish / Department, Do/Si -->
  <div
    *ngIf="countryStateService.showState$ | async"
    class="form-element"
    [ngStyle]="doubleFormElementStyle"
  >
    <app-fb-select
      *ngIf="countryStateService.isUsa$ | async"
      [formGroup]="paymentForm"
      [errorMassage]="countryStateService.stateError$ | async"
      [name]="'state'"
      [label]="countryStateService.state$ | async"
      placeholder="All states"
      [selectOptions]="stateOptions"
      [inputBackground]="template.inputBackground"
      [color]="template.fontColor"
      [fontFamily]="template.fontFamily"
      [fontSize]="template.fontSize"
      [disabled]="readOnly"
    ></app-fb-select>
    <app-fb-input
      *ngIf="!(countryStateService.isUsa$ | async)"
      [formGroup]="paymentForm"
      [errorMassage]="countryStateService.stateError$ | async"
      [name]="'state'"
      [label]="countryStateService.state$ | async"
      [inputBackground]="template.inputBackground"
      [color]="template.fontColor"
      [fontFamily]="template.fontFamily"
      [fontSize]="template.fontSize"
      [disabled]="readOnly"
    ></app-fb-input>
  </div>
</div>

<div class="form-row-wrapper">
  <!-- Zip Code / Postal Code -->
  <div
    *ngIf="countryStateService.showZip$ | async"
    class="form-element right-margin"
    [ngStyle]="doubleFormElementStyle"
  >
    <app-fb-input
      [formGroup]="paymentForm"
      [name]="'zipCode'"
      [label]="isEmployerAddress ? 'Employer ' + (countryStateService.zip$ | async) : (countryStateService.zip$ | async)"
      [errorMassage]="countryStateService.zipError$ | async"
      [placeholder]="countryStateService.zipPlaceholder$ | async"
      [mask]="countryStateService.zipMask$ | async"
      [type]="(countryStateService.isUsa$ | async) ? 'tel': 'text'"
      [onlyNumbers]="countryStateService.isUsa$ | async"
      [inputBackground]="template.inputBackground"
      [color]="template.fontColor"
      [fontFamily]="template.fontFamily"
      [fontSize]="template.fontSize"
      [disabled]="readOnly"
    ></app-fb-input>
  </div>
</div>
