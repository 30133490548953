<div class="registration-cancel">
    <p class="registration-cancel-title">{{"BUILD.You are about to leave the Registration" | translate}}</p>
    <div class="registration-cancel-action-btns">
        <app-button
            (click)="handleDiscard()"
            label="YES"
            type="stroked"
            color="#0064be"
            width="130"
        ></app-button>
        <app-button
            (click)="handleStay()"
            label="NO"
            type="stroked"
            color="#a9110e"
            width="130"
        ></app-button>
    </div>
</div>