<div class="fb-options-row">
    <div class="fb-row-element-full">
        <app-input
                [formGroup]="optionsForm"
                name="mainLabel"
                label="Element Text"
                [low]="true"
                (valueChanged)="onMainLabelChanged()"
                [disabled]="readOnly"
        ></app-input>
    </div>
</div>

<div class="fb-options-row">
    <div class="fb-row-element-full">
        <app-select
                [formGroup]="optionsForm"
                name="mainLabelAlignment"
                label="Label Alignment"
                [low]="true"
                (valueChanged)="onLabelAlignmentChanged()"
                [disabled]="readOnly"
                [selectOptions]="labelAlignmentOptions"
        ></app-select>
    </div>
</div>

<div class="fb-options-row">
    <app-fb-checkbox
            (clickEvent)="onIsSetAsFormDefaultChanged()"
            [value]="isSetAsFormDefault.value"
            [readOnly]="readOnly"
    >{{'BUILD.Set as form default' | translate}}</app-fb-checkbox>
</div>

<div class="fb-options-row">
  <app-fb-checkbox
    (clickEvent)="onRequiredStatusChanged()"
    [value]="required.value"
    [readOnly]="readOnly"
  >Required</app-fb-checkbox>
</div>

<div class="fb-options-row">
    <div class="fb-options-row-subtitle">{{'BUILD.Sublabels' | translate}}</div>
</div>

<div class="fb-options-row">
    <div class="fb-row-element-full">
        <app-input
                [formGroup]="optionsForm"
                name="subLabel"
                [low]="true"
                (valueChanged)="onSubLabelChanged()"
                [disabled]="readOnly"
        ></app-input>
    </div>
</div>

