<div class="image-builder-styling-content">
  <div class="image-builder-styling-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="shapeForm"
        label="Color"
        name="fill"
        [readOnly]="readOnly"
        (colorChanged)="onFillChanged()"
      ></app-email-color-picker>
    </div>
  </div>
  <div class="image-builder-styling-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="shapeForm"
        label="Stroke Color"
        name="stroke"
        [readOnly]="readOnly"
        (colorChanged)="onStrokeChanged()"
      ></app-email-color-picker>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="shapeForm"
        name="strokeWidth"
        label="Stroke Width"
        [min]="0"
        [max]="50"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onStrokeWidthChanged()"
        [disabled]="readOnly"
      ></app-slider>
    </div>
  </div>

  <div class="image-builder-styling-row">
    <div class="max-width-350">
      <app-radio
        [formGroup]="shapeForm"
        label="Keep Aspect Ratio"
        name="keepRatio"
        [selectOptions]="keepRatioOptions"
        [disabled]="readOnly || themedTemplate"
        (radioValueChanged)="onKeepRatioChanged($event)"
      ></app-radio>
    </div>
  </div>

</div>
