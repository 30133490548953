<div *ngIf="eventFinished">
  <div class="mt-100 flex-row justify-center align-center text-wrapper">
    <i class="fas fa-check mr-20 text-wrapper--icon"></i>
    <span>{{'BUILD.The event was canceled or complete.' | translate}}</span>
  </div>
</div>

<div *ngIf="fundraiserFinished">
  <div class="mt-100 flex-row justify-center align-center text-wrapper error">
    <i class="fas fa-times mr-20 text-wrapper--icon"></i>
    <span>Fundraiser was not approved or canceled</span>
  </div>
</div>