<div class="fb-page-container with-background min-height">
  <div class="fb-page-form-row thy-page" *ngIf="thankYouPageHtml$ | async">
      <app-radio
        [selectOptions]="pageOptions"
        [formGroup]="this.fbss.templateSettingsForm"
        label="Thank You Page Content"
        name="isCustomThankYouPage"
      ></app-radio>
    <div class="max-width-810" *ngIf="!isDefaultPage && activeStep">
      <app-text-editor
        [formGroup]="form"
        name="thankYouPageHtml"
        errorMassage="Content"
        [placeholders]="placeholders"
        [disabled]="readOnly"
        (valueChanged)="onValueChanged()"
      ></app-text-editor>
    </div>
    <div class="max-width-810 min-width-548" *ngIf="isDefaultPage && activeStep">
      <app-input
        [formGroup]="this.fbss.templateSettingsForm"
        name="customThankYouPageUrl"
        errorMassage="Custom Url"
        label="URL *"
        [disabled]="readOnly"
      ></app-input>
    </div>
  </div>
</div>
