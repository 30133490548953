import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs-compat';
import {switchMap, tap } from 'rxjs/operators'
import { P2PStaticPageModel } from 'src/app/models/p2p/p2p-static-page.model';
import { P2pFundraiserService } from 'src/app/services/p2p/p2p-fundraiser.service';
import { Paging } from 'src/app/models/paging/paging.model';
import { FilterType } from 'src/app/models/enum/filter.type';
import { ToastrService } from 'ngx-toastr';
import { EventDonationModel } from 'src/app/models/event/event-donation.model';
import { EventDonationsService } from 'src/app/services/events/event-donations.service';
import { P2PStatus } from '../../models/p2p/p2p-fundraiser.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserRolesType } from 'src/app/models/enum/user.roles.type';
import {DonationStatusType} from "../../models/enum/donation.status.type";

@Component({
  selector: 'app-publish-fundraiser',
  templateUrl: './publish-fundraiser.component.html',
  styleUrls: ['./publish-fundraiser.component.scss']
})
export class PublishFundraiserComponent implements OnInit, OnDestroy {

  staticPageModel: P2PStaticPageModel;
  isLoading: boolean;
  private subscription: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private fundraiserService: P2pFundraiserService,
    private eventDonationsService: EventDonationsService,
    private toastrService: ToastrService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    const eventFriendlyUrl: string = this.route.snapshot.params.friendlyURL;
    const ownerName: string = this.route.snapshot.params.ownerName;
    const customUrl: string = this.route.snapshot.params.customUrl;

    this.isLoading = true;

    this.subscription.add(
      this.fundraiserService.getP2PFundraiserPageInfo(eventFriendlyUrl, ownerName, customUrl)
        .pipe(
          tap((res: P2PStaticPageModel) => {
            const roles: string[] = this.authService.getIdentityClaimsRole();
            if (!roles?.includes(UserRolesType.CLIENT_ADMIN) && !roles?.includes(UserRolesType.SUPER_ADMIN) && ![P2PStatus.live, P2PStatus.approved].includes(res.fundraiserStatus) ) { // Check if fundraiser is live
              this.isLoading = false;
              this.toastrService.error("Fundraiser is not live");
              this.router.navigateByUrl(`/`);

              return;
            }

            this.staticPageModel = res;
          }),
          switchMap((res: P2PStaticPageModel) => {
            const paging: Paging = {
              filters: [
                {
                  field: "clientId",
                  value: res.clientId,
                  type: FilterType.Equal
                },
                {
                  field: "eventId",
                  value: res.eventId,
                  type: FilterType.Equal
                },
                {
                  field: "fundraiserID",
                  value: res.fundraiserId,
                  type: FilterType.Equal
                },
                {
                  field: "donationStatus",
                  value: `array[${DonationStatusType.Authorized}, ${DonationStatusType.Completed}]`,
                  type: FilterType.Equal
                }
              ],
            }

            return this.eventDonationsService.getEventDonationActivitiesLimited(paging);
          })
        )
        .subscribe(
          (res: EventDonationModel[]) => {
            this.isLoading = false;
            this.initDonations(res);
          },
          err => {
            this.isLoading = false;
            err?.error?.message && this.toastrService.error(err?.error?.message);
            this.router.navigateByUrl(`/`);
          }
        )
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initDonations(donations: EventDonationModel[]): void {
    const raisedFunds: number = donations
      ?.map((donation: EventDonationModel) => donation.donationAmmount)
      .reduce((acc, curr) => acc + curr,0);

    this.staticPageModel.donationsCount = donations?.length || 0;
    this.staticPageModel.raisedFunds = raisedFunds || 0;
  }
}
