import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCropperModel } from '../../../../../models/image.cropper.model';
import { ImageCroppedEvent } from 'ngx-image-cropper';
@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss']
})
export class CropperComponent {
  public croppedImage: string = '';
  public canvasRotation: number = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ImageCropperModel,
    public dialogRef: MatDialogRef<CropperComponent>,
  ) { }


  public imageCropped(image: ImageCroppedEvent): void {
    this.croppedImage = image.base64;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public rotateLeft(): void {
    this.canvasRotation--;
  }

  public rotateRight(): void {
    this.canvasRotation++;
  }

  public resetImage(): void {
    this.canvasRotation = 0;
  }

  public saveCroppedImage(): void {
    this.dialogRef.close(this.croppedImage);
  }

}
