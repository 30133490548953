import { ActivatedRoute, Router } from '@angular/router';
import { ChangePasswordModel } from 'src/app/models/user/change-password.model';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { FormValidators } from 'src/app/shared/validatiors/form-validators';
import { PasswordRequirementsModel } from 'src/app/models/user/password-requirements.model';
import { UserService } from 'src/app/services/user.service';
import {ToastrService} from 'ngx-toastr';
import { DonorEmployeeService } from 'src/app/services/donor.employee/donor-employee.service';
import { ClientEmployeeService } from 'src/app/services/client/client-employee.service';
import { Filter } from 'src/app/models/paging/filter.model';
import { FilterType } from 'src/app/models/enum/filter.type';
import { Paging } from 'src/app/models/paging/paging.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
  public requirements: PasswordRequirementsModel;
  public resetPassForm: FormGroup = new FormGroup({
    password: new FormControl(''),
    confirmPassword: new FormControl(''),
    email: new FormControl(null, [Validators.required, FormValidators.emailValidator]),
    code: new FormControl(null, [Validators.required])
  }, [FormValidators.passwordsEqual]);
  public success: boolean = false;

  constructor(
    private userService: UserService, private activeRouter: ActivatedRoute, private toastrService: ToastrService, private router: Router,
    private clientEmployeeService: ClientEmployeeService,
    private donorEmployeeService: DonorEmployeeService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.resetPassForm.get('email').setValue(this.activeRouter.snapshot.params['email']);
    this.resetPassForm.get('code').setValue(this.activeRouter.snapshot.params['code']);
    this.userService.CheckResetPasswordToken(this.resetPassForm.value).subscribe(response => {
      if (response === false) {
        this.toastrService.error(this.translate.instant('AUTH.Your link expired'));
        this.router.navigate(['']);
      }
    });
    this.userService.getPasswordRequirements().subscribe(response => {
      this.requirements = response;
      const validators: ValidatorFn[] = [Validators.required];
      if (response.requireDigit) {
        validators.push(FormValidators.oneNumber);
      }
      if (response.requireLowercase) {
        validators.push(FormValidators.oneSmallLetter);
      }
      if (response.requireUppercase) {
        validators.push(FormValidators.oneCapitalLetter);
      }
      if (response.requireNonAlphanumeric) {
        validators.push(FormValidators.specialSymbol);
      }
      if (response.requiredLength) {
        validators.push(Validators.minLength(response.requiredLength));
      }
      this.resetPassForm.get('password').setValidators(validators);
      this.resetPassForm.get('password').updateValueAndValidity();
      this.resetPassForm.get('confirmPassword').setValidators(validators);
      this.resetPassForm.get('confirmPassword').updateValueAndValidity();
      this.resetPassForm.updateValueAndValidity();
    });
  }

  public get email(): FormControl {
    return this.resetPassForm.get('email') as FormControl;
  }

  public get newPassword(): FormControl {
    return this.resetPassForm.get('password') as FormControl;
  }

  public get confirmPassword(): FormControl {
    return this.resetPassForm.get('confirmPassword') as FormControl;
  }

  public get showRequirements(): boolean {
    return this.resetPassForm.get('password').value.length > 0 || this.resetPassForm.get('password').touched;
  }

  public resetPassword(): void {
    if (this.resetPassForm.valid) {
      const filters: Filter[] = [
        {
          field: 'email',
          value: this.email.value,
          type: FilterType.Equal
        },
        {
          field: 'IsActive',
          value: true,
          type: FilterType.Equal
        }
      ];
      const paging: Paging = {
        includeDependencies: false,
        includeDeleted: true,
        filters: filters,
      };
      this.clientEmployeeService.getAnonymousEmployee(paging).subscribe(clients => {
        if (clients.length) {
          this.userService.resetPassword(this.resetPassForm.value).subscribe(response => {
            this.success = response;
            this.router.navigate(['']);
          });
        } else {
          this.donorEmployeeService.resetPassword(this.resetPassForm.value).subscribe(response => {
            this.success = response;
            this.router.navigate(['']);
          });
        }
      });
    }
  }
}
