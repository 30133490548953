import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilderStateService} from '../../form-builder.state.service';
import FbTemplateModel, {OptionsViewType} from '../../../../models/form-builder/fb.template.model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import FormElementDataModel from '../../../../models/form.element.data.model';
import {BACKGROUNDR_REPEAT_OPTIONS, BACKGROUNDR_SIZE_OPTIONS, FONTS, FONT_SIZES} from '../../../../constants';

@Component({
  selector: 'app-common-options',
  templateUrl: './common-options.component.html',
  styleUrls: ['../fb.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommonOptionsComponent implements OnInit, OnDestroy {
  @Input() public fbss: FormBuilderStateService;

  private subscription: Subscription = new Subscription();

  public optionsForm: FormGroup = this.formBuilder.group({
    pageColor: '',
    formColor: '',
    fontColor: '',
    inputBackground: '',
    width: '',
    spacing: '',
    textFont: '',
    fontSize: '',
    fontFamily: '',

    nextButtonLabel: '',
    donateButtonLabel: '',
    primaryButtonColor: '',
    primaryTextButtonColor: '',
    secondaryButtonColor: '',
    secondaryTextButtonColor: '',
    borderCheckboxColor: '',
    donationBubbleDefaultColor: '',
    formBgImg: '',
    backgroundRepeat: 'repeat',
    backgroundSize: 'auto'
  });

  public fontFamilyOptions: FormElementDataModel[] = FONTS;
  public fontSizeOptions: FormElementDataModel[] = FONT_SIZES.slice(1, 14);

  public backgroundRepeatOptions: FormElementDataModel[] = BACKGROUNDR_REPEAT_OPTIONS;
  public backgroundSizeOptions: FormElementDataModel[] = BACKGROUNDR_SIZE_OPTIONS;

  public imageURL: string = null;

  public isShowUploadImg: boolean = false;

  constructor(
    private formBuilder: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.fbss.optionsView$
        .pipe(
          filter((viewType: OptionsViewType) => viewType === OptionsViewType.Common),
          tap(this.setInitialValues.bind(this))
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public close(): void {
    this.fbss.setOptionsView(OptionsViewType.None);
  }

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }

  public get formBgImg(): FormControl {
    return this.optionsForm.get('formBgImg') as FormControl;
  }

  public get backgroundRepeat(): FormControl {
    return this.optionsForm.get('backgroundRepeat') as FormControl;
  }

  public get backgroundSize(): FormControl {
    return this.optionsForm.get('backgroundSize') as FormControl;
  }

  public imageUrlChanged(url: string): void {
      this.imageURL = null;
      if (url === null) {
        return;
      }
  }

  public onBackgroundRepeatChanged(): void {
    const value = this.backgroundRepeat.value;
    this.template.backgroundRepeat = value ? value : 'repeat';
    this.fbss.templateWasChanged();
  }

  public onBackgroundSizeChanged(): void {
    const value = this.backgroundSize.value;
    this.template.backgroundSize = value ? value : 'auto';
    this.fbss.templateWasChanged();
  }

  public onUrlChanged(): void {
    this.template.formBgImg = this.formBgImg.value || '';
    this.fbss.bgImgAdded$.next();
    this.fbss.templateWasChanged();
  }

  public deleteImg(): void {
    this.isShowUploadImg = false;
    this.formBgImg.setValue('');
    this.backgroundRepeat.setValue('repeat');
    this.backgroundSize.setValue('auto');
    this.template.formBgImg = '';
    this.template.backgroundRepeat = 'repeat';
    this.template.backgroundSize = 'auto';
    this.fbss.templateWasChanged();
  }

  private setInitialValues(): void {
    const {
      width = 760,
      pageColor = '#ECEDF3',
      formColor = '#ffffff',
      fontColor = '#2C3345',
      inputBackground = '#ffffff',
      spacing = 10,
      fontFamily = 'Roboto',
      fontSize = 16,

      nextButtonLabel = 'Next',
      donateButtonLabel = 'Donate',
      primaryButtonColor = '#0064be',
      primaryTextButtonColor = '#ffffff',
      secondaryButtonColor = '#0064be',
      secondaryTextButtonColor = '#ffffff',
      borderCheckboxColor = '#6A6A6A',
      donationBubbleDefaultColor = '#AFAFAF',
      formBgImg = '',
      backgroundRepeat = 'repeat',
      backgroundSize = 'auto'
    } = this.fbss.template$.getValue();
    if (formBgImg !== '') this.isShowUploadImg = true;
    this.optionsForm.get('width').setValue(width);
    this.optionsForm.get('pageColor').setValue(pageColor);
    this.optionsForm.get('formColor').setValue(formColor);
    this.optionsForm.get('fontColor').setValue(fontColor);
    this.optionsForm.get('inputBackground').setValue(inputBackground);
    this.optionsForm.get('spacing').setValue(spacing);
    this.optionsForm.get('fontFamily').setValue(fontFamily);
    this.optionsForm.get('fontSize').setValue(`${fontSize}px`);

    this.optionsForm.get('nextButtonLabel').setValue(nextButtonLabel);
    this.optionsForm.get('donateButtonLabel').setValue(donateButtonLabel);
    this.optionsForm.get('primaryButtonColor').setValue(primaryButtonColor);
    this.optionsForm.get('primaryTextButtonColor').setValue(primaryTextButtonColor);
    this.optionsForm.get('secondaryButtonColor').setValue(secondaryButtonColor);
    this.optionsForm.get('secondaryTextButtonColor').setValue(secondaryTextButtonColor);
    this.optionsForm.get('borderCheckboxColor').setValue(borderCheckboxColor);
    this.optionsForm.get('donationBubbleDefaultColor').setValue(donationBubbleDefaultColor);
    this.formBgImg.setValue(formBgImg);
    this.backgroundRepeat.setValue(backgroundRepeat);
    this.backgroundSize.setValue(backgroundSize);
  }

  public onPageColorChanged(): void {
    this.template.pageColor = this.optionsForm.get('pageColor').value || '#ECEDF3';
    this.fbss.templateWasChanged();
  }

  public onFormColorChanged(): void {
    this.template.formColor = this.optionsForm.get('formColor').value || '#ffffff';
    this.fbss.templateWasChanged();
  }

  public onFontColorChanged(): void {
    this.template.fontColor = this.optionsForm.get('fontColor').value || '#2C3345';
    this.fbss.templateWasChanged();
  }

  public onInputBackgroundChanged(): void {
    this.template.inputBackground = this.optionsForm.get('inputBackground').value || '#ffffff';
    this.fbss.templateWasChanged();
  }

  public onWidthChanged(): void {
    this.template.width = this.optionsForm.get('width').value || 760;
    this.fbss.templateWasChanged();
  }

  public onSpacingChanged(): void {
    this.template.spacing = this.optionsForm.get('spacing').value || 10;
    this.fbss.templateWasChanged();
  }

  public onFontFamilyChanged(): void {
    this.template.fontFamily = this.optionsForm.get('fontFamily').value || 'Roboto';
    this.fbss.templateWasChanged();
  }

  public onFontSizeChanged(): void {
    const fontSize = this.optionsForm.get('fontSize').value || '16px';
    this.template.fontSize = fontSize.slice(0, -2);
    this.fbss.templateWasChanged();
  }

  public onNextButtonLabelChanged(): void {
    this.template.nextButtonLabel = this.optionsForm.get('nextButtonLabel').value || 'Next';
    this.fbss.templateWasChanged();
  }

  public onDonateButtonLabelChanged(): void {
    this.template.donateButtonLabel = this.optionsForm.get('donateButtonLabel').value || 'Donate';
    this.fbss.templateWasChanged();
  }

  public showUploadImg(): void {
    this.isShowUploadImg = !this.isShowUploadImg;
  }

  public onPrimaryButtonColorChanged(): void {
    this.template.primaryButtonColor = this.optionsForm.get('primaryButtonColor').value || '#0064be';
    this.template.primaryButtonColorChanged = true;
    this.fbss.templateWasChanged();
  }

  public onPrimaryTextButtonColorChanged(): void {
    this.template.primaryTextButtonColor = this.optionsForm.get('primaryTextButtonColor').value || '#ffffff';
    this.fbss.templateWasChanged();
  }

  public onSecondaryButtonColorChanged(): void {
    this.template.secondaryButtonColor = this.optionsForm.get('secondaryButtonColor').value || '#0064be';
    this.template.secondaryButtonColorChanged = true;
    this.fbss.templateWasChanged();
  }

  public onSecondaryTextButtonColorChanged(): void {
    this.template.secondaryTextButtonColor = this.optionsForm.get('secondaryTextButtonColor').value || '#ffffff';
    this.fbss.templateWasChanged();
  }

  public onBorderCheckboxColorChanged(): void {
    this.template.borderCheckboxColor = this.optionsForm.get('borderCheckboxColor').value || '#6A6A6A';
    this.fbss.templateWasChanged();
  }

  public onDonationBubbleDefaultColorChanged(): void {
    this.template.donationBubbleDefaultColor = this.optionsForm.get('donationBubbleDefaultColor').value || '#AFAFAF';
    this.fbss.templateWasChanged();
  }

  private get template(): FbTemplateModel {
    return this.fbss.template$.getValue();
  }

  public tabIndexChanged(event): void {
    if (event === 2) {
      if (!this.optionsForm.get('nextButtonLabel').value) {
        this.optionsForm.get('nextButtonLabel').setValue('Next');
      }
      if (!this.optionsForm.get('donateButtonLabel').value) {
        this.optionsForm.get('donateButtonLabel').setValue('Donate');
      }
    }
  }
}
