import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Input,
  OnChanges,
  Output, Renderer2, SimpleChanges,
  ViewChild
} from '@angular/core';
import { BaseFormElementComponent } from '../../../../form-elements/base.form.element.component';
import { QuillEditorComponent } from 'ngx-quill';
import { TranslateService } from '@ngx-translate/core';

/*Set Quill configuration in one place: TextToolComponent*/

@Component({
  selector: 'app-fb-quill-editor',
  templateUrl: './fb-quill-editor.component.html',
  styleUrls: ['../base.form.scss']
})
export class FbQuillEditorComponent extends BaseFormElementComponent implements OnChanges, AfterViewInit {
  @Input() public color: string;
  @Input() public fontFamily: string;
  @Input() public inputBackground: string;
  @Input() public fontSize: number;
  @ViewChild('divElement') private container: ElementRef;

  public tab: number = 1;
  @Output() public cursorPositionOnFocus: EventEmitter<number> = new EventEmitter<number>();
  public editor: any;

  @ViewChild(QuillEditorComponent) public quillEditor: QuillEditorComponent | any;

  public modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'header': 1 }, { 'header': 2 }, { 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      ['blockquote', { 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link', 'clean'],
    ]
  };
  constructor(
    private renderer: Renderer2,
    public translate: TranslateService
  ) {
    super(translate);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.container && changes.inputBackground && changes.inputBackground.currentValue) {
      this.setColor();
    }
    super.ngOnChanges(changes);
  }

  public ngAfterViewInit(): void {
    this.setColor();
  }

  public onFocusEditor(event): void {
    this.cursorPositionOnFocus.emit(event.editor.getSelection().index);
  }

  public onEditorCreated(editorInstance): void {
    this.quillEditor.elementRef.nativeElement.lastChild.firstChild.innerHTML = this.formControl.value;
    this.editor = editorInstance;
  }

  public get labelStyle(): {[key: string]: string} {
    const fontSize = this.fontSize ? `${+this.fontSize * 0.75}px` : '12px';
    return {
      color: this.color || '#2C3345',
      fontFamily: this.fontFamily || 'Roboto',
      fontSize,
    };
  }

  private setColor(): void {
    const target: HTMLCollection = this.container.nativeElement.getElementsByClassName('qe-container');
    if (target && target.length) {
      for (let i = 0; i < target.length; i++) {
        this.renderer.setStyle(target[i], 'background', this.inputBackground || '#fff');
      }
    }
  }

}
