import { Injectable, Injector } from '@angular/core';
import { BaseModuleGuard } from './base.module.guard';
import { CLIENT_ADMIN, CONTENT_CREATOR, DONOR_MANAGER, FUNDRAISING_MANAGER } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ClientsModuleGuard extends BaseModuleGuard {
  constructor(protected injector: Injector) {
    super(injector);
  }

  public canLoad(): boolean {
    const roles = this.authService.getIdentityClaimsRole();
    const isClientAdmin = roles?.includes(CLIENT_ADMIN);
    const isFundraisingManager = roles?.includes(FUNDRAISING_MANAGER);
    const isContentCreator = roles?.includes(CONTENT_CREATOR);
    const isDonorManager = roles?.includes(DONOR_MANAGER);
    if (isClientAdmin || isFundraisingManager || isContentCreator || isDonorManager) {
      return true;
    } else {
      this.router.navigateByUrl('/');
      return false;
    }
  }
}
