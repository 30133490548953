import { Component } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Observable, Subject } from 'rxjs';
import {UtilsComponent} from '../utils.component';

@Component({
  selector: 'app-base-paging',
  template: ''
})
export class BasePagingComponent {
  public total: number = 0;
  public currentPage: number = 1;
  public entriesPerPage: number = 10;

  private setDefaultPage: Subject<number> = new Subject<number>();
  public setDefaultPage$: Observable<number> = this.setDefaultPage.asObservable();

  public getFirstItemOnPage(): number {
    return UtilsComponent.getFirstItemOnPage(this.total, this.currentPage, this.entriesPerPage)
  }

  public nextPage(page: number, onNextPage: () => void): void {
    this.currentPage = page;
    onNextPage();
  }

  public entriesPerPageChanged(amount: number, onSettingEntriesPerPage: () => void): void {
    this.entriesPerPage = amount;
    onSettingEntriesPerPage();
  }

  public onFilterChanged(): void {
    this.currentPage = 1;
    this.setDefaultPage.next(1);
  }

  public getArrQueryVal(val: string[]): string {
    return val.map((item: string) => `"${item}"`).join(',');
  }

  public mouseOver(tooltip: MatTooltip) {
    tooltip.disabled = false;
    tooltip.show();
  }

  public toggleTooltip(tooltip: MatTooltip) {
    if (tooltip.disabled) {
      tooltip.disabled = false;
      tooltip.show();
    } else {
      tooltip.hide();
      tooltip.disabled = true;
    }
  }

  public mouseLeave(tooltip: MatTooltip) {
    tooltip.hide();
    tooltip.disabled = true;
  }


}
