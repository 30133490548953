import { Component, Inject, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import TagModel from 'src/app/models/templates/tag.model';

@Component({
  selector: 'app-sign-up-preview',
  templateUrl: './sign-up-preview.component.html',
  styleUrls: ['./sign-up-preview.component.scss']
})
export class SignUpPreviewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() settings: any;
  customText: SafeHtml;
  private subscription: Subscription = new Subscription();
  public disableSubmit: boolean = false;
  public successMessage: boolean = false;
  public imageURL: string;
  
  public donorForm: FormGroup = this.formBuilder.group({
    firstName: [''],
    lastName: [''],
    email: [''],
    phone: [''],
    mailingZipCode: [''],
    mailingCountry: [''],
    mailingAddress: [''],
    mailingState: [''],
    mailingCity: [''],
    tag: [''],
  });
  
  constructor(
    private formBuilder: FormBuilder,
    @Inject(DOCUMENT) private document: Document,
    private sanitizer: DomSanitizer
  ) {}
  
  // Responds to changes in @Input() properties
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.settings && changes.settings.currentValue) {
      // Update customText when settings.customText changes
      this.customText = this.sanitizer.bypassSecurityTrustHtml(this.settings.customText);
    }
  }

  public ngOnInit(): void {
    // Initialize with the current value of customText
    if (this.settings && this.settings.customText) {
      this.customText = this.sanitizer.bypassSecurityTrustHtml(this.settings.customText);
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
