<div class="contributions-table-container">
  <table mat-table [dataSource]="tableSource" *ngIf="tableSource && tableDataColumns.length" #tableRef>
    <ng-container [matColumnDef]="column" *ngFor="let column of tableDataColumns">
      <th mat-header-cell *matHeaderCellDef class="table-header">
        <div class="table-header-title-wrapper">
          <span class="title">{{getColumnTitle(column) | translate}}</span>
          <span class="column-filter" (click)="sortTable(column)" *ngIf="isFiltersVisible && (column === 'publicID' || column === 'donationRealDate' || column === 'donationAmmount')">
            <i class="fas fa-sort-alpha-down sort-icon" *ngIf="checkColumn(column) && getSortOrder(column) === SortOrder.Ascending"></i>
            <i class="fas fa-sort-alpha-down-alt sort-icon" *ngIf="checkColumn(column) && getSortOrder(column) === SortOrder.Descending"></i>
            <i class="fas fa-sort action-icon" *ngIf="!checkColumn(column)"></i>
          </span>
          <span *ngIf="isFiltersVisible && withMultiSelectFilter(column)" class="column-filter">
            <app-multiselect
              [columnFilterOptions]="getSelectOptions(column)"
              (selectedOptions)="selectedOptions($event)"
              [currentSelection]="getInitialSelection(column)"
            >
              <i class="fas fa-filter action-icon" [class.active-filter]="isActiveFilter(column)"></i>
            </app-multiselect>
          </span>

          <span *ngIf="isFiltersVisible && withFilter(column)" class="column-filter">
             <app-lazy-select
               [items]="distinctData[column].items"
               [columnName]="column"
               [totalItems]="distinctData[column].total"
               (selectedValue)="onLazySelectFilterChanged($event, column)"
               (getOptions)="getOptions($event, column)"
             >
               <i class="fas fa-filter action-icon" [class.active-filter]="isActiveFilter(column)"></i>
             </app-lazy-select>
          </span>
        </div>
      </th>

      <td mat-cell *matCellDef="let element" class="widget-table-cell" [appTooltip]="tooltip">
        <!-- Tooltip -->
        <div matTooltip="{{getTextNode(element, column)}}" #tooltip="matTooltip" matTooltipPosition="above" matTooltipClass="table-tooltip"></div>

        <!-- Status -->
        <div class="cell-data" *ngIf="column === 'donationStatus'">
          <div [ngClass]="getStatusCssClass(element)"></div>
          <span [class.status]="column === 'donationStatus'">{{getTextNode(element, column)}}</span>
        </div>

        <!-- ID -->
        <div class="cell-data" *ngIf="column === 'publicID'">
          <a *ngIf="!isDonorPortal; else withoutContributionLinkTemplate" class="edit-link" [routerLink]="'/clients/contributions/' + element.id" >{{getTextNode(element, column)}}</a>
          <ng-template #withoutContributionLinkTemplate>
            <span>{{getTextNode(element, column)}}</span>
          </ng-template>
        </div>

        <div class="cell-data" *ngIf="column === 'countryId'">
          {{getTextNode(element, column)}}
        </div>

        <!-- Name -->
        <div class="cell-data" *ngIf="column === 'donorFullName'">
          <a *ngIf="rolesPermission && element.donorID; else withoutDonorLinkTemplate" [routerLink]="'/clients/donors/' + element.donorID" class="edit-link">{{getTextNode(element, column)}}</a>
          <ng-template #withoutDonorLinkTemplate>
            <span>{{getTextNode(element, column)}}</span>
          </ng-template>
        </div>

        <!-- Amount -->
        <div class="cell-data" *ngIf="column === 'donationAmmount'">
          <span
            *ngIf="element.reccuringId; else staticRecurringTemplate"
            class="recurring-link"
            (click)="recurringSettingsEdit(element.reccuringId)"
          >
            $ {{element[column] | convertAmount}} <i *ngIf="element.donationType !== 3" class="fas fa-clock" matTooltip="Recurring payment" matTooltipClass="table-tooltip"></i>
            <i *ngIf="element.donationType === 3" class="fas fa-clock"  style="color: #1665d8;" matTooltip="Pledge payment" matTooltipClass="table-tooltip"></i>
          </span>
          <ng-template #staticRecurringTemplate>
            <ng-container *ngIf="checkDonationType(element) && !element.reccuringId; else simpleAmountTemplate">
              <span *ngIf="element.donationType === 2" class="recurring">
                $ {{element[column] | convertAmount}} <i class="fas fa-clock" matTooltip="Recurring payment" matTooltipClass="table-tooltip"></i>
              </span>
              <span *ngIf="element.donationType === 3" class="pledge">
                $ {{element[column] | convertAmount}} <i class="fas fa-clock" matTooltip="Pledge payment" matTooltipClass="table-tooltip"></i>
              </span>
            </ng-container>
          </ng-template>
          <ng-template #simpleAmountTemplate>
            <span>$ {{element[column] | convertAmount}}</span>
          </ng-template>
        </div>

        <div *ngIf="column === 'fundraiserName'" class="cell-data">
          <span>{{getFundraiserName(element['fundraiserID'])}}</span>
        </div>

        <!-- Rest columns -->
        <span *ngIf="column !== 'publicID' && column !== 'donationStatus' && column !== 'donorFullName' && column !== 'donationAmmount' && column !== 'countryId' && column !== 'fundraiserName'">{{getTextNode(element, column)}}</span>
      </td>

    </ng-container>
    <ng-container matColumnDef="settings" *ngIf="isSettingsVisible">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button class="settings-container" mat-icon-button [matMenuTriggerFor]="settingsMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #settingsMenu overlapTrigger xPosition="before">
          <div class="table-action-menu">
            <div class="menu-items">
              <button mat-menu-item (click)="onEditLinkClick(element.id)">
                <span *ngIf="!element.isDeleted">{{element.origin === 'Online' ? ('See details' | translate) : ('Edit' | translate)}}</span>
                <span *ngIf="element.isDeleted">{{'History' | translate}}</span>
              </button>
              <button mat-menu-item (click)="onDeleteLinkClick(element.id)" *ngIf="!element.isDeleted && element.origin === 'Offline'">{{'Delete' | translate}}</button>
              <button mat-menu-item class="menu-close">
                <i class="fas fa-times-circle"></i>
              </button>
            </div>
          </div>
        </mat-menu>

      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>
  </table>
</div>
