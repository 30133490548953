import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilderStateService } from '../../form-builder.state.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { filter, first, map, startWith, tap } from 'rxjs/operators';
import { UtilsComponent } from '../../../utils.component';

@Component({
  selector: 'app-publish-tab-sm',
  templateUrl: './publish-tab-sm.component.html',
  styleUrls: ['../publish-tab/publish-tab.component.scss']
})
export class PublishTabSmComponent implements OnInit, OnChanges {
  @Input() public fbss: FormBuilderStateService;
  @Input() public socialMediaPostForm: FormGroup = null;
  @Input() public postFriendlyUrl$: BehaviorSubject<string>;

  public currentUrl$: Observable<string>;
  public paymentLink$: Observable<string>;
  public embedCode$: Observable<string>;

  public url: string = location.origin;
  public customUrlForm: FormGroup = this.formBuilder.group({
    url: ''
  });

  constructor(
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
  ) { }

  public ngOnInit(): void {
    this.paymentLink$ = this.postFriendlyUrl$.asObservable()
      .pipe(
        filter(value => !!value),
        map(friendlyUrl => `${this.url}/payments/social/${friendlyUrl}`)
      );

    this.embedCode$ = this.postFriendlyUrl$.asObservable()
      .pipe(
        filter(value => !!value),
        map(friendlyUrl => `<iframe src="${this.url}/embed/payments/social/${friendlyUrl}" width="600" height="600" frameborder="0"></iframe>`)
      );

    this.postFriendlyUrl$.asObservable()
      .pipe(
        filter(value => !!value),
        first(),
        tap(() => {
          this.currentUrl$ = this.postFriendlyUrl$.asObservable()
            .pipe(
              tap(value => this.customUrlForm.get('url').setValue(value)),
              map(value => `${this.url}/payments/social/${value}`)
            );
        }),
      )
      .subscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.socialMediaPostForm && changes.socialMediaPostForm.currentValue && !changes.socialMediaPostForm.previousValue) {
      this.currentUrl$ = this.socialMediaPostForm.get('name').valueChanges
        .pipe(
          startWith(this.socialMediaPostForm.get('name').value as string),
          map(value => UtilsComponent.replaceSpecialCharactersWithUnderscore(value)),
          tap(value => this.customUrlForm.get('url').setValue(value)),
          map(value => `${this.url}/payments/social/${value}`)
        );
    }
  }

  public copyContent(link, message): void {
    const copyBox = document.createElement('textarea');
    copyBox.style.position = 'fixed';
    copyBox.style.left = '0';
    copyBox.style.top = '0';
    copyBox.style.opacity = '0';
    copyBox.value = link;
    document.body.appendChild(copyBox);
    copyBox.focus();
    copyBox.select();
    document.execCommand('copy');
    copyBox.remove();
    this.toastrService.success(message, 'Notification');
  }
}
