import { BaseModelService } from '../base.model.service';
import { CampaignModel } from 'src/app/models/campaigns/campaign.model';
import { flatMap, map, mergeMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Paging } from 'src/app/models/paging/paging.model';
import { PagingHelper } from '../helper/paging.helper';
import HtmlConvertedModel from 'src/app/models/templates/html.converted.model';
import { CampaignUpdateStatusModel } from 'src/app/models/campaigns/campaign-update-status.model';

@Injectable({
  providedIn: 'root'
})
export class CampaignService extends BaseModelService<CampaignModel> {
  protected get controllerName(): string {
    return 'campaign';
  }

  constructor(protected injector: Injector) {
    super(injector);
  }

  public getModelList(filter?: Paging): Observable<CampaignModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http
          .get<CampaignModel[]>(`${config.apiURL}/${this.controllerName}`, {
            params
          })
          .pipe(
            map(x => {
              return x.map(campaign => {
                const campaignSchedularModel = campaign.campaignSchedularModel;
                return campaignSchedularModel
                  ? {
                    ...campaign,
                    startDate: campaignSchedularModel.jobDate,
                    endDate: campaignSchedularModel.endDate
                  }
                  : campaign;
              });
            })
          );
      })
    );
  }

    // for checking segment status
    public getUIModelList(filter?: Paging): Observable<CampaignModel[]> {
      const params: HttpParams = PagingHelper.getPagingParams(filter);
      return this.configService.loadConfig().pipe(
        flatMap((config) => {
          return this.http.get<CampaignModel[]>(
            `${config.apiURL}/${this.controllerName}/GetUIModelList`,
            {
              params
            }
          );
        })
      );
    }

  public activateCampaign(model: CampaignModel): Observable<CampaignModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/campaignActivate`;
        return this.http.put<CampaignModel>(url, model);
      })
    );
  }

  public getModel(id): Observable<CampaignModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http
          .get<CampaignModel>(`${config.apiURL}/${this.controllerName}/${id}`, {
            params: new HttpParams().set('includeDependencies', 'true')
          })
          .pipe(
            map(campaign => {
              const campaignSchedularModel = campaign.campaignSchedularModel;
              return campaignSchedularModel
                ? {
                  ...campaign,
                  startDate: campaignSchedularModel.jobDate,
                  endDate: campaignSchedularModel.endDate
                }
                : campaign;
            })
          );
      })
    );
  }

  public sendTestEmails(campaignId: string, emails: string[]): Observable<any> {
    let params: HttpParams = new HttpParams();
    emails.forEach(item => {
      params = params.append('emails', item);
    });
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.post<any>(`${config.apiURL}/${this.controllerName}/TestEmails/${campaignId}`, null, { params });
      }));
  }

  public printCampaign(filters: Paging, recipientId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filters);
    params = params.append("recipientId", recipientId);
    params = params.append("email", email);
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.post(`${config.apiURL}/${this.controllerName}/CampaignPrintAsync`, null, {params, responseType: 'blob'});
      })
    )
  }

  public printLetterLabels(filters: Paging, recipientId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filters);
    params = params.append("recipientId", recipientId);
    params = params.append("email", email);
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.post(`${config.apiURL}/${this.controllerName}/LabelPrintAsync`, null, {params, responseType: 'blob'});
      })
    )
  }

  public reprintLetters(filters: Paging, recipientId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filters);
    params = params.append("recipientId", recipientId);
    params = params.append("email", email);

    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.post(`${config.apiURL}/${this.controllerName}/ReprintCampaignAsync`, null, {params, responseType: 'blob'});
      })
    )
  }

  public reprintLetterLabels(filters: Paging, recipientId: string, email: string): Observable<Blob> {
    let params: HttpParams = PagingHelper.getPagingParams(filters);
    params = params.append("recipientId", recipientId);
    params = params.append("email", email);

    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.post(`${config.apiURL}/${this.controllerName}/ReprintLabelAsync`, null, {params, responseType: 'blob'});
      })
    )
  }

  public letterPreview(htmlConverted: HtmlConvertedModel): Observable<Blob> {
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.post(`${config.apiURL}/${this.controllerName}/LetterPreview`, htmlConverted, { responseType: 'blob'});
      })
    )
  }

  public updateStatus(updateStatusModel: CampaignUpdateStatusModel) : Observable<CampaignUpdateStatusModel> {
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.put<CampaignUpdateStatusModel>(`${config.apiURL}/${this.controllerName}/UpdateStatus`, updateStatusModel);
      })
    )
  }
}
