import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { EventRegistrationFormComponent } from "../shared/components/event-registration-form/event-registration-form.component";

@Injectable({
    providedIn: 'root'
})
export class EventRegistrationGuard implements CanDeactivate<EventRegistrationFormComponent> {

    private discard$: Subject<boolean> = new Subject<boolean>();

    public stay(): void {
        this.discard$.next(false);
    } 

    public discard(): void {
        this.discard$.next(true);
    }

    canDeactivate(component: EventRegistrationFormComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if(component.areFormsDirty()) {
            component.openCancelDialog();  
            return this.discard$.asObservable();
        }
              
        return true;
    }

}