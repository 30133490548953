import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {
  @Input() public formGroup: FormGroup;
  @Input() public isReadOnly: boolean;
  @Input() public name: string;
  @Input() public title: string;
  @Input() public label: string;
  @Output() public valueChanged: EventEmitter<any> = new EventEmitter<any>()
  public id: string = Math.random().toString();

  constructor() { }

  ngOnInit() {
  }
  public get labelText():string {
    return this.label ? this.label : this.title
  }

  public get formControl(): FormControl {
    return this.formGroup.get(this.name) as FormControl;
  }
}