<div class="widget-wrapper">
  <div class="widget-header">
    <span class="widget-title">{{'DASH.Recent Donations' | translate}}</span>

    <div class="widget-header-actions">
      <app-button label="VIEW ALL" (clickEvent)="viewAll()" type="flat" color="#0064be"></app-button>
    </div>
  </div>

  <div class="widget-content">
    <app-contributions-table
      isFiltersVisible="true"
      [filterSortConfig]="filterSortConfig"
      [columnsFilterOptions]="columnsFilterOptions"
      isSettingsVisible="true"
      (editRow)="editRow($event)"
      (filterChanged)="onCurrentFilterChanged($event)"
      (deleteRow)="deleteRow($event)"
      [data]="eventDonations"
      [tableDataColumns]="tableColumns"
      (sortChanged)="onSortChanged($event)"
      (getFilterOptions)="getOptions($event)"
      [distinctData]="distinctData"
      [allocatedFunds]="allocatedFunds"
    ></app-contributions-table>
  </div>
  <app-paginator
    [totalItems]="total"
    (nextPage)="getNextPage($event)"
    [setDefaultPage]="setDefaultPage$"
    (entriesPerPageChanged)="setEntriesPerPage($event)"
  ></app-paginator>

  <div *ngIf="showSpinner" class="spinner">
    <app-spinner></app-spinner>
  </div>

</div>
