import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-list-icon',
  templateUrl: './list.component.html',
})
export class ListComponent {
  @Input() public color: string = '#0064BE';

  public get getColor(): string {
    return this.color;
  }
}
