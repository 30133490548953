import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { BORDER_STYLE, WIDTH_IN_PERCENTAGE } from '../../../../constants';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { TemplateType } from '../../../../models/templates/template.type';

@Component({
  selector: 'app-divider-tool-settings',
  templateUrl: './divider-tool-settings.component.html',
  styleUrls: ['./divider-tool-settings.component.scss', '../tool-settings.scss']
})
export class DividerToolSettingsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() public tms: TemplateManagementService;

  public dividerForm: FormGroup = this.formBuilder.group({
    borderColor: '#000000',
    borderStyle: 'solid',
    borderWidth: ['4', [Validators.min(0)]],
    width: '100%',
    containerBackgroundColor: '',
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]],
  });

  public borderStyleOptions: FormElementDataModel[] = BORDER_STYLE;
  public widthOptions: FormElementDataModel[] = WIDTH_IN_PERCENTAGE;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.setInitialValues();
    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (!element || element.tagName !== MjmlTag.divider) {
          return;
        }
        this.setInitialValues();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setInitialValues(): void {
    const attributes = this.tms.activeElement.attributes;

    const borderColor = attributes['border-color'] || '#000000';
    const borderStyle = attributes['border-style'] || 'solid';
    const borderWidth = attributes['border-width'] || '4px';
    const width = attributes['width'] || '100%';
    const containerBackgroundColor = attributes['container-background-color'] || '';
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';

    this.dividerForm.get('borderColor').setValue(borderColor);
    this.dividerForm.get('borderStyle').setValue(borderStyle);
    this.dividerForm.get('borderWidth').setValue(borderWidth.slice(0, -2));
    this.dividerForm.get('width').setValue(width);
    this.dividerForm.get('containerBackgroundColor').setValue(containerBackgroundColor);
    this.dividerForm.get('paddingBottom').setValue(paddingBottom.slice(0, -2));
    this.dividerForm.get('paddingLeft').setValue(paddingLeft.slice(0, -2));
    this.dividerForm.get('paddingRight').setValue(paddingRight.slice(0, -2));
    this.dividerForm.get('paddingTop').setValue(paddingTop.slice(0, -2));
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public onBorderWidthChanged(): void {
    const value = this.dividerForm.get('borderWidth').value;
    this.tms.activeElement.attributes['border-width'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onBorderColorChanged(): void {
    const value = this.dividerForm.get('borderColor').value;
    this.tms.activeElement.attributes['border-color'] = value ? value : '#000000';
    this.tms.emitSave(true);
  }

  public onBorderStyleChanged(): void {
    const value = this.dividerForm.get('borderStyle').value;
    this.tms.activeElement.attributes['border-style'] = value ? value : 'solid';
    this.tms.emitSave(true);
  }

  public onWidthChanged(): void {
    const value = this.dividerForm.get('width').value;
    this.tms.activeElement.attributes['width'] = value ? value : '100%';
    this.tms.emitSave(true);
  }

  public onContainerBackgroundColorChanged(): void {
    const value = this.dividerForm.get('containerBackgroundColor').value;
    this.tms.activeElement.attributes['container-background-color'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onPaddingTopChanged(): void {
    const value = this.dividerForm.get('paddingTop').value;
    this.tms.activeElement.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.dividerForm.get('paddingRight').value;
    this.tms.activeElement.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.dividerForm.get('paddingBottom').value;
    this.tms.activeElement.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.dividerForm.get('paddingLeft').value;
    this.tms.activeElement.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

}
