<div class="report-wrapper">
  <app-report-header
    title="REPORTS.DONOR MANAGEMENT REPORTS"
    (clientIdChanged)="clientChanged()"
  >
  </app-report-header>
  <div class="report-body">
    <span class="report-body-title">{{'REPORTS.' + pageTitle | translate | uppercase}}</span>
    <div style="display: flex;justify-content: space-between;">
      <div class="report-body-filters">
        <!-- Start Date Input-Datepicker -->
        <div class="max-length-215">
          <app-date-picker
            [formGroup]="filterForm"
            name="startDate"
            label="Start Date"
            errorMassage="Start Date"
            placeholder="MM / DD / YYYY"
            (timeValueChanged)="onTopFilterChanged()"
          ></app-date-picker>
        </div>

        <!-- End Date Input-Datepicker -->
        <div class="max-length-215">
          <app-date-picker
            [formGroup]="filterForm"
            name="endDate"
            label="End Date"
            errorMassage="End Date"
            placeholder="MM / DD / YYYY"
            (timeValueChanged)="onTopFilterChanged()"
          ></app-date-picker>
        </div>
        <div class="max-length-350">
          <app-select
            [formGroup]="filterForm"
            name="event"
            label="Event"
            errorMassage="Event"
            [multiSelectOptions]="eventOptions"
            (valueChanged)="onEventOptionsChanged($event.value)"
          ></app-select>
        </div>
      </div>
      <div style="display:flex;align-items:flex-end;">
        <app-button label="Export" (clickEvent)="exportReport()" type="flat" color="#0064be"></app-button>
      </div>
    </div>
    <span class="report-body-total">{{'REPORTS.TOTAL' | translate }} - {{total$ | async}}</span>
    <div class="report-body-table">
      <div class="table-wrapper">
        <app-reports-table
          [data]="data$ | async"
          [tableDataColumns]="tableColumns"
          [filterSortConfig]="filterSortConfig"
          [columnsFilterOptions]="columnsFilterOptions$ | async"
          (filterChanged)="onCurrentFilterChanged($event)"
        ></app-reports-table>
      </div>
      <app-paginator
        [totalItems]="total"
        (nextPage)="getNextPage($event)"
        [setDefaultPage]="setDefaultPage$"
        (entriesPerPageChanged)="setEntriesPerPage($event)"
      ></app-paginator>
    </div>
  </div>
</div>
