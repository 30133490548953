import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { EventLayoutControlModel } from 'src/app/models/event/event-layout.control.model';
import { EventLayoutModel } from 'src/app/models/event/event-layout.model';
import { EventLayoutModuleModel } from 'src/app/models/event/event-layout.module.model';
import { EventLayoutService } from 'src/app/services/siteLayout/event-layout.service';
import { EventService } from 'src/app/services/events/event.service';
import { EventTemplateType } from 'src/app/models/enum/event-template.type';
import { FilterType } from 'src/app/models/enum/filter.type';
import { LookupService } from 'src/app/services/lookup/lookup.service';
import { Paging } from 'src/app/models/paging/paging.model';
import { StateModel } from 'src/app/models/state.model';
import { TemplateModuleType } from 'src/app/models/enum/template.module.type';
import { TimeZoneModel } from 'src/app/models/time-zone.model';
import EventModel from 'src/app/models/event/event.model';
import { MatDialog } from '@angular/material/dialog';
import { unwatchFile } from 'fs';
import { ParticipantCheckComponent } from 'src/app/shared/components/participant-check/participant-check.component';
import { take } from 'rxjs/operators';
import FbTemplateModel from '../../../models/form-builder/fb.template.model';
import { UtilsComponent } from '../../../components/utils.component';
import { DEFAULT_PAYMENT_FORM } from '../../../constants';

@Component({
  selector: 'app-layout-preview',
  templateUrl: './layout-preview.component.html',
  styleUrls: ['./layout-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutPreviewComponent implements OnInit {
  private apiURL: string = localStorage.getItem('apiurl');
  private timeZones: TimeZoneModel[];
  public event: EventModel;
  public isDonateFormVisible: boolean = false;
  public isPreview: boolean;
  public model: EventLayoutModel;
  public showItineraries: boolean;
  public states: StateModel[] = [];

  public showDonationNotification: boolean = false;

  public fbTemplate: FbTemplateModel = UtilsComponent.clone(DEFAULT_PAYMENT_FORM);

  @ViewChild('paymentFormRef') private paymentFormRef: ElementRef;

  constructor(private layoutService: EventLayoutService, private activeRouter: ActivatedRoute, private eventService: EventService, private lookupService: LookupService, private router: Router, private dialog: MatDialog) { }

  private get getHeader(): EventLayoutModuleModel {
    if (this.model)
      return this.model.moduleList.find(x => x.type === TemplateModuleType.Header);
  }

  private get getMain(): EventLayoutModuleModel {
    if (this.model)
      return this.model.moduleList.find(x => x.type === TemplateModuleType.Main);
  }

  private get getAdditional(): EventLayoutModuleModel {
    if (this.model)
      return this.model.moduleList.find(x => x.type === TemplateModuleType.Details);
  }

  private get getFooter(): EventLayoutModuleModel {
    if (this.model)
      return this.model.moduleList.find(x => x.type === TemplateModuleType.Footer);
  }

  public get getHeaderBackground(): EventLayoutControlModel {
    if (this.getHeader)
      return this.getHeader.controlList.find(x => x.name === 'Background');
  }

  public get getHeaderEvent(): EventLayoutControlModel {
    if (this.getHeader)
      return this.getHeader.controlList.find(x => x.name === 'EventName');
  }

  public get getMainBackground(): EventLayoutControlModel {
    if (this.getMain) {
      return this.getMain.controlList.find(x => x.name === 'Background');
    }
  }

  public get getMainTitles(): EventLayoutControlModel {
    if (this.getMain)
      return this.getMain.controlList.find(x => x.name === 'Titles');
  }

  public get getMainDetails(): EventLayoutControlModel {
    if (this.getMain)
      return this.getMain.controlList.find(x => x.name === 'Date');
  }

  public get getDescription(): string {
    if (this.event)
      return this.event.description.replace(/\n/g, '<br>');
    return '';
  }

  public get getAdditionalDetails(): EventLayoutControlModel {
    if (this.getAdditional)
      return this.getAdditional.controlList.find(x => x.name === 'Register');
  }

  public get getAdditionalDonate(): EventLayoutControlModel {
    if (this.getAdditional)
      return this.getAdditional.controlList.find(x => x.name === 'Donate');
  }

  public get getFooterTitles(): EventLayoutControlModel {
    if (this.getFooter)
      return this.getFooter.controlList.find(x => x.name === 'Titles');
  }

  public get getFundraisingLink(): EventLayoutControlModel {
    if (this.getFooter)
      return this.getFooter.controlList.find(x => x.name === 'FundraisingLink');
  }

  public get getFooterDetails(): EventLayoutControlModel {
    if (this.getFooter)
      return this.getFooter.controlList.find(x => x.name === 'OrganizationName');
  }

  public get getFooterLink(): EventLayoutControlModel {
    if (this.getFooter)
      return this.getFooter.controlList.find(x => x.name === 'Link');
  }

  public get state(): string {
    const stateNum = Number(this.event.state);
    return (stateNum > 0 && this.states) ? this.states.find(x => x.id === Number(this.event.state)).shortName : '';
  }
  public get shortTimeZone(): string {
    if (this.event && this.timeZones && this.event.timeZoneID) {
      return this.timeZones.find(x => x.id === Number(this.event.timeZoneID)).shortName;
    }
  }

  public get imageURL(): string {
    if (this.getHeaderBackground) {
      const img = this.getHeaderBackground.image;
      if (img) {
        return `${this.apiURL}/AzureFileStorage/image/${img}`;
      }
    }
    return '/assets/images/layout-img3.png';
  }

  ngOnInit() {
  const statePaging: Paging = {
      includeDependencies: false,
      includeDeleted: false,
      filters: [ {
        field: 'CountryID',
        value: '1',
        type: FilterType.Equal
      }]
    };
    this.lookupService.getStates(statePaging).subscribe((states: StateModel[]) => {
      this.states = states;
    });
    this.lookupService.getTimeZones().subscribe(response => {
      this.timeZones = response;
    });
    const eventId = this.activeRouter.snapshot.params['eventId'];
    this.isPreview = this.router.url.toLowerCase().includes('preview');
    const eventFilter: Paging = {
      includeDependencies: true,
      includeDeleted: false,
      filters: [
        {
          field: 'type',
          value: EventTemplateType.Event.toString(),
          type: FilterType.Equal
        },
        {
          field: 'eventID',
          value: eventId,
          type: FilterType.Equal
        }
      ]
    };
    this.layoutService.getModelList(eventFilter).subscribe(response => {
      this.model = response[0];
    });

    this.eventService.getModel(eventId).subscribe(response => {
      this.event = response;
    });
  }

  public get getState(): string {
    if (this.states) {
      const state = this.states.find(x => x.id === this.event.state);
      return (state) ? state.shortName : '';
    }
  }

  public toggleItineraries(): void {
    this.showItineraries = !this.showItineraries;
  }

  public registerRedirect(): void {
    if (!this.isPreview) {
      if (this.event.isSecondaryEvent) {
        this.showParticipantCheck();
      } else {
        this.router.navigate([`/registrationEvent/${this.activeRouter.snapshot.params.eventId}`]);
      }
    }
  }

  public showParticipantCheck(): void {
    const config = {
      data: {
        title: this.activeRouter.snapshot.params.eventId
      }
    };
    const dialogRef = this.dialog.open(ParticipantCheckComponent, config);
    dialogRef.afterClosed().pipe(take(1)).subscribe(content => {
      if (content) {
        this.router.navigateByUrl(`/registrationEvent/${this.activeRouter.snapshot.params.eventId}`);
      }
    });
  }
  public back(): void {
    if (this.event) {
      if (this.isPreview) {
        this.router.navigateByUrl(`/clients/events/6/${this.event.id}`);
      } else {
        this.router.navigateByUrl(`/clients/events/dashboard/${this.event.id}`);
      }
    }
  }

  public onDonateClick(): void {
    if (this.isPreview) {
      return;
    }
    this.isDonateFormVisible = !this.isDonateFormVisible;
    if (this.isDonateFormVisible) {
      setTimeout(() => this.paymentFormRef.nativeElement.scrollIntoView({behavior: 'smooth'}), 500);
    }
    this.showDonationNotification = false;
  }

  public onDonationFinished(): void {
    this.isDonateFormVisible = !this.isDonateFormVisible;
    this.showDonationNotification = true;
  }
}
