import FormElementDataModel from 'src/app/models/form.element.data.model';

export function onSort(a: FormElementDataModel, b: FormElementDataModel) {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
}
