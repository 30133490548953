<div
  class="custom-form-component-container-wrapper"
  [formGroup]="formGroup"
  *ngIf="!isNew"
>
  <label
    class="form-field-name"
    [for]="id"
  >{{label | translate}}</label>
  <mat-form-field
    appearance="outline"
    (change)="emitChanges()"
  >
    <textarea
      matInput
      #textArea
      [formControlName]="name"
      [id]="id"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      [type]="type"
      [maxLength]="maxlengthText"
      [mask]="mask ? mask : ''"
      [value]="formControl.value ? formControl.value : ''"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      [cdkAutosizeMinRows]="minRows"
      [cdkAutosizeMaxRows]="maxRows"
      (blur)="onBlur()"
    ></textarea>
    <mat-error *ngIf="formControl.invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>
</div>


<div
  class="new-custom-form-component-container-wrapper text-area-wrapper-new"
  [formGroup]="formGroup"
  *ngIf="isNew"
>
  <label
    class="form-field-name"
    [for]="id"
  >{{label | translate}}</label>
  <mat-form-field
    appearance="outline"
    (change)="emitChanges()"
  >
    <textarea
      matInput
      #textArea
      [formControlName]="name"
      [id]="id"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      [type]="type"
      [maxLength]="maxlengthText"
      [mask]="mask ? mask : ''"
      [value]="formControl.value ? formControl.value : ''"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      [cdkAutosizeMinRows]="minRows"
      [cdkAutosizeMaxRows]="maxRows"
      (blur)="onBlur()"
    ></textarea>
    <mat-error
      *ngIf="formControl.invalid && !customError"
    >
      <app-icons
        class="icon"
        [iconType]="IconsType.CircleClose"
        [width]="13.33"
        [height]="13.33"
        [color]="'#F44336'"
      >
      </app-icons>
      <span>{{getErrorMessage()}}</span>
    </mat-error>
  </mat-form-field>
</div>
