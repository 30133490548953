<div class="app-components-table-wrapper-encapsulation">

  <table
    mat-table
    [dataSource]="tableSource"
    class="app-components-table"
    *ngIf="tableSource"
  >
    <ng-container
      [matColumnDef]="column"
      *ngFor="let column of tableDataColumns"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        class="table-header"
      >
        <div class="table-header-title-wrapper">
          <span class="title">{{getColumnTitle(column) | translate}}</span>
          <span class="column-filter" (click)="sortTable(column)">
            <i class="fas fa-sort-alpha-down sort-icon" *ngIf="checkColumn(column) && getSortOrder(column) === SortOrder.Ascending"></i>
            <i class="fas fa-sort-alpha-down-alt sort-icon" *ngIf="checkColumn(column) && getSortOrder(column) === SortOrder.Descending"></i>
            <i class="fas fa-sort action-icon" *ngIf="!checkColumn(column)"></i>
          </span>
          <span class="column-filter">
            <app-multiselect
              [columnFilterOptions]="getSelectOptions(column)"
              (selectedOptions)="selectedOptions($event)"
            >
              <i
                class="fas fa-filter action-icon"
                [class.active-filter]="isActiveFilter(column)"
              ></i>
            </app-multiselect>
          </span>
        </div>
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="app-components-table-cell"
        [appTooltip]="tooltip"
        (mouseenter)="mouseOver(tooltip)"
        (mouseleave)="mouseLeave(tooltip)"
        (click)="toggleTooltip(tooltip)"
      >
        <div
          matTooltip="{{element[column] | translate}}"
          #tooltip="matTooltip"
          matTooltipPosition="above"
          matTooltipClass="table-tooltip"
        ></div>
        <div
          class="table-cell-wrapper"
          [ngSwitch]="column"
        >
          <span *ngSwitchCase="'totalCost'">{{getTotalCost(element[column])}}</span>
          <span *ngSwitchCase="'note'">{{getTotalCost(element[column])}}</span>
          <span *ngSwitchCase="'participantType'">{{getParticipantType(element) | translate}}</span>
          <span *ngSwitchCase="'createdOn'">{{element[column] | date: 'MM/dd/yyyy'}}</span>
          <!-- <span *ngSwitchDefault>{{element[column] | titlecase}}</span> -->
          <span *ngSwitchCase="'participantEventComment'" class="notes">{{getNotes(element)?.length > 0 ? "Notes" : ""}}</span>
          <span *ngSwitchDefault>{{element[column]}}</span>
        </div>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="tableColumns; sticky: true"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: tableColumns;"
    ></tr>

  </table>

</div>
