import { UtilsComponent } from './../../utils.component';
import {
  Component,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  Input
} from '@angular/core';
import { BaseFormElementComponent } from '../base.form.element.component';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['../base.form.element.component.scss', '../new.base.form.element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectComponent extends BaseFormElementComponent
  implements OnChanges {
  @Input() multiSelectOptions: FormElementDataModel[];
  @Input() isForm: boolean = true;
  @Input() selectOrganization: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() isNoPadding: boolean = false;
  @Input() isCustomHeight: boolean = false;
  @Input() shouldSort?: boolean = true;
  @Input() isDisableOptionCentering: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() labelFontSize: string;
  @Input() inputFontSize: string;
  @Input() labelFontFamily: string;
  @Input() inputFontFamily: string;
  @Input() labelFontColor: string;
  @Input() inputFontColor: string;
  constructor(public translate: TranslateService) {
    super(translate);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.name !== 'count') {
      if (this.shouldSort === true) {
        this.selectOptions?.sort(UtilsComponent.dropdownSorting('label'))
        this.multiSelectOptions?.sort(UtilsComponent.dropdownSorting('label'))
        this.moveNaValueToFirstPosition(this.selectOptions)
        !!this.multiSelectOptions?.length && this.moveNaValueToFirstPosition(this.multiSelectOptions)
      }
    }
    super.ngOnChanges(changes);
  }

  private moveNaValueToFirstPosition(options: FormElementDataModel[]): void {
    options?.forEach((option, index, array) => {
      if (option.label === 'N/A') {
        const indexNA = array.indexOf(option);
        if(indexNA !== -1){
          array.splice(indexNA, 1)
          array.unshift(option)
        }
      }
    })
  }

  public get getValueView(): string {
    let value = '';
    this.selectOptions.forEach(select => {
      if (select.value === this.formControl.value) {
        value = select.label;
      }
    });
    return value;
  }
}
