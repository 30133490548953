import { BaseModelService } from '../base.model.service';
import { ClientDonorModel } from 'src/app/models/client/client.donor.model';
import { EventDonationModel } from 'src/app/models/event/event-donation.model';
import { EventDonationWidgetModel } from '../../models/event/event-donation-widget.model';
import { flatMap, map, mergeMap } from 'rxjs/operators';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Paging } from '../../models/paging/paging.model';
import { PagingHelper } from '../helper/paging.helper';
import { TotalRowsModel } from 'src/app/models/paging/totalrow.model';
import EventDonationSumModel from '../../models/event/event.donation.sum.model';
import { EventDonationActivityModel } from '../../models/event/event.donation.activity.model';
import { ItemizedDonationResponseModel } from 'src/app/models/reports/itemized-donation.response.model';

@Injectable({
  providedIn: 'root'
})
export class EventDonationsService extends BaseModelService<EventDonationModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'EventDonation';
  }
 public getDonationsById(id: string): Observable<EventDonationModel> {
   return this.configService.loadConfig().pipe(
     mergeMap(config => {
       return this.http.get<EventDonationModel>(
         `${config.apiURL}/${this.controllerName}/${id}`
       );
     })
   );
 }
  public getItemizedDonations(filter?: Paging): Observable<ItemizedDonationResponseModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.get<ItemizedDonationResponseModel[]>(
          `${config.apiURL}/${this.controllerName}/ItemizedDonations`,
          { params }
        );
      })
    );
  }

  public getDonationSumByReccuringId(id: string): Observable<EventDonationSumModel> {
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/DonationSum/${id}`;
        return this.http.get<EventDonationSumModel>(url);
      })
    );
  }

  public getTaxDonors(filter: Paging): Observable<ClientDonorModel[]> {
    //const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        filter.filterFields = [];
        filter.filterTypes = [];
        filter.filterValues = [];

        filter.filters.forEach(elem => {
          filter.filterFields.push(elem.field);
          filter.filterTypes.push(elem.type);
          filter.filterValues.push(elem.value);
        });

        let jsonParams = JSON.stringify(filter);

        const url = `${config.apiURL}/${this.controllerName}/Taxes`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set("Accept", "application/json");
        return this.http.post<ClientDonorModel[]>(url, jsonParams, {headers: headers});
      })
    );
  }

  public getTaxDonorsTotal(filter: Paging): Observable<number> {
    //const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      mergeMap(config => {

        filter.filterFields = [];
        filter.filterTypes = [];
        filter.filterValues = [];

        filter.filters.forEach(elem => {
          filter.filterFields.push(elem.field);
          filter.filterTypes.push(elem.type);
          filter.filterValues.push(elem.value);
        });

        let jsonParams = JSON.stringify(filter);

        const url = `${config.apiURL}/${this.controllerName}/TaxesTotal`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set("Accept", "application/json");
        return this.http.post<TotalRowsModel>(url, jsonParams, {headers: headers}).pipe(map(model => model.total));
      })
    );
  }

  public getDonationActivities(filter: Paging, eventId: string): Observable<EventDonationActivityModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/donationActivities/${eventId}`;
        return this.http.get<EventDonationActivityModel[]>(url, { params });
      })
    );
  }

  public getDonationActivitiesTotal(filter: Paging, eventId: string): Observable<number> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/donationActivities/total/${eventId}`;
        return this.http.get<TotalRowsModel>(url, { params }).pipe(map(model => model.total));
      })
    );
  }

  public getTaxDonorsDistinct(filter: Paging): Observable<any> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.get<any>(`${config.apiURL}/${this.controllerName}/TaxesDistinct`, { params });
      })
    );
  }

  /**
   * For non-authorized users
   */
  public getEventDonationActivitiesLimited(filter: Paging): Observable<EventDonationModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/limited`;
        return this.http.get<EventDonationModel[]>(url, {params});
      })
    )
  }
}
