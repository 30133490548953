import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilderStateService } from '../../form-builder.state.service';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import { AuthService } from 'src/app/services/auth.service';
import { UserRolesType } from 'src/app/models/enum/user.roles.type';
import { timeHours } from 'd3';

@Component({
  selector: 'app-settings-tab',
  templateUrl: './settings-tab.component.html',
  styleUrls: ['./settings-tab.component.scss']
})
export class SettingsTabComponent implements OnInit, OnDestroy {
  @Input() public fbss: FormBuilderStateService;
  @ViewChild('stepper', ({static: false})) private stepper: MatStepper;
  public wasEmailSettingsOpened: boolean = false;
  private subscription: Subscription = new Subscription();
  isSuperAdmin: boolean = false;
  constructor(private authService: AuthService) { }

  public ngOnInit(): void {
    const roles = this.authService.getIdentityClaimsRole();
    this.isSuperAdmin = roles.includes(UserRolesType.SUPER_ADMIN) || roles.includes(UserRolesType.AUXILIA_ADMIN);
    this.subscription.add(
      this.fbss.invalidTemplate$.subscribe(this.showErrorFields.bind(this))
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private control(controlName: string): FormControl {
    return this.fbss.templateSettingsForm.get(controlName) as FormControl;
  }

  private showErrorFields(): void {
    if (!this.stepper) {
      return;
    }
    if (
      this.control('fbTemplateName').invalid
      || this.control('fbTemplateStatus').invalid
      || this.control('disableDate').invalid
      || this.control('disableTime').invalid
      || this.control('disableTimeZone').invalid
      || this.control('messageWhenDisabled').invalid
    ) {
      this.stepper.selectedIndex = 0;
    } else if (
      this.control('notifyEmails').invalid
      || this.control('emailNotification').invalid
      || this.control('subjectEmailNotification').invalid
      || this.control('emailToDonor').invalid
      || this.control('subjectEmailToDonor').invalid
    ) {
      this.stepper.selectedIndex = 1;
    } else if (this.control('customThankYouPageUrl').invalid) {
      this.stepper.selectedIndex = 2;
    }
  }

  public get activeStep(): boolean {
    return this.stepper && this.stepper.selectedIndex === 2;
  }
  public handleSelectionChange(step) {
    if(step.selectedIndex === 1 && !this.wasEmailSettingsOpened) this.wasEmailSettingsOpened = true;
  }

}
