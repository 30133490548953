<div class="email-builder-tool-settings-wrapper">

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-video-loader
        [formGroup]="videoForm"
        name="src"
        label="Upload video file"
        [readOnly]="readOnly || isPredesignedThemedTemplate"
        maxVideoDuration="300"
        (valueChanged)="onSrcChanged()"
      ></app-video-loader>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        label="Video file path"
        [formGroup]="videoForm"
        name="src"
        [low]="true"
        errorMassage="Path"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onSrcChanged()"
      ></app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Preload video"
        [formGroup]="videoForm"
        name="preload"
        errorMassage="Preload"
        [selectOptions]="preloadOptions"
        [low]="true"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onPreloadChanged()"
      ></app-select>
    </div>

    <div class="max-width-145">
      <app-select
        label="Autoplay"
        [formGroup]="videoForm"
        name="autoplay"
        errorMassage="Autoplay"
        [selectOptions]="autoplayOptions"
        [low]="true"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onAutoplayChanged()"
      ></app-select>
    </div>
  </div>

  <div
    class="email-builder-tool-settings-row"
    *ngIf="src.value && !(readOnly || isPredesignedThemedTemplate)"
  >
    <app-button
      type="flat"
      label="Make A Video Poster"
      (clickEvent)="makePoster()"
      color="#0064be"
      width="300"
    ></app-button>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-image-loader
        [formGroup]="videoForm"
        name="poster"
        label="Upload poster for video"
        [readOnly]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onPosterChanged()"
      ></app-image-loader>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        label="Poster file path"
        [formGroup]="videoForm"
        name="poster"
        [low]="true"
        errorMassage="Path"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onPosterChanged()"
      ></app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="videoForm"
        label="Background color"
        name="containerBackgroundColor"
        [readOnly]="readOnly || isPredesignedThemedTemplate"
        (colorChanged)="onContainerBackgroundColorChanged()"
      ></app-email-color-picker>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="videoForm"
        name="paddingTop"
        label="Padding Top"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingTopChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="videoForm"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingRightChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="videoForm"
        name="paddingBottom"
        label="Padding Bottom"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingBottomChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="videoForm"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingLeftChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <app-button
      type="basic"
      label="Delete content"
      (clickEvent)="tms.removeActiveElementFromColumn()"
      color="#f44336"
      width="300"
    ></app-button>
  </div>

  <div class="email-builder-tool-settings-row" *ngIf="!(readOnly || isPredesignedThemedTemplate)">
    <app-button
      type="basic"
      label="Delete content"
      (clickEvent)="tms.removeActiveElementFromColumn()"
      color="#f44336"
      width="300"
    ></app-button>
  </div>

  <div class="padding-source-100"></div>

</div>
