<div
  [formGroup]="formGroup"
  class="custom-form-component-container-wrapper"
>
  <mat-checkbox
    [ngClass]="{ opacity: isOpacity }"
    [disabled]="disabled"
    [formControlName]="name"
    (change)="emitChanges($event)"
    [value]="formControl.value"
  >{{isAdmin ? label : (label | translate)}}</mat-checkbox>
</div>
