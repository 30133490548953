import { AzureFileModel, AzureBytesFileModel } from 'src/app/models/files/azure-file.model';
import { BaseModelService } from '../base.model.service';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AzureUploadFileService extends BaseModelService<AzureFileModel> {
  protected get controllerName(): string {
    return 'AzureFileStorage';
  }
  constructor(protected injector: Injector) {
    super(injector);
  }

  public addModelBinary(model: AzureBytesFileModel): Observable<AzureBytesFileModel> {
    let formData = new FormData();
    formData.append('file', model.file);

    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/binary?folder=${model.folder}&name=${model.name}`;
        return this.http.post<AzureBytesFileModel>(url, formData);
      })
    );
  }
}
