<div class="wrapper-admin-confirm-component p-3">
  <h1 mat-dialog-title>{{data.title | translate}}:</h1>
  <div class="d-flex justify-content-between align-items-center flex-wrap mb-3">
    <form [formGroup]="form">
      <!--<span>Role *</span>
                <mat-form-field class="w-100" appearance="outline">
                      <mat-select formControlName="selectControl" multiple>
                    <mat-option *ngFor="let role of groups" [value]="role.value">
                      {{role.viewValue}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.controls.selectControl.hasError('required')">
                    The field is <strong>required</strong>
                  </mat-error>
                </mat-form-field>-->
      <app-select [formGroup]="form" name="selectControl" [label]="data.caption" [errorMassage]="data.errorMessage" [multiSelectOptions]="data.content"></app-select>
    </form>
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button color="primary" mat-button mat-stroked-button (click)="onOKClick()">{{'Save' | translate}}</button>
    <button color="primary" mat-button mat-stroked-button [mat-dialog-close]="false" (click)="close()">{{'Cancel' | translate}}</button>
  </div>
</div>
