<div class="ticket-types-block-container">

  <div class="ticket-types-block-content">

    <ng-container *ngIf="ticketsAvailable | async; else soldOut">
      <ng-container *ngFor="let ticket of ticketPackageList; index as ticketPackageIndex">
        <div *ngIf="ticket.isActive && isTicketEndDateValid(ticket)" class="ticket-types-block-entity">
          <ng-container>
            <div class="ticket-main-container">

              <div class="ticket-info">
                <div class="ticket-name">
                  <span>{{ticket.name}}</span>
                </div>
                <div class="ticket-info-desc">
                  <span *ngIf="!isPreviewMode && ticket.inPackageCount > 1">
                    {{ticket.inPackageCount}} {{'BUILD.tickets included' | translate}}, {{ticketRemaining(ticket) }}
                    {{'BUILD.packages remaining' | translate}}
                  </span>
                  <span *ngIf="!isPreviewMode && ticket.inPackageCount <= 1">
                    {{ticketRemaining(ticket) }} {{'BUILD.tickets remaining' | translate}}
                  </span>
                </div>
                <div class="ticket-description">{{ticket.description}}</div>
              </div>
              <ng-container>
                <div *ngIf="(isTicketStartDateValidAsync(ticket) | async) && ticket.isActive && ticketRemaining(ticket) > 0"
                  class="ticket-quantity-cost-container">
                  <div class="ticket-quantity" [ngClass]="{opacity: isPreviewMode || isEditModeOne}">
                    <app-select [disabled]="isPreviewMode || isEditModeOne" [formGroup]="ticketFormGroupById(ticket.id)"
                      name="quantity" label="Quantity" (valueChanged)="ticketSelect(ticket, $event.value)"
                      [selectOptions]="ticketsOptions[ticketPackageIndex]?.ticketOptions" [shouldSort]="false"></app-select>
                  </div>

                  <div class="ticket-cost">
                    <span class="ticket-cost-label">{{'BUILD.Cost' | translate}}</span>
                    <span class="ticket-cost-ammount">{{ticket.price | currency: 'USD'}}</span>
                  </div>
                </div>
                <div *ngIf="!(isTicketStartDateValidAsync(ticket) | async)" class="ticket-not-in-sale">
                  <span class="ticket-not-in-sale-message">{{"EVENTS.Ticket Sales Will Start in" | translate}}
                    <b>
                      <app-countdown-text [earlierDate]="getCurrentServerTime()" [laterDate]="ticketStartDate(ticket)"
                        [laterDateOffset]="getEventTimeZoneOffset()"
                        (countdownStopped)="handleCountdownStopped(ticket)"></app-countdown-text>
                    </b>
                  </span>
                </div>
                <div *ngIf="ticketRemaining(ticket) === 0">
                  <span class="ticket-sold-out">{{"BUILD.Sold out" | translate}}</span>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>

  </div>
</div>


<ng-template #soldOut>
  <div class="tickets-sold-out">
    <p>{{'BUILD.Sold out' | translate}}</p>
  </div>
</ng-template>

<ng-template #anotherOptionButtonLabel>
  <div class="button-label">
    <app-plus-icon></app-plus-icon>
    <span [style.color]="'#0064be'">{{'BUILD.Another option' | translate}}</span>
  </div>
</ng-template>


