import { BaseModelService } from '../base.model.service';
import { map, mergeMap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { MerchantModel } from 'src/app/models/payments/merchant.model';
import { Observable } from 'rxjs';
import { StripeOnboardingLinkModel } from '../../models/payments/stripe.onboarding.link.model';
import { StripeIntentModel } from '../../models/payments/stripe.intent.model';

@Injectable({
  providedIn: 'root'
})
export class MerchantService extends BaseModelService<MerchantModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'Merchant';
  }

  public getModelByClientId(clientId: string): Observable<MerchantModel> {
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.get<MerchantModel>(`${config.apiURL}/${this.controllerName}/Client/${clientId}`);
      })
    );
  }

  public validateMerchantData(model: MerchantModel): Observable<MerchantModel> {
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.post<MerchantModel>(`${config.apiURL}/${this.controllerName}/ValidateMerchant`, model);
      })
    );
  }

  public stripeOnboardingLink(clientId: string): Observable<string> {
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.get<StripeOnboardingLinkModel>(`${config.apiURL}/${this.controllerName}/GetStripeOnboardingLink/${clientId}`)
          .pipe(
            map((stripeOnboardingLinkModel: StripeOnboardingLinkModel) => stripeOnboardingLinkModel ? stripeOnboardingLinkModel.url : null)
          );
      })
    );
  }

  public completeOnboardingProcess(clientId: string): Observable<MerchantModel> {
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.get<MerchantModel>(`${config.apiURL}/${this.controllerName}/CompleteOnboardingProcess/${clientId}`);
      })
    );
  }


  public stripeInitializePayment(stripeIntent: StripeIntentModel): Observable<StripeIntentModel> {
    return this.configService.loadConfig().pipe(
      mergeMap(config => {
        return this.http.post<StripeIntentModel>(`${config.apiURL}/${this.controllerName}/InitializePayment`, stripeIntent);
      })
    );
  }
}
