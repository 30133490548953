<div class="email-builder-tool-settings-wrapper">
  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-select
        label="Font Family"
        [formGroup]="form"
        name="fontFamily"
        errorMassage="Font Family"
        [selectOptions]="fontFamilyOptions"
        [low]="true"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onFontFamilyChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-slider
        [formGroup]="form"
        name="fontSize"
        label="Font Size"
        [min]="8"
        [max]="24"
        [step]="1"
        [tickInterval]="1"
        (valueChanged)="onFontSizeChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-select
        [formGroup]="form"
        name="displayCount"
        label='Display Count'
        [selectOptions]="displayCountOptions"
        (valueChanged)="onDisplayCountChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        [low]="true"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="form"
        name="paddingTop"
        label="Padding Top"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingTopChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="form"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingRightChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="form"
        name="paddingBottom"
        label="Padding Bottom"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingBottomChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="form"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingLeftChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row" *ngIf="!(readOnly || isPredesignedThemedTemplate)">
    <app-button
      type="basic"
      label="Delete content"
      (clickEvent)="tms.removeActiveElementFromColumn()"
      color="#f44336"
      width="300"
    ></app-button>
  </div>

</div>

