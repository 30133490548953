import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../../../../services/auth.service';
import {Subscription} from 'rxjs';
import {ClientIdStateService} from '../../../../../services/client.module.state/client.id.state.service';
import {Paging} from '../../../../../models/paging/paging.model';
import {SortOrder} from '../../../../../models/enum/sort.order';
import TagModel from '../../../../../models/tag/tag.model';
import {Filter} from '../../../../../models/paging/filter.model';
import {FilterType} from '../../../../../models/enum/filter.type';
import {TagStatus} from '../../../../../models/enum/tag-status.type';
import {TagType} from '../../../../../models/enum/tag.type';
import {TagService} from '../../../../../services/tag/tag.service';
import { TagCreation, TagManagerAssignTagModel } from '../../../../../models/client/client.donor.tag.manager.model';
import { DonorsTagManagerService } from '../../../donors/components/donors-tag-manager/donors-tag-manager.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-new-tag-popup',
  templateUrl: './create-new-tag-popup.component.html',
  styleUrls: ['./create-new-tag-popup.component.scss'],
  providers: [DonorsTagManagerService]
})
export class CreateNewTagPopupComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public tagArrayLength: number = 0;
  public clientID = this.clientIdStateService.selectedRelationshipClientId;
  private allListFilterConfig: Filter[] = [{
    field: 'clientID',
    value: this.clientID,
    type: FilterType.Equal
  }];
  private tagStatusFilter: Filter[] = [
    {
      field: 'status',
      value: TagStatus.Active.toString(),
      type: FilterType.Equal,
    },
    {
      field: 'type',
      value: TagType.CustomTags.toString(),
      type: FilterType.Equal,
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TagCreation,
    private authService: AuthService,
    private dialogRef: MatDialogRef<CreateNewTagPopupComponent>,
    private clientIdStateService: ClientIdStateService,
    private clientDonorTagService: TagService,
    private ndtms: DonorsTagManagerService,
    public toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
    this.getTags();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getTags(): void {
    const paging: Paging = {
      includeDependencies: true,
      sortField: 'name',
      sortOrder: SortOrder.Ascending,
      filters: [...this.allListFilterConfig, ...this.tagStatusFilter]
    };
    this.clientDonorTagService.getModelList(paging)
      .subscribe(tags => {
        this.tagArrayLength = tags.length;
      });
  }

  public addNewTagToList(tag: TagModel): void {
    const addTagModel: TagManagerAssignTagModel = {
      isAllSelected: this.data.isAllChecked,
      tagIds: [tag.id],
      clientDonorIds: [...this.data.donorSelection]
    };
    this.ndtms.assignTagsToDonors(this.data.batchId, addTagModel)
      .subscribe(
        resp => {
          if (resp) this.dialogRef.close('CUSTOMIZATION.Tag has been added successfully')
        },
        err => this.toastr.error(err.message)
      );
  }

  public cancelCreateNewTag(): void {
    this.dialogRef.close(false);
  }

  public onClose(): void {
    this.dialogRef.close(false);
  }

}
