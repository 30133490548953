<div class="wrapper-admin-confirm-component">
    <div class="close">
        <i class="fas fa-times close-icon" (click)="onClose()" matRipple></i>
    </div>

    <div class="imageWrapper" *ngIf="data.img">
      <div class="img" [ngStyle]="{background: imgStyle}"></div>
      <span class="img-title">{{isAdminPortal ? data.title : (data.title | translate)}}</span>
    </div>
  <span class="title" *ngIf="!data.img">{{isAdminPortal ? data.title : (data.title | translate)}}</span>

    <div class="actions">
        <app-button type="stroked" label="{{(data?.firstButtonName || ('Yes'))}}" color="#0064be" (click)="onYesClick()"
            width="{{data?.widthButton || '100'}}">
        </app-button>

        <app-button *ngIf="data?.middleButtonName" type="stroked" label="{{data?.middleButtonName | translate}}" color="#0064be" (clickEvent)="onCancel()"
            width="{{data?.widthButton || '100'}}"></app-button>

        <app-button type="stroked" label="{{(data?.secondButtonName || ('No'))}}" color="#a9110e" (click)="onClose()"
            width="{{data?.widthButton || '100'}}"></app-button>
    </div>
  <br *ngIf="data.noActions">
</div>
