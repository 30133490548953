import {ActivatedRoute, Router, UrlSegment} from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import {AzureFileModel} from '../../../models/files/azure-file.model';
import {BehaviorSubject, forkJoin, merge, Observable, of, Subject, Subscription, zip} from 'rxjs';
import {ClientService} from 'src/app/services/client/client.service';
import {ADD_ON_TYPE_OPTIONS, DEFAULT_PAYMENT_FORM, MS_PER_DAY} from '../../../constants';
import {CodeType} from '../../../models/internationalization/code.type';
import {EventLayoutControlModel} from 'src/app/models/event/event-layout.control.model';
import {EventLayoutModel} from 'src/app/models/event/event-layout.model';
import {EventLayoutModuleModel} from 'src/app/models/event/event-layout.module.model';
import {EventLayoutService} from 'src/app/services/siteLayout/event-layout.service';
import {EventRegistrationRequiredFieldsType} from '../../../models/enum/event.registration.required.fields.type';
import {EventService} from 'src/app/services/events/event.service';
import {EventStatus} from 'src/app/models/enum/event.status';
import {EventTemplateType} from 'src/app/models/enum/event-template.type';
import {EventTicketService} from 'src/app/services/events/event-ticket.service';
import {exhaustMap, filter, first, map, switchMap, tap} from 'rxjs/operators';
import {FilterType} from 'src/app/models/enum/filter.type';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {FormValidators} from '../../validatiors/form-validators';
import {GoogleEventService} from 'src/app/services/externalServices/google/google-event.service';
import {LocalityType} from '../../../models/internationalization/locality.type';
import {MjmlApiService} from '../../../services/templates/mjml.api.service';
import {MjmlTag} from '../../../models/templates/mjml.tag.type';
import {OutlookEventService} from 'src/app/services/externalServices/outlook/outlook-event.service';
import {Paging} from 'src/app/models/paging/paging.model';
import {PoliticalUnitType} from '../../../models/internationalization/political.unit.type';
import {PriceTicket} from 'src/app/models/enum/event.price.ticket';
import {SecondaryEventParticipantService} from 'src/app/services/events/event-secondary-participant.service';
import {StateModel} from 'src/app/models/state.model';
import {TemplateModuleType} from 'src/app/models/enum/template.module.type';
import {TerritorialEntityType} from '../../../models/internationalization/territorial.entity.type';
import {TimeZoneModel} from 'src/app/models/time-zone.model';
import {ToastrService} from 'ngx-toastr';
import {UtilsComponent} from '../../../components/utils.component';
import CountryModel from '../../../models/internationalization/country.model';
import EventModel from 'src/app/models/event/event.model';
import EventRegistrationModel, {
  AllEventTicketAddonModel,
  EventRegistrationAdditionalParticipantModel,
  EventRegistrationInfoModel,
  EventRegistrationTickets,
} from 'src/app/models/event/event-registration.model';
import FbTemplateModel, {FbElementModel, FbElementType} from '../../../models/form-builder/fb.template.model';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import MjmlElementModel from '../../../models/templates/mjml.element.model';
import {LookupStoreService} from '../../../services/lookup/lookup-store.service';
import TicketPackageModel from '../../../models/event/ticket.package.model';
import {CouponValue, EventCouponModel} from '../../../models/event/event-coupon.model';
import {TicketRegistrationModel} from '../../../models/event/ticket.registration.model';
import {EventParticipantService} from '../../../services/events/event-participant.service';
import EventParticipantModel from '../../../models/event/event.participant.model';
import {FreeAddonModel} from '../../../models/event/free-addon.model';
import {MatDialog} from '@angular/material/dialog';
import {Filter} from '../../../models/paging/filter.model';
import {EventAddOnModel} from 'src/app/models/event/event.add-on.model';
import {AddOnIncludesRegistrationModel, AddOnRegistrationModel} from 'src/app/models/event/addOn.registration.model';
import {RegistrationCancelComponent} from 'src/app/components/registration-cancel/registration-cancel.component';
import {TabModel} from 'src/app/models/tab.model';
import {TranslateService} from '@ngx-translate/core';
import {EventAddOnTimesUsed} from 'src/app/models/event/event-add-on-times-used.model';
import {EventUsedAddOnsAmountService} from 'src/app/services/events/event.used-add-ons-amount.service';
import {SourceTemplateService} from 'src/app/services/templates/source.template.service';
import SourceTemplateModel from 'src/app/models/templates/source.template.model';
import {SortOrder} from 'src/app/models/enum/sort.order';
import {DonationPaymentPageType} from 'src/app/models/form-builder/donation.payment.page.type';
import {DatePipe} from '@angular/common';
import {ClientModel} from "../../../models/client/client.model";
import {DonationStatusType} from 'src/app/models/enum/donation.status.type';
import {DonationMethodType} from 'src/app/models/enum/donation.method.type';

@Component({
  selector: 'app-event-registration-form',
  templateUrl: './event-registration-form.component.html',
  styleUrls: ['./event-registration-form.component.scss'],
})
export class EventRegistrationFormComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() isPreviewMode = false;
  @Input() registrationStep: number = 1;
  @Input() eventModel: EventModel;
  @Input() model: EventLayoutModel;
  @Input() public checkCanDeactivate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true); // subject is used for canDeactivate method in ManualRegistrationComponent
  @Input() public triggerSaveOnDeactivate$: Subject<void>;
  public PriceTicket = PriceTicket;
  public sourceTemplateId: string = null;
  public guestMaxReached: boolean = false;
  private ticketErrorMessage = 'To continue, you will need to increase the quantity of tickets selected';
  public order: any[] = [];
  public tickets: FormElementDataModel[] = [];
  public microdepositLink: string = null;
  public addons: EventAddOnModel[] = [];
  public selectedTicket: number;
  public totalTickets: TicketRegistrationModel[] = [];
  public totalAddOns: AddOnRegistrationModel[] = [];
  public totalCost = 0;
  public totalCostWithoutGlobalPromo = 0;
  public states: StateModel[] = [];
  @Input() public countries: CountryModel[] = [];
  @Input() public countriesOptions: FormElementDataModel[] = [];
  @Input() public stateOptions: FormElementDataModel[] = [];
  public isRegistrationComplete = false;
  public isEmbedPage: boolean = false;
  public allTicketsOutdated: boolean = false;
  public clientLogoName: string;
  public isDonateFormVisible: boolean = false;
  public guestTicketOptions: FormElementDataModel[] = [];
  public ticketsGroup = new FormGroup({});
  public addonsGroup = new FormGroup({});
  public ticketChosenOptions: Map<string, any[]> = new Map<string, any[]>();
  public registrationForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.maxLength(250)]),
    lastName: new FormControl('', [Validators.required, Validators.maxLength(250)]),
    title: new FormControl('', [Validators.maxLength(250)]),
    email: new FormControl('', [Validators.required, FormValidators.emailValidator]),
    phone: new FormControl(''),
    country: new FormControl(1, [Validators.required]),
    territorialEntity: new FormControl('', [Validators.required]),
    ticketPackageId: new FormControl('', [Validators.required]),
    streetAddress: new FormControl('', [Validators.maxLength(250)]),
    streetAddress2: new FormControl('', [Validators.maxLength(250)]),
    id: new FormControl(''),
    city: new FormControl('', [Validators.maxLength(250)]),
    stateId: new FormControl('', [Validators.maxLength(250)]),
    zipCode: new FormControl('', [Validators.pattern('^(\\d{5}|\\d{9})$')]),
    hasGuest: new FormControl(false),
    note: new FormControl('', [Validators.maxLength(600)]),
    eventTicketAddons: new FormArray([]),
    volunteeringListIds: new FormControl([], [Validators.required]),
    isVolunteer: new FormControl(false),
  });
  public manualRegisterForm: FormGroup = this.formBuilder.group({
    registerDate: [new Date(), [Validators.required]],
    totalPayment: 0,
    numberInParty: 0,
    paymentStatus: 0,
    paymentMethod: 1,
  });
  public couponGroup: FormGroup = this.formBuilder.group({
    couponCode: '',
  })
  public locationRadioButtonData: FormElementDataModel[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  public statusOptions: FormElementDataModel[] = [
    { label: 'Authorized', value: DonationStatusType.Authorized },
    { label: 'Canceled', value: DonationStatusType.Canceled },
    { label: 'Completed', value: DonationStatusType.Completed },
    { label: 'Declined', value: DonationStatusType.Declined },
    { label: 'Deferred', value: DonationStatusType.Deferred },
    { label: 'In Progress', value: DonationStatusType.InProgress },
    { label: 'Pending', value: DonationStatusType.Pending },
    { label: 'Refunded', value: DonationStatusType.Refunded },
    { label: 'Retry', value: DonationStatusType.Retry }
  ];
  public methodOptions: FormElementDataModel[] = [
    { label: 'ACH', value: DonationMethodType.ACH},
    { label: 'Credit Card', value: DonationMethodType.CreditCard},
    { label: 'Check', value: DonationMethodType.Check},
    { label: 'Cash', value: DonationMethodType.Cash},
    { label: 'Money Order', value: DonationMethodType.MoneyOrder},
    { label: 'N/A', value: DonationMethodType['N/A']}
  ]

  public freeAddons: FreeAddonModel[];
  private navigateToOrigin$: Subject<void> = new Subject<void>()
  public ticketTypeOptions: FormElementDataModel[] = [];
  private apiURL: string = localStorage.getItem('apiurl');
  public registrationInProgress:boolean = false;
  public useDnDEmailBuilder: boolean = true;
  private subscription: Subscription = new Subscription();
  public eventRegistrationModel: EventRegistrationModel;
  private clientLogo: AzureFileModel;

  public fbTemplate: FbTemplateModel = UtilsComponent.clone(DEFAULT_PAYMENT_FORM);
  includeFee$: Observable<boolean> = new Observable<boolean>();
  public timeZones: TimeZoneModel[] = [];

  private registerDonor$: Subject<void> = new Subject<void>();
  private costChanged$: Subject<void> = new Subject<void>();
  private ticketCheck$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('ticketsRef') private ticketsRef: ElementRef;

  @Input() public countryState: BehaviorSubject<Partial<CountryModel>> = new BehaviorSubject<Partial<CountryModel>>({
    name: 'USA',
    id: 1,
    territorialEntityType: TerritorialEntityType.Unknown,
    localityType: LocalityType.City,
    politicalUnitType: PoliticalUnitType.State,
    codeType: CodeType.ZipCode,
    phoneCode: '1'
  });

  public triggerShowErrorFields: Subject<void> = new Subject<void>();
  public atLeastOneCoupon$: Observable<boolean>;
  public couponSet: Set<EventCouponModel> = new Set<EventCouponModel>();
  public isManualRegistration: boolean = false;
  public isEditMode: boolean = false;
  public participantId: string;
  public eventParticipant: EventParticipantModel;
  public isGuest: boolean = false;
  public eventRegEditModel: EventRegistrationModel;

  public mjml$: BehaviorSubject<MjmlElementModel> = new BehaviorSubject<MjmlElementModel>(null);

  public showHeaderAndFooter$: Observable<boolean>;
  public template: MjmlElementModel;

  @ViewChild('ticketsTemplate') public ticketsTemplateRef: TemplateRef<any>;
  @ViewChild('addOnsTemplate') public addOnsTemplateRef: TemplateRef<any>;
  @ViewChild('contactTemplate') public contactTemplateRef: TemplateRef<any>;
  @ViewChild('paymentTemplate') public paymentTemplateRef: TemplateRef<any>;
  public currentTab: number = 0
  public lastTab: number = 3;
  public tabList: TabModel[] = [];
  private fundraiserID: string;
  public aditionalParticipantsCount: number = 0;
  public client: ClientModel;
  public isEventSoldOut: boolean = false;
  public allTicketsInactive: boolean = false;

  public addOnsTimesUsed: EventAddOnTimesUsed[];

  public ticketChangeSubject: Subject<TicketPackageModel> = new Subject<TicketPackageModel>();

  constructor(
    private activeRouter: ActivatedRoute,
    private authService: AuthService,
    private clientModelService: ClientService,
    private eventService: EventService,
    private formBuilder: FormBuilder,
    private googleService: GoogleEventService,
    private layoutService: EventLayoutService,
    private lookupStoreService: LookupStoreService,
    private mjmlApiService: MjmlApiService,
    private outlookService: OutlookEventService,
    private router: Router,
    private dialog: MatDialog,
    private secondaryEventParticipantService: SecondaryEventParticipantService,
    private ticketService: EventTicketService,
    private toastrService: ToastrService,
    private eventParticipantService: EventParticipantService,
    private translate: TranslateService,
    private usedAddOnsAmount: EventUsedAddOnsAmountService,
    private sourceTemplateService: SourceTemplateService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe
  ) { }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.eventModel && changes.eventModel.currentValue) {
      if (this.eventModel.mjml) {
        this.setInheritSection();
      }
    }
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.costChanged$.subscribe(
       () => {
          this.recalculateTotalCost()
          if(this.isManualRegistration) {
            this.manualRegisterForm.get('totalPayment').setValue(this.totalCost);
          }
       }
      )
    )
    this.fundraiserID = this.activeRouter.snapshot.params.fundraiserId;
    this.removePromoCodeOnTicketUnselect();

    this.subscription.add(
      this.ticketsGroup.valueChanges
        .pipe(
          tap(() => {
            this.registrationForm.get('ticketPackageId').reset();
            if (this.registrationForm.controls.hasGuest.value) {
              const additionalParticipants = this.registrationForm.get('additionalParticipants') as FormArray;
              additionalParticipants?.controls.forEach(participant => {
                participant.get('ticketType').reset();
                participant.get('eventTicketAddons').reset();
              })
            }
            if (this.getEventTicketAddons?.controls?.length) {
              this.getEventTicketAddons.controls.forEach(control => {
                control.get('includeID').setValue(null);
              })
            }

          })
        )
        .subscribe()
    );
    if (this.activeRouter.snapshot.queryParams.registrationComplete) {
        this.isRegistrationComplete = true;
      }
      if (this.activeRouter.snapshot.queryParams.manualRegistration) {
        this.isManualRegistration = true;
      }
      if (this.activeRouter.snapshot.queryParams.isEditMode) {
        this.participantId = this.activeRouter.snapshot.queryParams.participantId;
        const paging: Paging = {
          includeDependencies: true,
          includeDeleted: false,
        };
      this.eventParticipantService.getEventParticipantById(this.participantId, paging)
        .pipe(
          tap((eventParticipant: EventParticipantModel) => {
            this.eventParticipant = eventParticipant;
            if(this.isManualRegistration) {
              this.manualRegisterForm.get('paymentMethod').setValue(eventParticipant.registrationPaymentMethod ?? DonationMethodType.CreditCard);
              this.manualRegisterForm.get('paymentStatus').setValue(eventParticipant.registrationPaymentStatus ?? DonationStatusType.Completed);
            }
            this.isGuest = eventParticipant.isGuest;
            if (this.isGuest) {
              this.addGuestForm();
              this.editGuestDisableForm();
              this.setGuestFormValue();
              this.lookupStoreService.countriesChanged$.next(this.eventParticipant);
            }
          }),
          //get free addons options
          switchMap(() => this.getFeeAddons()),
          tap(freeAddons => {
            if (this.isGuest) {
              this.freeAddons = freeAddons;
              this.addGuestAddons();
            }
          }),
          //if edit main guest
          switchMap(() => {
            if (this.isGuest) {
              return of(null);
            }
            return this.ticketService.getEventRegistration(this.participantId)
          }),
          tap((eventRegistration: EventRegistrationModel) => {
            if (eventRegistration) {
              this.eventRegEditModel = eventRegistration;
              this.lookupStoreService.countriesChanged$.next(this.eventRegEditModel);
              this.setEditInitialValues();
            }
          })
        )
        .subscribe();
      this.isEditMode = true;
    }
    if (!this.isPreviewMode && !this.isGuest) {
      const eventId = this.activeRouter.snapshot.params.eventId;
      this.getStatesAndCountries();
      this.getEventLayout();
      this.eventService.getModel(eventId)
        .pipe(
          switchMap((event) => {
            this.eventModel = event;
            this.setAdditionalValidators();
            this.setUseDnDEmailBuilder();
            this.initialTicketsOptions();
            this.initialAddons();
            this.setCoupons();
            this.initTabList();
            this.areTickesAvailable();
            return forkJoin([
              this.clientModelService.getClientLogo(event.clientID),
              this.fetchSourceTemplate(event),
              this.clientDataById(event.clientID)
            ]);
          }),
          tap((res: any[]) => {
            this.clientLogo = res[0]
            this.setupFbTemplate(res[1]);
            this.client = res[2]
          })
        )
        .subscribe();
    } else {
      this.registrationStep = 1;
      this.initialTicketsOptions();
      this.clientModelService.getClientLogo(this.authService.getIdentityClaimsOriginId()).subscribe(result => {
        this.clientLogoName = result && result.name;
      });
    }
    this.registerDonorFlow();
    this.ticketCheckFlow();

    if (this.isManualRegistration) {
      this.canDeactivateSubscription();
    }

    this.showHeaderAndFooter$ = this.activeRouter.url
      .pipe(
        map((urlSegments: UrlSegment[]) => !!urlSegments && !!urlSegments.length && urlSegments[0].path == 'embed'),
      );
      if (window.location.pathname.startsWith('/embed')) {
        this.isEmbedPage = true;
      }

      const eventId = this.activeRouter.snapshot.params.eventId;
      this.subscription.add(
        this.usedAddOnsAmount
          .getModel(eventId)
          .subscribe(res => this.addOnsTimesUsed = res)
      )

    this.navigateToOrigin$.asObservable()
        .pipe(
            tap(() => this.router.navigateByUrl(`/clients/events/dashboard/${this.eventModel.id}`).then(() => location.reload()))
        ).subscribe();
  }

  private fetchSourceTemplate(event: EventModel): Observable<SourceTemplateModel> {
    if(!event || event.donationPaymentPage === DonationPaymentPageType.AuxiliaDefault) return of(null);

    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: false,
      sortOrder: SortOrder.Descending,
      sortField: 'createdOn',
      filters: [{
        field: 'sourceID',
        value: event.id,
        type: FilterType.Equal,
      }],
      rows: 1,
    };

    return this.sourceTemplateService.getModelList(paging)
      .pipe(
        tap(res => this.sourceTemplateId = res[0].id),
        map((res: SourceTemplateModel[]) => res[0])
      )
  }
  private clientDataById(id: string): Observable<ClientModel> {
    return this.clientModelService.getModel(id, false);
  }

  private setIncludeFee(template: FbTemplateModel): void {
    const elementModel: FbElementModel = template.paymentPage.elements
      .find(({type}: FbElementModel) => type === FbElementType.PaymentDetails);

    const {isPassingFeeDefault, passMerchantFee} = elementModel.attributes;
    const  includeFee: boolean = passMerchantFee === "yes" || isPassingFeeDefault === "true";

    this.includeFee$ = of(includeFee);
  }

  private setupFbTemplate( sourceTemplate: SourceTemplateModel): void {
    if(!sourceTemplate) return;
    this.fbTemplate = JSON.parse(sourceTemplate.content);
    this.setIncludeFee(this.fbTemplate)
  }

  private recalculateTotalCost(): void {
    let ticketTotalDiscount: number = 0;
    if (!this.totalTickets || !this.addons) return;
    this.totalCost = 0;
    if (this.isRegistrationCost()) {
      this.totalCost = this.getRegistrationFee();
    }
    this.totalTickets.forEach(item => {
      if (item.quantity) {
        this.totalCost = this.totalCost + (item.cost - item.discount);
        ticketTotalDiscount += item.discount;
      }
    });
    this.totalAddOns.forEach(addon => {
      addon.quantity && (this.totalCost += addon.quantity * addon.price);

      if (!addon.includes || addon.includes.length === 0) return;
      addon.includes.forEach(subAddon => {
        subAddon.quantity && (this.totalCost += subAddon.quantity * subAddon.price);
      })
    })

    this.totalCostWithoutGlobalPromo = this.totalCost + ticketTotalDiscount;

    let finalCostPromoCodesDiscount: number = 0;
    this.eventModel.usedFinalCostCoupons?.forEach(coupon => {
      if (coupon.couponType === CouponValue.Dollar) {
        finalCostPromoCodesDiscount = coupon.couponValue;
      }
      if (coupon.couponType === CouponValue.Percent) {
        finalCostPromoCodesDiscount = (this.totalCost + ticketTotalDiscount + finalCostPromoCodesDiscount) * (coupon.couponValue / 100)
      }
      this.totalCost -= finalCostPromoCodesDiscount;
    })

    if (this.totalCost < 0) this.totalCost = 0
  }

  private removePromoCodeOnTicketUnselect(): void {
    this.subscription.add(
      this.ticketsGroup.valueChanges
      .pipe(
        tap(res => {
          Object.keys(res).forEach(ticketId => {
            if(!res[ticketId].quantity || res[ticketId].quantity === 0) {
              const currentTicketPackage = this.eventModel.ticketPackageList.find(ticket => ticket.id === ticketId);
              if(currentTicketPackage) {
                currentTicketPackage.useCoupon = null;
              }
            }
          })
        })
      ).subscribe()
    )
  }

  private isPossibleToRegister(): void {
    const filters: Filter[] = [
      {
        field: 'eventID',
        value: this.activeRouter.snapshot.params.eventId,
        type: FilterType.Equal,
      }
    ];
    const paging: Paging = {
      includeDependencies: true,
      includeDeleted: false,
      filters
    };
    this.eventParticipantService.getRegistrations(paging).subscribe(participant => {
      if (participant && (this.eventModel?.guestAmountMax === participant?.length) && !this.isRegistrationComplete) this.isEventSoldOut = true;
      if (participant && (this.eventModel?.guestAmountMax <= participant?.length + 1)) this.guestMaxReached = true;
    })
  }

  private areTickesAvailable(): void {
    const availableTickets: TicketPackageModel[] = this.eventModel
      .ticketPackageList
      ?.filter(ticket => ticket.soldCount + ticket.reservedCount < ticket.number);
    if(!availableTickets.length) this.isEventSoldOut = true;
    if(this.eventModel.priceTicketType === PriceTicket.Ticket) {
      if(this.eventModel.ticketPackageList.findIndex(e => e.isActive === true) == -1)
        this.allTicketsInactive = true;
      if(this.eventModel.ticketPackageList.findIndex(e => (new Date(e.endDate).getTime() + MS_PER_DAY) > (new Date(this.eventModel.currentServerTime).getTime())) == -1)
        this.allTicketsOutdated = true;
    }

  }

  private get areAddOnsAvailable(): boolean {
    return !!this.eventModel.addons.filter((addOn: EventAddOnModel) => {
      const currTimesUsed: EventAddOnTimesUsed = this.addOnsTimesUsed?.find((addOnTimesUsed: EventAddOnTimesUsed) => addOn.id === addOnTimesUsed.id);

      return (currTimesUsed?.timesUsed || 0) < addOn.quantity
    })?.length
  }

  public get getTotalCoast(): string {
    return this.totalCost.toFixed(2);
  }

  private getFeeAddons(): Observable<FreeAddonModel[]> {
    return this.ticketService.freeAddons(this.participantId)
  }

  private editGuestDisableForm(): void {
    this.registrationForm.get('firstName').disable();
    this.registrationForm.get('lastName').disable();
    this.registrationForm.get('title').disable();
    this.registrationForm.get('email').disable();
    this.registrationForm.get('country').disable();
    this.registrationForm.get('territorialEntity').disable();
    this.registrationForm.get('streetAddress').disable();
    this.registrationForm.get('streetAddress2').disable();
    this.registrationForm.get('stateId').disable();
    this.registrationForm.get('note').disable();
    this.registrationForm.get('zipCode').disable();
    this.registrationForm.get('volunteeringListIds').disable();
    this.registrationForm.get('isVolunteer').disable();
    this.zipCode.clearValidators();
    this.zipCode.updateValueAndValidity();
  }

  public get editAdditionalGuest(): FormGroup {
    return this.registrationForm.get('editAdditionalGuest') as FormGroup;
  }

  private setGuestFormValue(): void {
    this.editAdditionalGuest.get('fullName').setValue(this.eventParticipant.buyerFullName);
    this.editAdditionalGuest.get('email').setValue(this.eventParticipant.email);
    this.editAdditionalGuest.get('email').clearValidators();
    this.editAdditionalGuest.get('email').setValidators(FormValidators.emailValidator);
    this.editAdditionalGuest.get('email').updateValueAndValidity();

    this.editAdditionalGuest.get('volunteeringListIds').setValue(this.eventParticipant.volunteeringIds);
    this.ticketTypeOptions = [{ label: this.eventParticipant.ticketType, value: 1 }];
    this.manualRegisterForm.get('registerDate').setValue(new Date(this.eventParticipant.createdOn));
    this.editAdditionalGuest.get('ticketType').setValue(1);

    const countryModel: CountryModel = this.countries.find(item => item.id === this.eventParticipant.countryId);
    if(this.eventParticipant.mobile){
      const phone = this.eventParticipant.mobile.slice(countryModel.phoneCode.length);
      this.editAdditionalGuest.get('phone').setValue(phone);
    }

  }

  public ngAfterViewInit(): void {
    if (this.isPreviewMode) {
      this.setUseDnDEmailBuilder();
    }
    this.isPossibleToRegister();
  }

  public ticketFormGroupById(id: string): FormGroup {
    return this.ticketsGroup.get(id) as FormGroup;
  }

  public get getTitle(): string {
    if (this.isEditMode && this.isGuest) {
      return 'Edit Additional Registered Guest';
    }
    if (this.isEditMode && !this.isGuest) {
      return 'Edit Registered Guest';
    }
    return 'Add New Registered Guest';
  }

  public get isEditModeOne(): boolean {
    return this.isEditMode;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setEditInitialValues(): void {
    if (this.eventParticipant && !this.isGuest) {
      this.getFormControl('firstName').setValue(this.eventParticipant.firstName);
      this.getFormControl('lastName').setValue(this.eventParticipant.lastName);
      this.getFormControl('email').setValue(this.eventParticipant.email);
      this.manualRegisterForm.get('registerDate').setValue(new Date(this.eventParticipant.createdOn));
      this.getFormControl('country').setValue(this.eventParticipant.countryId);
      this.getFormControl('note').setValue(this.eventParticipant.participantEventComment);

      const countryModel: CountryModel = this.countries.find(item => item.id === this.eventParticipant.countryId);
      if(this.eventParticipant.mobile){
        const phone = this.eventParticipant.mobile.slice(countryModel.phoneCode.length);
        this.getFormControl('phone').setValue(phone);
      }

      if (this.eventRegEditModel && this.eventRegEditModel.registrationModel.volunteeringListIds.length) {
        this.getFormControl('isVolunteer').setValue(true);
        this.getFormControl('volunteeringListIds').setValue(this.eventRegEditModel.registrationModel.volunteeringListIds);
        this.getFormControl('volunteeringListIds').enable();
      }
      this.manualRegisterForm.get('numberInParty').setValue(this.eventRegEditModel.numberInParty);
      if (this.eventRegEditModel.registrationModel.city) {
        this.getFormControl('city').setValue(this.eventRegEditModel.registrationModel.city);
      }
      if (this.eventRegEditModel.registrationModel.id) {
        this.getFormControl('id').setValue(this.eventRegEditModel.registrationModel.id);
      }
      if (this.eventRegEditModel.registrationModel.stateId && this.eventRegEditModel.registrationModel.stateId !== 0) {
        this.getFormControl('stateId').setValue(+this.eventRegEditModel.registrationModel.stateId);
      }
      if (this.eventRegEditModel.registrationModel.zipCode) {
        this.getFormControl('zipCode').setValue(this.eventRegEditModel.registrationModel.zipCode);
      }
      this.getFormControl('streetAddress').setValue(this.eventRegEditModel.registrationModel.streetAddress);
      if (this.eventRegEditModel.registrationModel.streetAddress2) {
        this.getFormControl('streetAddress2').setValue(this.eventRegEditModel.registrationModel.streetAddress2);
      }
      if (this.eventRegEditModel.registrationModel.ticketPackageId) {
        this.getFormControl('ticketPackageId').setValue(this.eventRegEditModel.registrationModel.ticketPackageId);
      }
      this.setInitialTicketFromValue();
      if (this.eventRegEditModel.registrationModel.additionalParticipants.length) {
        this.registrationForm.addControl('additionalParticipants', this.formBuilder.array([]));
        this.eventRegEditModel.registrationModel.additionalParticipants.forEach(participant => {
          let participantPhone = participant.phone.replace(/\D/g, "");
          participantPhone = participantPhone.slice(countryModel.phoneCode.length);
          const editParticipantForm: FormGroup = this.formBuilder.group({
            fullName: [participant.fullName, [Validators.required]],
            email: [participant.email, [FormValidators.emailValidator]],
            ticketType: [this.tickets.filter(item => item.value === participant.ticketType)[0].value, [Validators.required]],
            phone: [participantPhone],
            id: [participant.id],
            volunteeringListIds: [participant.volunteeringListIds, [participant.volunteeringListIds.length ? Validators.required : Validators.nullValidator]],
            isVolunteer: !!participant.volunteeringListIds.length,
            eventTicketAddons: new FormArray([])
          });
          this.participantList.push(editParticipantForm);
        });
        this.registrationForm.get('hasGuest').setValue(true, { emitEvent: true });
      }
      this.setAdditionalGuestAddons();
      this.addCurrentAdditionalGuestAddonValue();
    }
  }

  private setInitialTicketFromValue(): void {
    if (this.eventRegEditModel) {
      this.eventRegEditModel.event.ticketPackageList.forEach((ticket, i) => {
        this.eventRegEditModel.packages.forEach(packages => {
          if (ticket.id === packages.id) {
            const item = { value: ticket.id, label: ticket.name, amount: packages.value * ticket.inPackageCount };
            this.tickets.push(item);
            this.ticketWasSelected(true);
          }
        });
      });
      this.setAddonsOptions();
      if (this.eventRegEditModel.registrationModel.ticketPackageId) {
        this.getFormControl('ticketPackageId').setValue(this.eventRegEditModel.registrationModel.ticketPackageId);
      }
      this.setSelectAddonsOptions();
    }
  }

  private setAddonsOptions(): void {
    this.eventRegEditModel.packages.filter(resp => !!this.ticketFormGroupById(resp.id)).forEach((item) => {
      //set main quantity
      this.ticketFormGroupById(item.id).get('quantity').setValue(item.value);
      // set addOns and subAddOns quantity
      if (item.addons) {
        item.addons.forEach(addOn => {
          const addOnGroup = this.getAddOnControlById(addOn.addonID);
          addOnGroup.get('isChecked').setValue(true);
          if(addOn.includeID) {
            const subAddOnGroup = this.getSubAddOnControlsByIds(addOn.addonID, addOn.includeID);
            subAddOnGroup.get('quantity').setValue(addOn.count);
          } else {
            addOnGroup.get('quantity').setValue(addOn.count);
          }
        })
      }
    });
  }

  private setSelectAddonsOptions(): void {
    this.eventRegEditModel.packages.forEach(selectPackage => {
      const unicAddonId = [];
      selectPackage?.addons?.forEach(item => {
        if (!unicAddonId.includes(item.addonID)) {
          unicAddonId.push(item.addonID);
        }
      });
      this.ticketChosenOptions.set(selectPackage.id, []);
      unicAddonId.forEach(item => {
        this.ticketChosenOptions.get(selectPackage.id).push({ [item]: [] })
      });
      selectPackage?.addons?.forEach(addon => {
        this.ticketChosenOptions.get(selectPackage.id).forEach(psackageSelect => {
          if (psackageSelect[addon.addonID] && addon.customName !== '') {
            psackageSelect[addon.addonID].push({ label: addon.customName, value: addon.includeID, count: addon.count });
          }
        })
      });
    });
    this.setMainGuestAddons();
    this.addCurrentAddonValue();
  }

  public setMainGuestAddons(): void {
    if (this.ticketChosenOptions.get(this.registrationForm.get('ticketPackageId').value)) {
      this.ticketChosenOptions.get(this.registrationForm.get('ticketPackageId').value).forEach((item, index) => {
        const key = +Object.keys(item)[0];
        const selectedArr = item[key];
        const newForm = this.formBuilder.group({
          addonID: key,
          selectedOptions: [selectedArr],
          includeID: null
        });
        this.getEventTicketAddons.push(newForm);
      });
    }
  }

  public addCurrentAddonValue(): void {
    if (this.eventRegEditModel.registrationModel.eventTicketAddons && this.eventRegEditModel.registrationModel.eventTicketAddons.length) {
      this.eventRegEditModel.registrationModel.eventTicketAddons.forEach(currentValue => {
        this.getEventTicketAddons.controls.forEach(item => {
          if (currentValue.addonID === item.get('addonID').value) {
            item.get('includeID').setValue(currentValue.includeID);
          }
        })
      })
    }
  }

  private setAdditionalGuestAddons(): void {
    if (this.getAdditionalParticipant) {
      this.getAdditionalParticipant.controls.forEach(participant => {
        if (this.ticketChosenOptions.get(participant.get('ticketType').value)) {
          this.ticketChosenOptions.get(participant.get('ticketType').value).forEach((item, index) => {
            const key = +Object.keys(item)[0];
            const selectedArr = item[key];
            const newForm = this.formBuilder.group({
              addonID: key,
              selectedOptions: [selectedArr],
              includeID: ''
            });
            this.getParticipantAddonForm(participant).push(newForm);
          });
        }
      })
    }
  }

  private addCurrentAdditionalGuestAddonValue(): void {
    if (this.eventRegEditModel.registrationModel.additionalParticipants.length) {
      this.eventRegEditModel.registrationModel.additionalParticipants.forEach(participant => {
        participant.eventTicketAddons.forEach(addon => {
          this.getAdditionalParticipant.controls.forEach(control => {
            const eventTicketAddon = control.get('eventTicketAddons') as FormArray;
            eventTicketAddon.controls.forEach(addonControl => {
              if (addonControl.get('addonID').value === addon.addonID) {
                if (control.get('fullName').value === participant.fullName) {
                  addonControl.get('includeID').setValue(addon.includeID);
                }
              }
            })
          })
        });
      });
    }
  }

  public getParticipantAddonForm(participant?: any): FormArray {
    return participant.get('eventTicketAddons') as FormArray;
  }

  private get getAdditionalParticipant(): FormArray {
    return this.registrationForm.get('additionalParticipants') as FormArray;
  }

  public get getEventTicketAddons(): FormArray {
    return this.registrationForm.get('eventTicketAddons') as FormArray;
  }

  private setUseDnDEmailBuilder(): void {
    if (this.eventModel) {
      const createOn = new Date(this.eventModel.createdOn);
      const changingPoint = new Date(2020, 6, 13); //todo set new date to prod, stage 16.07.2020, dev 13.07.2020
      this.useDnDEmailBuilder = createOn > changingPoint;

      if (this.useDnDEmailBuilder && this.eventModel.mjml) {
        this.setInheritSection();
      }
    }
  }

  private setInheritSection(): void {
    this.template = JSON.parse(this.eventModel.mjml);
    const mjml: MjmlElementModel = JSON.parse(this.eventModel.mjml);
    const inheritMjml: MjmlElementModel = mjml.children[1].children[0].children.find((section: MjmlElementModel) => !!section.attributes['inherit-section']);
    if (inheritMjml) {
      inheritMjml.children.forEach((column: MjmlElementModel) => {
        column.children.forEach((tool: MjmlElementModel, toolIndex) => {
          if (tool.tagName === MjmlTag.button) {
            column.children.splice(toolIndex, 1);
          }
        })
      });
      mjml.children[1].children[0].children = [inheritMjml];
      this.mjml$.next(mjml);
    } else {
      this.mjml$.next(null);
    }
  }

  private getFormControl(name: string): FormControl {
    return this.registrationForm.get(`${name}`) as FormControl;
  }

  public isEventAvailable(): boolean {
    if (this.eventModel && this.eventModel.endDate) {
      if(this.eventModel.status === EventStatus.Complete) return false;
      const today = new Date(this.eventModel.endDate);
      const myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0, 0);
      return !(this.eventModel.status === EventStatus.Canceled || new Date > myToday);
    } else {
      return !(!(this.eventModel?.endDate === null) && this.eventModel?.status === EventStatus.Canceled);
    }
  }

  public initialTicketsOptions(): void {
    if (!this.eventModel || !this.eventModel.ticketPackageList || !this.eventModel.ticketPackageList.length) return;
    this.registrationStep = 1;
    this.eventModel.hasGuestManagement = true;
    this.addTicketControls();
    this.tickets = [];

    if (this.eventModel.priceTicketType !== PriceTicket.Ticket) {
      this.ticketSelect(this.eventModel.ticketPackageList[0], 1);
      this.ticketsGroup.controls[this.eventModel.ticketPackageList[0].id].get('quantity').setValue(1);
      this.ticketsGroup.controls[this.eventModel.ticketPackageList[0].id].get('quantity').setErrors(null);
      this.totalCost = this.eventModel.ticketPackageList[0].price;
    }
  }

  public initialAddons(): void {
    if(!this.eventModel || !this.eventModel.addons || !this.eventModel.addons.length) return;
    this.addAddonControls()
  }

  public areTicketsAvailable(): boolean {
    let result = false;

    if (!this.eventModel || !this.eventModel.ticketPackageList || this.isPreviewMode) return true;
    this.eventModel.ticketPackageList.forEach(ticket => {
      if (ticket.number - ticket.soldCount - ticket.reservedCount > 0) {
        result = true;
      }
    });
    return result;
  }

  public areTicketsInSale(): boolean {
    if(!this.eventModel) return;
    if(new Date(this.getEarliestTicket().startDate) > new Date(this.eventModel.currentServerTime)) {
      return false;
    } else return true;
  }

  public isEventPriceTicket(): boolean {
    if(!this.eventModel) return;
    return this.eventModel.priceTicketType === PriceTicket.Ticket;
  }

  public get imageURL(): string {
    if (this.getHeaderBackground) {
      const img = this.getHeaderBackground.image;
      if (img) {
        return `${this.apiURL}/AzureFileStorage/image/${img}`;
      }
    }
    return '/assets/images/layout-img1.png';
  }

  public get clientLogoURL(): string {
    return `${this.apiURL}/AzureFileStorage/image/${this.clientLogoName}`;
  }

  public get getHeaderBackground(): EventLayoutControlModel {
    if (this.getHeader) {
      return this.getHeader.controlList.find(x => x.name === 'Background');
    }
  }

  private get getHeader(): EventLayoutModuleModel {
    if (this.model) {
      return this.model.moduleList.find(x => x.type === TemplateModuleType.Header);
    }
  }

  private getEventLayout(): void {
    const eventFilter: Paging = {
      includeDependencies: true,
      includeDeleted: false,
      filters: [
        {
          field: 'type',
          value: EventTemplateType.Event.toString(),
          type: FilterType.Equal
        },
        {
          field: 'eventID',
          value: this.activeRouter.snapshot.params.eventId,
          type: FilterType.Equal
        }
      ]
    };
    this.layoutService.getModelList(eventFilter).subscribe(response => {
      this.model = response[0];
    });
  }

  private getStatesAndCountries(): void {
    zip(this.lookupStoreService.usaStates$, this.lookupStoreService.countries$, this.lookupStoreService.timeZones$)
      .pipe(
        first(),
      )
      .subscribe(([states, countries, timeZones]) => {
        this.states = states;
        this.stateOptions = states.map(({ name, id }: StateModel) => ({ label: name, value: id }));
        this.countries = countries;
        this.countriesOptions = countries.map(({ name, id }: CountryModel) => ({ label: name, value: id }));
        this.timeZones = timeZones;
      });
  }

  public checkGuestCount(): boolean {
    if(this.eventModel.priceTicketType !== PriceTicket.Ticket) return true;
    let possibleCount = 0;
    this.eventModel.ticketPackageList.forEach(ticketPackage => {
      const ticketCount = this.ticketsGroup.controls[ticketPackage.id].get('quantity').value;
      possibleCount += ticketCount * ticketPackage.inPackageCount;
    });
    return ((!this.participantList && possibleCount > 1) || (this.participantList && this.participantList.length + 1 <= possibleCount));
  }

  public paymentDetailsOpen(checkTickets:boolean): void {
    if(this.eventModel.priceTicketType === PriceTicket.Ticket && checkTickets) {
      this.ticketCheck$.next(false);
      return
    }
    let possibleCount = 0;
    this.eventModel.ticketPackageList.forEach(ticketPackage => {
      const ticketCount = this.ticketsGroup.controls[ticketPackage.id].get('quantity').value;
      possibleCount += ticketCount * ticketPackage.inPackageCount;
    });
    if ((this.isValidForms(true) && !this.participantList) || (this.isValidForms(true) && this.checkGuestCount())) {
      if (this.eventModel.isSecondaryEvent) {
        const secondaryParticipantFilter: Paging = {
          includeDependencies: false,
          includeDeleted: false,
          filters: [
            {
              field: 'email',
              value: this.registrationForm.controls.email.value,
              type: FilterType.Equal
            },
            {
              field: 'eventID',
              value: this.eventModel.id,
              type: FilterType.Equal
            }
          ]
        };
        this.secondaryEventParticipantService.getModelList(secondaryParticipantFilter).subscribe(response => {
          if (response && response.length > 0) {
            this.eventRegistrationModel = this.getEventRegistrationModel();
            this.registrationStep = 2;
          } else {
            this.toastrService.error('Sorry you cannot register to this event using current email. This is a private event and your email should be included in the guests list', 'Error');
          }
        });
      } else {
        this.eventRegistrationModel = this.getEventRegistrationModel();
        this.registrationStep = 2;
      }
    } else {
      this.showErrorFields();
    }
  }

  public get ticketsList(): FormArray {
    return this.registrationForm.get('ticketsGroup') as FormArray;
  }

  public get participantList(): FormArray {
    return this.registrationForm.get('additionalParticipants') as FormArray;
  }


  public get getMainDetails(): EventLayoutControlModel {
    if (this.getMain) {
      return this.getMain.controlList.find(x => x.name === 'Date');
    }
  }

  public get getMainTitles(): EventLayoutControlModel {
    if (this.getMain) {
      return this.getMain.controlList.find(x => x.name === 'Titles');
    }
  }

  private get getMain(): EventLayoutModuleModel {
    if (this.model) {
      return this.model.moduleList.find(x => x.type === TemplateModuleType.Main);
    }
  }

  public get getMainBackground(): EventLayoutControlModel {
    if (this.getMain) {
      return this.getMain.controlList.find(x => x.name === 'Background');
    }
  }

  private get getAdditional(): EventLayoutModuleModel {
    if (this.model) {
      return this.model.moduleList.find(x => x.type === TemplateModuleType.Details);
    }
  }

  public get getAdditionalDetails(): EventLayoutControlModel {
    if (this.getAdditional) {
      return this.getAdditional.controlList.find(x => x.name === 'Register');
    }
  }

  public get getHeaderEvent(): EventLayoutControlModel {
    if (this.getHeader) {
      return this.getHeader.controlList.find(x => x.name === 'EventName');
    }
  }

  public get getDonateDetails(): EventLayoutControlModel {
    if (this.getAdditional) {
      return this.getAdditional.controlList.find(x => x.name === 'Donate');
    }
  }

  public addGuestForm(): void {
    const editAdditionalGuest = new FormGroup({
      fullName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, FormValidators.emailValidator]),
      ticketType: new FormControl(''),
      phone: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      volunteeringListIds: new FormControl(''),
      addonIncludes: new FormArray([]),
    });
    this.registrationForm.addControl('editAdditionalGuest', editAdditionalGuest);
  }

  public addGuestAddons(): void {
    this.eventParticipant.ticket.addonIncludes.forEach(addon => {
      let selectedOptions: FormElementDataModel[] = [{ label: addon.ticketPackageInclude?.name, value: addon.includeId }];
      const currentFreeAddon = this.freeAddons.filter(item => (item.addonId === addon.addonId) && (item.includeId !== addon.includeId));
      const freeSelectedOptions: FormElementDataModel[] = currentFreeAddon.map(option => {
        return {
          label: option.includeName,
          value: option.includeId
        }
      });
      if (freeSelectedOptions.length) {
        selectedOptions = [...selectedOptions, ...freeSelectedOptions];
      }
      const addonsGuestForm = this.formBuilder.group({
        addonId: addon.addonId,
        eventTicketId: addon.eventTicketId,
        includeId: addon.includeId ? addon.includeId : null,
        selectedOptions: [selectedOptions]
      });
      this.getAddonGuest.push(addonsGuestForm);
    });
  }

  public get getAddonGuest(): FormArray {
    return this.editAdditionalGuest.get('addonIncludes') as FormArray;
  }

  public addEventParticipantForm(): void {
    const participantGroup = this.formBuilder.group({
      fullName: ['', [Validators.required]],
      email: ['', [Validators.required, FormValidators.emailValidator]],
      ticketType: ['', [Validators.required]],
      phone: [''],
      volunteeringListIds: [[], [Validators.required]],
      isVolunteer: false,
    });
    this.setParticipantPhoneValidators(participantGroup);

    if (this.eventModel.priceTicketType === PriceTicket.Ticket) {
      participantGroup.addControl('ticketType', new FormControl(['', Validators.required]));
    }
    if (this.isEditMode && this.participantList) {
      this.participantList.push(participantGroup);
      return;
    }
    this.registrationForm.addControl('additionalParticipants', this.formBuilder.array([participantGroup]));
  }

  public addTicketControls(): void {
    if (!this.eventModel.ticketPackageList) return;
    this.tickets = [];
    this.eventModel.ticketPackageList.forEach(item => {
      const ticketGroup: FormGroup = this.formBuilder.group({
        quantity: '',
        addons: this.formBuilder.array([]),
      });

      if (item.addons && item.addons.length) {
        item.addons.forEach(addon => {
          const addOnGroup: FormGroup = this.formBuilder.group({
            isChecked: false,
            addonType: addon.type,
            addonID: addon.id,
            includes: this.formBuilder.array([])
          });
          (ticketGroup.get('addons') as FormArray).push(addOnGroup);
        });
      }
      this.ticketsGroup.addControl(item.id, ticketGroup);
      this.tickets.push({ value: item.id, label: item.name });
    });
    this.ticketsGroup.setValidators([FormValidators.atLeastOne]);
    this.registrationForm.valueChanges.subscribe(() => {
      this.getTotalTicket();
    });
    this.ticketsGroup.valueChanges.subscribe(() => {
      this.getTotalTicket();
    });
  }

  public addAddonControls(): void {
    if(!this.eventModel.addons) return;

    this.addons = [];

    this.eventModel.addons.forEach(item => {
      const addonGroup: FormGroup = this.formBuilder.group({
        isChecked: false,
        addonId: item.id,
        quantity: 0,
        subAddons: this.formBuilder.group({}),
        ticketPackageId: item.ticketPackageId,
      })

      // if addon has subAddOns
      if(item.includes && item.includes.length) {
        item.includes.forEach(subAddon => {
          const subAddonGroup: FormGroup = this.formBuilder.group({
            subAddonId: subAddon.id,
            quantity: 0,
          });
          (addonGroup.get('subAddons') as FormGroup)
            .addControl(`subAddon${subAddon.id}`, subAddonGroup);
        })
      }

      this.addonsGroup.addControl(`addon${item.id}`, addonGroup);
      this.addons.push(item);
    })
    this.addonsGroup.setValidators([this.selectMandatoryAddOnsValidator.bind(this), this.unrelatedAddOnsMinimumQuantityValidator.bind(this)])
    this.addonsGroup.valueChanges.subscribe(() => {
      this.updateAddonsModel();
    })
    this.ticketsGroup.valueChanges.subscribe(() => {
      this.updateAddonsModel();
    });
  }

  public unrelatedAddOnsMinimumQuantityValidator(formGroup: FormGroup):ValidationErrors | null {
    const errObj = {minimumQuantity: true}
    const ticketUnrelatedAddOns = this.totalAddOns.filter(addOn => !addOn.ticketPackageId);
    ticketUnrelatedAddOns.forEach(addOn => {
      const control = this.getAddOnControlById(addOn.id);
      if(control.value.isChecked && control.value.quantity <= 0) {
        control.setErrors(errObj);
        control.get('quantity').setErrors(errObj);
      } else {
        control.setErrors(null);
        control.get('quantity').setErrors(null);
      }
    })
    return null
  }

  private selectMandatoryAddOnsValidator(formGroup: FormGroup): ValidationErrors | null {
    const errTemplate = {requiredAddon: true};
    this.totalTickets.forEach(ticket => {
      if(ticket.quantity === 0 ) {
        this.changeValidityStateOfRelatedAddOns(ticket.id, errTemplate, true);
        return;
      }
      const currTicket: TicketPackageModel = this.eventModel.ticketPackageList.find(({id}) => ticket.id === id);
      const relatedAddOnsSum: number = Number(this.getRelatedAddOnsSum(ticket.id));
      const relatedAddOnsLeft: number = UtilsComponent.getRequiredAddOnsLeftCount(currTicket, this.addOnsTimesUsed);
      const validQuantity: number = currTicket.inPackageCount > 1 ? currTicket.inPackageCount * ticket.quantity : ticket.quantity;
      const totalValidQuantity: number = relatedAddOnsLeft < validQuantity ? relatedAddOnsLeft : validQuantity;
      if(relatedAddOnsSum !== totalValidQuantity) {
        this.changeValidityStateOfRelatedAddOns(ticket.id, errTemplate, false);
        return errTemplate;
      } else {
        this.changeValidityStateOfRelatedAddOns(ticket.id, errTemplate);
      }
    })
    return null;
  }

  private changeValidityStateOfRelatedAddOns(ticketPackageId: string, errObject, toValid: boolean = true): void {
    const ticketRelatedAddOns: AddOnRegistrationModel[] = this.totalAddOns.filter(addOn => addOn.ticketPackageId === ticketPackageId);

    ticketRelatedAddOns.forEach(addOn => {
      const addonControl = this.getAddOnControlById(addOn.id);
      if(toValid) {
        addonControl.setErrors(null);
        addonControl.get('quantity').setErrors(null);
        addonControl.get('quantity').markAsUntouched()
      } else {
        addonControl.setErrors([errObject]);
        addonControl.get('quantity').setErrors([errObject])
        addonControl.get('quantity').markAsTouched()
      }

      // if addOn has subAddOns
      if(addOn.includes && addOn.includes.length !== 0) {
        addOn.includes.forEach(subAddon => {
          const subAddOnControl = this.getSubAddOnControlsByIds(addOn.id, subAddon.id);
          if(toValid) {
            subAddOnControl.setErrors(null);
            subAddOnControl.get('quantity').setErrors(null);
            subAddOnControl.get('quantity').markAsUntouched();
          } else {
            subAddOnControl.setErrors([errObject]);
            subAddOnControl.get('quantity').setErrors([errObject]);
            subAddOnControl.get('quantity').markAsTouched();
          }
        })
      }
    })

    if (toValid) {
      const invalidAddOnsControls: any[] = Object.values(this.addonsGroup.controls).filter(addOn => addOn.errors);
      if (invalidAddOnsControls.length) {
        invalidAddOnsControls.forEach(control => {
          if (control.value.ticketPackageId === ticketPackageId && control.value.quantity === 0) {
            control.setErrors(null);
            control.markAsUntouched();
            control.controls.quantity.setErrors(null);
            control.controls.subAddons.setErrors(null);
            control.controls.quantity.markAsUntouched();
            control.controls.subAddons.markAsUntouched();
            const nestedSubAddonsControls: any[] = Object.values(control.controls.subAddons.controls);
            if (nestedSubAddonsControls.length){
              nestedSubAddonsControls.forEach(subAddon => {
                subAddon.setErrors(null);
                subAddon.markAsUntouched();
                subAddon.controls.quantity.setErrors(null);
                subAddon.controls.quantity.markAsUntouched();
              })
            }
          }
        })
      }
    }
  }

  private getRelatedAddOnsSum(ticketPackageId: string): number {
    let result: number = 0
    const relatedAddOns: AddOnRegistrationModel[] = this.totalAddOns
      .filter(addOn => addOn.ticketPackageId === ticketPackageId);
    relatedAddOns?.forEach(addOn => {
      if(addOn.includes && addOn.includes.length !== 0) {
        addOn.includes.forEach(include => {
          result += this.getSubAddOnControlsByIds(addOn.id, include.id).get('quantity').value;
        })
      } else {
        result += this.getAddOnControlById(addOn.id).get('quantity').value;
      }
    })
    return result;
  }

  private getAddOnControlById(addOnId: number): AbstractControl {
    return this.addonsGroup.get(`addon${addOnId}`);
  }

  private getSubAddOnControlsByIds(addOnId: number, subAddOnId: number): AbstractControl | null {
    const currentAddOnControl: AbstractControl = this.getAddOnControlById(addOnId)
    if(!currentAddOnControl.get('subAddons').value) return null;
    return currentAddOnControl.get('subAddons').get(`subAddon${subAddOnId}`);
  }

  private setParticipantPhoneValidators(participantGroup: FormGroup): void {
    if (this.requiredFieldsPermission) {
      participantGroup.get('phone').setValidators(Validators.required);
      participantGroup.get('phone').updateValueAndValidity();
      participantGroup.updateValueAndValidity();
    }
  };

  public get requiredFieldsPermission(): boolean {
    if (!this.eventModel) {
      return false;
    } else {
      const { hasRegistrationForm, priceTicketType, registrationRequiredFields }: EventModel = this.eventModel;
      return !(
        hasRegistrationForm
        && priceTicketType === PriceTicket.NoFee
        && registrationRequiredFields === EventRegistrationRequiredFieldsType.FirstLastNameEmailRequired
      );
    }
  }

  public manageParticipants(isGuest: any) {
    let eventType = this.eventModel?.priceTicketType;
    if (isGuest) {
      if (this.checkGuestCount()) {
        this.addEventParticipantForm();
      } else {
        setTimeout(() => {
          this.registrationForm.controls.hasGuest.setValue(false, { emitEvent: true });
        }, 0);
        this.toastrService.error(this.ticketErrorMessage, 'Warning');
      }
    } else {
      this.registrationForm.removeControl('additionalParticipants');
    }
  }

  addGoogleCalendar(): void {
    this.eventService.getModel(this.activeRouter.snapshot.params.eventId).subscribe(event => {
      this.eventModel = event;
      this.googleService.insertEvent(this.eventModel, this.timeZones.find(item => item.id === this.eventModel.timeZoneID));
    });
  }

  addOutlookCalendar() {
    this.eventService.getModel(this.activeRouter.snapshot.params.eventId).subscribe(event => {
      this.createEvent(event);
    })
  }

  selectTicket(index: number) {
    if (this.selectedTicket === index) {
      this.selectedTicket = null;
    } else {
      this.selectedTicket = index;
    }
  }

  getTicketTypes(): FormElementDataModel[] {
    const array: FormElementDataModel[] = [];
    this.eventModel.ticketPackageList.forEach(element => {
      array.push({ value: element.name, label: element.name })
    });
    return array;
  }

  getTicketCount(ticketCount: number): any[] {
    const array: any[] = [];
    for (let index = 1; index <= ticketCount; index++) {
      array.push({ value: index.toString(), label: index.toString() })
    }
    return array;
  }

  async createEvent(eventModel: EventModel) {
    await this.outlookService.createEvents(eventModel, this.timeZones.find(item => item.id === this.eventModel.timeZoneID));
  }

  // will be refactored in the nex sprit (with packages functionality)
  public getTotalTicket(): void {
    this.totalTickets = [];
    if (this.eventModel.priceTicketType === PriceTicket.Ticket) {
      this.eventModel.ticketPackageList.forEach(item => {
        if (this.ticketsGroup.controls[item.id]) {
          const quantity = Number(this.ticketsGroup.controls[item.id].get('quantity').value);
          const model: TicketRegistrationModel = {
            name: item.name,
            price: item.price,
            quantity,
            id: item.id,
            cost: item.price * quantity,
            coupon: null,
            discount: 0
          };
          const coupon = item.useCoupon
          if (coupon && !!model.quantity) {
            model.coupon = coupon;
            model.discount = this.getDiscount(model)
          }

          this.totalTickets.push(model);
        } else {
          this.totalTickets.push({
            name: item.name,
            price: item.price,
            quantity: Number(0),
            id: item.id,
            cost: 0,
            coupon: null,
            discount: 0
          });
        }
      });
    }

    this.getTotalAddOns();
    this.costChanged$.next();
  }

  public getTotalAddOns(): void {
    this.totalAddOns = [];
    this.addons.forEach(addOn => {
      const ticket = this.totalTickets.find(item => item.id === addOn.ticketPackageId);
      if(ticket && ticket.quantity === 0) return;
      const quantity: number = +this.addonsGroup.get(`addon${addOn.id}`).get('quantity').value;
      const model: AddOnRegistrationModel = {
        id: addOn.id,
        name: addOn.name,
        price: addOn.price,
        type: addOn.type,
        quantity: quantity,
        ticketPackageId: addOn.ticketPackageId,
      }
      if(!!addOn.includes.length) {
        model.includes = [];
        addOn.includes.forEach(subAddOn => {
          const quantity: number = +this.addonsGroup
            .get(`addon${addOn.id}`)
            .get('subAddons')
            .get(`subAddon${subAddOn.id}`)
            .get('quantity').value;
          const subAddOnModel: AddOnIncludesRegistrationModel = {
            id: subAddOn.id,
            name: subAddOn.name,
            price: subAddOn.price,
            quantity: quantity,
          }
          model.includes.push(subAddOnModel);
        })
      }
      this.totalAddOns.push(model);
    })
    this.costChanged$.next();
  }

  public updateAddonsModel(): void {
    this.addons.forEach(addon => {
      const selectedQuantity = this.addonsGroup.get(`addon${addon.id}`).get('quantity').value;
      if(this.eventModel.addons.find(addonModel => addonModel.id === addon.id)) {
        this.eventModel.addons.find(addonModel => addonModel.id === addon.id).isInUse = !!selectedQuantity;
      }

      if(addon.includes && addon.includes.length !== 0) {
        addon.includes.forEach(subAddon => {
          const selectedQuantity = this.addonsGroup.get(`addon${addon.id}`).get('subAddons').get(`subAddon${subAddon.id}`).get('quantity').value;
          this.eventModel.addons.find(addonModel => addonModel.id === addon.id).isInUse = true;
          this.eventModel
            .addons.find(addonModel => addonModel.id === addon.id)
            .includes.find(subAddonModel => subAddonModel.id === subAddon.id).isInUse = !!selectedQuantity;
        })
      }
    })
    this.getTotalAddOns();
  }

  private getDiscount({ cost, coupon }: TicketRegistrationModel): number {
    let discount = 0;
    if (coupon.couponType === CouponValue.Percent) {
      discount = cost * coupon.couponValue / 100;
    } else if (coupon.couponType === CouponValue.Dollar) {
      discount = coupon.couponValue;
    }
    if (!Number.isInteger(discount)) {
      discount = +discount.toFixed(2);
    }
    return discount;
  }

  public isValidForms(skipNotification: boolean = false): boolean {
    if (this.isGuest) {
      this.registrationForm.get('editAdditionalGuest').markAsTouched();
      this.manualRegisterForm.get('registerDate').markAsTouched();
      if (this.registrationForm.get('editAdditionalGuest').valid && this.manualRegisterForm.get('registerDate').valid) {
        return true;
      } else {
        !skipNotification && this.showErrorFields();
        this.triggerShowErrorFields.next();
        return false;
      }
    }
    this.registrationForm.markAllAsTouched();
    this.ticketsGroup.markAllAsTouched();
    this.addonsGroup.markAllAsTouched();
    if (this.registrationForm.valid && this.ticketsGroup.valid && this.addonsGroup.valid) {
      return true;
    } else {
      !skipNotification && this.showErrorFields();
      this.triggerShowErrorFields.next();
      return false;
    }
  }

  public registerDonor() {
    this.registrationInProgress = true;
    if(this.eventModel.priceTicketType === PriceTicket.Ticket) {
     this.ticketCheck$.next(true);
    }
    else this.registerDonor$.next();
  }

  private get paymentStatus(): DonationStatusType {
    return this.manualRegisterForm.get('paymentStatus').value
  }

  private get paymentMethod(): DonationMethodType {
    return this.manualRegisterForm.get('paymentMethod').value
  }

  private registerDonorFlow(): void {
    this.subscription.add(
      this.registerDonor$.asObservable()
        .pipe(
          exhaustMap(() => {
            if (!this.isValidForms()) {
              this.registrationInProgress = false;
              return of(null);
            }
            if (!this.isGuest) {
              return this.clientModelService.getClientLogo(this.eventModel.clientID)
                .pipe(
                  switchMap((client) => {
                    const registrationModel: EventRegistrationModel = this.getEventRegistrationModel();
                    const eventRegistrationModel: EventRegistrationModel = {
                      createdOn: new Date(this.manualRegisterForm.get('registerDate').value).toISOString(),
                      numberInParty: this.manualRegisterForm.get('numberInParty').value,
                      registrationPaymentStatus: this.paymentStatus,
                      registrationPaymentMethod: this.paymentMethod,
                      ...registrationModel,
                    };
                    const updatedParticipant: EventParticipantModel = {
                      ...this.eventParticipant,
                      registrationPaymentStatus: this.paymentStatus,
                      registrationPaymentMethod: this.paymentMethod,
                    }
                    if(this.fundraiserID) {
                      eventRegistrationModel.fundraiserID = this.fundraiserID;
                    }
                    if (this.isEditMode) {
                      return this.eventParticipantService.updateModel(updatedParticipant).pipe(
                          switchMap(() => this.ticketService.updateEventRegistration(eventRegistrationModel))
                      )
                    }
                    if (this.isManualRegistration) {
                      return this.ticketService.processEventRegistration(eventRegistrationModel);
                    }
                    delete eventRegistrationModel.createdOn;

                    return this.ticketService.processEventRegistration(eventRegistrationModel);
                  }),
                  tap(this.onEventRegistrationComplete.bind(this)),
                )
            }
            if (this.isGuest) {
              const bayerNameArray = this.registrationForm.get('editAdditionalGuest').get('fullName').value.split(' ');
              const firstName = bayerNameArray[0];
              const lastName = bayerNameArray[1];
              const countryModel: CountryModel = this.countries.find(item => item.id === this.registrationForm.get('country')?.value);
              const newModel: EventParticipantModel = {
                ...this.eventParticipant,
                firstName: firstName,
                lastName: lastName,
                email: this.registrationForm.get('editAdditionalGuest').get('email').value,
                mobile: (countryModel?.phoneCode ?? '') + this.registrationForm.get('editAdditionalGuest').get('phone').value.replace(/\D/g, ""),
                volunteeringIds: this.registrationForm.get('editAdditionalGuest').get('volunteeringListIds').value,
                createdOn: new Date(this.manualRegisterForm.get('registerDate').value).toISOString()
              };
              newModel.ticket.addonIncludes = this.getAddonGuest.controls.map(item => {
                return {
                  addonId: item.get('addonId').value,
                  includeId: item.get('includeId').value,
                  eventTicketId: item.get('eventTicketId').value
                }
              });
              return this.eventParticipantService.updateModel(newModel).pipe(tap(this.onEventRegistrationComplete.bind(this)));
            }
          })
        ).subscribe()
    );
  }



  private ticketCheckFlow() {
   this.subscription.add(
   this.ticketCheck$.asObservable().pipe(
     exhaustMap((shouldRegister) => {
       if (!this.isValidForms()) {
         this.registrationInProgress = false;
         return of(null);
       }
       const {packages} = this.getEventRegistrationModel();
       return this.checkIfTicketsAreStillValid(packages).pipe(
         tap((unavailablePackages) => {
           if(!unavailablePackages.length && shouldRegister) this.registerDonor$.next();
           if(!unavailablePackages.length && !shouldRegister) {
             this.registrationInProgress = false;
             this.paymentDetailsOpen(false);
           }
           if(!!unavailablePackages.length) {
             this.registrationInProgress = false;
             this.handleInvalidTickets(unavailablePackages);
           }
         })
       )
     })
   ).subscribe()
  )
  }

  private checkIfTicketsAreStillValid(tickets: EventRegistrationTickets[]) {
    return this.ticketService.checkIfTicketsAreAvailable(tickets, this.eventModel.id)
  }

  private handleInvalidTickets(tickets: TicketPackageModel[]) {
    this.isRegistrationComplete = false;
    const updatedTickets = this.eventModel.ticketPackageList.slice();
    tickets.forEach(ticket => {
    const relatedTicketIndex:number = this.eventModel.ticketPackageList.findIndex(tck => tck.id === ticket.id);
    updatedTickets[relatedTicketIndex] = ticket;
      const ticketFormGroup = this.ticketsGroup.controls[ticket.id];
      ticketFormGroup.get('quantity').setValue('');
    })
    this.registrationForm.get("ticketPackageId").setValue('');
    this.eventModel.ticketPackageList = updatedTickets;
    this.tickets = [];
    this.guestTicketOptions = [];
    const names:string = JSON.stringify(tickets.map(tck => tck.name)).replace(/[\[\]"]+/g,'').replace(/,/g, ", ")
    this.toastrService.error(this.translate.instant("EVENTS.Selected tickets ({{names}}) are no longer available in selected quantity", {names: names}))
    this.currentTab = this.tabList.find(tab => tab.labelName === "Tickets").index;
    this.cdr.detectChanges();
  }

  private getEventRegistrationModel(): EventRegistrationModel {
    const tickets: EventRegistrationTickets[] = [];
    const noTicketRelatedAddOns: AllEventTicketAddonModel[] = [];
    let numberInParty: number = 0;
    this.eventModel.ticketPackageList.forEach(item => {
      const ticketFormGroup = this.ticketsGroup.controls[item.id];
      const value = ticketFormGroup.get('quantity').value;
      let coupon = null;
      if(value && item.useCoupon) {
        coupon = item.useCoupon;
      }
      if(item.useCoupon && !value ) {
        item.useCoupon = null;
      }
      if (value) {
        tickets.push({ id: item.id, value, addons: [], coupon });
      }
      numberInParty += value * this.eventModel.ticketPackageList.find((ticket: TicketPackageModel) => ticket.id === item.id)?.inPackageCount || 0;
    });
    this.addons.forEach(addOn => {
      const currentTicket = tickets.find(ticket => ticket.id === addOn.ticketPackageId)
      if(addOn.isInUse && addOn.includes.length === 0) {
        const quantity = this.addonsGroup.get(`addon${addOn.id}`).get('quantity').value;
        const newAddOn: AllEventTicketAddonModel = {
          addonID: addOn.id,
          includeID: null,
          type: addOn.type,
          customName: addOn.name,
          count: quantity,
        }
        if(!!quantity) {
          if(addOn.ticketPackageId) {
            currentTicket?.addons.push(newAddOn)
          } else {
            noTicketRelatedAddOns.push(newAddOn)
          }
        }
      }
      if(addOn.isInUse && !!addOn.includes.length) {
        addOn.includes.forEach(subAddOn => {
          const quantity = this.addonsGroup
            .get(`addon${addOn.id}`)
            .get('subAddons')
            .get(`subAddon${subAddOn.id}`)
            .get('quantity').value;
          const newSubAddOn: AllEventTicketAddonModel = {
            addonID: addOn.id,
            includeID: subAddOn.id,
            type: addOn.type,
            customName: subAddOn.name,
            count: quantity,
          }
          if(!!quantity) {
            if(addOn.ticketPackageId) {
              currentTicket?.addons.push(newSubAddOn)
            } else {
              noTicketRelatedAddOns.push(newSubAddOn)
            }
          }
        })
      }
    })
    const registrationModel: EventRegistrationInfoModel = this.registrationForm.getRawValue();
    registrationModel.phone = registrationModel.phone ? `${this.countryState.getValue().phoneCode}${registrationModel.phone}` : '';
    if (registrationModel && registrationModel.additionalParticipants) {
      registrationModel.additionalParticipants.forEach((additionalParticipant: EventRegistrationAdditionalParticipantModel) => {
        additionalParticipant.phone = additionalParticipant.phone ? `${this.countryState.getValue().phoneCode}${additionalParticipant.phone}` : '';
      });
    }
    const state = this.states.find(stateModel => stateModel.id === registrationModel.stateId);
    if (!state) {
      registrationModel.stateName = registrationModel.stateId ? registrationModel.stateId.toString() : '';
      registrationModel.stateId = 0;
    }

    if (this.clientLogo) {
      return {
        event: this.eventModel,
        packages: tickets,
        addOns: noTicketRelatedAddOns,
        totalCost: this.totalCost ? this.totalCost : 0,
        numberInParty,
        registrationModel
      };
    } else {
      return {
        event: this.eventModel,
        packages: tickets,
        addOns: noTicketRelatedAddOns,
        totalCost: this.totalCost ? this.totalCost : 0,
        numberInParty,
        registrationModel
      };
    }
  }

  private onEventRegistrationComplete(): void {
    this.registrationInProgress = false;
    this.checkCanDeactivate$.next(true);
    if (this.isManualRegistration) {
      this.navigateToOrigin$.next()
    } else {
      this.router.navigate([location.pathname], { queryParams: { registrationComplete: true } });
      this.isRegistrationComplete = true;
      this.markAllFormsAsPristine();
      this.cdr.detectChanges();
    }
  }
 private markAllFormsAsPristine() {
   this.ticketsGroup.markAsPristine();
   this.addonsGroup.markAsPristine();
   this.registrationForm.markAsPristine();
   this.couponGroup.markAsPristine();

 }
  public ticketWasSelected(flag?: boolean): void {
    this.guestTicketOptions = JSON.parse(JSON.stringify(this.tickets));
    this.manageParticipants(false);
    if (flag) {
      return;
    }
    let numberInParty = 0;
    this.guestTicketOptions.forEach(element => {
      numberInParty += element.amount;
    });
    this.manualRegisterForm.get('numberInParty').setValue(numberInParty);
    this.registrationForm.get('hasGuest').setValue(false);
    this.registrationForm.get('ticketPackageId').reset();
  }

  public ticketAddonValidator(value: TicketPackageModel): void {
    this.ticketChangeSubject.next(value);
  }

  public ticketSelect(ticket: any, formValue: any): void {
    if (!ticket) return;
    const index = this.tickets.findIndex(e => e.value === ticket.id);
    const packageItem = this.eventModel.ticketPackageList.find(ticketPackage => ticketPackage.id === ticket.id);
    const item = { value: ticket.id, label: ticket.name, amount: formValue * packageItem.inPackageCount };
    if (index !== -1) {
      formValue === '' ? this.tickets.splice(index, 1) : this.tickets.splice(index, 1, item);
    } else {
      if (formValue !== '') {
        this.tickets.push(item);
      }
    }
    this.guestTicketOptions = JSON.parse(JSON.stringify(this.tickets));
    this.manageParticipants(false);
    this.registrationForm.get('hasGuest').setValue(false);
  }

  public backRegistration(): void {
    this.registrationStep = 1;
  }

  public getCurrentEventId(): string {
    return this.activeRouter.snapshot.params.eventId
  }

  public onDonateClick(): void {
    if (this.isPreviewMode) {
      return;
    }
    this.showDonationInfoMessage();
    this.isDonateFormVisible = !this.isDonateFormVisible;
  }

  public onPaymentFinished(event): void {
    if (this.registrationStep === 1) {
      return;
    }
    this.onEventRegistrationComplete();
  }

  public get paymentTitle(): string {
    if (!this.eventModel) {
      return 'Payment';
    }
    switch (this.eventModel.priceTicketType) {
      case PriceTicket.RegistrationFee:
        return 'Registration';
      case PriceTicket.Ticket:
        return 'Tickets';
      default:
        return 'Payment';
    }
  }

  private showErrorFields(): void {
    if (this.ticketsGroup.invalid) {
      this.changeTabToTicket();
      this.toastrService.error(this.translate.instant('EVENTS.Please select ticket quantity'));
    }
    else if(this.addonsGroup.invalid) {
      this.addonsGroup.markAsDirty();
      this.changeTabToAddOns();
      const invalidAddOnsControls: any[] = Object.values(this.addonsGroup.controls)
        .filter(addOn => addOn.errors)
        .map(addOn => addOn.value);
        invalidAddOnsControls.forEach(addOn => {
          const relatedTicket = this.totalTickets.find(ticket => ticket.id === addOn.ticketPackageId);
          if(!relatedTicket) return;
          const ticketPackage: TicketPackageModel = this.eventModel.ticketPackageList.find(({id}) => id === addOn.ticketPackageId);
          const addOnsLeft: number = UtilsComponent.getRequiredAddOnsLeftCount(ticketPackage, this.addOnsTimesUsed);
          const ticketsAmounts: number = ticketPackage.inPackageCount > 1 ? relatedTicket.quantity * ticketPackage.inPackageCount : relatedTicket.quantity;
          const result = addOnsLeft < ticketsAmounts ? addOnsLeft : ticketsAmounts;
          if (result > 0) {
            this.toastrService.error(this.translate.instant(`EVENTS.The required amount of Add-Ons related to ticket "{{value}}" is: `, {value: relatedTicket.name.toUpperCase()}) + result);
          }
        })
    }
    else if (this.registrationForm.invalid) {
      this.changeTabToContact();
      this.toastrService.error(this.translate.instant('DONOR_PORTAL.PROFILE.Please fill all mandatory fields'));
    }
    else if (!this.checkGuestCount()) {
      this.toastrService.error(this.translate.instant('EVENTS.Invalid guest count'));
    }
  }

  public getStateName(stateId: number): string {
    const stateName = this.stateOptions.find(item => item.value === stateId);
    return stateName ? stateName.label : '';
  }

  public getCountryName(countryId: string | number): string {
    const countryName = this.countriesOptions.find(item => item.value === countryId);
    return countryName ? countryName.label : '';
  }

  private setAdditionalValidators(): void {
    const { hasRegistrationForm, priceTicketType, registrationRequiredFields }: EventModel = this.eventModel;
    if (
      hasRegistrationForm
      && priceTicketType === PriceTicket.NoFee
      && registrationRequiredFields === EventRegistrationRequiredFieldsType.FirstLastNameEmailRequired
    ) {
      return;
    }
    if (!this.isGuest) {
      if (this.isEditMode || this.isManualRegistration) {
        this.phone.setValidators([Validators.maxLength(20)]);
        this.phone.updateValueAndValidity();
        this.registrationForm.get('email').clearValidators();
        this.registrationForm.get('email').setValidators(FormValidators.emailValidator);
        this.registrationForm.get('email').updateValueAndValidity();
      } else {
        this.phone.setValidators([Validators.required, Validators.maxLength(20)]);
      }
      this.phone.updateValueAndValidity();
      this.streetAddress.setValidators([Validators.required, Validators.maxLength(250)]);
      this.streetAddress.updateValueAndValidity();
      this.city.setValidators([Validators.required, Validators.maxLength(250)]);
      this.city.updateValueAndValidity();
      this.state.setValidators([Validators.required, Validators.maxLength(250)]);
      this.state.updateValueAndValidity();
      this.zipCode.setValidators([Validators.required, Validators.pattern('^(\\d{5}|\\d{9})$')]);
      this.zipCode.updateValueAndValidity();

      this.territorialEntity.setValidators([Validators.maxLength(250)]);
      this.territorialEntity.updateValueAndValidity();
    }


    this.registrationForm.updateValueAndValidity();
  }

  public get phone(): FormControl {
    return this.registrationForm.get('phone') as FormControl;
  }

  public get streetAddress(): FormControl {
    return this.registrationForm.get('streetAddress') as FormControl;
  }

  public get city(): FormControl {
    return this.registrationForm.get('city') as FormControl;
  }

  public get state(): FormControl {
    return this.registrationForm.get('stateId') as FormControl;
  }

  public get zipCode(): FormControl {
    return this.registrationForm.get('zipCode') as FormControl;
  }

  public get territorialEntity(): FormControl {
    return this.registrationForm.get('territorialEntity') as FormControl;
  }

  public get location(): string {
    const { territorialEntity, address1, address2, city, state, country = 1 } = this.eventModel;
    const countryModel = this.countries.find(({ id }) => id === +country);
    const countryName = countryModel ? countryModel.name : '';
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let { zip } = this.eventModel;
    let stateValue: any = state;
    if (countryName === 'USA') {
      stateValue = state ? this.stateOptions.find(({ value }: FormElementDataModel) => value === state).label : '';
    }
    if ((countryName === 'USA' || countryName.startsWith('Puerto')) && zip && zip.length > 5) {
      if (zip.indexOf('-') == -1) {
        zip = zip.slice(0, 5) + '-' + zip.slice(5);
      }
    }
    if (this.eventModel) {
      this.eventModel.stateName = stateValue
      this.eventModel.countryName = territorialEntity ? countryModel.name + ', ' + territorialEntity : countryModel.name
      this.eventModel.zip = zip
    }
    if (this.eventModel) {
      this.eventModel.stateName = stateValue
      this.eventModel.countryName = territorialEntity ? countryModel.name + ', ' + territorialEntity : countryModel.name
    }
    return `${address1 ? '<div>' + address1 + '</div>' : ''}
            ${address2 ? '<div>' + address2 + '</div>' : ''}
            <div>${city ? city : ''}${stateValue ? ', ' + stateValue : ''}${zip ? ', ' + zip : ''}</div>
            <div>${countryName}${territorialEntity ? ', ' + territorialEntity : ''}</div>`;
  }

  private setCoupons(): void {
    let atLeastOneCoupon = false;
    if(this.eventModel.coupons && !!this.eventModel.coupons.length) {
      atLeastOneCoupon = true;
    }
    this.atLeastOneCoupon$ = of(atLeastOneCoupon);
  }

  private handleWarnMessage(message: string): void {
    this.toastrService.warning(message)
    this.couponGroup.get('couponCode').setValue('');
    return;
  }



  public handleApplyCoupon(): void {
    if(!this.eventModel.usedFinalCostCoupons) {
      this.eventModel.usedFinalCostCoupons = [];
    }

    const couponCode = this.couponGroup.get('couponCode').value;
    if (!couponCode) {
      return;
    }

    let coupon: EventCouponModel = null;
    let couponTicketPackage: TicketPackageModel[] = [];
    let useCoupon: boolean = false;
    let isSoldOut: boolean = false;
    let isNotActive: boolean = false;
    let isExpired = false;
    let isInactive = false;

    const isOnlyOneCoupon = this.eventModel.useOnlyOneCoupon;

    // Check if only one coupon per registration is allowed
    if(isOnlyOneCoupon && !!this.getUsedCouponsCount()) {
      return this.handleWarnMessage(this.translate.instant('BUILD.Only one promotion code can be used'));
    }

    if(this.totalCost === 0){
      return this.handleWarnMessage(this.translate.instant('BUILD.Registration has no cost, can not apply promo code'));
    }

    const allCoupons = this.eventModel.coupons;
    let alreadyAppliedCount: number = 0;
    allCoupons.forEach(item => {
      if (couponCode !== item.couponCode) return;

      if (item.ticketPackages && item.ticketPackages.length !== 0) {
        this.eventModel.ticketPackageList.forEach((ticket: TicketPackageModel) => {
          if (item.ticketPackages.findIndex(tp => tp.id === ticket.id) == -1) return;
          if (!ticket.useCoupon || ticket.useCoupon.couponCode !== couponCode) {
            if(this.totalTickets.find(item => item.id === ticket.id).quantity) {
              couponTicketPackage.push(ticket);
            }
          }
          else {
            if(item.ticketPackages.length > 1) {
              alreadyAppliedCount++;
              if(alreadyAppliedCount === item.ticketPackages.length) useCoupon = true;
              return;
            }
            useCoupon = true;
          }
          return;
        });
        if (couponTicketPackage.length === 0) return;
      }


      // Check if sold out
      if (item.used && item.used >= item.limit) {
        isSoldOut = true;
        return;
      }
      // Check if active
      if (new Date(item.startDate) > new Date(this.eventModel.currentServerTime)) {
        isNotActive = true;
        return;
      }
      // check if expired
      const endDateFixed = new Date(item.endDate);
      endDateFixed.setHours(endDateFixed.getHours() + 23, endDateFixed.getMinutes() + 59, 59); //move the end date to the end of the day so it is available through last day
      if (endDateFixed < new Date(this.eventModel.currentServerTime)) {
        isExpired = true;
        return
      }
      // check if inactive
      if (!item.couponStatus) {
        isInactive = true;
        return;
      }

      if (couponTicketPackage.length > 0) {
        couponTicketPackage.forEach(ctp => {
          this.eventModel.ticketPackageList.find(item => item.id == ctp.id).useCoupon = item
        })

      } else {
        const usedFinalCostCouponsCount = this.eventModel
          .usedFinalCostCoupons
          ?.filter(usedCoupon => usedCoupon.couponCode === couponCode).length;
        if (!!usedFinalCostCouponsCount) {
          useCoupon = true;
          return;
        }
        this.eventModel.usedFinalCostCoupons.push(item);
      }
      coupon = item;
    })

    if(useCoupon) {
      return this.handleWarnMessage(this.translate.instant('BUILD.Promotion Code already in use'))
    }

    if(isSoldOut) {
      return this.handleWarnMessage(this.translate.instant('BUILD.Promotion Code Usage Limit Has Been Reached'))
    }

    if(isInactive) {
      return this.handleWarnMessage(this.translate.instant('BUILD.Promotional Code is Inactive'))
    }

    if(isNotActive) {
      return this.handleWarnMessage(this.translate.instant('BUILD.Promotion Code is Not Active'))
    }

    if(isExpired) {
      return this.handleWarnMessage(this.translate.instant('BUILD.Promotion Code Expired'))
    }

    if(!coupon) {
      this.toastrService.warning(this.translate.instant('BUILD.Your Promo Code is not valid. Please enter valid Promo Code'));
      this.couponGroup.get('couponCode').setValue('');
      return;
    }

    this.getTotalTicket();
    this.couponGroup.get('couponCode').setValue('');
  }

  private getUsedCouponsCount(): number {
    let usedCouponsCount: number = 0;
    usedCouponsCount += this.eventModel.ticketPackageList.filter((ticket: TicketPackageModel) => {
      return ticket.useCoupon;
    }).length;
    usedCouponsCount += this.eventModel.usedFinalCostCoupons.length || 0
    return usedCouponsCount;
  }

  public removeCoupon(): void {
    this.couponSet.clear();
    this.getTotalTicket();
  }

  public removeCouponByTicketId(ticketId: string): void {
    if(!ticketId) return;
    const ticket: TicketPackageModel = this.eventModel.ticketPackageList.find(ticket => ticket.id == ticketId);
    if(!ticket) return;
    ticket.useCoupon = null;
    this.getTotalTicket();
  }

  public removeFinalCostCouponByCode(couponCode: string): void {
    if(!couponCode || !this.eventModel.usedFinalCostCoupons) return;
    const newArray: EventCouponModel[] = this.eventModel.usedFinalCostCoupons.filter(coupon => coupon.couponCode !== couponCode);
    this.eventModel.usedFinalCostCoupons = newArray;
    this.costChanged$.next();
  }

  public couponUnits(couponType: CouponValue): string {
    switch (couponType) {
      case CouponValue.Dollar:
        return '$';
      case CouponValue.Percent:
        return '%';
      default:
        return '';
    }
  }

  public dateFilter = (date: Date): boolean => {
    if (!date) return false;
    const today = UtilsComponent.strictDate(new Date());
    return date >= today;
  };

  public editDateFilter = (date: Date): boolean => {
    if (!date) return false;
    const today = UtilsComponent.strictDate(new Date());
    return new Date(new Date(date).setHours(0, 0, 0, 0)) <= today;
  };

  public cancelManualRegistration(): void {
    this.router.navigateByUrl(`/clients/events/dashboard/${this.eventModel.id}`);
  }

  private canDeactivateSubscription(): void {
    const ticketSource$: Observable<boolean> = this.ticketsGroup.valueChanges
      .pipe(
        map(() => this.ticketsGroup.pristine)
      );
    const registrationFormSource$: Observable<boolean> = this.registrationForm.valueChanges
      .pipe(
        map(() => this.registrationForm.pristine)
      );
    const manualRegisterFormSource$: Observable<boolean> = this.manualRegisterForm.valueChanges
      .pipe(
        map(() => this.manualRegisterForm.pristine)
      );

    this.subscription.add(
      merge(ticketSource$, registrationFormSource$, manualRegisterFormSource$)
        .pipe(
          filter(value => !value),
          tap((value: boolean) => this.checkCanDeactivate$.next(value)) // subscription in ManualRegistrationComponent
        )
        .subscribe()
    );

    this.subscription.add(
      this.triggerSaveOnDeactivate$.asObservable() //triggered in ManualRegistrationComponent
        .pipe(
          tap(() => this.registerDonor())
        )
        .subscribe()
    );
  }

  public onGoToNextStep(tab: number): void {
    if(tab === this.currentTab) return;
    this.currentTab = tab;
  }

  public getAddonType(typeNum: number): string {
    const result = ADD_ON_TYPE_OPTIONS.find((type) => {
      return type.value === typeNum;
    });

    return result.label;
  }

  public getTotalCostDiscount(coupon: EventCouponModel): number {
    if(!coupon || !coupon.couponType) return;
    if(coupon.couponType === CouponValue.Percent) {
      return this.totalCostWithoutGlobalPromo * (coupon.couponValue / 100);
    }
    if(coupon.couponType === CouponValue.Dollar) {
      return coupon.couponValue;
    }
  }

  public handlePrevStep(): void {
    if(this.currentTab === 0) return;
    this.onGoToNextStep(this.currentTab - 1);
  }

  public handleNextStep(): void {
    if(this.currentTab === this.lastTab) return;
    this.onGoToNextStep(this.currentTab + 1);
  }

  public handleCancelRegistration(): string {
    const currentURL = this.activeRouter.snapshot.url;
    const manualRegistrationEventId = this.activeRouter.snapshot.params.eventId;
    if(
      this.eventModel &&
      this.eventModel.id &&
      this.eventModel.friendlyURL &&
      this.eventModel.status === EventStatus.Live &&
      currentURL[0].path === 'registrationEvent'
    ) {
        return `/Event/${this.eventModel.friendlyURL}`;
      }
    if(currentURL[0].path === 'manualEventRegistration' && manualRegistrationEventId) {
      return `/clients/events/dashboard/${manualRegistrationEventId}`
    }

    return null;
  }

  public openCancelDialog(): void {
    const config = {data: {}}
    this.dialog.open(RegistrationCancelComponent, config)
  }

  public areFormsDirty(): boolean {
    if(this.ticketsGroup.dirty || this.addonsGroup.dirty || this.registrationForm.dirty || this.couponGroup.dirty) return true;
    return false;
  }

  private initTabList(): void {
    const priceTicket = this.eventModel?.priceTicketType;
    this.tabList = [];
    let priceTicketEventTabNames: {name: string, templateRef: TemplateRef<any>}[] = [
      {name: "Tickets", templateRef: this.ticketsTemplateRef},
      {name: "Add-On's", templateRef: this.addOnsTemplateRef},
      {name: "Contact", templateRef: this.contactTemplateRef},
      {name: "Payment", templateRef: this.paymentTemplateRef}
    ];
    if(!this.addons || this.addons.length === 0 || !this.areAddOnsAvailable) {
      priceTicketEventTabNames = priceTicketEventTabNames.filter(element => element.name !== "Add-On's");
    }
    if(priceTicket === this.PriceTicket.Ticket) {
      priceTicketEventTabNames.forEach((item, index) => {
        const tabModel: TabModel = {
          index: index,
          labelName: item.name,
          labelIndicator: index + 1,
          contentRef: item.templateRef,
        }
        this.tabList.push(tabModel);
      })
      this.lastTab = priceTicketEventTabNames.length - 1
      return;
    }
    priceTicketEventTabNames.shift();
    const nonPriceTicketEventTabNames: {name: string, templateRef: TemplateRef<any>}[] = priceTicketEventTabNames;
    nonPriceTicketEventTabNames.forEach((item, index) => {
      const tabModel: TabModel = {
        index: index,
        labelName: item.name,
        labelIndicator: index + 1,
        contentRef: item.templateRef,
      }
      this.tabList.push(tabModel);
    })
    this.lastTab = nonPriceTicketEventTabNames.length - 1
  }

  public isRegistrationCost(): boolean {
    const priceTicket = this.eventModel?.priceTicketType;
    const registrationCost: number = this.eventModel?.registrationFee
    if(priceTicket !== this.PriceTicket.Ticket &&
      !!registrationCost) {
      return true;
    }
    return false;
  }

  public handleRegisterClick(): void {
    this.totalCost === 0 && !this.isDonateFormVisible ? this.registerDonor() : this.paymentDetailsOpen(true);
  }

  public changeTabToContact() {
    let eventType = this.eventModel?.priceTicketType;
    if(!this.addons || this.addons.length === 0) {
      if(eventType === PriceTicket.Ticket) {
        this.currentTab = 1;
        return;
      }
      this.currentTab = 0;
    } else {
      if(eventType === PriceTicket.Ticket) {
        this.currentTab = 2;
        return;
      }
      this.currentTab = 1;
    }
  }

  public changeTabToAddOns() {
    let eventType = this.eventModel?.priceTicketType;
    if(eventType === PriceTicket.Ticket) {
      this.currentTab = 1;
      return;
    }
    this.currentTab = 0;
  }

  public changeTabToTicket() {
    this.currentTab = 0;
  }

  public participantsCountChangeHandler(event: number) {
    this.aditionalParticipantsCount = event;
    this.costChanged$.next();
  }

  public getRegistrationFee(): number {
    return this.eventModel.registrationFee + this.aditionalParticipantsCount * this.eventModel.registrationFee;
  }

  public hideRegisterButton(): boolean {
    if(this.currentTab !== this.lastTab || this.isManualRegistration) return true;
    return false;
  }

  public hideManualRegSaveButton(): boolean {
    if(this.currentTab !== this.lastTab || !this.isManualRegistration) return true;
    return false;
  }

  public getDonateButtonLabel(): string {
    if(this.isDonateFormVisible) return this.translate.instant('BUILD.You added a donation to your payment.');
    return this.translate.instant('BUILD.Would you like to add a donation to your payment?');
  }

  public getDonateButtonText(): string {
    if(this.isDonateFormVisible) return this.translate.instant('BUILD.REMOVE Donation from payment');
    return this.translate.instant('BUILD.DONATE now');
  }

  private showDonationInfoMessage(): void {
    if(!this.isDonateFormVisible) {
      this.toastrService.info(this.translate.instant('BUILD.You will be able to set donation details at the final registration step'))
    }
  }

  public getEarliestTicket(): TicketPackageModel {
    if(!this.eventModel.ticketPackageList || !this.eventModel.ticketPackageList.length) return null;
    let earliestTicket: TicketPackageModel = this.eventModel.ticketPackageList[0];
    this.eventModel.ticketPackageList.forEach(ticket => {
      if(new Date(ticket.startDate) < new Date(earliestTicket.startDate)) {
        earliestTicket = ticket;
      }
    })
    return earliestTicket;
  }

  public getEarliestTicketStartDate() {
    return new Date(this.getEarliestTicket().startDate);
  }

  public get eventTimeZone(): TimeZoneModel {
    if(!this.eventModel) return
    const timeZoneId: number = this.eventModel.timeZoneID;
    return this.timeZones.find(timeZone => timeZone.id === timeZoneId)
  }

  public isNoEndDate(schedular: EventModel) {
    let endDate = this.datePipe.transform(schedular.endDate, 'yyyy')
    return endDate === '2137' || endDate === null;
  }
}
