import { Inject, Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MerchantService } from '../../services/payments/merchant.service';
import { MerchantModel } from '../../models/payments/merchant.model';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { CSCardModel, CSCheckModel, CSResponseModel } from '../../models/payments/cs.response.model';
import { PaymentMethodType } from '../../models/enum/payment.method.type';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

declare global {
  interface Window {
    CollectJS: any;
  }
}

@Injectable()
export class CornerstoneService {

  public cornerstoneCCNumberError: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public isCornerstoneCCNumberValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public cornerstoneCCExpError: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public isCornerstoneCCExpValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public cornerstoneCCCvvError: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public isCornerstoneCCCvvValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public cornerstoneAccountError: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public isCornerstoneAccountValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public cornerstoneRoutingError: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public isCornerstoneRoutingValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public cornerstoneNameError: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public isCornerstoneNameValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public paymentProcessStarted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public paymentFailed: Subject<void> = new Subject<void>();
  public triggerSaveOrUpdate: Subject<void> = new Subject<void>();
  public cardToken: BehaviorSubject<string>;
  private inputBackground: string;

  private form: FormGroup;

  constructor(
    private merchantService: MerchantService,
    private toastrService: ToastrService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public translate: TranslateService,
  ) {}

  public configureCornerstone(clientId: string, formGroup: FormGroup, inputBackground: string = '#ffffff'): void {
    this.getCornerstoneToken(clientId).subscribe(this.addScript.bind(this));
    this.form = formGroup;
    this.inputBackground = inputBackground;
  }

  private getCornerstoneToken(clientId): Observable<string> {
    return this.merchantService.getModelByClientId(clientId)
      .pipe(
        map((merchant: MerchantModel) => merchant.cS_Public_security_key)
      );
  }

  private addScript(tokenizationKey: string): void {
    if (!tokenizationKey.match(/^.{6}-.{6}-.{6}-.{6}$/g)) {
      this.toastrService.error('Service not available due to the technical issues.<br>Please, contact the support team.<br>support@theauxilia.com', 'Error', {enableHtml: true});
      return;
    }
    if (window.CollectJS) {
      this.configureCollectJS();
    } else {
      const body = this.document.body;
      const script = this.renderer.createElement('script');
      this.renderer.setAttribute(script, 'src', 'https://secure.networkmerchants.com/token/Collect.js');
      this.renderer.setAttribute(script, 'type', 'text/javascript');
      this.renderer.setAttribute(script, 'async', 'true');
      this.renderer.setAttribute(script, 'data-tokenization-key', tokenizationKey);
      this.renderer.appendChild(body, script);
      script.onload = () => this.configureCollectJS();
    }
  }

  private configureCollectJS(): void {
    const collectJsConfig: any = {
      variant: 'inline',
      styleSniffer: false,
      googleFont: 'Roboto:300,400,500,700',
      customCss: {
        'border-width': '1px',
        'border-style': 'solid',
        'border-color': 'rgba(0, 0, 0, 0.32)',
        'height': '45px',
        'border-radius': '4px',
        'padding': '0 10px',
        'color': 'rgba(0, 100, 190, 1)',
        'font-size': '16px',
        'background-color': this.inputBackground,
      },
      invalidCss: {
        'border-width': '2px',
        'border-color': '#f44336'
      },
      validCss: {},
      placeholderCss: {
        'color': '#afafaf'
      },
      focusCss: {
        'border-width': '2px',
        'border-color': '#afafaf',
        'border-radius': '4px'
      },
      fields: {
        ccnumber: {
          selector: '#creditCardNumber',
          title: `${this.translate.instant('Card Number')}`,
          placeholder: '0000 0000 0000 0000'
        },
        ccexp: {
          selector: '#creditCardExpiration',
          title: `${this.translate.instant('Expiration Date')}`,
          placeholder: '00 / 00'
        },
        cvv: {
          display: 'required',
          selector: '#creditCardCVV',
          title: `${this.translate.instant('CVV Code')}`,
          placeholder: '000(0)'
        },
        checkaccount: {
          selector: '#checkaccount',
          title: `${this.translate.instant('Account Number')}`,
          placeholder: '0000 0000 (0000)'
        },
        checkaba: {
          selector: '#checkaba',
          title: `${this.translate.instant('Routing Number')}`,
          placeholder: '000 000 000'
        },
        checkname: {
          selector: '#checkname',
          title: `${this.translate.instant('Name on Checking Account')}`,
          placeholder: `${this.translate.instant('Name on Checking Account')}`
        }
      },
      validationCallback: (field, status, message) => {
        switch (field) {
          case 'ccnumber':
            this.isCornerstoneCCNumberValid.next(status);
            this.cornerstoneCCNumberError.next(message);
            break;
          case 'ccexp':
            this.isCornerstoneCCExpValid.next(status);
            this.cornerstoneCCExpError.next(message);
            break;
          case 'cvv':
            this.isCornerstoneCCCvvValid.next(status);
            this.cornerstoneCCCvvError.next(message);
            break;

          case 'checkname':
            this.isCornerstoneNameValid.next(status);
            this.cornerstoneNameError.next(message);
            break;
          case 'checkaccount':
            this.isCornerstoneAccountValid.next(status);
            this.cornerstoneAccountError.next(message);
            break;
          case 'checkaba':
            this.isCornerstoneRoutingValid.next(status);
            this.cornerstoneRoutingError.next(message);
            break;
        }
      },
      callback: (response: CSResponseModel) => {
        this.checkCSResponse(response);
      },
      timeoutCallback: () => {
        if (this.paymentType.value === PaymentMethodType.CreditCard) {
          this.showErrorMessage('Please fill all Credit Card data fields');
        } else {
          this.showErrorMessage('Please fill all ACH mandatory fields');
        }
        this.isCornerstoneCCNumberValid.next(true);
        this.cornerstoneCCNumberError.next('');
        this.isCornerstoneCCExpValid.next(true);
        this.cornerstoneCCExpError.next('');
        this.isCornerstoneCCCvvValid.next(true);
        this.cornerstoneCCCvvError.next('');
        this.isCornerstoneNameValid.next(true);
        this.cornerstoneNameError.next('');
        this.isCornerstoneAccountValid.next(true);
        this.cornerstoneAccountError.next('');
        this.isCornerstoneRoutingValid.next(true);
        this.cornerstoneRoutingError.next('');

        this.paymentProcessStarted.next(false);
        window.CollectJS.configure(collectJsConfig);
        this.paymentFailed.next();
      },
      timeoutDuration: 6000,
    };
    window.CollectJS.configure(collectJsConfig);
  }

  private showErrorMessage(message: string): void {
    this.toastrService.error(this.translate.instant(message), 'Error');
  }

  private checkCSResponse(response: CSResponseModel): void {
    console.log(response);
    const paymentType: PaymentMethodType = this.paymentType.value;
    if (paymentType === PaymentMethodType.CreditCard) {
      const {number, bin, exp, hash, type}: CSCardModel = response.card;
      if (!number || !bin || !exp || !type) {
        this.onInvalidTokenModel('Please fill all Credit Card data fields');
      } else {
        this.onValidTokenModel(response.token);
      }
    } else {
      const {name, aba, account, hash}: CSCheckModel = response.check;
      if (!name || !aba || !account) {
        this.onInvalidTokenModel('Please fill all ACH mandatory fields');
      } else {
        this.onValidTokenModel(response.token);
      }
    }
  }

  private get paymentType(): FormControl {
    return this.form.get('paymentType') as FormControl;
  }

  private onInvalidTokenModel(message: string): void {
    this.showErrorMessage(message);
    //this.scrollIntoView(this.creditCardRef);
    this.paymentProcessStarted.next(false);
    this.cardToken.next(null);
    this.paymentFailed.next();
  }

  private onValidTokenModel(token: string): void {
    this.cardToken.next(token);
    this.triggerSaveOrUpdate.next();
  }

  public setCardToken(cardToken: BehaviorSubject<string>): void {
    this.cardToken = cardToken;
  }
}
