<div class="email-builder-tool-settings-wrapper">
  <div class="email-builder-tool-settings-row">
    <app-image-loader
      [formGroup]="optionsForm"
      name="logoSrc"
      label="Upload Logo"
      [readOnly]="readOnly"
      [imageURL]="imageURL"
      (valueChanged)="imageUrlChanged($event); onUrlChanged()"
    ></app-image-loader>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="logo-setting">
      <div class="max-width-145 right-margin margin-bottom-16">
        <app-input
          label="Logo URL"
          [formGroup]="optionsForm"
          name="logoSrc"
          errorMassage="Logo URL"
          [low]="true"
          [disabled]="readOnly"
          (valueChanged)="imageUrlChanged($event); onUrlChanged()"
        ></app-input>
      </div>
      <div class="max-width-145 right-margin margin-bottom-16">
        <app-input
          label="Logo Target URL"
          [formGroup]="optionsForm"
          name="logoTarget"
          [low]="true"
          [disabled]="readOnly"
          (valueChanged)="onLogoTargetChanged()"
        ></app-input>
      </div>
      <app-button
        *ngIf="logoSrc && logoSrc.value && !imageURL && !readOnly"
        label="Edit Image"
        type="flat"
        (clickEvent)="editImage()"
        color="#0064be"
        width="140"
      ></app-button>
      <!--<app-button
        *ngIf="!!imageURL"
        label="Cancel"
        type="flat"
        (clickEvent)="cancelEditImage()"
        color="#ec4c47"
        width="140"
      ></app-button>-->
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="options">
      <div class="max-width-145 right-margin">
        <app-select
          [formGroup]="optionsForm"
          name="logoPosition"
          label="Logo Position"
          [low]="true"
          (valueChanged)="onOptionChanged('logoPosition')"
          [disabled]="readOnly"
          [selectOptions]="alignmentOptions"
        ></app-select>
      </div>
      <div class="max-width-145">
        <app-select
          [formGroup]="optionsForm"
          name="logoSize"
          label="Logo Size"
          [low]="true"
          (valueChanged)="onOptionChanged('logoSize')"
          [disabled]="readOnly"
          [selectOptions]="sizeOptions"
        ></app-select>
      </div>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <app-email-color-picker
      [formGroup]="optionsForm"
      label='Background color'
      name="background"
      [readOnly]="readOnly"
      (colorChanged)="onOptionChanged('background')"
    ></app-email-color-picker>
  </div>
  <div class="padding-source"></div>
</div>
