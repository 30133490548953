<div class="comment-wall-popup-wrapper" [formGroup]="form">
  <div class="comment-wall-popup-header">
    <span>{{'ADD NEW COMMENT' | translate}}</span>
    <app-cross-icon (click)="onClose()"></app-cross-icon>
  </div>
  <br>

  <app-input
    [formGroup]="form"
    name="firstName"
    errorMassage="First Name"
    label="First Name *"
  ></app-input>

  <app-input
    [formGroup]="form"
    name="lastName"
    errorMassage="Last Name"
    label="Last Name *"
  ></app-input>

  <app-input
    [formGroup]="form"
    name="email"
    errorMassage="Email"
    label="E-mail *"
  ></app-input>

  <app-textarea
    [formGroup]="form"
    name="comment"
    errorMassage="Comment"
    label="Comment *"
    minRows="3"
  ></app-textarea>

  <div class="captcha-form-row">
    <ngx-recaptcha2
      [siteKey]="siteKey"
      [useGlobalDomain]="false"
      size="normal"
      hl="en"
      theme="light"
      type="image"
      formControlName="reCaptchaToken">
    </ngx-recaptcha2>
    <div class="captcha-error" *ngIf="captchaError">{{'Please, check the checkbox' | translate}}</div>
  </div>

  <div class="comment-wall-popup-actions">
    <app-button
      type="flat"
      color="#f44336"
      label="Cancel"
      (clickEvent)="cancel()"
    ></app-button>

    <app-button
      type="flat"
      color="#0064be"
      label="Add"
      (clickEvent)="add()"
    ></app-button>
  </div>

</div>

