import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomDateAdapter } from '../custom.date.adapter';
import { BaseFormElementComponent } from '../base.form.element.component';
import { MatDatepicker } from '@angular/material/datepicker';
import { DatePickerHeaderComponent } from '../date-picker/date-picker-header/date-picker-header/date-picker-header.component';
import { TranslateService } from '@ngx-translate/core';

export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
  },
};

@Component({
  selector: 'app-year-date-picker',
  templateUrl: './year-date-picker.component.html',
  styleUrls: ['../new.base.form.element.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS },
  ],
})
export class YearDatePickerComponent extends BaseFormElementComponent implements OnChanges {
  @Input() startAt: any = null;
  @Input() isAdmin: boolean = false;
  @Input() startView: 'multi-year';
  @ViewChild('datepicker') private datepicker: MatDatepicker<Date>;
  public calendarHeaderComponent = DatePickerHeaderComponent;

  constructor(public translate: TranslateService) {
    super(translate);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public onOpened(): void {
    this.formControl.disable();
  }

  public onClosed(): void {
    this.formControl.enable();
  }

  public yearSelected(date) {
    this.formControl.setValue(date);
    this.datepicker.close();
    this.onDateChange(date);
  }

}
