
<div class="publish-event-wrapper" #publishEventWrapperRef *ngIf="useTemplateModelHtml; else layoutPreviewComponentTemplate"></div>

<ng-container *ngIf="useTemplateModelHtml; else layoutPreviewComponentTemplate">
  <ng-container *ngIf="!isEventCanceled; else eventCanceledTemplate">
    <app-mjml-renderer
      *ngIf="mjml$ | async as mjml"
      [eventId]="event.id"
      [mjml]="mjml"
      [showHeaderAndFooter]="showHeaderAndFooter$ | async"
    ></app-mjml-renderer>
  </ng-container>
</ng-container>

<ng-template #layoutPreviewComponentTemplate>
  <app-layout-preview></app-layout-preview>
</ng-template>

<ng-template #eventCanceledTemplate>
  <div class="w-percentage-100 flex-row justify-center mt-50 canceled-event">
    <img alt="check" src="/assets/icons/check1.svg" class="mr-15"> 
    <span>{{'BUILD.The event was canceled.' | translate}}</span>
  </div>
</ng-template>
