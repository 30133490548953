import {Component, OnInit} from '@angular/core';
import {BaseFormComponent} from '../base.form.component';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import {ClientDonorType} from '../../../../../models/enum/donor.type';
import {FormControl, Validators} from '@angular/forms';
import {DonationOrganizationType} from "../../../../../models/enum/donation.organization.type";

@Component({
  selector: 'app-pac',
  templateUrl: './pac.component.html',
  styleUrls: ['../base.form.scss']
})
export class PacComponent  extends BaseFormComponent implements OnInit {

  public clientDonorTypeOptions: FormElementDataModel[] = [
    {label: 'Individual', value: ClientDonorType.Individual},
    {label: 'Organization', value: ClientDonorType.Organization}
  ];

  public donationOrganizationalTypeOptions: FormElementDataModel[] = [
    {label: 'Sole Proprietor', value: DonationOrganizationType.SoleProprietor},
    {label: 'Partnership', value: DonationOrganizationType.Partnership},
    {label: 'LLC', value: DonationOrganizationType.LLC},
    {label: 'Other', value: DonationOrganizationType.Other}
  ];

  public ClientDonorType = ClientDonorType;

  constructor() {
    super();
  }

  ngOnInit() {
    this.organizationType.valueChanges.subscribe(res => {
      if(res === DonationOrganizationType.Other) {
        this.organizationTypeName.setValidators([Validators.required, Validators.maxLength(250)])
      }
        else {
        this.organizationTypeName.clearValidators();
        this.organizationTypeName.reset();
      }


    })
  }

  public get organizationName(): FormControl {
    return this.paymentForm.get('organizationName') as FormControl;
  }

  public get organizationType(): FormControl {
    return this.paymentForm.get('organizationType') as FormControl;
  }
  public get organizationTypeName(): FormControl {
    return this.paymentForm.get('organizationTypeName') as FormControl;
  }

  public get federalIDNumber(): FormControl {
    return this.paymentForm.get('federalIDNumber') as FormControl;
  }

  public get donorType(): FormControl {
    return this.paymentForm.get('donorType') as FormControl;
  }

  public donorTypeChanged(): void {
    const type = this.donorType.value as ClientDonorType;
    if (type === ClientDonorType.Organization) {
      this.organizationName.setValidators([Validators.required, Validators.maxLength(250)]);
      this.organizationType.setValidators([Validators.required, Validators.maxLength(250)]);
      this.organizationName.updateValueAndValidity();
      if(this.element.federalIDRequired) this.federalIDNumber.setValidators([Validators.required, Validators.maxLength(20)]);
      else this.federalIDNumber.setValidators([Validators.maxLength(20), Validators.pattern("^[a-zA-Z0-9\-&]+$")]);
      this.federalIDNumber.updateValueAndValidity();
    } else {
      this.organizationName.setValidators([Validators.maxLength(250)]);
      this.organizationType.setValidators([Validators.maxLength(250)]);
      this.organizationName.updateValueAndValidity();
      this.organizationType.updateValueAndValidity();
      this.federalIDNumber.setValidators([Validators.maxLength(20), Validators.pattern("^[a-zA-Z0-9\-&]+$")]);
      this.federalIDNumber.updateValueAndValidity();
    }
  }
  
}
