<div class="comment-wall-popup-wrapper">
  <div class="comment-wall-popup-header">
    <span>{{'COMMENT WALL' | translate}}</span>
    <app-cross-icon (click)="onClose()"></app-cross-icon>
  </div>

  <app-button
    type="flat"
    color="#0064be"
    label="Add New"
    (clickEvent)="addNew()"
  ></app-button>

  <app-comment-wall-element
    [comments$]="comments$"
    [entity]="data.entity"
  ></app-comment-wall-element>
</div>
