import { MatCheckboxChange } from "@angular/material/checkbox";
import { DumbTableColumnConfigModel, DumbTableConfigModel, SelectionChangedData } from "src/app/models/dumb-table-config.model";

export class Selector {
  private _config: DumbTableConfigModel;
  private _total: number;
  private _isSelectAll: boolean;
  touchedItems: any[] = [];
  rowId: string | number;

  constructor(config: DumbTableConfigModel) {
    this._config = config;
  }

  private get selectedItemsCount(): number {
    if(this._isSelectAll) return this._total - this.touchedItems?.length;
    return this.touchedItems.length;
  }

  clearSelection(): void {
    this._isSelectAll = false;
    this.touchedItems = [];
  }

  get isSelectable(): boolean {
    return this._config.selectable;
  }

  isColSelectable(column: DumbTableColumnConfigModel): boolean {
    return this.isSelectable && column.name === "select";
  }

  onSelectRowChange(row: any): void {
    if(this._isSelectAll) {
      if(this.isRowChecked(row)) {
        this.touchedItems.push(row);
      } else {
        this.removeItem(row);
      }
    } else {
      if(this.isRowChecked(row)) {
        this.removeItem(row);
      } else {
        this.touchedItems.push(row)
      }
    }
    
  }

  get selectionChangedData(): SelectionChangedData {
    return {
      isSelectAll: this._isSelectAll,
      touchedRows: this.touchedItems,
      selectedRowsCount: this.selectedItemsCount
    }
  }

  private removeItem(row: any) {
    const i: number = this.touchedItems.findIndex((item: any) => item[this.rowId] === row[this.rowId]);
    this.touchedItems.splice(i, 1);
  }

  get isSelectAllChecked(): boolean {
    return !!this._total &&  this.touchedItems?.length === this._total
  }

  get isSelectAllIntederminate(): boolean {
    return !this.isSelectAllChecked && this.touchedItems?.length > 0;
  }

  isRowChecked(row: any): boolean {
    const i: number = this.touchedItems.findIndex((item: any) => item[this.rowId] === row[this.rowId]);
    if(this._isSelectAll) {
      return i < 0;
    }
    return i >= 0;
  }

  onSelectAllChanged(data: SelectAllDataChangedModel): void {
    this._total = data.total;
    this._isSelectAll = data.event.checked;
    this.touchedItems = [];
  }
}

export interface SelectAllDataChangedModel {
  data: any[],
  total: number,
  event: MatCheckboxChange
}