import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import TicketPackageModel from '../../models/event/ticket.package.model';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import FormElementDataModel from '../../models/form.element.data.model';
import {TicketPackageAddonModel} from '../../models/event/ticket-package-addon.model';
import {TicketAddonTypeEnum} from '../../models/enum/ticket-addon-type.enum';
import {ADD_ON_TYPE_OPTIONS, DEFAULT_ADD_ON_LABEL, MS_PER_DAY} from '../../constants';
import {TicketPackageIncludeModel} from '../../models/event/ticket-package-include.model';
import {UtilsComponent} from '../utils.component';
import EventRegistrationModel, {
  AllEventTicketAddonModel,
  EventTicketAddonModel
} from '../../models/event/event-registration.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { EventAddOnTimesUsed } from 'src/app/models/event/event-add-on-times-used.model';
import { TimeZoneModel } from 'src/app/models/time-zone.model';
import { LookupService } from 'src/app/services/lookup/lookup.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-ticket-types-block',
  templateUrl: './ticket-types-block.component.html',
  styleUrls: ['./ticket-types-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketTypesBlockComponent implements OnInit, OnChanges {
  @Input() public ticketsFormGroup: FormGroup;
  @Input() public isPreviewMode: boolean = false;
  @Input() public isEditModeOne: boolean = false;
  @Input() public ticketPackageList: TicketPackageModel[] = [];
  @Input() public tickets: FormElementDataModel[] = [];
  @Input() public eventRegEditModel: EventRegistrationModel;
  @Input() public ticketChosenOptions: Map<string, any[]>;
  @Input() private currentServerTime: string | Date;
  @Input() private addOnsTimesUsed: EventAddOnTimesUsed[];
  @Input() private eventTimeZone: TimeZoneModel;

  public ticketsOptions: any[] = [];

  @Output() public ticketsChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() public ticketAddonValidator: EventEmitter<TicketPackageModel> = new EventEmitter<TicketPackageModel>();

  public ticketsAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public includeOptions: Map<number, FormElementDataModel[]> = new Map<number, FormElementDataModel[]>();
  public maxTicketsPerType: Map<string, FormElementDataModel[]> = new Map<string, FormElementDataModel[]>();
  public ticketsWithInvalidStartDate$: BehaviorSubject<TicketPackageModel[]> = new BehaviorSubject([]);
  private subscription: Subscription = new Subscription();
  private isDaylightTime: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private lookupService: LookupService,
  ) { }

  public ngOnInit(): void {
    if(this.currentServerTime && this.eventTimeZone) {
      const serverDateString: string = new Date(this.currentServerTime).toDateString();
      this.subscription.add(
        this.lookupService.getIsDaylightSavingTimeById(serverDateString, this.eventTimeZone.id)
          .subscribe(res => {
            this.isDaylightTime = res
          })
      )
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.ticketPackageList) {
      this.setTickets(changes.ticketPackageList.currentValue);
      changes.ticketPackageList.currentValue.forEach(ticket => {
        if(!this.isTicketStartDateValid(ticket)) {
          this.ticketsWithInvalidStartDate$.getValue().push(ticket);
        }
      })
    }
  }

  private setTickets(ticketPackageList): void {
    this.ticketsOptions = [];
    if (this.isPreviewMode) {
      this.ticketsAvailable.next(true);
    } else if (!ticketPackageList) {
      this.ticketsAvailable.next(false);
    } else {
      let hasTickets = false;
      ticketPackageList.forEach(ticket => {
        if (ticket.number - ticket.soldCount - ticket.reservedCount > 0) {
          hasTickets = true;
        }
      });

      ticketPackageList.sort((a: TicketPackageModel, b: TicketPackageModel) => a.order - b.order);

      ticketPackageList.forEach((ticket: TicketPackageModel) => {
        const ticketData: FormElementDataModel[] = [{ label: '', value: '' }];
        const ticketOption = ticket.maxBuyCount > this.ticketRemaining(ticket) ? this.ticketRemaining(ticket) : ticket.maxBuyCount;
        for (let index = 1; index <= ticketOption; index++) {
          ticketData.push({ label: index.toString(), value: index });
        }
        this.ticketsOptions.push({
          ticketName: ticket.name,
          ticketOptions: ticketData,
          ticketId: ticket.id
        });
        ticket.addons.forEach((addOn: TicketPackageAddonModel) => {
          if (addOn.includes && addOn.includes.length) {
            const options = addOn.includes
              .map((include: TicketPackageIncludeModel) => ({label: include.name, value: include.id}));
            this.includeOptions.set(addOn.id, options);
          }
        });
        const maxTicketsPerType = [];
        for (let i = 1; i <= ticket.inPackageCount * ticket.maxBuyCount; i++) {
          maxTicketsPerType.push({label: i, value: i});
        }
        this.maxTicketsPerType.set(ticket.id, maxTicketsPerType);
      });

      this.ticketsAvailable.next(hasTickets);
    }
  }

  public ticketRemaining(ticketPackage: TicketPackageModel): number {
    if (this.isPreviewMode) {
      return ticketPackage.number;
    } else {
      return ticketPackage.number - (ticketPackage.reservedCount + ticketPackage.soldCount);
    }
  }

  public ticketSelect(ticket: TicketPackageModel, formValue: any = 0): void {
    if (!ticket) return;
    const index = this.tickets.findIndex(e => e.value === ticket.id);
    const packageItem = this.ticketPackageList.find(ticketPackage => ticketPackage.id === ticket.id);
    const item = { value: ticket.id, label: ticket.name, amount: formValue * packageItem.inPackageCount };
    if (index !== -1) {
      formValue === '' ? this.tickets.splice(index, 1) : this.tickets.splice(index, 1, item);
    } else {
      if (formValue !== '') {
        this.tickets.push(item);
      }
    }
    this.resetAddOnsForm(ticket);
    if (this.ticketChosenOptions.get(ticket.id)) {
      this.ticketChosenOptions.delete(ticket.id);
    }
    this.addOnRelatedTicketMessage(ticket, formValue)
    this.ticketsChanged.emit();
    this.ticketAddonValidator.emit(ticket);
  }

  private addOnRelatedTicketMessage(ticket: TicketPackageModel, selectedCount: number): void {
    const addOnsLeft = UtilsComponent.getRequiredAddOnsLeftCount(ticket, this.addOnsTimesUsed);
    const addonsQuantity = ticket.inPackageCount > 1 ? selectedCount * ticket.inPackageCount : selectedCount;
    const result = addOnsLeft < addonsQuantity ? addOnsLeft : addonsQuantity;
    if(ticket.addons && ticket.addons.length && addonsQuantity && addOnsLeft > 0) {
      this.toastrService.info(this.translate.instant('EVENTS.Please select the given amount of related Add-Ons: ') + result);
    }
  }

  public addOnOption(addOn: TicketPackageAddonModel): string {
    if (addOn.type !== TicketAddonTypeEnum.Other) {
      const option = ADD_ON_TYPE_OPTIONS.find(({value}) => value === addOn.type);
      return option ? option.label : '';
    } else {
      return addOn.customType;
    }
  }

  public ticketFormGroupById(id: string): FormGroup {
    return this.ticketsFormGroup.get(id) as FormGroup;
  }

  public toggleAddOnOption(ticket: TicketPackageModel, addOnIndex: number, addOn: TicketPackageAddonModel): void {
    const control = this.addOnOptionIsCheckedControl(ticket, addOnIndex);
    if (control.value) {
      control.setValue(false);
      const includesFormArray = this.addOnOptionIncludesFormArray(ticket, addOnIndex);
      UtilsComponent.cleanFormArray(includesFormArray);
      this.deleteTicketChosenOptions(ticket, addOn);
    } else {
      control.setValue(true);
      this.addIncludeFormGroup(ticket, addOnIndex);
      this.addTicketChosenOptions(ticket, addOn);
    }
  }

  isTicketEndDateValid(ticket: TicketPackageModel) {
  return (new Date(ticket.endDate).getTime() + MS_PER_DAY) > new Date(this.currentServerTime).getTime()
  }

  private addTicketChosenOptions(ticket: TicketPackageModel, addOn: TicketPackageAddonModel): void {
    if (this.ticketChosenOptions.has(ticket.id)) {
      this.ticketChosenOptions.get(ticket.id).push({[addOn.id]: []});
      return;
    }
    this.ticketChosenOptions.set(ticket.id, [{[addOn.id]: []}]);
  }

  private deleteTicketChosenOptions(ticket: TicketPackageModel, addOn: TicketPackageAddonModel): void {
    this.ticketChosenOptions.get(ticket.id).forEach((item, index) => {
      if (item[addOn.id]) {
        this.ticketChosenOptions.get(ticket.id).splice(index, 1)
      }
    });
  }

  public disabledFormIncludeModel(includeModel: FormGroup): void {

  }

  public addOnFormGroup(ticket: TicketPackageModel, addOnIndex: number): FormGroup {
    return this.addOnsFormArray(ticket).controls[addOnIndex] as FormGroup;
  }

  private addOnsFormArray(ticket: TicketPackageModel): FormArray {
    return this.ticketFormGroupById(ticket.id).get('addons') as FormArray;
  }

  public addOnOptionIsCheckedControl(ticket: TicketPackageModel, addOnIndex: number): FormControl {
    return this.addOnFormGroup(ticket, addOnIndex).get('isChecked') as FormControl;
  }

  public addOnOptionIncludesFormArray(ticket: TicketPackageModel, addOnIndex: number): FormArray {
    return this.addOnFormGroup(ticket, addOnIndex).get('includes') as FormArray;
  }

  public quantityByTicketId(id: string): FormControl {
    return this.ticketFormGroupById(id).get('quantity') as FormControl;
  }

  private resetAddOnsForm(ticket: TicketPackageModel): void {
    ticket.addons.forEach((addOn, i) => {
      const includesFormArray = this.addOnOptionIncludesFormArray(ticket, i);
      UtilsComponent.cleanFormArray(includesFormArray);
    });
    this.addOnsFormArray(ticket).reset();
  };

  public addIncludeFormGroup(ticket: TicketPackageModel, addOnIndex: number): void {
    const addOn = ticket.addons[addOnIndex];
    const includes = this.addOnOptionIncludesFormArray(ticket, addOnIndex);
    includes.push(this.includeFormGroup(addOn));
  }

  private includeFormGroup(addOn: TicketPackageAddonModel): FormGroup {
    const includeValidators = addOn.includes.length ? [Validators.required] : [];
    return this.formBuilder.group({
      addonID: [addOn.id],
      includeID: ['', includeValidators],
      type: [addOn.type],
      customName: [addOn.customType],
      count: ['', includeValidators],
    });
  }

  public addOnLabel(ticket: TicketPackageModel, addOnIndex: number): string {
    const addOnTicketLabel = ticket.addons[addOnIndex].name;
    return addOnTicketLabel === DEFAULT_ADD_ON_LABEL ? `${DEFAULT_ADD_ON_LABEL} Type` : addOnTicketLabel;
  }

  public getIncludeOptions(ticket: TicketPackageModel, addOnIndex: number, includeIndex: number): FormElementDataModel[] {
    const includes = this.addOnOptionIncludesFormArray(ticket, addOnIndex).value as EventTicketAddonModel[];
    /*previously selected value*/
    const currentValue = includes[includeIndex].includeID;
    /*set with selected values*/
    const otherSelectedOptions: Set<number> = new Set<number>();
    includes.forEach(include => otherSelectedOptions.add(include.includeID));
    /*all options*/
    const availableOptions = this.includeOptions.get(ticket.addons[addOnIndex].id);
    /*leave current value and add options that were not selected before*/
    return availableOptions.filter((option: FormElementDataModel) => {
      return option.value === currentValue || !otherSelectedOptions.has(option.value);
    });
  }

  public getOptionsName(includeModel: AbstractControl, ticket: TicketPackageModel, addOnIndex: number, addOn: TicketPackageAddonModel, includeIndex: number): void {
    const customName = ticket.addons[addOnIndex].includes.filter(item => item.id === includeModel.get('includeID').value)[0].name;
    includeModel.get('customName').setValue(customName);
    this.ticketChosenOptions.get(ticket.id).forEach(item => {
      if (item[addOn.id]) {
        const optionObj: FormElementDataModel = {label: customName, value: includeModel.get('includeID').value, count: 0};
        if (item[addOn.id][includeIndex]) {
          item[addOn.id].splice(includeIndex, 1, optionObj);
          includeModel.get('count').reset();
          return;
        }
        item[addOn.id].push(optionObj);
      }
    });
    this.ticketsChanged.emit();
  }

  public getOptionCount(includeModel: AbstractControl, ticket: TicketPackageModel, addOnIndex: number, addOn: TicketPackageAddonModel): void {
    this.ticketChosenOptions.get(ticket.id).forEach(item => {
      if (item[addOn.id]) {
        item[addOn.id].forEach(select => {
          if (select.value === includeModel.get('includeID').value) {
            select.count = includeModel.get('count').value;
          }
        });
      }
    });
    this.ticketsChanged.emit();
  }

  public getQuantityOptions(ticket: TicketPackageModel, addOnIndex: number, includeIndex: number): FormElementDataModel[] {
    const includes = this.addOnOptionIncludesFormArray(ticket, addOnIndex).value as AllEventTicketAddonModel[];
    /*how many tickets were selected on current include control before*/
    const currentCount = includes[includeIndex].count;
    /*how many ticket can be selected*/
    const ticketsRemaining = this.getRemainingTickets(ticket, addOnIndex, includeIndex);
    if (currentCount && currentCount > 0) {
      /*leave previously selected value and add available*/
      return this.maxTicketsPerType.get(ticket.id).slice(0, currentCount + ticketsRemaining);
    } else {
      /*leave available value*/
      return this.maxTicketsPerType.get(ticket.id).slice(0, ticketsRemaining);
    }
  }

  public includeDescription(ticket: TicketPackageModel, addonValue: number, addOnIndex: number): string {
    let description = '';
    if (ticket.addons && addonValue) {
      description = ticket.addons[addOnIndex].includes.filter(item => item.id === addonValue)[0].description
    }
    return description;
  }

  /*Check whether "Add" button hidden*/
  public addIncludeFormGroupHidden(ticket: TicketPackageModel, addOnIndex: number, includeIndex: number): boolean {
    const formArraySize = this.addOnOptionIncludesFormArray(ticket, addOnIndex).length - 1;
    const includesSize = ticket.addons[addOnIndex].includes.length - 1;
    return includeIndex === includesSize || includeIndex < formArraySize || !this.getRemainingTickets(ticket, addOnIndex, includeIndex);
  }

  /*Check whether "Remove" button visible*/
  public removeIncludeFormGroupVisible(ticket: TicketPackageModel, addOnIndex: number, includeIndex: number): boolean {
    return this.addOnOptionIncludesFormArray(ticket, addOnIndex).length > 1;
  }

  private getRemainingTickets(ticket: TicketPackageModel, addOnIndex: number, includeIndex: number): number {
    /*how many tickets were selected*/
    const ticketsSelected = this.ticketFormGroupById(ticket.id).get('quantity').value * ticket.inPackageCount;
    const includes = this.addOnOptionIncludesFormArray(ticket, addOnIndex).value as AllEventTicketAddonModel[];
    /*calculate how many tickets were selected on all include controls*/
    const includesCount = includes.reduce(((accumulator: number, include: AllEventTicketAddonModel) => accumulator + include.count), 0);
    /*how many ticket can be selected*/
    return ticketsSelected - includesCount;
  }

  public removeIncludeOption(ticket: TicketPackageModel, addOnIndex: number, includeIndex: number): void {
    const addOn = ticket.addons[addOnIndex];
    this.addOnOptionIncludesFormArray(ticket, addOnIndex).removeAt(includeIndex);
    if (this.ticketChosenOptions.get(ticket.id)) {
      this.ticketChosenOptions.get(ticket.id).forEach(item => {
        if (item[addOn.id]) {
          item[addOn.id].splice(includeIndex, 1);
        }
      });
    }
  }

  private isTicketStartDateValid(ticket: TicketPackageModel): boolean {
    return new Date(ticket.startDate) <= new Date(this.currentServerTime);
  }

  public isTicketStartDateValidAsync(ticket: TicketPackageModel): Observable<boolean> {
    if(this.ticketsWithInvalidStartDate$.getValue().find(item => item.id === ticket.id)) {
      return of(false);
    }
    return of(true);
  }

  public ticketStartDate(ticket: TicketPackageModel): Date {
    const ticketStartDate: Date = new Date(ticket.startDate);
    if(this.isDaylightTime) {
      ticketStartDate.setHours(ticketStartDate.getHours() - 1);
      return ticketStartDate;
    }
    return ticketStartDate;
  }

  public getCurrentServerTime(): Date {
    return new Date(this.currentServerTime);
  }

  public handleCountdownStopped(ticket: TicketPackageModel): void {
    const newTicketList = this.ticketsWithInvalidStartDate$.getValue().filter(item => item.id !== ticket.id);
    this.ticketsWithInvalidStartDate$.next(newTicketList);
  }

  public getEventTimeZoneOffset(): number {
    return this.eventTimeZone.offset;
  }
}
