<div class="unauthorized-client-wrapper">
  <div class="unauthorized-client-close">
    <i class="fas fa-times close-icon" (click)="onClose()" matRipple></i>
  </div>

  <span class="unauthorized-client-title">{{data.title | translate: {value: data?.numberOfChanges} }}</span>

  <div class="unauthorized-client-actions">
    <app-button type="stroked" label="{{data?.firstButtonName || 'OK' | translate}}" color="#0064be" (clickEvent)="onClose()"
                width="{{data?.widthButton || '130'}}"></app-button>
  </div>
</div>
