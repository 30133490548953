import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from '../base.model.service';
import TemplateStatistic from 'src/app/models/templates/template.statistic.model';

@Injectable({
  providedIn: 'root'
})
export class TemplateStatisticService extends BaseModelService<
  TemplateStatistic
> {
  constructor(protected injector: Injector) {
    super(injector);
  }
  protected get controllerName(): string {
    return 'TemplateStatistic';
  }
}
