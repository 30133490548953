import { Injectable, OnDestroy } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription, combineLatest } from "rxjs";
import { StripeACHDonorDataUtil } from "../stripe-ach-donor-data/stripe-ach-donor-data.util";
import { StripeACHFormStateService } from "src/app/services/donation-forms/stripe-ach-form-state.service";
import { ToastrService } from "ngx-toastr";
import { filter, pairwise, switchMap, tap, zip } from "rxjs/operators";
import { PaymentMethod, PaymentMethodResult } from "@stripe/stripe-js";
import CountryModel from "src/app/models/internationalization/country.model";
import { StripeACHService } from "src/app/components/form-builder/stripe-ach.service";
import { StripeACHInstantVerificationHandlerService } from "../stripe-ach-instant-verification-handler-service/stripe-ach-instant-verification-handler.service";

@Injectable()
export class StripeACHFormEventHandlersService implements OnDestroy {
  private _subscription: Subscription = new Subscription();

  public constructor(
    private _toastrService: ToastrService,
    private _stripeACHFormStateService: StripeACHFormStateService,
    private _stripeACHService: StripeACHService,
    private _stripeACHInstantVerificationHandler: StripeACHInstantVerificationHandlerService
  ) {}

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public handleStripeACHFormReady(secondPaymentForm: FormGroup): void {
    this._subscription.add(
      this._stripeACHFormStateService.formReady$.asObservable()
        .subscribe(() => {
          if(!StripeACHDonorDataUtil.isDonorDataValid(secondPaymentForm)) {
            this._stripeACHFormStateService.disableForm();
          }
        })
    )
  }

  public handleStripeACHFormFocus(secondPaymentForm: FormGroup): void {
    this._subscription.add(
      this._stripeACHFormStateService.formFocused$.asObservable()
        .subscribe(() => {
          if(!StripeACHDonorDataUtil.isDonorDataValid(secondPaymentForm)) {
            secondPaymentForm.markAllAsTouched();
            this._toastrService.error('Please fill all mandatory fields');
          }
        })
    )
  }

  public handleSecondPaymentFormChange(secondPaymentForm: FormGroup): void {
    const firstName = secondPaymentForm.get("firstName").valueChanges;
    const lastName = secondPaymentForm.get("lastName").valueChanges;
    const email = secondPaymentForm.get("email").valueChanges;
    const phone = secondPaymentForm.get("phone").valueChanges;
    const streetAddress = secondPaymentForm.get("streetAddress").valueChanges;
    const zipCode = secondPaymentForm.get("zipCode").valueChanges;
    const city = secondPaymentForm.get("city").valueChanges;
    const state = secondPaymentForm.get("state").valueChanges;
    const country = secondPaymentForm.get("country").valueChanges;

    this._subscription.add(
      combineLatest([firstName, lastName, email, phone, streetAddress, zipCode, city, state, country])
        .pipe(
          pairwise(),
          filter(([prev, curr]) => JSON.stringify(prev) !== JSON.stringify(curr))
        )
        .subscribe(() => {
          if(StripeACHDonorDataUtil.isDonorDataValid(secondPaymentForm)) {
            this._stripeACHFormStateService.enableForm();
          } else {
            this._stripeACHFormStateService.disableForm();
          }
        })
    )
  }

  public handleStripeACHFormCompletion(secondPaymentForm: FormGroup, countries: CountryModel[], onPostDonationAmountBump: () => void): void {
    this._subscription.add(
      this._stripeACHFormStateService.formCompleted$.asObservable()
        .pipe(
          switchMap(() => this._stripeACHService.createPaymentMethodObj(StripeACHDonorDataUtil.getDonorData(secondPaymentForm, countries)))
        )
        .subscribe((res: PaymentMethodResult) => {
          const usBankAccount: PaymentMethod.UsBankAccount = res.paymentMethod?.us_bank_account;

          if(!!usBankAccount) {
            if(this.shouldBumpCurrentStripeACHInstantVerificationFee(usBankAccount)) {
              this._stripeACHInstantVerificationHandler.bumpCurrentStripeACHInstantVerificationFee()
              onPostDonationAmountBump();
            }
            this._stripeACHService.usBankAccounts.push(usBankAccount);
          }
        }))
  }

  private shouldBumpCurrentStripeACHInstantVerificationFee(usBankAccount: PaymentMethod.UsBankAccount): boolean {
    const wasSelected: boolean = !!this._stripeACHService.usBankAccounts.find(selectedBankAccount => selectedBankAccount.fingerprint == usBankAccount.fingerprint)
    return !!usBankAccount.financial_connections_account && !wasSelected
  }
}