export enum DonationMethodType {
  Cash = 0,
  CreditCard = 1, // Default value
  Check = 2,
  MoneyOrder = 3,
  Bitcoin = 4,
  MobilePay = 5,
  Venmo = 6,
  Zelle = 7,
  'N/A' = 8,
  ACH = 9
}
