import { Component, OnDestroy, OnInit,ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { authConfig } from '../models/auth.config';
import { BehaviorSubject, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { ConfigService } from '../services/appconfig.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { delay, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GlobalVariable } from '../shared/service';
import { LoaderService } from '../services/loader.service';
import { AuthService } from '../services/auth.service';
import { UploadDonorsService } from '../services/client.donors/upload-donors.service';
import { UploadDonorFileModel } from '../models/donor/upload-donor-file.model';
import { ClientEmployeeModel } from '../models/client/client.employee.model';
import {
  AUXILIA_ADMIN,
  CLIENT_ADMIN,
  CONTENT_CREATOR,
  DONOR_MANAGER,
  FUNDRAISING_MANAGER,
  SUPER_ADMIN
} from '../constants';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AdminConfirmationComponent } from '../routes/admin/admin-confirmation/admin-confirmation.component';
import { NotificationsComponent } from '../shared/components/notifications/notifications.component';
import { UserRolesType } from '../models/enum/user.roles.type';
import { InfoNotificationService } from '../services/reports/info.notification.service';
import { InfoNotificationModel } from '../models/reports/info.notification.model';
import { PagingModel } from '../models/paging/paging.model';
import { SortOrder } from '../models/enum/sort.order';
import { FilterType } from '../models/enum/filter.type';
import { PortalUrls } from '../models/enum/portal.urls';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
//import { Keepalive } from '@ng-idle/keepalive';
import { ClientDonorService } from '../services/client.donors/client-donor.service';
import { UploadDonorResultModel } from '../models/donor/upload-donor-result.model';
import { AppConfig } from '../models/app.config';
import { ClientModuleService } from '../routes/clients/client.module.service';
import { DonorUploadModel } from '../models/donor/donor.upload.model';
import { TranslateService } from '@ngx-translate/core';
import { IconsType } from '../models/enum/icons.type';
import { ClientChildRelationshipService } from '../services/client-relationship.service';
import { ClientRelationshipModel } from '../models/client/client.relationship.model';
import FormElementDataModel from '../models/form.element.data.model';

enum Filters {
  Roles = 2,
  Invitation = 3,
  UploadDonors = 4,
  PublishFundraiser = 5,
  FundraiserNotification = 6
}

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private modelNotificationsFilter = new PagingModel();
  private newNotificationsFilter: PagingModel[] = [];
  public isMobileMenuOpen: boolean = false;
  public isDataLoading: boolean = false;
  public currentYear: number = new Date().getFullYear();
  public notificationCount: number;
  public appVersion: string = '';
  public isAdminPortal: boolean = false;
  public IconsType = IconsType;

  public commonNotificationFilterDate = {
    sortField: 'createdOn',
    sortOrder: SortOrder.Descending,
    includeDependencies: true,
    includeDeleted: false
  };

  public isLoggedIn: boolean = false;
  public urlSelected$: Observable<boolean>;

  public showUploadResults: boolean = false;
  public isFundraisingManager: boolean;
  public isContentCreator: boolean;
  public isDonorManager: boolean;
  public infoNotifications: InfoNotificationModel[] = [];
  public isContentCreatorOrFundraisingManagerOrClientAdmin: boolean;
  public notifications$: Observable<InfoNotificationModel[]>;
  public isClientAdmin: boolean;

  public interval$: Observable<number> = timer(3000, 5000);
  public finishCheckImport$: Subject<void> = new Subject<void>();

  private authInterval$: Observable<number> = timer(1000, 60000);

  public showLanguagePicker$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    public router: Router,
    private configService: ConfigService,
    private oauthService: OAuthService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private uploadDonorService: UploadDonorsService,
    private infoNotificationService: InfoNotificationService,
    private dialog: MatDialog,
    private idle: Idle,
    //private keepalive: Keepalive,
    private clientDonorService: ClientDonorService,
    private clientModuleService: ClientModuleService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private clientChildRelationshipService: ClientChildRelationshipService
  ) {
    this.settingsLogout();

    this.subscription.add(
      this.configService.loadConfig().subscribe((config) => {
        const ac = Object.assign({}, authConfig);
        ac.issuer = config.authority;
        this.oauthService.configure(ac);
        //this.oauthService.tokenValidationHandler = new JwksValidationHandler();
        this.oauthService.loadDiscoveryDocumentAndTryLogin().then((data) => {
          this.configService.onConfigLoaded.emit(true);
          GlobalVariable.oathService = this.oauthService;
        });
      })
    );

    this.subscription.add(
      this.oauthService.events.subscribe((event) => {
        //console.log(new Date().toJSON().split('T')[1], event);
        /*if (event.type === 'token_expires' && this.isLoggedIn) {
          this.oauthService.refreshToken();
        }*/
        if (event.type === 'token_refresh_error') {
          console.log('Logout on token_refresh_error');
          this.onLogout(true);
        }
      })
    );

    this.subscription.add(
      this.authInterval$.subscribe(val => {
        const expiresAt = localStorage.getItem('expires_at');
        if (!expiresAt) {
          return;
        }
        const moment = Date.now();
        if (+expiresAt - moment <= 270000 && this.isLoggedIn) {
          console.log('refreshToken');
          this.oauthService.refreshToken();
        } else {
          return;
        }
      })
    );
  }

  private settingsLogout(): void {
    this.idle.setIdle(1800);
    this.idle.setTimeout(5);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.subscription.add(
      this.idle.onIdleEnd.subscribe(() => {
        if (this.oauthService.hasValidAccessToken()) {
          this.idle.watch();
        } else {
          //console.log('Logout on IdleEnd');
          this.onLogout(true);
        }
      })
    );

    this.subscription.add(
      this.idle.onTimeout.subscribe(() => {
        //console.log('Logout on Timeout');
        this.onLogout(true);
      })
    );

    this.subscription.add(
      this.idle.onTimeoutWarning.subscribe((countdown) => {
        //console.log('LogOut in:', countdown);
      })
    );

    if (this.oauthService.hasValidAccessToken()) {
      this.idle.watch();
    }
  }

  public ngOnInit(): void {
    this.isAdminPortal = this.router.url.includes('/admin-dashboard/');
    this.modelNotificationsFilter.includeDependencies = true;
    this.modelNotificationsFilter.sortOrder = SortOrder.Descending;
    this.modelNotificationsFilter.sortField = 'createdOn';
    this.checkLoginState();

    this.subscription.add(
      this.configService.loadConfig().subscribe((config: AppConfig) => this.appVersion = config.appVersion)
    );

    this.subscription.add(
      this.loaderService.loader.pipe(delay(0)).subscribe((value: boolean) => (this.isDataLoading = value))
    );

    this.uploadDonor();

    this.subscription.add(
      this.uploadDonorService.resultSubject.subscribe((response) => {
        this.showUploadResults = response != null;
      })
    );

    if (localStorage.getItem('donorImportStarted')) {
      this.checkImport();
    }

    this.urlSelected$ = this.activatedRoute.url
      .pipe(
        tap((urlSegment: UrlSegment[]) => {
          if (urlSegment && urlSegment.length) {
            this.showLanguagePicker$.next(urlSegment[0].path !== 'admin-dashboard');
          } else {
            this.showLanguagePicker$.next(true);
          }
        }),
        map((urlSegment: UrlSegment[]) => !!urlSegment.length)
      );

    this.checkMasqueradeAsFeature();
    this.cdr.detectChanges();
  }

  private getRoles(): string[] {
    return this.authService.getIdentityClaimsRole();
  }

  public getArrQueryVal(val: string[]): string {
    return val.map((item: string) => `"${item}"`).join(',');
  }

  private createNotificationsFilter() {
    const values = this.getArrQueryVal([
      UserRolesType.FUNDRAISING_MANAGER,
      UserRolesType.CONTENT_CREATOR
    ]);

    this.modelNotificationsFilter.filters = [
      {
        field: 'clientID',
        value: this.authService.getIdentityClaimsOriginId(),
        type: FilterType.Equal
      },
      {
        field: 'userId',
        value: this.authService.getIdentityClaimsId(),
        type: FilterType.Equal
      },
      {
        field: 'IsDeleted',
        value: 'false',
        type: FilterType.Equal
      }
    ];
    this.newNotificationsFilter = [
      {
        ...this.commonNotificationFilterDate,
        filters: [
          {
            field: 'clientID',
            value: this.authService.getIdentityClaimsOriginId(),
            filterType: FilterType.Equal
          },
          {
            field: 'userId',
            value: this.authService.getIdentityClaimsId(),
            filterType: FilterType.Equal
          },
          {
            field: 'IsDeleted',
            value: 'false',
            filterType: FilterType.Equal
          }
        ]
      }
    ];

    if (this.isContentCreator && this.isFundraisingManager) {
      this.modelNotificationsFilter.filters[Filters.Roles] = {
        field: 'roles',
        value: `array[${values}]`,
        type: FilterType.Equal
      };
    } else if (this.isContentCreator) {
      this.modelNotificationsFilter.filters[Filters.Roles] = {
        field: 'roles',
        value: UserRolesType.CONTENT_CREATOR,
        type: FilterType.Equal
      };
    } else if (this.isFundraisingManager) {
      this.modelNotificationsFilter.filters[Filters.Roles] = {
        field: 'roles',
        value: UserRolesType.FUNDRAISING_MANAGER,
        type: FilterType.Equal
      };
    } else if (this.isDonorManager) {
      this.modelNotificationsFilter.filters[Filters.Roles] = {
        field: 'roles',
        value: UserRolesType.DONOR_MANAGER,
        type: FilterType.Equal
      };
    }

    if (this.isClientAdmin || this.isFundraisingManager) {
      this.modelNotificationsFilter.filters[Filters.Invitation] = {
        field: 'triggerName',
        value: 'Invitation',
        type: FilterType.Equal
      };
      this.newNotificationsFilter[Filters.Invitation] = {
        ...this.commonNotificationFilterDate,
        filters: [
          {
            field: 'clientID',
            value: this.authService.getIdentityClaimsOriginId(),
            filterType: FilterType.Equal
          },
          {
            field: 'triggerName',
            value: 'Invitation',
            filterType: FilterType.Equal
          }
        ]
      };
      this.modelNotificationsFilter.filters[Filters.UploadDonors] = {
        field: 'triggerName',
        value: 'UploadDonors',
        type: FilterType.Equal
      };
      this.newNotificationsFilter[Filters.UploadDonors] = {
        ...this.commonNotificationFilterDate,
        filters: [
          {
            field: 'clientID',
            value: this.authService.getIdentityClaimsOriginId(),
            filterType: FilterType.Equal
          },
          {
            field: 'triggerName',
            value: 'UploadDonors',
            filterType: FilterType.Equal
          }
        ],
        sortField: 'createdOn',
        sortOrder: 1
      };
      // P2P
      this.modelNotificationsFilter.filters[Filters.PublishFundraiser] = {
        field: 'triggerName',
        value: 'PublishFundraiser',
        type: FilterType.Equal
      };
      this.newNotificationsFilter[Filters.PublishFundraiser] = {
        ...this.commonNotificationFilterDate,
        filters: [
          {
            field: 'clientID',
            value: this.authService.getIdentityClaimsOriginId(),
            filterType: FilterType.Equal
          },
          {
            field: 'triggerName',
            value: 'PublishFundraiser',
            filterType: FilterType.Equal
          },
          {
            field: 'userId',
            value: this.authService.getIdentityClaimsId(),
            filterType: FilterType.Equal
          }
        ],
        sortField: 'createdOn',
        sortOrder: 1
      };
      this.newNotificationsFilter = this.newNotificationsFilter.filter(
        (val) => !!val
      );
    }
  }

  private getChildNotifications(childClientID: string): Observable<InfoNotificationModel[]> {
    let filterArray = this.newNotificationsFilter;
    this.createNotificationsFilter();
    const intervalNotification = timer(0, 900000);
    filterArray.forEach((filtersArray, index) => {
      filtersArray.filters.forEach((filter, childIndex) => {
        if (filter.field === "clientID") {
          filter.value = childClientID;
        }
        if (filter.field === "userId") {
          filterArray[index].filters.splice(childIndex, 1)
        }
      })
    });
    return intervalNotification.pipe(switchMap(() => this.infoNotificationService.filterList(filterArray)));
  }

  private getNotifications(): Observable<InfoNotificationModel[]> {
    this.createNotificationsFilter();
    const intervalNotification = timer(0, 900000);
    return intervalNotification.pipe(switchMap(() => this.infoNotificationService.filterList(this.newNotificationsFilter)));
  }

  public openNotifications(): void {
    this.infoNotifications.forEach(notification => notification.updatedOn = notification.updatedOn ? notification.updatedOn : notification.createdOn);
    this.infoNotifications.sort((a, b) => new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime());
    if (!this.isContentCreatorOrFundraisingManagerOrClientAdmin) {
      return;
    }
    const config: MatDialogConfig = {
      position: { right: 'right', top: '64px' },
      panelClass: 'notification-custom',
      data: this.infoNotifications
    };
    this.subscription.add(
      this.dialog.open(NotificationsComponent, config).afterClosed()
        .pipe(
          filter((data) => data.length),
          switchMap(() => this.infoNotificationService.filterList(this.newNotificationsFilter))
        )
        .subscribe((infoNotifications) => {
          this.infoNotifications = infoNotifications;
          this.notificationCount = infoNotifications.filter((notif) => !notif.isRead).length;
          this.addChildNotifications(); //closing notifications is refreshing them therefore child notifications have to be added again
        })
    );
  }

  public ngOnDestroy(): void {
    this.idle.stop();
    this.subscription.unsubscribe();
  }

  private uploadDonor(): void {
    this.subscription.add(
      this.uploadDonorService.uploadSubscription.subscribe((response: DonorUploadModel) => {
        const file = response.file;
        const clientId = response.clientId;
        const type = file.type === 'application/vnd.ms-excel' || file.type === 'text/csv' || file.type === 'text/comma-separated-values' ? 'csv' : 'xlsx';
        const reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this, type, file.sendInvitations, clientId);
        reader.readAsArrayBuffer(file); //for IE11 support
      })
    );
  }

  private handleReaderLoaded(type: string, sendInvitations: boolean, clientId: string, e: any): void {
    let binary = '';
    const bytes = new Uint8Array(e.target.result);
    const length = bytes.byteLength;
    for (let i = 0; i < length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const model: UploadDonorFileModel = {
      clientID: clientId,
      userID: this.authService.getIdentityClaimsId(),
      userName: this.authService.getIdentityClaimsName(),
      toImport: btoa(binary),
      type: type,
      sendInvitations: sendInvitations,
      importId: this.authService.getIdentityClaimsOriginId()
    };
    const timeout = timer(20000); // dirty hack to avoid timeout error
    this.uploadDonorService.sendDonorsFile(model).pipe(takeUntil(timeout)).subscribe((response) => {
      /*this.uploadDonorService.resultEmit(response);*/
    },
      (error) => {
        const message: string = error.error.message;
        this.uploadDonorService.headersError = true;
        this.uploadDonorService.mandatoryError = true;
      }
    );
    localStorage.setItem('donorImportStarted', this.authService.getIdentityClaimsOriginId());
    this.checkImport();
  }

  private checkImport(): void {
    const id = localStorage.getItem('currentSelectedClientID');
    this.subscription.add(
      this.interval$.pipe(takeUntil(this.finishCheckImport$)).subscribe(x => {
        this.clientDonorService.checkImport(id).subscribe((uploadDonorResult: UploadDonorResultModel) => {
          if (!uploadDonorResult || uploadDonorResult.isFinished) {
            this.finishCheckImport$.next();
            localStorage.removeItem('donorImportStarted');
          }
          this.uploadDonorService.resultEmit(uploadDonorResult);
        })
      })
    );
  }

  private defineRoles(): void {
    this.isFundraisingManager = this.getRoles() && this.getRoles().includes(UserRolesType.FUNDRAISING_MANAGER);
    this.isContentCreator = this.getRoles() && this.getRoles().includes(UserRolesType.CONTENT_CREATOR);
    this.isClientAdmin = this.getRoles() && this.getRoles().includes(UserRolesType.CLIENT_ADMIN);
    this.isDonorManager = this.getRoles() && this.getRoles().includes(UserRolesType.DONOR_MANAGER);
    this.isContentCreatorOrFundraisingManagerOrClientAdmin = this.isFundraisingManager || this.isContentCreator || this.isClientAdmin || this.isDonorManager;
  }

  private checkLoginState(): void {
    this.subscription.add(
      this.authService.isLoggedIn.subscribe((loggedIn: boolean) => {
        this.isLoggedIn = loggedIn;
        if (loggedIn) {
          this.defineRoles();
          if (this.isContentCreatorOrFundraisingManagerOrClientAdmin) {
            this.notifications$ = this.getNotifications().pipe(tap((infoNotifications) => {
              this.infoNotifications = infoNotifications;
              this.notificationCount = infoNotifications.filter((notif) => !notif.isRead).length;
              this.addChildNotifications();
            }));
          }
        }
      })
    );
  }

  private addChildNotifications() {
    let children: ClientRelationshipModel[] = this.clientChildRelationshipService.getAllChildrenForNotifications();
    children?.forEach((child) => {
      this.getChildNotifications(child.childID).subscribe(
        (childNotifications) => {
          this.infoNotifications = [
            ...this.infoNotifications,
            ...childNotifications,
          ];
          this.notificationCount += childNotifications.filter(
            (notif) => !notif.isRead,
          ).length;
        },
      );
    });
  }

  public logout(): void {
    const config = {
      data: {
        title: this.isAdmin ? 'Are you sure you want to log out?' : this.translate.instant('LAYOUT.Are you sure you want to log out?')
      }
    };
    this.subscription.add(
      this.dialog.open(AdminConfirmationComponent, config).afterClosed()
        .pipe(switchMap((isYes) => (isYes ? of(this.onLogout(false)) : of(null))))
        .subscribe()
    );
  }

  private onLogout(isForced: boolean) {
    this.authService.onLogout(isForced);
    this.notificationCount = 0;
    this.isDataLoading = false;
  }

  public toggleMenu(): void {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  public onContentClick(): void {
    this.isMobileMenuOpen = false;
  }

  private get roles(): string[] {
    return this.authService.getIdentityClaimsRole();
  }

  public get isAdmin(): boolean {
    return this.roles.includes(AUXILIA_ADMIN) || this.roles.includes(SUPER_ADMIN);
  }

  public onLogoClick(): void {
    this.isLoggedIn ? this.router.navigateByUrl('/') : (window.location.href = 'https://www.theauxilia.com/');
  }

  public get adminPermission(): boolean {
    return this.roles.includes(CLIENT_ADMIN);
  }

  public get templatePermission(): boolean {
    return (
      this.roles.includes(CLIENT_ADMIN) ||
      this.roles.includes(FUNDRAISING_MANAGER) ||
      this.roles.includes(CONTENT_CREATOR)
    )
  }

  public get adminManagerPermission(): boolean {
    return this.roles.includes(CLIENT_ADMIN) || this.roles.includes(FUNDRAISING_MANAGER);
  }

  public get donorManagerPermission(): boolean {
    return this.roles.includes(DONOR_MANAGER);
  }


  public get creatorManagerPermission(): boolean {
    return this.roles.includes(FUNDRAISING_MANAGER) || this.roles.includes(CONTENT_CREATOR);
  }

  public masqueradeLogout(): void {
    this.authService.cleanMasquerade();
    this.clientModuleService.clearState();
    this.router.navigateByUrl(PortalUrls.AdminPortal.substring(1));
    this.notificationCount = 0;
    this.isContentCreatorOrFundraisingManagerOrClientAdmin = null;
    this.clearClientDataInLocalStorage();
  }

  public get isMasquerade(): boolean {
    return !!this.authService.masqueradeAs;
  }

  public get masqueradeName(): string {
    const { firstName, lastName }: ClientEmployeeModel = this.authService.masqueradeAsRecord;
    return `${firstName} ${lastName}`;
  }

  public get masqueradeOrganization(): string {
    return this.authService.masqueradeAsRecord.client.name;
  }

  private clearClientDataInLocalStorage(): void {
    this.clientModuleService.clearStorage();
  }

  private checkMasqueradeAsFeature(): void {
    this.subscription.add(
      this.interval$
        .pipe(
          tap(() => {
            const masqueradeAs = this.authService.masqueradeAs;
            if (!masqueradeAs) {
              return;
            }
            const currentSelectedClientID = localStorage.getItem('currentSelectedClientID');
            const masqueradeAsRecord = this.authService.masqueradeAsRecord;
            const parentClients: FormElementDataModel[] = JSON.parse(localStorage.getItem("clientsWithRelationshipsToCurrentClient"));
            
            if (this.shouldMasqueradeLogout(currentSelectedClientID, masqueradeAsRecord, parentClients)) {
              this.masqueradeLogout();
            }
          })
        )
        .subscribe()
    );
  }

  private shouldMasqueradeLogout(currentSelectedClientID: string, masqueradeAsRecord: ClientEmployeeModel, parentClients: FormElementDataModel[]): boolean {
    if(!currentSelectedClientID || !masqueradeAsRecord) return true;

    if(currentSelectedClientID !== masqueradeAsRecord.client.id) {
      return masqueradeAsRecord.client.clientRelationship.every(d => d.childID !== currentSelectedClientID) 
        && !parentClients.filter((parent: FormElementDataModel) => parent?.value === currentSelectedClientID)?.length
    }

    return false;
  }
}
