import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import * as beautify from 'js-beautify';
import * as ace from 'ace-builds';

const DIMMENSIONS_UNIT: string = "in";

@Component({
  selector: 'app-label-editor',
  templateUrl: './label-editor.component.html',
  styleUrls: ['./label-editor.component.scss', '../../../shared/styles/layout.scss']
})
export class LabelEditorComponent implements OnChanges, AfterViewInit, OnInit {
  @Input() formGroup: FormGroup;
  @Input() name: string
  @Input() width: number = 2;
  @Input() height: number = 2;
  @Input() placeholders: {id: string | number, label: string}[];
  @Input() disabled: boolean;
  @ViewChild("quillEditor") private quillComponent: QuillEditorComponent;
  @ViewChild("htmlEditor") private aceEditorRef: ElementRef<HTMLElement>;

  modules: QuillModules;

  constructor() { 
    this.modules = {
      placeholder: {
        delimiters: ['[', ']'],
        placeholders: []
      }
    }
  }

  ngOnInit(): void {
    if(this.placeholders?.length) {
      this.modules.placeholder.placeholders = this.placeholders
    }
    
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setEditorDimmensions();
    })

    this.aceSetup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.width?.currentValue || changes.height?.currentValue) {
      this.setEditorDimmensions();      
    }
  }

  private setEditorDimmensions(): void {
    const innerLabelEditor: HTMLElement = this.quillComponent?.editorElem.getElementsByClassName("ql-editor")[0] as HTMLElement;
    if(!innerLabelEditor) return;
    innerLabelEditor.style["width"] = `${this.width}${DIMMENSIONS_UNIT}`;
    innerLabelEditor.style["height"] = `${this.height}${DIMMENSIONS_UNIT}`;
  }

  private aceSetup(): void {
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    ace.config.set('fontSize', '14px');
    const aceEditor = ace.edit(this.aceEditorRef.nativeElement);
    ace.edit(this.aceEditorRef.nativeElement).session.setUseWrapMode(true);
    aceEditor.session.setValue(beautify.html(this.formGroup.get(this.name)?.value));
    aceEditor.setTheme('ace/theme/textmate');
    aceEditor.session.setMode('ace/mode/html');
    // aceEditor.on('blur', () => {
    //   const value = aceEditor.getValue().replace(/\n|\t/g, '');
    //   this.formGroup.get(this.name)?.setValue(value);
    // });

    aceEditor.setReadOnly(this.disabled);
  }

  get htmlContent(): string {
    return this.formGroup.get(this.name)?.value;
  }

  setAceEditorValue(): void {
    ace.edit(this.aceEditorRef.nativeElement).session.setValue(beautify.html(this.formGroup.get(this.name)?.value));
  }

}
