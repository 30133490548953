import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-donor-popup',
  templateUrl: './delete-donor-popup.component.html',
  styleUrls: ['./delete-donor-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteDonorPopupComponent {
  public reasonForm: FormGroup = this.formBuilder.group({
    reason: ['', [Validators.required]]
  })
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dialogRef: MatDialogRef<DeleteDonorPopupComponent>,
    private formBuilder: FormBuilder
  ) { }


  public isInvalid(): boolean {
    return this.reasonForm.invalid;
  }

  public get getReason(): string {
    return this.reasonForm.get('reason').value;
  }

  public onClose(execAction: boolean) {
    this.dialogRef.close({ exec: execAction, reason: this.getReason });
  }
}
