import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';
import { AzureUploadFileService } from '../../../../services/lookup/azure-upload-file.service';
import { AzureFileModel } from '../../../../models/files/azure-file.model';

@Component({
  selector: 'app-file-loader',
  templateUrl: './file-loader.component.html',
  styleUrls: ['../loader.scss']
})
export class FileLoaderComponent implements OnDestroy {
  @Input()
  public formGroup: FormGroup;
  @Input()
  public name: string;
  @Input()
  public label: string;
  @Input() public readOnly: boolean = false;
  private subscription: Subscription = new Subscription();
  public apiURL: string = localStorage.getItem('apiurl');

  @Output() public valueChanged: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('fileLoaderInputRef') public fileLoaderInputRef: ElementRef;

  constructor(
    private toastrService: ToastrService,
    private uploadService: AzureUploadFileService
  ) { }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onFileDropped(fileList: FileList): void {
    if (this.readOnly) {
      return;
    }
    if (fileList && fileList.length) {
      this.uploadAttachment(fileList[0]);
    } else {
      return;
    }
  }

  public fileBrowseHandler(event: Event): void {
    if (event && (event.target as HTMLInputElement).files[0]) {
      this.uploadAttachment((event.target as HTMLInputElement).files[0]);
    } else {
      return;
    }
  }

  private uploadAttachment(file: File): void {
    if (file) {
      const fileNameSplitArray = file.name.split('.');
      const extension = fileNameSplitArray[fileNameSplitArray.length - 1].toLowerCase();
      if (file.size > 10485760) {
        this.toastrService.warning('Size of attachment can not be bigger than 10Mb');
        return;
      }
      if (extension !== 'doc' && extension !== 'docx' && extension !== 'xls'
        && extension !== 'xlsx' && extension !== 'csv' && extension !== 'pdf'
        && extension !== 'xps' && extension !== 'rtf') {
        this.toastrService.warning('File type must be Word, Excel, PDF or XPS');
        return;
      }
      const name = `${Guid.create()}.${extension}`;
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this, name);
      reader.onerror = this.onReaderError.bind(this);
      reader.readAsArrayBuffer(file); //for IE11 support
    }
  }

  private onReaderError(error): void {
    this.toastrService.error(`Error: ${error}`);
  }

  private handleReaderLoaded(fileName: string, event: ProgressEvent): void {
    let binary = '';
    const bytes = new Uint8Array(((event.target as FileReader).result as ArrayBuffer));
    const length = bytes.byteLength;
    for (let i = 0; i < length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const base64 = btoa(binary);
    const azure: AzureFileModel = {
      file: base64,
      name: fileName,
      folder: 'attachments'
    };
    this.subscription.add(
      this.uploadService.addModel(azure).subscribe((response) => {
        if (response) {
          this.formGroup.get(this.name).setValue(`${this.apiURL}/AzureFileStorage/attachments/${response.name}`);
          this.fileLoaderInputRef.nativeElement.value = null;
          this.valueChanged.emit(response.name);
        }
      })
    );
  }

}

