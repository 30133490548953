import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import EventModel from '../../../models/event/event.model';
import {CampaignModel} from '../../../models/campaigns/campaign.model';
import {StateModel} from '../../../models/state.model';
import {BehaviorSubject, combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {PaymentMethodType} from '../../../models/enum/payment.method.type';
import {FeeAndTaxes} from '../../../models/enum/fee-and-taxes.enum';
import FormElementDataModel from '../../../models/form.element.data.model';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormValidators} from '../../../shared/validatiors/form-validators';
import {ToastrService} from 'ngx-toastr';
import {CardConnectTokenizeService} from '../../../services/payments/card.connect.tokenize.service';
import {PaymentService} from '../../../services/payments/payment.service';
import {PaymentModel} from '../../../models/payments/payment.model';
import AchTokenResponseModel from '../../../models/payments/ach.token.response.model';
import RecurringSettingsModel from '../../../models/payments/recurring.settings.model';
import {RecurringSettingsService} from '../../../services/payments/recurring.settings.service';
import {TransactionModel} from '../../../models/payments/transaction.model';
import {PaymentBillingType} from '../../../models/enum/payment.billing.type';
import SocialPostModel from 'src/app/models/socialMedia/social.post.model';
import ClientDonor from 'src/app/models/client-donor.model';
import {PaymentServiceType} from '../../../models/enum/payment.service.type';
import {ClientModel} from '../../../models/client/client.model';
import {ClientPaymentModel} from 'src/app/models/client/client.payment.model';
import {ClientDonorType} from 'src/app/models/enum/donor.type';
import {DonationSourceType} from 'src/app/models/enum/donation.source.type';
import {ClientType} from 'src/app/models/enum/client.type';
import CountryModel from '../../../models/internationalization/country.model';
import {FundAllocationModel} from '../../../models/payments/fund.allocation.model';
import EventRegistrationModel from '../../../models/event/event-registration.model';
import FbTemplateModel, {FbElementModel, FbElementType} from '../../../models/form-builder/fb.template.model';
import {CornerstoneService} from '../../form-builder/cornerstone.service';
import {UtilsComponent} from '../../utils.component';
import {PaymentFeeModel} from '../../../models/payments/payment.fee.model';
import {AppStripeService} from '../../form-builder/app.stripe.service';
import {PaymentEntriesModel} from '../../../models/payments/payment.entries.model';
import {PaymentRecurrencyStatus} from '../../../models/enum/payment.recurrency.status';
import {TypesOfDonationsEnum} from '../../../models/enum/types-of-donations.enum';
import {catchError, filter, first, pairwise, startWith, switchMap} from 'rxjs/operators';
import {IpService} from '../../../services/ip/ip.service';
import {IpifyModel} from '../../../models/ip/ipify.model';
import {environment} from "../../../../environments/environment";
import {EventStatus} from '../../../models/enum/event.status';
import {ActivatedRoute} from '@angular/router';
import {PriceTicket} from 'src/app/models/enum/event.price.ticket';
import {EventDonationDisclaimerModel} from "../../../models/event/event-donation-disclaimer.model";
import {FormBuilderStateService} from "../../form-builder/form-builder.state.service";
import {DonationOrganizationType} from "../../../models/enum/donation.organization.type";
import { StripeACHService } from '../../form-builder/stripe-ach.service';
import { BaseStripeService } from '../../form-builder/base-stripe.service';
import { PaymentSummaryAmounts, PaymentSummaryComponent, PaymentSummaryResult, PaymentSummarySettings, TierResultModel, TierSettings, TiersCustomDonationResultModel } from '../../form-builder/components/elements/payment-summary/payment-summary.component';
import { StripeACHProcessorService } from './helpers/stripe/stripe-ach-processor-service/stripe-ach-processor.service';
import { StripeACHInstantVerificationHandlerService } from './helpers/stripe/stripe-ach-instant-verification-handler-service/stripe-ach-instant-verification-handler.service';
import { StripeACHInstantVerificationHandlerResult } from './helpers/stripe/models/stripe-ach-instant-verification-handler-result.model';
import { TierFormGroupModel } from './helpers/stripe/models/tier-form-group.model';
import { StripeACHDonorDataUtil } from './helpers/stripe/stripe-ach-donor-data/stripe-ach-donor-data.util';
import { StripeACHFormEventHandlersService } from './helpers/stripe/stripe-ach-form-event-handlers-service/stripe-ach-form-event-handlers.service';
import {PaymentRepeatType} from "../../../models/enum/payment.repeat.type";

export interface AmountValues {
  amount: number;
  feeAmount: number;
  ticketsCost: number;
  donationFee: number;
}

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ CornerstoneService, AppStripeService, StripeACHProcessorService, StripeACHInstantVerificationHandlerService, StripeACHFormEventHandlersService],
})
export class PaymentPageComponent implements OnDestroy, OnChanges, OnInit {
  @Input() public event: EventModel;
  @Input() public campaign: CampaignModel;
  @Input() public clientDonor: ClientDonor;
  @Input() public socialPost: SocialPostModel;
  @Input() public genericTemplateID:string = null;
  @Input() public genericSourceTemplateID:string = null;
  @Input() public client: ClientModel;
  @Input() public states: StateModel[] = [];
  @Input() public filledInData: any;
  @Input() public ammount: number;
  @Input() public isAnonymous: boolean = false;
  @Input() public noDonations: boolean = false;
  @Input() public mainPayment: number;
  @Input() public showMainPayment: boolean = false;
  @Input() public mainPaymentTitle: string;
  @Input() public recurringPayload: RecurringSettingsModel;
  @Input() public pledgeEditPaymentDetails: boolean = false;
  @Input() public initiateUpdateProcess: Observable<RecurringSettingsModel>;
  @Input() public isPreviewMode: boolean = false;
  @Input() public fundAllocation: FundAllocationModel[];
  @Input() public paymentServiceType: PaymentServiceType;
  @Input() public clientType: ClientType;
  @Input() public countries: CountryModel[];
  @Input() public countriesOptions: FormElementDataModel[];
  @Input() public paymentForm: FormGroup;
  @Input() public eventRegistrationModel: EventRegistrationModel;
  @Input() public isBackButtonVisible: boolean = false;
  @Input() public sourceTemplateModelId: string = null;
  @Input() public templateId: string = null;
  @Input() public fbTemplate: FbTemplateModel;
  @Input() public includeFee$: Observable<boolean>;
  @Input() public fbss: FormBuilderStateService;
  @Input() public paymentFee: PaymentFeeModel | any = {
    paymentFee: 0.02,
    paymentCommission: 0.2,
    applicationPaymentCommission: 0,
    applicationPaymentFeePercent: 0,
    stripeACHApplicationFeePercent: 0.002,
    stripeACHFeePercent: 0.008
  };
  @Input() public isStripeACHEnabled: boolean;

  @Output() public paymentFinished: EventEmitter<any> = new EventEmitter<any>();
  @Output() public paymentFailed: EventEmitter<void> = new EventEmitter<void>();
  @Output() public back: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('nameRef') private nameRef: ElementRef;
  @ViewChild('emailRef') private emailRef: ElementRef;
  @ViewChild('phoneRef') private phoneRef: ElementRef;
  @ViewChild('addressRef') private addressRef: ElementRef;
  @ViewChild('routingNumberRef') private routingNumberRef: ElementRef;
  @ViewChild('accountNumberRef') private accountNumberRef: ElementRef;
  @ViewChild('creditCardRef') private creditCardRef: ElementRef;
  @ViewChild('pacOrganizationRef') private pacOrganizationRef: ElementRef;
  @ViewChild('captchaRef') private captchaRef: ElementRef;
  @ViewChild('commentRef') private commentRef: ElementRef;
  @ViewChild('paymentSummaryRef') paymentSummaryComponent: PaymentSummaryComponent;

  public entity: EventModel | CampaignModel | SocialPostModel | ClientPaymentModel;
  private subscription: Subscription = new Subscription();
  public PaymentServiceType = PaymentServiceType;
  public paymentProcessStarted: boolean = false;
  public donationFeeBehaviorSubject:BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public mainFeeBehaviorSubject:BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public totalBehaviorSubject:BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public totalFeeDuringProcessingSubject:BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public isEmployerAddressRequired:boolean = true;
  public donationFeeVariable: number;
  public EventStatus = EventStatus;
  public fees = {donationFee: 0, mainFee: 0, total: 0};
  public valuesDuringProcessing = {
    ticketFeePre: 0,
    ticketFeePost: 0,
    donationFeePre: 0,
    donationFeePost: 0,
    totalPre: 0,
    totalPost: 0,
    totalFee: 0
  };
  private recurringModel: RecurringSettingsModel;
  public FeeAndTaxes = FeeAndTaxes;
  public ClientType = ClientType;

  public stateOptions: FormElementDataModel[] = [];

  public secondPaymentForm: FormGroup = this.formBuilder.group({
    organizationName: ['', [Validators.maxLength(250)]],
    federalIDNumber: ['', [Validators.maxLength(20)]],
    organizationType: ['', [Validators.maxLength(250)]],
    organizationTypeName: ['', [Validators.maxLength(250)]],
    firstName: ['', [Validators.required, Validators.maxLength(250)]],
    lastName: ['', [Validators.required, Validators.maxLength(250)]],
    email: ['', [Validators.required, FormValidators.emailValidator]],
    phone: ['', [Validators.required]],
    streetAddress: ['', [Validators.required, Validators.maxLength(250)]],
    zipCode: ['', [Validators.required, Validators.pattern('^(\\d{5}|\\d{9})$')]],
    city: ['', [Validators.required, Validators.maxLength(250)]],
    state: ['', [Validators.required, Validators.maxLength(250)]],
    paymentType: [PaymentMethodType.CreditCard, [Validators.required]],
    donorType: [ClientDonorType.Individual],
    includeFee: [true, [Validators.required]],
    includeEventFee: [false, [Validators.required]],
    country: [1, [Validators.required]],
    territorialEntity: ['', [Validators.maxLength(250)]],
    reCaptchaToken: ['', Validators.required],
    streetAddress2: ['', [Validators.maxLength(250)]],
    comment: ['', [Validators.maxLength(200)]],
    employer: this.formBuilder.group({
      occupation: ['', [Validators.maxLength(250)]],
      employer: ['', [Validators.maxLength(250)]],
      address1: ['', Validators.maxLength(250)],
      address2: ['', [Validators.maxLength(250)]],
      zipCode: ['', [Validators.pattern('^(\\d{5}|\\d{9})$')]],
      city: ['', [Validators.maxLength(250)]],
      state: ['', [Validators.maxLength(250)]],
      country: [1]
    }),
  });

  public achForm: FormGroup = this.formBuilder.group({
    routingNumber: ['', [Validators.required]],
    accountNumber: ['', [Validators.required]]
  });

  public siteKey: string = environment.recaptcha_site_key;
  public FbElementType = FbElementType;
  public updateHeader$: Subject<void> = new Subject<void>();

  public cardToken: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private fundraiserId: string;
  public stripeACHSettings: PaymentSummarySettings;

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private cardConnectTokenizeService: CardConnectTokenizeService,
    private paymentService: PaymentService,
    private recurringSettingsService: RecurringSettingsService,
    public cornerstoneService: CornerstoneService,
    public appStripeService: AppStripeService,
    private stripeACHService: StripeACHService,
    private ipService: IpService,
    private zone: NgZone,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private stripeACHProcessorService: StripeACHProcessorService,
    private stripeACHInstantVerificationHandler: StripeACHInstantVerificationHandlerService,
    private stripeACHFormEventHandlersService: StripeACHFormEventHandlersService
  ) {
  }

  public ngOnInit(): void {
    this.fundraiserId = this.route.snapshot.params.fundraiserId;
    if (!this.isPreviewMode) {
      if (this.event) {
        this.entity = this.event;
        this.setIncludeEventFee(this.entity.eventFeeAndTaxes);
      } else if (this.campaign) {
        this.entity = this.campaign;
      } else if (this.socialPost) {
        this.entity = this.socialPost;
      } else if (this.client) {
        this.entity = this.getClientPaymentModel(this.client);
      }
      if (this.entity) {
        if (this.paymentServiceType === PaymentServiceType.Cornerstone) {
          this.cornerstoneService.configureCornerstone(this.entity.clientID, this.secondPaymentForm, this.fbTemplate.inputBackground);
          // todo remove if CardConnect ach works
        } else if (this.paymentServiceType === PaymentServiceType.CardConnect) {
          this.paymentType.setValue(PaymentMethodType.CreditCard);
        } else if (this.paymentServiceType === PaymentServiceType.Stripe) {
          this.appStripeService.configureStripe(this.entity.clientID);
        }
      }
    }
    if (this.initiateUpdateProcess) {
      this.subscription.add(
        this.initiateUpdateProcess.subscribe((updatedRecurring: RecurringSettingsModel) => {
          this.recurringModel = updatedRecurring;
          this.confirmDonation();
        })
      );
    }

    this.cornerstoneService.setCardToken(this.cardToken);

    this.subscription.add(
      this.cornerstoneService.paymentProcessStarted.asObservable().subscribe(value => this.paymentProcessStarted = value)
    );
    this.subscription.add(
      this.cornerstoneService.triggerSaveOrUpdate.asObservable().subscribe(() => this.triggerSaveOrUpdate())
    );
    this.subscription.add(
      this.cornerstoneService.paymentFailed.asObservable().subscribe(() => this.paymentFailed.emit())
    );
    this.subscription.add(
      this.appStripeService.paymentProcessStarted.asObservable().subscribe(value => this.paymentProcessStarted = value)
    );
    this.subscription.add(
      this.appStripeService.triggerSaveOrUpdate.asObservable().subscribe(() => this.triggerSaveOrUpdate())
    );
    this.subscription.add(
      this.appStripeService.paymentFailed.asObservable().subscribe(() => this.paymentFailed.emit())
    );
    this.subscription.add(
      this.stripeACHService.paymentProcessStarted.asObservable().subscribe(value => this.paymentProcessStarted = value)
    );
    this.subscription.add(
      this.stripeACHService.triggerSaveOrUpdate.asObservable().subscribe(() => this.triggerSaveOrUpdate())
    );
    this.subscription.add(
      this.stripeACHService.paymentFailed.asObservable().subscribe(() => this.paymentFailed.emit())
    );
    this.subscription.add(
      this.donationFeeBehaviorSubject.subscribe(v => {
        this.fees.donationFee = v;
        if (v != 0 && this.fees.mainFee != 0) this.fees.donationFee -= this.paymentFee.paymentCommission;
      })
    );
    this.subscription.add(
      this.mainFeeBehaviorSubject.subscribe(v => {
        this.fees.mainFee = v;
      })
    );
    this.subscription.add(
      this.totalBehaviorSubject.subscribe(v => {
        this.fees.total = v;
      })
    );
    this.subscription.add(
      this.totalFeeDuringProcessingSubject.subscribe(v => {
        this.valuesDuringProcessing.totalFee = v;
      })
    );

    const includeFee$ = this.includeFee.valueChanges.pipe(startWith(this.includeFee.value));
    const includeEventFee$ = this.includeEventFee.valueChanges.pipe(startWith(this.includeEventFee.value));
    this.subscription.add(
      combineLatest([includeFee$, includeEventFee$])
        .pipe(
          pairwise(),
          filter(([prev, current]) => prev[0] !== current[0] || prev[1] !== current[1])
        )
        .subscribe(() => {
          this.handleInstantVerificationFeeAssignment();
          this.setupStripeACHSettings();
        })
    )

    const tier$ = this.tier.valueChanges.pipe(startWith(this.tier.value));
    const customFormTierAmount$ = this.customFormTiers.valueChanges;
    this.subscription.add(
      combineLatest([tier$, customFormTierAmount$]).subscribe(this.setupStripeACHSettings.bind(this))
    )

    this.stripeACHFormEventHandlersService.handleStripeACHFormReady(this.secondPaymentForm);
    this.stripeACHFormEventHandlersService.handleStripeACHFormFocus(this.secondPaymentForm)
    this.stripeACHFormEventHandlersService.handleSecondPaymentFormChange(this.secondPaymentForm)
    this.stripeACHFormEventHandlersService.handleStripeACHFormCompletion(this.secondPaymentForm, this.countries, () => {
      this.handleInstantVerificationFeeAssignment();
      this.setupStripeACHSettings();
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.ref.detectChanges();
    if (changes.states && changes.states.currentValue) {
      this.stateOptions = this.states.map(({name, id}: StateModel) => (
        {label: name, value: id}));
    }
    if (changes.filledInData && changes.filledInData.currentValue) {
      this.secondPaymentForm.patchValue(this.filledInData);
      if (this.filledInData.state === 0 && this.filledInData.stateName || this.filledInData.stateId) {
        this.secondPaymentForm.controls.state.setValue(this.filledInData.stateId);
      }
    }

    if (changes.clientDonor && changes.clientDonor.currentValue) {
      this.secondPaymentForm.patchValue(changes.clientDonor.currentValue);
      this.secondPaymentForm.controls.zipCode.setValue(changes.clientDonor.currentValue.postCode);
      this.secondPaymentForm.controls.streetAddress.setValue(changes.clientDonor.currentValue.street);
    }
    if (changes.fbTemplate && changes.fbTemplate.currentValue) {
      this.updateCommentValidator();
      if(this.client?.clientType === ClientType.PAC) this.updateEmployerGroupValidators();
    }

    if(!!changes.ammount?.currentValue || changes.eventRegistrationModel?.currentValue || changes.fbTemplate?.currentValue || JSON.stringify(changes.recurringPayload?.currentValue) !== JSON.stringify(changes.recurringPayload?.previousValue)) {
      this.setupStripeACHSettings();
    }

    if(!!changes.ammount?.currentValue || !!changes.eventRegistrationModel?.currentValue) {
      this.handleAmountChange(changes.ammount?.currentValue, changes.eventRegistrationModel?.currentValue.totalCost)
      this.setupStripeACHSettings();
    }

    if(changes.recurringPayload) {
      this.handleInstantVerificationFeeAssignment();
      this.setupStripeACHSettings();
    }

    this.ref.detectChanges();
  }

  private setIncludeEventFee(feeAndTaxes: FeeAndTaxes): void {
    if(this.event?.priceTicketType === PriceTicket.NoFee && (!this.mainPayment || this.mainPayment === 0)) return;
    if (feeAndTaxes === FeeAndTaxes.PassAutomatically) {
      this.includeEventFee.setValue(true);
    }
  }

  private getClientPaymentModel(client: ClientModel): ClientPaymentModel {
    const model = new ClientPaymentModel();
    model.clientModel = client;
    model.clientID = client.id;
    model.name = client.name;
    model.id = client.id;
    return model;
  }

  public get paymentType(): FormControl {
    return this.secondPaymentForm.get('paymentType') as FormControl;
  }

  public get donorType(): FormControl {
    return this.secondPaymentForm.get('donorType') as FormControl;
  }

  public get organizationName(): FormControl {
    return this.secondPaymentForm.get('organizationName') as FormControl;
  }

  public get federalIDNumber(): FormControl {
    return this.secondPaymentForm.get('federalIDNumber') as FormControl;
  }

  public get includeFee(): FormControl {
    return this.secondPaymentForm.get('includeFee') as FormControl;
  }

  public get includeEventFee(): FormControl {
    return this.secondPaymentForm.get('includeEventFee') as FormControl;
  }

  public get email(): FormControl {
    return this.secondPaymentForm.get('email') as FormControl;
  }

  public get routingNumber(): FormControl {
    return this.achForm.get('routingNumber') as FormControl;
  }

  public get accountNumber(): FormControl {
    return this.achForm.get('accountNumber') as FormControl;
  }

  private showErrorMessage(message: string): void {
    this.toastrService.error(message, 'Error');
  }

  public confirmDonation(): void {
   const form = this.paymentForm.getRawValue();
    const processingMessage = form.recurring ?  "Processing, please do not close screen until transaction has processed." : 'Processing, please wait. Do not close window.'
    this.paymentProcessStarted = true;
    this.secondPaymentForm.markAllAsTouched();
    const paymentType = this.paymentType.value;
    if (this.secondPaymentForm.invalid) {
      this.showErrorMessage('Please fill all mandatory fields');
      this.showErrorFields();
      this.paymentProcessStarted = false;
    } else if (this.paymentServiceType === PaymentServiceType.CardConnect) {
      switch (paymentType) {
        case PaymentMethodType.CreditCard:
          setTimeout(() => {
            if (!this.cardToken.getValue()) {
              this.showErrorMessage('Please fill all Credit Card data fields');
              UtilsComponent.scrollIntoView(this.creditCardRef);
              this.paymentProcessStarted = false;
            } else {
              this.toastrService.info(processingMessage);
              //this.notificationService.showInfo('Processing, please wait. Do not close window');
              this.triggerSaveOrUpdate();
            }
          }, 1000);
          break;
        case PaymentMethodType.ACH:
          this.achForm.setErrors(null);
          this.achForm.markAllAsTouched();
          if (this.achForm.invalid) {
            this.showErrorMessage('Please fill all ACH mandatory fields');
            this.showErrorFields();
            this.paymentProcessStarted = false;
          } else {
            this.toastrService.info(processingMessage);
            //this.notificationService.showInfo('Processing, please wait. Do not close window');
            this.getToken(() => this.triggerSaveOrUpdate());
          }
          break;
      }
    } else if (this.paymentServiceType === PaymentServiceType.Cornerstone) {
      this.toastrService.info(processingMessage);
      //this.notificationService.showInfo('Processing, please wait. Do not close window');
      window.CollectJS.startPaymentRequest();
    } else if (this.paymentServiceType === PaymentServiceType.Stripe) {
      this.toastrService.info(processingMessage);
      //this.notificationService.showInfo('Processing, please wait. Do not close window');
      switch(paymentType) {
        case PaymentMethodType.CreditCard:
          this.appStripeService.startStripePaymentRequest(
            this.secondPaymentForm.getRawValue().email,
            this.entity.clientID,
            this.amountValues.amount,
            (err) => {
              if (err && err.message) {
                this.showErrorMessage(err.message);
              }
              UtilsComponent.scrollIntoView(this.creditCardRef);
              this.paymentProcessStarted = false;
            }
          );
          break;
        case PaymentMethodType.ACH:
          this.stripeACHProcessorService.setup(this.stripeACHPaymentSummaryResult, !!this.recurringPayload, this.showMainPayment);
          this.stripeACHService.startStripeACHPaymentRequest(StripeACHDonorDataUtil.getDonorData(this.secondPaymentForm, this.countries), this.amountValues.amount, (err) => {
            if (err && err.message) {
              this.showErrorMessage(err.message);
            }
            this.paymentProcessStarted = false;
          });
          break;
        default:
          this.toastrService.error("Something went wrong");
          break;
      }
    }
  }

  private get selectedCountry(): CountryModel {
    return this.countries.find(ct => ct.id == this.secondPaymentForm.get('country').value)
  }

  private triggerSaveOrUpdate(): void {
    this.recurringModel ? this.updatePayment() : this.savePayment();
  }

  private savePayment(): void {
    this.stripeACHInstantVerificationHandler.updateFundAllocation(this.fundAllocation, this.ammount, this.eventRegistrationModel?.totalCost, !!this.includeFee.value, !!this.includeEventFee.value, !!this.recurringPayload);

    const {
      firstName,
      lastName,
      email,
      streetAddress,
      zipCode,
      city,
      state,
      paymentType,
      organizationName,
      federalIDNumber,
      donorType,
      country,
      territorialEntity,
      comment,
      streetAddress2,
      reCaptchaToken,
      organizationType,
      organizationTypeName,
    }: PaymentModel = this.secondPaymentForm.getRawValue();
    const disclaimers = this.customDisclaimers;
    const id = this.entity.id;
    const {amount, feeAmount, ticketsCost, donationFee}: AmountValues = this.amountValues;
    const currentState: StateModel = this.states.find((stateModels: StateModel) => stateModels.id === +state);
    if (this.eventRegistrationModel && this.eventRegistrationModel.registrationModel && this.eventRegistrationModel.registrationModel.eventTicketAddons) {
      this.eventRegistrationModel.registrationModel.eventTicketAddons = this.eventRegistrationModel.registrationModel.eventTicketAddons
        .filter(item => {
          let flag = false;
          if (item.includeID) {
            flag = true;
            return flag;
          }
          this.event.ticketPackageList.forEach(tickedPackage => {
            tickedPackage.addons.forEach(addonsPackage => {
              if (addonsPackage.id === item.addonID && addonsPackage.includes.length === 0 && item.includeID === null) {
                flag = true;
              }
            })
          });
          return flag;
        })
        .map(item => {
          return {addonID: item.addonID, includeID: item.includeID};
        });
    }
    if (this.eventRegistrationModel && this.eventRegistrationModel.registrationModel && this.eventRegistrationModel.registrationModel.additionalParticipants) {
      this.eventRegistrationModel.registrationModel.additionalParticipants.forEach(additionalParticipant => {
        additionalParticipant.eventTicketAddons = additionalParticipant.eventTicketAddons
          .filter(item => {
            let flag = false;
            if (item.includeID) {
              flag = true;
              return flag;
            }
            this.event.ticketPackageList.forEach(tickedPackage => {
              tickedPackage.addons.forEach(addonsPackage => {
                if (addonsPackage.id === item.addonID && addonsPackage.includes.length === 0 && item.includeID === null) {
                  flag = true;
                }
              })
            });
            return flag;
          })
          .map(item => {
          return {addonID: item.addonID, includeID: item.includeID};
        });
      });
    }
    const currCountry:CountryModel = this.countries.find(ct => ct.id == this.secondPaymentForm.get('country').value)
    let paymentModeldonationFee: number;
    if(this.isStripeACHSelected) paymentModeldonationFee = donationFee;
    else this.roundCurrency(this.fees.donationFee != 0 && this.fees.mainFee != 0 ? this.donationFee(false) : this.donationFee(true));
    const model: PaymentModel = {
      clientId: this.entity.clientID,
      ammount: amount,
      feeAmmount: feeAmount,
      appFee: this.isStripeACHSelected ? this.stripeACHProcessorService.getAppFee() : this.isStripe(),
      token: this.cardToken.getValue(),
      cardHolderName: `${firstName} ${lastName}`,
      currency: 'USD',
      email,
      firstName,
      lastName,
      streetAddress,
      zipCode,
      city,
      organizationName,
      federalIDNumber,
      donorType,
      state: currentState ? currentState.name : state,
      paymentType,
      isAnonymous: this.isAnonymous,
      isFeeIncluded: this.includeFee.value,
      isEventFeeIncluded: this.includeEventFee.value,
      ticketsCost,
      capture: 'N',
      PaymentServiceType: this.paymentServiceType,
      mobile: `${currCountry?.phoneCode || '+1'}${this.secondPaymentForm.get('phone').value}`,
      country,
      territorialEntity,
      countryName: currCountry?.name || 'USA',
      fundAllocation: this.fundAllocation,
      distributionAmongFunds: !!this.fundAllocation.length,
      eventRegistrationModel: this.eventRegistrationModel,
      streetAddress2,
      comment,
      formTemplateID: this.sourceTemplateModelId,
      templateID: this.templateId,
      donationFee: paymentModeldonationFee,
      reCaptchaToken,
      organizationType,
      FundraiserID: this.fundraiserId,
      employer: this.employerGroup.getRawValue(),
      customDisclaimers: disclaimers,
    };
    if(organizationType && organizationType == DonationOrganizationType.Other) model.organizationTypeName = organizationTypeName;
    model.paymentEntries = this.getPaymentEntries(ticketsCost, amount, feeAmount, donationFee, model);
    model.isDonorPaidFee = model.isFeeIncluded;
    //todo check cornerStone
    const stateModel = this.states.find(item => item.name === model.state);
    if (stateModel) {
      model.stateId = stateModel.id;
    }
    if (this.campaign) {
      model.campaignId = id;
      model.donationSource = DonationSourceType.Campaign;
      model.sourceValue = this.entity.name;
    }
    if (this.event) {
      model.eventId = id;
      model.donationSource = DonationSourceType.Event;
      model.sourceValue = this.entity.name;
    }
    if (this.socialPost) {
      model.socialPostId = id;
      model.donationSource = DonationSourceType.SocialPost;
      model.sourceValue = this.entity.name;
    }
    if (this.paymentForm && this.paymentForm.controls.appeal.value && this.paymentForm.controls.giftType && this.paymentForm.controls.recipientName) {
      model.giftType = this.paymentForm.controls.giftType.value;
      model.giftName = this.paymentForm.controls.namedPerson.value;
    }
    if (this.paymentServiceType === PaymentServiceType.Stripe) {
      const service: BaseStripeService = this.stripeServiceFactory();
      model.paymentMethod = service.paymentMethod;
      model.intentID = service.intentID;
      model.customerID = service.customerID;
      model.token = service.nextActionType;
    }
    this.processPay(model);
  }

  public get isDonationStandard(): boolean {
    return this.infoPage.typesOfDonations === TypesOfDonationsEnum.Standard;
  }

  private get customDisclaimers(): EventDonationDisclaimerModel[] {
    return this.infoPage.customDisclaimers
  }

  public get infoPage(): FbElementModel {
    if (!this.fbTemplate) {
      return null;
    }
    return this.fbTemplate.infoPage.elements.find(element => element.type === FbElementType.DonationInfo)
  }

  public get tier(): FormArray {
    return this.paymentForm.get('tiers') as FormArray;
  }
  public get customFormTiers(): FormGroup {
    return this.paymentForm.get('customDonationTiers') as FormGroup;
  }

  private getStripeACHPaymentEntries(model: PaymentModel): PaymentEntriesModel[] {
    if(this.stripeACHPaymentSummaryResult.tiersResult.tiers?.length) {
      const paymentEntries: PaymentEntriesModel[] = this.includedFormGroupTiers
        .map((tier: TierFormGroupModel) => ({
          ammount: this.getAchPaymentTierResultByName(tier.tierName).total,
          isFeeIncluded: this.includeFee.value,
          feeAmmount: this.getAchPaymentTierResultByName(tier.tierName).fee,
          donationFee: this.getAchPaymentTierResultByName(tier.tierName).fee,
          ticketsCost: 0,
          isTier: true,
          tierName: tier.tierName,
          tagId: tier.tagId,
          reccuringSettings: tier.recurring ? this.getStripeACHTiersRecurringPayload(tier, model) : null
        }))
      const customDonation: TiersCustomDonationResultModel = this.stripeACHPaymentSummaryResult.tiersResult?.customDonation || null;
      if(!!customDonation) {
        const customDonationFormGroupModel: TierFormGroupModel =  this.customFormTiers.getRawValue();
        paymentEntries.push({
          ammount: customDonation.total,
          isFeeIncluded: this.includeFee.value,
          feeAmmount: customDonation.fee,
          donationFee: customDonation.fee,
          ticketsCost: 0,
          isTier: false,
          tierName: null,
          tagId: this.customFormTiers.get('tagId').value,
          reccuringSettings: customDonation.isRecurring ? this.getStripeACHTiersCustomDonationRecurringPayload(customDonationFormGroupModel, model) : null
        })
      }
      return paymentEntries;
    }

    if(this.showMainPayment && this.recurringPayload) {
      return [
        {
          ammount: this.stripeACHPaymentSummaryResult.tickets.total,
          isFeeIncluded: this.includeFee.value,
          feeAmmount: this.stripeACHPaymentSummaryResult.tickets.fee,
          donationFee: this.stripeACHPaymentSummaryResult.donation.fee,
          ticketsCost: this.stripeACHPaymentSummaryResult.tickets.total,
          isTier: false,
          tierName: null,
          tagId: null,
          reccuringSettings: null
        },
        {
          ammount: this.stripeACHPaymentSummaryResult.donation.total,
          isFeeIncluded: this.includeFee.value,
          feeAmmount: this.stripeACHPaymentSummaryResult.donation.fee,
          donationFee: this.stripeACHPaymentSummaryResult.donation.fee,
          ticketsCost: 0,
          isTier: false,
          tierName: null,
          tagId: null,
          reccuringSettings: this.setRecurringPayload(this.recurringPayload, model)
        }
      ]
    } else {
      return [
        {
          ammount: this.stripeACHPaymentSummaryResult.total,
          isFeeIncluded: this.includeFee.value,
          feeAmmount: this.stripeACHPaymentSummaryResult.totalFee,
          donationFee: this.stripeACHPaymentSummaryResult.donation.fee,
          reccuringSettings: this.setRecurringPayload(this.recurringPayload, model),
          ticketsCost: this.stripeACHPaymentSummaryResult?.tickets.total || 0,
          isTier: false,
          tierName: null,
          tagId: null,
        }
      ]
    }
  }

  public getPaymentEntries(ticketsCost: number, amount: number, feeAmount: number, donationFee: number, model: PaymentModel): PaymentEntriesModel[] {
    if(this.isStripeACHSelected) return this.getStripeACHPaymentEntries(model);
    let entriesListArray: PaymentEntriesModel[] = [];

    if (!this.isDonationStandard) {
      // Tier donation
      entriesListArray = this.tier.getRawValue().map(tier => {
        if (tier.isInclude) {
          // When a tier is selected
          let fee = this.fees.donationFee != 0 && this.fees.mainFee != 0 ? this.donationFee(false) : this.donationFee(true);
          const paymentEntries: PaymentEntriesModel = {
            ammount: this.getAmount(tier.ammount),
            isFeeIncluded: this.includeFee.value,
            feeAmmount: fee,
            donationFee: fee,
            ticketsCost: 0,
            isTier: true,
            tierName: tier.tierName,
            tagId: tier.tagId,
            reccuringSettings: tier.recurring ? this.getTiersRecurringSettings(tier, model) : null
          };
          return paymentEntries;
        }
      }).filter(tier => tier !== undefined);

      if(this.customFormTiers.get('ammount').value !== null && this.customFormTiers.get('ammount').value !== "" && this.customFormTiers.get('ammount').value != 0) {
        //custom amount
        let fee = this.fees.donationFee != 0 && this.fees.mainFee != 0 ? this.donationFee(false) : this.donationFee(true);
        entriesListArray.push({
          ammount: this.getAmount(+this.customFormTiers.get('ammount').value),
          isFeeIncluded: this.includeFee.value,
          feeAmmount: fee,
          donationFee: fee,
          ticketsCost: 0,
          isTier: false,
          tierName: null,
          tagId: this.customFormTiers.get('tagId').value,
          reccuringSettings: this.customFormTiers.get('recurring').value ? this.getCustomDonationTiersRecurringSettings(model): null
        })
      }

      if (this.showMainPayment) {
        // show main payment include it
        let mainPaymentAmount = this.newFeeAndTotalCalculating('mainFee') + this.mainPayment;
        const paymentEntries1: PaymentEntriesModel = {
          ammount: mainPaymentAmount,
          isFeeIncluded: false,
          feeAmmount: this.newFeeAndTotalCalculating('mainFee'),
          donationFee: 0,
          ticketsCost: mainPaymentAmount,
          isTier: false,
          tierName: null,
          tagId: null,
          reccuringSettings: null,
          mainPayment: true
        };

        entriesListArray.push(paymentEntries1);
      }
      return entriesListArray;

    } else {

      if (this.showMainPayment && this.recurringPayload) {
        const paymentEntries1: PaymentEntriesModel = {
          ammount: this.roundCurrency(amount),
          isFeeIncluded: this.includeFee.value,
          feeAmmount: this.totalFee(),
          donationFee: this.fees.donationFee != 0 && this.fees.mainFee != 0 ? this.donationFee(false) : this.donationFee(true),
          ticketsCost: this.mainFee() + this.mainPayment,
          isTier: false,
          tierName: null,
          tagId: null,
          reccuringSettings: null
        };

        entriesListArray.push(paymentEntries1);

        const paymentEntries2: PaymentEntriesModel = {
          ammount: +this.recurringPayload.ammount + (this.fees.donationFee != 0 && this.fees.mainFee != 0 ? +this.donationFee(false) : +this.donationFee(true)) - this.paymentFee.applicationPaymentCommission,
          isFeeIncluded: this.includeFee.value,
          feeAmmount: this.fees.donationFee != 0 && this.fees.mainFee != 0 ? this.donationFee(false) : this.donationFee(true),
          donationFee: this.fees.donationFee != 0 && this.fees.mainFee != 0 ? this.donationFee(false) : this.donationFee(true),
          ticketsCost: 0,
          isTier: false,
          tierName: null,
          tagId: null,
          reccuringSettings: this.setRecurringPayload(this.recurringPayload, model)
        };
        entriesListArray.push(paymentEntries2);

      } else {
        const paymentEntries1: PaymentEntriesModel = {
          ammount: this.total(),
          isFeeIncluded: this.includeFee.value,
          feeAmmount: this.totalFee(),
          donationFee: this.fees.donationFee != 0 && this.fees.mainFee != 0 ? this.totalFee() - this.mainFee() : this.donationFee(true),
          ticketsCost: this.mainFee() + this.mainPayment,
          isTier: false,
          tierName: null,
          tagId: null,
          reccuringSettings: this.setRecurringPayload(this.recurringPayload, model)
        };
        entriesListArray.push(paymentEntries1);
      }
      return entriesListArray;
    }
  }


  private getCustomDonationTiersRecurringSettings(model: PaymentModel) {
    const recurrency = this.customFormTiers.get('reccurrency').value;
    let donationRecurrence = 1;

    if (recurrency === PaymentRepeatType.Weekly) {
      // Get current week day
      donationRecurrence = new Date().getDay();
    } else if (recurrency === PaymentRepeatType.Monthly
        || recurrency === PaymentRepeatType.Yearly) {
      // Get current month day
      donationRecurrence = new Date().getDate();
    }

    const recurringSettingsModel: RecurringSettingsModel = {
      clientId: this.entity.clientID,
      reccurrency: recurrency,
      reccurrencyValue: donationRecurrence,
      startDate: this.customFormTiers.get('startDate').value ? new Date(this.customFormTiers.get('startDate').value).toLocaleDateString() : null,
      reccurrencyPaymentType: this.customFormTiers.get('reccurrencyPaymentType').value,
      everyValue: this.customFormTiers.get('everyValue').value,
      maxCount: this.customFormTiers.get('customNumber').value,
      endDate: this.customFormTiers.get('endDate').value,
      ammount: this.getTierRecurringAmount(this.customFormTiers.getRawValue()),
      currency: 'USD',
      recurrencyStatusEnum: PaymentRecurrencyStatus.Active
    };
    if(this.event) recurringSettingsModel.eventId = this.event.id;
    if(this.campaign) recurringSettingsModel.campaignId = this.campaign.id;
    if(this.socialPost) recurringSettingsModel.socialMediaPost = this.socialPost.id;


    return this.setRecurringPayload(recurringSettingsModel, model);
  }

  public getTiersRecurringSettings(tier: TierFormGroupModel, model: PaymentModel): RecurringSettingsModel {
    return this.setRecurringPayload(
      this.createRecurringSettings(this.getTierRecurringAmount(tier), tier),
      model
    );
  }

  private getStripeACHTiersRecurringPayload(tierFormGroup: TierFormGroupModel, model: PaymentModel): RecurringSettingsModel {
    const recurringSettingsModel = {
      ...this.createRecurringSettings(this.getAchPaymentTierResultByName(tierFormGroup.tierName).total, tierFormGroup),
      ...this.createRecurringSettingsBillingData(model, this.getAchPaymentTierResultByName(tierFormGroup.tierName).amount)
    }
    return recurringSettingsModel;
  }

  private getStripeACHTiersCustomDonationRecurringPayload(customDonationFormGroupModel: TierFormGroupModel, model: PaymentModel): RecurringSettingsModel {
    const recurringSettingsModel = {
      ...this.createRecurringSettings(this.stripeACHPaymentSummaryResult.tiersResult.customDonation.total, customDonationFormGroupModel),
      ...this.createRecurringSettingsBillingData(model, this.stripeACHPaymentSummaryResult.tiersResult.customDonation.amount)
    }
    return recurringSettingsModel;
  }

  getTierRecurringAmount(control) {
    if(control.reccurrencyPaymentType === PaymentBillingType.Pledge) return control.amountPerTransaction;
    if(control.reccurrencyPaymentType === PaymentBillingType.Recurring) return control.ammount;
  }

  private setRecurringPayload(recurringSettingsModel: RecurringSettingsModel, model: PaymentModel): RecurringSettingsModel {
    if (!recurringSettingsModel) {
      return null;
    }
    if (this.includeFee.value) {
      if (recurringSettingsModel.reccurrencyPaymentType === PaymentBillingType.Pledge) {
        recurringSettingsModel.ammount = this.roundCurrency(+recurringSettingsModel.ammount + this.mathematicalRoundCurrency(+this.fees.donationFee / this.recurringPayload.maxCount));
      } else {
        if(this.isStripeACHSelected){
          recurringSettingsModel.ammount = this.stripeACHPaymentSummaryResult.donation.total;
        } else {
          recurringSettingsModel.ammount = this.roundCurrency(+recurringSettingsModel.ammount + +this.fees.donationFee);
        }

      }
    }
    recurringSettingsModel = {
      ...recurringSettingsModel,
      ...this.createRecurringSettingsBillingData(model, this.ammount)
    }
    return recurringSettingsModel;
  }

  private createRecurringSettings(amount: number, tierFormGroup: TierFormGroupModel): RecurringSettingsModel {
    let donationRecurrence = 1;

    if (tierFormGroup.reccurrency === PaymentRepeatType.Weekly) {
      // Get current week day
      donationRecurrence = new Date().getDay();
    } else if (tierFormGroup.reccurrency === PaymentRepeatType.Monthly
        || tierFormGroup.reccurrency === PaymentRepeatType.Yearly) {
      // Get current month day
      donationRecurrence = new Date().getDate();
    }

    let recurringSettingsModel: RecurringSettingsModel = {
      clientId: this.entity.clientID,
      reccurrency: tierFormGroup.reccurrency,
      reccurrencyValue: String(donationRecurrence),
      startDate: tierFormGroup.startDate ? new Date(tierFormGroup.startDate).toLocaleDateString() : null,
      reccurrencyPaymentType: tierFormGroup.reccurrencyPaymentType ,
      everyValue: tierFormGroup.everyValue,
      maxCount: tierFormGroup.customNumber,
      endDate: tierFormGroup.endDate,
      ammount: amount,
      currency: 'USD',
      recurrencyStatusEnum: PaymentRecurrencyStatus.Active
    }
    if (this.event) {
      recurringSettingsModel.eventId = this.event.id;
    }
    if (this.campaign) {
      recurringSettingsModel.campaignId = this.campaign.id;
    }
    if (this.socialPost) {
      recurringSettingsModel.socialMediaPost = this.socialPost.id;
    }
    return recurringSettingsModel;
  }

  private createRecurringSettingsBillingData(model: PaymentModel, fullAmount: number): RecurringSettingsModel {
    let recurringSettingsModel: RecurringSettingsModel = {};
    recurringSettingsModel.email = model.email;
    recurringSettingsModel.isFeeIncluded = this.includeFee.value;
    recurringSettingsModel.streetAddress = model.streetAddress;
    recurringSettingsModel.state = model.state;
    recurringSettingsModel.city = model.city;
    recurringSettingsModel.postalCode = model.zipCode;
    recurringSettingsModel.firstName = model.firstName;
    recurringSettingsModel.lastName = model.lastName;
    recurringSettingsModel.paymentCreditType = model.paymentType;
    recurringSettingsModel.fullAmmount = fullAmount;
    recurringSettingsModel.organizationName = model.organizationName;
    recurringSettingsModel.federalIDNumber = model.federalIDNumber;
    recurringSettingsModel.donorType = model.donorType;
    recurringSettingsModel.country = model.country;
    recurringSettingsModel.territorialEntity = model.territorialEntity;
    recurringSettingsModel.phone = model.mobile;
    recurringSettingsModel.countryName = model.countryName;
    recurringSettingsModel.fundAllocation = model.fundAllocation;
    recurringSettingsModel.distributionAmongFunds = model.distributionAmongFunds;
    recurringSettingsModel.comment = model.comment;
    recurringSettingsModel.streetAddress2 = model.streetAddress2;
    if(this.genericTemplateID) recurringSettingsModel.templateID = this.genericTemplateID;
    if(this.genericSourceTemplateID) recurringSettingsModel.sourceTemplateID  = this.genericSourceTemplateID;
    return recurringSettingsModel;
  }

  private processPay(model: PaymentModel): void {
    const state = this.states.find(item => item.name === model.state);
    if (state) {
      model.stateId = state.id;
    }
    if(this.fundraiserId) {
      model.FundraiserID = this.fundraiserId;
      if(model.eventRegistrationModel)
         model.eventRegistrationModel.fundraiserID = this.fundraiserId;
    }

    this.subscription.add(
      this.ipService.getIPAddress()
        .pipe(
          first(),
          catchError(error => of(null)),
          switchMap((ipifyModel: IpifyModel) => {
            if (ipifyModel && ipifyModel.ip) {
              model.userIp = ipifyModel.ip;
            } else {
              model.userIp = '';
            }
            return this.paymentService.addPaymentNew(model);
          })
        )
        .subscribe((response: TransactionModel) => {
          /*if (response.cCrespstat === 'R') {
            this.appStripeService.confirmStripePaymentRequest(response.stripeClientCode);
          }*/
          if (response.cCrespstat === 'A' || response.cCrespstat === 'P' || response.cCrespstat === "R") {
            this.paymentFinished.emit(response);
            this.paymentServiceType === PaymentServiceType.Cornerstone
              ? /*this.notificationService.showNotification(true, response.cCresptext)*/ this.zone.run(() => this.toastrService.success(response.cCresptext, 'Notification'))
              : this.toastrService.success(response.cCresptext, 'Notification');
          }
          else if(response.cCrespstat === 'SoldOut'){
            this.paymentFailed.emit();
            this.toastrService.error('Selected tickets are sold out, payment was not processed.', 'Notification', {enableHtml: true});
            this.paymentProcessStarted = false;
            this.paymentServiceType === PaymentServiceType.Cornerstone && window.CollectJS.clearInputs();
          } else {
            this.paymentFailed.emit();
            this.paymentServiceType === PaymentServiceType.Cornerstone
              ? /*this.notificationService.showNotification(false, response.cCresptext)*/ this.zone.run(() => this.toastrService.error(response.cCresptext ? response.cCresptext : 'Payment error.  Please, contact the support team. <br>support@theauxilia.com', 'Notification', {enableHtml: true}))
              : this.toastrService.error(response.cCresptext ? response.cCresptext : 'Payment error.  Please, contact the support team. <br>support@theauxilia.com', 'Notification', {enableHtml: true});
            this.paymentProcessStarted = false;
            this.paymentServiceType === PaymentServiceType.Cornerstone && window.CollectJS.clearInputs();
          }
        })
    );
  }

  private getToken(callback: () => void): void {
    const account = `${this.routingNumber.value}/${this.accountNumber.value}`;
    this.subscription.add(
      this.cardConnectTokenizeService.getToken(account).subscribe((tokenModel: AchTokenResponseModel) => {
        if (tokenModel && tokenModel.token) {
          this.cardToken.next(tokenModel.token);
          callback();
        } else {
          this.paymentProcessStarted = false;
          this.paymentFailed.emit();
        }
      })
    );
  }

  private updatePayment(): void {
    this.recurringModel.email = this.email.value;
    this.subscription.add(
      this.recurringSettingsService.updateModel(this.recurringModel).subscribe((response: RecurringSettingsModel) => {
        if (response) {
          const {
            firstName,
            lastName,
            email,
            streetAddress,
            zipCode,
            city,
            state,
            paymentType,
            organizationName,
            federalIDNumber,
            donorType,
            country,
            territorialEntity,
            comment,
            streetAddress2,
            reCaptchaToken
          }: PaymentModel = this.secondPaymentForm.getRawValue();
          const currentState: StateModel = this.states.find(stateModel => stateModel.id === +state);
          const currCountry:CountryModel = this.countries.find(ct => ct.id == this.secondPaymentForm.get('country').value)
          const paymentModel: PaymentModel = {
            clientId: this.entity.clientID,
            ammount: response.ammount,
            feeAmmount: this.roundCurrency(this.fees.donationFee + this.fees.mainFee),
            appFee: this.isStripe(),
            token: this.cardToken.getValue(),
            cardHolderName: `${firstName.toUpperCase()} ${lastName.toUpperCase()}`,
            currency: 'USD',
            email,
            firstName,
            lastName,
            streetAddress,
            zipCode,
            city,
            state: currentState ? currentState.name : state,
            paymentType,
            isAnonymous: false, //todo check field
            isFeeIncluded: this.includeFee.value,
            capture: 'N',
            PaymentServiceType: this.paymentServiceType,
            country,
            territorialEntity,
            mobile: `${currCountry?.phoneCode}${this.secondPaymentForm.getRawValue().phone}`,
            countryName: currCountry?.name,
            fundAllocation: this.fundAllocation,
            distributionAmongFunds: !!this.fundAllocation.length,
            organizationName,
            federalIDNumber,
            donorType,
            comment,
            streetAddress2,
            formTemplateID: this.sourceTemplateModelId,
            templateID: this.templateId,
            reCaptchaToken
          };
          paymentModel.paymentEntries = this.getPaymentEntries(0, paymentModel.ammount, paymentModel.feeAmmount, paymentModel.donationFee, paymentModel);
          this.processPay(paymentModel);
        }
      })
    );
  }

  public showError(): void {
    UtilsComponent.scrollIntoView(this.creditCardRef);
  }

  private getPaymentControl(controlName: string): FormControl {
    return this.secondPaymentForm.get(controlName) as FormControl;
  }

  private showErrorFields(): void {
    if (this.getPaymentControl('firstName') && this.getPaymentControl('firstName').invalid) {
      UtilsComponent.scrollIntoView(this.nameRef);
    } else if (this.getPaymentControl('lastName') && this.getPaymentControl('lastName').invalid) {
      UtilsComponent.scrollIntoView(this.nameRef);
    } else if (this.getPaymentControl('email') && this.getPaymentControl('email').invalid) {
      UtilsComponent.scrollIntoView(this.emailRef);
    } else if (this.getPaymentControl('phone') && this.getPaymentControl('phone').invalid) {
      UtilsComponent.scrollIntoView(this.phoneRef);
    } else if (this.getPaymentControl('streetAddress') && this.getPaymentControl('streetAddress').invalid) {
      UtilsComponent.scrollIntoView(this.addressRef);
    } else if (this.getPaymentControl('zipCode') && this.getPaymentControl('zipCode').invalid) {
      UtilsComponent.scrollIntoView(this.addressRef);
    } else if (this.getPaymentControl('city') && this.getPaymentControl('city').invalid) {
      UtilsComponent.scrollIntoView(this.addressRef);
    } else if (this.getPaymentControl('state') && this.getPaymentControl('state').invalid) {
      UtilsComponent.scrollIntoView(this.addressRef);
    } else if (this.getPaymentControl('organizationName') && this.getPaymentControl('organizationName').invalid) {
      UtilsComponent.scrollIntoView(this.pacOrganizationRef);
    } else if (this.getPaymentControl('federalIDNumber') && this.getPaymentControl('federalIDNumber').invalid) {
      UtilsComponent.scrollIntoView(this.pacOrganizationRef);
    } else if (this.getPaymentControl('country') && this.getPaymentControl('country').invalid) {
      UtilsComponent.scrollIntoView(this.addressRef);
    } else if (this.getPaymentControl('territorialEntity') && this.getPaymentControl('territorialEntity').invalid) {
      UtilsComponent.scrollIntoView(this.addressRef);
    } else if (this.getPaymentControl('reCaptchaToken') && this.getPaymentControl('reCaptchaToken').invalid) {
      UtilsComponent.scrollIntoView(this.captchaRef);
    } else if (this.getPaymentControl('comment') && this.getPaymentControl('comment').invalid) {
      UtilsComponent.scrollIntoView(this.commentRef);
    }
  }

  public get captchaError(): boolean {
    const control = this.secondPaymentForm.get('reCaptchaToken') as FormControl;
    return control.touched && control.invalid;
  }

  public goBack(): void {
    this.back.emit();
  }

  private isStripe() {
    if (this.paymentServiceType === PaymentServiceType.Stripe) return this.roundCurrency(this.stripeAndAppFee(true,true));
    return this.roundCurrency(this.newFeeAndTotalCalculating('mainFee'));
  }

  public get pageStyle(): {[key: string]: string} {
    if (!this.fbTemplate) {
      return {};
    }
    const {
      width = 760,
      formColor = '#fff',
      fontColor = '#2C3345',
      fontFamily = 'Roboto',
    }: FbTemplateModel = this.fbTemplate;
    return {
      'max-width': `${width}px`,
      background: formColor,
      color: fontColor,
      'font-family': fontFamily,
    };
  }

  public get elementStyle(): {[key: string]: string} {
    if (!this.fbTemplate) {
      return {};
    }
    const {
      spacing = 10,
    }: FbTemplateModel = this.fbTemplate;
    return {
      'padding-bottom': `${spacing}px`,
    };
  }

  private updateCommentValidator(): void {
    const comment = this.fbTemplate.paymentPage.elements.find(element => element.type === FbElementType.Comments);
    if (!comment) {
      return;
    }
    const isCommentRequired = comment.required;
    if (isCommentRequired) {
      this.secondPaymentForm.get('comment').setValidators(Validators.required);
      this.secondPaymentForm.get('comment').updateValueAndValidity();
    }
  }

  private get amountValues(): AmountValues {
    let amount = 0;
    let feeAmount = 0;
    let ticketsCost = 0;
    let donationFee = 0;

    if(this.isStripeACHSelected) {
      return this.stripeACHProcessorService.getAmountValues();
    }

    if (!this.showMainPayment) {
      if (this.recurringPayload) {
        switch (this.recurringPayload.reccurrencyPaymentType) {
          case PaymentBillingType.Recurring:
            amount = this.getAmount();
            feeAmount = +this.fees.donationFee;
            donationFee = feeAmount;
            break;
          case PaymentBillingType.Pledge:
            amount = this.getPledgeAmount(this.recurringPayload.ammount);
            feeAmount = +this.fees.donationFee;
            donationFee = feeAmount;
            break;
        }
      } else {
        amount = this.getAmount();
        feeAmount = +this.fees.donationFee;
        donationFee = feeAmount;
      }
      if (this.noDonations) {
        ticketsCost = this.getAmount();
      }
    } else if (!this.recurringPayload) {
      amount = this.roundCurrency(this.getAmount() + this.getMainPayment());
      feeAmount = this.roundCurrency(+this.fees.donationFee + +this.fees.mainFee);
      ticketsCost = this.roundCurrency(this.getMainPayment());
      donationFee = +this.fees.donationFee;
    } else {
      amount = this.roundCurrency(this.getMainPayment());
      feeAmount = +this.fees.mainFee;
      ticketsCost = this.roundCurrency(this.getMainPayment());
    }
    return {
      amount, feeAmount, ticketsCost, donationFee
    }
  }

  public getAmount(ammount?: number): number {
    if (ammount) {
      this.calculateFee(ammount);
      return this.includeFee.value ? +ammount + +this.fees.donationFee : +ammount;
    }
    this.calculateFee();
    return this.roundCurrency(this.includeFee.value ? +this.ammount + this.fees.donationFee : +this.ammount);
  }

  public getMainPayment(): number {
    this.calculateMainFee();
    return this.includeEventFee.value ? +this.mainPayment + +this.mainFee() : +this.mainPayment;
  }

  private getPledgeAmount(amount: number): number {
    return this.includeFee.value ? +amount + +this.fees.donationFee : +amount;
  }


  private calculateMainFee(amount: number = 0, preRound: boolean = false) {
    amount = this.mainPayment;
    let fee: number;
    if (this.paymentServiceType === PaymentServiceType.Stripe) {
      if(!this.includeEventFee.value){
        fee = 0;
      }
      else  {
        fee = this.calculateStripeFee(amount, this.paymentFee.paymentCommission);
      }
    } else {
      fee = !amount
        ? 0.00
        : (+this.paymentFee.paymentCommission + amount * this.paymentFee.paymentFee);
    }
    this.mainFeeBehaviorSubject.next(fee);
    if (preRound) {
      return fee;
    }
    this.totalFeeDuringProcessingSubject.next(fee);
    fee = this.roundCurrency(this.rebalanceFees());
    this.mainFeeBehaviorSubject.next(fee);
    return fee;
  }

  public calculateFee(baseAmount: number = 0, preRound: boolean = false) {
    let fee: number;
    if(baseAmount == 0){
      baseAmount = this.ammount;
    }
    if (!baseAmount) {
      fee = 0;
    }else{

      let applicationFixedFee: number = this.paymentFee.applicationPaymentCommission;
      let stripeFixedFee = this.paymentFee.paymentCommission;

      if (this.recurringPayload && this.recurringPayload.reccurrencyPaymentType === PaymentBillingType.Pledge) {
        if (this.paymentServiceType === PaymentServiceType.Stripe) {
          if (this.includeFee.value) {
            fee = this.recurringPayload.maxCount * this.calculateStripeFee(this.recurringPayload.ammount, stripeFixedFee, applicationFixedFee);
          }else {
            fee = 0;
          }
        } else {
          fee =(this.recurringPayload.maxCount * +(+this.paymentFee.paymentCommission + this.recurringPayload.ammount * this.paymentFee.paymentFee));
        }
      } else {
        if (this.paymentServiceType === PaymentServiceType.Stripe) {
          if (this.includeFee.value) {
            fee = this.calculateStripeFee(baseAmount, stripeFixedFee, applicationFixedFee);
          } else {
            fee = 0;
          }

        } else {
          fee = !baseAmount
            ? 0.00
            : (+this.paymentFee.paymentCommission + baseAmount * this.paymentFee.paymentFee);
        }
      }
    }

    this.donationFeeBehaviorSubject.next(fee);
    this.totalFeeDuringProcessingSubject.next(fee);
    return fee;
  }

  public get employerGroup():FormGroup {
    return this.secondPaymentForm.get('employer') as FormGroup;
  }

  /**
   * method calculating main fee to avoid errors when summing rounded values
   * @returns not rounded main fee
   */
  public rebalanceFees() {
    let fee, sum;
    sum = this.fees.mainFee + this.fees.donationFee;
    fee = this.roundCurrency(this.fees.donationFee);

    return sum - fee;
  }

  private calculateStripeFee(totalFeeBaseAmount: number, stripeFeeFixedAmount: number, applicationFeeFixedAmount: number = this.paymentFee.applicationPaymentCommission): number{
    let appFee: number = 0;
    appFee = this.calculateApplicationFee(totalFeeBaseAmount, applicationFeeFixedAmount);
    return ((+totalFeeBaseAmount + +stripeFeeFixedAmount + +appFee) / Number((1 - +this.paymentFee.paymentFee)) - +totalFeeBaseAmount);
  }

  private calculateApplicationFee(baseAmt: number, fixedCommissionAmt: number): number{
    return this.roundCurrency((((baseAmt * 1000) * (+this.paymentFee.applicationPaymentFeePercent * 1000))/1000000) + +fixedCommissionAmt);
  }

  private roundCurrency(value: number, decimalPoint = 2) {
    var d = decimalPoint;
    var m = Math.pow(10, d);
    var n = +(d ? value * m : value).toFixed(8); // Avoid rounding errors
    var i = Math.floor(n), f = n - i;
    var e = 1e-8; // Allow for rounding errors in f
    var r = (f > 0.5 - e && f < 0.5 + e) ?
      ((i % 2 == 0) ? i : i + 1) : Math.round(n);
    return d ? r / m : r;
  }

  newFeeAndTotalCalculating(option: string): number {
    switch (option){
      case 'total':
        this.totalBehaviorSubject.next(this.total())
        this.valuesDuringProcessing.totalPost = this.total();
        return this.total();
      case 'donationFee':
        const donationFeeTrue = this.donationFee(true);
        const donationFeeFalse = this.donationFee(false);
        const isInvalidMainPayment = this.mainPayment === undefined || isNaN(this.mainPayment);

        if (this.fees.donationFee === 0 || this.fees.mainFee === 0) {
          this.valuesDuringProcessing.donationFeePost = donationFeeTrue;
          return donationFeeTrue;
        }

        this.valuesDuringProcessing.donationFeePost = donationFeeFalse;

        if (this.paymentServiceType === PaymentServiceType.Stripe) {
          if (isInvalidMainPayment) {
            this.valuesDuringProcessing.donationFeePost = donationFeeTrue;
            return donationFeeTrue;
          }

          const appFee = this.roundCurrency(this.calculateStripeAndAppFee('app'));
          const stripeFee = this.roundCurrency(this.calculateStripeAndAppFee('stripe'));
          const ticketFeePre = this.mathematicalRoundCurrency(this.valuesDuringProcessing.ticketFeePre);

          return this.roundCurrency(appFee + stripeFee - ticketFeePre);
        }

        return this.includeFee.value ? this.roundCurrency(donationFeeFalse) : 0;
      case 'mainFee':
        this.valuesDuringProcessing.ticketFeePost = this.mainFee();
        return this.includeEventFee.value ? this.mathematicalRoundCurrency(this.valuesDuringProcessing.ticketFeePre) : 0;
      default:
        return 0;
    }
  }

  private donationFee(onlyDonationFee: boolean): number {
    this.valuesDuringProcessing.donationFeePre = onlyDonationFee ?
      this.calculateFee(Number(this.ammount))
      :
      this.calculateFee(Number(this.ammount)) - this.paymentFee.paymentCommission - this.paymentFee.applicationPaymentCommission;

    return onlyDonationFee ? this.roundCurrency(this.calculateFee(Number(this.ammount)))
      : this.roundCurrency(this.calculateFee(Number(this.ammount))) - this.paymentFee.paymentCommission
         - this.paymentFee.applicationPaymentCommission;
  }

  private mainFee(): number {
    this.valuesDuringProcessing.ticketFeePre = this.calculateMainFee(Number(this.mainPayment), true);
    return this.roundCurrency(this.calculateMainFee(Number(this.mainPayment)));
  }

  private total(): number {
    if (this.noDonations) this.includeFee.setValue(false); //when buying ticket and donation fee was checked by default

    if (!this.mainPayment) { //donation only
      this.valuesDuringProcessing.totalPre = this.includeFee.value ?
      Number(this.ammount) + this.calculateFee(Number(this.ammount))
      :
      Number(this.ammount);

      return this.includeFee.value ? this.roundCurrency(Number(this.ammount)
        + this.calculateFee(Number(this.ammount))) : Number(this.ammount);
    }
    if (this.includeEventFee.value && this.includeFee.value) { //both fees
      this.valuesDuringProcessing.totalPre = Number(this.ammount) + Number(this.mainPayment)
        + this.calculateFee(Number(this.ammount) + Number(this.mainPayment));

      return this.roundCurrency(Number(this.ammount) + Number(this.mainPayment) + this.calculateFee(Number(this.ammount)
        + Number(this.mainPayment)));
    }
    if (!this.includeEventFee.value && this.includeFee.value) { //donation fee only
      if (this.paymentServiceType === PaymentServiceType.Stripe) {
        this.valuesDuringProcessing.totalPre = Number(this.ammount) + Number(this.mainPayment) + this.calculateFee(Number(this.ammount));

        return this.roundCurrency(Number(this.ammount) + Number(this.mainPayment) + this.calculateFee(Number(this.ammount)));
      } else {
        return Number(this.mainPayment) + (Number(this.ammount) + this.roundCurrency(this.donationFee(false)));
      }
    }
    if (this.includeEventFee.value && !this.includeFee.value) { //ticket fee only
      this.valuesDuringProcessing.totalPre = Number(this.ammount) + Number(this.mainPayment)
        + this.calculateMainFee(Number(this.mainPayment), true);

      return this.roundCurrency(Number(this.ammount) + Number(this.mainPayment) + this.calculateMainFee(Number(this.mainPayment)));
    }
    if (!this.includeEventFee.value && !this.includeFee.value) { //no fees
      this.valuesDuringProcessing.totalPre = Number(this.ammount) + Number(this.mainPayment);
      return this.roundCurrency(Number(this.ammount) + Number(this.mainPayment));
    }
  }
  private totalFee(): number {
    return Number(this.mainPayment) ? ((this.total()*100) - ((Number(this.ammount)*100) + (Number(this.mainPayment)*100)))/100
      :  ((this.total()*100) - ((Number(this.ammount)*100)))/100;
  }

  public showCalculationsTable(): boolean {
    return environment.showCalculationsTableDuringPayment;
  }

  public mathematicalRoundCurrency(amount: number): number {
    return Math.round((amount + Number.EPSILON) * 100) / 100;
  }

  public calculateStripeAndAppFee(feeType: string): number {
    switch (feeType) {
      case 'stripe':
        return this.stripeAndAppFee(false);

      case 'app':
        return this.stripeAndAppFee(true);

      default:
        return 0;
    }
  }

  private stripeAndAppFee(isAppFee: boolean, wholeFee: boolean = false): number {
    let donation = Number(this.ammount);
    let ticket = Number(this.mainPayment);
    let percentFee = isAppFee ? this.paymentFee.applicationPaymentFeePercent : this.paymentFee.paymentFee;
    let commisionFee = isAppFee ? this.paymentFee.applicationPaymentCommission : this.paymentFee.paymentCommission;
    let amount: number;

    if ((this.includeEventFee.value && this.includeFee.value)||wholeFee) {
      amount = isAppFee ? ticket + donation : this.fees.total;
      return ((amount * 1000) * (percentFee * 1000)) / 1000000 + commisionFee;
    }

    if ((!this.mainPayment && this.includeFee.value) || (!this.includeEventFee.value && this.includeFee.value)) {
      amount = isAppFee ? donation : this.fees.total - ticket;
      return ((amount * 1000) * (percentFee * 1000)) / 1000000 + commisionFee;
    }

    if (this.includeEventFee.value && !this.includeFee.value) {
      amount = isAppFee ? ticket : this.fees.total - donation;
      return ((amount * 1000) * (percentFee * 1000)) / 1000000 + commisionFee;
    }

    return 0;
  }

  private updateEmployerGroupValidators(): void {
    const isOccupationRequired: boolean = this.fbTemplate.paymentPage.elements.find(element => element.type === FbElementType.Occupation)?.required;
    const isEmployerNameRequired: boolean = this.fbTemplate.paymentPage.elements.find(element => element.type === FbElementType.EmployerName)?.required;
    const isEmployerAddressRequired: boolean = this.fbTemplate.paymentPage.elements.find(element => element.type === FbElementType.EmployerMailingAddress)?.required;
    if(isOccupationRequired) this.employerGroup.get('occupation').setValidators([Validators.required,Validators.maxLength(250)]);
    if(isEmployerNameRequired) this.employerGroup.get('employer').setValidators([Validators.required,Validators.maxLength(250)]);
    if(isEmployerAddressRequired) {
      this.employerGroup.get('address1').setValidators([Validators.required,Validators.maxLength(250)]);
      this.employerGroup.get('zipCode').setValidators([Validators.required,Validators.maxLength(250)]);
      this.employerGroup.get('city').setValidators([Validators.required,Validators.maxLength(250)]);
      this.employerGroup.get('state').setValidators([Validators.required,Validators.maxLength(250)]);
      this.employerGroup.get('country').setValidators([Validators.required,Validators.maxLength(250)]);
    } else {
      this.isEmployerAddressRequired = false;
    }
  }

  private stripeServiceFactory(): BaseStripeService {
    if(!this.paymentType?.value) throw new Error("Payment method type needs to be defined")
    if(this.paymentType.value === PaymentMethodType.CreditCard) return this.appStripeService;
    if(this.paymentType.value === PaymentMethodType.ACH) return this.stripeACHService;
    throw new Error("Payment Type is neither ACH nor Credit Card");
  }

  get isStripeACHSelected(): boolean {
    return this.paymentServiceType === PaymentServiceType.Stripe && this.paymentType.value === PaymentMethodType.ACH;
  }

  setupStripeACHSettings(): void {
    const amounts: PaymentSummaryAmounts = {
      donationAmount: {
        amount: this.ammount,
        includeFee: this.includeFee.value
      },
      ticketsAmount: this.eventRegistrationModel?.totalCost ?
      {
        amount: this.eventRegistrationModel.totalCost,
        includeFee: this.includeEventFee.value
      } : null
    }

    const tierSettings: TierSettings = {
      tiers: this.includedFormGroupTiers.map(t => ({name: t.tierName, amount: +t.ammount, isRecurring: t.recurring})),
      customDonation: this.customFormTiers.get('ammount')?.value && {amount: this.customFormTiers.get('ammount')?.value, isRecurring: this.customFormTiers.get('recurring')?.value},
      includeFee: this.includeFee.value,
    }

    this.stripeACHSettings = {
      amounts,
      tierSettings,
      applicationFee: {
        feePercentage: this.paymentFee.stripeACHApplicationFeePercent,
        fee: 0
      },
      transactionFee: {
        feePercentage: this.paymentFee.stripeACHFeePercent,
        fee: 0
      },
      paymentMethodType: this.paymentType.value,
      paymentServiceType: this.paymentServiceType,
      isRecurring: !!this.recurringPayload
    }

    this.ref.detectChanges();
  }

  private get stripeACHPaymentSummaryResult(): PaymentSummaryResult {
    if(!this.paymentSummaryComponent?.result) throw new Error("Payment Summary Component's result undefined")
    return this.paymentSummaryComponent.result
  }

  private get includedFormGroupTiers(): TierFormGroupModel[] {
    return this.tier.getRawValue().filter(t => t !== undefined && t.isInclude)
  }

  private get tiersCustomDonation(): TierFormGroupModel {
    return (this.customFormTiers.value as TierFormGroupModel)?.ammount && this.customFormTiers.value;
  }

  private getAchPaymentTierResultByName(name: string): TierResultModel {
    return this.stripeACHPaymentSummaryResult.tiersResult.tiers.find(t => t.name === name);
  }

  private handleInstantVerificationFeeAssignment(): void {
    const result: StripeACHInstantVerificationHandlerResult = this.stripeACHInstantVerificationHandler.handleInstantVerificationFee(!!this.includeFee.value, !!this.includeEventFee.value, !!this.recurringPayload, this.includedFormGroupTiers, this.tiersCustomDonation);
    this.ammount = result.newDonationAmount;
    if(!!this.eventRegistrationModel) {
      this.eventRegistrationModel.totalCost = result.newEventRegistrationCostAmount;
      this.mainPayment = result.newEventRegistrationCostAmount;
    }
  }

  private handleAmountChange(donationAmount: number, registrationAmount: number): void {
    if(!!donationAmount) this.stripeACHInstantVerificationHandler.originalDonationAmount = donationAmount
    if(!!registrationAmount) this.stripeACHInstantVerificationHandler.originalEventRegistrationCost = registrationAmount;
    this.handleInstantVerificationFeeAssignment()
  }
}
