import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { MS_PER_DAY, MS_PER_HOUR, MS_PER_MINUTE, MS_PER_MONTH } from '../../../../../constants';
import { EventStatus } from '../../../../../models/enum/event.status';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnChanges, OnDestroy {
  @Input()
  public date: number;
  @Input()
  public eventStatus: number = null;
  public months: number = 0;
  public days: number = 0;
  public hours: number = 0;
  public minutes: number = 0;
  public sec: number = 0;
  public interval$: Observable<number> = interval(1000);
  private subscription: Subscription = new Subscription();
  public showShimmerTemplate: boolean = true;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.date && changes.date.currentValue/* && this.liveEventStatus*/) {
      this.subscription.add(
        this.interval$.subscribe(x => this.getValues())
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getValues(): void {
    this.showShimmerTemplate = false;
    const now = Date.now();
    //const end = this.date.getTime();
    //const diff = end - now;
    const diff = this.date - now;
    if (diff < 0) {
      return;
    }
    this.months = Math.floor(diff / MS_PER_MONTH);
    const msPerMonths = this.months * MS_PER_MONTH;
    this.days = Math.floor((diff - msPerMonths ) / MS_PER_DAY);
    const msPerDays = this.days * MS_PER_DAY;
    this.hours = Math.floor((diff - msPerMonths - msPerDays ) / MS_PER_HOUR);
    const msPerHours = this.hours * MS_PER_HOUR;
    this.minutes = Math.floor((diff - msPerMonths - msPerDays - msPerHours ) / MS_PER_MINUTE);
    this.sec = Math.floor((diff - msPerMonths - msPerDays - msPerHours - this.minutes * MS_PER_MINUTE) / 1000);
  }

  public get statusText(): string {
    switch (this.eventStatus) {
      case EventStatus.Draft:
        return 'This event has draft status!';
      case EventStatus.Live:
        return 'This event has already started!';
      case EventStatus.Complete:
        return 'This event has already completed!';
      case EventStatus.Archived:
        return 'This event has been archived!';
      case EventStatus.Canceled:
        return 'This event has been canceled!';
    }
  }

  public get liveEventStatus(): boolean {
    return this.eventStatus === EventStatus.Live;
  }

  get showSatusTest(): boolean {
    const diff = this.date - Date.now();
    if(this.eventStatus === EventStatus.Live && diff > 0) return false; 
    return true;
  }
}
