import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditPopupDataModel } from '../../../../models/edit-popup-data.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminConfirmationComponent } from '../../../admin/admin-confirmation/admin-confirmation.component';

@Component({
  selector: 'app-edit-popup',
  templateUrl: './edit-popup.component.html',
  styleUrls: ['./edit-popup.component.scss']
})
export class EditPopupComponent implements OnInit, OnDestroy {
  public title$: Observable<string>;
  public titleSubj: BehaviorSubject<string> = new BehaviorSubject<string>(this.data.title);
  private subscription: Subscription = new Subscription();
  public form: FormGroup = this.fb.group({
    comment: ['', [Validators.required, Validators.maxLength(200)]]
  });
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<EditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditPopupDataModel
  ) {
  }

  public ngOnInit(): void {
    this.title$ = this.titleSubj.asObservable();
    if (this.data.currentText) {
      this.comment.setValue(this.data.currentText);
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public save(): void {
    const config = {
      data: {
        title: 'Would you like to save the changes?'
      }
    }
    this.subscription.add(
      this.dialog.open(AdminConfirmationComponent, config).afterClosed()
        .subscribe(result => {
          if (!result) {
            return;
          }
          this.dialogRef.close(this.comment.value);
        })
    )
  }

  private get comment(): AbstractControl {
    return this.form.get('comment');
  }

  public onClose(): void {
    if (this.comment.dirty) {
      const config = {
        data: {
          title: 'You have unsaved changes. Are you sure you want to leave this form?'
        }
      }
      this.subscription.add(
        this.dialog.open(AdminConfirmationComponent, config).afterClosed()
          .subscribe(result => {
            if (!result) {
              return;
            }
            this.dialogRef.close();
          })
      )
      return;
    }
    this.dialogRef.close();
  }
}
