import { CampaignModel } from 'src/app/models/campaigns/campaign.model';
import { CampaignService } from '../../../../services/campaign/campaign.service';
import { CampaignStatus } from '../../../../models/enum';
import { ClientIdStateService } from '../../../../services/client.module.state/client.id.state.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Filter } from '../../../../models/paging/filter.model';
import { FilterType } from '../../../../models/enum/filter.type';
import { Paging } from '../../../../models/paging/paging.model';
import { SortOrder } from '../../../../models/enum/sort.order';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-event-registrations',
  templateUrl: './active-campaigns.component.html',
  styleUrls: [
    './active-campaigns.component.scss',
    '../simple-widget.component.scss'
  ]
})
export class ActiveCampaignsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public campaigns: CampaignModel[] = null;
  public eventTab: number = 1;

  constructor(
    private campaignModelService: CampaignService,
    private clientIdStateService: ClientIdStateService
  ) { }

  public ngOnInit(): void {
    this.subscription.add(
      this.clientIdStateService.clientIdObservable
        .pipe(
          distinctUntilChanged(),
          switchMap(this.getActiveCampaigns.bind(this))
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getActiveCampaigns(clientId: string): Observable<CampaignModel[]> {
    this.setValuesToNull();
    const filters: Filter[] = [
      {
        field: 'clientID',
        value: clientId,
        type: FilterType.Equal
      },
      {
        field: 'status',
        value: CampaignStatus.Active.toString(),
        type: FilterType.Equal
      }
    ];
    const paging: Paging = {
      filters,
      includeDependencies: true,
      rows: 3,
      selectFields: ['name', 'createdOn', 'campaignEmail'],
      sortField: 'createdOn',
      sortOrder: SortOrder.Descending
    };
    return this.campaignModelService.getModelList(paging)
      .pipe(
        tap((campaigns: CampaignModel[]) => this.campaigns = campaigns)
      );
  }

  private setValuesToNull(): void {
    this.campaigns = null;
  }
}
