<div class="email-builder-tool-settings-wrapper">

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        label="First and Last Name"
        [formGroup]="signatureForm"
        name="name"
        errorMassage="Name"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="setContent()"
      ></app-input>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-input
        label="Office Phone"
        [formGroup]="signatureForm"
        name="officePhone"
        errorMassage="Office Phone"
        mask="(000) 000-0000"
        placeholder="0 (000) 000-0000"
        prefix="+1 "
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="setContent()"
      ></app-input>
    </div>

    <div class="max-width-145">
      <app-input
        label="Cell Phone"
        [formGroup]="signatureForm"
        name="cellPhone"
        errorMassage="Cell Phone"
        mask="(000) 000-0000"
        placeholder="0 (000) 000-0000"
        prefix="+1 "
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="setContent()"
      ></app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-input
        label="Email"
        [formGroup]="signatureForm"
        name="email"
        errorMassage="Email"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="setContent()"
      ></app-input>
    </div>

    <div class="max-width-145">
      <app-input
        label="Company URL"
        [formGroup]="signatureForm"
        name="companyUrl"
        errorMassage="Company URL"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="setContent()"
      ></app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-textarea
        label="Text"
        [formGroup]="signatureForm"
        name="customText"
        errorMassage="Text"
        [low]="true"
        minRows="2"
        [disabled]="readOnly"
        (valueChanged)="setContent()"
      ></app-textarea>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <app-image-loader
      [formGroup]="signatureForm"
      name="imageSrc"
      label="Upload signature"
      [readOnly]="readOnly"
      [imageURL]="imageURL"
      (valueChanged)="imageUrlChanged($event)"
    ></app-image-loader>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-input
        label="Signature URL"
        [formGroup]="signatureForm"
        name="imageSrc"
        errorMassage="Signature URL"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="setContent()"
      ></app-input>
    </div>
    <app-button
      *ngIf="signatureForm.get('imageSrc') && signatureForm.get('imageSrc').value && !imageURL && !readOnly"
      label="Edit Signature"
      type="flat"
      (clickEvent)="editImage()"
      color="#0064be"
      width="140"
    ></app-button>
    <!--<app-button
      *ngIf="!!imageURL"
      label="Cancel"
      type="flat"
      (clickEvent)="cancelEditImage()"
      color="#ec4c47"
      width="140"
    ></app-button>-->
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="signatureForm"
        label="Color"
        name="color"
        [readOnly]="readOnly"
        (colorChanged)="onColorChanged()"
      ></app-email-color-picker>
    </div>
    <div class="max-width-145">
      <app-select
        label="Font Family"
        [formGroup]="signatureForm"
        name="fontFamily"
        errorMassage="Font Family"
        [selectOptions]="fontFamilyOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontFamilyChanged()"
      ></app-select>
      <div
        *ngIf="tms.templateAssignmentType === TemplateAssignmentType.Campaign"
        class="settings-info-icon"
        [matTooltip]="toolTipText"
        matTooltipHideDelay="200"
        matTooltipShowDelay="200"
        matTooltipPosition="above"
        matTooltipClass="settings-tooltip-custom-class"
      >
        <i class="fas fa-info-circle"></i>
      </div>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Font Size"
        [formGroup]="signatureForm"
        name="fontSize"
        errorMassage="Font Size"
        [selectOptions]="fontSizeOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontSizeChanged()"
      ></app-select>
    </div>
    <div class="max-width-145">
      <app-select
        label="Font Style"
        [formGroup]="signatureForm"
        name="fontStyle"
        errorMassage="Font Style"
        [selectOptions]="fontStyleOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontStyleChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Font Weight"
        [formGroup]="signatureForm"
        name="fontWeight"
        errorMassage="Font Weight"
        [selectOptions]="fontWeightOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontWeightChanged()"
      ></app-select>
    </div>
    <div class="max-width-145">
      <app-select
        label="Decoration"
        [formGroup]="signatureForm"
        name="textDecoration"
        errorMassage="Decoration"
        [selectOptions]="textDecorationOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onTextDecorationChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="signatureForm"
        label="Background color"
        name="containerBackgroundColor"
        [readOnly]="readOnly"
        (colorChanged)="onContainerBackgroundColorChanged()"
      ></app-email-color-picker>
    </div>
    <div class="max-width-145">
      <app-select
        label="Position"
        [formGroup]="signatureForm"
        name="align"
        errorMassage="Position"
        [selectOptions]="alignOptions"
        [low]="true"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onAlignChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="signatureForm"
        name="paddingTop"
        label="Padding Top"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingTopChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="signatureForm"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingRightChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="signatureForm"
        name="paddingBottom"
        label="Padding Bottom"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingBottomChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="signatureForm"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingLeftChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row" *ngIf="!(readOnly || isPredesignedThemedTemplate)">
    <app-button
      type="basic"
      label="Delete content"
      (clickEvent)="tms.removeActiveElementFromColumn()"
      color="#f44336"
      width="300"
    ></app-button>
  </div>

  <div class="padding-source-100"></div>

</div>

