export enum GenderType {
  PreferNotToSay = 0,
  Agender = 1,
  Androgyne = 2,
  Androgynous = 3,
  Bigender = 4,
  Cis = 5,
  CisFemale = 6,
  Male = 7,
  CisMan = 8,
  Woman = 9,
  Cisgender = 10,
  CisgenderFemale = 11,
  CisgenderMale = 12,
  CisgenderMan = 13,
  CisgenderWoman = 14,
  Female = 57,
  FemaleToMale = 15,
  FTM = 16,
  GenderFluid = 17,
  GenderNonconforming = 18,
  GenderQuestioning = 19,
  GenderVariant = 20,
  Genderqueer = 21,
  Intersex = 22,
  MaleToFemale = 23,
  MTF = 24,
  Neither = 25,
  Neutrois = 26,
  NonBinary = 27,
  Other = 28,
  Pangender = 29,
  Trans = 30,
  TransFemale = 31,
  TransMale = 32,
  TransMan = 33,
  TransPerson = 34,
  TransWoman = 35,
  TransX = 36,
  TransXFemale = 37,
  TransXMale = 38,
  TransXMan = 39,
  TransXPerson = 40,
  TransXWoman = 41,
  Transfeminine = 42,
  Transgender = 43,
  TransgenderFemale = 44,
  TransgenderMale = 45,
  TransgenderMan = 46,
  TransgenderPerson = 47,
  TransgenderWoman = 48,
  Transmasculine = 49,
  Transsexual = 50,
  TranssexualFemale = 51,
  TranssexualMale = 52,
  TranssexualMan = 53,
  TranssexualPerson = 54,
  TranssexualWoman = 55,
  TwoSpirit = 56
}
