import { Component, Input, OnInit } from '@angular/core';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';

@Component({
  selector: 'app-button-tool',
  templateUrl: './button-tool.component.html',
  styleUrls: ['./button-tool.component.scss']
})
export class ButtonToolComponent implements OnInit {
  @Input() public column: MjmlElementModel;
  @Input() public entity: MjmlElementModel;
  @Input() public tms: TemplateManagementService;
  @Input() public landingPageView: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  public getContainerStyles(): any {
    const attributes = this.entity.attributes;
    const align = attributes['align'] || 'center';
    let alignItems;
    switch (align) {
      case 'center':
        alignItems = 'center';
        break;
      case 'left':
        alignItems = 'flex-start';
        break;
      case 'right':
        alignItems = 'flex-end';
        break;
    }
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    return {
      'align-items': alignItems,
      'padding-bottom': paddingBottom,
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      'padding-top': paddingTop
    };
  }

  public getStyles(): any {
    const attributes = this.entity.attributes;
    const backgroundColor = attributes['background-color'] || '#414141';
    const border = attributes['border'];
    const borderRadius = attributes['border-radius'] || '3px';
    const color = attributes['color'] || '#ffffff';
    const fontFamily = attributes['font-family'] || 'Roboto';
    const fontSize = attributes['font-size'] || '13px';
    const fontStyle = attributes['font-style'] || 'normal';
    const fontWeight = attributes['font-weight'] || 'normal';
    const padding = attributes['inner-padding'];
    const textDecoration = attributes['text-decoration'] || 'none';
    const display = attributes['display'] || 'block';
    //const width = attributes['width'] || '';
    return {
      'background-color': backgroundColor,
      border,
      'border-radius': borderRadius,
      color,
      'font-family': fontFamily,
      'font-size': fontSize,
      'font-style': fontStyle,
      'font-weight': fontWeight,
      padding,
      'text-decoration': textDecoration,
      'display': display
     // width
    };
  }

  public get href(): string {
    return this.entity.attributes['href'];
  }
}
