export enum UserActivityType {
  Login = 'Login',
  Logout = 'Logout',
  CreateUser = 'Create User',
  UpdateUser = 'Update User',
  ArchiveUser = 'Archive User',
  CreateDonor = 'Create Donor',
  DeleteDonor = 'Delete Donor',
  UploadDonors = 'Upload Donors',
  UpdateDonor = 'Update Donor',
  ExportDonors = 'Export Donors',
  CreateEvent = 'Create Event',
  UpdateEvent = 'Update Event',
  PublishEvent = 'Publish Event',
  CancelEvent = 'Cancel Event',
  ArchiveEvent = 'Archive Event',
  DeleteEvent = 'Delete Event',
  UpdateGuestType = 'Update Guest Type',
  Export = 'Export',
  CreateCampaign = 'Create Campaign',
  UpdateCampaign = 'Update Campaign',
  ActivateCampaign = 'Activate Campaign',
  DisableCampaign = 'Disable Campaign',
  DeleteCampaign = 'Delete Campaign',
  CreateDonation = 'Create Donation',
  UpdateDonation = 'Update Donation',
  DeleteDonation = 'Delete Donation',
  UpdateProfile = 'Update Profile',
  ConnectSocialAccount = 'Connect Account',
  RemoveSocialAccount = 'Remove Account',
  CreatePost = 'Create Post',
  UpdatePost = 'Update Post',
  DeletePost = 'Delete Post',
  SubmitPost = 'Submit Post',
  ApprovePost = 'Approve Post',
  RequireUpdates = 'Require updates',
  ResubmitPost = 'Resubmit Post',
  PublishPost = 'Publish Post',
  SchedulePost = 'Schedule post',
  CreateTimeSlot = 'Create time slot',
  DeleteTimeSlot = 'Delete time slot',
  All = 'All',
  UpdateMerchant = 'Update Merchant',
  CreateDonationForm = 'Create Donation Form',
  EditDonationForm = 'Edit Donation Form',
  DisableDonationForm = 'Disable Donation Form',
  MergedDonors = 'Merged Donors',
  ArchiveDonor = 'Archive Donor',
  AddMerchant = 'Add Merchant',
  UpdateFee = 'Update Fee',
  TicketDeletion = 'Ticket Deletion'
}
