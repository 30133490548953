<div class="donor-retention-rate-wrapper">
  <div class="donor-retention-rate-header">
    <span class="donor-retention-rate-title">{{'DASH.Donor Retention Rate' | translate}}</span>
  </div>

  <div class="donor-retention-rate-data-container">
    <table>
      <tr>
        <td>
          <app-rate
            [value]="ytdNewDonors"
            [isClicked]="true"
            [navigateUrl]="'/donors/new?isYTD=true'"
            label="YTD New Donors"
            infoMessage="Number of Donors that made the first donation to the organization within the current year."
          ></app-rate>
        </td>
        <td>
          <app-rate
            [value]="lybunt"
            [isClicked]="true"
            [navigateUrl]="'/donors/lybunt'"
            label="LYBUNT"
            infoMessage="Donors that contributed during the last year, but have not donated this year."
          ></app-rate>
        </td>
        <td>
          <app-rate
            [value]="atRiskDonors"
            [isClicked]="true"
            [navigateUrl]="'/donors/at-risk-donors'"
            label="At-Risk Donors"
            infoMessage="The number of donors who are within 90 days of becoming lapsed donors."
          ></app-rate>
        </td>
      </tr>

      <tr>
        <td>
          <app-rate
            [value]="avgRetentionRate"
            label="Avg Retention Rate"
            infoMessage="Average rate of Donors who donated during the current year and also donated during the previous year."
          ></app-rate>
        </td>
        <td>
          <app-rate
            [value]="avgChurnRate"
            label="Avg Churn Rate"
            infoMessage="Average rate of Donors who donated during current year to number of Donors that did not make a donation during current year, but did it in previous."
          ></app-rate>
        </td>
        <td>
          <app-rate
            [value]="avgLifeTimeValue"
            label="Avg Lifetime Value"
            infoMessage="The projected amount that a donor will give over their life with your organization."
          ></app-rate>
        </td>
      </tr>
    </table>
  </div>

  <div class="donor-retention-rate-percentage-container">
    <span class="donor-retention-rate-label">{{'DASH.YTD' | translate}} {{'DASH.One-Time' | translate}} {{'DASH.vs.' | translate}} {{'DASH.Repeat' | translate}} {{'DASH.Donors' | translate}}</span>

    <app-circle-progress
      *ngIf="progress !== null; else shimmerCircleTemplate"
      class="donor-retention-rate-progress"
      [progress]="progress"
    ></app-circle-progress>
    <div
      class="donor-retention-rate-designation"
      matTooltip="{{'DASH.Number of Donors that have made 1 donation during current year.' | translate}}"
      matTooltipHideDelay="200"
      matTooltipShowDelay="200"
      matTooltipPosition="below"
      matTooltipClass="table-tooltip"
    >
      <div class="donor-retention-rate-dot donor-retention-rate-blue"></div>
      <span class="donor-retention-rate-text"><span class="navigate" (click)="navigateToReport(true)">{{'DASH.YTD' | translate}} {{'DASH.One-Time' | translate}} {{'DASH.Donors' | translate}}</span> - <span *ngIf="oneTime !== null; else shimmerTemplate">{{oneTime | number}}</span></span>
    </div>
    <div
      class="donor-retention-rate-designation"
      matTooltip="{{'DASH.Number of Donors that have made more than 1 donation within the current year.' | translate}}"
      matTooltipHideDelay="200"
      matTooltipShowDelay="200"
      matTooltipPosition="below"
      matTooltipClass="table-tooltip"
    >
      <div class="donor-retention-rate-dot donor-retention-rate-purple"></div>
      <span class="donor-retention-rate-text"><span class="navigate" (click)="navigateToReport()">{{'DASH.YTD' | translate}} {{'DASH.Repeat' | translate}} {{'DASH.Donors' | translate}}</span> - <span *ngIf="recurring !== null; else shimmerTemplate">{{recurring | number}}</span></span>
    </div>
  </div>

</div>


<ng-template #shimmerTemplate>
  <ngx-shimmer-loading shape="square" width="20px"></ngx-shimmer-loading>
</ng-template>

<ng-template #shimmerCircleTemplate>
  <ngx-shimmer-loading shape="circle" width="130px"></ngx-shimmer-loading>
</ng-template>
