import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ImageBuilderStateService } from '../../../image-builder.state.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TemplateSizeType } from '../../../../../models/image-builder/template.size.type';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import { BACKGROUNDR_REPEAT_OPTIONS, BACKGROUNDR_SIZE_OPTIONS } from '../../../../../constants';
import { TemplateType } from '../../../../../models/templates/template.type';
import { Subscription } from 'rxjs';
import { ImageTemplateModel } from '../../../../../models/image-builder/image.template.model';

@Component({
  selector: 'app-general-styling',
  templateUrl: './general-styling.component.html',
  styleUrls: ['../styling.component.scss']
})
export class GeneralStylingComponent implements OnInit, OnDestroy {
  @Input() public ibss: ImageBuilderStateService;
  private subscription: Subscription = new Subscription();

  public resolutionOptions: FormElementDataModel[] = [
    {label: '1200 x 675 Pixels (Twitter)', value: TemplateSizeType.resolution_1200x675},
    {label: '1080 x 1080 Pixels (Instagram / Facebook)', value: TemplateSizeType.resolution_1080x1080},
    {label: '1080 x 1350 Pixels (Instagram)', value: TemplateSizeType.resolution_1080x1350},
    {label: '1080 x 608 Pixels (Instagram)', value: TemplateSizeType.resolution_1080x608},
    {label: '1200 x 628 Pixels (LinkedIn)', value: TemplateSizeType.resolution_1200x628},
  ];

  public generalOptionsForm: FormGroup = this.formBuilder.group({
    size: null,
    backgroundColor: '#ffffff',
    backgroundRepeat: 'repeat',
    backgroundUrl: '',
    backgroundSize: 'auto',
    transparency: 100
  });
  public backgroundRepeatOptions: FormElementDataModel[] = BACKGROUNDR_REPEAT_OPTIONS;
  public backgroundSizeOptions: FormElementDataModel[] = BACKGROUNDR_SIZE_OPTIONS;
  public imageURL: string = null;

  private static getTemplateSizeType(width, height): TemplateSizeType {
    if (width === 1200 && height === 675) {
      return TemplateSizeType.resolution_1200x675;
    } else if (width === 1080 && height === 1080) {
      return TemplateSizeType.resolution_1080x1080;
    } else if (width === 1080 && height === 1350) {
      return TemplateSizeType.resolution_1080x1350;
    } else if (width === 1080 && height === 608) {
      return TemplateSizeType.resolution_1080x608;
    } else if (width === 1200 && height === 628) {
      return TemplateSizeType.resolution_1200x628;
    } else {
      return null;
    }
  }

  constructor(
    private formBuilder: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.subscription.add(
      this.ibss.templateObservable.subscribe((template: ImageTemplateModel) => this.setInitialValues())
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onSizeChanged(): void {
    const value: TemplateSizeType = this.generalOptionsForm.get('size').value;
    switch (value) {
      case TemplateSizeType.resolution_1200x675:
        this.ibss.setTemplateWidth(1200);
        this.ibss.setTemplateHeight(675);
        break;
      case TemplateSizeType.resolution_1080x1080:
        this.ibss.setTemplateWidth(1080);
        this.ibss.setTemplateHeight(1080);
        break;
      case TemplateSizeType.resolution_1080x1350:
        this.ibss.setTemplateWidth(1080);
        this.ibss.setTemplateHeight(1350);
        break;
      case TemplateSizeType.resolution_1080x608:
        this.ibss.setTemplateWidth(1080);
        this.ibss.setTemplateHeight(608);
        break;
      case TemplateSizeType.resolution_1200x628:
        this.ibss.setTemplateWidth(1080);
        this.ibss.setTemplateHeight(628);
        break;
    }
    this.ibss.setActiveLayerIndex(null);
    this.ibss.saveTemplate();
    this.ibss.emitResize();
  }

  public onBackgroundColorChanged(): void {
    const value = this.generalOptionsForm.get('backgroundColor').value || '';
    this.ibss.setTemplateAttributes('backgroundColor', value);
  }

  public imageUrlChanged(url: string): void {
    this.imageURL = null;
    this.onBackgroundUrlChanged();
  }

  public onBackgroundUrlChanged(): void {
    const value = this.generalOptionsForm.get('backgroundUrl').value || '';
    this.ibss.setTemplateAttributes('backgroundUrl', value);
  }

  public editImage(): void {
    this.imageURL = this.generalOptionsForm.get('backgroundUrl').value;
  }

  public cancelEditImage(): void {
    this.imageURL = null;
  }

  public onBackgroundRepeatChanged(): void {
    const value = this.generalOptionsForm.get('backgroundRepeat').value || 'repeat';
    this.ibss.setTemplateAttributes('backgroundRepeat', value);
  }

  public onBackgroundSizeChanged(): void {
    const value = this.generalOptionsForm.get('backgroundSize').value || 'auto';
    this.ibss.setTemplateAttributes('backgroundSize', value);
  }

  public onTransparencyChanged(): void {
    const value = this.generalOptionsForm.get('transparency').value;
    const transparency = value ? value / 100 : 0;
    this.ibss.setTemplateAttributes('transparency', transparency.toString());
  }

  public setInitialValues(): void {
    const width = this.ibss.templateWidth;
    const height = this.ibss.templateHeight;
    const {
      backgroundColor = '#ffffff',
      backgroundRepeat = 'repeat',
      backgroundUrl = '',
      backgroundSize = 'auto',
      transparency = '1'
    } = this.ibss.templateAttributes;
    this.generalOptionsForm.get('backgroundColor').setValue(backgroundColor);
    this.generalOptionsForm.get('backgroundRepeat').setValue(backgroundRepeat);
    this.generalOptionsForm.get('backgroundUrl').setValue(backgroundUrl);
    this.generalOptionsForm.get('backgroundSize').setValue(backgroundSize);
    this.generalOptionsForm.get('size').setValue(GeneralStylingComponent.getTemplateSizeType(width, height));
    this.generalOptionsForm.get('transparency').setValue(+transparency * 100);
  }

  public get readOnly(): boolean {
    return this.ibss.readOnly;
  }

  public get themedTemplate(): boolean {
    return this.ibss.themedTemplate;
  }

  public get componentBasedTemplate(): boolean {
    return this.ibss.componentBasedTemplate;
  }
}
