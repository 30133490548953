import { Component, Input, OnInit } from '@angular/core';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['../header/header.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() public mjml: MjmlElementModel;
  constructor() { }

  ngOnInit(): void {
  }

  public get footerStyle(): { [key: string]: string } {
    if (!this.headerFooter) {
      return {background: '#023665'};
    }
    const {
      background = '#023665',
    }: { [key: string]: string } = this.headerFooter.attributes;
    return {
      background,
    };
  }

  public get headerFooter(): MjmlElementModel {
    return this.mjml.children[1].children[1];
  }

}
