import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-signup-confirmation-page-settings',
  templateUrl: './signup-confirmation-page-settings.component.html',
  styleUrls: ['./signup-confirmation-page-settings.component.scss'],
})
export class SignupConfirmationPageSettingsComponent
  implements OnInit, OnChanges
{
  @Input() fbss: any;
  @Input() config: any;
  @Input() loadingData: any;
  @Output() formChange = new EventEmitter<any>();

  // Define the FormGroup
  public settingsForm: FormGroup;
  public placeholders: string[] = ['DonorName', 'DonorFirstName', 'DonorLastName', 'Email', 'OrganizationName', 'Phone', 'PrimaryContact'];
  public isUpdating = false;

  constructor(private fb: FormBuilder) {
    this.settingsForm = this.fb.group({
      confirmationScreen: ['', Validators.required],
    });
  }

  ngOnInit(): void {

    if (!this.config) {
      this.settingsForm.patchValue({
        confirmationScreen: this.getDefaultText(),
      });
    }

    this.settingsForm.valueChanges
    .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
    .subscribe(() => {
      if (!this.isUpdating) {
        this.onSubmit();
      }
    });
  }

  private getDefaultText(): string {
    return `<div style='text-align: center;'><span style='font-size: 20px;'>Thank you for connecting with us! We're thrilled to have you join our community.</span></div>`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      this.isUpdating = true;
      this.updateFormWithConfig(changes.config.currentValue);
      this.isUpdating = false;
    }
  }

  private updateFormWithConfig(config: any) {
    if (!this.settingsForm) {
      return;
    }

    this.settingsForm.patchValue({
      confirmationScreen: config.confirmationScreen ?? this.getDefaultText(),
    });
  }

  onSubmit() {
    const signUpConfig = {
      confirmationScreen: this.settingsForm.value.confirmationScreen,
    };
    if (this.settingsForm.valid) {
      this.formChange.emit({ formData: signUpConfig, isValid: true });
    } else {
      this.formChange.emit({ formData: signUpConfig, isValid: false });
    }
  }
}
