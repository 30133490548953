<div class="fb-input-wrapper" [formGroup]="formGroup" #divElement>
  <mat-form-field appearance="outline" (change)="emitChanges()">
    <textarea
      matInput
      [formControlName]="name"
      [id]="id"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      [type]="type"
      [maxLength]="maxlengthText"
      [mask]="mask ? mask : ''"
      [value]="formControl.value ? formControl.value : ''"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      [cdkAutosizeMinRows]="minRows"
      [cdkAutosizeMaxRows]="maxRows"
      (blur)="onBlur()"
    ></textarea>
  </mat-form-field>
  <span class="bottom-label error-message" *ngIf="formControl.touched && formControl.invalid && !customError; else labelTemplate">{{getErrorMessage()}}</span>
</div>


<ng-template #labelTemplate>
  <span class="bottom-label" [ngStyle]="labelStyle">{{label | translate}}</span>
</ng-template>
