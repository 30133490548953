import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl
} from '@angular/forms';
import { FormValidators } from 'src/app/shared/validatiors/form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DonorEmployeeService } from 'src/app/services/donor.employee/donor-employee.service';
import { Subscription } from 'rxjs';
import { DonorSource } from 'src/app/models/enum/donor.source';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-donor-register',
  templateUrl: './donor-register.component.html',
  styleUrls: ['./donor-register.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class DonorRegisterComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public agreeToPrivacyPolicy: boolean = false;
  public siteKey: string = environment.recaptcha_site_key;
  public sendEmail: boolean;
  private names: object = {
    firstName: 'first name',
    lastName: 'last name',
    email: 'email address'
  };
  public registerForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, FormValidators.emailValidator]],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    donorSource: DonorSource.Manual,
    reCaptchaToken: ['', [Validators.required]],
    returnUrl: this.route.snapshot.queryParams['returnUrl']
  });
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private donorEmployeeService: DonorEmployeeService,
    public translate: TranslateService,
  ) {}

  ngOnInit() {}

  public get email(): FormControl {
    return this.registerForm.get('email') as FormControl;
  }

  public get firstName(): FormControl {
    return this.registerForm.get('firstName') as FormControl;
  }

  public get lastName(): FormControl {
    return this.registerForm.get('lastName') as FormControl;
  }

  public get reCaptchaToken(): FormControl {
    return this.registerForm.get('reCaptchaToken') as FormControl;
  }

  public get captchaError(): boolean {
    const control = this.registerForm.get('reCaptchaToken') as FormControl;
    return control.touched && control.invalid;
  }

  public signUp(): void {
    if (this.registerForm.invalid) {
      // this.toastrService.error(this.errorMessages(), 'Notification');
      return;
    }

    this.subscription.add(
      this.donorEmployeeService
        .addModel(this.registerForm.value)
        .subscribe(() => {
          this.toastrService.success(this.translate.instant('DONOR_PORTAL.DONOR_REGISTER.Please check your email'), this.translate.instant('Notification'));
          this.sendEmail = true;
        })
    );
  }

  public signIn(): void {
    const queryUrl =  this.route.snapshot.queryParams['returnUrl'];
    this.router.navigate(['/'], {
      queryParams: { returnUrl: queryUrl },
    });
    // this.router.navigateByUrl('/');
  }

  // public errorMessages(): string {
  //   const emailMessage = this.errorMessage(this.email, 'email');
  //   const firstNameMessage = this.errorMessage(this.firstName, 'firstName');
  //   const lastNameMessage = this.errorMessage(this.lastName, 'lastName');

  //   const arrMessages = [
  //     emailMessage,
  //     firstNameMessage,
  //     lastNameMessage
  //   ].filter(item => !!item);

  //   return arrMessages.join('; ');
  // }

  public errorMessage({ errors }: FormControl, name: string): string {
    if (!errors) return null;

    let message = '';
    if (errors.required) {
      message += this.translate.instant('DONOR_PORTAL.DONOR_REGISTER.Field {{value}} is required', {value: this.translate.instant(this.names[name])});
    }
    if (!errors.required) {
      message += this.translate.instant('DONOR_PORTAL.DONOR_REGISTER.Please enter a valid {{value}}', {value: this.translate.instant(this.names[name])});
    }

    return message;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onBack(): void {
    const queryUrl =  this.route.snapshot.queryParams['returnUrl'];
    this.router.navigate(['/'], {
      queryParams: { returnUrl: queryUrl },
    });
    // this.router.navigateByUrl('/');
  }
}
