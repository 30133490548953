import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormElementComponent } from '../base.form.element.component';

@Component({
  selector: 'app-item-counter',
  templateUrl: './item-counter.component.html',
  styleUrls: ['./item-counter.component.scss']
})
export class ItemCounterComponent extends BaseFormElementComponent implements OnChanges, OnInit {
  @Input() public maxValue: number;
  @Output() public maxValueReached: EventEmitter<number> = new EventEmitter<number>();
  @Output() public valueChange: EventEmitter<number> = new EventEmitter<number>();

  constructor(public translate: TranslateService) {
    super(translate);
  }

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe(value => {
      const strippedInputValue: string = (value + '').replace(/\D/g, '');
      const parsedInputValue: number = Number(strippedInputValue);
      if (value !== parsedInputValue) {
        this.formControl.setValue(parsedInputValue);
        return;
      }

      this.valueChange.emit(value);
      if(this.maxValue && value > this.maxValue) {
        this.formControl.setValue(this.maxValue);
        this.maxValueReached.emit(this.maxValue);
      }
      if(value < 0) this.formControl.setValue(0);
    })
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public minus(): void {
    if (this.disabled) {
      return;
    }
    const value = this.formControl.value ? +this.formControl.value : 0;
    if (value > 0) {
      this.formControl.setValue(value - 1);
    } else {
      this.formControl.setValue(0);
    }
    this.formControl.markAsTouched();
  }

  public plus(): void {
    if (this.disabled) {
      return;
    }
    let maxValue: number;
    if(this.maxValue) {
      maxValue = this.maxValue;
    } else if(this.mask) {
      maxValue = Math.pow(10, this.mask.length) - 1;
    }
    const value = this.formControl.value ? +this.formControl.value : 0;
    if (value < maxValue) {
      this.formControl.setValue(value + 1);
    } else {
      //this.formControl.setValue;
      this.maxValueReached.emit(this.maxValue);
    }
    this.formControl.markAsTouched();
  }

}
