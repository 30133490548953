import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  @Input()
  public pieChartData: number[] = [];
  @Input()
  public pieChartLabels: Label[] = [];
  public pieChartOptions: ChartOptions = {
    responsive: true,
    //aspectRatio: 1,
    tooltips: {
      backgroundColor: '#f9fafc',
      titleFontSize: 14,
      titleFontColor: '#31394d',
      displayColors: false,
      cornerRadius: 3,
      bodyFontColor: '#31394d',
      bodyAlign: 'center',
      titleAlign: 'center',
      xPadding: 20
    },
  };
  public pieChartLegend = false;
  public pieChartType: ChartType = 'pie';
  public pieChartColors = [
    {
      backgroundColor: ['#ff5660', '#24ccb8', '#ffc400', '#4880ff'],
    },
  ];
  @ViewChild(BaseChartDirective, {static: true}) chart: BaseChartDirective;

  constructor() { }

  ngOnInit() {
  }

}
