<div class="event-list-table-container">
  <table
    mat-table
    [dataSource]="tableSource"
    *ngIf="tableSource"
  >
    <ng-container
      [matColumnDef]="column"
      *ngFor="let column of tableDataColumns"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        class="table-header"
      >
        <div class="table-header-title-wrapper">
          <span class="title">{{getColumnTitle(column) | translate}}</span>
          <span *ngIf="withFilter(column)" class="column-filter">
            <app-multiselect
              [columnFilterOptions]="getSelectOptions(column)"
              (selectedOptions)="selectedOptions($event)"
              [initialSelection]="initialSelection"
            >
              <i class="fas fa-filter action-icon" [class.active-filter]="isActiveFilter(column)"></i>
            </app-multiselect>
          </span>
        </div>
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="widget-table-cell"
        [appTooltip]="tooltip"
      >
        <div
          matTooltip="{{element[column]}}"
          #tooltip="matTooltip"
          matTooltipPosition="above"
          matTooltipClass="table-tooltip"
        ></div>
        <span *ngIf="column !== 'name'; else eventName">{{getTextNode(element, column)}}</span>

        <ng-template #eventName>
          <span
            [class.edit-link]="!draftsData"
            (click)="toEventDashboard(element)"
          >{{element[column]}}</span>
          <span *ngIf="element.isSecondaryEvent" matTooltip="Secondary" matTooltipHideDelay="200" matTooltipShowDelay="200" matTooltipPosition="above" matTooltipClass="table-tooltip">
            <i class="fa fa-link secondary-icon" aria-hidden="true"></i>
          </span>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="settings">
      <th
        mat-header-cell
        *matHeaderCellDef
      ></th>
      <td
        mat-cell
        *matCellDef="let element"
      >
          <button class="settings-container" mat-icon-button [matMenuTriggerFor]="settingsMenu"  *ngIf='isFullPermissionAvailable'>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu
            #settingsMenu
            overlapTrigger
            xPosition="before"
          >
            <div class="table-action-menu">
              <div class="menu-items">
                <button
                  mat-menu-item
                  (click)="onEditLinkClick(element)"
                >{{getEditSeeDetailTextNode(element) | translate}}</button>
                <button
                  *ngIf="isCancelArchiveAvailable(element)"
                  (click)="onCancelArchiveEvent(element)"
                  mat-menu-item
                >{{getCancelArchiveTextNode(element) | translate}}</button>
                <button
                  mat-menu-item
                  class="menu-close"
                >
                  <i class="fas fa-times-circle"></i>
                </button>
              </div>
            </div>
          </mat-menu>

      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="tableColumns; sticky: true"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: tableColumns;"
      class="table-row"
    ></tr>

  </table>

</div>
