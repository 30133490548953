import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogData} from "../../routes/admin/admin-confirmation/admin-confirmation.component";
import FormElementDataModel from "../../models/form.element.data.model";
import {RecurringActionAuthorize} from "../../models/enum/recurring-action-authorize";

@Component({
  selector: 'app-recurring-action-info',
  templateUrl: './recurring-action-form.component.html',
  styleUrls: ['./recurring-action-form.component.scss']
})
export class RecurringActionFormComponent implements OnInit {

  public actionInfoForm: FormGroup = this.fb.group({
    formReason: ['', [Validators.required]],
    formAuthorizedBy: ['',[Validators.required]],
    customer: [''],
  })

  public selectOptions: FormElementDataModel[] = [
    {label: "Credit Card Holder", value: RecurringActionAuthorize.CreditCardHolder},
    {label: "Customer", value: RecurringActionAuthorize.Customer}
  ]
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<RecurringActionFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RecurringActionFormData,
    ) { }

  ngOnInit(): void {
    if(this.data.useAuthorizedPartyNameInput) this.actionInfoForm.get('customer').setValidators(Validators.required)
  }

  public onApprove() {
    if(!this.actionInfoForm.valid) return;
    const formData: any = this.actionInfoForm.getRawValue();
    this.dialogRef.close(formData);
  }

  onCancel() {
    this.dialogRef.close(false)
  }
}

export interface RecurringActionFormData {
  title: string,
  useAuthorizedPartyNameInput: boolean
}
