import { Injectable, Injector } from "@angular/core";
import { EventAddOnTimesUsed } from "src/app/models/event/event-add-on-times-used.model";
import { BaseModelService } from "../base.model.service";

@Injectable({
  providedIn: 'root'
})
export class EventUsedAddOnsAmountService extends BaseModelService<EventAddOnTimesUsed[]> {
  
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'TicketAddonInclude/UsedAddonsAmount';
  }
}