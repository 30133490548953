export const GoogleAuthConfig = {
    clientId: '590240232899-kvr7fgn7ripllkcmi48qt0htdpd6288s.apps.googleusercontent.com',
    discoveryDocs: [],
    scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
}

export const GoogleAuthCalendarConfig = {
    clientId: '590240232899-kvr7fgn7ripllkcmi48qt0htdpd6288s.apps.googleusercontent.com',
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
    scope: 'https://www.googleapis.com/auth/calendar'
}