<div class="header-wrapper1">
  <div class="header-logo" (click)="onLogoClick()"></div>
  <div class="masquerade" *ngIf="isMasquerade">
    <span class="masquerade-text">{{'LAYOUT.You are masquerading as ' | translate}}<span
      class="masquerade-highlighting">{{masqueradeName}}</span> {{'LAYOUT.from' | translate}} <span
      class="masquerade-highlighting">{{masqueradeOrganization}}</span></span>
    <app-button label="{{'LAYOUT.Logout' | translate | uppercase}}" type="basic" (clickEvent)="masqueradeLogout()" width="140" color="#e5f0ff"></app-button>
  </div>
  <div class="header-actions" >
    <div *ngIf="showLanguagePicker$ | async" class="header-action-column">
      <app-language-picker></app-language-picker>
    </div>
    <ng-container *ngIf="isLoggedIn && (urlSelected$ | async)">
      <div
        class="header-action-row"
        (click)="openNotifications()"
      >
        <app-icons
          [iconType]="IconsType.Bell"
          color="#fff"
        ></app-icons>

        <ng-container *ngIf="isContentCreatorOrFundraisingManagerOrClientAdmin">
          <div class="notification-count" *ngIf="(notifications$ | async) && notificationCount">
            <span class="notification-count-value">{{notificationCount}}</span>
          </div>
        </ng-container>

        <span class="header-action-row-label">{{isAdminPortal ? 'Notifications' : ('LAYOUT.Notifications' | translate)}}</span>
      </div>

      <div class="header-action-row" (click)="logout()">
        <app-icons
          [iconType]="IconsType.LogOut"
          color="#fff"
        ></app-icons>
        <span class="header-action-row-label">{{isAdminPortal ? 'Logout' : ('LAYOUT.Logout' | translate)}}</span>
      </div>
    </ng-container>

  </div>
  <div class="tablet" *ngIf="router.url!='/'" (click)="toggleMenu()">
    <app-icons
      *ngIf="!isMobileMenuOpen; else closeIcon"
      [iconType]="IconsType.Menu"
      color="#fff"
    ></app-icons>
    <ng-template #closeIcon>
      <app-icons
        [iconType]="IconsType.Close"
        color="#fff"
      ></app-icons>
    </ng-template>
  </div>
</div>

<div [ngClass]="isMobileMenuOpen ? 'content-wrapper fix-content-wrapper' : 'content-wrapper'"
     (click)="onContentClick()">
  <div [class.loading]="isDataLoading">
    <router-outlet></router-outlet>
  </div>

  <div *ngIf="isDataLoading" class="spinner">
    <app-main-spinner></app-main-spinner>
  </div>
</div>


<div class="footer-wrapper">
  <div class="footer-links">
    <div>
      <span class="footer-owner-desktop">&#169; The Auxilia {{currentYear}} Version: {{appVersion}}</span>
    </div>
    <a class="footer-link" href="https://www.theauxilia.com/careers">{{isAdminPortal ? 'Careers' : ('LAYOUT.Careers' | translate)}}</a>
    <a class="footer-link" href="https://www.theauxilia.com/privacy-policy">{{isAdminPortal ? 'Privacy Policy' : ('LAYOUT.Privacy Policy' | translate)}}</a>
    <a class="footer-link" href="https://www.theauxilia.com/terms-of-use">{{isAdminPortal ? 'Terms of Use' : ('LAYOUT.Terms of Use' | translate)}}</a>
    <a class="footer-link" href="https://www.theauxilia.com/contact">{{isAdminPortal ? 'Contact Us' : ('LAYOUT.Contact Us' | translate)}}</a>
    <app-language-picker *ngIf="showLanguagePicker$ | async" [showLabel]="true"></app-language-picker>
  </div>
  <div class="footer-networks">
    <a class="footer-link" href="https://www.facebook.com/Auxilia-107010537442015/" target="_blank">
      <app-icons
        [iconType]="IconsType.Facebook"
        color="#93ccff"
      ></app-icons>
    </a>
    <a class="footer-link" href="https://www.instagram.com/auxiliasoftware/" target="_blank">
      <app-icons
        [iconType]="IconsType.Instagram"
        color="#93ccff"
      ></app-icons>
    </a>
    <a class="footer-link" href="https://twitter.com/AuxiliaSoftware" target="_blank">
      <app-icons
        [iconType]="IconsType.Twitter"
        color="#93ccff"
      ></app-icons>
    </a>
    <a class="footer-link" href="https://www.linkedin.com/company/auxilia-software/" target="_blank">
      <app-icons
        [iconType]="IconsType.LinkedIn"
        color="#93ccff"
      ></app-icons>
    </a>
  </div>
  <span class="footer-owner-tablet">&#169; The Auxilia {{currentYear}}</span>
</div>

<div *ngIf="isMobileMenuOpen" class="layout-menu">
  <ng-container *ngIf="!isAdmin; else admimMobileMenu">
    <a *ngIf="adminPermission" class="menu-item" routerLink="/clients/organization" routerLinkActive="active" (click)="toggleMenu()">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.Case"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Organization' | translate}}</span>
    </a>

    <a *ngIf="adminManagerPermission || donorManagerPermission" class="menu-item" routerLink="/clients/dashboard" routerLinkActive="active" (click)="toggleMenu()">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.Grid"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Dashboard' | translate}}</span>
    </a>

    <a *ngIf="adminManagerPermission || donorManagerPermission" class="menu-item" routerLink="/clients/donors" routerLinkActive="active" (click)="toggleMenu()">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.Group"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Donors' | translate}}</span>
    </a>

    <a class="menu-item" routerLink="/clients/events" routerLinkActive="active" (click)="toggleMenu()">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.Calendar"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Events' | translate}}</span>
    </a>

    <a class="menu-item" routerLink="/clients/campaigns" routerLinkActive="active" (click)="toggleMenu()">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.Campaigns"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Campaigns' | translate}}</span>
    </a>

    <a *ngIf="adminManagerPermission || donorManagerPermission" class="menu-item" routerLink="/clients/contributions" routerLinkActive="active" (click)="toggleMenu()">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.File"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Contributions' | translate}}</span>
    </a>

    <a *ngIf="creatorManagerPermission || donorManagerPermission" class="menu-item" routerLink="/clients/social-media" routerLinkActive="active" (click)="toggleMenu()">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.Share"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Social Media' | translate}}</span>
    </a>

    <a *ngIf="adminManagerPermission || donorManagerPermission" class="menu-item" routerLink="/clients/reports" routerLinkActive="active" (click)="toggleMenu()">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.BarChart"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Reports' | translate}}</span>
    </a>

    <a *ngIf="templatePermission" class="menu-item" routerLink="/clients/library" routerLinkActive="active" (click)="toggleMenu()">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.Layout"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Templates' | translate}}</span>
    </a>

    <a *ngIf="adminManagerPermission || donorManagerPermission" class="menu-item" routerLink="/clients/customization" routerLinkActive="active" (click)="toggleMenu()">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.Options"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Customization' | translate}}</span>
    </a>

    <a *ngIf="showUploadResults" class="menu-item" routerLink="/clients/donors/upload" routerLinkActive="active"
       (click)="toggleMenu()">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.CloudUpload"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Upload Results' | translate}}</span>
    </a>
  </ng-container>

  <ng-template #admimMobileMenu>
    <a class="menu-item" routerLink="/admin-dashboard/clients" routerLinkActive="active">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.Case"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Organization' | translate}}</span>
    </a>

    <a class="menu-item" routerLink="/admin-dashboard/monitor" routerLinkActive="active">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.Monitor"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Auxilia Monitor' | translate}}</span>
    </a>

    <a class="menu-item" routerLink="/admin-dashboard/reports" routerLinkActive="active">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.BarChart"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Reports' | translate}}</span>
    </a>

    <a class="menu-item" routerLink="/admin-dashboard/donors" routerLinkActive="active">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.Group"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Donors' | translate}}</span>
    </a>
    <a class="menu-item" routerLink="/admin-dashboard/library" routerLinkActive="active">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.Layout"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Templates' | translate}}</span>
    </a>
    <a class="menu-item" routerLink="/admin-dashboard/email-settings" routerLinkActive="active">
      <div class="menu-item-icon">
        <app-icons
          [iconType]="IconsType.Email"
          color="#798594"
        ></app-icons>
      </div>
      <span class="menu-item-link">{{'LAYOUT.Email settings' | translate}}</span>
    </a>
  </ng-template>


  <div class="logout-block">
    <div *ngIf="showLanguagePicker$ | async" class="header-action-column">
      <app-language-picker></app-language-picker>
    </div>

    <div class="header-action-row" (click)="logout(); toggleMenu()">
      <app-icons
        [iconType]="IconsType.LogOut"
        color="#fff"
      ></app-icons>
      <span class="header-action-row-label">{{'LAYOUT.Log Out' | translate}}</span>
    </div>
  </div>

</div>
