<div
  [formGroup]="formGroup"
  class="custom-form-component-container-wrapper"
>
  <label
    class="form-field-name"
    [for]="id"
  >{{label}}</label>
  <mat-form-field appearance="outline">
    <input
      matInput
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      [formControlName]="name"
      [ngxTimepicker]="timePicker"
      [readonly]="readonly"
      [id]="id"
      [value]="formControl.value"
    >
    <div class="time-icon"></div>
    <!-- Theme for current version of ngx-material-timepicker is not available: [theme]="timePickerTheme"-->
    <ngx-material-timepicker
      #timePicker
      (timeSet)="onDateChange($event)"
    ></ngx-material-timepicker>
    <mat-error *ngIf="formControl.invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>
</div>
