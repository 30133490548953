import { Patterns } from './patterns.model';
import { PriceTicket } from '../../models/enum/event.price.ticket';
import { AbstractControl, AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CouponValue } from '../../models/event/event-coupon.model';
import twitter from 'twitter-text';
import { Observable, of } from 'rxjs';
import { EventTicketService } from 'src/app/services/events/event-ticket.service';
import { Paging } from 'src/app/models/paging/paging.model';
import { FilterType } from 'src/app/models/enum/filter.type';
import { debounceTime, first, map, switchMap } from 'rxjs/operators';

export const unique = (value: any, index: any, self: string | any[]) => {
  return self.indexOf(value) === index;
}

export class FormValidators {
  static greaterThan(value: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      if (control.value && +control.value <= value) {
        return { 'greaterThan': `Value must be greater than ${value}` };
      }
    };
  }

  static existsInList(values: any[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (values && values.length > 0 && control.value && values.includes(control.value)) {
        return { 'existsInList': true };
      }
    };
  }

  static maxUniqueHashtags(maxCount: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      //if (control.value && new Set(control.value.match(Patterns.hashtag)).size > maxCount) {
      if (control.value && control.value.match(Patterns.hashtag) && control.value.match(Patterns.hashtag).filter(unique).length > maxCount) {
        return { 'hashtag': maxCount.toString() };
      }
    };
  }

  /*static get onlySpaces(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && control.value.replace(/\s+/g, '').length === 0) {
        return { 'onlySpaces': true };
      }
    };
  }*/

  /*static get spacesOnTheSides(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if ((control.value !== null && (control.value[0] === ' ' || control.value[(control.value.length - 1)] === ' ')) && control.value.replace(/\s+/g, '').length > 0) {
        return { 'spacesOnTheSides': true };
      }
    };
  }*/
  static get oneCapitalLetter(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null && control.value === control.value.toLowerCase()) {
        return { 'oneCapitalLetter': true };
      }
    };
  }

  static containsPlaceholder(placeholder: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      if (control.value !== null && control.value.includes(placeholder)) {
        return { 'containsPlaceholder': placeholder };
      }
    };
  }

  static get oneSmallLetter(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = String(control.value);
      if (control.value !== null && control.value === value.toUpperCase()) {
        return { 'oneSmallLetter': true };
      }
    };
  }

  static get oneNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = String(control.value);
      if (control.value !== null && !(value.includes('1') || value.includes('2') || value.includes('3') || value.includes('4') || value.includes('5') || value.includes('6') || value.includes('7') || value.includes('8') || value.includes('9') || value.includes('0'))) {
        return { 'oneNumber': true };
      }
    };
  }

  static get specialSymbol(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = String(control.value);
      if (control.value !== null && !(value.includes('!') || value.includes('@') || value.includes('*') || value.includes('$') || value.includes('%'))) {
        return { 'specialSymbol': true };
      }
    };
  }

  static passwordsEqual(group: FormGroup) {
    if (group.get('password') && group.get('confirmPassword')) {
      const pass = group.get('password').value;
      const confirmPass = group.get('confirmPassword').value;
      return pass === confirmPass ? null : { 'notSame': true };
    }
  }

  static atLeastOne(group: FormGroup) {
    let count = 0;
    Object.keys(group.controls).forEach(element => {
      if (group.controls[element] && group.controls[element].get('quantity').value !== '') {
        count++;
      }
    });
    return count !== 0 ? null : { 'atLeastOne': true };
  }

  static get emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const EMAIL_REGEXP = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/gi;
      if (control.value && control.value !== '' && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
        return { 'email': true };
      }
      return null;
    };
  }

  static comparePrimaryEmailWithAlternative(primaryEmail: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value === primaryEmail) {
        return { emailIsCompareWithPrimary: true }
      }
      return null
    }

  }

  static get emailListValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;
      if (!value) {
        return null;
      } else if (value.length <= 5) {
        return { 'email': true };
      } else if (value.indexOf(',') !== -1) {
        return { 'emailList': true };
      } else {
        const emails = value.split(';');
        const EMAIL_REGEXP = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;
        const errors = emails.filter((item: string) => !EMAIL_REGEXP.test(item.trim()));
        return errors.length ? { 'email': true } : null;
      }
    };
  }

  static deductibleAmountValidator(group: FormGroup): { [key: string]: boolean } | null {
    const deductibleAmount = group.get('deductibleAmount');
    const price = group.get('price');
    if (deductibleAmount && deductibleAmount.value && price && price.value && +deductibleAmount.value > +price.value) {
      deductibleAmount.setErrors({ 'deductibleAmount': true });
    }
    else {
      deductibleAmount.setErrors(null)
    }
    return null;
  }

  static priceTicketRegistrationFeeDeductibleAmountValidator(group: FormGroup): { [key: string]: boolean } | null {
    const priceTicketType = group.get('priceTicketType');
    const deductibleAmount = group.get('deductibleAmount');
    const registrationFee = group.get('registrationFee');
    if (priceTicketType && priceTicketType.value !== PriceTicket.RegistrationFee) {
      deductibleAmount && deductibleAmount.setErrors(null);
    } else if (deductibleAmount && deductibleAmount.value && registrationFee && registrationFee.value && +deductibleAmount.value > +registrationFee.value) {
      deductibleAmount.setErrors({ 'registrationFee': true });
    } else {
      deductibleAmount && deductibleAmount.value && deductibleAmount.setErrors(null);
    }
    return null;
  }

  /*static lengthCJKValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value.toString();
      let count = 0;
      const isChinese = (char: string): boolean => {
        const REGEX_CHINESE = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\u{2f800}-\u{2fa1f}]/u;
        return REGEX_CHINESE.test(char);
      };
      const isJapanese = (char: string): boolean => {
        const REGEX_JAPANESE = /[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/;
        return REGEX_JAPANESE.test(char);
      };
      const isKorean = (char: string): boolean => {
        const REGEX_KOREAN = /[\u1100-\u11FF\u3130-\u318F\uA960-\uA97F\uAC00-\uD7AF\uD7B0-\uD7FF]/g;
        return REGEX_KOREAN.test(char);
      };
      for (let i = 0; i < value.length; i++) {
        const char = value[i];
        if (isChinese(char) || isJapanese(char) || isKorean(char)) {
          count += 2;
        } else {
          count += 1;
        }
      }
      if (count > maxLength) {
        return {'lengthCJK': {actualLength: count, requiredLength: maxLength}};
      } else {
        return null;
      }
    };
  }*/

  static uniqueControlValueInFormArray(controlName: string): ValidatorFn {
    const emptyValue = '';
    return (formArray: FormArray): { [key: string]: boolean } | null => {
      const values: Set<string> = new Set();
      formArray.controls.forEach((formGroup) => {
        const control = formGroup.get(controlName);
        const currentValue = control.value;
        if (currentValue === emptyValue && values.has(currentValue)) {
          values.delete(currentValue);
          return;
        }
        if (values.has(currentValue)) {
          control.setErrors({'uniqueControlValueInFormArray': true});
          control.markAsTouched();
        } else {
          values.add(currentValue);
        }
      });
      return null;
    };
  }

  static couponValueValidator(group: FormGroup): { [key: string]: boolean } | null {
    const price = group.get('price') as FormControl;
    const couponList = group.get('couponList') as FormArray;

    price && couponList && couponList.controls.forEach((couponControl: FormGroup) => {
      const couponTypeControl = couponControl.get('couponType') as FormControl;
      const couponValueControl = couponControl.get('couponValue') as FormControl;
      if (
        couponValueControl
        && couponTypeControl
        && couponTypeControl.value === CouponValue.Percent
        && +couponValueControl.value > 100
      ) {
        couponValueControl.setErrors({'percent': {actual: couponValueControl.value, required: 100}});
      } else if (
        price.value
        && couponValueControl
        && couponTypeControl
        && couponTypeControl.value === CouponValue.Dollar
        && +couponValueControl.value > +price.value
      ) {
        couponValueControl.setErrors({'dollar': {actual: couponValueControl.value, required: price.value}});
      } else {
        couponValueControl && couponValueControl.setErrors(null);
      }
    });
    return null;
  }

  static twitterTextValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value !== '') {
        const tweet = twitter.parseTweet(control.value);
        if (tweet.valid) {
          return null;
        }
        return { 'maxlength': { actualLength: tweet.weightedLength, requiredLength: maxLength }};
      }
      return null;
    };
  }

  static simpleDateValidator(formGroup: FormGroup): ValidationErrors {
    const startDateControl = formGroup.get('startDate');
    const endDateControl = formGroup.get('endDate');
    const { startDate, endDate } = formGroup.value;
    if (startDate && endDate && startDate > endDate) {
      startDateControl.setErrors({ invalidDate: true });
      endDateControl.setErrors({ invalidDate: true });
      return { invalidDate: true }
    } else if (!(startDateControl.hasError('required') || endDateControl.hasError('required'))) {
      startDateControl.setErrors(null);
      endDateControl.setErrors(null);
      return null;
    }
  }

  static maxNameLengthValidator(maxNumber: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value.length >= maxNumber) {
        control.markAsTouched();
        return {'invalidLength': true}
      }
      return null;
    }
  }

  static soldCountValidatorAsync(service: EventTicketService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const ticketId = control.parent.get('id').value;
      if(!ticketId || !control.dirty) return of(null);
      const paging: Paging = {
        filters: [
          {
            field: 'ticketPackageID',
            value: ticketId,
            type: FilterType.Equal
          }
        ]
      }
      return control.valueChanges
        .pipe(
          debounceTime(500),
          switchMap(() => {
            return service.getSoldCount(paging);
          }),
          map(({total}) => {
            return control.value < total ?
              {'lessThanSold': true} : null;
          })
        ).pipe(first())
    }
  }

  static consecutiveCharacterValidator(characters: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const pattern = new RegExp("(?:[" + characters + "]{2,})");
      if(pattern.test(control.value)) {
        return {forbiddenConsecutiveCharacters: characters.split('')}
      }
      return null;
    }
  }

  static whitespaceAfterValidator(characters: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const pattern = new RegExp("[" + characters + "](?=\\s|$)")
      if(pattern.test(control.value)) {
        return {forbiddenWhitespaceAfterCharacter: characters.split('')}
      }
      return null;
    }
  }

  static precedingCharacterBeforeLinkValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const pattern = /(\w+|[^/\s])(www|http(|s)).+/m;
      if(pattern.test(control.value)) {
        return {precedingCharacterBeforeLink: true}
      }
      return null;
    }
  }
}
