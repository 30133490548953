<div class="main-block">
    <div class="close-block">
        <img src="assets/images/icon-close-popup.png" (click)="closeComponent()">
    </div>
    <div class="info-block">
        <div class="copy-block">
            <h1 mat-dialog-title>{{data.title | translate}}</h1>
            <img src="assets/images/icon-copy.png" (click)="copyMessage()">
        </div>
        <p>
            {{data.description | translate}}
        </p>
    </div>
  </div>

