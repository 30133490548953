<div class="home-wrapper">
  <div class="home-content">
    <div class="home-top-image"></div>
    <div *ngIf="!linkSent; else sentLinkTemplate" class="home-content-body">
      <span class="home-content-body-title" *ngIf="!emailError">{{'AUTH.Password Reset' | translate}}</span>
      <div class="short-description" *ngIf="!emailError">
        {{'AUTH.Enter the email address associated with your account' | translate}}
      </div>
      <div class="title-error" *ngIf="emailError">
        <img src="/assets/icons/times1.svg" style="margin-right: 15px;">{{'AUTH.Sorry, the email you entered was not found in our records. Please try again or click the Sign Up button below to create an account.' | translate}}
      </div>
      <form class="form-body">
        <mat-form-field appearance="outline">
          <mat-label>{{'Email Address' | translate}}</mat-label>
          <input [formControl]="email" matInput (keydown.enter)="submit()">
        </mat-form-field>
        <br>
        <div class="home-content-body-button">
          <app-button type="flat" label="SUBMIT" (clickEvent)="submit()" color="#0064be" width="100%"></app-button>
        </div>

        <span *ngIf="emailError" class="home-content-body-title">{{"AUTH.Don't have an Account?" | translate}}</span>
        <div *ngIf="emailError" class="home-content-body-button">
          <app-button type="flat" label="SIGN UP" (clickEvent)="createAccount()" color="#0064be" width="100%"></app-button>
        </div>
      </form>
    </div>

    <ng-template #sentLinkTemplate>
      <div class="title-sent">
        <div class="header">
          <img src="/assets/icons/check1.svg" style="margin-right: 15px;">{{'AUTH.Link Sent' | translate}}
          <div class="short-description">
            {{'AUTH.Check your Email' | translate}} {{email.value}}
          </div>
        </div>
        <div class="description" [innerHTML]="'AUTH.CheckYourEmail' | translate: {value: email.value}"></div>
        <div class="description" [innerHTML]="'AUTH.SupportLink' | translate">
          <!--If you still don't see it, you can click the <a (click)="back()" routerLink="/resetpass">Forgot Your Password</a> link and try again. You may also submit a request to our Client Success team at <a href="mailto:support@theauxilia.com">support@theauxilia.com</a>.
          We will respond to your request within 24 business hours.-->
        </div>
      </div>
    </ng-template>
  </div>
</div>
