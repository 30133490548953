import { Component, Input, OnInit } from '@angular/core';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { MjmlCustomType } from '../../../../models/templates/mjml.custom.type';

@Component({
  selector: 'app-image-tool',
  templateUrl: './image-tool.component.html',
  styleUrls: ['../img-tool.scss']
})
export class ImageToolComponent implements OnInit {
  @Input() public column: MjmlElementModel;
  @Input() public entity: MjmlElementModel;
  @Input() public tms: TemplateManagementService;
  @Input() public fundraiserId: string;

  constructor() { }

  ngOnInit() {

  }

  public getContainerStyles(): any {
    const attributes = this.entity.attributes;
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    return {
      'padding-bottom': paddingBottom,
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      'padding-top': paddingTop,
    };
  }

  public get label(): string {
    return this.entity.attributes['customType'] === MjmlCustomType.logo ? 'Logo' : 'Image';
  }

  public getTableStyles(): any {
    const attributes = this.entity.attributes;
    let alignSelf = '';
    switch (attributes['align']) {
      case 'left':
        alignSelf = 'flex-start';
        break;
      case 'right':
        alignSelf = 'flex-end';
        break;
      default:
        alignSelf = 'center';
    }
    return {
      'align-self': alignSelf,
    };
  }

  public getImageStyles(): any {
    const attributes = this.entity.attributes;
    return {
      'border-radius': attributes['border-radius'] || '0' + 'px',
      'width': attributes['width'] || '100%',
      'height': !this.fundraiserId && attributes['height'] || 'auto',
      'border': attributes['border']
    };
  }

}
