export enum LayerType {
  text = 1,
  image = 2,
  logo = 3,

  /** Event data */
  eventName = 4,
  eventDescription = 5,
  eventLocation = 6,
  eventScheduler = 7,
  eventContactDetails = 8,
  eventScheduleItinerary = 9,
  eventAccessibilityStatement = 10,

  /** Campaign data */
  campaignName = 11,

  /** Shapes */
  circle = 12,
  square = 13,
  triangle = 14,
  hexagon = 15,
  pentagon = 16,
}
