import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InfoNotificationModel } from 'src/app/models/reports/info.notification.model';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { InfoNotificationService } from 'src/app/services/reports/info.notification.service';
import { AuthService } from 'src/app/services/auth.service';

import { InfoNotificationStatus } from 'src/app/models/enum/info-notification.status';
import { ClientModuleService } from '../../../routes/clients/client.module.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  InfoNotificationStatus = InfoNotificationStatus;
  public subscription: Subscription = new Subscription();
  private userId: string = this.authService.getIdentityClaimsId();
  public commonNotificationData: InfoNotificationModel = {
    updatedByID: this.userId,
    updatedByUserName: ''
  };
  public isArchived: boolean = false;

  firstFive: InfoNotificationModel[];
  public show: boolean = false;
  private infoNotifications: InfoNotificationModel[];
  archiveData: InfoNotificationModel[];
  constructor(
    public dialogRef: MatDialogRef<NotificationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InfoNotificationModel[],
    private infoNotificationService: InfoNotificationService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private clientModuleService: ClientModuleService
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.clientModuleService.userName.subscribe((userName: string) => this.commonNotificationData.updatedByUserName = userName)
    );
    this.getFirstFive();

    if (this.data.filter(item => !item.isRead || !item.isArchived).length > 5) {
      this.show = true;
    }

    this.getArchived();

    this.subscription.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
  }

  public getFirstFive() {
    this.infoNotifications = [...this.data].filter(
      item => !item.isRead || !item.isArchived
    );
    this.firstFive = this.infoNotifications.splice(0, 5);
  }

  public onViewAll() {
    this.show = false;
    this.firstFive = [...this.firstFive, ...this.infoNotifications];
  }

  public checkDate(someDate: string): string {
    const infoDate = new Date(someDate);
    const today = new Date();
    if (
      infoDate.getDate() === today.getDate() &&
      infoDate.getMonth() === today.getMonth() &&
      infoDate.getFullYear() === today.getFullYear()
    ) {
      return 'Today';
    } else {
      return this.datePipe.transform(someDate, 'MMMM d y');
    }
  }

  public getArchived() {
    this.archiveData = this.data.filter(val => val.isArchived);
  }

  public onMarkAsRead(notification: InfoNotificationModel) {
    this.subscription.add(
      this.infoNotificationService
        .updateModel({
          ...notification,
          ...this.commonNotificationData,
          isRead: true
        })
        .subscribe(res => {
          const updatedNotification = this.data.find(
            item => item.id === res.id
          );
          if (updatedNotification) {
            updatedNotification.isRead = true;
          }
        })
    );
  }

  public onMarkAsArchive(notification: InfoNotificationModel) {
    this.subscription.add(
      this.infoNotificationService
        .updateModel({
          ...notification,
          ...this.commonNotificationData,
          isArchived: true,
          isRead: true,
          isDeleted: false
        })
        .subscribe(res => {
          const updatedNotification = this.data.find(
            item => item.id === res.id
          );
          if (updatedNotification) {
            const index = this.data.findIndex(
              item => item.id === updatedNotification.id
            );
            if (index > -1) {
              this.data.splice(index, 1);
            }
            this.getFirstFive();
            this.archiveData = [res, ...this.archiveData];
          }
        })
    );
  }
  public onRemove(notification: InfoNotificationModel) {
    this.subscription.add(
      this.infoNotificationService
        .updateModel({
          ...notification,
          ...this.commonNotificationData,
          isDeleted: true
        })
        .subscribe(() => {
          this.archiveData = this.archiveData.filter(
            val => val.id !== notification.id
          );
        })
    );
  }

  public onMarkAll(status: InfoNotificationStatus) {
    if (!this.data.length) return;

    if (status === InfoNotificationStatus.Archive) {
      this.data = this.data.map(item => {
        return {
          ...item,
          isArchived: true,
          isRead: true
        };
      });
      this.infoNotificationService.updateAll(this.data).subscribe(result => {
        this.data = [];
        this.dialogRef.close(this.data);
      });
    } else if (status === InfoNotificationStatus.Read) {
      this.data = this.data.map(item => {
        return {
          ...item,
          isRead: true
        };
      });
      this.infoNotificationService.updateAll(this.data).subscribe(result => {
        this.data.forEach(item => {
          item.isRead = true;
        });
        this.getFirstFive();
      });
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.dialogRef.close(this.data);
  }
}
