<div class="notifications-wrapper">
  <div class="d-flex justify-content-between header">
    <div>
      <i (click)="isArchived=!isArchived" class="fas fa-archive light" [ngClass]="{'light': !isArchived, 'dark': isArchived}"></i>
      <span (click)="isArchived=!isArchived" class="archived" [ngClass]="{'light': !isArchived, 'dark': isArchived}">{{'Archived' | translate}}</span>
    </div>
    <div>
      <span (click)="onMarkAll(InfoNotificationStatus.Read)" *ngIf="!isArchived" class="mark-all">{{'Mark All as Read' | translate}}</span>
      <span (click)="onMarkAll(InfoNotificationStatus.Archive)" *ngIf="!isArchived" class="mark-all">{{'Archive All' | translate}}</span>
    </div>
    <span *ngIf="isArchived" (click)="isArchived=!isArchived" class="mark-all">
      < {{'Back to Notifications' | translate}}</span>
  </div>

  <ng-container *ngIf="data.length; else noData">
    <ng-container *ngIf="!isArchived; else archived">
      <div class="body body-border" [class.noReaded]="!notification.isRead" *ngFor="let notification of firstFive">

        <p class="date">{{checkDate(notification?.updatedOn || notification?.createdOn)}}</p>
        <div>
          <p class="text" [innerHTML]="notification?.content | safeHTML"></p>
          <div class="d-flex">
            <span *ngIf="!notification.isRead" (click)="onMarkAsRead(notification)" class="mark-all">{{'Mark as Read' | translate}}</span>
            <span class="mark-all" (click)="onMarkAsArchive(notification)">{{'Archive' | translate}}</span>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #archived>
      <div class="body body-border" *ngFor="let notification of archiveData">

        <p class="date">{{checkDate(notification?.updatedOn || notification?.createdOn)}}</p>
        <div>
          <p class="text" [innerHTML]="notification?.content | safeHTML"></p>
          <div class="d-flex">
            <span (click)="onRemove(notification)" class="mark-all">{{'Remove' | translate}}</span>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #noData>
    <div class="body body-border">
      <p class="text"> {{'No notifications' | translate}}</p>
    </div>
  </ng-template>



  <div *ngIf="show && !isArchived" class="footer">
    <span (click)="onViewAll()" class="mark-all">{{'View All' | translate}}</span>
  </div>

</div>
