<div class="email-builder-tool-settings-wrapper">
  <div class="email-builder-tool-settings-row">
    <div class="max-width-310" *ngIf="isEventTemplate">
      <app-radio
        [formGroup]="sectionForm"
        label="Apply to registration and payment forms as banner"
        name="inheritSection"
        [selectOptions]="inheritOptions"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (radioValueChanged)="inheritSectionChanged($event)"
      ></app-radio>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="sectionForm"
        label="Background color"
        name="backgroundColor"
        [readOnly]="readOnly || isPredesignedThemedTemplate"
        (colorChanged)="onBackgroundColorChanged()"
      ></app-email-color-picker>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <app-image-loader
      [formGroup]="sectionForm"
      name="backgroundUrl"
      label="Upload background image"
      [readOnly]="readOnly || isPredesignedThemedTemplate"
      [imageURL]="imageURL"
      (valueChanged)="imageUrlChanged($event)"
    ></app-image-loader>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-input
        label="Background image URL"
        [formGroup]="sectionForm"
        name="backgroundUrl"
        errorMassage="URL"
        [low]="true"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onBackgroundUrlChanged()"
      ></app-input>
    </div>

    <app-button
      *ngIf="sectionForm.get('backgroundUrl') && sectionForm.get('backgroundUrl').value && !imageURL && !readOnly && !isPredesignedThemedTemplate"
      label="Edit Image"
      type="flat"
      (clickEvent)="editImage()"
      color="#0064be"
      width="140"
    ></app-button>
    <!--<app-button
      *ngIf="!!imageURL"
      label="Cancel"
      type="flat"
      (clickEvent)="cancelEditImage()"
      color="#ec4c47"
      width="140"
    ></app-button>-->

  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Repeat Background?"
        [formGroup]="sectionForm"
        name="backgroundRepeat"
        [low]="true"
        [selectOptions]="backgroundRepeatOptions"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onBackgroundRepeatChanged()"
      ></app-select>
    </div>
    <div class="max-width-145">
      <app-select
        label="Scale background image"
        [formGroup]="sectionForm"
        name="backgroundSize"
        [low]="true"
        [selectOptions]="backgroundSizeOptions"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onBackgroundSizeChanged()"
      ></app-select>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="sectionForm"
        name="paddingTop"
        label="Padding Top"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingTopChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="sectionForm"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingRightChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="sectionForm"
        name="paddingBottom"
        label="Padding Bottom"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingBottomChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="sectionForm"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingLeftChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
</div>
