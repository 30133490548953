import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilderStateService} from '../../form-builder.state.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import FbTemplateModel from '../../../../models/form-builder/fb.template.model';
import {debounceTime, distinctUntilChanged, map, tap} from 'rxjs/operators';
import {Observable, Subject, Subscription} from 'rxjs';
import FormElementDataModel from '../../../../models/form.element.data.model';
import {DefaultThankYouPageOptions} from '../../../../constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-thank-you-page-settings',
  templateUrl: './thank-you-page-settings.component.html',
  styleUrls: ['../fb.page.scss']
})
export class ThankYouPageSettingsComponent implements OnInit, OnDestroy {
  @Input() public fbss: FormBuilderStateService;
  @Input() public activeStep: boolean = false;

  public thankYouPageHtml$: Observable<boolean>;
  public placeholders: string[] = ['DonorName', 'Email', 'Phone', 'DonateAmount', 'Funds', 'FundsList'];
  public pageOptions: FormElementDataModel[] = DefaultThankYouPageOptions;

  private subscriptions: Subscription = new Subscription();

  public form: FormGroup = this.formBuilder.group({
    thankYouPageHtml: ''
  });

  private changesEmitter: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    public translate: TranslateService
  ) { }

  public ngOnInit(): void {
    this.thankYouPageHtml$ = this.fbss.template$.asObservable()
      .pipe(
        tap((template: FbTemplateModel) => {
          this.form.get('thankYouPageHtml').setValue(this.translate.instant(template.thankYouPageHtml));
          this.fbss.templateSettingsForm.get('customThankYouPageUrl').setValue(template.customThankYouPageUrl);
          this.fbss.templateSettingsForm.get('isCustomThankYouPage').setValue(template.isCustomThankYouPage);
        }),
        map((template: FbTemplateModel) => true)
    );

    this.subscriptions.add(
      this.form.get('thankYouPageHtml').valueChanges
      .subscribe(value => this.fbss.template$.getValue().thankYouPageHtml = value)
    );
    this.subscriptions.add(
      this.fbss.templateSettingsForm.get('isCustomThankYouPage').valueChanges
        .subscribe(value => {
          if (value) {
            this.fbss.templateSettingsForm.get('customThankYouPageUrl').enable();
          } else {
            this.fbss.templateSettingsForm.get('customThankYouPageUrl').disable();
          }
          this.fbss.template$.getValue().isCustomThankYouPage = value
        })
    );

    this.subscriptions.add(
      this.fbss.templateSettingsForm.get('customThankYouPageUrl').valueChanges
        .subscribe(value => this.fbss.template$.getValue().customThankYouPageUrl = value)
    );

    setTimeout(() => {
      this.subscriptions.add(
        this.changesEmitter.asObservable()
          .pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            tap(() => this.fbss.templateWasChanged(true))
          )
          .subscribe()
      )
    }, 3000);
  }

  public get isDefaultPage(): boolean {
    return this.fbss.templateSettingsForm.get('isCustomThankYouPage').value;
  }

  public pageContentChanged(): void {
    if (this.fbss.templateSettingsForm.get('isCustomThankYouPage').value) {
      this.fbss.templateSettingsForm.get('customThankYouPageUrl').setValue('');
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }

  public onValueChanged(): void {
    this.changesEmitter.next();
  }
}
