<div class="email-builder-tool-settings-wrapper">

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        label="Button Text"
        [formGroup]="buttonForm"
        name="text"
        [low]="true"
        errorMassage="Button Text"
        [disabled]="readOnly"
        (valueChanged)="onTextChanged()"
      ></app-input>
    </div>
  </div>

  <div
    *ngIf="isAttachment"
    class="email-builder-tool-settings-row"
  >
    <div class="max-width-310">
      <app-file-loader
        [formGroup]="buttonForm"
        name="href"
        label="Upload file"
        [readOnly]="readOnly"
        (valueChanged)="onHrefChanged()"
      ></app-file-loader>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        [label]="linkLabel"
        [formGroup]="buttonForm"
        name="href"
        [low]="true"
        errorMassage="Link"
        [disabled]="readOnly || tms.activeElement?.attributes['deletable'] === 'false'"
        (valueChanged)="onHrefChanged()"
      ></app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="buttonForm"
        label="Button color"
        name="backgroundColor"
        [readOnly]="readOnly"
        (colorChanged)="onBackgroundColorChanged()"
      ></app-email-color-picker>
    </div>

    <div class="max-width-145">
      <app-select
        label="Position"
        [formGroup]="buttonForm"
        name="align"
        errorMassage="Position"
        [selectOptions]="alignOptions"
        [low]="true"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onAlignChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="buttonForm"
        label="Text Color"
        name="color"
        [readOnly]="readOnly"
        (colorChanged)="onColorChanged()"
      ></app-email-color-picker>
    </div>

    <div class="max-width-145">
      <app-select
        label="Font Family"
        [formGroup]="buttonForm"
        name="fontFamily"
        errorMassage="Font Family"
        [selectOptions]="fontFamilyOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontFamilyChanged()"
      ></app-select>
      <div
        *ngIf="tms.templateAssignmentType === TemplateAssignmentType.Campaign"
        class="settings-info-icon"
        [matTooltip]="toolTipText"
        matTooltipHideDelay="200"
        matTooltipShowDelay="200"
        matTooltipPosition="above"
        matTooltipClass="settings-tooltip-custom-class"
      >
        <i class="fas fa-info-circle"></i>
      </div>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Font Size"
        [formGroup]="buttonForm"
        name="fontSize"
        errorMassage="Font Size"
        [selectOptions]="fontSizeOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontSizeChanged()"
      ></app-select>
    </div>

    <div class="max-width-145">
      <app-select
        label="Font Style"
        [formGroup]="buttonForm"
        name="fontStyle"
        errorMassage="Font Style"
        [selectOptions]="fontStyleOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontStyleChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-select
        label="Font Weight"
        [formGroup]="buttonForm"
        name="fontWeight"
        errorMassage="Font Weight"
        [selectOptions]="fontWeightOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onFontWeightChanged()"
      ></app-select>
    </div>

    <div class="max-width-145">
      <app-select
        label="Decoration"
        [formGroup]="buttonForm"
        name="textDecoration"
        errorMassage="Decoration"
        [selectOptions]="textDecorationOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onTextDecorationChanged()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="buttonForm"
        name="borderWidth"
        label="Border width"
        [min]="0"
        [max]="50"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="setBorder()"
        [disabled]="readOnly"
      ></app-slider>
    </div>

    <div class="max-width-145">
      <app-select
        label="Border style"
        [formGroup]="buttonForm"
        name="borderStyle"
        errorMassage="Border style"
        [selectOptions]="borderStyleOptions"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="setBorder()"
      ></app-select>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-email-color-picker
        [formGroup]="buttonForm"
        label="Border color"
        name="borderColor"
        [readOnly]="readOnly"
        (colorChanged)="setBorder()"
      ></app-email-color-picker>
    </div>

    <div class="max-width-145">
      <app-slider
        [formGroup]="buttonForm"
        name="borderRadius"
        label="Border radius"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onBorderRadiusChanged()"
        [disabled]="readOnly"
      ></app-slider>
    </div>
  </div>


  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="buttonForm"
        name="innerPaddingTopBottom"
        label="Inner padding top/bottom"
        [min]="0"
        [max]="25"
        [step]="1"
        [tickInterval]="5"
        (valueChanged)="setInnerPadding()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>

    <div class="max-width-145">
      <app-slider
        [formGroup]="buttonForm"
        name="innerPaddingLeftRight"
        label="Inner padding left/right"
        [min]="0"
        [max]="50"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="setInnerPadding()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="buttonForm"
        name="paddingTop"
        label="Padding Top"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingTopChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="buttonForm"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingRightChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="buttonForm"
        name="paddingBottom"
        label="Padding Bottom"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingBottomChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="buttonForm"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingLeftChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row" *ngIf="!(readOnly || isPredesignedThemedTemplate || tms.activeElement?.attributes['deletable'] === 'false')">
    <app-button
      type="basic"
      label="Delete content"
      (clickEvent)="tms.removeActiveElementFromColumn()"
      color="#f44336"
      width="300"
    ></app-button>
  </div>

</div>

