import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilderStateService } from '../../form-builder.state.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { first, map, startWith, switchMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SourceTemplateService } from '../../../../services/templates/source.template.service';
import { Filter } from '../../../../models/paging/filter.model';
import { FilterType } from '../../../../models/enum/filter.type';
import { Paging } from '../../../../models/paging/paging.model';
import { FbTemplateStatusType } from '../../../../models/form-builder/fb.template.model';
import TemplateModel from '../../../../models/templates/template.model';

@Component({
  selector: 'app-publish-tab',
  templateUrl: './publish-tab.component.html',
  styleUrls: ['./publish-tab.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PublishTabComponent implements OnInit {
  @Input() public fbss: FormBuilderStateService;

  public currentUrl$: Observable<string>;
  public paymentLink$: Observable<string>;
  public embedCode$: Observable<string>;

  public url: string = location.origin;

  private validationRequest: boolean = false;

  public isTemplateInactive$: Observable<boolean>;

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private sourceTemplateService: SourceTemplateService,
    private cdr: ChangeDetectorRef,
  ) { }

  public ngOnInit(): void {
    const startFriendlyURL = this.friendlyURL.value;
    this.currentFriendlyUrl.setValue(startFriendlyURL);

    this.currentUrl$ = this.currentFriendlyUrl.valueChanges
      .pipe(
        startWith(decodeURI(startFriendlyURL)),
        map(value => encodeURI(this.getPaymentLink(value)))
      );

    this.paymentLink$ = this.friendlyURL.valueChanges
      .pipe(
        startWith(decodeURI(startFriendlyURL)),
        map(value => this.getPaymentLink(value))
      );


    this.embedCode$ = this.friendlyURL.valueChanges
      .pipe(
        startWith(decodeURI(startFriendlyURL)),
        map(value => this.getEmbedCode(value))
      );

    this.isTemplateInactive$ = this.fbss.templateSettingsForm.valueChanges
      .pipe(
        switchMap(({fbTemplateStatus}: Partial<TemplateModel>) => {
          switch (fbTemplateStatus) {
            case FbTemplateStatusType.Active:
              return of(false);
            case FbTemplateStatusType.Inactive:
              return of(true);
            case FbTemplateStatusType.DisableOnDate:
              const disableTime = this.fbss.templateSettingsForm.get('disableTime');
              const disableDate = this.fbss.templateSettingsForm.get('disableDate');
              const disableTimeZone = this.fbss.templateSettingsForm.get('disableTimeZone');
              return of(disableDate.invalid || disableTime.invalid || disableTimeZone.invalid);
          }
        })
      );
  }

  public get friendlyURL(): FormControl {
    return this.fbss.publishForm.get('friendlyURL') as FormControl;
  }

  public get setAsAuxiliaDefault(): FormControl {
    return this.fbss.publishForm.get('setAsAuxiliaDefault') as FormControl;
  }

  public get currentFriendlyUrl(): FormControl {
    return this.fbss.publishForm.get('currentFriendlyUrl') as FormControl;
  }

  public handleCheckboxState(): void {
    const next = !this.setAsAuxiliaDefault.value;
    this.setAsAuxiliaDefault.setValue(next);
    this.fbss.updateDefaultClientTemplate$.next(next);
  }

  public applyUrl(): void {
    const nextValue = encodeURI(this.currentFriendlyUrl.value);
    this.friendlyURL.setValue(nextValue);
    this.fbss.addNewSourceTemplateModel$.next(nextValue);
  }

  public get applyDisabled(): boolean {
    return this.currentFriendlyUrl.invalid || this.validationRequest || this.currentFriendlyUrl.value === decodeURI(this.friendlyURL.value);
  }

  public copyContent(link, message): void {
    const copyBox = document.createElement('textarea');
    copyBox.style.position = 'fixed';
    copyBox.style.left = '0';
    copyBox.style.top = '0';
    copyBox.style.opacity = '0';
    copyBox.value = link;
    document.body.appendChild(copyBox);
    copyBox.focus();
    copyBox.select();
    document.execCommand('copy');
    copyBox.remove();
    this.toastrService.success(message, 'Notification');
  }

  private getPaymentLink(value: string): string {
    return `${this.url}/pay/${value}`;
  }

  private getEmbedCode(value: string): string {
    return `<iframe src="${this.url}/embed/pay/${value}" width="600" height="600" frameborder="0"></iframe>`;
  }

  public onBlur(): void {
    if (!this.currentFriendlyUrl.value || this.currentFriendlyUrl.value.length > 49) {
      return;
    }
    const filters: Filter[] = [
      {
        field: 'frendlyURL',
        value: encodeURI(this.currentFriendlyUrl.value),
        type: FilterType.Equal,
      },
      {
        field: 'frendlyURL',
        value: this.friendlyURL.value,
        type: FilterType.NotEqual,
      }
    ];
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: false,
      filters,
    };
    this.validationRequest = true;
    this.sourceTemplateService.getTotal(paging)
      .pipe(
        first(),
        tap((total: number) => {
          if (total > 0) {
            this.currentFriendlyUrl.setErrors({uniqueUrl: true});
          } else {
            this.currentFriendlyUrl.setErrors(null);
          }
          this.validationRequest = false;
          this.cdr.markForCheck()
        })
      )
      .subscribe();
  }

  public onFocus(): void {
    this.validationRequest = true;
  }
}
