<div class="publish-tab-wrapper">
  <div class="publish-tab-row">
    <app-fb-checkbox
      (clickEvent)="handleCheckboxState()"
      [value]="setAsAuxiliaDefault.value"
      [readOnly]="isTemplateInactive$ | async"
    >{{'BUILD.Set as Auxilia Default' | translate}}</app-fb-checkbox>
  </div>

  <div class="custom-url-result">
    <span class="custom-url-result-label">{{'BUILD.URL preview' | translate}}</span>
    <span>{{currentUrl$ | async}}</span>
  </div>
  <div class="publish-tab-row">
    <div class="max-width-500 right-margin">
      <app-input
        label="Custom URL part"
        [formGroup]="fbss.publishForm"
        name="currentFriendlyUrl"
        errorMassage="URL"
        (blurEvent)="onBlur()"
        (focusEvent)="onFocus()"
        matTooltip="URL will be encoded into valid UTF-8 encoding"
      ></app-input>
<!--
      <div
        class="url-info-icon"
        matTooltipHideDelay="200"
        matTooltipShowDelay="200"
        matTooltipPosition="above"
        matTooltipClass="tooltip-wrapper"
      >
        <i class="fas fa-info-circle"></i>
      </div> -->
    </div>

    <app-button
      label="Apply Changes"
      color="#0064be"
      (clickEvent)="applyUrl()"
      [disabled]="applyDisabled"
    ></app-button>
  </div>

  <div class="custom-url-result">
    <span class="custom-url-result-label">{{'BUILD.Payment Page URL' | translate}}</span>
    <div class="copy-url-container" *ngIf="paymentLink$ | async as link">
      <span>{{link}}</span>
      <div class="copy-button" (click)="copyContent(link, 'URL successfully copied')">
        <i class="far fa-copy"></i>
      </div>
    </div>
  </div>

  <div class="custom-url-result">
    <span class="custom-url-result-label">{{'BUILD.Embed Code' | translate}}</span>
    <div class="copy-url-container" *ngIf="embedCode$ | async as code">
      <span>{{code}}</span>
      <div class="copy-button" (click)="copyContent(code, 'Embed Code successfully copied')">
        <i class="far fa-copy"></i>
      </div>
    </div>
  </div>

</div>
