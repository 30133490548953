import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, forkJoin, merge, Observable, of, Subject, Subscription, zip} from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  filter,
  first,
  map,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';
import SegmentTagsInfoModel from '../../../../../models/segment/segment.tags.info.model';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import TagModel from 'src/app/models/tag/tag.model';
import { SingletonSegmentTagService } from 'src/app/services/tag/singleton-segment-tag.service';
import { ClientDonorTagModel } from '../../../../../models/tag/client-donor-tag.model';
import { Paging } from '../../../../../models/paging/paging.model';
import { FilterType } from '../../../../../models/enum/filter.type';
import { BasePagingComponent } from '../../../../../components/paginator/base.paging.component';
import { AdminConfirmationComponent } from '../../../../admin/admin-confirmation/admin-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService } from '../../../../../services/auth.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tag-manager',
  templateUrl: './tag-manager.component.html',
  styleUrls: ['./tag-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagManagerComponent extends BasePagingComponent implements OnInit, OnDestroy {
  public selectedTags: BehaviorSubject<TagModel[]> = new BehaviorSubject<TagModel[]>([]);
  public canWorkWithSegments$: Observable<boolean>;
  public segmentOptions$: Observable<FormElementDataModel[]> = of([]);
  public segments: SegmentTagsInfoModel[] = [];
  public donorId: string;
  public campaignIdToRedirectTo: string;
  public segmentForm: FormGroup = this.formBuilder.group({
    segment: '',
    search: ''
  });
  private batchId: string;
  public batchId$: Observable<string>;
  public currentSegment$: Observable<string>;

  public isAllSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public donors$: Observable<ClientDonorTagModel[]>;
  private pagingSubject$: Subject<void> = new Subject<void>();

  private subscription: Subscription = new Subscription();

  private toggleAllSubject$: Subject<boolean> = new Subject<boolean>();
  public inProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private updateSubject$: Subject<void> = new Subject<void>();

  private donorsStack: Set<ClientDonorTagModel> = new Set<ClientDonorTagModel>();
  private toggleDonorSubject$: Subject<Set<ClientDonorTagModel>> = new Subject<Set<ClientDonorTagModel>>();
  private singleSegmentSubject$: Subject<string> = new Subject<string>();

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public singletonClientDonorTagService: SingletonSegmentTagService,
    private dialog: MatDialog,
    private router: Router,
    public authService: AuthService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.campaignIdToRedirectTo = this.activatedRoute.snapshot.queryParams.campaignId;
    if (sessionStorage.getItem('selectedSegments')) {
      const segments = JSON.parse(sessionStorage.getItem('selectedSegments'));
      if (segments && segments.length) {
        this.segments = segments;
      if (this.segments.length === 1) {
        this.segment.setValue(this.segments[0].id)
      }
      this.segmentOptions$ = of(segments.map(({segmentName, id}: SegmentTagsInfoModel) => ({label: segmentName, value: id})));
        this.batchId$ = this.startSession(segments);
      }
    }
    !this.segments.length && this.toastr.error(this.translate.instant('CUSTOMIZATION.There is no selected segments. Please, choose at least one'), this.translate.instant('Error'));

    /* Check whether tags selected */
    this.canWorkWithSegments$ = this.selectedTags.asObservable()
      .pipe(
        map((tagList: TagModel[]) => !!tagList && !!tagList.length && !!this.segments.length),
        tap((bool: boolean) => {
          if(bool && this.segments.length === 1){
            setTimeout(() => {
              this.singleSegmentSubject$.next(this.segments[0].id)
            });
          }
        })
      );

    /* Actual segment ID*/
    this.currentSegment$ = merge(
      this.segment.valueChanges,
      this.singleSegmentSubject$.asObservable(),
    ).pipe(
      distinctUntilChanged(),
      tap((segmentId: string) => {
        this.onFilterChanged();
        this.search.reset();
        this.donors$ = this.getDonors(segmentId);
      }),
    );

    /* Check / Uncheck All*/
    this.subscription.add(
      this.toggleAllSubject$.asObservable()
        .pipe(
          exhaustMap((status: boolean) => this.updateAll(status)),
        )
        .subscribe()
    );

    /* Toggle donor */
    this.subscription.add(
      this.toggleDonorSubject$.asObservable()
        .pipe(
          debounceTime(1000),
          filter((set: Set<ClientDonorTagModel>) => !!set.size),
          concatMap(() => this.updateDonor())
        )
        .subscribe()
    );

    /* Update */
    this.subscription.add(
      this.updateSubject$.asObservable()
        .pipe(
          exhaustMap(() => this.isAtLeastOneDonorSelected()),
          switchMap((resp) => {
            if (!resp) {
              this.toastr.info('No donors selected');
              return of(null);
            } else {
              const config = {
                data: {
                  title: 'Are you sure you would like to update tags?',
                  secondButtonName: this.translate.instant('No')
                }
              };
             return this.dialog.open(AdminConfirmationComponent, config).afterClosed()
            }
          }),
          filter(value => !!value),
          switchMap((resp) => {
            this.inProgress$.next(true);
            return this.singletonClientDonorTagService.save(this.batchId);
          }),
          catchError(err => {
            if (err.status === 200) {
              return of(null)
            } else {
              return err;
            }
          }),
          tap(() => {
            this.toastr.success(this.translate.instant('CUSTOMIZATION.Tags has been updated successfully'), this.translate.instant('Info'));
            this.location.back();
          }),
        )
        .subscribe()
    );

    this.subscription.add(
      this.inProgress$.asObservable()
        .pipe(
          tap((inProgress: boolean) => {
            if (inProgress) {
              this.segment.disable();
              this.search.disable();
            } else {
              this.segment.enable();
              this.search.enable();
            }
          })
        )
        .subscribe()
    );
  }

  public backToSegmentManagement(): void {
    this.router.navigateByUrl(`/clients/customization?tab=segment${this.campaignIdToRedirectTo ? `&campaignId=${this.campaignIdToRedirectTo}` : ''}`);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private startSession(segments: SegmentTagsInfoModel[]): Observable<string> {
    return this.singletonClientDonorTagService.addSegments(segments)
      .pipe(
        first(),
        tap((batchId: string) => this.batchId = batchId)
      );
  }

  private get segment(): FormControl {
    return this.segmentForm.get('segment') as FormControl;
  }

  private get search(): FormControl {
    return this.segmentForm.get('search') as FormControl;
  }

  public toggleAll(): void {
    this.isAllSelected$.asObservable()
      .pipe(
        first(),
        tap((currentValue: boolean) => this.toggleAllSubject$.next(!currentValue))
      )
      .subscribe();
  }

  private getDonors(segmentId: string): Observable<ClientDonorTagModel[]> {
    const searchSource = this.search.valueChanges
      .pipe(
        startWith(''),
        distinctUntilChanged(),
        debounceTime((500)),
        tap(() => this.onFilterChanged()),
        map((searchValue: string) => ({searchValue, total: true})),
      );

    const pagingSource = this.pagingSubject$.asObservable()
      .pipe(
        map(() => ({searchValue: this.search.value, total: false})),
      );

    return merge(searchSource, pagingSource)
      .pipe(
        switchMap(({searchValue, total}) => {
          const paging: Paging = {
            includeDependencies: false,
            includeDeleted: false,
            filters: [{
              field: 'searchString',
              value: searchValue,
              type: FilterType.Contains
            }],
          };
          const donorsPaging: Paging = {
            ...paging,
            first: this.getFirstItemOnPage(),
            rows: this.entriesPerPage,
          };
          const donorsSource$ = this.singletonClientDonorTagService.getClientDonors(this.batchId, segmentId, donorsPaging);
          const totalSource$ = total
            ? this.singletonClientDonorTagService.getTotalClientDonors(this.batchId, segmentId, paging)
            : of(this.total);
          return zip(donorsSource$, totalSource$);
        }),
        map(([donors, totalDonors]) => {
          this.total = totalDonors;
          return donors;
        })
      );
  }

  public toggleDonor(donor: ClientDonorTagModel): void {
    if (this.inProgress$.getValue()) {
      return;
    } else {
      this.isAllSelected$.next(false);
      donor.isSelected = !donor.isSelected;
      this.donorsStack.has(donor) ? this.donorsStack.delete(donor) : this.donorsStack.add(donor);
      this.toggleDonorSubject$.next(this.donorsStack);
    }
  }

  public getNextPage(page: number): void {
    if (page === this.currentPage) {
      return;
    }
    this.nextPage(page, () => this.pagingSubject$.next());
  }

  public setEntriesPerPage(amount: number): void {
    this.entriesPerPageChanged(amount, () => this.pagingSubject$.next());
  }

  private updateDonor(): Observable<ClientDonorTagModel[]> {
    this.inProgress$.next(true);
    const sources$: Observable<ClientDonorTagModel>[] = [...this.donorsStack].map((donor: ClientDonorTagModel) => {
      return this.singletonClientDonorTagService.selectDonor(this.batchId, this.segment.value, donor.isSelected, donor.clientDonorID);
    });
    return forkJoin(sources$).pipe(
      tap(() => {
        this.donorsStack.clear();
        this.inProgress$.next(false);
      }),
    );
  }

  private updateAll(status: boolean): Observable<boolean> {
    this.inProgress$.next(true);
    return this.singletonClientDonorTagService.selectDonor(this.batchId, this.segment.value, status)
      .pipe(
        map(() => status),
        tap((isAllSelected: boolean) => {
          this.donorsStack.clear();
          this.subscription.add(
            this.donors$.subscribe(donor => {
              if (status) {
                donor.forEach(item => this.donorsStack.add(item));
              }
            })
          );
          this.isAllSelected$.next(isAllSelected);
          this.inProgress$.next(false);
          this.pagingSubject$.next();
        })
      );
  }

  private isAtLeastOneDonorSelected(): Observable<boolean> {
    return this.singletonClientDonorTagService.isAtLeastOneDonorSelected(this.batchId)
  }

  public update(): void {
    this.updateSubject$.next();
  }

}
