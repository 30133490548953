import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { filter } from 'rxjs/operators';
import { Patterns } from '../../shared/validatiors/patterns.model';

@Injectable({
  providedIn: 'root'
})
export class ClientIdStateService {
  public clientIdForm: FormGroup = this.formBuilder.group({
    clientID: this.selectedRelationshipClientId,
  });
  private clientName: string;
  private guidRegExp: RegExp = new RegExp(Patterns.guid);

  private clientIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.authService.getIdentityClaimsOriginId());
  public clientIdObservable: Observable<string> = this.clientIdSubject.asObservable()
    .pipe(
      filter(value => !!value && this.guidRegExp.test(value))
    );

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {
  }

  public setClientID(id: string): void {
    this.clientIdForm.get('clientID').setValue(id);
  }

  public setSelectedRelationshipClient(id: string, name: string): void {
    id ? localStorage.setItem('currentSelectedClientID', id) : localStorage.removeItem('currentSelectedClientID');
    name ? localStorage.setItem('currentSelectedClientName', name) : localStorage.removeItem('currentSelectedClientName');
    this.setClientID(id);
    this.clientName = name;
    this.clientIdSubject.next(id);
  }

  public get selectedRelationshipClientId(): string {
    return this.clientId ? this.clientId : this.authService.getIdentityClaimsOriginId();
  }

  public get selectedRelationshipClientName(): string {
    return this.clientName ? this.clientName : localStorage.getItem('clientName');
  }

  private get clientId(): string {
    if (!this.clientIdForm || !this.clientIdForm.get('clientID')) {
      return null;
    } else {
      return this.clientIdForm.get('clientID').value;
    }
  }
}
