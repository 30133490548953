import { Component, OnChanges, SimpleChanges } from '@angular/core';
//import { BaseTableComponent } from '../../../../../components/base-table/base-table.component';
//import { EventDonationModel } from '../../../../../models/event/event-donation.model';
//import EventModel from '../../../../../models/event/event.model';
import { formatDate } from '@angular/common';
//import { EventStatus } from '../../../../../models/enum/event.status';
import { BaseTableComponent } from '../base-table/base-table.component';
import { EventDonationModel } from '../../models/event/event-donation.model';
import { DonationStatusType } from '../../models/enum/donation.status.type';
import { DonationMethodType } from '../../models/enum/donation.method.type';

@Component({
  selector: 'app-history-donations-table',
  templateUrl: './history-donations-table.component.html',
  styleUrls: ['./history-donations-table.component.scss']
})
export class HistoryDonationsTableComponent extends BaseTableComponent<EventDonationModel> implements OnChanges {

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public getColumnTitle(column: string): string {
    const columnsTitles = {
      id: 'Donation Id',
      createdOn: 'Date',
      donationAmmount: 'Amount',
      donationMethod: 'Method',
      createdByUserName: 'Role',
      sourceValue: 'Source',
      donationStatus: 'Status',
      giftType: 'Gift Type'
    };
    return columnsTitles[column];
  }

  public getTextNode(element: EventDonationModel, column: string): string {
    switch (column) {
      case 'createdOn':
        return element[column] ? formatDate(element[column], 'MM/dd/yyyy', 'en-US') : '';
      case 'donationStatus':
        const donationStatusTypes = {
          [DonationStatusType.Completed]: 'Completed',
          [DonationStatusType.Canceled]: 'Canceled',
          [DonationStatusType.Refunded]: 'Refunded',
          [DonationStatusType.Declined]: 'Declined',
          [DonationStatusType.Pending]: 'Pending',
          [DonationStatusType.Deferred]: 'Deferred',
        };
        return donationStatusTypes[element[column]];
      case 'donationAmmount':
        return `$ ${element[column]}`;
      case 'donationMethod':
        const donationMethodTypes = {
          [DonationMethodType.Cash]: 'Cash',
          [DonationMethodType.CreditCard]: 'Credit Card',
          [DonationMethodType.Check]: 'Check',
          [DonationMethodType.MoneyOrder]: 'Money Order',
          [DonationMethodType.Bitcoin]: 'Bitcoin',
          [DonationMethodType.MobilePay]: 'Mobile Pay',
          [DonationMethodType.Venmo]: 'Venmo',
          [DonationMethodType.Zelle]: 'Zelle',
        };
        return donationMethodTypes[element[column]];
      default:
        return element[column];
    }
  }

  public getStatusCssClass(element: EventDonationModel): string {
    switch (element.donationStatus) {
      case DonationStatusType.Completed:
        return 'status-indicator green';
      case DonationStatusType.Canceled:
      case DonationStatusType.Refunded:
      case DonationStatusType.Declined:
        return 'status-indicator red';
      case DonationStatusType.Pending:
        return 'status-indicator black';
      case DonationStatusType.Deferred:
        return 'status-indicator yellow';
    }
  }

}
