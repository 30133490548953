import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconsType } from 'src/app/models/enum/icons.type';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
  @Input() public placeholder: string;
  @Output() public output: EventEmitter<string> = new EventEmitter<string>();
  public IconsType = IconsType;

  public onSearch(event: Event): void {
    this.output.next((event.target as HTMLInputElement).value);
  }
}
