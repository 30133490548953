<div class="image-builder-styling-wrapper">
  <mat-accordion multi>
    <mat-expansion-panel #generalOptionsExpansionPanelRef>
      <mat-expansion-panel-header>
        <div class="panel-title">{{'BUILD.General options' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-general-styling [ibss]="ibss"></app-general-styling>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel #layerOptionsExpansionPanelRef>
      <mat-expansion-panel-header>
        <div class="panel-title">{{'BUILD.Layer options' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div *ngIf="!ibss.activeLayer; else layerStylingTemplate">{{'BUILD.Select template element' | translate}}</div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>


<ng-template #layerStylingTemplate>
  <app-common-layer-styling [ibss]="ibss">
    <app-text-styling *ngIf="textActiveLayer" [ibss]="ibss"></app-text-styling>
    <app-image-styling *ngIf="imageActiveLayer" [ibss]="ibss"></app-image-styling>
    <app-shape-styling *ngIf="shapeLayer" [ibss]="ibss"></app-shape-styling>
  </app-common-layer-styling>
</ng-template>
