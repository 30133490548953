import { CampaignPlaceholderModel } from 'src/app/models/campaign-placeholder.model';
import { CauseModel } from '../../models/cause.model';
import { CharitableInterestModel } from 'src/app/models/charitable-interest.model';
import { ConfigService } from '../appconfig.service';
import { flatMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Paging } from 'src/app/models/paging/paging.model';
import { PagingHelper } from '../helper/paging.helper';
import { StateModel } from 'src/app/models/state.model';
import { TimeZoneModel } from '../../models/time-zone.model';
import CountryModel from '../../models/internationalization/country.model';
import HubspotPagingModel from 'src/app/models/hubspot/hubspot.paging.model';
import IndustryModel from '../../models/donor/industry.model';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  constructor(private configService: ConfigService, private http: HttpClient) { }

  public getCauses(): Observable<CauseModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<CauseModel[]>(`${config.apiURL}/lookup/clientcauses`);
      })
    );
  }

  public getStates(filter?: Paging): Observable<StateModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<StateModel[]>(`${config.apiURL}/lookup/States`, { params });
      })
    );
  }

  public getTimeZones(): Observable<TimeZoneModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<TimeZoneModel[]>(
          `${config.apiURL}/Lookup/TimeZones`
        );
      })
    );
  }

  public getCharitableInterests(): Observable<CharitableInterestModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<CharitableInterestModel[]>(
          `${config.apiURL}/Lookup/CharitableInterests`
        );
      })
    );
  }

  public getIndustryTypes(): Observable<IndustryModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<IndustryModel[]>(
          `${config.apiURL}/Lookup/industryTypes`
        );
      })
    );
  }

  public getBlogPosts(returnFromRow): Observable<HubspotPagingModel> {
    const offset = returnFromRow ? returnFromRow : 0;
    const params = new HttpParams().set('offset', offset.toString());
    const options = { params };
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<HubspotPagingModel>(
          `${config.apiURL}/Node`,
          options
        );
      })
    );
  }

  public getSkills(): Observable<IndustryModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<IndustryModel[]>(`${config.apiURL}/Lookup/Skills`);
      })
    );
  }

  public getCampaignPlaceholders(): Observable<CampaignPlaceholderModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<CampaignPlaceholderModel[]>(`${config.apiURL}/Lookup/campaignPlaceholders`);
      })
    );
  }

  public getVolunteerPreferences(): Observable<IndustryModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<IndustryModel[]>(
          `${config.apiURL}/Lookup/VolunteerPreferences`
        );
      })
    );
  }

  public getIsDaylightSavingTime(date: string, timeZoneName: string): Observable<boolean> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<boolean>(
          `${config.apiURL}/Lookup/isDayLight?date=${date}&timeZone=${timeZoneName}`
        );
      })
    );
  }

  public getZipCodesByZipRadius(zip: number, radius?: number): Observable<number[]> {
    let httpParams: HttpParams = new HttpParams();
    if (radius) {
      httpParams = httpParams.append('radius', radius.toString());
    }
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<number[]>(`${config.apiURL}/Lookup/zipCodes/${zip}`, { params: httpParams });
      })
    );
  }

  public getCountries(filter?: Paging): Observable<CountryModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<CountryModel[]>(`${config.apiURL}/Lookup/Countries`, { params });
      })
    );
  }

  public getIsDaylightSavingTimeById(date: string, id: number): Observable<boolean> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<boolean>(
          `${config.apiURL}/Lookup/isDayLightById?date=${date}&timeZoneId=${id}`
        );
      })
    );
  }
}
