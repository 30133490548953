<div class="fb-page-form-row">
    <div class="page-sub-name">{{'BUILD.Email for Notification' | translate}}</div>
</div>

<div class="fb-page-form-row">
    <div class="max-width-810">
        <app-email-list-textarea
                [formGroup]="fbss.templateSettingsForm"
                name="notifyEmails"
                errorMassage="Email"
                label="Notify Email Address"
                placeholder="Type in the email address of the person who will be notified every time a form is submitted"
                [disabled]="readOnly"
                (valueChanged)="valueChanged.emit()"
        ></app-email-list-textarea>
    </div>
</div>

<div class="fb-page-form-row">
    <div class="max-width-810">
        <app-input
                [formGroup]="fbss.templateSettingsForm"
                name="subjectEmailNotification"
                errorMassage="Subject"
                label="Notify Email Subject"
                placeholder="Type in the email subject"
                [disabled]="readOnly"
                (valueChanged)="valueChanged.emit()"
        ></app-input>
    </div>
</div>

<div class="fb-page-form-row">
    <div class="max-width-810">
        <app-text-editor
                [formGroup]="fbss.templateSettingsForm"
                name="emailNotification"
                errorMassage="Email"
                label="Email for Notification"
                placeholder="Create a notification email that gets sent to the define email address every time a form is submitted"
                [disabled]="readOnly"
        ></app-text-editor>
    </div>
</div>

<div class="fb-page-form-row top-margin">
    <app-button
            label="Reset Email"
            type="stroked"
            color="#0064be"
            (clickEvent)="resetEmailForNotification.emit()"
            [disabled]="readOnly"
    ></app-button>
</div>

<div class="fb-page-form-row" style="margin-top: 26px;">
    <div class="max-width-810">
        <app-radio
                [formGroup]="fbss.templateSettingsForm"
                name="isSendDonationEmailDisabled"
                errorMassage="Form Status"
                label="Send Donation Thank You Email?"
                [selectOptions]="formStatusOptions"
                [disabled]="readOnly"
        ></app-radio>
    </div>
</div>

<div class="fb-page-form-row">
    <div class="page-sub-name">{{'BUILD.Email to Donor' | translate}}</div>
</div>

<div class="fb-page-form-row">
    <div class="max-width-810">
        <app-input
                [formGroup]="fbss.templateSettingsForm"
                name="subjectEmailToDonor"
                errorMassage="Subject"
                label="Email to Donor Subject"
                placeholder="Type in the email subject"
                [disabled]="readOnly"
                (valueChanged)="valueChanged.emit()"
        ></app-input>
    </div>
</div>

<div class="fb-page-form-row">
    <div class="max-width-810">
        <app-text-editor
                [formGroup]="fbss.templateSettingsForm"
                name="emailToDonor"
                errorMassage="Email"
                label="Email to Donor"
                placeholder="Create an email that the donor received once the form has been submitted"
                [placeholders]="placeholders"
                [disabled]="readOnly"
        ></app-text-editor>
    </div>
</div>

<div class="fb-page-form-row top-margin">
    <app-button
            label="Reset Email"
            type="stroked"
            color="#0064be"
            (clickEvent)="resetEmailToDonor.emit()"
            [disabled]="readOnly"
    ></app-button>
</div>
