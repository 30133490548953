import { FormGroup } from '@angular/forms';
import CountryModel from 'src/app/models/internationalization/country.model';
import { StripeAchDonorDataModel } from 'src/app/models/payments/stripe-ach-donor-data.model';

export class StripeACHDonorDataUtil {
  public static getDonorData(secondPaymentForm: FormGroup, countries: CountryModel[]): StripeAchDonorDataModel {
    const countryShortName: string = countries.find(ct => ct.id == secondPaymentForm.get('country').value).shortName;
    return {
      accountHolderName: secondPaymentForm.get("firstName").value + secondPaymentForm.get("lastName").value,
      email: secondPaymentForm.get("email").value,
      phone: secondPaymentForm.get("phone").value,
      city: secondPaymentForm.get("city").value,
      country: countryShortName,
      state: secondPaymentForm.get("state").value,
      postalCode: secondPaymentForm.get("zipCode").value,
      street1: secondPaymentForm.get("streetAddress").value,
      street2: secondPaymentForm.get("streetAddress2").value
    }
  }

  public static isDonorDataValid(secondPaymentForm: FormGroup): boolean {
    return secondPaymentForm.get("firstName").valid &&
      secondPaymentForm.get("lastName").valid &&
      secondPaymentForm.get("email").valid &&
      secondPaymentForm.get("phone").valid &&
      secondPaymentForm.get("city").valid &&
      secondPaymentForm.get("country").valid &&
      secondPaymentForm.get("state").valid &&
      secondPaymentForm.get("streetAddress").valid;
  }
}