import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientEmployeeModel } from 'src/app/models/client/client.employee.model';
import { ClientEmployeeService } from 'src/app/services/client/client-employee.service';
import { ClientRegistrationModel } from 'src/app/models/client/client.registration.model';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { PasswordRequirementsModel } from 'src/app/models/user/password-requirements.model';
import { FormValidators } from 'src/app/shared/validatiors/form-validators';
import { IdentityUsersService } from '../../../services/identity/identity.users.service';
import { Subscription } from 'rxjs';
import { DonorEmployeeService } from 'src/app/services/donor.employee/donor-employee.service';
import DonorEmployeeModel from 'src/app/models/donor/donor.employee.model';
import { DonorStatus } from 'src/app/models/enum/donor.status';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmRegistrationComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public registrationConfirmed: boolean = false;
  public confirmForm = new FormGroup(
    {
      confirm: new FormControl(Validators.required),
      confirmPassword: new FormControl(''),
      email: new FormControl({ value: '', disabled: true }, [
        Validators.required
      ]),
      firstName: new FormControl({ value: '', disabled: true }, [
        Validators.required
      ]),
      lastName: new FormControl({ value: '', disabled: true }, [
        Validators.required
      ]),
      password: new FormControl('')
    },
    [FormValidators.passwordsEqual]
  );
  public isSuccessfulRegister = false;
  public requirements: PasswordRequirementsModel;
  private employeeModel: ClientEmployeeModel;
  private donorEmployeeModel: DonorEmployeeModel;
  private donorUrl: 'donor' = 'donor';
  private isDonorConfirmation: boolean;
  public isDonorNoNeedPassword: boolean;
  public returnUrl: string;

  constructor(
    public route: ActivatedRoute,
    private readonly clientEmployeeModelService: ClientEmployeeService,
    private router: Router,
    private toastrService: ToastrService,
    private userService: UserService,
    private identityUsersService: IdentityUsersService,
    private donorEmployeeService: DonorEmployeeService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.isDonorConfirmation = this.router.url.includes(this.donorUrl);
    this.isDonorNoNeedPassword = this.route.snapshot.params.notneedpass;
    const windowLocation = window.location.search;
    const urlParams = new URLSearchParams(windowLocation);
    this.returnUrl = urlParams.get('returnUrl');

    this.subscription.add(
      this.route.paramMap.subscribe(paramMap => {
        if (!this.isDonorConfirmation) {
          this.getClientEmployeeInfo(paramMap.get('id'));
        } else {
          this.getDonorEmployeeInfo();
        }
      })
    );
    this.subscription.add(
      this.userService.getPasswordRequirements().subscribe(response => {
        this.requirements = response;
        const validators: ValidatorFn[] = [Validators.required];
        if (response.requireDigit) {
          validators.push(FormValidators.oneNumber);
        }
        if (response.requireLowercase) {
          validators.push(FormValidators.oneSmallLetter);
        }
        if (response.requireUppercase) {
          validators.push(FormValidators.oneCapitalLetter);
        }
        if (response.requireNonAlphanumeric) {
          validators.push(FormValidators.specialSymbol);
        }
        if (response.requiredLength) {
          validators.push(Validators.minLength(response.requiredLength));
        }
        this.password.setValidators(validators);
        this.password.updateValueAndValidity();
        this.confirmPassword.setValidators(validators);
        this.confirmPassword.updateValueAndValidity();
        this.confirmForm.updateValueAndValidity();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get password(): FormControl {
    return this.confirmForm.get('password') as FormControl;
  }

  public get confirmPassword(): FormControl {
    return this.confirmForm.get('confirmPassword') as FormControl;
  }

  public get showRequirements(): boolean {
    return (
      (this.password.value && this.password.value.length > 0) ||
      this.password.touched
    );
  }

  public get firstName(): FormControl {
    return this.confirmForm.get('firstName') as FormControl;
  }

  public get lastName(): FormControl {
    return this.confirmForm.get('lastName') as FormControl;
  }

  public get email(): FormControl {
    return this.confirmForm.get('email') as FormControl;
  }

  private getClientEmployeeInfo(employeeId: string) {
    this.subscription.add(
      this.clientEmployeeModelService.getModel(employeeId).subscribe(
        employee => {
          if (employee && !employee.userID) {
            this.employeeModel = employee;
            this.firstName.setValue(this.employeeModel.firstName);
            this.firstName.disable();
            this.lastName.setValue(this.employeeModel.lastName);
            this.lastName.disable();
            this.email.setValue(this.employeeModel.email);
            this.email.disable();
            this.confirmPassword.markAsUntouched();
            this.password.markAsUntouched();
            this.password.setValue(null);
            this.checkEmail();
          } else {
            this.registrationConfirmed = true;
          }
        },
        err => {}
      )
    );
  }

  private getDonorEmployeeInfo() {
    const { donorEmployee } = this.route.snapshot.data;

    this.donorEmployeeModel = donorEmployee;
    this.firstName.setValue(this.donorEmployeeModel.firstName);
    this.firstName.disable();
    this.lastName.setValue(this.donorEmployeeModel.lastName);
    this.lastName.disable();
    this.email.setValue(this.donorEmployeeModel.email);
    this.email.disable();
    this.confirmPassword.markAsUntouched();
    this.password.markAsUntouched();
    this.password.setValue(null);

    this.checkEmail();

    if (this.isDonorNoNeedPassword) {
      this.subscription.add(
        this.donorEmployeeService
          .completeRegistration({
            Model: this.donorEmployeeModel,
            Password: null
          })
          .subscribe(result => {
            if (result) {
              this.toastrService.success(
                this.translate.instant('AUTH.Registration successfully complete'),
                this.translate.instant('Notification')
              );
              // this.router.navigate(['/index']);
              this.router.navigate(['/index'], {
                queryParams: { returnUrl: this.returnUrl }
              });
            }
          })
      );
    }
  }

  public completeRegistration(): void {
    if (!this.isDonorConfirmation) {
      const clientRegistartionModel = new ClientRegistrationModel();
      clientRegistartionModel.Model = this.employeeModel;
      clientRegistartionModel.Password = this.password.value;
      if (this.confirmForm.valid) {
        this.toastrService.success(
          this.translate.instant('AUTH.Registration successfully complete'),
          this.translate.instant('Notification')
        );
        this.subscription.add(
          this.clientEmployeeModelService
            .completeRegistration(clientRegistartionModel)
            .subscribe(result => {
              // this.router.navigate(['/index']);
              this.router.navigate(['/index'], {
                queryParams: { returnUrl: this.returnUrl }
              });
            })
        );
      } else {
        this.confirmPassword.markAsUntouched();
      }
    } else {
      if (this.confirmForm.valid) {
        this.toastrService.success(
          this.translate.instant('AUTH.Registration successfully complete'),
          this.translate.instant('Notification')
        );
        this.subscription.add(
          this.donorEmployeeService
            .completeRegistration({
              Model: {
                ...this.donorEmployeeModel,
                donorStatus: DonorStatus.Activated
              },
              Password: this.password.value
            })
            .subscribe(result => {
              // this.router.navigate(['/index']);
              this.router.navigate(['/index'], {
                queryParams: { returnUrl: this.returnUrl }
              });
            })
        );
      } else {
        this.confirmPassword.markAsUntouched();
      }
    }
  }

  private checkEmail(): void {
    this.subscription.add(
      this.identityUsersService
        .checkEmailExist(
          !this.isDonorConfirmation
            ? this.employeeModel.email
            : this.donorEmployeeModel.email
        )
        .subscribe((response: boolean) => {
          this.registrationConfirmed = response;
        })
    );
  }
}
