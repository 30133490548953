import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-base-table',
  template: '',
})
export class BaseTableComponent<T> implements OnInit, OnChanges {
  @Input()
  public data: T[] = [];
  @Input()
  public tableDataColumns: string[] = [];

  public tableColumns: string[] = [];
  public tableSource: MatTableDataSource<T>;

  @Output()
  public rowClicked: EventEmitter<T> = new EventEmitter<T>();

  @Output()
  public editClicked: EventEmitter<T> = new EventEmitter<T>();

  constructor() { }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    /** Change table columns */
    if (changes.tableDataColumns) {
      this.tableColumns = [...this.tableDataColumns, 'settings'];
    }
    if (changes.data && this.data) {
      this.tableSource = new MatTableDataSource<T>(this.data);
    }
  }

  public selectRow(row: T): void {
    this.rowClicked.emit(row);
  }

}
