import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ImageBuilderStateService } from '../../../image-builder.state.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LayerModel } from '../../../../../models/image-builder/layer.model';
import { TemplateType } from '../../../../../models/templates/template.type';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import { DonationAskOptions } from '../../../../../constants';

@Component({
  selector: 'app-shape-styling',
  templateUrl: './shape-styling.component.html',
  styleUrls: ['../styling.component.scss']
})
export class ShapeStylingComponent implements OnInit, OnDestroy {
  @Input() public ibss: ImageBuilderStateService;

  private subscription: Subscription = new Subscription();
  public keepRatioOptions: FormElementDataModel[] = DonationAskOptions;

  public shapeForm: FormGroup = this.formBuilder.group({
    fill: '',
    stroke: '#66788a',
    strokeWidth: '8',
    keepRatio: true,
  });

  constructor(private formBuilder: FormBuilder) { }

  public ngOnInit(): void {
    this.subscription.add(
      this.ibss.activeLayerObservable.subscribe((layer: LayerModel) => this.setInitialValues())
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get readOnly(): boolean {
    return this.ibss.readOnly;
  }

  public get themedTemplate(): boolean {
    return this.ibss.themedTemplate;
  }

  public get componentBasedTemplate(): boolean {
    return this.ibss.componentBasedTemplate;
  }

  public setInitialValues(): void {
    const {
      fill = '',
      stroke = '#66788a',
      strokeWidth = '8',
    } = this.ibss.activeLayerAttributes;
    const keepRatio = this.ibss.activeLayer ? this.ibss.activeLayer.keepRatio : false;
    this.shapeForm.get('keepRatio').setValue(keepRatio);
    this.shapeForm.get('fill').setValue(fill);
    this.shapeForm.get('stroke').setValue(stroke);
    this.shapeForm.get('strokeWidth').setValue(strokeWidth);
  }

  public onFillChanged(): void {
    const value = this.shapeForm.get('fill').value || '';
    this.ibss.setActiveLayerAttributes('fill', value);
    this.ibss.shapeChanged();
  }

  public onStrokeChanged(): void {
    const value = this.shapeForm.get('stroke').value || '#66788a';
    this.ibss.setActiveLayerAttributes('stroke', value);
    this.ibss.shapeChanged();
  }

  public onStrokeWidthChanged(): void {
    const value = this.shapeForm.get('strokeWidth').value || '0';
    this.ibss.setActiveLayerAttributes('strokeWidth', value);
    this.ibss.shapeChanged();
  }

  public onKeepRatioChanged(value: boolean): void {
    this.ibss.activeLayer.keepRatio = value;
    this.ibss.saveTemplate();
  }

}
