import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { P2PStaticPageModel } from 'src/app/models/p2p/p2p-static-page.model';

@Component({
  selector: 'app-p2p-static-page-preview-dialog',
  templateUrl: './p2p-static-page-preview-dialog.component.html',
  styleUrls: ['./p2p-static-page-preview-dialog.component.scss']
})
export class P2pStaticPagePreviewDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: P2PStaticPageModel) { }
}
