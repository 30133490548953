<div
  #target
  class="layer-target-container"
  [class.draggable]="draggable"
  [ngStyle]="layerTargetContainerStyle"
  (dblclick)="onLayerDblClick()"
  (click)="onLayerClick()"
>
  <!-- Text tool-->
  <app-text-renderer
    *ngIf="isTextLayer(layer?.type)"
    [layer]="layer"
    [ibss]="ibss"
    [static]="static"
    [layerIndex]="currentLayerIndex"
  ></app-text-renderer>

  <!-- Image tool-->
  <app-image-renderer
    *ngIf="isImageLayer(layer?.type)"
    [layer]="layer"
    [ibss]="ibss"
  ></app-image-renderer>

  <!-- Shape tool-->
  <app-shape-renderer
    *ngIf="isShapeLayer(layer?.type)"
    [layer]="layer"
    [ibss]="ibss"
  ></app-shape-renderer>
</div>
<ngx-moveable
  [target]="target"
  [origin]="false"
  [className]="(resizable || scalable) ? 'active-layer-target-moveable' : 'layer-target-moveable'"

  [draggable]="draggable"
  [throttleDrag]="0"
  (dragStart)="onDragStart($event)"
  (drag)="onDrag($event)"
  (dragEnd)="onDragEnd($event)"

  [resizable]="resizable"
  [throttleResize]="0"
  [keepRatio]="keepRatio"
  (resizeStart)="onResizeStart($event)"
  (resize)="onResize($event)"
  (resizeEnd)="onResizeEnd($event)"

  [rotatable]="rotatable"
  [throttleRotate]="0"
  rotationPosition="bottom"
  (rotateStart)="onRotateStart($event)"
  (rotate)="onRotate($event)"
  (rotateEnd)="onRotateEnd($event)"

  [scalable]="scalable"
  [throttleScale]="0"
  (scaleStart)="onScaleStart($event)"
  (scale)="onScale($event)"
  (scaleEnd)="onScaleEnd($event)"

  [pinchable]="true"
  [pinchThreshold]="20"
></ngx-moveable>
