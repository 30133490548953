<div class="fb-options-row">
  <div class="fb-row-element-full">
    <app-select
      label="Types of donations *"
      [formGroup]="optionsForm"
      name="typesOfDonations"
      (valueChanged)="onDonationTypesChanged($event.value)"
      [low]="true"
      [selectOptions]="typesOfDonationsOptions"
    ></app-select>
  </div>
</div>

<!-- Tiers -->
<ng-container *ngIf="isTiers">
  <div class="fb-options-row">
    <div class="fb-row-element-full">
      <ng-container *ngFor="let tier of getTiersList.controls; index as i">
        <mat-expansion-panel #expansionPanelRef [ngClass]="{panelBorderTopBot: i === 0, panelBorderBot: i > 0}" class="mat-elevation-z0 tiersPanel" >
          <mat-expansion-panel-header>
            <div class="custom-tools-panel-title">{{'BUILD.Tier' | translate}} {{i + 1}}</div>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="content-sections-tiers">

              <div class="fb-row-element-full">
                <app-input
                  #tierNameRef
                  [formGroup]="tier"
                  name="name"
                  label="Name *"
                  [autocompleteOff]="true"
                  [maxLength]="75"
                  errorMassage="field"
                  (valueChanged)="changeTiersTemplate()"
                  [disabled]="readOnly"
                ></app-input>
              </div>

              <div class="fb-row-element-full">
                <app-textarea
                  #tierDescriptionRef
                  [formGroup]="tier"
                  name="description"
                  label="Description *"
                  errorMassage="field"
                  [maxlengthText]="150"
                  [minRows]="4"
                  (valueChanged)="changeTiersTemplate()"
                  [maxRows]="5"
                ></app-textarea>
              </div>

              <div class="fb-row-element-full">
                <app-input
                  #tierPriceRef
                  [formGroup]="tier"
                  name="ammount"
                  errorMassage="field"
                  label="Price *"
                  mask="separator.2"
                  thousandSeparator=","
                  prefix="$ "
                  (valueChanged)="changeTiersTemplate()"
                  maxLength="13"
                ></app-input>
              </div>

              <div class="fb-row-element-full" *ngIf="!tier.get('imgUrl').value">
                <div class="img-loader-btn">
                  <app-image-loader
                    #fileReaderInput
                    [formGroup]="tier"
                    name="imgUrl"
                    label="Upload Image"
                    [readOnly]="readOnly"
                    [imageURL]="tier.get('imgUrl').value"
                    (valueChanged)="changeTiersTemplate()"
                  ></app-image-loader>
                </div>
              </div>

              <div class="fb-row-element-full" *ngIf="tier.get('imgUrl').value">
                <p class="upload-title">{{'Upload Image' | translate}}</p>
                <div class="img-wrapper">
                  <div class="img" [ngStyle]="{background: getBgImg(i)}"></div>
                  <p class="img_url">{{getImgName(tier.get('imgUrl').value)}}</p>
                  <app-button
                    class="delete-img-btn"
                    type="basic"
                    (click)="removeImg(i)"
                    color="#F44336"
                    width="50"
                  >
                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <g data-name="Layer 2">
                        <g data-name="trash-2">
                          <rect width="24" height="24" opacity="0"/>
                          <path d="M21 6h-5V4.33A2.42 2.42 0 0 0 13.5 2h-3A2.42 2.42 0 0 0 8 4.33V6H3a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8h1a1 1 0 0 0 0-2zM10 4.33c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V8h12z"/><path d="M9 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z"/>
                          <path d="M15 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z"/>
                        </g>
                      </g>
                    </svg>
                  </app-button>
                </div>
              </div>


              <div
                *ngIf="!(fbss.adminPortalRestriction | async)"
                class="fb-row-element-full"
              >
                <app-select
                  label="Select tag"
                  [formGroup]="tier"
                  name="selectedTag"
                  (valueChanged)="changeTiersTemplate()"
                  [selectOptions]="tagSelectOptions"
                ></app-select>
              </div>

              <div
                *ngIf="!tier.get('isCreateTagShow').value && !(fbss.adminPortalRestriction | async)"
                class="fb-row-element-full margin-bottom-10"
              >
                <app-button
                  class="create-new"
                  type="flat"
                  (clickEvent)="showCreateNewTag(i)"
                  color="transparent"
                  width="176"
                >
                  <div class="new-tag-wrapper">
                    <svg width="24" height="24" fill="#0064be" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <g data-name="Layer 2">
                        <g data-name="plus">
                          <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/>
                          <path d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z"/>
                        </g>
                      </g>
                    </svg>
                    <span style="color: #0064be">
                    {{'BUILD.Create new tag' | translate}}
                  </span>
                  </div>
                </app-button>
              </div>

              <ng-container *ngIf="tier.get('isCreateTagShow').value">
                <app-add-new-tag
                  [availableLength]="tagArrayLength"
                  [clientId]="clientID"
                  [isFormSettingsPage]="true"
                  (newTag)="addNewTagToList($event, i)"
                  (cancelEmitter)="cancelTagCreation(tier)"
                ></app-add-new-tag>
              </ng-container>


              <div class="fb-options-row" *ngIf="isMoreThenOne">
                <app-button
                  class="padding0"
                  type="basic"
                  label="Delete tier"
                  (clickEvent)="deleteTiers(i)"
                  color="#F44336"
                  width="258"
                >
                </app-button>
              </div>

            </div>
          </ng-template>
        </mat-expansion-panel>
      </ng-container>
    </div>
  </div>
</ng-container>

<div class="fb-options-row" *ngIf="getTiersList.length < 15 && isTiers">
  <div class="fb-row-element-full">
    <app-button
      class="add-tier-btn"
      type="flat"
      (clickEvent)="addTier()"
      color="#0064be"
      width="310"
    >
      <svg width="24" height="24" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g data-name="Layer 2">
          <g data-name="plus">
            <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/>
            <path d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z"/>
          </g>
        </g>
      </svg>
      <span>
            {{'BUILD.Add Tier' | translate}}
        </span>
    </app-button>
  </div>
</div>

<ng-container *ngIf="!isTiers">
  <div class="fb-options-row">
    <div class="fb-options-row-subtitle">{{'BUILD.Set Donation Amounts' | translate}}:</div>
  </div>

  <div class="fb-options-row">
    <div class="donate-options">
      <div
        *ngFor="let control of donationAmountsForm.controls; index as i"
        class="donate-options-item"
      >
        <div class="donate-options-item-input" style="margin-bottom: 10px">
          <app-input
            [formGroup]="control"
            name="value"
            [low]="true"
            [disabled]="readOnly"
            mask="separator.2"
            thousandSeparator=","
            prefix="$ "
            type="tel"
            errorMassage="Amount"
            (valueChanged)="updateDonationAmounts()"
          ></app-input>
        </div>

        <div class="donate-options-action-buttons">

          <i
            *ngIf="!readOnly && donationAmountsForm.length > 1"
            class="fas fa-minus-circle"
            (click)="removeControl(i)"
          ></i>

          <i
            *ngIf="!readOnly && donationAmountsForm.valid && i === donationAmountsForm.length - 1 && donationAmountsForm.length < 6"
            class="fas fa-plus-circle"
            (click)="addControl()"
          ></i>
        </div>
      </div>
    </div>
  </div>

  <div class="fb-options-row">
    <app-fb-checkbox
      (clickEvent)="handleCheckboxState('allowOwnAmount')"
      [value]="allowOwnAmount.value"
      [readOnly]="readOnly"
    >{{'BUILD.Allow Donor to Enter Their Own Amount' | translate}}</app-fb-checkbox>
  </div>

  <div class="fb-options-row">
    <div class="fb-row-element-full">
      <app-select
        [formGroup]="optionsForm"
        name="funds"
        label="Set Fund List"
        [multiSelectOptions]="fundsOptions"
        (valueChanged)="setFunds($event.value)"
      ></app-select>
    </div>
  </div>
</ng-container>

<div class="fb-options-row">
  <div class="fb-options-row-subtitle">{{'BUILD.Choose What Fields You Want' | translate}}:</div>
</div>


  <ng-container *ngIf="fbss.client$ | async as client">
    <div class="fb-options-row" *ngIf="client && client.clientType === 0">
      <app-fb-checkbox
        (clickEvent)="handleCheckboxState('isAnonymous')"
        [value]="isAnonymous.value"
        [readOnly]="readOnly"
      >{{'BUILD.Make Donation Anonymous' | translate}}</app-fb-checkbox>
    </div>
  </ng-container>

<ng-container *ngIf="!isTiers">
  <div class="fb-options-row">
    <div class="fb-row-element-full">
      <app-fb-checkbox
        (clickEvent)="handleCheckboxState('isRecurring')"
        [value]="isRecurring.value"
        [readOnly]="readOnly"
      >{{'BUILD.Make Your Donation Recurring' | translate}}</app-fb-checkbox>

        <div *ngIf="isRecurring.value" class="fb-options-row">
          <div class="fb-row-element-full">
            <app-fb-checkbox
              (clickEvent)="handleCheckboxState('allowWeekly')"
              [value]="allowWeekly.value"
              [readOnly]="readOnly || !allowMonthly.value && !allowYearly.value"
            >{{'BUILD.weekly' | translate}}</app-fb-checkbox>

            <app-fb-checkbox
              (clickEvent)="handleCheckboxState('allowMonthly')"
              [value]="allowMonthly.value"
              [readOnly]="readOnly || !allowWeekly.value && !allowYearly.value"
            >{{'BUILD.monthly' | translate}}</app-fb-checkbox>

            <app-fb-checkbox
              (clickEvent)="handleCheckboxState('allowYearly')"
              [value]="allowYearly.value"
              [readOnly]="readOnly || !allowWeekly.value && !allowMonthly.value"
            >{{'BUILD.yearly' | translate}}</app-fb-checkbox>
          </div>
        </div>
    </div>

<!--    <div class="fb-row-element-full">-->
<!--      <app-fb-checkbox-->
<!--        (clickEvent)="handleCheckboxState('isRecurringWithEndDate')"-->
<!--        [value]="isRecurringWithEndDate.value"-->
<!--        [readOnly]="readOnly || !isRecurring.value"-->
<!--      >{{'BUILD.End Date to Recurring Donation' | translate}}</app-fb-checkbox>-->
<!--    </div>-->
<!--    Pledge Type disabled for 15258-->
<!--    <div class="fb-row-element-full">-->
<!--      <app-fb-checkbox-->
<!--        (clickEvent)="handleCheckboxState('isPledgeType')"-->
<!--        [value]="isPledgeType.value"-->
<!--        [readOnly]="readOnly || !isRecurring.value"-->
<!--      >{{'BUILD.Show Pledge Type' | translate}}</app-fb-checkbox>-->
<!--    </div>-->
  </div>
</ng-container>


<div class="fb-options-row">
  <div class="fb-row-element-full">
    <div *ngIf="isTiers" class="fb-row-element-full">
      <app-fb-checkbox
        (clickEvent)="handleTierCheckboxState('isRecurring')"
        [value]="isRecurringTiers"
        [readOnly]="readOnly"
      >{{'BUILD.Make Your Donation Recurring' | translate}}</app-fb-checkbox>

        <div *ngIf="isRecurringTiers" class="fb-options-row">
          <div class="fb-row-element-full">
            <app-fb-checkbox
              (clickEvent)="handleTierCheckboxState('allowWeekly')"
              [value]="allowWeeklyTier"
              [readOnly]="readOnly || !allowMonthlyTier && !allowYearlyTier"
            >{{'BUILD.weekly' | translate}}</app-fb-checkbox>

            <app-fb-checkbox
              (clickEvent)="handleTierCheckboxState('allowMonthly')"
              [value]="allowMonthlyTier"
              [readOnly]="readOnly || !allowWeeklyTier && !allowYearlyTier"
            >{{'BUILD.monthly' | translate}}</app-fb-checkbox>

            <app-fb-checkbox
              (clickEvent)="handleTierCheckboxState('allowYearly')"
              [value]="allowYearlyTier"
              [readOnly]="readOnly || !allowWeeklyTier && !allowMonthlyTier"
            >{{'BUILD.yearly' | translate}}</app-fb-checkbox>
          </div>
        </div>
      <app-fb-checkbox
        (clickEvent)="handleTierCheckboxState('allowOwnAmount')"
        [readOnly]="readOnly"
        [value]="isAllowOwnAmountTiers"
        [noWrap]="true"
      >{{'BUILD.Allow Donor to Enter Their Own Amount' | translate}}</app-fb-checkbox>
<!--      Pledge Type disabled for 15258 -->
<!--      <app-fb-checkbox-->
<!--        (clickEvent)="handleTierCheckboxState('isPledgeType')"-->
<!--        [value]="isPledgeTypeTiers"-->
<!--        [readOnly]="readOnly || !isRecurringTiers"-->
<!--      >{{'BUILD.Show Pledge Type' | translate}}</app-fb-checkbox>-->
<!--    </div>-->
    </div>
    <app-fb-checkbox
      (clickEvent)="handleCheckboxState('isHonorGift')"
      [value]="isHonorGift.value"
      [readOnly]="readOnly"
      [noWrap]="true"
    >{{'BUILD.This is an Honor or Memorial Gift or in Support Of' | translate}}</app-fb-checkbox>
  </div>

<div class="fb-options-row">
  <div class="fb-row-element-full">
  <app-fb-checkbox
    (clickEvent)="handleCheckboxState('isDonationCost')"
    [value]="isDonationCost.value"
    [readOnly]="readOnly"
  >{{'BUILD.Donation Cost' | translate}}</app-fb-checkbox>
  <app-fb-checkbox
    (clickEvent)="handleCheckboxState('isHonorGiftWithSendEmail')"
    [value]="isHonorGiftWithSendEmail.value"
    [readOnly]="readOnly || !isHonorGift.value"
  >{{'BUILD.Send Email' | translate}}</app-fb-checkbox>
    <app-fb-checkbox
            *ngIf="!isTiers"
            (clickEvent)="handleCheckboxState('hasCustomDisclaimers')"
            [value]="optionsForm.get('hasCustomDisclaimers').value"
            [readOnly]="readOnly || !isHonorGift.value"
    >{{'BUILD.Custom Disclaimers' | translate}}</app-fb-checkbox>
  </div>
  <div *ngIf="optionsForm.get('hasCustomDisclaimers').value" class="position-relative mb-4" style="flex-grow: 1;">
    <ng-container *ngTemplateOutlet="disclaimers"></ng-container>
  </div>
  <div *ngIf="optionsForm.get('hasCustomDisclaimers').value" class="ml-0 flex-grow-1">
    <div class="fb-row-element-full position-relative">
      <app-textarea
              [minRows]="2"
              [maxRows]="5"
              label="Set disclaimer confirmation text:"
              (keyup)="handleDisclaimerChange(true)"
              [formGroup]="optionsForm"
              name="disclaimerConfirmationText"
              errorMassage="disclaimer content"
      ></app-textarea>
    </div>
  </div>
</div>
<ng-template #disclaimers>
  <ng-container *ngFor="let disclaimer of customDisclaimers.controls; index as i">
    <div class="fb-row-element-full position-relative">
      <app-textarea
              [minRows]="4"
              [maxRows]="5"
              placeholder="Please click here to add your own custom disclaimer text."
              (keyup)="handleDisclaimerChange(false)"
              [formGroup]="disclaimer"
              name="content"
              errorMassage="disclaimer content"
      ></app-textarea>
      <div
              *ngIf="customDisclaimers.controls.length > 1 && i < customDisclaimers.controls.length - 1"
              [ngStyle]="getIconStyle('minusT')"
              (click)="deleteDisclaimer(i)">
        <i style="color: #0064BE" class="fas fa-minus-circle"></i>
      </div>
    </div>
  </ng-container>
  <div id="addDisclaimer" [ngStyle]="getIconStyle('plus')" (click)="addDisclaimer()">
    <i style="color: #0064BE" class="fas fa-plus-circle"></i>
  </div>
  <div
          *ngIf="customDisclaimers.controls.length > 1"
          [ngStyle]="getIconStyle('minusB')"
          (click)="deleteDisclaimer(customDisclaimers.controls.length - 1)">
    <i style="color: #0064BE" class="fas fa-minus-circle"></i>
  </div>

</ng-template>
