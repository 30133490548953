import { BaseModelService } from '../base.model.service';
import { flatMap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { YouTubeUploadModel } from 'src/app/models/socialMedia/youtube-upload.model';
import { YouTubeUploadRequestModel } from 'src/app/models/socialMedia/youtube-upload-request.model';
import SocialPostModel from '../../models/socialMedia/social.post.model';
import { Paging } from 'src/app/models/paging/paging.model';
import { HttpParams } from '@angular/common/http';
import { PagingHelper } from '../helper/paging.helper';

@Injectable({
  providedIn: 'root'
})
export class SocialPostService extends BaseModelService<SocialPostModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'SocialPost';
  }

  public getPublicSocialPost(id: string): Observable<SocialPostModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<SocialPostModel>(`${config.apiURL}/${this.controllerName}/payment/${id}`);
      })
    );
  }

  public getYouTubeUploadUri(model: YouTubeUploadRequestModel, id: string): Observable<string> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.post<string>(`${config.apiURL}/${this.controllerName}/youtubeUri?id=${id}`, model);
      })
    );
  }

  public youTubeUpload(model: YouTubeUploadModel): Observable<string> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.put<string>(`${config.apiURL}/${this.controllerName}/YouTubeUpload`, model);
      })
    );
  }
  
  public getModelListByFriendlyURL(filter?: Paging): Observable<SocialPostModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<SocialPostModel[]>(
          `${config.apiURL}/${this.controllerName}/FriendlyURL`,
          { params }
        );
      })
    );
  }
}