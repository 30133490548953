import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TableRowActionModel } from '../../models/table.row.action.model';
import ColumnFilterOptionsModel from '../../models/filter-sort/column.filter.options.model';
import { DistinctDataModel } from '../../models/distinct/distinct.data.model';
import ColumnFilterModel from '../../models/filter-sort/column.filter.model';
import { UtilsComponent } from '../utils.component';
import LazyLoadSearchModel, { LazyLoadRequestModel } from '../../models/filter-sort/lazy.load.search.model';
import { IconsType } from 'src/app/models/enum/icons.type';
import FilterSortModel from '../../models/filter-sort/filter.sort.model';
import { SortOrder } from '../../models/enum/sort.order';
import {BaseTableComponent} from "../base-table/base-table.component";
import {CampaignModel} from "../../models/campaigns/campaign.model";
import {QuickbookDonorReportModel, QuickBookReportShortModel} from "../../models/reports/quickbook.report.model";
import {DonationMethodType} from "../../models/enum/donation.method.type";

export const SMART_TABLE_COLUMN_TITLES = {
  multiselect: '',
  rowActions: ''
}

@Component({
  selector: 'app-smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: ['./smart-table.component.scss']
})
export class SmartTableComponent implements OnInit {

  @Input() public data: any[]; /*Table data*/
  @Input() public multiselect: boolean; /*Whether table has checkboxes or not*/
  @Input() public columns: string[] = []; /*Table columns*/
  @Input() public columnsWithFilter: string[] = []; /*Table columns that have simple multiselect filter*/
  @Input() public columnsWithLazyFilter: string[] = []; /*Table columns that have lazy-loading filter*/
  @Input() public columnsWithSort: string[] = []; /*Table columns with that have sorting*/
  @Input() public rowClickColumn: string; /*Field that will emit click*/
  @Input() public rowClick: Subject<any> = null; /*Row click emitter*/
  @Input() public rowActions: TableRowActionModel[] = []; /*List of actions (3-dots menu)*/
  @Input() public columnsFilterOptions: ColumnFilterOptionsModel[] = [];  /*Filter options for simple multiselect filter*/
  @Input() public distinctData$: BehaviorSubject<DistinctDataModel> = null; /*Options for lazy-loading filter*/
  @Input() public multiselectSet: Set<string> = null; /*Exclude or include set*/
  @Input() public isAllSelected$: BehaviorSubject<boolean> = null; /*State for Check All, this value is used for sending to BE*/
  @Input() public filterSortConfig: FilterSortModel; /*Filter configuration*/
  @Input() public total: number; /*Total of items*/
  @Input() public columnLabels: {[key: string]: string} = {}; /*Combine it with SMART_TABLE_COLUMN_TITLES*/
  @Input() public isDonorReportList: boolean = false;
  @Input() public sortEvent: Subject<void> = null; /*Sort table emitter*/
  @Input() public filterEvent: Subject<ColumnFilterModel> = null; /*Filter table emitter*/
  @Input() public filterOptionsRequest: Subject<LazyLoadRequestModel> = null; /*lazy-select data request*/
  @Output() private export:EventEmitter<QuickBookReportShortModel> = new EventEmitter<QuickBookReportShortModel>()
  public tableColumns: string[] = [];
  public isAllSelected: boolean = false;
  public IconsType = IconsType;
  public SortOrder = SortOrder;

  constructor() {
  }

  public ngOnInit(): void {
    this.tableColumns = [...this.columns];
    if (this.multiselect) {
      this.tableColumns = ['multiselect', ...this.tableColumns];
    }
    if (this.rowActions.length) {
      this.tableColumns = [...this.tableColumns, 'rowActions'];
    }
  }

  public toggleAll(selectAll: boolean): void {
    // if (!this.total) {
    //   return;
    // }
    this.isAllSelected$.next(selectAll);
    this.multiselectSet.clear();
    this.isAllSelected = selectAll;
  }
  getExportModel(element:any, action:any) {
    return {element, action}
  }
  public getTextNode(element: any, column: string):string {
    switch (column) {
      case 'donationMethod':
        return UtilsComponent.getDonationMethodTextByType(element[column] as DonationMethodType);
      default :
          return element[column]
    }
  }

  public getColumnTitle(column: string): string {
    return {...SMART_TABLE_COLUMN_TITLES, ...this.columnLabels}[column] || column;
  }

  public onLazySelectFilterChanged(values: any[], field: string): void {
    this.onCurrentFilterChanged({ field, values });
  }

  public onCurrentFilterChanged(columnFilter: ColumnFilterModel): void {
    this.filterSortConfig.columnFilters = UtilsComponent.updateColumnFilters(columnFilter, this.filterSortConfig);
    this.filterEvent && this.filterEvent.next(columnFilter);
  }

  public getOptions(searchModel: LazyLoadSearchModel, column: string): void {
    this.filterOptionsRequest && this.filterOptionsRequest.next({ ...searchModel, field: column });
  }

  public isActiveFilter(column): boolean {
    if (!this.filterSortConfig) {
      return false;
    } else {
      const { columnFilters }: FilterSortModel = this.filterSortConfig;
      return !!columnFilters.find(({ field }: ColumnFilterModel) => field === column);
    }
  }

  public sortTable(column: string): void {
    this.filterSortConfig.sortField = column;
    this.filterSortConfig.sortOrder = UtilsComponent.nextSortOrder(this.filterSortConfig, column);
    this.sortEvent && this.sortEvent.next();
  }

  public checkColumn(column: string): boolean {
    return this.filterSortConfig.sortField === column;
  }

  public getSortOrder(column: string): SortOrder {
    return this.filterSortConfig.sortField === column ? this.filterSortConfig.sortOrder : null;
  }

  public getSelectOptions(column: string): ColumnFilterOptionsModel {
    return this.columnsFilterOptions.find(options => options.columnName === column);
  }

  public toggleElement(id: string): void {
    this.multiselectSet.has(id) ? this.multiselectSet.delete(id):this.multiselectSet.add(id);
    if (this.isAllSelected) {
      this.isAllSelected = false;
    }
    const allChecked = this.isAllSelected$.getValue();
    const size = this.multiselectSet.size;
    if (!allChecked && size === this.total || allChecked && size === 0) {
      this.toggleAll(true);
    }
    if (allChecked && size === this.total) {
      this.toggleAll(false);
    }
  }

  public navigate(element): void {
    this.rowClick && this.rowClick.next(element);
  }
}
