import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { DialogData } from 'src/app/routes/admin/admin-confirmation/admin-confirmation.component';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommentDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public formControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(255),
    Validators.minLength(1)
  ]);

  constructor(
    public dialogRef: MatDialogRef<CommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
  ) { }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
  }

  public blur(): void {
    this.formControl.setValue(this.formControl.value.trim());
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public get getError(): string {
    if (this.formControl.hasError('required')) return 'Name is required';
    if (this.formControl.hasError('onlySpaces')) return `Name can't contain only spaces`;
    if (this.formControl.hasError('maxlength')) return `Name can't contain more than 255 characters`;
  }

  public onOKClick(): void {
    this.formControl.markAsTouched();
    if (this.formControl.valid) this.dialogRef.close(this.formControl.value);
  }
}