import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreatedTimeAgoType } from '../../../../models/event/event.donation.activity.model';
import { Paging } from '../../../../models/paging/paging.model';
import { SortOrder } from '../../../../models/enum/sort.order';
import { EventCommentWallModel } from '../../../../models/event/event.comment.wall.model';
import { EventCommentService } from '../../../../services/events/event.comment.service';
import { Filter } from '../../../../models/paging/filter.model';
import { FilterType } from '../../../../models/enum/filter.type';

@Injectable()
export class CommentWallService {

  private mockData: EventCommentWallModel[] = [
    {fullName: 'Ester Howard', comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', createdTimeAgo: 5.55, createdTimeAgoType: CreatedTimeAgoType.Minute},
    {fullName: 'Dianne Russell', comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', createdTimeAgo: 8.55, createdTimeAgoType: CreatedTimeAgoType.Hour},
    {fullName: 'Theresa Webb', comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', createdTimeAgo: 10.55, createdTimeAgoType: CreatedTimeAgoType.Hour},
    {fullName: 'Jacob Jones', comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', createdTimeAgo: 12.55, createdTimeAgoType: CreatedTimeAgoType.Day, updatedOn: new Date().toJSON(), updatedByID: 'admin'},
    {fullName: 'Guy Hawkins', comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', createdTimeAgo: 15.55, createdTimeAgoType: CreatedTimeAgoType.Month},
  ];
  private sortConfig: Partial<Paging> = {
    sortField: 'createdOn',
    sortOrder: SortOrder.Descending,
  };

  private static filters(eventID: string): Filter[] {
    return [
      {
        field: 'eventID',
        value: eventID,
        type: FilterType.Equal
      },
    ];
  }

  private static paging(eventID: string): Paging {
    return {
      includeDeleted: false,
      includeDependencies: false,
      filters: CommentWallService.filters(eventID),
    };
  }

  constructor(
    private eventCommentsService: EventCommentService
  ) {}

  public getComments(isMockData: boolean, eventID: string, first: number = 0, rows: number = 10): Observable<EventCommentWallModel[]> {
    if (isMockData) {
      return of(this.mockData);
    } else {
      const paging: Paging = {
        ...CommentWallService.paging(eventID),
        ...this.sortConfig,
        rows,
        first,
      };
      return this.eventCommentsService.getModelList(paging);
    }
  }

  public getTotal(isMockData: boolean, eventID: string): Observable<number> {
    if (isMockData) {
      return of(this.mockData.length);
    } else {
      return this.eventCommentsService.getTotal(CommentWallService.paging(eventID));
    }
  }

  public getAllComments(isMockData: boolean, eventID: string): Observable<EventCommentWallModel[]> {
    if (isMockData) {
      return of(this.mockData);
    } else {
      const paging: Paging = {
        ...CommentWallService.paging(eventID),
        ...this.sortConfig,
      };
      return this.eventCommentsService.getModelList(paging);
    }
  }

  public addNewComment(comment: Partial<EventCommentWallModel>): Observable<EventCommentWallModel> {
    const commentModel: EventCommentWallModel = {
      ...comment,
      fullName: `${comment.firstName} ${comment.lastName}`
    };
    return this.eventCommentsService.addModel(commentModel);
  }
}
