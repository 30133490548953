import { Component, OnInit } from '@angular/core';

import { BaseFormComponent } from '../base.form.component';

@Component({
    selector: 'app-employer-full-name',
    templateUrl: './employer-full-name.component.html',
    styleUrls: ['../base.form.scss']
})
export class EmployerFullNameComponent extends BaseFormComponent implements OnInit {

    constructor() {
        super();
    }

    ngOnInit() {
    }

}
