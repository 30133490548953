<div
  [formGroup]="formGroup"
  class="new-custom-form-component-container-wrapper"
  (click)="!disabled && datepicker.open()"
  [class.disabled-control]="formControl.disabled"
>
  <label class="form-field-name" [for]="id">
    {{isAdmin ? label : (label | translate)}}
    <i
      *ngIf="toolTipText"
      class="fas fa-info-circle"
      [matTooltip]="isAdmin ? toolTipText : (toolTipText | translate)"
      matTooltipClass="table-tooltip"
    ></i>
  </label>

  <mat-datepicker
    #datepicker
    (opened)="onOpened()"
    (closed)="onClosed()"
    disabled="false"
    startView="multi-year"
    [startAt]="startAt"
    [calendarHeaderComponent]="calendarHeaderComponent"
    (yearSelected)="yearSelected($event)"
  ></mat-datepicker>

  <app-icons
    class="new-date-icon"
    [iconType]="IconsType.Calendar"
    [color]="'#0064BE'"
  ></app-icons>

  <mat-form-field appearance="outline">
    <input
      matInput
      [formControlName]="name"
      [id]="id"
      [matDatepicker]="datepicker"
      [matDatepickerFilter]="dateFilter"
      [min]="minDate"
      [max]="maxDate"
      [placeholder]="placeholder ? (isAdmin ? placeholder : (placeholder | translate)) : ''"
      (dateChange)="onDateChange($event.value)"
      [value]="formControl.value"
    >
    <mat-error *ngIf="formControl.invalid">
      <app-icons
        class="icon"
        [iconType]="IconsType.CircleClose"
        [width]="13.33"
        [height]="13.33"
        [color]="'#F44336'"
      ></app-icons>
      <span>{{getErrorMessage()}}</span>
    </mat-error>
  </mat-form-field>
</div>
