<!--<div class="fb-options-row">
  <div class="fb-row-element-full">
    <app-input
      [formGroup]="optionsForm"
      name="mainLabel"
      label="Heading Text"
      [low]="true"
      (valueChanged)="onMainLabelChanged()"
      [disabled]="readOnly"
    ></app-input>
  </div>
</div>-->

<!--<div class="fb-options-row">
  <div class="fb-row-element-full">
    <app-input
      [formGroup]="optionsForm"
      name="subLabel"
      label="Sub-Heading Text"
      [low]="true"
      (valueChanged)="onSubLabelChanged()"
      [disabled]="readOnly"
    ></app-input>
  </div>
</div>-->

<!--<div class="fb-options-row">
  <div class="fb-row-element-full">
    <app-select
      [formGroup]="optionsForm"
      name="size"
      label="Heading Size"
      [low]="true"
      (valueChanged)="onSizeChanged()"
      [disabled]="readOnly"
      [selectOptions]="sizeOptions"
    ></app-select>
  </div>
</div>-->

<div class="fb-options-row">
  <div class="fb-row-element-full">
    <app-select
      [formGroup]="optionsForm"
      name="alignment"
      label="Text Alignment"
      [low]="true"
      (valueChanged)="onAlignmentChanged()"
      [disabled]="readOnly"
      [selectOptions]="alignmentOptions"
    ></app-select>
  </div>
</div>

<div class="fb-options-row">
  <div class="fb-row-element-full">
    <app-button
      label="Duplicate Field"
      type="flat"
      color="#575757"
      (clickEvent)="duplicateField()"
      [disabled]="readOnly"
    ></app-button>
  </div>
  <span>{{'BUILD.Duplicate this field with all the same element settings' | translate}}</span>
</div>
