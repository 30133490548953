import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatepickerService } from '../../datepicker.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-date-picker-header',
  templateUrl: './date-picker-header.component.html',
  styleUrls: ['./date-picker-header.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DatePickerHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    private datepickerService: DatepickerService,
    private cdr: ChangeDetectorRef,
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  public ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public changeDate(mode: 'year' | 'multi-year'): void {
    this.datepickerService.startView = mode;
    this._calendar._goToDateInView(this._calendar.activeDate, mode);
  }

  public get periodLabelMonth() {
    return this._dateAdapter
      .format(
        this._calendar.activeDate,
        'MMMM'
      )
      .toLocaleUpperCase();
  }

  public get periodLabelYear() {
    return this._dateAdapter
      .format(
        this._calendar.activeDate,
        'YYYY'
      )
      .toLocaleUpperCase();
  }

  public previousClicked(): void {
    this._calendar.activeDate =
      this.datepickerService.startView === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this.datepickerService.startView === 'year'
          ? this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1)
          : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -24);
  }

  public nextClicked(): void {
    this._calendar.activeDate =
      this.datepickerService.startView === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this.datepickerService.startView === 'year'
          ? this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1)
          : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 24);
  }
}
