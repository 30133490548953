<div class="cancelling-reason-wrapper">
  <h1 mat-dialog-title>{{'Add reason for cancelling a donation' | translate}}</h1>
  <div class="cancelling-reason-field">
    <app-select
      [formGroup]="reasonForm"
      name="reason"
      label="Reason *"
      [selectOptions]="data?.isDonorPortal ? reasonOptionsDonorPortal : reasonOptions"
      errorMassage="Reason"
      (valueChanged)="onReasonChanged()"
    ></app-select>
  </div>

  <div class="cancelling-reason-field" *ngIf="reason === CancelReasonType.Other">
    <app-input
      [formGroup]="reasonForm"
      name="otherReason"
      label="Your Reason *"
      errorMassage="Your Reason"
    ></app-input>
  </div>

  <div mat-dialog-actions class="justify-content-end">
    <button color="primary" mat-button mat-stroked-button [disabled]="reasonForm.invalid" (click)="onOkClick()">{{'Save' | translate}}</button>
    <button color="primary" mat-button mat-stroked-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
  </div>
</div>
