<ng-container *ngIf="!colorPiker && !isNew">
  <div [ngClass]="low ? 'low-custom-form-component-container-wrapper' : 'custom-form-component-container-wrapper'" [formGroup]="formGroup">
    <label class="form-field-name" [ngStyle]="{'font-size': labelFontSize, 'font-family': labelFontFamily, 'color': labelFontColor}" [for]="id"
    >{{label | translate}} {{isRequired ? '*' : ''}}<i
      *ngIf="toolTipText"
      class="fas fa-info-circle {{classForTooltipIcon}}"
      [matTooltip]="toolTipText | translate"
      matTooltipHideDelay="200"
      matTooltipShowDelay="200"
      matTooltipPosition="below"
      matTooltipClass="table-tooltip {{classForTooltip}}"
    ></i
    ></label>
    <mat-form-field appearance="outline" (change)="emitChanges()" [ngClass]="{opacity: inputReadOnly}">
      <input
        matInput
        #input
        [formControlName]="name"
        [id]="id"
        [placeholder]="placeholder ? (placeholder | translate) : ''"
        [type]="type"
        [maxlength]="maxLength"
        [attr.disabled]="disabled"
        [mask]="mask ? mask : ''"
        [prefix]="prefix ? prefix : ''"
        [suffix]="suffix ? suffix : ''"
        [thousandSeparator]="thousandSeparator ? thousandSeparator : ''"
        [readonly]="inputReadOnly"
        (blur)="onBlur()"
        (keydown)="handleKeyDown($event)"
        (keydown.enter)="onKeydownEnter($event)"
        (input)="onInput($event)"
        [autocomplete]="autocompleteOff ? 'autocompleteOff' : ''"
        (focus)="focusEvent.emit()"
        [ngStyle]="{'font-size': inputFontSize, 'font-family': inputFontFamily, 'color': inputFontColor }"
      >
      <mat-error *ngIf="formControl.invalid && !customError">{{getErrorMessage()}}</mat-error>
      <mat-error *ngIf="formControl.invalid && customError">
        <ng-content></ng-content>
      </mat-error>
    </mat-form-field>
  </div>
</ng-container>

<ng-container *ngIf="isNew && !colorPiker">
  <div
    [ngClass]="low ? 'low-custom-form-component-container-wrapper' : 'new-custom-form-component-container-wrapper'"
    class="container-wrapper"
    [formGroup]="formGroup"
    [class.disabled-control]="formControl.disabled"
  >
    <label class="form-field-name" [for]="id"
    >{{label | translate}} {{isRequired ? '*' : ''}}<i
      *ngIf="toolTipText"
      class="fas fa-info-circle"
      [matTooltip]="toolTipText | translate"
      matTooltipHideDelay="200"
      matTooltipShowDelay="200"
      matTooltipPosition="below"
      matTooltipClass="table-tooltip"
    ></i
    ></label>
    <mat-form-field appearance="outline" (change)="emitChanges()" [ngClass]="{opacity: inputReadOnly}">
      <input
        matInput
        #input
        [formControlName]="name"
        [id]="id"
        [placeholder]="placeholder ? (placeholder | translate) : ''"
        [type]="type"
        [maxlength]="maxLength"
        [attr.disabled]="disabled"
        [mask]="mask ? mask : ''"
        [prefix]="prefix ? prefix : ''"
        [suffix]="suffix ? suffix : ''"
        [thousandSeparator]="thousandSeparator ? thousandSeparator : ''"
        [readonly]="inputReadOnly"
        (blur)="onBlur()"
        (keydown)="handleKeyDown($event)"
        (keydown.enter)="onKeydownEnter($event)"
        (input)="onInput($event)"
        [autocomplete]="autocompleteOff ? 'autocompleteOff' : ''"
        (focus)="focusEvent.emit()"
      >
      <!--      <mat-error *ngIf="formControl.invalid && !customError">{{getErrorMessage()}}</mat-error>-->
      <mat-error
        *ngIf="formControl.invalid && !customError"
      >
        <app-icons
          class="icon"
          [iconType]="IconsType.CircleClose"
          [width]="13.33"
          [height]="13.33"
          [color]="'#F44336'"
        >
        </app-icons>
        <span>{{getErrorMessage()}}</span>
        </mat-error>
      <mat-error *ngIf="formControl.invalid && customError">
        <app-icons
          class="icon"
          [iconType]="IconsType.CircleClose"
          [width]="13.33"
          [height]="13.33"
          [color]="'#F44336'"
        >
        </app-icons>
        <ng-content></ng-content>
      </mat-error>
    </mat-form-field>
  </div>
</ng-container>

<ng-container *ngIf="colorPiker && !isNew">
  <div class="color-custom-form-component-container-wrapper" [formGroup]="formGroup" [class.top-fix]="topFix">
    <label class="form-field-name" [for]="id">{{label | translate}} {{isRequired ? '*' : ''}}</label>
    <mat-form-field appearance="outline" (change)="emitChanges()" [ngClass]="{opacity: inputReadOnly}">
      <input
        matInput
        [formControlName]="name"
        [id]="id"
        [placeholder]="placeholder ? (placeholder | translate) : ''"
        type="text"
        [maxlength]="maxLength"
        [readonly]="inputReadOnly"
        (blur)="onBlur()"
        (keydown.enter)="onKeydownEnter($event)"
        (input)="onInput($event)"
        [autocomplete]="autocompleteOff ? 'autocompleteOff' : ''"
        (focus)="focusEvent.emit()"
        (keydown)="handleKeyDown($event)"
      >
      <app-email-color-picker
        [formGroup]="formGroup"
        [name]="name"
        (colorChanged)="onInput($event)"
        [colorPickerWithInput]="colorPiker"
      ></app-email-color-picker>
    </mat-form-field>
  </div>
</ng-container>

<ng-container *ngIf="colorPiker && isNew">
  <div class="new-color-custom-form-component-container-wrapper" [formGroup]="formGroup">
    <label class="form-field-name" [for]="id">{{label | translate}} {{isRequired ? '*' : ''}}</label>
    <mat-form-field appearance="outline" (change)="emitChanges()" [ngClass]="{opacity: inputReadOnly}">
      <input
        class="colorPikerInput"
        matInput
        [formControlName]="name"
        [id]="id"
        [placeholder]="placeholder ? (placeholder | translate) : ''"
        type="text"
        [maxlength]="maxLength"
        [readonly]="inputReadOnly"
        (blur)="onBlur()"
        (keydown.enter)="onKeydownEnter($event)"
        (input)="onInput($event)"
        [autocomplete]="autocompleteOff ? 'autocompleteOff' : ''"
        (focus)="focusEvent.emit()"
        (keydown)="handleKeyDown($event)"
      >
      <app-email-color-picker
        [formGroup]="formGroup"
        [name]="name"
        [isNew]="isNew"
        (colorChanged)="onInput($event)"
        [colorPickerWithInput]="colorPiker"
      ></app-email-color-picker>
    </mat-form-field>
  </div>
</ng-container>
