import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import { BaseFormComponent } from '../base.form.component';
import CountryModel from '../../../../../models/internationalization/country.model';
import FormElementDataModel from '../../../../../models/form.element.data.model';
import { MatSelectChange } from '@angular/material/select';
import { FbCountryStateService } from '../../../fb.country.state.service';
import {BehaviorSubject, combineLatest, Subject, Subscription, zip} from "rxjs";
import FbTemplateModel from "../../../../../models/form-builder/fb.template.model";
import {mergeMap, tap} from "rxjs/operators";

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['../base.form.scss'],
  providers: [FbCountryStateService]
})
export class AddressComponent extends BaseFormComponent implements OnChanges, OnDestroy{
  @Input() public stateOptions: FormElementDataModel[] = [];
  @Input() public isEmployerAddress: boolean = false;
  @Input() private isAddressRequired: boolean = true;
  @Input() public countries: CountryModel[];
  @Input() public filledInData: any;
  @Input() public updateAddress$: Subject<void>;
  @Input() public phonePrefix$: BehaviorSubject<string>;
  @Input() public phoneMask$: BehaviorSubject<string>;
  @Input() public phonePlaceholder$: BehaviorSubject<string>;
  private subscription: Subscription = new Subscription();
  constructor(public countryStateService: FbCountryStateService) {
    super();
  }
  ngOnChanges(changes: SimpleChanges) {
    if(!this.isAddressRequired) {
      this.countryStateService.isAddressRequired$.next(false);
    }
    if (changes.template && changes.template.currentValue) {
       const template$ = new BehaviorSubject<FbTemplateModel>(this.template);
       this.countryStateService.setTemplate(template$);
    }
    if (changes.paymentForm && changes.paymentForm.currentValue) {
      this.countryStateService.setForm(this.paymentForm);
    }
    if (changes.countries && changes.countries.currentValue) {
      this.countryStateService.storeCountries(this.countries);
      // secondPaymentForm not ready
      setTimeout(() => {
        if (this.filledInData && this.filledInData.country !== 1) {
          this.countryStateService.setCountryById(this.filledInData.country);
        } else {
          if(this.isEmployerAddress && this.paymentForm.get('country').value !== 1) {
            this.countryStateService.setCountryById(+this.paymentForm.get('country').value)
          } else this.countryStateService.setDefaultCountry();
        }
      }, 1000);
    }
    if (changes.updateAddress$ && changes.updateAddress$.currentValue) {
      this.countryStateService.addUpdateAddressSubscription(this.updateAddress$);
    }
    if (changes.phonePrefix$ && changes.phonePrefix$.currentValue) {
      this.adjustPhoneSettings();
    }
  }
  private adjustPhoneSettings() {
  this.subscription.add(
      combineLatest(this.countryStateService.phonePrefix$, this.countryStateService.phoneMask$, this.phonePlaceholder$)
      .pipe(
          tap((res: string[]) => {
            this.phonePrefix$.next(res[0]);
            this.phoneMask$.next(res[1]);
            this.phonePlaceholder$.next(res[2]);
          })
      ).subscribe())
  }

  public onCountryChanged({value}: MatSelectChange): void {
    const country = this.countryStateService.countries.find(({id}: CountryModel) => id === value);
    this.countryStateService.countryState.next(country);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
