<div class="header-wrapper1">
  <div class="header-logo" (click)="onLogoClick()"></div>

  <div class="header-action-column">
    <app-language-picker></app-language-picker>
  </div>
</div>

<div class="content-wrapper">
  <div [class.loading]="isDataLoading">
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="isDataLoading" class="spinner">
    <app-main-spinner></app-main-spinner>
  </div>
</div>

<div class="footer-wrapper">
  <div class="footer-links">
    <div>
      <span class="footer-owner-desktop">&#169; The Auxilia {{currentYear}} Version: {{appVersion}}</span>
    </div>
    <a class="footer-link" href="https://www.theauxilia.com/careers">{{'LAYOUT.Careers' | translate}}</a>
    <a class="footer-link" href="https://www.theauxilia.com/privacy-policy">{{'LAYOUT.Privacy Policy' | translate}}</a>
    <a class="footer-link" href="https://www.theauxilia.com/terms-of-use">{{'LAYOUT.Terms of Use' | translate}}</a>
    <a class="footer-link" href="https://www.theauxilia.com/contact">{{'LAYOUT.Contact Us' | translate}}</a>
    <app-language-picker [showLabel]="true"></app-language-picker>
  </div>
  <div class="footer-networks">
    <a class="footer-link" href="https://www.facebook.com/Auxilia-107010537442015/" target="_blank">
      <app-icons
        [iconType]="IconsType.Facebook"
        color="#93ccff"
      ></app-icons>
    </a>
    <a class="footer-link" href="https://www.instagram.com/auxiliasoftware/" target="_blank">
      <app-icons
        [iconType]="IconsType.Instagram"
        color="#93ccff"
      ></app-icons>
    </a>
    <a class="footer-link" href="https://x.com/AuxiliaSoftware" target="_blank">
      <app-icons
        [iconType]="IconsType.Twitter"
        color="#93ccff"
      ></app-icons>
    </a>
    <a class="footer-link" href="https://www.linkedin.com/company/auxilia-software/" target="_blank">
      <app-icons
        [iconType]="IconsType.LinkedIn"
        color="#93ccff"
      ></app-icons>
    </a>
  </div>
  <span class="footer-owner-tablet">&#169; The Auxilia {{currentYear}}</span>
</div>
