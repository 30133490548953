
<ng-container *ngIf="!isNew">
  <ng-container *ngIf="isForm; else noForm">
    <div [ngClass]="low ? 'low-custom-form-component-container-wrapper' : 'custom-form-component-container-wrapper'" [formGroup]="formGroup">
      <label class="form-field-name" [ngStyle]="{'font-size': labelFontSize, 'font-family': labelFontFamily, 'color': labelFontColor}"
       [for]="id">{{isAdmin ? label : (label | translate)}} {{isRequired ? '*' : ''}}<i
        *ngIf="toolTipText"
        class="fas fa-info-circle"
        [matTooltip]="isAdmin ? toolTipText : (toolTipText | translate)"
        matTooltipHideDelay="200"
        matTooltipShowDelay="200"
        matTooltipPosition="below"
      ></i
      ></label>
      <mat-form-field appearance="outline">
        <mat-select [formControlName]="name" [multiple]="!!multiSelectOptions" [placeholder]="placeholder ? (isAdmin ? placeholder : (placeholder | translate)) : ''" [id]="id"
                    (selectionChange)="emitChanges($event)" [value]="formControl.value">
          <ng-container *ngIf="!multiSelectOptions; else multiSelect">
            <mat-option *ngFor="let option of selectOptions" [value]="option.value" [disabled]="option.hidden" >
              {{isAdmin ? option.label : (option.label | translate)}}
            </mat-option>
          </ng-container>
          <ng-template #multiSelect>
            <mat-option *ngFor="let option of multiSelectOptions" [value]="option.value" [disabled]="option.hidden">
              {{isAdmin ? option.label : (option.label | translate)}}
            </mat-option>
          </ng-template>

        </mat-select>
        <mat-error *ngIf="formControl.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
  </ng-container>
  <ng-template #noForm>
    <div [ngClass]="low ? 'low-custom-form-component-container-wrapper' : 'custom-form-component-container-wrapper'">
      <label class="form-field-name" [for]="id">{{isAdmin ? label : (label | translate)}} {{isRequired ? '*' : ''}}<i
        *ngIf="toolTipText"
        class="fas fa-info-circle"
        [matTooltip]="isAdmin ? toolTipText : (toolTipText | translate)"
        matTooltipHideDelay="200"
        matTooltipShowDelay="200"
        matTooltipPosition="below"
        [ngStyle]="{'font-size': labelFontSize, 'font-family': labelFontFamily, 'color': labelFontColor}"
      ></i
      ></label>
      <mat-form-field appearance="outline">
        <mat-select [multiple]="!!multiSelectOptions" [placeholder]="placeholder ? (isAdmin ? placeholder : (placeholder | translate)) : ''" [id]="id"
                    (selectionChange)="emitChanges($event)">
          <ng-container *ngIf="!multiSelectOptions; else multiSelect">
            <mat-option *ngFor="let option of selectOptions" [value]="option.value" [disabled]="option.hidden">
              {{isAdmin ? option.label : (option.label | translate)}}
            </mat-option>
          </ng-container>
          <ng-template #multiSelect>
            <mat-option *ngFor="let option of multiSelectOptions" [value]="option.value" [disabled]="option.hidden">
              {{isAdmin ? option.label : (option.label | translate)}}
            </mat-option>
          </ng-template>

        </mat-select>
      </mat-form-field>
    </div>
  </ng-template>
</ng-container>



<!--new-->
<ng-container *ngIf="isNew">
  <ng-container *ngIf="isForm; else noForm">
    <div
      [ngClass]="low ? 'low-custom-form-component-container-wrapper' : 'new-custom-form-component-container-wrapper'"
      [class.disabled-control]="formControl.disabled"
      [formGroup]="formGroup"
    >
      <label *ngIf="label" class="form-field-name" [for]="id">{{isAdmin ? label : (label | translate)}} {{isRequired ? '*' : ''}}<i
        *ngIf="toolTipText"
        class="fas fa-info-circle"
        [matTooltip]="isAdmin ? toolTipText : (toolTipText | translate)"
        matTooltipHideDelay="200"
        matTooltipShowDelay="200"
        matTooltipPosition="below"
        [ngStyle]="{'font-size': labelFontSize, 'font-family': labelFontFamily, 'color': labelFontColor}"
      ></i
      ></label>
      <mat-form-field [ngClass]="{noPadding: isNoPadding, customHeight: isCustomHeight}" appearance="outline">
        <mat-select
          [disableOptionCentering]="isDisableOptionCentering"
          [formControlName]="name"
          [multiple]="!!multiSelectOptions"
          [placeholder]="placeholder ? (isAdmin ? placeholder : (placeholder | translate)) : ''" [id]="id"
          (selectionChange)="emitChanges($event)"
          [value]="formControl.value"
        >
          <mat-select-trigger *ngIf="selectOrganization">
            <div class="wrapper-icon">
              <app-icons
                *ngIf="iconType"
                class="placeholder-icon"
                [iconType]="iconType"
                [width]="24"
                [height]="24"
                [color]="iconColor"
              >
              </app-icons>
              <span class="value-item">{{getValueView}}</span>
            </div>
          </mat-select-trigger>
          <ng-container *ngIf="!multiSelectOptions; else multiSelect">
            <mat-option *ngFor="let option of selectOptions" [value]="option.value">
              {{isAdmin ? option.label : (option.label | translate)}}
            </mat-option>
          </ng-container>
          <ng-template #multiSelect>
            <mat-option *ngFor="let option of multiSelectOptions" [value]="option.value">
              {{isAdmin ? option.label : (option.label | translate)}}
            </mat-option>
          </ng-template>

        </mat-select>
        <mat-error
          *ngIf="formControl.invalid && formControl.touched && !customError"
        >
          <app-icons
            class="icon"
            [iconType]="IconsType.CircleClose"
            [width]="13.33"
            [height]="13.33"
            [color]="'#F44336'"
          >
          </app-icons>
          <span class="message">{{getErrorMessage()}}</span>
        </mat-error>
      </mat-form-field>



    </div>
  </ng-container>

  <ng-template #noForm>
    <div [ngClass]="low ? 'low-custom-form-component-container-wrapper' : 'new-custom-form-component-container-wrapper'">
      <label class="form-field-name" [for]="id">{{isAdmin ? label : (label | translate)}} {{isRequired ? '*' : ''}}<i
        *ngIf="toolTipText"
        class="fas fa-info-circle"
        [matTooltip]="isAdmin ? toolTipText : (toolTipText | translate)"
        matTooltipHideDelay="200"
        matTooltipShowDelay="200"
        matTooltipPosition="below"
        [ngStyle]="{'font-size': labelFontSize, 'font-family': labelFontFamily, 'color': labelFontColor}"
      ></i
      ></label>
      <mat-form-field appearance="outline">
        <mat-select [multiple]="!!multiSelectOptions" [placeholder]="placeholder ? (isAdmin ? placeholder : (placeholder | translate)) : ''" [id]="id"
                    (selectionChange)="emitChanges($event)">
          <ng-container *ngIf="!multiSelectOptions; else multiSelect">
            <mat-option *ngFor="let option of selectOptions" [value]="option.value">
              {{isAdmin ? option.label : (option.label | translate)}}
            </mat-option>
          </ng-container>
          <ng-template #multiSelect>
            <mat-option *ngFor="let option of multiSelectOptions" [value]="option.value">
              {{isAdmin ? option.label : (option.label | translate)}}
            </mat-option>
          </ng-template>

        </mat-select>
      </mat-form-field>
    </div>
  </ng-template>
</ng-container>

