<div class="line-chart-wrapper" *ngIf="lineChartData && lineChartData.length; else noDataTemplate">
  <canvas
    baseChart
    width="950"
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="lineChartOptions"
    [colors]="lineChartColors"
    [legend]="lineChartLegend"
    [chartType]="lineChartType"
  ></canvas>
</div>
<ng-template #noDataTemplate>
  <div class="no-data">{{'No Available Data' | translate}}</div>
</ng-template>
