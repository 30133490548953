import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import { BaseTableComponent } from '../base-table/base-table.component';
import { CampaignStatus, CampaignType } from 'src/app/models/enum';
import { CampaignModel } from 'src/app/models/campaigns/campaign.model';
import ColumnFilterOptionsModel from '../../models/filter-sort/column.filter.options.model';
import ColumnFilterModel from '../../models/filter-sort/column.filter.model';
import FilterSortModel from '../../models/filter-sort/filter.sort.model';
import { AuthService } from 'src/app/services/auth.service';
import { CLIENT_ADMIN, CONTENT_CREATOR, FUNDRAISING_MANAGER } from 'src/app/constants';
import { TranslateService } from '@ngx-translate/core';
import { LetterCampaignStatusOption } from 'src/app/models/campaigns/letter-campaign-status-option.model';

const LETTER_CAMPAIGN_STATUSES: LetterCampaignStatusOption[] = [
  { status: CampaignStatus.Active, label: "Active" },
  { status: CampaignStatus.LettersMailed, label: "Mailed" },
  { status: CampaignStatus.LettersPrinted, label: "Printed" },
  { status: CampaignStatus.Complete, label: "Complete" },
] 

@Component({
  selector: 'app-campaign-list-table',
  templateUrl: './campaign-list-table.component.html',
  styleUrls: ['./campaign-list-table.component.scss']
})
export class CampaignListTableComponent extends BaseTableComponent<CampaignModel> implements OnInit, OnChanges {
  @Input() public filterSortConfig: FilterSortModel;

  @Input() public origin: string;

  @Input() public draftsData: boolean = false;

  @Input() public columnsFilterOptions: ColumnFilterOptionsModel[] = [];

  @Input() public initialSelection: any;

  @Input() readOnly: boolean;

  @Output() public navigateToCampaignDashboard: EventEmitter<CampaignModel> = new EventEmitter<CampaignModel>();

  @Output() public cancelArchiveCampaign: EventEmitter<CampaignModel> = new EventEmitter<CampaignModel>();

  @Output() public filterChanged: EventEmitter<ColumnFilterModel> = new EventEmitter<ColumnFilterModel>();

  @Output() public letterStatusChanged: EventEmitter<CampaignModel> = new EventEmitter<CampaignModel>();

  public isAllPermissionAvailable = false;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.origin === 'event-tab') {
      this.tableColumns.pop();
    }
    this.isAllPermissionAvailable = this.rolePermission();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public selectedOptions(event: ColumnFilterModel): void {
    this.filterChanged.emit(event);
  }

  public rolePermission(): boolean {
    const roles = this.authService.getIdentityClaimsRole();
    return roles.includes(CLIENT_ADMIN) || roles.includes(FUNDRAISING_MANAGER) || roles.includes(CONTENT_CREATOR);
  }

  public getColumnTitle(column): string {
    const columnsTitles = {
      name: 'Campaign Name',
      clientID: 'Organization',
      createdByUserName: 'Role',
      startDate: 'Start Date',
      endDate: 'End Date',
      status: 'Status',
      scheduler: 'Sent Option',
      type: 'Campaign Type'
    };
    return this.translate.instant('CAMP.' + columnsTitles[column]);
  }

  public onEditLinkClick(element: CampaignModel): void {
    this.editClicked.emit(element);
  }

  public toCampaignDashboard(element: CampaignModel): void {
    if (this.draftsData) {
      return;
    } else {
      this.navigateToCampaignDashboard.emit(element);
    }
  }

  public withFilter(column): boolean {
    return column === 'status' || column === 'clientID' || column === 'type';
  }

  public getTextNode(element: CampaignModel, column: string): string {
    switch (column) {
      case 'status':
        const status = {
          [CampaignStatus.Draft]: 'Draft',
          [CampaignStatus.Active]: 'Active',
          [CampaignStatus.Sent]: 'Sent',
          [CampaignStatus.SegmentSaving]: 'Active',
          [CampaignStatus.Disabled]: 'Disabled',
          [CampaignStatus.Complete]: 'Complete',
          [CampaignStatus.SegmentCreatingError]: 'Failed',
          [CampaignStatus.LettersPrinted]: 'Printed',
          [CampaignStatus.LettersMailed]: 'Mailed'
        };
        return this.translate.instant(status[element.status]);
      case 'clientID':
        const clients: ColumnFilterOptionsModel = this.columnsFilterOptions.find(({columnName}: ColumnFilterOptionsModel) => columnName === 'clientID');
        const option = clients.options.find((item: FormElementDataModel) => item.value === element.clientID);
        return option ? option.label : '';
      case 'type':
        return CampaignType[element.type];
      default:
        return element[column];
    }
  }

  public getEditSeeDetailTextNode({ status }: CampaignModel): string {
    return status === CampaignStatus.Draft ? 'Edit' : 'See details';
  }

  public isCancelArchiveAvailable({ status }: CampaignModel): boolean {
    // return (
    // status === CampaignStatus.Used
    // status === CampaignStatus..Canceled ||
    // status === EventStatus.Complete
    // );
    return;
  }

  public onCancelArchiveCampaign(element: CampaignModel): void {
    this.cancelArchiveCampaign.emit(element);
  }

  public getCancelArchiveTextNode({ status }: CampaignModel): string {
    // return status === CampaignStatus.Used ? 'Cancel' : 'Archive';
    return;
  }

  public getSelectOptions(column: string): ColumnFilterOptionsModel {
    return this.columnsFilterOptions.find(
      options => options.columnName === column
    );
  }

  public isActiveFilter(column: string): boolean {
    if (!this.filterSortConfig) {
      return false;
    } else {
      const { columnFilters }: FilterSortModel = this.filterSortConfig;
      return !!columnFilters.find(({ field }: ColumnFilterModel) => field === column);
    }
  }

  isLetterCampaign(element: CampaignModel): boolean {
    return element.type === CampaignType.Letter;
  }

  availableLetterStatuses(element: CampaignModel): LetterCampaignStatusOption[] {
    return LETTER_CAMPAIGN_STATUSES.filter((item: LetterCampaignStatusOption) => item.status !== element.status);
  }

  onChangeStatusClick(campaign: CampaignModel, statusOption: LetterCampaignStatusOption): void {
    const newCampaign: CampaignModel = {...campaign};
    newCampaign.status = statusOption.status;
    this.letterStatusChanged.emit(newCampaign);
  }
}
