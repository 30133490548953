import { Injectable } from '@angular/core';
import { LookupService } from './lookup.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { StateModel } from '../../models/state.model';
import { Paging } from '../../models/paging/paging.model';
import { FilterType } from '../../models/enum/filter.type';
import { shareReplay, tap, filter, map } from 'rxjs/operators';
import FormElementDataModel from '../../models/form.element.data.model';
import { TimeZoneModel } from '../../models/time-zone.model';
import CountryModel from '../../models/internationalization/country.model';

@Injectable({
  providedIn: 'root'
})
export class LookupStoreService {
  private allStates: BehaviorSubject<StateModel[]> = new BehaviorSubject<StateModel[]>([]);
  public allStates$: Observable<StateModel[]>;
  public allStatesOptions$: Observable<FormElementDataModel[]>;

  private usaStates: BehaviorSubject<StateModel[]> = new BehaviorSubject<StateModel[]>([]);
  public usaStates$: Observable<StateModel[]>;
  public usaStatesOptions$: Observable<FormElementDataModel[]>;
  public usaStatesMap$: Observable<Map<number, StateModel>>;

  private timeZones: BehaviorSubject<TimeZoneModel[]> = new BehaviorSubject<TimeZoneModel[]>([]);
  public timeZones$: Observable<TimeZoneModel[]>;
  public timeZonesOptions$: Observable<FormElementDataModel[]>;

  private countries: BehaviorSubject<CountryModel[]> = new BehaviorSubject<CountryModel[]>([]);
  public countries$: Observable<CountryModel[]>;
  public countriesOptions$: Observable<FormElementDataModel[]>;
  public countriesMap$: Observable<Map<number, CountryModel>>;
  public countriesChanged$: Subject<any> = new Subject<any>();

  constructor(
    private lookupService: LookupService
  ) {
    this.setKnownLocalStorageData('allStates');
    this.setKnownLocalStorageData('usaStates');
    this.setKnownLocalStorageData('timeZones');
    this.setKnownLocalStorageData('countries');

    this.timeZones$ = this.getTimeZones();
    this.timeZonesOptions$ = this.timeZones$.pipe(
      map((timeZones: TimeZoneModel[]) => timeZones.map(({ description, id }: TimeZoneModel) => ({label: description, value: id})))
    );

    this.allStates$ = this.getAllStates();
    this.allStatesOptions$ = this.allStates$.pipe(
      map((states: StateModel[]) => states.map(({ name, id }: StateModel) => ({ label: name, value: id })))
    );

    this.usaStates$ = this.getUsaStates();
    this.usaStatesOptions$ = this.usaStates$.pipe(
      map((states: StateModel[]) => states.map(({ name, id }: StateModel) => ({label: name, value: id})))
    );

    this.countries$ = this.getCountries();
    this.countriesOptions$ = this.countries$.pipe(
      map((countries$: CountryModel[]) => countries$.map(({ name, id }: CountryModel) => ({label: name, value: id})))
    );

    this.countriesMap$ = this.countries$.pipe(
      map((countries: CountryModel[]) => {
        const hashMap = new Map<number, CountryModel>();
        countries.forEach((country: CountryModel) => hashMap.set(country.id, country));
        return hashMap;
      })
    );

    this.usaStatesMap$ =  this.usaStates$.pipe(
      map((states: StateModel[]) => {
        const hashMap = new Map<number, StateModel>();
        states.forEach((state: StateModel) => hashMap.set(state.id, state));
        return hashMap;
      })
    );
  }

  private setKnownLocalStorageData(key: string): void {
    if (localStorage.getItem(key)) {
      const data = JSON.parse(localStorage.getItem(key));
      this[key].next(data);
    }
  }

  private getAllStates(): Observable<StateModel[]> {
    if (this.allStates.getValue() && this.allStates.getValue().length) {
      return this.allStates.asObservable();
    } else {
      const paging: Paging = {
        includeDependencies: false,
        includeDeleted: false
      }
      return this.lookupService.getStates(paging)
        .pipe(
          filter(value => !!value),
          shareReplay(),
          tap((states: StateModel[]) => {
            this.allStates.next(states);
            localStorage.setItem('allStates', JSON.stringify(states));
          })
        );
    }
  }

  private getUsaStates(): Observable<StateModel[]> {
    if (this.usaStates.getValue() && this.usaStates.getValue().length) {
      return this.usaStates.asObservable();
    } else {
      const statePaging: Paging = {
        includeDependencies: false,
        includeDeleted: false,
        filters: [ {
          field: 'CountryID',
          value: '1',
          type: FilterType.Equal
        }]
      };
      return this.lookupService.getStates(statePaging)
        .pipe(
          filter(value => !!value),
          shareReplay(),
          tap((states: StateModel[]) => {
            this.usaStates.next(states);
            localStorage.setItem('usaStates', JSON.stringify(states));
          })
        );
    }
  }

  private getTimeZones(): Observable<TimeZoneModel[]> {
    if (this.timeZones.getValue() && this.timeZones.getValue().length) {
      return this.timeZones.asObservable();
    } else {
      return this.lookupService.getTimeZones()
        .pipe(
          filter(value => !!value),
          shareReplay(),
          map((timeZones: TimeZoneModel[]) => {
            const sortedTimeZones = timeZones.sort((A, B) => A.id - B.id);
            return [
              ...sortedTimeZones.slice(4, 8),
              ...sortedTimeZones.slice(0, 4),
              ...sortedTimeZones.slice(8)
            ];
          }),
          tap((timeZones: TimeZoneModel[]) => {
            this.timeZones.next(timeZones);
            localStorage.setItem('timeZones', JSON.stringify(timeZones));
          })
        );
    }
  }

  private getCountries(): Observable<CountryModel[]> {
    if (this.countries.getValue() && this.countries.getValue().length) {
      return this.countries.asObservable();
    } else {
      return this.lookupService.getCountries()
        .pipe(
          filter(value => !!value),
          shareReplay(),
          tap((countries: CountryModel[]) => {
            this.countries.next(countries);
            localStorage.setItem('countries', JSON.stringify(countries));
          })
        );
    }
  }
}
