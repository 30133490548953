import {
  ChangeDetectionStrategy,
  Component,
  ElementRef, Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '../../../services/events/event.service';
import MjmlElementModel from '../../../models/templates/mjml.element.model';
import { DashboardService } from '../../../services/dashboard/dashboard.service';
import { finalize, first, tap } from 'rxjs/operators';
import { EventThermometerWidgetModel } from '../../../models/event/event-thermometer-widget.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-my-thermometer',
  templateUrl: './my-thermometer.component.html',
  styleUrls: ['./my-thermometer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyThermometerComponent implements OnInit {
  @Input() public landingPageView: boolean = false;

  @ViewChild('thermometer') private thermometer: ElementRef;
  private transform: BehaviorSubject<string> = new BehaviorSubject<string>('scale(1)');
  public transformParam$: Observable<string> = this.transform.asObservable();
  public wrapperParam: DOMRect;
  @Input() public entity: MjmlElementModel;
  public donations: string;
  public donors: string;
  @Input() public eventId: string;
  public data$: Observable<EventThermometerWidgetModel>;
  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private router: Router,
    private dashboardService: DashboardService
  ) { }

  public ngOnInit(): void {
    if (!this.landingPageView) {
      this.eventId = this.route.snapshot.params.eventId;
    }
    this.data$ = this.dashboardService.getThermometerInfo(this.eventId)
      .pipe(
        first(),
        tap((response: EventThermometerWidgetModel) => {
          response.values.forEach(item => {
            if (item.fieldName === 'Donors') {
              this.donors = item.count.toString();
            }
            if (item.fieldName === 'Donations') {
              this.donations = item.count.toString();
            }
          });
          if (this.landingPageView) {
            this.entity.attributes['donors'] = this.donors;
            this.entity.attributes['donations'] = this.donations;
          } else {
            this.getEntity();
          }
        }),
        finalize(() => setTimeout(() => this.updateTransformParams()))
      );
  }

  private getEntity(): void {
    this.entity = {
      attributes: {
        donors: this.donors,
        donations: this.donations,
        alignment: this.getQueryParamFromMalformedURL('alignment'),
        borderColor: this.getQueryParamFromMalformedURL('borderColor'),
        containerFillColor: this.getQueryParamFromMalformedURL('containerFillColor'),
        containerTextColor: this.getQueryParamFromMalformedURL('containerTextColor'),
        containerGradientColor: this.getQueryParamFromMalformedURL('containerGradientColor'),
        description: this.getQueryParamFromMalformedURL('description'),
        goal: this.getQueryParamFromMalformedURL('goal'),
        iteration_1: this.getQueryParamFromMalformedURL('iteration_1'),
        iteration_2: this.getQueryParamFromMalformedURL('iteration_2'),
        iteration_3: this.getQueryParamFromMalformedURL('iteration_3'),
        showDonations: this.getQueryParamFromMalformedURL('showDonations'),
        showDonors: this.getQueryParamFromMalformedURL('showDonors'),
        showGoal: this.getQueryParamFromMalformedURL('showGoal'),
        showPercent: this.getQueryParamFromMalformedURL('showPercent'),
        textPlace: this.getQueryParamFromMalformedURL('textPlace'),
        appearance: this.getQueryParamFromMalformedURL('appearance'),
        fontSizeGoal: this.getQueryParamFromMalformedURL('fontSizeGoal'),
        fontSizeIterations: this.getQueryParamFromMalformedURL('fontSizeIterations'),
        fontSizeDescription: this.getQueryParamFromMalformedURL('fontSizeDescription'),
        showIterations: this.getQueryParamFromMalformedURL('showIterations'),
        resize: this.getQueryParamFromMalformedURL('resize'),
        'padding-bottom': this.getQueryParamFromMalformedURL('padding-bottom'),
        'padding-left': this.getQueryParamFromMalformedURL('padding-left'),
        'padding-right': this.getQueryParamFromMalformedURL('padding-right'),
        'padding-top': this.getQueryParamFromMalformedURL('padding-top'),
      }
    };
  }

  private getQueryParamFromMalformedURL(name: string): string {
    const results = new RegExp('[\\?&]' + name + '=([^&]*)').exec(decodeURI(this.router.url));
    if (!results) {
      return;
    }
    return results[1];
  }

  private updateTransformParams(): void {
    if (this.thermometer && !this.landingPageView) {
      const wrapperRect = this.thermometer.nativeElement.getBoundingClientRect();
      const childRect = this.thermometer.nativeElement.firstChild.firstChild.getBoundingClientRect();
      const scaleX = childRect.width > wrapperRect.width ? wrapperRect.width / childRect.width - 0.01 : 1;
      const scaleY = childRect.height > wrapperRect.height ? wrapperRect.height / childRect.height - 0.01 : 1;
      const scale = Math.min(scaleX, scaleY);
      this.transform.next(`scale(${scale}, ${scale})`);
    }
  }

}
