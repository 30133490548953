<ng-container *ngIf="!value; else checkedTemplate">
  <svg
    *ngIf="!disabled"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    (click)="onClick(true)"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="active-checkbox"
  >
    <rect
      x="0.5"
      y="0.5"
      width="19"
      height="19"
      rx="1.5"
      fill="white"
      [attr.stroke]="stroke"
    />
  </svg>

  <svg *ngIf="disabled" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" fill="#F0F3F7" stroke="#C5D0DE"/>
  </svg>
</ng-container>

<ng-template #checkedTemplate>
  <ng-container *ngIf="main">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      (click)="onClick(false)"
      class="active-checkbox"
      (mouseenter)="onMouseEnter()"
      (mouseleave)="onMouseLeave()"
    >
      <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" fill="white" stroke="#C5D0DE"/>
      <rect x="5" y="5" width="10" height="10" fill="#0064BE"/>
    </svg>
  </ng-container>
  <ng-container *ngIf="!main">
    <svg
      *ngIf="!disabled"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      (click)="onClick(false)"
      (mouseenter)="onMouseEnter()"
      (mouseleave)="onMouseLeave()"
      class="active-checkbox"
    >
      <rect width="20" height="20" rx="2" [attr.fill]="fill"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.57591 14C8.39191 14 8.21591 13.924 8.08991 13.79L4.84791 10.3374C4.59525 10.0694 4.60925 9.64737 4.87725 9.39537C5.14591 9.14337 5.56791 9.1567 5.81925 9.4247L8.56925 12.352L14.1746 6.21737C14.4239 5.9447 14.8452 5.9267 15.1172 6.1747C15.3886 6.4227 15.4072 6.8447 15.1592 7.11603L9.06791 13.7827C8.94325 13.92 8.76591 13.9987 8.58058 14H8.57591Z" fill="white"/>
      <mask id="mask0_4385_10240" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="4" y="6" width="12" height="8">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.57591 14C8.39191 14 8.21591 13.924 8.08991 13.79L4.84791 10.3374C4.59525 10.0694 4.60925 9.64737 4.87725 9.39537C5.14591 9.14337 5.56791 9.1567 5.81925 9.4247L8.56925 12.352L14.1746 6.21737C14.4239 5.9447 14.8452 5.9267 15.1172 6.1747C15.3886 6.4227 15.4072 6.8447 15.1592 7.11603L9.06791 13.7827C8.94325 13.92 8.76591 13.9987 8.58058 14H8.57591Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_4385_10240)">
        <rect x="2" y="2" width="16" height="16" fill="white"/>
      </g>
    </svg>

    <svg *ngIf="disabled" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="2" fill="#0064BE"/>
    </svg>

  </ng-container>
</ng-template>
