import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/routes/admin/admin-confirmation/admin-confirmation.component';
import { SecondaryEventParticipantService } from 'src/app/services/events/event-secondary-participant.service';
import { Paging } from 'src/app/models/paging/paging.model';
import EventModel from 'src/app/models/event/event.model';
import { FilterType } from 'src/app/models/enum/filter.type';
import { FormValidators } from '../../validatiors/form-validators';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-participant-check',
  templateUrl: './participant-check.component.html',
  styleUrls: ['./participant-check.component.scss']
})
export class ParticipantCheckComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public isCheckFailed = false;
  public isParticipantExist = false;
  participantForm = new FormGroup({
    email: new FormControl('', [Validators.required, FormValidators.emailValidator])});
  constructor(
    public dialogRef: MatDialogRef<ParticipantCheckComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private secondaryParticipantService: SecondaryEventParticipantService,
    private authService: AuthService,
  ) { }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onOKClick(): void {
    this.participantForm.markAllAsTouched();
    if  (this.participantForm.valid) {
      const eventFilter: Paging = {
        includeDependencies: false,
        includeDeleted: false,
        filters: [
          {
            field: 'eventID',
            value: this.data.title,
            type: FilterType.Equal
          },
          {
            field: 'email',
            value: this.participantForm.controls.email.value,
            type: FilterType.Equal
          }
        ]
      };
      this.secondaryParticipantService.getModelList(eventFilter).subscribe(result => {
        if (!result[0]) {
          this.isCheckFailed = true;
        } else {
          this.isParticipantExist = true;
          this.close();
        }
      })
    }
  }

  public close(): void {
    this.dialogRef.close(this.isParticipantExist);
  }

}
