import { Injectable, Injector } from '@angular/core';
import SourceTemplateModel from '../../models/templates/source.template.model';
import { BaseModelService } from '../base.model.service';

@Injectable({
  providedIn: 'root'
})
export class SourceTemplateService extends BaseModelService<SourceTemplateModel> {
  public updateSourceTemplate$: Subject<void> = new Subject<void>()

  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'SourceTemplate';
  }

}
import {} from '../../models/templates/source.template.model';import { Subject } from 'rxjs';

