<div class="fb-container">
  <mat-tab-group
    mat-align-tabs="center"
    [selectedIndex]="currentTab"
    (selectedIndexChange)="currentTab = $event"
  >
    <mat-tab label="{{'BUILD.Create' | translate}}">

      <div class="create-tab-top-bar">
        <div
          (click)="fbss.undo()"
          class="top-bar-button"
          [class.disabled]="fbss.undoDisable"
          matRipple
          [matRippleDisabled]="fbss.undoDisable"
        >
          <div class="action-container">
            <span>{{'BUILD.Undo' | translate}}</span>
            <i class="fas fa-undo-alt"></i>
          </div>
        </div>
        <div
          (click)="fbss.redo()"
          class="top-bar-button"
          [class.disabled]="fbss.redoDisable"
          matRipple
          [matRippleDisabled]="fbss.redoDisable"
        >
          <div class="action-container">
            <span>{{'BUILD.Redo' | translate}}</span>
            <i class="fas fa-redo-alt"></i>
          </div>
        </div>
      </div>

      <app-create-tab
        *ngIf="templateReady$ | async"
        [fbss]="fbss"
        [cornerstoneService]="cornerstoneService"
        [appStripeService]="appStripeService"
      ></app-create-tab>
    </mat-tab>

    <mat-tab label="{{'BUILD.Settings' | translate}}">
      <app-settings-tab [fbss]="fbss"></app-settings-tab>
    </mat-tab>

    <mat-tab
      *ngIf="!publishStepHidden && !isAdminPortal"
      [disabled]="!(templateIsSaved$ | async)"
    >
      <ng-template mat-tab-label >
        <span #publishLabel>{{'BUILD.Publish' | translate}}</span>
      </ng-template>
      <app-publish-tab [fbss]="fbss"></app-publish-tab>
    </mat-tab>

    <mat-tab label="{{'BUILD.Publish' | translate}}" *ngIf="socialMediaPublishStep">
      <app-publish-tab-sm
        [fbss]="fbss"
        [socialMediaPostForm]="socialMediaPostForm"
        [postFriendlyUrl$]="postFriendlyUrl$"
      ></app-publish-tab-sm>
    </mat-tab>
  </mat-tab-group>

  <div
    class="screenshots-window"
    [style.height]="(screenshotsInProgress$ | async) ? '100%' : '0%'"
  ></div>
</div>
