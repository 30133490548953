import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../../../../services/auth.service';
import {ClientIdStateService} from '../../../../../services/client.module.state/client.id.state.service';
import {TagService} from '../../../../../services/tag/tag.service';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {Filter} from '../../../../../models/paging/filter.model';
import {FilterType} from '../../../../../models/enum/filter.type';
import {TagStatus} from '../../../../../models/enum/tag-status.type';
import {TagType} from '../../../../../models/enum/tag.type';
import {Paging} from '../../../../../models/paging/paging.model';
import {SortOrder} from '../../../../../models/enum/sort.order';
import TagModel from '../../../../../models/tag/tag.model';
import {debounceTime, distinctUntilChanged, startWith, switchMap} from 'rxjs/operators';
import {DonorsTagManagerService} from '../../../donors/components/donors-tag-manager/donors-tag-manager.service';
import {TagCreation, TagManagerAssignTagModel} from '../../../../../models/client/client.donor.tag.manager.model';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-add-donor-tags-popup',
  templateUrl: './add-donor-tags-popup.component.html',
  styleUrls: ['./add-donor-tags-popup.component.scss'],
  providers: [DonorsTagManagerService]
})
export class AddDonorTagsPopupComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public searchEventsByName: Subject<string> = new Subject<string>();
  public tags$: Observable<TagModel[]>;
  public inProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selection: Set<string> = new Set<string>();
  public isAllSelected$: Observable<boolean>;
  public clientID = this.clientIdStateService.selectedRelationshipClientId;
  private allListFilterConfig: Filter[] = [{
    field: 'clientID',
    value: this.clientID,
    type: FilterType.Equal
  }];
  private tagStatusFilter: Filter[] = [
    {
      field: 'status',
      value: TagStatus.Active.toString(),
      type: FilterType.Equal,
    },
    {
      field: 'type',
      value: TagType.CustomTags.toString(),
      type: FilterType.Equal,
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TagCreation,
    private authService: AuthService,
    private dialogRef: MatDialogRef<AddDonorTagsPopupComponent>,
    private clientIdStateService: ClientIdStateService,
    private clientDonorTagService: TagService,
    public ndtms: DonorsTagManagerService,
    public toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
    this.tags$ = this.getTags();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getTags(): Observable<TagModel[]> {
    return this.searchEventsByName.asObservable().pipe(
      startWith(''),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((value) => {
        const searchFilter: Filter[] = [{
          field: 'name',
          value: value,
          type: FilterType.Contains,
        }];
        const paging: Paging = {
          includeDependencies: true,
          sortField: 'name',
          sortOrder: SortOrder.Ascending,
          filters: value ? [...this.allListFilterConfig, ...this.tagStatusFilter, ...searchFilter] : [...this.allListFilterConfig, ...this.tagStatusFilter]
        };
        return this.clientDonorTagService.getModelList(paging)
      })
    );
  }

  public onClose(): void {
    this.dialogRef.close(false);
  }

  public checkBoxClick(id: string): void {
    if (this.selection.has(id)) {
      this.selection.delete(id);
      return;
    }
    this.selection.add(id);
  }

  public clearAll(): void {
    this.selection.clear();
  }

  public add(): void {
    const addTagModel: TagManagerAssignTagModel = {
      isAllSelected: this.data.isAllChecked,
      tagIds: [...this.selection],
      clientDonorIds: [...this.data.donorSelection]
    };
    this.ndtms.assignTagsToDonors(this.data.batchId, addTagModel)
      .subscribe(
        resp => {
          if (resp) this.dialogRef.close('CUSTOMIZATION.Tags has been updated successfully');
        },
          err => this.toastr.error(err.message)
      );
  }

  public onEventSearch(event: Event): void {
    this.searchEventsByName.next((event.target as HTMLInputElement).value);
  }

}
