import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cross-icon',
  templateUrl: './cross-icon.component.html',
})
export class CrossIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
