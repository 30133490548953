import { BaseModelService } from '../base.model.service';
import { ClientDonorModel } from '../../models/client/client.donor.model';
import { ClientModel } from 'src/app/models/client/client.model';
import { flatMap, map, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadDonorResultModel } from '../../models/donor/upload-donor-result.model';
import {Paging} from '../../models/paging/paging.model';
import {HttpParams} from '@angular/common/http';
import {PagingHelper} from '../helper/paging.helper';
import {ClientDonorShortModel} from '../../models/client/client.donor.short.model';
import { ClientDonorFieldType } from 'src/app/models/enum/client.donor.field.type';
import { ClientDonorMergeInfo } from 'src/app/models/client/client.donor.merge.info';
import { ClientDonorDeleteInfo } from 'src/app/models/client/client.donor.delete.info';
import { UtilsComponent } from 'src/app/components/utils.component';
import {TotalRowsModel} from "../../models/paging/totalrow.model";
import {DonorActivityLogModel} from "../../models/donor/donor-activity-log.model";

@Injectable({
  providedIn: 'root'
})
export class ClientDonorService extends BaseModelService<ClientDonorModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'ClientDonor';
  }

  public getModelByClientIdEmail(clientId: string, email: string): Observable<ClientDonorModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<ClientDonorModel>(`${config.apiURL}/${this.controllerName}/${clientId}/${email}`);
      })
    );
  }

  public getShortModel(filter?: Paging): Observable<ClientDonorShortModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<ClientDonorShortModel[]>(`${config.apiURL}/${this.controllerName}/shortModels`, { params });
      }));
  }

  public getSupportingClients(donorId: string): Observable<ClientModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<ClientModel[]>(`${config.apiURL}/${this.controllerName}/Supporter/${donorId}`);
      })
    );
  }

  public checkImport(id: string): Observable<UploadDonorResultModel> {
    return this.configService.loadConfig().pipe(flatMap(config => {
        return this.http.get<UploadDonorResultModel>(`${config.apiURL}/${this.controllerName}/checkImport/${id}`);
      })
    );
  }

  public findDuplicates(ids: string[], criteria: number): Observable<ClientDonorShortModel[][]> {
    const splitedIds: string[][] = UtilsComponent.splitArray(ids, 50);
    const requests: Observable<any>[] = splitedIds.map(idSet => {
      return this.configService.loadConfig().pipe(flatMap(config => {
        const idsString: string = idSet.join('&ids=');
        let url: string = `${config.apiURL}/${this.controllerName}/FindDuplicates`;
        url += '?ids=' + idsString;
        url += '&criteria=' + criteria;
        return this.http.get<ClientDonorShortModel[][]>(url);
      }));
    })

    return forkJoin(requests)
      .pipe(
        map(res => [].concat(...res)),
      )
  }

  public mergeDonors(mergeInfo: ClientDonorMergeInfo): Observable<any> {
    if(!mergeInfo.changedFields) mergeInfo.changedFields = [];
    return this.configService.loadConfig().pipe(flatMap(config => {
      const url = `${config.apiURL}/${this.controllerName}/MergeDonors`
      return this.http.put(url, mergeInfo);
    }));
  }

  public findNewDonorDuplicates(model: ClientDonorShortModel, criteria?: ClientDonorFieldType): Observable<ClientDonorShortModel[]> {
    const paramsArr: string[] = Object.keys(model).map(key => `${key}=${model[key]}`)
    const params: string = `?` + paramsArr.join('&');
    return this.configService.loadConfig().pipe(flatMap(config => {
      let url = `${config.apiURL}/${this.controllerName}/FindNewDonorDuplicates${params}`;
      if(criteria) url += `&criteria=${criteria}`
      return this.http.get<ClientDonorShortModel[]>(url);
    }));
  }

  public softDeleteDonors(deleteInfo: ClientDonorDeleteInfo): Observable<any> {
    return this.configService.loadConfig().pipe(flatMap(config => {
      const url = `${config.apiURL}/${this.controllerName}/SoftDeleteDonors`
      return this.http.put(url, deleteInfo);
    }));
  }

  public getDonorActivity(filter: Paging):Observable<DonorActivityLogModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
        flatMap(config => {
          return this.http.get<DonorActivityLogModel[]>(
              `${config.apiURL}/DonorActivityLog`,
              { params }
          );
        })
    );
  }
  public getDonorActivityTotal(filter: Paging):Observable<number> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
        flatMap(config => {
              return this.http.post<TotalRowsModel>(
                  `${config.apiURL}/DonorActivityLog/posttotal`, filter).pipe(map(model => model.total))
            }
        )
    )
  }
}
