import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { Subscription } from 'rxjs';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { MjmlCustomType } from '../../../../models/templates/mjml.custom.type';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import ChartBuilderData from '../../../../models/templates/chart.builder.data';
import { TemplateType } from '../../../../models/templates/template.type';
import { NgxChartsLibBuilderComponent } from '../ngx-charts-lib-builder/ngx-charts-lib-builder.component';

@Component({
  selector: 'app-chart-tool-settings',
  templateUrl: './chart-tool-settings.component.html',
  styleUrls: ['./chart-tool-settings.component.scss', '../tool-settings.scss']
})
export class ChartToolSettingsComponent implements OnInit, OnDestroy {
  public MjmlTag = MjmlTag;
  private subscription: Subscription = new Subscription();
  @Input() public tms: TemplateManagementService;

  public chartForm: FormGroup = this.formBuilder.group({
    src: ['', [Validators.required]],
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]],
    href: '',
    title: '',
  });

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
  ) { }

  public ngOnInit(): void {
    this.setInitialValues();

    this.subscription.add(
      this.chartForm.get('src').valueChanges.subscribe(value => {
        this.tms.activeElement.attributes['src'] = value ? value : '';
      })
    );

    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (!element || element.tagName !== MjmlTag.image || element.attributes['customType'] !== MjmlCustomType.chart) {
          return;
        }
        this.setInitialValues();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setInitialValues(): void {
    const attributes = this.tms.activeElement.attributes;
    const src = attributes['src'] || '';
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    const href = attributes['href'] || '';
    const title = attributes['title'] || '';

    this.chartForm.get('src').setValue(src);
    this.chartForm.get('paddingBottom').setValue(paddingBottom.slice(0, -2));
    this.chartForm.get('paddingLeft').setValue(paddingLeft.slice(0, -2));
    this.chartForm.get('paddingRight').setValue(paddingRight.slice(0, -2));
    this.chartForm.get('paddingTop').setValue(paddingTop.slice(0, -2));
    this.chartForm.get('href').setValue(href);
    this.chartForm.get('title').setValue(title);
  }

  public setNgxChart(): void {
    const chartData: string = this.tms.activeElement.attributes['chart-data-set'];
    const data: ChartBuilderData = {
      formGroup: this.chartForm,
      controlName: 'src',
      ngxChartData: chartData,
    };
    const config: MatDialogConfig = {
      data,
      width: '95vw',
      maxWidth: '95vw',
      height: '95vh'
    };
    const dialogRef = this.dialog.open(NgxChartsLibBuilderComponent, config);
    this.subscription.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          return;
        }
        this.tms.activeElement.attributes['chart-data-set'] = result;
        this.tms.emitSave(true);
      })
    );
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public onSrcChanged(): void {
    const value = this.chartForm.get('src').value;
    this.tms.activeElement.attributes['src'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onPaddingTopChanged(): void {
    const value = this.chartForm.get('paddingTop').value;
    this.tms.activeElement.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.chartForm.get('paddingBottom').value;
    this.tms.activeElement.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.chartForm.get('paddingRight').value;
    this.tms.activeElement.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.chartForm.get('paddingLeft').value;
    this.tms.activeElement.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onHrefChanged(): void {
    const value = this.chartForm.get('href').value;
    this.tms.activeElement.attributes['href'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onTitleChanged(): void {
    const value = this.chartForm.get('title').value;
    this.tms.activeElement.attributes['title'] = value ? value : '';
    this.tms.emitSave(true);
  }
}
