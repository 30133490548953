import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-resend-emails-popup',
  templateUrl: './resend-emails-popup.component.html',
  styleUrls: ['./resend-emails-popup.component.scss']
})
export class ResendEmailsPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dialogRef: MatDialogRef<ResendEmailsPopupComponent>
  ) { }

  public onClose(execAction: boolean) {
    this.dialogRef.close(execAction);
  }
}
