import { ChangeDetectorRef, Injectable } from '@angular/core';
import { LayerModel } from '../../models/image-builder/layer.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ImageTemplateModel } from '../../models/image-builder/image.template.model';
import { debounceTime } from 'rxjs/operators';
import { TemplateType } from '../../models/templates/template.type';
import EventModel from '../../models/event/event.model';
import { CampaignModel } from '../../models/campaigns/campaign.model';
import FormElementDataModel from '../../models/form.element.data.model';
import { formatDate } from '@angular/common';
import { EventSchedularModel } from '../../models/event/event.schedular.model';
import { TimeZoneModel } from '../../models/time-zone.model';
import { EventItineraryModel } from '../../models/event/event-itinerary.model';
import { DEFAULT_IMAGE_TEMPLATE, MS_PER_DAY } from '../../constants';
import TemplatePayload from '../../models/templates/template.payload';
import { LayerType } from '../../models/image-builder/layer.type';
import LayerIndexModel from '../../models/image-builder/layer.index.model';
import { UtilsComponent } from '../utils.component';

@Injectable()
export class ImageBuilderStateService {
  public template: ImageTemplateModel = UtilsComponent.clone(DEFAULT_IMAGE_TEMPLATE);
  private historyStorage: ImageTemplateModel[] = [];
  private undoIndex: number = 0;
  private activeLayerIndex: number;

  /** Event and Campaign data */
  public event: Partial<EventModel>;
  public campaign: Partial<CampaignModel>;

  public stateOptions: FormElementDataModel[] = [];
  public timeZones: TimeZoneModel[] = [];
  public templatePayload: TemplatePayload;
  private patchTemplateSubject: Subject<void> = new Subject<void>();

  /** Template Type */
  public templateType: TemplateType = TemplateType.Layout;
  public readOnly: boolean = false;

  /** Index */
  private activeLayerIndexSubject: BehaviorSubject<LayerIndexModel> = new BehaviorSubject<LayerIndexModel>(null);
  public activeLayerIndexObservable: Observable<LayerIndexModel> = this.activeLayerIndexSubject.asObservable();

  /** Layer */
  private activeLayerSubject: BehaviorSubject<LayerModel> = new BehaviorSubject<LayerModel>(null);
  public activeLayerObservable: Observable<LayerModel> = this.activeLayerSubject.asObservable();

  /** Template */
  private templateSubject: BehaviorSubject<ImageTemplateModel> = new BehaviorSubject<ImageTemplateModel>(this.template);
  public templateObservable: Observable<ImageTemplateModel> = this.templateSubject.asObservable();

  /** Save */
  private saveSubject: Subject<void> = new Subject<void>();
  public saveObservable: Observable<void> = this.saveSubject.asObservable().pipe(debounceTime(10000));

  /** Template Resize */
  private resizeSubject: Subject<void> = new Subject<void>();
  public resizeObservable: Observable<void> = this.resizeSubject.asObservable();

  /** Shape Layer Changed */
  private shapeSubject: Subject<void> = new Subject<void>();
  public shapeObservable: Observable<void> = this.shapeSubject.asObservable();

  private forceSaveTemplateSubject: Subject<TemplatePayload> = new Subject<TemplatePayload>();
  public forceSaveTemplateEmitter: Observable<TemplatePayload> = this.forceSaveTemplateSubject.asObservable();

  private draggableElementType: LayerType = null;
  public logoSrc: string = null;
  private apiURL: string = localStorage.getItem('apiurl');

  private static getDefaultWidth(type: LayerType): number {
    switch (type) {
      case LayerType.logo:
        return 200;
      case LayerType.pentagon:
        return 512;
      case LayerType.hexagon:
        return 726;
      default:
        return 600;
    }
  }

  private static getDefaultHeight(type: LayerType): number {
    switch (type) {
      case LayerType.text:
      case LayerType.eventName:
      case LayerType.eventLocation:
      case LayerType.campaignName:
        return 300;
      case LayerType.logo:
        return 200;
      case LayerType.pentagon:
        return 512;
      case LayerType.hexagon:
        return 628;
      default:
        return 600;
    }
  }

  private static isShapeLayer(type: LayerType): boolean {
    switch (type) {
      case LayerType.circle:
      case LayerType.square:
      case LayerType.triangle:
      case LayerType.pentagon:
      case LayerType.hexagon:
        return true;
      default:
        return false;
    }
  }

  constructor(
    private cdr: ChangeDetectorRef
  ) {
    this.patchTemplateSubject.asObservable().subscribe(this.patchTemplate.bind(this));
  }

  public addLayer(layer: LayerModel): void {
    this.layers.push(layer);
    this.saveTemplate();
  }

  public setActiveLayerIndex(index: number, activate: boolean = true): void {
    /*if (index === this.activeLayerIndex) {
      return;
    }*/
    this.activeLayerIndex = index;
    this.activeLayerIndexSubject.next({index, activate});
    this.activeLayerSubject.next(this.activeLayer);
  }

  public get activeLayer(): LayerModel {
    if (!this.layers.length) {
      return null;
    } else {
      const layer = this.layers[this.activeLayerIndex];
      return layer ? layer : null;
    }
  }

  /** List of template layers */
  public get layers(): LayerModel[] {
    return this.template.layers ? this.template.layers : null;
  }

  /** Template Width */
  public get templateWidth(): number {
    return this.template.width;
  }

  public setTemplateWidth(width: number): void {
    this.template.width = width;
  }

  /** Template Height */
  public get templateHeight(): number {
    return this.template.height;
  }

  public setTemplateHeight(height: number): void {
    this.template.height = height;
  }

  /** Save Template */
  public saveTemplate(trackChanges: boolean = true): void {
    this.saveSubject.next();
    if (trackChanges) {
      if (this.undoIndex > 0) {
        this.historyStorage.splice(-this.undoIndex);
      }
      this.addTemplateToHistory();
      this.undoIndex = 0;
    }
  }

  public duplicateLayer(): void {
    const layer: LayerModel = UtilsComponent.clone(this.activeLayer);
    const [x, y] = layer.frame.translate;
    layer.frame.translate = [x + 25, y + 25];
    this.addLayer(layer);
    this.saveTemplate();
  }

  public deleteLayer(): void {
    this.layers.splice(this.activeLayerIndex, 1);
    this.setActiveLayerIndex(null);
    this.saveTemplate();
  }

  public bringLayerForward(): void {
    if (this.activeLayerIndex === this.layers.length - 1) {
      return;
    }
    const targetLayer = UtilsComponent.clone(this.layers[this.activeLayerIndex]);
    this.layers.splice(this.activeLayerIndex, 1);
    this.layers.splice(this.activeLayerIndex + 1, 0, targetLayer);
    const nextIndex = this.activeLayerIndex + 1;
    setTimeout(() => this.setActiveLayerIndex(nextIndex, false));
    this.saveTemplate();
  }

  public sendLayerBackward(): void {
    if (this.activeLayerIndex === 0) {
      return;
    }
    const targetLayer = UtilsComponent.clone(this.layers[this.activeLayerIndex]);
    this.layers.splice(this.activeLayerIndex, 1);
    this.layers.splice(this.activeLayerIndex - 1, 0, targetLayer);
    const nextIndex = this.activeLayerIndex - 1;
    setTimeout(() => this.setActiveLayerIndex(nextIndex, false));
    this.saveTemplate();
  }

  public setTemplate(template: ImageTemplateModel = DEFAULT_IMAGE_TEMPLATE, trackChanges: boolean = true): void {
    this.template = UtilsComponent.clone(template);
    this.templateSubject.next(this.template);
    this.setActiveLayerIndex(null);
    this.saveTemplate(trackChanges);
    this.emitResize();
    trackChanges && this.patchTemplateSubject.next();
  }

  private addTemplateToHistory(): void {
    const storageLength = this.historyStorage.length;
    if (storageLength && JSON.stringify(this.historyStorage[storageLength - 1]) === JSON.stringify(this.template)) {
      return;
    }
    this.historyStorage.push(UtilsComponent.clone(this.template));
    if (this.historyStorage.length === 12) {
      this.historyStorage.splice(0, 1);
    }
  }

  public undo(): void {
    const length = this.historyStorage.length;
    if (length <= 1 || this.undoIndex === length - 1) {
      return;
    }
    const template = UtilsComponent.clone(this.historyStorage[length - 1 - ++this.undoIndex]);
    this.setTemplate(template, false);
  }

  public redo(): void {
    if (this.undoIndex === 0) {
      return;
    }
    const length = this.historyStorage.length;
    const template = UtilsComponent.clone(this.historyStorage[length - 1 - --this.undoIndex]);
    this.setTemplate(template, false);
  }

  public get undoDisable(): boolean {
    const length = this.historyStorage.length;
    return length <= 1 || this.undoIndex === length - 1 || this.readOnly;
  }

  public get redoDisable(): boolean {
    return this.undoIndex === 0 || this.readOnly;
  }

  public setTemplateAttributes(key: string, value: string): void {
    this.template.attributes[key] = value;
    this.saveTemplate();
  }

  public get templateAttributes(): { [key: string]: string } {
    return this.template.attributes;
  }

  public setActiveLayerAttributes(key: string, value: string): void {
    this.activeLayer.attributes[key] = value;
    this.saveTemplate();
  }

  public get activeLayerAttributes(): { [key: string]: string } {
    return this.activeLayer ? this.activeLayer.attributes : {};
  }

  public emitResize(): void {
    this.resizeSubject.next();
  }

  public setEventData(event: Partial<EventModel>): void {
    this.event = event;
    this.patchTemplateSubject.next();
  }

  public setCampaignData(campaign: Partial<CampaignModel>): void {
    this.campaign = campaign;
    this.patchTemplateSubject.next();
  }

  public setReadOnly(readOnly: boolean): void {
    this.readOnly = readOnly;
  }

  public setStateOptions(stateOptions: FormElementDataModel[]): void {
    this.stateOptions = stateOptions;
  }

  public setTimeZonesOptions(timeZones: TimeZoneModel[]): void {
    this.timeZones = timeZones;
  }

  /** Event Name HTML */
  public get eventNameHtml(): string {
    if (!this.event) return '';
    return this.event.name ? `<div>${this.event.name}</div>` : '';
  }

  /** Event Description HTML */
  public get eventDescriptionHtml(): string {
    if (!this.event) return '';
    return this.event.description ? `<div>${this.event.description}</div>` : '';
  }

  /** Event Location HTML */
  public get eventLocationHtml(): string {
    if (!this.event) return '';
    const {address1, city, state}: Partial<EventModel> = this.event;
    const stateOption = state ? this.stateOptions.find(({value}: FormElementDataModel) => value === state) : null;
    const stateName = stateOption ? stateOption.label : '';
    let zip = this.event.zip;
    if (zip && zip.length > 5) {
      zip = zip.slice(0, 5) + '-' + zip.slice(5);
    }
    return `${address1 ? '<div>' + address1 + '</div>' : ''}<div>${city ? city + ', ' : ''}${stateName ? stateName + ' ' : ''}${zip ? zip : ''}</div>`;
  }

  /** Event Scheduler HTML */
  public get eventSchedulerHtml(): string {
    if (!this.event) return '';
    const {eventSchedularList, timeZoneID}: Partial<EventModel> = this.event;
    const timeZone = this.timeZones.find(({id}: TimeZoneModel) => id === +timeZoneID);
    const timeZoneShortName = timeZone ? timeZone.shortName : '';
    let content = '';
    eventSchedularList.forEach(({startDate, endDate, startTime, endTime}: EventSchedularModel) => {
      content += `<div>${startDate ? formatDate(startDate, 'MMMM dd, yyyy', 'en-US') + ' - ' : ''}
            ${endDate ? formatDate(endDate, 'MMMM dd, yyyy', 'en-US') : ''}
            ${startTime ? startTime : ''}
            ${endTime ? ' - ' + endTime : ''}
            ${timeZoneShortName ? ' ' + timeZoneShortName : ''}</div>`;
    });
    return content;
  }

  /** Event Contacts HTML */
  public get eventContactsHtml(): string {
    if (!this.event) return '';
    const {clientName, clientWebSite, contactUsEmail}: Partial<EventModel> = this.event;
    let contactUsPhone = this.event.contactUsPhone;
    if (contactUsPhone && contactUsPhone.length) {
      contactUsPhone = `+${contactUsPhone.slice(0, 1)} (${contactUsPhone.slice(1, 4)}) ${contactUsPhone.slice(4, 7)}-${contactUsPhone.slice(-4)}`;
    }
    return `${clientName ? '<div>' + clientName + '</div>' : ''}
          ${clientWebSite ? '<div>' + clientWebSite + '</div>' : ''}
          ${contactUsEmail ? '<div>' + contactUsEmail + '</div>' : ''}
          ${contactUsPhone ? '<div>' + contactUsPhone + '</div>' : ''}`;
  }

  /** Event Itinerary HTML */
  public get eventItineraryHtml(): string {
    if (!this.event) return '';
    const eventItineraries = this.event.eventItineraries;
    if (!eventItineraries.length) return '';
    const dates: Set<number> = new Set();
    eventItineraries.forEach((item: EventItineraryModel) => {
      const from = new Date(item.startDate);
      const to = new Date(item.endDate);
      const daysInRange = (to.getTime() - from.getTime()) / MS_PER_DAY + 1;
      for (let i = 0; i < daysInRange; i++) {
        dates.add(new Date(from.getFullYear(), from.getMonth(), from.getDate() + i).getTime());
      }
    });

    let result = '';
    dates.forEach((uniqueDay: number) => {
      result += `<div><b>${formatDate(uniqueDay, 'EEEE, MMMM d, y', 'en-US')}:</b></div>`;
      eventItineraries.forEach((item: EventItineraryModel) => {
        const from = new Date(item.startDate).getTime();
        const to = new Date(item.endDate).getTime();
        if (uniqueDay >= from && uniqueDay <= to) {
          result += `<div>${item.startTime} - ${item.endTime}: ${item.text}</div>`;
        }
      });
    });
    return result;
  }

  /** Event Accessibility Text HTML */
  public get eventAccessibilityTextHtml(): string {
    if (!this.event) return '';
    return this.event.accessabilityText ? `<div>${this.event.accessabilityText}</div>` : '';
  }

  /** Campaign Name HTML */
  public get campaignNameHtml(): string {
    if (!this.campaign) return '';
    return this.campaign.name ? `<div>${this.campaign.name}</div>` : '';
  }

  public shapeChanged(): void {
    this.shapeSubject.next();
  }

  /** On save click */
  public triggerSaveTemplate(templatePayload: TemplatePayload): void {
    this.forceSaveTemplateSubject.next(templatePayload);
  }

  public patchTemplate(): void {
    this.layers.forEach((layer: LayerModel, index) => {
      switch (layer.type) {
        case LayerType.eventName:
          layer.content = this.eventNameHtml;
          break;
        case LayerType.eventDescription:
          layer.content = this.eventDescriptionHtml;
          break;
        case LayerType.eventLocation:
          layer.content = this.eventLocationHtml;
          break;
        case LayerType.eventScheduler:
          layer.content = this.eventSchedulerHtml;
          break;
        case LayerType.eventContactDetails:
          layer.content = this.eventContactsHtml;
          break;
        case LayerType.eventScheduleItinerary:
          layer.content = this.eventItineraryHtml;
          break;
        case LayerType.eventAccessibilityStatement:
          layer.content = this.eventAccessibilityTextHtml;
          break;
        case LayerType.campaignName:
          layer.content = this.campaignNameHtml;
          break;
      }
    });
    this.cdr.markForCheck();
    //this.saveTemplate();
  }

  public get themedTemplate(): boolean {
    return this.templateType === TemplateType.Themed;
  }

  public get componentBasedTemplate(): boolean {
    return this.templateType === TemplateType.ComponentBased;
  }

  public resetHistoryStorage(): void {
    this.historyStorage = [];
    this.undoIndex = 0;
    this.addTemplateToHistory();
  }

  public setDraggableElementType(type: LayerType): void {
    this.draggableElementType = type;
  }

  public createLayer(type: LayerType, x?: number, y?: number): void {
    if (this.readOnly || this.themedTemplate || this.componentBasedTemplate) {
      return;
    }
    const translateX = x
      ? x - ImageBuilderStateService.getDefaultWidth(this.draggableElementType) / 2
      : (this.templateWidth - ImageBuilderStateService.getDefaultWidth(this.draggableElementType)) / 2;
    const translateY = y
      ? y - ImageBuilderStateService.getDefaultHeight(this.draggableElementType) / 2
      : (this.templateHeight - ImageBuilderStateService.getDefaultHeight(this.draggableElementType)) / 2;
    const layer: LayerModel = {
      frame: {
        translate: [translateX, translateY],
        scale: [1, 1],
        rotate: 0,
      },
      type,
      transparency: 1,
      width: ImageBuilderStateService.getDefaultWidth(type),
      height: ImageBuilderStateService.getDefaultHeight(type),
      content: this.getDefaultContent(type),
      attributes: this.getDefaultAttributes(type),
      keepRatio: ImageBuilderStateService.isShapeLayer(type),
    };
    this.addLayer(layer);
  }

  public createLayerOnDrop(x: number, y: number): void {
    if (!this.draggableElementType) {
      return;
    }
    this.createLayer(this.draggableElementType, x, y);
  }

  private getDefaultContent(type: LayerType): string {
    switch (type) {
      case LayerType.text:
        return '<div>Awesome Text</div>';
      case LayerType.eventName:
        return this.eventNameHtml;
      case LayerType.eventDescription:
        return this.eventDescriptionHtml;
      case LayerType.eventLocation:
        return this.eventLocationHtml;
      case LayerType.eventScheduler:
        return this.eventSchedulerHtml;
      case LayerType.eventContactDetails:
        return this.eventContactsHtml;
      case LayerType.eventScheduleItinerary:
        return this.eventItineraryHtml;
      case LayerType.eventAccessibilityStatement:
        return this.eventAccessibilityTextHtml;
      case LayerType.campaignName:
        return this.campaignNameHtml;
      default:
        return '';
    }
  }

  private getDefaultAttributes(type: LayerType): { [key: string]: string } {
    switch (type) {
      case LayerType.logo:
        return {url: this.logoSrc ? `${this.apiURL}/AzureFileStorage/image/${this.logoSrc}` : null};
      case LayerType.circle:
      case LayerType.square:
      case LayerType.triangle:
      case LayerType.pentagon:
      case LayerType.hexagon:
        return {stroke: '#66788a', strokeWidth: '8'};
      default:
        return {};
    }
  }

  public tryToDeleteLayer(): void {
    if (this.activeLayerIndex === null) {
      return;
    }
    this.deleteLayer();
  }
}
