import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import TransactionReviewModel from '../../../models/payments/transaction.review.model';
import {BaseTableComponent} from '../../base-table/base-table.component';
import {PaymentTransactionReviewStatus} from '../../../models/enum/payment.transaction.review.status';
import {SortOrder} from "../../../models/enum/sort.order";
import FilterSortModel from "../../../models/filter-sort/filter.sort.model";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import * as moment from 'moment';
import { ZEROEVENT } from 'src/app/constants';

const STATUS = {
  [PaymentTransactionReviewStatus.Cancelled]: 'Cancelled',
  [PaymentTransactionReviewStatus.Completed]: 'Completed',
  [PaymentTransactionReviewStatus.Failed]: 'Declined',
  [PaymentTransactionReviewStatus.Pledged]: 'Pledged',
  [PaymentTransactionReviewStatus.Scheduled]: 'Scheduled',
  [PaymentTransactionReviewStatus.Rescheduled]: 'Rescheduled',
};

@Component({
  selector: 'app-schedule-table',
  templateUrl: './schedule-table.component.html',
  styleUrls: ['./schedule-table.component.scss']
})
export class ScheduleTableComponent extends BaseTableComponent<TransactionReviewModel> implements OnInit, OnChanges {
  @Output() initiateChargeEvent:EventEmitter<string> = new EventEmitter();
  @Output() skipPaymentEvent:EventEmitter<void> = new EventEmitter();
  @Input() isDonorPortal: boolean = false;
  @Input() filterSortConfig: FilterSortModel;
  @Input() showFilters: boolean = false;
  @Input() nextDonationDate: string = '';
  @Input() lastSkippedDonation: TransactionReviewModel[] = [];
  @Output() public sortChanged: EventEmitter<FilterSortModel> = new EventEmitter<FilterSortModel>();
  public SortOrder = SortOrder;
  private firstScheduledTransactionIndex: number;

  constructor(
      private router: Router,
      private translate: TranslateService
  ) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.tableDataColumns) {
      this.tableColumns = [...this.tableDataColumns];
    }

    if(!!changes.data?.currentValue) {
      this.firstScheduledTransactionIndex = (changes.data.currentValue as TransactionReviewModel[]).findIndex((transactionReview: TransactionReviewModel) => transactionReview.id === ZEROEVENT);
    }
  }

  public sortTable(column: string): void {
    let sortOrder: SortOrder;
    if (this.filterSortConfig.sortField === column) {
      switch (this.filterSortConfig.sortOrder) {
        case SortOrder.Ascending:
          sortOrder = SortOrder.Descending;
          break;
        case SortOrder.Descending:
          sortOrder = SortOrder.Ascending;
      }
    } else {
      sortOrder = SortOrder.Ascending;
    }
    const result: FilterSortModel = {
      sortField: column,
      sortOrder,
      columnFilters: this.filterSortConfig.columnFilters
    };
    this.sortChanged.emit(result);
  }

  public getColumnTitle(column: string): string {
    let columnsTitles:any = {
      paymentDate: 'Payment Date',
      amount: 'Amount',
      status: 'Status',
      declineReason: 'Cancel Reason',
      noteReason: 'Note (Reason)',
      eventDonationPublicId: 'Transaction ID',
      description: 'Transaction Details',
      loggedOnDate: 'Action Date',
      transactionDate: 'Transaction Date'
    };

    if (this.isDonorPortal) columnsTitles = {
      paymentDate: 'Payment Date',
      amount: 'Amount',
      status: 'Status',
      declineReason: 'Cancel Reason',
      noteReason: null
    }

    return columnsTitles[column];
  }

  public getTextNode(element: TransactionReviewModel, column: string): string {
    switch (column) {
      case 'paymentDate':
      case 'loggedOnDate':
      case 'transactionDate':
        return element[column] ? moment.utc(element[column]).format('L') : '';
      case 'amount':
        const amount: number = +(element[column]);
        return element[column] ? `$ ${amount.toFixed(2)}` : '';
      case 'status':
        return this.translate.instant(STATUS[element[column]]);
      case 'declineReason':
        return element[column] ? this.translate.instant(element[column]) : '';
      case 'noteReason':
        return element['reason']
      default:
        return element[column]

    }
  }
  public checkColumn(column: string): boolean {
    return this.filterSortConfig.sortField === column;
  }

  public getSortOrder(column: string): SortOrder {
    return this.filterSortConfig.sortField === column ? this.filterSortConfig.sortOrder : null;
  }

 public goToDonationPage(id: string) {
    if(id) this.router.navigateByUrl(`/clients/contributions/${id}`)
 }
  public getStatusCssClass(element: TransactionReviewModel): string {
    switch (element.status) {
      case PaymentTransactionReviewStatus.Completed:
        return 'status-indicator green';
      case PaymentTransactionReviewStatus.Cancelled:
        return 'status-indicator red';
      case PaymentTransactionReviewStatus.Failed:
        return 'status-indicator red';
      case PaymentTransactionReviewStatus.Scheduled:
        return 'status-indicator black';
      case PaymentTransactionReviewStatus.Rescheduled:
        return 'status-indicator light-blue';
      case PaymentTransactionReviewStatus.Pledged:
        return 'status-indicator yellow';
    }
  }
  public isScheduledAndPassed(element: TransactionReviewModel):boolean {
    if(!element?.id || element.id === ZEROEVENT) return false;
    const paymentDate = new Date(element.paymentDate).getTime();
    const currentDate = new Date().getTime();
    return ((element.status === PaymentTransactionReviewStatus.Scheduled && paymentDate < currentDate && !element.reason && !this.isDonorPortal))
  }

  public shouldDisplaySkipBtn(index: number): boolean {
    return !this.lastSkippedDonation?.length && this.firstScheduledTransactionIndex === index;
  }
}
