<div class="client-templates-wrapper">
  <div class="client-templates-header-row">
    <span class="client-templates-title">Organization Templates</span>
    <div class="client-templates-select-wrapper">
      <app-search-select
        [formGroup]="clientForm"
        name="client"
        [selectOptions]="clientsOptions"
        placeholder="Choose Organization"
        (valueChanged)="getData()"
      ></app-search-select>
    </div>
  </div>

  <div
    *ngIf="client && client.value"
    class="client-templates-list"
  >
    <mat-tab-group
      [selectedIndex]="currentTab"
      (selectedIndexChange)="currentTab = $event"
      mat-align-tabs="start"
      dynamicHeight
    >
      <mat-tab label="Event">
        <div class="client-template-inner-wrapper">
          <div
            *ngFor="let template of (eventTemplates$ | async); index as i"
            class="template-card"
            (click)="navigateTo(template)"
          >
            <div class="image-container">
              <img
                [src]="getTemplateImgSrc(template.imagePreviewURL)"
                alt="Preview"
              >
              <!--<ng-template #iframeTemplate>
                <div class="mock-img-layout"></div>
                <iframe
                  [width]="getTemplateWidth(template)"
                  [height]="getTemplateWidth(template)"
                  frameborder="0"
                  [id]="'event_' + i"
                  [style.transform]="getScaleRate(template)"
                  scrolling="no"
                >{{setContent(template, 'event_' + i)}}</iframe>
              </ng-template>-->
            </div>
            <h2 *ngIf="isNewTemplate(template)" class="client-template-new-marker">New</h2>
            <h2 class="template-card-title">{{template.name}}</h2>
            <p class="template-card-body-text">{{template.description}}</p>
          </div>
        </div>
        <app-paginator
          [entriesPerPageSettings]="entriesPerPageSettings"
          [totalItems]="eventTemplatesTotal"
          [setDefaultPage]="setEventDefaultPage$"
          [entriesPerPage]="eventTemplatesEntriesPerPage"
          (entriesPerPageChanged)="eventTemplatesEntriesPerPageChanged($event)"
          (nextPage)="eventTemplatesNextPageChanged($event)"
        ></app-paginator>
      </mat-tab>
      <mat-tab label="Campaign">
        <div class="client-template-inner-wrapper">

          <div
            *ngFor="let template of (campaignTemplates$ | async); index as i"
            class="template-card"
            (click)="navigateTo(template)"
          >
            <div class="image-container">
              <img
                [src]="getTemplateImgSrc(template.imagePreviewURL)"
                alt="Preview"
              >
              <!--<ng-template #iframeTemplate>
                <div class="mock-img-layout"></div>
                <iframe
                  [width]="getTemplateWidth(template)"
                  [height]="getTemplateWidth(template)"
                  frameborder="0"
                  [id]="'campaign_' + i"
                  [style.transform]="getScaleRate(template)"
                  scrolling="no"
                >{{setContent(template, 'campaign_' + i)}}</iframe>
              </ng-template>-->
            </div>
            <h2 *ngIf="isNewTemplate(template)" class="client-template-new-marker">New</h2>
            <h2 class="template-card-title">{{template.name}}</h2>
            <p class="template-card-body-text">{{template.description}}</p>
          </div>
        </div>
        <app-paginator
          [entriesPerPageSettings]="entriesPerPageSettings"
          [totalItems]="campaignTemplatesTotal"
          [setDefaultPage]="setCampaignDefaultPage$"
          [entriesPerPage]="campaignTemplatesEntriesPerPage"
          (entriesPerPageChanged)="campaignTemplatesEntriesPerPageChanged($event)"
          (nextPage)="campaignTemplatesNextPageChanged($event)"
        ></app-paginator>
      </mat-tab>
      <mat-tab label="Social Media">
        <div class="client-template-inner-wrapper">
          <div
            *ngFor="let template of (socialMediaTemplates$ | async); index as i"
            class="template-card"
            (click)="navigateTo(template)"
          >
            <div class="image-container">
              <img [src]="getTemplateImgSrc(template.imagePreviewURL)" alt="Preview">
            </div>
            <h2 *ngIf="isNewTemplate(template)" class="client-template-new-marker">New</h2>
            <h2 class="template-card-title">{{template.name}}</h2>
            <p class="template-card-body-text">{{template.description}}</p>
          </div>
        </div>
        <app-paginator
          [entriesPerPageSettings]="entriesPerPageSettings"
          [totalItems]="socialMediaTemplatesTotal"
          [setDefaultPage]="setSocialMediaDefaultPage$"
          [entriesPerPage]="socialMediaTemplatesEntriesPerPage"
          (entriesPerPageChanged)="socialMediaTemplatesEntriesPerPageChanged($event)"
          (nextPage)="socialMediaTemplatesNextPageChanged($event)"
        ></app-paginator>
      </mat-tab>
      <mat-tab label="Donation Forms">
        <div class="client-template-inner-wrapper">
          <div *ngFor="let template of (donationFormsTemplates$ | async); index as i" class="template-card" (click)="navigateTo(template)">
            <div class="image-container">
              <img [src]="getTemplateImgSrc(template.imagePreviewURL)" alt="Preview">
            </div>
            <h2 *ngIf="isNewTemplate(template)" class="client-template-new-marker">New</h2>
            <h2 class="template-card-title">{{template.name}}</h2>
            <p class="template-card-body-text">{{template.description}}</p>
          </div>
        </div>
        <app-paginator [entriesPerPageSettings]="entriesPerPageSettings" [totalItems]="donationFormsTemplatesTotal" [setDefaultPage]="setDonationFormsDefaultPage$" [entriesPerPage]="donationFormsTemplatesEntriesPerPage" (entriesPerPageChanged)="donationFormsTemplatesEntriesPerPageChanged($event)"
          (nextPage)="donationFormsTemplatesNextPageChanged($event)"></app-paginator>
      </mat-tab>
      <mat-tab label="Letters">
        <div class="client-template-inner-wrapper">
          <div *ngFor="let template of (letterTemplates$ | async); index as i" class="template-card" (click)="navigateTo(template)">
            <div class="image-container">
              <img [src]="getTemplateImgSrc(template.imagePreviewURL)" alt="Preview">
            </div>
            <h2 *ngIf="isNewTemplate(template)" class="client-template-new-marker">New</h2>
            <h2 class="template-card-title">{{template.name}}</h2>
            <p class="template-card-body-text">{{template.description}}</p>
          </div>
        </div>
        <app-paginator [entriesPerPageSettings]="entriesPerPageSettings" [totalItems]="letterTemplatesTotal" [setDefaultPage]="setLettersDefaultPage$" [entriesPerPage]="letterTemplatesEntriesPerPage" (entriesPerPageChanged)="letterTemplatesEntriesPerPageChanged($event)"
          (nextPage)="letterTemplatesNextPageChanged($event)"></app-paginator>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
