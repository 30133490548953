import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from '../base.model.service';
import { ClientRelationshipModel } from '../../models/client/client.relationship.model';

@Injectable({
  providedIn: 'root'
})
export class ClientRelationshipService extends BaseModelService<ClientRelationshipModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'ClientRelationship';
  }
}
