<div class="template-preview-wrapper">
  <div class="template-preview-header">
    <span>{{'Preview' | translate}}</span>
    <i class="fas fa-times" (click)="close()"></i>
  </div>
  <div class="template-preview-content">
    <mat-tab-group
      *ngIf="!data.previewFile"
      mat-align-tabs="center"
      dynamicHeight
      [selectedIndex]="currentTab"
      (selectedIndexChange)="onTabChanged($event)"
      animationDuration="0ms"
    >
      <mat-tab label="{{'Desktop' | translate}}">
        <div class="desktop-preview">
          <div class="desktop-header"></div>
          <iframe
            *ngIf="data.templateAssignmentType === TemplateAssignmentType.Campaign"
            width="1248"
            height="700"
            frameborder="0"
            #iframeDesktopRef
          ></iframe>

          <app-mjml-renderer
            class="desktop-preview-event"
            *ngIf="data.templateAssignmentType === TemplateAssignmentType.Event"
            [eventId]="data.eventId"
            [mjml]="data.mjml"
            [screenshots]="true"
            [showHeaderAndFooter]="true"
          ></app-mjml-renderer>
        </div>
      </mat-tab>
      <mat-tab label="{{'Mobile' | translate}}">
        <div class="mobile-preview">
          <div class="mobile-preview-iframe">
            <iframe
              *ngIf="data.templateAssignmentType === TemplateAssignmentType.Campaign"
              width="320"
              height="569"
              frameborder="0"
              #iframeMobileRef
            ></iframe>
          </div>
          <app-mjml-renderer
            class="mobile-preview-event"
            *ngIf="data.templateAssignmentType === TemplateAssignmentType.Event"
            [eventId]="data.eventId"
            [mjml]="data.mjml"
            [screenshots]="true"
            [showHeaderAndFooter]="true"
          ></app-mjml-renderer>
        </div>
      </mat-tab>
    </mat-tab-group>

    <div *ngIf="data.previewFile && !!src" class="print-preview">
      <iframe #iframePrintRef
        [src]="src"
        frameborder="0"
        height="100%"
        class="print-preview_iframe"
      ></iframe>
    </div>
  </div>
</div>
