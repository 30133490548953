import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from '../base.model.service';
import CampaignSegmentModel from '../../models/campaigns/campaign.segment.model';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CampaignSegmentService extends BaseModelService<
  CampaignSegmentModel
> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'CampaignSegment';
  }

  public addSegmentNew(
    model: CampaignSegmentModel,
    campaignId: string
  ): Observable<CampaignSegmentModel> {
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        const url = `${config.utilsURL}/${this.controllerName}/insert/?campaignId=${campaignId}`;
        return this.http.post<CampaignSegmentModel>(url, model);
      })
    );
  }

  public updateSegmentNew(
    model: CampaignSegmentModel,
    campaignId?: string
  ): Observable<CampaignSegmentModel> {
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        const url = campaignId
          ? `${config.utilsURL}/${this.controllerName}/update/?campaignId=${campaignId}`
          : `${config.utilsURL}/${this.controllerName}/update`;
        return this.http.put<CampaignSegmentModel>(url, model);
      })
    );
  }

  public unsubscribeRecipient(
    clientId: string,
    clientDonorId: string,
    isAlt: string
  ): Observable<any> {
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        const url =
          `${config.apiURL}/${this.controllerName}/unsubscribe?clientID=` +
          clientId +
          `&` +
          `clientDonorID=` +
          clientDonorId +
          `&` +
          `isAlt=` +
          (isAlt ?? "false");
        return this.http.get(url);
      })
    );
  }

  public deleteSegment(
    model: CampaignSegmentModel
  ): Observable<CampaignSegmentModel> {
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        const url = `${config.utilsURL}/${this.controllerName}/DeleteSegment`;
        return this.http.post<CampaignSegmentModel>(url, model);
      })
    );
  }
}
