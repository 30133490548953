import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private load: Subject<boolean> = new Subject<boolean>();
  public loader: Observable<boolean> = this.load.asObservable();

  public startRequest(): void {
    this.load.next(true);
  }

  public endRequest(): void {
    this.load.next(false);
  }
}
