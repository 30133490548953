import FormElementDataModel from '../form.element.data.model';
import {EventDonationDisclaimerModel} from "../event/event-donation-disclaimer.model";

export default interface FbTemplateModel {
  infoPage: FbPageModel;
  paymentPage: FbPageModel;
  topImage: FbElementModel;
  headerFooter: FbElementModel;

  width: number;
  pageColor: string;
  formColor: string;
  fontColor: string;
  formBgImg: string;
  backgroundRepeat: string;
  backgroundSize: string;
  inputBackground: string;
  spacing: number;
  fontFamily: string;
  fontSize: number;

  nextButtonLabel: string;
  donateButtonLabel: string;
  primaryButtonColor: string;
  primaryButtonColorChanged: boolean;
  primaryTextButtonColor: string;
  secondaryButtonColor: string;
  secondaryButtonColorChanged: boolean;
  secondaryTextButtonColor: string;
  borderCheckboxColor: string;
  donationBubbleDefaultColor: string;

  thankYouPageHtml: string;
  isCustomThankYouPage?: boolean;
  customThankYouPageUrl?: string;
}

export interface FbPageModel {
  attributes: {[key: string]: string};
  elements: FbElementModel[];
}

export interface FbElementModel {
  type: FbElementType;
  attributes: {[key: string]: string};
  required: boolean;
  federalIDRequired?: boolean;
  text?: string;
  amountToDonateText?: string;
  isAnonymous?: boolean;
  isRecurring?: boolean;
  allowWeekly?: boolean,
  allowMonthly?: boolean,
  allowYearly?: boolean,
  isInSupportOf?: boolean;
  isRecurringWithEndDate?: boolean;
  isPledgeType?: boolean;
  isHonorGift?: boolean;
  isHonorGiftWithSendEmail?: boolean;
  isDonationCost?: boolean;
  donationAmounts?: string[];
  allowOwnAmount?: boolean;
  funds?: string;
  typesOfDonations?: number;
  tiersOptions?: string;
  customDisclaimers?:EventDonationDisclaimerModel[];
  disclaimerCheckBoxText?: string;
}

/*export interface FbDonationInfoPageModel {
  text: string;
  isAnonymous: boolean;
  isRecurring: boolean;
  isRecurringWithEndDate: boolean;
  isHonorGift: boolean;
  isHonorGiftWithSendEmail: boolean;
  isDonationCost: boolean;
  donationAmounts: number[];
  allowOwnAmount: boolean;
}*/

export enum FbElementType {
  Header = 1,
  FullName = 2,
  Email = 3,
  Address = 4,
  Phone = 5,
  PaymentDetails = 6,
  Comments = 7,
  DonationInfo = 8,
  TopImage = 9,
  HeaderFooter = 10,
  PAC = 11,
  Occupation = 12,
  EmployerName = 13,
  EmployerMailingAddress = 14


}

export enum AlignmentType {
  Left = 'left',
  Right = 'right',
  Top = 'top',
}

export enum AvailablePaymentMethods {
  All = 'all',
  CreditCard = 'creditCard',
  ACH = 'ach'
}

export enum OptionsViewType {
  None = 1,
  Element = 2,
  Common = 3,
}

export enum HeaderSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum PassMerchantFee {
  Yes = 'yes',
  No = 'no'
}

export enum FbTemplateStatusType {
  Active = 0,
  Inactive = 1,
  DisableOnDate = 2,
}
