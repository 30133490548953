import { BaseModelService } from '../base.model.service';
import { Injectable } from '@angular/core';
import { EventLayoutModel } from 'src/app/models/event/event-layout.model';

@Injectable({
  providedIn: 'root'
})
export class EventLayoutService extends BaseModelService<EventLayoutModel> {
  protected get controllerName(): string {
    return 'Layout';
  }
}