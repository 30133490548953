<div class="edit-popup-wrapper">
  <div class="edit-popup-header">
    <span>{{title$ | async | translate}}</span>
    <app-cross-icon (click)="onClose()"></app-cross-icon>
  </div>

  <div class="edit-popup-main">
    <app-textarea
      [formGroup]="form"
      name="comment"
      label="Comment *"
      errorMassage="Comment"
      minRows="3"
      maxRows="4"
    ></app-textarea>
  </div>

  <div class="event-popup-actions">
    <app-button
      type="flat"
      label="Cancel"
      color="#F44336"
      (click)="onClose()"
      width="211"
    >
    </app-button>

    <app-button
      [disabled]="form.invalid || !form.dirty"
      type="flat"
      color="#0064be"
      label="Save"
      (clickEvent)="save()"
      width="211"
    >
    </app-button>
  </div>

</div>
