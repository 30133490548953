import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { SignUpSettingsService } from 'src/app/services/templates/signup.settings.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from '../../services/client/client.service';
import {ClientModel} from '../../models/client/client.model';

@Component({
  selector: 'app-signup-settings',
  templateUrl: './signup-settings.component.html',
  styleUrls: ['./signup-settings.component.scss']
})
export class SignUpSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('stepper', { static: false }) private stepper: MatStepper;

  public clientId: string;
  public config: any = null;
  public wasEmailSettingsOpened: boolean = false;
  private subscription: Subscription = new Subscription();
  public loadingData: boolean = false;
  public formValid: boolean = true;
  private existingConfigId: string | null = null;
  public signUpConfig: any;
  public defaultEmail: string;
  
  private existingConfig: any = {
    name: 'Sign Up Settings',
    customTitle: '<div style="text-align: center;"><strong style="font-size: 30px; color: #212529; font-weight: bold;">Sign Up Today</strong></div>',
    labelFontFamilyName: 'Roboto',
    labelFontSize: '16px',
    labelFontColor: '#2C3345',
    inputFontFamilyName: 'Roboto',
    inputFontSize: '16px',
    inputFontColor: '#000000',
    firstNameValue: 'First Name',
    lastNameValue: 'Last Name',
    tagLabel: 'Tag',
    emailValue: 'Email',
    phoneFieldRequired: true,
    phoneNumberValue: 'Phone',
    buttonFontFamilyName: 'Roboto',
    buttonFontSize: '16px',
    buttonFontColor: '#ffffff',
    buttonBackgroundColor: '#0064be',
    buttonValue: 'SUBMIT',
    showLogo: true,
    showPhoneNumber: true,
    logoPosition: 'left',
    logosize: '44px',
    showCustomOptions: false,
    showMailingAddressFields: false,
    areMailingAddressFieldsRequired: false,
    country: 'Country',
    address: 'Address',
    state: 'State',
    city: 'City',
    zipCode: 'Zip Code',
    layoutStyle: 'vertical',
    layoutColumns: '2',
    confirmationMailBody: "<div>Dear [DonorName],</div><div><br></div><div>Thank you for signing up to connect with [OrganizationName]! We're thrilled to have you join our community.</div><div><br></div><div>Your interest means a lot to us, and we're excited to explore how we can collaborate and make a meaningful impact together. Whether you're looking to stay updated, get involved, or learn more about what we do, we're here to support you every step of the way.</div><div><br></div><div>Feel free to reach out if you have any questions or need more information—we'd love to hear from you.</div><div><br></div><div>[PrimaryContact]</div><div>[OrganizationName]</div>",
    confirmationMailFromAddress: '', 
    confirmationMailFromSubject: "Welcome! Thank You for Connecting with Us",
    sendConfirmationEmail: false,
    confirmationScreen: `<div style='text-align: center;'><span style='font-size: 20px;'>Thank you for connecting with us! We're thrilled to have you join our community.</span></div>`,
  };

  constructor(
    private signUpSettingsService: SignUpSettingsService, 
    private routes: ActivatedRoute,
    private clientService: ClientService,
    private toastr: ToastrService
  ) {}
  
  ngOnInit(): void {
    this.clientId = this.routes.snapshot.params.clientID;
    this.checkExistingConfiguration();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private checkExistingConfiguration() {
    this.loadingData = true;
    this.signUpSettingsService.getConfigurationByClientId(this.clientId).subscribe(
      (config) => {
        if (config) {
          this.existingConfigId = config.id;
          this.existingConfig = {...this.existingConfig, ...config};
          this.config = config;
          this.loadingData = false;
        } else {
          this.subscription.add(
            this.clientService.getModel(this.clientId).subscribe(async (clientModelList: ClientModel) => {
              if (clientModelList) {
                this.defaultEmail = clientModelList.sendFromEmail;
                if(!this.config) {
                  this.existingConfig.confirmationMailFromAddress = this.defaultEmail;
                }
                this.loadingData = false;
              }
            })
          );
        }
      },
      (error) => {
        this.loadingData = false;
        console.error('Error fetching configuration', error);
        this.toastr.warning('Error fetching configuration');
      }
    );
  }

  onSave() {
    if(this.formValid === false) {
      this.toastr.error('Some fields are missing or contain invalid information. Please check and correct all required fields.');
      return false;
    }
    this.loadingData = true;
    if (this.existingConfigId) {

      const updateData = { id: this.existingConfigId, ...this.existingConfig };
      this.signUpSettingsService.updateSignUpConfiguration(updateData).subscribe(
        (response) => {
          this.loadingData = false;
          this.existingConfig = response;
          this.config = response;
          this.toastr.success('Configuration updated successfully');
        },
        (error) => {
          this.loadingData = false;
          this.toastr.error('Error updating configuration');
        }
      );
    } else {
      this.signUpSettingsService.submitSignUpConfiguration(this.existingConfig).subscribe(
        (response) => {
          this.loadingData = false;
          this.existingConfigId = response.id;
          this.existingConfig = response;
          this.config = response;
          this.toastr.success('Configuration updated successfully');
        },
        (error) => {
          this.loadingData = false;
          this.toastr.error('Error updating configuration');
        }
      );
    }
  }

  public updateFormData(formData: any, isValid: boolean) {
    
    this.formValid = isValid;

    let signUpConfig = {
      clientID: this.clientId,
      ...this.existingConfig, 
      ...formData
    };

    this.existingConfig = signUpConfig; 
  }

  public get activeStep(): boolean {
    return this.stepper && this.stepper.selectedIndex === 2;
  }

  public handleSelectionChange(step) {
    if (step.selectedIndex === 1 && !this.wasEmailSettingsOpened) {
      this.wasEmailSettingsOpened = true;
    }
  }
}
