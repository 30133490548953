<div class="button-tool-wrapper" [ngStyle]="getContainerStyles()">

  <div *ngIf="!landingPageView || (landingPageView && !href); else landingPageTemplate" class="button-tool-body" [ngStyle]="getStyles()">
    <span>{{entity?.content | translate}}</span>
  </div>


</div>

<ng-template #landingPageTemplate>
  <a [href]="href">
    <div class="button-tool-body" [ngStyle]="getStyles()">
      <span>{{entity?.content | translate}}</span>
    </div>
  </a>
</ng-template>
