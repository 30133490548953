import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from '../base.model.service';
import SecondaryEventParticipantModel from 'src/app/models/event/event-secondary-participant.model';


@Injectable({
  providedIn: 'root'
})
export class SecondaryEventParticipantService extends BaseModelService<SecondaryEventParticipantModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }
  protected get controllerName(): string {
    return 'EventSecondaryParticipant';
  }
}
