import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import FormElementDataModel from 'src/app/models/form.element.data.model';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { ButtonToggleModel } from '../../models/buttonToggleModel';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialogComponent } from '../comment-dialog/comment-dialog.component';
import ColumnFilterModel from '../../models/filter-sort/column.filter.model';

@Component({
  selector: 'app-filtered-listbox',
  templateUrl: './filtered-listbox.component.html',
  styleUrls: ['./filtered-listbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilteredListboxComponent implements OnChanges {
  @Input()
  public items: FormElementDataModel[];
  @Input()
  public columnName: string = 'name';
  @Input()
  public isFilterButtonsVisible: boolean;
  @Input()
  public multiselectable: boolean = false;
  @Input()
  public toggleOptions: Array<ButtonToggleModel> = [];
  @Input()
  public isCustomValueAddingAllow: boolean = true;
  @Input()
  public buttonEnablingValues: string[] = [];
  public canActivate: boolean = false;
  @Input()
  public modalLabel: string;
  @Input() public initialSelection: any;
  @Input() public currentSelection: ColumnFilterModel;
  @Input() enableTranslation: boolean;
  public isDataLoading: boolean = false;

  public searchText: FormControl = new FormControl('');
  public filtered: FormElementDataModel[] = [];

  public multiSelection: SelectionModel<FormElementDataModel> = new SelectionModel<FormElementDataModel>(true, []);
  public singleSelection: SelectionModel<FormElementDataModel> = new SelectionModel<FormElementDataModel>(false, []);

  @Output()
  public selectedValue: EventEmitter<FormElementDataModel> = new EventEmitter<FormElementDataModel>();
  @Output()
  public multiSelectedValue: EventEmitter<FormElementDataModel[]> = new EventEmitter<FormElementDataModel[]>();
  @Output()
  public changeFilterType: EventEmitter<MatButtonToggleChange> = new EventEmitter<MatButtonToggleChange>();

  constructor(private dialog: MatDialog, protected ref: ChangeDetectorRef) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.filtered = changes.items.currentValue;
      if (this.currentSelection) {
        this.multiSelection.clear(); //for some reason filter in registration in my-fundriaser is not clearing filters
        this.currentSelection.values.forEach(option => {
          const initialItem = this.filtered.find(({value}: FormElementDataModel) => value === option);
          if (initialItem) {
            this.toggleItem(initialItem);
          }
        })
      }

      if (this.initialSelection) {
        let len = this.initialSelection.length;
        let typeOfObject = typeof this.initialSelection == "object";
        if ((len > 1) && typeOfObject) { // type == object so for string it wont go in cuz of len > 1
          this.isDataLoading = true;
          for(let i=0; i<len; i++) {
            let initialItemMany = this.filtered.find(({value}: FormElementDataModel) => value === this.initialSelection[i]);
            if (initialItemMany) {
              this.toggleItem(initialItemMany);
              this.isDataLoading = false;
            }
          }
        } else {
          this.isDataLoading = true;
          let initialItem = this.filtered.find(({value}: FormElementDataModel) => value === this.initialSelection);
          if (initialItem) {
            this.toggleItem(initialItem);
          }
          this.isDataLoading = false
        }
      }
    }

    if (changes.initialSelection && changes.initialSelection.currentValue && !changes.initialSelection.firstChange && this.filtered.length) {
      const initialItem = this.filtered.find(({value}: FormElementDataModel) => value === this.initialSelection);
      if (initialItem) {
        this.multiSelection.clear();
        this.toggleItem(initialItem);
      }
    }
  }

  public selectItem(item: FormElementDataModel): void {
    this.singleSelection.select(item);
    this.selectedValue.emit(item);
  }

  public get isCanActivate(): boolean {
    // return this.canActivate && this.searchText.value !== '';
    return this.canActivate;
  }

  public get isVisible(): boolean {
    return this.canActivate && this.isCustomValueAddingAllow;
  }

  public search(): void {
    this.filtered.forEach(
      (item: FormElementDataModel) => item.hidden = !item.label?.toLowerCase().includes(this.searchText.value.toLowerCase())
    );
  }

  public searchLetter(letter: string): void {
    this.searchText.setValue(letter);
    this.filtered.forEach(
      (item: FormElementDataModel) => item.hidden = !item.label.toLowerCase().startsWith(letter.toLowerCase())
    );
  }

  public clearAll(): void {
    this.searchText.setValue('');
    this.multiSelection.clear();
    this.filtered.forEach(
      (item: FormElementDataModel) => item.hidden = !item.label?.toLowerCase().startsWith('')
    );
    this.multiSelectedValue.emit([]);
  }

  public applyFilters(): void {
    this.multiSelectedValue.emit(this.multiSelection.selected);
  }

  public toggleItem(item: FormElementDataModel): void {
    this.multiSelection.toggle(item);
  }

  public get isPhones(): boolean {
    return this.columnName === 'primaryPhone';
  }

  public onChangeFilterType($event): void {
    const flag = this.buttonEnablingValues.find(e => e === $event.toString());
    this.canActivate = !!flag;
    this.changeFilterType.emit($event);
  };

  private setCustomValue(customSource: string): void {
    const newSelect: FormElementDataModel = {
      hasDonationAsk: false,
      hidden: false,
      isCampaign: false,
      isEvent: false,
      isCustomSource: true,
      label: customSource,
      value: customSource
    };
    this.selectItem(newSelect);
  }

  public openModal() {
    const config = {
      data: {
        title: this.modalLabel
      }
    };
    const dialogRef = this.dialog.open(CommentDialogComponent, config);
    dialogRef.afterClosed().subscribe(content => {
      if (content) {
        this.setCustomValue(content);
      }
    });
  }
}
