<form [formGroup]="settingsForm" class="mt-3 signup-email-confirm">
  <div class="fb-page-form-row">
    <div class="max-width-810">
      <app-radio
        [formGroup]="settingsForm"
        [selectOptions]="yesNoOptions"
        name="sendConfirmationEmail"
        [ngStyle]="{'padding-left': '5px'}"
        label="Send Confirmation Email?"
        (radioValueChanged)="onRadioChange($event, 'sendConfirmationEmail')"
        [isNew]="true"
      ></app-radio>
    </div>
  </div>
  <div class="fb-page-form-row mt-3">
    <div class="max-width-810">
      <app-input
        [formGroup]="settingsForm"
        errorMassage="From Email"
        name="notifyEmails"
        label="From Address"
      ></app-input>
    </div>
  </div>

  <div class="fb-page-form-row mt-3">
    <div class="max-width-810">
      <app-input
        [formGroup]="settingsForm"
        name="subjectEmailNotification"
        errorMassage="Subject"
        label="Subject"
      ></app-input>
    </div>
  </div>
  <div class="fb-page-form-row mt-3">
    <div class="max-width-810">
      <app-email-list-textarea
      [formGroup]="settingsForm"
      name="bccEmail"
      errorMassage="BCC Email"
      label="BCC"
    ></app-email-list-textarea>
    </div>
  </div>

  <div class="fb-page-form-row mt-3" *ngIf="formLoaded">
    <div class="max-width-810">
      <app-text-editor
        [formGroup]="settingsForm"
        name="emailNotification"
        errorMassage="Email"
        label="Email"
        [placeholders]="placeholders"
      ></app-text-editor>
    </div>
  </div>
  <div
    style="margin-top: 10px"
  >
    <app-button
      label="RESET EMAIL BODY"
      (clickEvent)="resetEmailBody()"
      type="stroked"
      width="fit-content"
      [isNew]="true"
    ></app-button>
  </div>
</form>
