import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { CommentWallService } from './comment-wall.service';
import { TemplateAssignmentType } from '../../../../models/templates/template.assignment.type';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { EventCommentWallModel } from '../../../../models/event/event.comment.wall.model';
import { BasePagingComponent } from '../../../paginator/base.paging.component';
import {first, startWith, switchMap, tap} from 'rxjs/operators';
import { UtilsComponent } from '../../../utils.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CommentWallAddNewComponent} from './comment-wall-add-new/comment-wall-add-new.component';
import {CommentWallPopupDataModel} from '../../../../models/templates/comment.wall.popup.data.model';
import {CommentWallAllComponent} from './comment-wall-all/comment-wall-all.component';

@Component({
  selector: 'app-comment-wall',
  templateUrl: './comment-wall.component.html',
  styleUrls: ['./comment-wall.component.scss', '../donation-activity/donation-activity.component.scss'],
  providers: [CommentWallService]
})
export class CommentWallComponent extends BasePagingComponent implements OnInit, OnDestroy {
  @Input() public column: MjmlElementModel;
  @Input() public entity: MjmlElementModel;
  @Input() public tms: TemplateManagementService;
  @Input() public landingPageView: boolean = false;
  @Input() public eventID: string;
  @Input() public screenshots: boolean = false;

  public TemplateAssignmentType = TemplateAssignmentType;
  public comments$: Observable<EventCommentWallModel[]>;
  public total$: Observable<number>;
  public pagingSource$: Subject<void> = new Subject<void>();
  private subscriptions: Subscription = new Subscription();

  constructor(
    public commentWallService: CommentWallService,
    private dialog: MatDialog,
  ) {
    super();
  }

  public ngOnInit(): void {
    const isMockData = this.screenshots || !this.landingPageView;
    this.comments$ = this.pagingSource$.asObservable()
      .pipe(
        startWith(''),
        switchMap(() => {
          if (this.isDisplayCountAll) {
            return this.commentWallService.getAllComments(isMockData, this.eventID);
          } else {
            return this.commentWallService.getComments(
              isMockData,
              this.eventID,
              UtilsComponent.getFirstItemOnPage(this.total, this.currentPage, this.entriesPerPage),
              this.entriesPerPage
            );
          }
        })
      );

    this.total$ = this.commentWallService.getTotal(isMockData, this.eventID)
      .pipe(
        tap((total: number) => this.total = total)
      );

    this.subscriptions.add(
      this.tms.commentWallDisplayCountEmitter
        .subscribe(() => this.setDefaultEntriesPerPage())
    );
    this.setDefaultEntriesPerPage();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public get containerStyles(): {[key: string]: string} {
    const attributes = this.entity.attributes;
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    return {
      'padding-bottom': paddingBottom,
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      'padding-top': paddingTop
    };
  }

  public get isDisplayCountAll(): boolean {
    return this.entity.attributes['display-count'] === '0';
  }

  public addNew(): void {
    if (!this.landingPageView) {
      return;
    }
    this.dialog.open(CommentWallAddNewComponent, this.matDialogConfig).afterClosed()
      .pipe(
        first(),
        tap(response => {
          if (response === 'refreshComments') {
            this.pagingSource$.next();
            this.total$ = of(++this.total);
          }
        })
      )
      .subscribe();
  }

  public viewAll(): void {
    if (!this.landingPageView) {
     return;
   }
    this.dialog.open(CommentWallAllComponent, this.matDialogConfig).afterClosed()
      .pipe(
        first(),
        tap(response => {
          if (response === 'addNew') {
            this.addNew();
          }
        })
      )
      .subscribe();
  }

  private get matDialogConfig(): MatDialogConfig {
    const data: CommentWallPopupDataModel = {
      eventID: this.eventID,
      entity: this.entity,
      commentWallService: this.commentWallService
    };
    return {
      data,
      width: '100%',
      maxWidth: '500px',
      maxHeight: '95vh'
    };
  }

  public getNextPage(page: number): void {
    this.nextPage(page, () => this.pagingSource$.next());
  }

  public setEntriesPerPage(amount: number): void {
    this.onFilterChanged();
    this.entriesPerPageChanged(amount, () => this.pagingSource$.next());
  }

  private setDefaultEntriesPerPage(): void {
    const displayCount = this.entity.attributes['display-count'];
    if (displayCount && displayCount !== '0') {
      this.setEntriesPerPage(+displayCount);
    }
  }

}
