<div
  class="fb-input-wrapper"
  [formGroup]="formGroup"
  #divElement
  (click)="!disabled && datepicker.open()"
>
  <mat-datepicker
    #datepicker
    (opened)="onOpened()"
    (closed)="onClosed()"
    disabled="false"
  ></mat-datepicker>
  <div class="date-icon"></div>
  <mat-form-field appearance="outline">
    <input
      matInput
      [formControlName]="name"
      [id]="id"
      [matDatepicker]="datepicker"
      [autocomplete]="autocompleteOff ? 'autocompleteOff' : ''"
      [matDatepickerFilter]="dateFilter"
      [min]="minDate"
      [max]="maxDate"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      (dateChange)="onDateChange($event.value)"
      [value]="formControl.value"
    >
  </mat-form-field>
  <span class="bottom-label error-message" *ngIf="formControl.touched && formControl.invalid && !customError; else labelTemplate">{{getErrorMessage()}}</span>
</div>


<ng-template #labelTemplate>
  <span class="bottom-label" [ngStyle]="labelStyle">{{label | translate}}</span>
</ng-template>
