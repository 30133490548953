import { Component, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/routes/admin/admin-confirmation/admin-confirmation.component';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-update-value-dialog',
  templateUrl: './update-value-dialog.component.html',
  styleUrls: ['./update-value-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdateValueDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  public formControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(255)
  ]);

  constructor(
    public dialogRef: MatDialogRef<UpdateValueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.formControl.setValue(this.data.formElementData);
    this.subscriptions.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
   }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public get getError(): string {
    if (this.formControl.hasError('required')) return 'Reason is required';
    if (this.formControl.hasError('onlySpaces')) return `Reason can't contain only spaces`;
    if (this.formControl.hasError('maxlength')) return `Reason can't contain more than 255 characters`;
  }

  public onOKClick(): void {
    this.formControl.markAsTouched();
    if (this.formControl.valid) this.dialogRef.close(this.formControl.value);
  }
}
