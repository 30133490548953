import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { EventDonationActivityModel } from '../../../../models/event/event.donation.activity.model';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { TIME_RANGE } from '../../../../constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-donation-activity-element',
  templateUrl: './donation-activity-element.component.html',
  styleUrls: ['./donation-activity-element.component.scss']
})
export class DonationActivityElementComponent  {
  @Input() public donations$: Observable<EventDonationActivityModel[]>;
  @Input() public entity: MjmlElementModel;

  constructor(public translate: TranslateService) {}

  public get donationRowStyles(): {[key: string]: string} {
    const attributes = this.entity.attributes;
    const fontFamily = attributes['font-family'] || 'Roboto';
    const fontSize = attributes['font-size'] || '14px';
    return {
      'font-family': fontFamily,
      'font-size': fontSize,
    };
  }

  public get showDonationAmount(): boolean {
    const attributes = this.entity.attributes;
    return attributes['show-donation-amount'] ? JSON.parse(attributes['show-donation-amount']) : true;
  }

  public getTime(model: EventDonationActivityModel): string {
    if(!!model.createdTimeAgoString) return model.createdTimeAgoString;
    
    const value = Math.floor(model.createdTimeAgo);
    const timeRange = TIME_RANGE[model.createdTimeAgoType];
    if(value && timeRange) {
      const units = value > 1 ? this.translate.instant(timeRange.plural) : this.translate.instant(timeRange.singular);
      return `${value} ${units}`;
    } else return this.translate.instant("BUILD.a few seconds ago");

  }

}
