import { Component, Input } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent {
  @Input()
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  @Input()
  public barChartData: ChartDataSets[] = [];

  public barChartOptions: ChartOptions = {
    responsive: true,
    aspectRatio: 3,
    tooltips: {
      backgroundColor: '#f9fafc',
      titleFontSize: 14,
      titleFontColor: '#31394d',
      displayColors: false,
      cornerRadius: 3,
      bodyFontColor: '#31394d',
      bodyAlign: 'center',
      titleAlign: 'center',
      xPadding: 20
    },
    scales: {
      xAxes: [{
        ticks: {fontColor: '#748aa1'},
        gridLines: {display: false},
      }],
      yAxes: [
        {
          ticks: {fontColor: '#748aa1', min: 0},
          position: 'left',
          gridLines: {color: 'rgba(63, 63, 68, 0.05)', zeroLineColor: 'rgba(63, 63, 68, 0.05)'},
        }
      ]
    },
  };
  public barChartColors: Color[] = [
    {
      backgroundColor: '#4880ff',
      borderColor: '#4880ff',

    }
  ];

}
