import { BaseModelService } from '../base.model.service';
import { HonorModel } from 'src/app/models/client/honor.model';
import { Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClientHonorService extends BaseModelService<HonorModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'Honor';
  }
}