<!--Header-->
<app-event-header
  *ngIf="showHeaderAndFooter"
  [mjml]="mjml"
></app-event-header>

<!--Full container-->
<div
  class="mjml-renderer-wrapper"
  [ngStyle]="fullContainerStyle"
  #mjmlRendererContainer
>
  <!--Template body-->
  <div
    class="template-container"
    [ngStyle]="templateContainerStyle"
  >
    <!--Template Row-->
    <div
      *ngFor="let section of sections; index as sectionIndex"
      class="template-section"
      [class.mobile-screen]="isMobileScreen | async"
      [ngStyle]="getRowStyles(section)"
    >
      <!--Template Column-->
      <div
        *ngFor="let column of section.children"
        class="template-column"
        [class.mobile-screen]="isMobileScreen | async"
        [ngStyle]="getColumnStyles(column)"
      >
        <!--Template Element-->
        <app-tools-renderer
          [column]="column"
          [tms]="tms"
          [eventId]="eventId"
          [fundraiserId]="fundraiserId"
          [landingPageView]="true"
          [screenshots]="screenshots"
        ></app-tools-renderer>
      </div>
    </div>
  </div>
</div>

<!-- Footer -->
<app-footer
  *ngIf="showHeaderAndFooter"
  [mjml]="mjml"
></app-footer>
