<div class="widget-simple-wrapper">
  <div class="widget-simple-column">
    <div class="widget-simple-title-wrapper">
      <span class="widget-simple-title">{{'DASH.YTD Donations' | translate | uppercase}}</span>
      <span class="widget-simple-sum" *ngIf="summaryDonations !== null; else summaryDonationsShimmerTemplate">&#36;{{currentYearDonations | number:'.2'}}</span>
    </div>
    <div *ngIf="currentYearDonations !== null; else lineShimmerTemplate" class="widget-simple-changes export-link" (click)="exportReport()">
      <div class="widget-simple-triangle-mock"></div>
      <!--<span class="widget-simple-percentage widget-simple-down">{{fiscalYearChanges}}&#37;</span>
      <span class="widget-simple-period">YTD donations ( &#36;{{previousYearDonations | number:'.2'}} )</span>-->
    </div>
    <div *ngIf="previousYearDonations !== null; else lineShimmerTemplate" class="widget-simple-changes">
      <div [ngClass]="differenceBetweenCurrentAndPreviousYear > 0 ? 'widget-simple-triangle-up' : 'widget-simple-triangle-down'"></div>
      <span [ngClass]="differenceBetweenCurrentAndPreviousYear > 0 ? 'widget-simple-percentage widget-simple-up' : 'widget-simple-percentage widget-simple-down'">{{previousYearPercentage}}&#37;</span>
      <span class="widget-simple-period">{{'DASH.' + previousYearChanges | translate}} ( &#36;{{previousYearDonations | number:'.2'}} )</span>
    </div>
  </div>
  <div class="widget-simple-column margin-left">
    <div class="widget-simple-icon">
      <img src="/assets/images/statistics@2x.png" alt="widget-simple-icon">
    </div>
    <a routerLink="/clients/contributions" class="widget-simple-link">
      <span>{{'View All' | translate}}</span>
      <div class="widget-simple-triangle-right"></div>
    </a>
  </div>
</div>
<ng-template #summaryDonationsShimmerTemplate>
  <ngx-shimmer-loading width="250px" height="28px"></ngx-shimmer-loading>
</ng-template>
<ng-template #lineShimmerTemplate>
  <ngx-shimmer-loading width="250px" height="18px"></ngx-shimmer-loading>
</ng-template>
