import { HttpParams } from '@angular/common/http';
import { Paging } from '../../models/paging/paging.model';
import { SocialMetricFilterModel } from '../../models/socialMedia/social-metric-filter.model';

export class PagingHelper {
  static getPagingParams(params: Paging, addPagingRows: boolean = true): HttpParams {
    let httpParams = new HttpParams();
    if (params) {
      if (params.filters) {
        params.filters.forEach((filter, i) => {
          if (filter && filter.value !== null && filter.value !== undefined && filter.value !== '') {
            httpParams = httpParams.append('filterFields', filter.field);
            httpParams = httpParams.append('filterValues', filter.value);
            httpParams = httpParams.append('filterTypes', filter.type.toString());
          }
        });
      }
      if (addPagingRows) {
        if (params.first) {
          httpParams = httpParams.append('first', params.first.toString());
        }
        if (params.rows) {
          httpParams = httpParams.append('rows', params.rows.toString());
        }
      }
      if (params.selectFields) {
        params.selectFields.forEach(filter => {
          httpParams = httpParams.append('selectFields', filter);
        });
      }
      if (params.sortField) {
        httpParams = httpParams.append('sortField', params.sortField.toString());
      }
      if (params.sortOrder !== null && params.sortOrder !== undefined) {
        httpParams = httpParams.append('sortOrder', params.sortOrder.toString());
      }
      if (params.campaignId) {
        httpParams = httpParams.append('campaignId', params.campaignId);
      }
      if (params.isAddRequest != null || params.isAddRequest !== undefined) {
        httpParams = httpParams.append('isAddRequest', params.isAddRequest.toString());
      }
      if (params.segmentType != null || params.segmentType !== undefined) {
        httpParams = httpParams.append('segmentType', params.segmentType.toString());
      }
      if (params.includeDeleted != null || params.includeDeleted !== undefined) {
        httpParams = httpParams.append('includeDeleted', params.includeDeleted.toString());
      }
      if (params.includeDependencies != null || params.includeDependencies !== undefined) {
        httpParams = httpParams.append('includeDependencies', params.includeDependencies.toString());
      }
      if (params.isAlternative != null || params.isAlternative !== undefined) {
        httpParams = httpParams.append('isAlternative', params.isAlternative.toString());
      }
    }
    return httpParams;
  }

  static getSocialWidgetParams(params: SocialMetricFilterModel): HttpParams {
    let httpParams = new HttpParams();
    if (params) {
      const { startDate, postIds, metricType, grouping, endDate, provider, clientId, socialLoginId }: SocialMetricFilterModel = params;
      if (startDate) {
        httpParams = httpParams.append('startDate', startDate.toJSON());
      }
      if (postIds) {
        postIds.forEach((id: string) => {
          if (id !== null && id !== undefined && id !== '') {
            httpParams = httpParams.append('postIds', id);
          }
        });
      }
      if (metricType) {
        httpParams = httpParams.append('metricType', metricType.toString());
      }
      if (grouping) {
        httpParams = httpParams.append('grouping', grouping.toString());
      }
      if (endDate) {
        httpParams = httpParams.append('endDate', endDate.toJSON());
      }
      if (provider) {
        httpParams = httpParams.append('provider', provider.toString());
      }
      if (clientId) {
        httpParams = httpParams.append('clientId', clientId.toString());
      }
      if (socialLoginId) {
        httpParams = httpParams.append('socialLoginId', socialLoginId.toString());
      }
    }
    return httpParams;
  }

  static getCombineParams(metricParams: SocialMetricFilterModel, pagingParams: Paging, addPagingRows: boolean = true): HttpParams {
    let httpParams = new HttpParams();
    if (metricParams) {
      const { startDate, postIds, metricType, grouping, endDate, provider, clientId }: SocialMetricFilterModel = metricParams;
      if (startDate) {
        httpParams = httpParams.append('startDate', startDate.toJSON());
      }
      if (postIds) {
        postIds.forEach((id: string) => {
          if (id !== null && id !== undefined && id !== '') {
            httpParams = httpParams.append('postIds', id);
          }
        });
      }
      if (metricType) {
        httpParams = httpParams.append('metricType', metricType.toString());
      }
      if (grouping) {
        httpParams = httpParams.append('grouping', grouping.toString());
      }
      if (endDate) {
        httpParams = httpParams.append('endDate', endDate.toJSON());
      }
      if (provider) {
        httpParams = httpParams.append('provider', provider.toString());
      }
      if (clientId) {
        httpParams = httpParams.append('clientId', clientId.toString());
      }
    }
    if (pagingParams) {
      const { filters, first, rows, sortField, sortOrder, includeDeleted, includeDependencies, campaignId, isAddRequest, segmentType }: Paging = pagingParams;
      if (filters) {
        filters.forEach((filter, i) => {
          if (filter.value !== null && filter.value !== undefined && filter.value !== '') {
            httpParams = httpParams.append('filterFields', filter.field);
            httpParams = httpParams.append('filterValues', filter.value);
            httpParams = httpParams.append('filterTypes', filter.type.toString());
          }
        });
      }
      if (addPagingRows) {
        if (first) {
          httpParams = httpParams.append('first', first.toString());
        }
        if (rows) {
          httpParams = httpParams.append('rows', rows.toString());
        }
      }
      if (sortField) {
        httpParams = httpParams.append('sortField', sortField.toString());
      }
      if (sortOrder) {
        httpParams = httpParams.append('sortOrder', sortOrder.toString());
      }
      if (campaignId) {
        httpParams = httpParams.append('campaignId', campaignId);
      }
      if (isAddRequest != null || isAddRequest !== undefined) {
        httpParams = httpParams.append('isAddRequest', isAddRequest.toString());
      }
      if (segmentType != null || segmentType !== undefined) {
        httpParams = httpParams.append('segmentType', segmentType.toString());
      }
      if (includeDeleted != null || includeDeleted !== undefined) {
        httpParams = httpParams.append('includeDeleted', includeDeleted.toString());
      }
      if (includeDependencies != null || includeDependencies !== undefined) {
        httpParams = httpParams.append('includeDependencies', includeDependencies.toString());
      }
    }
    return httpParams;
  }
}
