import {
  Component,
  EventEmitter,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
  Input
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormElementComponent } from '../base.form.element.component';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: [
    './password-input.component.scss',
    '../base.form.element.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PasswordInputComponent extends BaseFormElementComponent
  implements OnChanges {
  public type: 'text' | 'password' = 'password';
  @Input() public showIcon: boolean = true;
  @Output()
  public keydownEnter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public showAsterisks: boolean = false;
  @Input() public normalHeight: boolean = false;
  @Input() public errorMargin: boolean = false;

  constructor(public translate: TranslateService) {
    super(translate);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public onKeydownEnter(): void {
    this.keydownEnter.emit();
  }

  public toggleType(): void {
    this.type = this.type === 'password' ? 'text' : 'password';
  }
}
