import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthService} from '../../../../services/auth.service';
import {Subscription} from 'rxjs';
import FormElementDataModel from '../../../../models/form.element.data.model';
import {
  Alignment,
  Appearance,
  FONT_SIZE_THERMOMETER,
  ShowPercentage,
  TextPosition,
  TextPositionHorizontal
} from '../../../../constants';
import {ThermometerBuilderData} from '../../../../models/templates/thermometerBuilderModel';

@Component({
  selector: 'app-thermometer-builder',
  templateUrl: './thermometer-builder.component.html',
  styleUrls: ['./thermometer-builder.component.scss']
})
export class ThermometerBuilderComponent implements OnInit, OnDestroy {
  public alignmentOptions: FormElementDataModel[] = Alignment;
  public textPositionOptions: FormElementDataModel[] = TextPosition;
  public textPositionHorizontal: FormElementDataModel[] = TextPositionHorizontal;
  public showPercentageOptions: FormElementDataModel[] = ShowPercentage;
  public showThermometerAppearance: FormElementDataModel[] = Appearance;
  public fontSizeOptions: FormElementDataModel[] = FONT_SIZE_THERMOMETER;
  private subscription: Subscription = new Subscription();

  public thermometerForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ThermometerBuilderComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: ThermometerBuilderData,
  ) { }

  public ngOnInit(): void {
    this.thermometerForm = this.data.formGroup;
    this.subscription.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.dialogRef.close();
        }
      })
    );
  }

  public get getPosition(): FormElementDataModel[] {
    return this.thermometerForm.controls.alignment.value === 'horizontal' ? this.textPositionHorizontal : this.textPositionOptions;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public captureThermometer(): void {
    this.dialogRef.close(true);
  }

  public getGoal(): void {
    const goal = this.thermometerForm.value.goal;
    this.thermometerForm.controls['showIterations'].setValue(goal >= 5000);
    if (!this.thermometerForm.value.showIterations) {
      this.thermometerForm.controls['iteration_1'].setValue(null);
      this.thermometerForm.controls['iteration_2'].setValue(null);
      this.thermometerForm.controls['iteration_3'].setValue(null);
      this.thermometerForm.controls['showIterations'].setValue(false);
    }
    if (!isNaN(goal) && this.thermometerForm.value.showIterations) {
      this.thermometerForm.controls['iteration_1'].setValue(this.getIterations(+goal, 0.25));
      this.thermometerForm.controls['iteration_2'].setValue(this.getIterations(+goal, 0.50));
      this.thermometerForm.controls['iteration_3'].setValue(this.getIterations(+goal, 0.75));
      this.thermometerForm.controls['showIterations'].setValue(true);
    }
  }

  private getIterations(goal: number, percent: number): string {
    return (Math.ceil((goal * percent) * 100 ) / 100) + '';
  }

}
