<div
  [formGroup]="formGroup"
  *ngIf="!isNew"
  class="custom-form-component-container-wrapper"
>
  <span class="form-field-name">{{label | translate}}<i
    *ngIf="toolTipText"
    class="fas fa-info-circle"
    [matTooltip]="toolTipText | translate"
    matTooltipHideDelay="200"
    matTooltipShowDelay="200"
    matTooltipPosition="below"
  ></i
></span>
  <mat-radio-group
    [formControlName]="name"
    (change)="onRadioChange($event)"
    [value]="formControl.value"
  >
    <mat-radio-button [value]="item.value" *ngFor="let item of selectOptions">{{item.label | translate}}</mat-radio-button>
  </mat-radio-group>
</div>


<div
  [formGroup]="formGroup"
  *ngIf="isNew"
  class="new-custom-form-component-container-wrapper"
>
  <span class="form-field-name">{{label | translate}}<i
    *ngIf="toolTipText"
    class="fas fa-info-circle"
    [matTooltip]="toolTipText | translate"
    matTooltipHideDelay="200"
    matTooltipShowDelay="200"
    matTooltipPosition="below"
  ></i
  ></span>
  <mat-radio-group
    [formControlName]="name"
    (change)="onRadioChange($event)"
    [value]="formControl.value"
  >
    <mat-radio-button [value]="item.value" *ngFor="let item of selectOptions">{{item.label | translate}}</mat-radio-button>
  </mat-radio-group>
</div>
