<div class="tag-select-wrapper">

  <div
    *ngIf="availableTags | async as tags"
    class="tag-select-lists"
  >
    <!-- Available tags -->
    <div class="tag-select-list-area">
      <span class="tag-select-list-area-title">{{'Add' | translate}} {{'Tag' | translate}}:</span>
      <div class="tag-select-list-area-container">
        <div
          *ngFor="let tag of tags"
          class="list-item"
          [class.tag-selected]="potentialSelectedTagsSet.has(tag)"
          (click)="togglePotentialSelectedTags($event, tag)"
          matRipple
        >
          <span>{{tag.name}}</span>
        </div>
      </div>
    </div>

    <!-- Actions -->
    <div class="tag-select-actions">
      <div
        class="tag-select-actions-button"
        matRipple
        (click)="addTagsSource$.next()"
      >
        <i class="fas fa-long-arrow-alt-right"></i>
      </div>
      <div
        class="tag-select-actions-button"
        matRipple
        (click)="removeTagsSource$.next()"
      >
        <i class="fas fa-long-arrow-alt-left"></i>
      </div>
    </div>

    <!-- Selected tags -->
    <div class="tag-select-list-area">
      <span class="tag-select-list-area-title">{{'Selected' | translate}} {{'Tags' | translate}}:</span>
      <div class="tag-select-list-area-container">
        <div
          *ngFor="let tag of selectedTags.getValue()"
          class="list-item"
          [class.tag-selected]="potentialUnselectedTagsSet.has(tag)"
          (click)="togglePotentialUnselectedTags($event, tag)"
          matRipple
        >
          <span>{{tag.name}}</span>
        </div>
      </div>
    </div>
  </div>

  <app-add-new-tag
    [availableLength]="tagArrayLength"
    [clientId]="clientId"
    [selectOnly]="selectOnly"
    (newTag)="addNewTagToList($event)"
  ></app-add-new-tag>

</div>
