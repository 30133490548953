<div class="wrapper-auth-register-client-form-component">
  <form [formGroup]="clientForm">
    <mat-form-field appearance="outline">
      <mat-label>{{'First Name' | translate}}</mat-label>
      <input formControlName="firstName" matInput placeholder="{{'First Name' | translate}}">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'Last Name' | translate}}</mat-label>
      <input formControlName="lastName" matInput placeholder="{{'Last Name' | translate}}">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'AUTH.Job Title / Profession' | translate}}</mat-label>
      <input formControlName="job" matInput placeholder="{{'AUTH.Job Title / Profession' | translate}}">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'AUTH.Company' | translate}}</mat-label>
      <input formControlName="company" matInput placeholder="{{'AUTH.Company' | translate}}">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'Email Address' | translate}}</mat-label>
      <input formControlName="email" type="email" matInput placeholder="{{'Email Address' | translate}}">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'Password' | translate}}</mat-label>
      <input formControlName="password" type="password" matInput placeholder="{{'Password' | translate}}">
    </mat-form-field>
    <div>
      <mat-checkbox formControlName="confirm" [innerHTML]="'IAgree' | translate">{{'I agree to the' | translate}} <span class="terms">{{'Terms and Conditions' | translate}}</span></mat-checkbox>
    </div>
    <button mat-button class="register-buttons signup" (click)="register()">{{'SIGN UP' | translate}}</button>
  </form>
</div>
