import { AuthService } from '../../../services/auth.service';
import { SocialAuthService, FacebookLoginProvider, SocialUser } from 'angularx-social-login';
import { BeforeUnloadComponent } from 'src/app/components/before-unload/before-unload.component';
import { ClientIdStateService } from '../../../services/client.module.state/client.id.state.service';
import { Component, ViewEncapsulation, OnInit, NgZone } from '@angular/core';
import { DialogBeforeunloadResponseType } from 'src/app/models/enum/dialog.beforeunload.response.type';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormValidators } from '../../../shared/validatiors/form-validators';
import { GoogleAuthConfig } from 'src/app/models/externalServices/googleAuth.config';
import { GoogleAuthService } from 'src/app/services/externalServices/google/google-auth.service';
import { MatDialog } from '@angular/material/dialog';
import { OAuthService } from 'angular-oauth2-oidc';
import { of, Subscription } from 'rxjs';
import { portalUrls } from 'src/app/constants';
import { PortalUrls } from 'src/app/models/enum/portal.urls';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  public subscription: Subscription = new Subscription();
  public loginForm: FormGroup = this.formBuilder.group({
    userName: ['', [Validators.required, FormValidators.emailValidator]],
    password: ['', [Validators.required]]
  });

  private redirectRouteURL: string;
  private toDonorPortal: boolean;
  private isAdmin: boolean = false;

  constructor(
    private authService: AuthService,
    private clientIdStateService: ClientIdStateService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private googleAuthService: GoogleAuthService,
    private oauthService: OAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private toastrService: ToastrService,
    private zone: NgZone,
    private translate: TranslateService
  ) { }

  public ngOnInit(): void {
    this.getReturnUrl();
    this.authService.cleanMasquerade();
    this.authService.clearStorage();
  }

  private getReturnUrl(): void {
    const params = this.route.snapshot.queryParams;
    if (params['redirectURL']) {
      this.redirectRouteURL = params['redirectURL'];
    }
  }

  public get userName(): FormControl {
    return this.loginForm.get('userName') as FormControl;
  }

  public get password(): FormControl {
    return this.loginForm.get('password') as FormControl;
  }

  public signIn(): void {
    if (this.loginForm.invalid) {
      this.toastrService.error(this.errorMessages(), this.translate.instant('Notification'));
      return;
    }
    const { userName, password } = this.loginForm.value;
    this.authRequest(userName, password, null);
  }

  private choosePortal(): void {
    const config = {
      data: {
        title: this.translate.instant('AUTH.You have both Donor and Organization accounts. Where would you like to login?'),
        firstButtonName: this.translate.instant('AUTH.Non-profit portal'),
        secondButtonName: this.translate.instant('AUTH.Donor portal'),
        widthButton: '200'
      }
    };
    let fn;
    this.dialog.open(BeforeUnloadComponent, config).afterClosed().pipe(
      switchMap((portal: DialogBeforeunloadResponseType) => {
        const portals = {
          [DialogBeforeunloadResponseType.Accept]: () => PortalUrls.ClientPortal,
          [DialogBeforeunloadResponseType.Reject]: () => {
            this.toDonorPortal = true;
            return PortalUrls.DonorPortal;
          },
          [DialogBeforeunloadResponseType.Close]: () => this.oauthService.logOut(),
          default: () => this.oauthService.logOut()
        };

        portals[portal] ? (fn = portals[portal]) : (fn = portals['default']);

        return of(fn());
      })
    )
      .subscribe((redirectURL) => {
        if (redirectURL) {
          if (this.redirectRouteURL) {
            const returnUrl = this.route.snapshot.queryParams['returnUrl'] || redirectURL;
            this.navigateToPortal(returnUrl);
            // this.navigateToPortal(redirectURL);
          } else {
            const returnUrl = this.route.snapshot.queryParams['returnUrl'] || redirectURL;
            this.router.navigateByUrl(returnUrl);
            // this.router.navigateByUrl(redirectURL);
          }

          this.authService.setIsLoggedIn(true);
          this.setUserInfo();
        }
      });
  }

  private setUserInfo(): void {
    localStorage.setItem('origin_id', this.authService.getIdentityClaimsOriginId());
    localStorage.setItem('given_name', this.authService.getIdentityClaimsName());
    localStorage.setItem('helper_id', this.authService.getIdentityClaimsDonorId());
    localStorage.setItem('helperEmployee_id', this.authService.getIdentityClaimsClientEmployeeId());
    localStorage.setItem('roleCopy', JSON.stringify(this.authService.getIdentityClaimsRole()));
    this.clientIdStateService.setSelectedRelationshipClient(this.authService.getIdentityClaimsOriginId(), null);
  }

  private navigateToPortal(redirectURL: string) {
    let url: string;
    if (this.toDonorPortal) {
      if (this.redirectRouteURL.includes(PortalUrls.AdminPortal)) {
        return this.router.navigateByUrl(redirectURL);
      }
      url = this.redirectRouteURL.includes(PortalUrls.DonorPortal) ? this.redirectRouteURL : redirectURL;
    } else {
      if (this.redirectRouteURL.includes(PortalUrls.AdminPortal)) {
        return this.router.navigateByUrl(redirectURL);
      }

      url = !this.redirectRouteURL.includes(PortalUrls.DonorPortal) ? this.redirectRouteURL : redirectURL;
    }
    this.router.navigateByUrl(url);
  }

  public onForgotPassword(): void {
    this.router.navigateByUrl('/resetpass');
  }

  public signUp(): void {
    const queryUrl =  this.route.snapshot.queryParams['returnUrl'];
    this.router.navigate(['/donor-register'], {
      queryParams: { returnUrl: queryUrl },
    });
    // this.router.navigateByUrl('/donor-register');
  }

  public errorMessages(): string {
    const arrMessages = [];
    const emailControl = this.loginForm.controls['userName'];
    const passwordControl = this.loginForm.controls['password'];
    if (emailControl.errors && emailControl.errors['required'])
      arrMessages.push(this.translate.instant('AUTH.Email required'));
    if (passwordControl.errors && passwordControl.errors['required'])
      arrMessages.push(this.translate.instant('AUTH.Password required'));
    if (emailControl.errors && emailControl.errors['email'])
      arrMessages.push(this.translate.instant('AUTH.Invalid email'));
    return arrMessages.join('; ');
  }

  public continueWithGoogle(): void {
    this.googleAuthService.initClient(GoogleAuthConfig, () => {
      this.googleAuthService.login()
        .then((googleUser) => {
          const gtoken = googleUser.getAuthResponse().id_token;
          this.authRequest('test', 'test', {gtoken});
        });
    });
  }

  public continueWithFacebook(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((userData: SocialUser) => {
      if (userData && userData.authToken) {
        this.authRequest('test', 'test', { fbtoken: userData.authToken });
      }
    });
  }

  private authRequest(login: string, password: string, customQueryParams?: any) {
    this.oauthService.customQueryParams = customQueryParams;
    this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(login, password)
      .then(() => {
        let redirectURL: string, hasClientUrl: boolean, hasDonorUrl: boolean;
        const roles = this.oauthService.getIdentityClaims()['role'];
        roles.forEach((role) => {
          redirectURL = portalUrls[role];
          if (redirectURL === PortalUrls.ClientPortal) {
            hasClientUrl = true;
          } else if (redirectURL === PortalUrls.DonorPortal) {
            hasDonorUrl = true;
          } else if (redirectURL === PortalUrls.AdminPortal) {
            this.isAdmin = true;
          }
        });
        this.authService.setIsLoggedIn(true);
        this.zone.run(() => {
          if (hasClientUrl && hasDonorUrl) {
            //this.zone.run(this.choosePortal.bind(this));
            this.choosePortal();
          } else {
            if (this.isAdmin) {
              const returnUrl = this.route.snapshot.queryParams['returnUrl'] || (this.redirectRouteURL && this.redirectRouteURL.includes(PortalUrls.AdminPortal) && this.redirectRouteURL) || redirectURL;
              this.router.navigateByUrl(returnUrl);
              // this.router.navigateByUrl((this.redirectRouteURL && this.redirectRouteURL.includes(PortalUrls.AdminPortal) && this.redirectRouteURL) || redirectURL);
            } else {
              let url = '';
              if (hasDonorUrl) {
                url = this.redirectRouteURL && this.redirectRouteURL.includes(PortalUrls.DonorPortal) ? this.redirectRouteURL : redirectURL;
              } else if (hasClientUrl) {
                url = this.redirectRouteURL && this.redirectRouteURL.includes(PortalUrls.ClientPortal) ? this.redirectRouteURL : redirectURL;
              }
              const returnUrl = this.route.snapshot.queryParams['returnUrl'] || url;
              this.router.navigateByUrl(returnUrl);
              // this.router.navigateByUrl('/donor-portal/organizations/ad176788-28c1-428e-af3a-afe90cda5caf/createP2P');
            }
            this.setUserInfo();
          }
        });
      });
  }

  public onBlur(formControl: FormControl): void {
    formControl.patchValue(formControl.value.trim());
  }
}
