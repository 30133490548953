import {Component, OnInit, Renderer2} from '@angular/core';
import {CampaignService} from '../../../services/campaign/campaign.service';
import {forkJoin, Observable, of} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CampaignModel} from '../../../models/campaigns/campaign.model';
import SocialPostModel from 'src/app/models/socialMedia/social.post.model';
import {SocialPostService} from 'src/app/services/socialMedia/social.post.service';
import {ClientDonorService} from 'src/app/services/client.donors/client-donor.service';
import {ClientDonorModel} from 'src/app/models/client/client.donor.model';
import {ClientService} from 'src/app/services/client/client.service';
import {ClientModel} from 'src/app/models/client/client.model';
import EventModel from 'src/app/models/event/event.model';
import {EventService} from 'src/app/services/events/event.service';
import {Paging} from '../../../models/paging/paging.model';
import {FilterType} from '../../../models/enum/filter.type';
import FbTemplateModel, {
  AlignmentType,
  FbElementModel,
  FbElementType,
  FbTemplateStatusType,
  HeaderSize,
  PassMerchantFee
} from '../../../models/form-builder/fb.template.model';
import {UtilsComponent} from '../../utils.component';
import {DEFAULT_PAYMENT_FORM, DEFAULT_THANK_YOU_PAGE_HTML} from '../../../constants';
import {filter, first, map, switchMap, tap} from 'rxjs/operators';
import {LookupStoreService} from '../../../services/lookup/lookup-store.service';
import {SourceTemplateService} from '../../../services/templates/source.template.service';
import SourceTemplateModel, {TemplateSourceType} from '../../../models/templates/source.template.model';
import {LookupService} from '../../../services/lookup/lookup.service';
import {TimeZoneModel} from '../../../models/time-zone.model';
import {
  SettingImageModel
} from '../../form-builder/components/element-options/top-image-options/top-image-options.component';
import {DonationPaymentPageType} from '../../../models/form-builder/donation.payment.page.type';
import {SortOrder} from '../../../models/enum/sort.order';
import {TranslateService} from '@ngx-translate/core';
import {Patterns} from '../../../shared/validatiors/patterns.model';
import {ToastrService} from "ngx-toastr";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  public campaign: CampaignModel;
  public socialPost: SocialPostModel;
  public clientDonor: ClientDonorModel;
  public event: EventModel;
  public client: ClientModel;
  public isDonationComplete: boolean = false;
  public isDonationRecurring: boolean = false;
  private guidRegExp: RegExp = new RegExp(Patterns.guid);
  public addMicroDepositLinkToDom: boolean = false;
  public microdepositsLink: string = '';
  public fbTemplate$: Observable<FbTemplateModel>;
  public sourceTemplateModelId: string = null;
  public templateId: string = null;
  public genericTemplateId: string = null;
  public genericSourceTemplateId: string = null;
  public thankYouPageHtml$: Observable<string>;
  public email: string;
  public phone: string;
  public donateAmount: string;
  public funds: string;
  public fundsList: string;
  public donorName: string;
  public numberOfImage: SettingImageModel[] = [];
  public bgcArray: string[] = [];
  public imageURL0: string;
  public imageURL1: string;
  public imageURL2: string;
  public imageURL3: string;

  public isEmbedPage: boolean = false;

  public error$: Observable<boolean> = of(false);
  public includeFee$: Observable<boolean> = new Observable<boolean>();

  private static friendlyURLPaging(friendlyURL: string): Paging {
    return {
      includeDependencies: false,
      includeDeleted: false,
      filters: [{
        field: 'friendlyURL',
        value: friendlyURL,
        type: FilterType.Equal,
      }],
      rows: 1,
      selectFields: ['clientID', 'id', 'name', 'hasAppeal', 'feeAndTaxes', 'donationPaymentPage'],
    };
  }

  constructor(
    private campaignModelService: CampaignService,
    private activatedRoute: ActivatedRoute,
    private socialPostService: SocialPostService,
    private clientDonorService: ClientDonorService,
    private clientModelService: ClientService,
    private eventService: EventService,
    private router: Router,
    public lookupStoreService: LookupStoreService,
    private sourceTemplateService: SourceTemplateService,
    private lookupService: LookupService,
    public translate: TranslateService,
    private toastService: ToastrService
  ) {
  }

  public ngOnInit(): void {
    this.parseQueryParams();

    if (window.location.pathname.startsWith('/pay/')) {
      const friendlyURL = window.location.pathname.substring(5);
      this.fbTemplate$ = this.getDataByFriendlyURL(friendlyURL)
        .pipe(
          map((template: FbTemplateModel) => {
            this.setIncludeFee(template);
            this.setClientInfo(template);
            this.getImgData(template);
            return template;
          })
        );
    } else if (window.location.pathname.startsWith('/embed/pay/')) {
      this.isEmbedPage = true;
      const friendlyURL = window.location.pathname.substring(11);
      this.fbTemplate$ = this.getDataByFriendlyURL(friendlyURL)
        .pipe(
          map((template: FbTemplateModel) => {
            this.setIncludeFee(template);
            this.setClientInfo(template);
            this.getImgData(template);
            return template;
          })
        );
    } else {
      this.fbTemplate$ = this.activatedRoute.params
        .pipe(
          first(),
          switchMap(({campaignId, socialPostId, clientId, eventId}) => {
            this.isEmbedPage = window.location.pathname.startsWith('/embed/');
            if (clientId) {
              return this.clientData(clientId)
                .pipe(
                  map((client: ClientModel) => ({client, sourceID: null, donationPaymentPage: DonationPaymentPageType.Unknown}))
                );
            } else if (campaignId) {
              const clientDonorID = this.activatedRoute.snapshot.queryParamMap.get('userId');
              const clientDonor$ = this.clientDonorData(clientDonorID);
              const campaign$ = this.campaignData(campaignId)
                .pipe(
                  switchMap((campaign: CampaignModel) => {
                    this.campaign = campaign;
                    return this.clientDataById(campaign.clientID);
                  })
                );
              return forkJoin([campaign$, clientDonor$])
                .pipe(
                  map(([client, clientDonor]) => ({client, sourceID: campaignId, donationPaymentPage: this.campaign.donationPaymentPage}))
                );
            } else if (socialPostId) {
              return this.socialPostData(socialPostId)
                .pipe(
                  switchMap((socialPost: SocialPostModel) => {
                    this.socialPost = socialPost;
                    return this.clientDataById(socialPost.clientID);
                  }),
                  map((client: ClientModel) => ({client, sourceID: this.socialPost.id, donationPaymentPage: this.socialPost.donationPaymentPage}))
                );
            } else if (eventId) {
              return this.eventData(eventId)
                .pipe(
                  switchMap((event: EventModel) => {
                    this.event = event;
                    return this.clientDataById(event.clientID);
                  }),
                  map((client: ClientModel) => ({client, sourceID: eventId, donationPaymentPage: this.event.donationPaymentPage}))
                );
            }
          }),
          switchMap(({client, sourceID, donationPaymentPage}) => {
            this.client = client;
            switch (donationPaymentPage) {
              case DonationPaymentPageType.Custom:
              case DonationPaymentPageType.Unknown:
                if (sourceID) {
                  return this.getTemplateBySourceId(sourceID, client.defaultTemplateId);
                } else {
                  return this.getTemplateById(client.defaultTemplateId);
                }
              case DonationPaymentPageType.ClientDefault:
                return this.getTemplateById(client.defaultTemplateId);
              default:
                return this.getTemplateById(null);
            }
          }),
          map((template: FbTemplateModel) => {
            this.setIncludeFee(template);
            this.setClientInfo(template);
            this.getImgData(template);
            return template;
          })
        );
    }

    this.thankYouPageHtml$ = this.fbTemplate$
      .pipe(
        map((template: FbTemplateModel) => {
          if (template.thankYouPageHtml) {
            const temp = this.translate.instant(template.thankYouPageHtml);
            return this.replacePlaceholders(temp);
          } else {
            return this.translate.instant(DEFAULT_THANK_YOU_PAGE_HTML);
          }
        }),
        tap(() => this.displayMicrodepositVerificationMessage()),
      );
  }

  public mainContainerStyle(template: FbTemplateModel): { [key: string]: string } {
    if (!template) {
      return {};
    }
    if (this.isEmbedPage) {
      return {
        'background' : 'transparent'
      }
    }
    const {
      pageColor = '#ECEDF3',
      formBgImg = '',
      backgroundRepeat = 'repeat',
      backgroundSize = 'auto'
    }: FbTemplateModel = template;
      return {
        'background-repeat': backgroundRepeat,
        'background-image' : formBgImg ? `url(${formBgImg})` : 'none',
        'background-size' : backgroundSize,
        'background-color': pageColor,
      };
  }

  public topImageStyle(template: FbTemplateModel, i: number): string {
    if (!template) {
      return null;
    }
    if (!template.topImage.attributes.settings) {
      template.topImage.attributes.settings = JSON.stringify([template.topImage.attributes]);
      template.topImage.attributes.width = '1080px';
      template.topImage.attributes.height = '150px';
      template.topImage.attributes.paddingBottom = '0px';
      template.topImage.attributes.paddingTop = '0px';
      template.topImage.attributes.paddingRight = '0px';
      template.topImage.attributes.paddingLeft = '0px';
    }
    const {
      settings
    }: { [key: string]: string } = template.topImage.attributes;
    this.numberOfImage = JSON.parse(settings);
    if (this.numberOfImage[i]) {
      this.assignVariables(i);
      return `url(${this.numberOfImage[i] === undefined ? null : this.numberOfImage[i].url}) no-repeat ${this.numberOfImage[i] === undefined ? null : this.numberOfImage[i].position} / contain`;
    }
  }

  private setClientInfo(template: FbTemplateModel): void {
    if(!template) return;
    const infoPage = template.infoPage.elements.find(element => element.type === FbElementType.DonationInfo);
    const {name, facebookLink, twitterLink, instagramLink, linkedinLink, url}: ClientModel = this.client;
    if (name) {
      infoPage.text = infoPage.text.replace('Auxilia', name);
    }
    if (facebookLink) {
      template.headerFooter.attributes.facebookLink = facebookLink;
    }
    if (twitterLink) {
      template.headerFooter.attributes.twitterLink = twitterLink;
    }
    if (instagramLink) {
      template.headerFooter.attributes.instagramLink = instagramLink;
    }
    if (linkedinLink) {
      template.headerFooter.attributes.linkedInLink = linkedinLink;
    }
    if (url) {
      template.headerFooter.attributes.logoTarget = url;
    }
  }

  private getDataByFriendlyURL(friendlyURL: string): Observable<FbTemplateModel> {
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: false,
      sortOrder: SortOrder.Descending,
      sortField: 'createdOn',
      filters: [{
        field: 'frendlyURL',
        value: friendlyURL,
        type: FilterType.Equal,
      }],
      rows: 1,
    };
    return this.sourceTemplateService.getModelList(paging)
      .pipe(
        switchMap((sourceList: SourceTemplateModel[]) => {
          if (sourceList.length) {
            const {sourceType, clientID, sourceID, templateId}: SourceTemplateModel = sourceList[0];
            this.genericTemplateId = templateId;
            let paymentSource$ = of(null);
            if (sourceType === TemplateSourceType.SocialMedia) {
              paymentSource$ = this.socialPostDataById(sourceID);
            } else if (sourceType === TemplateSourceType.Campaign) {
              paymentSource$ = this.campaignDataById(sourceID);
            } else if (sourceType === TemplateSourceType.Event) {
              paymentSource$ = this.eventDataById(sourceID)
            }

            return forkJoin([this.clientDataById(clientID), paymentSource$])
              .pipe(
                switchMap(([client, sourceModel]) => {
                  this.client = client;
                  if (sourceType === TemplateSourceType.SocialMedia) {
                    this.socialPost = sourceModel as SocialPostModel;
                  } else if (sourceType === TemplateSourceType.Campaign) {
                    this.campaign = sourceModel as CampaignModel;
                  } else if (sourceType === TemplateSourceType.Event) {
                    this.event = sourceModel as EventModel;
                  }
                  return this.parseTemplateByStatus(sourceList[0], this.getTemplateById(client.defaultTemplateId));
                })
              );
          } else {
            //no source
            this.router.navigateByUrl('');
            return of(null);
          }
        })
      );
  }

  private eventData(eventId: string): Observable<EventModel> {
    if (this.guidRegExp.test(eventId)) {
      return this.eventDataById(eventId);
    } else {
      return this.eventDataByFriendlyURL(eventId);
    }
  }

  private eventDataById(eventId: string): Observable<EventModel> {
    return this.eventService.getModel(eventId, false);
  }

  private eventDataByFriendlyURL(friendlyURL: string): Observable<EventModel> {
    return this.listToFirstItem(
      this.eventService.getModelList(PaymentComponent.friendlyURLPaging(friendlyURL))
    );
  }

  private campaignData(campaignId: string): Observable<CampaignModel> {
    if (this.guidRegExp.test(campaignId)) {
      return this.campaignDataById(campaignId);
    } else {
      return this.campaignDataByFriendlyURL(campaignId);
    }
  }

  private campaignDataById(campaignId: string): Observable<CampaignModel> {
    return this.campaignModelService.getModel(campaignId);
  }

  private campaignDataByFriendlyURL(friendlyURL: string): Observable<CampaignModel> {
    return this.listToFirstItem(
      this.campaignModelService.getModelList(PaymentComponent.friendlyURLPaging(friendlyURL))
    );
  }

  private clientData(clientId: string): Observable<ClientModel> {
    if (this.guidRegExp.test(clientId)) {
      return this.clientDataById(clientId);
    } else {
      return this.clientDataByFriendlyURL(clientId);
    }
  }

  private clientDataById(id: string): Observable<ClientModel> {
    return this.clientModelService.getModel(id, false);
  }

  private clientDataByFriendlyURL(friendlyURL: string): Observable<ClientModel> {
    return this.itemValidation(this.clientModelService.getClientDataByFriendlyUrl(friendlyURL));
  }

  private getTemplateById(id: string): Observable<FbTemplateModel> {
    if (!id) {
      // added fee option to Auxilia Default Form (not custom form)
      const auxiliaDefaultForm: FbTemplateModel = UtilsComponent.clone(DEFAULT_PAYMENT_FORM);
      const paymentDetails = auxiliaDefaultForm.paymentPage.elements.find(({type}: FbElementModel) => type === FbElementType.PaymentDetails);
      paymentDetails.attributes.passMerchantFee = PassMerchantFee.No;
      paymentDetails.attributes.isPassingFeeDefault = 'false';
      return of(auxiliaDefaultForm);
    }
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: false,
      sortOrder: SortOrder.Descending,
      sortField: 'createdOn',
      filters: [{
        field: 'templateId',
        value: id,
        type: FilterType.Equal,
      }],
      rows: 1,
    };
    this.templateId = id;
    return this.sourceTemplateService.getModelList(paging)
      .pipe(
        switchMap((sourceList: SourceTemplateModel[]) => this.parseTemplateByStatus(sourceList[0], of(UtilsComponent.clone(DEFAULT_PAYMENT_FORM))))
      );
  }

  private getTemplateBySourceId(sourceID: string, defaultTemplateId: string): Observable<FbTemplateModel> {
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: false,
      sortOrder: SortOrder.Descending,
      sortField: 'createdOn',
      filters: [{
        field: 'sourceID',
        value: sourceID,
        type: FilterType.Equal,
      }],
      rows: 1,
    };
    return this.sourceTemplateService.getModelList(paging)
      .pipe(
        switchMap((sourceList: SourceTemplateModel[]) => {
          if (sourceList.length) {
            return this.parseTemplateByStatus(sourceList[0], this.getTemplateById(defaultTemplateId));
          } else {
            return this.getTemplateById(defaultTemplateId);
          }
        })
      );
  }

  private parseTemplateByStatus(source, alternativeTemplateObservable: Observable<FbTemplateModel>): Observable<FbTemplateModel> {
    if(!source) return ;
    const {content, templateStatus, disableDate, disableTime, disableTimeZone, id, templateId, sourceType}: SourceTemplateModel = source;
    this.genericTemplateId = templateId;
    if(sourceType === TemplateSourceType.Event || sourceType === TemplateSourceType.Campaign) this.genericSourceTemplateId = id;
    switch (templateStatus) {
      case FbTemplateStatusType.Active:
        if (content) {
          this.sourceTemplateModelId = id;
          return of(JSON.parse(content))
        } else {
          return this.formUnavailableCase()
        }
      case FbTemplateStatusType.Inactive:
        return this.formUnavailableCase()
      case FbTemplateStatusType.DisableOnDate:
        return this.lookupStoreService.timeZones$
          .pipe(
            map((timeZones: TimeZoneModel[]) => timeZones.find((timeZone) => timeZone.id === disableTimeZone)),
            switchMap((timeZone: TimeZoneModel) => this.convertToUTC(timeZone, disableDate, disableTime)),
            switchMap((convertedDate: number) => {
              if (convertedDate > Date.now()) {
                if (content) {
                  this.sourceTemplateModelId = id;
                  return of(JSON.parse(content))
                } else {
                  return of(UtilsComponent.clone(DEFAULT_PAYMENT_FORM));
                }
              } else {
                return this.formUnavailableCase()
              }
            })
          );
    }
  }

  private formUnavailableCase(): Observable<null> {
    this.toastService.error("This page is no longer available.");
    return of(null);
  }

  private convertToUTC(timeZone: TimeZoneModel, disableDate: string, disableTime: string): Observable<number> {
    let offset = timeZone.offset;
    return this.lookupService.getIsDaylightSavingTime(new Date().toISOString(), timeZone.name)
      .pipe(
        map((isDayLight: boolean) => {
          if (isDayLight) {
            ++offset;
          }
          const dateParts = disableDate.split('T')[0].split('-');
          const timeParts = disableTime.split(' ');
          const hours12Value = +timeParts[0].split(':')[0];
          const hours24Value = hours12Value === 12 ? 0 : hours12Value;
          const hours = timeParts[1] === 'am' ? hours24Value : hours24Value + 12;
          const minutes = +timeParts[0].split(':')[1];
          return Date.UTC(+dateParts[0], (+dateParts[1] - 1), +dateParts[2], hours - offset, minutes);
        })
      );
  }

  private socialPostData(socialPostId: string): Observable<SocialPostModel> {
    if (this.guidRegExp.test(socialPostId)) {
      return this.socialPostDataById(socialPostId);
    } else {
      return this.socialPostDataByFriendlyURL(socialPostId);
    }
  }

  private socialPostDataById(socialPostId: string): Observable<SocialPostModel> {
    return this.socialPostService.getPublicSocialPost(socialPostId);
  }

  private socialPostDataByFriendlyURL(friendlyURL: string): Observable<SocialPostModel> {
    return this.listToFirstItem(
      this.socialPostService.getModelListByFriendlyURL(PaymentComponent.friendlyURLPaging(friendlyURL))
    );
  }

  private clientDonorData(clientDonorId: string): Observable<ClientDonorModel> {
    if (clientDonorId) {
      return this.clientDonorService.getModel(clientDonorId)
        .pipe(
          filter(value => !!value),
          tap((clientDonor: ClientDonorModel) => this.clientDonor = clientDonor)
        );
    } else {
      return of(null);
    }
  }

  public onPaymentFinished(event, template?: FbTemplateModel): void {
    if (template && template.isCustomThankYouPage) {
      window.location.href = `${template.customThankYouPageUrl}`;
      return;
    }
    const {cardHolderName, fundAllocation, email, amountCaptured, phone, ammount, microdepositVerificationHash, recurring}: any = event;
    let funds = '';
    let fundsList = '';
    if (fundAllocation && fundAllocation.length) {
      funds = fundAllocation.map(item => item.name).join(', ');
      fundsList = fundAllocation.map(item => `$${item.amount} to be donated to ${item.name}`).join(', ');
    }
    this.router.navigate([window.location.pathname], {
      queryParams: {
        donationComplete: true,
        DonorName: cardHolderName,
        Email: email,
        Phone: phone,
        DonateAmount: amountCaptured !== 0 ? amountCaptured : ammount,
        Funds: funds,
        FundsList: fundsList,
        Recurring: recurring,
        MicrodepositVerification: microdepositVerificationHash
      }
    }).then(() => window.location.reload());
  }

  private getImgData(template: FbTemplateModel): void {
    if(!template) return;
      if (template.topImage.attributes.settings) {
      } else {
        template.topImage.attributes.settings = JSON.stringify([template.topImage.attributes]);
        template.topImage.attributes.width = '1080px';
        template.topImage.attributes.height = '150px';
        template.topImage.attributes.paddingBottom = '0px';
        template.topImage.attributes.paddingTop = '0px';
        template.topImage.attributes.paddingRight = '0px';
        template.topImage.attributes.paddingLeft = '0px';
      }
      if (!template) {
        return;
      }
      const {
        settings
      }: { [key: string]: string } = template.topImage.attributes;
      if (settings) {
        this.numberOfImage = JSON.parse(settings);
        this.numberOfImage.forEach((item, i) => {
          this.assignVariables(i);
          this.bgcArray[i] = this.topImageStyle(template, i);
        });
      }
  }

  private assignVariables(i: number): void {
    switch (i) {
      case 0:
        this.imageURL0 = this.numberOfImage[i].url;
        break;
      case 1:
        this.imageURL1 = this.numberOfImage[i].url;
        break;
      case 2:
        this.imageURL2 = this.numberOfImage[i].url;
        break;
      case 3:
        this.imageURL3 = this.numberOfImage[i].url;
        break;
      default:
        break;
    }
  }

  public getSettings(template: FbTemplateModel, settingName: string): string {
    if (!template) {
      return;
    }
    const {
      width = '1080px',
      height = '150px',
      paddingBottom = '0px',
      paddingTop = '0px',
      paddingRight = '0px',
      paddingLeft = '0px',
    }: { [key: string]: string } = template.topImage.attributes;
    switch (settingName) {
      case 'width':
        return width;
      case 'height':
        return height;
      case 'top':
        return paddingTop;
      case 'right':
        return paddingRight;
      case 'bottom':
        return paddingBottom;
      case 'left':
        return paddingLeft;
      default:
        break;
    }
  }

  public headerStyle(template: FbTemplateModel): { [key: string]: string } {
    if (!template) {
      return {};
    }
    const {
      background = '#023665',
      logoPosition = AlignmentType.Left
    }: { [key: string]: string } = template.headerFooter.attributes;
    return {
      background,
      'justify-content': UtilsComponent.getLogoPosition(logoPosition as AlignmentType),
    };
  }

  public logoSrc(template: FbTemplateModel): string {
    const {
      logoSrc = '/assets/images/logo.png'
    }: { [key: string]: string } = template.headerFooter.attributes;
    return logoSrc;
  }

  public logoStyle(template: FbTemplateModel): { [key: string]: string } {
    const {
      logoSize = HeaderSize.Medium,
    }: { [key: string]: string } = template.headerFooter.attributes;
    return {
      width: 'auto',
      height: `${UtilsComponent.getLogoSize(logoSize as HeaderSize)}px`,
    };
  }

  public logoTarget(template: FbTemplateModel): string {
    const {logoTarget} = template.headerFooter.attributes;
    if (logoTarget) {
      if (logoTarget.startsWith('http')) {
        return logoTarget;
      } else {
        return `https://${logoTarget}`;
      }
    } else {
      return 'https://www.theauxilia.com/';
    }
  }

  public footerStyle(template: FbTemplateModel): { [key: string]: string } {
    if (!template) {
      return {};
    }
    const {
      background = '#023665',
    }: { [key: string]: string } = template.headerFooter.attributes;
    return {
      background,
    };
  }

  public paymentFormStyle(template: FbTemplateModel): { [key: string]: string } {
    if (!template) {
      return {};
    }
    const {
      formColor = '#ffffff',
      width = 760
    }: FbTemplateModel = template;
    return {
      background: formColor,
      maxWidth: `${width + 100}px`,
      width: '100%'
    };
  }

  private parseQueryParams(): void {
    if (this.activatedRoute.snapshot.queryParams.donationComplete) {
      this.isDonationComplete = true;
    }
    if(this.activatedRoute.snapshot.queryParams.Recurring === 'true') {
      this.isDonationRecurring = true;
    }
    const {DonorName = '', Email = '', Phone, DonateAmount, Funds = '', FundsList = ''}: any = this.activatedRoute.snapshot.queryParams;
    this.donorName = DonorName;
    this.email = Email;
    this.phone = Phone ? `+${Phone}` : '';
    this.donateAmount = DonateAmount ? `$${DonateAmount}` : '';
    this.funds = Funds;
    this.fundsList = FundsList;
  }

  private replacePlaceholders(html: string): string {
    return html
      .split('[DonorName]').join(this.donorName)
      .split('[FullName]').join(this.donorName)
      .split('[Email]').join(this.email)
      .split('[Phone]').join(this.phone)
      .split('[DonateAmount]').join(this.donateAmount)
      .split('[Funds]').join(this.funds)
      .split('[FundsList]').join(this.fundsList);
  }

  private itemValidation(observable: Observable<any>): Observable<any> {
    return observable.pipe(
      tap((list: any) => {
        if (!list) {
          this.error$ = of(true);
        }
      })
    );
  }

  private listToFirstItem(observable: Observable<any[]>): Observable<any> {
    return observable.pipe(
      tap((list: any[]) => {
        if (!list || !list.length) {
          this.error$ = of(true);
        }
      }),
      map((list: any[]) => list[0])
    );
  }

  private setIncludeFee(template: FbTemplateModel): void {
    if(!template) return;
    const elementModel: FbElementModel = template.paymentPage.elements
      .find(({type}: FbElementModel) => type === FbElementType.PaymentDetails);
    const {isPassingFeeDefault} = elementModel.attributes;
    const {passMerchantFee, doesClientCoverFee} = elementModel.attributes;
    const includeFee: boolean = !JSON.parse(doesClientCoverFee ?? 'false') && (passMerchantFee === "yes" || isPassingFeeDefault === "true");

    this.includeFee$ = of(includeFee);
  }

  public parseTemplate(template: FbTemplateModel) {
    return JSON.parse(template.topImage.attributes.settings)
  }

  private displayMicrodepositVerificationMessage(): void {
    const microdepositVerificationHash: string = new URLSearchParams(window.location.search).get("MicrodepositVerification")
    if(microdepositVerificationHash) {
      const verifyWithMicrodepositsLink: string = `https://payments.stripe.com/microdeposit/${microdepositVerificationHash}`;
      if(environment.enableTestMode) {
        this.microdepositsLink = verifyWithMicrodepositsLink
        this.addMicroDepositLinkToDom = true;
      }
    }
  }
}
