<div class="image-builder-root-container" #rootContainerRef>
  <!-- Work Area -->
  <div class="image-builder-main-container" #mainContainerRef>
    <div class="image-builder-top-bar">
      <div
        (click)="ibss.undo()"
        class="top-bar-button"
        [class.disabled]="ibss.undoDisable"
        matRipple
        [matRippleDisabled]="ibss.undoDisable"
      >
        <div class="action-container">
          <span>{{'SM.Undo' | translate }}</span>
          <i class="fas fa-undo-alt"></i>
        </div>
      </div>
      <div
        (click)="ibss.redo()"
        class="top-bar-button"
        [class.disabled]="ibss.redoDisable"
        matRipple
        [matRippleDisabled]="ibss.redoDisable"
      >
        <div class="action-container">
          <span>{{'SM.Redo' | translate }}</span>
          <i class="fas fa-redo-alt"></i>
        </div>
      </div>

      <div
        (click)="screenshots()"
        class="top-bar-button"
        matRipple
      >
        <div class="action-container">
          <span>{{'SM.Get Image' | translate }}</span>
          <i class="fas fa-camera-retro"></i>
        </div>
      </div>

      <div
        (click)="exportPDF()"
        class="top-bar-button"
        matRipple
      >
        <div class="action-container">
          <span>PDF</span>
          <i class="fas fa-file-pdf"></i>
        </div>
      </div>

      <div
        (click)="exportDOCX()"
        class="top-bar-button"
        matRipple
      >
        <div class="action-container">
          <span>DOCX</span>
          <i class="fas fa-file-word"></i>
        </div>
      </div>
    </div>

    <div class="image-builder-work-area">
      <div
        class="resize-container"
        [ngStyle]="sizeValues"
        (dragover)="onDragOver($event)"
        (drop)="onDrop($event)"
      >
        <div
          #imageBuilderContainerRef
          class="image-builder-container"
          [ngStyle]="imageBuilderContainerStyle"
        >
          <div
            class="image-builder-container-screenshots"
            [ngStyle]="screenshotsSizeValues"
            data-html2canvas-ignore
          ></div>
          <div
            class="image-builder-container-background"
            [ngStyle]="imageBuilderContainerBackgroundStyle"
          ></div>
          <app-layer
            *ngFor="let layer of ibss.layers; index as i"
            [ibss]="ibss"
            [layer]="layer"
            [currentLayerIndex]="i"
          ></app-layer>
        </div>
      </div>
    </div>
  </div>


  <!-- Tools and Settings -->
  <div class="image-builder-tools-area">
    <mat-tab-group
      dynamicHeight
      [selectedIndex]="currentTab"
      (selectedIndexChange)="currentTab = $event"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="image-builder-tools-tab-wrapper" [class.active]="currentTab === 0">
            <span class="tab-title">{{'SM.Styling' | translate}}</span>
          </div>
        </ng-template>
        <div class="styling-overflow-container" [style.height.px]="stylingContainerHeight">
          <app-styling [ibss]="ibss"></app-styling>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <div class="image-builder-tools-tab-wrapper" [class.active]="currentTab === 1">
            <span class="tab-title">{{'SM.Elements' | translate}}</span>
          </div>
        </ng-template>
        <div class="styling-overflow-container" [style.height.px]="stylingContainerHeight">
          <app-elements [ibss]="ibss"></app-elements>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <div class="image-builder-tools-tab-wrapper" [class.active]="currentTab === 2">
            <span class="tab-title">{{'SM.Save' | translate}}</span>
          </div>
        </ng-template>
        <div class="styling-overflow-container" [style.height.px]="stylingContainerHeight">
          <app-save
            [ibss]="ibss"
            [templatePayload]="ibss.templatePayload"
            [strictReadOnly]="strictReadOnly"
            (invalidTagFormEmitter)="showErrorFields()"
          ></app-save>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>


