import { BaseModelService } from '../base.model.service';
import { DashboardWidgetModel } from 'src/app/models/dashboard/dashboard-widget.model';
import { EventDonationModel } from '../../models/event/event-donation.model';
import { EventDonationWidgetModel } from 'src/app/models/event/event-donation-widget.model';
import { EventThermometerWidgetModel } from '../../models/event/event-thermometer-widget.model';
import { flatMap, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Paging } from '../../models/paging/paging.model';
import { PagingHelper } from '../helper/paging.helper';
import { TotalRowsModel } from '../../models/paging/totalrow.model';
import DashboardModel from '../../models/dashboard/dashboard.model';
import EventModel from '../../models/event/event.model';
import EventParticipantModel from '../../models/event/event.participant.model';
import SocialPostModel from '../../models/socialMedia/social.post.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseModelService<DashboardModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }

  protected get controllerName(): string {
    return 'Dashboard';
  }

  public getYTDDonationsWidget(clientId: string): Observable<DashboardWidgetModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<DashboardWidgetModel>(`${config.apiURL}/${this.controllerName}/YTDDonationsWidget/${clientId}`);
      })
    );
  }

  public getTopDonationWidget(filter?: Paging): Observable<EventDonationWidgetModel> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<EventDonationWidgetModel>(
          `${config.apiURL}/${this.controllerName}/TopDonationWidget`,
          { params }
        );
      })
    );
  }

  public getThermometerInfo(eventId: string): Observable<EventThermometerWidgetModel> {
    const param: HttpParams = new HttpParams().set('eventId', eventId);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<EventThermometerWidgetModel>(`${config.apiURL}/${this.controllerName}/ThermometerWidget`, { params: param });
      })
    );
  }

  public getYTDDonorsWidget(paging: Paging): Observable<DashboardWidgetModel> {
    const params: HttpParams = PagingHelper.getPagingParams(paging);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<DashboardWidgetModel>(`${config.apiURL}/${this.controllerName}/YTDDonorRetentionWidget`, {params});
      })
    );
  }

  public getDonorRetentionRateWidget(paging: Paging): Observable<DashboardWidgetModel> {
    const params: HttpParams = PagingHelper.getPagingParams(paging);
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<DashboardWidgetModel>(`${config.apiURL}/${this.controllerName}/RetentionRateWidget`, {params});
      })
    );
  }

  public getUpcomingEventsList(filter?: Paging): Observable<EventModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(flatMap(config => {
      return this.http.get<EventModel[]>(`${config.apiURL}/${this.controllerName}/UpcomingEventsWidget`, { params });
    })
    );
  }

  public getUpcomingEventsDonationsList(filter?: Paging): Observable<EventDonationModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(flatMap(config => {
      return this.http.get<EventDonationModel[]>(`${config.apiURL}/${this.controllerName}/UpcomingEventsWidgetDonations`, { params });
    })
    );
  }

  public getUpcomingEventsParticipantsList(filter?: Paging): Observable<EventParticipantModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(flatMap(config => {
      return this.http.get<EventParticipantModel[]>(`${config.apiURL}/${this.controllerName}/UpcomingEventsWidgetParticipants`, { params });
    })
    );
  }

  public getEventsSummaryEventsList(filter?: Paging): Observable<EventModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(flatMap(config => {
      return this.http.get<EventModel[]>(`${config.apiURL}/${this.controllerName}/EventsSummaryWidgetEvents`, { params });
    })
    );
  }

  public getEventsSummaryEventsListTotal(filter?: Paging): Observable<number> {
    const params: HttpParams = PagingHelper.getPagingParams(filter, false);
    return this.configService.loadConfig().pipe(flatMap(config => {
      const url = `${config.apiURL}/${this.controllerName}/EventsSummaryWidgetEventsTotal`;
      return this.http.get<TotalRowsModel>(url, { params }).pipe(map(model => model.total));
    })
    );
  }

  public getEventsSummaryParticipantsList(filter?: Paging): Observable<EventParticipantModel[]> {
    const params: HttpParams = PagingHelper.getPagingParams(filter);
    return this.configService.loadConfig().pipe(flatMap(config => {
      return this.http.get<EventParticipantModel[]>(`${config.apiURL}/${this.controllerName}/EventsSummaryWidgetEventParticipants`, { params });
    })
    );
  }

  public getTopPostById(id: string): Observable<SocialPostModel> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        return this.http.get<SocialPostModel>(`${config.apiURL}/${this.controllerName}/TopPostsWidgetPost/${id}`);
      })
    );
  }
}
