import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import DonorEmployeeModel from 'src/app/models/donor/donor.employee.model';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpdateDataService {
  private userProfileUpdated: Subject<void> = new Subject<void>();
  public userProfileUpdatedSubscriber$: Observable<any> = this.userProfileUpdated.asObservable();

  private donorConnectionsUpdated: Subject<void> = new Subject<void>();
  public donorConnectionsUpdatedSubscriber$: Observable<any> = this.donorConnectionsUpdated.asObservable();

  private postListUpdated: Subject<void> = new Subject<void>();
  public postListUpdatedSubscriber$: Observable<any> = this.postListUpdated.asObservable();

  private timeSlotsListUpdated: Subject<void> = new Subject<void>();
  public timeSlotsListUpdatedSubscriber$: Observable<any> = this.timeSlotsListUpdated.asObservable();

  private donorSubject: BehaviorSubject<DonorEmployeeModel> = new BehaviorSubject<DonorEmployeeModel>(null);
  public donorConnectionsClientIds$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  private clientProfileUpdated: Subject<void> = new Subject<void>();
  public clientProfileUpdatedSubscriber$: Observable<any> = this.clientProfileUpdated.asObservable();

  public isIncludeFeeByDefault$: Subject<boolean> = new Subject<boolean>();

  private infinitScrollSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public segmentFilterUsed$: Subject<void> = new Subject<void>();
  public setInfititeScroll(donor: any): void {
    this.infinitScrollSubject.next(donor);
  }

  public getInfititeScroll(): Observable<any> {
    return this.infinitScrollSubject.asObservable();
  }

  public setDonor(donor: DonorEmployeeModel): void {
    this.donorSubject.next(donor);
  }

  public getDonor(): Observable<DonorEmployeeModel> {
    return this.donorSubject.asObservable()
      .pipe(
        filter(value => !!value),
        map((donor: DonorEmployeeModel) => {
          if (!donor.birthDate || (!!donor.birthDate && donor.birthDate.toString() === '0001-01-01T00:00:00+00:00')) {
            donor.birthDate = null;
          }
          return donor;
        })
      );
  }

  public userProfileWasUpdated(): void {
    this.userProfileUpdated.next();
  }

  public donorConnectionsWasUpdated(): void {
    this.donorConnectionsUpdated.next();
  }

  public postListWasUpdated(): void {
    this.postListUpdated.next();
  }

  public timeSlotsListWasUpdated(): void {
    this.timeSlotsListUpdated.next();
  }

  public clientProfileWasUpdated(): void {
    this.clientProfileUpdated.next();
  }
}
