<div class="history-donations-table-container">
  <table
    mat-table
    [dataSource]="tableSource"
    *ngIf="tableSource"
  >
    <ng-container
      [matColumnDef]="column"
      *ngFor="let column of tableDataColumns"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        class="table-header"
      >
        <div class="table-header-title-wrapper">
          <span class="title">{{getColumnTitle(column) | translate}}</span>
        </div>
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="widget-table-cell"
        [appTooltip]="tooltip"
      >
        <div
          matTooltip="{{element[column]}}"
          #tooltip="matTooltip"
          matTooltipPosition="above"
          matTooltipClass="table-tooltip"
        ></div>
        <div class="cell-data" *ngIf="column === 'donationStatus'; else textNode">
          <div [ngClass]="getStatusCssClass(element)"></div>
          <span [class.status]="column === 'donationStatus'">{{getTextNode(element, column)}}</span>
        </div>
        <ng-template #textNode>
          <span>{{getTextNode(element, column)}}</span>
        </ng-template>

      </td>
    </ng-container>

    <ng-container matColumnDef="settings">
      <th
        mat-header-cell
        *matHeaderCellDef
      ></th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <button class="settings-container" mat-icon-button [matMenuTriggerFor]="settingsMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu
          #settingsMenu
          overlapTrigger
          xPosition="before"
        >
          <!--<div class="table-action-menu">
            <div class="menu-items">
              <button
                mat-menu-item
                (click)="onEditLinkClick(element)"
              >{{getEditSeeDetailTextNode(element)}}</button>
              <button
                *ngIf="isCancelArchiveAvailable(element)"
                (click)="onCancelArchiveEvent(element)"
                mat-menu-item
              >{{getCancelArchiveTextNode(element)}}</button>
              <button
                mat-menu-item
                class="menu-close"
              >
                <i class="fas fa-times-circle"></i>
              </button>
            </div>
          </div>-->
        </mat-menu>

      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="tableColumns; sticky: true"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: tableColumns;"
      class="table-row"
    ></tr>

  </table>

</div>
