<div class="report-header">
  <div class="report-title-wrapper">
    <span class="report-title">{{title | translate | uppercase}}</span>
    <a class="event-dashboard-back-link" routerLink="/clients/reports">
      < {{'REPORTS.Back to All Reports' | translate}} </a>
  </div>
  <app-client-select
    *ngIf="clientsWithRelationships.length > 1"
    [formGroup]="formGroup"
    name="clientID"
    (clientChanged)="clientChanged($event)"
    [selectOptions]="clientsWithRelationships"
  >

  </app-client-select>
</div>
