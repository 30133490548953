<div class="header">
  {{header | translate}}
</div>
<div class="combobox-container">
  <div [matMenuTriggerFor]="menu" class="combobox" *ngIf="!isReadOnly" [class.combobox-error]="formControl.invalid && formControl.touched">
    <span>{{label | translate}}</span>
    <mat-menu #menu="matMenu">
      <app-filtered-listbox [isCustomValueAddingAllow]="isCustomValueAddingAllow" [toggleOptions]="toggleOptions"
                            [buttonEnablingValues]="buttonEnablingValues"
                            (changeFilterType)="onChangeFilterType($event)"
                            [modalLabel]="modalLabel"
                             (selectedValue)="selectValue($event)" [items]="items"></app-filtered-listbox>
    </mat-menu>
  </div>
  <app-button *ngIf="isClearButtonVisible && !isReadOnly" style="margin-left:10px" label="{{'N/A' | translate}}" type="flat" color="#0064be" (clickEvent)="clear()"></app-button>
</div>
<mat-error *ngIf="formControl.invalid && formControl.touched" class="error">{{getErrorMessage()}}</mat-error>
<div *ngIf="isReadOnly" class="label-only">
  {{label | translate}}
</div>
