import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import {ClientModuleService} from "../../../routes/clients/client.module.service";
import {ClientIdStateService} from "../../../services/client.module.state/client.id.state.service";
import {Subscription, zip} from "rxjs";
import FormElementDataModel from "../../../models/form.element.data.model";
import {AuthService} from "../../../services/auth.service";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['../../../routes/clients/reports/components/reports-common.scss']
})
export class ReportHeaderComponent implements OnInit {
  @Input() title: string = '';
  @Output() clientIdChanged: EventEmitter<void> = new EventEmitter<void>();

  private subscription: Subscription = new Subscription();
  public clientsWithRelationships: FormElementDataModel[] = [];
  constructor(
    private clientModuleService: ClientModuleService,
    private clientIdStateService: ClientIdStateService,
    private authService: AuthService,
  ) {}
  ngOnInit() {
    this.subscription.add(
      zip(this.clientModuleService.clientsWithRelationships, this.clientModuleService.clientName)
        .subscribe(([clientsWithRelationships, clientName]) => {
          this.clientsWithRelationships = [{ label: clientName, value: this.authService.getIdentityClaimsOriginId() }, ...clientsWithRelationships];
        })
    );
  }
  public get formGroup(): FormGroup {
    return this.clientIdStateService.clientIdForm;
  }
  public clientChanged(clientID: string): void {
    const name = this.clientsWithRelationships.find((option: FormElementDataModel) => option.value === clientID).label;
    this.clientIdStateService.setSelectedRelationshipClient(clientID, name);
    this.clientIdChanged.emit();
  }
}
