<ng-container *ngIf="!isNew">
  <div [ngSwitch]="type">

    <div *ngSwitchCase="'flat'">
      <div
        class="app-component-button app-component-button-flat"
        [class.disabled-button]="disabled"
        (click)="onClick()"
        [ngClass]="{fullWidth: isFullWidth}"
        [ngStyle]="{'width.px': width, 'backgroundColor': (disabled ? '#afafaf' : color), 'border': '1px solid' + (disabled ? '#afafaf' : color)}"
        matRipple
      >
      <span
        class="app-component-button-text"
        *ngIf="label"
        [style.color]="labelColor"
        [ngStyle]="{'font-size': fontSize, 'font-family': fontFamily }"
        [class.app-component-white]="disabled"
      >{{isAdminPortal ? (label | titlecase | uppercase) : isTitleCaseText ? (label | titlecase | translate) : (label | titlecase | translate | uppercase)}}</span>
        <div class="app-component-button-text color" [style.color]="labelColor" 
        [ngStyle]="{'font-size': fontSize, 'font-family': fontFamily }"
        [ngClass]="{bgImageBtn: isBgImageBtn}" *ngIf="!label"
        >
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      </div>
    </div>


    <div *ngSwitchCase="'stroked'">
      <div class="app-component-button app-component-button-stroked" (click)="onClick()" [ngClass]="{fullWidth: isFullWidth}" [ngStyle]="{'minWidth': width + 'px', 'width': width, 'border': '1px solid' + getBorderColor}" matRipple>
        <span *ngIf="label" class="app-component-button-text" [style.color]="disabled ? '#afafaf' : color">{{isAdminPortal ? (label | titlecase | uppercase) : (label | titlecase | translate | uppercase)}}</span>
        <div *ngIf="!label" class="app-component-button-text" [style.color]="disabled ? '#afafaf' : color">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      </div>
    </div>

    <div
      *ngSwitchCase="'basic'"
      class="app-component-button app-component-button-basic"
      (click)="onClick()"
      [ngClass]="{fullWidth: isFullWidth}"
      [ngStyle]="{'minWidth': width + 'px', 'width': width}"
      matRipple
    >
    <span
      *ngIf="label"
      class="app-component-button-text"
      [style.color]="color"
    >{{isAdminPortal ? label : (label | translate)}}</span>
      <div
        *ngIf="!label"
        class="app-component-button-text"
        [style.color]="color"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>

  </div>

</ng-container>

<!--new-->

<ng-container *ngIf="isNew">
  <div [ngSwitch]="type">

    <div *ngSwitchCase="'flat'">
      <div
        class="new-app-component-button app-component-button-flat"
        [class.disabled-button]="disabled"
        (click)="onClick()"
        [ngStyle]="{'width.px': width}"
        [ngClass]="{
              fullWidth: isFullWidth,
              primaryFlat: buttonColorType === 'primary',
              warnFlat:  buttonColorType === 'warn'
              }"
        matRipple
      >
      <span
        class="app-component-button-text"
        *ngIf="label && !isUpperCase"
        [style.color]="labelColor"
        [ngStyle]="{'font-size': fontSize, 'font-family': fontFamily }"
        [class.app-component-white]="disabled"
      >
        <app-icons
          *ngIf="iconType"
          [iconType]="iconType"
          color="{{getColor}}"
        ></app-icons>
        {{isAdminPortal ? (label | titlecase | uppercase) : (label | titlecase | translate | uppercase)}}
      </span>
        <span
          class="app-component-button-text"
          *ngIf="label && isUpperCase"
          [style.color]="labelColor"
          [ngStyle]="{'font-size': fontSize, 'font-family': fontFamily }"
          [class.app-component-white]="disabled"
        >{{isAdminPortal ? (label | titlecase ) : (label | titlecase | translate)}}</span>
        <div class="app-component-button-text color" 
          [style.color]="labelColor" 
          [ngClass]="{bgImageBtn: isBgImageBtn}" 
          [ngStyle]="{'font-size': fontSize, 'font-family': fontFamily }"
          *ngIf="!label">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="'stroked'">
      <div
        class="new-app-component-button app-component-button-stroked"
        (click)="onClick()"
        [ngStyle]="{'minWidth': width + 'px', 'width': width, 'height.px': height}"
        [class.disabled-button]="disabled"
        [ngClass]="{
            fullWidth: isFullWidth,
            primaryStroked: buttonColorType === 'primary',
            warnStroked: buttonColorType === 'warn'
            }"
        matRipple
      >
      <span
        *ngIf="label && !isUpperCase"
        class="app-component-button-text"
      >
        <app-icons
          *ngIf="iconType"
          [iconType]="iconType"
          color="{{getColor}}"
        ></app-icons>
        {{isAdminPortal ? (label | titlecase | uppercase) : (label | titlecase | translate | uppercase)}}
      </span>
        <span
          class="app-component-button-text"
          *ngIf="label && isUpperCase"
          [style.color]="labelColor"
          [ngStyle]="{'font-size': fontSize, 'font-family': fontFamily }"
          [class.app-component-white]="disabled"
        >{{isAdminPortal ? (label | titlecase ) : (label | titlecase | translate)}}</span>
        <div
          *ngIf="!label"
          class="app-component-button-text"
          [style.color]="disabled ? '#afafaf' : color"
        >
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      </div>
    </div>

    <div
      *ngSwitchCase="'basic'"
      class="new-app-component-button app-component-button-basic"
      (click)="onClick()"
      [class.disabled-button]="disabled"
      [ngStyle]="{'minWidth': width + 'px', 'width': width, 'height.px': height}"
      [ngClass]="{
              fullWidth: isFullWidth,
              primaryBasic: buttonColorType === 'primary',
              darkBasic:  buttonColorType === 'dark',
              warnBasic: buttonColorType === 'warn'
              }"
      matRipple
    >
    <span
      *ngIf="label && !isUpperCase"
      class="app-component-button-text"
    >
      <app-icons
        *ngIf="iconType"
        [iconType]="iconType"
        color="{{getColor}}"
      ></app-icons>
      {{isAdminPortal ? (label | titlecase | uppercase) : (label | titlecase | translate | uppercase)}}
    </span>
      <span
        class="app-component-button-text"
        *ngIf="label && isUpperCase"
        [class.app-component-white]="disabled"
      >{{isAdminPortal ? (label | titlecase ) : (label | titlecase | translate)}}</span>
      <div
        *ngIf="!label"
        class="app-component-button-text"
        [style.color]="color"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>

    <div
      *ngSwitchCase="'icon-button'"
      class="app-icon-button"
      (click)="onClick()"
      [class.bgc-white]="isWhiteBgc"
      [class.disabled-button]="disabled"
    >
      <app-icons
        *ngIf="iconType"
        [iconType]="iconType"
        [color]="color"
      ></app-icons>
    </div>
  </div>

</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>


