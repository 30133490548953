<div
  [formGroup]="formGroup"
  class="time-select-component-container-wrapper"
>
  <label
    class="label-name"
    [for]="id"
  >{{label}}</label>

  <div class="ngx-timepicker-field">
    <ngx-timepicker-field
      [id]="id"
      [defaultTime]="formControl.value"
      [toggleIcon]="icon"
      [disabled]="disabled"
      #ngxTimePickerFieldRef
    ></ngx-timepicker-field>
  </div>

  <div class="error-message">
    <span *ngIf="formControl.touched && formControl.invalid">{{getErrorMessage()}}</span>
  </div>

</div>


<ng-template #icon>
  <div class="toggle-icon">
    <i class="far fa-clock"></i>
  </div>
</ng-template>
