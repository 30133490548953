import { AuthService } from '../../../../services/auth.service';
import { CLIENT_DONATIONS_COLUMNS, CLIENT_DONATIONS_COLUMNS_TEMPLATE } from '../../../../constants';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { EventDonationModel } from '../../../../models/event/event-donation.model';
import { EventDonationsService } from '../../../../services/events/event-donations.service';
import { Filter } from '../../../../models/paging/filter.model';
import { FilterType } from '../../../../models/enum/filter.type';
import { MatDialog } from '@angular/material/dialog';
import { Paging } from '../../../../models/paging/paging.model';
import { Router } from '@angular/router';
import { SortOrder } from '../../../../models/enum/sort.order';
import {Observable, Subject, Subscription, zip} from 'rxjs';
import { BasePagingComponent } from '../../../../components/paginator/base.paging.component';
import ColumnFilterOptionsModel from '../../../../models/filter-sort/column.filter.options.model';
import FilterSortModel from '../../../../models/filter-sort/filter.sort.model';
import ColumnFilterModel from '../../../../models/filter-sort/column.filter.model';
import { DonationStatusType } from '../../../../models/enum/donation.status.type';
import { DonationMethodType } from '../../../../models/enum/donation.method.type';
import { CommentDialogComponent } from '../../../../components/comment-dialog/comment-dialog.component';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { ClientModuleService } from '../../client.module.service';
import { first, switchMap, tap, skip } from 'rxjs/operators';
import { LazyLoadRequestModel } from '../../../../models/filter-sort/lazy.load.search.model';
import { ClientIdStateService } from '../../../../services/client.module.state/client.id.state.service';
import { GiftType } from '../../../../models/enum/gift.type';
import { ClientAllocatedFundModel } from '../../../../models/client/client-allocated-fund.model';
import { ClientAllocatedFundService } from '../../../../services/client/client-allocated-fund.service';
import { ClientType } from 'src/app/models/enum/client.type';
import { UtilsComponent } from '../../../../components/utils.component';

const FILTERED_FIELDS = ['donationStatus', 'donationMethod', 'isAnonymous', 'origin', 'allocatedFundID', 'giftType', 'tierName'];

@Component({
  selector: 'app-recent-donations',
  templateUrl: './recent-donations.component.html',
  styleUrls: ['./recent-donations.component.scss', '../widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecentDonationsComponent extends BasePagingComponent implements OnInit, OnDestroy {
  public subscription: Subscription = new Subscription();
  public eventDonations: EventDonationModel[] = [];
  public filter: Filter[] = [];
  public tableColumns: string[] = [...CLIENT_DONATIONS_COLUMNS];
  public clientsWithRelationships: FormElementDataModel[] = [];
  public columnsFilterOptions: ColumnFilterOptionsModel[] = [];
  private clientOptionWasChanged$: Subject<void> = new Subject<void>();
  public currentClientID: string = this.authService.getIdentityClaimsOriginId();
  public allocatedFunds: ClientAllocatedFundModel[] = [];

  public filterSortConfig: FilterSortModel = {
    sortField: 'createdOn',
    sortOrder: SortOrder.Descending,
    columnFilters: []
  };
  public showSpinner: boolean = true;
  private baseFilter: Filter[];

  public distinctData: any = {
    donorFullName: {items: [], total: 0},
    sourceValue: {items: [], total: 0},
  };

  public childParentOrgChange: NodeJS.Timeout;

  constructor(
    private eventDonationsService: EventDonationsService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private clientModuleService: ClientModuleService,
    private cdr: ChangeDetectorRef,
    private clientIdStateService: ClientIdStateService,
    private clientAllocatedFundService: ClientAllocatedFundService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initializeData();
    this.childParentOrgChange = setInterval(() => {
      if (localStorage.getItem('currentSelectedClientID') !== this.currentClientID) {
        this.showSpinner = true;
        this.eventDonations = [];
        this.cdr.markForCheck();
        this.initializeData();
      }
    }, 2000)
    this.subscription.add(
        this.clientOptionWasChanged$.asObservable().pipe(
            switchMap(() => {
              this.distinctData = {
                donorFullName: {items: [], total: 0},
                sourceValue: {items: [], total: 0},
              }
             this.filterSortConfig.columnFilters = this.filterSortConfig.columnFilters.filter(c => c.field === 'clientID');
             return this.getDistinctValues();
            })

        ).subscribe()
    )
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    clearInterval(this.childParentOrgChange);
  }

  private initializeData(): void {
    zip(this.clientModuleService.clientsWithRelationships, this.clientModuleService.clientName, this.clientIdStateService.clientIdObservable, this.clientModuleService.client)
    .pipe(
      first(),
      switchMap(([clientsWithRelationships, clientName, clientId, client]) => {
        this.distinctData = {
          donorFullName: {items: [], total: 0},
          sourceValue: {items: [], total: 0},
        }
        this.clientsWithRelationships = [
          {
            label: clientName,
            value: this.authService.getIdentityClaimsOriginId(),
            isPAC: client.clientType === ClientType.PAC,
          },
          ...clientsWithRelationships,
        ];
        this.baseFilter = this.getBaseFilter();
        const columnFilter: ColumnFilterModel = { field: 'clientID', values: [clientId] };
        this.onCurrentFilterChanged(columnFilter, true);
        this.currentClientID = clientId;
        return zip(this.getDistinctValues(), this.getAllocatedFunds())
      }),
    )
    .subscribe();
  }

  private getAllocatedFunds(): Observable<ClientAllocatedFundModel[]> {
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: true,
      filters: this.getBaseFilter()
    };
    return this.clientAllocatedFundService.getModelList(paging)
      .pipe(tap((funds: ClientAllocatedFundModel[]) => this.allocatedFunds = funds));
  }

  private getBaseFilter(): Filter[] {
    const ids: string[] = [this.clientIdStateService.selectedRelationshipClientId];
    const idsArray = `array[${ids.join(',')}]`;
    return [{
      field: 'clientID',
      value: idsArray,
      type: FilterType.Equal
    }];
  }

  private getDonations(): void {
    this.showSpinner = true;
    const paging: Paging = {
      first: this.getFirstItemOnPage(),
      rows: this.entriesPerPage,
      includeDependencies: false,
      includeDeleted: false,
      filters: this.filters,
      sortField: this.filterSortConfig.sortField,
      sortOrder: this.filterSortConfig.sortOrder,

      selectFields: ['id', 'donorFullName', 'countryId', 'donationAmmount', 'donationRealDate', 'donationStatus', 'donationMethod', 'donationSource', 'origin', 'publicID', 'isAnonymous', 'organizationName', 'federalIDNumber', 'sourceValue', 'donationType', 'clientID', 'reccuringId', 'allocatedFundID', 'giftType', 'donorId', 'giveType', 'tierName'],
    };
    this.subscription.add(
      this.eventDonationsService.getModelList(paging)
        .subscribe((eventDonations: EventDonationModel[]) => {
          this.eventDonations = eventDonations;
          this.showSpinner = false;
          this.cdr.markForCheck();
        })
    );
  }

  private getTotal(): void {
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: false,
      filters: this.filters,
    };
    this.subscription.add(
      this.eventDonationsService.getTotal(paging)
        .subscribe((total: number) => {
          this.total = total;
          this.cdr.markForCheck();
        })
    );
  }

  private getDistinctValues(): Observable<any> {
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: false,
      filters: this.filters,
      selectFields: FILTERED_FIELDS
    };
    return this.eventDonationsService.getDistinctValues(paging)
      .pipe(tap(this.onDistinctValuesReceived.bind(this)));
  }

  private onDistinctValuesReceived(distinctValues: any): void {
    this.columnsFilterOptions = FILTERED_FIELDS.map((column: string) => {
      const optionsList = distinctValues[column].filter((item: string) => item !== null && item !== undefined && item !== '');
      let options;
      if (column === 'donationStatus') {
        options = optionsList.map((option: number) => {
          const status = DonationStatusType[option].toString();
          return {
            label: status ? status : option,
            value: option
          }
        });
      } else if (column === 'giftType') {
        options = optionsList.map((option: number) => {
          let giftType = GiftType[option];
          if (giftType) {
            giftType = giftType.toString();
            return {
              label: giftType ? giftType : option,
              value: option
            }
          }
        });
      } else if (column === 'donationMethod') {
        options = optionsList.map((option: number) => {
          const status = DonationMethodType[option].toString();
          return {
            label: status ? status : option,
            value: option
          }
        });
      } else if (column === 'isAnonymous') {
        options = optionsList.map((option: boolean) => ({ label: option ? 'Yes' : 'No', value: option }))
      } else if (column === 'allocatedFundID') {
        options = optionsList.map((option: string) => {
          const fund = this.allocatedFunds.find(({ id }) => id === option);
          return { label: fund ? fund.name : option, value: option }
        })
      } else {
        options = optionsList.map((option: string) => ({ label: option, value: option }))
      }
      return {
        columnName: column,
        options
      };
    });
    this.columnsFilterOptions.push({
      columnName: 'clientID',
      options: this.clientsWithRelationships
    });
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  public onSortChanged(event: FilterSortModel): void {
    this.filterSortConfig.sortField = event.sortField;
    this.filterSortConfig.sortOrder = event.sortOrder;
    this.getDonations();
  }

  public editRow(event: string): void {
    this.router.navigateByUrl(`clients/contributions/${event}`);
  }

  public deleteRow(event: string): void {
    const config = {
      data: {
        title: 'Please, enter reasons why you delete this donation record'
      }
    };
    this.subscription.add(
      this.dialog.open(CommentDialogComponent, config).afterClosed().pipe(switchMap(content => {
        if (!content) {
          return;
        }
        const donation = this.eventDonations.find(x => x.id === event);
        donation.reason = content;
        donation.deletedByUserName = this.authService.getIdentityClaimsName();
        donation.deletedByID = this.authService.getIdentityClaimsId();
        donation.isDeleted = true;
        donation.deletedOn = new Date();
        return this.eventDonationsService.updateModel(donation);
      })).subscribe(() => {
        this.getNextPage(0);
        this.getTotal();
      })
    );
  }

  public getNextPage(page: number): void {
    this.nextPage(page, () => this.getDonations());
  }

  public setEntriesPerPage(amount: number): void {
    this.entriesPerPageChanged(amount, () => this.getDonations());
  }

  public viewAll(): void {
    this.router.navigateByUrl('/clients/reports/financial/itemized-donations');
  }

  private get tableFilter(): Filter[] {
    if (!this.filterSortConfig.columnFilters.length) {
      return [];
    } else {
      return this.filterSortConfig.columnFilters.map((columnFilter: ColumnFilterModel) => {
        if (columnFilter.field === 'isAnonymous') {
          return {
            field: columnFilter.field,
            value: columnFilter.values.length > 1 ? '' : columnFilter.values[0],
            type: FilterType.Equal,
          };
        } else {
          const values = columnFilter.values.map((item: string) => `"${item}"`).join(',');
          return {
            field: columnFilter.field,
            value: `array[${values}]`,
            type: FilterType.Equal,
          };
        }
      });
    }
  }

  public onCurrentFilterChanged(columnFilter: ColumnFilterModel, skipReloadOptions: boolean = false): void {
    if(columnFilter.field === 'clientID' && !skipReloadOptions) this.clientOptionWasChanged$.next();
    this.filterSortConfig.columnFilters = UtilsComponent.updateColumnFilters(columnFilter, this.filterSortConfig);
    this.tableColumns = UtilsComponent.checkOrgsIsPAC(this.tableColumns, this.clientsWithRelationships, columnFilter);
    this.onFilterChanged();
    this.getDonations();
    this.getTotal();
  }

  private get filters(): Filter[] {
    const clientFilter: Filter[] = !this.tableFilter.find((filter: Filter) => filter.field === 'clientID') ? this.baseFilter : [];
    return [
      ...clientFilter,
      {
        field: 'donationStatus',
        value: DonationStatusType.Declined.toString(),
        type: FilterType.NotEqual
      },
      ...this.tableFilter,
      this.pastNinetyDaysFilter,
    ];
  }

  private get pastNinetyDaysFilter(): Filter {
    const nowDate = new Date();
    const ninetyDaysMS = 90 * 24 * 60 * 60 * 1000;
    const ninetyDaysAgo = +nowDate - ninetyDaysMS;
    return  {
      field: 'donationRealDate',
      value: new Date(ninetyDaysAgo).toISOString(),
      type: FilterType.GreaterThanOrEqual
    };
  }

  public getOptions(searchModel: LazyLoadRequestModel): void {
    const result = [];
    const clientFilter = this.tableFilter.find((filter: Filter) => filter.field === 'clientID')
    const clientFilterForOptions: Filter[] = clientFilter ? [clientFilter] : this.baseFilter;



    const paging: Paging = {
      includeDependencies: false,
      selectFields: [searchModel.field],
      first: searchModel.first,
      rows: searchModel.rows,
      filters: [{
        field: searchModel.field,
        value: searchModel.searchValue,
        type: FilterType.Contains
      },
        {
          field: 'donationStatus',
          value: DonationStatusType.Declined.toString(),
          type: FilterType.NotEqual
        },
        this.pastNinetyDaysFilter,
        ...clientFilterForOptions]
    };
    this.subscription.add(
      this.eventDonationsService.getDistinctValues(paging).subscribe(response => {
        response[searchModel.field].forEach((item: string) => result.push({label: item, value: item}));
        this.distinctData[searchModel.field].items = result;
        this.distinctData[searchModel.field].total += response[searchModel.field].length + 1;
        this.cdr.markForCheck();
      }));
  }
}
