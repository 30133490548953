<div class="email-template-builder-wrapper" *ngIf="tms.availablePlaceholders$ | async">

  <div class="template-block-wrapper">
    <!-- Undo / Redo -->
    <div class="email-template-builder-top-bar">
      <div
        (click)="tms.undo()"
        class="top-bar-button"
        [class.disabled]="tms.undoDisable"
        matRipple
        [matRippleDisabled]="tms.undoDisable"
      >
        <div class="action-container">
          <span>{{'Undo' | translate}}</span>
          <i class="fas fa-undo-alt"></i>
        </div>
      </div>

      <div
        (click)="tms.redo()"
        class="top-bar-button"
        [class.disabled]="tms.redoDisable"
        matRipple
        [matRippleDisabled]="tms.redoDisable"
      >
        <div class="action-container">
          <span>{{'Redo' | translate}}</span>
          <i class="fas fa-redo-alt"></i>
        </div>
      </div>

      <div
        (click)="openPreview()"
        class="top-bar-button"
        matRipple
      >
        <div class="action-container">
          <span>{{'Preview' | translate}}</span>
          <i class="fas fa-eye"></i>
        </div>
      </div>

      <div
        (click)="exportPDF()"
        class="top-bar-button"
        matRipple
      >
        <div class="action-container">
          <span>PDF</span>
          <i class="fas fa-file-pdf"></i>
        </div>
      </div>

      <div
        *ngIf="!tms.isLetterBuilder"
        (click)="exportDOCX()"
        class="top-bar-button"
        matRipple
      >
        <div class="action-container">
          <span>DOCX</span>
          <i class="fas fa-file-word"></i>
        </div>
      </div>
    </div>

    <!-- Template -->


    <div class="template-container-wrapper" [ngClass]="{forbidden: canEdit()}">
      <div class="template-container" #templateContainerRef>
        <!-- Header -->
        <div *ngIf="tms.templateAssignmentType === TemplateAssignmentType.Event && headerFooter" class="template-header" (click)="activateHeaderFooter()" [class.active-row]="tms.activeRow?.tagName === MjmlTag.body" [ngStyle]="headerStyle">
          <img *ngIf="logo" [src]="logo" [ngStyle]="logoStyle">
        </div>

        <div class="template-list" [style.padding]="tms.templateAssignmentType === TemplateAssignmentType.Event ? '0 30px' : '110px 30px 20px'" [ngStyle]="getBackgroundStyles()">
          <!--List-->
          <div
            cdkDropList
            #mjmlBodyChildrenList="cdkDropList"
            [cdkDropListData]="templateBody"
            [cdkDropListConnectedTo]="[sectionsList]"
            class="template-list-body"
            (cdkDropListDropped)="drop($event)"
            [ngStyle]="getTemplateBodyStyles()"
          >
            <!--Row-->
            <div
              *ngFor="let rowElement of templateBody; index as i"
              class="template-list-row"
              cdkDrag
              [cdkDragDisabled]="readOnly || predesignedTemplate || isTemplateLetterFooterSection(rowElement)"
              [class.active-row]="tms.activeRow === rowElement"
              [style.width]="this.template.children[1].attributes['width'] || '900px'"
            >
              <div *cdkDragPreview class="preview-drag-block preview-section-block">{{'EVENTS.Section block' | translate}}</div>

              <div *ngIf="!(readOnly || predesignedTemplate)" class="row-handle" cdkDragHandle>
                <i class="fas fa-arrows-alt-v"></i>
              </div>
              <div *ngIf="!(readOnly || predesignedTemplate || rowElement?.children[0]?.children[0]?.attributes['deletable'] === 'false')" class="remove-row-handle" (click)="removeSection(i)">
                <i class="far fa-trash-alt"></i>
              </div>
              <div
                cdkDropList
                cdkDropListOrientation="horizontal"
                class="template-list-row-columns"
                (cdkDropListDropped)="horizontalDrop($event, rowElement.children)"
                [ngStyle]="getRowStyles(rowElement)"
                (click)="setActiveRow(rowElement)"
              >

                <!-- Row Column-->
                <div
                  class="list-row-column"
                  *ngFor="let column of rowElement.children; index as j"
                  cdkDrag
                  [cdkDragDisabled]="readOnly || predesignedTemplate"
                  (dragover)="setDropContainer($event, column, rowElement, tooltip)"
                  (dragleave)="removeDropContainer(tooltip)"
                  (click)="setActiveColumn(column)"
                  [class.active-column]="tms.activeColumn === column"
                  [ngStyle]="getColumnStyles(column)"
                  #tooltip="matTooltip"
                  [matTooltip]="'EVENTS.' + tooltipRestrictionMessage | translate"
                  (mouseenter)="mouseEnter(tooltip)"
                >
                  <div *cdkDragPreview class="preview-drag-block preview-column-block">{{'EVENTS.Column block' | translate}}</div>

                  <div *ngIf="!(readOnly || predesignedTemplate)" class="row-column-handle" cdkDragHandle>
                    <i class="fas fa-arrows-alt-h"></i>
                  </div>
                  <!-- Column templates-->
                  <app-tools-renderer
                    *ngIf="column.children.length; else emptyContainerTemplate"
                    [column]="column"
                    [tms]="tms"
                    [eventId]="eventId"
                  ></app-tools-renderer>

                  <ng-template #emptyContainerTemplate>
                    <div class="empty-container"><span>{{'EVENTS.DnD block' | translate}}</span></div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer -->
        <div *ngIf="tms.templateAssignmentType === TemplateAssignmentType.Event && headerFooter" class="template-footer" [ngStyle]="footerStyle" (click)="activateHeaderFooter()" [class.active-row]="tms.activeRow?.tagName === MjmlTag.body">
          <div class="item">
            <img src="/assets/images/logo1.png" style="margin-right:10px;">
            <a style="color:white;" href="https://www.theauxilia.com">Powered by Auxilia</a>
            <a style="color:#93ccff;margin-left:15px;" href="https://www.theauxilia.com/privacy-policy?__hstc=181257784.ff7e06784cd8dfcd424620280a8af09b.1581499076821.1618415342297.1618475974870.854&__hssc=181257784.17.1618475974870&__hsfp=1488147824">{{'EVENTS.Privacy Policy' | translate}}</a>
          </div>
          <div class="item">
            <div class="sm-link" *ngIf="headerFooterAttributes.facebookLink">
              <a [href]="headerFooterAttributes.facebookLink">
                <i class="fab fa-facebook"></i>
              </a>
            </div>

            <div class="sm-link" *ngIf="headerFooterAttributes.twitterLink">
              <a [href]="headerFooterAttributes.twitterLink">
                <i class="fa6 fab fa-square-x-twitter"></i>
              </a>
            </div>

            <div class="sm-link" *ngIf="headerFooterAttributes.instagramLink">
              <a [href]="headerFooterAttributes.instagramLink">
                <i class="fab fa-instagram"></i>
              </a>
            </div>

            <div class="sm-link" *ngIf="headerFooterAttributes.linkedInLink">
              <a [href]="headerFooterAttributes.linkedInLink">
                <i class="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- Tools -->
  <div class="tools-container">
    <app-button
      *ngIf="!libraryVersion"
      label="Save In Library"
      type="flat"
      color="#0064be"
      (clickEvent)="triggerTemplatePayload()"
      width="100%"
    ></app-button>
    <mat-tab-group
      dynamicHeight
      [selectedIndex]="currentTab"
      (selectedIndexChange)="tabChanged($event)"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="tools-tab-wrapper" [class.active]="currentTab === 0">
            <span class="tab-title">{{'BUILD.Styling' | translate}}</span>
          </div>
        </ng-template>
        <div class="overflow-container">
          <app-email-settings [eventId]="eventId" [tms]="tms"></app-email-settings>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <div class="tools-tab-wrapper" [class.active]="currentTab === 1">
            <span class="tab-title">{{'BUILD.Modules' | translate}}</span>
          </div>
        </ng-template>

        <!-- List of Rows -->
        <div class="content-sections">
          <div
            cdkDropList
            #sectionsList="cdkDropList"
            [cdkDropListData]="availableSections"
            [cdkDropListConnectedTo]="[mjmlBodyChildrenList]"
            class="content-sections-list"
            (cdkDropListDropped)="drop($event)"
            cdkDropListSortingDisabled
            [cdkDropListEnterPredicate]="noReturnPredicate"
          >
            <div
              class="row-example-box"
              *ngFor="let section of availableSections"
              cdkDrag
              [cdkDragDisabled]="readOnly || predesignedTemplate"
            >
              <div class="row-example-box-item" *ngFor="let block of section.children"></div>
            </div>
          </div>
        </div>

        <mat-accordion>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <div class="custom-tools-panel-title">{{'BUILD.Tools' | translate}}</div>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <!-- DnD Elements -->
              <div class="content-sections">
                <div class="content-drag-tools">
                  <!-- Text -->
                  <div [draggable]="!(readOnly || predesignedTemplate)" (dragend)="dropItem(MjmlTag.text)"
                       class="tool-template">
                    <i class="fas fa-align-left"></i>
                    <span>{{'BUILD.Text' | translate}}</span>
                  </div>
                  <!-- Image -->
                  <div [draggable]="!(readOnly || predesignedTemplate)" (dragend)="dropItem(MjmlTag.image)"
                       class="tool-template">
                    <i class="far fa-image"></i>
                    <span>{{'BUILD.Image' | translate}}</span>
                  </div>
                  <!-- Divider -->
                  <div [draggable]="!(readOnly || predesignedTemplate)" (dragend)="dropItem(MjmlTag.divider)"
                       class="tool-template">
                    <i class="fas fa-equals"></i>
                    <span>{{'BUILD.Divider' | translate}}</span>
                  </div>
                  <!-- Logo -->
                  <div [draggable]="!(readOnly || predesignedTemplate)"
                       (dragend)="dropItem(MjmlTag.image, MjmlCustomType.logo)" class="tool-template">
                    <div class="logo-icon">TM</div>
                    <span>{{'BUILD.Logo' | translate}}</span>
                  </div>
                  <!-- Footer -->
                  <div *ngIf="tms.isLetterBuilder" 
                    [draggable]="!(readOnly || predesignedTemplate) && !templateLetterFooterSection" 
                    class="tool-template"
                    (dragend)="addLetterFooter()"
                    [ngClass]="{'tool-template--disabled': !!templateLetterFooterSection}"
                  >
                    <i class="fas fa-columns"></i>
                    <span>{{"BUILD.Footer" | translate}}</span>
                  </div>
                  <!-- Video -->
                  <div
                    *ngIf="tms.templateAssignmentType === TemplateAssignmentType.Event && !tms.isLetterBuilder"
                    [draggable]="!(readOnly || predesignedTemplate)"
                    (dragend)="dropItem(MjmlTag.text, MjmlCustomType.video)"
                    class="tool-template"
                  >
                    <i class="fas fa-video"></i>
                    <span>{{'BUILD.Video' | translate}}</span>
                  </div>
                  <!-- Email Video -->
                  <div
                    *ngIf="tms.templateAssignmentType === TemplateAssignmentType.Campaign && !tms.isLetterBuilder"
                    [draggable]="!(readOnly || predesignedTemplate)"
                    (dragend)="dropItem(MjmlTag.text, MjmlCustomType.emailVideo)"
                    class="tool-template"
                  >
                    <i class="fas fa-video"></i>
                    <span>{{'BUILD.Video' | translate}}</span>
                  </div>
                  <!-- Attachment -->
                  <div *ngIf="!tms.isLetterBuilder"
                      [draggable]="!(readOnly || predesignedTemplate)"
                      (dragend)="dropItem(MjmlTag.button, MjmlCustomType.attachment)" class="tool-template">
                    <i class="fas fa-paperclip"></i>
                    <span>{{'BUILD.Attachment' | translate}}</span>
                  </div>
                  <!-- Chart / Graph -->
                  <div *ngIf="!tms.isLetterBuilder" 
                      [draggable]="!(readOnly || predesignedTemplate)"
                      (dragend)="dropItem(MjmlTag.image, MjmlCustomType.chart)" class="tool-template">
                    <i class="far fa-chart-bar"></i>
                    <span>{{'BUILD.Chart Graph' | translate}}</span>
                  </div>
                  <!-- Table -->
                  <div *ngIf="!tms.isLetterBuilder" 
                      [draggable]="!(readOnly || predesignedTemplate)" (dragend)="dropItem(MjmlTag.table)"
                      class="tool-template">
                    <i class="fas fa-table"></i>
                    <span>{{'BUILD.Table' | translate}}</span>
                  </div>
                  <!-- Button -->
                  <div *ngIf="!tms.isLetterBuilder" 
                      [draggable]="!(readOnly || predesignedTemplate)" (dragend)="dropItem(MjmlTag.button)"
                      class="tool-template">
                    <div class="button-icon">Text</div>
                    <span>{{'BUILD.Simple Button' | translate}}</span>
                  </div>
                  <!-- Donation Button -->
                  <div
                    *ngIf="donateButtonPermission && !tms.isLetterBuilder"
                    [draggable]="!(readOnly || predesignedTemplate)"
                    (dragend)="dropItem(MjmlTag.button, MjmlCustomType.donate)"
                    class="tool-template"
                  >
                    <div class="button-icon">{{'BUILD.Donate' | translate}}</div>
                    <span>{{'BUILD.Donation Button' | translate}}</span>
                  </div>
                  <!-- Register Button -->
                  <div
                    *ngIf="isRegisterButtonAvailable && !tms.isLetterBuilder"
                    [draggable]="!(readOnly || predesignedTemplate)"
                    (dragend)="dropItem(MjmlTag.button, MjmlCustomType.register)"
                    class="tool-template"
                  >
                    <div class="button-icon">{{'BUILD.Register' | translate}}</div>
                    <span>{{'BUILD.Register Button' | translate}}</span>
                  </div>
                  <!-- Share Button -->
                  <!--<div [draggable]="!readOnly || !predesignedTemplate" (dragend)="dropItem(MjmlTag.button)"
                       class="tool-template">
                    <i class="fas fa-share-alt-square"></i>
                    <span>{{'BUILD.Share Button' | translate}}</span>
                  </div>-->
                  <!-- Unsubscribe Button -->
                  <div
                    *ngIf="tms.templateAssignmentType === TemplateAssignmentType.Campaign && !tms.isLetterBuilder"
                    [draggable]="!(readOnly || predesignedTemplate)"
                    (dragend)="dropItem(MjmlTag.button, MjmlCustomType.unsubscribe)"
                    class="tool-template"
                  >
                    <i class="fas fa-unlink"></i>
                    <span>{{'BUILD.Unsubscribe' | translate}}</span>
                  </div>
                  <!-- Social Media Button -->
                  <div *ngIf="!tms.isLetterBuilder" 
                      [draggable]="!(readOnly || predesignedTemplate)" (dragend)="dropItem(MjmlTag.social)"
                      class="tool-template">
                    <i class="fas fa-users"></i>
                    <span>{{'BUILD.Social Media' | translate}}</span>
                  </div>
                  <!--Thermometer-->
                  <div
                    *ngIf="(tms.templateAssignmentType === TemplateAssignmentType.Event) && donateButtonPermission"
                    [draggable]="!(readOnly || predesignedTemplate)"
                    (dragend)="dropItem(MjmlTag.text, MjmlCustomType.thermometer)"
                    class="tool-template"
                  >
                    <i class="fas fa-thermometer-half"></i>
                    <span>{{'BUILD.Thermometer' | translate}}</span>
                  </div>
                  <!-- Signature -->
                  <div
                    *ngIf="tms.templateAssignmentType === TemplateAssignmentType.Campaign || tms.isLetterBuilder"
                    [draggable]="!(readOnly || predesignedTemplate)"
                    (dragend)="dropItem(MjmlTag.text, MjmlCustomType.signature)"
                    class="tool-template"
                  >
                    <i class="fas fa-signature"></i>
                    <span>{{'BUILD.Signature' | translate}}</span>
                  </div>
                  <!-- Fundraiser Button -->
                  <div
                    *ngIf="isCreateFundraiserButtonAvailable"
                    [draggable]="!readOnly || !predesignedTemplate"
                    (dragend)="dropItem(MjmlTag.button, MjmlCustomType.fundraiser)"
                    class="tool-template"
                  >
                    <i class="fas fa-search-dollar"></i>
                    <span>{{'BUILD.Create Fundraiser' | translate}}</span>
                  </div>

                  <!-- Donation Activity -->
                  <div
                    *ngIf="(tms.templateAssignmentType === TemplateAssignmentType.Event) && donateButtonPermission"
                    [draggable]="!(readOnly || predesignedTemplate)"
                    (dragend)="dropItem(MjmlTag.text, MjmlCustomType.donationActivity); onDonationActivityDragEnd()"
                    (dragstart)="onDonationActivityDragStart()"
                    class="tool-template"
                  >
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M28.5468 19.6407L25.0007 24.2779C24.2912 25.2057 23.1899 25.75 22.0219 25.75H15.4375C14.9198 25.75 14.5 25.3302 14.5 24.8125C14.5 24.2947 14.9198 23.875 15.4375 23.875H21.1443C22.3709 23.875 23.3981 22.8712 23.3746 21.6448C23.3519 20.4564 22.3814 19.5 21.1875 19.5H14.625C12.7408 17.6883 9.83319 17.4763 7.70612 18.9956L7 19.5V30.125H13.25H14.5H23.304C25.218 30.125 27.0006 29.1517 28.0356 27.5417L31.6943 21.8504C31.8939 21.54 31.9999 21.1788 31.9999 20.8097C32 18.9694 29.6647 18.1788 28.5468 19.6407Z" fill="#0064BE"></path>
                      <path d="M4.1875 17.625H0.9375C0.41975 17.625 0 18.0447 0 18.5625V31.0625C0 31.5802 0.41975 32 0.9375 32H4.1875C4.70525 32 5.125 31.5802 5.125 31.0625V18.5625C5.125 18.0447 4.70525 17.625 4.1875 17.625Z" fill="#0064BE"></path>
                      <path d="M17.8944 5.18158C17.7036 5.32783 17.5985 5.54927 17.5624 5.74133C17.5124 6.00627 17.5756 6.25239 17.7234 6.36827C17.7755 6.40908 17.8329 6.44921 17.8944 6.48864V5.18158Z" fill="#0064BE"></path>
                      <path d="M18.5625 15.625C22.8703 15.625 26.375 12.1203 26.375 7.8125C26.375 3.50469 22.8703 0 18.5625 0C14.2547 0 10.75 3.50469 10.75 7.8125C10.75 12.1203 14.2547 15.625 18.5625 15.625ZM16.1976 10.3668C16.3864 10.0781 16.7739 9.99681 17.0627 10.1859C17.4003 10.4068 17.6119 10.5025 17.8944 10.5422V7.90275C17.4952 7.71981 17.1968 7.54375 16.952 7.35169C16.4333 6.94487 16.1965 6.23912 16.3339 5.50969C16.4837 4.71481 17.037 4.08238 17.7778 3.85925C17.8171 3.84744 17.8559 3.83662 17.8944 3.82644V3.4375C17.8944 3.09238 18.1742 2.8125 18.5194 2.8125C18.8645 2.8125 19.1444 3.09238 19.1444 3.4375V3.75794C19.7512 3.863 20.1801 4.16175 20.3878 4.40019C20.6146 4.66044 20.5873 5.05525 20.3271 5.28194C20.0682 5.50744 19.6763 5.48181 19.4489 5.22538C19.4346 5.21094 19.3353 5.11531 19.1444 5.04488V7.06437C19.2553 7.10556 19.3662 7.1455 19.475 7.184C20.5736 7.57237 21.1847 8.58937 20.9956 9.71469C20.8485 10.5894 20.1829 11.4764 19.1444 11.7399V12.1875C19.1444 12.5326 18.8645 12.8125 18.5194 12.8125C18.1742 12.8125 17.8944 12.5326 17.8944 12.1875V11.7999C17.3898 11.7563 16.9714 11.6199 16.3784 11.232C16.0896 11.043 16.0086 10.6557 16.1976 10.3668Z" fill="#0064BE"></path>
                      <path d="M19.7629 9.50749C19.8039 9.26318 19.8207 8.68099 19.1443 8.39618V10.4037C19.4959 10.2024 19.7059 9.84624 19.7629 9.50749Z" fill="#0064BE"></path>
                    </svg>
                    <span>{{'BUILD.Donation Activity' | translate}}</span>
                  </div>

                  <!-- Comment Wall -->
                  <div
                    *ngIf="(tms.templateAssignmentType === TemplateAssignmentType.Event)"
                    [draggable]="!(readOnly || predesignedTemplate)"
                    (dragend)="dropItem(MjmlTag.text, MjmlCustomType.commentWall); onCommentWallDragEnd()"
                    (dragstart)="onCommentWallDragStart()"
                    class="tool-template"
                  >
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.91693 15.5823C9.91693 14.8003 10.5516 14.1657 11.3336 14.1657C12.1156 14.1657 12.7503 14.8003 12.7503 15.5823C12.7503 16.3643 12.1156 16.999 11.3336 16.999C10.5516 16.999 9.91693 16.3643 9.91693 15.5823ZM17.0003 14.1657C16.2183 14.1657 15.5836 14.8003 15.5836 15.5823C15.5836 16.3643 16.2183 16.999 17.0003 16.999C17.7823 16.999 18.4169 16.3643 18.4169 15.5823C18.4169 14.8003 17.7823 14.1657 17.0003 14.1657ZM22.6669 14.1657C21.8849 14.1657 21.2503 14.8003 21.2503 15.5823C21.2503 16.3643 21.8849 16.999 22.6669 16.999C23.4489 16.999 24.0836 16.3643 24.0836 15.5823C24.0836 14.8003 23.4489 14.1657 22.6669 14.1657ZM28.1897 17.4168C27.4714 22.0266 23.7555 25.8502 19.1527 26.7129C16.93 27.1323 14.6662 26.8928 12.612 26.023C12.0297 25.7765 11.4277 25.6518 10.8369 25.6518C10.5677 25.6518 10.3014 25.6773 10.0393 25.7298L6.05566 26.5259L6.85325 22.5352C7.02041 21.7064 6.91841 20.8196 6.56 19.971C5.69016 17.9168 5.45216 15.6544 5.87008 13.4303C6.73283 8.82751 10.555 5.1116 15.1662 4.39335C18.8354 3.82243 22.4238 4.97843 25.0135 7.5681C27.6046 10.1592 28.762 13.749 28.1897 17.4168ZM27.0181 5.56493C23.781 2.32926 19.3043 0.88568 14.7299 1.5926C8.95416 2.4936 4.16583 7.14593 3.08491 12.9075C2.56358 15.6813 2.86391 18.5062 3.9505 21.0746C4.08791 21.4018 4.13041 21.7064 4.07658 21.9798L2.86108 28.0545C2.76758 28.5192 2.9135 28.9994 3.24925 29.3338C3.517 29.6029 3.87825 29.7488 4.25083 29.7488C4.34291 29.7488 4.435 29.7403 4.5285 29.7219L10.5961 28.5078C10.9446 28.4413 11.2817 28.539 11.507 28.6325C14.0782 29.7191 16.9031 30.018 19.6741 29.4981C25.4371 28.4172 30.0894 23.6288 30.9904 17.8531C31.7016 13.2815 30.2552 8.80201 27.0181 5.56493Z" fill="#231F20"></path>
                      <mask id="mask0" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="2" y="1" width="30" height="29">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.91693 15.5823C9.91693 14.8003 10.5516 14.1657 11.3336 14.1657C12.1156 14.1657 12.7503 14.8003 12.7503 15.5823C12.7503 16.3643 12.1156 16.999 11.3336 16.999C10.5516 16.999 9.91693 16.3643 9.91693 15.5823ZM17.0003 14.1657C16.2183 14.1657 15.5836 14.8003 15.5836 15.5823C15.5836 16.3643 16.2183 16.999 17.0003 16.999C17.7823 16.999 18.4169 16.3643 18.4169 15.5823C18.4169 14.8003 17.7823 14.1657 17.0003 14.1657ZM22.6669 14.1657C21.8849 14.1657 21.2503 14.8003 21.2503 15.5823C21.2503 16.3643 21.8849 16.999 22.6669 16.999C23.4489 16.999 24.0836 16.3643 24.0836 15.5823C24.0836 14.8003 23.4489 14.1657 22.6669 14.1657ZM28.1897 17.4168C27.4714 22.0266 23.7555 25.8502 19.1527 26.7129C16.93 27.1323 14.6662 26.8928 12.612 26.023C12.0297 25.7765 11.4277 25.6518 10.8369 25.6518C10.5677 25.6518 10.3014 25.6773 10.0393 25.7298L6.05566 26.5259L6.85325 22.5352C7.02041 21.7064 6.91841 20.8196 6.56 19.971C5.69016 17.9168 5.45216 15.6544 5.87008 13.4303C6.73283 8.82751 10.555 5.1116 15.1662 4.39335C18.8354 3.82243 22.4238 4.97843 25.0135 7.5681C27.6046 10.1592 28.762 13.749 28.1897 17.4168ZM27.0181 5.56493C23.781 2.32926 19.3043 0.88568 14.7299 1.5926C8.95416 2.4936 4.16583 7.14593 3.08491 12.9075C2.56358 15.6813 2.86391 18.5062 3.9505 21.0746C4.08791 21.4018 4.13041 21.7064 4.07658 21.9798L2.86108 28.0545C2.76758 28.5192 2.9135 28.9994 3.24925 29.3338C3.517 29.6029 3.87825 29.7488 4.25083 29.7488C4.34291 29.7488 4.435 29.7403 4.5285 29.7219L10.5961 28.5078C10.9446 28.4413 11.2817 28.539 11.507 28.6325C14.0782 29.7191 16.9031 30.018 19.6741 29.4981C25.4371 28.4172 30.0894 23.6288 30.9904 17.8531C31.7016 13.2815 30.2552 8.80201 27.0181 5.56493Z" fill="white"></path>
                      </mask>
                      <g mask="url(#mask0)">
                        <rect width="34" height="34" fill="#0064BE"></rect>
                      </g>
                    </svg>
                    <span>{{'BUILD.Comment Wall' | translate}}</span>
                  </div>

                  <!-- Share on SM -->
                  <div
                    *ngIf="(tms.templateAssignmentType === TemplateAssignmentType.Event)"
                    [draggable]="!(readOnly || predesignedTemplate)"
                    (dragend)="dropItem(MjmlTag.text, MjmlCustomType.shareOnSM)"
                    class="tool-template"
                  >
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.19 16.2917C14.1424 16.2917 9.58927 18.6065 6.70919 22.3252C8.03377 16.8442 13.1267 12.75 19.19 12.75C19.9734 12.75 20.6067 12.1167 20.6067 11.3333V8.60907L27.4407 14.5222L20.6067 20.4892V17.7083C20.6067 16.9249 19.9734 16.2917 19.19 16.2917ZM5.35344 29.75C6.00652 29.75 6.57602 29.3037 6.73044 28.6691C7.99127 23.4997 12.4269 19.7653 17.7734 19.2001V20.7882C17.7734 21.7883 18.3669 22.6794 19.3218 23.1115C20.3574 23.5832 21.5658 23.4132 22.4059 22.6808L29.5827 16.4135C30.1394 15.9262 30.4582 15.2362 30.4582 14.5208C30.4582 13.8054 30.1394 13.1155 29.5827 12.6282L22.4059 6.36082C21.5658 5.6284 20.3574 5.4584 19.3218 5.93015C18.3669 6.36224 17.7734 7.25332 17.7734 8.25349V9.97899C9.80602 10.6774 3.54152 17.2125 3.54152 25.1458C3.54152 26.2933 3.68886 27.4777 3.97786 28.6677C4.13086 29.3037 4.69894 29.75 5.35344 29.75Z" fill="#231F20"></path>
                      <mask id="mask1" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="3" y="5" width="28" height="25">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.19 16.2917C14.1424 16.2917 9.58927 18.6065 6.70919 22.3252C8.03377 16.8442 13.1267 12.75 19.19 12.75C19.9734 12.75 20.6067 12.1167 20.6067 11.3333V8.60907L27.4407 14.5222L20.6067 20.4892V17.7083C20.6067 16.9249 19.9734 16.2917 19.19 16.2917ZM5.35344 29.75C6.00652 29.75 6.57602 29.3037 6.73044 28.6691C7.99127 23.4997 12.4269 19.7653 17.7734 19.2001V20.7882C17.7734 21.7883 18.3669 22.6794 19.3218 23.1115C20.3574 23.5832 21.5658 23.4132 22.4059 22.6808L29.5827 16.4135C30.1394 15.9262 30.4582 15.2362 30.4582 14.5208C30.4582 13.8054 30.1394 13.1155 29.5827 12.6282L22.4059 6.36082C21.5658 5.6284 20.3574 5.4584 19.3218 5.93015C18.3669 6.36224 17.7734 7.25332 17.7734 8.25349V9.97899C9.80602 10.6774 3.54152 17.2125 3.54152 25.1458C3.54152 26.2933 3.68886 27.4777 3.97786 28.6677C4.13086 29.3037 4.69894 29.75 5.35344 29.75Z" fill="white"></path>
                      </mask>
                      <g mask="url(#mask1)">
                        <rect width="34" height="34" transform="matrix(-1 0 0 1 34 0)" fill="#0064BE"></rect>
                      </g>
                    </svg>
                    <span>{{'BUILD.Share on SM' | translate}}</span>
                  </div>

                  <!-- Add Sponsors -->
                  <div
                    *ngIf="isP2PEnabled"
                    [draggable]="!(readOnly || predesignedTemplate)"
                    (dragend)="dropItem(MjmlTag.sponsors)"
                    class="tool-template"
                  >
                    <i class="far fa-images"></i>
                    <span>{{'BUILD.Add Sponsors' | translate}}</span>
                  </div>

                </div>
              </div>
            </ng-template>
          </mat-expansion-panel>

          <app-modules
            [eventForm]="eventForm"
            [tms]="tms"
            [toolContainer]="toolContainer"
            [stateOptions]="stateOptions"
            [timeZonesOptions]="timeZonesOptions"
            [eventStatus]="eventStatus"
            [timeZones]="timeZones"
            [campaignForm]="campaignForm"
            [countryStateService]="countryStateService"
          ></app-modules>
        </mat-accordion>

      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <div class="tools-tab-wrapper" [class.active]="currentTab === 2">
            <span class="tab-title">{{'BUILD.Tags' | translate}}</span>
          </div>
        </ng-template>
        <div class="templates-tags">
          <app-tags
            [tms]="tms"
            [templatePayload]="templatePayload"
            [strictReadOnly]="strictReadOnly"
            (invalidTagFormEmitter)="showErrorFields()"
          ></app-tags>
        </div>
      </mat-tab>

    </mat-tab-group>

  </div>

  <!--Export container-->
  <div
    *ngIf="showExportContainer$ | async"
    class="email-template-builder-export-container"
    #exportContainer
  >
    <app-mjml-renderer
      [eventId]="eventId"
      [mjml]="tms.template"
      [screenshots]="true"
      [showHeaderAndFooter]="true"
    ></app-mjml-renderer>
  </div>
</div>
