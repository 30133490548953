import {ActivatedRoute, Router} from '@angular/router';
import {AdminConfirmationComponent} from '../../../routes/admin/admin-confirmation/admin-confirmation.component';
import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ComponentCanDeactivate} from '../../../guards/pending-changes.guard';
import {DayOfWeek} from '../../../models/enum/day-of-week';
import {FeeAndTaxes} from '../../../models/enum/fee-and-taxes.enum';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {BehaviorSubject, forkJoin, Observable, of, Subject, Subscription, zip} from 'rxjs';
import {PaymentBillingType} from '../../../models/enum/payment.billing.type';
import {PaymentMethodType} from '../../../models/enum/payment.method.type';
import {PaymentRecurrencyStatus} from 'src/app/models/enum/payment.recurrency.status';
import {PaymentRepeatType} from '../../../models/enum/payment.repeat.type';
import {RecurringSettingsService} from '../../../services/payments/recurring.settings.service';
import {SchedulerService} from 'src/app/services/scheduler.service';
import {ToastrService} from 'ngx-toastr';
import FormElementDataModel from '../../../models/form.element.data.model';
import RecurringSettingsModel from '../../../models/payments/recurring.settings.model';
import {EventService} from '../../../services/events/event.service';
import {CampaignService} from '../../../services/campaign/campaign.service';
import {SocialPostService} from '../../../services/socialMedia/social.post.service';
import EventModel from '../../../models/event/event.model';
import {CampaignModel} from '../../../models/campaigns/campaign.model';
import SocialPostModel from '../../../models/socialMedia/social.post.model';
import {LookupService} from '../../../services/lookup/lookup.service';
import {StateModel} from '../../../models/state.model';
import {ClientDonorService} from '../../../services/client.donors/client-donor.service';
import {ClientDonorModel} from '../../../models/client/client.donor.model';
import {TransactionReviewService} from '../../../services/payments/transaction.review.service';
import {Paging} from '../../../models/paging/paging.model';
import {SortOrder} from '../../../models/enum/sort.order';
import {FilterType} from '../../../models/enum/filter.type';
import TransactionReviewModel from '../../../models/payments/transaction.review.model';
import {PaymentTransactionReviewStatus} from '../../../models/enum/payment.transaction.review.status';
import {ClientModel} from '../../../models/client/client.model';
import {ClientService} from '../../../services/client/client.service';
import {PaymentServiceType} from '../../../models/enum/payment.service.type';
import {EventDonationsService} from '../../../services/events/event-donations.service';
import {bufferCount, first, map, switchMap, takeWhile, tap} from 'rxjs/operators';
import EventDonationSumModel from '../../../models/event/event.donation.sum.model';
import CountryModel from '../../../models/internationalization/country.model';
import {TerritorialEntityType} from '../../../models/internationalization/territorial.entity.type';
import {LocalityType} from '../../../models/internationalization/locality.type';
import {PoliticalUnitType} from '../../../models/internationalization/political.unit.type';
import {CodeType} from '../../../models/internationalization/code.type';
import {CancellingReasonComponent} from '../../cancelling-reason/cancelling-reason.component';
import {FundAllocationModel} from '../../../models/payments/fund.allocation.model';
import {DEFAULT_PAYMENT_FORM,DONATION_FREQUENCY_OPTIONS} from 'src/app/constants';
import FbTemplateModel from '../../../models/form-builder/fb.template.model';
import {UtilsComponent} from '../../utils.component';
import {PaymentFeeModel} from '../../../models/payments/payment.fee.model';
import {
  RecurringActionFormComponent,
  RecurringActionFormData
} from "../../recurring-action-form/recurring-action-form.component";
import {ScheduleRecurringSettingModel} from "../../../models/payments/schedule-recurring-setting.model";
import FilterSortModel from "../../../models/filter-sort/filter.sort.model";
import { HttpResponseBase } from '@angular/common/http';
import {DonationFrequency} from "../../../models/enum/donation-frequency.enum";

@Component({
  selector: 'app-recurring-payments',
  templateUrl: './recurring-payments.component.html',
  styleUrls: ['./recurring-payments.component.scss']
})
export class RecurringPaymentsComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  public dayOfMonth: FormElementDataModel[] = [];
  public dayOfWeek: FormElementDataModel[] = [
    {label: 'Sunday', value: DayOfWeek.Sunday},
    {label: 'Monday', value: DayOfWeek.Monday},
    {label: 'Tuesday', value: DayOfWeek.Tuesday},
    {label: 'Wednesday', value: DayOfWeek.Wednesday},
    {label: 'Thursday', value: DayOfWeek.Thursday},
    {label: 'Friday', value: DayOfWeek.Friday},
    {label: 'Saturday', value: DayOfWeek.Saturday}
  ];
  public paymentFrequency: FormElementDataModel[] = [
    {label: 'Weekly', value: PaymentRepeatType.Weekly},
    {label: 'Monthly', value: PaymentRepeatType.Monthly}
  ];
  public billingTypeOptions: FormElementDataModel[] = [
    {label: 'Recurring payment', value: PaymentBillingType.Recurring},
    {label: 'Pledge', value: PaymentBillingType.Pledge},
  ];
  public subscription: Subscription = new Subscription();
  public recurringSettingsId: string;
  public recurringSettingsModel: RecurringSettingsModel;

  public PaymentMethodType = PaymentMethodType;
  public FeeAndTaxes = FeeAndTaxes;
  public PaymentRepeatType = PaymentRepeatType;
  public PaymentBillingType = PaymentBillingType;
  public transactionsHistory: any = [
      {
    transactionId: 89898,
    paymentDate: '12.12.2023',
    transactionDetails: 'test details 1'
      },
    {
      transactionId: 83428,
      paymentDate: '12.13.2023',
      transactionDetails: 'test details 2'
    },
  ]
  public transactionsHistory$: Observable<any[]>;
  public filterSortConfig: FilterSortModel = {
    sortField: 'loggedOnDate',
    sortOrder: SortOrder.Descending,
    columnFilters: []
  };
  private fetchHistory$: BehaviorSubject<void> = new BehaviorSubject<void>(null)
  public isPublicEditPledgePage: boolean = false;
  public changePaymentDetails: boolean = false;
  public event: EventModel;
  public campaign: CampaignModel;
  public socialMediaPost: SocialPostModel;
  public states: StateModel[] = [];
  private tryToUpdate: Subject<RecurringSettingsModel> = new Subject<RecurringSettingsModel>();
  public initiateUpdateProcess$: Observable<RecurringSettingsModel> = this.tryToUpdate.asObservable();
  public filledInData: any = {};
  public isEditPledge: boolean = false;
  public isCancelledAll: boolean = false;
  public transactions: TransactionReviewModel[] = [];
  public tableDataColumns: string[] = ['paymentDate', 'amount', 'status', 'declineReason', 'noteReason'];
  public transactionHistoryDataColumns: string[] = ['eventDonationPublicId', 'loggedOnDate', 'transactionDate', 'description'];
  public isCompleted: boolean = false;
  public showMessagePage: boolean = false;
  public infoMessage: string = '';
  public isDataLoaded: boolean = false;
  public isOldRecurringOption: boolean = false;
  public isWeeklyRecurringDonation: boolean = false;
  public isMonthlyRecurringDonation: boolean = false;
  public isYearlyRecurringDonation: boolean = false;
  public donationRecurrenceOptions = DONATION_FREQUENCY_OPTIONS;

  public paymentServiceType: PaymentServiceType;
  public client: ClientModel;
  private initialRecurringSettingsModel: RecurringSettingsModel;
  public PaymentRecurrencyStatus = PaymentRecurrencyStatus;

  public paymentForm: FormGroup = this.formBuilder.group({
    ammount: ['', [Validators.required, Validators.min(5)]],
    reccurrencyPaymentType: [PaymentBillingType.Recurring],
    startDate: [new Date(), [Validators.required]],
    nextExecutionDate: [''],
    nextAmmount: [''],
    endDate: [''],
    reccurrency: [PaymentRepeatType.Monthly],
    reccurrencyValue: [1, [Validators.required]],
    everyValue: [1, [Validators.required, Validators.min(1)]],
    donationFrequency: [2],
    maxCount: ['', [Validators.min(1)]],
    amountPerTransaction: [''],

    fundAllocation: this.formBuilder.array([])
  });

  private loadSubject: Subject<void> = new Subject<void>();
  public countryState: BehaviorSubject<Partial<CountryModel>> = new BehaviorSubject<Partial<CountryModel>>({
    name: 'USA',
    id: 1,
    territorialEntityType: TerritorialEntityType.Unknown,
    localityType: LocalityType.City,
    politicalUnitType: PoliticalUnitType.State,
    codeType: CodeType.ZipCode,
    phoneCode: '1',
  });
  public countries: CountryModel[] = [];
  public countriesOptions: FormElementDataModel[] = [];

  public distributeAmongFunds$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  public fbTemplate: FbTemplateModel = UtilsComponent.clone(DEFAULT_PAYMENT_FORM);
  public paymentFee: PaymentFeeModel = {
    paymentFee: 0.02,
    paymentCommission: 0.2,
    applicationPaymentCommission: 0,
    applicationPaymentFeePercent: 0,
  };

  arePaymentsFinished: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private recurringSettingsService: RecurringSettingsService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    private router: Router, private schedulerService: SchedulerService,
    private eventService: EventService,
    private campaignService: CampaignService,
    private socialPostService: SocialPostService,
    private lookupModelService: LookupService,
    private clientDonorService: ClientDonorService,
    private transactionReviewService: TransactionReviewService,
    private clientModelService: ClientService,
    private eventDonationsService: EventDonationsService
  ) {
  }

  /*private static strictDate(date: Date): Date {
    const myDate = new Date(date);
    return new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate());
  }*/

  private static truncateNumber(value: number): string {
    if (!value) {
      return (0).toFixed(2);
    } else {
      return value.toFixed(3).slice(0, -1);
    }
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    // todo check logic
    if (this.paymentForm.touched && !this.paymentForm.pristine) {
      const dialogRef = this.dialog.open(AdminConfirmationComponent, {
        data: {
          title: 'You have unsaved changes. Are you sure you want to leave this page?'
        }
      });
      return dialogRef.afterClosed();
    }
    return true;
  }

  public ngOnInit(): void {
    this.recurringSettingsId = this.activatedRoute.snapshot.params.id;
    this.isPublicEditPledgePage = window.location.pathname.includes('pledge');
    if (this.recurringSettingsId) {
      this.getRecurringSettingsModel(() => {
        if (!this.isPublicEditPledgePage) {
          this.getTransactions();
          this.transactionsHistory$ = this.getTransactionHistory();
        }
      });
    } else if (!this.isPublicEditPledgePage) {
      this.getTransactions();
    }
    for (let i = 1; i <= 31; i++) {
      this.dayOfMonth.push({label: i.toString(), value: i})
    }

    const bufferSize = this.isPublicEditPledgePage ? 1 : 2;
    this.subscription.add(
      this.loadSubject.asObservable().pipe(bufferCount(bufferSize)).subscribe(() => this.isDataLoaded = true)
    );
    this.subscription.add(
      this.fundAllocation.valueChanges.subscribe((value: FundAllocationModel[]) => {
        if (!this.distributeAmongFunds$.getValue()) {
          return;
        }
        let totalAmount = 0;
        value.forEach(({amount}: FundAllocationModel) => totalAmount += (+amount + (this.recurringSettingsModel?.isFeeIncluded ? this.getFee(+amount) : 0)));
        this.nextAmmount.setValue(totalAmount || '')
      })
    );
  }

  public transactionHistorySortChanged(sortConfig: FilterSortModel) {
    this.filterSortConfig = sortConfig;
    this.fetchHistory$.next();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getRecurringSettingsModel(callback: () => void): void {
    const recurringSettings$ = this.recurringSettingsService.getModel(this.recurringSettingsId);
    const donationSum$ = this.eventDonationsService.getDonationSumByReccuringId(this.recurringSettingsId);
    const states$ = this.lookupModelService.getStates();
    const countries$ = this.lookupModelService.getCountries();

    recurringSettings$.pipe(
        switchMap(res => {
          if(res.recurrencyStatusEnum === PaymentRecurrencyStatus.HistoricalEntry) {
             const paging: Paging = {
               includeDependencies: true,
               filters: [
                 {
                   field: 'recurrencyStatusEnum',
                   value: PaymentRecurrencyStatus.HistoricalEntry.toString(),
                   type: FilterType.NotEqual
                 },
                 {
                   field: 'grouppedById',
                   value: res.grouppedByID,
                   type: FilterType.Equal
                 }
               ]
             }
             return this.getActiveRecurringByGrouppedById(paging).pipe(
                 switchMap(activeSettings => zip(of(activeSettings), this.eventDonationsService.getDonationSumByReccuringId(activeSettings.id), states$, countries$))
             )
          }

          else return zip(of(res), donationSum$,states$,countries$)
        }),
              tap(([recurringSettings, donationSum, states, countries]) => {
                this.initialRecurringSettingsModel = {...recurringSettings};
                this.states = states;
                this.countries = countries;
                this.countriesOptions = countries.map(({name, id}: CountryModel) => ({label: name, value: id}));
                if (recurringSettings) {
                  const {
                    reccurrencyPaymentType,
                    nextExecutionStatusEnum,
                    fullAmmount,
                    ammount,
                    maxCount,
                    eventId,
                    campaignId,
                    socialMediaPost,
                    isFeeIncluded,
                    donorId,
                    clientId,
                    firstName,
                    lastName,
                    city,
                    email,
                    organizationName,
                    federalIDNumber,
                    postalCode,
                    state,
                    streetAddress,
                    donorType,
                    country = 1,
                    territorialEntity,
                    phone,
                    fundAllocation,
                    distributionAmongFunds,
                    reccurrency,
                    everyValue
                  }: RecurringSettingsModel = recurringSettings;
                  this.isEditPledge = reccurrencyPaymentType === PaymentBillingType.Pledge;
                  if (!this.isEditPledge && this.isPublicEditPledgePage) {
                    this.router.navigateByUrl('/');
                    return;
                  }

                  this.distributeAmongFunds$.next(!!distributionAmongFunds);
                  if (distributionAmongFunds) {
                    this.setFundAllocation(fundAllocation);
                  }

                  this.countryState.next(countries.find(({id}: CountryModel) => +country === id));
                  this.recurringSettingsModel = recurringSettings;
                  this.recurringSettingsId = this.recurringSettingsModel.id;
                  this.isCancelledAll = nextExecutionStatusEnum === PaymentRecurrencyStatus.CancelledAll;
                  this.paymentForm.patchValue(recurringSettings);
                  callback();
                  if (this.isCancelledAll) {
                    this.startDate.disable();
                    this.paymentForm.disable();
                  }
                  this.distributeAmongFunds$.getValue() ? this.nextAmmount.setValue(ammount) : this.nextAmmount.setValue(fullAmmount);
                  if (this.recurringSettingsModel.reccurrencyPaymentType == PaymentBillingType.Pledge) {
                    this.nextAmmount.setValue(ammount);
                  }

                  this.ammount.setValue(fullAmmount);
                  this.isEditPledge && this.setNextAmountValidator(fullAmmount, maxCount, donationSum);
                  const amountPerTransaction = (fullAmmount - donationSum.sum) / (maxCount - donationSum.count);
                  this.amountPerTransaction.setValue(RecurringPaymentsComponent.truncateNumber(amountPerTransaction));
                  this.reccurrencyPaymentType.disable();
                  this.donationFrequency.disable();
                  this.donationFrequency.setValue(this.getDonationFrequency(reccurrency, everyValue));
                  this.isWeeklyRecurringDonation = reccurrency === PaymentRepeatType.Weekly;
                  this.isMonthlyRecurringDonation = reccurrency === PaymentRepeatType.Monthly;
                  this.isYearlyRecurringDonation = reccurrency === PaymentRepeatType.Yearly;
                  this.nextExecutionDate.disable();
                  this.maxCount.disable();
                  if(this.maxCount.value === 0){
                    this.maxCount.setValue(null);
                  }
                  this.amountPerTransaction.disable();
                  if (eventId) {
                    this.getEvent(eventId);
                  } else if (campaignId) {
                    this.getCampaign(campaignId);
                  } else if (socialMediaPost) {
                    this.getSocialMediaPost(socialMediaPost);
                  } else if (clientId) {
                    this.getPaymentServiceType(clientId);
                  }
                  const stateModel: StateModel = this.states.find(({name}: StateModel) => name === state);
                  this.filledInData = {
                    includeFee: isFeeIncluded,
                    organizationName,
                    federalIDNumber,
                    firstName,
                    lastName,
                    email,
                    streetAddress,
                    zipCode: postalCode,
                    city,
                    state: stateModel ? stateModel.id : 0,
                    donorType,
                    country,
                    territorialEntity,
                    phone: phone ? phone.slice(this.countryState.getValue()?.phoneCode.length) : '',
                  };
                  if (donorId) {
                    this.getDonor(donorId);
                  }
                  if (this.isEditPledge) {
                    this.ammount.disable();
                    this.reccurrency.disable();
                    this.reccurrencyValue.disable();
                    this.everyValue.disable();
                  }
                  if (reccurrencyPaymentType === PaymentBillingType.Recurring) {
                    this.reccurrency.disable();
                    this.everyValue.disable();
                    this.reccurrencyValue.disable();
                  }
                  if (recurringSettings.actualCount === recurringSettings.maxCount || recurringSettings.endDate && (recurringSettings.endDate < recurringSettings.startDate)) {
                    this.isCompleted = true;
                    this.paymentForm.disable();
                  }
                  this.loadSubject.next();
                } else {
                  this.router.navigateByUrl('/');
                }
              })



    ).subscribe()

  }

  private setNextAmountValidator(fullAmmount: number, maxCount: number, donationSum: EventDonationSumModel): void {
    const maxAvailableValue = fullAmmount - donationSum.sum;
    this.nextAmmount.setValidators([Validators.required, Validators.min(1), Validators.max(maxAvailableValue)]);
    if (maxCount - donationSum.count < 2) {
      this.nextAmmount.disable();
    }
    this.nextAmmount.updateValueAndValidity();
    this.paymentForm.updateValueAndValidity();
  }

  public onBackToAll(): void {
    this.router.navigateByUrl('/clients/contributions');
  }
  public onBackToRecurring(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/clients/contributions/recurring-payments', this.recurringSettingsId])
  }
  private adjustRecurringFeeSettings(model: RecurringSettingsModel): void {
    model.paymentComission = this.paymentFee.paymentCommission;
    model.paymentFeePercent = this.paymentFee.paymentFee;
    model.applicationPaymentComission = this.paymentFee.applicationPaymentCommission;
    model.applicationPaymentFeePercent = this.paymentFee.applicationPaymentFeePercent;
    model.paymentServiceType = this.paymentServiceType
  }
  private getFundDistributionAmountValue():number {
    let value = 0;
    this.fundAllocation.controls.forEach(c => {
      value += +c.get('amount').value;
    })
    return value
  }

  public save(): void {
    this.estimateNextExecutionDateControlValue();
    this.paymentForm.markAllAsTouched();
    if (this.paymentForm.invalid) {
      this.showErrorMessage('Please fill all mandatory fields');
    } else {
      const updatedRecurring: RecurringSettingsModel = {
        ...this.recurringSettingsModel,
        ...this.paymentForm.getRawValue()
      };
      this.adjustRecurringFeeSettings(updatedRecurring);
      updatedRecurring.startDate = new Date(updatedRecurring.startDate).toUTCString();
      this.recurringSettingsModel.nextExecutionStatusEnum === PaymentRecurrencyStatus.Cancelled ?
        updatedRecurring.nextExecutionDate = this.nextExecutionDate.value :
        updatedRecurring.nextExecutionDate && (updatedRecurring.nextExecutionDate = new Date(updatedRecurring.nextExecutionDate).toUTCString());
      updatedRecurring.nextExecutionStatusEnum = PaymentRecurrencyStatus.Active;
      updatedRecurring.feeAmount = this.recurringSettingsModel.isFeeIncluded ? this.getFee(this.recurringSettingsModel.fullAmmount, updatedRecurring) : 0;
      if (this.isEditPledge) {
        updatedRecurring.ammount = +this.nextAmmount.value;
      } else {
        const isFundDistribution = this.distributeAmongFunds$.getValue();
        const value = isFundDistribution ? this.getFundDistributionAmountValue() : +this.nextAmmount.value;
        const fee = this.recurringSettingsModel.isFeeIncluded ? +this.getFee(value, updatedRecurring).toFixed(2) : 0;
        updatedRecurring.feeAmount = fee;
        updatedRecurring.ammount = this.recurringSettingsModel.isFeeIncluded ? value + fee : value;
        !this.isEditPledge && (updatedRecurring.fullAmmount = value);
        !this.isEditPledge && (updatedRecurring.nextAmmount = updatedRecurring.fullAmmount);
      }
      if (this.changePaymentDetails) {
        this.tryToUpdate.next(updatedRecurring);
      } else {
        let updatedRecurringModel;
        this.subscription.add(
          this.openActionInfoForm('Approve', true)
            .pipe(
              takeWhile((res: ScheduleRecurringSettingModel) => !!res),
              switchMap((res) =>
                forkJoin(
                  this.recurringSettingsService.updateModel(updatedRecurring),
                  of(res),
                ),
              ),
              switchMap(([updatedModel, res]) => {
                updatedRecurringModel = updatedModel;
                const settings: ScheduleRecurringSettingModel = {
                  reccuringID: this.recurringSettingsId,
                  oneTimePayment: false,
                  ...res,
                  feeAmount: updatedModel.feeAmount,
                  isFeeIncluded: updatedModel.isFeeIncluded,
                  ammount: updatedModel.ammount
                };
                return this.recurringSettingsService.scheduleReccuringSetting(
                  settings,
                );
              }),
            )
            .subscribe(() => {
              if (updatedRecurringModel) {
                this.recurringSettingsModel = updatedRecurringModel;
                this.infoMessage =
                  'Recurring settings have been successfully updated';
                this.showSuccessMessage(this.infoMessage);
                this.showMessagePage = true;
              }
            }),
        );
      }
      this.paymentForm.markAsPristine();
    }
  }

  public decline(status: PaymentRecurrencyStatus = PaymentRecurrencyStatus.Cancelled): void {
    if (!this.isPublicEditPledgePage) {
      this.subscription.add(
        this.askForReason().pipe(switchMap((reason) => {
          if (!reason) {
            return of(null);
          } else {
            this.recurringSettingsModel.declineReason = reason;
            this.recurringSettingsModel.nextExecutionStatusEnum = status;
            return this.recurringSettingsService.updateModel(this.recurringSettingsModel);
          }
        })).subscribe(this.onDeclineResponse.bind(this, status))
      );
    } else {
      this.recurringSettingsModel.nextExecutionStatusEnum = status;
      this.subscription.add(
        this.recurringSettingsService.updateModel(this.recurringSettingsModel).subscribe(this.onDeclineResponse.bind(this, status))
      );
    }
  }

  private onDeclineResponse(status: PaymentRecurrencyStatus, response: RecurringSettingsModel): void {
    if (response) {
      this.recurringSettingsModel = response;
      if (status === PaymentRecurrencyStatus.Cancelled) {
        this.infoMessage = 'Next payment cancelled successfully';
        this.showSuccessMessage(this.infoMessage);
        this.showMessagePage = true;
      }
    }
  }

  private getTransactionHistory(): Observable<any[]> {
    return this.fetchHistory$.pipe(
        switchMap(() => {
          const paging: Paging = {
            filters: [
              {
                field:'recurringSettingsId',
                value: this.recurringSettingsId,
                type: FilterType.Equal
              }
            ],
            sortField: this.filterSortConfig.sortField,
            sortOrder: this.filterSortConfig.sortOrder,
          }
          return this.recurringSettingsService.getRecurringTransactionHistoryLog(paging);
        })
    )
  }

  private getActiveRecurringByGrouppedById(paging: Paging): Observable<RecurringSettingsModel> {
    return this.recurringSettingsService.getModelList(paging).pipe(
        map(res => res[0]),
        tap(res => this.router.navigate([`/clients/contributions/recurring-payments/${res.id}`]))

    )
  }

  public stopRecurringSchedule(): void {
    if (!this.isPublicEditPledgePage) {
      this.subscription.add(
        this.askForReason().pipe(switchMap((reason) => {
          if (!reason) {
            return of(null);
          } else {
            this.recurringSettingsModel.declineReason = reason;
            this.recurringSettingsModel.nextExecutionStatusEnum = PaymentRecurrencyStatus.CancelledAll;
            return forkJoin([this.recurringSettingsService.updateModel(this.recurringSettingsModel), this.schedulerService.deleteJob(this.recurringSettingsModel.id)]);
          }
        })).subscribe(this.onDeclineAllResponse.bind(this))
      );
    } else {
      this.subscription.add(
        forkJoin([this.recurringSettingsService.updateModel(this.recurringSettingsModel), this.schedulerService.deleteJob(this.recurringSettingsModel.id)])
          .subscribe(this.onDeclineAllResponse.bind(this))
      );
    }
  }
  public restartRecurringSchedule() {
    this.subscription.add(
      this.openActionInfoForm("Restart Schedule", true).pipe(
        switchMap((res) => {
            const updatedRecurring: RecurringSettingsModel = {
              ...this.recurringSettingsModel,
            };
        this.adjustRecurringFeeSettings(updatedRecurring);
        if(!this.isEditPledge) {
          updatedRecurring.ammount = this.recurringSettingsModel.isFeeIncluded ? updatedRecurring.fullAmmount + +this.getFee(updatedRecurring.fullAmmount, updatedRecurring).toFixed(2) : updatedRecurring.fullAmmount;
          updatedRecurring.feeAmount = updatedRecurring.isFeeIncluded ? +this.getFee(updatedRecurring.fullAmmount).toFixed(2) : 0
        }

        return forkJoin(
            this.recurringSettingsService.updateModel(updatedRecurring),
            of(res),
          )
        }
        ),
          switchMap((res) => {
            if(!res[1]) return of('CANCEL');
            const model: ScheduleRecurringSettingModel = {
              reccuringID: this.recurringSettingsId,
              oneTimePayment: false,
              feeAmount: res[0].feeAmount,
              isFeeIncluded: res[0].isFeeIncluded,
              ammount: res[0].ammount,
              ...res[1]
            }
            return this.recurringSettingsService.scheduleReccuringSetting(model)
          }),
          tap(this.onRecurringActionCompleted.bind(this))
      ).subscribe()
    )
  }

  private onDeclineAllResponse(response): void {
    if (response) {
      this.infoMessage = 'Payment successfully cancelled';
      this.showSuccessMessage(this.infoMessage);
      this.showMessagePage = true;
    }
  }

  private showSuccessMessage(message: string): void {
    this.toastrService.success(message, 'Notification');
  }

  private showErrorMessage(message: string): void {
    this.toastrService.error(message, 'Error');
  }

  public get ammount(): FormControl {
    return this.paymentForm.get('ammount') as FormControl;
  }

  public get nextAmmount(): FormControl {
    return this.paymentForm.get('nextAmmount') as FormControl;
  }

  public get reccurrencyPaymentType(): FormControl {
    return this.paymentForm.get('reccurrencyPaymentType') as FormControl;
  }

  public get startDate(): FormControl {
    return this.paymentForm.get('startDate') as FormControl;
  }

  public get nextExecutionDate(): FormControl {
    return this.paymentForm.get('nextExecutionDate') as FormControl;
  }

  public get endDate(): FormControl {
    return this.paymentForm.get('endDate') as FormControl;
  }

  public get reccurrency(): FormControl {
    return this.paymentForm.get('reccurrency') as FormControl;
  }

  public get reccurrencyValue(): FormControl {
    return this.paymentForm.get('reccurrencyValue') as FormControl;
  }

  public get donationFrequency(): FormControl {
    return this.paymentForm.get('donationFrequency') as FormControl;
  }

  public get everyValue(): FormControl {
    return this.paymentForm.get('everyValue') as FormControl;
  }

  public get maxCount(): FormControl {
    return this.paymentForm.get('maxCount') as FormControl;
  }

  public get amountPerTransaction(): FormControl {
    return this.paymentForm.get('amountPerTransaction') as FormControl;
  }

  public get fundAllocation(): FormArray {
    return this.paymentForm.get('fundAllocation') as FormArray;
  }

  public updateBillingType(event): void {
    if (event === PaymentBillingType.Recurring) {
      this.endDate.enable();
      this.maxCount.setValidators([Validators.min(1)]);
      this.maxCount.updateValueAndValidity();
    } else {
      this.endDate.disable();
      this.maxCount.setValidators([Validators.required, Validators.min(1)]);
      this.maxCount.updateValueAndValidity();
      this.maxCount.markAsUntouched();
    }
  }

  public manageRepeatType(event): void {
    this.everyValue.setValue(1);
    this.reccurrencyValue.setValue(1);
  }

  public startDateFilter = (date: Date): boolean => {
    const endDate = this.endDate.value;
    const checkDate = UtilsComponent.strictDate(date);
    if (endDate) {
      return checkDate <= UtilsComponent.strictDate(endDate);
    } else {
      return true;
    }
  };

  public get showStart(): boolean {
    if (!this.nextExecutionDate.value) {
      return false;
    }
    const startDate = this.startDate.value;
    return UtilsComponent.strictDate(startDate) > UtilsComponent.strictDate(new Date())
      && UtilsComponent.strictDate(this.nextExecutionDate.value).toDateString() === UtilsComponent.strictDate(new Date()).toDateString();
  }

  public nextDateFilter = (date: Date): boolean => {
    const currentTransactionIndex = this.transactions.findIndex(transaction => transaction.paymentDate === this.nextExecutionDate.value);
    const checkDate = UtilsComponent.strictDate(date);
    const startDate = UtilsComponent.strictDate(new Date());
    const currentPaymentDate = UtilsComponent.strictDate(this.nextExecutionDate.value);
    const endDate = this.endDate.value ? UtilsComponent.strictDate(this.endDate.value) : null;
    if (currentTransactionIndex !== -1 && currentTransactionIndex < this.transactions.length - 1) {
      const nextPaymentDate = this.transactions[currentTransactionIndex + 1].paymentDate;
      return checkDate <= UtilsComponent.strictDate(new Date(nextPaymentDate))
        && checkDate >= currentPaymentDate
        && checkDate >= startDate;
    } else if (endDate) {
      return checkDate <= endDate
        && checkDate >= currentPaymentDate
        && checkDate >= startDate;
    } else {
      return checkDate >= currentPaymentDate && checkDate >= startDate;
    }
  };

  public endDateFilter = (date: Date): boolean => {
    const startDate = this.startDate.value;
    const checkDate = UtilsComponent.strictDate(date);
    if (startDate) {
      return checkDate >= UtilsComponent.strictDate(startDate);
    } else {
      return true;
    }
  };

  private getEvent(id: string): void {
    this.subscription.add(
      this.eventService.getModel(id).subscribe((event: EventModel) => {
        this.event = event;
        this.getPaymentServiceType(this.event.clientID);
      })
    );
  }

  private getCampaign(id: string): void {
    this.subscription.add(
      this.campaignService.getModel(id).subscribe((campaign: CampaignModel) => {
        this.campaign = campaign;
        this.getPaymentServiceType(this.campaign.clientID);
      })
    );
  }

  private getSocialMediaPost(id: string): void {
    this.subscription.add(
      this.socialPostService.getModel(id).subscribe((socialMediaPost: SocialPostModel) => {
        this.socialMediaPost = socialMediaPost;
        this.getPaymentServiceType(this.socialMediaPost.clientID);
      })
    );
  }

  private getPaymentServiceType(id: string): void {
    this.subscription.add(
      this.clientModelService.getModel(id, false).subscribe((client: ClientModel) => {
        this.client = client;
        if (client.paymentFeePercent !== undefined && !isNaN(client.paymentFeePercent) && client.paymentFeePercent !== null) {
          this.paymentFee.paymentFee = client.paymentFeePercent;
        }
        if (client.paymentComission !== undefined && !isNaN(client.paymentComission) && client.paymentComission !== null) {
          this.paymentFee.paymentCommission = client.paymentComission;
        }
        this.paymentFee.applicationPaymentFeePercent = +client.applicationPaymentFeePercent;
        this.paymentFee.applicationPaymentCommission = +client.applicationPaymentComission;
        if (client.paymentServiceType) {
          this.paymentServiceType = client.paymentServiceType;
        }
      })
    );
  }

  public paymentFinished(event): void {
    this.infoMessage = 'Recurring settings have been successfully updated';
    this.showSuccessMessage(this.infoMessage);
    this.showMessagePage = true;
  }

  public getFee(amount: number, settings?: RecurringSettingsModel): number {
    let commissions = 0;
    if(!settings) {
      if(this.paymentServiceType === PaymentServiceType.Stripe) {
        commissions = +this.paymentFee?.paymentCommission + +this.paymentFee?.applicationPaymentCommission;
        return ((amount + commissions + (amount * this.paymentFee.applicationPaymentFeePercent)) / (1 - this.paymentFee?.paymentFee)) - amount;
      } else {
        return +this.paymentFee?.paymentCommission + amount * +this.paymentFee?.paymentFee
      }
    }

    else {
      if(settings.paymentServiceType === PaymentServiceType.Stripe) {
        commissions = +settings.paymentComission + +settings.applicationPaymentComission;
        return ((amount + commissions + (amount * settings.applicationPaymentFeePercent)) / (1 - settings.paymentFeePercent)) - amount;
      }
      else {
       return +settings.paymentComission + amount * +settings.paymentFeePercent
      }
    }
  }

  public getRecurringPayload(): RecurringSettingsModel {
    const recurringSettingsModel: RecurringSettingsModel = {
      reccurrencyPaymentType: this.reccurrencyPaymentType.value,
      maxCount: this.maxCount.value,
    };
    recurringSettingsModel.ammount = this.isEditPledge ? this.amountPerTransaction.value : this.ammount.value;
    return recurringSettingsModel;
  }

  private getDonor(id: string): void {
    this.subscription.add(
      this.clientDonorService.getModel(id).subscribe((clientDonor: ClientDonorModel) => {
        if (clientDonor) {
          const {firstName, lastName, email, street, city, state, postCode, country, district: territorialEntity, primaryPhone}: ClientDonorModel = clientDonor;
          this.filledInData = {
            ...this.filledInData,
            firstName,
            lastName,
            email,
            streetAddress: street,
            city,
            state,
            zipCode: postCode,
            country,
            territorialEntity,
            phone: primaryPhone ? primaryPhone.slice(this.countryState.getValue().phoneCode?.length) : ''
          };
        }
      })
    );
  }

  private getTransactions(): void {
    const paging: Paging = {
      includeDependencies: true,
      includeDeleted: false,
      filters: [{
        field: 'reccuringSettingsId',
        value: this.recurringSettingsId,
        type: FilterType.Equal,
      }],
      sortField: 'paymentDate',
      sortOrder: SortOrder.Descending,
    };

    this.transactionReviewService.GetRecurringSettingsScheduleById(this.recurringSettingsId)
      .pipe(
        first(),
        tap((transactions: TransactionReviewModel[]) => {
          const incompletePayments = transactions.filter(item => new Date(item.paymentDate).getTime() > new Date().getTime() && (item.status === PaymentTransactionReviewStatus.Scheduled || item.status === PaymentTransactionReviewStatus.Pledged));
          !!incompletePayments.length && this.nextExecutionDate.patchValue(incompletePayments[0].paymentDate);
          !this.nextExecutionDate.value && this.nextExecutionDate.setValue(new Date());
          this.transactions = transactions.sort(function(a, b) {
            return new Date(a.paymentDate).getTime() - new Date(b.paymentDate).getTime()
          });
          this.loadSubject.next();
        })
      )
      .subscribe((transactions: TransactionReviewModel[]) => {
        this.arePaymentsFinished = !transactions.some((transaction: TransactionReviewModel) => transaction.status === 3 || transaction.status === 4);
      });
  }

  public onPaymentFailed(): void {
    this.subscription.add(
      this.recurringSettingsService.updateModel(this.initialRecurringSettingsModel).subscribe(() => {
      })
    );
  }

  private openActionInfoForm(title: string, useAuthorizedPartyNameInput: boolean): Observable<ScheduleRecurringSettingModel> {
    const config: MatDialogConfig<RecurringActionFormData> = {
      data: { useAuthorizedPartyNameInput, title }
    }
    return this.dialog.open(RecurringActionFormComponent, config).afterClosed()
}

  private askForReason(): Observable<string | undefined> {
    return this.dialog.open(CancellingReasonComponent).afterClosed();
  }

  private setFundAllocation(fundAllocation: FundAllocationModel[]): void {
    fundAllocation.forEach(({name, fundId, amount}: FundAllocationModel) => {
      const formGroup: FormGroup = this.formBuilder.group({name, fundId, amount});
      this.fundAllocation.push(formGroup);
    });
  }
  private onRecurringActionCompleted(res: any) {
    if(res === 'CANCEL') return
    this.infoMessage = 'Recurring settings have been successfully updated';
    this.showSuccessMessage(this.infoMessage);
    this.showMessagePage = true;
  }

  public initiateCharge(reviewID: string) {
    this.subscription.add(
        this.openActionInfoForm('Initiate Charge', true).pipe(
            switchMap((formData: ScheduleRecurringSettingModel) => {
              if(!formData) return of(null);
              const model: ScheduleRecurringSettingModel = {
                reviewID,
                reccuringID: this.recurringSettingsId,
                oneTimePayment: true,
                ...formData
              }
              return this.recurringSettingsService.scheduleReccuringSetting(model)
            }),
            tap((res: HttpResponseBase) => {
              if(!res?.ok) return;
              this.toastrService.success('Reinstatement Confirmation email will be sent to the donor');
              this.getTransactions();
            })
        ).subscribe()
    )
  }

  private get cancelledPaymentDate(): Date {
    return new Date(this.transactions.filter(t => t.status === PaymentTransactionReviewStatus.Cancelled).slice(-1)[0].paymentDate);
  }

  private estimateNextExecutionDateControlValue(): void {
    if(this.recurringSettingsModel.nextExecutionStatusEnum === PaymentRecurrencyStatus.Cancelled) {
      this.nextExecutionDate.setValue(this.cancelledPaymentDate);
    }
  }

  public onFrequencyChanged(event: any) {
    // recurrency = 1 = weekly, 2 = monthly
    // everyValue = If weeks, number of weeks. || If months, number of months.
    if (event.value === DonationFrequency.Weekly) {
      // this.reccurrency.setValue(1)
      // this.everyValue.setValue(1)
    }
    if (event.value === DonationFrequency.BiWeekly) {
      // this.reccurrency.setValue(1)
      // this.everyValue.setValue(2)
    }
    if (event.value === DonationFrequency.Monthly) {
      // this.reccurrency.setValue(2)
      // this.everyValue.setValue(1)
    }
    if (event.value === DonationFrequency.Quarterly) {
      // this.reccurrency.setValue(2)
      // this.everyValue.setValue(3)
    }
    if (event.value === DonationFrequency.Yearly) {
      // this.reccurrency.setValue(2)
      // this.everyValue.setValue(12)
    }
  }

  public getDonationFrequency(frequencyType: number, frequencyValue: number): DonationFrequency {
    if (frequencyType === PaymentRepeatType.Weekly && frequencyValue === 1) {
      this.isOldRecurringOption = false;
      return DonationFrequency.Weekly
    }

    if (frequencyType === PaymentRepeatType.Monthly && frequencyValue === 1) {
      this.isOldRecurringOption = false;
      return DonationFrequency.Monthly
    }

    if (frequencyType === PaymentRepeatType.Yearly && frequencyValue === 1) {
      this.isOldRecurringOption = false;
      return DonationFrequency.Yearly
    }

    this.isOldRecurringOption = true;
    return null;
  }
}
