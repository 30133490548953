import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from '../base.model.service';
import SendGridLogModel from '../../models/reports/send.grid.log.model';
import { InfoNotificationModel } from 'src/app/models/reports/info.notification.model';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InfoNotificationService extends BaseModelService<
  InfoNotificationModel
> {
  constructor(protected injector: Injector) {
    super(injector);
  }
  protected get controllerName(): string {
    return 'infoNotification';
  }

  public updateAll(
    model: InfoNotificationModel[]
  ): Observable<InfoNotificationModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        const url = `${config.apiURL}/${this.controllerName}/UpdateBatch`;
        return this.http.put<InfoNotificationModel[]>(url, model);
      })
    );
  }

  public filterList(model: any): Observable<InfoNotificationModel[]> {
    return this.configService.loadConfig().pipe(
      flatMap((config) => {
        const url = `${config.apiURL}/${this.controllerName}/filterList`;
        return this.http.post<InfoNotificationModel[]>(url, model);
      })
    );
  }
}
