import {
  AfterViewInit,
  Component,
  ElementRef, HostListener, Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FrameModel } from '../../../../models/image-builder/frame.model';
import { ImageBuilderStateService } from '../../image-builder.state.service';
import { Subscription } from 'rxjs';
import { LayerModel } from '../../../../models/image-builder/layer.model';
import { LayerType } from '../../../../models/image-builder/layer.type';
import { ImageBuilderUtilsComponent } from '../../image-builder.utils.component';
import { NgxMoveableComponent } from 'ngx-moveable';
import LayerIndexModel from '../../../../models/image-builder/layer.index.model';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-layer',
  templateUrl: './layer.component.html',
  styleUrls: ['./layer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayerComponent extends ImageBuilderUtilsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public layer: LayerModel;
  @Input() public currentLayerIndex: number = 0;
  @Input() public ibss: ImageBuilderStateService;
  public draggable: boolean = false;
  public resizable: boolean = false;
  public rotatable: boolean = false;
  public scalable: boolean = false;

  public static: boolean = true;
  private lastActiveLayerIndex: number = null;

  @ViewChild('target') private target: ElementRef;
  @ViewChild(NgxMoveableComponent) private moveable: NgxMoveableComponent;

  private subscription: Subscription = new Subscription();

  @HostListener('window:click', ['$event']) public clickHandler(event: MouseEvent): void {
    if (this.layer.type !== LayerType.text || this.currentLayerIndex !== this.lastActiveLayerIndex) {
      return;
    }
    if (!this.target.nativeElement.contains(event.target)) {
      this.draggable = true;
      this.static = true;
    }
  }

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.ibss.activeLayerIndexObservable.subscribe((indexModel: LayerIndexModel) => {
        if (!indexModel) {
          this.deactivateLayer();
        } else {
          const {index, activate}: LayerIndexModel = indexModel;
          this.lastActiveLayerIndex = index;
          if (index === null) {
            this.deactivateLayer();
          } else {
            this.currentLayerIndex === index && activate ? this.activateLayer() : this.deactivateLayer();
          }
        }
      })
    );
  }

  public ngAfterViewInit(): void {
    const {translate, scale, rotate} = this.frame;
    this.renderer.setStyle(this.target.nativeElement, 'transform', `translate(${translate[0]}px, ${translate[1]}px) rotate(${rotate}deg) scale(${scale[0]}, ${scale[1]})`);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /* Drag */
  public onDragStart(event): void {
    event.set(this.frame.translate);
  }

  public onDrag(event): void {
    const {target, beforeTranslate} = event;
    this.frame.translate = beforeTranslate;
    target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px) rotate(${this.frame.rotate}deg) scale(${this.frame.scale[0]}, ${this.frame.scale[1]})`;
  }

  public onDragEnd(event): void {
    this.ibss.saveTemplate();
  }

  /* Resize */
  public onResizeStart(event): void {
    const {target, setOrigin, set, dragStart} = event;
    // Set origin if transform-orgin use %.
    setOrigin(['%', '%']);
    // If cssSize and offsetSize are different, set cssSize. (no box-sizing)
    const style = window.getComputedStyle(target);
    const cssWidth = parseFloat(style.width);
    const cssHeight = parseFloat(style.height);
    set([cssWidth, cssHeight]);
    // If a drag event has already occurred, there is no dragStart.
    dragStart && dragStart.set(this.frame.translate);
  }

  public onResize(event): void {
    const {target, width, height, drag} = event;
    target.style.width = `${width}px`;
    target.style.height = `${height}px`;
    // get drag event
    this.frame.translate = drag.beforeTranslate;
    target.style.transform = `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px) rotate(${this.frame.rotate}deg) scale(${this.frame.scale[0]}, ${this.frame.scale[1]})`;
  }

  public onResizeEnd(event): void {
    const {lastEvent} = event;
    this.layer.width = lastEvent.width;
    this.layer.height = lastEvent.height;
    this.ibss.saveTemplate();
  }

  /* Rotate */
  public onRotateStart(event): void {
    event.set(this.frame.rotate);
  }

  public onRotate(event): void {
    const {target, beforeRotate} = event;
    this.frame.rotate = beforeRotate;
    target.style.transform = `translate(${this.frame.translate[0]}px, ${this.frame.translate[1]}px) rotate(${beforeRotate}deg) scale(${this.frame.scale[0]}, ${this.frame.scale[1]})`;
  }

  public onRotateEnd(event): void {
    this.ibss.saveTemplate();
  }

  /* Scale */
  public onScaleStart(event): void {
    const {set, dragStart} = event;
    set(this.frame.scale);
    // If a drag event has already occurred, there is no dragStart.
    dragStart && dragStart.set(this.frame.translate);
  }

  public onScale(event): void {
    const {target, scale, drag} = event;
    this.frame.scale = scale;
    // get drag event
    this.frame.translate = drag.beforeTranslate;
    target.style.transform = `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px) rotate(${this.frame.rotate}deg) scale(${scale[0]}, ${scale[1]})`;
  }

  public onScaleEnd(event): void {
    this.ibss.saveTemplate();
  }

  private activateLayer(): void {
    const {readOnly, themedTemplate}: any = this.ibss;
    if (readOnly || themedTemplate) {
      return;
    }
    this.draggable = true;
    this.rotatable = true;
    this.resizable = !this.isShapeLayer(this.layer.type);
    this.scalable = this.isShapeLayer(this.layer.type);
  }

  private deactivateLayer(): void {
    this.draggable = false;
    this.resizable = false;
    this.rotatable = false;
    this.scalable = false;
    this.static = true;
  }

  public onLayerClick(): void {
    this.ibss.setActiveLayerIndex(this.currentLayerIndex);
  }

  private get frame(): FrameModel {
    return this.layer.frame;
  }

  public get layerTargetContainerStyle(): any {
    const {width, height, transparency}: LayerModel = this.layer;
    return {
      width: `${width}px`,
      height: `${height}px`,
      opacity: transparency,
    };
  }

  public onLayerDblClick(): void {
    if (this.layer.type !== LayerType.text || this.ibss.readOnly) {
      return;
    }
    this.draggable = !this.draggable;
    this.static = !this.static;
  }

  public get keepRatio(): boolean {
    if (!this.layer) return false;
    return this.layer.keepRatio;
  }

}
