<div class="image-builder-elements-wrapper">
  <div class="image-builder-elements-common">
    <!-- Text -->
    <div
      (click)="addLayer(LayerType.text)"
      class="tool-template"
      [class.draggable]="isDraggable"
      matRipple
      [draggable]="isDraggable"
      (dragstart)="onDragStart($event, LayerType.text)"
      (dragend)="onDragEnd($event)"
    >
      <i class="fas fa-font"></i>
      <span>{{'BUILD.Text' | translate}}</span>
    </div>

    <!-- Image -->
    <div
      (click)="addLayer(LayerType.image)"
      class="tool-template"
      [class.draggable]="isDraggable"
      matRipple
      [draggable]="isDraggable"
      (dragstart)="onDragStart($event, LayerType.image)"
      (dragend)="onDragEnd($event)"
    >
      <i class="far fa-image"></i>
      <span>{{'BUILD.Image' | translate}}</span>
    </div>

    <!-- Logo -->
    <div
      (click)="addLayer(LayerType.logo)"
      class="tool-template"
      [class.draggable]="isDraggable"
      matRipple
      [draggable]="isDraggable"
      (dragstart)="onDragStart($event, LayerType.logo)"
      (dragend)="onDragEnd($event)"
    >
      <div class="logo-icon">TM</div>
      <span>{{'BUILD.Logo' | translate}}</span>
    </div>

    <!-- Circle -->
    <div
      (click)="addLayer(LayerType.circle)"
      class="tool-template"
      [class.draggable]="isDraggable"
      matRipple
      [draggable]="isDraggable"
      (dragstart)="onDragStart($event, LayerType.circle)"
      (dragend)="onDragEnd($event)"
    >
      <i class="far fa-circle"></i>
      <span>{{'BUILD.Circle' | translate}}</span>
    </div>

    <!-- Square -->
    <div
      (click)="addLayer(LayerType.square)"
      class="tool-template"
      [class.draggable]="isDraggable"
      matRipple
      [draggable]="isDraggable"
      (dragstart)="onDragStart($event, LayerType.square)"
      (dragend)="onDragEnd($event)"
    >
      <i class="far fa-square"></i>
      <span>{{'BUILD.Square' | translate}}</span>
    </div>

    <!-- Triangle -->
    <div
      (click)="addLayer(LayerType.triangle)"
      class="tool-template"
      [class.draggable]="isDraggable"
      matRipple
      [draggable]="isDraggable"
      (dragstart)="onDragStart($event, LayerType.triangle)"
      (dragend)="onDragEnd($event)"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
        <polygon points="300,65 550,565 50,565 300,65" fill="none" stroke="#0064be" stroke-width="50" transform="scale(0.05)"></polygon>
      </svg>
      <span>{{'BUILD.Triangle' | translate}}</span>
    </div>

    <!-- Pentagon -->
    <div
      (click)="addLayer(LayerType.pentagon)"
      class="tool-template"
      [class.draggable]="isDraggable"
      matRipple
      [draggable]="isDraggable"
      (dragstart)="onDragStart($event, LayerType.pentagon)"
      (dragend)="onDragEnd($event)"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
        <polygon
          points="408.067,500.333 100.867,500.333 7,225.56 254.467,8.813 501.933,227.267"
          fill="none"
          stroke="#0064be"
          stroke-width="50"
          transform="translate(1, 1), scale(0.055)"
        ></polygon>
      </svg>
      <span>{{'BUILD.Pentagon' | translate}}</span>
    </div>

    <!-- Hexagon -->
    <div
      (click)="addLayer(LayerType.hexagon)"
      class="tool-template"
      [class.draggable]="isDraggable"
      matRipple
      [draggable]="isDraggable"
      (dragstart)="onDragStart($event, LayerType.hexagon)"
      (dragend)="onDragEnd($event)"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26">
        <polygon
          points="723,314 543,625.769145 183,625.769145 3,314 183,2.230855 543,2.230855 723,314"
          fill="none"
          stroke="#0064be"
          stroke-width="60"
          transform="translate(1, 1), scale(0.038)"
        ></polygon>
      </svg>
      <span>{{'BUILD.Hexagon' | translate}}</span>
    </div>
  </div>

  <mat-accordion multi>
    <mat-expansion-panel *ngIf="ibss.event">
      <mat-expansion-panel-header>
        <div class="panel-title">{{'BUILD.Event Elements' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="image-builder-elements-common">
          <!-- Event Name -->
          <div
            (click)="addLayer(LayerType.eventName)"
            class="tool-template"
            [class.draggable]="isDraggable"
            matRipple
            [draggable]="isDraggable"
            (dragstart)="onDragStart($event, LayerType.eventName)"
            (dragend)="onDragEnd($event)"
          >
            <i class="fas fa-heading"></i>
            <span>{{'BUILD.Event Name' | translate}}</span>
          </div>

          <!-- Event Description -->
          <div
            (click)="addLayer(LayerType.eventDescription)"
            class="tool-template"
            [class.draggable]="isDraggable"
            matRipple
            [draggable]="isDraggable"
            (dragstart)="onDragStart($event, LayerType.eventDescription)"
            (dragend)="onDragEnd($event)"
          >
            <i class="fas fa-comment-alt"></i>
            <span>{{'BUILD.Event Description' | translate}}</span>
          </div>

          <!-- Event Location -->
          <div
            (click)="addLayer(LayerType.eventLocation)"
            class="tool-template"
            [class.draggable]="isDraggable"
            matRipple
            [draggable]="isDraggable"
            (dragstart)="onDragStart($event, LayerType.eventLocation)"
            (dragend)="onDragEnd($event)"
          >
            <i class="fas fa-map-marked-alt"></i>
            <span>{{'BUILD.Event Location' | translate}}</span>
          </div>

          <!-- Event Scheduler -->
          <div
            (click)="addLayer(LayerType.eventScheduler)"
            class="tool-template"
            [class.draggable]="isDraggable"
            matRipple
            [draggable]="isDraggable"
            (dragstart)="onDragStart($event, LayerType.eventScheduler)"
            (dragend)="onDragEnd($event)"
          >
            <i class="fas fa-calendar-day"></i>
            <span>{{'BUILD.Event Scheduler' | translate}}</span>
          </div>

          <!-- Contact Details -->
          <div
            (click)="addLayer(LayerType.eventContactDetails)"
            class="tool-template"
            [class.draggable]="isDraggable"
            matRipple
            [draggable]="isDraggable"
            (dragstart)="onDragStart($event, LayerType.eventContactDetails)"
            (dragend)="onDragEnd($event)"
          >
            <i class="fas fa-at"></i>
            <span>{{'BUILD.Contact Details' | translate}}</span>
          </div>

          <!-- Schedule an Itinerary -->
          <div
            (click)="addLayer(LayerType.eventScheduleItinerary)"
            class="tool-template"
            [class.draggable]="isDraggable"
            matRipple
            [draggable]="isDraggable"
            (dragstart)="onDragStart($event, LayerType.eventScheduleItinerary)"
            (dragend)="onDragEnd($event)"
          >
            <i class="fas fa-clipboard-list"></i>
            <span>{{'BUILD.Schedule an Itinerary' | translate}}</span>
          </div>

          <!-- Accessibility Statement Text -->
          <div
            (click)="addLayer(LayerType.eventAccessibilityStatement)"
            class="tool-template"
            [class.draggable]="isDraggable"
            matRipple
            [draggable]="isDraggable"
            (dragstart)="onDragStart($event, LayerType.eventAccessibilityStatement)"
            (dragend)="onDragEnd($event)"
          >
            <i class="fas fa-universal-access"></i>
            <span>{{'BUILD.Accessibility Statement' | translate}}</span>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="ibss.campaign">
      <mat-expansion-panel-header>
        <div class="panel-title">{{'BUILD.Campaign Elements' | translate}}</div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="image-builder-elements-common">
          <!-- Campaign Name -->
          <div
            (click)="addLayer(LayerType.campaignName)"
            class="tool-template"
            [class.draggable]="isDraggable"
            matRipple
            [draggable]="isDraggable"
            (dragstart)="onDragStart($event, LayerType.campaignName)"
            (dragend)="onDragEnd($event)"
          >
            <i class="fas fa-heading"></i>
            <span>{{'BUILD.Campaign Name' | translate}}</span>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>

</div>
