import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';

@Component({
  selector: 'app-email-color-picker',
  templateUrl: './email-color-picker.component.html',
  styleUrls: ['./email-color-picker.component.scss']
})
export class EmailColorPickerComponent implements OnInit {
  @Input()
  public formGroup: FormGroup;
  @Input()
  public name: string;
  @Input()
  public label: string;
  @Input() public readOnly: boolean = false;
  @Input() public isNew: boolean = false;
  @Input() public colorPickerWithInput: boolean = false;

  public position: string = 'auto';
  @Output()
  public colorChanged: EventEmitter<string> = new EventEmitter<string>();

  public presetColors: string[] = [];

  constructor() { }

  ngOnInit() {
    this.presetColors.push(localStorage.getItem('color1'));
    this.presetColors.push(localStorage.getItem('color2'));
    this.presetColors.push(localStorage.getItem('color3'));
  }

  public get getPikerSize(): string {
    if (this.colorPickerWithInput && !this.isNew) {
      return 'small-picker';
    }
    if (this.colorPickerWithInput && this.isNew) {
      return 'new-picker';
    }
    return 'large-picker';
  }

  public get control(): FormControl {
    return this.formGroup.get(this.name) as FormControl;
  }

  public get color(): string {
    const value = this.control.value;
    return value ? value : '#ffffff';
  }

  public colorChange(color: string): void {
    if (!color) {
      return;
    }
    this.control.setValue(color);
    this.colorChanged.emit(color);
  }
}
