import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {CornerstoneService} from '../../cornerstone.service';
import {FormBuilderStateService} from '../../form-builder.state.service';
import {FormPageType} from '../../../../models/form-builder/form.page.type';
import {LookupStoreService} from '../../../../services/lookup/lookup-store.service';
import {Observable, of, Subject, Subscription} from 'rxjs';
import CountryModel from '../../../../models/internationalization/country.model';
import FbTemplateModel, {
  AlignmentType,
  FbElementModel,
  FbElementType,
  HeaderSize,
  OptionsViewType
} from '../../../../models/form-builder/fb.template.model';
import {ClientType} from '../../../../models/enum/client.type';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SettingImageModel} from '../element-options/top-image-options/top-image-options.component';
import FormElementDataModel from '../../../../models/form.element.data.model';
import {tap} from 'rxjs/operators';
import { AppStripeService } from '../../app.stripe.service';
import {ImgLoaderEmitterService} from '../../img-loader-emitter.service';
import {PaymentBillingType} from '../../../../models/enum/payment.billing.type';
import {PaymentRepeatType} from '../../../../models/enum/payment.repeat.type';
import {AdminConfirmationComponent} from '../../../../routes/admin/admin-confirmation/admin-confirmation.component';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import { UtilsComponent } from 'src/app/components/utils.component';
import { TranslateService } from '@ngx-translate/core';
import {DEFAULT_DISCLAIMER_TEXT} from "../../../../constants";
import {DonationRecurringOption} from "../../../../models/enum/donation-recurring-option.enum";

@Component({
  selector: 'app-create-tab',
  templateUrl: './create-tab.component.html',
  styleUrls: ['./create-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateTabComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() public fbss: FormBuilderStateService;
  @Input() public cornerstoneService: CornerstoneService;
  @Input() public appStripeService: AppStripeService;
  public donationInfoChanged$: Subject<void> = new Subject<void>();

  @ViewChild('donationInfoPageRef') private donationInfoPageRef: ElementRef;

  private subscription: Subscription = new Subscription();
  public countries: CountryModel[] = [];
  public FormPageType = FormPageType;
  public FbElementType = FbElementType;
  public OptionsViewType = OptionsViewType;

  private element: FbElementModel;

  public numberOfImage: SettingImageModel[] = [];

  public ClientType = ClientType;

  public availablePaymentPageElements: FbElementModel[] = [];

  public availableInfoPageElements: FbElementModel[] = [];

  public allocatedFundsOptions: FormElementDataModel[] = [];

  public containerWidth$: Observable<number>;

  public imageURL0: string;
  public imageURL1: string;
  public imageURL2: string;
  public imageURL3: string;

  private getDefaultAttributes(type: FbElementType): { [key: string]: any} {
    switch (type) {
      case FbElementType.Header:
        return {
          mainLabel: this.translate.instant('Type a header'),
          alignment: AlignmentType.Left,
          subLabel: this.translate.instant('Type a Sub-header'),
          size: HeaderSize.Large,
          duplicates: '1',
          content: [{mainLabel: '<div><span style="font-size: 60px; font-family: arial;">Type a header</span></div>', subLabel: '<div><span style="font-size: 30px; font-family: arial;">Type a Sub-header</span></div>'}]
        };
      case FbElementType.Comments:
        return {
          mainLabel: this.translate.instant('Add a Comment:'),
          mainLabelAlignment: AlignmentType.Top,
          subLabel: 'Let us know what you think about our work!'
        };
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    public formBuilder: FormBuilder,
    public lookupStoreService: LookupStoreService,
    private imfLoaderEmitterService: ImgLoaderEmitterService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    public translate: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.element = this.fbss.template$.getValue().topImage;
    this.getImgData();
    this.getAllocatedFundsOptions();
    this.getCountries();

    if (this.fbss.fundsChanged) {
      this.subscription.add(
        this.fbss.fundsChanged.pipe(tap(() => this.getAllocatedFundsOptions())).subscribe()
      );
    }

    this.subscription.add(
      this.fbss.template$.asObservable()
        .pipe(tap(() => this.getAllocatedFundsOptions()))
        .subscribe((template: FbTemplateModel) => {
          const infoPageElements = template.infoPage.elements;
          const paymentPageElements = template.paymentPage.elements;
          this.availableInfoPageElements = [];
          this.availablePaymentPageElements = [];
          if (!infoPageElements.find(element => element.type === FbElementType.Header)) {
            this.availableInfoPageElements.push({
              type: FbElementType.Header,
              attributes: this.getDefaultAttributes(FbElementType.Header),
              required: true,
            });
          }
          if (!paymentPageElements.find(element => element.type === FbElementType.Header)) {
            this.availablePaymentPageElements.push({
              type: FbElementType.Header,
              attributes: this.getDefaultAttributes(FbElementType.Header),
              required: true,
            })
          }
          if (!paymentPageElements.find(element => element.type === FbElementType.Comments)) {
            this.availablePaymentPageElements.push({
              type: FbElementType.Comments,
              attributes: this.getDefaultAttributes(FbElementType.Comments),
              required: false,
            })
          }
        })
    );

    this.subscription.add(
      this.fbss.bgImgAdded$
        .pipe(tap(() => this.mainContainerStyle))
        .subscribe()
    );

    this.subscription.add(
      this.fbss.tiersChanged$.pipe(tap(() => {
        this.fbssPaymentFormTiers.clear();
        this.setMokTiersForm();
      })).subscribe()
    );
    this.setMokTiersForm();

    this.subscription.add(
        this.fbss.disclaimersChanged$.pipe(tap(() => {
          this.fbssCustomDisclaimersForm.clear();
          this.setCustomDisclaimers();
        })).subscribe()
    )
  }

  private setMokTiersForm(): void {
    if (this.infoPage.tiersOptions) {
      JSON.parse(this.infoPage.tiersOptions).forEach((tier, i) => {
        this.fbssPaymentFormTiers.push(this.tiersFormGroup);
        this.setCustomDonationTiersValue(tier);
        this.fbssPaymentFormTiers.controls[i].get('tierName').setValue(tier.name);
        this.fbssPaymentFormTiers.controls[i].get('ammount').setValue(tier.ammount);
        this.fbssPaymentFormTiers.controls[i].get('tagId').setValue(tier.selectedTag);
        this.fbssPaymentFormTiers.controls[i].get('allowOwnAmount').setValue(tier.allowOwnAmount);
        this.fbssPaymentFormTiers.controls[i].get('isPledgeType').setValue(tier.isPledgeType);
        this.fbssPaymentFormTiers.controls[i].get('isEndDate').setValue(tier.isEndDate);
        if(tier.recurring) {
          this.fbssPaymentFormTiers.controls[i].get('recurring').setValue(false)
        }
      });
    }
  }
  private setCustomDonationTiersValue(tier) {
    this.fbssPaymentFormCustomDonation.get('reccurrencyPaymentType').setValue(1);
    this.fbssPaymentFormCustomDonation.get('allowOwnAmount').setValue(tier.allowOwnAmount);
    this.fbssPaymentFormCustomDonation.get('isPledgeType').setValue(tier.isPledgeType);
    this.fbssPaymentFormCustomDonation.get('isEndDate').setValue(tier.isEndDate);
    if(tier.recurring) this.fbssPaymentFormCustomDonation.get('recurring').setValue(false)
  }

  private setCustomDisclaimers() {
   if(this.infoPage.customDisclaimers) {
  this.infoPage.customDisclaimers.forEach((disclaimer, index) => {
      this.fbssCustomDisclaimersForm.push(this.fbss.disclaimersGroup);
      this.fbssCustomDisclaimersForm.controls[index].get('content').setValue(disclaimer.disclaimerText);
  })
}
  }
  private get tiersFormGroup(): FormGroup {
    return this.formBuilder.group({
      tierName: [],
      ammount: [null,[Validators.min(5)]],
      isTire: [true],
      isInclude: [false],
      recurring: [false],
      reccurrencyPaymentType: [PaymentBillingType.Recurring],
      agreedTermsConditions: [false],
      pledgeDonation: [false],
      reccurrencyValue: [1],
      everyValue: [1, [ Validators.min(1)]],
      startDate: [new Date()],
      amountPerTransaction: [null],
      endDate: [''],
      numberOfMonth: [null],
      customNumber: [null, [Validators.min(1), Validators.max(60)]],
      tagId: [],
      allowOwnAmount: [true],
      isPledgeType: [true],
      isEndDate: [true],
      reccurrency: [PaymentRepeatType.Monthly],
      donationFrequency: [2, [Validators.required]],
      tierDonationRecurringOption: [DonationRecurringOption.Once],
    });
  }

  public uploadFile(i: number): void {
    this.imfLoaderEmitterService.loaderEmitter.next(i);
  }

  public ngAfterViewChecked(): void {
    if (this.donationInfoPageRef) {
      this.containerWidth$ = of(this.donationInfoPageRef.nativeElement.clientWidth);
    }
  }

  private getAllocatedFundsOptions(): void {
    if (this.infoPage.funds) {
      this.allocatedFundsOptions = JSON.parse(this.infoPage.funds);
    }
  }

  public get infoPage(): FbElementModel {
    const fbTemplate = this.fbss.template$.getValue();
    if (!fbTemplate) {
      return null;
    }
    return fbTemplate.infoPage.elements.find(element => element.type === FbElementType.DonationInfo)
  }

  public get readOnly(): boolean {
    return this.fbss.readOnly;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public isImageURL(i: number): boolean {
    switch (i) {
      case 0:
        return !!this.imageURL0;
      case 1:
        return !!this.imageURL1;
      case 2:
        return !!this.imageURL2;
      case 3:
        return !!this.imageURL3;
      default:
        return false;
    }
  }

  public drop(event: CdkDragDrop<FbElementModel[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.fbss.templateWasChanged();
  }

  public switchPage(page: FormPageType): void {
    this.fbss.setFormPage(page);
  }

  public addLogo(): void {
    this.fbss.setOptionsView(OptionsViewType.Element);
    this.fbss.setActiveElementSettings(FbElementType.HeaderFooter);
  }

  public addSecondPage(): void {
    this.fbss.activateSecondPage();
  }

  public showPaymentPageElementSettings(element: FbElementModel): void {
    const template = this.fbss.template$.getValue();
    if (this.fbss.activeElementSettings$.getValue() === element.type && element.type === FbElementType.Header) {
      this.fbss.setOptionsView(OptionsViewType.None);
      this.fbss.setActiveElementSettings(null);
      setTimeout(() => {
        this.fbss.setOptionsView(OptionsViewType.Element);
        this.fbss.setActiveElementSettings(element.type);
      });
    } else {
      if (this.fbss.isTiersEmptyTier(template)) {
        this.toastrService.error('Please fill all mandatory fields');
        return
      }
      if (this.fbss.isTierHasImg(template) && (this.fbss.activeElementSettings$.getValue() === FbElementType.DonationInfo)) {
        const config = {
          data: {
            img: '/assets/images/icon-donor-heart.png',
            title: `${this.translate.instant('An image was not uploaded for all the tiers you have entered. Would you like to upload the default picture?')}`
          }
        };
        this.subscription.add(
          this.dialog.open(AdminConfirmationComponent, config).afterClosed()
            .subscribe((res: boolean) => {
              if (res) {
                this.fbss.setOptionsView(OptionsViewType.Element);
                this.fbss.setActiveElementSettings(element.type);
              }
            })
        );
        return;
      }
      this.fbss.setOptionsView(OptionsViewType.Element);
      this.fbss.setActiveElementSettings(element.type);
    }
  }

  public deletePaymentPageElement(paymentPageElements: FbElementModel[], index: number, availableElements) {
    const elements: FbElementModel[] = paymentPageElements.splice(index, 1);
    if (!elements || !elements[0]) {
      return;
    }
    const type = elements[0].type;
    const availableElement: FbElementModel = {
      type,
      attributes: this.getDefaultAttributes(type),
      required: elements[0].required,
    };
    availableElements.push(availableElement);
    this.fbss.setOptionsView(OptionsViewType.None);
    this.fbss.setActiveElementSettings(null);
    this.fbss.templateWasChanged();
  }

  /** Prevent adding cdkDrag element in cdkDropList container */
  public noReturnPredicate(): boolean {
    return false;
  }

  public blockTitle(type: FbElementType): string {
    switch (type) {
      case FbElementType.Header:
        return 'Header Block Element';
      case FbElementType.Comments:
        return 'Comment Block Element';
      case FbElementType.PaymentDetails:
        return 'Payment Details Block Element';
      case FbElementType.Phone:
        return 'Phone Block Element';
      case FbElementType.Address:
        return 'Address Block Element';
      case FbElementType.Email:
        return 'Email Block Element';
      case FbElementType.FullName:
        return 'Name Block Element';
      case FbElementType.PAC:
        return 'Political Action Committee Block Element';
      case FbElementType.DonationInfo:
        return 'Donation Info Block Element';
      default:
        return 'Payment Form Block';
    }
  }

  private get fbssCustomDisclaimersForm(): FormArray {
    return this.fbss.paymentForm.get('customDisclaimers') as FormArray;
  }

  private get fbssPaymentFormTiers(): FormArray {
    return this.fbss.paymentForm.get('tiers') as FormArray;
  }
  private get fbssPaymentFormCustomDonation(): FormGroup {
    return this.fbss.paymentForm.get('customDonationTiers') as FormGroup
  }

  private getCountries(): void {
    this.subscription.add(
      this.lookupStoreService.countries$.subscribe((countries: CountryModel[]) => {
        this.countries = countries;
      })
    );
  }

  public openFormDesign(): void {
    this.fbss.setOptionsView(OptionsViewType.Common);
  }

  public get pageStyle(): { [key: string]: string } {
    const template = this.fbss.template$.getValue();
    if (!template) {
      return {};
    }
    const {
      width = 760,
    }: FbTemplateModel = template;
    if (this.fbss.optionsView$.getValue() === OptionsViewType.None) {
      return {
        'width': `${width}px`,
      };
    } else {
      return {
        'max-width': `${width}px`,
      };
    }
  }

  public get mainContainerStyle(): { [key: string]: string } {
    const template = this.fbss.template$.getValue();
    if (!template) {
      return {};
    }
    const {
      pageColor = '#ECEDF3',
      formBgImg = '',
      backgroundRepeat = 'repeat',
      backgroundSize = 'auto'
    }: FbTemplateModel = template;
    const type = this.fbss.optionsView$.getValue();
    if (type === OptionsViewType.None) {
      return {
        'background-repeat': backgroundRepeat,
        'background-image' : formBgImg ? `url(${formBgImg})` : 'none',
        'background-size' : backgroundSize,
        'background-color': pageColor,
      };
    } else {
      return {
        'background-repeat': backgroundRepeat,
        'background-image' : formBgImg ? `url(${formBgImg})` : 'none',
        'background-size' : backgroundSize,
        'background-color': pageColor,
        'max-height': '1000px',
        'overflow': 'auto'
      };
    }
  }

  public get secondPageStyle(): { [key: string]: string } {
    const template = this.fbss.template$.getValue();
    if (!template) {
      return {};
    }
    const {
      formColor = '#ffffff',
      spacing = 10,
    }: FbTemplateModel = template;
    return {
      'background': formColor,
      'padding-bottom': `${spacing}px`,
    };
  }

  private getImgData(): void {
    const template = this.fbss.template$.getValue();
    if (template.topImage.attributes.settings) {
    } else {
      template.topImage.attributes.settings = JSON.stringify([template.topImage.attributes]);
      template.topImage.attributes.width = '1080px';
      template.topImage.attributes.height = '150px';
      template.topImage.attributes.paddingBottom = '0px';
      template.topImage.attributes.paddingTop = '0px';
      template.topImage.attributes.paddingRight = '0px';
      template.topImage.attributes.paddingLeft = '0px';
    }
    if (!template) {
      return;
    }
    const {
      settings
    }: { [key: string]: string } = template.topImage.attributes;
    if (settings) {
      this.numberOfImage = JSON.parse(settings);
      this.numberOfImage.forEach((item, i) => {
        this.assignVariables(i);
      });
    }
  }

  public getSettings(settingName: string): string {
    const template = this.fbss.template$.getValue();
    if (!template) {
      return;
    }
    const {
      width = '1080px',
      height = '150px',
      paddingBottom = '0px',
      paddingTop = '0px',
      paddingRight = '0px',
      paddingLeft = '0px',
    }: { [key: string]: string } = template.topImage.attributes;
    switch (settingName) {
      case 'width':
        return width;
      case 'height':
        return height;
      case 'top':
        return paddingTop;
      case 'right':
        return paddingRight;
      case 'bottom':
        return paddingBottom;
      case 'left':
        return paddingLeft;
      default:
        break;
    }
  }

  private assignVariables(i: number): void {
    switch (i) {
      case 0:
        this.imageURL0 = this.numberOfImage[i].url;
        break;
      case 1:
        this.imageURL1 = this.numberOfImage[i].url;
        break;
      case 2:
        this.imageURL2 = this.numberOfImage[i].url;
        break;
      case 3:
        this.imageURL3 = this.numberOfImage[i].url;
        break;
      default:
        break;
    }
  }

  public topImageStyle(i: number): Observable<string> {
    const template = this.fbss.template$.getValue();
    if (template.topImage.attributes.settings) {
    } else {
      template.topImage.attributes.settings = JSON.stringify([template.topImage.attributes]);
      template.topImage.attributes.width = '1080px';
      template.topImage.attributes.height = '150px';
      template.topImage.attributes.paddingBottom = '0px';
      template.topImage.attributes.paddingTop = '0px';
      template.topImage.attributes.paddingRight = '0px';
      template.topImage.attributes.paddingLeft = '0px';
    }
    if (!template) {
      return of(null);
    }
    const {
      settings
    }: { [key: string]: string } = template.topImage.attributes;
    if (settings) {
      this.numberOfImage = JSON.parse(settings);
      if (this.numberOfImage[i]) {
        this.assignVariables(i);
        return of(!this.numberOfImage[i].url ? null : `url(${this.numberOfImage[i] === undefined ? null : this.numberOfImage[i].url}) no-repeat ${this.numberOfImage[i] === undefined ? null : this.numberOfImage[i].position} / contain`);
      }
    }
  }

  public get logoSrc(): string {
    const {
      logoSrc = '/assets/images/logo.png'
    }: { [key: string]: string } = this.fbss.template$.getValue().headerFooter.attributes;
    return logoSrc;
  }

  public get logoStyle(): { [key: string]: string } {
    const {
      logoSize = HeaderSize.Medium,
    }: { [key: string]: string } = this.fbss.template$.getValue().headerFooter.attributes;
    return {
      width: 'auto',
      height: `${UtilsComponent.getLogoSize(logoSize as HeaderSize)}px`,
    };
  }

  public get headerStyle(): { [key: string]: string } {
    const template = this.fbss.template$.getValue();
    if (!template) {
      return {};
    }
    const {
      background = '#023665',
      logoPosition = AlignmentType.Left,
    }: { [key: string]: string } = template.headerFooter.attributes;
    let {logoSize = HeaderSize.Medium} = template.headerFooter.attributes;
    if (logoSize === HeaderSize.Small) {
      logoSize = HeaderSize.Medium;
    }
    return {
      background,
      'justify-content': UtilsComponent.getLogoPosition(logoPosition as AlignmentType),
      'min-height':  `${UtilsComponent.getLogoSize(logoSize as HeaderSize)}px`,
    };
  }

  public get footerStyle(): { [key: string]: string } {
    const template = this.fbss.template$.getValue();
    if (!template) {
      return {};
    }
    const {
      background = '#023665',
    }: { [key: string]: string } = template.headerFooter.attributes;
    return {
      background,
    };
  }
  public isPackOrEmployerElementType(element:any): boolean {
    return (element.type === FbElementType.PAC
        || element.type === FbElementType.Occupation
        || element.type === FbElementType.EmployerName
        || element.type === FbElementType.EmployerMailingAddress)
  }

  public get headerFooterAttributes(): { [key: string]: string } {
    return this.fbss.template$.getValue().headerFooter.attributes;
  }
}
