<div class="select-template-wrapper">
  <div class="d-flex mb-4 justify-content-between flex-wrap" [class.mb-4]="!(clientID && clientsWithRelationships.length > 1)">
    <div class="d-flex align-items-center mb-4">
      <div class="title-admin-templates mr-3">{{globalTemplate?.name || 'Template creation' | translate}}</div>
      <span class="donor-taxes-create-edit-back-link" (click)="onBackToAllTemplates()">
            < {{'Back to All Templates' | translate}}</span>
    </div>
    <div class="buttons">
      <app-button
              *ngIf="isLetterTemplatePage && !strictReadOnly && !!clientID"
              class="mr-4"
              type="flat"
              label="Create Campaign"
              (clickEvent)="showCreateCampaignDialog()"
              color="#0064be"
              width="200"
              [disabled]="isButtonDisabled"
      ></app-button>
      <app-button
        *ngIf="!strictReadOnly"
        class="mr-1"
        type="flat"
        label="SAVE"
        (clickEvent)="saveProcess()"
        color="#0064be"
        width="140"
        [disabled]="isButtonDisabled"
      ></app-button>

      <app-button
        *ngIf="updateInitialFormActive"
        class="ml-1"
        label="Update Initial Form"
        type="flat"
        color="#0064be"
        [disabled]="isButtonDisabled || isTemplateChanged"
        (clickEvent)="updateInitialForm()"
      ></app-button>
    </div>
  </div>

  <div *ngIf="clientID && clientsWithRelationships.length > 1" class="organization-name-wrapper">
    <span class="organization-name"><i class="fas fa-users-cog"></i> {{organizationName}}</span>
  </div>

  <div class="campaign-layout-dnd-wrapper">
    <app-email-builder
      *ngIf="isEmailBuilder"
      [eventForm]="isEventForm && eventForm"
      [eventHasRegistration]="isEventForm"
      [libraryVersion]="true"
      [eventStatus]="EventStatus.Draft"
      [campaignEventHasRegistrationForm]="isCampaignForm"
      [campaignForm]="isCampaignForm && campaignForm"
      [stateOptions]="lookupStoreService.usaStatesOptions$ | async"
      [timeZonesOptions]="lookupStoreService.timeZonesOptions$ | async"
      [timeZones]="lookupStoreService.timeZones$ | async"
      [eventId]="mockId"
      [campaignId]="isCampaignForm && mockId"
      [clientPortalVersion]="isClientPortal"
      [readOnly]="readOnly"
      [strictReadOnly]="strictReadOnly"
      [countryStateService]="countryStateService"
    ></app-email-builder>

    <app-image-builder
      *ngIf="isImageBuilder"
      [stateOptions]="lookupStoreService.usaStatesOptions$ | async"
      [timeZones]="lookupStoreService.timeZones$ | async"
      [event]="eventForm.getRawValue()"
      [campaign]="campaignForm.getRawValue()"
      [readOnly]="readOnly"
      [libraryVersion]="true"
      [strictReadOnly]="strictReadOnly"
    ></app-image-builder>

    <app-form-builder
      *ngIf="isFormBuilder"
      [readOnly]="readOnly"
      [templateIsSaved$]="templateIsSaved$.asObservable()"
      [isAdminPortal]="isAdminPortal"
    ></app-form-builder>
  </div>
</div>
