<div class="client-select-wrapper" [matMenuTriggerFor]="menu">
  <span class="selected-client-name"><i class="fas fa-users-cog"></i> {{clientName}} <i class="fas fa-filter selected-client-name-colored"></i></span>
</div>


<mat-menu #menu="matMenu">
  <div class="client-select-menu-wrapper">
    <div
      mat-menu-item
      *ngFor="let option of selectOptions"
      (click)="onMenuItemClick(option.value)"
      class="client-select-menu-item"
      [class.client-select-menu-item-active]="isActive(option.value)"
    >
      <span>{{option.label | translate}}</span>
    </div>
  </div>
</mat-menu>
