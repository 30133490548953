<div
  *ngIf="twitterAccounts > 0 || facebookAccounts > 0 || instagramAccounts > 0 || linkedinAccounts > 0 || youTubeAccounts > 0"
  class="custom-form-component-container-wrapper"
  [formGroup]="formGroup"
>
  <label class="form-field-name" [for]="id">{{label}}</label>
  <div class="select-row">
    <div
      *ngIf="facebookAccounts > 0"
      class="media-item"
      [class.active]="selectedProviders.has(SocialProviderEnum.Facebook)"
      (click)="toggleItem(SocialProviderEnum.Facebook)"
    >
      <i class="fab fa-facebook" [class.active]="selectedProviders.has(SocialProviderEnum.Facebook)"></i>
      <div class="media-item-name" [matTooltip]="facebookRestrictions | translate" matTooltipClass="tooltip-restrictions-list">Facebook</div>
    </div>
    <div
      *ngIf="instagramAccounts > 0"
      class="media-item"
      [class.active]="selectedProviders.has(SocialProviderEnum.Instagram)"
      (click)="toggleItem(SocialProviderEnum.Instagram)"
    >
      <i class="fab fa-instagram" [class.active]="selectedProviders.has(SocialProviderEnum.Instagram)"></i>
      <div class="media-item-name" [matTooltip]="instagramRestrictions | translate" matTooltipClass="tooltip-restrictions-list">Instagram</div>
    </div>
    <div
      *ngIf="twitterAccounts > 0"
      class="media-item"
      [class.active]="selectedProviders.has(SocialProviderEnum.Twitter)"
      (click)="toggleItem(SocialProviderEnum.Twitter)"
    >
      <i class="fa6 fab fa-square-x-twitter" [class.active]="selectedProviders.has(SocialProviderEnum.Twitter)"></i>
      <div class="media-item-name" [matTooltip]="twitterRestrictions | translate" matTooltipClass="tooltip-restrictions-list">X</div>
    </div>
    <div
      *ngIf="linkedinAccounts > 0"
      class="media-item"
      [class.active]="selectedProviders.has(SocialProviderEnum.Linkedin)"
      (click)="toggleItem(SocialProviderEnum.Linkedin)"
    >
      <i class="fab fa-linkedin" [class.active]="selectedProviders.has(SocialProviderEnum.Linkedin)"></i>
      <div class="media-item-name" [matTooltip]="linkedInRestrictions | translate" matTooltipClass="tooltip-restrictions-list">Linkedin</div>
    </div>
    <div *ngIf="youTubeAccounts > 0" class="media-item" [class.active]="selectedProviders.has(SocialProviderEnum.YouTube)" (click)="toggleItem(SocialProviderEnum.YouTube)" style="padding-left:12px;">
      <img src="assets/images/yt_logo_mono_dark.png" *ngIf="selectedProviders.has(SocialProviderEnum.YouTube)">
      <img src="assets/images/yt_logo_mono_light.png" *ngIf="!selectedProviders.has(SocialProviderEnum.YouTube)">
    </div>
  </div>
  <input
    [formControlName]="name"
    [id]="id"
  >
  <div class="error-message">
    <span *ngIf="formControl.touched && formControl.invalid">{{getErrorMessage()}}</span>
  </div>
</div>
