import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import FbTemplateModel, {
  AlignmentType,
  AvailablePaymentMethods,
  FbElementModel,
  FbElementType,
  FbTemplateStatusType,
  HeaderSize,
  OptionsViewType
} from '../../models/form-builder/fb.template.model';
import {UtilsComponent} from '../utils.component';
import {
  DEFAULT_DISCLAIMER_TEXT,
  DEFAULT_PAYMENT_FORM,
  DefaultRecurringDonationEmailSubjects,
  DefaultRecurringDonationEmailTexts
} from '../../constants';
import {FormPageType} from '../../models/form-builder/form.page.type';
import {FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {GiftType} from '../../models/enum/gift.type';
import {FormValidators} from '../../shared/validatiors/form-validators';
import {PaymentBillingType} from '../../models/enum/payment.billing.type';
import {PaymentRepeatType} from '../../models/enum/payment.repeat.type';
import FormElementDataModel from '../../models/form.element.data.model';
import {ClientModel} from '../../models/client/client.model';
import EventModel from '../../models/event/event.model';
import {CampaignModel} from '../../models/campaigns/campaign.model';
import SocialPostModel from '../../models/socialMedia/social.post.model';
import {ClientPaymentModel} from '../../models/client/client.payment.model';
import {PaymentMethodType} from '../../models/enum/payment.method.type';
import {ClientDonorType} from '../../models/enum/donor.type';
import {PaymentServiceType} from '../../models/enum/payment.service.type';
import {ClientModuleService} from '../../routes/clients/client.module.service';
import {ClientSettingsService} from '../../services/client/client.settings.service';
import {Paging} from '../../models/paging/paging.model';
import {AuthService} from '../../services/auth.service';
import {FilterType} from '../../models/enum/filter.type';
import {ClientSettingsModel} from '../../models/client/client.settings.model';
import {debounceTime, filter, first, map, tap} from 'rxjs/operators';
import TemplatePayload from '../../models/templates/template.payload';
import {TemplateType} from '../../models/templates/template.type';
import {ClientIdStateService} from '../../services/client.module.state/client.id.state.service';
import {Guid} from 'guid-typescript';
import {TimeZoneModel} from '../../models/time-zone.model';
import TemplateModel from '../../models/templates/template.model';
import {LookupStoreService} from '../../services/lookup/lookup-store.service';
import {SettingImageModel} from './components/element-options/top-image-options/top-image-options.component';
import {TiersSettingModel} from '../../models/event/tiers.setting.model';
import {TypesOfDonationsEnum} from '../../models/enum/types-of-donations.enum';
import {RecurringEmailType} from "../../models/enum/recurring-email-type";
import { Patterns } from 'src/app/shared/validatiors/patterns.model';
import { TranslateService } from '@ngx-translate/core';
import { DonationRecurringOption } from 'src/app/models/enum/donation-recurring-option.enum';

@Injectable()
export class FormBuilderStateService {
  public template$: BehaviorSubject<FbTemplateModel> = new BehaviorSubject<FbTemplateModel>(UtilsComponent.clone(DEFAULT_PAYMENT_FORM));
  public paymentPage$: BehaviorSubject<FormPageType> = new BehaviorSubject<FormPageType>(FormPageType.First);
  public distributeAmongFunds$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public client$: BehaviorSubject<ClientModel> = new BehaviorSubject<ClientModel>(null);
  public entity$: BehaviorSubject<EventModel | CampaignModel | SocialPostModel | ClientPaymentModel>
    = new BehaviorSubject<EventModel | CampaignModel | SocialPostModel | ClientPaymentModel>(null);
  public secondPageActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public paymentServiceType$: BehaviorSubject<PaymentServiceType> = new BehaviorSubject<PaymentServiceType>(null);
  public optionsView$: BehaviorSubject<OptionsViewType> = new BehaviorSubject<OptionsViewType>(OptionsViewType.None);
  public activeElementSettings$: BehaviorSubject<FbElementType> = new BehaviorSubject<FbElementType>(null);

  private forceSaveTemplateSubject: Subject<TemplatePayload> = new Subject<TemplatePayload>();
  public forceSaveTemplateEmitter: Observable<TemplatePayload> = this.forceSaveTemplateSubject.asObservable();

  /* subscription in Country State Service */
  public updateAddress$: Subject<void> = new Subject<void>();
  /* subscription in Header */
  public updateHeader$: Subject<void> = new Subject<void>();
  /* subscription in PaymentDetailsComponent */
  public updatePaymentMethod$: Subject<AvailablePaymentMethods> = new Subject<AvailablePaymentMethods>();

  private templatePayload: TemplatePayload;
  private invalidTemplateSettingsFormEmitter: Subject<void> = new Subject<void>();
  public invalidTemplate$: Observable<void> = this.invalidTemplateSettingsFormEmitter.asObservable();

  public addNewSourceTemplateModel$: Subject<string> = new Subject<string>();
  public updateDefaultClientTemplate$: Subject<boolean> = new Subject<boolean>();

  public isTopImageChange$: Subject<void> = new Subject<void>();

  private historyStorage: FbTemplateModel[] = [];
  private undoIndex: number = 0;
  public lastTemplateState: Partial<TemplateModel> = null;
  private historyStorageSource: Subject<void> = new Subject<void>();

  public fundsChanged: Subject<void> = new Subject<void>();
  public tiersChanged$: Subject<void> = new Subject<void>();
  public disclaimersChanged$: Subject<void> = new Subject<void>();
  public bgImgAdded$: Subject<void> = new Subject<void>();
  public isTiersEmpty: Subject<number> = new Subject<number>();
  public inSupportOfSubject$: Subject<any> = new Subject<any>();
  public isPledgeType: Subject<boolean> = new Subject<boolean>();
  public phonePrefix$ = new BehaviorSubject<string>('');
  public phoneMask$ = new BehaviorSubject<string>('');
  public phonePlaceholder$ = new BehaviorSubject<string>('');
  private isAdminPortal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public adminPortalRestriction: Observable<boolean> = this.isAdminPortal.asObservable();
  private recurringEmailOptions = Object.values(RecurringEmailType).filter(a => !isNaN(Number(a)) && Number(a) !== 0);

  public paymentForm: FormGroup = this.formBuilder.group({
    ammount: ['', [Validators.min(5)]],
    isAnonymous: [false],
    appeal: [false],
    isEmailSend: [false],
    giftType: [GiftType.InHonorOf],
    recipientName: [''],
    recipientEmail: ['', [FormValidators.emailValidator]],
    emailSubject: ['', [Validators.maxLength(250), FormValidators.containsPlaceholder('[NamedPerson]')]],
    emailText: ['', [FormValidators.containsPlaceholder('[NamedPerson]')]],
    sendEmail: [new Date()],
    namedPerson: ['', Validators.maxLength(50)],
    recurring: [false],
    reccurrencyPaymentType: [PaymentBillingType.Recurring],
    donationRecurringOption: [DonationRecurringOption.Once],
    startDate: [new Date()],
    endDate: [''],
    reccurrency: [PaymentRepeatType.Monthly],
    reccurrencyValue: [1],
    everyValue: [1, [Validators.min(1)]],
    donationFrequency: [PaymentRepeatType.Monthly],
    numberOfPeriods: ['', [Validators.min(1)]],
    amountPerTransaction: [''],
    agreedTermsConditions: [false],
    pledgeDonation: [false],
    fundAllocation: this.formBuilder.array([]),
    customDisclaimers: this.formBuilder.array([]),
    tiers: this.formBuilder.array([]),
    customDonationTiers: this.formBuilder.group({
      ammount: [null,[Validators.min(5)]],
      isTire: [false],
      isInclude: [false],
      recurring: [false],
      reccurrencyPaymentType: [PaymentBillingType.Recurring],
      tierDonationRecurringOption: [DonationRecurringOption.Once],
      donationRecurringOption: [DonationRecurringOption.Once],
      agreedTermsConditions: [false],
      pledgeDonation: [false],
      reccurrency: [PaymentRepeatType.Monthly],
      donationFrequency: [PaymentRepeatType.Monthly],
      reccurrencyValue: [1],
      everyValue: [1, [ Validators.min(1)]],
      startDate: [new Date()],
      amountPerTransaction: [null],
      endDate: [''],
      numberOfMonth: [null],
      customNumber: [null, [Validators.min(1), Validators.max(60)]],
      tagId: [],
      allowOwnAmount: [true],
      isPledgeType: [true],
      isEndDate: [true],
    })
  });


  public secondPaymentForm: FormGroup = this.formBuilder.group({
    organizationName: ['', [Validators.maxLength(250)]],
    organizationType: ['', [Validators.required]],
    organizationTypeName: [''],
    federalIDNumber: ['', [Validators.maxLength(20)]],
    firstName: ['', [Validators.maxLength(250)]],
    lastName: ['', [Validators.maxLength(250)]],
    email: ['', [FormValidators.emailValidator]],
    phone: [''],
    streetAddress: ['', [Validators.maxLength(250)]],
    streetAddress2: ['', [Validators.maxLength(250)]],
    zipCode: ['', [Validators.pattern('^(\\d{5}|\\d{9})$')]],
    city: ['', [Validators.maxLength(250)]],
    state: ['', [Validators.maxLength(250)]],
    paymentType: [PaymentMethodType.CreditCard],
    donorType: [ClientDonorType.Individual],
    includeFee: [true],
    includeEventFee: [false],
    country: [1],
    territorialEntity: ['', [Validators.maxLength(250)]],
    comment: [''],
    employer: this.formBuilder.group({
      occupation: ['', [Validators.maxLength(250)]],
      employer: ['', [Validators.maxLength(250)]],
      address1: ['', Validators.maxLength(250)],
      address2: ['', [Validators.maxLength(250)]],
      zipCode: ['', [Validators.pattern('^(\\d{5}|\\d{9})$')]],
      city: ['', [Validators.maxLength(250)]],
      state: ['', [Validators.maxLength(250)]],
      country: [1]
    }),
  });

  public achForm: FormGroup = this.formBuilder.group({
    routingNumber: [''],
    accountNumber: ['']
  });

  public templateSettingsForm: FormGroup = this.formBuilder.group({
    // FormSettingsComponent
    fbTemplateName: ['', [Validators.required, Validators.maxLength(50)]],
    fbTemplateStatus: [FbTemplateStatusType.Active],
    disableDate: [null, [Validators.required]],
    disableTime: [null, [Validators.required]],
    disableTimeZone: [13, [Validators.required]],
    messageWhenDisabled: ['We apologize, this form is currently unavailable!', [Validators.required, Validators.maxLength(250)]],
    // FbEmailSettingsComponent
    notifyEmails: [this.authService.getIdentityClaimsName(), {
      validators: FormValidators.emailListValidator,
      updateOn: 'blur'
    }],
    isSendDonationEmailDisabled: [false, [Validators.required]],
    emailNotification: [''],
    subjectEmailNotification: ['[FormName] Notification', [Validators.maxLength(100)]],
    recurringEmailGroup: new FormArray(this.recurringDonationEmailGroup),
    emailToDonor: [''],
    isCustomThankYouPage: [false],
    customThankYouPageUrl: ['', [Validators.required, Validators.pattern(Patterns.url)]],
    subjectEmailToDonor: ['We have Received your generous donation!', [Validators.maxLength(100)]],
  }, {validators: [this.timeValidator.bind(this)]});

  public publishForm: FormGroup = this.formBuilder.group({
    friendlyURL: [Guid.create().toString(), [Validators.required]],
    setAsAuxiliaDefault: false,
    currentFriendlyUrl: ['', [Validators.required, Validators.maxLength(50)]],
  });

  public allocatedFundsOptions: FormElementDataModel[] = [];

  public readOnly: boolean = false;

  private clientName: string;
  private facebookLink: string;
  private twitterLink: string;
  private instagramLink: string;
  private linkedInLink: string;
  private clientUrl: string;

  private primaryBrandColor: string;
  private timeZones: TimeZoneModel[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private clientModuleService: ClientModuleService,
    private clientSettingsService: ClientSettingsService,
    private clientIdStateService: ClientIdStateService,
    private authService: AuthService,
    public lookupStoreService: LookupStoreService,
    public translate: TranslateService,
  ) {
    const template: FbTemplateModel = this.template$.getValue();
    const paging: Paging = {
      includeDependencies: false,
      includeDeleted: false,
      filters: [
        {
          field: 'clientID',
          value: this.clientIdStateService.selectedRelationshipClientId,
          type: FilterType.Equal
        },
        {
          field: 'settingsName',
          value: '1',
          type: FilterType.Equal
        }
      ]
    };

    if (this.clientIdStateService.selectedRelationshipClientId) {
      this.clientSettingsService.getModelList(paging)
        .pipe(
          first(),
          filter(response => !!response && !!response.length),
          tap((settings: ClientSettingsModel[]) => {
            this.primaryBrandColor = settings[0].value;
            this.setBrandColor(template);
          }),
        )
        .subscribe();
    }


    this.lookupStoreService.timeZones$
      .pipe(
        first(),
        tap(timeZones => this.timeZones = timeZones)
      )
      .subscribe();

    this.historyStorageSource.asObservable()
      .pipe(
        debounceTime(500),
        filter(() => {
          const storageLength = this.historyStorage.length;
          const templateState = this.template$.getValue();
          return !(storageLength && JSON.stringify(this.historyStorage[storageLength - 1]) === JSON.stringify(templateState));
        }),
        tap(() => {
          if (this.undoIndex > 0) {
            this.historyStorage.splice(-this.undoIndex);
          }
          this.historyStorage.push(UtilsComponent.clone(this.template$.getValue()));
          if (this.historyStorage.length === 12) {
            this.historyStorage.splice(0, 1);
          }
          this.undoIndex = 0;
        })
      )
      .subscribe();
  }
  public get employerGroup():FormGroup {
    return this.secondPaymentForm.get('employer') as FormGroup;
  }

  public setTemplate(template: FbTemplateModel): void {
    this.setClientInfo(template);
    setTimeout(() => {
      this.tiersChanged$.next();
      this.disclaimersChanged$.next();
    }, 0);
    this.setBrandColor(template);
    this.template$.next(template);
  }

  public setFormPage(page: FormPageType): void {
    this.paymentPage$.next(page);
  }

  public get recurringEmailGroupFormArray(): FormArray {
    return this.templateSettingsForm.get('recurringEmailGroup') as FormArray;
  }

  public setClient(client: ClientModel): void {
    this.updateTemplateWithClientData(client);
    this.client$.next(client);
  }

  public get recurringDonationEmailGroup(): FormGroup[] {
    return this.recurringEmailOptions.map((opt: RecurringEmailType) => {
      return new FormGroup({
        type: new FormControl(opt),
        subject: new FormControl(this.getDefaultRecurringEmailSubject(opt)),
        BBC: new FormControl(''),
        email: new FormControl(this.getDefaultRecurringEmailContents(opt))
      })
    })
  }

  public setEntity(entity: EventModel | CampaignModel | SocialPostModel | ClientPaymentModel): void {
    this.entity$.next(entity);
  }

  private getDefaultRecurringEmailSubject(type: RecurringEmailType): string {
    return DefaultRecurringDonationEmailSubjects.find(el => el.value === type)?.label;
  }


  private getDefaultRecurringEmailContents(type: RecurringEmailType): string {
    return DefaultRecurringDonationEmailTexts.find(e => e.value === type)?.label;
  }

  public activateSecondPage(): void {
    this.secondPageActive$.next(true);
  }

  public setPaymentServiceType(paymentServiceType: PaymentServiceType): void {
    this.paymentServiceType$.next(paymentServiceType);
  }

  public setOptionsView(viewType: OptionsViewType): void {
    this.optionsView$.next(viewType);
    if (viewType !== OptionsViewType.Element) {
      this.setActiveElementSettings(null);
    }
  }

  public imgViewHandler(template: FbTemplateModel): void {
    const empty = '';
    const defaultUrl = '';
    const imageArray: SettingImageModel[] = JSON.parse(template.topImage.attributes.settings);
    if (imageArray.length > 1) {
      const filterImages = imageArray.filter(item => item.url !== empty);
      if (filterImages.length) {
        template.topImage.attributes.settings = JSON.stringify(filterImages);
        this.isTopImageChange$.next();
      } else {
        filterImages.push({url: defaultUrl, position: 'center'});
        template.topImage.attributes.settings = JSON.stringify(filterImages);
        this.isTopImageChange$.next();
      }
    } else {
      if (imageArray[0].url === empty) {
        imageArray[0].url = defaultUrl;
        template.topImage.attributes.settings = JSON.stringify(imageArray);
        this.isTopImageChange$.next();
      }

    }
  }

  public isTierHasImg(template: FbTemplateModel): boolean {
    const empty = '';
    const infoPage = template.infoPage.elements.find(element => element.type === FbElementType.DonationInfo);
    if (infoPage.typesOfDonations === TypesOfDonationsEnum.Tier) {
      const tiersArray: TiersSettingModel[] = JSON.parse(infoPage.tiersOptions);
      const isImg = tiersArray.some(tier => tier.imgUrl === empty);
      return isImg;
    }
    return false;
  }

  public isTiersEmptyTier(template: FbTemplateModel): boolean {
    const empty = '';
    const infoPage = template.infoPage.elements.find(element => element.type === FbElementType.DonationInfo);
    if (infoPage.typesOfDonations === TypesOfDonationsEnum.Tier) {
      const tiersArray: TiersSettingModel[] = JSON.parse(infoPage.tiersOptions);
      const emptyIndex = tiersArray.findIndex(tier => tier.name === empty || tier.description === empty || tier.ammount === empty || Number(tier.ammount) < 5);
      if (emptyIndex !== -1) {
        this.isTiersEmpty.next(emptyIndex);
        return true;
      }
    }
    return false;
  }

  public setActiveElementSettings(elementType: FbElementType): void {
    this.activeElementSettings$.next(elementType);
  }

  public setAsFormDefault(alignmentType: AlignmentType): void {
    const elements = this.template$.getValue().paymentPage.elements;
    elements.forEach((element: FbElementModel) => element.attributes.mainLabelAlignment = alignmentType);
  }

  private setBrandColor(template: FbTemplateModel): void {
    const {primaryButtonColorChanged, secondaryButtonColorChanged} = template;
    if (this.primaryBrandColor) {
      !primaryButtonColorChanged && (template.primaryButtonColor = this.primaryBrandColor);
      !secondaryButtonColorChanged && (template.secondaryButtonColor = this.primaryBrandColor);
    }
  }

  private setClientInfo(template: FbTemplateModel): void {
    const infoPage = template.infoPage.elements.find(element => element.type === FbElementType.DonationInfo);
    if (this.clientName && infoPage.text === 'Donate to Auxilia') { //todo should be reworked
      infoPage.text = `${this.translate.instant('Donate to')} ${this.clientName}`;
    }
    if (this.facebookLink) {
      template.headerFooter.attributes.facebookLink = this.facebookLink;
    }
    if (this.twitterLink) {
      template.headerFooter.attributes.twitterLink = this.twitterLink;
    }
    if (this.instagramLink) {
      template.headerFooter.attributes.instagramLink = this.instagramLink;
    }
    if (this.linkedInLink) {
      template.headerFooter.attributes.linkedInLink = this.linkedInLink;
    }
    if (this.clientUrl) {
      template.headerFooter.attributes.logoTarget = this.clientUrl;
    }
  }

  /** On save click */
  public triggerSaveTemplate(templatePayload: TemplatePayload): void {
    this.forceSaveTemplateSubject.next(templatePayload);
  }

  public triggerTemplatePayload(): void {
    if (!this.templateSettingsFormValid()) {
      return;
    }
    const payload: TemplatePayload = {
      ...this.templatePayload,
      ...this.templateSettingsForm.getRawValue(),
      templateType: TemplateType.Layout,
      name: this.templateSettingsForm.get('fbTemplateName').value,
      customThankYouPageUrl: this.templateSettingsForm.get('customThankYouPageUrl').value
    };
    this.triggerSaveTemplate(payload);
  }

  public patchTemplateSettingsForm(templatePayload: TemplatePayload): void {
    this.templatePayload = templatePayload;
    this.templateSettingsForm.patchValue(templatePayload);
  }

  public setReadOnly(readOnly: boolean): void {
    this.readOnly = readOnly;
  }

  private updateTemplateWithClientData(client: ClientModel): void {
    const {name, facebookLink, twitterLink, instagramLink, linkedinLink, url}: ClientModel = client;
    this.clientName = name;
    this.facebookLink = facebookLink;
    this.twitterLink = twitterLink;
    this.instagramLink = instagramLink;
    this.linkedInLink = linkedinLink;
    this.clientUrl = url;
    this.setClientInfo(this.template$.getValue());
  }

  private timeValidator(formGroup: FormGroup): ValidationErrors {
    if (this.readOnly) {
      return null;
    }
    const {fbTemplateStatus, disableDate, disableTime, disableTimeZone}: TemplateModel = formGroup.value as TemplateModel;
    if (fbTemplateStatus !== FbTemplateStatusType.DisableOnDate || !disableDate || !disableTime || !disableTimeZone) {
      return null;
    }
    const offset = this.timeZones.find(({id}: TimeZoneModel) => id === disableTimeZone).offset;
    const checkDate = UtilsComponent.getCustomDate(new Date(disableDate), disableTime, offset);
    if (checkDate < new Date()) {
      formGroup.controls.disableTime.setErrors({invalidTime: true});
    } else {
      formGroup.controls.disableTime.setErrors(null);
    }
    return checkDate > new Date() ? null : {invalidTime: true};
  }

  /** Call when template was changed */
  public templateWasChanged(onlyForState: boolean = false): void {
    if (!onlyForState) {
      this.historyStorageSource.next();
    }
    const model = {...this.templateSettingsForm.value};
    UtilsComponent.transformRecurringEmailGroupToTemplateModelProps((model as TemplateModel), model.recurringEmailGroup);
    delete model.recurringEmailGroup;
    delete model.fbTemplateName;
    this.lastTemplateState = {
      content: JSON.stringify(this.template$.getValue()),
      ...model
    };
  }

  public resetHistoryStorage(): void {
    this.historyStorage = [];
    this.undoIndex = 0;
    setTimeout(() => this.historyStorageSource.next(), 500);
  }

  public templateSettingsFormValid(): boolean {
    this.templateSettingsForm.markAllAsTouched();
    if (this.templateSettingsForm.invalid) {
      this.invalidTemplateSettingsFormEmitter.next();
      return false;
    } else {
      return true;
    }
  }

  public undo(): void {
    const length = this.historyStorage.length;
    if (length <= 1 || this.undoIndex === length - 1) {
      return;
    }
    const template = UtilsComponent.clone(this.historyStorage[length - 1 - ++this.undoIndex]);
    this.refreshView(template);
  }

  public redo(): void {
    if (this.undoIndex === 0) {
      return;
    }
    const length = this.historyStorage.length;
    const template = UtilsComponent.clone(this.historyStorage[length - 1 - --this.undoIndex]);
    this.refreshView(template);
  }

  private refreshView(template: FbTemplateModel): void {
    this.template$.next(template);
    const view = this.optionsView$.getValue();
    const elementType = this.activeElementSettings$.getValue();
    if (view !== OptionsViewType.None) {
      this.setOptionsView(view);
      if (view === OptionsViewType.Element) {
        this.activeElementSettings$.next(elementType);
      }
    }
  }

  public get undoDisable(): boolean {
    const length = this.historyStorage.length;
    return length <= 1 || this.undoIndex === length - 1 || this.readOnly;
  }

  public get redoDisable(): boolean {
    return this.undoIndex === 0 || this.readOnly;
  }

  public setAdminPortalRestrictions(isAdminPortal: boolean): void {
    this.isAdminPortal.next(isAdminPortal);
  }
  public get disclaimersGroup(): FormGroup {
    return this.formBuilder.group({
      content: [DEFAULT_DISCLAIMER_TEXT],
      confirmed: false
    })
  }
}
