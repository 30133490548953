<div class="wrapper">
  <div class="fb-options-row">
    <div class="fb-row-element-full">
      <app-slider
        [formGroup]="settingForm"
        name="width"
        label="Block Width (px)"
        [min]="350"
        [max]="1080"
        [step]="25"
        [tickInterval]="25"
        (valueChanged)="onSettingsChange('width')"
        [disabled]="readOnly"
      ></app-slider>
    </div>
  </div>
  <div class="fb-options-row">
    <div class="fb-row-element-full">
      <app-slider
        [formGroup]="settingForm"
        name="height"
        label="Block Height (px)"
        [min]="150"
        [max]="400"
        [step]="10"
        [tickInterval]="10"
        (valueChanged)="onSettingsChange('height')"
        [disabled]="readOnly"
      ></app-slider>
    </div>
  </div>
  <div class="fb-options-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="settingForm"
        name="paddingTop"
        label="Padding Top"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onSettingsChange('top')"
        [disabled]="readOnly"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="settingForm"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onSettingsChange('right')"
        [disabled]="readOnly"
      ></app-slider>
    </div>
  </div>
  <div class="fb-options-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="settingForm"
        name="paddingBottom"
        label="Padding Bottom"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onSettingsChange('bottom')"
        [disabled]="readOnly"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="settingForm"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onSettingsChange('left')"
        [disabled]="readOnly"
      ></app-slider>
    </div>
  </div>
  <div class="img-element" *ngFor="let image of numberOfImage; index as i">
    <p class="top-title">{{'BUILD.Image' | translate}} {{i + 1}}</p>
    <div class="fb-options-row">
      <div class="fb-row-element-full">
        <!-- <div
          class="top-img-icon"
          [matTooltip]="topImgInfoMessage"
          matTooltipHideDelay="200"
          matTooltipShowDelay="200"
          matTooltipPosition="above"
          matTooltipClass="top-img-icon-tool"
        >
          <i class="fas fa-info-circle"></i>
        </div> -->
        <app-image-loader
            #fileReaderInput
            [formGroup]="getForm(i)"
            name="url"
            label="Upload Image"
            [readOnly]="readOnly"
            [imageURL]="getImageUrl(i)"
            (valueChanged)="imageUrlChanged($event, i); onUrlChanged(i)"
            [matTooltip]="topImgInfoMessage"
        ></app-image-loader>
      </div>
    </div>

    <div class="fb-options-row">
      <div class="fb-row-element-full">
        <app-input
          label="Image URL"
          [formGroup]="getForm(i)"
          name="url"
          errorMassage="Image URL"
          [low]="true"
          [disabled]="readOnly"
          (valueChanged)="imageUrlChanged($event, i); onUrlChanged(i)"
        ></app-input>
      </div>
    </div>

    <div class="fb-options-row">
      <div class="fb-row-element-full">
        <app-select
          [formGroup]="getForm(i)"
          name="position"
          label="Image Position"
          [low]="true"
          (valueChanged)="onPositionChanged(i)"
          [disabled]="readOnly"
          [selectOptions]="positionOptions"
        ></app-select>
      </div>

      <app-button
        class="edit-img-btn"
        *ngIf="getUrl(i) && getUrl(i).value && !getImageUrl(i) && !readOnly"
        label="Edit Image"
        type="flat"
        (clickEvent)="editImage(i)"
        color="#0064be"
        width="170"
      ></app-button>
      <!--<app-button
        *ngIf="!!getImageUrl(i)"
        label="Cancel"
        type="flat"
        (clickEvent)="cancelEditImage(i)"
        color="#ec4c47"
        width="140"
      ></app-button>-->
    </div>
    <div class="fb-options-row" *ngIf="isMoreThenOne">
      <app-button
        class="edit-img-btn"
        type="basic"
        label="Delete Image"
        (clickEvent)="deleteImg(i)"
        color="#F44336"
        width="310"
      >
      </app-button>
    </div>
    <div class="fb-row-element-full">
      <hr class="line"/>
    </div>
  </div>
  <div class="fb-options-row" *ngIf="isArrayFull">
    <app-button
      class="edit-img-btn"
      type="flat"
      (clickEvent)="addImage()"
      color="#0064be"
      width="310"
    >
      <svg width="24" height="24" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g data-name="Layer 2">
          <g data-name="image">
            <rect width="24" height="24" opacity="0"/>
            <path d="M18 3H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3zM6 5h12a1 1 0 0 1 1 1v8.36l-3.2-2.73a2.77 2.77 0 0 0-3.52 0L5 17.7V6a1 1 0 0 1 1-1zm12 14H6.56l7-5.84a.78.78 0 0 1 .93 0L19 17v1a1 1 0 0 1-1 1z"/>
            <circle cx="8" cy="8.5" r="1.5"/>
          </g>
        </g>
      </svg>
<!--      <i style="margin-right: 5px" class="fas fa-image"></i>-->
      <span>
          {{'BUILD.Add Image' | translate}}
      </span>
    </app-button>
  </div>
  <div class="fb-options" *ngIf="!isArrayFull">
    <span class="message">
      {{'BUILD.You can upload up to 4 pictures' | translate}}
    </span>
  </div>
</div>
