import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { noop, Subscription } from 'rxjs';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TemplateType } from '../../../../models/templates/template.type';
import { MjmlCustomType } from '../../../../models/templates/mjml.custom.type';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { TEXT_ALIGN } from '../../../../constants';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import { MatDialog } from '@angular/material/dialog';
import { VideoScreenshotsComponent } from '../video-screenshots/video-screenshots.component';
import { AzureUploadFileService } from '../../../../services/lookup/azure-upload-file.service';

@Component({
  selector: 'app-email-video-tool-settings',
  templateUrl: './email-video-tool-settings.component.html',
  styleUrls: ['./email-video-tool-settings.component.scss', '../tool-settings.scss']
})
export class EmailVideoToolSettingsComponent implements OnInit, OnDestroy {
  public MjmlTag = MjmlTag;
  private subscription: Subscription = new Subscription();
  @Input() public tms: TemplateManagementService;

  public imageURL: string = null;
  public alignOptions: FormElementDataModel[] = TEXT_ALIGN;
  //public apiURL: string = localStorage.getItem('apiurl');
  public storageURL: string = localStorage.getItem('storageURL');

  public emailVideoForm: FormGroup = this.formBuilder.group({
    poster: '',
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]],
    videoSrc: '',
  });

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private uploadService: AzureUploadFileService,
  ) { }

  public ngOnInit(): void {
    this.setInitialValues();

    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (!element || element.tagName !== MjmlTag.text || element.attributes['customType'] !== MjmlCustomType.emailVideo) {
          return;
        }
        this.setInitialValues();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public imageUrlChanged(url: string): void {
    this.imageURL = null;
    this.tms.activeElement.attributes['poster'] = url ? url : '';
    this.updateVideo();
    this.tms.emitSave(true);
  }

  public editImage(): void {
    this.imageURL = this.emailVideoForm.get('poster').value;
  }

  public cancelEditImage(): void {
    this.imageURL = null;
  }

  private setInitialValues(): void {
    const attributes = this.tms.activeElement.attributes;
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';
    const poster = attributes['poster'] || '';
    const videoSrc = attributes['video-src'] || '';

    this.emailVideoForm.get('poster').setValue(poster);
    this.emailVideoForm.get('paddingBottom').setValue(paddingBottom.slice(0, -2));
    this.emailVideoForm.get('paddingLeft').setValue(paddingLeft.slice(0, -2));
    this.emailVideoForm.get('paddingRight').setValue(paddingRight.slice(0, -2));
    this.emailVideoForm.get('paddingTop').setValue(paddingTop.slice(0, -2));
    this.emailVideoForm.get('videoSrc').setValue(videoSrc);
  }

  private updateVideo(): void {
    const poster = this.emailVideoForm.get('poster').value;
    const videoSrc = this.videoSrc.value;
    this.tms.activeElement.content = this.tms.getEmailVideoHtml(videoSrc, poster);
    this.tms.videoElementChanged();
  }

  public onVideoSrcChanged(): void {
    const value = this.videoSrc.value;
    this.tms.activeElement.attributes['video-src'] = value ? value : '';
    this.tms.emitSave(true);
    this.updateVideo();
  }

  public onPaddingTopChanged(): void {
    const value = this.emailVideoForm.get('paddingTop').value;
    this.tms.activeElement.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.emailVideoForm.get('paddingBottom').value;
    this.tms.activeElement.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.emailVideoForm.get('paddingRight').value;
    this.tms.activeElement.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.emailVideoForm.get('paddingLeft').value;
    this.tms.activeElement.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public makePoster(): void {
    const config = {
      height: '90vh',
      width: '90vw',
      data: {
        link: this.videoSrc.value
      }
    };
    this.subscription.add(
      this.dialog.open(VideoScreenshotsComponent, config).afterClosed()
        .subscribe(response => {
          if (!response) {
            return;
          }
          const poster = this.poster.value;
          if (poster) {
            //this.deletePrevious(poster);
          }
          const url = `${this.storageURL}/AzureFileStorage/image/${response}`;
          this.poster.setValue(url);
          this.imageUrlChanged(url);
        })
    );
  }

  public get videoSrc(): FormControl {
    return this.emailVideoForm.get('videoSrc') as FormControl;
  }

  public get poster(): FormControl {
    return this.emailVideoForm.get('poster') as FormControl;
  }

  /*private deletePrevious(url: string): void {
    const urlArray = url.split('/');
    const fileName = urlArray[urlArray.length - 1];
    this.subscription.add(
      this.uploadService.deleteModel(fileName).subscribe()
    );
  }*/

}
