<div class="email-builder-tool-settings-wrapper">
  <div class="email-builder-tool-settings-row">
    <app-button
      label="Add New Or Update Chart / Graph"
      width="310"
      color="#0064eb"
      type="flat"
      (clickEvent)="setNgxChart()"
      [disabled]="readOnly || isPredesignedThemedTemplate"
    ></app-button>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        label="Chart URL"
        [formGroup]="chartForm"
        name="src"
        errorMassage="Chart URL"
        [low]="true"
        [disabled]="readOnly || isPredesignedThemedTemplate"
        (valueChanged)="onSrcChanged()"
      ></app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="chartForm"
        name="paddingTop"
        label="Padding Top"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingTopChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="chartForm"
        name="paddingRight"
        label="Padding Right"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingRightChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>
  <div class="email-builder-tool-settings-row">
    <div class="max-width-145 right-margin">
      <app-slider
        [formGroup]="chartForm"
        name="paddingBottom"
        label="Padding Bottom"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingBottomChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
    <div class="max-width-145">
      <app-slider
        [formGroup]="chartForm"
        name="paddingLeft"
        label="Padding Left"
        [min]="0"
        [max]="100"
        [step]="1"
        [tickInterval]="10"
        (valueChanged)="onPaddingLeftChanged()"
        [disabled]="readOnly || isPredesignedThemedTemplate"
      ></app-slider>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        label="Link to redirect to on click"
        [formGroup]="chartForm"
        name="href"
        errorMassage="URL"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onHrefChanged()"
      ></app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row">
    <div class="max-width-310">
      <app-input
        label="Tooltip"
        [formGroup]="chartForm"
        name="title"
        errorMassage="Tooltip"
        [low]="true"
        [disabled]="readOnly"
        (valueChanged)="onTitleChanged()"
      ></app-input>
    </div>
  </div>

  <div class="email-builder-tool-settings-row" *ngIf="!(readOnly || isPredesignedThemedTemplate)">
    <app-button
      type="basic"
      label="Delete content"
      (clickEvent)="tms.removeActiveElementFromColumn()"
      color="#f44336"
      width="300"
    ></app-button>
  </div>
</div>

