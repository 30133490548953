import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
  @Input()
  public lineChartData: ChartDataSets[] = [];
  @Input()
  public lineChartLabels: Label[] = [];

  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    aspectRatio: 3,
    tooltips: {
      backgroundColor: '#f9fafc',
      titleFontSize: 14,
      titleFontColor: '#31394d',
      displayColors: false,
      cornerRadius: 3,
      bodyFontColor: '#31394d',
      bodyAlign: 'center',
      titleAlign: 'center',
      xPadding: 20
    },

    scales: {
      xAxes: [{
        ticks: {fontColor: '#748aa1'},
        gridLines: {display: false},
      }],
      yAxes: [
        {
          ticks: {
            fontColor: '#748aa1',
            min: 0,
            //stepSize: 1
          },
          position: 'left',
          gridLines: {
            color: 'rgba(63, 63, 68, 0.05)',
            zeroLineColor: 'rgba(63, 63, 68, 0.05)'
          },
        }
      ]
    },
    annotation: {},
  };

  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(148,159,177,0)',
      borderColor: '#4072ee',
      pointBackgroundColor: '#fff',
      pointBorderColor: '#4072ee',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#4072ee',
      pointHoverRadius: 8,
      pointHoverBorderWidth: 3,
      borderWidth: 2,
      pointRadius: 1,
    }
  ];
  public lineChartLegend = false;
  public lineChartType: ChartType = 'line';
  @ViewChild(BaseChartDirective, {static: true}) chart: BaseChartDirective;

  constructor() { }

  ngOnInit() {
  }

}
