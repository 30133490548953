import { BaseModelService } from '../base.model.service';
import { FilterType } from '../../models/enum/filter.type';
import { flatMap, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Paging } from 'src/app/models/paging/paging.model';
import { PagingHelper } from '../helper/paging.helper';
import SendGridLogModel from '../../models/reports/send.grid.log.model';
import DonorTrackedEmailModel from '../../models/donor/donor-tracked-email.model';
import { TotalRowsModel } from '../../models/paging/totalrow.model';

@Injectable({
  providedIn: 'root'
})
export class DonorTrackedEmailService extends BaseModelService<DonorTrackedEmailModel> {
  constructor(protected injector: Injector) {
    super(injector);
  }
  protected get controllerName(): string {
    return 'DonorTrackedEmail';
  }

  public resendEmails(ids: string[]): Observable<boolean> {
    return this.configService.loadConfig().pipe(
      flatMap(config => {
        const url = `${config.apiURL}/${this.controllerName}/ResendEmails`;
        return this.http.post<any>(url, ids);
      })
    );
  }
}
