<div class="widget-wrapper">

  <div class="widget-header">
    <span class="widget-title">{{'DASH.Events Summary' | translate}}</span>

    <div class="widget-header-actions">
      <!--<div class="widget-header-sort">
        <span class="widget-sort-by">Sort by:</span>
        <mat-form-field >
          <mat-select (selectionChange)="sortByChange($event)">
            <mat-option
              *ngFor="let option of sortOptions"
              [value]="option"
            >{{option}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>-->
      <app-button
        label="VIEW ALL"
        (clickEvent)="navigateToEvents()"
        type="flat"
        color="#0064be"
      ></app-button>
    </div>
  </div>

  <div class="widget-content">
    <app-event-list-table
      [data]="tableData"
      [tableDataColumns]="tableColumns"
      [columnsFilterOptions]="columnsFilterOptions"
      [filterSortConfig]="filterSortConfig"
      [initialSelection]="currentClientID"
      (editClicked)="editEvent($event)"
      (filterChanged)="onCurrentFilterChanged($event)"
      (navigateToEventDashboard)="navigateToEventDashboard($event)"
      (cancelArchiveEvent)="cancelArchiveEvent($event)"
    ></app-event-list-table>
  </div>
  <app-paginator
    [totalItems]="total"
    (nextPage)="getNextPage($event)"
    (entriesPerPageChanged)="setEntriesPerPage($event)"
    [setDefaultPage]="setDefaultPage$"
  ></app-paginator>

  <div *ngIf="showSpinner" class="spinner">
    <app-spinner></app-spinner>
  </div>

</div>

