<div class="widget-simple-wrapper">
  <div class="widget-simple-column">
    <div class="widget-simple-title-wrapper">
      <span class="widget-simple-title">{{'DASH.YTD Donor Retention' | translate | uppercase}}</span>
    </div>
    <div class="widget-simple-data">
      <div class="widget-simple-data-item">
        <span class="widget-simple-data-item-title">{{'DASH.First Time' | translate}}</span>
        <span
          *ngIf="totalNewDonors !== null; else topShimmerTemplate"
          (click)="goToReport('First')"
          class="widget-simple-data-item-value"
        >
          {{totalNewDonors | number}}
        </span>
      </div>
      <div class="widget-simple-data-item">
        <span class="widget-simple-data-item-title">{{'DASH.Retained' | translate}}</span>
        <span
          *ngIf="totalActiveDonors !== null; else topShimmerTemplate"
          (click)="goToReport()"
          class="widget-simple-data-item-value"
        >
          {{totalActiveDonors | number}}
        </span>
      </div>

    </div>
    <div *ngIf="totalPriorYearDonors > 0" class="widget-simple-changes">
      <div [ngClass]="totalPriorYearDonors > 0 ? 'widget-simple-triangle-up' : 'widget-simple-triangle-down'"></div>
      <span [ngClass]="totalPriorYearDonors > 0 ? 'widget-simple-percentage widget-simple-up' : 'widget-simple-percentage widget-simple-down'">{{retentionRate | number}}&#37;</span>
      <span class="widget-simple-period">{{'DASH.Retention Level' | translate}}</span>
    </div>
    <div *ngIf="totalPriorYearDonors === 0" width="250px" height="18px">
    </div>
  </div>
  <div class="widget-simple-column margin-left">
    <div class="widget-simple-icon">
      <img src="/assets/images/donor_3.png" alt="widget-simple-icon">
    </div>
    <a routerLink="/clients/donors" class="widget-simple-link">
      <span>{{'View All' | translate}}</span>
      <div class="widget-simple-triangle-right"></div>
    </a>
  </div>
</div>

<ng-template #topShimmerTemplate>
  <ngx-shimmer-loading width="50px" height="36px"></ngx-shimmer-loading>
</ng-template>

<ng-template #bottomShimmerTemplate>
  <ngx-shimmer-loading width="250px" height="18px"></ngx-shimmer-loading>
</ng-template>
