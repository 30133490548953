<div class="history-templates-table-container">
  <table mat-table [dataSource]="tableSource" *ngIf="tableSource">
    <ng-container [matColumnDef]="column" *ngFor="let column of tableDataColumns">
      <th mat-header-cell *matHeaderCellDef class="table-header">
        <div class="table-header-title-wrapper">
          <span class="title">{{getColumnTitle(column)}}</span>
          <mat-form-field class="hidden">
            <mat-select #sortSelect (selectionChange)="sortChange($event.value, column)">
              <mat-option *ngFor="let direction of sortingDirectionOptions" [value]="direction.value">
                {{ direction.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span *ngIf="showSorting(column)" (click)="sortSelect.toggle()" class="column-filter">
            <i class="fas fa-sort action-icon"></i>
          </span>
          <span *ngIf="column=='tagName'" [matMenuTriggerFor]="search" class="column-filter">
            <i class="fas fa-search magnifier"></i>
          </span>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="widget-table-cell" [appTooltip]="tooltip">
        <div matTooltip="{{element[column]}}" #tooltip="matTooltip" matTooltipPosition="above" matTooltipClass="table-tooltip"></div>
        <ng-container [ngSwitch]="column">
          <div class="d-flex align-items-center" *ngSwitchCase="'tagName'">
            <span class="tag-title">{{element[column]}}</span>
          </div>
          <div class="d-flex align-items-center" *ngSwitchCase="'lastUsedByName'">
            <!-- <span class="edit-link" (click)="navigateTo(element)">{{element[column]}}</span> -->
            <span class="edit-link">{{element[column]}}</span>
          </div>
          <span *ngSwitchDefault>
            {{getTextNode(element, column)}}
          </span>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;" class="table-row"></tr>

  </table>

</div>

<mat-menu #search="matMenu" xPosition="after" class="search-filter-wrapper">
  <div (click)="$event.stopPropagation();" class="input-wrapper-admin-table">
    <input (input)="onTagNameSearch($event.target.value)" type="search">
    <i class="fas fa-search magnifier"></i>
  </div>
</mat-menu>
