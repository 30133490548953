import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TemplateManagementService } from '../../../../services/templates/template.management.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import MjmlElementModel from '../../../../models/templates/mjml.element.model';
import FormElementDataModel from '../../../../models/form.element.data.model';
import { FONTS, FONT_SIZES, TEXT_ALIGN, TOOLTIP_TEXT_FOR_FONTS } from '../../../../constants';
import { MjmlTag } from '../../../../models/templates/mjml.tag.type';
import { TemplateType } from '../../../../models/templates/template.type';
import { TemplateAssignmentType } from '../../../../models/templates/template.assignment.type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table-tool-settings',
  templateUrl: './table-tool-settings.component.html',
  styleUrls: ['../tool-settings.scss']
})
export class TableToolSettingsComponent implements OnInit, OnDestroy {
  @Input() public tms: TemplateManagementService;
  private subscription: Subscription = new Subscription();
  public tableForm: FormGroup = this.formBuilder.group({
    columns: [2, [Validators.required, Validators.min(1)]],
    rows: [2, [Validators.required, Validators.min(1)]],

    align: 'left',
    color: '#000000',
    containerBackgroundColor: 'transparent',
    fontFamily: 'Roboto',
    fontSize: '13px',
    width: ['100', [Validators.required, Validators.min(10), Validators.max(100)]],
    paddingBottom: ['10', [Validators.min(0)]],
    paddingLeft: ['25', [Validators.min(0)]],
    paddingRight: ['25', [Validators.min(0)]],
    paddingTop: ['10', [Validators.min(0)]]
  });

  public alignOptions: FormElementDataModel[] = TEXT_ALIGN;
  public fontFamilyOptions: FormElementDataModel[] = FONTS;
  public fontSizeOptions: FormElementDataModel[] = FONT_SIZES;
  public TemplateAssignmentType = TemplateAssignmentType;
  public toolTipText: string = this.translate.instant(TOOLTIP_TEXT_FOR_FONTS);

  constructor(
    private formBuilder: FormBuilder,
    public translate: TranslateService,
  ) { }

  public ngOnInit() {
    this.setInitialValues();

    this.subscription.add(
      this.tms.activeElementEmitter.subscribe((element: MjmlElementModel) => {
        if (!element || element.tagName !== MjmlTag.table) {
          return;
        }
        this.setInitialValues();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getTableHTML(): string {
    const rows = this.tableForm.get('rows').value;
    const columns = this.tableForm.get('columns').value;
    return this.tms.getTableHtml(rows, columns);
  }

  private setInitialValues(): void {
    const attributes = this.tms.activeElement.attributes;
    const rows = attributes['rows'] || 2;
    const columns = attributes['columns'] || 2;
    const align = attributes['align'] || 'left';
    const color = attributes['color'] || '#000000';
    const containerBackgroundColor = attributes['container-background-color'] || 'transparent';
    const fontFamily = attributes['font-family'] || 'Roboto';
    const fontSize = attributes['font-size'] || '13px';
    const width = attributes['width'] ? attributes['width'].slice(0, -1) : '100';
    const paddingBottom = attributes['padding-bottom'] || '10px';
    const paddingLeft = attributes['padding-left'] || '25px';
    const paddingRight = attributes['padding-right'] || '25px';
    const paddingTop = attributes['padding-top'] || '10px';

    this.tableForm.get('rows').setValue(rows);
    this.tableForm.get('columns').setValue(columns);
    this.tableForm.get('align').setValue(align);
    this.tableForm.get('color').setValue(color);
    this.tableForm.get('containerBackgroundColor').setValue(containerBackgroundColor);
    this.tableForm.get('fontFamily').setValue(fontFamily);
    this.tableForm.get('fontSize').setValue(fontSize);
    this.tableForm.get('width').setValue(width);
    this.tableForm.get('paddingBottom').setValue(paddingBottom);
    this.tableForm.get('paddingLeft').setValue(paddingLeft);
    this.tableForm.get('paddingRight').setValue(paddingRight);
    this.tableForm.get('paddingTop').setValue(paddingTop);
  }

  public get readOnly(): boolean {
    return this.tms.readOnly;
  }

  public get isPredesignedThemedTemplate(): boolean {
    return this.tms.templateType === TemplateType.Themed;
  }

  public onColumnsChanged(): void {
    const rows = this.tableForm.get('rows').value;
    const columns = this.tableForm.get('columns').value;
    if (columns < 1 || rows < 1) {
      return;
    }
    this.tms.activeElement.attributes['columns'] = columns;
    this.tms.activeElement.content = this.getTableHTML();
    this.tms.tableElementChanged();
  }

  public onRowsChanged(): void {
    const rows = this.tableForm.get('rows').value;
    const columns = this.tableForm.get('columns').value;
    if (rows < 1 || columns < 1) {
      return;
    }
    this.tms.activeElement.attributes['rows'] = rows;
    this.tms.activeElement.content = this.getTableHTML();
    this.tms.tableElementChanged();
  }

  public onColorChanged(): void {
    const value = this.tableForm.get('color').value;
    this.tms.activeElement.attributes['color'] = value ? value : '#000000';
    this.tms.emitSave(true);
  }

  public onAlignChanged(): void {
    const value = this.tableForm.get('align').value;
    this.tms.activeElement.attributes['align'] = value ? value : 'left';
    this.tms.emitSave(true);
  }

  public onContainerBackgroundColorChanged(): void {
    const value = this.tableForm.get('containerBackgroundColor').value;
    this.tms.activeElement.attributes['container-background-color'] = value ? value : '';
    this.tms.emitSave(true);
  }

  public onFontFamilyChanged(): void {
    const value = this.tableForm.get('fontFamily').value;
    this.tms.activeElement.attributes['font-family'] = value ? value : 'Roboto';
    this.tms.emitSave(true);
  }

  public onFontSizeChanged(): void {
    const value = this.tableForm.get('fontSize').value;
    this.tms.activeElement.attributes['font-size'] = value ? value : '13px';
    this.tms.emitSave(true);
  }

  public onWidthChanged(): void {
    const value = this.tableForm.get('width').value;
    this.tms.activeElement.attributes['width'] = value ? `${value}%` : '100%';
    this.tms.emitSave(true);
  }

  public onPaddingTopChanged(): void {
    const value = this.tableForm.get('paddingTop').value;
    this.tms.activeElement.attributes['padding-top'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingRightChanged(): void {
    const value = this.tableForm.get('paddingRight').value;
    this.tms.activeElement.attributes['padding-right'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingBottomChanged(): void {
    const value = this.tableForm.get('paddingBottom').value;
    this.tms.activeElement.attributes['padding-bottom'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }

  public onPaddingLeftChanged(): void {
    const value = this.tableForm.get('paddingLeft').value;
    this.tms.activeElement.attributes['padding-left'] = value ? `${value}px` : '0px';
    this.tms.emitSave(true);
  }
}
