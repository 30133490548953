import { BaseTableComponent } from '../base-table/base-table.component';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { EventParticipantType } from '../../models/enum/event.participant.type';
import { MatTableDataSource } from '@angular/material/table';
import { SortOrder } from '../../models/enum/sort.order';
import ColumnFilterModel from '../../models/filter-sort/column.filter.model';
import ColumnFilterOptionsModel from '../../models/filter-sort/column.filter.options.model';
import EventParticipantModel from '../../models/event/event.participant.model';
import FilterSortModel from '../../models/filter-sort/filter.sort.model';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
  selector: 'app-history-event-attended-table',
  templateUrl: './history-event-attended-table.component.html',
  styleUrls: ['./history-event-attended-table.component.scss']
})
export class HistoryEventAttendedTableComponent extends BaseTableComponent<EventParticipantModel> implements OnChanges {
  @Input() public columnsFilterOptions: ColumnFilterOptionsModel[] = [];
  @Input() public filterSortConfig: FilterSortModel;
  @Output() public filterChanged: EventEmitter<ColumnFilterModel> = new EventEmitter<ColumnFilterModel>();
  @Output() public sortChanged: EventEmitter<FilterSortModel> = new EventEmitter<FilterSortModel>();
  public SortOrder = SortOrder;

  constructor() {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableDataColumns) {
      this.tableColumns = [...this.tableDataColumns];
    }
    if (changes.data && this.data) {
      this.tableSource = new MatTableDataSource<EventParticipantModel>(this.data);
    }
  }

  public getColumnTitle(column: string): string {
    const columnsTitles = {
      totalCost: 'Total Payment',
      eventName: 'Event',
      createdOn: 'Registration Date',
      participantType: 'Guest Type',
      participantEventComment: 'Note'
    };
    return columnsTitles[column];
  }

  public getParticipantType(element: EventParticipantModel): string {
    const participantTypes = {
      [EventParticipantType.Celebrity]: 'Celebrity',
      [EventParticipantType.Speaker]: 'Speaker',
      [EventParticipantType.Sponsor]: 'Sponsor',
      [EventParticipantType.Standard]: 'Standard'
    };
    return participantTypes[element.participantType];
  }

  public getNotes(element: EventParticipantModel): string{
    return element.participantEventComment;
  }

  public mouseOver(tooltip: MatTooltip){
    tooltip.disabled = true;
  }

  public toggleTooltip(tooltip: MatTooltip){
    if (tooltip.disabled ) {
      tooltip.disabled = false;
      tooltip.show();
    } else {
      tooltip.hide();
      tooltip.disabled = true;
    }
  }

  public mouseLeave(tooltip: MatTooltip){
    tooltip.hide();
    tooltip.disabled = true;
  }

  public getSelectOptions(column: string): ColumnFilterOptionsModel {
    const field = column === 'eventName' ? 'eventID' : column;
    return this.columnsFilterOptions.find(options => options.columnName === field);
  }

  public selectedOptions(event: ColumnFilterModel): void {
    this.filterChanged.emit(event);
  }

  public isActiveFilter(column): boolean {
    const columnName = column === 'eventName' ? 'eventID' : column;
    if (!this.filterSortConfig) {
      return false;
    } else {
      const { columnFilters }: FilterSortModel = this.filterSortConfig;
      return !!columnFilters.find(({ field }: ColumnFilterModel) => field === columnName);
    }
  }

  public getTotalCost(value): string {
    if (!value || value.toString() === '0') {
      return '-';
    } else {
      return `$${value}`;
    }
  }

  public sortTable(column: string): void {
    const columnName = column === 'eventName' ? 'eventID' : column;
    let sortOrder: SortOrder;
    if (this.filterSortConfig.sortField === columnName) {
      switch (this.filterSortConfig.sortOrder) {
        case SortOrder.Ascending:
          sortOrder = SortOrder.Descending;
          break;
        case SortOrder.Descending:
          sortOrder = SortOrder.Ascending;
      }
    } else {
      sortOrder = SortOrder.Ascending;
    }
    const result: FilterSortModel = {
      sortField: columnName,
      sortOrder,
      columnFilters: this.filterSortConfig.columnFilters
    };
    this.sortChanged.emit(result);
  }

  public checkColumn(column: string): boolean {
    const columnName = column === 'eventName' ? 'eventID' : column;
    return this.filterSortConfig.sortField === columnName;
  }

  public getSortOrder(column: string): SortOrder {
    const columnName = column === 'eventName' ? 'eventID' : column;
    return this.filterSortConfig.sortField === columnName ? this.filterSortConfig.sortOrder : null;
  }
}
